const gender = `Gender,collectionName,pentaSliceEnd,pubCount
,Cooperation2021,1915,1
,Cooperation2021,1960,2
,Cooperation2021,1965,19
,Cooperation2021,1970,53
,Cooperation2021,1975,37
,Cooperation2021,1980,14
,Cooperation2021,1985,31
,Cooperation2021,1990,22
,Cooperation2021,1995,35
,Cooperation2021,2000,70
,Cooperation2021,2005,175
,Cooperation2021,2010,295
,Cooperation2021,2015,323
,CooperationAndReligion,1980,2
,CooperationAndReligion,1995,1
,CooperationAndReligion,2000,4
,CooperationAndReligion,2005,4
,CooperationAndReligion,2010,23
,CooperationAndReligion,2015,25
,Religion2020,1910,10
,Religion2020,1915,11
,Religion2020,1920,2
,Religion2020,1925,5
,Religion2020,1930,10
,Religion2020,1935,7
,Religion2020,1940,9
,Religion2020,1945,10
,Religion2020,1950,20
,Religion2020,1955,32
,Religion2020,1960,51
,Religion2020,1965,77
,Religion2020,1970,84
,Religion2020,1975,114
,Religion2020,1980,126
,Religion2020,1985,137
,Religion2020,1990,209
,Religion2020,1995,259
,Religion2020,2000,346
,Religion2020,2005,640
,Religion2020,2010,1197
,Religion2020,2015,1212
Female,Cooperation2021,1950,1
Female,Cooperation2021,1955,2
Female,Cooperation2021,1960,1
Female,Cooperation2021,1965,5
Female,Cooperation2021,1970,18
Female,Cooperation2021,1975,32
Female,Cooperation2021,1980,44
Female,Cooperation2021,1985,53
Female,Cooperation2021,1990,87
Female,Cooperation2021,1995,204
Female,Cooperation2021,2000,360
Female,Cooperation2021,2005,741
Female,Cooperation2021,2010,1302
Female,Cooperation2021,2015,1385
Female,CooperationAndReligion,1975,2
Female,CooperationAndReligion,1980,1
Female,CooperationAndReligion,1985,1
Female,CooperationAndReligion,1990,3
Female,CooperationAndReligion,1995,4
Female,CooperationAndReligion,2000,17
Female,CooperationAndReligion,2005,44
Female,CooperationAndReligion,2010,96
Female,CooperationAndReligion,2015,152
Female,Religion2020,1900,1
Female,Religion2020,1905,3
Female,Religion2020,1910,15
Female,Religion2020,1915,6
Female,Religion2020,1920,4
Female,Religion2020,1925,4
Female,Religion2020,1930,4
Female,Religion2020,1935,2
Female,Religion2020,1940,13
Female,Religion2020,1945,11
Female,Religion2020,1950,10
Female,Religion2020,1955,5
Female,Religion2020,1960,14
Female,Religion2020,1965,32
Female,Religion2020,1970,82
Female,Religion2020,1975,163
Female,Religion2020,1980,332
Female,Religion2020,1985,550
Female,Religion2020,1990,850
Female,Religion2020,1995,1244
Female,Religion2020,2000,2202
Female,Religion2020,2005,4014
Female,Religion2020,2010,6901
Female,Religion2020,2015,8539
Male,Cooperation2021,1840,1
Male,Cooperation2021,1865,1
Male,Cooperation2021,1875,1
Male,Cooperation2021,1890,1
Male,Cooperation2021,1895,15
Male,Cooperation2021,1900,11
Male,Cooperation2021,1905,1
Male,Cooperation2021,1910,6
Male,Cooperation2021,1915,5
Male,Cooperation2021,1920,8
Male,Cooperation2021,1925,8
Male,Cooperation2021,1930,10
Male,Cooperation2021,1935,7
Male,Cooperation2021,1940,13
Male,Cooperation2021,1945,15
Male,Cooperation2021,1950,24
Male,Cooperation2021,1955,42
Male,Cooperation2021,1960,54
Male,Cooperation2021,1965,135
Male,Cooperation2021,1970,282
Male,Cooperation2021,1975,364
Male,Cooperation2021,1980,328
Male,Cooperation2021,1985,463
Male,Cooperation2021,1990,550
Male,Cooperation2021,1995,1016
Male,Cooperation2021,2000,1915
Male,Cooperation2021,2005,3014
Male,Cooperation2021,2010,4667
Male,Cooperation2021,2015,4450
Male,CooperationAndReligion,1840,1
Male,CooperationAndReligion,1945,1
Male,CooperationAndReligion,1950,1
Male,CooperationAndReligion,1955,3
Male,CooperationAndReligion,1965,1
Male,CooperationAndReligion,1970,4
Male,CooperationAndReligion,1975,3
Male,CooperationAndReligion,1980,6
Male,CooperationAndReligion,1985,11
Male,CooperationAndReligion,1990,12
Male,CooperationAndReligion,1995,36
Male,CooperationAndReligion,2000,46
Male,CooperationAndReligion,2005,102
Male,CooperationAndReligion,2010,192
Male,CooperationAndReligion,2015,276
Male,Religion2020,1820,2
Male,Religion2020,1825,11
Male,Religion2020,1830,2
Male,Religion2020,1835,2
Male,Religion2020,1840,6
Male,Religion2020,1845,4
Male,Religion2020,1850,6
Male,Religion2020,1855,1
Male,Religion2020,1860,2
Male,Religion2020,1875,9
Male,Religion2020,1880,10
Male,Religion2020,1885,13
Male,Religion2020,1890,34
Male,Religion2020,1895,82
Male,Religion2020,1900,131
Male,Religion2020,1905,113
Male,Religion2020,1910,186
Male,Religion2020,1915,169
Male,Religion2020,1920,136
Male,Religion2020,1925,202
Male,Religion2020,1930,220
Male,Religion2020,1935,197
Male,Religion2020,1940,246
Male,Religion2020,1945,341
Male,Religion2020,1950,462
Male,Religion2020,1955,511
Male,Religion2020,1960,768
Male,Religion2020,1965,1113
Male,Religion2020,1970,1579
Male,Religion2020,1975,2012
Male,Religion2020,1980,2472
Male,Religion2020,1985,3267
Male,Religion2020,1990,4093
Male,Religion2020,1995,5241
Male,Religion2020,2000,7093
Male,Religion2020,2005,11152
Male,Religion2020,2010,18140
Male,Religion2020,2015,20861
`

export default gender;