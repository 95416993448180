const colorList = ['#2E91E5', '#E15F99', '#1CA71C', '#FB0D0D', '#DA16FF', '#222A2A', '#B68100', '#750D86', '#EB663B', '#511CFB', '#00A08B', '#FB00D1', '#FC0080', '#B2828D', '#6C7C32', '#778AAE', '#862A16', '#A777F1', '#620042', '#1616A7', '#DA60CA', '#6C4516', '#0D2A63', '#AF0038']

const colorMap = {
    'Caucasian or White': colorList[0],
    'South Asian': colorList[1],
    'Other': colorList[2],
    'African or Black': colorList[3],
    'East or Southeast Asian': colorList[4],
    'Hispanic or Latinx': colorList[5],

    'Male': colorList[0],
    'Female': colorList[1],

    'Non-Academic': colorList[0],
    'Researcher': colorList[1],
    'Professor Emeritus': colorList[2],
    'Assistant Professor': colorList[3],
    'Professor': colorList[4],
    'Adjunct Professor': colorList[5],
    'Associate Professor': colorList[6],
    'Graduate Student': colorList[7],
    'Postdoctoral Fellow': colorList[8],
    'Undergraduate Student': colorList[9],

    "1610's": colorList[0],
    "1790's": colorList[1],
    "1820's": colorList[2],
    "1830's": colorList[3],
    "1840's": colorList[4],
    "1850's": colorList[5],
    "1860's": colorList[6],
    "1870's": colorList[7],
    "1880's": colorList[8],
    "1890's": colorList[9],
    "1900's": colorList[10],
    "1910's": colorList[11],
    "1920's": colorList[12],
    "1930's": colorList[13],
    "1940's": colorList[14],
    "1950's": colorList[15],
    "1960's": colorList[16],
    "1970's": colorList[17],
    "1980's": colorList[18],
    "1990's": colorList[19],
    "2000's": colorList[20],
    "2010's": colorList[21],
    "2020's": colorList[22]
}

export default colorMap;