import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from "react-plotly.js/factory";
import {useEffect} from "react";
import $ from 'jquery'

const BPLOT = ({title, data, height}) => {
    const Plot = createPlotlyComponent(Plotly);

    var trace = {
        x: data[0],
        y: data[1],
        type: 'bar'
    }

    useEffect(() => {
        $('.xtick').css('height', '200px')
    }, [])

    return (
        <>
            <Plot data={[trace]}
                  layout={{
                      autosize: true,
                      // font: {
                      //     size: 15
                      // },
                      title: {
                          text: title,
                          font: {
                              size: 16
                          },
                          xref: 'paper',
                          x: -0.5,
                      },
                      xaxis: {
                          automargin: true
                      },
                      showlegend: false,
                      width: window.innerWidth * 0.31,
                      height: window.innerHeight * height
                  }}/>
        </>
    );
}

export default BPLOT;