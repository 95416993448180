import clusterTextDict from "../assets/clusterTextDict";
import {breakDStats} from "../assets/utils";

const ClusterText = ({textNumber}) => {
    let allText = ''
    let title = ''
    let text = ''
    let description = ''
    let textArr = []

    if (clusterTextDict[textNumber.toString()]) {
        allText = clusterTextDict[textNumber.toString()].split('~')
        title = allText[0]
        text = allText[1]
        const testText = text.split('*')
        if (testText.length > 1) {
            description = testText[0]
            text = testText[1]
        }
        textArr = breakDStats(text)
    }

    return (
        allText !== '' &&
        <>
            <b>{title}</b>
            {
                description !== '' && <p style={{'marginTop': '10px'}}>{description}</p>
            }
            {/*break 'text' into subsections: first, Departments:, Locations:, Gender:, Ethnicity:*/}
            {/*each of these subsections is a part of a text array*/}
            {
                (textArr !== [] &&
                textArr.map(str => {
                    return (
                        <p style={{'marginTop': '10px'}}>{str}</p>
                    )
                })) || <p style={{'marginTop': '10px'}}>{text}</p>
            }
        </>
    )
}

export default ClusterText;