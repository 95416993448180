const termDegree = `Decade,collectionName,pentaSliceEnd,pubCount
1610's,Religion2020,1900,1
1610's,Religion2020,1985,1
1610's,Religion2020,1990,1
1610's,Religion2020,1995,13
1610's,Religion2020,2005,2
1610's,Religion2020,2015,1
1790's,Religion2020,1820,1
1790's,Religion2020,1825,1
1790's,Religion2020,1835,1
1790's,Religion2020,1845,2
1790's,Religion2020,1850,1
1790's,Religion2020,1860,1
1790's,Religion2020,1900,1
1790's,Religion2020,2005,1
1790's,Religion2020,2015,1
1820's,Cooperation2021,2010,1
1820's,CooperationAndReligion,2010,1
1820's,Religion2020,1830,1
1820's,Religion2020,1955,10
1820's,Religion2020,1965,2
1820's,Religion2020,1970,2
1820's,Religion2020,1990,1
1820's,Religion2020,2005,21
1820's,Religion2020,2010,3
1830's,Cooperation2021,1875,1
1830's,Religion2020,1875,8
1830's,Religion2020,1885,9
1830's,Religion2020,1890,1
1830's,Religion2020,1900,1
1830's,Religion2020,1905,3
1830's,Religion2020,1910,3
1830's,Religion2020,1915,2
1830's,Religion2020,1920,1
1830's,Religion2020,1925,1
1830's,Religion2020,2005,1
1840's,Religion2020,1890,1
1840's,Religion2020,2010,9
1850's,Religion2020,1880,8
1850's,Religion2020,1895,3
1850's,Religion2020,1900,7
1860's,Religion2020,1895,1
1860's,Religion2020,1900,2
1860's,Religion2020,1905,1
1860's,Religion2020,1910,1
1860's,Religion2020,1920,1
1860's,Religion2020,1945,1
1860's,Religion2020,1965,1
1860's,Religion2020,1970,4
1860's,Religion2020,1975,3
1860's,Religion2020,2000,2
1860's,Religion2020,2010,2
1860's,Religion2020,2015,1
1870's,Cooperation2021,1895,1
1870's,Religion2020,1885,2
1870's,Religion2020,1890,6
1870's,Religion2020,1895,12
1870's,Religion2020,1900,8
1870's,Religion2020,1905,5
1870's,Religion2020,1910,12
1870's,Religion2020,1915,12
1870's,Religion2020,1920,7
1870's,Religion2020,1925,1
1870's,Religion2020,1935,1
1870's,Religion2020,1960,1
1880's,Cooperation2021,1890,1
1880's,Cooperation2021,1895,2
1880's,Cooperation2021,1900,3
1880's,Cooperation2021,1910,1
1880's,Cooperation2021,2015,1
1880's,Religion2020,1885,1
1880's,Religion2020,1890,12
1880's,Religion2020,1895,29
1880's,Religion2020,1900,36
1880's,Religion2020,1905,20
1880's,Religion2020,1910,12
1880's,Religion2020,1915,17
1880's,Religion2020,1920,11
1880's,Religion2020,1925,4
1880's,Religion2020,1930,12
1880's,Religion2020,1935,6
1880's,Religion2020,1940,2
1880's,Religion2020,1945,2
1880's,Religion2020,1950,4
1880's,Religion2020,1955,6
1880's,Religion2020,1960,5
1880's,Religion2020,1965,1
1880's,Religion2020,1975,1
1880's,Religion2020,1980,1
1880's,Religion2020,1990,6
1880's,Religion2020,1995,5
1880's,Religion2020,2000,2
1880's,Religion2020,2005,2
1880's,Religion2020,2010,5
1880's,Religion2020,2015,4
1890's,Cooperation2021,1895,12
1890's,Cooperation2021,1900,6
1890's,Cooperation2021,1905,1
1890's,Cooperation2021,1910,2
1890's,Cooperation2021,1915,4
1890's,Cooperation2021,1920,3
1890's,Cooperation2021,1925,2
1890's,Cooperation2021,1930,1
1890's,Cooperation2021,1935,2
1890's,Cooperation2021,1960,1
1890's,Cooperation2021,1970,1
1890's,Cooperation2021,2010,1
1890's,Religion2020,1890,10
1890's,Religion2020,1895,28
1890's,Religion2020,1900,58
1890's,Religion2020,1905,54
1890's,Religion2020,1910,80
1890's,Religion2020,1915,65
1890's,Religion2020,1920,34
1890's,Religion2020,1925,53
1890's,Religion2020,1930,17
1890's,Religion2020,1935,22
1890's,Religion2020,1940,12
1890's,Religion2020,1945,22
1890's,Religion2020,1950,7
1890's,Religion2020,1955,2
1890's,Religion2020,1960,1
1890's,Religion2020,1965,1
1890's,Religion2020,1975,10
1890's,Religion2020,2000,8
1890's,Religion2020,2005,6
1890's,Religion2020,2010,8
1890's,Religion2020,2015,4
1900's,Cooperation2021,1920,1
1900's,Cooperation2021,1930,1
1900's,Cooperation2021,1935,1
1900's,Cooperation2021,1940,1
1900's,Cooperation2021,1945,1
1900's,Cooperation2021,1955,1
1900's,CooperationAndReligion,1955,1
1900's,Religion2020,1895,1
1900's,Religion2020,1900,1
1900's,Religion2020,1905,19
1900's,Religion2020,1910,45
1900's,Religion2020,1915,33
1900's,Religion2020,1920,30
1900's,Religion2020,1925,28
1900's,Religion2020,1930,20
1900's,Religion2020,1935,15
1900's,Religion2020,1940,10
1900's,Religion2020,1945,3
1900's,Religion2020,1950,11
1900's,Religion2020,1955,7
1900's,Religion2020,1960,3
1900's,Religion2020,1965,4
1900's,Religion2020,1990,2
1900's,Religion2020,2005,1
1900's,Religion2020,2010,2
1910's,Cooperation2021,1910,1
1910's,Cooperation2021,1915,1
1910's,Cooperation2021,1920,1
1910's,Cooperation2021,1925,2
1910's,Cooperation2021,1930,6
1910's,Cooperation2021,1935,1
1910's,Cooperation2021,1940,5
1910's,Cooperation2021,1945,1
1910's,Cooperation2021,1950,5
1910's,Cooperation2021,1955,2
1910's,CooperationAndReligion,1955,1
1910's,Religion2020,1900,2
1910's,Religion2020,1905,4
1910's,Religion2020,1910,16
1910's,Religion2020,1915,16
1910's,Religion2020,1920,28
1910's,Religion2020,1925,42
1910's,Religion2020,1930,64
1910's,Religion2020,1935,46
1910's,Religion2020,1940,63
1910's,Religion2020,1945,45
1910's,Religion2020,1950,62
1910's,Religion2020,1955,24
1910's,Religion2020,1960,32
1910's,Religion2020,1965,27
1910's,Religion2020,1970,2
1910's,Religion2020,1975,2
1910's,Religion2020,1980,1
1910's,Religion2020,1985,6
1910's,Religion2020,2005,8
1910's,Religion2020,2010,4
1910's,Religion2020,2015,2
1920's,Cooperation2021,1925,3
1920's,Cooperation2021,1930,2
1920's,Cooperation2021,1935,1
1920's,Cooperation2021,1940,2
1920's,Cooperation2021,1945,3
1920's,Cooperation2021,1950,1
1920's,Cooperation2021,1955,3
1920's,Cooperation2021,1960,3
1920's,Cooperation2021,1965,2
1920's,Cooperation2021,1970,5
1920's,Cooperation2021,1975,3
1920's,Cooperation2021,1985,1
1920's,CooperationAndReligion,1965,1
1920's,CooperationAndReligion,1975,2
1920's,Religion2020,1910,1
1920's,Religion2020,1915,1
1920's,Religion2020,1920,11
1920's,Religion2020,1925,43
1920's,Religion2020,1930,57
1920's,Religion2020,1935,55
1920's,Religion2020,1940,58
1920's,Religion2020,1945,78
1920's,Religion2020,1950,74
1920's,Religion2020,1955,37
1920's,Religion2020,1960,46
1920's,Religion2020,1965,45
1920's,Religion2020,1970,27
1920's,Religion2020,1975,13
1920's,Religion2020,1980,2
1920's,Religion2020,1985,5
1920's,Religion2020,1990,2
1920's,Religion2020,1995,2
1920's,Religion2020,2000,1
1920's,Religion2020,2005,2
1920's,Religion2020,2010,10
1920's,Religion2020,2015,2
1930's,Cooperation2021,1940,2
1930's,Cooperation2021,1945,3
1930's,Cooperation2021,1950,3
1930's,Cooperation2021,1955,4
1930's,Cooperation2021,1960,3
1930's,Cooperation2021,1965,3
1930's,Cooperation2021,1970,10
1930's,Cooperation2021,1975,7
1930's,Cooperation2021,1980,4
1930's,Cooperation2021,1985,2
1930's,Cooperation2021,2000,2
1930's,CooperationAndReligion,1950,1
1930's,CooperationAndReligion,1975,1
1930's,CooperationAndReligion,1980,1
1930's,CooperationAndReligion,1985,1
1930's,Religion2020,1930,8
1930's,Religion2020,1935,21
1930's,Religion2020,1940,36
1930's,Religion2020,1945,57
1930's,Religion2020,1950,77
1930's,Religion2020,1955,81
1930's,Religion2020,1960,98
1930's,Religion2020,1965,86
1930's,Religion2020,1970,96
1930's,Religion2020,1975,77
1930's,Religion2020,1980,49
1930's,Religion2020,1985,34
1930's,Religion2020,1990,9
1930's,Religion2020,1995,7
1930's,Religion2020,2000,6
1930's,Religion2020,2005,8
1930's,Religion2020,2010,12
1930's,Religion2020,2015,9
1940's,Cooperation2021,1945,2
1940's,Cooperation2021,1950,10
1940's,Cooperation2021,1955,4
1940's,Cooperation2021,1960,3
1940's,Cooperation2021,1965,21
1940's,Cooperation2021,1970,17
1940's,Cooperation2021,1975,36
1940's,Cooperation2021,1980,7
1940's,Cooperation2021,1985,17
1940's,Cooperation2021,1990,13
1940's,Cooperation2021,1995,11
1940's,Cooperation2021,2000,11
1940's,Cooperation2021,2005,7
1940's,Cooperation2021,2010,5
1940's,Cooperation2021,2015,11
1940's,CooperationAndReligion,1970,1
1940's,CooperationAndReligion,1985,1
1940's,CooperationAndReligion,1990,1
1940's,CooperationAndReligion,1995,1
1940's,Religion2020,1920,1
1940's,Religion2020,1935,3
1940's,Religion2020,1940,19
1940's,Religion2020,1945,32
1940's,Religion2020,1950,72
1940's,Religion2020,1955,97
1940's,Religion2020,1960,141
1940's,Religion2020,1965,148
1940's,Religion2020,1970,158
1940's,Religion2020,1975,113
1940's,Religion2020,1980,112
1940's,Religion2020,1985,91
1940's,Religion2020,1990,73
1940's,Religion2020,1995,51
1940's,Religion2020,2000,43
1940's,Religion2020,2005,24
1940's,Religion2020,2010,22
1940's,Religion2020,2015,30
1950's,Cooperation2021,1950,4
1950's,Cooperation2021,1955,15
1950's,Cooperation2021,1960,28
1950's,Cooperation2021,1965,36
1950's,Cooperation2021,1970,48
1950's,Cooperation2021,1975,68
1950's,Cooperation2021,1980,30
1950's,Cooperation2021,1985,31
1950's,Cooperation2021,1990,40
1950's,Cooperation2021,1995,40
1950's,Cooperation2021,2000,31
1950's,Cooperation2021,2005,24
1950's,Cooperation2021,2010,31
1950's,Cooperation2021,2015,18
1950's,CooperationAndReligion,1980,1
1950's,CooperationAndReligion,1985,1
1950's,CooperationAndReligion,1990,1
1950's,CooperationAndReligion,1995,2
1950's,CooperationAndReligion,2005,1
1950's,CooperationAndReligion,2010,3
1950's,CooperationAndReligion,2015,1
1950's,Religion2020,1940,2
1950's,Religion2020,1945,4
1950's,Religion2020,1950,30
1950's,Religion2020,1955,135
1950's,Religion2020,1960,252
1950's,Religion2020,1965,341
1950's,Religion2020,1970,343
1950's,Religion2020,1975,353
1950's,Religion2020,1980,336
1950's,Religion2020,1985,373
1950's,Religion2020,1990,321
1950's,Religion2020,1995,280
1950's,Religion2020,2000,206
1950's,Religion2020,2005,220
1950's,Religion2020,2010,296
1950's,Religion2020,2015,165
1960's,Cooperation2021,1955,6
1960's,Cooperation2021,1960,5
1960's,Cooperation2021,1965,55
1960's,Cooperation2021,1970,106
1960's,Cooperation2021,1975,119
1960's,Cooperation2021,1980,78
1960's,Cooperation2021,1985,112
1960's,Cooperation2021,1990,98
1960's,Cooperation2021,1995,134
1960's,Cooperation2021,2000,193
1960's,Cooperation2021,2005,161
1960's,Cooperation2021,2010,162
1960's,Cooperation2021,2015,95
1960's,CooperationAndReligion,1975,1
1960's,CooperationAndReligion,1980,1
1960's,CooperationAndReligion,1985,2
1960's,CooperationAndReligion,1990,4
1960's,CooperationAndReligion,1995,8
1960's,CooperationAndReligion,2000,2
1960's,CooperationAndReligion,2005,3
1960's,CooperationAndReligion,2010,9
1960's,CooperationAndReligion,2015,12
1960's,Religion2020,1840,1
1960's,Religion2020,1900,1
1960's,Religion2020,1925,1
1960's,Religion2020,1930,3
1960's,Religion2020,1940,3
1960's,Religion2020,1945,7
1960's,Religion2020,1950,2
1960's,Religion2020,1955,11
1960's,Religion2020,1960,84
1960's,Religion2020,1965,283
1960's,Religion2020,1970,469
1960's,Religion2020,1975,545
1960's,Religion2020,1980,577
1960's,Religion2020,1985,689
1960's,Religion2020,1990,772
1960's,Religion2020,1995,744
1960's,Religion2020,2000,781
1960's,Religion2020,2005,815
1960's,Religion2020,2010,959
1960's,Religion2020,2015,836
1970's,Cooperation2021,1910,2
1970's,Cooperation2021,1920,2
1970's,Cooperation2021,1960,2
1970's,Cooperation2021,1965,13
1970's,Cooperation2021,1970,67
1970's,Cooperation2021,1975,108
1970's,Cooperation2021,1980,149
1970's,Cooperation2021,1985,168
1970's,Cooperation2021,1990,160
1970's,Cooperation2021,1995,284
1970's,Cooperation2021,2000,382
1970's,Cooperation2021,2005,453
1970's,Cooperation2021,2010,521
1970's,Cooperation2021,2015,406
1970's,CooperationAndReligion,1970,1
1970's,CooperationAndReligion,1975,1
1970's,CooperationAndReligion,1980,4
1970's,CooperationAndReligion,1985,2
1970's,CooperationAndReligion,1990,4
1970's,CooperationAndReligion,1995,5
1970's,CooperationAndReligion,2000,8
1970's,CooperationAndReligion,2005,20
1970's,CooperationAndReligion,2010,32
1970's,CooperationAndReligion,2015,43
1970's,Religion2020,1850,1
1970's,Religion2020,1890,1
1970's,Religion2020,1910,3
1970's,Religion2020,1930,1
1970's,Religion2020,1935,1
1970's,Religion2020,1940,2
1970's,Religion2020,1945,7
1970's,Religion2020,1950,4
1970's,Religion2020,1955,5
1970's,Religion2020,1960,21
1970's,Religion2020,1965,36
1970's,Religion2020,1970,192
1970's,Religion2020,1975,620
1970's,Religion2020,1980,1024
1970's,Religion2020,1985,1210
1970's,Religion2020,1990,1411
1970's,Religion2020,1995,1457
1970's,Religion2020,2000,1736
1970's,Religion2020,2005,2219
1970's,Religion2020,2010,3147
1970's,Religion2020,2015,2733
1980's,Cooperation2021,1935,1
1980's,Cooperation2021,1950,1
1980's,Cooperation2021,1955,1
1980's,Cooperation2021,1965,3
1980's,Cooperation2021,1970,4
1980's,Cooperation2021,1975,12
1980's,Cooperation2021,1980,57
1980's,Cooperation2021,1985,110
1980's,Cooperation2021,1990,164
1980's,Cooperation2021,1995,319
1980's,Cooperation2021,2000,584
1980's,Cooperation2021,2005,737
1980's,Cooperation2021,2010,928
1980's,Cooperation2021,2015,772
1980's,CooperationAndReligion,1980,1
1980's,CooperationAndReligion,1985,1
1980's,CooperationAndReligion,1995,12
1980's,CooperationAndReligion,2000,15
1980's,CooperationAndReligion,2005,28
1980's,CooperationAndReligion,2010,41
1980's,CooperationAndReligion,2015,47
1980's,Religion2020,1850,1
1980's,Religion2020,1855,1
1980's,Religion2020,1890,1
1980's,Religion2020,1910,2
1980's,Religion2020,1915,1
1980's,Religion2020,1925,2
1980's,Religion2020,1930,2
1980's,Religion2020,1935,2
1980's,Religion2020,1940,2
1980's,Religion2020,1945,3
1980's,Religion2020,1950,4
1980's,Religion2020,1955,2
1980's,Religion2020,1960,6
1980's,Religion2020,1965,11
1980's,Religion2020,1970,18
1980's,Religion2020,1975,56
1980's,Religion2020,1980,236
1980's,Religion2020,1985,697
1980's,Religion2020,1990,1195
1980's,Religion2020,1995,1577
1980's,Religion2020,2000,1857
1980's,Religion2020,2005,2662
1980's,Religion2020,2010,4018
1980's,Religion2020,2015,3991
1990's,Cooperation2021,1920,1
1990's,Cooperation2021,1960,2
1990's,Cooperation2021,1965,1
1990's,Cooperation2021,1970,10
1990's,Cooperation2021,1975,6
1990's,Cooperation2021,1980,3
1990's,Cooperation2021,1985,12
1990's,Cooperation2021,1990,81
1990's,Cooperation2021,1995,322
1990's,Cooperation2021,2000,606
1990's,Cooperation2021,2005,1115
1990's,Cooperation2021,2010,1631
1990's,Cooperation2021,2015,1441
1990's,CooperationAndReligion,1990,1
1990's,CooperationAndReligion,1995,5
1990's,CooperationAndReligion,2000,15
1990's,CooperationAndReligion,2005,40
1990's,CooperationAndReligion,2010,58
1990's,CooperationAndReligion,2015,107
1990's,Religion2020,1910,1
1990's,Religion2020,1915,5
1990's,Religion2020,1920,1
1990's,Religion2020,1940,1
1990's,Religion2020,1950,2
1990's,Religion2020,1955,3
1990's,Religion2020,1960,1
1990's,Religion2020,1965,7
1990's,Religion2020,1970,23
1990's,Religion2020,1975,27
1990's,Religion2020,1980,33
1990's,Religion2020,1985,85
1990's,Religion2020,1990,351
1990's,Religion2020,1995,1206
1990's,Religion2020,2000,2309
1990's,Religion2020,2005,3686
1990's,Religion2020,2010,5647
1990's,Religion2020,2015,6124
2000's,Cooperation2021,1970,4
2000's,Cooperation2021,1975,6
2000's,Cooperation2021,1980,6
2000's,Cooperation2021,1985,6
2000's,Cooperation2021,1990,10
2000's,Cooperation2021,1995,38
2000's,Cooperation2021,2000,303
2000's,Cooperation2021,2005,1076
2000's,Cooperation2021,2010,2054
2000's,Cooperation2021,2015,1814
2000's,CooperationAndReligion,1985,1
2000's,CooperationAndReligion,1990,1
2000's,CooperationAndReligion,1995,1
2000's,CooperationAndReligion,2000,8
2000's,CooperationAndReligion,2005,36
2000's,CooperationAndReligion,2010,102
2000's,CooperationAndReligion,2015,126
2000's,Religion2020,1950,1
2000's,Religion2020,1965,1
2000's,Religion2020,1970,16
2000's,Religion2020,1975,3
2000's,Religion2020,1980,21
2000's,Religion2020,1985,44
2000's,Religion2020,1990,64
2000's,Religion2020,1995,198
2000's,Religion2020,2000,957
2000's,Religion2020,2005,3173
2000's,Religion2020,2010,6764
2000's,Religion2020,2015,8210
2010's,Cooperation2021,1970,2
2010's,Cooperation2021,1980,1
2010's,Cooperation2021,1985,5
2010's,Cooperation2021,1990,8
2010's,Cooperation2021,1995,4
2010's,Cooperation2021,2000,29
2010's,Cooperation2021,2005,196
2010's,Cooperation2021,2010,756
2010's,Cooperation2021,2015,1202
2010's,CooperationAndReligion,1970,1
2010's,CooperationAndReligion,2000,3
2010's,CooperationAndReligion,2005,4
2010's,CooperationAndReligion,2010,48
2010's,CooperationAndReligion,2015,104
2010's,Religion2020,1970,24
2010's,Religion2020,1975,2
2010's,Religion2020,1980,13
2010's,Religion2020,1985,17
2010's,Religion2020,1990,23
2010's,Religion2020,1995,47
2010's,Religion2020,2000,84
2010's,Religion2020,2005,520
2010's,Religion2020,2010,2783
2010's,Religion2020,2015,4952
2020's,Cooperation2021,1995,1
2020's,Cooperation2021,2000,2
2020's,Cooperation2021,2005,3
2020's,Cooperation2021,2010,7
2020's,Cooperation2021,2015,66
2020's,CooperationAndReligion,2005,1
2020's,CooperationAndReligion,2010,2
2020's,CooperationAndReligion,2015,11
2020's,Religion2020,2000,3
2020's,Religion2020,2005,17
2020's,Religion2020,2010,56
2020's,Religion2020,2015,114
`

export default termDegree;