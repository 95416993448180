import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from "react-plotly.js/factory";
import {getPercentages} from "../assets/utils";
import colorMap from "../assets/color_map";

const BSPLOT = ({title, dataObject, dataString, yearIndex, countIndex}) => {
    const Plot = createPlotlyComponent(Plotly);
    let traceArray = []

    for (const [key, value] of Object.entries(dataObject)) {
        const yearArray = value[0]
        const countArray = value[1]
        const collectionArray = value[2]

        const percentageArray = getPercentages(dataString, yearArray, countArray, collectionArray, yearIndex, countIndex)

        var trace = {
            x: yearArray,
            y: percentageArray,
            name: key,
            type: 'bar',
            marker: {
                color: colorMap[key],
                line: {
                    color: 'black',
                    width: 0.35
                }
            }
        }

        traceArray.push(trace)
    }

    traceArray.sort(function(a,b) {
        return (-1 * a.name.localeCompare(b.name))
    })


    return (
        <>
            <Plot
                data={traceArray}
                layout={{
                    barmode: 'stack',
                    font: {
                        size: 16
                    },
                    title: {
                        text: title,
                        font: {
                            size: 14
                        },
                        xref: 'paper',
                        x: -0.5,
                    },
                    showlegend: true,

                    width: window.innerWidth * 0.98,
                    height: window.innerHeight * 0.8,

                    xaxis: {
                        title: {
                            text: 'Year',
                            font: {
                                size: 14,
                                color: '#7f7f7f'
                            }
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Percent of Publications',
                            font: {
                                size: 14,
                                color: '#7f7f7f'
                            }
                        }
                    }
                }}/>
        </>
    );
}

export default BSPLOT;