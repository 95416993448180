const ethnicities = `Ethnicity,collectionName,pentaSliceEnd,pubCount
,Cooperation2021,1915,1
,Cooperation2021,1925,1
,Cooperation2021,1930,4
,Cooperation2021,1935,1
,Cooperation2021,1940,3
,Cooperation2021,1945,4
,Cooperation2021,1950,8
,Cooperation2021,1955,11
,Cooperation2021,1960,7
,Cooperation2021,1965,38
,Cooperation2021,1970,72
,Cooperation2021,1975,52
,Cooperation2021,1980,40
,Cooperation2021,1985,54
,Cooperation2021,1990,49
,Cooperation2021,1995,81
,Cooperation2021,2000,149
,Cooperation2021,2005,343
,Cooperation2021,2010,538
,Cooperation2021,2015,490
,CooperationAndReligion,1955,1
,CooperationAndReligion,1975,1
,CooperationAndReligion,1980,3
,CooperationAndReligion,1985,1
,CooperationAndReligion,1995,2
,CooperationAndReligion,2000,2
,CooperationAndReligion,2005,9
,CooperationAndReligion,2010,46
,CooperationAndReligion,2015,40
,Religion2020,1885,9
,Religion2020,1895,4
,Religion2020,1900,12
,Religion2020,1905,19
,Religion2020,1910,18
,Religion2020,1915,29
,Religion2020,1920,24
,Religion2020,1925,23
,Religion2020,1930,53
,Religion2020,1935,36
,Religion2020,1940,56
,Religion2020,1945,90
,Religion2020,1950,109
,Religion2020,1955,98
,Religion2020,1960,166
,Religion2020,1965,240
,Religion2020,1970,262
,Religion2020,1975,359
,Religion2020,1980,362
,Religion2020,1985,444
,Religion2020,1990,510
,Religion2020,1995,602
,Religion2020,2000,826
,Religion2020,2005,1366
,Religion2020,2010,2517
,Religion2020,2015,2523
African or Black,Cooperation2021,1975,1
African or Black,Cooperation2021,1985,2
African or Black,Cooperation2021,1990,4
African or Black,Cooperation2021,1995,2
African or Black,Cooperation2021,2000,25
African or Black,Cooperation2021,2005,35
African or Black,Cooperation2021,2010,43
African or Black,Cooperation2021,2015,38
African or Black,CooperationAndReligion,1985,1
African or Black,CooperationAndReligion,2000,6
African or Black,CooperationAndReligion,2005,9
African or Black,CooperationAndReligion,2010,8
African or Black,CooperationAndReligion,2015,5
African or Black,Religion2020,1935,1
African or Black,Religion2020,1950,1
African or Black,Religion2020,1955,2
African or Black,Religion2020,1960,3
African or Black,Religion2020,1965,7
African or Black,Religion2020,1970,26
African or Black,Religion2020,1975,30
African or Black,Religion2020,1980,37
African or Black,Religion2020,1985,42
African or Black,Religion2020,1990,72
African or Black,Religion2020,1995,98
African or Black,Religion2020,2000,198
African or Black,Religion2020,2005,358
African or Black,Religion2020,2010,642
African or Black,Religion2020,2015,816
Caucasian or White,Cooperation2021,1840,1
Caucasian or White,Cooperation2021,1865,1
Caucasian or White,Cooperation2021,1875,1
Caucasian or White,Cooperation2021,1890,1
Caucasian or White,Cooperation2021,1895,15
Caucasian or White,Cooperation2021,1900,11
Caucasian or White,Cooperation2021,1905,1
Caucasian or White,Cooperation2021,1910,6
Caucasian or White,Cooperation2021,1915,5
Caucasian or White,Cooperation2021,1920,8
Caucasian or White,Cooperation2021,1925,8
Caucasian or White,Cooperation2021,1930,10
Caucasian or White,Cooperation2021,1935,6
Caucasian or White,Cooperation2021,1940,10
Caucasian or White,Cooperation2021,1945,11
Caucasian or White,Cooperation2021,1950,17
Caucasian or White,Cooperation2021,1955,35
Caucasian or White,Cooperation2021,1960,46
Caucasian or White,Cooperation2021,1965,110
Caucasian or White,Cooperation2021,1970,274
Caucasian or White,Cooperation2021,1975,360
Caucasian or White,Cooperation2021,1980,306
Caucasian or White,Cooperation2021,1985,463
Caucasian or White,Cooperation2021,1990,550
Caucasian or White,Cooperation2021,1995,1030
Caucasian or White,Cooperation2021,2000,1838
Caucasian or White,Cooperation2021,2005,2580
Caucasian or White,Cooperation2021,2010,3881
Caucasian or White,Cooperation2021,2015,3429
Caucasian or White,CooperationAndReligion,1840,1
Caucasian or White,CooperationAndReligion,1945,1
Caucasian or White,CooperationAndReligion,1950,1
Caucasian or White,CooperationAndReligion,1955,2
Caucasian or White,CooperationAndReligion,1965,1
Caucasian or White,CooperationAndReligion,1970,4
Caucasian or White,CooperationAndReligion,1975,2
Caucasian or White,CooperationAndReligion,1980,6
Caucasian or White,CooperationAndReligion,1985,11
Caucasian or White,CooperationAndReligion,1990,12
Caucasian or White,CooperationAndReligion,1995,36
Caucasian or White,CooperationAndReligion,2000,52
Caucasian or White,CooperationAndReligion,2005,93
Caucasian or White,CooperationAndReligion,2010,193
Caucasian or White,CooperationAndReligion,2015,288
Caucasian or White,Religion2020,1820,2
Caucasian or White,Religion2020,1825,11
Caucasian or White,Religion2020,1830,2
Caucasian or White,Religion2020,1835,2
Caucasian or White,Religion2020,1840,6
Caucasian or White,Religion2020,1845,4
Caucasian or White,Religion2020,1850,6
Caucasian or White,Religion2020,1855,1
Caucasian or White,Religion2020,1860,2
Caucasian or White,Religion2020,1875,9
Caucasian or White,Religion2020,1880,10
Caucasian or White,Religion2020,1885,4
Caucasian or White,Religion2020,1890,34
Caucasian or White,Religion2020,1895,78
Caucasian or White,Religion2020,1900,120
Caucasian or White,Religion2020,1905,99
Caucasian or White,Religion2020,1910,192
Caucasian or White,Religion2020,1915,156
Caucasian or White,Religion2020,1920,118
Caucasian or White,Religion2020,1925,190
Caucasian or White,Religion2020,1930,186
Caucasian or White,Religion2020,1935,167
Caucasian or White,Religion2020,1940,212
Caucasian or White,Religion2020,1945,269
Caucasian or White,Religion2020,1950,370
Caucasian or White,Religion2020,1955,432
Caucasian or White,Religion2020,1960,658
Caucasian or White,Religion2020,1965,971
Caucasian or White,Religion2020,1970,1417
Caucasian or White,Religion2020,1975,1884
Caucasian or White,Religion2020,1980,2479
Caucasian or White,Religion2020,1985,3335
Caucasian or White,Religion2020,1990,4324
Caucasian or White,Religion2020,1995,5610
Caucasian or White,Religion2020,2000,7785
Caucasian or White,Religion2020,2005,12115
Caucasian or White,Religion2020,2010,19003
Caucasian or White,Religion2020,2015,21582
East or Southeast Asian,Cooperation2021,1965,5
East or Southeast Asian,Cooperation2021,1970,3
East or Southeast Asian,Cooperation2021,1975,4
East or Southeast Asian,Cooperation2021,1980,16
East or Southeast Asian,Cooperation2021,1985,14
East or Southeast Asian,Cooperation2021,1990,30
East or Southeast Asian,Cooperation2021,1995,81
East or Southeast Asian,Cooperation2021,2000,187
East or Southeast Asian,Cooperation2021,2005,660
East or Southeast Asian,Cooperation2021,2010,1194
East or Southeast Asian,Cooperation2021,2015,1361
East or Southeast Asian,CooperationAndReligion,1975,2
East or Southeast Asian,CooperationAndReligion,1990,1
East or Southeast Asian,CooperationAndReligion,1995,1
East or Southeast Asian,CooperationAndReligion,2005,9
East or Southeast Asian,CooperationAndReligion,2010,19
East or Southeast Asian,CooperationAndReligion,2015,24
East or Southeast Asian,Religion2020,1950,12
East or Southeast Asian,Religion2020,1955,12
East or Southeast Asian,Religion2020,1960,13
East or Southeast Asian,Religion2020,1965,23
East or Southeast Asian,Religion2020,1970,42
East or Southeast Asian,Religion2020,1975,19
East or Southeast Asian,Religion2020,1980,17
East or Southeast Asian,Religion2020,1985,34
East or Southeast Asian,Religion2020,1990,58
East or Southeast Asian,Religion2020,1995,87
East or Southeast Asian,Religion2020,2000,241
East or Southeast Asian,Religion2020,2005,623
East or Southeast Asian,Religion2020,2010,1379
East or Southeast Asian,Religion2020,2015,2032
Hispanic or Latinx,Cooperation2021,1955,1
Hispanic or Latinx,Cooperation2021,1960,5
Hispanic or Latinx,Cooperation2021,1965,5
Hispanic or Latinx,Cooperation2021,1970,3
Hispanic or Latinx,Cooperation2021,1975,7
Hispanic or Latinx,Cooperation2021,1980,6
Hispanic or Latinx,Cooperation2021,1985,1
Hispanic or Latinx,Cooperation2021,1990,7
Hispanic or Latinx,Cooperation2021,1995,10
Hispanic or Latinx,Cooperation2021,2000,47
Hispanic or Latinx,Cooperation2021,2005,125
Hispanic or Latinx,Cooperation2021,2010,232
Hispanic or Latinx,Cooperation2021,2015,210
Hispanic or Latinx,CooperationAndReligion,1990,1
Hispanic or Latinx,CooperationAndReligion,1995,1
Hispanic or Latinx,CooperationAndReligion,2000,1
Hispanic or Latinx,CooperationAndReligion,2005,12
Hispanic or Latinx,CooperationAndReligion,2010,20
Hispanic or Latinx,CooperationAndReligion,2015,35
Hispanic or Latinx,Religion2020,1965,3
Hispanic or Latinx,Religion2020,1970,3
Hispanic or Latinx,Religion2020,1975,9
Hispanic or Latinx,Religion2020,1980,5
Hispanic or Latinx,Religion2020,1985,17
Hispanic or Latinx,Religion2020,1990,34
Hispanic or Latinx,Religion2020,1995,58
Hispanic or Latinx,Religion2020,2000,76
Hispanic or Latinx,Religion2020,2005,200
Hispanic or Latinx,Religion2020,2010,558
Hispanic or Latinx,Religion2020,2015,807
Other,Cooperation2021,1965,1
Other,Cooperation2021,1970,1
Other,Cooperation2021,1975,3
Other,Cooperation2021,1980,9
Other,Cooperation2021,1985,3
Other,Cooperation2021,1990,3
Other,Cooperation2021,1995,13
Other,Cooperation2021,2000,39
Other,Cooperation2021,2005,67
Other,Cooperation2021,2010,159
Other,Cooperation2021,2015,181
Other,CooperationAndReligion,1990,1
Other,CooperationAndReligion,2000,2
Other,CooperationAndReligion,2005,15
Other,CooperationAndReligion,2010,16
Other,CooperationAndReligion,2015,23
Other,Religion2020,1925,3
Other,Religion2020,1935,2
Other,Religion2020,1940,3
Other,Religion2020,1945,6
Other,Religion2020,1950,4
Other,Religion2020,1955,2
Other,Religion2020,1960,3
Other,Religion2020,1965,10
Other,Religion2020,1970,7
Other,Religion2020,1975,23
Other,Religion2020,1980,42
Other,Religion2020,1985,46
Other,Religion2020,1990,57
Other,Religion2020,1995,123
Other,Religion2020,2000,182
Other,Religion2020,2005,403
Other,Religion2020,2010,751
Other,Religion2020,2015,1268
South Asian,Cooperation2021,1970,1
South Asian,Cooperation2021,1975,3
South Asian,Cooperation2021,1980,6
South Asian,Cooperation2021,1985,9
South Asian,Cooperation2021,1990,15
South Asian,Cooperation2021,1995,21
South Asian,Cooperation2021,2000,61
South Asian,Cooperation2021,2005,179
South Asian,Cooperation2021,2010,295
South Asian,Cooperation2021,2015,413
South Asian,CooperationAndReligion,2000,1
South Asian,CooperationAndReligion,2005,2
South Asian,CooperationAndReligion,2010,14
South Asian,CooperationAndReligion,2015,30
South Asian,Religion2020,1890,1
South Asian,Religion2020,1915,2
South Asian,Religion2020,1920,3
South Asian,Religion2020,1955,9
South Asian,Religion2020,1960,1
South Asian,Religion2020,1965,2
South Asian,Religion2020,1970,11
South Asian,Religion2020,1975,18
South Asian,Religion2020,1980,24
South Asian,Religion2020,1985,39
South Asian,Religion2020,1990,72
South Asian,Religion2020,1995,110
South Asian,Religion2020,2000,184
South Asian,Religion2020,2005,463
South Asian,Religion2020,2010,949
South Asian,Religion2020,2015,1313
`

export default ethnicities;