const clusterTextDict2 = {
    "79": `Religion and the Evolution of Human Cooperation~
    cooperation (550), social (437), group (427), behavior (393), game (354), religious (280), evolution (257), human (239), theory (237), punishment (235), evolutionary (209), selection (204), study (184), model (178), religion (171), effect (167), goods (162), individuals (161), beliefs (152), evidence (144), experiments (137), cultural (135), hypothesis (134), results (120), cooperative (118), public (111), research (110), society (109), dilemma (108), altruism (106), large (106), benefits (104), role (104), data (103), cost (101), mechanism (101), size (100), supernatural (100), interaction (99), people (98), reciprocity (98), cognitive (95), costly (92), strategies (90), members (89), problem (86), levels (84), rituals (83), humans (80), analysis (79)`,
    "96": `Religion and Organ Donation in America and Africa~
    donation (530), organ (383), transplantation (151), organs (128), study (122), donors (121), religious (121), beliefs (108), family (106), attitudes (103), donor (103), American (100), group (92), religion (91), knowledge (86), African (76), death (76), research (73), survey (68), population (66), factors (65), care (64), education (64), analysis (62), issues (59), transplant (59), community (58), medical (58), members (58), health (57), groups (55), willingness (55), students (52), body (51), system (50), faith (49), process (49), ethnic (48), Americans (47), patients (47), people (47), role (45), number (44), barriers (43), countries (43), cultural (43), age (42), decisions (42), rate (41), public (40)`,
    "6": `Experimental Psychology of Religion and Altruism~
    religious (77), religion (42), behavior (32), study (31), research (30), social (28), beliefs (25), theory (24), group (23), moral (22), effect (21), effects (21), values (20), cooperation (19), god (19), evidence (18), findings (17), game (17), life (17), people (17), positive (17), self (17), goods (16), health (16), literature (16), orientation (16), altruism (15), experiments (15), attitudes (14), dilemma (13), experiment (13), prosociality (13), relationship (13), scale (13), analysis (12), data (12), differences (12), factors (12), healing (12), identity (12), individuals (12), religiosity (12), control (11), goal (11), mental (11), participants (11), public (11), charity (10), information (10), model (10)`,
    "34": `Spirituality and Health~
    spirituality (58), social (38), religion (35), study (33), analysis (30), religious (25), behavior (24), health (23), life (22), role (21), spiritual (21), self (20), factor (19), factors (19), recovery (17), relationship (17), risk (17), effect (16), outcomes (16), altruism (15), group (15), experience (14), implications (14), leadership (14), positive (14), beliefs (13), support (13), treatment (13), program (12), research (12), results (12), association (11), Buddhist (11), gratitude (11), importance (11), intervention (11), issues (11), patients (11), practice (11), approach (10), benefits (10), community (10), control (10), depression (10), dimensions (10), ethical (10), mind (10), modeling (10), process (10), bonding (9)`,
    "101": `Religion and Organ Donation in Spain~
    donation (563), organ (339), attitudes (233), transplantation (168), population (164), organs (149), study (137), factors (125), analysis (118), knowledge (113), religion (109), questionnaire (108), kidney (106), donors (103), death (93), students (93), transplant (90), donor (88), religious (85), test (82), family (80), living (78), Spain (77), group (75), beliefs (71), respondents (70), medical (65), public (63), rate (63), survey (61), education (60), favor (60), variables (58), American (57), rates (57), age (56), experience (56), residents (56), consent (54), attitude (52), toward (51), deceased (50), hospital (50), professionals (49), program (49), levels (48), process (48), citizens (47), years (47), psychosocial (46)`,
    "38": `Religion and Economic Cooperation~
    social (95), religious (80), religion (56), behavior (51), cooperation (50), game (45), economic (34), work (34), history (31), experiments (29), goods (29), human (27), model (27), people (27), study (27), beliefs (23), group (23), public (23), life (22), individuals (21), preferences (21), society (21), effect (20), levels (20), research (20), trust (20), cooperative (19), differences (19), evidence (19), income (19), provision (19), services (19), theory (19), survey (18), analysis (17), economy (17), measures (17), results (17), world (17), dilemma (16), incentives (16), data (15), organization (15), participation (15), countries (14), political (14), population (14), service (14), subjects (14), competition (13)`,
    "54": `Religion, Big Gods, and Charitable Giving~
    giving (67), religion (50), social (45), religious (44), group (36), charitable (35), study (35), behavior (33), analysis (28), theory (26), gods (25), political (23), factors (21), research (21), relationship (20), data (19), differences (19), survey (19), cooperation (18), model (18), big (17), income (17), effect (16), influence (16), literature (16), Norenzayan (15), society (14), charity (13), global (13), organization (13), philanthropy (13), state (13), foundations (12), identity (12), impact (12), individuals (12), levels (12), capital (11), evidence (11), favoritism (11), goods (11), groups (11), households (11), ideology (11), moral (11), participation (11), regression (11), results (11), role (11), size (11)`,
    "39": `Religion and Family Cooperation~
    religious (48), social (34), religion (32), research (27), survey (26), selection (20), life (19), beliefs (18), study (18), group (16), perspective (16), marriage (15), cooperation (14), evolutionary (14), human (14), commitment (13), data (13), supernatural (13), theory (12), context (11), high (11), network (11), psychological (11), role (11), trust (11), evil (10), findings (10), groups (10), national (10), support (10), congregational (9), evolution (9), giving (9), health (9), impact (9), literature (9), members (9), women (9), American (8), behavior (8), conflict (8), cost (8), factors (8), intermarriage (8), levels (8), relationship (8), sexual (8), state (8), theological (8), environmental (7)`,
    "30": `Religious Attitudes Toward Cooperation~
    social (54), cultural (39), culture (23), differences (23), religion (23), religious (20), group (18), les (18), beliefs (16), psychology (16), research (16), system (16), values (16), collectivism (15), individualism (15), study (15), perspective (14), cross (13), world (13), des (12), patterns (12), behavior (11), survey (11), theory (11), evolutionary (10), parents (10), people (10), psychological (10), shame (10), traditionnalisme (10), age (9), chez (9), conditions (9), global (9), groups (9), holistic (9), issues (9), prosocial (9), American (8), axioms (8), behavioral (8), conflict (8), cooperation (8), data (8), evolution (8), immune (8), life (8), loi (8), measures (8), mechanisms (8)`,
    "173": `Religion, Children, and the Evolution of Cooperation~
    social (86), behavior (83), cooperation (78), children (71), group (68), evolution (66), cultural (63), human (47), model (39), system (35), culture (33), evolutionary (33), religious (33), theory (33), game (31), prosocial (31), norms (30), research (28), selection (28), punishment (27), cooperative (24), learning (24), religion (24), age (23), conflict (23), interaction (23), society (23), differences (22), process (22), evidence (21), individuals (21), levels (20), psychology (20), people (19), rank (19), results (19), sharing (19), systems (18), humans (17), role (16), beliefs (15), effect (15), form (15), groups (15), individual (15), religiosity (15), years (15), young (15) adaptation (14), adaptive (14)`,
    "36": `Game Theory and Religious Cooperation~
    cooperation (135), game (110), social (107), behavior (89), group (89), goods (70), dilemma (58), public (52), experiments (50), effect (48), cooperative (41), interaction (41), levels (41), model (41), individuals (36), evidence (33), experimental (33), punishment (33), reciprocity (33), results (32), theory (32), evolution (31), experiment (30), human (30), contribution (29), diversity (29), mechanism (29), selection (29), study (28), people (27), prisoner (27), strategies (27), subjects (27), conditions (25), theoretical (25), altruism (24), conflict (23), identity (23), religious (23), data (22), networks (22), effects (21), ethnic (21), members (21), norms (21), research (21), strategy (21), different (19), intergroup (19)`
}

export default clusterTextDict2