const finalauthors = `creatorRowID,collectionName,pentaSliceStart,pubCount
David A. Bennett,Religion2020,2010,41,
Norman Pettit,Religion2020,1985,21,
Mark Hill,Religion2020,2015,12,
Benton Johnson,Religion2020,1975,3,
Luc Deliens,Religion2020,2015,10,
David W. K. Yeung,Cooperation2021,2015,21,
Josephine Griffith,Cooperation2021,2005,2,
H. Frankfort,Religion2020,1935,2,
Todd L. Cherry,Cooperation2021,2010,6,
Bhikhu Parekh,Cooperation2021,2000,3,
DAVID GRUMETT,Religion2020,2015,36,
Peter L. Berger,Religion2020,2010,4,
Charlene P. E. Burns,Religion2020,2015,1,
Carsten K. W. De Dreu,Cooperation2021,2005,8,
Paul Gifford,Religion2020,2010,4,
Edwin S. Gaustad,Religion2020,1950,2,
Charles Y. Glock,Religion2020,1970,6,
Russell Re Manning,Religion2020,2010,6,
Yi-Yuan Tang,Cooperation2021,2010,1,
Michael W. Parker,Religion2020,2000,20,
Robert C. Fuller,Cooperation2021,2015,1,
Wade Clark Roof,Religion2020,1985,10,
Jörg Rüpke,Cooperation2021,2005,1,
Mark A. Yarhouse,Religion2020,1995,1,
Joseph A. Bulbulia,CooperationAndReligion,2010,5,
C. Daniel Batson,Religion2020,1980,10,
Geir Skeie,Religion2020,2000,2,
H. J. Rose,Religion2020,1925,5,
Jamie D. Aten,Religion2020,2005,7,
Walter Brueggemann,Religion2020,2005,6,
Robert Perske,Religion2020,1970,3,
Åke Hultkrantz,Religion2020,1975,5,
Neal M Krause,Cooperation2021,1995,1,
David Lester,Religion2020,2015,8,
Jeff Astley,Religion2020,2000,12,
Li Zhang,Religion2020,2010,5,
Sheridan Gilley,Religion2020,1975,3,
Bernard Williams,Religion2020,1995,13,
Peter Cumper,Religion2020,2000,4,
Anna Dreber,Cooperation2021,2005,6,
Bryan D. Spinks,Religion2020,2005,4,
R. E. S. Tanner,Religion2020,1970,12,
Dean R. Hoge,Religion2020,1970,12,
Charles Taliaferro,Religion2020,2005,9,
David W. Foy,Religion2020,2015,8,
David W. Orme-Johnson,Religion2020,1995,3,
Marc A. Musick,Religion2020,2010,8,
William Adams Brown,Religion2020,1935,3,
Justin L. Barrett,Cooperation2021,2005,1,
Quentin D. Atkinson,Religion2020,2010,10,
Robert S. Wilson,Religion2020,2010,18,
Paul A. M. Van Lange,Cooperation2021,2005,7,
Larry VandeCreek,Religion2020,1990,7,
Pascual Parrilla,CooperationAndReligion,2010,10,
Pekka Kärkkäinen,Religion2020,2015,4,
Edward B. Davis,Religion2020,1990,4,
Keith J. Edwards,Religion2020,2015,6,
Elaine Howard Ecklund,Religion2020,2005,16,
Lorenzo Cohen,Religion2020,2005,7,
Ursula King,Religion2020,1975,1,
S. Mark Heim,Religion2020,1980,1,
Jean-Paul Willaime,Religion2020,2015,3,
Carl E. Thoresen,Cooperation2021,1970,4,
David R. Hodge,Religion2020,2015,24,
Linda Woodhead,Religion2020,1910,1,
Raymond F. Paloutzian,Cooperation2021,1975,1,
Susumu Shimazono,Religion2020,2000,8,
Fraser Watts,Religion2020,2005,3,
Joan Marques,Religion2020,2010,14,
Jeffrey A. Dusek,Religion2020,2015,3,
Jeffrey S. Levin,Religion2020,2015,6,
Irwan Abdullah,Religion2020,2010,9,
Keith Ward,Religion2020,1965,3,
Bryan S. Turner,Religion2020,1970,8,
Fiona Timmins,Cooperation2021,2005,1,
David W. Johnson,Cooperation2021,2000,1,
Brick Johnstone,Religion2020,2010,9,
John Marenbon,Religion2020,1985,1,
Alejandro Frigerio,Religion2020,1995,6,
Aya Hagishima,Cooperation2021,2005,2,
James J. Buckley,Religion2020,1995,5,
Ellen F. Davis,Religion2020,2005,2,
Richard Ling,Cooperation2021,2015,3,
Wyatt MacGaffey,Religion2020,1995,2,
Joanna Elizabeth Crossman,Religion2020,2010,6,
Eckhard Frick,CooperationAndReligion,2015,1,
Shirley Otis-Green,Religion2020,2015,10,
David Brown,Religion2020,2000,1,
Tatsuya Morita,Religion2020,2000,3,
Michel Despland,Religion2020,1995,5,
Laurent Cesalli,Religion2020,2010,17,
Hans Schwarz,Religion2020,2015,4,
Leonard J. Biallas,Religion2020,1985,5,
Rosemary Radford Ruether,Religion2020,1985,7,
Ellen L. Idler,Religion2020,2010,5,
Michael J. Wade,Cooperation2021,2010,3,
Stephen Cranney,Religion2020,2015,16,
David Lester,Cooperation2021,1970,1,
John Riches,Religion2020,1985,2,
Rex Ahdar,Religion2020,2005,4,
Peter Marshall,Religion2020,2005,7,
Sara W Lazar,Religion2020,2015,6,
Christopher Key Chapple,Religion2020,2010,10,
Luther H. Martin,Religion2020,2005,1,
Paul Helm,Religion2020,2005,5,
HUGO MEYNELL,Religion2020,1965,1,
Maurice Eisenbruch,Religion2020,2000,3,
Yori Gidron,Religion2020,2010,15,
Charles Burnett,Religion2020,2005,2,
Russell T. McCutcheon,Religion2020,1995,12,
David F. Ford,Religion2020,2005,3,
Xianjia Wang,Cooperation2021,2015,13,
Jeffrey A. French,Cooperation2021,1980,3,
Lodovico Balducci,Religion2020,2010,12,
Linda Ross,Religion2020,2000,4,
Chris Shilling,Religion2020,2015,6,
Angus Buckling,Cooperation2021,2015,12,
Nathan R. Kollar,Religion2020,2015,2,
Christopher Cullen,Religion2020,1995,1,
Hisashi Ohtsuki,Cooperation2021,2000,2,
Pascual Parrilla,Religion2020,2005,6,
Asonzeh Ukah,Religion2020,2005,1,
Detlef Pollack,Religion2020,2000,9,
Amanda Porterfield,Religion2020,1990,13,
Seward Hiltner,Religion2020,1950,8,
Gary E. Roberts,Cooperation2021,2000,1,
Reza Arjmand,Religion2020,2015,34,
Ahmed Abdel-Khalek,Religion2020,2000,1,
Deepak Chopra,Religion2020,2000,3,
Daniel A. Helminiak,Religion2020,2005,6,
Richard H. Popkin,Religion2020,1990,16,
Thomas Williams,Religion2020,2015,1,
James M. Day,Religion2020,2010,16,
Samuel Bowles,Cooperation2021,2015,3,
Karen E. Steinhauser,Religion2020,2010,8,
Richard Egan,Religion2020,2010,5,
Anna Fedele,Religion2020,2015,12,
Howard Kreisel,Religion2020,1990,1,
Jocelyne Cesari,Religion2020,1995,2,
Ruth Feldman,Cooperation2021,2015,8,
Roberto Cipriani,Religion2020,2015,7,
Sana Loue,Religion2020,2015,5,
Mark Silk,Religion2020,2010,2,
Jodok Troy,Religion2020,2005,4,
Robert C. Fuller,Religion2020,1985,5,
Oleksandr N. Sagan,Religion2020,1995,7,
Lewis R. Rambo,Religion2020,1980,3,
Darren Cronshaw,Religion2020,2015,15,
Julia M. Puaschunder,Cooperation2021,2010,15,
William H. Swatos,Religion2020,1990,21,
Judi Neal,Religion2020,2010,15,
Daniel L. Overmyer,Religion2020,2000,2,
Michael Northcott,Religion2020,2010,9,
J. Milton Yinger,Religion2020,1970,7,
Elisa Harumi Kozasa,Religion2020,2015,15,
Maryam Dilmaghani,Cooperation2021,2015,2,
Paul J. Watson,Religion2020,1980,1,
James S. Gordon,Religion2020,2010,3,
F. LeRon Shults,CooperationAndReligion,2010,2,
Mark van Vugt,Cooperation2021,2015,6,
Phillip R. Shaver,Religion2020,1985,1,
Tyler S. Greenway,Cooperation2021,2015,4,
Rufus A. Johnstone,Cooperation2021,2005,4,
Jacob Neusner,Religion2020,2010,5,
David R. Williams,Religion2020,1985,4,
Simo Knuuttila,Religion2020,1990,1,
Aidan Nichols,Religion2020,2005,1,
Kenneth Surin,Religion2020,1980,2,
Ann Gleig,Religion2020,2005,12,
Phillip E. Hammond,Religion2020,1975,4,
Christoph Stenschke,Religion2020,2005,10,
Ian T. Baldwin,Cooperation2021,2000,9,
Gerardo Muñoz,Religion2020,2015,6,
Elad Schiff,Religion2020,2005,4,
Kenneth Ira Pargament,Religion2020,1975,2,
Roger Finke,Cooperation2021,2005,1,
Robert John Russell,Religion2020,1980,1,
Zehavit Gross,Religion2020,2010,15,
John Wolffe,Religion2020,1990,1,
Hans Mol,Religion2020,1985,6,
Antonio Ríos,Cooperation2021,2005,5,
Ursula McKenna,Religion2020,2010,2,
Adrian Furnham,Religion2020,1985,3,
Catherine L. Albanese,Religion2020,1975,5,
Alessandra Lamas Granero Lucchetti,Religion2020,2010,18,
Jeffrey C. Alexander,Cooperation2021,1990,3,
Russell Sandberg,CooperationAndReligion,2015,1,
Peter Kivisto,Religion2020,2010,6,
Michael Welker,Religion2020,2015,9,
Ernst Fehr,Cooperation2021,1995,2,
Michele Dillon,Religion2020,2010,7,
W. D. Hudson,Religion2020,1975,11,
Richard Ling,Religion2020,2015,3,
David Little,Religion2020,2010,4,
Kai Nielsen,Religion2020,1970,40,
Rowan Williams,Religion2020,2015,10,
Mark A. Noll,Religion2020,2000,6,
Brendan Carmody,Religion2020,2015,9,
JOHN D. CAPUTO,Religion2020,1990,4,
Keith G. Meador,Religion2020,2005,6,
Christoph Engel,Cooperation2021,2010,20,
Julián García,Cooperation2021,2015,6,
Ivan Strenski,Religion2020,2010,6,
Marian de Souza,Cooperation2021,2000,2,
Elisabeth Arweck,Religion2020,2005,6,
Benjamin Grant Purzycki,Religion2020,2010,10,
Robert W. Hefner,Religion2020,2000,2,
Pieter De Leemans,Religion2020,2010,26,
Tamar Rudavsky,Religion2020,2010,35,
Thomas Robbins,Religion2020,1970,2,
Louis Putterman,Cooperation2021,1995,1,
Lee W. Bailey,Religion2020,2010,16,
John Parratt,Religion2020,2005,4,
Ronald Hutton,Religion2020,1995,8,
Kaveh Madani,Cooperation2021,2015,10,
Linda E. Carlson,Religion2020,2005,22,
Jonathan Chaplin,Religion2020,1990,2,
Michael J. Reiss,Cooperation2021,1980,2,
David Robinson,Religion2020,2015,10,
Anthony J. Blasi,Religion2020,1985,9,
Forrest Clingerman,Religion2020,2015,8,
Francis X. Clooney,Religion2020,2000,3,
Shailer Mathews,Religion2020,1915,6,
Martin E. Marty,Religion2020,1995,10,
Jerry H Gill,Religion2020,1965,40,
Login S. George,Religion2020,2015,2,
Peter Lake,Religion2020,1985,4,
John F. Miller,Religion2020,1970,4,
Bernard McGINN,Religion2020,1990,2,
Jacques Waardenburg,Religion2020,1980,2,
Keith E. Yandell,Religion2020,2000,1,
William Schweiker,CooperationAndReligion,2010,1,
Zehavit Gross,Cooperation2021,2010,1,
Daniel Burston,Religion2020,2015,2,
Joretta L. Marshall,Religion2020,1995,2,
Dale F. Eickelman,Religion2020,1990,8,
Heinz Streib,Religion2020,1990,2,
Peter C. Hill,Cooperation2021,2005,1,
Susan E. Henking,Religion2020,2010,15,
John Bowker,Religion2020,1975,4,
Adam B. Seligman,Religion2020,2010,12,
William Schweiker,Religion2020,1990,9,
Sarah Coakley,Religion2020,1990,3,
Ednan Aslan,Religion2020,2010,15,
Douglas A. MacDonald,Religion2020,2000,16,
Zuoxin Wang,Cooperation2021,2015,5,
Sigurd Bergmann,Religion2020,2005,2,
Winthrop S. Hudson,Religion2020,1965,5,
Jonathan Jackson,Cooperation2021,2015,9,
Meredith B. McGuire,Religion2020,1985,4,
Kelly Bulkeley,Religion2020,2015,6,
Robert Merrihew Adams,Religion2020,1965,4,
Hans G. Kippenberg,Religion2020,2015,4,
Diarmaid MacCulloch,Religion2020,2000,3,
Yan Liu,Cooperation2021,2005,8,
Steve Fuller,Cooperation2021,2000,1,
John E. Smith,Religion2020,1960,5,
Gari Walkowitz,Cooperation2021,2010,21,
Eli Berman,Religion2020,2015,2,
Bruce Ross,Religion2020,1995,5,
David Hay,Religion2020,2005,12,
David Brown,Cooperation2021,2000,2,
Patrick Sherry,Religion2020,1985,3,
Peter Gilbert,Religion2020,2010,36,
Lisa Miller,Cooperation2021,1990,1,
Eileen Barker,Religion2020,1975,2,
Ian Reader,Religion2020,2010,11,
Steven Stack,Religion2020,2005,2,
David C. Queller,Cooperation2021,1990,12,
T. H. Barrett,Religion2020,1975,1,
Jorge M. Pacheco,Cooperation2021,2015,16,
Jerry Z. Park,Religion2020,2005,16,
Anatolii M. Kolodnyi,Religion2020,2000,10,
Aviva Berkovich-Ohana,Religion2020,2015,14,
Eliezer Witztum,Religion2020,1985,3,
Aryeh Lazar,Religion2020,2015,20,
Whitney A. Bauman,Religion2020,2015,26,
Pasquale Annicchino,Religion2020,2015,18,
Ben J. Hatchwell,Cooperation2021,2005,4,
Chunyan Zhang,Cooperation2021,2010,16,
Colin Gunton,Religion2020,2005,6,
Ben Clements,Cooperation2021,2015,2,
Daniel H. Levine,Religion2020,2015,2,
HUGO MEYNELL,Religion2020,2005,4,
Andries G. Van Aarde,Religion2020,2000,4,
Chris Baker,Cooperation2021,2010,1,
Nathan R. Kollar,Religion2020,1975,10,
Edwin S. Gaustad,Religion2020,1990,4,
Eugene V. Gallagher,Religion2020,1995,3,
David Martin,Religion2020,1990,2,
Bernard Doherty,Religion2020,2015,14,
M. M. Knappen,Religion2020,1940,6,
Jeffrey C. Witt,Religion2020,2015,3,
John T. Jost,Religion2020,2015,15,
Ted G. Jelen,Religion2020,1995,11,
Gavin D'Costa,Religion2020,1985,5,
Pascal Boyer,Cooperation2021,2015,3,
Jing Li,Cooperation2021,2005,9,
Richard A. McCormick,Religion2020,1975,12,
Richard A. McCormick,Cooperation2021,1970,2,
Bradford McCall,Religion2020,2005,24,
Ronald M. Glassman,Religion2020,2015,22,
JOSEPH M. KITAGAWA,Religion2020,1970,8,
Roberto Lambertini,Religion2020,2010,32,
Joyce Ann Mercer,Religion2020,1990,1,
Li Zhang,Cooperation2021,2005,5,
Ariel Dinar,Cooperation2021,2015,6,
Robin Gill,Religion2020,1970,3,
Urs Fischbacher,Religion2020,2015,1,
Stephen J. Stein,Religion2020,2000,2,
Eveline A. Crone,Cooperation2021,2010,14,
Rosalind I. J. Hackett,Religion2020,2000,8,
Paul Harvey,Religion2020,2000,4,
Robert S. Ellwood,Religion2020,2015,5,
Toshio Yamagishi,Cooperation2021,1995,7,
Raymond F. Paloutzian,Religion2020,2010,4,
Abdulaziz Sachedina,Religion2020,1890,2,
John Corrigan,Religion2020,2015,3,
Marjorie Mandelstam Balzer,Religion2020,1995,5,
David Lester,Religion2020,1975,1,
Richard S. Ascough,Religion2020,2015,8,
Alexander Agadjanian,Religion2020,2000,4,
Sofia Sjö,Religion2020,2005,2,
Keith Robbins,Religion2020,2005,3,
James G. Emerson,Religion2020,1965,2,
Arniika Kuusisto,Religion2020,2010,5,
Gary D. Bouma,Religion2020,2015,7,
Richard J. Davidson,Religion2020,2015,30,
Kathryn Pitkin Derose,Cooperation2021,2005,3,
David C. Dollahite,Religion2020,2015,24,
Rhys H. Williams,Religion2020,2000,7,
Paul J. Griffiths,Religion2020,1985,4,
Francis Schüssler Fiorenza,Religion2020,1980,10,
James F. Keenan,Religion2020,2015,3,
C.W. Young,Cooperation2021,1975,2,
Georgi Kapriev,Religion2020,2015,3,
Kenneth A. Rasinski,Religion2020,2005,1,
Ronald Labonté,Cooperation2021,2010,20,
Lorenzo Casini,Religion2020,2010,17,
Philipp C. Wichardt,Cooperation2021,2015,2,
John Hick,Religion2020,1985,8,
Candy Gunther Brown,CooperationAndReligion,2010,1,
Aru Narayanasamy,Religion2020,2000,10,
Carole A. Rayburn,Religion2020,2015,4,
Willem B. Drees,Religion2020,2015,16,
Ed Diener,Religion2020,1990,3,
Mircea Eliade,Religion2020,1970,3,
Lionel Obadia,Religion2020,1970,1,
Christian Smith,Religion2020,1990,5,
Stuart A. West,Cooperation2021,2000,12,
Gregory D. Alles,Religion2020,2010,5,
Carles Salazar,Religion2020,2005,2,
Quentin D. Atkinson,Cooperation2021,2010,8,
Daniel H. Grossoehme,Religion2020,2000,2,
Dan J. Stein,Religion2020,2005,8,
H Tristram Engelhardt,Religion2020,1970,1,
Pnina Werbner,Cooperation2021,2005,2,
Robert N. Bellah,Religion2020,1955,8,
Stewart M. Hoover,Religion2020,1985,3,
Ryan T. Cragun,Religion2020,2015,25,
Chao Liu,Cooperation2021,2010,1,
Ernest M. Ligon,Religion2020,1955,19,
David De Cremer,Cooperation2021,1995,4,
ROBERT M. GRANT,Religion2020,1975,2,
David Little,Religion2020,1970,4,
Massimo Leone,Religion2020,2005,6,
Amos Yong,Religion2020,2015,19,
John M. Salsman,Religion2020,2005,6,
David R. Johnson,Religion2020,2000,1,
Peter J. Verhagen,Religion2020,2010,14,
Simon Goldhill,Religion2020,2015,6,
Patricia E. Murphy,Religion2020,2005,8,
Ramón Alonso-Sanz,Cooperation2021,2000,8,
Walter E. Conn,Religion2020,2010,2,
Euan Cameron,Religion2020,1995,8,
Stephan De Beer,Religion2020,2010,10,
Todd M. Johnson,Religion2020,2005,2,
John Armson,Religion2020,1985,1,
William K. Kay,Religion2020,2015,4,
Heinz Streib,Cooperation2021,2015,1,
Jean Bethke Elshtain,Religion2020,1995,4,
Roland Boer,Religion2020,2010,50,
Pnina Werbner,Religion2020,1980,1,
Peter Adamson,Religion2020,2010,34,
Harro Höpfl,Religion2020,2010,42,
Farr A. Curlin,Religion2020,2010,29,
Ronald J. Morris,Religion2020,2015,5,
Jeffrey A. Dusek,Religion2020,2000,6,
Gina A. Zurlo,CooperationAndReligion,2015,2,
J. Gwyn Griffiths,Religion2020,1955,3,
Fergus Kerr,Religion2020,2015,5,
Andrew Louth,Religion2020,1980,6,
Ann Loades,Religion2020,1995,5,
John F. Miller,Religion2020,2010,8,
Philip Hefner,Religion2020,2005,12,
René Descartes,Religion2020,1900,1,
Vivienne Baumfield,Religion2020,2015,2,
Simon Gächter,Cooperation2021,2010,15,
Nella van den Brandt,Religion2020,2015,14,
Michael Argyle,Religion2020,1955,4,
Walter Brueggemann,Religion2020,1965,1,
Tariq Modood,Religion2020,1990,3,
Anders Klostergaard Petersen,Religion2020,2010,6,
Gerard Leavey,Religion2020,2010,19,
Thomas G. Plante,Religion2020,2015,48,
Mark Chapman,Religion2020,2005,2,
Enzo Pace,Religion2020,2005,9,
Linda K. George,Religion2020,2010,6,
Marc Galanter,Religion2020,1995,5,
Leslie J. Francis,Religion2020,1995,24,
Brian Skyrms,Cooperation2021,2010,12,
Kim Knott,Religion2020,2015,32,
Merold Westphal,Religion2020,1990,3,
Michael J Balboni,Religion2020,2015,6,
Cheryl L. Holt,Cooperation2021,2000,1,
Sergej Flere,Religion2020,2000,1,
Clive D. Field,Religion2020,2005,4,
Steven N. Durlauf,Cooperation2021,2005,2,
Michael J. McFarland,Religion2020,2010,16,
Anthony Gill,Religion2020,2015,8,
Roberto Cipriani,Religion2020,1975,1,
Peer Scheepers,Cooperation2021,2015,2,
Albert Doja,Religion2020,2015,3,
Irene Dingel,Religion2020,2010,18,
Jeffrey Haynes,Religion2020,2015,29,
John R. Bowen,Religion2020,2005,1,
Jørn Borup,Religion2020,2010,6,
Kenneth A. Rasinski,Cooperation2021,1990,1,
Sam P. Brown,Cooperation2021,2005,3,
T. H. Barrett,CooperationAndReligion,1995,1,
Antonio Ríos,CooperationAndReligion,2005,5,
James T. Richardson,Religion2020,2000,12,
Peter C. Hill,Religion2020,2015,23,
Jeffrey M. Perl,Religion2020,2005,2,
Iain R. Torrance,Religion2020,1970,2,
Don Schweitzer,Religion2020,2015,1,
Russell Sandberg,Cooperation2021,2015,1,
Dick Anthony,Religion2020,1985,3,
H Tristram Engelhardt,Cooperation2021,1995,2,
Eric A. Storch,Religion2020,2005,4,
Peter C. Phan,Religion2020,1995,3,
Aziz Sheikh,Religion2020,2015,8,
William Breitbart,Religion2020,2005,26,
Emyr Williams,Religion2020,2005,10,
Victor Counted,Religion2020,2015,19,
Catherine L. Albanese,Religion2020,2015,2,
Brian Collins,Religion2020,2015,19,
John Kelsay,Religion2020,2005,5,
Matthew Clarke,Religion2020,2015,13,
Martin A. Nowak,Cooperation2021,1990,5,
Seward Hiltner,CooperationAndReligion,1980,1,
Colby Dickinson,Religion2020,2010,10,
Daniel Callahan,Cooperation2021,1985,6,
Keith J. Egan,Religion2020,1995,2,
Adrian Furnham,Cooperation2021,1980,1,
Dean Tjosvold,Cooperation2021,1995,8,
Joan E. Strassmann,Cooperation2021,1980,1,
Carsten K.W. De Dreu,Cooperation2021,2015,6,
Andrea C. Phelps,Religion2020,2010,22,
Peter Harrison,Religion2020,1995,1,
Roland Littlewood,Cooperation2021,2005,2,
Jonathan Fox,Religion2020,2010,22,
Joseph B. Tamney,Religion2020,1995,1,
Veit Bader,Religion2020,2010,16,
Michael Pye,Religion2020,1980,2,
Nancy T. Ammerman,Religion2020,2015,8,
John Wilson,Religion2020,1840,1,
Thijl Sunier,Religion2020,2010,4,
Michael Argyle,Religion2020,1975,6,
David Martin,Religion2020,2010,10,
Sohail Inayatullah,Cooperation2021,1995,3,
J. Gwyn Griffiths,Religion2020,1935,2,
Alastair Hamilton,Religion2020,1980,1,
Barbara Hargrove,Religion2020,1970,1,
Jürgen Moltmann,Religion2020,2000,2,
Jörg Rüpke,Religion2020,2010,14,
Kristin Bezio,Religion2020,2015,14,
Randall Balmer,Religion2020,2000,6,
James H. Fowler,Cooperation2021,2010,13,
Eugene V. Gallagher,Religion2020,1975,5,
J. E. Wood,Religion2020,2000,1,
H Tristram Engelhardt,CooperationAndReligion,1995,1,
Martyn Percy,Religion2020,2010,7,
David Piché,Religion2020,2015,1,
Louis Putterman,Religion2020,2005,1,
Heather Walton,Religion2020,2005,4,
Keith Ward,Religion2020,2000,2,
Samta P. Pandya,Religion2020,2010,3,
Simon Dein,Religion2020,2005,16,
Aasim I. Padela,Religion2020,2005,1,
Edward R. Canda,Religion2020,2010,5,
Frederick M. Burkle,Religion2020,2010,4,
Pamela Ebstyne King,Religion2020,2015,12,
Amos Yong,Religion2020,1995,4,
John Bowker,Religion2020,2015,4,
Bruce V. Foltz,Religion2020,2015,2,
Gail Ironson,Religion2020,2010,7,
Jeppe Sinding Jensen,Religion2020,1980,1,
Robert H. Schneider,Religion2020,1990,1,
Kelly Bulkeley,Religion2020,1995,4,
Louis Hoffman,Religion2020,2015,8,
Peter C. Phan,Religion2020,2015,16,
Richard L. Gorsuch,Religion2020,2005,5,
J. Milton Yinger,Cooperation2021,1985,1,
Michiel van Elk,Cooperation2021,2015,2,
Dewey D. Wallace,Religion2020,1990,7,
Graham Ward,Religion2020,2000,15,
Robert N. Bellah,Religion2020,1995,2,
Anthony J. Blasi,Religion2020,2005,2,
Pascal Boyer,Religion2020,2010,5,
Robert Boyd,Cooperation2021,1980,3,
James Mark Shields,Religion2020,2010,4,
Peter Berger,Cooperation2021,1970,4,
Fergus Kerr,Religion2020,1995,2,
Arthur Stephen McGrade,Religion2020,1985,1,
Bron Taylor,Religion2020,1995,4,
John T. Ford,Religion2020,2015,3,
Peter Byrne,Religion2020,1995,9,
Ninian Smart,Religion2020,1970,12,
Brian J. Grim,Religion2020,2010,16,
Dale F. Eickelman,Religion2020,2010,2,
Harold G. Koenig,Religion2020,2010,84,
James Bissett Pratt,Religion2020,1915,5,
Thomas G. Plante,Religion2020,1995,36,
Michael W. Parker,Cooperation2021,1990,4,
Yolanda Dreyer,Religion2020,2010,16,
Sarah F. Brosnan,Cooperation2021,2005,4,
Paul J. Mills,Religion2020,2015,30,
Janusz Salamon,Religion2020,2000,6,
Bing-Hong Wang,Cooperation2021,2010,25,
Daniel Philpott,Religion2020,2005,8,
Ralph W. Hood Jr.,Religion2020,1990,2,
D. Z Phillips,Religion2020,2010,1,
Gert Pickel,Religion2020,2005,5,
Ram A. Cnaan,Religion2020,2000,4,
Ram A. Cnaan,Cooperation2021,2005,2,
Charles H. Lippy,Religion2020,2000,8,
Nick J. Watson,Religion2020,2015,14,
Angelos Chaniotis,Religion2020,2000,2,
Alessandra Lamas Granero Lucchetti,CooperationAndReligion,2010,1,
Frank Whaling,Religion2020,1980,10,
Dermot Moran,Religion2020,2015,2,
Pascal Boyer,Religion2020,1990,8,
Amy B. Wachholtz,Religion2020,2000,4,
Robert Jackson,Religion2020,1995,3,
Neal M Krause,Religion2020,1995,4,
Arto Kallioniemi,Religion2020,2000,2,
Guy G. Stroumsa,Religion2020,2005,5,
Patsy Yates,Religion2020,2005,2,
Kimberly Georgedes,Religion2020,2010,15,
Susannah Cornwall,Religion2020,2010,8,
Andrew Buckser,Religion2020,1985,1,
INGOLF U. DALFERTH,Religion2020,2015,12,
Robert A. Segal,Religion2020,2010,16,
Carl Sterkens,Religion2020,2015,30,
Ursula King,Religion2020,2015,2,
Michael Argyle,Religion2020,1995,2,
Erin K. Wilson,Cooperation2021,2010,1,
George Pattison,Religion2020,1985,2,
David Tracy,Religion2020,1985,4,
Philip Hefner,Religion2020,1965,1,
Joshua B. Grubbs,Religion2020,2010,5,
Harvey Whitehouse,CooperationAndReligion,2015,3,
David R. Williams,Cooperation2021,2005,2,
Paul Badham,Religion2020,2000,1,
Laurel Kendall,Religion2020,1995,15,
James L. Guth,Religion2020,1995,3,
Geir Skeie,Cooperation2021,1970,2,
Dinesh Bhugra,Religion2020,2015,9,
Alister E McGrath,Religion2020,2005,10,
Human Friedrich Unterrainer,Religion2020,2015,12,
Bryan S. Turner,Religion2020,2010,56,
Hisao Ishibuchi,Cooperation2021,2015,6,
Shailer Mathews,Religion2020,1895,2,
Sohail Inayatullah,Religion2020,1985,3,
Mikko Yrjönsuuri,Religion2020,2010,54,
Catherine König-Pralong,Religion2020,2005,1,
Marcia Grant,Religion2020,2000,3,
Mikel Burley,Religion2020,2005,3,
Mathieu Terrier,Religion2020,2010,2,
David B. Burrell,Religion2020,2000,4,
Vito Fragnelli,Cooperation2021,2000,13,
Janet Martin Soskice,Religion2020,2005,6,
Shirley Telles,Religion2020,2005,12,
S. Brent Plate,Religion2020,2005,4,
Kevin McCabe,Cooperation2021,2005,14,
Donald Wiebe,Religion2020,1990,10,
Paul Tillich,Religion2020,1965,5,
David Lester,Cooperation2021,2010,1,
Andrew W. Arlig,Religion2020,2010,23,
Barbara Keller,Religion2020,2005,1,
David Reiss,Religion2020,1985,6,
Amnon Rapoport,Cooperation2021,1980,3,
Kevin J. Flannelly,Religion2020,2015,4,
Richard A. McCormick,CooperationAndReligion,1995,2,
Amy M. Burdette,Religion2020,2010,14,
Constantin Klein,Religion2020,2010,7,
Edward R. Canda,Religion2020,1990,3,
Harvey Whitehouse,Cooperation2021,2010,4,
Steve Bruce,Religion2020,2015,9,
Dewey D. Wallace,Religion2020,1970,2,
Birgit Meyer,Religion2020,2005,6,
R. Khari Brown,Religion2020,2010,14,
Richard J. Davidson,Religion2020,1975,3,
Julia Ipgrave,Religion2020,2000,1,
Steve Bruce,Religion2020,1995,12,
Rosalind I. J. Hackett,Religion2020,1980,2,
KIERAN FLANAGAN,Religion2020,1990,1,
Stephen Gersh,Religion2020,2010,27,
Helen Hardacre,Religion2020,2015,2,
Diarmaid MacCulloch,Religion2020,1980,1,
Philip G. Ziegler,Religion2020,2010,7,
Nathaniel Gray Sutanto,Religion2020,2015,18,
Peter Byrne,Religion2020,2015,3,
Kaili Chen Zhang,Religion2020,2010,27,
J. N. Mohanty,Religion2020,2015,14,
Daniel Didomizio,Religion2020,1995,8,
T. H. Barrett,Cooperation2021,1995,1,
Arne Traulsen,Cooperation2021,2010,39,
Paul Tillich,Religion2020,1945,3,
Vern L. Bengtson,Religion2020,2015,21,
David Morgan,Religion2020,2000,24,
Jörg Rüpke,Religion2020,1990,1,
James S. Gordon,Religion2020,1990,3,
Lisiunia A. Romanienko,Cooperation2021,2010,2,
C. N. L. Brooke,Religion2020,1965,9,
Samuel Stroope,Religion2020,2010,7,
Maykel Verkuyten,Religion2020,2010,17,
Neil Ormerod,Religion2020,1990,1,
Peter Hopkins,Religion2020,2015,16,
David W. Lankshear,Religion2020,1985,3,
Wendy Cadge,Religion2020,2015,19,
Andrew J. Weaver,Religion2020,1995,8,
Dion Smyth,Religion2020,2010,2,
Louis Dupré,Religion2020,2000,1,
Cristiano Castelfranchi,Cooperation2021,1990,4,
John Eade,Religion2020,2005,4,
ROBERT M. GRANT,Religion2020,2015,1,
Johann-Albrecht Meylahn,Religion2020,2010,15,
Erika L. Rosenberg,Religion2020,2015,4,
Patrick Collinson,Religion2020,1990,1,
Rosaria Conte,Cooperation2021,1995,4,
Andrew Buckser,Religion2020,2005,1,
Jesse Fox,Cooperation2021,2010,1,
Brian R Clack,Religion2020,1995,19,
Bernard Spilka,Religion2020,1965,2,
David G. Rand,Religion2020,2015,7,
Ted Peters,Religion2020,1975,10,
John R. Peteet,Religion2020,2010,32,
Mandy Robbins,Religion2020,2000,11,
Aaron C. T. Smith,Religion2020,2010,13,
Robert N. Bellah,Religion2020,1975,11,
James H. Leuba,Religion2020,1900,4,
Alister E McGrath,Religion2020,1985,3,
George A. Coe,Religion2020,1915,6,
Robert W. McGee,Religion2020,2015,24,
Zining Yang,Cooperation2021,2010,6,
Lewis S. Ford,Religion2020,1960,1,
Frederick M Burkle,Cooperation2021,2010,24,
Sohail Inayatullah,Religion2020,2005,3,
Michael Gurven,Cooperation2021,2005,11,
Stanley Hauerwas,Religion2020,1975,3,
P. Scott Richards,Religion2020,2005,24,
Oleksandr N. Sagan,Religion2020,2015,7,
Daniel De Smet,Religion2020,2010,22,
Arvind Sharma,Religion2020,2015,7,
L. Philip Barnes,Religion2020,1985,1,
Jean-Robert Tyran,Cooperation2021,2015,4,
K. Helmut Reich,Religion2020,1995,6,
Thomas Ostermann,Religion2020,2005,16,
Sung Joon Jang,Religion2020,2000,4,
Traugott Roser,Religion2020,2015,13,
Feng Wang,Cooperation2021,2010,14,
John Swinton,Religion2020,2000,5,
Thomas Robbins,Religion2020,1990,18,
Birger A. Pearson,Religion2020,1970,10,
Philip L. Quinn,Religion2020,1985,1,
Brandon Vaidyanathan,Religion2020,2015,15,
Jacob Neusner,Religion2020,1970,3,
Robin I. M. Dunbar,Religion2020,2010,2,
Don Schweitzer,Religion2020,1995,3,
F Rosner,Religion2020,1970,7,
Andrew Wright,Cooperation2021,2000,1,
Merold Westphal,Religion2020,2005,8,
Amos N. Wilder,Religion2020,1985,3,
Keith Ward,Religion2020,1980,2,
David W. Macdonald,Cooperation2021,1975,2,
Philip Clayton,Religion2020,2005,8,
David C. Dollahite,Religion2020,1995,4,
Andrew Louth,Religion2020,2015,24,
Kai Nielsen,Religion2020,2010,4,
J. Gwyn Griffiths,Religion2020,1990,4,
Albrecht Classen,CooperationAndReligion,2015,1,
William Gibson,Religion2020,2015,15,
Helen Hardacre,Religion2020,1995,3,
Ursula King,Religion2020,1995,6,
Jing Liu,Religion2020,2010,2,
Robert John Russell,Religion2020,2000,6,
Celia Deane-Drummond,Religion2020,2000,1,
Daniel T. Blumstein,Cooperation2021,2000,4,
Olli Hallamaa,Religion2020,2010,22,
James M. Dorsey,Cooperation2021,2010,2,
Janet L. Williams,Religion2020,2015,13,
Samuel Stroope,Cooperation2021,2015,2,
Lasko Basnarkov,Cooperation2021,2015,14,
Pehr Granqvist,Religion2020,2015,11,
Alyssa N. Rockenbach,Cooperation2021,2015,3,
David R. Williams,Religion2020,2005,6,
Eduardo Bruera,Religion2020,2010,12,
Irene J. Higginson,Cooperation2021,2010,3,
David Lukoff,Religion2020,1985,2,
Marc Galanter,Religion2020,1975,5,
Andrew Wright,Religion2020,2015,4,
Merold Westphal,Religion2020,1970,2,
Allen E. Bergin,Religion2020,1995,2,
Paul J. Watson,Religion2020,2000,3,
Aryeh Lazar,Religion2020,2000,4,
Afe Adogame,Religion2020,2005,7,
Darryl Macer,Religion2020,1990,12,
ROBERT M. GRANT,Religion2020,1995,1,
Kocku von Stuckrad,Religion2020,2000,3,
Sheridan Gilley,Religion2020,1995,3,
Rubina Raja,Religion2020,2010,1,
Doug Oman,Religion2020,2000,6,
JOHN D. CAPUTO,Religion2020,2010,8,
Mark R. McMinn,Religion2020,2010,7,
Jonathan Tuckett,Religion2020,2015,20,
Yi-Yuan Tang,Religion2020,2010,16,
Dan J. Stein,Cooperation2021,2015,6,
Carl E. Thoresen,Religion2020,2000,24,
Alessandro Palazzo,Religion2020,2010,20,
Ankur Barua,Religion2020,2015,13,
John Parratt,Religion2020,1985,4,
Susan J. Palmer,Religion2020,1995,4,
Kate Miriam Loewenthal,Religion2020,2000,10,
Idhamsyah Eka Putra,Religion2020,2015,15,
Will M. Gervais,CooperationAndReligion,2015,2,
Ismail Suardi Wekke,Religion2020,2015,29,
Paul A. M. Van Lange,Cooperation2021,1985,1,
Stephen H. Webb,Religion2020,1995,4,
Aziz Sheikh,Religion2020,1995,6,
Darren E. Sherkat,Religion2020,2005,7,
Don E. Davis,Religion2020,2010,30,
Abby Day,Religion2020,2005,8,
Robert Jackson,Religion2020,2015,22,
Wei Li,Religion2020,2015,24,
Rachel Muers,Religion2020,2015,9,
Robert Kaizen Gunn,Religion2020,2010,18,
Ichiro Kawachi,Religion2020,2000,2,
Nicholas A. Christakis,Religion2020,1990,2,
KIERAN FLANAGAN,Religion2020,2010,12,
Charles Hartshorne,Religion2020,1985,3,
James F. Keenan,Religion2020,1995,7,
John Kelsay,Religion2020,1985,1,
Malcolm Brown,Religion2020,2010,14,
Geoffrey Wainwright,Religion2020,1985,6,
Tariq Modood,Religion2020,2010,14,
Anders Klostergaard Petersen,CooperationAndReligion,2010,1,
Adrian Furnham,Cooperation2021,2000,1,
Philip Hefner,Religion2020,1985,3,
William P. Alston,Religion2020,1955,1,
Linda K. George,Religion2020,1990,6,
Elena Bastida,Religion2020,2010,18,
Leslie J. Francis,Religion2020,1975,2,
Brian Skyrms,Cooperation2021,1990,3,
David A. Bennett,Religion2020,1995,1,
Kenneth D. Wald,Religion2020,1995,4,
Keith J. Edwards,Religion2020,1975,6,
Genki Ichinose,Cooperation2021,2010,9,
Charles H. Lippy,Religion2020,1980,3,
Meredith B. McGuire,Religion2020,2005,1,
Constant J. Mews,Religion2020,2000,4,
Zhen Wang,Cooperation2021,2010,64,
Camilla Zimmermann,Religion2020,2005,5,
Vincent Goossaert,Religion2020,2015,12,
Todd W. Hall,Religion2020,2010,8,
Jonathan Jong,Religion2020,2010,7,
Mary Grey,Religion2020,1995,2,
Christoph Hauert,Cooperation2021,2005,19,
Cary J. Nederman,Religion2020,1990,3,
Leonardo Becchetti,Cooperation2021,2015,14,
William Adams Brown,Religion2020,1915,2,
Adrian Kent,Cooperation2021,2015,18,
Patrick H. McNamara,Religion2020,1970,3,
Marcus W. Feldman,Cooperation2021,1975,2,
Lindsay B. Carey,Religion2020,2005,2,
Nancy T. Ammerman,Religion2020,1995,3,
Armin W. Geertz,Religion2020,2015,9,
Ruth Mace,CooperationAndReligion,2015,1,
Kristoffer L. Nielbo,Cooperation2021,2010,6,
Mandy Robbins,Cooperation2021,2010,2,
Alexandra Walsham,Religion2020,2005,5,
Chris G. Sibley,Religion2020,2015,14,
Tony Walter,Religion2020,2000,8,
Harald Walach,Religion2020,2010,40,
Isabel Iribarren,Religion2020,2005,1,
Holger Cramer,Religion2020,2010,14,
Travis Dumsday,Cooperation2021,2015,2,
Colin Gunton,Religion2020,1970,6,
Anna Halafoff,Religion2020,2010,7,
Bernard McGINN,Religion2020,2010,4,
Manfred Milinski,Cooperation2021,2005,10,
Jerry H Gill,Religion2020,1985,16,
Wade C. Rowatt,Religion2020,2005,6,
William Schweiker,Religion2020,2010,3,
David M. Wulff,Religion2020,2000,3,
Jenny Berglund,Religion2020,2005,2,
Andrew R. Lewis,Religion2020,2010,12,
James V. Spickard,Religion2020,1995,5,
George Fitchett,Religion2020,2010,22,
Stephen G. Parker,Religion2020,2015,28,
Dean Tjosvold,Cooperation2021,2015,3,
Ivan Strenski,Religion2020,1970,1,
Peter Harrison,Religion2020,2015,9,
Brock Bastian,Religion2020,2015,16,
Melissa Raphael,Religion2020,2000,2,
David Robinson,Religion2020,1975,4,
Roland Robertson,Religion2020,1980,3,
Heinz Streib,Religion2020,2010,8,
Michael Pye,Religion2020,2000,8,
George Aaron Barton,Religion2020,1920,6,
Ryan LaMothe,Cooperation2021,2005,1,
David Voas,Religion2020,2005,9,
Benjamin Beit-Hallahmi,Religion2020,2000,3,
Joshua N. Hook,Religion2020,2005,4,
Kevin S. Masters,Religion2020,2015,3,
Justin L. Barrett,Religion2020,2005,5,
Maureen R. Benjamins,Religion2020,2010,18,
Dinesh Bhugra,Cooperation2021,2010,1,
Stephen Pattison,Religion2020,2000,6,
Paul Vermeer,Religion2020,2000,5,
Winthrop S. Hudson,Religion2020,1945,4,
C. Robert Cloninger,Religion2020,2000,4,
Peter van der Veer,Religion2020,2010,6,
Keith E. Yandell,Cooperation2021,2015,1,
Larry J. Young,Cooperation2021,2010,7,
Gina A. Zurlo,Cooperation2021,2015,2,
Lucia Gagliese,Religion2020,2005,9,
JOSEPH M. KITAGAWA,Religion2020,1950,7,
Wei Li,Cooperation2021,2005,18,
Matthew Levering,Religion2020,2005,6,
Gareth B. Matthews,Religion2020,1965,1,
Richard McElreath,Cooperation2021,2010,16,
Paul-François Tremlett,Religion2020,2015,6,
James Bissett Pratt,Religion2020,1935,1,
Daniel Ashlock,Cooperation2021,2015,9,
Kamaldeep Bhui,Religion2020,2000,12,
Steven Kettell,Religion2020,2010,18,
Bernard Spilka,Religion2020,2000,1,
Joseph H. Fichter,Religion2020,1975,2,
J. D. Y. Peel,Religion2020,2005,2,
Ralph W. Hood Jr.,Religion2020,2010,9,
D. Z Phillips,Religion2020,1990,6,
John Marenbon,Religion2020,2005,4,
John Armson,Religion2020,2005,16,
Friedrich Schweitzer,Religion2020,2000,3,
Amy L. Ai,Cooperation2021,2010,1,
Tinde van Andel,Religion2020,2015,16,
Rodney Stark,Religion2020,1980,7,
Henrik Lagerlund,Religion2020,2005,1,
Lorne L. Dawson,Religion2020,2000,6,
Joanna Collicutt,Religion2020,2010,15,
Edwin E. Aubrey,Religion2020,1950,8,
C. Daniel Batson,Cooperation2021,2005,2,
Daniel H. Levine,Religion2020,1995,6,
Egbert Bos,Religion2020,2005,1,
Frank Cranmer,Religion2020,2000,2,
Noah Samuels,Religion2020,2010,4,
LILY KONG,Religion2020,2010,6,
Mia Lövheim,Religion2020,2010,4,
Peter Lake,Religion2020,2005,1,
Jeffrey Haynes,CooperationAndReligion,2005,1,
Gregory R. Peterson,Religion2020,2005,10,
Philip Hefner,CooperationAndReligion,1995,1,
Candy Gunther Brown,Religion2020,2005,4,
Ted G. Jelen,Religion2020,2015,7,
Marian Burchardt,Religion2020,2015,56,
W. R. Ward,Religion2020,1970,5,
H Tristram Engelhardt,Religion2020,1990,10,
Alvin Plantinga,Religion2020,1970,2,
Karl Sigmund,Cooperation2021,2015,4,
Terrence W. Tilley,Religion2020,1975,1,
Maureen E. Lyon,Religion2020,2000,2,
Philippe Huguelet,Religion2020,2015,6,
F. LeRon Shults,Religion2020,2005,6,
David Reiss,Cooperation2021,2005,6,
Christoph Auffarth,Religion2020,2010,4,
Quentin D. Atkinson,CooperationAndReligion,2015,12,
David A. Leeming,Religion2020,2015,1,
Betty R. Ferrell,Religion2020,2000,2,
Samuel Bowles,Cooperation2021,1995,3,
Ronald Hutton,Religion2020,2015,3,
John David Creswell,Religion2020,2010,12,
John Fisher,Religion2020,2000,8,
David Little,Religion2020,1990,2,
Cécile Laborde,Religion2020,2015,14,
Roxanne D. Marcotte,Religion2020,2010,24,
Chris Baker,Religion2020,2005,8,
Frank J. Korom,Religion2020,2000,8,
Renée de la Torre,Religion2020,2005,2,
Mark Silk,Religion2020,1990,2,
Ronald L. Grimes,Religion2020,2010,4,
David H Rosmarin,Religion2020,2005,2,
David De Cremer,Cooperation2021,2015,10,
ROBERT M. GRANT,Religion2020,1955,6,
Michael Scott,Religion2020,2010,21,
Johan Buitendag,Religion2020,2010,15,
Giuseppe Benagiano,Cooperation2021,2010,2,
Marjorie Mandelstam Balzer,Religion2020,2015,9,
Mark J. Cartledge,Religion2020,2005,10,
Ninian Smart,Religion2020,2005,1,
Jean Bethke Elshtain,Religion2020,2015,4,
James G. Emerson,Religion2020,1985,2,
Daniel L. Overmyer,Cooperation2021,1980,2,
Adam Possamai,Religion2020,2005,7,
Lori G. Beaman,Religion2020,2010,9,
Ann Berger,Religion2020,2010,2,
Richard A. McCormick,Religion2020,1995,2,
Benjamin Beit-Hallahmi,Religion2020,1980,6,
Roland Robertson,Religion2020,2000,2,
Wei Zhang,Religion2020,2005,2,
Graham Harvey,Religion2020,1995,1,
Sian Cotton,Religion2020,2015,1,
Giancarlo Lucchetti,CooperationAndReligion,2010,1,
Armin W. Geertz,Religion2020,1995,3,
John P. Bartkowski,Religion2020,2005,9,
Gareth B. Matthews,Religion2020,1985,1,
Joseph Drexler-Dreis,Religion2020,2015,22,
Todd W. Hall,Religion2020,1990,4,
John T. Slotemaker,Religion2020,2010,42,
Paul J. Griffiths,Religion2020,2005,6,
Ann Loades,Religion2020,2015,16,
Aidan Nichols,Religion2020,1985,6,
Clive Marsh,Religion2020,2005,7,
Marco Casari,Cooperation2021,2015,3,
Michel Chapuisat,Cooperation2021,2005,7,
James R. Lewis,Religion2020,2000,2,
Kathrin Milbury,Religion2020,2015,15,
Ralph Del Colle,Religion2020,2005,6,
Cecilia Panti,Religion2020,2010,15,
Kenneth Ira Pargament,Religion2020,1995,26,
Yolanda Dreyer,Cooperation2021,2000,1,
John Cottingham,Religion2020,2000,2,
William Sims Bainbridge,Cooperation2021,2010,1,
Luther H. Martin,Religion2020,1985,2,
Owen Anderson,Religion2020,2000,3,
Jeffrey C. Alexander,Religion2020,2010,6,
Zhuo Job Chen,Religion2020,2015,27,
Rodney Stark,Religion2020,2000,13,
Kim Knott,Religion2020,1995,4,
Azim F. Shariff,CooperationAndReligion,2015,2,
Ernst Fehr,Cooperation2021,2015,3,
James A. Tulsky,Religion2020,2005,12,
Nancey Murphy,Religion2020,2000,4,
Rowan Williams,Religion2020,1995,2,
Martha Frederiks,Religion2020,2005,16,
Jonathan Gratch,Cooperation2021,2015,14,
Robert Zachariae,Religion2020,2010,14,
Brendan Carmody,Religion2020,1995,3,
Sana Loue,Religion2020,1995,1,
Frantz Grenet,Religion2020,2005,9,
Robert H. Schneider,Religion2020,2005,9,
Juan Cruz Esquivel,Religion2020,2015,18,
Robert W. Hefner,Religion2020,1980,2,
Patrick Sherry,Religion2020,2005,4,
Andrew M. Colman,Cooperation2021,2005,5,
Daniel L. Overmyer,Religion2020,1980,6,
Tom O’Donoghue,Religion2020,2010,13,
James T. Richardson,Religion2020,1980,5,
Peter C. Hill,Religion2020,1995,3,
Linda Woodhead,Religion2020,2005,8,
D. Z Phillips,Religion2020,1975,2,
Philip Clayton,Religion2020,1985,3,
David Lukoff,Religion2020,2005,8,
Andrew Wright,Religion2020,1995,9,
Mark van Vugt,Cooperation2021,1995,5,
Steven Stack,Religion2020,1985,4,
H Tristram Engelhardt,Cooperation2021,1975,1,
Elina Kuusisto,Religion2020,2015,14,
Herbert Benson,Religion2020,2010,3,
Jaco Beyers,Religion2020,2015,16,
Miguel Farias,Cooperation2021,2015,1,
Stephen Pattison,Religion2020,1980,1,
Albert Ellis,Religion2020,1990,9,
John R. Vile,Religion2020,2005,24,
Keith E. Yandell,Religion2020,1980,2,
Herbert Gintis,Cooperation2021,2000,10,
Lucas Molleman,Cooperation2021,2010,8,
Christopher Ocker,Religion2020,1985,3,
DiMarkco Stephen Chandler,Religion2020,2000,4,
Thomas Quartier,Religion2020,2000,2,
Irving Hexham,Religion2020,1990,12,
Peter van der Veer,Religion2020,1990,4,
Christian Smith,Religion2020,2010,8,
John Hick,Religion2020,1965,6,
Carole A. Rayburn,Religion2020,1995,8,
H. W. S.,Religion2020,1935,7,
Adam B. Seligman,Religion2020,1990,2,
David F. Ford,Religion2020,1985,3,
Grace Davie,Religion2020,1995,7,
Jacob Neusner,Religion2020,1990,6,
Paul A. Djupe,Religion2020,2015,22,
Irene J. Higginson,Religion2020,2015,12,
J. L. SCHELLENBERG,Religion2020,2005,2,
John Wolffe,Religion2020,2010,6,
Jeremy E. Uecker,CooperationAndReligion,2015,1,
Åke Hultkrantz,Religion2020,1995,2,
David W. Johnson,Cooperation2021,1980,5,
Donald Capps,Religion2020,2010,14,
David Lester,Religion2020,1995,19,
Richard S. Ascough,Religion2020,1995,1,
Eli Berman,Religion2020,1995,2,
Hans G. Kippenberg,Religion2020,1995,6,
Rachel Muers,Religion2020,1995,1,
Kelly R. Morton,Religion2020,2005,6,
Lodovico Balducci,Cooperation2021,1990,2,
William Desmond,Religion2020,2015,9,
Michel Despland,Religion2020,1975,4,
Gerald Birney Smith,Religion2020,1900,10,
Ferrari Silvio,Religion2020,2015,16,
Larry VandeCreek,Religion2020,2010,2,
Louis Putterman,Cooperation2021,2015,9,
Renee V. Galliher,Religion2020,2010,6,
Keith Robbins,Religion2020,1985,6,
Jeffrey K. Hadden,Religion2020,1985,5,
Li Wang,Religion2020,2015,3,
Emma Wild-Wood,Religion2020,2005,6,
Doug P. VanderLaan,Cooperation2021,2015,10,
David G. Rand,Cooperation2021,2005,4,
Anna Akasoy,Religion2020,2010,29,
Christopher Alan Lewis,Religion2020,2005,32,
Mitchell J. Neubert,Cooperation2021,2005,2,
Klaus Baumann,Religion2020,2015,29,
John Riches,Religion2020,2005,2,
Roman Lunkin,Religion2020,2000,2,
George A. Coe,Religion2020,1935,4,
Toivo J. Holopainen,Religion2020,2010,54,
David Jasper,Religion2020,1985,8,
Benton Johnson,Religion2020,1955,1,
Martin E. Marty,Religion2020,2015,3,
Wolfgang Vondey,Religion2020,2005,6,
Cleophea Ferrari,Religion2020,2010,18,
Yan Li,Religion2020,2010,4,
Ryan M. Denney,Religion2020,2010,15,
Daniel Balliet,Cooperation2021,2005,2,
Mark A. Yarhouse,Religion2020,2015,5,
Daniel Burston,Religion2020,1995,1,
Susumu Shimazono,Religion2020,1980,4,
Simo Knuuttila,Religion2020,2010,55,
Hans Mol,Religion2020,1965,7,
Philip L. Quinn,Religion2020,2005,5,
Ann Taves,Religion2020,1995,2,
K. Helmut Reich,Religion2020,2015,1,
P. Scott Richards,Religion2020,1985,6,
Jonathan Mathias Lassiter,Religion2020,2010,3,
Kathryn A. Johnson,Religion2020,2010,7,
Andrew Rippin,Religion2020,2015,12,
Jonathan Fox,CooperationAndReligion,2010,1,
Zuoxin Wang,Cooperation2021,1995,6,
Jennifer D. Allen,CooperationAndReligion,2015,3,
Bryan S. Turner,Religion2020,1990,4,
Nick Stauner,Religion2020,2015,13,
Chammah J. Kaunda,Religion2020,2015,14,
William Sims Bainbridge,Religion2020,1980,7,
Winnifred Fallers Sullivan,Religion2020,2010,6,
James Davis,Religion2020,2010,5,
Taneli Kukkonen,Religion2020,2005,1,
Jing Liu,Cooperation2021,2005,1,
Wyatt MacGaffey,Religion2020,1975,3,
Mary Jo Neitz,Religion2020,1990,4,
James M. Blythe,Religion2020,2010,26,
John Corrigan,Religion2020,1995,1,
Maria Harris,Religion2020,1990,2,
Vassilis Saroglou,CooperationAndReligion,2010,2,
John E. Smith,Religion2020,1980,2,
Julian Stern,Religion2020,2005,2,
J. Milton Yinger,Religion2020,1950,1,
Siegfried Dewitte,Cooperation2021,2000,4,
Raymond F. Paloutzian,Religion2020,1990,2,
Robert C. Fuller,Religion2020,2005,7,
David Hay,Religion2020,1985,10,
Michael J. Wade,Cooperation2021,1990,1,
Wei Zhang,Cooperation2021,2010,13,
David Fergusson,Religion2020,1990,1,
Elinor Ostrom,Cooperation2021,2005,7,
David C. Queller,Cooperation2021,2010,36,
Sanford Nidich,Religion2020,1970,2,
Tony Richie,Religion2020,2015,2,
Rosemary Radford Ruether,Religion2020,2005,5,
Nima Ghorbani,Religion2020,2005,7,
Li Wang,Cooperation2021,2010,12,
Hiroki Sayama,Cooperation2021,2015,12,
Robert S. Wilson,Cooperation2021,1985,1,
Hillary S. Webb,Religion2020,2010,13,
Jennifer D. Allen,Religion2020,2010,15,
Ronald J. Morris,Cooperation2021,1995,1,
Eliezer Witztum,Religion2020,2005,4,
C. Daniel Batson,Religion2020,2000,4,
Benjamin Beit-Hallahmi,Cooperation2021,2010,1,
HUGO MEYNELL,Religion2020,1985,3,
Werner Güth,Cooperation2021,1990,3,
Zhu Han,Cooperation2021,2015,15,
Jeffrey A. French,Religion2020,2005,3,
Nikki R. Keddie,Religion2020,1980,24,
S. Mark Heim,Religion2020,2000,4,
Paul Helm,Religion2020,1985,2,
George Thomas Kurian,CooperationAndReligion,2010,1,
Joretta L. Marshall,Religion2020,2015,2,
Robert A. Segal,Religion2020,1990,14,
Niels Henrik Gregersen,Religion2020,2005,16,
Vassilis Saroglou,Religion2020,2005,14,
Nathan R. Kollar,Religion2020,1995,2,
Kevin J Flannelly,Religion2020,2000,10,
Conrad Hackett,Religion2020,2010,10,
James H. Fowler,Religion2020,2015,1,
Edwin S. Gaustad,Religion2020,1970,5,
Eugene V. Gallagher,Religion2020,2015,6,
Len Sperry,Religion2020,2010,9,
Viren Swami,Religion2020,2015,3,
Susan E. Henking,Religion2020,1990,12,
John Scheid,Religion2020,2015,7,
Gavin D'Costa,Religion2020,2005,23,
Daniel A. Helminiak,Religion2020,1985,5,
Paul Weller,Religion2020,2000,4,
Richard H. Popkin,Religion2020,1970,6,
Pablo Ramírez,Cooperation2021,2015,25,
George Aaron Barton,Religion2020,1960,1,
Brian Patrick McGuire,Religion2020,2000,2,
Dinesh Bhugra,Religion2020,1995,8,
James M. Day,Religion2020,1990,4,
Paul Wink,Religion2020,2005,10,
David Robinson,Religion2020,1995,2,
Rohana Ulluwishewa,Religion2020,2010,15,
Kevin J. Flannelly,Religion2020,1995,1,
Christopher McCrudden,Religion2020,2015,4,
Arthur Stephen McGrade,Religion2020,2005,1,
Manfred Milinski,Cooperation2021,1985,2,
Bron Taylor,Religion2020,2015,12,
Vincent Goossaert,Religion2020,2000,6,
Alejandro Frigerio,Religion2020,2015,4,
James V. Spickard,Religion2020,2015,8,
Fergus Kerr,Religion2020,1975,1,
LILY KONG,Religion2020,1990,3,
Jan-Olav Henriksen,Religion2020,2015,10,
Paul D. Molnar,Religion2020,2000,4,
David Brown,Religion2020,1980,1,
Ian Olver,Religion2020,2015,16,
Oliver D. Crisp,Religion2020,2015,14,
Leslie Francis,Religion2020,2005,13,
James J. Buckley,Religion2020,2015,3,
Eileen Barker,Religion2020,2010,7,
William H. Swatos,Religion2020,2010,7,
Alvin Plantinga,Religion2020,1990,4,
Gregory Baum,Religion2020,1975,6,
Karl Sigmund,Cooperation2021,1995,9,
Paul Tillich,Religion2020,1985,6,
Phillip R. Shaver,Religion2020,2005,1,
Amy Adamczyk,Religion2020,2005,9,
Amy Adamczyk,Cooperation2021,2005,1,
Jonathan S. Abramowitz,Religion2020,2005,4,
Pierre-Yves Brandt,Religion2020,2015,8,
Kenneth D. Wald,Religion2020,2015,8,
George Karamanolis,Religion2020,2015,1,
Cary J. Nederman,Religion2020,2010,30,
Werner Güth,Cooperation2021,2005,11,
Heiko Rauhut,Cooperation2021,2015,2,
Denise Lardner Carmody,Religion2020,1985,21,
Marcus W. Feldman,Cooperation2021,1995,2,
H. Byron Earhart,Religion2020,1980,6,
Darryl Macer,Cooperation2021,2005,4,
Stefan Schmidt,Cooperation2021,2015,1,
Iain R. Torrance,Religion2020,2010,38,
Jon R Stone,Religion2020,2000,13,
Seward Hiltner,Religion2020,1970,6,
Lodovico Balducci,Religion2020,1990,2,
Ian James Kidd,Religion2020,2015,21,
Wei Wang,Religion2020,2005,1,
Karl Peltzer,Religion2020,2000,8,
Daniel L. Overmyer,CooperationAndReligion,1980,2,
Anders Klostergaard Petersen,Cooperation2021,2015,1,
Marius Nel,Religion2020,2015,17,
Michelle J. Pearce,Religion2020,2005,12,
James L. Guth,Religion2020,2015,6,
John Maltby,Religion2020,1995,19,
John Wilson,Religion2020,2000,8,
H. Newton Malony,Religion2020,1975,8,
Justin L. Barrett,CooperationAndReligion,2015,1,
Ruth Mace,Religion2020,2015,2,
Michiyo Ando,Cooperation2021,2015,1,
Keith J. Edwards,Religion2020,1995,12,
Naoki Masuda,Cooperation2021,2005,12,
Neal M Krause,Religion2020,2015,21,
Barbara Keller,Cooperation2021,2005,1,
Laurel Kendall,Religion2020,2015,12,
Peter Kivisto,Religion2020,1990,9,
Michael Welker,Religion2020,1995,1,
Jeremy E. Uecker,Religion2020,2005,6,
Edwin E. Aubrey,Religion2020,1930,1,
William P. Alston,Religion2020,2015,4,
David Reiss,Religion2020,2005,3,
Eli Berman,Cooperation2021,1995,2,
Jean-Yves Nau,Cooperation2021,2005,1,
Sidney E. Mead,Religion2020,1945,12,
Manfred Milinski,CooperationAndReligion,2005,1,
Cecilia Martini Bonadeo,Religion2020,2010,26,
René Descartes,Religion2020,1995,13,
Wei Chen,Religion2020,2010,3,
John D. Yoon,Cooperation2021,2015,1,
Stephen D. Glazier,Religion2020,1985,8,
Nicola Slee,Religion2020,2000,12,
Joseph Rivera,Religion2020,2010,2,
Robert Audi,Religion2020,2015,4,
Holly Rogers,Religion2020,2015,18,
Loren D. Marks,Religion2020,2005,5,
Charles Taliaferro,Religion2020,1985,2,
Ivan Strenski,Religion2020,1990,4,
Dinesh Bhugra,Cooperation2021,1990,1,
Norman Doe,Cooperation2021,2010,2,
Simon Coleman,Cooperation2021,2010,1,
Thomas M. Izbicki,Religion2020,1995,1,
Lorenza S. Colzato,CooperationAndReligion,2010,2,
Rima Dada,Religion2020,2015,16,
Anson Shupe,Religion2020,2005,3,
J. N. Mohanty,Religion2020,1995,7,
John Hedley Brooke,Religion2020,2000,5,
Kenneth Surin,Religion2020,2000,1,
Peter L. Berger,Cooperation2021,1995,1,
Wade Clark Roof,Religion2020,2005,3,
Philip E. Devenish,Religion2020,1985,4,
Lloyd Strickland,Religion2020,2015,16,
Fenggang Yang,Religion2020,2010,16,
Steven Engler,Religion2020,2005,18,
Lisa Miller,Religion2020,2000,6,
Daniel P. Sulmasy,Religion2020,2005,7,
Rodney Stark,Religion2020,1960,2,
D Bellandi,Religion2020,1995,19,
Matthias Sutter,Cooperation2021,2015,4,
Clyde Wilcox,Religion2020,1990,21,
Joyce J. Fitzpatrick,Religion2020,2005,10,
Jacqueline S. Mattis,Religion2020,2005,6,
Joseph Henrich,Cooperation2021,2010,10,
Pei-Chen Lo,Religion2020,2015,5,
Scott Atran,Cooperation2021,2005,4,
Nirbhay N. Singh,Religion2020,2015,13,
Amos N. Wilder,Religion2020,1965,3,
Pascal Boyer,CooperationAndReligion,2010,1,
Benjamin Hill,Religion2020,2010,16,
Kathryn Lofton,Religion2020,2015,13,
Eleanor Nesbitt,Religion2020,2000,6,
Pekka Kärkkäinen,Religion2020,2010,28,
Daniel H. Levine,Religion2020,1975,2,
Jean-Yves Nau,Religion2020,2010,13,
Sidney E. Mead,Religion2020,1985,6,
Juan Wang,Cooperation2021,2010,13,
Benjamin Allen,Cooperation2021,2010,7,
Kamaldeep Bhui,Cooperation2021,2005,4,
Lisa Sowle Cahill,Religion2020,1985,6,
Christopher P. Scheitle,Religion2020,2010,11,
Claire Dwyer,Religion2020,2015,14,
William Gibson,Religion2020,1995,1,
W. R. Ward,Religion2020,1990,6,
Steve Nolan,Religion2020,2005,6,
Terrence W. Tilley,Religion2020,1995,9,
Shirley Otis-Green,Religion2020,1995,2,
Roger Finke,Religion2020,1990,8,
Richard Rymarz,Religion2020,2000,2,
Philippe Huguelet,Religion2020,1995,1,
Christoph Auffarth,Religion2020,1990,2,
Julie A. Schneider,Religion2020,2015,19,
Scott Atran,Religion2020,2015,10,
Hans Hinrich Biesterfeldt,Religion2020,2015,1,
John Witte,Religion2020,2010,11,
Volkhard Krech,Religion2020,2005,4,
Roe Fremstedal,Religion2020,2010,14,
Eran Ben-Arye,Religion2020,2015,26,
Allen E. Bergin,Religion2020,2015,1,
James H. Leuba,Religion2020,1920,4,
William Gibson,Cooperation2021,1990,1,
Dirk Helbing,Cooperation2021,1995,6,
John Milbank,Religion2020,2000,16,
Gavin Flood,Religion2020,2010,4,
Chen Chu,Cooperation2021,2015,16,
Bruce Lincoln,Religion2020,1975,2,
L. Philip Barnes,Religion2020,2005,18,
Duncan B. Forrester,Religion2020,2000,6,
Paul Gifford,Religion2020,1990,2,
Charles Y. Glock,Religion2020,1990,1,
J. D. Y. Peel,Religion2020,1985,5,
J. D. Y. Peel,Cooperation2021,1965,1,
Heinrich H. Nax,Cooperation2021,2010,1,
Laurence R. Iannaccone,Religion2020,2015,1,
David G. Bromley,Religion2020,2005,6,
H. J. Rose,Religion2020,1945,5,
F Rosner,Religion2020,1990,4,
John C. Green,Religion2020,1990,10,
Daniel A. Helminiak,CooperationAndReligion,2015,1,
Wei Wang,Cooperation2021,2005,9,
Joseph H. Fichter,Religion2020,1955,3,
Edward B. Davis,Religion2020,2010,5,
Christopher C. H. Cook,Religion2020,2010,14,
Linda M. Chatters,Religion2020,2000,3,
Joseph A. Bulbulia,Cooperation2021,2015,5,
Susan J. Palmer,Religion2020,2015,12,
Steven Pirutinsky,Religion2020,2015,42,
Duane Williams,Religion2020,2015,14,
Peter W. Williams,Religion2020,2000,9,
Arjan W. Braam,Religion2020,1995,5,
Emily J. Cottrell,Religion2020,2010,34,
Aaron W. Hughes,Religion2020,2005,3,
Åke Hultkrantz,Religion2020,1955,2,
Allen D. Hertzke,Religion2020,2005,4,
SVENN LINDSKOLD,Cooperation2021,1970,12,
Kevin J. Christiano,Religion2020,2010,1,
Mar Griera,Religion2020,2010,3,
Stanley Hauerwas,Cooperation2021,1970,1,
Ihsan Yilmaz,Religion2020,2015,25,
Asbjörn Medhus,Cooperation2021,1970,2,
Kevin Schilbrack,Religion2020,2000,8,
Michel Despland,Religion2020,2015,1,
INGOLF U. DALFERTH,Religion2020,1975,3,
George Handzo,Religion2020,2015,24,
Ronald L. Grimes,Religion2020,1970,1,
Alyssa N. Rockenbach,Religion2020,2000,1,
Supa Pengpid,Religion2020,2015,13,
David W. Orme-Johnson,Religion2020,2015,2,
Ara Norenzayan,Religion2020,2000,4,
Amparo Oliver,Religion2020,2015,14,
Abdulaziz Sachedina,CooperationAndReligion,2010,2,
J. E. Wood,Religion2020,1980,7,
Geoffrey Wainwright,Religion2020,2005,1,
Antoine Lutz,Religion2020,2010,12,
Edna B. Foa,Religion2020,1985,1,
Siebren Miedema,Religion2020,2005,8,
George Fitchett,Religion2020,1990,4,
Teresa Rupp,Religion2020,2010,24,
Leonard J. Biallas,Religion2020,2005,2,
Ellen L. Idler,Religion2020,1990,1,
David W. Macdonald,Cooperation2021,1995,6,
Martin E. Marty,Religion2020,1955,1,
Donald Capps,Religion2020,1970,2,
Jane Anderson,Religion2020,2010,8,
Tony Richie,Religion2020,2000,1,
Benton Johnson,Religion2020,1995,6,
David B Larson,Religion2020,2005,2,
Thorsten Barnhofer,Religion2020,2005,12,
William Sims Bainbridge,Religion2020,2015,4,
F. LeRon Shults,Cooperation2021,2010,2,
Andrew M. Greeley,Religion2020,1990,10,
Jean-Paul Willaime,Religion2020,1995,2,
Orna Zagoory-Sharon,Cooperation2021,2010,14,
François Taddei,Cooperation2021,2005,6,
David Carr,Religion2020,1970,1,
Shirley Jackson Case,Religion2020,1920,4,
Cok Bakker,Religion2020,2015,16,
T. H. Barrett,Religion2020,2015,4,
Sara MacKian,Religion2020,2010,14,
Kathleen McPhillips,Religion2020,1995,3,
Joan E. Strassmann,Cooperation2021,2000,8,
Holly K. Oxhandler,Religion2020,2015,18,
Paul F. Knitter,Religion2020,1980,5,
R. Stephen Warner,Religion2020,2000,2,
Peter Admirand,Religion2020,2010,5,
Arnaud Delorme,Religion2020,2010,12,
E. L. Mascall,Religion2020,1970,9,
David Novak,Religion2020,1995,4,
Joyce Ann Mercer,Religion2020,2010,4,
Lei Yu,Cooperation2021,2010,2,
Kun Yang,Cooperation2021,2005,3,
Janet Martin Soskice,Religion2020,1985,9,
Stephen J. Stein,Religion2020,1980,3,
Louis Dupré,Religion2020,1980,1,
Patricia M. Davidson,Religion2020,2005,16,
Curtis W. Hart,Religion2020,2015,4,
Charles Hartshorne,Religion2020,1965,6,
Christopher Baethge,Religion2020,2015,2,
Rosaria Conte,Cooperation2021,2015,8,
Dimitris Xygalatas,Religion2020,2015,21,
David Carr,Religion2020,1990,1,
Anson Shupe,Religion2020,1985,5,
Klaus Zuberbühler,Cooperation2021,2010,16,
Philip L. Tite,Religion2020,2005,3,
John R. Peteet,Religion2020,1990,2,
Lucila Castanheira Nascimento,Religion2020,2005,5,
Laurence R. Iannaccone,Religion2020,1995,10,
Wade Clark Roof,CooperationAndReligion,2005,1,
Vanda Aramavičiūtė,Religion2020,2005,1,
Amnon Rapoport,Cooperation2021,2000,21,
John C. Green,Religion2020,2010,6,
Mark Juergensmeyer,Religion2020,2005,4,
Anthony B. Pinn,Religion2020,2015,5,
Frank Whaling,Religion2020,2000,5,
Shauna L. Shapiro,Religion2020,2000,4,
Gary D. Bouma,Religion2020,1995,5,
Jon Adams,Religion2020,2010,5,
Christopher G. Ellison,Religion2020,1990,10,
Jules JANSSENS,Religion2020,2005,1,
Shun Kurokawa,Cooperation2021,2015,16,
Hubert Knoblauch,Religion2020,2000,7,
Göran Larsson,Religion2020,2015,13,
Robin Gill,Religion2020,2010,5,
Vincent Goossaert,CooperationAndReligion,2015,2,
Herbert Gintis,Religion2020,2010,1,
Elaine Graham,Religion2020,2005,15,
Marco Tomassini,Cooperation2021,2005,12,
Crystal L. Park,Religion2020,1995,3,
Edward P. Shafranske,Religion2020,2000,15,
Steven J. Sandage,Religion2020,2005,4,
Yaojun Li,Religion2020,2010,2,
Nickolas P. Roubekas,Religion2020,2015,14,
W. R. Ward,Religion2020,2010,1,
Robert S. Ellwood,Religion2020,1975,10,
Pablo Ramírez,Religion2020,2005,6,
Aku Visala,Religion2020,2015,10,
Niels Christian Hvidt,CooperationAndReligion,2015,2,
Elvyda Martišauskienė,Religion2020,2010,5,
Romy Lauche,Religion2020,2005,2,
Tim Lomas,Religion2020,2010,10,
William P. Alston,Religion2020,1995,6,
Ina ter Avest,Religion2020,2005,2,
Emyr Williams,Cooperation2021,2005,1,
Christophe Jaffrelot,Religion2020,1995,3,
Robin I. M. Dunbar,Cooperation2021,2005,4,
Frederick M. Smith,Religion2020,2015,2,
Friedrich Schweitzer,Religion2020,2015,25,
Brian R Clack,Religion2020,2015,3,
Phillip E. Hammond,Religion2020,1990,13,
Hugo Simkin,Religion2020,2010,2,
Dirk Helbing,Cooperation2021,2015,26,
Philippe Vallat,Religion2020,2010,42,
James T. Richardson,CooperationAndReligion,2000,1,
Nicholas Grant Boeving,Religion2020,2005,1,
Dimitris Xygalatas,Cooperation2021,2015,9,
Giuseppe Benagiano,Religion2020,2015,4,
Urs Fischbacher,Cooperation2021,2005,8,
Andy Gardner,Cooperation2021,2015,13,
Scott Atran,CooperationAndReligion,2005,2,
Ruth Mace,Cooperation2021,2000,2,
Bernard Spilka,Religion2020,1985,7,
Lionel Obadia,Religion2020,2010,13,
Gregory Baum,Religion2020,2015,2,
Niels Christian Hvidt,Religion2020,2010,15,
Amos N. Wilder,Religion2020,1945,3,
Darren E. Sherkat,Cooperation2021,2000,1,
Chris G. Sibley,Cooperation2021,2010,2,
Norman Doe,Religion2020,2005,12,
Helen Rose Ebaugh,Religion2020,2000,11,
George A. Coe,Religion2020,1895,3,
Hanna Kulagina-Stadnichenko,Religion2020,1995,2,
Stanley Hauerwas,Religion2020,1995,3,
Bernard McGINN,Religion2020,1970,4,
Laszlo Zsolnai,Religion2020,2005,4,
Jack L. Seymour,Religion2020,2010,18,
Arvind Sharma,Religion2020,1995,8,
Berry Billingsley,Religion2020,2015,18,
Lewis S. Ford,Religion2020,1980,3,
Eamon Duffy,Religion2020,2010,2,
Hans-Georg Ziebertz,Religion2020,2000,6,
George Aaron Barton,Religion2020,1905,2,
Dirk Hutsebaut,Religion2020,2000,8,
Oded Stark,Cooperation2021,2000,12,
Kathleen Galek,Religion2020,2005,17,
Vern L. Bengtson,Religion2020,1995,6,
Tracy Anne Balboni,Religion2020,2015,14,
Marc Galanter,Religion2020,2015,5,
Anatol Rapoport,Cooperation2021,1980,3,
Russell Sandberg,Religion2020,2005,15,
Neil Ormerod,Religion2020,2010,8,
K. J. Ray Liu,Cooperation2021,2010,13,
Martin E. Marty,Religion2020,1975,13,
David B Larson,Cooperation2021,2005,2,
Thomas J. Csordas,Religion2020,1995,6,
Simplice Asongu,Religion2020,2010,65,
Robert Merrihew Adams,Religion2020,2005,2,
Michael Welker,Cooperation2021,2015,1,
Keith J. Edwards,Cooperation2021,2005,3,
Helen Rose Ebaugh,Religion2020,1980,1,
David W. Johnson,Religion2020,2005,1,
John Wilson,Religion2020,1980,6,
Joseph Rivera,Cooperation2021,2015,1,
Joseph Bracken,Religion2020,2005,5,
JOSEPH M. KITAGAWA,Religion2020,1990,2,
Mirjam Künkler,Religion2020,2005,2,
Wei Chen,Cooperation2021,2015,9,
Ninian Smart,Religion2020,1990,3,
Eamon Duffy,Religion2020,1990,8,
Ralph W. Hood Jr.,Religion2020,1970,5,
Eckhard Frick,Religion2020,2015,37,
Christian Smith,CooperationAndReligion,2010,1,
Samuel L. Perry,Religion2020,2010,10,
John Scheid,Religion2020,1995,2,
Patrick Collinson,Religion2020,1970,3,
Paul Avis,Religion2020,2005,4,
Amy L. Ai,Religion2020,2005,18,
Elizabeth Johnston Taylor,Religion2020,2005,12,
Maykel Verkuyten,Cooperation2021,2005,2,
W. Muss-Arnolt,Religion2020,1905,2,
Ted Peters,Religion2020,1995,8,
Herbert Benson,Religion2020,1995,3,
Harvey Whitehouse,Religion2020,2000,7,
Nathan Carlin,Religion2020,2015,19,
Joseph Henrich,Religion2020,2015,15,
W. Brad Johnson,Religion2020,2005,2,
Ilona Kickbusch,Cooperation2021,2015,6,
Mary Jo Neitz,Religion2020,2010,3,
Yan Li,Cooperation2021,2015,33,
Sung Joon Jang,Cooperation2021,2000,3,
Kristene Diggins,Religion2020,2010,6,
René Descartes,Religion2020,2015,1,
Kevin N. Laland,Religion2020,2015,3,
David Carr,Religion2020,2010,1,
Keith J. Egan,Religion2020,1975,12,
H Tristram Engelhardt,Religion2020,2005,5,
Alvin Plantinga,Religion2020,2010,6,
Marcel Barnard,Religion2020,2010,6,
Christopher T. Begg,Religion2020,2005,1,
Carsten K.W. De Dreu,Cooperation2021,1995,2,
Martin A. Nowak,Cooperation2021,2010,44,
William Sims Bainbridge,Religion2020,2000,4,
John Wilson,Cooperation2021,2005,1,
Jaffary Awang,Religion2020,2010,6,
Mark Juergensmeyer,Religion2020,1985,3,
Michael Speca,Religion2020,2000,8,
Robert N. Bellah,Religion2020,2015,3,
Gary D. Bouma,Religion2020,1975,3,
Simon Coleman,Religion2020,2000,50,
Yoh Iwasa,Cooperation2021,2000,1,
Thomas J. Csordas,Religion2020,2015,4,
Paul R. Katz,Religion2020,2000,8,
Howard Kreisel,Religion2020,2010,19,
Harold G. Koenig,Religion2020,1990,11,
Ronald Labonte,Cooperation2021,2000,10,
Peter Koslowski,Religion2020,2005,2,
Oliver Leaman,Religion2020,2005,5,
Richard McElreath,CooperationAndReligion,2010,2,
Peter L. Berger,Religion2020,1970,2,
Jack L. Seymour,Religion2020,1990,1,
David B. Burrell,Religion2020,1980,5,
Marcus W. Feldman,Cooperation2021,2015,4,
Philip Schwadel,Religion2020,2015,14,
Brian Leftow,Religion2020,1995,2,
Donald Wiebe,Religion2020,2010,10,
Toshio Yamagishi,Cooperation2021,2015,13,
Crystal L. Park,Religion2020,2015,49,
Benjamin Grant Purzycki,CooperationAndReligion,2015,5,
Kristoffer L. Nielbo,Religion2020,2010,18,
Ichiro Kawachi,Cooperation2021,2010,20,
Joseph Henrich,CooperationAndReligion,2015,12,
James A. Beckford,Religion2020,1980,9,
Anatol Rapoport,Cooperation2021,1960,1,
Landon Schnabel,Religion2020,2010,1,
Edna B. Foa,Religion2020,2000,9,
Veit Bader,Cooperation2021,2015,2,
Maarten J. F. M. Hoenen,Religion2020,2000,1,
Åke Hultkrantz,Religion2020,2015,1,
Stephen Hunt,Religion2020,1995,4,
Adam B. Cohen,Religion2020,2005,12,
Marcelo Saad,Religion2020,2010,2,
Colin Gunton,Religion2020,1985,5,
Curtis W. Hart,Religion2020,1995,1,
Claudia Fichtel,Cooperation2021,2015,2,
Maurice Eisenbruch,Religion2020,1980,6,
Daniel Callahan,Religion2020,1960,3,
Russell T. McCutcheon,Religion2020,2015,15,
Pam McGrath,Religion2020,2010,3,
Peter Beyer,Religion2020,2005,9,
Susan H. McFadden,Religion2020,2010,5,
Jeppe Sinding Jensen,Religion2020,2000,4,
Andrew Village,Religion2020,2005,6,
Marian de Souza,Religion2020,2005,18,
Ralph L. Piedmont,Religion2020,2000,12,
Lodovico Balducci,Cooperation2021,2010,2,
R. W. Dyson,Religion2020,2010,16,
Chris Shilling,Religion2020,1995,3,
Mark Juergensmeyer,Cooperation2021,2010,1,
Anthony B. Pinn,Religion2020,1995,3,
Olga Louchakova-Schwartz,Religion2020,2010,2,
Dean R. Hoge,Religion2020,2010,2,
Richard L. Gorsuch,Religion2020,1985,5,
Amanda Porterfield,Religion2020,2010,6,
Helen Lavretsky,Religion2020,2015,18,
Robin I. M. Dunbar,CooperationAndReligion,2010,1,
Wesley J. Wildman,Religion2020,2005,15,
Roland Littlewood,Religion2020,1975,2,
Sumner B. Twiss,Religion2020,2005,2,
Jeffrey K. Hadden,Religion2020,1965,4,
Robert Boyd,Cooperation2021,2000,8,
Carole M. Cusack,Cooperation2021,2015,1,
Gustav J. Dobos,Religion2020,2005,4,
Rod Macleod,Religion2020,2005,6,
Altaf Husain,Religion2020,2015,24,
Graham Rossiter,Religion2020,2000,1,
Stephen Gersh,Religion2020,1970,1,
Nikki R. Keddie,Religion2020,1960,3,
James Wm. McClendon,Religion2020,1995,20,
Barbara J. Culliton,Cooperation2021,1970,8,
Duncan B. Forrester,Religion2020,1980,3,
Kevin R. Foster,Cooperation2021,2005,10,
Andreas Glöckner,Cooperation2021,2005,2,
Syamsuri Syamsuri,CooperationAndReligion,2015,2,
Albrecht Classen,Religion2020,2000,3,
Kathleen McPhillips,Religion2020,2015,13,
John R. Bowen,Religion2020,1985,1,
David G. Bromley,Religion2020,1985,7,
E E Griffith,Religion2020,1995,8,
Bruce Lincoln,Religion2020,1995,2,
Jocelyne Cesari,Religion2020,2015,22,
Alexandros Sakellariou,Religion2020,2015,10,
William K. Kay,Religion2020,1995,16,
J. D. Y. Peel,Religion2020,1965,6,
George Pattison,Religion2020,2000,4,
Konstantinos Papastathis,Religion2020,2015,20,
Michael Northcott,Religion2020,1990,3,
Pnina Werbner,Religion2020,2000,4,
Peter W. Williams,Religion2020,1980,7,
Henry Nelson Wieman,Religion2020,1935,4,
Fiona Timmins,Religion2020,2010,8,
Donald Capps,Religion2020,1990,4,
Brian Leftow,Religion2020,2015,16,
LILY KONG,Cooperation2021,2005,1,
David Little,Religion2020,1890,1,
Abdulaziz Sachedina,Religion2020,2010,10,
Christopher Cullen,Religion2020,2015,1,
Richard Cross,Religion2020,2005,22,
James J. Buckley,Religion2020,1980,5,
Rafael Domingo,Religion2020,2015,30,
Peter White,Religion2020,2015,16,
Carel P. van Schaik,Cooperation2021,2000,9,
Ted Peters,Religion2020,2015,64,
Stefan Schmidt,Religion2020,2005,2,
Terrence W. Tilley,Religion2020,2015,3,
John Hick,Religion2020,2005,3,
Maxwell V. Rainforth,Religion2020,2005,10,
Ryan LaMothe,Religion2020,2005,10,
James A. Beckford,Religion2020,2000,10,
Luther H. Martin,CooperationAndReligion,2010,1,
Michael J. Reiss,Religion2020,2005,4,
Joël Biard,Religion2020,2015,1,
Rega Wood,Religion2020,2010,15,
Betty R. Ferrell,Cooperation2021,2005,1,
Christopher G. Ellison,Religion2020,2010,45,
E. Brooks Holifield,Religion2020,1985,2,
Stephen Hunt,Religion2020,2015,11,
Brendan Hyde,Religion2020,2000,1,
Sian Cotton,Religion2020,1995,1,
Yori Gidron,Cooperation2021,2010,1,
Peter M. Kappeler,Cooperation2021,2005,3,
John L. Longeway,Religion2020,2015,1,
Ian A. McFarland,Religion2020,2000,7,
Jesús Gómez-Gardeñes,Cooperation2021,2005,10,
Vincent Goossaert,Cooperation2021,2015,2,
David A. S. Fergusson,Religion2020,2015,2,
Anthony Gill,Religion2020,1995,6,
Richard Sawatzky,Religion2020,2000,3,
Christopher P. Scheitle,Cooperation2021,2005,2,
Victor Asal,Religion2020,2005,4,
Christine A. Courtois,Religion2020,2010,2,
Paul Helm,Religion2020,1965,2,
Stephen A. Diamond,Religion2020,2010,78,
Leonardo Becchetti,Religion2020,2015,3,
Christopher Baethge,Cooperation2021,2015,22,
Daniel Callahan,Religion2020,1980,6,
Patrick H. McNamara,Religion2020,2010,5,
Peter Beyer,Religion2020,1985,2,
Dean R. Hoge,Religion2020,1990,4,
Michael Stausberg,Religion2020,2005,17,
Ann Taves,Religion2020,2015,9,
Barbara Pesut,Religion2020,2000,8,
Kalkuni Visweswaraiah Naveen,Religion2020,2015,6,
David B Larson,Religion2020,1985,8,
Jonathan Chaplin,Religion2020,2010,4,
Joel Robbins,Religion2020,2000,2,
Marcus Moberg,Religion2020,2015,14,
Pablo Ramírez,CooperationAndReligion,2005,6,
Paul F. Knitter,Religion2020,2000,5,
Jennifer Wessel,Religion2020,2010,14,
Norman Pettit,Religion2020,1965,21,
Yoh Iwasa,Cooperation2021,1980,2,
David Jasper,Religion2020,2000,1,
Elvira Wakelnig,Religion2020,2015,2,
Allen D. Hertzke,Religion2020,1985,8,
Joseph Bracken,Religion2020,1985,3,
Peter Berger,Religion2020,1975,4,
Aristotle Papanikolaou,Religion2020,2000,9,
J. Gwyn Griffiths,Religion2020,1975,11,
Kevin J. Christiano,Religion2020,1990,7,
Andrew Louth,Religion2020,2000,15,
Lisa Sowle Cahill,Religion2020,2005,5,
Ronald L. Grimes,Religion2020,1990,5,
Vassilis Saroglou,Cooperation2021,2005,4,
Andrea Bamberg Migliano,Cooperation2021,2015,16,
Laura R. Olson,Religion2020,2010,12,
Boyd Taylor Coolman,Religion2020,2015,4,
Daniel Callahan,Religion2020,2015,3,
David B. Burrell,Religion2020,1960,2,
Rob Freathy,Religion2020,2010,8,
Adrian Furnham,Religion2020,2005,11,
Dean Tjosvold,Cooperation2021,1975,1,
Michael Taborsky,Cooperation2021,2010,11,
Holly Gwen Prigerson,Religion2020,2010,30,
Benjamin Grant Purzycki,Cooperation2021,2010,2,
Roger Finke,Religion2020,2010,7,
Paul Avis,Religion2020,1985,1,
J. E. Wood,Religion2020,1960,4,
John Witte,Religion2020,1990,1,
Eric van Dijk,Cooperation2021,2000,8,
David Fergusson,Religion2020,2010,13,
Hans Mol,Religion2020,2005,1,
Ronald J. Morris,Religion2020,1995,3,
Catherine L. Albanese,Religion2020,1995,2,
Gregory D. Alles,Religion2020,1990,2,
Christopher Key Chapple,Religion2020,1990,2,
Carles Salazar,CooperationAndReligion,2010,2,
Joerg Rieger,Religion2020,2015,11,
Phillip R. Shaver,Cooperation2021,2005,1,
Graham Harvey,Religion2020,2015,14,
Thomas M. Izbicki,Religion2020,2015,1,
Paul C. Cooper,Religion2020,2005,1,
Coralie Buxant,Religion2020,2005,20,
Joseph A. Bulbulia,Religion2020,2000,1,
Bruce Ross,Religion2020,2015,15,
David C. Currow,Religion2020,2010,20,
Jean-Paul Willaime,Religion2020,1975,1,
Ying-shih Yü,Religion2020,1985,4,
Robin Gill,Religion2020,1990,2,
Wilhelm Gräb,Religion2020,2010,11,
Fenggang Yang,Religion2020,1990,1,
Wilfred McSherry,Religion2020,2000,10,
Eileen Barker,Religion2020,1995,5,
J. N. Mohanty,Religion2020,1975,7,
Kenneth Ira Pargament,Religion2020,2015,28,
Masoud Bahrami,Religion2020,2010,10,
Nicholas A. Christakis,Cooperation2021,2015,10,
JAMES L. COX,Religion2020,2010,4,
David Lehmann,Religion2020,2000,4,
Ian Reader,Religion2020,1990,11,
Andrew M. Greeley,Religion2020,1970,30,
T. H. Barrett,Religion2020,1995,5,
Antonio Ríos,Religion2020,2005,5,
Tom O’Donoghue,Cooperation2021,2015,1,
Christina M. Puchalski,Religion2020,2005,15,
Myrna M. Weissman,Religion2020,2005,1,
Robert Merrihew Adams,Religion2020,1985,2,
Kate Miriam Loewenthal,Cooperation2021,2000,1,
John Maltby,Religion2020,2015,1,
H. Newton Malony,Religion2020,1995,8,
R. Stephen Warner,Religion2020,1980,2,
Jill E. Bormann,Religion2020,2015,12,
Arjan W. Braam,Religion2020,2015,9,
James A Van Slyke,Religion2020,2010,20,
François Gauthier,Religion2020,2015,18,
Charles Hartshorne,Religion2020,1945,2,
Manfred Milinski,Religion2020,2005,1,
Jacques Waardenburg,Religion2020,2000,5,
Rowan Williams,Religion2020,1975,1,
Yeon-Shim Lee,Religion2020,2010,3,
Peer Scheepers,Religion2020,2010,6,
Louis Dupré,Religion2020,1960,2,
Russell L. Friedman,Religion2020,2010,37,
Terrence D. Hill,Religion2020,2005,15,
Walter Brueggemann,Religion2020,1985,6,
Jakub Urbaniak,Religion2020,2015,28,
Gregory Baum,Religion2020,1995,3,
Ara Norenzayan,Cooperation2021,2015,24,
Andrea D. Clements,Religion2020,2015,15,
Sarah Coakley,Religion2020,2010,45,
Joshua N. Hook,Cooperation2021,2005,1,
Leslie J. Francis,Religion2020,2015,83,
Kevin S. Masters,Cooperation2021,2010,1,
Michael Tomasello,Cooperation2021,2000,10,
Pascal Boyer,Cooperation2021,1995,2,
Edward B. Davis,CooperationAndReligion,2010,1,
Julie J. Exline,Religion2020,2010,12,
Grace Davie,Religion2020,2015,14,
Stewart M. Hoover,Religion2020,2005,6,
Daniel Houser,Cooperation2021,2005,9,
Ariel Dinar,Cooperation2021,1995,1,
Peter Kaufman,Religion2020,1985,2,
Euan Cameron,Religion2020,2015,10,
Michael E. McCullough,Religion2020,2005,9,
Lewis S. Ford,Religion2020,2000,1,
Edward J. Furton,Religion2020,2000,24,
Silvio Ferrari,Religion2020,2005,2,
Stanley Hauerwas,Religion2020,2015,2,
Daniel P. Sulmasy,Religion2020,1985,1,
Robert S. Ellwood,Religion2020,1995,5,
Arvind Sharma,Religion2020,1975,3,
H. W. S.,Religion2020,1955,1,
James R. Lewis,CooperationAndReligion,2015,1,
Armin W. Geertz,Cooperation2021,2010,1,
Robert Audi,Religion2020,2000,5,
Gary Rodin,Religion2020,2005,5,
R. Ruard Ganzevoort,Religion2020,2005,1,
Mark Juergensmeyer,CooperationAndReligion,2010,1,
Joseph B. Tamney,Cooperation2021,1995,1,
Lorenza S. Colzato,Religion2020,2010,22,
Jeff Astley,Religion2020,1980,1,
Mitchell J. Neubert,Religion2020,2010,10,
Roland Littlewood,Religion2020,2010,7,
Shailer Mathews,Religion2020,1935,1,
Abbas Ebadi,Religion2020,2015,20,
Jeffrey S. Levin,Religion2020,1995,30,
J. Kwabena Asamoah-Gyadu,Religion2020,2000,1,
Philippe Huguelet,Cooperation2021,2010,1,
Jaffary Awang,CooperationAndReligion,2015,2,
David Novak,Religion2020,2015,3,
Sidney E. Mead,Religion2020,1965,9,
Abdulkader Tayob,Religion2020,2000,2,
Johannes A. Van der Ven,Religion2020,2000,10,
Nicola Slee,Religion2020,1980,3,
Francis Schüssler Fiorenza,Religion2020,2000,4,
Liam Gearon,Religion2020,2010,10,
Gyula Klima,Religion2020,2010,40,
Antonius Liedhegener,Religion2020,2005,3,
Paul Tillich,Religion2020,2005,8,
Roland Phillips,Religion2020,1975,2,
Willem B. Drees,Religion2020,1995,5,
John T. Ford,CooperationAndReligion,2010,1,
Stephen D. Glazier,Religion2020,2005,6,
David Martin,Religion2020,1970,7,
Bert Roebben,Religion2020,2010,10,
Will M. Gervais,Religion2020,2005,1,
John Witte,CooperationAndReligion,2000,1,
Charles E. Farhadian,Religion2020,2005,1,
Tariq Modood,Cooperation2021,2010,2,
Alexander Treiger,Religion2020,2015,1,
Tyler S. Greenway,Religion2020,2015,16,
Norman Doe,Cooperation2021,2005,2,
Tyler J. VanderWeele,Religion2020,2015,31,
Paul Vermeer,Cooperation2021,2010,1,
Kenneth D. Wald,Religion2020,2010,8,
William Van Gordon,Religion2020,2015,17,
Aristotle Papanikolaou,Religion2020,2005,3,
Mary Grey,Religion2020,2010,4,
Antonius Liedhegener,Religion2020,2000,1,
Wei Li,Religion2020,2010,3,
Cary J. Nederman,Religion2020,2005,2,
Jean-Paul Willaime,Religion2020,1990,2,
Steven Engler,Religion2020,2010,12,
Heiko Rauhut,Cooperation2021,2010,14,
Lisa Miller,Religion2020,1995,4,
John T. Ford,Religion2020,1980,1,
Robert A. Segal,Religion2020,1995,22,
Ted Peters,Religion2020,2010,24,
Anders Klostergaard Petersen,Cooperation2021,2010,1,
Michelle J. Pearce,Religion2020,2010,12,
John Scheid,Religion2020,2010,8,
Laurel Kendall,Religion2020,2010,9,
Joyce J. Fitzpatrick,Religion2020,2010,16,
Linda K. George,Religion2020,1985,2,
James L. Guth,Religion2020,2010,12,
John Wilson,Religion2020,1995,8,
Ruth Mace,Religion2020,2010,3,
Robert Jackson,Religion2020,2010,20,
David Jasper,Religion2020,2005,2,
Neal M Krause,Religion2020,2010,32,
Andrew J. Weaver,Religion2020,2005,9,
Jeffrey S. Levin,Religion2020,1990,18,
William P. Alston,Religion2020,1960,2,
Robert Boyd,Cooperation2021,1995,2,
Roland Littlewood,Religion2020,1990,2,
Cheryl L. Holt,Religion2020,2015,16,
Anatol Rapoport,Cooperation2021,1995,3,
Graham Rossiter,Religion2020,1995,5,
Holly Rogers,Religion2020,2010,2,
Marcel Zwahlen,Religion2020,2010,14,
SVENN LINDSKOLD,Cooperation2021,1965,3,
Taneli Kukkonen,Religion2020,2000,1,
Yamir Moreno,Cooperation2021,2015,18,
Charles E. Butterworth,Religion2020,2015,1,
Steven Kettell,Religion2020,2005,3,
Stephen J. Stein,Religion2020,2015,1,
Louis Dupré,Religion2020,2015,1,
Cristiano Castelfranchi,Cooperation2021,2005,20,
Kalkuni Visweswaraiah Naveen,Religion2020,2010,24,
Doug Oman,Religion2020,2005,13,
Di Di,Religion2020,2015,24,
Arvind Sharma,Religion2020,2010,3,
Yan Chen,Religion2020,2015,1,
Leslie Francis,Religion2020,2000,4,
Hiroki Sayama,Cooperation2021,2010,14,
C. N. L. Brooke,Religion2020,2000,3,
William H. Swatos,Religion2020,2015,3,
Wei Zhang,Cooperation2021,2005,9,
Siebren Miedema,Religion2020,2000,9,
Konstantinos Papastathis,Religion2020,2010,4,
Pnina Werbner,Religion2020,1995,2,
Peter Admirand,CooperationAndReligion,2015,1,
Jonathan S. Abramowitz,Religion2020,2000,15,
Arndt Büssing,Cooperation2021,2015,1,
Eliezer Witztum,Religion2020,2000,5,
Christoph Engel,Religion2020,2000,1,
Travis Dumsday,Religion2020,2010,18,
Frederick M Burkle,Religion2020,2005,4,
H. Byron Earhart,Religion2020,1975,3,
Anna Halafoff,Religion2020,2005,1,
Werner Güth,Cooperation2021,2010,12,
Daniel T. Blumstein,Cooperation2021,2005,8,
Chris Shilling,Religion2020,1990,3,
Ian James Kidd,Religion2020,2010,15,
Denise Lardner Carmody,Religion2020,1990,3,
Paul J. Mills,Religion2020,1980,9,
Kamaldeep Bhui,Religion2020,2015,24,
Ted Peters,Cooperation2021,2010,2,
George Aaron Barton,Religion2020,1915,5,
Lasko Basnarkov,Cooperation2021,2010,6,
Stefan Schmidt,Cooperation2021,2010,1,
Maxwell V. Rainforth,Religion2020,2000,8,
E. Ahmed,Cooperation2021,2005,10,
Ryan LaMothe,Religion2020,2010,12,
William Sims Bainbridge,Religion2020,2010,5,
Joël Biard,Religion2020,2010,35,
Terrence D. Hill,Religion2020,2010,11,
Walter Brueggemann,Religion2020,1980,3,
John H. Shaver,CooperationAndReligion,2010,1,
Stewart M. Hoover,Religion2020,2000,3,
Aaron W. Hughes,Religion2020,2000,1,
Lorenza S. Colzato,Cooperation2021,2010,2,
Joseph Henrich,CooperationAndReligion,2010,2,
Klaus Baumann,Religion2020,2010,11,
Paul D. Molnar,Religion2020,2005,4,
Winnifred Fallers Sullivan,Religion2020,2005,5,
Ariel Dinar,Cooperation2021,1990,2,
Kun Yang,Cooperation2021,2015,4,
KIERAN FLANAGAN,Religion2020,1985,3,
Silvio Ferrari,Religion2020,2010,10,
Hans G. Kippenberg,Religion2020,2000,3,
Jan-Olav Henriksen,Religion2020,2010,7,
Wyatt MacGaffey,Religion2020,1970,5,
Mary Jo Neitz,Religion2020,1995,2,
David R. Johnson,Religion2020,2015,17,
James V. Spickard,Religion2020,2010,12,
D. Brian Smothers,Religion2020,2010,30,
Simon Goldhill,Religion2020,2000,3,
James Davis,Religion2020,2005,5,
Jean S. Kutner,Religion2020,2005,15,
Kevin Schilbrack,Religion2020,2005,5,
Michel Despland,Religion2020,1970,5,
Kevin N. Laland,Cooperation2021,2015,15,
Eileen Barker,Religion2020,2015,13,
Vito Fragnelli,Cooperation2021,2015,2,
C. N. L. Brooke,Cooperation2021,1955,3,
Michael J. Wade,Cooperation2021,1985,6,
Michael Speca,Religion2020,2015,8,
Robert S. Ellwood,Religion2020,1990,6,
Andreas Losch,Cooperation2021,2005,2,
Michael Tomasello,Cooperation2021,2015,42,
Hans Schwarz,Religion2020,1990,2,
William K. Kay,Religion2020,2000,12,
Kevin J. Flannelly,Religion2020,2000,19,
Li Wang,Religion2020,2010,3,
Li Wang,Cooperation2021,2015,15,
Elina Kuusisto,Religion2020,2010,4,
R. Stephen Warner,Religion2020,2015,3,
Edward B. Davis,Cooperation2021,2010,1,
Isabel Iribarren,Religion2020,2010,17,
Jacob Neusner,Religion2020,1985,4,
Heinz Streib,Religion2020,2005,3,
Anthony Egan,Religion2020,1995,5,
Bernard McGINN,Religion2020,2005,4,
Jerry H Gill,Religion2020,1980,32,
Ara Norenzayan,Cooperation2021,2010,6,
David G. Bromley,Religion2020,2000,4,
Kristene Diggins,Religion2020,2005,24,
Daniel Burston,Religion2020,2000,1,
L. Philip Barnes,Religion2020,2000,5,
Yeon-Shim Lee,Religion2020,2015,24,
Hans Mol,Religion2020,1960,3,
Stephen G. Parker,Religion2020,2010,5,
Dean Tjosvold,Cooperation2021,1980,1,
K. Helmut Reich,Religion2020,2010,2,
Peter Harrison,Religion2020,2010,7,
Simon Coleman,Religion2020,1995,6,
Constantin Klein,Cooperation2021,2015,1,
Paul J. Watson,Religion2020,2005,4,
Myrna M. Weissman,Religion2020,2000,2,
Nancey Murphy,Religion2020,1995,16,
Jean-Yves Nau,Religion2020,2005,10,
Philip G. Ziegler,Religion2020,2015,12,
Gina A. Zurlo,Religion2020,2015,32,
Shirley Jackson Case,Religion2020,1935,3,
Peter Byrne,Religion2020,2010,5,
Roberto Cipriani,Religion2020,1990,2,
Claire Dwyer,Religion2020,2010,5,
Daniel H. Levine,Religion2020,1980,9,
Juan Cruz Esquivel,Religion2020,2010,4,
Rania Awaad,Religion2020,2015,15,
Robert C. Fuller,Religion2020,2010,2,
William Adams Brown,Religion2020,1910,13,
Julia M. Puaschunder,Religion2020,2010,6,
Giuseppe Giordan,Religion2020,2010,6,
Emma Wild-Wood,Religion2020,2000,4,
Daniel L. Overmyer,Religion2020,1975,8,
J. Milton Yinger,Religion2020,1945,3,
Ralph W. Hood Jr.,Religion2020,1975,2,
Terrence W. Tilley,Religion2020,1990,7,
Anatol Rapoport,Religion2020,1995,2,
Dick Anthony,Religion2020,2000,3,
Andrew M. Greeley,Religion2020,1975,8,
Daniel A. Helminiak,Cooperation2021,2015,1,
David Lester,Religion2020,1990,6,
Paul Badham,Religion2020,1995,7,
Anna Akasoy,Religion2020,2015,1,
Eleanor Nesbitt,Religion2020,2005,9,
Philippe Huguelet,CooperationAndReligion,2010,1,
Tony Richie,Religion2020,2010,5,
Anatolii M. Kolodnyi,Religion2020,2015,28,
Peter W. Williams,Religion2020,2015,3,
Darren E. Sherkat,Religion2020,2010,10,
Herbert Benson,Religion2020,1990,2,
Grace Davie,Religion2020,2000,18,
Sian Cotton,Religion2020,2010,18,
Joseph Drexler-Dreis,Religion2020,2010,2,
Brian J. Grim,Religion2020,2005,8,
John Hedley Brooke,Religion2020,1995,1,
Ilona Kickbusch,Cooperation2021,2010,12,
J. Gwyn Griffiths,Religion2020,1970,2,
Stanley Hauerwas,Religion2020,2000,4,
Marius Nel,Religion2020,2010,2,
Martin Ubani,Religion2020,2015,13,
Sarah F. Brosnan,Cooperation2021,2000,6,
Michel Chapuisat,Cooperation2021,2000,5,
James R. Lewis,Religion2020,1995,4,
David F. Ford,Religion2020,1980,1,
Friedrich Schweitzer,Religion2020,1985,1,
Ruth Mace,Cooperation2021,2015,18,
Carmela Baffioni,Religion2020,2015,2,
Jeffrey B. Pettis,Religion2020,2010,15,
Martin E. Marty,Religion2020,1970,8,
Clyde Wilcox,Religion2020,1985,6,
Irene J. Higginson,Religion2020,2010,36,
Enzo Pace,Religion2020,1990,1,
Cheryl L. Holt,CooperationAndReligion,2000,1,
Peter Kivisto,Religion2020,1985,6,
Winthrop S. Hudson,Religion2020,1980,3,
Christopher C. H. Cook,Religion2020,1995,1,
Donald Capps,Religion2020,2005,17,
Eduardo Bruera,Religion2020,1995,1,
David Lukoff,Religion2020,2000,2,
Dirk Helbing,Cooperation2021,2010,70,
John Milbank,Religion2020,1985,24,
Wei Chen,Cooperation2021,2010,10,
Randall Balmer,Religion2020,2015,8,
John T Slotemaker,Religion2020,2010,15,
Xi Yong,Cooperation2021,2015,16,
LILY KONG,Cooperation2021,1990,1,
David Robinson,Religion2020,1990,2,
Dan J. Stein,Cooperation2021,2010,2,
John Parratt,Religion2020,1980,4,
Tom O’Donoghue,Religion2020,2005,1,
Ara Norenzayan,CooperationAndReligion,2015,16,
Nirbhay N. Singh,Religion2020,2000,1,
Frank Whaling,Religion2020,1995,13,
Robert Merrihew Adams,Religion2020,2000,4,
W. Brad Johnson,CooperationAndReligion,2010,2,
Luther H. Martin,Religion2020,1990,4,
Jeffrey C. Alexander,Religion2020,2005,6,
Ninian Smart,Religion2020,1975,4,
John T. Slotemaker,Religion2020,2005,5,
Thomas Quartier,Religion2020,2005,4,
Stephen E. Lahey,Religion2020,2005,1,
John Cottingham,Religion2020,2005,4,
Kathrin Milbury,Religion2020,2010,4,
Pam McGrath,Religion2020,2005,15,
Ralph Del Colle,Religion2020,2000,12,
Steve Fuller,Religion2020,2015,6,
Anna Strhan,Religion2020,2015,28,
Douglas Pratt,Religion2020,2015,10,
Crystal L. Park,Religion2020,1990,2,
Patrick Collinson,Religion2020,1985,4,
J. L. SCHELLENBERG,Religion2020,2010,10,
W. R. Ward,Religion2020,2005,3,
Rodney Stark,Religion2020,2005,2,
Neil Ormerod,Religion2020,2015,7,
T. H. Barrett,Religion2020,2010,4,
Duane Williams,Religion2020,2010,6,
Keith Ward,Religion2020,2005,2,
James A. Tulsky,Religion2020,2000,9,
Ismail Ismail,Religion2020,2015,17,
David W. Macdonald,Cooperation2021,2000,8,
Arndt Büssing,CooperationAndReligion,2015,1,
Susan H. McFadden,Religion2020,1995,7,
Mark Silk,Religion2020,1985,2,
Ihsan Yilmaz,Religion2020,2010,4,
Mark A. Noll,Religion2020,1985,1,
Martha Frederiks,Religion2020,2010,8,
Lori G. Beaman,Religion2020,2005,1,
Ann Berger,Religion2020,2015,18,
Michael Scott,Religion2020,2015,8,
Robert Zachariae,Religion2020,2005,2,
Marjorie Mandelstam Balzer,Religion2020,2010,2,
Rosemary Radford Ruether,Religion2020,2000,6,
Mark Juergensmeyer,Religion2020,2010,14,
Louis Hoffman,Religion2020,2010,12,
Bernard Spilka,Religion2020,1980,6,
Elinor Ostrom,Cooperation2021,2000,8,
Niels Christian Hvidt,Cooperation2021,2015,2,
Donald Capps,Religion2020,1965,1,
Catherine L. Albanese,Religion2020,2010,1,
James A. Beckford,Religion2020,1975,5,
Benjamin Beit-Hallahmi,Religion2020,1975,13,
Michael J. Reiss,Religion2020,1980,1,
Leonard J. Biallas,Religion2020,2000,2,
Eran Ben-Arye,Religion2020,2010,24,
Graham Harvey,Religion2020,1990,1,
Alister E McGrath,Religion2020,2010,4,
Michiyo Ando,Religion2020,2015,4,
Christophe Jaffrelot,Religion2020,2010,15,
Elvyda Martišauskienė,Religion2020,2005,1,
Werner Güth,Cooperation2021,1995,2,
Lene Kühle,Religion2020,2015,22,
David B. Burrell,Religion2020,1995,9,
Vassilis Saroglou,Religion2020,2000,13,
Hans-Georg Ziebertz,Cooperation2021,2015,1,
Simo Knuuttila,Religion2020,2005,1,
Nikki R. Keddie,Religion2020,1985,6,
Oliver Leaman,Cooperation2021,1990,1,
Ralph L. Piedmont,Religion2020,1995,6,
Kenneth Ira Pargament,Religion2020,1990,12,
Gavin D'Costa,Religion2020,2000,6,
Nathan R. Kollar,Religion2020,1990,2,
Julia M. Puaschunder,CooperationAndReligion,2010,3,
Kevin J Flannelly,Religion2020,2005,11,
Paul Weller,Religion2020,2005,4,
H Tristram Engelhardt,Religion2020,2010,7,
Mircea Eliade,Religion2020,2010,1,
Birger A. Pearson,Religion2020,1995,4,
Eric L. Garland,Religion2020,2010,30,
William Sims Bainbridge,Cooperation2021,2015,2,
Andrew Rippin,Religion2020,2010,21,
Jeffrey C. Alexander,Cooperation2021,2005,3,
Joel Robbins,Religion2020,2015,8,
Arjan W. Braam,Religion2020,2000,4,
Jane Anderson,Religion2020,2015,16,
Marc Galanter,Religion2020,1990,2,
Anatol Rapoport,Cooperation2021,1955,2,
Roland Robertson,Religion2020,1995,1,
Frank J. Korom,Religion2020,1995,2,
Julian Stern,Religion2020,2000,6,
Keith J. Egan,Religion2020,1990,4,
Kevin J. Christiano,Religion2020,2015,4,
Mar Griera,Religion2020,2015,27,
Cécile Laborde,Religion2020,2010,7,
Colby Dickinson,Religion2020,2015,13,
Christopher Lo,Religion2020,2015,4,
Johan Buitendag,Religion2020,2015,11,
Marcus W. Feldman,Cooperation2021,2010,2,
Lisa Sowle Cahill,Cooperation2021,1985,1,
Lisa Sowle Cahill,Religion2020,1980,8,
Ted Peters,CooperationAndReligion,2010,2,
Michael Pye,Religion2020,1985,1,
David C. Queller,Cooperation2021,2005,18,
Tamás Martos,Religion2020,2010,14,
Carsten K.W. De Dreu,Cooperation2021,2010,14,
Roger Finke,Religion2020,1985,6,
Ellen L. Idler,Religion2020,1985,3,
John Witte,Religion2020,2015,13,
Larry VandeCreek,Religion2020,2005,3,
David Fergusson,Religion2020,1985,1,
Edna B. Foa,Religion2020,1980,3,
Dinesh Bhugra,Religion2020,2010,12,
Miguel Farias,Religion2020,2015,14,
Joel Tsevat,Religion2020,1995,1,
Benton Johnson,Religion2020,1990,1,
Martin E. Marty,Religion2020,2010,2,
Edward R. Canda,Religion2020,1985,3,
Susumu Shimazono,Religion2020,1975,4,
Anson Shupe,Religion2020,2000,5,
Robert W. McGee,Religion2020,2000,1,
Peter van der Veer,Religion2020,1985,4,
Ann Taves,Religion2020,1990,3,
Laurence R. Iannaccone,Religion2020,2010,5,
John R. Bowen,Religion2020,2000,2,
Wade Clark Roof,Religion2020,2000,6,
Euan Cameron,Religion2020,1990,10,
Peter C. Phan,Religion2020,1990,3,
Heinrich H. Nax,Cooperation2021,2015,16,
Kathleen McPhillips,Religion2020,2000,1,
Matthias Sutter,Cooperation2021,2010,10,
Joan E. Strassmann,Cooperation2021,1995,6,
Fazlollah Ahmadi,Religion2020,2015,16,
Geir Skeie,Religion2020,2015,16,
H. J. Rose,Religion2020,1940,1,
Scott Atran,Cooperation2021,2000,2,
Erin K. Wilson,CooperationAndReligion,2010,1,
Thomas Robbins,Religion2020,2005,2,
Amos N. Wilder,Religion2020,1960,3,
Tyler S. Greenway,CooperationAndReligion,2015,2,
Helané Wahbeh,Religion2020,2015,44,
James A. Tulsky,Cooperation2021,2000,3,
Helen Rose Ebaugh,Religion2020,2015,1,
Wyatt MacGaffey,Religion2020,2010,1,
Rowan Williams,Religion2020,2000,5,
Kelly R. Morton,Religion2020,2000,3,
Michel Despland,Religion2020,2010,2,
Ellen F. Davis,Religion2020,1990,2,
Brian R Clack,Religion2020,2010,3,
Jeffrey A. French,Cooperation2021,2015,12,
Louis Putterman,Cooperation2021,2010,12,
George Fitchett,Religion2020,1985,2,
Ivan Strenski,Religion2020,1995,7,
Shirley Otis-Green,Religion2020,2000,2,
Leslie J. Francis,Religion2020,1990,25,
Julie A. Schneider,Religion2020,2010,29,
Jeffrey K. Hadden,Religion2020,1980,7,
Jonathan Fox,Cooperation2021,2010,1,
Geoffrey Wainwright,Religion2020,2000,3,
Dan J. Stein,Cooperation2021,1995,2,
Robert Jackson,Cooperation2021,2010,1,
Kathryn Lofton,Religion2020,2010,10,
Gregory D. Alles,Religion2020,2005,4,
Christopher Alan Lewis,Religion2020,2000,34,
Volkhard Krech,Religion2020,2000,3,
Roe Fremstedal,Religion2020,2005,1,
Allen E. Bergin,Religion2020,2010,2,
James H. Leuba,Religion2020,1925,11,
K. J. Ray Liu,Cooperation2021,2005,4,
Linda M. Chatters,Religion2020,2005,13,
Jon Stewart,Religion2020,2015,33,
Lucas Molleman,Cooperation2021,2015,14,
Charles Lyttle,Religion2020,1935,2,
Herbert Gintis,Cooperation2021,1995,1,
Gavin Flood,Religion2020,2005,16,
Brock Bastian,CooperationAndReligion,2010,4,
Philip E. Devenish,Religion2020,1990,16,
Ernst M. Conradie,Religion2020,2005,3,
Marco Casari,Cooperation2021,2010,14,
Daniel Callahan,Religion2020,1995,12,
F Rosner,Religion2020,1995,6,
John Wolffe,Religion2020,2005,4,
Bing-Hong Wang,Cooperation2021,2005,9,
Robert Joseph Taylor,Religion2020,1995,8,
Constantin Klein,CooperationAndReligion,2015,1,
Tim Lomas,Religion2020,2015,15,
Paul R. Katz,Religion2020,2015,14,
Armin W. Geertz,Religion2020,1990,4,
John P. Bartkowski,Religion2020,2000,8,
Valerie Michaelson,Religion2020,2010,4,
Matthias Koenig,Religion2020,2005,7,
Betty R. Ferrell,Religion2020,1995,5,
James T. Tedeschi,Cooperation2021,1975,5,
Paul Wink,Religion2020,2010,6,
David C. Dollahite,Religion2020,2010,8,
Harvey Whitehouse,Religion2020,2015,17,
Roxanne D. Marcotte,Religion2020,2005,2,
Sidney E. Mead,Religion2020,1940,3,
Lewis R. Rambo,Religion2020,1995,3,
Andrew M. Colman,Cooperation2021,2010,4,
Stephen D. Glazier,Religion2020,1980,8,
Nicola Slee,Religion2020,2005,6,
Phillip E. Hammond,Religion2020,1985,8,
Pekka Kärkkäinen,Religion2020,2005,2,
Andreas Losch,Religion2020,2005,4,
Karl Sigmund,Cooperation2021,1990,5,
Ted G. Jelen,Religion2020,1990,24,
Robert John Russell,Religion2020,2015,8,
Kenneth A. Rasinski,Religion2020,2010,18,
Edwin E. Aubrey,Religion2020,1925,5,
Kate Miriam Loewenthal,CooperationAndReligion,2010,1,
James A. Beckford,Religion2020,2015,11,
Daniel H. Grossoehme,Religion2020,1995,3,
Carel P. van Schaik,Cooperation2021,2015,8,
Peter W. Williams,Religion2020,1975,1,
Ernst Fehr,Religion2020,2015,1,
Daniel Houser,Religion2020,2015,1,
David W. Johnson,Cooperation2021,1965,2,
Joseph A. Bulbulia,Religion2020,2015,7,
Sara W Lazar,Religion2020,2000,2,
Eileen Barker,Religion2020,1980,7,
Adam Dinham,Religion2020,2015,13,
Francis Schüssler Fiorenza,Religion2020,1995,4,
Yan Liu,Cooperation2021,2000,2,
Seward Hiltner,Religion2020,1965,6,
Willem B. Drees,Religion2020,2000,12,
Christopher Cullen,Religion2020,2010,17,
Fenggang Yang,Religion2020,2005,6,
Lisa Miller,CooperationAndReligion,2015,1,
Russell T. McCutcheon,Religion2020,2010,6,
Eric van Dijk,Cooperation2021,1995,5,
Amanda Porterfield,Religion2020,2005,1,
Jon R Stone,Religion2020,2005,1,
John Hick,Religion2020,2000,5,
Roger Finke,Religion2020,2005,11,
Aru Narayanasamy,Religion2020,2015,4,
Richard S. Ascough,Religion2020,2010,8,
Alexander Agadjanian,Religion2020,1995,2,
John Wilson,CooperationAndReligion,2015,1,
Keith Robbins,Religion2020,2000,3,
Ahmed Abdel-Khalek,Religion2020,2015,1,
John Witte,Religion2020,1995,4,
Dinesh Bhugra,Religion2020,1990,1,
Peter Adamson,Religion2020,2005,2,
Christopher G. Ellison,Religion2020,2015,23,
Jonathan Chaplin,Religion2020,2005,2,
Lene Kühle,Religion2020,1995,2,
Andrew Louth,Religion2020,1985,9,
Jürgen Moltmann,Religion2020,1975,1,
Jean-Paul Willaime,Cooperation2021,2005,1,
Keith E. Yandell,Religion2020,1995,1,
Benjamin E. Zeller,Religion2020,2015,15,
Alejandro Frigerio,Religion2020,2010,6,
Joretta L. Marshall,Religion2020,2000,2,
David Martin,Religion2020,1985,2,
George Handzo,Religion2020,2010,20,
Victor Asal,Religion2020,2000,2,
Christine A. Courtois,Religion2020,2015,14,
Nikki R. Keddie,Religion2020,1965,12,
Susan E. Henking,Religion2020,2005,9,
David Brown,Religion2020,1985,1,
Leonardo Becchetti,Religion2020,2010,1,
Adam B. Seligman,Religion2020,2005,4,
Duncan B. Forrester,Religion2020,1975,3,
Pei-Chen Lo,Religion2020,2000,2,
C. Daniel Batson,Religion2020,2015,2,
Alexandros Sakellariou,Religion2020,2010,18,
Antoine Lutz,Religion2020,2005,8,
Edna B. Foa,Religion2020,1995,3,
Shane Sinclair,Religion2020,2015,17,
Beth R. Crisp,Religion2020,2015,13,
Naoki Masuda,Cooperation2021,2000,2,
Armin W. Geertz,CooperationAndReligion,2015,1,
William Sims Bainbridge,Religion2020,1995,6,
John Maltby,Religion2020,1990,2,
Albrecht Classen,Cooperation2021,2015,1,
Gyula Klima,Religion2020,2005,1,
S. Mark Heim,Religion2020,1995,4,
Ian A. McFarland,Religion2020,2015,5,
Peer Scheepers,Religion2020,2005,4,
Julián García,Cooperation2021,2010,14,
Bruce Ross,Religion2020,1990,5,
Ronald Labonte,Religion2020,2015,5,
Brendan Carmody,Religion2020,2010,15,
Peter Beyer,Religion2020,2000,17,
Susan H. McFadden,Religion2020,2015,2,
Anthony Gill,Religion2020,2010,8,
Susumu Shimazono,Religion2020,2015,6,
Josep Maria Ruiz Simon,Religion2020,2010,15,
H. Newton Malony,Religion2020,1970,3,
Farooq Naeem,Religion2020,2015,14,
Sara MacKian,Religion2020,2015,4,
Dean R. Hoge,Religion2020,2005,6,
Robert W. Hefner,Religion2020,1995,5,
Sam P. Brown,Cooperation2021,2000,3,
Anthony J. Blasi,Religion2020,1980,1,
Forrest Clingerman,Religion2020,2010,18,
Ram A. Cnaan,CooperationAndReligion,2015,1,
Rod Macleod,Religion2020,2010,12,
Roland Littlewood,Religion2020,2005,4,
Cheryl L. Holt,Religion2020,2000,4,
Login S. George,Religion2020,2010,14,
Tom Lenaerts,Cooperation2021,2015,20,
Jack L. Seymour,Religion2020,1985,1,
Bruce Lincoln,Religion2020,1990,5,
William B. Bean,Religion2020,1960,17,
Ninian Smart,Religion2020,1995,16,
Eamon Duffy,Religion2020,1985,2,
Nella van den Brandt,Cooperation2021,2015,2,
Brian Skyrms,Cooperation2021,2015,3,
I Ketut Sudarsana,CooperationAndReligion,2015,1,
Zuoxin Wang,Cooperation2021,2010,9,
Alexander Moreira-Almeida,Religion2020,2005,10,
Adrian Furnham,Religion2020,1980,1,
Benjamin Grant Purzycki,CooperationAndReligion,2010,1,
Anatolii M. Kolodnyi,CooperationAndReligion,1995,1,
Vlastimil Křivan,Cooperation2021,2015,16,
Norman Doe,Religion2020,2010,10,
Christopher C. H. Cook,Religion2020,2015,10,
Thomas J. Csordas,Religion2020,1990,2,
Laszlo Zsolnai,Religion2020,2000,4,
Roberto Lambertini,Religion2020,2005,2,
Jozef Matula,Religion2020,2010,50,
Yan Li,Religion2020,2005,8,
Frank J. Korom,Religion2020,2015,2,
Jeppe Sinding Jensen,Religion2020,2005,3,
David De Cremer,Cooperation2021,2000,38,
ROBERT M. GRANT,Religion2020,1940,1,
David Little,Religion2020,2005,2,
Ramón Alonso-Sanz,Cooperation2021,1995,2,
Zengqiang Chen,Cooperation2021,2015,16,
Mark A. Yarhouse,Religion2020,2010,7,
Bruce Lincoln,Religion2020,2010,3,
Rob Freathy,Religion2020,2005,2,
Charles Y. Glock,Religion2020,1955,1,
Oded Stark,Cooperation2021,1995,3,
Holly Gwen Prigerson,Religion2020,2015,3,
Philip Hefner,Cooperation2021,1990,1,
Vern L. Bengtson,Religion2020,1990,3,
Nima Ghorbani,Cooperation2021,2000,1,
Ana V. Diez Roux,Cooperation2021,2005,10,
Alvin Plantinga,Religion2020,2005,8,
Gregory Baum,Religion2020,1990,1,
Robert Hastings Nichols,Religion2020,1925,4,
Richard L. Gorsuch,Religion2020,1980,4,
Wesley J. Wildman,Religion2020,2000,3,
Nathan Carlin,Religion2020,2010,32,
C. Daniel Batson,Religion2020,1995,4,
John Maltby,Cooperation2021,2000,1,
Roland Robertson,Religion2020,2015,3,
Azim F. Shariff,Cooperation2021,2015,4,
Frank D. Fincham,Cooperation2021,2000,1,
Bryan S. Turner,Religion2020,1985,8,
Keith J. Edwards,Cooperation2021,2000,3,
Yoh Iwasa,Cooperation2021,1995,2,
Ronald J. Morris,Religion2020,1980,5,
JOSEPH M. KITAGAWA,Religion2020,1985,6,
Oliver Leaman,Religion2020,2000,2,
Abdulkader Tayob,Religion2020,2015,13,
Kamaldeep Bhui,Cooperation2021,2010,4,
Andy Gardner,Cooperation2021,2010,18,
Joseph Bracken,Religion2020,2000,5,
Francis Schüssler Fiorenza,Religion2020,2015,1,
Peter Koslowski,Religion2020,2000,26,
Toshio Yamagishi,Cooperation2021,2010,17,
Ichiro Kawachi,Cooperation2021,2015,5,
Tatsuya Morita,Religion2020,2015,7,
Kevin N. Laland,Cooperation2021,2000,12,
David B. Burrell,Religion2020,1975,1,
Aryeh Lazar,Religion2020,2010,8,
J. Kwabena Asamoah-Gyadu,Religion2020,2015,7,
William Sims Bainbridge,Religion2020,1975,1,
Marc Galanter,Cooperation2021,2000,1,
Eric van Dijk,Cooperation2021,2015,5,
Chris G. Sibley,Cooperation2021,2005,4,
Thomas Williams,Religion2020,2010,17,
Keith Ward,Religion2020,1970,6,
Pascal Boyer,Cooperation2021,2010,2,
Christopher G. Ellison,Religion2020,1995,15,
Thomas Jeschke,Religion2020,2015,2,
Joseph Bracken,Religion2020,1980,1,
Kevin J. Christiano,Religion2020,1995,5,
Keith E. Yandell,Religion2020,1975,3,
Yan Li,Cooperation2021,2010,12,
Shun Kurokawa,Cooperation2021,2010,2,
Boyd Taylor Coolman,Religion2020,2010,22,
Peter Gilbert,Religion2020,2005,22,
Katie G. Cannon,Religion2020,2010,34,
Daniel Callahan,Religion2020,2010,3,
Richard Harding,Religion2020,2005,4,
Paul Avis,Religion2020,1980,1,
Daniel P. Sulmasy,Religion2020,2000,9,
Steven Stack,Religion2020,2000,3,
Martin A. Nowak,Cooperation2021,2015,40,
Walter E. Conn,Religion2020,1975,6,
Joseph M. Currier,Religion2020,2010,14,
Ronald J. Morris,Religion2020,2000,4,
Joseph Henrich,Religion2020,2010,9,
Jacques Launay,Cooperation2021,2015,24,
Maroš Servátka,Cooperation2021,2005,18,
Robert Audi,Religion2020,1985,2,
Christophe Jaffrelot,Religion2020,1990,3,
Sarah Coakley,Religion2020,1995,3,
Altaf Husain,Religion2020,2010,2,
F Rosner,Cooperation2021,1975,1,
John C. Green,Religion2020,2005,6,
Heinz Streib,CooperationAndReligion,2015,1,
Louis Dupré,Religion2020,1975,6,
M. M. Knappen,Religion2020,1935,18,
François Gauthier,Religion2020,2000,2,
John F. Miller,Religion2020,1975,12,
Lei Yu,Cooperation2021,2015,3,
Jesús Gómez-Gardeñes,Cooperation2021,2010,28,
David W. Orme-Johnson,Religion2020,1980,3,
Patrick Collinson,Religion2020,1965,2,
John Cottingham,Religion2020,1985,2,
Russell T. McCutcheon,Religion2020,1990,2,
Patrick H. McNamara,Religion2020,2005,2,
Lionel Obadia,Religion2020,2005,4,
George Pattison,Religion2020,2005,4,
Dean R. Hoge,Religion2020,1985,10,
Douglas Pratt,Religion2020,1995,1,
Amanda Porterfield,Religion2020,1985,7,
John R. Peteet,Religion2020,1985,2,
Michele Dillon,Cooperation2021,1995,1,
Helen Rose Ebaugh,Religion2020,1995,1,
Robert Perske,Religion2020,1965,3,
Adam B. Cohen,Religion2020,2010,18,
Rhys H. Williams,Religion2020,2015,11,
Li Zhang,Religion2020,2005,1,
Hans G. Kippenberg,Religion2020,1980,2,
Hubert Knoblauch,Religion2020,1995,1,
HUGO MEYNELL,Religion2020,1980,5,
Abdulkader Tayob,Religion2020,1995,1,
Wilfred McSherry,Religion2020,2015,18,
Marjorie Mandelstam Balzer,Cooperation2021,2005,1,
Edward P. Shafranske,Religion2020,1995,6,
Eamon Duffy,Religion2020,2005,8,
R. E. S. Tanner,Religion2020,1965,3,
Ivan Strenski,Religion2020,2015,9,
Rod Blackhirst,Religion2020,2010,16,
Wade Clark Roof,Cooperation2021,2005,1,
Michel Despland,Religion2020,1990,2,
Ed Diener,Religion2020,2005,9,
David Lester,Religion2020,2010,11,
Lei Shi,Cooperation2021,2015,37,
I Ketut Sudarsana,Religion2020,2015,19,
Daniel H. Grossoehme,Religion2020,2015,12,
Wen-Xu Wang,Cooperation2021,2005,9,
Stuart A. West,Cooperation2021,2015,25,
Phillip R. Shaver,Religion2020,1980,1,
Norman Doe,Religion2020,1990,2,
Julie J. Exline,Religion2020,2005,1,
Thomas J. Csordas,Religion2020,2010,2,
Giancarlo Lucchetti,Religion2020,2010,39,
Patricia M. Davidson,Religion2020,2000,8,
Clive D. Field,Religion2020,1990,4,
Lewis S. Ford,Religion2020,1995,1,
Mark J. Cartledge,Religion2020,2000,2,
Phillip E. Hammond,Religion2020,1970,5,
Niels Christian Hvidt,Religion2020,2015,26,
James T. Richardson,Religion2020,1985,10,
Philip L. Tite,Religion2020,2010,18,
Laurence R. Iannaccone,Religion2020,1990,5,
Michael E. McCullough,Religion2020,2000,13,
Joerg Rieger,Religion2020,2000,2,
H. J. Rose,Religion2020,1920,4,
Jamie D. Aten,Religion2020,2000,3,
Steven Stack,Religion2020,1980,10,
Anthony B. Pinn,Religion2020,2010,41,
Mariano Barbato,Religion2020,2015,8,
Herbert Benson,Cooperation2021,2005,1,
Gary D. Bouma,Religion2020,1990,3,
Karen E. Steinhauser,Religion2020,2005,4,
Stephen Hunt,Religion2020,2000,8,
Vincent Lloyd,Religion2020,2015,28,
Kai Nielsen,Religion2020,1965,8,
Sidney E. Mead,Religion2020,1960,9,
Conrad Hackett,Religion2020,2005,4,
Nicola Slee,Religion2020,1985,27,
Phillip E. Hammond,Religion2020,2005,2,
SVENN LINDSKOLD,Cooperation2021,1985,5,
Russell L. Friedman,Religion2020,2005,1,
Aidan Nichols,Religion2020,1990,5,
Roland Phillips,Religion2020,1980,6,
Barbara Pesut,Religion2020,2015,14,
David W. Orme-Johnson,Religion2020,2000,7,
Ichiro Kawachi,Cooperation2021,1995,6,
Michela Balconi,Cooperation2021,2010,18,
Dimitris Xygalatas,CooperationAndReligion,2015,6,
Sarah Coakley,Religion2020,2015,12,
Vegard Skirbekk,Religion2020,2015,34,
Leslie J. Francis,Religion2020,2010,59,
Daniel A. Helminiak,Religion2020,1980,2,
Edna B. Foa,Religion2020,2015,2,
Len Sperry,Religion2020,2015,10,
Lorenza S. Colzato,Religion2020,2005,2,
Chao Liu,Religion2020,2015,4,
Coralie Buxant,Religion2020,2010,4,
Armin W. Geertz,Cooperation2021,2015,1,
ROBERT M. GRANT,Religion2020,1960,11,
Mirjam Künkler,Religion2020,2000,2,
Joyce Ann Mercer,Religion2020,2015,15,
Robin Gill,Religion2020,1995,6,
Charles Burnett,Religion2020,2000,2,
Anson Shupe,Religion2020,1980,1,
Stephen J. Stein,Religion2020,1975,3,
Harold G. Koenig,CooperationAndReligion,2015,1,
John Hedley Brooke,Religion2020,1975,1,
Ronald Labonte,Cooperation2021,1995,5,
Michiel van Elk,CooperationAndReligion,2015,1,
Raymond F. Paloutzian,Religion2020,1985,1,
Renee V. Galliher,Religion2020,2015,16,
Wade Clark Roof,Religion2020,1980,10,
David Schultz,Religion2020,2015,5,
Euan Cameron,Religion2020,2010,8,
Robert Audi,Religion2020,2005,7,
R. Ruard Ganzevoort,Religion2020,2000,6,
Nima Ghorbani,Religion2020,2010,10,
Jean Bethke Elshtain,Religion2020,2010,6,
Eckhard Frick,Religion2020,2010,11,
Paul F. Knitter,Religion2020,1995,1,
Betty R. Ferrell,Religion2020,2015,13,
Robert Boyd,Cooperation2021,2015,5,
Samuel Bowles,Cooperation2021,2010,15,
Francis X. Clooney,Religion2020,2015,10,
Abraham Melamed,Religion2020,2010,26,
Johannes A. Van der Ven,Religion2020,1995,10,
Jacques Waardenburg,Religion2020,1995,3,
H. Frankfort,Religion2020,1950,2,
Darren Cronshaw,Religion2020,2010,12,
Jocelyne Cesari,Religion2020,2000,4,
David Martin,Religion2020,1965,12,
Liam Gearon,Religion2020,2005,5,
Alexander Treiger,Religion2020,2010,26,
David W. Foy,Cooperation2021,1975,4,
Julia Downing,Religion2020,2015,18,
Ryan M. Denney,Religion2020,2005,1,
Lewis R. Rambo,Religion2020,2015,2,
Tong Chen,Cooperation2021,2015,15,
Tracey E. Robert,Religion2020,2005,1,
Mark Juergensmeyer,Religion2020,1990,6,
John Witte,CooperationAndReligion,1995,1,
Richard L. Gorsuch,CooperationAndReligion,2000,1,
W. Brad Johnson,Religion2020,2000,14,
Michael Tomasello,Cooperation2021,1995,4,
Amnon Rapoport,Cooperation2021,2015,9,
Graham Rossiter,Religion2020,2015,3,
David Robinson,Cooperation2021,1970,4,
Bert Roebben,Religion2020,2005,6,
Richard Egan,Religion2020,2005,1,
Andrea D. Clements,Religion2020,2010,3,
Shailer Mathews,Religion2020,1930,2,
Abbas Ebadi,Religion2020,2010,4,
Arndt Büssing,Religion2020,2015,47,
Harvey Whitehouse,Religion2020,1995,1,
E. Brooks Holifield,Religion2020,1980,2,
Thomas M. Izbicki,Religion2020,2010,28,
Paul C. Cooper,Religion2020,2000,2,
Nicolas Baumard,Religion2020,2015,12,
Herbert Gintis,Cooperation2021,2015,3,
Wilhelm Gräb,Religion2020,2005,3,
David A. S. Fergusson,Religion2020,2010,17,
Rosalind I. J. Hackett,Religion2020,2015,2,
S. Mark Heim,Religion2020,2015,11,
Paul Helm,Religion2020,1970,2,
Kenneth Surin,Religion2020,2015,1,
George Zografidis,Religion2020,2015,1,
JAMES L. COX,Religion2020,2005,3,
Daniel Callahan,Religion2020,1975,3,
Eileen Barker,Religion2020,2000,3,
Urs Fischbacher,Cooperation2021,2010,6,
Robert A. Segal,Religion2020,1975,24,
Hans-Georg Ziebertz,Religion2020,2015,20,
Michael Stausberg,Religion2020,2000,4,
Elisabeth Arweck,Religion2020,1990,1,
Christina M. Puchalski,Religion2020,2010,16,
Ralph L. Piedmont,Religion2020,2015,14,
H. W. S.,Religion2020,1950,4,
Elizabeth Johnston Taylor,Religion2020,2000,7,
Robert Joseph Taylor,Religion2020,2015,14,
David G. Rand,CooperationAndReligion,2010,3,
Sanford Nidich,Religion2020,2005,5,
James T. Richardson,Cooperation2021,1965,1,
John Wilson,Religion2020,1975,6,
Michael Tomasello,Religion2020,2005,2,
Jeffrey C. Alexander,Religion2020,1985,6,
Tatsuya Sasaki,Cooperation2021,2015,25,
J. L. SCHELLENBERG,Religion2020,1990,2,
Amos N. Wilder,Religion2020,1940,6,
Henry Nelson Wieman,Religion2020,1940,4,
Rufus A. Johnstone,Cooperation2021,1990,1,
Nathan R Todd,Cooperation2021,2010,4,
Charles Hartshorne,Religion2020,1940,6,
Clive Marsh,Religion2020,2000,4,
Rowan Williams,Religion2020,1980,1,
Elaine Graham,Religion2020,2000,10,
Richard Cross,Religion2020,2000,4,
Jeffrey M Drazen,Cooperation2021,2015,16,
Steven J. Sandage,Religion2020,2000,1,
Paul J. Griffiths,Religion2020,2010,1,
Adrian Furnham,Religion2020,2000,3,
Benjamin Grant Purzycki,Cooperation2021,2015,6,
David Brown,Religion2020,2005,6,
Carole A. Rayburn,Religion2020,1990,20,
Abdulaziz Sachedina,Religion2020,2005,8,
Jack L. Seymour,Religion2020,2005,8,
Catherine L. Albanese,Religion2020,1990,6,
Gregory D. Alles,Religion2020,1985,8,
William P. Alston,Religion2020,2000,1,
Anatol Rapoport,Cooperation2021,1975,2,
David Fergusson,Religion2020,2005,4,
Shauna L. Shapiro,Religion2020,2015,4,
Graham Harvey,Religion2020,2010,3,
Elaine Howard Ecklund,Religion2020,2010,7,
Benton Johnson,Religion2020,1970,3,
John Maltby,Religion2020,2010,2,
John L. Longeway,Religion2020,2010,34,
Robert W. McGee,Cooperation2021,2000,1,
Jean-Paul Willaime,Religion2020,1970,1,
Joel Cabrita,Religion2020,2015,12,
Bruce Ross,Religion2020,2010,20,
Albrecht Classen,Religion2020,2005,7,
Peter M. Kappeler,Cooperation2021,2000,5,
Diarmaid MacCulloch,Religion2020,2015,3,
Kenneth Ira Pargament,Religion2020,2010,76,
Nicholas Grant Boeving,Religion2020,2010,23,
David Lehmann,Religion2020,1995,6,
Pam McGrath,Cooperation2021,2000,3,
Susumu Shimazono,Religion2020,1995,4,
H. Newton Malony,Religion2020,1990,11,
Ian Reader,Religion2020,1995,3,
Joel Robbins,Religion2020,1995,2,
T. H. Barrett,Religion2020,1990,6,
J. Milton Yinger,Religion2020,1985,2,
Ann Taves,Religion2020,2010,9,
Fiona Timmins,Religion2020,2005,2,
Jonathan Jackson,Cooperation2021,2010,13,
Carole M. Cusack,CooperationAndReligion,2015,1,
Peter-Ben Smit,Religion2020,2015,48,
Christoph Auffarth,Religion2020,2005,24,
Robin Gill,Religion2020,1975,4,
Lisa Sowle Cahill,Religion2020,2000,4,
John E. Smith,Religion2020,1955,2,
Gari Walkowitz,Cooperation2021,2005,3,
Syamsuri Syamsuri,Cooperation2021,2015,2,
Alastair Hamilton,Religion2020,2015,5,
Terrence W. Tilley,Religion2020,2010,1,
Jeffrey Haynes,CooperationAndReligion,2010,1,
Benjamin Grant Purzycki,Religion2020,2015,17,
David H. Rosmarin,Religion2020,2015,18,
Richard Rymarz,Religion2020,2015,12,
Patrick Sherry,Religion2020,1980,5,
Geoffrey Wainwright,Religion2020,1980,6,
Carel P. van Schaik,Cooperation2021,1995,5,
David C. Queller,Cooperation2021,1985,8,
Stefan Schmidt,Religion2020,2000,1,
David Tracy,Religion2020,1970,3,
Michael Taborsky,Cooperation2021,2005,13,
Paul F. Knitter,Religion2020,2015,5,
Anatolii M. Kolodnyi,Religion2020,1995,22,
Dewey D. Wallace,Religion2020,2005,2,
George A. Coe,Religion2020,1910,4,
Martin E. Marty,Religion2020,1990,17,
Vincent Lloyd,CooperationAndReligion,2015,2,
Gareth B. Matthews,Religion2020,1960,2,
Bernard McGINN,Religion2020,1985,5,
Kocku von Stuckrad,Religion2020,2005,9,
Jacques Waardenburg,Religion2020,1975,8,
Michael Gurven,Religion2020,2010,1,
Michael Gurven,Cooperation2021,2010,11,
Stanley Hauerwas,Religion2020,1980,2,
Ann Loades,Religion2020,1990,5,
Aidan Nichols,Religion2020,2010,4,
P. Scott Richards,Religion2020,2010,20,
Kevin R. Foster,Cooperation2021,2000,6,
Frederick M Burkle,Cooperation2021,2005,12,
DAVID GRUMETT,CooperationAndReligion,2005,2,
K. Helmut Reich,Religion2020,1990,5,
Elisabeth Arweck,Religion2020,1910,1,
John Bowker,Religion2020,1980,4,
David G. Bromley,Religion2020,1980,5,
Will M. Gervais,CooperationAndReligion,2010,1,
Feng Wang,Cooperation2021,2005,4,
John Swinton,Religion2020,1995,1,
Birger A. Pearson,Religion2020,1975,4,
Pascual Parrilla,Cooperation2021,2005,6,
Joan E. Strassmann,Cooperation2021,2015,10,
David B Larson,Religion2020,1990,16,
Alessandra Lamas Granero Lucchetti,Religion2020,2015,18,
Luther H. Martin,Cooperation2021,2010,1,
Douglas A. MacDonald,Religion2020,2005,8,
Frederick M. Smith,Religion2020,2005,14,
Russell Sandberg,CooperationAndReligion,2010,1,
Merold Westphal,Religion2020,1985,3,
Frank D. Fincham,Religion2020,2015,7,
Kathryn Pitkin Derose,Religion2020,2015,24,
Simon Coleman,Religion2020,2015,15,
Winthrop S. Hudson,Religion2020,1960,3,
David W. Macdonald,Cooperation2021,1980,4,
Jacques Waardenburg,Cooperation2021,2005,1,
Mark A. Noll,Religion2020,2005,7,
Matthew Clarke,Cooperation2021,2010,1,
Stephen D. Glazier,Religion2020,2000,5,
Brian Leftow,Religion2020,2010,4,
Syamsuri Syamsuri,Religion2020,2015,20,
David Little,Religion2020,1965,2,
Diarmaid MacCulloch,Religion2020,1995,5,
Charles E. Farhadian,Religion2020,2010,22,
Eric A. Storch,Religion2020,2000,14,
John Parratt,Religion2020,2000,8,
Marian de Souza,Religion2020,2000,6,
William Desmond,Religion2020,1990,6,
Edwin E. Aubrey,Religion2020,1945,2,
James S. Gordon,Religion2020,2005,6,
James A. Beckford,Religion2020,1995,9,
Ian Reader,Religion2020,2015,6,
Jeffrey K. Hadden,Religion2020,1960,1,
Chao Liu,Cooperation2021,2005,1,
Linda E. Carlson,Religion2020,2000,8,
Meredith B. McGuire,Religion2020,1980,8,
Donald Capps,Religion2020,1985,1,
Eduardo Bruera,Religion2020,2015,46,
James H. Leuba,Religion2020,1905,5,
Daniel Houser,Cooperation2021,2010,17,
Farr A. Curlin,Religion2020,2005,19,
Jacob Neusner,Religion2020,1965,6,
Mitchell J. Neubert,Religion2020,2015,14,
Herbert Gintis,Cooperation2021,1975,2,
Rod Ling,Cooperation2021,2010,1,
Crystal L. Park,Religion2020,2010,43,
Lorenzo Casini,Religion2020,2015,1,
Steve Fuller,Religion2020,1995,2,
Edward P. Shafranske,Religion2020,2015,3,
Peter L. Berger,Religion2020,1965,7,
Friedrich Schweitzer,Religion2020,2005,9,
Denni Arli,Religion2020,2015,13,
Michael Argyle,Religion2020,1960,6,
Deepak Chopra,Religion2020,2005,3,
John Witte,Cooperation2021,1995,1,
Tomas Ekenberg,Religion2020,2010,26,
Aasim I. Padela,CooperationAndReligion,2010,1,
Ryan T. Cragun,Religion2020,2010,9,
Jon R. Webb,Religion2020,2000,1,
Javier Garcia-Campayo,Religion2020,2015,15,
Anatol Rapoport,Cooperation2021,2015,2,
David Robinson,Religion2020,2010,6,
Lei Yu,Cooperation2021,1995,1,
Louis Dupré,Religion2020,1995,1,
Charles Hartshorne,Religion2020,1980,1,
Mary Jo Neitz,Religion2020,2015,2,
Paul D. Molnar,Religion2020,1985,2,
Candy Gunther Brown,Religion2020,2015,13,
Carole A. Rayburn,Cooperation2021,2000,4,
John A. Demetracopoulos,Religion2020,2000,1,
Rosaria Conte,Cooperation2021,2000,14,
E E Griffith,Religion2020,1980,12,
David Carr,Religion2020,2005,9,
Christophe Erismann,Religion2020,2015,1,
Elinor Ostrom,Cooperation2021,1980,1,
S. Brent Plate,Religion2020,2010,18,
Marcel Barnard,Religion2020,2005,1,
Michael Northcott,Religion2020,2005,6,
John R. Peteet,Religion2020,2005,16,
William Sweet,Religion2020,2015,6,
Rosemary Radford Ruether,Religion2020,1980,6,
William K. Kay,Religion2020,1980,22,
Paul A. M. Van Lange,Religion2020,2005,1,
Kenneth D. Wald,Religion2020,1990,14,
Genki Ichinose,Cooperation2021,2005,2,
Luo-Luo Jiang,Cooperation2021,2005,6,
Julia Ipgrave,Religion2020,2005,1,
Fraser Watts,Religion2020,2000,2,
Heidemarie Kremer,Religion2020,2015,6,
Bhikhu Parekh,Religion2020,2000,6,
Jean-Paul Willaime,Religion2020,2010,13,
Richard Sawatzky,Religion2020,2005,18,
Carl Sterkens,Religion2020,2010,14,
Philipp C. Wichardt,Cooperation2021,2010,16,
Ann Casement,Religion2020,2010,14,
John Hedley Brooke,Religion2020,2015,7,
Iain R. Torrance,Religion2020,1985,2,
Christoph Jacobs,Religion2020,2015,18,
Paul J. Mills,Religion2020,2000,6,
Lisa Miller,Religion2020,2015,26,
Wilfred McSherry,Religion2020,1995,3,
F. LeRon Shults,Religion2020,1990,2,
Sergej Flere,Religion2020,2015,2,
Janusz Salamon,Religion2020,2015,12,
Amy L. Ai,Religion2020,2000,8,
Neil Ormerod,Religion2020,1995,3,
Hisashi Ohtsuki,Cooperation2021,2005,20,
Jeffrey S. Levin,Religion2020,2010,14,
Angelos Chaniotis,Religion2020,2015,3,
Christopher Key Chapple,Religion2020,2005,4,
Joyce J. Fitzpatrick,Religion2020,1990,2,
Marc Galanter,CooperationAndReligion,2010,1,
Luther H. Martin,Religion2020,2010,6,
B. Alan Wallace,Religion2020,2005,3,
Claude-Hélène Mayer,Religion2020,2015,45,
E. Brooks Holifield,Religion2020,2015,1,
Kelly Bulkeley,Religion2020,2010,5,
Paul J. Watson,Religion2020,1985,5,
John T. Ford,Cooperation2021,2010,1,
Roberto Cipriani,Religion2020,2010,10,
Ronald L. Grimes,Religion2020,1985,6,
Paul Harvey,Cooperation2021,2000,2,
Elvira Wakelnig,Religion2020,2010,54,
Ariel Dinar,Cooperation2021,2010,11,
Alyssa N. Rockenbach,Religion2020,2015,14,
Gerard Leavey,Cooperation2021,2000,1,
Laura R. Olson,Religion2020,2005,12,
Arvind Sharma,Religion2020,1990,8,
Leslie Francis,Religion2020,1980,3,
Marjorie Mandelstam Balzer,Religion2020,1990,8,
Rega Wood,Religion2020,2005,1,
Joseph H. Fichter,Religion2020,1990,1,
Robert S. Ellwood,Religion2020,2010,10,
Pascual Parrilla,CooperationAndReligion,2005,6,
Niels Christian Hvidt,Religion2020,1995,1,
Dirk Hutsebaut,Religion2020,1995,4,
David Lester,Religion2020,1970,2,
Marc Galanter,Cooperation2021,1980,2,
Marc Galanter,Religion2020,2010,14,
Michael J. Reiss,Religion2020,2000,1,
Graham Rossiter,Religion2020,1975,1,
David Robinson,Cooperation2021,2010,2,
Pnina Werbner,Religion2020,2015,7,
Betty R. Ferrell,Cooperation2021,2010,1,
Keith J. Edwards,Religion2020,2010,3,
Joan Marques,Religion2020,2005,1,
Paul Helm,Religion2020,2010,6,
HUGO MEYNELL,Religion2020,1960,1,
Robert A. Segal,Religion2020,2015,22,
Andreas Glöckner,Cooperation2021,2010,10,
Hubert Knoblauch,Religion2020,2015,5,
William Schweiker,Religion2020,1995,4,
Nathan R. Kollar,Religion2020,1970,4,
Jesse Fox,Religion2020,2015,14,
Charles Y. Glock,Religion2020,1975,7,
Teresa Obolevitch,Religion2020,2015,16,
Gregory Baum,Religion2020,2010,6,
George Aaron Barton,Religion2020,1935,4,
Lodovico Balducci,Religion2020,2005,4,
John Scheid,Religion2020,1990,2,
Ramón Alonso-Sanz,Cooperation2021,2015,14,
Virginia A. Kelly,Religion2020,2015,26,
C. Daniel Batson,Religion2020,1975,12,
Leslie J. Francis,CooperationAndReligion,2010,1,
Pasquale Annicchino,Religion2020,2010,42,
Walter Brueggemann,Religion2020,2000,10,
Shauna L. Shapiro,Religion2020,1995,1,
Amy B. Wachholtz,Religion2020,2015,2,
W. D. Hudson,Religion2020,1980,2,
Charles H. Lippy,Religion2020,1995,15,
Vincent Lloyd,Cooperation2021,2015,2,
Romy Lauche,Religion2020,2010,12,
Göran Larsson,Religion2020,2010,12,
Sheridan Gilley,Religion2020,1970,3,
Claudia Fichtel,Cooperation2021,2010,16,
David A. S. Fergusson,Religion2020,1990,2,
Randall Balmer,Religion2020,1995,8,
Philip L. De Jager,Religion2020,2010,11,
José Filipe Silva,Religion2020,2015,1,
Bryan D. Spinks,Religion2020,2000,10,
Hans-Georg Ziebertz,Religion2020,1995,3,
Darryl Macer,Religion2020,2015,8,
Marc A. Musick,Religion2020,2005,20,
Mandy Robbins,Religion2020,1995,2,
David Lehmann,Religion2020,2015,3,
JOHN D. CAPUTO,Religion2020,1985,2,
Mark R. McMinn,Religion2020,1985,2,
Patrick H. McNamara,Religion2020,1985,4,
Dick Anthony,Religion2020,1980,24,
Robert S. Wilson,Religion2020,2005,10,
Paul A. M. Van Lange,Cooperation2021,2010,8,
Robert S. Ellwood,Religion2020,1970,4,
David W. Foy,Religion2020,2010,20,
Naser Aghababaei,Cooperation2021,2010,2,
W. Brad Johnson,Cooperation2021,2005,2,
Nicolas Baumard,Cooperation2021,2015,22,
Edo Shonin,Religion2020,2015,32,
H. Newton Malony,Religion2020,2010,2,
Russell Sandberg,Cooperation2021,2010,2,
Robert N. Bellah,Religion2020,2010,4,
David A. Bennett,Religion2020,2005,24,
William Breitbart,Religion2020,2000,20,
Lorne L. Dawson,Religion2020,2015,12,
Norman Pettit,Religion2020,1980,7,
Mark Hill,Religion2020,2010,8,
H. Frankfort,Religion2020,1930,2,
Ursula King,Religion2020,1970,3,
David Novak,Religion2020,2000,7,
Peter L. Berger,Religion2020,2005,1,
David W. K. Yeung,Cooperation2021,2010,18,
Yaacov J. Katz,Religion2020,1995,2,
Josephine Griffith,Cooperation2021,2000,1,
Peter White,Religion2020,1990,2,
Harold G. Koenig,Religion2020,1995,33,
DAVID GRUMETT,Religion2020,2010,24,
Fergus Kerr,Religion2020,2010,7,
Victor Cohn,Cooperation2021,1970,1,
José A. Cuesta,Cooperation2021,2015,1,
Ania Loomba,Religion2020,2005,14,
Bruce V. Foltz,Religion2020,2010,13,
Jeppe Sinding Jensen,Religion2020,1985,1,
Niels Henrik Gregersen,Religion2020,1990,4,
Anders Klostergaard Petersen,Religion2020,2015,17,
Klaus Zuberbühler,Cooperation2021,2005,2,
Brian Patrick McGuire,Religion2020,2015,1,
Richard L. Gorsuch,Religion2020,2000,11,
Peter Harrison,Religion2020,1990,1,
David Morgan,Religion2020,2005,21,
Jacob Neusner,Religion2020,2005,24,
Clyde Wilcox,Religion2020,2005,7,
David R. Williams,Religion2020,1980,2,
Linda K. George,Cooperation2021,2005,1,
Aurélien Robert,Religion2020,2015,1,
Peter Kivisto,Religion2020,2005,6,
Kim Knott,Religion2020,2010,8,
Michael Welker,Religion2020,2010,22,
Brendan Hyde,Religion2020,2015,8,
Yoh Iwasa,Cooperation2021,2015,8,
C. Robert Cloninger,Religion2020,2015,20,
Albert Doja,Religion2020,2010,6,
James Wm. McClendon,Religion2020,1970,40,
Wilhelm Gräb,CooperationAndReligion,2005,1,
Orna Zagoory-Sharon,Cooperation2021,2015,4,
Jørn Borup,Religion2020,2005,2,
Shirley Jackson Case,Religion2020,1915,8,
Stephan De Beer,Religion2020,2015,16,
Duncan B. Forrester,Cooperation2021,1995,1,
John M. Salsman,Religion2020,2010,12,
Dion Smyth,Religion2020,2005,17,
Francois Venter,Religion2020,2015,15,
Mujiburrahman Mujiburrahman,Religion2020,2015,13,
J. Milton Yinger,Religion2020,1965,8,
D. Z Phillips,Religion2020,1960,4,
Elisabeth Arweck,Religion2020,2010,8,
Dominiek D. Coates,Religion2020,2015,2,
Myrna M. Weissman,Religion2020,1980,2,
Jeff Astley,Religion2020,1995,5,
Michael J Balboni,Religion2020,2010,14,
Andrew M. Greeley,Religion2020,1995,8,
David G. Rand,Religion2020,2010,5,
Paul F. Knitter,Religion2020,1975,1,
Peter W. Williams,Religion2020,1995,12,
R. Stephen Warner,Religion2020,1995,10,
Roy Wallis,Religion2020,1985,28,
Emyr Williams,Religion2020,2010,14,
Fiona Timmins,CooperationAndReligion,2005,1,
Barbara Hargrove,Religion2020,1985,9,
Irving Hexham,Religion2020,2015,4,
Jacques Waardenburg,Religion2020,2015,1,
David M. Wulff,Religion2020,2015,3,
Simon Gächter,Cooperation2021,2015,11,
Yinka Olomojobi,Religion2020,2015,16,
Nella van den Brandt,Religion2020,2010,2,
Todd L. Cherry,Cooperation2021,2005,12,
Paul J. Griffiths,Religion2020,1990,7,
Jonas Preposi Cruz,Religion2020,2015,15,
Philip Hefner,Religion2020,2010,5,
Martin A. Nowak,Cooperation2021,1995,7,
Aru Narayanasamy,Religion2020,1995,5,
Ronald Labonté,Cooperation2021,2015,16,
James R. Lewis,Religion2020,2015,25,
Mircea Eliade,Religion2020,1975,3,
Eckhard Frick,Cooperation2021,2015,1,
Donald Wiebe,Religion2020,2005,8,
Detlef Pollack,Religion2020,1995,7,
Dean Tjosvold,Cooperation2021,2000,5,
Gerard Leavey,Religion2020,2005,6,
Enzo Pace,Religion2020,2010,10,
Amnon Rapoport,Cooperation2021,1995,9,
WILLIAM F. QUILLIAN,Religion2020,1950,13,
Carmela Baffioni,Religion2020,1995,1,
Hans Mol,Religion2020,1980,12,
Albert Ellis,Religion2020,2015,3,
Albert Ellis,Cooperation2021,1945,3,
Rufus A. Johnstone,Cooperation2021,2010,8,
Robert Jackson,Religion2020,1990,3,
William P. Alston,Religion2020,1980,1,
Ram A. Cnaan,Cooperation2021,2010,1,
Agnes Imhof,Religion2020,2005,15,
Brick Johnstone,Religion2020,2005,12,
Mingchu Li,Cooperation2021,2005,1,
Richard A. McCormick,Religion2020,1980,16,
Amy M. Burdette,Religion2020,2015,24,
Steven N. Durlauf,Cooperation2021,2000,2,
Joanna Elizabeth Crossman,Religion2020,2015,18,
Robert Brancatelli,Religion2020,2005,8,
Clive D. Field,Religion2020,2010,16,
Joyce Ann Mercer,Religion2020,1995,1,
Keith E. Yandell,Religion2020,2015,12,
Li Zhang,Cooperation2021,2010,10,
John Warwick Montgomery,Religion2020,2015,2,
Andrew L. Whitehead,Religion2020,2010,10,
James T. Richardson,Religion2020,2005,16,
Cok Bakker,Religion2020,2010,20,
Duncan B. Forrester,Religion2020,1995,2,
Lewis S. Ford,Religion2020,1975,5,
Don Schweitzer,Religion2020,2010,13,
William H. Swatos,Religion2020,1995,7,
Joseph H. Fichter,Religion2020,1950,2,
Judi Neal,Religion2020,2015,6,
J. D. Y. Peel,Religion2020,1980,2,
Julia Ipgrave,Cooperation2021,2005,1,
Peter Marshall,Religion2020,2000,5,
Linda K. George,Religion2020,2005,1,
Whitney A. Bauman,Religion2020,2010,12,
Michael Argyle,Religion2020,2000,3,
Daniel A. Helminiak,Religion2020,2000,2,
James F. Keenan,Religion2020,2010,5,
Guy G. Stroumsa,Religion2020,2000,1,
John F. Miller,Religion2020,2015,4,
HUGO MEYNELL,Religion2020,2000,1,
Satinder Dhiman,Cooperation2021,2015,1,
Chris Shilling,Religion2020,2010,30,
Nicholas A. Christakis,Religion2020,2005,4,
Peter Berger,Religion2020,1990,4,
Colin Gunton,Religion2020,2000,5,
Mario Fernando Prieto Peres,Religion2020,2015,16,
Robert W. Hefner,Religion2020,2015,9,
John Hick,Religion2020,1980,7,
Amparo Oliver,Religion2020,2010,2,
Carole A. Rayburn,Religion2020,2010,4,
Linda Ross,Religion2020,2005,4,
Malcolm Brown,Religion2020,1995,2,
Steve Nolan,Religion2020,2000,2,
Ted Peters,Religion2020,1990,6,
Richard H. Popkin,Religion2020,1985,4,
David R. Hodge,Religion2020,2010,42,
Len Sperry,Religion2020,1995,1,
E. Brooks Holifield,Religion2020,2000,4,
Rollen Edward Houser,Religion2020,2010,26,
Myrna M. Weissman,CooperationAndReligion,2015,2,
Todd M. Johnson,Religion2020,2010,10,
Paul Harvey,Religion2020,1995,11,
Amos Yong,Religion2020,2010,73,
Robin Gill,Religion2020,2015,9,
Ronald M. Glassman,Cooperation2021,1985,1,
Qian Zhang,Cooperation2021,2005,22,
David R. Johnson,Religion2020,1995,2,
Chad Barrett,Religion2020,2010,13,
Rosalind I. J. Hackett,Religion2020,1995,4,
ROBERT M. GRANT,Religion2020,1980,2,
Roland Boer,Religion2020,2005,24,
Patricia E. Murphy,Religion2020,2000,4,
John Corrigan,Religion2020,2010,8,
JAMES L. COX,Religion2020,1985,1,
Yan Chen,Cooperation2021,1995,1,
E E Griffith,CooperationAndReligion,1985,2,
Michael J. Wade,Cooperation2021,2005,2,
Jean Bethke Elshtain,Religion2020,1990,4,
Matjaž Perc,Cooperation2021,2015,42,
Linda Woodhead,Religion2020,1990,2,
Raymond F. Paloutzian,Religion2020,2005,2,
Laura R. Olson,Religion2020,1990,2,
Joseph Henrich,Cooperation2021,2015,21,
Susan J. Palmer,Religion2020,2010,2,
Risto Saarinen,Religion2020,2005,5,
Hans Schwarz,Religion2020,2010,10,
Will M. Gervais,Cooperation2021,2010,2,
Roman Lunkin,Religion2020,2015,14,
Pekka Kärkkäinen,Religion2020,2010,34,
Gary D. Bouma,Religion2020,2010,22,
Simon Dein,Religion2020,2000,10,
Francis X. Clooney,Religion2020,1995,9,
George A. Coe,Religion2020,1950,1,
Ben J. Hatchwell,Cooperation2021,2010,12,
Pamela Ebstyne King,Religion2020,2010,6,
Francis Schüssler Fiorenza,Religion2020,1975,3,
William Gibson,Religion2020,2000,2,
Peter Lake,Religion2020,1980,3,
Andries G. Van Aarde,Religion2020,2005,8,
Ji Quan,Cooperation2021,2015,13,
Iain T. Benson,Religion2020,2015,6,
Loren D. Marks,Religion2020,2000,5,
David Hay,CooperationAndReligion,2010,2,
Frank Cranmer,CooperationAndReligion,2005,2,
Carsten K. W. De Dreu,Cooperation2021,2000,2,
Shailer Mathews,Religion2020,1910,8,
E. L. Mascall,Religion2020,1965,6,
Francis William Newman,Religion2020,2005,15,
C. Daniel Batson,Cooperation2021,1980,2,
Jeffrey C. Witt,Religion2020,2010,23,
Carl E. Thoresen,Cooperation2021,1965,2,
Richard J. Davidson,Religion2020,2000,5,
Franco Fabbro,Religion2020,1995,1,
Joanna Collicutt,Religion2020,2015,9,
Jacqueline S. Mattis,Religion2020,2010,11,
Gørill Haugan,Religion2020,2010,16,
Helen Hardacre,Religion2020,1990,7,
Jing Liu,Religion2020,2005,1,
Kai Nielsen,Religion2020,2005,4,
Rachel Muers,Religion2020,2010,6,
Brian R Clack,Religion2020,1990,4,
Janet L. Williams,Religion2020,2010,1,
Eveline A. Crone,Cooperation2021,2015,8,
Jeffrey A. French,Cooperation2021,1995,6,
Thomas Morstyn,Cooperation2021,2015,14,
Ben Clements,Religion2020,2015,20,
Toshio Yamagishi,Cooperation2021,1990,2,
Supa Pengpid,Cooperation2021,2010,1,
Detlef Pollack,Religion2020,2015,17,
Jerry Z. Park,Religion2020,2000,12,
James M. Nelson,Religion2020,2005,19,
Angus Buckling,Cooperation2021,2000,6,
Elena Bastida,Religion2020,2005,6,
Philippe Huguelet,Religion2020,2010,16,
Karl Peltzer,Religion2020,2005,16,
Samuel Stroope,Cooperation2021,2010,1,
Tariq Modood,Religion2020,2015,8,
Mircea Eliade,Religion2020,1955,2,
Jeremy E. Uecker,Religion2020,2010,6,
Albert Ellis,Religion2020,1975,3,
Amy M. Burdette,CooperationAndReligion,2015,4,
Afe Adogame,Religion2020,2000,6,
Maureen E. Lyon,Religion2020,2015,4,
Fazlollah Ahmadi,Cooperation2021,2015,1,
Manfred Milinski,Cooperation2021,1980,1,
Wilhelm Gräb,Cooperation2021,2005,1,
Eugene V. Gallagher,Religion2020,2000,2,
Laura Béres,Religion2020,2015,26,
Irving Hexham,Religion2020,1975,2,
Wei Li,Cooperation2021,2000,6,
Christopher McCrudden,Religion2020,2010,14,
William Schweiker,Cooperation2021,2010,1,
Maria Bigoni,CooperationAndReligion,2010,1,
David M. Wulff,Religion2020,1975,1,
James J. Buckley,Religion2020,2010,2,
Keith G. Meador,Religion2020,1990,5,
Edwin S. Gaustad,Religion2020,1985,8,
Maykel Verkuyten,Religion2020,2005,7,
Thomas Robbins,Religion2020,1985,16,
Gregory Baum,Religion2020,1970,1,
Alyssa N. Rockenbach,CooperationAndReligion,2015,2,
Traugott Roser,Religion2020,2010,5,
Henry Nelson Wieman,Religion2020,1955,2,
Ernst Fehr,Cooperation2021,2000,23,
Stephen Pattison,Religion2020,2015,5,
Michele Dillon,Religion2020,2005,14,
Lorne L. Dawson,Religion2020,1995,20,
Bryan S. Turner,Religion2020,2005,34,
DiMarkco Stephen Chandler,Religion2020,2010,28,
Marianne Moyaert,Religion2020,2005,6,
Sheridan Gilley,Religion2020,2010,2,
Marian Burchardt,Religion2020,2010,30,
Robert John Russell,Religion2020,1995,2,
Ines Testoni,Cooperation2021,2005,1,
George Fitchett,Religion2020,2005,8,
Jeffrey Haynes,Cooperation2021,2015,3,
Gregory R. Peterson,Religion2020,2000,13,
Ara Norenzayan,Religion2020,2015,40,
David Hay,Religion2020,2000,4,
Erika L. Rosenberg,Religion2020,2010,14,
Mark van Vugt,Cooperation2021,1980,1,
H Tristram Engelhardt,Cooperation2021,1990,2,
Anders Klostergaard Petersen,Religion2020,1995,2,
Pehr Granqvist,Religion2020,2010,12,
Peter C. Hill,Religion2020,2010,12,
Jianwei Huang,Cooperation2021,2015,18,
Adam B. Cohen,Cooperation2021,2005,2,
David R. Williams,Religion2020,2000,12,
William Sims Bainbridge,Cooperation2021,2000,1,
David Reiss,Cooperation2021,2000,3,
W. R. Ward,Religion2020,1965,2,
Peter W. Williams,Cooperation2021,2010,1,
Robert N. Bellah,Religion2020,1970,4,
Paul Vermeer,Religion2020,1995,2,
Stephen Hunt,Cooperation2021,2005,1,
Peter Cumper,Religion2020,2015,10,
Dale F. Eickelman,Religion2020,1985,2,
Fergus Kerr,Religion2020,1970,1,
Ursula King,Religion2020,2010,6,
C.W. Young,Cooperation2021,1970,2,
Larry J. Young,Cooperation2021,2005,14,
Ralph W. Hood Jr.,Religion2020,2015,1,
Arne Traulsen,Cooperation2021,2005,16,
Paul-François Tremlett,Religion2020,2010,16,
Jean-Robert Tyran,Cooperation2021,2010,13,
Renee V. Galliher,Cooperation2021,2005,2,
Oleksandr N. Sagan,Religion2020,2010,5,
H. J. Rose,Religion2020,1960,3,
F Rosner,Religion2020,1975,5,
Laura Galiana,Cooperation2021,2015,2,
Rodney Stark,Religion2020,1985,6,
Friederike Range,Cooperation2021,2015,14,
Philip L. Quinn,Religion2020,1990,9,
David W. Macdonald,Cooperation2021,2015,8,
Linda M. Chatters,Religion2020,1985,2,
Rhys H. Williams,Religion2020,1995,7,
Mark Chapman,Religion2020,2000,2,
Owen Anderson,Religion2020,2015,1,
Robert N. Bellah,Religion2020,1990,3,
Anthony J. Blasi,Religion2020,2000,3,
Don E. Davis,Religion2020,2015,64,
Human Friedrich Unterrainer,Religion2020,2010,16,
Maryam Rassouli,Religion2020,2015,58,
John Milbank,Religion2020,2005,32,
Yaacov J. Katz,Religion2020,2015,8,
Barbara J. Culliton,Religion2020,1995,4,
James Mark Shields,Religion2020,2015,14,
Ursula King,Cooperation2021,2000,1,
Dirk Helbing,Cooperation2021,1990,4,
Massimo Leone,Religion2020,2000,6,
Johann-Albrecht Meylahn,Religion2020,2005,2,
Keith G. Meador,Religion2020,2010,12,
Marjorie Mandelstam Balzer,Cooperation2021,1990,1,
Ruth Feldman,Cooperation2021,2010,14,
Edward J. Furton,Cooperation2021,2010,2,
Harold G. Koenig,Religion2020,2015,100,
Kristoffer L. Nielbo,CooperationAndReligion,2010,3,
Sung Joon Jang,Religion2020,2015,16,
Marcia Grant,Religion2020,1995,2,
Yolanda Dreyer,Religion2020,2015,8,
Michael Scott,Religion2020,1995,2,
Shirley Telles,Religion2020,2000,4,
Daniel Philpott,Religion2020,2010,7,
Pablo Ramírez,Cooperation2021,2010,14,
Donald Wiebe,Religion2020,1985,8,
Paul Tillich,Religion2020,1970,2,
Dora Clayton-Jones,Religion2020,2015,21,
Jonathan Mathias Lassiter,Religion2020,2015,15,
Sarah Coakley,Religion2020,1975,3,
Andrew J. Weaver,Religion2020,1990,1,
Sanjay Kalra,Cooperation2021,2010,1,
Benjamin Beit-Hallahmi,Religion2020,1995,5,
Stephen H. Webb,Religion2020,2010,5,
Frank Whaling,Religion2020,1975,5,
Azim F. Shariff,Religion2020,2015,13,
Arniika Kuusisto,Religion2020,2005,1,
Heather Walton,Religion2020,2000,4,
Herbert Benson,Religion2020,1970,5,
Yori Gidron,Religion2020,2005,1,
Christopher P. Scheitle,Religion2020,2015,35,
Gabriele Camera,Cooperation2021,2015,6,
Christopher Ocker,Religion2020,2000,3,
Paul Helm,Cooperation2021,1975,1,
Constant J. Mews,Religion2020,2005,4,
Ed Diener,Cooperation2021,2005,3,
René Descartes,Religion2020,1990,1,
Adrian Kent,Cooperation2021,2010,12,
Zehavit Gross,Religion2020,2005,3,
David F. Ford,Religion2020,2000,7,
Ann Gleig,Religion2020,2010,23,
Alexander Agadjanian,Religion2020,2015,14,
Scott Atran,Religion2020,2010,14,
W. R. Ward,Religion2020,1985,4,
Elizabeth Johnston Taylor,Cooperation2021,2000,1,
H Tristram Engelhardt,Religion2020,1975,1,
Seward Hiltner,Religion2020,1945,1,
R. Khari Brown,Religion2020,2005,4,
Franco Fabbro,Religion2020,2015,13,
Nick Stauner,Religion2020,2010,1,
David W. Johnson,Cooperation2021,1985,6,
Steve Bruce,Religion2020,1990,7,
Nancy T. Ammerman,Religion2020,1990,1,
Nirbhay N. Singh,Cooperation2021,2015,1,
Stephen H. Webb,Religion2020,1990,3,
Paul Vermeer,Religion2020,2015,14,
Keith J. Edwards,Cooperation2021,1980,3,
Hisao Ishibuchi,Cooperation2021,2010,16,
Constantin Klein,Religion2020,2015,23,
Mauro Zonta,Religion2020,2010,32,
David Martin,Religion2020,2005,8,
Ursula King,Religion2020,1990,9,
JOSEPH M. KITAGAWA,Religion2020,1965,16,
Irving Hexham,Religion2020,1995,8,
Jürgen Moltmann,Religion2020,1995,1,
Nancey Murphy,Religion2020,2015,10,
Philip Hefner,Religion2020,1990,6,
Jodok Troy,Religion2020,2010,22,
David Brown,Cooperation2021,1995,1,
Lloyd Strickland,Religion2020,2010,12,
Mathieu Terrier,Religion2020,2015,14,
Kristin Bezio,Religion2020,2010,1,
Ralph W. Hood Jr.,Religion2020,1995,3,
Tatjana Schnell,Religion2020,2005,6,
David Tracy,Religion2020,2010,3,
James Bissett Pratt,Religion2020,1920,10,
Thomas G. Plante,Religion2020,2000,32,
Andrew Wright,Religion2020,2010,7,
Rodney Stark,Religion2020,1965,12,
Bernard Spilka,Religion2020,2015,5,
Daniel L. Overmyer,Religion2020,1995,3,
Martyn Percy,Religion2020,2005,1,
Philip L. Quinn,Religion2020,2010,1,
Paul A. Djupe,Religion2020,2010,13,
Nick J. Watson,Religion2020,2010,7,
Brian Skyrms,Cooperation2021,1995,18,
Amnon Rapoport,Cooperation2021,1975,3,
Ellen L. Idler,Religion2020,2005,4,
Dermot Moran,Religion2020,2010,17,
Beth R. Crisp,Religion2020,1995,2,
Åke Hultkrantz,Religion2020,1990,3,
Carole M. Cusack,Religion2020,2015,49,
Robin I. M. Dunbar,Religion2020,2005,1,
David R. Williams,Cooperation2021,2010,2,
David C. Currow,Cooperation2021,2010,4,
Brian Collins,Religion2020,2010,3,
Jerry H Gill,Religion2020,2000,8,
Frank Cranmer,Cooperation2021,2005,2,
Oliver D. Crisp,Religion2020,2010,5,
Anthony Egan,Religion2020,2015,5,
Robert Hastings Nichols,Religion2020,1945,8,
Mary T Sweat,Religion2020,2015,3,
Charles Taliaferro,Religion2020,1990,2,
Teemu Taira,Religion2020,2005,4,
Brock Bastian,Cooperation2021,2010,4,
Susannah Cornwall,Religion2020,2005,2,
Jeff Astley,Religion2020,2015,12,
Benjamin Beit-Hallahmi,Religion2020,2015,11,
Kevin S. Masters,Religion2020,2000,1,
Yi-Yuan Tang,Religion2020,2015,18,
Jordan J. Ballor,Religion2020,2015,4,
Peter Kivisto,Cooperation2021,2005,3,
Graham Ward,Religion2020,2005,3,
Jennifer D. Allen,Religion2020,2015,12,
Pascal Boyer,Religion2020,2005,6,
Anna Halafoff,Cooperation2021,2015,1,
Arto Kallioniemi,Religion2020,2015,18,
Mia Lövheim,Religion2020,2015,18,
Andrew Buckser,Religion2020,2000,24,
Christoph Engel,Cooperation2021,2005,5,
Ellen F. Davis,Religion2020,2010,1,
Peter Byrne,Religion2020,1990,13,
Gerald Birney Smith,Religion2020,1915,16,
Hanna Kulagina-Stadnichenko,Religion2020,2015,14,
Christian Smith,Religion2020,2005,4,
Gert Pickel,Religion2020,2010,11,
Charles Taliaferro,Religion2020,2010,15,
Vern L. Bengtson,Religion2020,1975,3,
Frank Cranmer,Religion2020,2005,12,
William Adams Brown,Religion2020,1930,2,
Nancy T. Ammerman,Religion2020,2010,4,
F. LeRon Shults,Religion2020,2010,34,
Jeffrey K. Hadden,Religion2020,2000,1,
Ingeborg Rosario,Religion2020,2010,13,
Matthew J. Pereira,Religion2020,2010,22,
Aziz Sheikh,Religion2020,2010,20,
Darren E. Sherkat,Religion2020,1990,8,
Stephen Pattison,Religion2020,1995,1,
Ram A. Cnaan,Cooperation2021,1990,2,
Winthrop S. Hudson,Religion2020,1940,1,
Ronald Hutton,Religion2020,2010,8,
Frederick M. Burkle,Religion2020,2015,8,
Paul Harvey,Religion2020,2015,10,
Matthew Levering,Religion2020,2000,7,
Peter van der Veer,Religion2020,2005,7,
Ichiro Kawachi,Religion2020,2015,14,
Yolanda Dreyer,Religion2020,1995,2,
E E Griffith,Cooperation2021,1985,2,
Maria Harris,Religion2020,1985,14,
David Martin,Cooperation2021,2000,1,
Ninian Smart,Religion2020,2010,2,
Joseph B. Tamney,Religion2020,1990,8,
Samuel Stroope,Religion2020,2015,14,
Peter C. Phan,Religion2020,2010,13,
D. Z Phillips,Religion2020,1995,13,
Andrea C. Phelps,Religion2020,2015,2,
Paul A. Frewen,Religion2020,2005,3,
Lorenzo Cohen,Religion2020,2010,6,
John David Creswell,Religion2020,2005,8,
John Marenbon,Religion2020,2000,1,
Kelly Bulkeley,Religion2020,1990,1,
Jonathan Fox,Religion2020,2005,16,
John H. Shaver,Cooperation2021,2010,1,
Collium Banda,Religion2020,2015,16,
Kathryn Pitkin Derose,Religion2020,2000,6,
Harald Walach,Religion2020,2005,46,
Åke Hultkrantz,Religion2020,1970,4,
Catherine König-Pralong,Religion2020,2010,14,
Andrew Louth,Religion2020,1965,3,
Ann Loades,Religion2020,2010,2,
Gotthard Strohmaier,Religion2020,2010,21,
Daniel H. Levine,Religion2020,2000,1,
Lewis R. Rambo,Religion2020,1975,3,
William Schweiker,Religion2020,2015,6,
Vivienne Baumfield,Religion2020,2000,2,
Philip Hefner,CooperationAndReligion,1990,1,
Robert C. Fuller,Religion2020,1990,7,
John Bowker,Religion2020,2000,2,
Ted G. Jelen,Religion2020,2010,10,
Julia Downing,Cooperation2021,2010,6,
Jörg Rüpke,Religion2020,2005,10,
H Tristram Engelhardt,Religion2020,1995,16,
Mark W. Elliott,Religion2020,2015,22,
Richard H. Popkin,Religion2020,1965,2,
Louis Putterman,Cooperation2021,1990,1,
R. David Hayward,Religion2020,2015,19,
Susan E. Henking,Religion2020,1985,6,
Shailer Mathews,Religion2020,1890,2,
Wendy Cadge,Religion2020,2010,16,
Steve Bruce,Religion2020,2010,25,
C. Daniel Batson,Cooperation2021,2000,4,
F. LeRon Shults,CooperationAndReligion,2015,2,
Thijl Sunier,Religion2020,2005,1,
David A. Leeming,Religion2020,2010,38,
Maureen R. Benjamins,Religion2020,2005,24,
Allen E. Bergin,CooperationAndReligion,1995,1,
Birgit Meyer,Religion2020,2010,12,
Adam B. Cohen,CooperationAndReligion,2005,2,
Neal M Krause,CooperationAndReligion,1995,1,
Christoph Hauert,Cooperation2021,2000,11,
Jürgen Moltmann,Religion2020,2015,10,
Richard McElreath,Cooperation2021,2005,16,
Jacques Waardenburg,CooperationAndReligion,2005,1,
Marcia Grant,Religion2020,2015,3,
Celia Deane-Drummond,Religion2020,2015,11,
John Corrigan,Religion2020,1990,6,
Duncan B. Forrester,Religion2020,2015,13,
Paul Gifford,Religion2020,2005,3,
Russell Re Manning,Religion2020,2005,3,
J. D. Y. Peel,Religion2020,2000,3,
David Tracy,Religion2020,1990,3,
Paul Tillich,Religion2020,1950,4,
Robert C. Fuller,CooperationAndReligion,2015,1,
William Sweet,Religion2020,1995,6,
June Boyce-Tillman,Religion2020,2005,6,
Tinde van Andel,Religion2020,2010,14,
Bernard Spilka,Religion2020,1995,3,
Joseph H. Fichter,Religion2020,1970,2,
Veit Bader,Religion2020,2005,6,
Harvey Whitehouse,CooperationAndReligion,2010,2,
Richard S. Ascough,Religion2020,1990,1,
Armin W. Geertz,Religion2020,2010,18,
John Wilson,Religion2020,2015,2,
Geir Skeie,Religion2020,1995,2,
Keith Robbins,Religion2020,1980,4,
Tony Walter,Religion2020,2005,4,
Jeremy E. Uecker,Cooperation2021,2015,1,
George A. Coe,Religion2020,1930,2,
David Jasper,Religion2020,1990,11,
Luc Deliens,Religion2020,2000,4,
Bron Taylor,Religion2020,2000,3,
Dale F. Eickelman,Religion2020,2005,2,
Yinka Olomojobi,Cooperation2021,2015,2,
Fergus Kerr,Religion2020,1990,1,
LILY KONG,Religion2020,2005,7,
Peter Lake,Religion2020,2000,3,
Manfred Milinski,Cooperation2021,2000,5,
Ian Olver,Religion2020,2000,2,
P. Scott Richards,Religion2020,1990,12,
Daniel Balliet,Cooperation2021,2010,11,
David M. Wulff,Religion2020,1995,6,
Andrew R. Lewis,Religion2020,2015,20,
Mark Silk,Religion2020,2005,16,
Leonard J. Biallas,Religion2020,1980,5,
John Swinton,Religion2020,2015,13,
Kevin McCabe,Cooperation2021,2000,12,
Adrian Furnham,Cooperation2021,1995,1,
Karl Sigmund,Cooperation2021,2010,11,
Ryan LaMothe,Cooperation2021,2010,3,
Barbara Keller,Religion2020,2000,1,
Joshua N. Hook,Religion2020,2010,29,
Justin L. Barrett,Religion2020,2000,7,
Melissa Raphael,Religion2020,2005,8,
Quentin D. Atkinson,CooperationAndReligion,2010,4,
Allen E. Bergin,Religion2020,1990,8,
Neal M Krause,Religion2020,1990,3,
Laurel Kendall,Religion2020,1990,3,
Susan J. Palmer,Religion2020,1990,8,
James L. Guth,Religion2020,1990,14,
Charles Y. Glock,Religion2020,2015,1,
Ninian Smart,Religion2020,1955,1,
Mary Grey,Religion2020,1990,10,
Zhen Wang,Cooperation2021,2015,53,
Patrick Collinson,Religion2020,2005,2,
Yan Chen,Cooperation2021,2015,9,
JOHN D. CAPUTO,Religion2020,2005,8,
Mark R. McMinn,Religion2020,2005,5,
Kamaldeep Bhui,Religion2020,1995,4,
John T. Ford,Religion2020,2000,6,
Ismail Suardi Wekke,Religion2020,2010,3,
George Pattison,Religion2020,1990,4,
Linda Woodhead,Religion2020,2010,15,
Mandy Robbins,Religion2020,2015,16,
Christopher Schabel,Religion2020,2005,1,
Leonardo Becchetti,Cooperation2021,2010,10,
Dewey D. Wallace,Religion2020,1985,2,
Alexandra Walsham,Religion2020,2000,3,
Keith Ward,Religion2020,1985,3,
Ram A. Cnaan,Religion2020,2005,8,
Albert Ellis,Religion2020,1995,3,
Holger Cramer,Religion2020,2015,60,
Joshua R. Farris,Religion2020,2015,20,
Fiona Godlee,Cooperation2021,2015,21,
Christopher R. Cotter,Religion2020,2010,2,
KIERAN FLANAGAN,Religion2020,2005,7,
Ivan Christov,Religion2020,2015,3,
James F. Keenan,Religion2020,1990,2,
Wyatt MacGaffey,Religion2020,1990,7,
Charles Y. Glock,Cooperation2021,1955,1,
James V. Spickard,Religion2020,1990,6,
John E. Smith,Religion2020,1995,3,
Ian T. Baldwin,Cooperation2021,2015,6,
Simplice A. Asongu,Religion2020,2010,15,
Christopher Ocker,Cooperation2021,1995,3,
Michael I. Posner,Religion2020,2005,1,
John Hick,Religion2020,1960,6,
Wade C. Rowatt,Religion2020,2000,4,
PASQUALE PORRO,Religion2020,2000,2,
Anna Dreber,CooperationAndReligion,2010,4,
Eugene V. Gallagher,Religion2020,1980,3,
Roland Robertson,Religion2020,1975,7,
C. N. L. Brooke,Religion2020,1980,3,
Malcolm Brown,Religion2020,2015,2,
Anders Klostergaard Petersen,CooperationAndReligion,2015,1,
Michael Pye,Religion2020,2005,5,
Linda E. Carlson,Religion2020,2015,30,
Philip Clayton,Religion2020,2000,3,
David Voas,Religion2020,2010,12,
Leslie J. Francis,Cooperation2021,2010,3,
Kundharu Saddhono,Religion2020,2015,15,
Kim Knott,Religion2020,1990,6,
Merold Westphal,Religion2020,1965,2,
Meredith B. McGuire,Religion2020,2000,2,
Edward R. Canda,Religion2020,2005,11,
Kate Miriam Loewenthal,Religion2020,2005,2,
Sheridan Gilley,Religion2020,1990,2,
Todd W. Hall,Religion2020,2005,24,
Gail Ironson,Religion2020,2015,39,
Liz Gwyther,Religion2020,2015,2,
Oliver Leaman,Religion2020,2015,17,
Chris Baker,Religion2020,2000,3,
DANIEL PATTE,Religion2020,1975,1,
Peter C. Hill,Religion2020,1990,1,
Adam Possamai,Religion2020,2000,2,
Thomas Ostermann,Religion2020,2010,5,
Patrick Sherry,Religion2020,2000,7,
David B. Burrell,Religion2020,2015,2,
Don Schweitzer,Religion2020,1990,1,
Andrew M. Greeley,Religion2020,2015,8,
Mark van Vugt,Cooperation2021,2000,7,
Paul A. M. Van Lange,Cooperation2021,1990,3,
William H. Swatos,Religion2020,1975,1,
Carl E. Thoresen,Religion2020,1995,8,
Robert N. Bellah,Religion2020,2005,12,
Birgit Meyer,Religion2020,1995,3,
Thomas J. Csordas,Religion2020,2005,5,
Edward R. Canda,Religion2020,2000,4,
Shirley Telles,Religion2020,2015,12,
Joshua Furnal,Religion2020,2015,20,
Fergus Kerr,Religion2020,2005,3,
Manfred Milinski,Cooperation2021,2015,12,
Euan Cameron,Religion2020,2005,2,
Vivienne Baumfield,Religion2020,2005,6,
Bruce V. Foltz,Religion2020,2005,1,
Philip Schwadel,Religion2020,2005,1,
Joel Cabrita,Religion2020,2010,12,
Donald Wiebe,Religion2020,2000,6,
Kathleen Galek,Religion2020,2015,1,
John R. Bowen,Religion2020,2015,5,
Ramón Alonso-Sanz,Cooperation2021,2010,2,
David M. Wulff,Religion2020,2010,8,
Teresa Obolevitch,Religion2020,2010,2,
Ji Quan,Cooperation2021,2010,2,
Peter C. Phan,Religion2020,2005,7,
Gregory Baum,Religion2020,2005,3,
Mariano Barbato,Religion2020,2010,18,
Richard L. Gorsuch,Religion2020,1995,5,
Shane Sinclair,Cooperation2021,2005,1,
Amy B. Wachholtz,Religion2020,2010,14,
Claude-Hélène Mayer,Religion2020,2010,15,
Aasim I. Padela,Religion2020,2015,34,
Helen Rose Ebaugh,Religion2020,1990,4,
Peter Hopkins,Religion2020,2005,4,
Ronald Labonte,Cooperation2021,2010,5,
Anna Halafoff,Cooperation2021,2010,2,
C.W. Young,Cooperation2021,1985,14,
James Wm. McClendon,Religion2020,1965,20,
Arthur Stephen McGrade,Religion2020,1975,1,
Claudia Fichtel,Cooperation2021,2005,2,
Phillip E. Hammond,Religion2020,2000,3,
Dion Smyth,Religion2020,2000,2,
Eamon Duffy,Religion2020,2000,4,
Hans-Georg Ziebertz,Religion2020,1990,4,
Harold G. Koenig,Religion2020,2000,52,
Arvind Sharma,Religion2020,1985,3,
Andrew Buckser,Religion2020,1995,20,
Leslie Francis,Religion2020,1975,4,
John T. Ford,Religion2020,2005,24,
Peter Byrne,Religion2020,1985,10,
Jeppe Sinding Jensen,Religion2020,1990,3,
Daniel H. Grossoehme,Religion2020,2010,9,
C. N. L. Brooke,Religion2020,1975,3,
Dirk Hutsebaut,Religion2020,1990,1,
David Morgan,Religion2020,2010,30,
Yolanda Dreyer,Religion2020,2000,10,
John Maltby,CooperationAndReligion,2015,1,
Edo Shonin,Religion2020,2010,22,
H. Newton Malony,Religion2020,2005,2,
Anatol Rapoport,Cooperation2021,1970,2,
Dick Anthony,Religion2020,1975,18,
Julia Ipgrave,Cooperation2021,2010,1,
Charles H. Lippy,Religion2020,1990,6,
Maryam Dilmaghani,Religion2020,2015,22,
Rhys H. Williams,Religion2020,2010,9,
Lindsay B. Carey,Religion2020,2015,16,
Marc Galanter,Religion2020,2005,17,
Graham Ward,Religion2020,2010,6,
Phillip R. Shaver,Cooperation2021,1995,1,
Aziz Sheikh,Religion2020,2005,20,
Gary D. Bouma,Religion2020,2005,6,
E. L. Mascall,Religion2020,1960,3,
Bradford McCall,Religion2020,2015,12,
Joyce Ann Mercer,Religion2020,2000,5,
Li Zhang,Cooperation2021,2015,10,
Michela Balconi,Religion2020,2015,18,
INGOLF U. DALFERTH,Religion2020,2005,9,
Carl Sterkens,Religion2020,2005,10,
Simplice A. Asongu,Cooperation2021,2015,5,
Richard McElreath,Religion2020,2010,4,
Iain T. Benson,Religion2020,2010,14,
Sanford Nidich,Religion2020,2015,9,
Gert Pickel,Religion2020,2015,27,
Peter Harrison,Religion2020,1985,1,
Rany M. Salem,Religion2020,2010,22,
John Scheid,Religion2020,1985,2,
Ismail Suardi Wekke,Cooperation2021,2015,2,
Robert Joseph Taylor,Religion2020,2010,16,
W. Muss-Arnolt,Religion2020,1895,22,
Michael Pye,Religion2020,1970,5,
S. Brent Plate,Religion2020,2015,4,
Pablo Ramírez,Religion2020,2015,24,
Romy Lauche,Religion2020,2015,30,
Heidemarie Kremer,Religion2020,2010,18,
Angelos Chaniotis,Religion2020,2010,22,
Janusz Salamon,Religion2020,2010,27,
Amy L. Ai,Religion2020,1995,1,
C. Robert Cloninger,Cooperation2021,2010,12,
Nancy T. Ammerman,Religion2020,2005,5,
Vanda Aramavičiūtė,Religion2020,2015,13,
Kate Miriam Loewenthal,Religion2020,2010,8,
James Davis,Religion2020,1980,5,
Jürgen Moltmann,Religion2020,1990,2,
Amos Yong,Religion2020,2005,54,
Vincent Goossaert,Religion2020,1970,2,
Elaine Graham,Religion2020,1995,5,
Shun Kurokawa,Cooperation2021,2005,1,
David Carr,Religion2020,2000,7,
Edwin S. Gaustad,Religion2020,2000,3,
Niels Henrik Gregersen,Religion2020,2010,24,
Steven J. Sandage,Religion2020,1995,2,
James V. Spickard,Religion2020,1985,3,
Acharya Balkrishna,Religion2020,2010,20,
Michael Tomasello,Cooperation2021,1990,2,
Elizabeth Johnston Taylor,Religion2020,2015,14,
Robert S. Ellwood,Religion2020,1965,1,
Teemu Taira,Religion2020,2010,16,
Maria Bigoni,Cooperation2021,2015,3,
Peter W. Williams,Religion2020,1990,9,
Azim F. Shariff,Cooperation2021,2010,4,
Heather Walton,Religion2020,1995,3,
Keith Ward,Religion2020,1990,3,
B. Alan Wallace,Religion2020,2010,27,
J. Gwyn Griffiths,Religion2020,1945,1,
Hubert Knoblauch,Religion2020,2010,14,
Andrew Louth,Religion2020,1970,3,
Yan Liu,Cooperation2021,2015,9,
Daniel H. Levine,Religion2020,2005,1,
Matthew Clarke,Cooperation2021,2015,2,
François Taddei,Cooperation2021,2015,12,
Andy Gardner,Cooperation2021,2005,17,
Gail Ironson,Religion2020,2000,3,
Heather Shipley,Religion2020,2015,13,
Graham Little,Religion2020,2015,14,
Bhikhu Parekh,Religion2020,1995,6,
Ralph W. Hood Jr.,Religion2020,2000,3,
D. Z Phillips,Religion2020,1980,3,
James Bissett Pratt,Religion2020,1925,2,
John T. Jost,Religion2020,2005,3,
Thomas G. Plante,Religion2020,2005,56,
Nicholas A. Christakis,Religion2020,2000,2,
Carole M. Cusack,Religion2020,2010,24,
John Wilson,Cooperation2021,1975,2,
Maroš Servátka,Cooperation2021,2010,21,
Keith Robbins,Religion2020,2015,2,
Andrew M. Greeley,Religion2020,2000,4,
Anthony J. Blasi,Religion2020,1995,5,
Francis X. Clooney,Religion2020,2010,9,
Ram A. Cnaan,Religion2020,1990,1,
Luo-Luo Jiang,Cooperation2021,2010,27,
E. Brooks Holifield,Religion2020,2010,2,
Dominiek D. Coates,Religion2020,2010,14,
Johannes A. Van der Ven,Religion2020,1990,3,
Sanni Yaya,Religion2020,2015,14,
Robert H. Schneider,Religion2020,2000,7,
Louis Dupré,Cooperation2021,1990,2,
John Eric Killinger,Religion2020,2010,15,
Charles Hartshorne,Religion2020,1975,2,
David Novak,Religion2020,2005,4,
Christopher Schabel,Religion2020,2010,38,
Bruce Lincoln,Religion2020,2005,5,
Shirley Jackson Case,Religion2020,1910,9,
Cok Bakker,Religion2020,2005,2,
Ninian Smart,Religion2020,1960,8,
Richard Sawatzky,Religion2020,2010,9,
Bert Roebben,Religion2020,2000,5,
William Sweet,Religion2020,2010,9,
Joseph H. Fichter,Religion2020,1985,1,
J. D. Y. Peel,Religion2020,2015,2,
Birger A. Pearson,Religion2020,1980,4,
Ted G. Jelen,Religion2020,2005,8,
Myrna M. Weissman,Religion2020,1975,2,
Mark Chapman,Religion2020,2015,12,
F. LeRon Shults,Religion2020,1995,4,
Douglas A. MacDonald,Religion2020,2010,22,
Jacques Launay,Cooperation2021,2010,2,
Volkhard Krech,Religion2020,2015,13,
Alexandra Walsham,Religion2020,2015,11,
R. Stephen Warner,Religion2020,1990,4,
Kenneth D. Wald,Religion2020,1985,12,
Lorne L. Dawson,Religion2020,2010,6,
Jacob Neusner,Religion2020,1960,1,
Tom Lenaerts,Cooperation2021,2010,12,
Richard Cross,Religion2020,2015,8,
Daniel Callahan,Cooperation2021,1975,3,
Philipp C. Wichardt,Cooperation2021,2005,8,
Albrecht Classen,Religion2020,1990,1,
Barbara Hargrove,Religion2020,1980,12,
John Milbank,Religion2020,2010,16,
Mariano Barbato,Cooperation2021,2010,2,
Antonio Ríos,CooperationAndReligion,2015,23,
Andrew L. Whitehead,Religion2020,2015,18,
Jeffrey Haynes,CooperationAndReligion,2015,3,
Christoph Jacobs,Religion2020,2010,2,
Sam P. Brown,Cooperation2021,2015,10,
Scott Atran,Religion2020,2005,8,
Will M. Gervais,Religion2020,2015,13,
Ana V. Diez Roux,Cooperation2021,2010,12,
W. R. Ward,Religion2020,1980,4,
H Tristram Engelhardt,Cooperation2021,2005,1,
Philip Clayton,Religion2020,2015,6,
Robert Merrihew Adams,Religion2020,1975,4,
Sergej Flere,Religion2020,2010,9,
Amos N. Wilder,Religion2020,1975,3,
Frank D. Fincham,Religion2020,2010,13,
James H. Leuba,Religion2020,1910,11,
Emyr Williams,Religion2020,2015,4,
Daniel Houser,Cooperation2021,2015,6,
Wei Li,Cooperation2021,2015,15,
Matthew Levering,Religion2020,2015,10,
Allen D. Hertzke,Cooperation2021,1995,2,
David Carr,Cooperation2021,2000,1,
Michael E. McCullough,Religion2020,2015,5,
Randall Balmer,Religion2020,1990,2,
Lewis S. Ford,Religion2020,2010,1,
Frederick M. Burkle,Cooperation2021,2000,12,
Patricia M. Davidson,Religion2020,2015,32,
Michael Argyle,Cooperation2021,1985,1,
Mark W. Elliott,Religion2020,2010,10,
Stuart A. West,Cooperation2021,2010,29,
Dean Tjosvold,Cooperation2021,2005,6,
William Desmond,Religion2020,1985,3,
Enzo Pace,Religion2020,2015,10,
Kate Miriam Loewenthal,Cooperation2021,2010,1,
Leslie J. Francis,Religion2020,2005,51,
Lei Shi,Religion2020,2015,1,
Winthrop S. Hudson,Religion2020,1955,2,
C. Robert Cloninger,Religion2020,2010,8,
Meredith B. McGuire,Cooperation2021,1980,1,
Donald Capps,Religion2020,1980,5,
Ryan LaMothe,Cooperation2021,1995,1,
William P. Alston,Religion2020,1985,4,
Christopher F. Silver,Religion2020,2015,26,
Paul F. Knitter,Religion2020,2010,8,
Farr A. Curlin,Religion2020,2000,2,
Todd M. Johnson,Religion2020,2015,16,
Barbara Keller,Religion2020,2015,26,
John Maltby,Cooperation2021,2015,1,
Ann Loades,Religion2020,1985,3,
Massimo Leone,Religion2020,2015,24,
Loren D. Marks,Religion2020,1995,1,
Joan E. Strassmann,Cooperation2021,2010,17,
Satinder Dhiman,Religion2020,2015,18,
Kevin Schilbrack,Religion2020,1990,2,
George Handzo,Religion2020,2005,4,
ROBERT M. GRANT,Religion2020,1985,6,
Brian Skyrms,Cooperation2021,2000,9,
J. E. Wood,Religion2020,1970,6,
Rodney Stark,Cooperation2021,1995,1,
Antoine Lutz,Religion2020,2000,1,
Robert Hastings Nichols,Religion2020,1940,12,
Jeremy E. Uecker,Religion2020,2015,17,
Aaron W. Hughes,Religion2020,2015,6,
Ronald Hutton,Religion2020,1985,2,
C. Robert Cloninger,Religion2020,1970,4,
David B Larson,Religion2020,1995,22,
Nicolas Baumard,CooperationAndReligion,2010,2,
Ian A. McFarland,Religion2020,2010,19,
Elvira Wakelnig,Religion2020,2005,1,
John Fisher,Religion2020,1970,2,
Lisa Sowle Cahill,Religion2020,1995,4,
Ronald L. Grimes,Religion2020,1980,4,
Christopher P. Scheitle,CooperationAndReligion,2010,1,
Christoph Stenschke,Religion2020,2015,14,
Mirjam Künkler,Religion2020,2015,22,
Aidan Nichols,Religion2020,2015,4,
Antonio Ríos,Cooperation2021,2015,25,
Antonio Ríos,Religion2020,2015,24,
Aru Narayanasamy,Religion2020,2010,10,
Linda Ross,Religion2020,2010,6,
Howard Bauchner,Cooperation2021,2015,22,
Adam Dinham,Religion2020,2010,6,
Peter-Ben Smit,Religion2020,2010,9,
Sumner B. Twiss,Religion2020,1995,8,
Michael Argyle,Religion2020,2005,3,
Malcolm Brown,Religion2020,2000,6,
Joseph B. Tamney,Religion2020,1965,3,
Hisashi Ohtsuki,Cooperation2021,2010,10,
Dewey D. Wallace,Religion2020,2000,3,
Azim F. Shariff,Religion2020,2010,8,
Amy Adamczyk,Religion2020,2015,12,
Richard J. Davidson,Religion2020,2005,11,
Fiona Timmins,Cooperation2021,2015,1,
Ram A. Cnaan,Cooperation2021,2015,2,
Alejandro Frigerio,Religion2020,2005,10,
Peter Kaufman,Religion2020,2015,28,
John Hedley Brooke,Religion2020,2010,8,
JOSEPH M. KITAGAWA,Religion2020,1980,2,
James F. Keenan,Religion2020,2005,8,
Paul D. Molnar,Religion2020,1990,2,
Bernard McGINN,Religion2020,1980,7,
Lodovico Balducci,Religion2020,2000,4,
Maxwell V. Rainforth,Religion2020,2015,2,
Ed Diener,Religion2020,1980,3,
Lewis R. Rambo,Religion2020,1990,3,
Sarah F. Brosnan,Cooperation2021,2015,24,
James J. Buckley,Religion2020,2005,2,
Daniel Philpott,Religion2020,2015,12,
Philip L. Quinn,Religion2020,1975,2,
Gregory Baum,Religion2020,1965,1,
Karl Sigmund,Cooperation2021,1985,5,
David Tracy,Religion2020,1975,8,
Seward Hiltner,Cooperation2021,1980,1,
George A. Coe,Religion2020,1905,1,
Shailer Mathews,Religion2020,1905,3,
Eleanor Nesbitt,Religion2020,1990,6,
Rex Ahdar,Religion2020,2015,22,
Mircea Eliade,Religion2020,1960,4,
Pierre-Yves Brandt,Religion2020,2005,8,
James M. Day,Religion2020,2000,4,
E. Brooks Holifield,Religion2020,1995,5,
Jonathan Jackson,Cooperation2021,2005,5,
David W. Lankshear,Religion2020,2015,18,
Paul Wink,Religion2020,2015,3,
Ben J. Hatchwell,Cooperation2021,2015,7,
Duncan B. Forrester,Religion2020,1990,2,
John T. Ford,Religion2020,1965,1,
Jon Stewart,Religion2020,2010,1,
Andreas Glöckner,Cooperation2021,2015,16,
William Gibson,Religion2020,2005,4,
Ursula King,Religion2020,1985,5,
J. D. Y. Peel,Religion2020,1975,2,
John Warwick Montgomery,Religion2020,2010,24,
Jorge M. Pacheco,Cooperation2021,2005,12,
Terrence W. Tilley,Religion2020,2005,7,
Michael E. McCullough,Cooperation2021,2015,8,
Gustav J. Dobos,Religion2020,2015,12,
Myrna M. Weissman,Cooperation2021,1970,2,
Ryan LaMothe,Religion2020,1995,4,
Susan J. Palmer,Religion2020,2005,6,
Michael J. Reiss,Religion2020,1995,2,
Jean Bethke Elshtain,Religion2020,1985,2,
Simon Dein,Religion2020,1995,10,
Winthrop S. Hudson,Religion2020,2015,1,
Steve Bruce,Religion2020,1985,13,
Alexander Agadjanian,Religion2020,2010,8,
Whitney A. Bauman,Religion2020,2005,2,
Kevin S. Masters,Religion2020,1985,2,
Pamela Ebstyne King,Religion2020,2005,5,
Khusnul Khotimah,Cooperation2021,2015,4,
Colin Gunton,Religion2020,1995,14,
Oliver Leaman,Religion2020,1995,2,
Peter L. Berger,Religion2020,1960,4,
Mario Fernando Prieto Peres,Religion2020,2010,12,
Jerry Z. Park,Religion2020,2015,12,
Tatsuya Morita,Religion2020,2010,16,
Lisa Miller,Religion2020,2010,20,
Edward P. Shafranske,Religion2020,2010,21,
Risto Saarinen,Religion2020,2010,4,
Symeon Papavassiliou,Cooperation2021,2010,2,
David R. Hodge,Religion2020,2005,29,
Toshio Yamagishi,Cooperation2021,2005,18,
David Tracy,Religion2020,2015,3,
Elisabeth Arweck,Religion2020,2015,13,
Peter Kivisto,Religion2020,2000,12,
Michael Welker,Religion2020,2005,4,
Kathryn Pitkin Derose,CooperationAndReligion,2000,3,
Eirini Eleni Tsiropoulou,Cooperation2021,2015,13,
Agnes Imhof,Religion2020,2000,5,
Kenneth Surin,Religion2020,1990,1,
Lei Yu,Religion2020,2010,13,
Jerry H Gill,Religion2020,2015,8,
Michael Gurven,Cooperation2021,2015,12,
Rosalind I. J. Hackett,Religion2020,1990,18,
Stanley Hauerwas,Religion2020,1985,4,
S. Mark Heim,Religion2020,1990,3,
Johann-Albrecht Meylahn,Religion2020,2000,1,
Wade Clark Roof,Religion2020,1995,16,
P. Scott Richards,Religion2020,2015,16,
Lewis S. Ford,Religion2020,1970,7,
Niels Henrik Gregersen,Religion2020,1995,4,
Stephen J. Stein,Religion2020,1990,6,
Louis Dupré,Religion2020,1990,6,
Neil Ormerod,Religion2020,2000,7,
Pablo Ramírez,Cooperation2021,2005,6,
Matthias Sutter,Cooperation2021,2005,12,
Ivan Strenski,Religion2020,2000,7,
Tami Borneman,Religion2020,1995,2,
David Robinson,Religion2020,2005,2,
Hans Schwarz,Religion2020,2005,2,
Scott Atran,Cooperation2021,1995,4,
Walter Brueggemann,Religion2020,2015,2,
Michael J. Wade,Cooperation2021,2000,3,
Julia Ipgrave,Religion2020,2010,7,
Kaveh Madani,Cooperation2021,2005,2,
Joyce J. Fitzpatrick,Religion2020,1995,2,
C. Daniel Batson,Religion2020,1990,14,
Benjamin Beit-Hallahmi,CooperationAndReligion,2010,1,
Geir Skeie,Religion2020,2010,12,
Anatolii M. Kolodnyi,Cooperation2021,1995,1,
Maarten J. F. M. Hoenen,Religion2020,2010,30,
W. D. Hudson,Religion2020,1985,2,
Robert Jackson,Religion2020,1985,2,
Jacques Waardenburg,Religion2020,1970,4,
Maurice Eisenbruch,Religion2020,1990,6,
DAVID GRUMETT,Religion2020,2005,22,
Fergus Kerr,Religion2020,1965,3,
Guy G. Stroumsa,Religion2020,1995,3,
Kai Nielsen,Religion2020,1980,32,
Manfred Milinski,Cooperation2021,1975,1,
Klaus Zuberbühler,Cooperation2021,2000,4,
Philip Hefner,Cooperation2021,1995,1,
Robert W. Hefner,Religion2020,2010,7,
David Brown,Cooperation2021,2010,1,
Chris Shilling,Religion2020,2005,3,
Rosaria Conte,Cooperation2021,2005,6,
Larry VandeCreek,Religion2020,2000,10,
Daniel L. Overmyer,Religion2020,2010,1,
Ted Peters,Religion2020,1985,2,
Louis Putterman,Cooperation2021,2005,7,
Christopher T. Begg,Religion2020,2015,16,
Aurélien Robert,Religion2020,2010,18,
Doug P. VanderLaan,Cooperation2021,2005,4,
Paul A. M. Van Lange,Cooperation2021,2015,11,
WILLIAM F. QUILLIAN,Religion2020,1945,1,
William H. Swatos,Religion2020,2000,10,
Steven Stack,Religion2020,2015,7,
Mingchu Li,Cooperation2021,2010,4,
Peter Marshall,Religion2020,1995,2,
Sara W Lazar,Religion2020,2005,2,
Christopher Alan Lewis,Religion2020,1995,24,
W. Brad Johnson,Cooperation2021,2010,2,
Amnon Rapoport,Cooperation2021,1990,12,
Phillip R. Shaver,Religion2020,1995,4,
Simon Coleman,Religion2020,2010,14,
Rufus A. Johnstone,Cooperation2021,2015,4,
Yoh Iwasa,Cooperation2021,2010,6,
B Japsen,Religion2020,1995,36,
Sarah Coakley,Cooperation2021,2010,9,
Linjie Liu,Cooperation2021,2015,22,
Taneli Kukkonen,Religion2020,2015,5,
Jing Liu,Cooperation2021,2015,17,
John Kelsay,Religion2020,2015,3,
Diarmaid MacCulloch,Religion2020,1990,4,
Keith E. Yandell,Religion2020,2010,2,
Chris Baker,Religion2020,1975,1,
Samuel L. Perry,Cooperation2021,2015,1,
Martin A. Nowak,Cooperation2021,2000,9,
Seward Hiltner,Religion2020,1960,6,
Patrick Sherry,Religion2020,1975,2,
Leslie Francis,Religion2020,2015,4,
Simo Knuuttila,Religion2020,2000,4,
Robert S. Wilson,Religion2020,2000,2,
Emma Wild-Wood,Religion2020,2015,16,
Elizabeth Johnston Taylor,Cooperation2021,2015,1,
Mujiburrahman Mujiburrahman,Religion2020,2010,3,
Elad Schiff,Religion2020,2015,8,
Ann Taves,Religion2020,1985,3,
Meredith B. McGuire,Religion2020,1975,2,
Paul Badham,Religion2020,2010,4,
Cheryl L. Holt,Religion2020,2005,13,
Deepak Chopra,Religion2020,2010,3,
Maureen R. Benjamins,Religion2020,2000,6,
Stephen Pattison,Religion2020,1990,2,
Albert Ellis,Religion2020,2000,12,
George A. Coe,Religion2020,1945,3,
John Marenbon,Religion2020,1995,1,
Donald Capps,Cooperation2021,2005,2,
Anthony Egan,Religion2020,2010,25,
Irving Hexham,Religion2020,2000,8,
Peter van der Veer,Religion2020,2000,5,
Wolfgang Vondey,Religion2020,2015,14,
Aya Hagishima,Cooperation2021,2015,1,
John Corrigan,Religion2020,1985,6,
José A. Cuesta,Cooperation2021,2010,16,
Steven Engler,Cooperation2021,2000,1,
Brendan Carmody,Religion2020,1985,3,
Maria Harris,Religion2020,1980,6,
Jamin Halberstadt,Cooperation2021,2015,4,
Linda Woodhead,Religion2020,1995,4,
D. Z Phillips,Religion2020,1965,4,
Jodok Troy,Religion2020,2015,14,
Robert C. Fuller,Religion2020,1995,12,
David G. Bromley,Religion2020,2015,1,
Andrew M. Colman,Cooperation2021,1995,2,
Justin L. Barrett,Religion2020,2015,18,
Valerie Michaelson,Religion2020,2015,18,
Leonard J. Biallas,Religion2020,1995,2,
Kevin J. Flannelly,Cooperation2021,1975,2,
Philip L. Quinn,Religion2020,2015,3,
Brian Patrick McGuire,Religion2020,2010,16,
Kim Knott,Religion2020,2005,16,
Gary D. Bouma,Religion2020,1970,3,
David W. Johnson,Cooperation2021,1990,3,
Lorenzo Cohen,Religion2020,2015,25,
Paul A. Djupe,Religion2020,2005,11,
Ellen L. Idler,Religion2020,2000,4,
John E. Smith,Religion2020,1990,2,
Constant J. Mews,Religion2020,2010,24,
Martha Frederiks,Religion2020,2015,2,
H. W. S.,Religion2020,1925,1,
Patrick Sherry,Religion2020,2015,1,
Mark R. McMinn,Religion2020,1980,1,
Christoph Engel,Cooperation2021,2000,1,
Steve Fuller,Religion2020,2010,16,
José Filipe Silva,Religion2020,2010,16,
John Wolffe,Religion2020,2000,5,
Bernard Spilka,Religion2020,2010,23,
H Tristram Engelhardt,Religion2020,1980,2,
R. David Hayward,Religion2020,2010,43,
Christian Smith,Religion2020,2000,14,
Kristene Diggins,Religion2020,2015,6,
Betty R. Ferrell,Religion2020,1990,3,
Mark Hill,Religion2020,2005,6,
Åke Hultkrantz,Religion2020,1985,2,
Raphael Jospe,Religion2020,2010,19,
Kathryn Lofton,Religion2020,2005,12,
Luc Deliens,Religion2020,2005,20,
Massimo Leone,Religion2020,1975,3,
David W. K. Yeung,Cooperation2021,2005,3,
John Fisher,Religion2020,1990,2,
Christopher Ocker,Religion2020,1995,3,
Thomas Quartier,Religion2020,2010,14,
Curtis W. Hart,Religion2020,1990,1,
Paul Harvey,Religion2020,2010,5,
Wyatt MacGaffey,Religion2020,2005,1,
Stephen E. Lahey,Religion2020,2010,65,
Seward Hiltner,Religion2020,1940,3,
Lori G. Beaman,Religion2020,2000,3,
Johan Buitendag,Religion2020,2000,4,
David F. Ford,Religion2020,1995,2,
Ellen F. Davis,Religion2020,2015,25,
Todd W. Hall,Religion2020,2000,16,
Deepak Chopra,Religion2020,1990,6,
George Aaron Barton,Religion2020,1930,4,
Jordan J. Ballor,Religion2020,2010,16,
Michiel van Elk,Religion2020,2010,1,
John Hick,Religion2020,1975,2,
Ichiro Kawachi,Religion2020,2010,3,
Naser Aghababaei,Religion2020,2015,6,
Jeff Astley,Religion2020,2010,8,
C. Daniel Batson,Religion2020,1970,4,
Melissa Raphael,Religion2020,2010,4,
James T. Robinson,Religion2020,2010,28,
J. L. SCHELLENBERG,Religion2020,2015,20,
Anatolii M. Kolodnyi,Religion2020,2010,30,
Fraser Watts,Religion2020,2015,17,
Paul J. Watson,Cooperation2021,2005,1,
Jeffrey S. Levin,Cooperation2021,1980,2,
John P. Bartkowski,Religion2020,1995,6,
Anna Fedele,Religion2020,2005,6,
Maurice Eisenbruch,Religion2020,2010,6,
Gavin Flood,Religion2020,2000,12,
Robert H. Schneider,Religion2020,2015,3,
Philipp M. Altrock,Cooperation2021,2015,16,
Ivan Christov,Religion2020,2010,35,
Nancey Murphy,Religion2020,2010,16,
Mauro Zonta,Religion2020,2005,2,
James R. Lewis,Religion2020,1990,1,
Ralph Del Colle,Religion2020,1995,6,
Ruth Feldman,Cooperation2021,2005,3,
Sana Loue,Religion2020,2005,2,
Gerald Birney Smith,Religion2020,1910,19,
Asonzeh Ukah,Religion2020,2015,13,
Charles Taliaferro,Religion2020,1995,3,
Marc A. Musick,Religion2020,2000,36,
Brock Bastian,Cooperation2021,2015,12,
William Adams Brown,Religion2020,1925,2,
Nicolas Baumard,Cooperation2021,2010,14,
Kevin S. Masters,Religion2020,2005,8,
Mark van Vugt,Cooperation2021,2005,6,
Paul A. M. Van Lange,Cooperation2021,1995,7,
William H. Swatos,Religion2020,1980,4,
Daniel L. Overmyer,Religion2020,1990,4,
James H. Leuba,Religion2020,2010,1,
David W. Johnson,Cooperation2021,2010,3,
David W. Lankshear,Cooperation2021,2015,3,
Myrna M. Weissman,Cooperation2021,1990,1,
David Lukoff,Religion2020,1995,12,
Dániel Z. Kádár,Religion2020,2015,60,
Nancey Murphy,Religion2020,1990,4,
Jean-Paul Willaime,Religion2020,2005,9,
Ying-shih Yü,Religion2020,2015,24,
Qian Zhang,Cooperation2021,2010,10,
Peter Cumper,Religion2020,2010,10,
James R. Lewis,Cooperation2021,2015,1,
Bryan D. Spinks,Religion2020,2015,7,
Simplice A. Asongu,Religion2020,2015,50,
Simon Goldhill,Religion2020,1985,3,
H Tristram Engelhardt,Religion2020,2000,12,
Daniel L. Overmyer,Religion2020,1970,1,
Anders Klostergaard Petersen,Religion2020,2000,2,
Philip Hefner,Religion2020,1995,7,
Birthe Loa Knizek,Cooperation2021,2010,2,
Linda K. George,Religion2020,2000,10,
John Wilson,Religion2020,2010,6,
Friedrich Schweitzer,Religion2020,1990,3,
Will M. Gervais,Cooperation2021,2015,2,
Geoffrey Wainwright,Religion2020,1995,4,
Bernard Spilka,Religion2020,1990,8,
Albert Ellis,Religion2020,1980,6,
Martin E. Marty,Cooperation2021,1960,1,
Jacob Neusner,Religion2020,1980,4,
C. Daniel Batson,Cooperation2021,1995,6,
Fazlollah Ahmadi,Religion2020,2010,13,
Marc Galanter,Cooperation2021,1975,1,
Renée de la Torre,Religion2020,2015,13,
Ninian Smart,Religion2020,2015,2,
Eli Berman,CooperationAndReligion,1995,2,
Richard Ling,Cooperation2021,2005,6,
LILY KONG,Religion2020,2000,5,
JOSEPH M. KITAGAWA,Religion2020,1960,13,
Chris Baker,Religion2020,2015,14,
H Tristram Engelhardt,Cooperation2021,1985,1,
Peter C. Hill,Religion2020,2005,13,
Adam Possamai,Religion2020,2015,24,
Jørn Borup,Religion2020,2000,4,
Lloyd Strickland,Religion2020,2005,4,
Carsten K. W. De Dreu,Cooperation2021,2015,20,
Don Schweitzer,Religion2020,2005,3,
David Reiss,Cooperation2021,1995,6,
David Robinson,Cooperation2021,2005,4,
Lisiunia A. Romanienko,Religion2020,2010,27,
Rosemary Radford Ruether,Religion2020,1975,4,
W. R. Ward,Religion2020,1960,1,
Arniika Kuusisto,Religion2020,2000,1,
Robert N. Bellah,Religion2020,1965,11,
Sigurd Bergmann,Religion2020,2015,13,
Henrik Lagerlund,Religion2020,2015,1,
Isabel Iribarren,Religion2020,2015,3,
Frank J. Korom,Religion2020,1990,4,
Gabriele Camera,Cooperation2021,2010,18,
Clive D. Field,Religion2020,2015,32,
Yaojun Li,Cooperation2021,2015,21,
Cécile Laborde,Religion2020,2005,1,
Robert W. McGee,Religion2020,2005,52,
Michael Scott,Religion2020,2000,5,
John Cottingham,Religion2020,2010,10,
William Adams Brown,Religion2020,1905,3,
Ronald Labonté,Religion2020,2015,8,
Paul Gifford,Religion2020,2000,5,
James T. Richardson,Religion2020,2010,13,
Jean-Robert Tyran,Cooperation2021,2005,2,
Charles Taliaferro,Religion2020,2015,12,
Aru Narayanasamy,Religion2020,1990,1,
James M. Nelson,Religion2020,2010,4,
Wade C. Rowatt,Religion2020,2015,7,
Joannis Mylonopoulos,Religion2020,2005,2,
Sébastien Moureau,Religion2020,2010,17,
Ted Peters,CooperationAndReligion,2005,2,
Alvin Plantinga,Religion2020,1980,2,
Joan Marques,Cooperation2021,2010,2,
Armin W. Geertz,Religion2020,2005,7,
Roland Robertson,Religion2020,1990,6,
Stephen Pattison,Religion2020,2010,8,
Tony Walter,Religion2020,1990,2,
Gary D. Bouma,Cooperation2021,2015,1,
Åke Hultkrantz,Religion2020,1965,7,
John Marenbon,Religion2020,2015,4,
Linda M. Chatters,Religion2020,1990,4,
John Fisher,Religion2020,2010,12,
Allen D. Hertzke,Religion2020,2015,6,
David Little,Religion2020,1960,1,
Ann Loades,Religion2020,2005,7,
Gareth B. Matthews,Religion2020,1975,1,
Bernard McGINN,Religion2020,2000,3,
Camilla Zimmermann,Religion2020,2015,8,
Jerry H Gill,Religion2020,1975,16,
Satinder Dhiman,CooperationAndReligion,2015,1,
Patrick H. McNamara,Religion2020,1980,5,
Kamaldeep Bhui,Religion2020,2010,32,
L. Philip Barnes,Religion2020,1995,1,
Philip L. De Jager,Religion2020,2005,1,
Hanna Kulagina-Stadnichenko,Religion2020,2000,4,
Rodney Stark,Religion2020,1990,10,
Dan J. Stein,Religion2020,2015,10,
Wei Zhang,Religion2020,2015,11,
Siebren Miedema,Religion2020,2015,10,
James Bissett Pratt,Religion2020,1945,1,
Eric L. Garland,Religion2020,2005,9,
Benjamin Beit-Hallahmi,Religion2020,1990,5,
Melissa Raphael,Religion2020,1990,4,
F Rosner,Religion2020,1980,4,
June Boyce-Tillman,Religion2020,2010,6,
Heinz Streib,Religion2020,2000,5,
Peter Kivisto,Religion2020,1980,3,
Norman Doe,CooperationAndReligion,2005,2,
David Voas,Religion2020,2015,9,
Maureen E. Lyon,Religion2020,2010,18,
Benjamin Beit-Hallahmi,Religion2020,2010,30,
Leslie J. Francis,Cooperation2021,2015,1,
Colin Gunton,Religion2020,1980,2,
Larry J. Young,Cooperation2021,2000,4,
Mark A. Noll,Religion2020,2010,5,
Louis Dupré,Religion2020,2010,1,
Frederick M Burkle,Religion2020,2010,8,
Laura Béres,Religion2020,2010,2,
John Eade,Religion2020,2015,14,
Christopher Ocker,Cooperation2021,2000,3,
Ivan Strenski,Religion2020,1980,1,
Wade Clark Roof,Religion2020,2015,4,
Anson Shupe,Religion2020,2015,2,
Janet Martin Soskice,Religion2020,2015,3,
Stephen J. Stein,Religion2020,2010,3,
H. J. Rose,Religion2020,1955,6,
Rodney Stark,Religion2020,1970,3,
Michael Pye,Religion2020,2010,1,
Arne Traulsen,Cooperation2021,2000,2,
Paul-François Tremlett,Religion2020,2005,2,
Lorne L. Dawson,Religion2020,1990,4,
Robert Jackson,Religion2020,2005,11,
Richard A. McCormick,Religion2020,1965,10,
Edwin E. Aubrey,Religion2020,1940,10,
Enzo Pace,Religion2020,1975,2,
Elena Bastida,Religion2020,2000,3,
Li Zhang,Cooperation2021,1995,1,
Kun Yang,Religion2020,2015,1,
Patsy Yates,Religion2020,2015,14,
Thilo Gross,Cooperation2021,2010,14,
KIERAN FLANAGAN,Religion2020,2000,3,
Christoph Hauert,Cooperation2021,2015,18,
Wei Li,Religion2020,2005,3,
Rachel Muers,Religion2020,2005,6,
Kocku von Stuckrad,Religion2020,2010,10,
Philip Hefner,Religion2020,1975,2,
Marian Burchardt,Religion2020,2005,2,
Keith G. Meador,Religion2020,1995,3,
Marjorie Mandelstam Balzer,Religion2020,1985,3,
Heather Honoré Goltz,Religion2020,2010,30,
John Parratt,Religion2020,1995,8,
Tamás Martos,Cooperation2021,2010,2,
Toshio Yamagishi,Cooperation2021,1985,4,
Detlef Pollack,Religion2020,2010,8,
Jeffrey Haynes,Cooperation2021,2010,1,
Gregory R. Peterson,Religion2020,1995,3,
Philippe Huguelet,Religion2020,2005,10,
Luther H. Martin,Religion2020,2015,12,
Carles Salazar,Religion2020,2015,18,
Christoph Auffarth,Religion2020,2000,10,
Geoffrey Wainwright,Religion2020,1975,5,
Jeffrey B. Pettis,Religion2020,2005,1,
Stephen H. Webb,Religion2020,2005,13,
Phillip R. Shaver,Religion2020,2015,5,
Tony Walter,Religion2020,2010,5,
Meredith B. McGuire,Religion2020,1995,4,
David B. Burrell,Religion2020,2010,6,
H. Byron Earhart,Religion2020,1990,6,
Constant J. Mews,Religion2020,1990,1,
Bron Taylor,Religion2020,2005,3,
Andrew Village,Cooperation2021,2015,1,
Ian Olver,Religion2020,2005,2,
Leonardo Becchetti,Cooperation2021,2005,1,
Oliver D. Crisp,Religion2020,2005,4,
Yan Chen,Cooperation2021,2010,16,
Steven Engler,Religion2020,2015,5,
Sanjay Kalra,Religion2020,2015,14,
Jacqueline S. Mattis,Religion2020,2015,2,
Mitchell J. Neubert,Cooperation2021,1995,2,
Joshua N. Hook,Religion2020,2015,63,
David Robinson,Religion2020,1985,2,
Karl Peltzer,Religion2020,2010,22,
Alexandra Walsham,Religion2020,1995,5,
Chris G. Sibley,Religion2020,2005,1,
Kenneth D. Wald,Religion2020,2005,10,
Alister E McGrath,Religion2020,1995,1,
Linda E. Carlson,Religion2020,2010,18,
Philip Clayton,Religion2020,1995,7,
Kai Nielsen,Religion2020,2000,4,
HUGO MEYNELL,Religion2020,2015,7,
Manfred Milinski,Cooperation2021,1995,2,
Joshua R. Farris,Religion2020,2010,2,
Colin Gunton,Religion2020,2015,1,
Ben Clements,Religion2020,2010,10,
Nikki R. Keddie,Religion2020,2010,3,
Paul Helm,Religion2020,2015,3,
Guy G. Stroumsa,Religion2020,2015,18,
Ed Diener,Religion2020,2015,6,
William Schweiker,Religion2020,2000,5,
David M. Wulff,Religion2020,1990,4,
Marcus W. Feldman,Cooperation2021,1985,8,
Jamin Halberstadt,Religion2020,2010,4,
Richard L. Gorsuch,Religion2020,2015,2,
Donald Wiebe,Religion2020,1980,4,
K. Helmut Reich,Religion2020,2005,10,
Thomas Ostermann,Religion2020,2015,5,
Sung Joon Jang,CooperationAndReligion,2015,1,
David H. Rosmarin,Religion2020,2010,22,
Dick Anthony,Religion2020,2015,3,
Roland Robertson,Religion2020,1970,12,
Daniel A. Helminiak,Religion2020,2015,5,
David G. Rand,Cooperation2021,2015,52,
Elizabeth A. Minton,Cooperation2021,2015,1,
David R. Williams,Cooperation2021,2015,2,
Kelly Bulkeley,Religion2020,2005,9,
Eduardo Bruera,Religion2020,2000,2,
Marc Galanter,Religion2020,1965,1,
Justin L. Barrett,Religion2020,1995,5,
Kundharu Saddhono,Religion2020,2010,2,
Kim Knott,Religion2020,1985,2,
Azim F. Shariff,CooperationAndReligion,2005,1,
Allen E. Bergin,Religion2020,1985,7,
Ernst Fehr,Cooperation2021,2005,15,
Franco Fabbro,Religion2020,2010,11,
Mary Jo Neitz,Religion2020,1980,2,
Sheridan Gilley,Religion2020,1985,4,
Peter Byrne,Religion2020,2005,4,
Liz Gwyther,Religion2020,2010,16,
Christoph Stenschke,Religion2020,1995,4,
Helen Hardacre,Religion2020,2005,2,
George Thomas Kurian,Cooperation2021,2010,1,
Patrick Collinson,Religion2020,2000,2,
Zehavit Gross,Religion2020,2000,1,
JOHN D. CAPUTO,Religion2020,2000,6,
Mark R. McMinn,Religion2020,2000,13,
Muhammad Ayub Buzdar,Religion2020,2015,14,
Mandy Robbins,Religion2020,2010,30,
Celia Deane-Drummond,Religion2020,2010,8,
Patrick Sherry,Religion2020,1995,1,
Alvin J. Schmidt,Religion2020,2010,19,
Ota Pavlíček,Religion2020,2015,1,
J. Milton Yinger,Religion2020,1940,1,
Tatjana Schnell,Religion2020,2010,16,
Marian de Souza,Cooperation2021,2010,2,
Michael I. Posner,Religion2020,2010,15,
Graham Ward,Religion2020,1990,4,
Darren E. Sherkat,Religion2020,1995,14,
W. D. Hudson,Religion2020,1965,11,
Clyde Wilcox,Cooperation2021,2015,1,
Wendy Cadge,Religion2020,2005,17,
Fergus Kerr,Religion2020,1985,3,
Irving Hexham,Religion2020,1980,4,
Wyatt MacGaffey,Religion2020,1985,8,
Werner Güth,Cooperation2021,1980,1,
Marcel Zwahlen,Religion2020,2015,4,
Ian T. Baldwin,Cooperation2021,2010,12,
Keith J. Egan,Religion2020,2005,2,
Dale F. Eickelman,Religion2020,2000,4,
Moshe Hoffman,Cooperation2021,2015,14,
Ben Clements,CooperationAndReligion,2015,2,
John Hick,Religion2020,1955,1,
Sung Joon Jang,Religion2020,2010,6,
Paul J. Mills,Religion2020,2005,3,
Ann Gleig,Religion2020,2015,10,
Julian Stern,Religion2020,2015,16,
Leonard J. Biallas,Religion2020,1975,5,
Laura Galiana,Religion2020,2015,14,
Paul Weller,Religion2020,1990,14,
Louis Putterman,Cooperation2021,1985,1,
Adrian Furnham,Cooperation2021,1990,1,
Linda Woodhead,Religion2020,2015,13,
Merold Westphal,Religion2020,2015,7,
Ronald Hutton,Religion2020,2005,5,
David Jasper,Religion2020,1995,3,
Eliezer Witztum,Religion2020,2015,3,
Neal M Krause,Religion2020,1985,1,
Cristiano Castelfranchi,Cooperation2021,2000,16,
Sohail Inayatullah,Religion2020,1995,15,
Ursula King,Religion2020,2005,5,
Jürgen Moltmann,Religion2020,2010,4,
Daniel Balliet,Cooperation2021,2015,18,
Angus Buckling,Cooperation2021,2005,28,
Casey Golomski,Religion2020,2010,2,
Giuseppe Benagiano,Cooperation2021,2000,2,
Vassilis Saroglou,Religion2020,2015,22,
Richard Harding,Cooperation2021,2010,1,
Matthew J. Pereira,Religion2020,2015,1,
Supa Pengpid,Cooperation2021,2015,1,
Traugott Roser,Religion2020,2005,4,
P. Scott Richards,Religion2020,1995,10,
Oleksandr N. Sagan,Religion2020,2005,4,
Gavin D'Costa,Religion2020,2015,8,
Reza Arjmand,Religion2020,2005,2,
Malcolm Brown,Cooperation2021,2010,2,
Veit Bader,Religion2020,2000,8,
Thomas Robbins,Religion2020,1980,14,
Laurel Kendall,Religion2020,1985,21,
Gørill Haugan,Religion2020,2015,6,
James L. Guth,Religion2020,1985,2,
C. Daniel Batson,CooperationAndReligion,1990,2,
Keith Robbins,Religion2020,1975,2,
Helge Årsheim,Religion2020,2015,14,
Birgit Meyer,Religion2020,2015,11,
Katrina J. Debnam,Religion2020,2015,6,
George A. Coe,Religion2020,1925,3,
Jianwei Huang,Cooperation2021,2010,8,
E. L. Mascall,Religion2020,1940,4,
Mark Silk,Religion2020,2000,2,
Christopher Ocker,Religion2020,2015,9,
Peter Koslowski,Cooperation2021,1995,2,
Andrew Louth,Religion2020,2005,36,
Ivo Jirásek,Religion2020,2015,15,
Paul Tillich,Religion2020,1955,9,
Robert John Russell,Religion2020,1990,2,
Jörg Rüpke,Religion2020,2000,4,
Lewis R. Rambo,Religion2020,1970,1,
Daniel Burston,Religion2020,1985,1,
David F. Ford,Religion2020,2015,2,
Ted Peters,Cooperation2021,2005,2,
Pascual Parrilla,Religion2020,2015,27,
Philip L. Quinn,Religion2020,1995,11,
Kevin McCabe,Cooperation2021,1995,4,
Pehr Granqvist,Religion2020,2005,8,
David Tracy,Religion2020,1995,3,
David R. Williams,Religion2020,1995,2,
Steve Bruce,Religion2020,2005,17,
James S. Gordon,Religion2020,2000,6,
Simon Peng-Keller,Religion2020,2015,14,
Erin K. Wilson,Religion2020,2015,12,
John Swinton,Religion2020,2010,21,
Anthony J. Blasi,Religion2020,2015,1,
Alister E McGrath,Religion2020,2015,6,
Ram A. Cnaan,Religion2020,2010,7,
Julie J. Exline,Religion2020,2000,1,
David B Larson,Cooperation2021,2015,2,
Laszlo Zsolnai,Religion2020,2015,24,
Stanley Hauerwas,Religion2020,2005,12,
Dirk Helbing,Cooperation2021,2005,18,
Peter Lake,Religion2020,2015,7,
Gotthard Strohmaier,Religion2020,2015,2,
Johannes A. Van der Ven,Religion2020,2010,6,
Sanni Yaya,Cooperation2021,2015,1,
Lewis S. Ford,Religion2020,1990,2,
Brian Leftow,Religion2020,1985,2,
Christopher R. Cotter,Religion2020,2015,14,
Joshua D. Reichard,Cooperation2021,2010,5,
Marcia Grant,Religion2020,2010,12,
Vern L. Bengtson,Religion2020,2005,3,
Ichiro Kawachi,Cooperation2021,2000,1,
Michael E. McCullough,Religion2020,1995,13,
H Tristram Engelhardt,Religion2020,2015,1,
Joseph H. Fichter,Religion2020,1965,6,
J. D. Y. Peel,Religion2020,1995,3,
Birger A. Pearson,Religion2020,2000,4,
Peter C. Hill,CooperationAndReligion,2005,1,
Oded Stark,Cooperation2021,2010,12,
Robin I. M. Dunbar,Cooperation2021,1995,1,
Wesley J. Wildman,Religion2020,2015,48,
Louis Hoffman,Religion2020,2005,16,
Bernard Spilka,Religion2020,1975,6,
Dewey D. Wallace,Religion2020,1980,3,
Peter W. Williams,Religion2020,2010,80,
Keith Ward,Religion2020,2010,5,
Pascal Boyer,Religion2020,2000,8,
Simon Dein,Religion2020,2015,16,
Christophe Jaffrelot,Religion2020,2005,3,
MARTA DOMINGUEZ DIAZ,Religion2020,2015,4,
Bhikhu Parekh,Religion2020,1975,3,
Daniel H. Levine,Religion2020,1985,7,
Jerry H Gill,Religion2020,1960,8,
James Bissett Pratt,Religion2020,1905,5,
Paul A. Frewen,Religion2020,2010,6,
Patrick Collinson,Religion2020,1980,7,
Pam McGrath,Religion2020,2000,33,
Brandon Vaidyanathan,Cooperation2021,2010,1,
David Thang Moe,Religion2020,2015,17,
Loren D. Marks,Cooperation2021,2015,1,
Ralph W. Hood Jr.,Religion2020,1980,2,
D. Z Phillips,Religion2020,2000,5,
Douglas Pratt,Religion2020,2010,31,
Tracy Anne Balboni,Religion2020,2005,8,
Andrew M. Greeley,Religion2020,1980,12,
Bing-Hong Wang,Cooperation2021,2000,5,
George Pattison,Religion2020,1995,3,
Mark Popovsky,Religion2020,2010,18,
T. H. Barrett,Religion2020,2005,5,
Martin E. Marty,Religion2020,1965,10,
Jing Li,Religion2020,2015,3,
Robert Merrihew Adams,Religion2020,1995,6,
Eleanor Nesbitt,Religion2020,2010,8,
Nirbhay N. Singh,Religion2020,2005,4,
Amos N. Wilder,Religion2020,1955,9,
Ram A. Cnaan,CooperationAndReligion,2000,1,
Paul Badham,Religion2020,1990,13,
Catherine L. Albanese,Religion2020,2005,1,
Lisiunia A. Romanienko,CooperationAndReligion,2010,1,
George Zografidis,Religion2020,2010,49,
Wei Chen,Cooperation2021,2005,10,
Charles E. Butterworth,Religion2020,1970,2,
Jenny Berglund,Religion2020,2015,16,
Ninian Smart,Religion2020,1980,15,
Louis Dupré,Religion2020,1970,3,
Adrian Furnham,Religion2020,2015,14,
Dean Tjosvold,Cooperation2021,1985,5,
John R. Peteet,Religion2020,1980,2,
John Hick,CooperationAndReligion,2005,1,
Nicholas A. Christakis,Cooperation2021,2005,4,
JAMES L. COX,Religion2020,2000,9,
Michael Argyle,Religion2020,1985,2,
John C. Green,Religion2020,2000,3,
Jonathan Fox,Religion2020,2000,14,
Daniel P. Sulmasy,Religion2020,2015,5,
Heather Walton,Religion2020,2015,10,
Herbert Benson,Religion2020,1985,2,
Miguel Farias,Religion2020,2010,9,
Christopher C. H. Cook,Religion2020,2000,1,
Steve Bruce,Cooperation2021,2005,1,
Afe Adogame,Religion2020,2015,8,
Victor Asal,Religion2020,2015,6,
Gerardo Muñoz,Cooperation2021,2015,3,
Arto Kallioniemi,Religion2020,2010,2,
John Milbank,Religion2020,1990,8,
Christina M. Puchalski,Religion2020,2000,12,
Laurence R. Iannaccone,Religion2020,2005,4,
John D. Yoon,Religion2020,2010,15,
Yudhijit Bhattacharjee,Religion2020,2010,6,
Edward P. Shafranske,Religion2020,1990,9,
Scott Atran,CooperationAndReligion,2015,4,
Ruth Mace,Cooperation2021,2010,7,
W. R. Ward,Religion2020,2000,3,
Martyn Percy,Religion2020,2000,3,
Dean R. Hoge,Cooperation2021,1995,2,
Abby Day,Religion2020,2015,16,
Helen Rose Ebaugh,Religion2020,2010,8,
Katerina Ierodiakonou,Religion2020,2015,4,
Joseph M. Currier,Religion2020,2015,64,
William P. Alston,Religion2020,2005,1,
Ariel Dinar,Cooperation2021,1985,1,
Werner Güth,Cooperation2021,2015,13,
Francis Schüssler Fiorenza,Religion2020,2010,3,
Christophe Grellard,Religion2020,2015,2,
James F. Keenan,Cooperation2021,1995,1,
Richard McElreath,Cooperation2021,2000,6,
Denise Lardner Carmody,Religion2020,1995,6,
Giuseppe Benagiano,Religion2020,2005,4,
Randall Balmer,Religion2020,2010,14,
Richard Harding,Religion2020,2010,24,
Hans-Georg Ziebertz,Religion2020,2010,7,
James T. Richardson,Cooperation2021,2000,1,
Mary T Sweat,Religion2020,2010,10,
Ara Norenzayan,CooperationAndReligion,2010,4,
Daniel T. Blumstein,Cooperation2021,2010,14,
Daniel Ashlock,Cooperation2021,2005,11,
Hans Schwarz,Religion2020,1970,4,
Dick Anthony,Religion2020,1995,6,
Wei Wang,Religion2020,2015,6,
Robert S. Ellwood,Religion2020,1985,2,
John Parratt,Religion2020,1975,4,
Enzo Pace,Religion2020,1995,1,
Marc Galanter,Religion2020,1985,1,
Leslie J. Francis,Religion2020,1985,13,
Anatol Rapoport,Religion2020,1990,1,
Ina ter Avest,Religion2020,2015,14,
R. Stephen Warner,Religion2020,2010,4,
Robert N. Bellah,Religion2020,1985,2,
Paul Vermeer,Religion2020,2010,12,
Michelle Pearce,Religion2020,2015,28,
Abbas Ebadi,Religion2020,2005,1,
Thomas J. Csordas,Religion2020,1985,4,
INGOLF U. DALFERTH,Religion2020,1985,6,
Gerald Birney Smith,Religion2020,1925,11,
John Hedley Brooke,Religion2020,1990,5,
Stephen Gersh,Religion2020,1980,1,
Peter M. Kappeler,Cooperation2021,2015,8,
Sheridan Gilley,CooperationAndReligion,1970,1,
Gert Pickel,Religion2020,1995,3,
Philip L. Tite,Religion2020,2015,48,
Martin Ubani,Religion2020,2010,4,
Joan E. Strassmann,Cooperation2021,1990,2,
John R. Bowen,Religion2020,1995,6,
Lewis R. Rambo,Religion2020,2010,25,
Carmela Baffioni,Religion2020,2010,59,
Tariq Modood,Religion2020,2000,2,
Peter C. Phan,Religion2020,1985,1,
Loren D. Marks,Religion2020,2015,27,
Karl Sigmund,Cooperation2021,2005,12,
Nick J. Watson,Religion2020,2005,1,
Paul J. Watson,Religion2020,2010,8,
Joseph Henrich,Cooperation2021,2000,8,
William Sims Bainbridge,Religion2020,2005,5,
Sergej Flere,Religion2020,1990,1,
Suyitno Suyitno,Religion2020,2015,16,
Allen E. Bergin,Cooperation2021,1995,1,
Paul Wink,Religion2020,1995,2,
Rhys H. Williams,Religion2020,1990,11,
Myrna M. Weissman,Religion2020,1995,1,
Nancy T. Ammerman,Religion2020,1985,3,
F. LeRon Shults,Religion2020,2015,22,
James V. Spickard,Religion2020,2005,5,
Jean S. Kutner,Religion2020,2000,3,
James Wm. McClendon,Religion2020,1985,10,
Elaine Graham,Religion2020,2015,18,
Gina A. Zurlo,Religion2020,2010,8,
Frank Cranmer,Religion2020,2010,16,
Kevin N. Laland,Cooperation2021,1995,3,
Shirley Jackson Case,Religion2020,1930,1,
Lisa Sowle Cahill,Religion2020,1975,2,
Steven J. Sandage,Religion2020,2015,31,
David R. Johnson,Religion2020,2010,2,
Friederike Range,Cooperation2021,2010,2,
Jeffrey M. Perl,Religion2020,2015,14,
Terrence W. Tilley,Religion2020,1985,2,
Gregory R. Peterson,Religion2020,2015,2,
Patricia E. Murphy,Religion2020,2015,12,
David Reiss,Cooperation2021,2015,3,
William Sweet,Religion2020,1990,9,
Sumner B. Twiss,Cooperation2021,1970,2,
Wei Wang,Cooperation2021,2015,13,
Joseph B. Tamney,Religion2020,1985,8,
Christoph Flüeler,Religion2020,2010,26,
John H. Shaver,CooperationAndReligion,2015,4,
Eran Ben-Arye,Religion2020,2005,10,
William Breitbart,Religion2020,2015,26,
Arjan W. Braam,Religion2020,2005,5,
Michiyo Ando,Religion2020,2010,17,
Jon Adams,Cooperation2021,2000,1,
Peter Berger,Religion2020,2005,4,
Silvio Ferrari,Religion2020,2015,17,
Mary Grey,Religion2020,2005,8,
Paul D. Molnar,Religion2020,2010,8,
Robin Gill,Religion2020,2000,5,
Kevin Schilbrack,Religion2020,2010,9,
Dimitris Xygalatas,CooperationAndReligion,2010,1,
Joretta L. Marshall,Religion2020,1990,4,
Peer Scheepers,Religion2020,2000,8,
Simon Goldhill,Religion2020,2005,9,
Lucia Gagliese,Religion2020,2015,3,
Gregory Baum,Religion2020,1985,3,
George Fitchett,Religion2020,2000,6,
Peter Harrison,Religion2020,2005,7,
Ara Norenzayan,Religion2020,2010,38,
Ara Norenzayan,Cooperation2021,2005,4,
John R. Bowen,Cooperation2021,1995,1,
Harald Walach,Religion2020,2000,8,
Joseph A. Bulbulia,Cooperation2021,2005,1,
William K. Kay,Religion2020,2005,12,
J. E. Wood,Religion2020,1990,9,
Steve Nolan,Religion2020,2015,26,
Michael Pye,Religion2020,1990,5,
Darren E. Sherkat,Religion2020,2015,12,
Henry Nelson Wieman,Religion2020,1960,1,
Peter Adamson,Religion2020,2000,4,
Tony Walter,Cooperation2021,2010,1,
Joel Tsevat,Religion2020,2010,8,
Paul Wink,Cooperation2021,1995,1,
Travis Dumsday,Religion2020,2015,36,
Marianne Moyaert,Religion2020,2010,18,
Winnifred Fallers Sullivan,Religion2020,2000,6,
Benjamin E. Zeller,Religion2020,2010,11,
Yamir Moreno,Cooperation2021,2010,17,
John A. Demetracopoulos,Religion2020,2015,6,
Ernst M. Conradie,Religion2020,2010,13,
Yan Chen,Religion2020,2010,2,
Berry Billingsley,Religion2020,2010,10,
Vito Fragnelli,Cooperation2021,2010,2,
Edwin S. Gaustad,Religion2020,1980,4,
Siebren Miedema,Religion2020,1995,2,
Adrian Furnham,Cooperation2021,2010,3,
Sung Joon Jang,Cooperation2021,2010,2,
Ted G. Jelen,Religion2020,1985,6,
Mikel Burley,Religion2020,2015,18,
Michael Tomasello,Cooperation2021,2010,38,
Sofia Sjö,Religion2020,2015,22,
David Reiss,Religion2020,1995,3,
David Robinson,Cooperation2021,1985,2,
Terrence D. Hill,Religion2020,2015,20,
Dan J. Stein,Cooperation2021,2005,2,
Harvey Whitehouse,Religion2020,2010,11,
Jon R. Webb,Religion2020,2015,4,
Mark Chapman,Religion2020,1995,2,
W. Brad Johnson,Religion2020,2015,2,
Roland Littlewood,Religion2020,1985,3,
Kate Miriam Loewenthal,Religion2020,1990,4,
Phillip R. Shaver,Cooperation2021,2015,1,
Franco Fabbro,Religion2020,1990,1,
Robert Perske,Religion2020,2000,18,
Beth R. Crisp,Religion2020,2010,5,
Daniel Houser,Religion2020,2010,1,
Åke Hultkrantz,Religion2020,2005,1,
KIERAN FLANAGAN,Cooperation2021,2010,1,
Andrew Louth,Religion2020,1990,18,
Jean-Paul Willaime,Religion2020,1985,2,
The Anh Han,Cooperation2021,2015,19,
Sheridan Gilley,Religion2020,2005,2,
Rod Ling,Religion2020,2010,18,
Doug Oman,Religion2020,2010,9,
John Scheid,Religion2020,2005,4,
Boyd Taylor Coolman,Religion2020,2005,6,
Anthony Gill,Religion2020,2005,4,
Eugene V. Gallagher,Religion2020,2005,5,
J. Gwyn Griffiths,Religion2020,1965,3,
Eliezer Witztum,Religion2020,1995,5,
Richard S. Ascough,Religion2020,2005,7,
Keith Robbins,Religion2020,1995,2,
Carl E. Thoresen,Religion2020,2010,6,
Roger Finke,Religion2020,2000,12,
Roman Lunkin,Religion2020,2010,8,
James T. Tedeschi,Cooperation2021,1970,19,
Norman Doe,Religion2020,2015,6,
Robert Boyd,Cooperation2021,1990,2,
Francis X. Clooney,Religion2020,1990,7,
Winthrop S. Hudson,Religion2020,1975,7,
Jürgen Moltmann,Cooperation2021,1970,1,
Arthur Stephen McGrade,Religion2020,1995,1,
John R. Vile,Religion2020,2015,5,
Michelle J. Pearce,Religion2020,2015,26,
Dirk Hutsebaut,Religion2020,2010,5,
Paul Tillich,Religion2020,1975,2,
Robert John Russell,Religion2020,2010,12,
William Desmond,Religion2020,2005,27,
Arvind Sharma,Religion2020,2005,4,
Anatol Rapoport,Cooperation2021,1990,2,
Graham Rossiter,Religion2020,1990,2,
Julie A. Schneider,Religion2020,2005,10,
Terrence D. Hill,Cooperation2021,2015,2,
Jan Grimell,Religion2020,2015,14,
John Witte,Religion2020,2000,11,
David Jasper,Religion2020,2010,6,
David C. Dollahite,Religion2020,2005,6,
David R. Williams,Religion2020,2015,18,
David Lester,Religion2020,1985,4,
Gregory D. Alles,Religion2020,2000,6,
James A. Beckford,Religion2020,2010,9,
Allen E. Bergin,Religion2020,2005,1,
Ernst Fehr,Religion2020,2010,1,
Tyler J. VanderWeele,Religion2020,2010,10,
Bryan S. Turner,Cooperation2021,2000,6,
James A. Tulsky,Religion2020,2015,18,
William Van Gordon,Religion2020,2010,11,
Euan Cameron,Religion2020,1985,6,
Daniel Callahan,Religion2020,1990,15,
Nicola Slee,Religion2020,2010,15,
Helen Hardacre,Religion2020,1985,8,
Irene Zavattero,Religion2020,2015,2,
George Pattison,Religion2020,2010,3,
Andreas Losch,Religion2020,2010,4,
Michael Stausberg,Religion2020,2015,8,
René Descartes,Religion2020,2005,2,
Kenneth A. Rasinski,Religion2020,2015,3,
Andrew M. Colman,Cooperation2021,2015,9,
Geir Skeie,Religion2020,1970,6,
Kundharu Saddhono,Cooperation2021,2015,1,
Mark Juergensmeyer,Religion2020,1975,3,
Andrew Wright,Religion2020,2005,8,
Thomas Robbins,Religion2020,2000,11,
Hisao Ishibuchi,Cooperation2021,2005,14,
Cristina D’Ancona,Religion2020,2010,34,
Neal M Krause,Religion2020,2005,11,
Laurel Kendall,Religion2020,2005,6,
James L. Guth,Religion2020,2005,4,
John Wilson,Religion2020,1990,2,
Hisham Abu-Raiya,Religion2020,2015,14,
Helané Wahbeh,Religion2020,2010,12,
Jules JANSSENS,Religion2020,2015,3,
Charles H. Lippy,Religion2020,2010,81,
Chris Baker,Cooperation2021,2000,1,
Jonathan Gratch,Cooperation2021,2005,1,
Phillip E. Hammond,Religion2020,1980,6,
John E. Smith,Religion2020,1970,5,
Jeffrey A. French,Cooperation2021,2010,3,
Daniel Didomizio,Religion2020,1985,12,
Peter Koslowski,Religion2020,1995,1,
HUGO MEYNELL,Religion2020,1995,5,
Paul Tillich,Religion2020,1935,1,
Laurence R. Iannaccone,Cooperation2021,1995,1,
Philip E. Devenish,Religion2020,1995,4,
Andrew Buckser,Religion2020,2015,1,
Marcus W. Feldman,Cooperation2021,2005,4,
Brian R Clack,Religion2020,2005,3,
Ron J. Bigalke,Religion2020,2010,48,
Carel P. van Schaik,Cooperation2021,2010,5,
Veit Bader,Cooperation2021,2005,4,
David C. Queller,Cooperation2021,2000,18,
Jing Li,Cooperation2021,2015,9,
James S. Gordon,Religion2020,1980,3,
Joseph Henrich,CooperationAndReligion,2005,1,
Gary E. Roberts,Religion2020,2010,6,
Anthony Reddie,Religion2020,2010,10,
Owen Anderson,Religion2020,2010,12,
Miguel Farias,Cooperation2021,2005,1,
Michele Dillon,Religion2020,2000,8,
Maryam Rassouli,Religion2020,2010,2,
Chao Liu,Cooperation2021,2015,13,
Mark A. Noll,Religion2020,1990,4,
Joretta L. Marshall,Religion2020,2005,10,
Anson Shupe,Religion2020,1995,4,
Sohail Inayatullah,Religion2020,2015,3,
Gerardo Muñoz,CooperationAndReligion,2010,12,
Charles Lyttle,Religion2020,1930,16,
Rowan Williams,Religion2020,2005,7,
Robert C. Fuller,Religion2020,2015,7,
Gavin D'Costa,Religion2020,1995,6,
John Corrigan,Religion2020,2005,2,
Nathan R. Kollar,Religion2020,1985,4,
Brendan Carmody,Religion2020,2005,15,
Susan H. McFadden,Religion2020,2000,8,
George Aaron Barton,Religion2020,1895,1,
Lee W. Bailey,Religion2020,2000,1,
Elizabeth A. Minton,Religion2020,2015,19,
Raymond F. Paloutzian,Religion2020,2000,2,
Paul R. Katz,Religion2020,2010,14,
Jacqueline S. Mattis,Religion2020,1995,2,
Mark Juergensmeyer,Religion2020,2015,14,
Robert Joseph Taylor,Religion2020,1990,5,
Lukas Pokorny,Religion2020,2015,26,
Forrest Clingerman,Religion2020,2005,18,
Rüdiger Arnzen,Religion2020,2010,26,
Luther H. Martin,Religion2020,1995,3,
Owen Anderson,Cooperation2021,2005,1,
Jeffrey K. Hadden,Religion2020,1975,2,
Keith J. Egan,Religion2020,1985,10,
Paul Helm,Religion2020,1995,2,
Jan-Olav Henriksen,Religion2020,2005,10,
James R. Lewis,Religion2020,2010,17,
Keith G. Meador,Religion2020,2015,2,
Peter Beyer,Religion2020,2015,17,
Robert A. Segal,Religion2020,2000,8,
Eamon Duffy,Religion2020,1980,4,
Daniel A. Helminiak,Religion2020,1995,6,
Zuoxin Wang,Cooperation2021,2005,8,
Michael Speca,Religion2020,2010,12,
Detlef Pollack,Religion2020,1990,3,
Susan E. Henking,Religion2020,2000,6,
Michel Despland,Religion2020,1965,2,
Frank Whaling,Religion2020,1990,1,
Dinesh Bhugra,Religion2020,2005,12,
Jonathan S. Abramowitz,Religion2020,1995,1,
Bryan S. Turner,Religion2020,2000,12,
Martin E. Marty,Religion2020,2005,2,
Hans G. Kippenberg,Religion2020,2005,3,
Mary Jo Neitz,Religion2020,2000,8,
Lene Kühle,Religion2020,2010,10,
Antonio M. Hernández,Cooperation2021,2015,26,
David B. Burrell,Religion2020,1990,4,
Anna Strhan,Religion2020,2010,32,
H. Byron Earhart,Religion2020,1970,9,
Joseph Bracken,Religion2020,2015,3,
Eli Berman,Religion2020,2005,14,
Jon R Stone,Religion2020,1990,1,
Kenneth Ira Pargament,Religion2020,1985,4,
Lucila Castanheira Nascimento,Religion2020,2015,40,
David Hay,Religion2020,1995,10,
Kevin N. Laland,Religion2020,2005,3,
José A. Cuesta,CooperationAndReligion,2010,1,
Justin L. Barrett,CooperationAndReligion,2005,1,
Paul Avis,Religion2020,2015,18,
Kevin J. Flannelly,Religion2020,2005,26,
Elizabeth Johnston Taylor,CooperationAndReligion,2000,1,
J. Milton Yinger,Religion2020,1960,7,
Tamás Martos,Religion2020,2015,2,
Phillip R. Shaver,Religion2020,1975,1,
Luc Deliens,Cooperation2021,2005,2,
Linda M. Chatters,Religion2020,2010,14,
Jacob Neusner,Religion2020,2000,22,
Jeffrey A. Dusek,Religion2020,2005,12,
John F. Miller,Religion2020,2000,4,
Rowan Williams,Religion2020,1985,1,
Susumu Shimazono,Religion2020,1990,2,
Phillip E. Hammond,Religion2020,1965,5,
KIERAN FLANAGAN,CooperationAndReligion,2010,1,
Nikki R. Keddie,Religion2020,1990,6,
Kevin J Flannelly,Religion2020,2010,10,
Christopher Lo,Religion2020,2010,18,
Pam McGrath,Cooperation2021,1995,6,
L. Philip Barnes,Religion2020,2015,9,
Simon Gächter,Cooperation2021,2000,11,
John M. Salsman,Religion2020,2015,18,
Roland Phillips,Religion2020,1985,14,
Raymond F. Paloutzian,Religion2020,1980,2,
David Hay,Religion2020,1975,6,
David Brown,Religion2020,2010,5,
David Reiss,Cooperation2021,1975,3,
F Rosner,Religion2020,2000,3,
Rosemary Radford Ruether,Religion2020,1995,3,
Hans Mol,Religion2020,1975,7,
Elinor Ostrom,Cooperation2021,1995,5,
Gerard Leavey,Religion2020,2000,1,
Simplice Asongu,Cooperation2021,2015,5,
Giancarlo Lucchetti,Religion2020,2015,55,
David Lukoff,Religion2020,2015,12,
Gary Rodin,Religion2020,2015,9,
R. Ruard Ganzevoort,Religion2020,2015,10,
Faizan Ur Rehman,Religion2020,2015,16,
Eileen Barker,Religion2020,2005,7,
Frantz Grenet,Religion2020,2015,6,
Edward J. Furton,Religion2020,2010,2,
Christopher D. Rodkey,Religion2020,2015,24,
HUGO MEYNELL,Religion2020,1975,2,
Keith E. Yandell,Religion2020,1970,1,
Shirley Otis-Green,Religion2020,2005,10,
Michel Despland,Religion2020,1985,3,
Ed Diener,Religion2020,2000,3,
David Lehmann,Religion2020,2010,9,
Conrad Hackett,Religion2020,2000,2,
Shirley Jackson Case,Cooperation2021,1930,1,
Matjaž Perc,Cooperation2021,2010,63,
James T. Richardson,Religion2020,1990,5,
Len Sperry,Religion2020,2000,5,
Elisabeth Arweck,Religion2020,1995,2,
Christina M. Puchalski,Religion2020,2015,9,
Benton Johnson,Religion2020,1965,3,
Richard A. McCormick,Religion2020,1985,14,
Clyde Wilcox,Religion2020,2000,9,
Benjamin Beit-Hallahmi,Religion2020,1970,3,
Marc Galanter,Cooperation2021,1995,1,
Peter L. Berger,Religion2020,2000,7,
Herbert Gintis,Cooperation2021,2010,5,
Wilhelm Gräb,Religion2020,2000,3,
Ronald L. Grimes,Religion2020,2000,3,
James M. Dorsey,Religion2020,2015,14,
Hans-Georg Ziebertz,CooperationAndReligion,2015,1,
J. Gwyn Griffiths,Religion2020,1985,3,
LILY KONG,CooperationAndReligion,2005,1,
Hans G. Kippenberg,Religion2020,1985,1,
José A. Cuesta,Religion2020,2010,1,
Charlene P. E. Burns,Religion2020,2005,5,
Marjorie Mandelstam Balzer,Religion2020,2005,3,
Carsten K. W. De Dreu,Cooperation2021,1995,4,
Mark J. Cartledge,Religion2020,1995,6,
Marcel Barnard,Cooperation2021,2015,1,
Sanford Nidich,Religion2020,2000,6,
Amanda Porterfield,Religion2020,1980,4,
Kenneth Ira Pargament,Religion2020,2005,40,
Tami Borneman,Religion2020,2015,18,
Carole A. Rayburn,Religion2020,2005,4,
Justin L. Barrett,Cooperation2021,2015,1,
John Riches,Religion2020,1995,4,
Walter Brueggemann,Religion2020,1995,9,
Rodney Stark,Religion2020,2010,2,
Dan J. Stein,Religion2020,1995,2,
James G. Emerson,Religion2020,1975,2,
Matthias Koenig,Religion2020,2010,8,
Edward B. Davis,Religion2020,2000,1,
Sian Cotton,Religion2020,2005,14,
Peter Marshall,Religion2020,2015,12,
Fabrizio Amerini,Religion2020,2015,1,
Armin W. Geertz,Religion2020,1985,4,
Clive Marsh,Religion2020,1995,5,
Andries G. Van Aarde,Religion2020,2010,10,
Jesús Gómez-Gardeñes,Cooperation2021,2015,6,
John Fisher,Cooperation2021,1995,2,
Josephine Griffith,Cooperation2021,2015,16,
Michel Chapuisat,Cooperation2021,1995,6,
Mark A. Yarhouse,Religion2020,2005,6,
Wilfred McSherry,Religion2020,2010,4,
Bryan D. Spinks,Religion2020,1995,4,
David H Rosmarin,Religion2020,2015,4,
François Gauthier,Religion2020,2005,6,
Stefania Palmisano,Religion2020,2015,13,
David W. Orme-Johnson,Religion2020,1985,6,
Kelly R. Morton,Religion2020,2015,6,
John Cottingham,Religion2020,1990,1,
Marco Casari,Cooperation2021,2005,4,
Luther H. Martin,Religion2020,1975,1,
Jeffrey C. Alexander,Religion2020,2000,6,
H. J. Rose,Religion2020,1935,8,
Jamie D. Aten,Religion2020,2015,25,
F Rosner,Cooperation2021,1980,1,
Samta P. Pandya,Religion2020,2015,39,
Keith J. Edwards,Religion2020,2005,9,
Grace Davie,Religion2020,2005,5,
Fraser Watts,Religion2020,1995,2,
Allen D. Hertzke,Religion2020,1995,8,
Paul J. Griffiths,Religion2020,2015,2,
Silvio Ferrari,Religion2020,1995,1,
Kevin J. Christiano,Religion2020,2000,4,
Ursula King,CooperationAndReligion,2000,1,
Antonius Liedhegener,Religion2020,2015,11,
Abdulkader Tayob,Religion2020,2010,6,
Patrick H. McNamara,Religion2020,2000,1,
Anna Dreber,Cooperation2021,2015,12,
Terry Kit Selfe,Religion2020,2015,18,
Nicola Slee,Religion2020,1990,6,
M. M. Knappen,Religion2020,1930,3,
David W. Orme-Johnson,Religion2020,2005,5,
Michel Despland,Religion2020,2005,4,
H. W. S.,Religion2020,1945,2,
Walter E. Conn,Religion2020,1980,10,
Giuseppe Giordan,Religion2020,2005,5,
Daniel Callahan,CooperationAndReligion,1990,3,
Frederick M. Smith,Religion2020,1990,5,
Robert Audi,Religion2020,1990,1,
R. Ruard Ganzevoort,Religion2020,1995,2,
Edna B. Foa,Religion2020,1975,3,
Dean R. Hoge,Religion2020,1980,6,
George Fitchett,Religion2020,1980,1,
Ronald J. Morris,Religion2020,2005,1,
Jeffrey S. Levin,Religion2020,2005,10,
Angelos Chaniotis,Cooperation2021,2005,2,
Gørill Haugan,Cooperation2021,2015,2,
Roland Littlewood,Religion2020,2000,4,
Thorsten Barnhofer,Religion2020,2015,12,
Betty R. Ferrell,Religion2020,2010,4,
Stewart M. Hoover,Religion2020,2015,2,
Brendan Hyde,Religion2020,2010,16,
Benton Johnson,Religion2020,1985,6,
Shailer Mathews,Religion2020,1925,1,
Irene J. Higginson,Religion2020,2005,15,
Frank J. Korom,Religion2020,2010,16,
David Martin,Religion2020,1960,1,
Susumu Shimazono,Religion2020,2010,4,
Albert Doja,Religion2020,2005,9,
Joseph Bracken,Religion2020,1975,1,
Liam Gearon,Religion2020,2000,5,
Sidney E. Mead,Religion2020,1955,6,
Girard Etzkorn,Religion2020,2010,16,
Iain T. Benson,CooperationAndReligion,2010,2,
Jeffrey Haynes,Religion2020,2005,14,
David Brown,Religion2020,1990,1,
Julia M. Puaschunder,Religion2020,2015,9,
Aron S. Buchman,Religion2020,2015,2,
Armin W. Geertz,CooperationAndReligion,2010,1,
Michael J Balboni,Religion2020,2005,1,
Javier Garcia-Campayo,Religion2020,2010,6,
J. L. SCHELLENBERG,Religion2020,1995,2,
Roland Robertson,Religion2020,2010,4,
Joel Robbins,Religion2020,2010,11,
Merold Westphal,Religion2020,2000,3,
John H. Shaver,Religion2020,2010,3,
Chris G. Sibley,CooperationAndReligion,2015,1,
Roy Wallis,Religion2020,1980,18,
Anthony J. Blasi,Religion2020,1975,3,
J. Kwabena Asamoah-Gyadu,Religion2020,2010,14,
Sidney E. Mead,Religion2020,1975,15,
Yaacov J. Katz,Religion2020,1990,12,
Eileen Barker,Religion2020,1985,7,
Steven Kettell,Cooperation2021,2000,3,
Claire Dwyer,Religion2020,2005,1,
Todd L. Cherry,Cooperation2021,2000,9,
David De Cremer,Cooperation2021,2005,60,
Clive Marsh,Religion2020,2015,13,
Kathleen McPhillips,Religion2020,2005,2,
Michael I. Posner,Cooperation2021,1985,1,
Birthe Loa Knizek,Religion2020,2010,14,
Willem B. Drees,Religion2020,2005,6,
Bruce Lincoln,Religion2020,1985,6,
David Lehmann,Religion2020,1910,1,
John P. Bartkowski,Religion2020,2015,15,
Ying-Hai Wang,Cooperation2021,2005,14,
Jean Bethke Elshtain,Religion2020,2005,8,
Eric A. Storch,Religion2020,2015,5,
DANIEL PATTE,Religion2020,2010,16,
Shane Sinclair,Religion2020,2010,5,
Henry Nelson Wieman,Religion2020,1925,8,
Ryan T. Cragun,Religion2020,2005,4,
Ernest M. Ligon,Religion2020,1945,2,
Jeff Astley,Religion2020,1990,3,
Yan Liu,Cooperation2021,1995,1,
Arthur Stephen McGrade,Religion2020,2015,1,
Yaacov J. Katz,Cooperation2021,2010,2,
Walter E. Conn,Religion2020,2000,2,
Mark J. Cartledge,Religion2020,2015,18,
SVENN LINDSKOLD,Cooperation2021,1980,3,
Paul J. Griffiths,Religion2020,1995,4,
Russell L. Friedman,Religion2020,2000,2,
ROBERT M. GRANT,Religion2020,1965,3,
Farooq Naeem,Religion2020,2010,4,
Mircea Eliade,Religion2020,1980,5,
Philip Hefner,Religion2020,2015,3,
John Hick,Religion2020,1995,15,
David G. Bromley,Religion2020,1995,3,
E. Ahmed,Cooperation2021,2000,16,
Vegard Skirbekk,Religion2020,2010,12,
Steven Pirutinsky,Religion2020,2005,12,
Lei Shi,Cooperation2021,2010,4,
John Witte,Cooperation2021,2000,1,
Walter Brueggemann,Religion2020,1975,3,
Geoffrey Wainwright,Religion2020,2015,1,
Ian Wilks,Religion2020,2015,1,
Michael Welker,Religion2020,1985,3,
Maryam Rassouli,Cooperation2021,2015,2,
Stewart M. Hoover,Religion2020,1995,5,
Paul R. Katz,Religion2020,1990,2,
Jonathan Chaplin,Religion2020,2000,4,
David Martin,Religion2020,1980,7,
Peter M. Kappeler,Cooperation2021,1995,11,
Jürgen Moltmann,Religion2020,1970,3,
Manfred Gerwing,Religion2020,2010,17,
Joel Robbins,Religion2020,1990,1,
Michael J. Wade,Cooperation2021,1980,13,
Charles E. Farhadian,Religion2020,2015,1,
Ann Taves,Religion2020,2005,10,
Kalkuni Visweswaraiah Naveen,Religion2020,2005,6,
Charles E. Butterworth,Religion2020,2010,27,
C. Daniel Batson,Religion2020,2010,2,
Joseph Rivera,Religion2020,2000,1,
Pasquale Annicchino,Religion2020,2005,6,
Hans Schwarz,Religion2020,1985,2,
Scott Atran,Cooperation2021,2015,4,
Denni Arli,Religion2020,2010,1,
Robert N. Bellah,Cooperation2021,1995,1,
Pascal Boyer,Cooperation2021,2005,2,
Kathryn Pitkin Derose,Religion2020,2010,30,
Thomas Jeschke,Religion2020,2010,16,
Ronald J. Morris,Religion2020,1985,13,
Joseph A. Bulbulia,Religion2020,2010,18,
Bernard Williams,Cooperation2021,1975,1,
Alastair Hamilton,Religion2020,2010,17,
Francis Schüssler Fiorenza,Religion2020,1990,1,
Kevin J. Christiano,Religion2020,1980,7,
Gareth B. Matthews,Religion2020,2015,1,
Ariel Dinar,Cooperation2021,2005,4,
Peter L. Berger,Religion2020,1980,3,
Nella van den Brandt,CooperationAndReligion,2015,2,
J. N. Mohanty,Religion2020,1985,7,
Peter Berger,Religion2020,1965,4,
Richard Ling,Religion2020,2005,18,
Khusnul Khotimah,Religion2020,2015,28,
Richard Rymarz,Religion2020,2010,15,
Fenggang Yang,Religion2020,2000,4,
Russell T. McCutcheon,Religion2020,2005,5,
Peter Beyer,Religion2020,1995,15,
Stephen D. Glazier,Religion2020,1995,9,
Dean R. Hoge,Religion2020,2000,6,
Adrian Furnham,Religion2020,1995,6,
Amanda Porterfield,Religion2020,2000,8,
Holly Gwen Prigerson,Religion2020,2000,3,
Jeffrey S. Levin,Religion2020,1985,24,
Catherine L. Albanese,Religion2020,1985,4,
Pei-Chen Lo,Religion2020,2005,13,
Robert S. Ellwood,Religion2020,2005,1,
Pascual Parrilla,Cooperation2021,2010,15,
Pnina Werbner,Religion2020,2010,14,
Jonathan S. Abramowitz,Religion2020,2015,6,
Beth R. Crisp,Cooperation2021,2005,1,
Thomas M. Izbicki,Religion2020,2005,1,
Paul C. Cooper,Religion2020,1995,1,
Naoki Masuda,Cooperation2021,2015,20,
Keith E. Yandell,Religion2020,1990,3,
Joshua D. Reichard,Religion2020,2015,15,
Aristotle Papanikolaou,Religion2020,2010,12,
Rosalind I. J. Hackett,Religion2020,2010,4,
Diarmaid MacCulloch,Religion2020,2010,5,
George Thomas Kurian,Religion2020,2010,51,
Kenneth Surin,Religion2020,2010,2,
E. Ahmed,Religion2020,2000,2,
Adam B. Seligman,Religion2020,2000,2,
Patrick Collinson,Religion2020,1960,1,
E E Griffith,Religion2020,1985,8,
David Lehmann,Religion2020,1990,2,
Joseph Bracken,Religion2020,1995,5,
J. Milton Yinger,Religion2020,1980,1,
Peter C. Hill,Cooperation2021,1980,1,
Douglas Pratt,Religion2020,1990,2,
Jon R Stone,Religion2020,2010,2,
David G. Bromley,Religion2020,1975,1,
Russell Sandberg,Religion2020,2015,15,
Tatsuya Sasaki,Cooperation2021,2010,12,
Andrew M. Greeley,Religion2020,1960,24,
William K. Kay,Religion2020,1985,6,
T. H. Barrett,Religion2020,1985,2,
Martin E. Marty,Religion2020,1985,20,
Rebecca Erwin Wells,Religion2020,2015,16,
Donald Capps,Religion2020,2000,8,
Christopher Key Chapple,Religion2020,2000,2,
John Maltby,Religion2020,2005,2,
H. Newton Malony,Religion2020,1985,11,
R. Stephen Warner,Religion2020,1970,1,
Jill E. Bormann,Religion2020,2005,10,
Fiona Timmins,Religion2020,2000,1,
Elaine Howard Ecklund,Religion2020,2015,35,
Sarah Coakley,Religion2020,2000,24,
Nathan Carlin,Religion2020,2005,14,
Christopher P. Scheitle,Cooperation2021,2015,1,
John E. Smith,Religion2020,1950,5,
Todd DuBose,Religion2020,2010,34,
Albrecht Classen,Religion2020,2010,7,
Jacques Waardenburg,Religion2020,1990,3,
Yan Li,Cooperation2021,2005,4,
Michael Northcott,Religion2020,2000,4,
David Morgan,Religion2020,1995,18,
Abdulaziz Sachedina,Religion2020,2000,4,
Richard Cross,Religion2020,1995,2,
Roberto Cipriani,Religion2020,2005,4,
Daniel P. Sulmasy,Cooperation2021,1985,1,
Steven Stack,Religion2020,1995,5,
Judi Neal,Religion2020,2000,4,
Ted Peters,Religion2020,2005,32,
Richard H. Popkin,Religion2020,2000,8,
David C. Queller,Cooperation2021,1980,4,
Myrna M. Weissman,Religion2020,2015,11,
Christopher Alan Lewis,Religion2020,2015,36,
Joseph Henrich,Religion2020,2005,1,
Ian Reader,Religion2020,2000,7,
Ahmed Abdel-Khalek,Religion2020,2010,13,
Ismail Suardi Wekke,CooperationAndReligion,2015,1,
Shauna L. Shapiro,Religion2020,2010,6,
Henrik Lagerlund,Religion2020,2015,1,
Betty R. Ferrell,Cooperation2021,1995,1,
Bryan S. Turner,Religion2020,1980,14,
Christopher G. Ellison,Religion2020,2000,15,
Simon Coleman,Religion2020,1990,2,
Kenju Kamei,Cooperation2021,2015,15,
Christopher S. M. Grimes,Religion2020,2010,22,
Michela Balconi,Cooperation2021,2015,60,
Daniel Callahan,Religion2020,1970,3,
David B. Burrell,Religion2020,1970,3,
Lisa Sowle Cahill,Religion2020,2015,3,
Aidan Nichols,Religion2020,1995,4,
Stefan Schmidt,Religion2020,2015,12,
Marco Tomassini,Cooperation2021,2015,6,
Andrew Village,Religion2020,2015,18,
Crystal L. Park,Religion2020,2005,20,
David Hay,Cooperation2021,2010,2,
Seward Hiltner,Religion2020,1980,3,
Eric van Dijk,Cooperation2021,2010,17,
Karl Peltzer,Cooperation2021,2010,8,
Marcus Moberg,Religion2020,2005,4,
James T. Richardson,Religion2020,1970,1,
Michael Stausberg,Religion2020,1995,1,
Rufus A. Johnstone,Cooperation2021,1995,9,
E. Brooks Holifield,Religion2020,1975,9,
Stephen Hunt,Religion2020,2005,5,
David H. Jensen,Religion2020,2010,11,
Ryan LaMothe,Religion2020,2015,10,
Geir Skeie,CooperationAndReligion,1970,2,
Paul F. Knitter,Religion2020,1990,2,
Amos N. Wilder,Religion2020,1935,3,
Vincent Lloyd,Religion2020,2010,20,
Arndt Büssing,Religion2020,2010,29,
Robin I. M. Dunbar,Cooperation2021,2015,17,
Wesley J. Wildman,Religion2020,1995,3,
David Little,Religion2020,2000,1,
Nikki R. Keddie,Religion2020,1970,15,
Paul Helm,Religion2020,1975,7,
Kai Nielsen,Religion2020,1960,16,
Zhu Han,Cooperation2021,2005,16,
Vincent Goossaert,Religion2020,2010,18,
H. W. S.,Cooperation2021,1930,1,
James H. Fowler,Religion2020,2005,1,
Urs Fischbacher,Cooperation2021,2015,2,
Edwin S. Gaustad,Religion2020,1960,6,
Robert A. Segal,Religion2020,1980,22,
Richard L. Gorsuch,Religion2020,1975,3,
Viren Swami,Religion2020,2005,12,
Robert W. Hefner,Religion2020,1990,4,
Barbara Pesut,Religion2020,2010,16,
Carole A. Rayburn,Religion2020,1985,12,
Jack L. Seymour,Religion2020,2000,2,
Gregory D. Alles,Religion2020,1980,1,
Joerg Rieger,Religion2020,2005,5,
Amnon Rapoport,Cooperation2021,2010,3,
David Fergusson,Religion2020,2000,4,
Christophe Erismann,Religion2020,2010,38,
Pablo Ramírez,CooperationAndReligion,2015,24,
Herbert Benson,Religion2020,2005,2,
Adam B. Cohen,Religion2020,2015,23,
Nicolas Baumard,Religion2020,2010,6,
John Wilson,Religion2020,1970,6,
John Wilson,Cooperation2021,2015,2,
Michael J. Reiss,Religion2020,2015,15,
Curtis W. Hart,Religion2020,2005,4,
S. Mark Heim,Religion2020,2010,2,
Margaret Cameron,Religion2020,2010,23,
James J. Buckley,Religion2020,1990,4,
William B. Bean,Religion2020,1955,5,
Charles Y. Glock,Religion2020,1960,6,
Jeppe Sinding Jensen,Religion2020,2010,12,
Stephen J. Stein,Religion2020,1970,1,
Victor Asal,Cooperation2021,2005,2,
Marian de Souza,Religion2020,2015,14,
Ralph L. Piedmont,Religion2020,2010,16,
Wade Clark Roof,Religion2020,1975,11,
Michel Chapuisat,Cooperation2021,2015,2,
Ines Testoni,Religion2020,2015,13,
Jocelyne Cesari,Religion2020,2005,10,
Sumner B. Twiss,Religion2020,2015,12,
Mark Juergensmeyer,Religion2020,1995,5,
Wen-Xu Wang,Cooperation2021,2010,14,
Elizabeth Johnston Taylor,Religion2020,1995,4,
Anthony B. Pinn,Religion2020,2005,9,
Eckhard Frick,Religion2020,2005,1,
Gary D. Bouma,Religion2020,1985,1,
Robert Boyd,Cooperation2021,2010,10,
Samuel Bowles,Cooperation2021,2005,21,
Karen E. Steinhauser,Religion2020,2000,4,
David W. Johnson,Religion2020,2015,1,
Elisa Harumi Kozasa,Religion2020,2005,9,
Göran Larsson,Cooperation2021,2015,1,
H. Frankfort,Religion2020,1945,14,
Kevin R. Foster,Cooperation2021,2015,9,
Brian Leftow,Religion2020,2005,2,
Justin Sands,Religion2020,2015,30,
Vhumani Magezi,Religion2020,2015,24,
Lidia Lanza,Religion2020,2010,21,
Aku Visala,Religion2020,2005,1,
Joaquim Soler,Religion2020,2015,14,
Siegfried Dewitte,Cooperation2021,2010,4,
Maxwell V. Rainforth,Religion2020,1995,4,
Julia Downing,Religion2020,2010,60,
Carel P. van Schaik,Cooperation2021,1990,3,
Alexander Moreira-Almeida,Religion2020,2010,18,
Maykel Verkuyten,Cooperation2021,2015,1,
Edna B. Foa,Religion2020,2010,8,
Elinor Ostrom,Cooperation2021,2015,3,
William Sims Bainbridge,Religion2020,1990,2,
John Riches,Religion2020,2015,10,
Graham Rossiter,Religion2020,2010,7,
Amy L. Ai,Religion2020,2015,9,
Rosemary Radford Ruether,Religion2020,2015,2,
Nima Ghorbani,Religion2020,2015,15,
Lars Lien,Religion2020,2010,14,
Harvey Whitehouse,Religion2020,1990,2,
David Lester,Religion2020,2005,18,
Rod Macleod,Religion2020,2015,27,
W. Brad Johnson,Religion2020,1995,6,
James A. Beckford,Religion2020,1990,10,
Tony Walter,CooperationAndReligion,2010,1,
Robert Boyd,Religion2020,1985,1,
Lorne L. Dawson,Religion2020,1985,4,
Beth R. Crisp,Religion2020,2005,3,
Sheridan Gilley,Religion2020,2000,5,
Patrick H. McNamara,Religion2020,2015,3,
Anthony Gill,Religion2020,2000,12,
Christopher P. Scheitle,Cooperation2021,2010,1,
Peer Scheepers,Religion2020,1995,2,
Anna Halafoff,CooperationAndReligion,2015,1,
Rubina Raja,Religion2020,2015,16,
Philip Hefner,Religion2020,1980,2,
Ara Norenzayan,Religion2020,2005,12,
Cecilia Martini Bonadeo,Religion2020,2015,1,
JOHN D. CAPUTO,Religion2020,2015,6,
Mark R. McMinn,Religion2020,2015,6,
Amnon Rapoport,Cooperation2021,1965,6,
Karl Peltzer,Cooperation2021,1995,2,
Tariq Modood,Cooperation2021,2005,1,
Dean R. Hoge,Religion2020,1995,10,
George Fitchett,Religion2020,1995,8,
E. L. Mascall,Religion2020,1935,1,
Naser Aghababaei,CooperationAndReligion,2010,1,
Ronald J. Morris,Religion2020,1990,7,
Eliezer Witztum,CooperationAndReligion,2010,1,
Roland Littlewood,Religion2020,2015,1,
William Sims Bainbridge,Religion2020,1985,5,
Phillip R. Shaver,Religion2020,2010,12,
Henry Nelson Wieman,Religion2020,1965,5,
Naoki Masuda,Cooperation2021,2010,48,
Paul Wink,Religion2020,2000,6,
David C. Dollahite,Religion2020,2000,6,
Bron Taylor,Religion2020,2010,11,
Yan Li,Religion2020,2015,10,
Jeppe Sinding Jensen,Religion2020,2015,9,
Richard Harding,Cooperation2021,2015,1,
Eamon Duffy,Religion2020,1975,10,
Ursula King,Religion2020,2000,5,
Marianne Moyaert,Religion2020,2015,28,
H. J. Rose,Religion2020,1950,9,
Paul A. M. Van Lange,Cooperation2021,1980,1,
Friederike Range,Cooperation2021,2005,1,
Ann Taves,Religion2020,2000,4,
Rany M. Salem,Cooperation2021,2015,2,
Robert John Russell,Religion2020,2005,8,
Rhys H. Williams,Religion2020,1985,3,
David R. Williams,Religion2020,2010,6,
John Maltby,Religion2020,2000,15,
H. Newton Malony,Religion2020,1980,6,
Joseph Rivera,CooperationAndReligion,2015,1,
Christopher Magezi,Religion2020,2015,13,
Dinesh Bhugra,Religion2020,2000,10,
Robert N. Bellah,Religion2020,1980,4,
James A. Tulsky,Cooperation2021,2010,3,
Thomas J. Csordas,Religion2020,1980,1,
Fergus Kerr,Religion2020,1980,4,
Rachel Muers,Religion2020,2000,6,
Camilla Zimmermann,Religion2020,2010,14,
Wilhelm Gräb,Religion2020,2015,12,
Rod Ling,Religion2020,2005,1,
Willem B. Drees,Religion2020,1990,3,
Eugene V. Gallagher,Religion2020,2010,11,
Gerald Birney Smith,Religion2020,1920,11,
Olli Hallamaa,Religion2020,1995,1,
Peter Berger,Religion2020,1960,4,
Karl Peltzer,Religion2020,1995,2,
Gregory Baum,Religion2020,1980,2,
Kevin McCabe,Cooperation2021,1990,2,
Toshio Yamagishi,Cooperation2021,1980,3,
Jon R Stone,Religion2020,1995,1,
Roger Finke,Religion2020,1995,10,
Joseph A. Bulbulia,Religion2020,2005,1,
Richard S. Ascough,Religion2020,2000,2,
Marc Galanter,Cooperation2021,2010,1,
John Riches,Religion2020,2010,4,
Keith Robbins,Religion2020,1990,6,
Terrence D. Hill,CooperationAndReligion,2015,2,
Nirbhay N. Singh,Cooperation2021,1985,1,
Aziz Sheikh,Cooperation2021,2010,6,
Helané Wahbeh,Religion2020,2005,2,
Peter Tavel,Religion2020,2015,60,
Joel Tsevat,Religion2020,2005,19,
Vassilis Saroglou,Religion2020,2010,21,
Francis Schüssler Fiorenza,Religion2020,2005,2,
S. Mark Heim,Religion2020,2005,3,
Kenju Kamei,Cooperation2021,2010,7,
Qian Zhang,Religion2020,2015,8,
John Fisher,Religion2020,1955,2,
Conrad Hackett,Religion2020,2015,20,
Eileen Barker,Religion2020,1990,10,
Duncan B. Forrester,Religion2020,1965,1,
Edwin S. Gaustad,Religion2020,1975,11,
Brian R Clack,Religion2020,2000,1,
David Martin,Religion2020,1975,2,
Elizabeth Johnston Taylor,Religion2020,1990,2,
Amanda Porterfield,Religion2020,2015,1,
Sung Joon Jang,Cooperation2021,2015,1,
James M. Nelson,Religion2020,2015,1,
Casey Golomski,Religion2020,2015,16,
Kevin N. Laland,Cooperation2021,1990,3,
Benton Johnson,Religion2020,2000,2,
David W. Johnson,Cooperation2021,1975,3,
Nancy T. Ammerman,Religion2020,1980,1,
C. Daniel Batson,Religion2020,2005,8,
Maureen E. Lyon,Religion2020,2005,4,
David Reiss,Cooperation2021,2010,6,
Gary D. Bouma,Cooperation2021,2010,1,
Michiyo Ando,Religion2020,2005,5,
John Marenbon,Religion2020,2010,32,
Linda M. Chatters,Religion2020,1995,8,
Philip Clayton,Religion2020,1990,1,
Afe Adogame,Religion2020,2010,13,
Werner Güth,Cooperation2021,1985,1,
Lucia Gagliese,Religion2020,2010,21,
Moshe Hoffman,Cooperation2021,2010,8,
Khusnul Khotimah,Religion2020,1970,8,
Albrecht Classen,Religion2020,2015,8,
Nikki R. Keddie,Religion2020,1975,6,
Peter M. Kappeler,Cooperation2021,2010,7,
Raymond F. Paloutzian,Religion2020,1995,5,
John Corrigan,Religion2020,2000,7,
Susan H. McFadden,Religion2020,2005,4,
Joretta L. Marshall,Religion2020,2010,8,
Julian Stern,Religion2020,2010,18,
Robert Joseph Taylor,Religion2020,1985,5,
David Fergusson,Religion2020,1995,2,
Michael Pye,Religion2020,1995,2,
Loren D. Marks,Religion2020,2010,9,
Peter C. Hill,Religion2020,2000,5,
Arne Traulsen,Cooperation2021,2015,15,
Paul R. Katz,Religion2020,2005,8,
Jonathan Chaplin,Religion2020,2015,14,
Joseph Henrich,Cooperation2021,2005,8,
F Rosner,Religion2020,1985,1,
J. E. Wood,Religion2020,1985,10,
Antoine Lutz,Religion2020,2015,24,
Roman Lunkin,Religion2020,2005,2,
Edward B. Davis,Religion2020,2015,25,
Francis X. Clooney,Religion2020,1985,5,
Ram A. Cnaan,CooperationAndReligion,2005,2,
Marc Galanter,Religion2020,1980,6,
Hans Hinrich Biesterfeldt,Religion2020,2010,49,
Ninian Smart,Religion2020,1985,19,
Charles Hartshorne,Religion2020,1950,2,
Simo Knuuttila,Religion2020,2015,5,
Patsy Yates,Cooperation2021,1995,6,
Gavin Flood,Religion2020,2015,44,
Renee V. Galliher,Religion2020,2005,2,
Doug Oman,CooperationAndReligion,2015,1,
Wade C. Rowatt,Religion2020,2010,13,
Frank Cranmer,Religion2020,2015,8,
Bruce Lincoln,Religion2020,1980,3,
Ying-Hai Wang,Cooperation2021,2010,2,
Wei Wang,Cooperation2021,2010,7,
Richard H. Popkin,Religion2020,2015,6,
Richard L. Gorsuch,Cooperation2021,2000,1,
Betty R. Ferrell,Religion2020,2005,3,
Ram A. Cnaan,Cooperation2021,2000,1,
Andrea D. Clements,Religion2020,2000,3,
John David Creswell,Religion2020,2015,8,
David W. Macdonald,Cooperation2021,2010,6,
Abraham Melamed,Religion2020,2000,1,
Manfred Milinski,Cooperation2021,1990,1,
Andries G. Van Aarde,Religion2020,2015,11,
Chris Baker,Religion2020,2010,13,
Irene Zavattero,Religion2020,2010,13,
Renée de la Torre,Religion2020,2010,6,
David M. Wulff,Religion2020,1985,3,
Pekka Kärkkäinen,Religion2020,2015,1,
Andrew Louth,Religion2020,1995,12,
Roxanne D. Marcotte,Religion2020,2015,1,
Kai Nielsen,Religion2020,1995,20,
Adam Possamai,Religion2020,2010,18,
Karl Sigmund,Cooperation2021,2000,8,
D. Z Phillips,Religion2020,2005,1,
John R. Bowen,Religion2020,1990,8,
Lewis R. Rambo,Religion2020,2005,1,
Benjamin Allen,Cooperation2021,2015,14,
David G. Rand,Cooperation2021,2010,46,
Tariq Modood,Religion2020,2005,4,
Thomas Robbins,Religion2020,1995,15,
Peter C. Phan,Religion2020,1980,1,
T. H. Barrett,Religion2020,2000,7,
J. Milton Yinger,Religion2020,1995,1,
Christopher Alan Lewis,Religion2020,2010,28,
Pei-Chen Lo,Religion2020,2010,4,
Philippe Huguelet,Religion2020,2000,1,
Luther H. Martin,Religion2020,1980,1,
J. L. SCHELLENBERG,Religion2020,2000,4,
Herbert Benson,Religion2020,2015,4,
Abby Day,Religion2020,2010,18,
Julie J. Exline,Religion2020,1995,1,
Katerina Ierodiakonou,Religion2020,2010,70,
Herbert Gintis,Cooperation2021,2005,7,
Mark A. Noll,Religion2020,1975,1,
Qian Zhang,Cooperation2021,2015,2,
Dale F. Eickelman,Religion2020,2015,6,
Joshua D. Reichard,Religion2020,2010,40,
Wei Li,Cooperation2021,1990,6,
Kenneth Surin,Religion2020,2005,2,
John R. Vile,Religion2020,2010,1,
Carole A. Rayburn,Religion2020,2000,20,
Wade Clark Roof,Religion2020,2010,4,
H. W. S.,Religion2020,1940,13,
Antonio M. Hernández,CooperationAndReligion,2015,24,
Michael E. McCullough,Religion2020,1990,2,
Lewis S. Ford,Religion2020,1985,3,
Scott Atran,Cooperation2021,2010,12,
John Wolffe,Religion2020,2015,8,
Feng Wang,Religion2020,2015,4,
Adrian Furnham,Religion2020,2010,20,
Ivan Strenski,Religion2020,1985,5,
John Hick,Religion2020,1970,3,
Simplice Asongu,Religion2020,2015,70,
Catherine L. Albanese,Religion2020,2000,3,
Leslie J. Francis,Religion2020,1980,7,
Friedrich Schweitzer,Religion2020,2010,23,
Melissa Raphael,Religion2020,2015,8,
Peter W. Williams,Religion2020,2005,5,
Heather Walton,Religion2020,2010,4,
Herbert Benson,Religion2020,1980,1,
R. Khari Brown,Religion2020,2015,12,
Peter Berger,Religion2020,2000,4,
Aidan Nichols,Religion2020,1980,1,
Constant J. Mews,Religion2020,2015,2,
Robin Gill,Religion2020,2005,2,
Orna Zagoory-Sharon,Cooperation2021,2005,2,
Allen D. Hertzke,Religion2020,2010,2,
James Bissett Pratt,Religion2020,1910,2,
Jean-Baptiste Brenet,Religion2020,2010,18,
Nicholas A. Christakis,Religion2020,2015,2,
David B. Burrell,Religion2020,1985,4,
Frantz Grenet,Religion2020,2010,15,
Robert H. Schneider,Religion2020,2010,7,
Matjaž Perc,Cooperation2021,2005,20,
Tom O’Donoghue,Religion2020,2015,1,
J. Milton Yinger,Religion2020,1955,8,
Ralph W. Hood Jr.,Religion2020,1985,4,
Len Sperry,Religion2020,2005,8,
Andrew M. Greeley,Religion2020,1985,4,
Ellen L. Idler,Religion2020,1995,7,
Zhuo Job Chen,Religion2020,2010,8,
Steven Stack,Cooperation2021,1995,1,
Edna B. Foa,Religion2020,1990,1,
Amy M. Burdette,Cooperation2021,2015,4,
David Lukoff,Religion2020,2010,10,
John Wilson,Cooperation2021,1990,1,
Ruth Mace,Cooperation2021,2005,2,
Simon Dein,Religion2020,2010,56,
Christopher G. Ellison,Religion2020,1985,1,
Christophe Jaffrelot,Religion2020,2000,3,
Grace Davie,Religion2020,1990,5,
Martin E. Marty,Religion2020,1960,7,
George A. Coe,Religion2020,1940,1,
Toivo J. Holopainen,Religion2020,2015,4,
Richard A. McCormick,Religion2020,1990,2,
Kathryn A. Johnson,Religion2020,2015,23,
Armin W. Geertz,Religion2020,2000,6,
John P. Bartkowski,Religion2020,2010,13,
Alastair Hamilton,Religion2020,1985,1,
Dirk Helbing,Cooperation2021,2000,2,
Gareth B. Matthews,Religion2020,1990,1,
Wolfgang Vondey,Religion2020,2010,14,
David G. Bromley,Religion2020,2010,3,
Andrew M. Colman,Cooperation2021,2000,2,
James R. Lewis,Religion2020,2005,3,
L. Philip Barnes,Religion2020,2010,7,
Duncan B. Forrester,Religion2020,2005,3,
David Waldron,Religion2020,2010,13,
Hans Mol,Religion2020,1970,13,
J. D. Y. Peel,Religion2020,1990,5,
Michiel van Elk,Religion2020,2015,15,
D. Z Phillips,Religion2020,1970,4,
Dean Tjosvold,Cooperation2021,1990,3,
Laura R. Olson,Religion2020,2000,2,
Frederick M. Smith,Religion2020,2010,22,
David Reiss,Cooperation2021,1970,3,
Jaffary Awang,Religion2020,2015,16,
Nima Ghorbani,Religion2020,2000,6,
Tinde van Andel,Religion2020,2000,2,
Joseph H. Fichter,Religion2020,1960,7,
Frank Whaling,Religion2020,1985,2,
R. Stephen Warner,Religion2020,2005,2,
Keith Ward,Religion2020,2015,4,
William Breitbart,Religion2020,2010,24,
Grace Davie,CooperationAndReligion,2010,1,
William B. Bean,Religion2020,1970,1,
Anson Shupe,Religion2020,1990,10,
Ronald L. Grimes,Religion2020,2015,2,
Joseph Bracken,Religion2020,2010,6,
Silvio Ferrari,Cooperation2021,2010,1,
Frank J. Korom,Religion2020,2005,4,
István P. Bejczy,Religion2020,1990,1,
Peter Harrison,Religion2020,2000,1,
Lucila Castanheira Nascimento,Religion2020,2010,15,
David Morgan,Cooperation2021,2000,3,
Lori G. Beaman,Religion2020,2015,17,
Emma Wild-Wood,Religion2020,2010,12,
Rodney Stark,Religion2020,1995,18,
Steve Nolan,Religion2020,2010,20,
Wei Zhang,Religion2020,2010,5,
Veit Bader,Religion2020,2015,2,
Jacques Launay,Religion2020,2015,4,
Lei Shi,Cooperation2021,2005,2,
Paul Avis,Religion2020,2010,11,
Byron Rangiwai,Religion2020,2015,24,
Roland Robertson,Religion2020,2005,2,
Robert S. Wilson,Religion2020,1995,2,
Adam B. Cohen,Religion2020,2000,9,
Helen Lavretsky,Religion2020,2010,14,
Joseph A. Bulbulia,Cooperation2021,2010,5,
Paul J. Watson,Religion2020,2015,5,
Benjamin Beit-Hallahmi,Religion2020,1985,3,
Steven Pirutinsky,Religion2020,2010,57,
Wesley J. Wildman,Religion2020,2010,51,
Anna Akasoy,Religion2020,2005,2,
Benjamin Beit-Hallahmi,Cooperation2021,2015,1,
Anthony Reddie,Religion2020,2005,24,
Jeffrey A. French,Religion2020,2010,3,
DAVID GRUMETT,Religion2020,2000,4,
HUGO MEYNELL,Religion2020,1990,3,
David Carr,Religion2020,1975,1,
Shirley Jackson Case,Religion2020,1925,2,
Peter Beyer,Religion2020,2010,10,
Roberto Cipriani,Religion2020,1980,1,
Gabriele Camera,Cooperation2021,2005,4,
Brian Patrick McGuire,Religion2020,2005,2,
Daniel T. Blumstein,Cooperation2021,2015,4,
Michael Scott,Religion2020,2005,3,
Andrew Buckser,Religion2020,2010,2,
Adrian Kent,Cooperation2021,2000,6,
Dimitris Xygalatas,Religion2020,2010,18,
Aurélien Robert,Religion2020,2005,1,
Dick Anthony,Religion2020,1990,12,
Doug P. VanderLaan,Cooperation2021,2010,20,
Joseph B. Tamney,Religion2020,1980,7,
Bernard Spilka,Religion2020,1970,3,
Richard H. Popkin,Religion2020,1975,4,
Peter Kivisto,Religion2020,1995,3,
Michael Welker,Religion2020,2000,3,
Peter Admirand,Cooperation2021,2015,1,
Michele Dillon,Religion2020,1995,7,
James A. Tulsky,Religion2020,2010,12,
Eirini Eleni Tsiropoulou,Cooperation2021,2010,1,
Taneli Kukkonen,Religion2020,2010,26,
Jing Liu,Cooperation2021,2010,9,
Lene Kühle,Religion2020,2005,2,
Doug Oman,Cooperation2021,2015,1,
Brendan Carmody,Religion2020,2000,6,
H. Byron Earhart,Religion2020,1965,9,
Charles Hartshorne,Religion2020,2010,1,
George Pattison,Religion2020,2015,19,
Martyn Percy,Religion2020,1995,4,
Lukas Pokorny,Religion2020,2010,7,
Martin Ubani,Religion2020,2005,3,
John Hick,Cooperation2021,2005,1,
David Hay,Religion2020,1990,8,
Constantin Klein,Religion2020,2005,1,
Joseph Rivera,Religion2020,2015,13,
Kevin J. Flannelly,Religion2020,2010,19,
Andrew Wright,Religion2020,2000,7,
Samuel Stroope,CooperationAndReligion,2015,1,
Carmela Baffioni,Religion2020,2005,1,
Keith J. Edwards,Religion2020,2000,6,
Jules JANSSENS,Religion2020,2010,21,
David Jasper,Religion2020,2015,24,
David W. Johnson,Cooperation2021,2015,2,
H. A. Enno van Gelder,Religion2020,1960,13,
Kevin J. Christiano,Religion2020,2005,2,
Jan-Olav Henriksen,Religion2020,2000,1,
Cary J. Nederman,Religion2020,2015,2,
Jonathan Gratch,Cooperation2021,2010,4,
Randall Balmer,Religion2020,2005,2,
Marcus W. Feldman,Cooperation2021,2000,10,
Robert A. Segal,Religion2020,2005,10,
Dale F. Eickelman,Religion2020,1975,2,
Daniel Didomizio,Religion2020,1980,8,
Marius Nel,Cooperation2021,2015,1,
Mary T Sweat,Religion2020,2005,15,
Supa Pengpid,Religion2020,2010,5,
Iain R. Torrance,Religion2020,2015,4,
Carsten K.W. De Dreu,Cooperation2021,2000,4,
David W. Orme-Johnson,Religion2020,1970,1,
Zuoxin Wang,Cooperation2021,2000,7,
John Witte,Religion2020,2005,5,
C. N. L. Brooke,Religion2020,1950,3,
Michael Speca,Religion2020,2005,6,
Veit Bader,Cooperation2021,2000,2,
Cecilia Panti,Religion2020,2015,2,
Jane Anderson,Religion2020,2005,12,
Enzo Pace,Religion2020,2000,2,
Ina ter Avest,Religion2020,2010,30,
Owen Anderson,Religion2020,2005,11,
John C. Green,Religion2020,1995,5,
Wei Wang,Religion2020,2010,4,
Peter Admirand,Religion2020,2015,14,
Miguel Farias,Cooperation2021,2010,1,
Ernst Fehr,Religion2020,2005,1,
Pierre-Yves Brandt,Religion2020,2010,12,
James M. Day,Religion2020,1995,2,
Arnaud Delorme,Religion2020,2015,16,
Antonius Liedhegener,CooperationAndReligion,2010,1,
Jean-Yves Nau,Religion2020,2015,8,
John Milbank,Religion2020,1995,20,
Janet Martin Soskice,Religion2020,1990,9,
John Eade,Religion2020,1990,4,
David H Rosmarin,Religion2020,2010,16,
James Davis,Cooperation2021,2015,5,
Helen Hardacre,Religion2020,1980,3,
Patrick Collinson,Religion2020,1975,2,
Steven Engler,Religion2020,2000,6,
Daniel Callahan,Cooperation2021,1990,3,
Pam McGrath,Religion2020,1995,15,
John T. Ford,Religion2020,1970,1,
Philip L. De Jager,Religion2020,2015,17,
Shirley Telles,Religion2020,1990,2,
Alvin Plantinga,Religion2020,1995,4,
Douglas Pratt,Religion2020,2005,8,
Marc A. Musick,Religion2020,1995,8,
John Scheid,Religion2020,2000,7,
Åke Hultkrantz,Religion2020,2000,1,
Christopher C. H. Cook,Religion2020,2005,1,
Donald Capps,Religion2020,2015,5,
Jacqueline S. Mattis,Religion2020,2000,9,
David Robinson,Religion2020,2000,2,
W. R. Ward,Religion2020,1995,3,
Nirbhay N. Singh,Religion2020,2010,10,
Chris G. Sibley,Cooperation2021,2015,1,
Robert Boyd,Cooperation2021,1985,5,
Charles H. Lippy,Religion2020,2005,4,
Martha Frederiks,Religion2020,2000,2,
David F. Ford,Religion2020,1990,5,
Christopher P. Scheitle,Religion2020,2005,9,
John Hedley Brooke,Religion2020,2005,8,
Peter Koslowski,Religion2020,1990,1,
David Novak,Religion2020,1990,6,
Yamir Moreno,Cooperation2021,2005,4,
John A. Demetracopoulos,Religion2020,2010,65,
Arvind Sharma,Religion2020,2000,7,
Christopher Cullen,Religion2020,1980,1,
Fenggang Yang,Religion2020,2015,17,
Dimitris Xygalatas,Cooperation2021,2010,4,
Lisa Miller,Religion2020,2005,13,
Daniel P. Sulmasy,Religion2020,2010,8,
D Bellandi,Religion2020,2000,24,
Dirk Hutsebaut,Religion2020,2005,9,
Paul Tillich,Religion2020,1980,1,
Oded Stark,Religion2020,2010,3,
Oded Stark,Cooperation2021,2005,9,
Jacob Neusner,Religion2020,1995,8,
Clyde Wilcox,Religion2020,1995,6,
Sofia Sjö,Religion2020,2010,8,
Graham Rossiter,Religion2020,1985,4,
Hiroki Sayama,Cooperation2021,2000,2,
John H. Shaver,Cooperation2021,2015,6,
Henry Nelson Wieman,Religion2020,1930,5,
Kathryn Pitkin Derose,Religion2020,2005,3,
Simon Coleman,Religion2020,2005,30,
Aaron W. Hughes,Religion2020,2010,31,
Werner Güth,Cooperation2021,2000,13,
The Anh Han,Cooperation2021,2010,7,
J. N. Mohanty,Religion2020,1990,28,
J. Gwyn Griffiths,Religion2020,1960,1,
Ariel Dinar,Cooperation2021,2000,2,
Peter L. Berger,Religion2020,1975,6,
Denise Lardner Carmody,Religion2020,1980,15,
Daniel Callahan,Religion2020,1985,3,
Simon Gächter,Religion2020,2015,1,
Stephen D. Glazier,Religion2020,1990,7,
Roberto Cipriani,Religion2020,2000,3,
Ronald L. Grimes,Religion2020,1975,1,
Carles Salazar,Cooperation2021,2010,2,
Ian Reader,Religion2020,2005,6,
Deepak Chopra,Religion2020,2015,30,
Landon Schnabel,Religion2020,2015,14,
Robert S. Ellwood,Religion2020,2000,3,
Michael Stausberg,Religion2020,2010,25,
David L. Hudson,Religion2020,2005,24,
Lars Lien,Religion2020,2015,14,
Eliezer Witztum,Religion2020,1990,8,
Paul Wink,CooperationAndReligion,1995,1,
Ryan LaMothe,Religion2020,2000,7,
Pnina Werbner,Religion2020,2005,8,
John H. Shaver,Religion2020,2015,13,
Amy Adamczyk,Religion2020,2010,6,
Jonathan S. Abramowitz,Religion2020,2010,11,
Stewart M. Hoover,Religion2020,1990,6,
Daniel Houser,Cooperation2021,2000,3,
Diarmaid MacCulloch,Religion2020,2005,5,
Kevin Schilbrack,Religion2020,1995,4,
William Desmond,Religion2020,2000,6,
Victor Cohn,Cooperation2021,1975,15,
E E Griffith,Religion2020,1990,2,
David Carr,Religion2020,2015,4,
James J. Buckley,Religion2020,1985,6,
J. D. Y. Peel,Cooperation2021,1970,2,
Marcel Barnard,Religion2020,2015,18,
Kathleen McPhillips,Religion2020,2010,1,
Martin A. Nowak,Religion2020,2010,1,
Maxwell V. Rainforth,Religion2020,2010,6,
Abdulaziz Sachedina,Religion2020,2015,6,
Michael J. Reiss,Religion2020,2010,6,
Hans Schwarz,Religion2020,1980,2,
Rega Wood,Religion2020,2015,3,
Michael J. Wade,Cooperation2021,1975,4,
Winthrop S. Hudson,Religion2020,1970,1,
Harvey Whitehouse,Religion2020,2005,2,
Donald Capps,Religion2020,1995,13,
W. Brad Johnson,Religion2020,2010,4,
James A. Beckford,Religion2020,2005,2,
Michael Tomasello,Cooperation2021,2005,28,
Robert Boyd,Cooperation2021,2005,5,
Martin E. Marty,Religion2020,2000,4,
David B Larson,Religion2020,2000,40,
Graham Rossiter,Religion2020,2005,4,
Amy L. Ai,Religion2020,2010,21,
Maykel Verkuyten,Cooperation2021,2010,1,
Arthur Stephen McGrade,Religion2020,2010,43,
Vincent Goossaert,Religion2020,2005,18,
Robert A. Segal,Religion2020,1985,12,
Khusnul Khotimah,Religion2020,2010,4,
Lidia Lanza,Religion2020,2015,1,
Mirjam Künkler,Religion2020,2010,18,
Mary Jo Neitz,Religion2020,2005,2,
David G. Bromley,Religion2020,1990,7,
Patrick Sherry,Religion2020,1970,2,
JAMES L. COX,Religion2020,2015,5,
Aron S. Buchman,Religion2020,2010,15,
Kevin R. Foster,Cooperation2021,2010,8,
Lisa Sowle Cahill,Religion2020,1990,3,
Pascual Parrilla,Cooperation2021,2015,27,
Elizabeth A. Minton,CooperationAndReligion,2015,1,
Michael Taborsky,Cooperation2021,1995,1,
John T. Jost,Cooperation2021,2000,3,
Holly K. Oxhandler,Religion2020,2010,1,
Samuel L. Perry,Religion2020,2015,42,
Fraser Watts,Religion2020,1990,1,
Mingchu Li,Cooperation2021,2015,13,
Jeffrey S. Levin,Religion2020,2000,2,
George Arabatzis,Religion2020,2010,36,
Roland Littlewood,Religion2020,1980,3,
Thorsten Barnhofer,Religion2020,2010,18,
Jocelyne Cesari,Religion2020,2010,6,
Charles E. Butterworth,Religion2020,2005,1,
DAVID GRUMETT,Cooperation2021,2005,2,
Antonius Liedhegener,Religion2020,2010,13,
Aidan Nichols,Religion2020,2000,4,
Joan E. Strassmann,Cooperation2021,2005,8,
Jeffrey Haynes,Religion2020,1910,1,
Marian de Souza,Religion2020,2010,18,
David W. Orme-Johnson,Religion2020,2010,4,
Kalkuni Visweswaraiah Naveen,Religion2020,2000,6,
Chris Shilling,Religion2020,2000,6,
F. LeRon Shults,Cooperation2021,2015,2,
Bert Roebben,Religion2020,2015,6,
Will M. Gervais,Religion2020,2010,12,
Jeffrey K. Hadden,Religion2020,1970,5,
William H. Swatos,Religion2020,2005,9,
David R. Hodge,Religion2020,2000,14,
Pnina Werbner,Religion2020,1985,2,
Julie J. Exline,Religion2020,2015,45,
Christophe Jaffrelot,Religion2020,2015,12,
Brendan Hyde,Religion2020,2005,11,
Donald Capps,Religion2020,1975,7,
Jeff Astley,Religion2020,1985,1,
Zhu Han,Cooperation2021,2010,16,
ROBERT M. GRANT,Religion2020,1970,1,
Paul D. Molnar,Religion2020,2015,8,
Clive Marsh,Religion2020,1990,1,
Sidney E. Mead,Religion2020,1950,18,
Winnifred Fallers Sullivan,Religion2020,2015,9,
Asbjörn Medhus,Cooperation2021,1975,14,
Niels Henrik Gregersen,Religion2020,2000,6,
Albert Doja,Religion2020,2000,15,
Peter White,Religion2020,2000,2,
Hugo Simkin,Religion2020,2015,14,
SVENN LINDSKOLD,Cooperation2021,1975,5,
Jean S. Kutner,Cooperation2021,2015,3,
Abdulaziz Sachedina,Religion2020,1995,4,
René Descartes,Religion2020,1985,1,
Marco Casari,Cooperation2021,2000,1,
Michael E. McCullough,Religion2020,2010,10,
Lewis S. Ford,Religion2020,2005,1,
Jakub Urbaniak,Religion2020,2010,8,
Andreas Losch,Religion2020,2015,22,
Viren Swami,Religion2020,2010,12,
Donald Wiebe,Religion2020,2015,30,
Robert A. Thomson,Religion2020,2015,17,
Carole A. Rayburn,Religion2020,1980,8,
Armin W. Geertz,Religion2020,1980,2,
Jaffary Awang,Cooperation2021,2015,2,
Hans Schwarz,Religion2020,2000,4,
I Ketut Sudarsana,Cooperation2021,2015,1,
Joel Robbins,Religion2020,2005,5,
William K. Kay,Religion2020,2010,12,
Shauna L. Shapiro,Religion2020,2005,13,
Roy Wallis,Religion2020,1975,12,
Fiona Timmins,Religion2020,2015,31,
Benton Johnson,Religion2020,1960,2,
Ian Reader,Religion2020,1985,2,
Risto Saarinen,Religion2020,2015,17,
Ben J. Hatchwell,Cooperation2021,2000,5,
Cleophea Ferrari,Religion2020,2015,4,
Patricia E. Murphy,Religion2020,2010,4,
Jörg Rüpke,CooperationAndReligion,2005,1,
Aristotle Papanikolaou,Religion2020,2015,6,
Hans G. Kippenberg,Religion2020,1990,3,
Yaojun Li,Religion2020,2015,3,
Li Wang,Cooperation2021,2005,3,
Carel P. van Schaik,Cooperation2021,2005,1,
Jean Bethke Elshtain,Religion2020,2000,6,
Robert S. Ellwood,Religion2020,1980,3,
Roland Boer,Religion2020,2015,26,
Matthias Koenig,Religion2020,2015,13,
Phillip R. Shaver,Cooperation2021,2010,1,
Jaco Beyers,Religion2020,2010,14,
James T. Tedeschi,Cooperation2021,1985,1,
C. Robert Cloninger,Cooperation2021,2005,8,
Shailer Mathews,Religion2020,1920,3,
ROBERT M. GRANT,Cooperation2021,1995,1,
Jacques Waardenburg,Religion2020,1985,2,
Kun Yang,Cooperation2021,2010,13,
Barbara J. Culliton,Cooperation2021,1975,32,
Bryan D. Spinks,Religion2020,1990,4,
Darryl Macer,Cooperation2021,1990,20,
David C. Currow,Religion2020,2015,8,
Alastair Hamilton,Religion2020,2005,7,
Liam Gearon,Religion2020,1995,4,
Kelly R. Morton,Religion2020,2010,12,
Richard Rymarz,Religion2020,2005,8,
Marco Casari,CooperationAndReligion,2010,1,
David Lehmann,Religion2020,2005,6,
John D. Yoon,Religion2020,2015,17,
Anna Dreber,Religion2020,2010,4,
Mark Juergensmeyer,Religion2020,1980,2,
Stefania Palmisano,Religion2020,2010,5,
Ted Peters,Religion2020,2000,14,
Klaus Zuberbühler,Cooperation2021,2015,12,
Laurence R. Iannaccone,Religion2020,2000,2,
Peter Marshall,Religion2020,2010,6,
E. L. Mascall,Religion2020,1975,4,
Vanda Aramavičiūtė,Religion2020,2010,2,
John Wilson,Religion2020,1985,3,
Jeffrey C. Alexander,Religion2020,1995,6,
Bert Roebben,Religion2020,1995,1,
Paul F. Knitter,Religion2020,2005,3,
Amos N. Wilder,Religion2020,1950,6,
Pascal Boyer,CooperationAndReligion,1995,1,
Helen Rose Ebaugh,Religion2020,2005,4,
Norman Pettit,Religion2020,1970,7,
Thomas M. Izbicki,Religion2020,2000,1,
Allen D. Hertzke,Religion2020,1990,2,
Eamon Duffy,Religion2020,2015,2,
Hans-Georg Ziebertz,Religion2020,2005,4,
Rosalind I. J. Hackett,Religion2020,2005,6,
Ann Loades,Religion2020,1980,3,
Bernard McGINN,Religion2020,1975,2,
Rowan Williams,Religion2020,1990,2,
Candy Gunther Brown,Cooperation2021,2010,1,
Jack L. Seymour,Religion2020,2015,4,
Ernst M. Conradie,Religion2020,2015,10,
JAMES L. COX,Religion2020,1995,11,
Giuseppe Benagiano,Religion2020,2010,18,
Rodney Stark,Religion2020,2015,2,
Robert Joseph Taylor,Religion2020,2005,14,
Siebren Miedema,Religion2020,1990,1,
George Aaron Barton,Religion2020,1910,8,
István P. Bejczy,Religion2020,2010,26,
John Bowker,Religion2020,1970,4,
Brick Johnstone,Religion2020,2015,14,
Linda M. Chatters,Religion2020,2015,12,
Jeffrey A. Dusek,Religion2020,2010,12,
Frederick M. Smith,Religion2020,1995,2,
Anatol Rapoport,Cooperation2021,1985,5,
Frank D. Fincham,Cooperation2021,2010,1,
Lorenza S. Colzato,Religion2020,2015,22,
Simplice Asongu,Cooperation2021,2010,5,
William P. Alston,Religion2020,1990,7,
Tracy Anne Balboni,Religion2020,2010,26,
Ilona Kickbusch,Cooperation2021,2000,9,
Paul J. Griffiths,Religion2020,2000,3,
Massimo Leone,Cooperation2021,2000,3,
Oliver Leaman,Religion2020,2010,6,
Elisabeth Arweck,Religion2020,2000,3,
Shirley Otis-Green,Religion2020,2010,26,
David W. Orme-Johnson,Religion2020,1990,3,
Arvind Sharma,Religion2020,1980,2,
Yudhijit Bhattacharjee,Religion2020,2005,20,
Mircea Eliade,Religion2020,1985,2,
David Thang Moe,Religion2020,2010,1,
Aku Visala,Religion2020,2010,18,
Joaquim Soler,Religion2020,2010,5,
Siegfried Dewitte,Cooperation2021,2005,18,
Amnon Rapoport,Religion2020,1985,3,
Anatol Rapoport,Cooperation2021,1965,9,
Scott Atran,CooperationAndReligion,2010,6,
Gary Rodin,Religion2020,2010,13,
R. Ruard Ganzevoort,Religion2020,2010,11,
Graham Harvey,Religion2020,2000,1,
Elaine Howard Ecklund,Religion2020,2000,1,
Elvyda Martišauskienė,Religion2020,2015,18,
Elizabeth Johnston Taylor,CooperationAndReligion,2015,1,
Curtis W. Hart,Religion2020,2010,26,
Stanley Hauerwas,Religion2020,2010,6,
Clive Marsh,Religion2020,2010,9,
Jacques Waardenburg,Religion2020,2005,1,
Elaine Graham,Religion2020,2010,10,
Susumu Shimazono,Religion2020,1985,2,
Steven J. Sandage,Religion2020,2010,17,
James M. Dorsey,Religion2020,2010,4,
Joel Cabrita,Religion2020,2005,3,
Robert Brancatelli,Religion2020,2015,24,
ROBERT M. GRANT,Religion2020,1950,3,
David Brown,Religion2020,2015,13,
John Scheid,Religion2020,1980,1,
David Schultz,Religion2020,2005,24,
Michel Chapuisat,Cooperation2021,2010,7,
Steven Engler,CooperationAndReligion,2000,1,
Charles Y. Glock,Religion2020,1965,13,
Birger A. Pearson,Religion2020,2005,2,
Alvin Plantinga,Religion2020,2015,6,
Raymond F. Paloutzian,Religion2020,1975,1,
Kenneth Ira Pargament,Religion2020,2000,42,
Samuel L. Perry,CooperationAndReligion,2015,1,
Paul F. Knitter,Religion2020,1985,7,
Arjan W. Braam,Religion2020,2010,5,
Samuel Bowles,Cooperation2021,2000,27,
Luo-Luo Jiang,Cooperation2021,2015,21,
Paul C. Cooper,Religion2020,2010,17,
Nathan R Todd,Religion2020,2010,20,
H. Frankfort,Religion2020,1940,4,
Michel Despland,Religion2020,1980,7,
Mark J. Cartledge,Religion2020,2010,24,
Eamon Duffy,Religion2020,1995,4,
Patricia M. Davidson,Religion2020,2010,8,
Paul Helm,Religion2020,1980,5,
Amnon Rapoport,Cooperation2021,2005,18,
Owen Anderson,CooperationAndReligion,2005,1,
Alexander Moreira-Almeida,Religion2020,2015,17,
Marcel Barnard,CooperationAndReligion,2015,1,
James T. Richardson,Cooperation2021,1975,1,
Jing Li,Religion2020,2010,3,
Arndt Büssing,Religion2020,2005,17,
Clyde Wilcox,Religion2020,2015,7,
David Lester,Religion2020,2000,9,
Robert Merrihew Adams,Religion2020,1990,10,
W. Brad Johnson,Religion2020,1990,4,
Peter Kivisto,Religion2020,2015,24,
Lars Lien,Cooperation2021,2010,2,
J. Kwabena Asamoah-Gyadu,Religion2020,2005,10,
Fazlollah Ahmadi,CooperationAndReligion,2015,1,
Johannes A. Van der Ven,Religion2020,2005,1,
Benjamin E. Zeller,Cooperation2021,2010,1,
Yaacov J. Katz,Religion2020,1985,6,
Marcelo Saad,Religion2020,2015,18,
David De Cremer,Cooperation2021,2010,28,
Liam Gearon,Religion2020,2015,8,
Sidney E. Mead,Religion2020,1970,15,
Abdulkader Tayob,Religion2020,2005,1,
Nathan R. Kollar,Religion2020,2000,2,
Daniel Callahan,Religion2020,1965,6,
Stephen D. Glazier,Religion2020,2010,2,
Harold G. Koenig,Religion2020,1985,9,
Anthony B. Pinn,Religion2020,2000,10,
Olga Louchakova-Schwartz,Religion2020,2015,13,
Andrew Village,Religion2020,2010,18,
Ralph L. Piedmont,Religion2020,2005,22,
Ichiro Kawachi,Cooperation2021,2005,6,
John Wilson,Religion2020,1965,2,
H. J. Rose,Religion2020,1910,1,
Sumner B. Twiss,Religion2020,2010,2,
Mark Juergensmeyer,Religion2020,2000,5,
Wen-Xu Wang,Cooperation2021,2015,6,
Karl Peltzer,Cooperation2021,2015,2,
Gary D. Bouma,Religion2020,1980,2,
Stewart M. Hoover,Religion2020,2010,2,
Sian Cotton,Religion2020,2000,1,
Vincent Lloyd,Religion2020,2005,8,
Elisa Harumi Kozasa,Religion2020,2010,18,
Sarah Coakley,Religion2020,2005,18,
Julián García,Cooperation2021,2000,2,
John Hedley Brooke,Religion2020,1985,2,
Vhumani Magezi,Religion2020,2010,2,
David Novak,Religion2020,2010,7,
Matthew Clarke,CooperationAndReligion,2015,2,
Barbara Pesut,Religion2020,2005,28,
Kristoffer L. Nielbo,Religion2020,2015,9,
Ichiro Kawachi,CooperationAndReligion,2015,1,
Maxwell V. Rainforth,Religion2020,1990,4,
Victor Asal,Religion2020,2010,14,
Brian Leftow,Religion2020,2000,2,
Steven Stack,Religion2020,1990,4,
Wei Zhang,Cooperation2021,2015,12,
Edna B. Foa,Religion2020,2005,5,
Elinor Ostrom,Cooperation2021,2010,10,
Pablo Ramírez,CooperationAndReligion,2010,10,
Robert W. Hefner,Religion2020,1985,2,
Jacob Neusner,Religion2020,2015,3,
Robin I. M. Dunbar,Cooperation2021,2010,5,
James A. Beckford,Religion2020,1985,10,
Acharya Balkrishna,Religion2020,2015,24,
Joerg Rieger,Religion2020,2010,9,
Rosemary Radford Ruether,Religion2020,2010,7,
Herbert Benson,Religion2020,2000,7,
Grace Davie,Religion2020,2010,19,
Stephen Hunt,Religion2020,2010,17,
Robert Audi,Religion2020,1995,4,
Walter Brueggemann,Religion2020,1990,5,
Russell L. Friedman,Religion2020,2015,2,
Hubert Knoblauch,Religion2020,2005,2,
John Milbank,Religion2020,1975,4,
Robin Gill,Religion2020,1985,6,
Wade Clark Roof,Religion2020,1970,1,
Walter E. Conn,Religion2020,1985,4,
Terry Kit Selfe,Religion2020,2010,9,
Peer Scheepers,Religion2020,2015,20,
Richard Harding,Religion2020,2015,7,
Louis Dupré,Religion2020,1965,3,
Marcus Moberg,Religion2020,2010,12,
Stefan Schmidt,Religion2020,2010,12,
Marco Tomassini,Cooperation2021,2010,15,
Crystal L. Park,Religion2020,2000,2,
John Hick,Religion2020,2010,5,
Ara Norenzayan,CooperationAndReligion,2005,2,
Jill E. Bormann,Religion2020,2010,9,
Winthrop S. Hudson,Religion2020,1990,1,
Myrna M. Weissman,Religion2020,2010,7,
Fabrizio Amerini,Religion2020,2010,36,
Michael J. Reiss,CooperationAndReligion,1980,1,
John C. Green,Religion2020,2015,3,
Christopher G. Lis,Religion2020,2005,18,
François Gauthier,Religion2020,2010,16,
David Little,Religion2020,1995,6,
Gareth B. Matthews,Religion2020,2010,18,
HUGO MEYNELL,Religion2020,1970,10,
Wilfred McSherry,Religion2020,2005,6,
Edwin S. Gaustad,Religion2020,1955,1,
John E. Smith,Religion2020,1945,1,
R. E. S. Tanner,Religion2020,1955,12,
Andrea Bamberg Migliano,Cooperation2021,2010,2,
Christopher D. Rodkey,Religion2020,2010,16,
Holly Gwen Prigerson,Religion2020,2005,18,
Masoud Bahrami,Religion2020,2015,14,
Kevin N. Laland,Cooperation2021,2010,6,
David Carr,Religion2020,1995,3,
Russell T. McCutcheon,Religion2020,2000,6,
Christophe Erismann,Religion2020,2005,2,
Michael Northcott,Religion2020,1995,3,
Richard L. Gorsuch,Religion2020,1970,6,
Amanda Porterfield,Religion2020,1995,2,
Ian Wilks,Religion2020,2010,26,
Henrik Lagerlund,Religion2020,2010,59,
Bryan S. Turner,Religion2020,1975,6,
Christopher G. Ellison,Religion2020,2005,37,
David H. Jensen,Religion2020,2005,13,
Peter Marshall,Cooperation2021,2000,1,
Kathryn Lee Boyd,Religion2020,2015,16,
J. Gwyn Griffiths,Religion2020,1980,2,
Paul Harvey,Cooperation2021,2010,1,
Keith E. Yandell,Religion2020,1985,3,
Laszlo Zsolnai,Religion2020,2010,6,
Vassilis Saroglou,Cooperation2021,2010,3,
Phillip E. Hammond,Religion2020,1960,2,
Joseph Bracken,Religion2020,1990,3,
Nicholas A. Christakis,Cooperation2021,2010,20,
Laurent Cesalli,Religion2020,2015,1,
Rob Freathy,Religion2020,2015,25,
Lisa Sowle Cahill,Religion2020,2010,3,
Ninian Smart,Cooperation2021,1980,1,
Ronald L. Grimes,Religion2020,1995,5,
David W. Orme-Johnson,Cooperation2021,1985,1,
Roger Finke,Religion2020,2015,7,
Laura R. Olson,Religion2020,2015,11,
Amparo Oliver,Cooperation2021,2015,2,
Adam B. Seligman,Religion2020,1995,6,
Niels Christian Hvidt,Religion2020,2005,1,
James T. Richardson,Religion2020,1975,8,
Christian Smith,Cooperation2021,2010,1,
Philip L. Tite,Religion2020,2000,9,
Michael Taborsky,Cooperation2021,2015,20,
Jon R Stone,Religion2020,2015,1,
Paul Avis,Religion2020,1990,4,
Russell Sandberg,Religion2020,2010,12,
Andrew M. Greeley,Religion2020,1965,8,
Eric van Dijk,Cooperation2021,2005,27,
Mariano Barbato,Religion2020,2005,2,
Martin E. Marty,Religion2020,1980,5,
David B Larson,Religion2020,1980,6,
Heidemarie Kremer,Religion2020,2005,18,
Eliezer Witztum,Cooperation2021,2010,2,
Thomas J. Csordas,Religion2020,2000,5,
Christopher Key Chapple,Religion2020,1995,4,
E. Brooks Holifield,Religion2020,1990,2,
Myrna M. Weissman,Cooperation2021,1975,3,
Catherine L. Albanese,Religion2020,1980,8,
Eric L. Garland,Religion2020,2015,33,
Susan J. Palmer,Religion2020,2000,2,
HUGO MEYNELL,Religion2020,2010,11,
Rafael Domingo,Religion2020,2010,6,
M. M. Knappen,Religion2020,1945,3,
Kevin J. Christiano,Religion2020,1985,8,
William Gibson,Religion2020,2010,3,
Charles Hartshorne,Religion2020,1930,3,
Ian A. McFarland,Religion2020,2005,5,
H. W. S.,Religion2020,1960,2,
Zehavit Gross,Religion2020,2015,8,
Katie G. Cannon,Religion2020,2000,1,
Peter Beyer,Religion2020,1990,4,
Jonathan Jong,Cooperation2021,2015,3,
Christoph Stenschke,Religion2020,2010,8,
Antonio Ríos,Cooperation2021,2010,14,
Adrian Furnham,Religion2020,1990,8,
Jeffrey Haynes,Cooperation2021,2005,1,
Howard Bauchner,Cooperation2021,2010,2,
Sumner B. Twiss,Religion2020,1990,4,
Michael Argyle,Religion2020,2010,2,
Malcolm Brown,Religion2020,2005,12,
Joseph B. Tamney,Religion2020,1960,1,
Daniel P. Sulmasy,Religion2020,1990,3,
Jorge M. Pacheco,Cooperation2021,2010,25,
Merold Westphal,Religion2020,2010,6,
Richard Egan,Religion2020,2015,19,
Philip Clayton,Religion2020,2010,9,
Robert Merrihew Adams,Religion2020,1970,4,
Rod Macleod,Religion2020,2000,6,
Benjamin Beit-Hallahmi,Religion2020,2005,3,
Albrecht Classen,Religion2020,1995,1,
Peter M. Kappeler,Cooperation2021,1990,5,
John F. Miller,Religion2020,1965,4,
Massimo Leone,Religion2020,2010,51,
Tom Lenaerts,Cooperation2021,2005,14,
Larry J. Young,Cooperation2021,1995,4,
Richard Cross,Religion2020,2010,40,
Giuseppe Giordan,Religion2020,2015,14,
Mark Silk,Religion2020,2015,8,
Peter J. Verhagen,Religion2020,2015,6,
Bruce Ross,Religion2020,2000,5,
Colin Gunton,Religion2020,1975,4,
Philip L. Quinn,Religion2020,1980,1,
T. H. Barrett,Religion2020,1980,4,
Brandon Vaidyanathan,Religion2020,2010,5,
Robert Hastings Nichols,Religion2020,1935,10,
David Hay,Religion2020,2010,4,
John Maltby,Cooperation2021,2010,1,
Brian Skyrms,Cooperation2021,2005,6,
F Rosner,Religion2020,1965,5,
Rodney Stark,Religion2020,1975,2,
Steven Stack,Religion2020,2010,3,
James G. Emerson,Religion2020,2010,24,
Anthony J. Blasi,Religion2020,1990,3,
Fiona Timmins,CooperationAndReligion,2015,1,
Simon Coleman,Cooperation2021,1995,1,
Dale F. Eickelman,Religion2020,1995,4,
Christopher Ocker,Religion2020,2010,9,
Keith E. Yandell,Religion2020,2005,2,
Yori Gidron,Religion2020,2015,1,
David F. Ford,Religion2020,2010,7,
Lewis S. Ford,Religion2020,1965,9,
Adam Dinham,Religion2020,2005,1,
Jaco J. Hamman,Religion2020,2010,17,
Christopher P. Scheitle,CooperationAndReligion,2015,1,
Jonathan Jong,Religion2020,2015,22,
Seward Hiltner,Religion2020,1955,6,
Adam B. Seligman,Religion2020,2015,6,
Bruce Lincoln,Religion2020,2000,2,
Fenggang Yang,Religion2020,1995,1,
Charles Burnett,Religion2020,2010,27,
Keith G. Meador,Religion2020,2000,5,
Melissa Raphael,Religion2020,1995,10,
Dan J. Stein,Religion2020,2010,8,
Detlef Pollack,Religion2020,2005,4,
Oded Stark,Cooperation2021,1985,9,
John Hick,Religion2020,1990,21,
Aru Narayanasamy,Religion2020,2005,7,
Azim F. Shariff,Cooperation2021,2005,2,
Heather Walton,Religion2020,1990,3,
Michele Dillon,Religion2020,2015,7,
Sara W Lazar,Religion2020,2010,14,
Jonathan Chaplin,Religion2020,1995,2,
Michael J. Reiss,Cooperation2021,1985,1,
Andy Gardner,Cooperation2021,2000,2,
David Little,Religion2020,2015,5,
John Milbank,Religion2020,2015,12,
Robert W. Hefner,Religion2020,2005,3,
Traugott Roser,Religion2020,2000,1,
Marco Casari,Religion2020,2010,1,
Sam P. Brown,Cooperation2021,2010,12,
David M. Wulff,Religion2020,1965,2,
Vivienne Baumfield,Cooperation2021,2005,2,
Tamar Rudavsky,Religion2020,2015,1,
Scott Atran,Religion2020,2000,12,
Ana V. Diez Roux,Cooperation2021,2015,6,
W. R. Ward,Religion2020,1975,6,
H Tristram Engelhardt,Cooperation2021,2000,1,
Thomas Robbins,Religion2020,1975,7,
Shane Sinclair,Religion2020,2005,5,
Henry Nelson Wieman,Religion2020,1945,1,
Peter Adamson,Religion2020,2015,8,
Stephen Pattison,Religion2020,2005,8,
Myrna M. Weissman,Religion2020,1970,1,
Ernest M. Ligon,Religion2020,1950,14,
Yaacov J. Katz,Cooperation2021,2005,2,
Wei Li,Cooperation2021,2010,39,
Kenneth Surin,Religion2020,1985,14,
Peter van der Veer,Religion2020,2015,10,
Marienza Benedetto,Religion2020,2010,26,
Elaine Graham,Religion2020,1990,6,
Jack L. Seymour,Religion2020,1995,1,
JAMES L. COX,Religion2020,1975,1,
Shirley Jackson Case,Religion2020,1905,2,
Edwin S. Gaustad,Religion2020,1995,5,
Susumu Shimazono,Religion2020,2005,10,
Digant Gupta,Religion2020,2005,18,
Dean Tjosvold,Cooperation2021,2010,4,
Marian de Souza,CooperationAndReligion,2010,2,
Robert C. Fuller,Religion2020,1980,4,
David Brown,Religion2020,1995,1,
Ted G. Jelen,Religion2020,2000,8,
Abdulaziz Sachedina,Cooperation2021,2010,2,
Irwan Abdullah,Religion2020,2015,18,
David Voas,Religion2020,2000,4,
David Reiss,Cooperation2021,1990,3,
Farooq Naeem,Religion2020,2005,4,
Mark W. Elliott,Religion2020,2005,2,
Ursula McKenna,Religion2020,2015,14,
Alexandra Walsham,Religion2020,2010,14,
Peter W. Williams,Religion2020,1985,3,
R. Stephen Warner,Religion2020,1985,1,
Robert N. Bellah,Religion2020,1960,1,
Simon Dein,Religion2020,1990,4,
Dániel Z. Kádár,Religion2020,2010,55,
Daniel H. Levine,Religion2020,2010,2,
Richard McElreath,Religion2020,2015,2,
Sheridan Gilley,Religion2020,1980,6,
Anna Dreber,Cooperation2021,2010,28,
Bryan D. Spinks,Religion2020,2010,2,
Bonnie Spring,Religion2020,2010,22,
Travis Dumsday,Religion2020,2005,12,
Pamela Ebstyne King,Religion2020,2000,4,
Michael I. Posner,Religion2020,2015,14,
Birthe Loa Knizek,Religion2020,2015,6,
Willem B. Drees,Religion2020,2010,14,
Lewis R. Rambo,Religion2020,1985,1,
Peter Cumper,Religion2020,2005,6,
William B. Bean,Cooperation2021,1955,1,
Giancarlo Lucchetti,Cooperation2021,2010,1,
Symeon Papavassiliou,Cooperation2021,2015,13,
J. Milton Yinger,Religion2020,1975,2,
Karl Sigmund,Cooperation2021,1980,1,
Kathryn Pitkin Derose,Cooperation2021,2000,3,
Bryan S. Turner,Religion2020,2015,50,
Karen E. Steinhauser,Religion2020,2015,16,
Rufus A. Johnstone,Cooperation2021,2000,6,
E. L. Mascall,Religion2020,1955,4,
Roland Littlewood,Religion2020,1995,5,
Francis X. Clooney,Religion2020,2005,3,
Benton Johnson,Religion2020,1980,3,
Richard A. McCormick,Religion2020,1970,8,
Gustav J. Dobos,Religion2020,2010,18,
Todd W. Hall,Religion2020,2015,20,
J. Gwyn Griffiths,Religion2020,2015,1,
Francis Schüssler Fiorenza,Religion2020,1985,3,
Peter Lake,Religion2020,1990,4,
Göran Larsson,Religion2020,2000,1,
Johannes A. Van der Ven,Religion2020,1985,1,
Antonio Ríos,Religion2020,2010,15,
Michael Taborsky,Cooperation2021,1980,1,
Duncan B. Forrester,Religion2020,1985,1,
Carsten K. W. De Dreu,Cooperation2021,2010,18,
Paul A. M. Van Lange,Cooperation2021,2000,8,
Yi-Yuan Tang,Cooperation2021,2015,1,
Elizabeth Johnston Taylor,Religion2020,2010,12,
Judi Neal,Religion2020,2005,4,
J. D. Y. Peel,Religion2020,1970,3,
Richard H. Popkin,Religion2020,1995,2,
Quentin D. Atkinson,Cooperation2021,2015,16,
Dick Anthony,Religion2020,1970,3,
Ahmed Abdel-Khalek,Religion2020,2005,11,
Jamie D. Aten,Religion2020,2010,24,
Daniel H. Grossoehme,Religion2020,2005,5,
Daniel A. Helminiak,Religion2020,2010,4,
Joseph A. Bulbulia,CooperationAndReligion,2015,3,
Lindsay B. Carey,Religion2020,2010,5,
Ednan Aslan,Religion2020,2015,5,
H. Newton Malony,Religion2020,2000,4,
Michael Tomasello,Religion2020,2015,2,
H. J. Rose,Religion2020,1930,14,
Robert Jackson,Cooperation2021,2000,1,
Lorenzo Cohen,Religion2020,2000,1,
Kathryn Lofton,Religion2020,2000,1,
Luc Deliens,Religion2020,2010,32,
Rebecca Erwin Wells,Religion2020,2010,24,
Fergus Kerr,Religion2020,2000,6,
Wyatt MacGaffey,Religion2020,2000,4,
Wilhelm Gräb,Religion2020,1995,1,
Alejandro Frigerio,Religion2020,2000,8,
Eugene V. Gallagher,Religion2020,1990,1,
George Handzo,Religion2020,2000,4,
Heather Shipley,Religion2020,2010,4,
Peter Berger,Religion2020,1980,8,
Raymond F. Paloutzian,Religion2020,2015,10,
Kathleen Galek,Religion2020,2010,10,
Tami Borneman,Religion2020,2010,16,
Michel Despland,Religion2020,2000,5,
James J. Buckley,Religion2020,2000,2,
Urs Fischbacher,Cooperation2021,2000,14,
Michael Pye,Religion2020,2015,1,
Gregory Baum,Religion2020,1960,1,
Sanford Nidich,Religion2020,1995,1,
George Fitchett,Religion2020,1975,1,
John T. Jost,Religion2020,2010,3,
Keith Robbins,Religion2020,2010,1,
Tatsuya Sasaki,Cooperation2021,2005,1,
J. E. Wood,Religion2020,1965,4,
Michael J. Wade,Cooperation2021,2015,2,
David Fergusson,Religion2020,2015,11,
W. Muss-Arnolt,Religion2020,1900,9,
Edward B. Davis,Religion2020,1995,1,
Aasim I. Padela,Religion2020,2010,12,
Julia Ipgrave,Cooperation2021,2015,1,
Yoh Iwasa,Cooperation2021,2005,11,
Fraser Watts,Religion2020,2010,11,
Peter Marshall,CooperationAndReligion,2000,1,
Simo Knuuttila,Religion2020,1995,1,
Amos Yong,Religion2020,2000,6,
John T. Ford,Religion2020,2010,24,
Peer Scheepers,Cooperation2021,2010,1,
David R. Johnson,Religion2020,1985,1,
Phillip E. Hammond,Religion2020,1995,6,
Ronald Labonte,Cooperation2021,2005,30,
Russell L. Friedman,Religion2020,1995,1,
David Piché,Religion2020,2010,27,
R. David Hayward,Religion2020,2005,4,
Elad Schiff,Religion2020,2010,16,
Roland Boer,Religion2020,1995,2,
Maria Bigoni,Cooperation2021,2010,13,
Risto Saarinen,Religion2020,1995,1,
Nickolas P. Roubekas,Religion2020,2010,5,
John Wolffe,Religion2020,1995,3,
Lionel Obadia,Religion2020,2015,20,
Lionel Obadia,Cooperation2021,1970,1,
Joan Marques,Religion2020,2015,3,
Maryam Dilmaghani,Religion2020,2010,1,
Linda K. George,Religion2020,2015,1,
Marc Galanter,Religion2020,2000,6,
Leslie J. Francis,Religion2020,2000,36,
Andrew Rippin,Religion2020,2000,12,
Darren E. Sherkat,CooperationAndReligion,2000,1,
Birgit Meyer,Religion2020,2000,5,
Gary D. Bouma,Religion2020,2000,4,
Robert Jackson,Religion2020,1980,2,
Jeff Astley,Religion2020,2005,4,
Thijl Sunier,Religion2020,2015,13,
Joyce Ann Mercer,Religion2020,2005,7,
Simon Gächter,Cooperation2021,2005,4,
Philip Schwadel,Religion2020,2010,13,
Jonathan Jong,CooperationAndReligion,2015,1,
Nikki R. Keddie,Religion2020,1995,12,
Irving Hexham,Religion2020,2005,4,
Yan Liu,Religion2020,2015,2,
Jörg Rüpke,Religion2020,2015,33,
Ramón Alonso-Sanz,Cooperation2021,2005,10,
Rosaria Conte,Cooperation2021,2010,14,
Rupert John Kilcullen,Religion2020,2010,14,
Christopher Lo,Religion2020,2005,4,
Euan Cameron,Religion2020,2000,6,
Pascual Parrilla,CooperationAndReligion,2015,25,
Pablo Ramírez,Religion2020,2010,15,
Kevin McCabe,Cooperation2021,2010,2,
Michael Northcott,Religion2020,2015,7,
Donald Wiebe,Religion2020,1995,7,
Roland Phillips,Religion2020,1990,10,
Quentin D. Atkinson,Religion2020,2015,24,
Tracey E. Robert,Religion2020,2015,26,
Paul Weller,Religion2020,2015,4,
Larry VandeCreek,Religion2020,1995,11,
Thomas Robbins,Religion2020,2015,2,
Michael Pye,Religion2020,1975,6,
Amy B. Wachholtz,Religion2020,2005,12,
Peter Hopkins,Religion2020,2010,4,
Fraser Watts,Cooperation2021,2010,1,
Kaveh Madani,Cooperation2021,2010,46,
Rhys H. Williams,Religion2020,2005,6,
John Kelsay,Religion2020,2010,13,
Lei Yu,Religion2020,2015,16,
Peter L. Berger,Religion2020,2015,2,
Jeppe Sinding Jensen,Religion2020,1995,3,
Richard Sawatzky,Religion2020,2015,6,
Stephen J. Stein,Religion2020,1985,6,
Peter White,Cooperation2021,2010,2,
Todd L. Cherry,Cooperation2021,2015,15,
Clive D. Field,Religion2020,2000,4,
Nicholas A. Christakis,Cooperation2021,1990,2,
Walter E. Conn,Religion2020,2005,4,
Maria Bigoni,Religion2020,2010,1,
Steve Fuller,Religion2020,2005,3,
Börje Bydén,Religion2020,2010,22,
Peter Byrne,Religion2020,1980,3,
Christian Smith,Religion2020,1995,6,
Stuart A. West,Cooperation2021,2005,24,
Matthias Sutter,Cooperation2021,2000,8,
Ivan Strenski,Religion2020,2005,3,
Wade Clark Roof,Religion2020,1990,8,
Gregory D. Alles,Religion2020,2015,4,
Christopher Key Chapple,Religion2020,2015,8,
Cheryl L. Holt,Religion2020,2010,19,
Geir Skeie,Religion2020,2005,4,
Jonathan Fox,Religion2020,2015,29,
James T. Richardson,Religion2020,1995,10,
Merold Westphal,Religion2020,1995,6,
Harro Höpfl,Religion2020,2005,1,
David W. Macdonald,Cooperation2021,1990,2,
Luc Deliens,Cooperation2021,2010,2,
Christopher Alan Lewis,Religion2020,1990,10,
Luther H. Martin,Religion2020,2000,7,
Bhikhu Parekh,Religion2020,1990,6,
Colin Gunton,Religion2020,1990,5,
Kai Nielsen,Religion2020,1975,20,
Steven N. Durlauf,Cooperation2021,2010,14,
Christoph Engel,Cooperation2021,2015,7,
Jamin Halberstadt,CooperationAndReligion,2015,2,
Peter White,Religion2020,1980,2,
Irene Dingel,Religion2020,2015,6,
Philip Hefner,Religion2020,1960,3,
John R. Bowen,Religion2020,2010,4,
Jørn Borup,Religion2020,2015,30,
François Taddei,Cooperation2021,2010,9,
Vivienne Baumfield,Religion2020,2010,16,
Peter C. Phan,Religion2020,2000,8,
John Parratt,Religion2020,2010,4,
Antonio Ríos,CooperationAndReligion,2010,10,
Richard L. Gorsuch,Religion2020,1990,5,
J. Milton Yinger,Religion2020,2015,1,
Sergej Flere,Religion2020,2005,15,
Kevin S. Masters,CooperationAndReligion,2010,1,
Eric A. Storch,Religion2020,2010,4,
Ted Peters,Religion2020,1980,2,
Anders Klostergaard Petersen,Religion2020,2005,1,
Louis Putterman,Cooperation2021,2000,7,
Anatolii M. Kolodnyi,Religion2020,2005,26,
Phillip R. Shaver,Religion2020,1990,2,
E. Brooks Holifield,Religion2020,2005,1,
B Japsen,Religion2020,1990,9,
Farr A. Curlin,Religion2020,2015,24,
Mark Chapman,Religion2020,2010,20,
Stanley Hauerwas,Religion2020,1990,4,
Ursula King,Religion2020,1980,7,
Diarmaid MacCulloch,Religion2020,1985,2,
Jürgen Moltmann,Religion2020,1985,2,
Jerry H Gill,Religion2020,2010,8,
Gavin D'Costa,Religion2020,1990,9,
E. Ahmed,Cooperation2021,2010,4,
Nathan R. Kollar,Religion2020,1980,2,
Ania Loomba,Religion2020,2015,1,
David Martin,Religion2020,1995,11,
KIERAN FLANAGAN,Religion2020,1980,3,
Irene J. Higginson,Religion2020,2000,9,
Tony Richie,Religion2020,2005,13,
Teemu Taira,Religion2020,2015,16,
Jeffrey Haynes,Religion2020,2010,27,
Gerard Leavey,Religion2020,2015,7,
Martin A. Nowak,Cooperation2021,2005,37,
Graham Ward,Religion2020,2015,4,
Aviva Berkovich-Ohana,Religion2020,2010,6,
Hisham Abu-Raiya,Religion2020,2010,9,
B. Alan Wallace,Religion2020,2015,18,
Richard J. Davidson,Religion2020,2010,11,
Yan Liu,Cooperation2021,2010,11,
Li Zhang,Religion2020,2015,8,
Chris Baker,Cooperation2021,2005,1,
Peter L. Berger,Religion2020,1955,1,
Maurice Eisenbruch,Religion2020,1995,3,
John E. Smith,Religion2020,1965,3,
Gari Walkowitz,Cooperation2021,2015,18,
R. E. S. Tanner,Religion2020,1975,3,
Paul J. Griffiths,Religion2020,1980,1,
Paul Helm,Religion2020,2000,4,
Philip Hefner,Religion2020,2000,7,
Jerry Z. Park,Religion2020,2010,20,
William Adams Brown,Religion2020,1940,1,
Ronald Labonté,Cooperation2021,2005,8,
Edward P. Shafranske,Religion2020,2005,15,
David C. Queller,Cooperation2021,1995,10,
Iain T. Benson,Cooperation2021,2010,2,
Dean R. Hoge,Religion2020,1975,16,
Thomas G. Plante,Religion2020,2010,36,
John R. Peteet,Cooperation2021,1980,2,
Jeffrey A. Dusek,Religion2020,1995,3,
Kevin S. Masters,Religion2020,1990,3,
Maroš Servátka,Cooperation2021,2015,15,
Amnon Rapoport,Cooperation2021,1985,24,
Mircea Eliade,Religion2020,1965,8,
Daniel L. Overmyer,Religion2020,2005,2,
Jon Adams,Religion2020,2015,14,
Maarten J. F. M. Hoenen,Religion2020,2015,2,
Elżbieta Jung,Religion2020,2010,25,
Carole M. Cusack,Religion2020,2005,10,
Ronald J. Morris,Religion2020,2010,1,
Matthias Koenig,Cooperation2021,1995,1,
Arniika Kuusisto,Religion2020,2015,16,
David A. Bennett,Religion2020,2015,26,
Julia Ipgrave,Religion2020,2015,14,
C. Daniel Batson,Religion2020,1985,4,
Eveline A. Crone,Cooperation2021,2005,2,
Harold G. Koenig,Religion2020,2005,52,
Mary Grey,Religion2020,2015,3,
Yaacov J. Katz,Religion2020,2005,4,
John Fisher,Religion2020,1975,2,
JAMES L. COX,Cooperation2021,1975,1,
Mark A. Yarhouse,Religion2020,2000,8,
Charles E. Butterworth,Religion2020,1985,1,
Paul J. Mills,Cooperation2021,1980,3,
Ralph Del Colle,Religion2020,1990,4,
Louis Dupré,Religion2020,1985,7,
Walter Brueggemann,Religion2020,2010,4,
Neil Ormerod,Religion2020,2005,7,
Brandon Vaidyanathan,CooperationAndReligion,2010,1,
Terrence W. Tilley,Religion2020,2000,2,
David Morgan,Religion2020,2015,27,
Christopher F. Silver,Religion2020,2010,10,
Azim F. Shariff,Religion2020,2005,4,
Robert N. Bellah,Religion2020,2000,1,
George A. Coe,Religion2020,1900,7,
David W. Johnson,Religion2020,1990,2,
Jing Li,Cooperation2021,2010,10,
Daniel H. Levine,Religion2020,1970,1,
Oliver Leaman,Religion2020,1990,1,
Georgi Kapriev,Religion2020,2010,17,
Charlene P. E. Burns,Religion2020,2010,16,
Daniel Burston,Religion2020,2010,21,
Marjorie Mandelstam Balzer,Religion2020,2000,6,
Brian J. Grim,Religion2020,2015,16,
Bernard Doherty,Religion2020,2010,6,
JOSEPH M. KITAGAWA,Religion2020,1975,3,
James G. Emerson,Religion2020,1970,2,
Elinor Ostrom,Cooperation2021,1990,1,
Toshio Yamagishi,Cooperation2021,2000,4,
Siegfried Dewitte,Religion2020,2005,2,
Tatsuya Morita,Religion2020,2005,10,
Sarah F. Brosnan,Cooperation2021,2010,22,
Eleanor Nesbitt,Religion2020,1995,4,
Douglas A. MacDonald,Religion2020,1995,2,
John Riches,Religion2020,1990,2,
Gary E. Roberts,Religion2020,2015,22,
Rex Ahdar,Religion2020,2010,8,
Rosemary Radford Ruether,Religion2020,1990,8,
Bradford McCall,Religion2020,2010,70,
Steve Bruce,Religion2020,1980,6,
David Lester,Religion2020,1980,1,
David Lester,Cooperation2021,1975,1,
Alexander Agadjanian,Religion2020,2005,4,
Robert Merrihew Adams,Religion2020,2010,2,
Merold Westphal,Religion2020,1975,1,
Keith Ward,Religion2020,1975,1,
Allen E. Bergin,Religion2020,2000,5,
Eduardo Bruera,Religion2020,2005,3,
Edwin E. Aubrey,Religion2020,1935,1,
Enzo Pace,Religion2020,1980,1,
KIERAN FLANAGAN,Religion2020,1995,10,
Hans G. Kippenberg,Religion2020,2010,8,
George Karamanolis,Religion2020,2010,22,
Kamaldeep Bhui,Religion2020,2005,20,
Marjorie Mandelstam Balzer,Religion2020,1980,1,
Sean Gorman,Religion2020,2015,20,
Thilo Gross,Cooperation2021,2005,2,
Harold G. Koenig,Cooperation2021,2015,2,
Bhikhu Parekh,Religion2020,2010,6,
Hisashi Ohtsuki,Cooperation2021,2015,9,
John Parratt,Religion2020,1990,16,
Linda Ross,Religion2020,2015,16,
Philip E. Devenish,Religion2020,1980,6,
Daniel T. Blumstein,Cooperation2021,1995,6,
Nicolas Baumard,CooperationAndReligion,2015,4,
Roland Robertson,Religion2020,1965,1,
Carl E. Thoresen,Religion2020,2005,26,
Jean Bethke Elshtain,Religion2020,1980,2,
Arniika Kuusisto,Cooperation2021,2005,1,
Samta P. Pandya,Cooperation2021,2015,1,
Sigurd Bergmann,Religion2020,2010,1,
James H. Leuba,Religion2020,1895,1,
Alister E McGrath,Religion2020,1980,1,
Winnifred Fallers Sullivan,Religion2020,1995,8,
Mark A. Noll,Religion2020,2015,7,
Vito Fragnelli,Cooperation2021,2005,1,
Janet Martin Soskice,Religion2020,2010,3,
H. Byron Earhart,Religion2020,1985,4,
Paul D. Molnar,Religion2020,1995,6,
Robert W. McGee,Religion2020,2010,15,
Joannis Mylonopoulos,Religion2020,2010,14,
James T. Richardson,Religion2020,2015,15,
Mikel Burley,Religion2020,2010,6,
H. W. S.,Religion2020,1900,1,
William Schweiker,Religion2020,1985,5,
PASQUALE PORRO,Religion2020,2010,14,
Grace Davie,Cooperation2021,2010,1,
Meredith B. McGuire,Religion2020,1990,6,
Jon R. Webb,Religion2020,2010,14,
Barbara Keller,Religion2020,2010,3,
Virginia A. Kelly,Religion2020,2005,1,
Kate Miriam Loewenthal,Religion2020,1995,9,
Amos N. Wilder,Religion2020,1970,3,
Keith J. Edwards,Religion2020,1980,3,
George A. Coe,Religion2020,1920,5,
Robert Jackson,Religion2020,2000,5,
Jacob Neusner,Religion2020,1975,10,
Ryan LaMothe,Cooperation2021,2000,1,
Patsy Yates,Religion2020,2010,2,
Bernard McGINN,Religion2020,1995,4,
Kocku von Stuckrad,Religion2020,2015,7,
Juan Wang,Cooperation2021,2015,16,
Zhen Wang,Religion2020,2015,3,
Rowan Williams,Religion2020,2010,5,
Jerry H Gill,Religion2020,1970,40,
Jean S. Kutner,Religion2020,2015,12,
Mary Grey,Religion2020,2000,1,
Christoph Hauert,Cooperation2021,2010,15,
Andrew Louth,Religion2020,2010,30,
Oliver Leaman,CooperationAndReligion,1990,1,
Cary J. Nederman,Religion2020,1995,3,
Doug Oman,Religion2020,2015,30,
Rosaria Conte,Cooperation2021,1990,2,
Antonio M. Hernández,Religion2020,2015,24,
Leslie Francis,Religion2020,2010,7,
L. Philip Barnes,Religion2020,1990,2,
Simon Goldhill,Religion2020,2010,9,
Feng Wang,Cooperation2021,2015,12,
John Swinton,Religion2020,2005,7,
Heinz Streib,Religion2020,1995,4,
S. Brent Plate,Religion2020,2000,4,
Michelle J. Pearce,Religion2020,2000,6,
Benjamin Grant Purzycki,Religion2020,2005,1,
Paul Badham,Religion2020,2005,4,
Marc Galanter,CooperationAndReligion,1980,2,
Leonard J. Biallas,Religion2020,1970,1,
Rosemary Radford Ruether,Religion2020,1970,1,
Geoffrey Wainwright,Religion2020,1970,1,
Joshua A. Wilt,Religion2020,2015,68,
Julia Ipgrave,Religion2020,1995,1,
Christoph Auffarth,Religion2020,2015,2,
Jeffrey M. Perl,Religion2020,2010,2,
Nancey Murphy,Religion2020,1985,4,
Jacques Waardenburg,Religion2020,1965,2,
Jean-Paul Willaime,Religion2020,2000,4,
Philip G. Ziegler,Religion2020,2005,8,
Kimberly Georgedes,Religion2020,2005,1,
Cristiano Castelfranchi,Cooperation2021,1995,12,
John Eade,Religion2020,2010,4,
Michael Gurven,Cooperation2021,2000,6,
Stanley Hauerwas,Religion2020,1970,3,
John R. Peteet,Religion2020,2015,30,
P. Scott Richards,Religion2020,2000,20,
Paul J. Mills,Religion2020,1990,3,
John T. Ford,Religion2020,1990,1,
Dion Smyth,Religion2020,2015,1,
Frederick M Burkle,Religion2020,2015,4,
Ernst Fehr,CooperationAndReligion,2010,1,
Frank D. Fincham,Religion2020,2005,5,
James H. Leuba,Religion2020,1915,12,
Alister E McGrath,Religion2020,2000,7,
Ram A. Cnaan,Religion2020,1995,2,
Winthrop S. Hudson,Religion2020,1950,6,
John Fisher,Religion2020,2015,12,
Gareth B. Matthews,Religion2020,1970,1,
Sheridan Gilley,Cooperation2021,1970,1,
Chunyan Zhang,Cooperation2021,2015,13,
Mark A. Noll,Religion2020,1995,7,
Anna Fedele,Religion2020,2010,12,
James Davis,Religion2020,2015,15,
Rosalind I. J. Hackett,Religion2020,1985,6,
Silvio Ferrari,CooperationAndReligion,2010,1,
Wyatt MacGaffey,Religion2020,1980,5,
Matthew Levering,Religion2020,2010,5,
Robert H. Schneider,Religion2020,1995,7,
Ian T. Baldwin,Cooperation2021,2005,15,
Gerardo Muñoz,CooperationAndReligion,2015,3,
Patricia M. Davidson,Cooperation2021,2010,8,
John Bowker,Religion2020,1990,2,
Jørn Borup,Religion2020,1995,2,
William Desmond,Religion2020,1980,3,
Wei Chen,Religion2020,2015,2,
R James Long,Religion2020,2010,15,
Steven Kettell,Religion2020,2015,27,
Laura Galiana,Religion2020,2010,1,
June Boyce-Tillman,Religion2020,2015,14,
Siebren Miedema,Religion2020,2010,13,
David Piché,Religion2020,1995,1,
Richard L. Gorsuch,Religion2020,2010,5,
Sanford Nidich,Religion2020,1975,1,
Adam B. Cohen,CooperationAndReligion,2015,3,
Edward R. Canda,Religion2020,2015,3,
Aryeh Lazar,Religion2020,2005,4,
Todd M. Johnson,Religion2020,2000,3,
C. Daniel Batson,Cooperation2021,1990,2,
Lei Shi,Religion2020,2010,1,
Kim Knott,Religion2020,1980,2,
Volkhard Krech,Religion2020,2010,10,
David A. Leeming,Religion2020,2000,1,
Roe Fremstedal,Religion2020,2015,1,
Miguel Farias,CooperationAndReligion,2005,1,
James T. Tedeschi,Cooperation2021,1965,11,
Darryl Macer,Cooperation2021,2010,4,
Thomas Quartier,Religion2020,2015,2,
Ilona Kickbusch,Religion2020,2015,3,
Christophe Grellard,Religion2020,2010,30,
Yaojun Li,Cooperation2021,2010,1,
Mary Jo Neitz,Religion2020,1985,7,
Patrick Sherry,Religion2020,1990,2,
David B. Burrell,Religion2020,2005,6,
Paul Gifford,Religion2020,1995,10,
Gail Ironson,Religion2020,2005,8,
Liz Gwyther,Religion2020,2005,2,
James V. Spickard,Religion2020,2000,8,
James S. Gordon,Religion2020,2015,6,
James T. Robinson,Religion2020,2005,1,
Roland Robertson,Religion2020,1985,7,
David Tracy,Religion2020,1980,3,
Paul Tillich,Religion2020,1940,2,
Amy Adamczyk,Cooperation2021,2010,1,
Jonathan Jackson,Religion2020,2015,2,
Shailer Mathews,Religion2020,1900,7,
Naser Aghababaei,Religion2020,2010,13,
Andrew W. Arlig,Religion2020,2015,2,
David A. Bennett,Religion2020,2000,8,
Miguel Farias,Religion2020,2005,3,
David W. Lankshear,Religion2020,2010,6,
Amy M. Burdette,Religion2020,2005,22,
Neal M Krause,Religion2020,2000,7,
Myrna M. Weissman,Cooperation2021,2015,2,
LILY KONG,Religion2020,1995,2,
Guy G. Stroumsa,Religion2020,2010,5,
Li Zhang,Cooperation2021,2000,2,
Zining Yang,Cooperation2021,2015,15,
Steven N. Durlauf,Religion2020,2010,2,
Sana Loue,Religion2020,2010,16,
Gerald Birney Smith,Religion2020,1905,7,
Ninian Smart,Religion2020,1965,9,
Nicola Slee,Religion2020,2015,27,
Cristiano Castelfranchi,Cooperation2021,2015,4,
Gerardo Muñoz,Cooperation2021,2010,15,
Joan E. Strassmann,Cooperation2021,1985,3,
Christopher Schabel,Religion2020,2015,4,
Michael E. McCullough,Cooperation2021,2010,4,
James H. Fowler,Cooperation2021,2005,13,
Anthony Gill,Cooperation2021,1995,2,
Rohana Ulluwishewa,Religion2020,2015,4,
Jeffrey K. Hadden,Religion2020,1990,7,
Mark van Vugt,Cooperation2021,2010,13,
William H. Swatos,Religion2020,1985,7,
Gert Pickel,Religion2020,2000,5,
Asonzeh Ukah,Religion2020,2010,4,
Laurel Kendall,Religion2020,2000,3,
Kate Miriam Loewenthal,Religion2020,2015,8,
James L. Guth,Religion2020,2000,6,
Fazlollah Ahmadi,Religion2020,2005,2,
Carles Salazar,Religion2020,2010,4,
Christoph Auffarth,Religion2020,1995,4,
Dewey D. Wallace,Religion2020,1995,6,
Keith Ward,Religion2020,1995,3,
Pascal Boyer,Religion2020,2015,6,
Stephen Pattison,Religion2020,1985,1,
Tony Walter,Religion2020,2015,8,
Blake Victor Kent,Religion2020,2015,18,
Paul Harvey,Religion2020,2005,5,
Antonius Liedhegener,Cooperation2021,2010,1,
James F. Keenan,Religion2020,2000,4,
Peter van der Veer,Religion2020,1995,6,
Ihsan Yilmaz,Religion2020,2000,1,
Matthew Clarke,Religion2020,2010,6,
Yan Chen,Cooperation2021,2005,3,
Susan H. McFadden,Religion2020,1985,1,
Anson Shupe,Religion2020,2010,2,
Stephen J. Stein,Religion2020,2005,2,
KIERAN FLANAGAN,Religion2020,2015,6,
ROBERT M. GRANT,Religion2020,1990,2,
Bing-Hong Wang,Cooperation2021,2015,6,
Linda Woodhead,Religion2020,2000,2,
David W. Foy,Religion2020,2005,4,
Andrea C. Phelps,Religion2020,2005,4,
Ian Olver,Religion2020,2010,16,
Satinder Dhiman,Religion2020,2010,6,
Robert Audi,Religion2020,2010,8,
Julie A. Schneider,Religion2020,2000,1,
Leonard J. Biallas,Religion2020,1990,5,
C. N. L. Brooke,Religion2020,1990,3,
Geoffrey Wainwright,Religion2020,1990,5,
Mark Hill,Religion2020,2000,4,
Sarah Coakley,CooperationAndReligion,2010,6,
Sanjay Kalra,Religion2020,2010,9,
Linda K. George,Religion2020,1995,11,
Kim Knott,Religion2020,2000,2,
Dewey D. Wallace,Religion2020,1975,3,
David W. Johnson,Cooperation2021,1995,2,
C. Robert Cloninger,Religion2020,2005,12,
Paul A. Djupe,Religion2020,2000,4,
Steve Bruce,Religion2020,2000,9,
Richard McElreath,Cooperation2021,2015,8,
Mauro Zonta,Religion2020,2000,2,
Maurice Eisenbruch,Religion2020,2015,12,
Philipp M. Altrock,Cooperation2021,2010,10,
Angela Guidi,Religion2020,2010,17,
Daniel Didomizio,Religion2020,2000,4,
MARTA DOMINGUEZ DIAZ,Religion2020,2010,14,
Daniel H. Levine,Religion2020,1990,7,
Nancey Murphy,Religion2020,2005,12,
David C. Queller,Cooperation2021,2015,26,
Thomas G. Plante,Religion2020,1990,4,
Laurence R. Iannaccone,Cooperation2021,2010,2,
Patrick Sherry,Religion2020,2010,1,
Niels Henrik Gregersen,Religion2020,2015,24,
John E. Smith,Religion2020,1985,2,
Eleanor Nesbitt,Religion2020,2015,7,
C. N. L. Brooke,Religion2020,1970,3,
Erin K. Wilson,Religion2020,2010,15,
Bernard Spilka,Religion2020,2005,1,
H Tristram Engelhardt,Religion2020,1985,4,
Daniel L. Overmyer,Religion2020,1985,5,
James M. Day,Religion2020,2015,4,
Ram A. Cnaan,Religion2020,2015,12,
Albert Ellis,Religion2020,2005,6,
Åke Hultkrantz,Religion2020,1980,2,
Naoki Masuda,Religion2020,2015,2,
Barbara Hargrove,Religion2020,1975,5,
Jürgen Moltmann,Religion2020,2005,2,
Bernard McGINN,Religion2020,2015,3,
Manfred Milinski,Cooperation2021,2010,16,
Johan Buitendag,Religion2020,2005,10,
David Martin,Religion2020,2015,18,
Todd W. Hall,Religion2020,1995,20,
Anthony Egan,Religion2020,2005,5,
Andrew L. Whitehead,Cooperation2021,2010,1,
John Bowker,Religion2020,2010,10,
Marcia Grant,Religion2020,2005,10,
Colby Dickinson,Religion2020,2005,1,
David M. Wulff,Religion2020,2005,7,
Brendan Carmody,Religion2020,1980,6,
Edward R. Canda,Religion2020,1995,3,
Justin L. Barrett,Religion2020,2010,11,
Heinz Streib,Religion2020,2015,32,
Shirley Telles,Religion2020,2010,19,
Martyn Percy,Religion2020,2015,18,
Jordan J. Ballor,Religion2020,2005,2,
Pascal Boyer,Religion2020,1995,5,
Tony Walter,Religion2020,1995,2,
Katrina J. Debnam,Religion2020,2010,15,
W. D. Hudson,Religion2020,1970,2,
Charles H. Lippy,Religion2020,1985,4,
W. Brad Johnson,Cooperation2021,1995,2,
Russell Re Manning,Religion2020,2015,18,
Mark Silk,Religion2020,1995,4,
Keith J. Egan,Religion2020,2000,2,
Mia Lövheim,Religion2020,2005,1,
Ivo Jirásek,Religion2020,2010,2,
Daniel Burston,Religion2020,1990,1,
Lisa Miller,Cooperation2021,2015,1,
Patrick H. McNamara,Religion2020,1975,7,
Randall Balmer,Religion2020,1985,6,
Paul Gifford,Religion2020,2015,10,
Adrian Furnham,Cooperation2021,1985,1,
Paul Tillich,Religion2020,1960,7,
Michael W. Parker,Religion2020,2005,12,
Brock Bastian,Religion2020,2010,4,
Kevin Schilbrack,Religion2020,2015,13,
Alessandra Lamas Granero Lucchetti,Cooperation2021,2010,1,
F. LeRon Shults,Religion2020,2000,8,
William Sweet,Religion2020,2005,9,
Roland Robertson,Cooperation2021,2010,1,
Mark Popovsky,Religion2020,2005,2,
Pascual Parrilla,Religion2020,2010,15,
Allen E. Bergin,Religion2020,1980,5,
Henrik Lagerlund,Religion2020,2010,33,
Lorne L. Dawson,Religion2020,2005,6,
Aasim I. Padela,Cooperation2021,2010,1,
Ronald Hutton,Religion2020,2000,1,
Brick Johnstone,Cooperation2021,2015,1,
Jean-Paul Willaime,CooperationAndReligion,2005,1,
Bernard Williams,Religion2020,2010,1,
Noah Samuels,Religion2020,2015,16,
LILY KONG,Religion2020,2015,10,
Peter Lake,Religion2020,2010,5,
Johann-Albrecht Meylahn,Religion2020,2015,13,
Zhen Wang,Cooperation2021,2005,2,
JOHN D. CAPUTO,Religion2020,1995,2,
Daniel Callahan,Cooperation2021,1970,15,
Mark R. McMinn,Religion2020,1995,5,
Christoph Engel,Religion2020,2010,1,
Gerardo Muñoz,Religion2020,2010,15,
Egbert Bos,Religion2020,2010,22,
Gavin D'Costa,Religion2020,2010,21,
Daniel Ashlock,Cooperation2021,2010,12,
Patrick Collinson,Religion2020,1995,4,
John Cottingham,Religion2020,2015,10,
José A. Cuesta,Cooperation2021,2005,9,
Terrence W. Tilley,Religion2020,1980,2,
Marian de Souza,Cooperation2021,2005,2,
Gregory R. Peterson,Religion2020,2010,5,
Robert C. Fuller,Religion2020,2000,8,
Candy Gunther Brown,Religion2020,2010,7,
Mandy Robbins,Religion2020,2005,23,
James L. Guth,Religion2020,1980,1,
C. Daniel Batson,Cooperation2021,2010,4,
Helge Årsheim,Religion2020,2010,1,
D. Z Phillips,Religion2020,1985,2,
David W. Lankshear,Religion2020,1990,3,
Eliezer Witztum,Religion2020,2010,5,
Andrew J. Weaver,Religion2020,2000,21,
Edwin E. Aubrey,Religion2020,1955,5,
Laurel Kendall,Religion2020,1980,6,
Darren E. Sherkat,Religion2020,2000,10,
Chris G. Sibley,Religion2020,2010,15,
Jennifer D. Allen,Cooperation2021,2015,3,
Amy Adamczyk,CooperationAndReligion,2010,1,
Paul Vermeer,Religion2020,2005,5,
Michelle Pearce,Religion2020,2010,2,
Larry J. Young,Cooperation2021,2015,13,
JOSEPH M. KITAGAWA,Religion2020,1955,10,
John Kelsay,Religion2020,1890,1,
Irving Hexham,Religion2020,1985,16,
David Brown,Cooperation2021,1985,1,
Robert John Russell,Religion2020,1985,3,
Jörg Rüpke,Religion2020,1995,1,
William Schweiker,Religion2020,2005,8,
Andrew M. Colman,Cooperation2021,1980,5,
James R. Lewis,Religion2020,1985,2,
Alessandro Palazzo,Religion2020,2015,5,
J. D. Y. Peel,Religion2020,2010,3,
Philip L. Quinn,Religion2020,2000,10,
Marius Nel,Religion2020,2000,1,
Pehr Granqvist,Religion2020,2000,7,
Martin A. Nowak,CooperationAndReligion,2010,1,
Douglas A. MacDonald,Religion2020,2015,4,
John Riches,Religion2020,1970,4,
Thijl Sunier,Religion2020,1995,1,
Maykel Verkuyten,Religion2020,2015,14,
Joseph H. Fichter,Religion2020,1980,6,
Jianwei Huang,Cooperation2021,2005,3,
Richard A. McCormick,Cooperation2021,1995,2,
David R. Williams,Religion2020,1990,6,
Kelly Bulkeley,Religion2020,2000,4,
Wendy Cadge,Religion2020,2000,4,
John Armson,Religion2020,2010,4,
Ernst Fehr,Cooperation2021,2010,6,
Harald Walach,Religion2020,2015,14,
Adam B. Cohen,Cooperation2021,2015,3,
Harvey Whitehouse,Cooperation2021,2015,9,
David Lukoff,Religion2020,1990,2,
John T. Slotemaker,Religion2020,2015,7,
Frederick M. Burkle,Cooperation2021,2015,16,
Anna Halafoff,Religion2020,2015,17,
Helen Hardacre,Religion2020,2000,6,
Jing Liu,Religion2020,2015,1,
Stephen E. Lahey,Religion2020,2015,3,
Oliver D. Crisp,Religion2020,2000,3,
Angus Buckling,Cooperation2021,2010,28,
Jenny Berglund,Religion2020,2010,16,
Ralph Del Colle,Religion2020,2010,2,
Jennifer Huberty,Religion2020,2015,18,
Sanford Nidich,Religion2020,2010,7,
Ralph W. Hood Jr.,Religion2020,2005,2,
George Fitchett,Religion2020,2015,36,
James Bissett Pratt,Religion2020,1930,3,
Oleksandr N. Sagan,Religion2020,2000,5,
Celia Deane-Drummond,Religion2020,2005,8,
Friedrich Schweitzer,Religion2020,1995,4,
David Robinson,Religion2020,1980,4,
Yi-Yuan Tang,Religion2020,2005,2,
Veit Bader,Religion2020,1995,2,
George Aaron Barton,Religion2020,1925,2,
Ankur Barua,Religion2020,2010,8,
Graham Ward,Religion2020,1995,7,
Kenneth D. Wald,Religion2020,2000,12,
Don E. Davis,Religion2020,2005,3,
Genki Ichinose,Cooperation2021,2015,18,
Isabel Iribarren,Religion2020,2000,2,
Robin I. M. Dunbar,Religion2020,2015,3,
Jeffrey A. French,Cooperation2021,1985,3,
Ben Clements,Religion2020,2005,1,
Susannah Cornwall,Religion2020,2015,14,
William Adams Brown,Religion2020,1920,1,
Shirley Jackson Case,Religion2020,1945,1,
Paul J. Mills,Religion2020,2010,9,
Marcus W. Feldman,Cooperation2021,1980,6,
Jamin Halberstadt,Religion2020,2015,28,
Sung Joon Jang,Religion2020,2005,1,
Joshua B. Grubbs,Religion2020,2015,22,
Lodovico Balducci,Religion2020,2015,10,
David H. Rosmarin,Religion2020,2005,6,
Yolanda Dreyer,Religion2020,2005,16,
Ed Diener,Religion2020,2010,12,
Donald Wiebe,Religion2020,1975,5,
Charles Taliaferro,Religion2020,2000,6,
K. Helmut Reich,Religion2020,2000,12,
Paul A. Frewen,Religion2020,2015,15,
William P. Alston,Religion2020,1950,1,
Nancy T. Ammerman,Religion2020,2000,3,
Kevin S. Masters,Religion2020,2010,13,
Joseph B. Tamney,Religion2020,2000,1,
Daniel Philpott,Religion2020,2000,3,
Stephen H. Webb,Religion2020,2000,4,
Azim F. Shariff,CooperationAndReligion,2010,4,
Aziz Sheikh,Religion2020,2000,8,
Roy Wallis,Religion2020,2015,4,
Anthony J. Blasi,Religion2020,2010,5,
Peter Byrne,Religion2020,2000,1,
Ninian Smart,Religion2020,2000,1,
Joshua Furnal,Religion2020,2010,2,
Charles Hartshorne,Religion2020,1990,1,
Aya Hagishima,Cooperation2021,2010,13,
Ichiro Kawachi,Religion2020,2005,4,
Doug Oman,Religion2020,1995,2,
Leslie Francis,Religion2020,1990,1,
INGOLF U. DALFERTH,Religion2020,2000,3,
Maria Harris,Religion2020,1975,2,
Jonathan Fox,Religion2020,1995,5,
Karl Peltzer,Religion2020,2015,26,
Ota Pavlíček,Religion2020,2010,16,
Christian Smith,Religion2020,2015,9,
Tatjana Schnell,Religion2020,2015,14,
Paul J. Watson,Religion2020,1995,2,
Paul Badham,Religion2020,1985,2,
Angelos Chaniotis,Religion2020,2005,5,
Idhamsyah Eka Putra,Religion2020,2010,3,
Ellen L. Idler,Religion2020,2015,3,`

export default finalauthors;