const rankings = `LastRank,collectionName,pentaSliceEnd,pubCount
,Cooperation2021,1900,2
,Cooperation2021,1945,1
,Cooperation2021,1955,3
,Cooperation2021,1960,5
,Cooperation2021,1965,10
,Cooperation2021,1970,16
,Cooperation2021,1975,22
,Cooperation2021,1980,11
,Cooperation2021,1985,17
,Cooperation2021,1990,13
,Cooperation2021,1995,29
,Cooperation2021,2000,68
,Cooperation2021,2005,144
,Cooperation2021,2010,293
,Cooperation2021,2015,361
,CooperationAndReligion,1980,1
,CooperationAndReligion,1995,2
,CooperationAndReligion,2000,1
,CooperationAndReligion,2005,2
,CooperationAndReligion,2010,15
,CooperationAndReligion,2015,17
,Religion2020,1890,2
,Religion2020,1895,3
,Religion2020,1900,7
,Religion2020,1905,4
,Religion2020,1910,10
,Religion2020,1915,18
,Religion2020,1920,4
,Religion2020,1925,7
,Religion2020,1930,12
,Religion2020,1935,6
,Religion2020,1940,10
,Religion2020,1945,15
,Religion2020,1950,21
,Religion2020,1955,9
,Religion2020,1960,36
,Religion2020,1965,76
,Religion2020,1970,92
,Religion2020,1975,128
,Religion2020,1980,134
,Religion2020,1985,161
,Religion2020,1990,158
,Religion2020,1995,217
,Religion2020,2000,380
,Religion2020,2005,586
,Religion2020,2010,1101
,Religion2020,2015,1127
Adjunct Professor,Cooperation2021,1950,1
Adjunct Professor,Cooperation2021,1965,1
Adjunct Professor,Cooperation2021,1970,14
Adjunct Professor,Cooperation2021,1975,13
Adjunct Professor,Cooperation2021,1980,19
Adjunct Professor,Cooperation2021,1985,24
Adjunct Professor,Cooperation2021,1990,19
Adjunct Professor,Cooperation2021,1995,62
Adjunct Professor,Cooperation2021,2000,97
Adjunct Professor,Cooperation2021,2005,145
Adjunct Professor,Cooperation2021,2010,292
Adjunct Professor,Cooperation2021,2015,245
Adjunct Professor,CooperationAndReligion,1970,1
Adjunct Professor,CooperationAndReligion,1990,1
Adjunct Professor,CooperationAndReligion,2000,1
Adjunct Professor,CooperationAndReligion,2005,7
Adjunct Professor,CooperationAndReligion,2010,18
Adjunct Professor,CooperationAndReligion,2015,16
Adjunct Professor,Religion2020,1900,4
Adjunct Professor,Religion2020,1905,2
Adjunct Professor,Religion2020,1910,8
Adjunct Professor,Religion2020,1915,3
Adjunct Professor,Religion2020,1920,3
Adjunct Professor,Religion2020,1925,5
Adjunct Professor,Religion2020,1930,6
Adjunct Professor,Religion2020,1935,3
Adjunct Professor,Religion2020,1940,8
Adjunct Professor,Religion2020,1945,7
Adjunct Professor,Religion2020,1950,14
Adjunct Professor,Religion2020,1955,30
Adjunct Professor,Religion2020,1960,29
Adjunct Professor,Religion2020,1965,37
Adjunct Professor,Religion2020,1970,52
Adjunct Professor,Religion2020,1975,62
Adjunct Professor,Religion2020,1980,95
Adjunct Professor,Religion2020,1985,145
Adjunct Professor,Religion2020,1990,191
Adjunct Professor,Religion2020,1995,241
Adjunct Professor,Religion2020,2000,350
Adjunct Professor,Religion2020,2005,695
Adjunct Professor,Religion2020,2010,1457
Adjunct Professor,Religion2020,2015,1506
Assistant Professor,Cooperation2021,1930,1
Assistant Professor,Cooperation2021,1935,1
Assistant Professor,Cooperation2021,1945,1
Assistant Professor,Cooperation2021,1960,1
Assistant Professor,Cooperation2021,1970,9
Assistant Professor,Cooperation2021,1975,6
Assistant Professor,Cooperation2021,1980,21
Assistant Professor,Cooperation2021,1985,19
Assistant Professor,Cooperation2021,1990,10
Assistant Professor,Cooperation2021,1995,20
Assistant Professor,Cooperation2021,2000,72
Assistant Professor,Cooperation2021,2005,211
Assistant Professor,Cooperation2021,2010,504
Assistant Professor,Cooperation2021,2015,594
Assistant Professor,CooperationAndReligion,1990,1
Assistant Professor,CooperationAndReligion,2000,3
Assistant Professor,CooperationAndReligion,2005,3
Assistant Professor,CooperationAndReligion,2010,16
Assistant Professor,CooperationAndReligion,2015,41
Assistant Professor,Religion2020,1850,1
Assistant Professor,Religion2020,1895,1
Assistant Professor,Religion2020,1900,2
Assistant Professor,Religion2020,1905,7
Assistant Professor,Religion2020,1910,11
Assistant Professor,Religion2020,1915,1
Assistant Professor,Religion2020,1920,3
Assistant Professor,Religion2020,1925,4
Assistant Professor,Religion2020,1930,3
Assistant Professor,Religion2020,1935,10
Assistant Professor,Religion2020,1940,6
Assistant Professor,Religion2020,1945,4
Assistant Professor,Religion2020,1950,2
Assistant Professor,Religion2020,1955,4
Assistant Professor,Religion2020,1960,14
Assistant Professor,Religion2020,1965,12
Assistant Professor,Religion2020,1970,33
Assistant Professor,Religion2020,1975,32
Assistant Professor,Religion2020,1980,56
Assistant Professor,Religion2020,1985,92
Assistant Professor,Religion2020,1990,129
Assistant Professor,Religion2020,1995,148
Assistant Professor,Religion2020,2000,232
Assistant Professor,Religion2020,2005,659
Assistant Professor,Religion2020,2010,2022
Assistant Professor,Religion2020,2015,2946
Associate Professor,Cooperation2021,1960,2
Associate Professor,Cooperation2021,1965,7
Associate Professor,Cooperation2021,1970,13
Associate Professor,Cooperation2021,1975,9
Associate Professor,Cooperation2021,1980,18
Associate Professor,Cooperation2021,1985,18
Associate Professor,Cooperation2021,1990,31
Associate Professor,Cooperation2021,1995,57
Associate Professor,Cooperation2021,2000,168
Associate Professor,Cooperation2021,2005,499
Associate Professor,Cooperation2021,2010,1005
Associate Professor,Cooperation2021,2015,1077
Associate Professor,CooperationAndReligion,1980,1
Associate Professor,CooperationAndReligion,1985,1
Associate Professor,CooperationAndReligion,2005,20
Associate Professor,CooperationAndReligion,2010,70
Associate Professor,CooperationAndReligion,2015,110
Associate Professor,Religion2020,1905,2
Associate Professor,Religion2020,1910,7
Associate Professor,Religion2020,1915,2
Associate Professor,Religion2020,1925,1
Associate Professor,Religion2020,1930,3
Associate Professor,Religion2020,1935,2
Associate Professor,Religion2020,1940,2
Associate Professor,Religion2020,1945,2
Associate Professor,Religion2020,1950,6
Associate Professor,Religion2020,1955,15
Associate Professor,Religion2020,1960,18
Associate Professor,Religion2020,1965,32
Associate Professor,Religion2020,1970,29
Associate Professor,Religion2020,1975,37
Associate Professor,Religion2020,1980,100
Associate Professor,Religion2020,1985,142
Associate Professor,Religion2020,1990,210
Associate Professor,Religion2020,1995,436
Associate Professor,Religion2020,2000,829
Associate Professor,Religion2020,2005,1956
Associate Professor,Religion2020,2010,4290
Associate Professor,Religion2020,2015,5618
Graduate Student,Cooperation2021,2000,3
Graduate Student,Cooperation2021,2010,4
Graduate Student,Cooperation2021,2015,21
Graduate Student,CooperationAndReligion,2010,1
Graduate Student,CooperationAndReligion,2015,1
Graduate Student,Religion2020,1955,1
Graduate Student,Religion2020,1960,1
Graduate Student,Religion2020,1965,7
Graduate Student,Religion2020,1970,7
Graduate Student,Religion2020,1975,19
Graduate Student,Religion2020,1980,18
Graduate Student,Religion2020,1985,11
Graduate Student,Religion2020,1990,20
Graduate Student,Religion2020,1995,16
Graduate Student,Religion2020,2000,28
Graduate Student,Religion2020,2005,69
Graduate Student,Religion2020,2010,245
Graduate Student,Religion2020,2015,316
Non-Academic,Cooperation2021,1865,1
Non-Academic,Cooperation2021,1950,2
Non-Academic,Cooperation2021,1955,9
Non-Academic,Cooperation2021,1960,4
Non-Academic,Cooperation2021,1965,18
Non-Academic,Cooperation2021,1970,33
Non-Academic,Cooperation2021,1975,40
Non-Academic,Cooperation2021,1980,14
Non-Academic,Cooperation2021,1985,27
Non-Academic,Cooperation2021,1990,38
Non-Academic,Cooperation2021,1995,72
Non-Academic,Cooperation2021,2000,118
Non-Academic,Cooperation2021,2005,214
Non-Academic,Cooperation2021,2010,294
Non-Academic,Cooperation2021,2015,285
Non-Academic,CooperationAndReligion,1955,1
Non-Academic,CooperationAndReligion,1970,1
Non-Academic,CooperationAndReligion,1990,2
Non-Academic,CooperationAndReligion,1995,4
Non-Academic,CooperationAndReligion,2000,6
Non-Academic,CooperationAndReligion,2005,8
Non-Academic,CooperationAndReligion,2010,37
Non-Academic,CooperationAndReligion,2015,39
Non-Academic,Religion2020,1820,1
Non-Academic,Religion2020,1825,1
Non-Academic,Religion2020,1830,1
Non-Academic,Religion2020,1835,2
Non-Academic,Religion2020,1845,4
Non-Academic,Religion2020,1850,3
Non-Academic,Religion2020,1860,2
Non-Academic,Religion2020,1880,1
Non-Academic,Religion2020,1895,4
Non-Academic,Religion2020,1900,6
Non-Academic,Religion2020,1905,4
Non-Academic,Religion2020,1910,3
Non-Academic,Religion2020,1915,5
Non-Academic,Religion2020,1920,7
Non-Academic,Religion2020,1925,10
Non-Academic,Religion2020,1930,13
Non-Academic,Religion2020,1935,21
Non-Academic,Religion2020,1940,14
Non-Academic,Religion2020,1945,28
Non-Academic,Religion2020,1950,39
Non-Academic,Religion2020,1955,36
Non-Academic,Religion2020,1960,49
Non-Academic,Religion2020,1965,48
Non-Academic,Religion2020,1970,80
Non-Academic,Religion2020,1975,103
Non-Academic,Religion2020,1980,159
Non-Academic,Religion2020,1985,211
Non-Academic,Religion2020,1990,312
Non-Academic,Religion2020,1995,450
Non-Academic,Religion2020,2000,696
Non-Academic,Religion2020,2005,1445
Non-Academic,Religion2020,2010,2222
Non-Academic,Religion2020,2015,2452
Postdoctoral Fellow,Cooperation2021,1970,1
Postdoctoral Fellow,Cooperation2021,1975,1
Postdoctoral Fellow,Cooperation2021,1980,1
Postdoctoral Fellow,Cooperation2021,1985,5
Postdoctoral Fellow,Cooperation2021,1990,4
Postdoctoral Fellow,Cooperation2021,1995,13
Postdoctoral Fellow,Cooperation2021,2000,27
Postdoctoral Fellow,Cooperation2021,2005,42
Postdoctoral Fellow,Cooperation2021,2010,177
Postdoctoral Fellow,Cooperation2021,2015,270
Postdoctoral Fellow,CooperationAndReligion,2010,12
Postdoctoral Fellow,CooperationAndReligion,2015,26
Postdoctoral Fellow,Religion2020,1955,4
Postdoctoral Fellow,Religion2020,1960,5
Postdoctoral Fellow,Religion2020,1965,2
Postdoctoral Fellow,Religion2020,1970,2
Postdoctoral Fellow,Religion2020,1975,3
Postdoctoral Fellow,Religion2020,1980,5
Postdoctoral Fellow,Religion2020,1985,17
Postdoctoral Fellow,Religion2020,1990,14
Postdoctoral Fellow,Religion2020,1995,20
Postdoctoral Fellow,Religion2020,2000,42
Postdoctoral Fellow,Religion2020,2005,126
Postdoctoral Fellow,Religion2020,2010,435
Postdoctoral Fellow,Religion2020,2015,719
Professor,Cooperation2021,1875,1
Professor,Cooperation2021,1890,1
Professor,Cooperation2021,1895,15
Professor,Cooperation2021,1900,9
Professor,Cooperation2021,1905,1
Professor,Cooperation2021,1910,6
Professor,Cooperation2021,1915,6
Professor,Cooperation2021,1920,8
Professor,Cooperation2021,1925,6
Professor,Cooperation2021,1930,8
Professor,Cooperation2021,1935,6
Professor,Cooperation2021,1940,9
Professor,Cooperation2021,1945,11
Professor,Cooperation2021,1950,11
Professor,Cooperation2021,1955,19
Professor,Cooperation2021,1960,25
Professor,Cooperation2021,1965,60
Professor,Cooperation2021,1970,141
Professor,Cooperation2021,1975,184
Professor,Cooperation2021,1980,196
Professor,Cooperation2021,1985,308
Professor,Cooperation2021,1990,409
Professor,Cooperation2021,1995,802
Professor,Cooperation2021,2000,1574
Professor,Cooperation2021,2005,2471
Professor,Cooperation2021,2010,3728
Professor,Cooperation2021,2015,3359
Professor,CooperationAndReligion,1945,1
Professor,CooperationAndReligion,1950,1
Professor,CooperationAndReligion,1965,1
Professor,CooperationAndReligion,1970,1
Professor,CooperationAndReligion,1975,4
Professor,CooperationAndReligion,1980,5
Professor,CooperationAndReligion,1985,7
Professor,CooperationAndReligion,1990,10
Professor,CooperationAndReligion,1995,23
Professor,CooperationAndReligion,2000,41
Professor,CooperationAndReligion,2005,89
Professor,CooperationAndReligion,2010,142
Professor,CooperationAndReligion,2015,213
Professor,Religion2020,1850,1
Professor,Religion2020,1855,1
Professor,Religion2020,1875,9
Professor,Religion2020,1880,9
Professor,Religion2020,1885,13
Professor,Religion2020,1890,31
Professor,Religion2020,1895,66
Professor,Religion2020,1900,89
Professor,Religion2020,1905,85
Professor,Religion2020,1910,134
Professor,Religion2020,1915,131
Professor,Religion2020,1920,91
Professor,Religion2020,1925,154
Professor,Religion2020,1930,176
Professor,Religion2020,1935,142
Professor,Religion2020,1940,188
Professor,Religion2020,1945,243
Professor,Religion2020,1950,328
Professor,Religion2020,1955,308
Professor,Religion2020,1960,449
Professor,Religion2020,1965,601
Professor,Religion2020,1970,794
Professor,Religion2020,1975,941
Professor,Religion2020,1980,1346
Professor,Religion2020,1985,1943
Professor,Religion2020,1990,2611
Professor,Religion2020,1995,3654
Professor,Religion2020,2000,5578
Professor,Religion2020,2005,8859
Professor,Religion2020,2010,13873
Professor,Religion2020,2015,15624
Professor Emeritus,Cooperation2021,1925,3
Professor Emeritus,Cooperation2021,1930,5
Professor Emeritus,Cooperation2021,1940,4
Professor Emeritus,Cooperation2021,1945,2
Professor Emeritus,Cooperation2021,1950,11
Professor Emeritus,Cooperation2021,1955,18
Professor Emeritus,Cooperation2021,1960,21
Professor Emeritus,Cooperation2021,1965,67
Professor Emeritus,Cooperation2021,1970,148
Professor Emeritus,Cooperation2021,1975,167
Professor Emeritus,Cooperation2021,1980,133
Professor Emeritus,Cooperation2021,1985,162
Professor Emeritus,Cooperation2021,1990,176
Professor Emeritus,Cooperation2021,1995,254
Professor Emeritus,Cooperation2021,2000,310
Professor Emeritus,Cooperation2021,2005,334
Professor Emeritus,Cooperation2021,2010,341
Professor Emeritus,Cooperation2021,2015,224
Professor Emeritus,CooperationAndReligion,1955,2
Professor Emeritus,CooperationAndReligion,1970,1
Professor Emeritus,CooperationAndReligion,1975,1
Professor Emeritus,CooperationAndReligion,1980,3
Professor Emeritus,CooperationAndReligion,1985,4
Professor Emeritus,CooperationAndReligion,1990,3
Professor Emeritus,CooperationAndReligion,1995,11
Professor Emeritus,CooperationAndReligion,2000,9
Professor Emeritus,CooperationAndReligion,2005,20
Professor Emeritus,CooperationAndReligion,2010,21
Professor Emeritus,CooperationAndReligion,2015,21
Professor Emeritus,Religion2020,1840,1
Professor Emeritus,Religion2020,1850,1
Professor Emeritus,Religion2020,1890,1
Professor Emeritus,Religion2020,1895,8
Professor Emeritus,Religion2020,1900,24
Professor Emeritus,Religion2020,1905,9
Professor Emeritus,Religion2020,1910,37
Professor Emeritus,Religion2020,1915,18
Professor Emeritus,Religion2020,1920,34
Professor Emeritus,Religion2020,1925,35
Professor Emeritus,Religion2020,1930,38
Professor Emeritus,Religion2020,1935,35
Professor Emeritus,Religion2020,1940,51
Professor Emeritus,Religion2020,1945,68
Professor Emeritus,Religion2020,1950,93
Professor Emeritus,Religion2020,1955,164
Professor Emeritus,Religion2020,1960,278
Professor Emeritus,Religion2020,1965,498
Professor Emeritus,Religion2020,1970,754
Professor Emeritus,Religion2020,1975,1097
Professor Emeritus,Religion2020,1980,1197
Professor Emeritus,Religion2020,1985,1458
Professor Emeritus,Religion2020,1990,1732
Professor Emeritus,Religion2020,1995,1772
Professor Emeritus,Religion2020,2000,1858
Professor Emeritus,Religion2020,2005,2241
Professor Emeritus,Religion2020,2010,2833
Professor Emeritus,Religion2020,2015,2220
Researcher,Cooperation2021,1840,1
Researcher,Cooperation2021,1960,1
Researcher,Cooperation2021,1965,5
Researcher,Cooperation2021,1970,30
Researcher,Cooperation2021,1975,39
Researcher,Cooperation2021,1980,14
Researcher,Cooperation2021,1985,26
Researcher,Cooperation2021,1990,32
Researcher,Cooperation2021,1995,84
Researcher,Cooperation2021,2000,233
Researcher,Cooperation2021,2005,525
Researcher,Cooperation2021,2010,902
Researcher,Cooperation2021,2015,920
Researcher,CooperationAndReligion,1840,1
Researcher,CooperationAndReligion,1990,1
Researcher,CooperationAndReligion,1995,5
Researcher,CooperationAndReligion,2000,9
Researcher,CooperationAndReligion,2005,16
Researcher,CooperationAndReligion,2010,48
Researcher,CooperationAndReligion,2015,75
Researcher,Religion2020,1820,1
Researcher,Religion2020,1825,10
Researcher,Religion2020,1830,1
Researcher,Religion2020,1840,5
Researcher,Religion2020,1890,1
Researcher,Religion2020,1895,1
Researcher,Religion2020,1900,2
Researcher,Religion2020,1905,6
Researcher,Religion2020,1910,4
Researcher,Religion2020,1915,9
Researcher,Religion2020,1920,4
Researcher,Religion2020,1925,3
Researcher,Religion2020,1930,2
Researcher,Religion2020,1935,4
Researcher,Religion2020,1940,3
Researcher,Religion2020,1945,7
Researcher,Religion2020,1950,7
Researcher,Religion2020,1955,5
Researcher,Religion2020,1960,16
Researcher,Religion2020,1965,14
Researcher,Religion2020,1970,48
Researcher,Religion2020,1975,103
Researcher,Religion2020,1980,139
Researcher,Religion2020,1985,187
Researcher,Religion2020,1990,277
Researcher,Religion2020,1995,452
Researcher,Religion2020,2000,721
Researcher,Religion2020,2005,1355
Researcher,Religion2020,2010,2809
Researcher,Religion2020,2015,3384
Undergraduate Student,Cooperation2021,2000,1
Undergraduate Student,Cooperation2021,2015,2
Undergraduate Student,CooperationAndReligion,2015,1
Undergraduate Student,Religion2020,2005,5
Undergraduate Student,Religion2020,2010,16
Undergraduate Student,Religion2020,2015,23
`

export default rankings;