import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LDA from "./LDA";
import Author from "./Author";
import Institutional from "./Institutional";
import Network from "./Network";
import "../styles.css";
import Ethnicity from "./Ethnicity";
import Gender from "./Gender";
import Rank from "./Rank";
import Departments from "./Departments";
import colorsCitationCoop from "../assets/NewCoop1PercOrMoreRGBCodes";
import colorsCoAuthorCoop from "../assets/coauthor_cluster_colors";
import colorsCitationReligANDCoop from "../assets/ReligAndCoopRGBCodes"
import Network2 from "./Network2";
import About from "./About";
import Network3 from "./Network3";
import TermDegree from "./TermDegree";
import Network4 from "./Network4";
import rcCitationCodes from "../assets/RelAndCoopRGBCodes";


export default function App() {

    return (
        <div className={"box"}>
            <Tabs forceRenderTabPanel defaultIndex={0}>
                <TabList id={'main-list'}>
                    <Tab>Topic</Tab>
                    <Tab>Author</Tab>
                    <Tab>Ethnicity</Tab>
                    <Tab>Gender</Tab>
                    <Tab>Rank</Tab>
                    <Tab>Institution</Tab>
                    <Tab>Department</Tab>
                    <Tab>Network</Tab>
                    <Tab>Terminal Degree</Tab>
                    <Tab>About</Tab>
                </TabList>

                <TabPanel>
                    <Tabs forceRenderTabPanel>
                        <TabList id={'sub-list'}>
                            <Tab>Cooperation NOT Religion (k=11, C=0.59)</Tab>
                            <Tab>Religion and Cooperation (k=3, C=0.60)</Tab>
                            <Tab>R&C (k=6, C=0.53)</Tab>
                            <Tab>R&C (k=8, C=0.51)</Tab>
                        </TabList>
                        <TabPanel>
                            <LDA iframe={'lda_optimal_CooperationNotReligion.html'} isCnot={true}/>
                        </TabPanel>
                        <TabPanel>
                            <LDA iframe={'lda_optimal_CooperationANDReligion.html'} isCnot={false} ldaNum={2}/>
                        </TabPanel>
                        <TabPanel>
                            <LDA iframe={'6k_lda_optimal_CooperationANDReligion.html'} isCnot={false} ldaNum={3}/>
                        </TabPanel>
                        <TabPanel>
                            <LDA iframe={'8k_lda_optimal_CooperationANDReligion.html'} isCnot={false}/>
                        </TabPanel>
                    </Tabs>
                </TabPanel>
                <TabPanel>
                    <Author/>
                </TabPanel>
                <TabPanel>
                    <Ethnicity/>
                </TabPanel>
                <TabPanel>
                    <Gender/>
                </TabPanel>
                <TabPanel>
                    <Rank/>
                </TabPanel>
                <TabPanel>
                    <Institutional/>
                </TabPanel>
                <TabPanel>
                    <Departments/>
                </TabPanel>
                <TabPanel>
                    <Tabs forceRenderTabPanel>
                        <TabList id={'sub-list'}>
                            <Tab>Cooperation Citation</Tab>
                            <Tab>Religion And Cooperation Citation</Tab>
                            <Tab>Cooperation Coauthorship</Tab>
                            <Tab>Religion and Cooperation Coauthorship</Tab>
                        </TabList>
                        <TabPanel>
                            <Network data={colorsCitationCoop}/>
                        </TabPanel>
                        <TabPanel>
                            <Network4 data={rcCitationCodes}/>
                        </TabPanel>
                        <TabPanel>
                            <Network2 data={colorsCoAuthorCoop}/>
                        </TabPanel>
                        <TabPanel>
                            <Network3 data={colorsCitationReligANDCoop}/>
                        </TabPanel>
                    </Tabs>
                </TabPanel>
                <TabPanel>
                    <TermDegree/>
                </TabPanel>
                <TabPanel>
                    <About/>
                </TabPanel>
            </Tabs>
        </div>
    );
}