const rcCitationCodes = {
  "79": {
    "r": "0",
    "g": "93",
    "b": "52"
  },
  "6": {
    "r": "255",
    "g": "103",
    "b": "21"
  },
  "173": {
    "r": "255",
    "g": "171",
    "b": "119"
  },
  "34": {
    "r": "0",
    "g": "225",
    "b": "255"
  },
  "39": {
    "r": "110",
    "g": "213",
    "b": "241"
  },
  "38": {
    "r": "154",
    "g": "55",
    "b": "92"
  },
  "36": {
    "r": "67",
    "g": "89",
    "b": "143"
  },
  "54": {
    "r": "238",
    "g": "165",
    "b": "0"
  },
  "30": {
    "r": "195",
    "g": "207",
    "b": "98"
  },
  "101": {
    "r": "46",
    "g": "228",
    "b": "0"
  },
  "96": {
    "r": "255",
    "g": "132",
    "b": "255"
  }
}

export default rcCitationCodes