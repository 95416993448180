const institution = `instName,collectionName,pentaSliceEnd,pubCount
 All Souls College,Religion2020,2015,2
 Al-Quds University,Religion2020,2015,1
 Brandenburg Medical School Theodor Fontane,Religion2020,2015,2
 Düsseldorf Institute for Competition,Cooperation2021,2015,5
 Düsseldorf Institute for Competition,Religion2020,2015,1
 École Pratique des Hautes Études,Religion2020,2015,4
 Faraday Institute for Science and Religion,Religion2020,2015,9
 Harvest Bible College,Religion2020,2015,10
 I. M. Sechenov First Moscow State Medical University.,Religion2020,2015,1
 Isfahan University of Medical Sciences,Religion2020,2015,7
 Meir Medical Center,Religion2020,2015,1
 Michigan Center for Urban African American Aging Research Community Liaison Core,Religion2020,2015,1
 Open Stories Foundation,Religion2020,2015,6
 Pontificia Universidade Católica do Rio de Janeiro,Religion2020,2015,7
 Stuckinski Centre for Alzheimer’s Disease Care and Research,Religion2020,2015,1
 Tom Baker Cancer Centre,Religion2020,2015,3
 University of the District of Columbia,Religion2020,2015,3
 WHO Collaborating Centre for Research and Dissemination of Psychological Interventions,Religion2020,2015,4
 Xi’an Jiaotong University,Cooperation2021,2015,2
Angel Kanchev University of Ruse,Cooperation2021,2015,7
Angel Kanchev University of Ruse,Religion2020,2015,7
Aahrus University,Religion2020,2015,4
Aalto University School of Science and Technology,Cooperation2021,2015,5
Aalto University School of Science and Technology,Religion2020,2015,1
Aarhus University,Cooperation2021,2015,18
Aarhus University,CooperationAndReligion,2015,7
Aarhus University,Religion2020,2015,148
Aberdeen University,Cooperation2021,2015,1
Aberdeen University,CooperationAndReligion,2015,1
Aberdeen University,Religion2020,2015,1
Aberystwyth University,Religion2020,2015,8
Abilene Christian University,Religion2020,2015,3
Ables Editing Services,Religion2020,2015,2
abo akademi university,Religion2020,2015,1
Åbo Akademi University,Religion2020,2015,47
Academia Sinica,Cooperation2021,2015,7
Academy Ignatianum,Religion2020,2015,6
Academy of Finland,Religion2020,2015,7
Academy of Russian Medical Sciences,Religion2020,2015,2
Academy of Sciences of Albania,Religion2020,2015,1
Acadia University,Cooperation2021,2015,1
Acadia University,Religion2020,2015,2
Achva Academic College,Religion2020,2015,8
ACT of Living Psychological Services,Cooperation2021,2015,3
ACT of Living Psychological Services,Religion2020,2015,4
Acton Institute for the Study of Religion and Liberty,Religion2020,2015,2
Addis Ababa University,Religion2020,2015,7
Adelphi University,Religion2020,2015,6
Adler University,Religion2020,2015,5
Adnan Menderes University,Religion2020,2015,3
AdventHealth,Religion2020,2015,4
Adventist Health System,Religion2020,2015,1
Advocate Aurora Health Care,Religion2020,2015,4
Advocates for African American Elders,Religion2020,2015,2
AETHOS Consulting Group,Religion2020,2015,1
Africa Institute for Missiology,Religion2020,2015,8
Africa Mental Health Foundation (AMHF),Religion2020,2015,3
African Centre for Contemporary Studies,Cooperation2021,2015,1
African Centre for Contemporary Studies,Religion2020,2015,8
African Governance and Development Institute,Cooperation2021,2015,2
African Governance and Development Institute,Religion2020,2015,24
African Growth Institute,Cooperation2021,2015,1
African Growth Institute,Religion2020,2015,10
African Population and Health Research Center,Religion2020,2015,3
Aga Khan University,Cooperation2021,2015,1
Aga Khan University,Religion2020,2015,17
Agricultural Institute,Cooperation2021,2015,3
Airlangga University,Religion2020,2015,7
Aix-Marseille Université,Cooperation2021,2015,3
Aix-Marseille Université,Religion2020,2015,29
Al Akhawayn University,Religion2020,2015,10
Al al-Bayt University,Religion2020,2015,5
ALAN Analytics s.r.o,Cooperation2021,2015,1
ALAN Analytics s.r.o,Religion2020,2015,2
Albany Law School,Religion2020,2015,2
Albert Einstein College of Medicine,Cooperation2021,2015,1
Albert Einstein College of Medicine,Religion2020,2015,14
Alberta Health Services,Religion2020,2015,6
Albion College,Religion2020,2015,3
Alcohol Research Group,Religion2020,2015,3
Alfaisal University,Religion2020,2015,9
Al-Farabi Kazakh National University,Religion2020,2015,9
Alfred University,Religion2020,2015,2
Alkermes,Religion2020,2015,1
All India Institute of Medical Sciences,Cooperation2021,2015,1
All India Institute of Medical Sciences,CooperationAndReligion,2015,1
All India Institute of Medical Sciences,Religion2020,2015,36
All Souls College,Religion2020,2015,2
Allamaeh Tabataba'i University,Religion2020,2015,6
Allameh Tabataba'i University,Cooperation2021,2015,3
Allegheny College,Religion2020,2015,2
Alliant International University,Religion2020,2015,1
Alma College,Religion2020,2015,1
Alphacrucis College,Religion2020,2015,10
Alzheimer's Association,Religion2020,2015,5
Ambrose University,Religion2020,2015,12
American Cancer Society,Religion2020,2015,7
American College of Education,Religion2020,2015,3
American College Personnel Association,Religion2020,2015,3
American Council on Education,Religion2020,2015,2
American Enterprise Institute,Religion2020,2015,3
American Geographical Society,Religion2020,2015,1
American Institutes for Research,Religion2020,2015,1
American Museum of Natural History,Religion2020,2015,4
American Psychiatric Association,Religion2020,2015,3
American Psychological Association,Cooperation2021,2015,2
American Psychological Association,Religion2020,2015,1
American University,Religion2020,2015,22
American University of Beirut,Religion2020,2015,6
"American University of Iraq, Sulaimani",Religion2020,2015,3
American University of Kuwait,Religion2020,2015,5
American University of Sharjah,Religion2020,2015,3
American University of Sovereign Nations,Religion2020,2015,2
Amherst College,Religion2020,2015,3
Amherst H. Wilder Foundation,Religion2020,2015,5
Amniotics,Religion2020,2015,1
Amsterdam UMC,Cooperation2021,2015,1
Amsterdam UMC,Religion2020,2015,11
Andalas University,Cooperation2021,2015,1
Andalas University,Religion2020,2015,7
Andover Newton Theological School,Religion2020,2015,11
Anglia Ruskin University,Religion2020,2015,22
Anglo American University,Religion2020,2015,4
Anhui University of Technology,Cooperation2021,2015,8
Anhui University of Technology,Religion2020,2015,1
Annals of Internal Medicine,Cooperation2021,2015,10
Anscombe Bioethics Centre,Religion2020,2015,5
Antares Foundation,Religion2020,2015,3
Antonini Museum,Religion2020,2015,8
"Antwerp Management School, Breda University of Applied Sciences",Cooperation2021,2015,3
Antwerp University,Cooperation2021,2015,1
Antwerp University,Religion2020,2015,3
Aoyama Gakuin University,Cooperation2021,2015,13
Apex School of Theology,Religion2020,2015,1
Apollo Hospitals,Cooperation2021,2015,4
Apollo Hospitals,CooperationAndReligion,2015,4
Apollo Hospitals,Religion2020,2015,4
Appalachian State University,Cooperation2021,2015,16
Appalachian State University,Religion2020,2015,2
Arak University of Medical Sciences,Religion2020,2015,3
Archdiocese of Johannesburg,Religion2020,2015,1
"Archeological & Environmental Consultants, LLC",Religion2020,2015,4
Arctic University of Norway,Cooperation2021,2015,1
Arctic University of Norway,Religion2020,2015,1
Arden University,Religion2020,2015,3
Argentine Association for Psychobiological Research,Religion2020,2015,10
Argentine Catholic University,Religion2020,2015,2
Argonne National Laboratory,Cooperation2021,2015,7
Ariel University,Cooperation2021,2015,1
Ariel University,Religion2020,2015,30
Aristotle University of Thessaloniki,Religion2020,2015,11
Arizona State University,Cooperation2021,2015,43
Arizona State University,CooperationAndReligion,2015,7
Arizona State University,Religion2020,2015,146
ARK,Religion2020,2015,4
ARTIS International,Cooperation2021,2015,2
ARTIS International,CooperationAndReligion,2015,2
ARTIS International,Religion2020,2015,5
Asbury Theological Seminary,Cooperation2021,2015,1
Asbury Theological Seminary,CooperationAndReligion,2015,1
Asbury Theological Seminary,Religion2020,2015,45
Ashkelon Academic College,Religion2020,2015,6
Ashland University,Religion2020,2015,1
Ashoka University,Religion2020,2015,7
Asia Pacific University of Technology and Innovation,Religion2020,2015,2
Asia United Theological University,Religion2020,2015,2
Asia University,Cooperation2021,2015,7
Asia University,Religion2020,2015,1
ASL Salerno,Religion2020,2015,3
Associacao Medico-Espirita de Sao Paulo,Religion2020,2015,9
Association of Improvement for Mental Health Programmes,Cooperation2021,2015,2
Association of Improvement for Mental Health Programmes,Religion2020,2015,4
Association of Religion Data Archives,Religion2020,2015,1
Aston University,Religion2020,2015,12
Ateneo de Manila University,Cooperation2021,2015,1
Ateneo de Manila University,CooperationAndReligion,2015,1
Ateneo de Manila University,Religion2020,2015,6
Athens State University,Religion2020,2015,2
Atlantic School of Theology,Religion2020,2015,1
Auburn University,Cooperation2021,2015,1
Auburn University,Religion2020,2015,20
Auburn University at Montgomery,Religion2020,2015,8
Auckland University of Technology,Cooperation2021,2015,1
Auckland University of Technology,CooperationAndReligion,2015,1
Auckland University of Technology,Religion2020,2015,21
Augusta University,Cooperation2021,2015,1
Augusta University,Religion2020,2015,25
Augustana College,Religion2020,2015,2
Augustana University,Religion2020,2015,7
Austin Presbyterian Theological Seminary,Cooperation2021,2015,2
Austin Presbyterian Theological Seminary,Religion2020,2015,6
Australia Theological Hall,Religion2020,2015,1
Australian Catholic University,Cooperation2021,2015,8
Australian Catholic University,Religion2020,2015,112
Australian College of Applied Psychology,Religion2020,2015,1
Australian Council for Educational Research,Religion2020,2015,1
Australian Football League,Religion2020,2015,10
Australian National University,Cooperation2021,2015,13
Australian National University,Religion2020,2015,52
Australian Research Center for Complementary & Integrative Medicine [ARCCIM],Religion2020,2015,1
Austrian Academy of Sciences,Religion2020,2015,1
Autonomous University of Barcelona,Cooperation2021,2015,1
Autonomous University of Barcelona,Religion2020,2015,44
Avila University,Religion2020,2015,2
Awake to Wisdom Centre for Meditation and Mindfulness Research,Religion2020,2015,16
Awaris GMBH,Religion2020,2015,3
Ayurvedic Medical College,Religion2020,2015,1
Ayushman College,Religion2020,2015,1
Azabu University,Cooperation2021,2015,4
Azienda Ospedaliero-Universitari,Cooperation2021,2015,3
Azienda Unita Sanitaria Modena,Religion2020,2015,1
Azusa Pacific University,Cooperation2021,2015,1
Azusa Pacific University,Religion2020,2015,25
B.G. Rickhi Professional Corp.,Religion2020,2015,2
Babcock University,Cooperation2021,2015,1
Babcock University,Religion2020,2015,8
Bahiana School of Medicine and Public Health,Religion2020,2015,2
Ball State University,Religion2020,2015,4
Banaras Hindu University,Religion2020,2015,3
Bangor University,Religion2020,2015,16
Baptist Seminary of Kentucky,Religion2020,2015,4
Baptist Union of Victoria,Religion2020,2015,15
Baqiyatallah University of Medical Sciences,Religion2020,2015,34
Bar Ilan University,Cooperation2021,2015,3
Bar Ilan University,Religion2020,2015,50
Bard College,Religion2020,2015,6
Bar-Ilan University,Cooperation2021,2015,2
Bar-Ilan University,Religion2020,2015,117
Barnard College,Religion2020,2015,17
Barre Center for Buddhist Studies,Religion2020,2015,9
Barretos Cancer Hospital,Religion2020,2015,6
Barrington College,Religion2020,2015,1
Barrow Neurological Institute,Religion2020,2015,4
Barts Cancer Center,Cooperation2021,2015,1
Barts Cancer Center,Religion2020,2015,2
Bashkir State University,Religion2020,2015,18
Basic Health International,Cooperation2021,2015,3
Basic Health International,CooperationAndReligion,2015,3
Basic Health International,Religion2020,2015,4
Bastyr University,Religion2020,2015,5
Bates College,Religion2020,2015,7
Bath Spa University,Religion2020,2015,16
Bauer College of Business,Religion2020,2015,2
Baycrest Geriatric Centre,Religion2020,2015,4
Baylor College of Medicine,Cooperation2021,2015,5
Baylor College of Medicine,Religion2020,2015,31
Baylor University,Cooperation2021,2015,16
Baylor University,CooperationAndReligion,2015,5
Baylor University,Religion2020,2015,192
BC Centre for Palliative Care,Religion2020,2015,7
BE Works,Religion2020,2015,1
Beckman Research Institute of City of Hope,Religion2020,2015,9
Behavioral Health of the Palm Beaches,Religion2020,2015,6
Beihang University,Cooperation2021,2015,1
Beijing Foreign Studies University,Cooperation2021,2015,3
Beijing Foreign Studies University,Religion2020,2015,1
"Beijing Hospital of Traditional Medicine, Capital Medical Univeristy",Cooperation2021,2015,7
"Beijing Hospital of Traditional Medicine, Capital Medical Univeristy",Religion2020,2015,3
Beijing Institute of Technology,Cooperation2021,2015,4
Beijing Institute of Technology,Religion2020,2015,1
Beijing Municipal Institute of Labor Protections,Cooperation2021,2015,6
Beijing Municipal Institute of Labor Protections,Religion2020,2015,1
Beijing Normal University,Cooperation2021,2015,50
Beijing Normal University,Religion2020,2015,29
Beijing University of Posts and Telecommunications,Cooperation2021,2015,25
Bellvitge University Hospital,Religion2020,2015,3
Bemidji State University,Religion2020,2015,1
Ben Gurion University in the Negev,Religion2020,2015,13
Ben-Gurion University of the Negev,Cooperation2021,2015,3
Ben-Gurion University of the Negev,CooperationAndReligion,2015,2
Ben-Gurion University of the Negev,Religion2020,2015,30
Bentley University,Religion2020,2015,1
Berea College,Religion2020,2015,2
Berlin-Brandenburgischen Akademie der Wissenschaften,Religion2020,2015,2
Berry College,Religion2020,2015,2
Beth Israel Deaconess Medical Center,Religion2020,2015,1
Bethany College,Religion2020,2015,6
Bethel University,Religion2020,2015,10
Bethlem Royal Hospital,Religion2020,2015,2
Bexley Seabury Seminary Federation,Religion2020,2015,4
Bharath Hospital and Institute of Oncology,Religion2020,2015,14
BI Norwegian Business School,Cooperation2021,2015,2
BI Norwegian Business School,Religion2020,2015,1
Bible College of Malaysia,Religion2020,2015,3
biblical-theological academy,Religion2020,2015,7
Bielefeld University,Religion2020,2015,35
Bina Nusantra University,Religion2020,2015,8
Binghampton University,Cooperation2021,2015,6
Binghamton University,Religion2020,2015,2
Biola University,Religion2020,2015,53
Birbeck University of London,Religion2020,2015,4
"Birkbeck, University of London",Religion2020,2015,1
Birla Insitute of Technology and Science,Religion2020,2015,9
Birmingham Children's Hospital NHS Foundation Trust,Religion2020,2015,10
Birmingham City University,Cooperation2021,2015,10
Birmingham City University,Religion2020,2015,14
Birmingham Southern College,Religion2020,2015,5
Birmingham University,Religion2020,2015,3
Bishop Grosseteste University,Religion2020,2015,15
Bishop Kember School of Ministry,Religion2020,2015,6
Bishop of Guilford,Cooperation2021,2015,1
Bishop of Guilford,Religion2020,2015,3
Bishop's University,Religion2020,2015,5
Blackfox Philanthropy,Religion2020,2015,2
Bloomberg LP,Religion2020,2015,6
Bloomsburg University,Religion2020,2015,2
Blueprint 1543,Cooperation2021,2015,1
Blueprint 1543,CooperationAndReligion,2015,1
Blueprint 1543,Religion2020,2015,18
BM-Science - Brain & Mind Technologies Research Centre,Religion2020,2015,3
Bocconi University,Cooperation2021,2015,2
Boğaziçi University,Religion2020,2015,4
Boise State University,Religion2020,2015,1
Bon Secours Mercy Health,Religion2020,2015,1
Boston College,Cooperation2021,2015,11
Boston College,CooperationAndReligion,2015,1
Boston College,Religion2020,2015,78
Boston Medical Center,Religion2020,2015,3
Boston Museum of Fine Arts,Religion2020,2015,1
Boston University,Cooperation2021,2015,9
Boston University,Religion2020,2015,213
Boston University School of Medicine,Cooperation2021,2015,10
Boston University School of Medicine,Religion2020,2015,21
Bournemouth University,Cooperation2021,2015,4
Bournemouth University,Religion2020,2015,6
Bowdoin College,Religion2020,2015,1
Bowling Green State University,Cooperation2021,2015,2
Bowling Green State University,Religion2020,2015,41
Bradford Institute for Health Research,Cooperation2021,2015,1
Bradford Institute for Health Research,CooperationAndReligion,2015,1
Bradford Institute for Health Research,Religion2020,2015,1
Bradley University,Cooperation2021,2015,1
Bradley University,CooperationAndReligion,2015,1
Bradley University,Religion2020,2015,9
Brandeis University,Cooperation2021,2015,1
Brandeis University,Religion2020,2015,30
Brandon University,Religion2020,2015,3
Brawijaya University,Religion2020,2015,7
Bridgewater College,Cooperation2021,2015,6
Bridgewater College,Religion2020,2015,3
Brigham & Women's Hospital,Religion2020,2015,38
Brigham Young University,Cooperation2021,2015,4
Brigham Young University,CooperationAndReligion,2015,1
Brigham Young University,Religion2020,2015,126
"Bristol Institute of Technology, UWE",Cooperation2021,2015,7
Brite Divinity,Religion2020,2015,7
British Academy,Religion2020,2015,13
British Journal of Psychiatry,Religion2020,2015,6
British Medical Journal,Cooperation2021,2015,7
British Psychoanalytic Society,Religion2020,2015,3
Brock University,Cooperation2021,2015,3
Brock University,Religion2020,2015,28
Brookings Institution,Religion2020,2015,1
Brooklyn College,Religion2020,2015,1
Brooklyn University,Religion2020,2015,2
Brown University,Cooperation2021,2015,15
Brown University,Religion2020,2015,70
Brown University Medical School,Cooperation2021,2015,1
Brown University Medical School,Religion2020,2015,5
Brown University School of Public Health,Religion2020,2015,2
Bruce Rappaport Faculty of Medicine,Religion2020,2015,9
Brugmann University Hospital,Cooperation2021,2015,10
Brugmann University Hospital,Religion2020,2015,3
Brunel University London,Cooperation2021,2015,18
Brunel University London,CooperationAndReligion,2015,4
Brunel University London,Religion2020,2015,31
Bryant Temple AME Church,Religion2020,2015,2
Bryn Mawr College,Cooperation2021,2015,7
Bryn Mawr College,Religion2020,2015,2
Buck Institute,Cooperation2021,2015,2
Buckingham University Centre for Security and Intelligence Studies,Religion2020,2015,3
Bucknell University,Religion2020,2015,16
Buffalo State College,Cooperation2021,2015,5
Buffalo State College,Religion2020,2015,12
Bukovinian State Medical University,Religion2020,2015,7
Burgundy School of Business,Cooperation2021,2015,7
Burlington College,Religion2020,2015,3
Butler University,Religion2020,2015,19
C.G. Jung Institute of New York,Religion2020,2015,2
Ca' Foscari University,Cooperation2021,2015,1
CAFM Educational Research Alliance (CERA),Religion2020,2015,1
Cairnmiller institute,Religion2020,2015,1
Californa Institute of Integral Studies  California Institute of Integral Studies,Religion2020,2015,7
California Baptist University,Religion2020,2015,5
California Institute of Integral Studies,Religion2020,2015,14
California Institute of Technology,Cooperation2021,2015,11
California Lutheran University,Religion2020,2015,37
California State Polytechnic University,Cooperation2021,2015,1
California State Polytechnic University,Religion2020,2015,8
California State Polytechnic University-Pomona,Cooperation2021,2015,5
California State University,Religion2020,2015,10
California State University - Channel Islands,Religion2020,2015,3
"California State University, Chico",Cooperation2021,2015,1
"California State University, Chico",Religion2020,2015,1
"California State University, Dominguez Hills",Religion2020,2015,1
"California State University, Fresno",Religion2020,2015,4
"California State University, Fullerton",Cooperation2021,2015,4
"California State University, Fullerton",CooperationAndReligion,2015,2
"California State University, Fullerton",Religion2020,2015,13
"California State University, Long Beach",Cooperation2021,2015,1
"California State University, Long Beach",Religion2020,2015,9
"California State University, Los Angeles",Religion2020,2015,10
"California State University, Northridge",Cooperation2021,2015,1
"California State University, Northridge",Religion2020,2015,20
"California State University, Sacramento",Religion2020,2015,5
"California State University, Stanislaus",Religion2020,2015,2
Calvary Health Care,Religion2020,2015,4
Calvin College,Cooperation2021,2015,2
Calvin College,CooperationAndReligion,2015,2
Calvin College,Religion2020,2015,31
Calvin University,Cooperation2021,2015,2
Calvin University,CooperationAndReligion,2015,1
Calvin University,Religion2020,2015,29
Cambridge Health Alliance,Religion2020,2015,2
Cambridge University,Religion2020,2015,30
Canada Research Chairs,Religion2020,2015,1
Canadian Institute of Natural and Integrative Medicine,Religion2020,2015,2
Canadian Physiotherapy Association,Religion2020,2015,2
Cancer Institute NSW,Religion2020,2015,2
Cancer Prevention Research Center,Religion2020,2015,7
Cancer Research UK Beatson Institute,Cooperation2021,2015,3
CancerLife,Cooperation2021,2015,3
CancerLife,Religion2020,2015,2
Candler School of Theology,Religion2020,2015,2
Canterbury Christchurch University,Religion2020,2015,16
Canterbury District Health Board,Cooperation2021,2015,3
Canterbury District Health Board,Religion2020,2015,2
Cantonal Hospital,Religion2020,2015,2
Cape Breton University,Religion2020,2015,3
CAPS Methods Core,Cooperation2021,2015,2
CAPS Methods Core,Religion2020,2015,4
Cardiff University,Cooperation2021,2015,8
Cardiff University,CooperationAndReligion,2015,1
Cardiff University,Religion2020,2015,99
Cardozo School of Law,Religion2020,2015,1
Carleton College,Religion2020,2015,10
Carleton University,Religion2020,2015,12
Carlos III Health Institute,Religion2020,2015,1
Carnegie Mellon University,Cooperation2021,2015,7
Carnegie Mellon University,Religion2020,2015,8
Carolina Population Center  Carolina Population Center,Religion2020,2015,7
Carrick Institute for Graduate Studies,Religion2020,2015,4
Case Western Reserve University,Cooperation2021,2015,6
Case Western Reserve University,CooperationAndReligion,2015,1
Case Western Reserve University,Religion2020,2015,70
"Catholic Educational Institute, Salzburg",Religion2020,2015,10
Catholic Health Association,Religion2020,2015,5
Catholic Research and Development Centre,Religion2020,2015,3
Catholic Theological Union,Religion2020,2015,8
Catholic University of America,Religion2020,2015,49
Catholic University of Eichstätt-Ingolstadt,Religion2020,2015,6
Catholic University of Korea,Cooperation2021,2015,1
Catholic University of Korea,Religion2020,2015,13
Catholic University of Leuven,Religion2020,2015,16
Catholic University of Louvain,Religion2020,2015,5
Catholic University of Milan,Religion2020,2015,8
Catholic University of Pernambuco,Religion2020,2015,12
Catholic University of Portugal,Religion2020,2015,14
Catholic University of Sacred Heart,Religion2020,2015,4
Catholic University of the Sacred Heart,Cooperation2021,2015,14
Catholic University of the Sacred Heart,Religion2020,2015,6
Cedars-Sinai Medical Center,Cooperation2021,2015,1
Cedarville University,Cooperation2021,2015,1
Cedarville University,Religion2020,2015,2
Center for Adolescent Studies,Religion2020,2015,1
Center for Advanced Studies Oslo,Religion2020,2015,3
Center for Applied Research in the Apostolate,Religion2020,2015,5
Center for Care Delivery and Outcomes Research,Religion2020,2015,3
Center for Change,Religion2020,2015,8
Center for Critical Research on Religion,Religion2020,2015,4
"Center for Health Education, Medicine, & Dentistry",Religion2020,2015,14
Center for Healthy Minds,Religion2020,2015,3
"Center for Holistic Systems Analysis in Fairfield, Iowa",Religion2020,2015,1
Center for Inside-Out Understanding,Religion2020,2015,6
Center for Mind and Culture,Cooperation2021,2015,3
Center for Mind and Culture,CooperationAndReligion,2015,3
Center for Mind and Culture,Religion2020,2015,26
Center for Mindfulness and Contemplative Studies,Religion2020,2015,1
Center for Optimal Living,Religion2020,2015,6
Center for Professional Studies,Religion2020,2015,7
Center for Psychobiology of Personality,Religion2020,2015,5
Center for Psychosocial Research,Religion2020,2015,4
Center for Religion and Contemporary Society,Religion2020,2015,15
Center for Research and Advanced Studies of the National Polytechnic Institute,Cooperation2021,2015,1
Center for Research and Higher Studies in Social Anthropology,Religion2020,2015,13
Center for Research and Post Graduate Studies in Social Anthropology,Religion2020,2015,6
Center for Scientific Research,Cooperation2021,2015,2
Center for Southeast Asia Studies,Religion2020,2015,4
Center for Studies on New Religions,Religion2020,2015,6
Center for Taiji Studies,Cooperation2021,2015,4
Center for Taiji Studies,Religion2020,2015,5
Center for the Study on New Religions,Religion2020,2015,3
Center for Theological Inquiry,Religion2020,2015,2
Center for Trauma-Sensitive Faith Leadership,Religion2020,2015,1
Center of Innovation for Complex Chronic Healthcare,Religion2020,2015,3
Center to Advance Palliative Care,Religion2020,2015,2
Centered Living LLC,Religion2020,2015,3
Centers for Disease Control and Prevention,Religion2020,2015,2
Centers for Disease Control and Prevention- Division of HIV/AIDS Prevention,Cooperation2021,2015,1
Centers for Disease Control and Prevention- Division of HIV/AIDS Prevention,CooperationAndReligion,2015,1
Centers for Disease Control and Prevention- Division of HIV/AIDS Prevention,Religion2020,2015,2
Central European University,Cooperation2021,2015,4
Central European University,Religion2020,2015,2
Central Michigan University,Religion2020,2015,11
Central Queensland University,Cooperation2021,2015,2
Central Queensland University,Religion2020,2015,2
Central Research Institute for Physics,Religion2020,2015,1
Central South University,Cooperation2021,2015,12
Central South University,CooperationAndReligion,2015,1
Central South University,Religion2020,2015,2
Central University of Finance and Economics,Cooperation2021,2015,2
Central University of Finance and Economics,Religion2020,2015,1
Central University of Kerala,Religion2020,2015,7
Centre d'Ecologie Fonctionnelle et Evolutive,Cooperation2021,2015,2
Centre for Addiction and Mental Health,Cooperation2021,2015,5
Centre for Addiction and Mental Health,Religion2020,2015,10
Centre for Cellular & Molecular Biology,Religion2020,2015,2
Centre for Classical and Near Eastern Studies of Australia,Religion2020,2015,5
Centre for European Economic Research,Cooperation2021,2015,8
Centre for European Economic Research,Religion2020,2015,2
Centre for Posttraumatic Mental Health,Religion2020,2015,3
Centre for Research and Evidence on Security Threats,Religion2020,2015,16
Centre for Research and Professional Learning- University of Exeter,Religion2020,2015,1
Centre for Sensory Studies,Religion2020,2015,1
Centre for the Study of Developing Societies,Religion2020,2015,1
Centre for WTO Studies,Religion2020,2015,3
Centre Hospitalier Universitaire Vaudois,Religion2020,2015,10
Centre National de la Recherche Scientifique,Cooperation2021,2015,4
Centre National de la Recherche Scientifique,Religion2020,2015,33
Centre of Biomedical Research,Religion2020,2015,1
Centre Tricontinental (CETRI),Religion2020,2015,1
Centro Clinico NEMO,Religion2020,2015,1
Centro de Investigaciones Medicas Quirurgicas,Cooperation2021,2015,2
Centro de Investigaciones Medicas Quirurgicas,CooperationAndReligion,2015,2
Centro de Investigaciones Medicas Quirurgicas,Religion2020,2015,2
Centro di Riferimento Oncologico della Basilicata,Cooperation2021,2015,1
Centro Hospitalar do Algarve,Religion2020,2015,8
Centro Universitário Franciscano,Religion2020,2015,1
Centro Universitário São Camilo,Religion2020,2015,6
CERGE-E,Cooperation2021,2015,1
Cervera Real Estate,Religion2020,2015,2
Chalmers University of Technology,Cooperation2021,2015,8
Chamberlain University,Religion2020,2015,2
Chang Gung Medical Foundation,Religion2020,2015,3
Chang Gung Memorial Hospital at Linkou,Cooperation2021,2015,1
Chang Gung Memorial Hospital at Linkou,Religion2020,2015,6
Chang Gung University,Cooperation2021,2015,4
Chang Gung University,Religion2020,2015,7
Chang'an University,Cooperation2021,2015,9
Changwon National University,Religion2020,2015,16
Chapman University,Cooperation2021,2015,12
Chapman University,Religion2020,2015,12
Charite Universitatsmedizin Berlin,Religion2020,2015,3
Charité Universitätsmedizin Berlin,Cooperation2021,2015,1
Charité Universitätsmedizin Berlin,Religion2020,2015,18
Charles R. Drew University of Medicine & Science,Religion2020,2015,11
Charles Sturt University,Cooperation2021,2015,3
Charles Sturt University,CooperationAndReligion,2015,1
Charles Sturt University,Religion2020,2015,43
Charles University,Religion2020,2015,10
Charles University in Prague,Religion2020,2015,12
Chatham University,Religion2020,2015,2
Chelsea Art School,Religion2020,2015,3
Chennai School of Music Therapy,Religion2020,2015,1
Cheongju University,Religion2020,2015,2
Cherry Hill Seminary,Religion2020,2015,1
Cheung Kong Graduate School of Business,Cooperation2021,2015,5
Cheung Kong Graduate School of Business,Religion2020,2015,8
Chiang Mai University,Religion2020,2015,1
Chiba University,Religion2020,2015,5
Chicago Center for Family Health,Religion2020,2015,2
CHILD USA,Religion2020,2015,2
Children’s National Medical Center,Religion2020,2015,2
Children's Hospital Alabama,Religion2020,2015,2
Children's Hospital Boston,Cooperation2021,2015,1
Children's Hospital of Philadelphia,Cooperation2021,2015,12
Children's Hospital of Philadelphia,Religion2020,2015,4
Children's Mercy Kansas City,Religion2020,2015,2
Children's National Medical Center,Cooperation2021,2015,1
Children's National Medical Center,Religion2020,2015,5
Children's Wisconsin,Religion2020,2015,2
China Medical University,Cooperation2021,2015,6
China Medical University,Religion2020,2015,2
China University of Mining and Technology,Cooperation2021,2015,5
Chinese Academy of Sciences,Cooperation2021,2015,72
Chinese Academy of Sciences,Religion2020,2015,12
Chinese Academy of Social Sciences,Religion2020,2015,5
Chinese University of Hong Kong,Cooperation2021,2015,24
Chinese University of Hong Kong,CooperationAndReligion,2015,1
Chinese University of Hong Kong,Religion2020,2015,42
Chonbuk National University,Religion2020,2015,3
Chongqin University,Cooperation2021,2015,2
Chongqin University,Religion2020,2015,5
Chongqing Institute of Green and Intelligent Technology,Cooperation2021,2015,4
Chongqing Institute of Green and Intelligent Technology,Religion2020,2015,2
Chopra Center,Religion2020,2015,5
Christian Brothers University,Cooperation2021,2015,1
Christian Brothers University,Religion2020,2015,3
Christian Council on Ageing,Religion2020,2015,1
Christian Michelsen Institute,Religion2020,2015,4
Christian Service University College,Religion2020,2015,8
Christian Theological Seminary,Religion2020,2015,1
Christopher Newport University,Religion2020,2015,7
Chulalongkorn University,Religion2020,2015,2
Chung Shan Medical University,Religion2020,2015,1
Chungbuk National University,Religion2020,2015,2
Church Mission Society,Religion2020,2015,6
Church of England,Religion2020,2015,38
Church of Scotland,Religion2020,2015,1
Church of Sweden,Religion2020,2015,2
Church Research Institute,Religion2020,2015,4
CICERO Center for International Climate Research,Cooperation2021,2015,5
Cincinnati Children's Hospital Medical Center,Cooperation2021,2015,2
Cincinnati Children's Hospital Medical Center,Religion2020,2015,9
CIRANO,Cooperation2021,2015,3
City College of New York- Brooklyn College,Cooperation2021,2015,1
City College of New York- Brooklyn College,Religion2020,2015,2
City College of New York Hunter College,Religion2020,2015,6
City of Hope National Medical Center,Cooperation2021,2015,3
City of Hope National Medical Center,Religion2020,2015,25
City University London,Cooperation2021,2015,10
City University London,Religion2020,2015,3
City University of Hong Kong,Cooperation2021,2015,4
City University of Hong Kong,Religion2020,2015,4
City University of New York,Cooperation2021,2015,3
City University of New York,Religion2020,2015,31
City University of New York Graduate Center,Cooperation2021,2015,1
City University of New York Graduate Center,Religion2020,2015,7
Claremont Graduate University,Cooperation2021,2015,10
Claremont Graduate University,Religion2020,2015,36
Claremont McKenna College,Religion2020,2015,3
Claremont school of theology,Religion2020,2015,20
Clark University,Religion2020,2015,9
Claude Bernard University Lyon 1,Religion2020,2015,24
Clemson University,Cooperation2021,2015,4
Clemson University,Religion2020,2015,70
Clermont Graduate University,Religion2020,2015,6
Cleveland Clinic,Cooperation2021,2015,5
Cleveland Clinic,CooperationAndReligion,2015,3
Cleveland Clinic,Religion2020,2015,26
Cleveland State University,Religion2020,2015,21
Cliff College,Religion2020,2015,6
Clinic for Senology,Religion2020,2015,5
Clinic SGM Langenthal and Research Institute for Spirituality and Health,Religion2020,2015,6
Clinica Daniel Lerario,Religion2020,2015,9
Clinical Psychologist,Religion2020,2015,2
Clinics Essen - Mitte,Religion2020,2015,30
CMTF UP in Olomouc,Religion2020,2015,4
CNRS Comité national de la recherche scientifique,Cooperation2021,2015,14
CNRS Comité national de la recherche scientifique,CooperationAndReligion,2015,2
CNRS Comité national de la recherche scientifique,Religion2020,2015,19
CNRS Délégation Rhône-Auvergne,Cooperation2021,2015,1
Coalition for Compassionate Care of California,Religion2020,2015,5
Colby College,Religion2020,2015,6
Colgate University,Religion2020,2015,5
Collaborative Caring,Religion2020,2015,5
Collège de France,Religion2020,2015,20
College of Charleston,Religion2020,2015,11
College of Charlestown,Religion2020,2015,7
College of Communication Engineering,Cooperation2021,2015,6
College of Dentistry,Religion2020,2015,1
College of France,Religion2020,2015,2
College of Holy Cross,Religion2020,2015,6
College of Idaho,Religion2020,2015,11
College of St. Rose,Religion2020,2015,1
College of St. Scholastica,Religion2020,2015,8
College of the Holy Cross,Religion2020,2015,4
College of Wooster,Religion2020,2015,4
Collegiate Church of st Mary,Religion2020,2015,9
Collegium Helveticum,Religion2020,2015,7
Colorado School of Mines,Religion2020,2015,1
Colorado State University,Cooperation2021,2015,6
Colorado State University,Religion2020,2015,22
Columbia Theological Seminary,Religion2020,2015,12
Columbia University,Cooperation2021,2015,16
Columbia University,CooperationAndReligion,2015,3
Columbia University,Religion2020,2015,195
Columbia University Medical Center,Religion2020,2015,7
Columbus State University,Cooperation2021,2015,1
Columbus State University,Religion2020,2015,7
Coma Science Group,Religion2020,2015,3
Comenius Institute,Religion2020,2015,5
Comenius University,Cooperation2021,2015,1
Comenius University,Religion2020,2015,3
Community Care Western Cheshire,Religion2020,2015,2
Compass Health Research Institute,Religion2020,2015,1
Complexity Science Hub Vienna,Cooperation2021,2015,3
Complexity Science Hub Vienna,Religion2020,2015,6
Complutense University of Madrid,Cooperation2021,2015,1
Complutense University of Madrid,Religion2020,2015,6
Concordia College,Religion2020,2015,8
Concordia Seminary,Religion2020,2015,5
Concordia University,Cooperation2021,2015,7
Concordia University,Religion2020,2015,54
Concordia University Chicago,Religion2020,2015,2
Concordia University Irvine,Religion2020,2015,1
Connecticut College,Religion2020,2015,4
Consejo Nacional de Investigaciones Cientificas y Tecnicas,Religion2020,2015,7
Constantine the Philosopher University,Cooperation2021,2015,3
Constantine the Philosopher University,CooperationAndReligion,2015,3
Constantine the Philosopher University,Religion2020,2015,23
Cooper University Hospital,Religion2020,2015,4
Coordination of Improvement of Higher Education Personnel,Religion2020,2015,3
Copenhagen Business School,Religion2020,2015,14
Cornell University,Cooperation2021,2015,20
Cornell University,Religion2020,2015,73
Cornerstone of Recovery,Religion2020,2015,2
Corporate Health Solutions,Religion2020,2015,1
Corvinus University of Budapest,Cooperation2021,2015,2
Corvinus University of Budapest,Religion2020,2015,12
Cosmos & Creation,Religion2020,2015,1
Council of European Churches,Religion2020,2015,3
Council on Spiritual Practices,Religion2020,2015,2
Coventry University,Cooperation2021,2015,4
Coventry University,CooperationAndReligion,2015,1
Coventry University,Religion2020,2015,53
Crandall University,Religion2020,2015,5
Cranfield University,Religion2020,2015,3
Cranney Data Science,Religion2020,2015,8
Creighton University,Religion2020,2015,13
CRI,Cooperation2021,2015,5
CSIRO,Cooperation2021,2015,1
CSIRO,Religion2020,2015,2
Cuban Academy of Sciences,Cooperation2021,2015,1
Cuban Academy of Sciences,CooperationAndReligion,2015,1
Cuban Academy of Sciences,Religion2020,2015,1
CUNY,Religion2020,2015,49
CUNY Graduate Center,Cooperation2021,2015,1
CUNY Graduate Center,Religion2020,2015,8
"CUNY, Baruch College",Religion2020,2015,5
"CUNY, Hunter College",Religion2020,2015,3
Curtin University,Cooperation2021,2015,1
Curtin University,Religion2020,2015,18
Curtin University of Technology,Cooperation2021,2015,1
Curtin University of Technology,Religion2020,2015,5
CWI Amsterdam,Cooperation2021,2015,1
CyberAgent,Cooperation2021,2015,7
Czech Academy of Sciences,Cooperation2021,2015,1
Czech Academy of Sciences,Religion2020,2015,1
Czechoslovak Aademy of Sciences,Cooperation2021,2015,8
Daegu University,Religion2020,2015,2
Dalarna University,Religion2020,2015,9
Dalhousie University,Cooperation2021,2015,1
Dalhousie University,Religion2020,2015,18
Dalian University of Technology,Cooperation2021,2015,29
Dalian University of Technology,Religion2020,2015,1
Dana-Farber Cancer Institute,Religion2020,2015,16
Danish Cancer Society Research Center,Religion2020,2015,1
Danish Institute for Human Rights,Cooperation2021,2015,1
Danish Institute for Human Rights,CooperationAndReligion,2015,1
Danish Institute for Human Rights,Religion2020,2015,2
Danish Institute for International Studies,Religion2020,2015,5
Danish National Stem Cell Center,Cooperation2021,2015,6
Dartmouth College,Cooperation2021,2015,2
Dartmouth College,Religion2020,2015,37
David and Carol Myers Foundation,Religion2020,2015,2
David Yellin College of Education,Religion2020,2015,4
Davidson College,Religion2020,2015,2
Daya Counselling Centre,Religion2020,2015,13
De La Salle University,Cooperation2021,2015,1
De La Salle University,CooperationAndReligion,2015,1
De La Salle University,Religion2020,2015,9
De Montfort University,Cooperation2021,2015,7
De Montfort University,Religion2020,2015,10
Deakin University,Cooperation2021,2015,5
Deakin University,CooperationAndReligion,2015,2
Deakin University,Religion2020,2015,126
DeepMind,Cooperation2021,2015,12
DeepMind,Religion2020,2015,1
Defense Institute of Physiology and Allied Sciences,Religion2020,2015,2
Delft University of Technology,Cooperation2021,2015,16
Delft University of Technology,Religion2020,2015,3
Delhi University,Cooperation2021,2015,1
Delhi University,CooperationAndReligion,2015,1
Delhi University,Religion2020,2015,12
DeltaQuest Foundation,Religion2020,2015,3
Denison University,Religion2020,2015,22
Denpasar State Hindu Dharma Institute,Cooperation2021,2015,1
Denpasar State Hindu Dharma Institute,CooperationAndReligion,2015,1
Denpasar State Hindu Dharma Institute,Religion2020,2015,19
Department of Religious Affairs of the Ministry of the Interior,Religion2020,2015,5
Department of Veterans Affairs,Religion2020,2015,2
DePaul University,Religion2020,2015,27
DePauw University,Religion2020,2015,6
Dharma Drum Institute of Liberal Arts,Religion2020,2015,1
Diakonie Kliniken Zschadrass,Cooperation2021,2015,1
Diakonie Kliniken Zschadrass,Religion2020,2015,2
Diakonova University College,Religion2020,2015,2
Diponegoro University,Religion2020,2015,7
Diponegro University,Religion2020,2015,9
Divine Mercy University,Religion2020,2015,1
Division for Applied Social Psychology Research,Religion2020,2015,5
Divya Yog Mandir Trust,Religion2020,2015,6
Doha Institute for Graduate Studies,Religion2020,2015,13
Dominican School of Philosophy and Theology,Religion2020,2015,1
Dominican University of California,Religion2020,2015,5
Dongbei University of Finance and Economics,Religion2020,2015,1
Dongguan University of Technology,Cooperation2021,2015,7
Dongguan University of Technology,CooperationAndReligion,2015,1
Dongguan University of Technology,Religion2020,2015,2
Dongguk University,Religion2020,2015,4
Dongseo University,Religion2020,2015,2
D'Or Institute of Education and Research,Cooperation2021,2015,1
Doshisha University,Cooperation2021,2015,1
Doshisha University,Religion2020,2015,8
"Douglas Mental Health University Institute, DMHUI",Religion2020,2015,3
Doutor Fernando Fonseca,Religion2020,2015,7
Downing College,Religion2020,2015,1
Dr Williams's Library,Religion2020,2015,2
Dr. Justin Jones (Licensed Clinical Psychologist),Religion2020,2015,5
Drake University,Religion2020,2015,2
Dresden University of Technology,Cooperation2021,2015,1
Dresden University of Technology,Religion2020,2015,18
Drew University,Religion2020,2015,14
Drew University Theological School,Cooperation2021,2015,1
Drew University Theological School,CooperationAndReligion,2015,1
Drew University Theological School,Religion2020,2015,7
Drexel University,Cooperation2021,2015,3
Drexel University,Religion2020,2015,1
Dublin City University,Cooperation2021,2015,2
Dublin City University,CooperationAndReligion,2015,2
Dublin City University,Religion2020,2015,33
Duke Divinity School,Religion2020,2015,38
Duke Global Health Institute,Cooperation2021,2015,1
Duke Global Health Institute,Religion2020,2015,3
Duke Kunshan University,Cooperation2021,2015,1
Duke Kunshan University,CooperationAndReligion,2015,1
Duke Kunshan University,Religion2020,2015,8
Duke University,Cooperation2021,2015,47
Duke University,CooperationAndReligion,2015,3
Duke University,Religion2020,2015,253
Duke University Medical Center,Religion2020,2015,6
Duksung Women's University,Religion2020,2015,6
Duquesne University,Cooperation2021,2015,1
Duquesne University,Religion2020,2015,23
Durban University of Technology,Religion2020,2015,6
Durham University,Cooperation2021,2015,17
Durham University,Religion2020,2015,149
Duta Wacana University,Religion2020,2015,5
Dutch Ministry of Justice and Safety,Religion2020,2015,10
Duzce University,Religion2020,2015,7
EAPC Primary Care Reference Group,Religion2020,2015,2
EAPC Spiritual Care Reference Group,Religion2020,2015,3
EAST ATLANTA THERAPY,Religion2020,2015,3
East Carolina University,Cooperation2021,2015,4
East Carolina University,Religion2020,2015,16
East China Normal University,Cooperation2021,2015,16
East China Normal University,Religion2020,2015,7
East China University of Science and Technology,Cooperation2021,2015,8
East China University of Science and Technology,Religion2020,2015,3
East Tennessee State University,Religion2020,2015,14
Eastern Illinois University,Religion2020,2015,8
Eastern Institute of Technology,Religion2020,2015,3
Eastern Mennonite University,Cooperation2021,2015,2
Eastern Mennonite University,Religion2020,2015,4
Eastern Michigan University,Cooperation2021,2015,1
Eastern University,Religion2020,2015,6
E-Bus Competence Center,Cooperation2021,2015,2
Ecclesiastical University of Education Vienna,Religion2020,2015,9
Eckerd College,Religion2020,2015,3
Ecole des Hautes Etudes,Religion2020,2015,5
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,2015,7
Ecole Francaise D'Extreme-Orient,Religion2020,2015,2
Ecole Normale Supérieure,Religion2020,2015,5
École Normale Supérieure,Cooperation2021,2015,12
École Normale Supérieure,CooperationAndReligion,2015,2
École Normale Supérieure,Religion2020,2015,11
Ecole Normale Supérieure de Saint-Cloud,Religion2020,2015,3
Ecole Polytechnique,Cooperation2021,2015,2
Ecole Polytechnique Fédérale de Lausanne,Cooperation2021,2015,2
Ecole Pratique des Hautes Etudes,Cooperation2021,2015,1
Ecole Pratique des Hautes Etudes,CooperationAndReligion,2015,1
Ecole Pratique des Hautes Etudes,Religion2020,2015,24
École Pratique des Hautes Etudes,Religion2020,2015,5
École pratique des hautes études,Cooperation2021,2015,1
École pratique des hautes études,Religion2020,2015,8
Economic and Social Research Institute,Religion2020,2015,6
Ecumenical Institute,Religion2020,2015,12
Ecumenical Theological Seminary,Religion2020,2015,6
Edge Hill University,Religion2020,2015,7
Edgewalkers International,Religion2020,2015,7
Edinburgh Napier University,Cooperation2021,2015,4
Edinburgh Napier University,Religion2020,2015,2
Edinburgh University,Religion2020,2015,5
Edith Cowan University,Religion2020,2015,3
Eindhoven University of Technology,Cooperation2021,2015,1
"El Colegio de México, AC",Cooperation2021,2015,2
"El Colegio de México, AC",Religion2020,2015,3
Elements Behavioral Health,Religion2020,2015,7
Eliot Hospital,Religion2020,2015,3
Elizabethtown College,Religion2020,2015,9
Ellis Effect Psychological Group,Religion2020,2015,2
ELNEC End-of-Life Nursing Education Consortium,Religion2020,2015,4
Emanuel University of Oradea,Religion2020,2015,6
Emerson College,Religion2020,2015,2
eMindful Inc,Religion2020,2015,1
Emmanuel College,Religion2020,2015,12
"Emmy-Noether Junior Research Group, University of Mannheim",Religion2020,2015,3
Emory College,Religion2020,2015,6
Emory University,Cooperation2021,2015,42
Emory University,CooperationAndReligion,2015,1
Emory University,Religion2020,2015,185
Empire State College,Religion2020,2015,3
Engie Energy Access,Religion2020,2015,4
Eotvos Lorand University,Cooperation2021,2015,2
Eötvös Loránd University,Cooperation2021,2015,5
Epsilon Consultants,Religion2020,2015,3
Equinox Publishing Ltd,Religion2020,2015,10
Erasmus University Rotterdam,Cooperation2021,2015,16
Erasmus University Rotterdam,Religion2020,2015,26
Erin Presbyterian Church,Religion2020,2015,1
Esalen Institute,Cooperation2021,2015,1
Esalen Institute,Religion2020,2015,1
Esprit Journal,Religion2020,2015,2
ESSEC Business School,Cooperation2021,2015,4
essex county college,Religion2020,2015,1
Esslingen University of Applied Sciences,Religion2020,2015,3
ETH Zurich,Cooperation2021,2015,20
Ethiopian Journal of Health Sciences,Cooperation2021,2015,11
Eubios Ethics Institute,Religion2020,2015,2
Eunice Kennedy Shriver National Institute of Child Health and Human Development  Eunice Kennedy Shriver National Institute of Child Health and Human Development  Eunice Kennedy Shriver National Institute of Child Health and Human Development,Religion2020,2015,1
European Commission,Cooperation2021,2015,6
European Commission,Religion2020,2015,12
European Institute of Design,Religion2020,2015,8
European Institute of Florence,Religion2020,2015,6
European Institute of Oncology,Religion2020,2015,4
European School of Molecular Medicine,Religion2020,2015,4
European University Institute,Cooperation2021,2015,15
European University Institute,Religion2020,2015,22
European University Viadrina,Cooperation2021,2015,10
European University Viadrina,Religion2020,2015,19
European Xtramile Centre of African Studies (EXCAS),Cooperation2021,2015,1
European Xtramile Centre of African Studies (EXCAS),Religion2020,2015,14
Evangel University,Religion2020,2015,1
Evangelical Central Office for Weltanschauung,Religion2020,2015,7
Evangelical Lutheran Church in Bavaria,Religion2020,2015,3
Evangelische Hochschule Tabor in Marburg,Religion2020,2015,1
Evangelische Studiengemeinschaft eV,Religion2020,2015,1
Evangelische Theologische Faculteit,Religion2020,2015,8
Evesham Methodist Church,Religion2020,2015,4
Ewha Women's University,Cooperation2021,2015,3
Ewha Women's University,CooperationAndReligion,2015,1
Ewha Women's University,Religion2020,2015,12
Excelsia College,Religion2020,2015,7
Extreme Wellness Center,Religion2020,2015,1
Facebook,Cooperation2021,2015,9
Facebook,Religion2020,2015,4
Faculty of Medicine of Itajubá,Religion2020,2015,10
Fairfield University,Religion2020,2015,34
Fairleigh Dickinson University,Religion2020,2015,2
Fairsource Botanicals,Religion2020,2015,2
Fakultas Dakwah IAIN Purwokerto,Cooperation2021,2015,1
Fakultas Dakwah IAIN Purwokerto,Religion2020,2015,7
Fakultas Humaniora UIN Maulana Malik Ibrahim Malang,Religion2020,2015,3
Fakultas Ushuludin STFI Sadra Jakarta,Religion2020,2015,8
Family First Psychotherapy,Religion2020,2015,1
Family Service Center of Galveston County,Religion2020,2015,5
Family-Counselling Services,Religion2020,2015,1
Far Western Anthropological Research Group Inc.,Cooperation2021,2015,1
Far Western Anthropological Research Group Inc.,Religion2020,2015,1
Faulkner University,Religion2020,2015,3
Fayetteville State University,Religion2020,2015,24
Federal Institute for Risk Assessment (Germany),Religion2020,2015,2
Federal University for Public Administration - HS Bund,Religion2020,2015,1
Federal University of Alfenas,Religion2020,2015,6
Federal University of Bahia,Religion2020,2015,4
Federal University of Juiz de Fora,Religion2020,2015,68
Federal University of Mato Grosso do Sul,Religion2020,2015,4
Federal University of Pernambuco,Religion2020,2015,3
Federal University of Rio de Janeiro,Cooperation2021,2015,2
Federal University of Rio de Janeiro,Religion2020,2015,13
federal university of rio grande do norte,Cooperation2021,2015,1
federal university of rio grande do norte,Religion2020,2015,2
Federal University of Rio Grande do Sul,Religion2020,2015,6
Federal University of Santa Catarina,Religion2020,2015,10
Federal University of São Paulo (UNIFESP),Religion2020,2015,23
Federal University of Tocantins,Cooperation2021,2015,1
Federal University of Tocantins,Religion2020,2015,2
Federal University of Triangulo Mineiro,Religion2020,2015,3
Federation University Australia,Cooperation2021,2015,1
Federation University Australia,Religion2020,2015,21
Ferdowsi University Of Mashhad,Religion2020,2015,12
Fern University,Religion2020,2015,1
Fernando Pessoa University,Religion2020,2015,1
Fielding Graduate University,Religion2020,2015,1
First Baptist Church of Stockton,Religion2020,2015,5
First Things,Religion2020,2015,1
Flame University,Religion2020,2015,4
Flinders University,Cooperation2021,2015,5
Flinders University,Religion2020,2015,23
Flordia State,Cooperation2021,2015,2
Flordia State,CooperationAndReligion,2015,2
Flordia State,Religion2020,2015,12
Florida Agricultural and Mechanical University,Cooperation2021,2015,2
Florida Atlantic University,Cooperation2021,2015,2
Florida Atlantic University,CooperationAndReligion,2015,2
Florida Atlantic University,Religion2020,2015,38
Florida International University,Cooperation2021,2015,4
Florida International University,CooperationAndReligion,2015,2
Florida International University,Religion2020,2015,35
Florida International University - Tropical Conservation Institute,Cooperation2021,2015,2
Florida International University - Tropical Conservation Institute,Religion2020,2015,2
Florida SouthWestern State College,Religion2020,2015,2
Florida State University,Cooperation2021,2015,25
Florida State University,CooperationAndReligion,2015,5
Florida State University,Religion2020,2015,66
Florida State University Panama Campus,Cooperation2021,2015,2
Florida State University Panama Campus,CooperationAndReligion,2015,1
Florida State University Panama Campus,Religion2020,2015,7
flourishing metrics,Religion2020,2015,5
Fluminese Federal University,Religion2020,2015,6
Fondazione per le Scienze Religiose,Religion2020,2015,9
Forbes Hospital,Religion2020,2015,1
Fordham Road Collaborative,Religion2020,2015,6
Fordham University,Religion2020,2015,72
Forlaget Univers,Religion2020,2015,15
Fors Marsh Group,Religion2020,2015,2
Fortune Institute of Technology,Religion2020,2015,2
Forum on European Expansion and Global Interaction,Religion2020,2015,1
Foundation for Advancement of International Science,Religion2020,2015,1
"Founder and Director of the Jung's Center Mind, Body, Spirit Institute",Religion2020,2015,11
Four Seasons Hospice Care,Religion2020,2015,3
Fox Chase Cancer Center,Cooperation2021,2015,2
Fox Valley Memory Project,Religion2020,2015,2
Francis Marion University,Religion2020,2015,2
Francis Taylor Building,Religion2020,2015,12
Franciscan University,Religion2020,2015,8
Franciscan University of Steubenville,Religion2020,2015,4
Franklin & Marshall College,Religion2020,2015,1
Fraunhofer FIT,Cooperation2021,2015,1
frederick university,Religion2020,2015,1
Free State University,Cooperation2021,2015,1
Free State University,Religion2020,2015,13
Free University Berlin,Cooperation2021,2015,3
Free University Berlin,Religion2020,2015,3
Free University of Berlin,Cooperation2021,2015,4
Free University of Berlin,Religion2020,2015,13
Freelance Researcher,Religion2020,2015,2
Freiburg Institute for Advanced Studies,Religion2020,2015,1
Freiburg University of Mining and Technology,Cooperation2021,2015,5
Freie Universität Berlin,Religion2020,2015,12
French Institute for Anatolian Studies,Cooperation2021,2015,1
French Institute for Anatolian Studies,CooperationAndReligion,2015,1
French Institute for Anatolian Studies,Religion2020,2015,5
French Institute for Research in Computer Science and Automation,Cooperation2021,2015,1
French Institute of Health and Medical Research,Religion2020,2015,1
French National Center for Scientific Research,Cooperation2021,2015,4
French National Center for Scientific Research,Religion2020,2015,7
French National Council of Universities,Religion2020,2015,1
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,2015,8
Friedrich Schiller University Jena,Cooperation2021,2015,5
Friedrich Schiller University Jena,CooperationAndReligion,2015,1
Friedrich Schiller University Jena,Religion2020,2015,1
Friedrich-Alexander University Erlangen-Nuremberg,Religion2020,2015,2
Friedrich-Alexander-Universität Erlangen,Religion2020,2015,2
Friends Research Institute,Religion2020,2015,1
Frontiers of Immunology,Religion2020,2015,1
FTSE Russell,Cooperation2021,2015,3
FTSE Russell,Religion2020,2015,2
Fu Jen Catholic University,Religion2020,2015,6
Fudan University,Cooperation2021,2015,13
Fudan University,Religion2020,2015,23
Fukushima medical University,Cooperation2021,2015,6
Fuller Graduate School of Psychology,Religion2020,2015,5
Fuller Theological Seminary,Cooperation2021,2015,3
Fuller Theological Seminary,CooperationAndReligion,2015,2
Fuller Theological Seminary,Religion2020,2015,147
Fundação Centro de Estudos,Religion2020,2015,4
Fundação Faculdade de Medicina,Religion2020,2015,4
Fundação Getulio Vargas,Cooperation2021,2015,1
Fundacion Botin,Cooperation2021,2015,5
Furman University,Religion2020,2015,16
Fuzhou University,Cooperation2021,2015,12
FWD.us,Religion2020,2015,4
Gadjah Mada University,Religion2020,2015,13
Gandhi Naturopathic Medical College,Religion2020,2015,1
Gansu University of Traditional Chinese Medicine,Cooperation2021,2015,6
Gansu University of Traditional Chinese Medicine,Religion2020,2015,5
Gardner-Webb University,Religion2020,2015,1
Garrett-Evangelical Theological Seminary,Religion2020,2015,8
"GC University, Lahore",Religion2020,2015,3
Geestelijk Verzorger Zinzia Zorggroep,Religion2020,2015,3
Geha Mental Health Center,Religion2020,2015,1
Geisel School of Medicine,Religion2020,2015,1
General Hospital Virovitica,Religion2020,2015,1
Georg August University of Göttingen,Cooperation2021,2015,5
Georg August University of Göttingen,Religion2020,2015,6
George Fox University,Religion2020,2015,14
George Mason University,Cooperation2021,2015,19
George Mason University,CooperationAndReligion,2015,1
George Mason University,Religion2020,2015,27
George State University,Religion2020,2015,8
George Washington University,Cooperation2021,2015,9
George Washington University,Religion2020,2015,51
Georgetown Medical School,Religion2020,2015,1
Georgetown University,Cooperation2021,2015,11
Georgetown University,Religion2020,2015,165
Georgetown University School of Medicine,Religion2020,2015,2
Georgia Institute of Technology,Cooperation2021,2015,16
Georgia Southern University,Religion2020,2015,7
Georgia State University,Religion2020,2015,70
Gereformeerde Hogeschool,Religion2020,2015,5
German Institute for Economic Research (DIW Berlin),Cooperation2021,2015,2
German Institute for Economic Research (DIW Berlin),Religion2020,2015,1
German Primate Center,Cooperation2021,2015,1
GESIS-Leibniz Institute for the Social Sciences,Religion2020,2015,6
Getty Images,Religion2020,2015,2
Gettysburg College,Religion2020,2015,11
GGZ Central,Religion2020,2015,3
Ghent University,Cooperation2021,2015,12
Ghent University,CooperationAndReligion,2015,5
Ghent University,Religion2020,2015,65
GIGA German Institute of Global and Area Studies,Religion2020,2015,12
GKT School of Medical Education,Religion2020,2015,1
Glasgow Caledonian University,Religion2020,2015,1
Glasgow Centre for Population Health,Cooperation2021,2015,2
Glasgow Centre for Population Health,Religion2020,2015,2
Glendale Adventist Medical Center,Religion2020,2015,9
Glendale Community College,Religion2020,2015,3
Global Alliance in Management Education,Religion2020,2015,12
Global Cyber University,Religion2020,2015,3
Global Ethic Institute,Religion2020,2015,2
Glyndwr University,Religion2020,2015,18
Goa Medical College,Religion2020,2015,1
Goa University,Religion2020,2015,2
Goce Delčev University of Štip,Cooperation2021,2015,10
Goethe University Frankfurt,Cooperation2021,2015,1
Goethe University Frankfurt,Religion2020,2015,27
Goettingen University,Religion2020,2015,3
Golden Gate Baptist Theological Seminary,Religion2020,2015,1
"Goldsmiths, University of London",Cooperation2021,2015,1
"Goldsmiths, University of London",Religion2020,2015,62
Gonzaga University,Religion2020,2015,2
Google,Cooperation2021,2015,15
Gordon College,Religion2020,2015,1
Gordon Conwell Theological Seminary,Religion2020,2015,3
Gordon Life Science Institute,Cooperation2021,2015,1
Gordon-Conwell Theological Seminary,Cooperation2021,2015,1
Gordon-Conwell Theological Seminary,CooperationAndReligion,2015,1
Gordon-Conwell Theological Seminary,Religion2020,2015,24
Governemnt Science College,Religion2020,2015,7
Government College University,Religion2020,2015,7
Government Sadiq Egerton College Bahawalpur,Religion2020,2015,4
Government Yoga and Naturopathy Medical College,Religion2020,2015,4
Grace,Religion2020,2015,1
Graduate Institute of International and Developmental Studies in Geneva,Cooperation2021,2015,2
Graduate Institute of International and Developmental Studies in Geneva,Religion2020,2015,1
Graduate Theological Foundation,Religion2020,2015,5
Graduate Theological Union,Religion2020,2015,69
Graduate University of Advanced Studies (SOKENDAI),Cooperation2021,2015,9
Graduate University of Advanced Studies (SOKENDAI),Religion2020,2015,1
Grand Valley State University,Cooperation2021,2015,1
Grand Valley State University,CooperationAndReligion,2015,1
Grand Valley State University,Religion2020,2015,12
Grand View University,Religion2020,2015,2
Grande Ospedale Metropolitano Niguarda,Religion2020,2015,1
Gratz College,Religion2020,2015,8
Green Monkey Yoga,Religion2020,2015,5
Green Templeton College,Cooperation2021,2015,1
Green Templeton College,Religion2020,2015,4
Greenville Health System NCORP,Religion2020,2015,1
Greenwall Foundation,Religion2020,2015,3
Greenwich Hospital,Religion2020,2015,9
Greenwich School of Theology,Religion2020,2015,6
Grenoble Alpes University,Cooperation2021,2015,1
Grenoble Alpes University,Religion2020,2015,1
Gresham College,Religion2020,2015,12
Griffin Hospital,Religion2020,2015,2
Griffith University,Cooperation2021,2015,9
Griffith University,Religion2020,2015,27
Grove City College,Religion2020,2015,10
Guangdong University of Foreign Studies,Religion2020,2015,12
Guangxi Normal University,Cooperation2021,2015,15
Guangzhou University,Cooperation2021,2015,3
Guestbrook Project,Religion2020,2015,3
Gustavus Adolphus College,Religion2020,2015,2
Gwynedd Mercy College,Religion2020,2015,1
H&S Ventures,Religion2020,2015,3
H. Lee Moffitt Cancer Center and Research Institute,Cooperation2021,2015,8
Hacettepe University,Religion2020,2015,4
Hadassah University Medical Center,Religion2020,2015,7
Haifa University,Religion2020,2015,22
Haight-Ashbury Free Clinic (HAFC),Cooperation2021,2015,1
Haight-Ashbury Free Clinic (HAFC),Religion2020,2015,1
Hainan Medical University,Religion2020,2015,8
Halmstad University,Religion2020,2015,5
Hamad bin Khalifa College of Law and Public Policy,Religion2020,2015,3
Hamad Bin Khalifa University,Religion2020,2015,4
Hamburg University,Cooperation2021,2015,1
Hamburg University,Religion2020,2015,18
Hamilton College,Religion2020,2015,7
Handong University,Religion2020,2015,2
handzo consulting,Religion2020,2015,12
Hangzhou Dianzi University,Cooperation2021,2015,10
Hangzhou Dianzi University,Religion2020,2015,2
Hankuk university of foreign studies,Religion2020,2015,16
Harbin Institute of Technology,Cooperation2021,2015,9
Harbin Institute of Technology Shenzhen,Cooperation2021,2015,2
Harbin Institute of Technology Shenzhen,Religion2020,2015,8
Hardin-Simmons University,Religion2020,2015,3
Harper College,Religion2020,2015,2
Harris Manchester College,Religion2020,2015,10
Hartford Institute for Religion Research,Religion2020,2015,1
Hartford Seminary,Religion2020,2015,1
Hartford University,Religion2020,2015,5
Harvard Business School,Cooperation2021,2015,9
Harvard Business School,CooperationAndReligion,2015,1
Harvard Business School,Religion2020,2015,3
Harvard Divinity School,Cooperation2021,2015,1
Harvard Divinity School,Religion2020,2015,43
Harvard Medical School,Cooperation2021,2015,25
Harvard Medical School,Religion2020,2015,101
Harvard School of Public Health,Cooperation2021,2015,8
Harvard School of Public Health,CooperationAndReligion,2015,3
Harvard School of Public Health,Religion2020,2015,110
Harvard T.H. Chan School of Public Health,Religion2020,2015,35
Harvard University,Cooperation2021,2015,114
Harvard University,CooperationAndReligion,2015,15
Harvard University,Religion2020,2015,157
Hastings Center,Religion2020,2015,1
Haverford College,Religion2020,2015,1
Havering College of Further and Higher Education,Religion2020,2015,2
Hawaii Pacific University,Religion2020,2015,8
Hazara University,Religion2020,2015,3
HCA Midwest Health,Cooperation2021,2015,1
HCF Nutrition Foundation,Religion2020,2015,1
Heal,Religion2020,2015,6
health action inc,Religion2020,2015,1
"Health and Human Services Agency, San Diego",Religion2020,2015,5
Healthcare Chaplaincy,Religion2020,2015,1
Healthcare Chaplaincy and Spiritual Care,Religion2020,2015,2
HealthPartners,Religion2020,2015,1
Hebrew Union College,Religion2020,2015,9
Hebrew University - Hadessah,Cooperation2021,2015,1
Hebrew University - Hadessah,CooperationAndReligion,2015,1
Hebrew University - Hadessah,Religion2020,2015,6
Hebrew University of Jerusalem,Cooperation2021,2015,3
Hebrew University of Jerusalem,Religion2020,2015,80
HEC Montreal,Cooperation2021,2015,3
Hecht Partners LLP,Religion2020,2015,8
heckercounseling,Religion2020,2015,1
Hefei University of Technology,Cooperation2021,2015,3
Heidelberg University,Cooperation2021,2015,13
Heidelberg University,Religion2020,2015,18
Heights Therapy,Religion2020,2015,1
Heinrich Heine University Düsseldorf,Religion2020,2015,9
Heinrich-Heine University,Cooperation2021,2015,7
Heinrich-Heine University,Religion2020,2015,6
Helen Dowling Institute,Religion2020,2015,12
Hellenic Foundation for European and Foreign Policy,Religion2020,2015,11
Hellenic Open University,Religion2020,2015,5
HELP University,Religion2020,2015,1
Helsinki University,Cooperation2021,2015,1
Helsinki University,Religion2020,2015,12
Henan Normal University,Cooperation2021,2015,4
Henan Normal University,Religion2020,2015,2
Henderson State University,Religion2020,2015,3
Heriot-Watt University,Religion2020,2015,8
Hermanos Ameijeiras Hospital,Cooperation2021,2015,1
Hermanos Ameijeiras Hospital,CooperationAndReligion,2015,1
Hermanos Ameijeiras Hospital,Religion2020,2015,1
Hertie School,Religion2020,2015,3
"Herzog Hospital, Jerusalem",Religion2020,2015,1
Heythrop College,Religion2020,2015,20
Higher School of Economics,Cooperation2021,2015,2
Highland Theological College,Religion2020,2015,3
Highmark,Religion2020,2015,2
Hillsdale College,Religion2020,2015,2
Hindu University of America,Religion2020,2015,1
Hitit University,Cooperation2021,2015,1
Hitit University,Religion2020,2015,4
Hitotsubashi University,Cooperation2021,2015,18
Hobart and William Smith Colleges,Religion2020,2015,4
Hofstra University,Cooperation2021,2015,2
Hofstra University,CooperationAndReligion,2015,1
Hofstra University,Religion2020,2015,11
Hogeschool Utrecht,Religion2020,2015,8
Hokkaido University,Cooperation2021,2015,9
Hokkaido University,Religion2020,2015,2
Holy Cross College,Religion2020,2015,1
Holy Ghost College,Religion2020,2015,5
Holy Spirit Seminary College of Theology & Philosophy,Religion2020,2015,3
Homerton University Hospital NHS Foundation Trust,Religion2020,2015,8
"Homewood Research Institute, Guelph, Ontario",Religion2020,2015,5
Hong Kong Baptist University,Cooperation2021,2015,7
Hong Kong Baptist University,Religion2020,2015,9
Hong Kong Polytechnic University,Cooperation2021,2015,25
Hong Kong Polytechnic University,CooperationAndReligion,2015,2
Hong Kong Polytechnic University,Religion2020,2015,26
Hong Kong Shue Yan College,Cooperation2021,2015,7
Hong Kong University of Science and Technology,Cooperation2021,2015,2
Hong Kong University of Science and Technology,CooperationAndReligion,2015,1
Hong Kong University of Science and Technology,Religion2020,2015,3
Hongik University,Religion2020,2015,2
Hope College,Religion2020,2015,19
Hopewell Presbyterian Church,Religion2020,2015,1
Hôpital Paul-Brousse – Hôpitaux universitaires Paris-Sud,Religion2020,2015,1
Hopitaux Universitaires de Geneve,Religion2020,2015,3
Hosei University,Cooperation2021,2015,3
Hospice by the Bay,Religion2020,2015,8
Hospice palliative care ssociation of south africa,Religion2020,2015,1
Hospice Rieti,Religion2020,2015,18
Hospital Clínico Universitario Virgen de la Arrixaca,Cooperation2021,2015,31
Hospital Clínico Universitario Virgen de la Arrixaca,CooperationAndReligion,2015,29
Hospital Clínico Universitario Virgen de la Arrixaca,Religion2020,2015,31
Hospital das Clinicas da UFPE,Religion2020,2015,5
Hospital de Cancer de Barretos,Religion2020,2015,7
Hospital De Clínicas De Porto Alegre,Religion2020,2015,5
"Hospital for Integral Psychiatry, Psychosomatic  and Psychotherapy",Cooperation2021,2015,1
"Hospital for Integral Psychiatry, Psychosomatic  and Psychotherapy",Religion2020,2015,2
Hospital for Sick Children,Cooperation2021,2015,2
Hospital for Sick Children,Religion2020,2015,2
Hospital Israelita Albert Einstein,Religion2020,2015,8
Hospital Regional de Alta Especialidad del Bajío,Cooperation2021,2015,1
Hospital Regional de Alta Especialidad del Bajío,CooperationAndReligion,2015,1
Hospital Regional de Alta Especialidad del Bajío,Religion2020,2015,1
Hospital Universitario Virgen de la Arrixaca,Cooperation2021,2015,1
Hospital Universitario Virgen de la Arrixaca,CooperationAndReligion,2015,1
Hospital Universitario Virgen de la Arrixaca,Religion2020,2015,1
Houston Baptist University,Religion2020,2015,21
Houston Methodist Hospital,Cooperation2021,2015,1
Houston Methodist Hospital,CooperationAndReligion,2015,1
Houston Methodist Hospital,Religion2020,2015,7
Howard University,Religion2020,2015,16
Huawei Technologies,Cooperation2021,2015,5
Huazhong University of Science and Technology,Cooperation2021,2015,8
Huazhong University of Science and Technology,Religion2020,2015,2
Hubei University of Medicine,Religion2020,2015,6
Hudson College of Public Health,Cooperation2021,2015,2
Hudson College of Public Health,Religion2020,2015,6
Hult International Business School,Religion2020,2015,13
Human Sciences Research Council,Cooperation2021,2015,1
Human Sciences Research Council,Religion2020,2015,13
Humanists UK,Religion2020,2015,8
Humboldt State University,Religion2020,2015,2
Humboldt University of Berlin,Cooperation2021,2015,6
Humboldt University of Berlin,CooperationAndReligion,2015,1
Humboldt University of Berlin,Religion2020,2015,40
Hunan Medical University,Religion2020,2015,4
Hunan Normal University,Cooperation2021,2015,3
Hunan Normal University,Religion2020,2015,4
Hunan University,Cooperation2021,2015,8
Hungarian Academy of Sciences,Cooperation2021,2015,16
Hungarian Academy of Sciences,Religion2020,2015,12
"Hunter College, CUNY",Religion2020,2015,3
Huntingdon College,Religion2020,2015,8
Huntington University,Religion2020,2015,6
Huntsman Cancer Institute,Religion2020,2015,11
Huron University College,Religion2020,2015,1
Huston-Tilloston University,Religion2020,2015,2
IAIN Langsa,Religion2020,2015,7
IAIN Pongoro,Religion2020,2015,5
IAIN Pontianak,Religion2020,2015,11
IAIN Purwokerto,Cooperation2021,2015,1
IAIN Purwokerto,Religion2020,2015,14
Ibaraki University,Religion2020,2015,4
Ibn Haldun University,Religion2020,2015,3
Ibnu Khaldun University of Bogor,Religion2020,2015,5
Icahn School of Medicine at Mount Sinai,Cooperation2021,2015,2
Icahn School of Medicine at Mount Sinai,Religion2020,2015,13
ICERRS,Religion2020,2015,6
Idaho State University,Religion2020,2015,2
Ignatianum Academy in Krakow,Religion2020,2015,7
Iliff School of Theology,Religion2020,2015,7
Illif School of Theology,Religion2020,2015,7
Illinois State University,Religion2020,2015,13
Illinois Wesleyan University,Religion2020,2015,6
Illumeo,Religion2020,2015,6
Immanuel Hospital Berlin,Religion2020,2015,6
Immersion Neuroscience,Cooperation2021,2015,3
Immersion Neuroscience,Religion2020,2015,2
Immundiagnostik AG,Cooperation2021,2015,3
Imperial College London,Cooperation2021,2015,16
Imperial College London,Religion2020,2015,9
IMT School for Advanced Studies Lucca,Cooperation2021,2015,6
IMT Strategy and Oversight,Religion2020,2015,6
Incheon National University,Religion2020,2015,1
Independent Consulting,Religion2020,2015,1
Independent Researcher,Religion2020,2015,1
India Institute of Technology,Cooperation2021,2015,12
India Institute of Technology,CooperationAndReligion,2015,8
India Institute of Technology,Religion2020,2015,20
Indian Council of Social Science Research,Religion2020,2015,2
Indian Institute for Foreign Trade,Cooperation2021,2015,2
Indian Institute for Foreign Trade,CooperationAndReligion,2015,2
Indian Institute for Foreign Trade,Religion2020,2015,2
Indian Institute of Management,Religion2020,2015,2
Indian Institute of Science,Cooperation2021,2015,5
Indian Institute of Technology,Cooperation2021,2015,1
Indian Institute of Technology,Religion2020,2015,18
Indian Institute of Technology Bombay,Religion2020,2015,9
Indian Naturopathy & Yoga Graduates Medical Association [INYGMA],Religion2020,2015,1
Indian River State College,Religion2020,2015,5
Indian Statistical Institute,Religion2020,2015,4
Indiana School of Medicine,Religion2020,2015,1
Indiana State University,Cooperation2021,2015,1
Indiana State University,CooperationAndReligion,2015,1
Indiana State University,Religion2020,2015,13
Indiana University,Cooperation2021,2015,16
Indiana University,Religion2020,2015,99
Indiana University at Bloomington,Cooperation2021,2015,3
Indiana University at Bloomington,Religion2020,2015,4
Indiana University of Pennsylvania,Religion2020,2015,3
Indiana University Purdue University Indianapolis,Religion2020,2015,1
Indiana University South Bend,Cooperation2021,2015,3
Indiana University South Bend,CooperationAndReligion,2015,1
Indiana University South Bend,Religion2020,2015,5
Indiana University-Purdue University Columbus,Religion2020,2015,4
Indiana University-Purdue University Fort Wayne,Cooperation2021,2015,1
Indiana University-Purdue University Fort Wayne,CooperationAndReligion,2015,1
Indiana University-Purdue University Fort Wayne,Religion2020,2015,6
Indiana University-Purdue University Indianapolis,Religion2020,2015,8
Indiana Wesleyan University,Religion2020,2015,1
Indic Academy,Religion2020,2015,1
Indonesia Institute of Sciences,Cooperation2021,2015,1
Indonesia Institute of Sciences,Religion2020,2015,3
Indonesian Institute of Sciences,Religion2020,2015,8
Indus Business Academy,Religion2020,2015,11
Inholland University,Religion2020,2015,7
Inland Norway University of Applied Science,Religion2020,2015,6
Inner Mongolia University of Science and Technology,Cooperation2021,2015,3
Inner Mongolia University of Science and Technology,Religion2020,2015,1
Innlandet Hospital Trust,Religion2020,2015,7
"INSEAD, Singapore",Religion2020,2015,2
Inserm,Cooperation2021,2015,8
InsightLA,Religion2020,2015,1
Institut Agama Islam Negeri Palu,Religion2020,2015,1
Institut Agama Islam Negeri Purwokerto,Religion2020,2015,4
Institut Agama Islam Negeri Sorong,Cooperation2021,2015,2
Institut Agama Islam Negeri Sorong,CooperationAndReligion,2015,1
Institut Agama Islam Negeri Sorong,Religion2020,2015,29
Institut Agama Islam Negeri Sultan Amai,Religion2020,2015,8
Institut für Evangelische Theologie,Cooperation2021,2015,1
Institut für Evangelische Theologie,CooperationAndReligion,2015,1
Institut für Evangelische Theologie,Religion2020,2015,3
Institut für Grenzgebiete der Psychologie und Psychohygiene,Religion2020,2015,3
Institut universitaire de gériatrie de Montréal (IUGM),Religion2020,2015,1
Institute Ecumenical and Cultural Research,Religion2020,2015,2
Institute for Advanced Study,Religion2020,2015,17
Institute for American Constitutional Heritage,Religion2020,2015,3
Institute for Applied Epistemiology and Medical Methodology,Religion2020,2015,2
Institute for Applied Statistics and Data Analysis,Religion2020,2015,1
Institute for Breathing and Sleep,Religion2020,2015,1
Institute for Cognitive Sciences and Technologies,Cooperation2021,2015,1
Institute for Ecumenical Research,Religion2020,2015,5
Institute for Frontier Areas of Psychology and Mental Health,Religion2020,2015,7
Institute for Genetic and Biomedical Research,Cooperation2021,2015,1
Institute for Global Engagement,Religion2020,2015,1
Institute for Management and Planning Studies,Cooperation2021,2015,3
Institute for Neuro-Immune Medicine,Religion2020,2015,3
Institute for Orthodox Christian Studies,Religion2020,2015,8
Institute for Political Science,Cooperation2021,2015,1
Institute for Political Science,Religion2020,2015,3
Institute for Research in Immunology and Cancer,Cooperation2021,2015,1
Institute for Social Policy and Understanding,Religion2020,2015,12
Institute for Social Sciences at the TU Braunschweig,Religion2020,2015,2
Institute for Strategic Dialogue,Religion2020,2015,1
Institute for the Biocultural Study of Religion,Religion2020,2015,16
Institute for the Danube Region and Central Europe,Cooperation2021,2015,3
Institute for the Danube Region and Central Europe,CooperationAndReligion,2015,3
Institute for the Danube Region and Central Europe,Religion2020,2015,3
Institute for the Study of Complex Systems,Cooperation2021,2015,1
Institute for Transpersonal Psychology,Religion2020,2015,6
Institute of Applied Psychology,Religion2020,2015,15
Institute of Basic Medical Sciences,Religion2020,2015,1
Institute of Child Health and Human Development,Cooperation2021,2015,3
Institute of Child Health and Human Development,Religion2020,2015,6
Institute of Communications Engineering,Cooperation2021,2015,4
Institute of Communications Engineering,Religion2020,2015,1
Institute of Developing Economics,Religion2020,2015,5
Institute of Electrical and Electronics Engineers,Cooperation2021,2015,1
Institute of Human Behavioral Medicine,Religion2020,2015,6
Institute of Ismaili Studies,Religion2020,2015,6
Institute of Kidney Diseases and Research Center,Cooperation2021,2015,3
Institute of Kidney Diseases and Research Center,CooperationAndReligion,2015,3
Institute of Kidney Diseases and Research Center,Religion2020,2015,3
Institute of Lutheran Theology,Religion2020,2015,1
Institute of Mathematical Sciences,Cooperation2021,2015,6
Institute of Mental Health,Religion2020,2015,6
Institute of Modern History,Religion2020,2015,7
Institute of Molecular Pathology,Cooperation2021,2015,1
Institute of Noetic Sciences,Religion2020,2015,23
Institute of Nuclear Medicine & Allied Sciences,Religion2020,2015,4
Institute of Psychiatry & Human Behavior,Religion2020,2015,2
Institute of Psychology,Cooperation2021,2015,6
Institute of Race Relations,Religion2020,2015,1
Institute of Science and Technology Austria,Cooperation2021,2015,10
"Institute of Science, Technology and Public Policy",Religion2020,2015,1
Institute of Security and Global Affairs,Religion2020,2015,8
Institute of Theoretical and Applied Social Sciences,Religion2020,2015,14
Institute on Global Conflict and Cooperation,Religion2020,2015,1
Institute on Religion in an Age of Science,Religion2020,2015,1
Instituto de Análisis Económico,Cooperation2021,2015,2
Instituto de Astrofisica de Canarias,Religion2020,2015,8
Instituto de Telecomunicacones,Cooperation2021,2015,5
Instituto Piaget,Religion2020,2015,1
Instituto Universitário de Ciências Psicológicas,Religion2020,2015,3
Insubria University,Religion2020,2015,6
Interdisciplinary Center Herzliya,Cooperation2021,2015,2
Interdisciplinary Center Herzliya,Religion2020,2015,13
Interdisciplinary Center Herzliya:,Cooperation2021,2015,8
"International Association for Human Values, United Nations",Religion2020,2015,2
International Association of Sickle Cell Nurses and Professional Associates,Religion2020,2015,7
International Baptist Theological Seminary,Religion2020,2015,5
International Children's Palliative Care Network,Religion2020,2015,3
International Christian University,Religion2020,2015,5
International Institute for Applied Systems Analysis,Cooperation2021,2015,8
International Institute for Applied Systems Analysis,Religion2020,2015,10
International Institute for Population Sciences,Cooperation2021,2015,1
International Institute for Population Sciences,Religion2020,2015,8
International Institute of Advanced Islamic Studies,Religion2020,2015,5
International Institute of Molecular and Cell Biology in Warsaw,Cooperation2021,2015,2
International Institute of Spiritual Leadership,Religion2020,2015,3
International Islamic University Malaysia,Religion2020,2015,20
International Journal of Reformed Theology and Life,Religion2020,2015,7
International Peace and Development Ethics Center,Religion2020,2015,2
International Primary Palliative Care Network (IPPCN),Religion2020,2015,2
International Society of Addiction Medicine,Religion2020,2015,2
International Telematic University UNINETTUNO,Cooperation2021,2015,4
International University of Rabat,Religion2020,2015,1
Inter-University Centre Dubrovnik,Religion2020,2015,3
IOIO,Cooperation2021,2015,1
IOIO,CooperationAndReligion,2015,1
IOIO,Religion2020,2015,2
Iona College,Religion2020,2015,2
Iowa State University,Cooperation2021,2015,12
Iowa State University,Religion2020,2015,26
Iran University of Medical Sciences,Cooperation2021,2015,1
Iran University of Medical Sciences,Religion2020,2015,26
Iranian Institute for Health Sciences Research,Religion2020,2015,5
IRCCS Eugenio Medea,Religion2020,2015,2
IRCCS Istituto Nazionale dei Tumori,Cooperation2021,2015,2
IRCCS Istituto Nazionale dei Tumori,CooperationAndReligion,2015,1
IRCCS Istituto Nazionale dei Tumori,Religion2020,2015,5
ISAP- Zurich,Religion2020,2015,4
ISAPEG,Cooperation2021,2015,1
ISAPEG,CooperationAndReligion,2015,1
ISAPEG,Religion2020,2015,2
Isfahan University,Religion2020,2015,29
Isfahan University of Medical Science,Religion2020,2015,4
Isfahan University of Technology,Cooperation2021,2015,11
Islamic Azad University,Cooperation2021,2015,12
Islamic Azad University,Religion2020,2015,2
Islamic University of Gaza,Religion2020,2015,3
ISPM,Religion2020,2015,2
Israel Institute of Technology,Religion2020,2015,4
Istanbul Bilgi University,Religion2020,2015,5
Istanbul Sabahattin Zaim University,Religion2020,2015,7
Istanbul University,Cooperation2021,2015,3
Istanbul University,CooperationAndReligion,2015,2
Istanbul University,Religion2020,2015,8
Istituto Auxologico Italiano,Religion2020,2015,1
Istituto di Scienze e Technologie della Cognizione,Religion2020,2015,1
Istituto Mente e Corpo,Religion2020,2015,3
Istituto Nazionale dei Tumori,Cooperation2021,2015,2
Istituto Nazionale dei Tumori,CooperationAndReligion,2015,1
Istituto Nazionale dei Tumori,Religion2020,2015,5
Istituto Ortopedico Rizzoli,Cooperation2021,2015,3
Italian National Institute of Health,Cooperation2021,2015,6
Italian National Research Council,Cooperation2021,2015,3
Ithaca College,Religion2020,2015,2
Jackson State University,Religion2020,2015,10
Jacobs University Bremen,Religion2020,2015,4
Jadavpur University,Cooperation2021,2015,3
Jagiellonian University,Cooperation2021,2015,2
Jagiellonian University,Religion2020,2015,20
Jakarta State University,Religion2020,2015,20
James Cook University,Cooperation2021,2015,4
James Cook University,Religion2020,2015,4
James Cook University-Singapore,Religion2020,2015,4
James Madison University,Religion2020,2015,8
Jamia Millia Islamia,Religion2020,2015,3
Jangxi University of Finance and Economics,Religion2020,2015,1
Japan Advanced Institute of Science and Technology,Cooperation2021,2015,16
Japan Society for Buddhist-Christian Studies,Religion2020,2015,1
Jawaharlal Nehru Medical College,Cooperation2021,2015,4
Jawaharlal Nehru Medical College,CooperationAndReligion,2015,4
Jawaharlal Nehru Medical College,Religion2020,2015,4
Jawaharlal Nehru University,Religion2020,2015,7
Jean Monnet Centre of Excellence,Religion2020,2015,5
Jena University,Religion2020,2015,1
Jesuit Faculty of Philosophy and Theology,Religion2020,2015,5
Jesuit Ignatianum University,Religion2020,2015,3
Jesuit Institute,Religion2020,2015,1
Jesus College,Religion2020,2015,5
Jewish Theological Seminary,Religion2020,2015,6
Jiangsu Normal University,Cooperation2021,2015,7
Jiangsu Normal University,Religion2020,2015,3
Jilin University,Cooperation2021,2015,12
Jilin University,Religion2020,2015,11
Jimma University,Religion2020,2015,4
Jindal Global University,Religion2020,2015,9
Jnana-Deepa Vidyapeeth,Religion2020,2015,3
jobZology,Cooperation2021,2015,1
jobZology,Religion2020,2015,3
Johann Wolfgang Goethe-University,Religion2020,2015,2
Johannes a Lasco Library,Religion2020,2015,2
Johannes Gutenberg University,Religion2020,2015,8
John Cabot University,Cooperation2021,2015,1
John Cabot University,CooperationAndReligion,2015,1
John Cabot University,Religion2020,2015,4
John Carroll University,Religion2020,2015,7
John Hopkins University,Cooperation2021,2015,12
John Hopkins University,Religion2020,2015,30
John Innes Centre,Cooperation2021,2015,5
John Jay College,Religion2020,2015,2
John Jay College of Criminal Justice,Religion2020,2015,12
John Paul II Catholic University of Lublin,Religion2020,2015,19
John Templeton Foundation Board of Advisors,Religion2020,2015,2
John Wycliffe Theological College,Religion2020,2015,1
Johns Hopkins Bayview Medical Center,Religion2020,2015,5
Johns Hopkins Bloomberg School of Public Health,Religion2020,2015,4
Johns Hopkins Hospital,Religion2020,2015,2
Johns Hopkins School of Medicine,Cooperation2021,2015,6
Johns Hopkins School of Medicine,Religion2020,2015,14
Johns Hopkins University,Cooperation2021,2015,4
Johns Hopkins University,Religion2020,2015,60
Joint Special Operations University,Religion2020,2015,3
Jonkoping University,Religion2020,2015,13
Jönköping University,Cooperation2021,2015,1
Jönköping University,Religion2020,2015,8
Joshi-Bedekar College,Religion2020,2015,1
Journal of Law and Religion,Religion2020,2015,11
"Journal of Policing, Intelligence and Counter Terrorism (JPICT)",Religion2020,2015,5
Journal of the American Medical Association (JAMA),Cooperation2021,2015,10
Journey Mental Health Center,Religion2020,2015,1
Józef Pilsudski Academy of Physical Education,Cooperation2021,2015,1
Józef Pilsudski Academy of Physical Education,Religion2020,2015,3
JPsych and Center for Anxiety,Religion2020,2015,14
Jungian Psychoanalytic Association of New York,Religion2020,2015,1
Justice Resource Institute,Religion2020,2015,1
Justus Liebig University Giessen,Religion2020,2015,4
Kadir Has University,Cooperation2021,2015,1
Kadir Has University,CooperationAndReligion,2015,1
Kadir Has University,Religion2020,2015,6
Kaiser Permanente,Religion2020,2015,3
Kalinga Institute of Industrial Technology,Cooperation2021,2015,1
Kalinga Institute of Industrial Technology,Religion2020,2015,1
KalpaTaru,Religion2020,2015,3
Kampen Theological University,Cooperation2021,2015,2
Kampen Theological University,CooperationAndReligion,2015,2
Kampen Theological University,Religion2020,2015,4
Kangwon National University,Religion2020,2015,2
Kansai University,Cooperation2021,2015,8
Kansas State University,Religion2020,2015,5
Kansas University,Religion2020,2015,6
Kaohsiung Medical University,Religion2020,2015,2
Karachi University,Cooperation2021,2015,2
Karachi University,CooperationAndReligion,2015,2
Karachi University,Religion2020,2015,8
Karakter Child and Adolescent  Psychiatry,Religion2020,2015,2
Karen D. Lincoln Consulting Services,Religion2020,2015,2
Karl Franzens University,Religion2020,2015,3
Karl-Franzens University Graz,Religion2020,2015,6
Karlstad University,Religion2020,2015,1
Karnatak University,Religion2020,2015,7
Karolinska Institute,Cooperation2021,2015,4
Karolinska Institute,Religion2020,2015,15
Karolinska Institutet,Religion2020,2015,1
Karuna-Shechen,Cooperation2021,2015,1
Karuna-Shechen,Religion2020,2015,4
Kashan University of Medical Sciences,Religion2020,2015,4
Katholieke Universiteit Leuven,Cooperation2021,2015,10
Katholieke Universiteit Leuven,Religion2020,2015,17
Keele University,Religion2020,2015,24
Keenesaw State University,Cooperation2021,2015,1
Keenesaw State University,Religion2020,2015,4
Keimyung University,Religion2020,2015,9
Keio University,Cooperation2021,2015,8
Keio University,Religion2020,2015,2
Keio University Shonan Fujisawa,Cooperation2021,2015,5
Keio University Shonan Fujisawa,Religion2020,2015,2
Kellogg School of Management,Religion2020,2015,10
KEMRI-Wellcome Trust Research Programme,Cooperation2021,2015,2
Kenrick Glennon Seminary,Religion2020,2015,5
Kent State University,Cooperation2021,2015,2
Kent State University,CooperationAndReligion,2015,1
Kent State University,Religion2020,2015,40
Kenyatta University,Religion2020,2015,7
Kenyon College,Religion2020,2015,5
Kerman University of Medical Sciences,Cooperation2021,2015,1
Kerman University of Medical Sciences,Religion2020,2015,21
Keston College,Religion2020,2015,1
Keston Institute,Religion2020,2015,9
Kettering Health Network,Religion2020,2015,5
Kfar Shaul Hospital,Religion2020,2015,2
Khalifa University,Cooperation2021,2015,3
Khon Kaen University,Religion2020,2015,3
Kickbusch Health Consult,Cooperation2021,2015,2
Kickbusch Health Consult,Religion2020,2015,1
Kiel Institute for the World Economy,Cooperation2021,2015,1
Kiev University,Religion2020,2015,8
KIFO Institute,Religion2020,2015,4
Kindred Hospital El Paso,Cooperation2021,2015,1
Kindred Hospital El Paso,Religion2020,2015,2
King Abdulaziz University,Cooperation2021,2015,4
King Abdulaziz University,CooperationAndReligion,2015,1
King Abdulaziz University,Religion2020,2015,12
King Abdul-Aziz University,Religion2020,2015,2
King Abdullah University of Science and Technology,Cooperation2021,2015,4
King Faisal University,Cooperation2021,2015,2
King Saud University,Cooperation2021,2015,5
King Saud University,Religion2020,2015,6
King’s College London,Cooperation2021,2015,2
King’s College London,Religion2020,2015,35
Kings College,Religion2020,2015,1
King's College,Cooperation2021,2015,1
King's College,Religion2020,2015,40
King's College London,Cooperation2021,2015,4
King's College London,CooperationAndReligion,2015,1
King's College London,Religion2020,2015,72
Kings College London University,Cooperation2021,2015,3
Kings College London University,CooperationAndReligion,2015,1
Kings College London University,Religion2020,2015,16
Kings University College,Religion2020,2015,3
King's University College,Religion2020,2015,1
Kingston University,Cooperation2021,2015,1
Kingston University,Religion2020,2015,16
Kintampo Health Research Centre,Cooperation2021,2015,1
Kintampo Health Research Centre,Religion2020,2015,5
Kliniken Essen-Mitte,Religion2020,2015,6
Knight Analysis,Religion2020,2015,5
Knox College,Cooperation2021,2015,2
Kobe University,Cooperation2021,2015,5
Kobe University,Religion2020,2015,4
Koc University,Religion2020,2015,2
Kochi University of Technology,Cooperation2021,2015,6
Kohima Institute,Religion2020,2015,5
Koje College,Religion2020,2015,2
Kokugakuin University,Religion2020,2015,2
Konrad Lorenz Institute in Vienna,Cooperation2021,2015,14
Kookmin University,Religion2020,2015,6
Korea Advanced Institute of Science and Technology (KAIST),Cooperation2021,2015,7
Korea Institute for International Economic Policy,Cooperation2021,2015,3
Korea University,Religion2020,2015,3
Korea University Guro Hospital,Religion2020,2015,4
Kosice Institute for Society and Health,Religion2020,2015,10
Kripalu Center,Religion2020,2015,1
KU Eichstätt-Ingolstadt,Religion2020,2015,4
KU Leuven,Cooperation2021,2015,15
KU Leuven,CooperationAndReligion,2015,1
KU Leuven,Religion2020,2015,92
Kumamoto University,Cooperation2021,2015,2
Kunming Institute of Botany,Cooperation2021,2015,3
Kunming Institute of Botany,Religion2020,2015,3
Kunming Medical University,Cooperation2021,2015,3
Kunming Medical University,Religion2020,2015,3
Kunsan National University,Religion2020,2015,10
Kurume University,Religion2020,2015,2
Kutztown University of Pennsylvania,Religion2020,2015,1
Kuwait University,Religion2020,2015,4
Kyoto University,Cooperation2021,2015,7
Kyoto University,Religion2020,2015,14
Kyung Hee University,Religion2020,2015,3
Kyungpook National University,Cooperation2021,2015,2
Kyushu University,Cooperation2021,2015,12
La Salle University,Cooperation2021,2015,4
La Salpetriere Hospital,Cooperation2021,2015,1
La Salpetriere Hospital,Religion2020,2015,3
La Sierra University,Religion2020,2015,2
La Trobe University,Cooperation2021,2015,1
La Trobe University,Religion2020,2015,32
Laboratory for Analysis and Architecture of Systems,Cooperation2021,2015,2
Laboratory for Statistics and Computation,Religion2020,2015,1
labspitec at Univeristy of Valencia,Religion2020,2015,3
Lady Hardinge Medical College,Religion2020,2015,1
Lafayette College,Religion2020,2015,5
Lafayette University,Religion2020,2015,5
Lahore University of Management Sciences,Religion2020,2015,12
Laidlaw College,Religion2020,2015,3
Lake Forest College,Religion2020,2015,15
Lamar University,Cooperation2021,2015,1
Lamar University,Religion2020,2015,1
Lancaster Theological Seminary,Religion2020,2015,13
Lancaster University,Cooperation2021,2015,2
Lancaster University,Religion2020,2015,64
Lander College,Religion2020,2015,3
Lander University,Religion2020,2015,4
Land's End,Religion2020,2015,1
Lanzhou University,Religion2020,2015,2
Lariboisière Hospital,Cooperation2021,2015,1
LaTrobe University,Religion2020,2015,8
Laurentian University,Religion2020,2015,4
Laval University,Religion2020,2015,31
Lebanon Valley College,Religion2020,2015,3
Lee University,Religion2020,2015,15
Leeds Metropolitan University,Religion2020,2015,3
Leer.tips,Cooperation2021,2015,1
Leer.tips,CooperationAndReligion,2015,1
Leer.tips,Religion2020,2015,1
Lehigh University,Religion2020,2015,7
Leibniz Institute for Evolution and Biodiversity Research,Cooperation2021,2015,4
Leibniz Institute of European History,Religion2020,2015,2
Leibniz University of Hanover,Cooperation2021,2015,1
Leibniz University of Hanover,Religion2020,2015,3
Leibniz-Institut für Wissensmedien,Religion2020,2015,2
Leiden University,Cooperation2021,2015,27
Leiden University,Religion2020,2015,66
Leipzig University,Cooperation2021,2015,4
Leipzig University,Religion2020,2015,34
Leo Baeck College,Religion2020,2015,4
Lesley University,Religion2020,2015,2
Lesya Ukrainka Volyn National University,Religion2020,2015,3
Leuphana University of Luneburg,Cooperation2021,2015,2
Leuphana University of Luneburg,Religion2020,2015,2
Leuven University,Religion2020,2015,11
Leviathan Security Group,Religion2020,2015,1
Lewis & Clark College,Religion2020,2015,1
Lewis Clark State College,Cooperation2021,2015,1
Lewis Clark State College,Religion2020,2015,1
Lewis University,Religion2020,2015,1
Lexington Theological Seminary,Religion2020,2015,6
"Li Ka Shing Faculty of Medicine,The University of Hong Kong",Religion2020,2015,2
Libera Università Maria SS. Assunta,Religion2020,2015,6
Liberty Fund,Cooperation2021,2015,1
Liberty Fund,Religion2020,2015,6
Liberty University,Religion2020,2015,13
Lifespan Centers for Behavioral and Preventive Medicine,Religion2020,2015,3
Lille Catholic University,Religion2020,2015,6
Lincoln Memorial University,Religion2020,2015,2
Linfield University,Religion2020,2015,1
Lingnan University,Cooperation2021,2015,9
Lingnan University,Religion2020,2015,1
Link Care Center,Religion2020,2015,5
Linköping University,Cooperation2021,2015,5
Linköping University,Religion2020,2015,14
Linnaeus University,Cooperation2021,2015,4
Linnaeus University,CooperationAndReligion,2015,1
Linnaeus University,Religion2020,2015,30
Lisbon University Institute,Religion2020,2015,4
Lithuanian Cultural Research Institute,Religion2020,2015,3
Lithuanian University of Educational Sciences,Cooperation2021,2015,1
Lithuanian University of Educational Sciences,CooperationAndReligion,2015,1
Lithuanian University of Educational Sciences,Religion2020,2015,20
Liverpool Hope University,Religion2020,2015,36
Liverpool John Moores University,Cooperation2021,2015,2
Liverpool John Moores University,Religion2020,2015,13
LMU Munich,Religion2020,2015,10
LoKation® Real Estate,Religion2020,2015,2
Lokmanya Tilak Municipal Medical College,Religion2020,2015,1
Loma Linda University,Cooperation2021,2015,1
Loma Linda University,CooperationAndReligion,2015,1
Loma Linda University,Religion2020,2015,42
London Health Sciences Centre,Religion2020,2015,5
London Interdisciplinary School,Cooperation2021,2015,6
London Metropolitan University,Cooperation2021,2015,3
London Metropolitan University,CooperationAndReligion,2015,3
London Metropolitan University,Religion2020,2015,42
London School of Economics,Cooperation2021,2015,8
London School of Economics,CooperationAndReligion,2015,2
London School of Economics,Religion2020,2015,54
London School of Economics and Political Science,Cooperation2021,2015,10
London School of Economics and Political Science,Religion2020,2015,15
London School of Hygiene and Tropical Medicine,Cooperation2021,2015,17
London School of Hygiene and Tropical Medicine,Religion2020,2015,9
London South Bank University,Cooperation2021,2015,2
London University,Religion2020,2015,4
Los Angeles First Church of the Nazarene,Religion2020,2015,1
Los Angeles Valley College,Religion2020,2015,2
Loughborough University,Cooperation2021,2015,6
Loughborough University,Religion2020,2015,15
Louisiana State University,Cooperation2021,2015,3
Louisiana State University,CooperationAndReligion,2015,1
Louisiana State University,Religion2020,2015,31
Louisiana Tech University,Religion2020,2015,1
Louisville Institute,Religion2020,2015,2
Louisville Presbyterian Theological Seminary,Religion2020,2015,2
Lovisenberg Diaconal College,Cooperation2021,2015,2
Lovisenberg Diaconal College,Religion2020,2015,2
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,2015,1
Loyola Marymount University,Religion2020,2015,12
Loyola University Chicago,Cooperation2021,2015,2
Loyola University Chicago,Religion2020,2015,51
Loyola University Maryland,Cooperation2021,2015,1
Loyola University Maryland,Religion2020,2015,27
Loyola University New Orleans,Cooperation2021,2015,1
Loyola University New Orleans,Religion2020,2015,16
LSU Health Sciences Center/Our Lady of the Lake,Religion2020,2015,2
Ltrek,Religion2020,2015,7
Ludwig Maximilian University of Munich,Cooperation2021,2015,5
Ludwig Maximilian University of Munich,Religion2020,2015,5
Ludwig-Maximilians-Universit,Religion2020,2015,5
Ludwig-Maximilians-University of Munich,Cooperation2021,2015,1
Ludwig-Maximilians-University of Munich,CooperationAndReligion,2015,1
Ludwig-Maximilians-University of Munich,Religion2020,2015,32
LUISS Guido Carli University,Religion2020,2015,5
Luiss University Rome,Cooperation2021,2015,14
Lumiere University Lyon 2,Religion2020,2015,3
Lund University,Cooperation2021,2015,9
Lund University,Religion2020,2015,19
Luther College,Religion2020,2015,8
Luther Seminary,Religion2020,2015,7
Luther Theological Seminary,Religion2020,2015,2
Lutheran School of theology at Chicago,Religion2020,2015,3
Lutheran Theological Seminary,Religion2020,2015,4
Lutheran Theological Seminary at Philadelphia,Religion2020,2015,7
Lutheran Theological Southern Seminary,Religion2020,2015,2
Maastricht University,Cooperation2021,2015,34
Maastricht University,Religion2020,2015,20
Macalaster College,Religion2020,2015,3
Macalester College,Cooperation2021,2015,3
Macalester College,Religion2020,2015,3
Macau University of Science and Technology,Cooperation2021,2015,3
Macau University of Science and Technology,Religion2020,2015,2
Macedonian Academy of Arts and Sciences,Cooperation2021,2015,7
Macquarie University,Cooperation2021,2015,15
Macquarie University,CooperationAndReligion,2015,2
Macquarie University,Religion2020,2015,46
Magdalene College,Cooperation2021,2015,6
Magdalene College,Religion2020,2015,3
Mahana Theraputics,Cooperation2021,2015,2
Maharishi International University,Religion2020,2015,3
Maharishi Markandeshwar University,Religion2020,2015,1
Maharishi University of Management,Religion2020,2015,21
Mahidol University,Cooperation2021,2015,2
Mahidol University,Religion2020,2015,23
Mail & Guardian,Religion2020,2015,1
Maimonides Medical Center,Religion2020,2015,4
Makerere University,Religion2020,2015,3
Malardalen University,Cooperation2021,2015,4
Malmo University,Religion2020,2015,6
Malmö University,Religion2020,2015,3
Management Development Institute,Cooperation2021,2015,1
Management Development Institute,Religion2020,2015,10
Manchester Cathedral,Religion2020,2015,3
Manchester Metropolitan University,Cooperation2021,2015,4
Manchester Metropolitan University,Religion2020,2015,12
Manchester University,Cooperation2021,2015,21
Manchester University,Religion2020,2015,5
Manhattan College,Cooperation2021,2015,2
Manhattan College,Religion2020,2015,8
MAPS Psychedelic Science Center,Religion2020,2015,2
Marburg University,Religion2020,2015,2
Maria Curie-Sklodowska University,Religion2020,2015,3
Marian University,Religion2020,2015,2
Marie Curie Hospice Homestead,Religion2020,2015,1
Marquette University,Religion2020,2015,19
Marshall University,Religion2020,2015,5
Martin Luther University of Halle-Wittenberg,Cooperation2021,2015,1
Martin Luther University of Halle-Wittenberg,Religion2020,2015,15
Maryland University of Integrative Health,Religion2020,2015,1
Marymount Manhattan College,Religion2020,2015,6
Marymount University,Religion2020,2015,6
Masaryk University,Cooperation2021,2015,8
Masaryk University,CooperationAndReligion,2015,5
Masaryk University,Religion2020,2015,20
Mashhad University,Religion2020,2015,6
Massachusetts General Hospital,Religion2020,2015,33
Massachusetts Institute of Technology,Cooperation2021,2015,57
Massachusetts Institute of Technology,Religion2020,2015,13
Massachusetts Medical Society,Religion2020,2015,4
Massey University,Cooperation2021,2015,3
Massey University,Religion2020,2015,32
Masters and Johnson,Religion2020,2015,9
Mater Dei Hospital,Religion2020,2015,1
Mater Dei Institute of Education,Religion2020,2015,5
Maulana Malik Ibrahim State Islamic University,Religion2020,2015,3
Max Ernst GGZ,Religion2020,2015,3
Max Planck Institute,Cooperation2021,2015,19
Max Planck Institute,Religion2020,2015,10
Max Planck Institute for Chemical Ecology,Cooperation2021,2015,2
Max Planck Institute for Comparative Public Law and International Law,Religion2020,2015,3
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Cooperation2021,2015,17
Max Planck Institute for Evolutionary Biology,Cooperation2021,2015,21
Max Planck Institute for Human Cognitive and Brain Sciences,Cooperation2021,2015,5
Max Planck Institute for Human Cognitive and Brain Sciences,Religion2020,2015,14
Max Planck Institute for Human Development,Cooperation2021,2015,11
Max Planck Institute for Human Development,Religion2020,2015,4
Max Planck- Institute for Informatics,Cooperation2021,2015,11
"Max Planck Institute for International, European and Regulatory Procedural Law",Cooperation2021,2015,9
Max Planck Institute for Psychological Research,Religion2020,2015,1
Max Planck Institute for Research on Collective Goods,Cooperation2021,2015,10
Max Planck Institute for Social Anthropology,Religion2020,2015,8
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,2015,2
Max Planck Institute for the Science of Human History,Cooperation2021,2015,8
Max Planck Institute for the Science of Human History,CooperationAndReligion,2015,6
Max Planck Institute for the Science of Human History,Religion2020,2015,12
Max Planck Institute for the Study of Religious and Ethnic Diversity,Religion2020,2015,41
Max Planck Institute of Psychiatry,Cooperation2021,2015,3
Max Weber Center for Advanced Cultural and Social Scudies,Cooperation2021,2015,3
Max Weber Center for Advanced Cultural and Social Scudies,CooperationAndReligion,2015,1
Max Weber Center for Advanced Cultural and Social Scudies,Religion2020,2015,12
Max-Planck-Institute for Research for Collective Goods,Cooperation2021,2015,5
Max-Planck-Institute for Research for Collective Goods,Religion2020,2015,1
Maynooth University,Cooperation2021,2015,1
Maynooth University,CooperationAndReligion,2015,1
Maynooth University,Religion2020,2015,9
Mayo Clinic - Phoenix,Cooperation2021,2015,4
Mayo Clinic - Phoenix,CooperationAndReligion,2015,2
Mayo Clinic - Phoenix,Religion2020,2015,12
Mayo Clinic - Rochester,Religion2020,2015,18
Mayo Clinic College of Medicine and Science,Religion2020,2015,2
Mayo Clinic School of Medicine,Cooperation2021,2015,5
Mayo Clinic School of Medicine,CooperationAndReligion,2015,5
Mayo Clinic School of Medicine,Religion2020,2015,30
Mayo Foundation for Medical Education and Research,Religion2020,2015,1
Mazandaran University of Medical Sciences,Religion2020,2015,5
McDaniel College,Religion2020,2015,4
McGill University,Cooperation2021,2015,1
McGill University,Religion2020,2015,69
McLean Hospital,Cooperation2021,2015,10
McLean Hospital,Religion2020,2015,5
McMaster Divinity School,Religion2020,2015,2
McMaster University,Cooperation2021,2015,12
McMaster University,Religion2020,2015,21
Medica Campestre,Cooperation2021,2015,1
Medica Campestre,CooperationAndReligion,2015,1
Medica Campestre,Religion2020,2015,2
Medical College of Georgia,Religion2020,2015,4
Medical College of Wisconsin,Cooperation2021,2015,2
Medical College of Wisconsin,Religion2020,2015,49
Medical University of Austria,Religion2020,2015,3
Medical University of Graz,Religion2020,2015,7
Medical University of Lübeck,Religion2020,2015,4
Medical University of Lublin,Religion2020,2015,7
Medical University of Poznan,Religion2020,2015,7
Medical University of Silesia,Religion2020,2015,1
Medical University of South Carolina,Religion2020,2015,6
Medical University of Vienna,Religion2020,2015,2
Medipol University,Cooperation2021,2015,1
Medipol University,CooperationAndReligion,2015,1
Medipol University,Religion2020,2015,7
Meertens Instituut (KNAW),Religion2020,2015,1
Meiji University,Cooperation2021,2015,3
Melbourne Austin Hospital,Religion2020,2015,6
Memorial Sloan Kettering Cancer Center,Cooperation2021,2015,3
Memorial Sloan Kettering Cancer Center,Religion2020,2015,48
Memorial University of Newfoundland,Religion2020,2015,13
Mens Sana Monographs,Religion2020,2015,1
Mental Health Center,Religion2020,2015,6
Mental Health Christoph,Religion2020,2015,1
Mercer University,Religion2020,2015,13
Messerli Research Institute,Cooperation2021,2015,7
Messiah College,Religion2020,2015,26
Methodist Homes (MHA),Religion2020,2015,3
Methodist Theological School,Religion2020,2015,2
Methodist University,Religion2020,2015,1
Methodist University of Sao Paulo,Religion2020,2015,4
Metropolitan State University,Religion2020,2015,2
Mettaa Institute,Religion2020,2015,1
Mexican Institute of Social Security,Cooperation2021,2015,13
Mexican Institute of Social Security,CooperationAndReligion,2015,13
Mexican Institute of Social Security,Religion2020,2015,11
"MF Norwegian School of Theology, Religion, and Society",Religion2020,2015,24
MGH Institute of Health Professions,Religion2020,2015,4
Miami University,Cooperation2021,2015,4
Miami University,Religion2020,2015,27
Michael E. DeBakey VA Medical Center,Religion2020,2015,2
Michael E. DeBakey VAMC,Religion2020,2015,5
Michigan State University,Cooperation2021,2015,26
Michigan State University,Religion2020,2015,38
Michlalah Jersusalem Academic College,Religion2020,2015,4
Microsoft,Cooperation2021,2015,2
Mid-Atlantic Crossroads,Cooperation2021,2015,8
Middle East Cancer Consortium,Religion2020,2015,10
Middle Tennesse State University,Religion2020,2015,17
Middlebury College,Religion2020,2015,1
Middlebury Institute of International Studies at Monterey,Religion2020,2015,4
Middlesex University,Cooperation2021,2015,9
Middlesex University,Religion2020,2015,17
Millersville State College,Religion2020,2015,2
Mind and Life Institute,Religion2020,2015,10
Mind-Body Evolution,Religion2020,2015,2
"Mindfulness, Compassion and Yoga Based Therapies",Religion2020,2015,1
Ministry of Education and Vocational Training,Religion2020,2015,10
Ministry of Finance,Cooperation2021,2015,1
Ministry of Finance,Religion2020,2015,3
"Ministry of Health, Iran",Cooperation2021,2015,1
"Ministry of Health, Iran",Religion2020,2015,29
"Ministry of health, Israel",Cooperation2021,2015,4
"Ministry of health, Israel",CooperationAndReligion,2015,2
"Ministry of health, Israel",Religion2020,2015,4
Ministry of Religious Affairs of the Republic of Indonesia,Cooperation2021,2015,2
Ministry of Religious Affairs of the Republic of Indonesia,CooperationAndReligion,2015,2
Ministry of Religious Affairs of the Republic of Indonesia,Religion2020,2015,6
Minneapolis Medical Research Foundation,Religion2020,2015,1
Minneapolis VA Health Care System,Religion2020,2015,4
Minneapolis Veterans Affairs Medical Center,Religion2020,2015,3
Minnesota VA Healthcare System,Religion2020,2015,2
Miriam Hospital,Religion2020,2015,1
Mission Economic Development Agency,Cooperation2021,2015,1
Mission Economic Development Agency,CooperationAndReligion,2015,1
Mission Economic Development Agency,Religion2020,2015,4
Mississippi College,Religion2020,2015,3
Mississippi State University,Religion2020,2015,8
Missouri State University,Cooperation2021,2015,5
Missouri State University,Religion2020,2015,13
MIT Media Lab,Cooperation2021,2015,7
MIT Media Lab,Religion2020,2015,2
MJHS Institute for Innovation and Palliative Care,Religion2020,2015,3
Moffitt Cancer Center and Research Institute,Religion2020,2015,13
Monash University,Cooperation2021,2015,51
Monash University,CooperationAndReligion,2015,1
Monash University,Religion2020,2015,141
Monash University Malaysia,Religion2020,2015,3
Monmouth College,Cooperation2021,2015,1
Monmouth College,CooperationAndReligion,2015,1
Monmouth College,Religion2020,2015,4
Montefiore Medical Center,Religion2020,2015,1
Montefiore-Einstein Center for Cancer Care,Religion2020,2015,1
Montreal School of Theology,Religion2020,2015,5
Moody Bible Institute,Religion2020,2015,2
"Moravian Church Diabetes Clinics, Bluefields and  Pearl Lagoon",Religion2020,2015,1
Morehead State University,Religion2020,2015,2
Morehouse College,Cooperation2021,2015,12
Morehouse School of Medicine,Cooperation2021,2015,1
Morehouse School of Medicine,CooperationAndReligion,2015,1
Morehouse School of Medicine,Religion2020,2015,3
Morgan State University,Religion2020,2015,5
Mormon Stories Podcast,Religion2020,2015,6
Mount Holyoke College,Religion2020,2015,6
Mount Royal University,Religion2020,2015,13
Mount Saint Mary's University,Religion2020,2015,5
Mount Saint Vincent University,Religion2020,2015,12
Mount Sinai Hospital Toronto,Cooperation2021,2015,3
Mount Sinai Hospital Toronto,Religion2020,2015,4
Mount Sinai School of Medicine,Religion2020,2015,7
Mount Vernon Hospital,Religion2020,2015,3
Moving Byzantium,Religion2020,2015,4
MRC/UVRI Uganda Research Unit,Religion2020,2015,2
Mt. Sinai School of Medicine,Religion2020,2015,2
MTs Babussalam Pagelaran Malang,Cooperation2021,2015,1
MTs Babussalam Pagelaran Malang,Religion2020,2015,7
Muhlenberg College,Religion2020,2015,5
Munich School of Philosophy,Cooperation2021,2015,1
Munich School of Philosophy,CooperationAndReligion,2015,1
Munich School of Philosophy,Religion2020,2015,44
Murdoch University,Religion2020,2015,8
Murray Edwards College,Religion2020,2015,4
Murray State,Cooperation2021,2015,1
Museo Nacional de Ciencias Naturales,Cooperation2021,2015,1
Museum for Cultural History,Cooperation2021,2015,2
Museum für Naturkunde Berlin,Cooperation2021,2015,4
Myriad Neuroscience,Religion2020,2015,2
Nagasaki University,Cooperation2021,2015,3
Nagoya University,Cooperation2021,2015,10
Nanjing Normal University,Cooperation2021,2015,3
Nanjing Normal University,Religion2020,2015,3
Nanjing University,Cooperation2021,2015,14
Nanjing University,Religion2020,2015,17
Nanjing University of Aeronautics and Astronautics,Religion2020,2015,1
Nanjing University of Posts and Telecommunications,Cooperation2021,2015,2
Nankai University,Cooperation2021,2015,22
Nanyang Technological University,Cooperation2021,2015,23
Nanyang Technological University,Religion2020,2015,48
Naropa University,Religion2020,2015,14
Nashotah House,Religion2020,2015,6
National Academy of Sciences of Ukraine,Religion2020,2015,12
National Autonomous University of Mexico,Cooperation2021,2015,1
National Cancer Institute,Cooperation2021,2015,1
National Cancer Institute,Religion2020,2015,4
National Cancer Institute of Milan,Cooperation2021,2015,3
National Cancer Institute of Milan,CooperationAndReligion,2015,1
National Cancer Institute of Milan,Religion2020,2015,7
National Center for Dual Diagnoses,Religion2020,2015,4
National Center for Nanoscience and Technology,Cooperation2021,2015,6
National Center for Nanoscience and Technology,Religion2020,2015,3
National Center for PTSD,Religion2020,2015,7
National Center for Science Education,Religion2020,2015,1
National Center for Veterans Studies,Religion2020,2015,11
National Center of Neurology and Psychiatry,Religion2020,2015,1
National Central University,Religion2020,2015,7
National Centre for Immunization Research and Surveillance of Vaccine Preventable Diseases,Cooperation2021,2015,1
National Centre for Immunization Research and Surveillance of Vaccine Preventable Diseases,Religion2020,2015,5
National Centre of Medicine and Science in Sports,Religion2020,2015,1
National Cerebral and Cardiovascular Center,Cooperation2021,2015,4
National Cheng Kung University,Religion2020,2015,1
National Chiao Tung University,Religion2020,2015,5
National Church Life Survey,Cooperation2021,2015,2
National Church Life Survey,Religion2020,2015,9
National Conservatory of Arts and Trades,Cooperation2021,2015,4
National Council for Scientific and Technological Development,Religion2020,2015,5
National Counterterrorism Agency,Religion2020,2015,6
National Defense Medical College,Religion2020,2015,4
National Distance Education University,Cooperation2021,2015,2
National Distance Education University,Religion2020,2015,3
National Economic Research Institute,Cooperation2021,2015,1
National Economic Research Institute,CooperationAndReligion,2015,1
National Economic Research Institute,Religion2020,2015,3
"National Heart, Lung and Blood Institute",Religion2020,2015,1
national institute for advanced industrial science and technology,Cooperation2021,2015,4
National Institute for Healthcare Research,Religion2020,2015,4
National Institute for Mathematical Sciences,Cooperation2021,2015,3
National Institute for Mental Health and Neurosciences,Cooperation2021,2015,1
National Institute for Mental Health and Neurosciences,Religion2020,2015,21
National Institute of Cancer Prevention and Research,Cooperation2021,2015,1
National Institute of Cancer Prevention and Research,CooperationAndReligion,2015,1
National Institute of Cancer Prevention and Research,Religion2020,2015,2
National Institute of Education – Singapore,Religion2020,2015,2
National Institute of Environmental Research,Religion2020,2015,2
National Institute of Health and Science on Aging,Religion2020,2015,3
National Institute of Industrial Engineering,Cooperation2021,2015,1
National Institute of Industrial Engineering,CooperationAndReligion,2015,1
National Institute of Industrial Engineering,Religion2020,2015,2
National Institute of Mental Health,Cooperation2021,2015,1
National Institute of Mental Health and Neuro Science,Religion2020,2015,1
National Institute of Mental Health and Neuro Sciences,Religion2020,2015,4
National Institute of Mental Health and Neurosciences,Cooperation2021,2015,1
National Institute of Mental Health and Neurosciences,Religion2020,2015,8
National Institute Of Mental Health Harvard Medical School,Religion2020,2015,3
National Institute of Psychology,Religion2020,2015,2
National Institute of Siddha Medical Sciences,Religion2020,2015,1
National Institutes of Health,Religion2020,2015,14
National Kaohsiung Normal University,Religion2020,2015,6
National Law School of India University,Cooperation2021,2015,1
National Law School of India University,Religion2020,2015,6
National Library of Medicine,Cooperation2021,2015,10
National Museum of Denmark,Religion2020,2015,6
National Museum of Ethnology,Religion2020,2015,5
National Palliative Care Research Center,Religion2020,2015,3
National Public Radio,Religion2020,2015,6
National Renewable Energy Laboratory,Religion2020,2015,6
National Research Council,Cooperation2021,2015,5
National Research Council,Religion2020,2015,2
National Research University Higher School of Economics,Cooperation2021,2015,1
National Research University Higher School of Economics,Religion2020,2015,11
National Science Foundation,Cooperation2021,2015,2
National Science Foundation,Religion2020,2015,4
National Scientific and Technical Research Council,Religion2020,2015,9
National Study of Treatment and Addiction Recovery Residences,Religion2020,2015,2
National Sun Yat-sen University,Cooperation2021,2015,2
National Taichung University of Science and Technology,Religion2020,2015,6
National Taipei University,Religion2020,2015,4
National Taiwan Sport University,Religion2020,2015,2
National Taiwan University,Cooperation2021,2015,2
National Taiwan University,Religion2020,2015,12
National Technical University of Athens,Cooperation2021,2015,13
National University of Colombia,Cooperation2021,2015,3
National University of Colombia,Religion2020,2015,7
National University of Ireland,Cooperation2021,2015,15
National University of Ireland,Religion2020,2015,9
"National University of Ireland, Galway",Cooperation2021,2015,9
National University of Malaysia,Religion2020,2015,9
National University of Singapore,Cooperation2021,2015,10
National University of Singapore,CooperationAndReligion,2015,1
National University of Singapore,Religion2020,2015,75
National Yang-Ming University,Religion2020,2015,2
National Yunlin University of Science and Technology,Religion2020,2015,1
Nature,Cooperation2021,2015,1
Nature Publishing Group,Cooperation2021,2015,1
Naval Amphibious Base Coronado,Religion2020,2015,16
Nazarbayev University,Religion2020,2015,13
Nazarene Theological Seminary,Religion2020,2015,1
Nazareth College,Religion2020,2015,1
NCLS Research,Cooperation2021,2015,2
NCLS Research,Religion2020,2015,6
Nebraska Occupational Health Center,Religion2020,2015,2
NEC Labs,Cooperation2021,2015,8
NEC Labs,Religion2020,2015,1
Neo-Calvinism Research Institute,Religion2020,2015,9
Neshama,Religion2020,2015,2
Netherlands Institute for Advanced Study in the Humanities and Social Sciences,Religion2020,2015,6
Netherlands Institute for Health Sciences,Cooperation2021,2015,1
Netherlands Institute for Health Sciences,Religion2020,2015,4
Netherlands Institute of Ecology,Cooperation2021,2015,1
Netherlands Interdiscplinary Demographic Institute,Religion2020,2015,1
Neuro Habits,Cooperation2021,2015,1
Neuro Habits,CooperationAndReligion,2015,1
Neuro Habits,Religion2020,2015,1
NeuroMyth,Religion2020,2015,2
Neuroscience Center,Religion2020,2015,2
Neuroscience Institute-El Camino Hospital,Religion2020,2015,1
NEURO-SYS,Religion2020,2015,3
New College of California,Religion2020,2015,1
New England Complex Systems Institute,Religion2020,2015,2
New England Journal of Medicine,Cooperation2021,2015,7
New School for Social Research,Religion2020,2015,5
New World Medical,Religion2020,2015,3
New York City College of Technology,Religion2020,2015,3
New York Medical College,Religion2020,2015,1
New York Presbyterian Hospital,Cooperation2021,2015,1
New York Presbyterian Hospital,Religion2020,2015,11
New York State Psychiatric Institute,Religion2020,2015,4
New York University,Cooperation2021,2015,31
New York University,Religion2020,2015,101
New York University Abu Dhabi,Religion2020,2015,10
New York University Endocrine Associates,Religion2020,2015,1
New York University School of Medicine,Cooperation2021,2015,1
New York University School of Medicine,Religion2020,2015,1
Newcastle University,Cooperation2021,2015,6
Newcastle University,Religion2020,2015,39
NHS Foundation,Religion2020,2015,3
Niagara County Community College,Cooperation2021,2015,4
Niagara County Community College,Religion2020,2015,3
NICM (National Institute of Complementary Medicine) Western Sydney University,Religion2020,2015,2
Nigerian Institute of Advanced Legal Studies,Cooperation2021,2015,6
Nigerian Institute of Advanced Legal Studies,Religion2020,2015,1
NIH Clinical Center,Religion2020,2015,20
Nihon University,Religion2020,2015,3
Nihon University School of Medicine,Religion2020,2015,2
NIMH Family Research Consortium IV- UCLA,Religion2020,2015,1
Ningxia Medical University,Religion2020,2015,10
Ningxia University,Cooperation2021,2015,1
Ningxia University,Religion2020,2015,1
NIVEL,Religion2020,2015,2
Njala University,Religion2020,2015,2
Nord University,Cooperation2021,2015,1
Nord University,Religion2020,2015,3
Nordic School of Holistic Medicine,Religion2020,2015,1
North American Lutheran Seminary,Religion2020,2015,4
North Carolina Agricultural & Technical State University,Religion2020,2015,3
North Carolina State University,Cooperation2021,2015,3
North Carolina State University,CooperationAndReligion,2015,2
North Carolina State University,Religion2020,2015,23
North Caucasian Institute—Branch of the Russian Academy of the National Economy and Civil Service,Religion2020,2015,12
North China Electric Power University,Religion2020,2015,12
North Dakota State University,Cooperation2021,2015,4
North Dakota State University,Religion2020,2015,14
North Park University,Cooperation2021,2015,1
North Park University,Religion2020,2015,4
North West University,Religion2020,2015,59
Northeastern Seminary,Religion2020,2015,1
Northeastern University,Cooperation2021,2015,11
Northeastern University,Religion2020,2015,19
"Northeastern University (Shenyang, China)",Cooperation2021,2015,5
Northern Arizona University,Religion2020,2015,17
northern Baptist theological seminary,Religion2020,2015,6
Northern Illinois University,Religion2020,2015,19
Northern Roots Therapy Center,Religion2020,2015,1
Northern Sydney Local Health District,Religion2020,2015,1
Northumbria University,Religion2020,2015,4
Northwell Health,Religion2020,2015,1
Northwest University,Religion2020,2015,14
North-West University,Cooperation2021,2015,1
North-West University,Religion2020,2015,66
Northwestern College,Religion2020,2015,1
Northwestern Medicine,Religion2020,2015,5
Northwestern Memorial Hospital,Religion2020,2015,1
Northwestern Polytechnical University,Cooperation2021,2015,16
Northwestern Polytechnical University,Religion2020,2015,3
Northwestern University,Cooperation2021,2015,19
Northwestern University,Religion2020,2015,73
Northwood University,Religion2020,2015,6
Norwegian Institute of International Affairs,Cooperation2021,2015,2
Norwegian Institute of Public Health,Religion2020,2015,17
Norwegian Ministry of Foreign Affaris,Cooperation2021,2015,5
Norwegian School of Economics and Business Administration,Cooperation2021,2015,4
Norwegian School of Theology,Religion2020,2015,10
Norwegian University of Science and Technology,Cooperation2021,2015,7
Norwegian University of Science and Technology,CooperationAndReligion,2015,1
Norwegian University of Science and Technology,Religion2020,2015,47
Norweign School of Theology,Religion2020,2015,4
Notre Dame College,Religion2020,2015,3
Nottingham Trent University,Cooperation2021,2015,1
Nottingham Trent University,Religion2020,2015,47
Nova Southeastern University,Religion2020,2015,15
NOVA University of Lisbon,Cooperation2021,2015,9
Nove de Julho University,Religion2020,2015,5
Novosibirsk State University,Religion2020,2015,2
NPO-Interational Research Institute,Religion2020,2015,1
Nurses Christian Fellowship,Religion2020,2015,5
NVIDIA,Cooperation2021,2015,4
NVIDIA,Religion2020,2015,2
Nyenrode Business University,Religion2020,2015,4
NYU Grossman School of Medicine,Religion2020,2015,3
Oak Hill College,Religion2020,2015,12
Oakland University,Cooperation2021,2015,2
Oakland University,CooperationAndReligion,2015,1
Oakland University,Religion2020,2015,7
Oakwood University,Religion2020,2015,1
Oberlin College,Religion2020,2015,3
ObjectVideo Labs,Cooperation2021,2015,37
ObjectVideo Labs,Religion2020,2015,1
Occidental College,Religion2020,2015,4
"Ochsner Health, Institute for Medicine, Education, and Spirituality",Religion2020,2015,6
Odense University,Cooperation2021,2015,2
Ohio Health,Religion2020,2015,2
Ohio Northern University,Religion2020,2015,8
Ohio State University,Cooperation2021,2015,52
Ohio State University,CooperationAndReligion,2015,12
Ohio State University,Religion2020,2015,71
Ohio University,Cooperation2021,2015,2
Ohio University,Religion2020,2015,37
Oklahoma State University,Cooperation2021,2015,1
Oklahoma State University,Religion2020,2015,21
Old Dominion University,Religion2020,2015,1
Old Dominion University - Virginia Modeling Analysis and Stimulation Center,Religion2020,2015,5
Omega Graduate School,Religion2020,2015,3
OneOncology,Religion2020,2015,2
Ono Academic College,Religion2020,2015,1
Open University (The),Religion2020,2015,63
Open University of Catalonia,Religion2020,2015,3
Open University of Cyprus,Religion2020,2015,8
Open University of Japan,Religion2020,2015,1
Opole University,Religion2020,2015,9
Oral Roberts University,Religion2020,2015,8
Orange Labs,Cooperation2021,2015,2
Oranim College of Education,Religion2020,2015,5
Oregon College of Oriental Medicine,Religion2020,2015,1
Oregon Health & Science University,Cooperation2021,2015,1
Oregon Health & Science University,Religion2020,2015,35
Oregon State University,Cooperation2021,2015,6
Oregon State University,Religion2020,2015,5
Orot Israel College,Religion2020,2015,1
Orthodox Church,Religion2020,2015,2
Osaka Prefecture University,Cooperation2021,2015,3
Osaka Savings Credit Union,Religion2020,2015,1
Osaka University,Cooperation2021,2015,2
Oslo Metropolitan University,Religion2020,2015,6
Oslo University,Religion2020,2015,5
Osmania University,Religion2020,2015,1
Ostfalia Hochschule,Religion2020,2015,1
Otago University,Religion2020,2015,4
Otto-von-Guericke University Magdeburg,Cooperation2021,2015,10
Otto-von-Guericke-Universität,Cooperation2021,2015,2
Otto-von-Guericke-Universität,Religion2020,2015,1
Oxford Brookes University,Cooperation2021,2015,1
Oxford Brookes University,Religion2020,2015,45
Oxford Centre for Mission Studies,Cooperation2021,2015,1
Oxford Centre for Mission Studies,Religion2020,2015,5
Oxford University,Cooperation2021,2015,23
Oxford University,CooperationAndReligion,2015,5
Oxford University,Religion2020,2015,91
Oxiquim S.A.,Cooperation2021,2015,4
Oxiquim S.A.,CooperationAndReligion,2015,4
Oxiquim S.A.,Religion2020,2015,5
Pace University,Cooperation2021,2015,1
Pace University,CooperationAndReligion,2015,1
Pace University,Religion2020,2015,2
Pace Univesity,Religion2020,2015,9
Pacific Lutheran University,Religion2020,2015,3
Pacific Union College,Religion2020,2015,1
Pacific University,Religion2020,2015,12
Pacifica Graduate Institute,Religion2020,2015,1
Padjadjaran University,Religion2020,2015,16
"Palacky University, Olomouc",Religion2020,2015,10
Palo Alto University,Religion2020,2015,6
Panjab University,Religion2020,2015,1
Panteion University of Social and Political Sciences,Religion2020,2015,5
Paracelsus Medical University,Cooperation2021,2015,1
Paracelsus Medical University,CooperationAndReligion,2015,1
Paracelsus Medical University,Religion2020,2015,8
Pardee RAND Graduate School,Religion2020,2015,8
Parent Spring,Religion2020,2015,1
Parexel,Religion2020,2015,6
Paris Descarte University,Religion2020,2015,8
Paris School of Economics,Cooperation2021,2015,2
Paris School of Economics,Religion2020,2015,3
Paris Sud University,Religion2020,2015,1
Paris West University Nanterre La Défense,Religion2020,2015,1
Paris-Sorbonne University,Religion2020,2015,2
Pasteur Institute,Cooperation2021,2015,1
Patanjali Food and Herbal Park,Religion2020,2015,6
Patanjali Research Foundation,Religion2020,2015,9
Patanjali Yogpeeth,Religion2020,2015,12
Patient-Centered Outcomes Research Institute,Religion2020,2015,2
Paul Sabatier University,Religion2020,2015,8
Paulista State University Júlio de Mesquita Filho,Religion2020,2015,1
Pavol Jozef Safarik University,Religion2020,2015,4
Pazmny Peter Catholic University BTK,Religion2020,2015,6
Peace Research Institute Frankfurt,Cooperation2021,2015,1
Peace Research Institute Frankfurt,CooperationAndReligion,2015,1
Peace Research Institute Frankfurt,Religion2020,2015,7
Peace Research Institute Oslo PRIO,Religion2020,2015,1
PEAK Lab,Religion2020,2015,3
Peking University,Cooperation2021,2015,53
Peking University,Religion2020,2015,36
Peking University Cancer Hospital and Institute,Religion2020,2015,4
Peking University People's Hospital,Cooperation2021,2015,3
Peking University People's Hospital,Religion2020,2015,3
"Peking University, Beijing",Religion2020,2015,3
Penn State University,Religion2020,2015,15
Penn State York,Religion2020,2015,6
PennState Harrisburg,Religion2020,2015,10
Pennsylvania State University,Cooperation2021,2015,13
Pennsylvania State University,Religion2020,2015,59
Pentecostal Theological Seminary,Religion2020,2015,2
Peoples' Friendship University of Russia,Cooperation2021,2015,1
Peoples' Friendship University of Russia,CooperationAndReligion,2015,1
Peoples' Friendship University of Russia,Religion2020,2015,7
Pepperdine University,Cooperation2021,2015,1
Pepperdine University,Religion2020,2015,13
Perdana University,Religion2020,2015,1
Peres Academic Center,Religion2020,2015,4
Perimeter Institute for Theoretical Physics,Cooperation2021,2015,6
Persada Indonesia University,Religion2020,2015,5
Péter Pázmány Catholic University,Religion2020,2015,1
Pew Research Center,Cooperation2021,2015,1
Pew Research Center,CooperationAndReligion,2015,1
Pew Research Center,Religion2020,2015,13
Philadelphia Corporation for Aging,Cooperation2021,2015,1
Philadelphia Corporation for Aging,Religion2020,2015,2
Philadelphia VA Medical Center,Religion2020,2015,1
Philipps University Marburg,Cooperation2021,2015,1
Philipps University Marburg,CooperationAndReligion,2015,1
Philipps University Marburg,Religion2020,2015,12
Philipps-Universität Marburg,Cooperation2021,2015,1
Philipps-Universität Marburg,Religion2020,2015,7
Philosophical-Theological University of Munster,Religion2020,2015,10
Philosophical-Theological University Sankt Georgen,Religion2020,2015,2
Philosophy Institute of the Ukrainian Academy of Sciences,Religion2020,2015,7
Phoenix Australia - Centre for Posttraumatic Mental Health,Religion2020,2015,2
Physicians Wellness Collaborative,Religion2020,2015,5
Piedmont Virginia Community College,Religion2020,2015,2
Pima Community College,Religion2020,2015,1
Pittsburg State University,Religion2020,2015,1
Pittsburgh Theological Seminary,Religion2020,2015,6
Pitzer College,Religion2020,2015,3
PLA University of Science and Technology,Cooperation2021,2015,7
PLA University of Science and Technology,Religion2020,2015,1
Planned Parenthood,Religion2020,2015,9
Pohang University of Science and Technology,Cooperation2021,2015,1
Point Loma Nazarene University,Religion2020,2015,8
Polish Academy of Sciences,Religion2020,2015,1
Politecnico di Milano,Religion2020,2015,6
Polytechnic University of Madrid,Cooperation2021,2015,7
Pomona College,Religion2020,2015,1
Pompestichting Centre for Forensic Psychiatry,Cooperation2021,2015,1
Pompestichting Centre for Forensic Psychiatry,Religion2020,2015,3
Pompeu Fabra University,Cooperation2021,2015,2
Pontifical Academy of Theology,Religion2020,2015,18
Pontifical Biblical Institute,Religion2020,2015,3
Pontifical Catholic University of Campinas,Religion2020,2015,14
Pontifical Catholic University of Chile,Cooperation2021,2015,1
Pontifical Catholic University of Chile,Religion2020,2015,5
Pontifical Catholic University of Parana,Religion2020,2015,3
Pontifical Catholic University of Rio,Cooperation2021,2015,2
Pontifical Catholic University of Rio,Religion2020,2015,3
Pontifical Catholic University of Sao Paulo,Cooperation2021,2015,2
Pontifical Catholic University of Sao Paulo,CooperationAndReligion,2015,1
Pontifical Catholic University of Sao Paulo,Religion2020,2015,17
Pontifical Gregorian University,Religion2020,2015,1
Pontifical University Antonianum,Religion2020,2015,7
Pontifical University of John Paul II in Krakow,Religion2020,2015,20
Pontifical University of John Paul II in Kraków,Religion2020,2015,7
Pontifical University of the Holy Cross,Religion2020,2015,6
Pontificia Universidad Católica de Chile,Religion2020,2015,3
Pontificia Universidade Catolica do Parana,Religion2020,2015,11
Population Studies Center,Religion2020,2015,2
Portland State University,Cooperation2021,2015,8
Portland State University,Religion2020,2015,1
Portuguese Catholic University,Religion2020,2015,8
Postgraduate Institute of Medical Education & Research,Religion2020,2015,14
Prague College of Psychosocial Studies,Religion2020,2015,10
Prague University,Religion2020,2015,3
Prairie View A&M university,Religion2020,2015,6
Prakash Neurology and Psychiatry Clinic,Cooperation2021,2015,1
"Presbyterian Theological Seminary, Seoul",Religion2020,2015,6
Press Ganey Associates,Religion2020,2015,3
Prevention Research Center,Religion2020,2015,4
Prince of Songkla University,Religion2020,2015,7
Princess Alice Hospital,Religion2020,2015,13
Princess Margaret Cancer Centre,Religion2020,2015,6
Princeton Theological Seminary,Religion2020,2015,81
Princeton University,Cooperation2021,2015,38
Princeton University,Religion2020,2015,114
Priory of St Michael the Archangel,Religion2020,2015,4
Private Practice,Religion2020,2015,47
"Private University College of Education, Diocese of Linz",Religion2020,2015,9
PRN Pharmaceutical Research Network LLC,Religion2020,2015,1
Protection of Conscience Project,Religion2020,2015,4
Protestant Church in Hesse and Nassau,Religion2020,2015,1
Protestant Theological University,Cooperation2021,2015,1
Protestant Theological University,CooperationAndReligion,2015,1
Protestant Theological University,Religion2020,2015,34
Protestant University of Applied Sciences,Religion2020,2015,5
Protestant University Wuppertal/Bethel,Religion2020,2015,4
Providence Care,Religion2020,2015,1
Providence College,Cooperation2021,2015,2
Providence College,CooperationAndReligion,2015,1
Providence College,Religion2020,2015,7
pruvio,Religion2020,2015,5
Psychocultural Institute- Toronto,Religion2020,2015,1
Psychological Pedagogic Development Centre,Religion2020,2015,3
Psychological Safety Australia,Cooperation2021,2015,3
Psychological Safety Australia,Religion2020,2015,4
Psychology of Technology Institute,Cooperation2021,2015,1
Psychology of Technology Institute,Religion2020,2015,5
Public Health England,Religion2020,2015,8
Public Health Foundation of India,Religion2020,2015,2
Publishers Weekly,Religion2020,2015,7
Punjab Agricultural University,Religion2020,2015,1
Purdue University,Cooperation2021,2015,8
Purdue University,CooperationAndReligion,2015,1
Purdue University,Religion2020,2015,45
Purdue University Global,Religion2020,2015,2
Purdue University Northwest,Religion2020,2015,1
Pusan National University,Religion2020,2015,6
Qatar Computing Research Institute,Religion2020,2015,5
Qatar University,Religion2020,2015,12
Qazvin University of Medical Science,Religion2020,2015,14
QIMR Berhofer Medical Research Institute,Religion2020,2015,2
Queen Margaret University,Religion2020,2015,5
Queen Mary College,Religion2020,2015,2
Queen Mary University of London,Cooperation2021,2015,5
Queen Mary University of London,Religion2020,2015,16
Queen’s University Belfast,Religion2020,2015,4
"Queen's College, Birmingham",Religion2020,2015,5
"Queens College, City University of New York",Religion2020,2015,1
Queen's University,Cooperation2021,2015,2
Queen's University,Religion2020,2015,44
Queens University Belfast,Religion2020,2015,5
Queen's University Belfast,Religion2020,2015,43
Queen's University of Belfast,Religion2020,2015,2
Queensland Health,Religion2020,2015,7
Queensland University of Technology,Cooperation2021,2015,1
Queensland University of Technology,Religion2020,2015,12
Quinnipiac University,Religion2020,2015,4
Rabin Medical Center,Cooperation2021,2015,2
Rabin Medical Center,Religion2020,2015,2
Radboud University,Cooperation2021,2015,5
Radboud University,CooperationAndReligion,2015,4
Radboud University,Religion2020,2015,96
Radboud University Medical Centre,Religion2020,2015,17
Radboud University Nijmegen,Cooperation2021,2015,6
Radboud University Nijmegen,Religion2020,2015,38
Radford College,Religion2020,2015,1
Rafsanjan University of Medical Sciences,Religion2020,2015,2
Rambam Health Care Campus,Religion2020,2015,5
Rambam Hospital Haifa,Religion2020,2015,7
Ramon Llull University,Religion2020,2015,4
Rancho Sante Fe Senior Center,Religion2020,2015,3
RAND Corporation,Cooperation2021,2015,1
RAND Corporation,Religion2020,2015,26
Rare Disease Social Research Center,Religion2020,2015,4
Rationality Enhancement at Max Planck Institute,Cooperation2021,2015,1
Rationality Enhancement at Max Planck Institute,Religion2020,2015,1
Real Estate Investor,Religion2020,2015,1
Redeemer University College,Cooperation2021,2015,1
Redeemer University College,CooperationAndReligion,2015,1
Redeemer University College,Religion2020,2015,12
Reed College,Religion2020,2015,5
Reformed Theological Seminary,Religion2020,2015,18
Reformed University of Applied Sciences,Religion2020,2015,6
Refua Shlema Clinic,Cooperation2021,2015,1
Regent College,Religion2020,2015,21
Regent University,Religion2020,2015,46
Regina Apostolorum Pontifical University,Religion2020,2015,3
Rehabilitation Institute of Chicago,Religion2020,2015,3
Religion,Religion2020,2015,5
Religion and Science Course Program,Religion2020,2015,2
religion news service,Religion2020,2015,4
Religions of South Asia,Religion2020,2015,2
Religious Freedom & Business Foundation,Religion2020,2015,8
Renaissance Center for Higher Education,Religion2020,2015,1
Renmin University,Cooperation2021,2015,70
Renmin University,Religion2020,2015,10
Renmin University of China,Religion2020,2015,16
Rennes 1 University,Cooperation2021,2015,4
Rensselaer Polytechnic Institute,Religion2020,2015,1
Research Africa Network,Cooperation2021,2015,1
Research Africa Network,Religion2020,2015,14
"Research Centre of Regional Resources (PSDR), the Indonesian Institute of Sciences (LIPI)",Religion2020,2015,9
Research Collaborator and Public Health Practioner,Religion2020,2015,1
Research Institute for Theology and Religion,Religion2020,2015,6
Research Institute of Industrial Economics (IFN),Religion2020,2015,1
Rethink Priorities,Cooperation2021,2015,2
Rheinische Friedrich-Wilhelms-Universität Bonn,Religion2020,2015,9
Rhodes College,Religion2020,2015,12
Rhodes University,Cooperation2021,2015,9
Rhodes University,Religion2020,2015,16
Rice University,Cooperation2021,2015,24
Rice University,Religion2020,2015,60
Richard Stockton University,Cooperation2021,2015,1
Richard Stockton University,Religion2020,2015,3
Rider University,Religion2020,2015,3
Rigshospitalet,Religion2020,2015,5
RIKEN Center For Developmental Biology,Cooperation2021,2015,1
RINO Groep,Cooperation2021,2015,6
RINRI Institute,Cooperation2021,2015,10
Ripon College Cuddesdon,Religion2020,2015,9
Rissho University,Cooperation2021,2015,1
Ritsumeikan University,Cooperation2021,2015,1
Ritsumeikan University,Religion2020,2015,6
Ritual Studied Internatinoal,Religion2020,2015,2
RIVM,Religion2020,2015,2
Rizzoli Orthopedic Institute,Cooperation2021,2015,4
RMIT University,Religion2020,2015,6
Roanoke College,Religion2020,2015,5
Robert Morris University,Religion2020,2015,7
Robert Mundle Productons Inc.,Religion2020,2015,1
Roberts Wesleyan College,Religion2020,2015,4
Roche,Cooperation2021,2015,7
Rochester Institute of Technology,Religion2020,2015,1
Rockefeller University,Religion2020,2015,2
Rockhurst University,Religion2020,2015,9
Rocky Mountain Biological Laboratory,Cooperation2021,2015,2
Roehampton University,Religion2020,2015,11
Rollins College,Religion2020,2015,4
Roma Tre University,Religion2020,2015,7
Romanian-American University,Religion2020,2015,3
ronin institute,Religion2020,2015,3
Roosevelt University,Religion2020,2015,4
Roskilde University,Religion2020,2015,11
Roudebush VA Medical Center,Religion2020,2015,2
Rowan University,Religion2020,2015,13
Royal Academy of Fine Arts,Cooperation2021,2015,2
Royal Academy of Fine Arts,CooperationAndReligion,2015,1
Royal Academy of Fine Arts,Religion2020,2015,3
"Royal College of Physicians, London",Cooperation2021,2015,7
Royal College of Psychiatrists,Religion2020,2015,4
Royal Historical Society,Religion2020,2015,5
"Royal Holloway, University of London",Cooperation2021,2015,4
"Royal Holloway, University of London",Religion2020,2015,40
Royal Institute for Cultural Heritage,Religion2020,2015,1
Royal Roads University,Religion2020,2015,3
Royal University of Phnom Phen,Religion2020,2015,4
Royan Institute,Cooperation2021,2015,1
Roybal Institute on Aging,Religion2020,2015,2
RTI International,Religion2020,2015,7
RUDN University,Religion2020,2015,6
Rudolf Steiner University College,Religion2020,2015,1
Ruhr University Bochum,Cooperation2021,2015,1
Ruhr University Bochum,Religion2020,2015,71
Ruppin Academic Center,Religion2020,2015,4
Ruprecht-Karls-Universität Heidelberg,Religion2020,2015,1
Rush University,Cooperation2021,2015,6
Rush University,Religion2020,2015,75
Ruskin College Oxford,Religion2020,2015,2
Russian Academy of  Sciences,Religion2020,2015,7
Russian Academy of Educational Science,Religion2020,2015,2
Russian Academy of Medical Sciences,Religion2020,2015,2
Russian Academy of National Economy and Public Administration,Religion2020,2015,7
Russian Academy of Sciences,Religion2020,2015,6
Russian Armenian University,Religion2020,2015,2
Russian State University for the Humanities,Religion2020,2015,7
Rutgers School of Public Heath,Religion2020,2015,6
Rutgers University,Cooperation2021,2015,18
Rutgers University,CooperationAndReligion,2015,2
Rutgers University,Religion2020,2015,76
Rutherford University,Religion2020,2015,1
RWTH Aachen University,Cooperation2021,2015,2
RWTH Aachen University,Religion2020,2015,4
Ryerson University,Cooperation2021,2015,4
Ryerson University,Religion2020,2015,5
Sackler School of Medicine,Religion2020,2015,1
Saddleback College,Religion2020,2015,5
Saginaw Valley State University,Religion2020,2015,2
Sahand University of Technology,Religion2020,2015,4
Saint Anselm College,Religion2020,2015,5
Saint Cloud VA Health Care System,Religion2020,2015,4
Saint Elizabeth's Hospital,Religion2020,2015,1
Saint Joseph University,Cooperation2021,2015,8
Saint Joseph University,Religion2020,2015,1
Saint Joseph's Theological Institute,Religion2020,2015,3
Saint Joseph's Universty,Religion2020,2015,3
Saint Louis University,Religion2020,2015,56
Saint Mary's College,Religion2020,2015,17
Saint Mary's University,Cooperation2021,2015,2
Saint Mary's University,Religion2020,2015,27
Saint Meinrad Seminary and School of Theology,Religion2020,2015,10
Saint Michael's College,Religion2020,2015,2
Saint Paul University,Religion2020,2015,12
Saint Petersburg State University,Cooperation2021,2015,7
Saint Petersburg State University,Religion2020,2015,8
Salesian Pontifical University,Religion2020,2015,15
Salk Institute,Religion2020,2015,1
Sam Houston State University,Religion2020,2015,2
Samford University,Cooperation2021,2015,1
Samford University,CooperationAndReligion,2015,1
Samford University,Religion2020,2015,6
Sampoerna Capital,Religion2020,2015,7
Samsung Life Insurance,Cooperation2021,2015,1
Samsung Life Insurance,Religion2020,2015,2
San Diego State University,Cooperation2021,2015,6
San Diego State University,CooperationAndReligion,2015,1
San Diego State University,Religion2020,2015,38
San Francisco State University,Religion2020,2015,17
San Francisco Theological Seminary,Religion2020,2015,3
San Jose State University,Religion2020,2015,10
Sansone Family Center for Well-Being,Religion2020,2015,5
Santa Barbara Institute for Consciousness Studies,Religion2020,2015,6
Santa Clara University,Cooperation2021,2015,1
Santa Clara University,Religion2020,2015,61
Santa Fe Institute,Cooperation2021,2015,4
Santa Fe Institute,Religion2020,2015,1
São Paulo State Research Support Foundation,Religion2020,2015,5
Sao Paulo State University,Religion2020,2015,6
Sapienza University of Rome,Cooperation2021,2015,1
Sapienza University of Rome,Religion2020,2015,14
Sarum College,Religion2020,2015,19
saudi center for organ transplantation,Cooperation2021,2015,1
saudi center for organ transplantation,Religion2020,2015,1
Saw Swee Hock School of Public Health,Religion2020,2015,8
Saybrook Graduate School,Religion2020,2015,8
Saybrook University,Religion2020,2015,6
Scarritt College,Religion2020,2015,4
School of Mission and Theology at Stavanger,Religion2020,2015,8
School of Oriental and African Studies,Religion2020,2015,12
Schussental Clinic,Religion2020,2015,1
Sciences Po,Religion2020,2015,13
Scientific Association of Stress Management and Health Promotion,Religion2020,2015,3
Scripps College,Religion2020,2015,6
SDM College of Naturopathy and Yogic Sciences,Religion2020,2015,1
Search Institute,Religion2020,2015,1
Seattle Cancer Care Alliance,Religion2020,2015,5
Seattle Pacific University,Religion2020,2015,7
Seattle University,Religion2020,2015,18
Sebelas Maret University,Cooperation2021,2015,4
Sebelas Maret University,CooperationAndReligion,2015,2
Sebelas Maret University,Religion2020,2015,47
Second Military Medical University,Cooperation2021,2015,9
Second Military Medical University,Religion2020,2015,1
Seirei Mikatahara General Hospital,Religion2020,2015,7
Self-employed,Cooperation2021,2015,1
Self-employed,Religion2020,2015,7
Semmelweis University,Religion2020,2015,1
Semnan University of Medical Sciences,Religion2020,2015,4
Seoul National University,Cooperation2021,2015,6
Seoul National University,Religion2020,2015,12
Seoul National University College of Medicine,Religion2020,2015,11
Seoul National University Graduate School,Religion2020,2015,9
Seoul Theological University,Religion2020,2015,10
Serbian Academy of Sciences and Arts,Religion2020,2015,1
Seton Hall University,Religion2020,2015,12
Sewanee: The University of the South,Religion2020,2015,1
Shahed University,Religion2020,2015,3
Shahid Bahonar University of Kerman,Religion2020,2015,2
Shahid Beheshti University of Medical Science,Cooperation2021,2015,2
Shahid Beheshti University of Medical Science,Religion2020,2015,51
Shahid Sadoughi University of Medical Sciences and Health Services,Religion2020,2015,8
Shalem Institute for Spiritual Formation,Religion2020,2015,1
Shalom Hartman Institute,Religion2020,2015,5
Shandong University,Cooperation2021,2015,10
Shandong University,Religion2020,2015,3
Shanghai Clinical Research Center,Cooperation2021,2015,1
Shanghai Institutes for Biological Sciences,Cooperation2021,2015,2
Shanghai International Studies University,Cooperation2021,2015,8
Shanghai International Studies University,Religion2020,2015,4
Shanghai Jiao Tong University,Cooperation2021,2015,36
Shanghai Jiao Tong University,CooperationAndReligion,2015,1
Shanghai Jiao Tong University,Religion2020,2015,11
Shanghai Maritime University,Cooperation2021,2015,5
Shanghai Maritime University,Religion2020,2015,3
Shanghai Medical University,Cooperation2021,2015,2
Shanghai University,Cooperation2021,2015,12
Shanghai University,Religion2020,2015,5
Shaqra University,Religion2020,2015,5
Sheffield Teaching Hospitals NHS Foundation Trust,Religion2020,2015,5
"Shenyang Institute of Automation, Chinese Academy of Sciences",Cooperation2021,2015,1
Shenzhen University,Cooperation2021,2015,10
Shenzhen University,Religion2020,2015,2
Shepherd Center,Cooperation2021,2015,1
Shepherd Center,Religion2020,2015,14
Shimane University,Cooperation2021,2015,2
Shiraz University,Religion2020,2015,10
Shiraz University of Medical Sciences,Cooperation2021,2015,1
Shiraz University of Medical Sciences,Religion2020,2015,4
Shizuoka University,Cooperation2021,2015,18
Sichuan University,Cooperation2021,2015,13
Sichuan University,CooperationAndReligion,2015,1
Sichuan University,Religion2020,2015,11
SIKA,Cooperation2021,2015,2
SIKA,Religion2020,2015,5
Simon Fraser University,Cooperation2021,2015,16
Simon Fraser University,CooperationAndReligion,2015,1
Simon Fraser University,Religion2020,2015,18
Singapore Management University,Cooperation2021,2015,18
Singapore Management University,Religion2020,2015,17
Singapore National Cancer Center,Religion2020,2015,2
Singapore University of Social Sciences,Cooperation2021,2015,8
Skidmore College,Religion2020,2015,8
Slate,Religion2020,2015,8
Slovak Academy of Sciences,Religion2020,2015,33
Slovak Economic Association,Cooperation2021,2015,5
Smith College,Religion2020,2015,4
Smithsonian Tropical Research Institute,Cooperation2021,2015,8
SOAS University of London,Cooperation2021,2015,1
SOAS University of London,Religion2020,2015,23
Social Sciences and Humanities Research Council of Canada,Religion2020,2015,3
society for experimental social psychology,Cooperation2021,2015,2
society for experimental social psychology,CooperationAndReligion,2015,1
society for experimental social psychology,Religion2020,2015,14
Society of Australasian Social Psychologists,Cooperation2021,2015,3
Society of Australasian Social Psychologists,Religion2020,2015,4
Society of General Internal Medicine,Cooperation2021,2015,1
Society of General Internal Medicine,Religion2020,2015,4
Sofia University,Religion2020,2015,8
Sogang University,Cooperation2021,2015,7
Sogang University,Religion2020,2015,6
Soka University,Religion2020,2015,2
Sophia University,Religion2020,2015,9
Sør-Trøndelag University College,Religion2020,2015,3
Sør‐Trøndelag University College,Religion2020,2015,3
Sotiria General Hospital,Religion2020,2015,3
South Bay Latino Research Center- San Diego State University,Cooperation2021,2015,1
South Bay Latino Research Center- San Diego State University,Religion2020,2015,3
South China Agricultural University,Cooperation2021,2015,3
South China Agricultural University,Religion2020,2015,4
South Dakota State University,Religion2020,2015,4
"South Texas Veterans Health Care System,",Religion2020,2015,1
Southeast University,Religion2020,2015,8
Southeastern Louisiana University,Religion2020,2015,3
Southeastern Medical Oncology Center,Religion2020,2015,1
Southeastern University,Religion2020,2015,9
Southern Baptist Theological Seminary,Religion2020,2015,1
Southern California Edison,Cooperation2021,2015,5
Southern California University of Health Sciences,Religion2020,2015,4
Southern Cross University,Religion2020,2015,16
Southern Illinois University,Cooperation2021,2015,2
Southern Illinois University,Religion2020,2015,24
Southern Illinois University Edwardsville,Religion2020,2015,2
Southern Medical University,Cooperation2021,2015,1
Southern Medical University,Religion2020,2015,2
Southern Methodist University,Cooperation2021,2015,2
Southern Methodist University,Religion2020,2015,32
Southern Oregon Rehabilitation Center & Clinics,Religion2020,2015,3
Southern Oregon University,Religion2020,2015,3
Southern University of Science and Technology,Cooperation2021,2015,3
Southwest Forestry University,Cooperation2021,2015,9
Southwest Forestry University,Religion2020,2015,3
Southwest National Primate Center,Cooperation2021,2015,4
Southwest University,Cooperation2021,2015,4
Southwest University,Religion2020,2015,1
Southwestern College,Religion2020,2015,3
Spanish National Research Council,Cooperation2021,2015,6
Spanish National Research Council,Religion2020,2015,10
Spiritual Competency Academy,Religion2020,2015,6
Spring Hill College,Religion2020,2015,2
Sri Aurobindo Institute of Medical Sciences,Religion2020,2015,1
SS. Cyril and Methodius Byzantine Catholic Seminary,Religion2020,2015,6
SS. Cyril and Methodius University,Cooperation2021,2015,10
SSM Health,Religion2020,2015,2
St Andrews University,Cooperation2021,2015,1
St George & Sutherland Clinical School,Religion2020,2015,2
St Mary's Centre for Religion and Education,Religion2020,2015,4
St Mary's University,Religion2020,2015,9
St Peter’s Saltley Trust,Religion2020,2015,4
St Peter’s University,Religion2020,2015,1
St. Ambrose Universtiy,Religion2020,2015,1
St. Augustine College,Religion2020,2015,25
St. Augustine's College,Religion2020,2015,5
St. Bonaventure University,Religion2020,2015,1
St. Catharine's College,Religion2020,2015,2
St. Edmundsbury,Religion2020,2015,3
St. Edward's University,Religion2020,2015,4
St. Francis Xavier University,Religion2020,2015,4
St. George's University,Religion2020,2015,2
St. Hilda's College,Religion2020,2015,2
St. Jerome's University,Religion2020,2015,2
St. John of God Clinical Research Centre,Religion2020,2015,1
St. John Providence Health System,Religion2020,2015,19
St. John's College,Religion2020,2015,8
St. John's Medical College,Cooperation2021,2015,1
St. John's University,Religion2020,2015,4
St. Joseph’s College of Maine,Religion2020,2015,1
St. Louis University,Religion2020,2015,9
St. Luke's Canyon View Behavioral Health Clinic,Religion2020,2015,1
St. Marylebone Healing and Counseling Center,Religion2020,2015,1
St. Mary's Seminary and University,Cooperation2021,2015,1
St. Mary's Seminary and University,Religion2020,2015,5
St. Mary's University,Cooperation2021,2015,1
St. Mary's University,CooperationAndReligion,2015,1
St. Mary's University,Religion2020,2015,11
St. Mary's University College,Religion2020,2015,4
St. Olaf College,Cooperation2021,2015,1
St. Olaf College,CooperationAndReligion,2015,1
St. Olaf College,Religion2020,2015,23
St. Patrick's College,Religion2020,2015,2
St. Paul's Hospital,Religion2020,2015,7
St. Paul's institute of Philosophy,Religion2020,2015,6
St. Paul's United Church of Christ,Religion2020,2015,6
St. Peters College,Religion2020,2015,6
St. Petersburg State University,Cooperation2021,2015,9
St. Petersburg State University,Religion2020,2015,21
St. Philip's Centre,Religion2020,2015,7
St. Stephen’s & St. Agnes School (SSSAS),Religion2020,2015,5
St. Stephen's College,Religion2020,2015,7
St. Stephen's Episcopal Church,Religion2020,2015,1
"St. Thomas Hospital, London",Religion2020,2015,3
St. Thomas More College,Religion2020,2015,1
St. Thomas University,Religion2020,2015,2
St. Tikhon's Orthodox University,Religion2020,2015,6
St. Vincent College,Religion2020,2015,8
Staffordshire University,Cooperation2021,2015,1
Staffordshire University,Religion2020,2015,28
STAIN Gajah Putih Takengon,Cooperation2021,2015,1
STAIN Gajah Putih Takengon,Religion2020,2015,6
STAIN Purwokerto,Cooperation2021,2015,1
STAIN Purwokerto,Religion2020,2015,7
Stanford University,Cooperation2021,2015,31
Stanford University,Religion2020,2015,114
STAR H LLC,Religion2020,2015,2
State Higher Vocational School in Tarnow,Religion2020,2015,8
State Institute for Islamic Studies (STAIN) Salatiga,Religion2020,2015,4
State Islamic Institute of Bukittinggi,Religion2020,2015,17
State University of Campinas,Religion2020,2015,4
State University of New York - Nassau,Religion2020,2015,1
State University of New York at Albany,Religion2020,2015,12
State University of New York at Binghamton University,Cooperation2021,2015,3
State University of New York at Binghamton University,CooperationAndReligion,2015,1
State University of New York at Binghamton University,Religion2020,2015,8
State University of New York at Buffalo,Cooperation2021,2015,3
State University of New York at Buffalo,Religion2020,2015,11
State University of New York at Geneseo,Religion2020,2015,1
State University of New York at New Paltz,Religion2020,2015,3
State University of New York at Oswego,Cooperation2021,2015,4
State University of New York Downstate Medical Center,Religion2020,2015,2
"State University of New York, Old Westbury",Religion2020,2015,3
State University of Paraiba,Religion2020,2015,5
Stellenbosch University,Cooperation2021,2015,1
Stellenbosch University,Religion2020,2015,63
Steppsa Counselling and Psychotherapy,Religion2020,2015,3
Stetson University,Religion2020,2015,17
STKIP Widya Yuwana,Religion2020,2015,8
Stockholm Läns Landsting,Religion2020,2015,1
Stockholm Resilience Center,Cooperation2021,2015,4
Stockholm Resilience Center,Religion2020,2015,1
Stockholm School of Economics,Cooperation2021,2015,11
Stockholm School of Theology,Cooperation2021,2015,3
Stockholm School of Theology,CooperationAndReligion,2015,2
Stockholm School of Theology,Religion2020,2015,5
Stockholm University,Cooperation2021,2015,4
Stockholm University,CooperationAndReligion,2015,1
Stockholm University,Religion2020,2015,89
Stonehill College,Religion2020,2015,1
Stony Brook School of Medicine University of New York,Cooperation2021,2015,6
Stony Brook School of Medicine University of New York,Religion2020,2015,4
Stony Brook University,Cooperation2021,2015,14
Stony Brook University,CooperationAndReligion,2015,1
Stony Brook University,Religion2020,2015,25
Stranmillis University College,Religion2020,2015,3
Strayer College,Religion2020,2015,1
Strength Counselling Services,Religion2020,2015,2
StressWise,Religion2020,2015,1
Strong BRAIN Institute,Religion2020,2015,5
Studia Traditionis Theologiae,Religion2020,2015,2
Substance Abuse and Mental Health Services Administration,Religion2020,2015,4
Suffolk University,Religion2020,2015,1
Sultan Agung Islamic University,Cooperation2021,2015,1
Sultan Agung Islamic University,Religion2020,2015,7
Sun Yat-Sen University,Cooperation2021,2015,16
Sun Yat-Sen University,Religion2020,2015,6
Sunan Ampel State Islamic University Surabaya,Religion2020,2015,6
Sunan Kalijaga State Islamic University,Religion2020,2015,23
Sungkyunkwan University (SKKU),Cooperation2021,2015,1
Sungkyunkwan University (SKKU),Religion2020,2015,3
Sunnybrook Health Sciences Centre,Religion2020,2015,1
SUNY Buffalo,Religion2020,2015,7
SUNY Fredonia,Religion2020,2015,3
SUNY Oswego,Religion2020,2015,3
SUNY Upstate Medical University,Cooperation2021,2015,1
Svenska kyrkan,Religion2020,2015,7
SVYASA University,Cooperation2021,2015,1
SVYASA University,Religion2020,2015,29
Swami Vivekananda Yoga Anusandhana Samsthana,Religion2020,2015,10
Swansea University,Cooperation2021,2015,3
Swansea University,Religion2020,2015,9
Swarthmore College,Religion2020,2015,10
Swinburne University of Technology,Cooperation2021,2015,2
Swinburne University of Technology,Religion2020,2015,3
Swiss Federal Institute of Technology Zurich,Religion2020,2015,4
Swiss Institute for Experimental Cancer Research,Cooperation2021,2015,2
Syarif Hidayatullah State Islamic University,Religion2020,2015,14
sycamore primary care group,Religion2020,2015,1
Sydney College of Divinity,Religion2020,2015,7
Syiah Kuala University,Cooperation2021,2015,1
Syiah Kuala University,Religion2020,2015,16
Synamedia,Cooperation2021,2015,1
Syracuse University,Cooperation2021,2015,4
Syracuse University,CooperationAndReligion,2015,1
Syracuse University,Religion2020,2015,54
T&C Chen Center for Social and Decision Neuroscience,Cooperation2021,2015,2
Tabor College,Religion2020,2015,2
Tabriz University of Medical Sciences,Religion2020,2015,27
Taichung Veterans General Hospital,Religion2020,2015,3
Taipei Medical University,Religion2020,2015,2
Tajen University,Religion2020,2015,3
Tamagawa University,Cooperation2021,2015,11
Tamkang University,Religion2020,2015,1
Tampere University,Cooperation2021,2015,1
Tampere University,Religion2020,2015,12
Taras Shevchenko Kiev State University,Religion2020,2015,7
Taras Shevchenko National University of Kyiv,Religion2020,2015,2
Tarbiat Modares University,Cooperation2021,2015,4
Tarbiat Modares University,CooperationAndReligion,2015,1
Tarbiat Modares University,Religion2020,2015,22
Tarleton State University,Religion2020,2015,4
Tata Institute of Social Sciences,Cooperation2021,2015,1
Tata Institute of Social Sciences,Religion2020,2015,48
Taylor University,Religion2020,2015,1
Taylor's University,Religion2020,2015,10
Te Wānanga o Aotearoa,Religion2020,2015,12
Technical University of Berlin,Religion2020,2015,5
Technical University of Chemnitz,Religion2020,2015,10
Technical University of Dresden,Religion2020,2015,8
Technical University of Lisbon,Cooperation2021,2015,8
Technical University of Lisbon,Religion2020,2015,1
Technical University of Munich,Cooperation2021,2015,6
Technion Israel Institute of Technology,Cooperation2021,2015,2
Technion Israel Institute of Technology,Religion2020,2015,14
Technische Universität Darmstadt,Religion2020,2015,4
TED Translators,Religion2020,2015,2
Teesside University,Cooperation2021,2015,19
Teesside University,Religion2020,2015,2
Tehran University of Medical Sciences,Cooperation2021,2015,2
Tehran University of Medical Sciences,CooperationAndReligion,2015,1
Tehran University of Medical Sciences,Religion2020,2015,53
Teikyo University,Cooperation2021,2015,3
Tel Aviv Medical Center,Cooperation2021,2015,4
Tel Aviv Medical Center,Religion2020,2015,2
Tel Aviv University,Cooperation2021,2015,7
Tel Aviv University,Religion2020,2015,56
Tel Aviv University Medical School Tel Hashomer Hospital,Religion2020,2015,21
Tel Hai College,Religion2020,2015,5
Teleios Inc.,Religion2020,2015,1
Telenor Research,Cooperation2021,2015,1
Telenor Research,Religion2020,2015,1
tel-hai college,Cooperation2021,2015,1
Telkom University,Cooperation2021,2015,1
Telkom University,Religion2020,2015,5
Temple University,Religion2020,2015,17
Templeton Religion Trust,Religion2020,2015,2
Tennessee State University,Religion2020,2015,1
Terry Fox Research Institute,Cooperation2021,2015,2
Texas A&M University,Cooperation2021,2015,15
Texas A&M University,Religion2020,2015,52
Texas Christian University,Religion2020,2015,6
Texas State University,Cooperation2021,2015,1
Texas State University,Religion2020,2015,11
Texas Tech University,Cooperation2021,2015,1
Texas Tech University,Religion2020,2015,31
Texas Woman's University,Religion2020,2015,1
Thanyapura Mind Centre,Religion2020,2015,6
"The Academy of Korean Studies, Seongnam",Religion2020,2015,1
The Addis Clinic,Religion2020,2015,3
The American University in Cairo,Religion2020,2015,10
The Arctic University of Norway,Religion2020,2015,1
The Art of Living Foundation,Religion2020,2015,2
The Artic University of Norway,Cooperation2021,2015,1
The Artic University of Norway,CooperationAndReligion,2015,1
The Artic University of Norway,Religion2020,2015,10
The Broad Institute of Harvard and MIT,Cooperation2021,2015,1
The Catholic University of America,Cooperation2021,2015,1
The Catholic University of America,CooperationAndReligion,2015,1
The Catholic University of America,Religion2020,2015,9
the Center for Koru Mindfulness,Religion2020,2015,9
The Center for Mind Body Medicine,Religion2020,2015,2
The Chinese University of Hong Kong,Cooperation2021,2015,24
The Chinese University of Hong Kong,Religion2020,2015,2
The Chopra Foundation,Religion2020,2015,10
The College of Idaho,Religion2020,2015,1
The College of William and Mary,Cooperation2021,2015,3
The Donner Institute,Religion2020,2015,11
the Drug Research Center of the Sant Pau Hospital,Religion2020,2015,7
The Essential Medical Technology Foundation,Cooperation2021,2015,3
The Essential Medical Technology Foundation,Religion2020,2015,3
The European Graduate School,Religion2020,2015,3
The Family and Youth Institute,Religion2020,2015,1
The Hastings Center,Religion2020,2015,1
The Hebrew University,Cooperation2021,2015,1
The Hebrew University,Religion2020,2015,13
The Helsinki Academy of Philosophy,Religion2020,2015,3
The Hong Kong Polytechnic University,Cooperation2021,2015,1
The Hong Kong Polytechnic University,Religion2020,2015,3
The Human Sentience Project,Cooperation2021,2015,1
The Human Sentience Project,CooperationAndReligion,2015,1
The Human Sentience Project,Religion2020,2015,11
The Jane Goodall Institute,Cooperation2021,2015,1
The Key Institute for Brain-Mind Research,Religion2020,2015,3
"The Kinneret Center on Peace, Securityand Society",Cooperation2021,2015,1
The Lancet,Cooperation2021,2015,6
The Lancet,Religion2020,2015,5
The Lighthouse Institute,Religion2020,2015,3
The Middle East Technical University,Cooperation2021,2015,1
The Middle East Technical University,Religion2020,2015,2
The National Academy of Sciences of Ukraine,Religion2020,2015,28
The New School,Religion2020,2015,6
The Open University,Cooperation2021,2015,1
The Open University,Religion2020,2015,14
The People's Hospital of Huangmei,Cooperation2021,2015,2
The People's Hospital of Huangmei,Religion2020,2015,3
The Queens Foundation,Religion2020,2015,9
The Rahmah Foundation,Religion2020,2015,5
The Religious Studies Project,Religion2020,2015,5
The Research Institute of Industrial Economics,Religion2020,2015,3
The Royal Children's Hospital,Religion2020,2015,1
The Salk Institute for Biological Studies,Cooperation2021,2015,10
The Salk Institute for Biological Studies,Religion2020,2015,3
The Science Council of Japan,Cooperation2021,2015,4
the Sun Jar Coaching,Religion2020,2015,5
The Tiltenburg,Religion2020,2015,1
The Underground University,Religion2020,2015,4
the University of Denver,Religion2020,2015,4
The University of Hong Kong,Cooperation2021,2015,1
The University of Hong Kong,Religion2020,2015,9
The University of Massachusetts Boston,Religion2020,2015,3
The University of New South Wales-The Kirby Institute,Religion2020,2015,3
The University of Queensland,Religion2020,2015,15
The University of Rochester School of Medicine,Religion2020,2015,4
The University of Texas at Dallas,Religion2020,2015,5
The University of Texas at Houston,Religion2020,2015,5
The University of Texas at San Antonio,Cooperation2021,2015,1
The University of Texas at San Antonio,Religion2020,2015,6
The University of Trans Disciplinary Health Sciences & Technology,Religion2020,2015,1
The University of Western Australia,Cooperation2021,2015,6
The University of Western Australia,Religion2020,2015,2
The University of Western Ontario,Religion2020,2015,5
The Van Leer Jerusalem Institute,Religion2020,2015,1
The Yoga Foundation,Religion2020,2015,1
Theological College of Zimbabwe,Religion2020,2015,8
Theopolis Institute,Religion2020,2015,1
Thomas Jefferson University,Religion2020,2015,5
Thomas More College of Liberal Arts,Religion2020,2015,10
Thorneloe University,Religion2020,2015,2
Thrive Therapeutic Software,Cooperation2021,2015,1
Thrive Therapeutic Software,Religion2020,2015,3
Tiangong University,Cooperation2021,2015,5
Tianjin University,Cooperation2021,2015,56
Tianjin University of Finance and Economics,Cooperation2021,2015,7
Tianjin University of Traditional Chinese Medicine,Religion2020,2015,2
Tidewater Child and Family Behavioral Health,Religion2020,2015,5
Tilburg University,Cooperation2021,2015,23
Tilburg University,Religion2020,2015,36
Timber Creek Counseling,Religion2020,2015,6
Tinbergen Institute,Cooperation2021,2015,1
Titus Brandsma Institute,Religion2020,2015,1
T-Mobile,Religion2020,2015,2
Tohoku University,Cooperation2021,2015,6
Tohoku University,Religion2020,2015,6
Tokai University,Cooperation2021,2015,3
Tokyo Institute of Technology,Cooperation2021,2015,12
Tokyo Medical and Dental University,Religion2020,2015,2
Tokyo University,Religion2020,2015,1
Tokyo Woman's Christian University,Cooperation2021,2015,1
Tokyo Woman's Christian University,Religion2020,2015,2
Tokyo Women's Medical University,Religion2020,2015,1
Tom Baker Cancer Center,Religion2020,2015,3
Tomas Bata University of Zlin,Religion2020,2015,15
Tongji University,Cooperation2021,2015,8
Tongji University,Religion2020,2015,12
Tor Vergata University of Rome,Cooperation2021,2015,14
Tor Vergata University of Rome,Religion2020,2015,5
Torrens University,Religion2020,2015,2
Touro College,Religion2020,2015,16
Toyohashi University of Technology,Cooperation2021,2015,1
Tree of Life Tai Chi Center,Religion2020,2015,1
Trilateral Research Ltd,Religion2020,2015,1
Trinity College,Cooperation2021,2015,2
Trinity College,Religion2020,2015,30
Trinity College Dublin,Cooperation2021,2015,5
Trinity College Dublin,CooperationAndReligion,2015,2
Trinity College Dublin,Religion2020,2015,64
Trinity College of Arts & Sciences,Religion2020,2015,9
"Trinity College, Cambridge",Religion2020,2015,1
Trinity Evangelical Divinity School,Religion2020,2015,17
Trinity International University,Religion2020,2015,3
Trinity Lutheran Church,Religion2020,2015,3
Trinity Lutheran Seminary,Religion2020,2015,17
Trinity Theological College,Religion2020,2015,4
Trinity Western University,Religion2020,2015,35
Tripler Army Medical Center,Religion2020,2015,3
Trnavska Univerzita,Religion2020,2015,2
Troy University,Cooperation2021,2015,1
Truman State University,Religion2020,2015,5
Tshwane Leadership Foundation,Religion2020,2015,8
Tsinghua University,Cooperation2021,2015,21
Tsinghua University,Religion2020,2015,2
Tsukuba Medical Center Hospital,Religion2020,2015,3
Tucker Counseling and Consulting,Religion2020,2015,2
Tufts University,Cooperation2021,2015,1
Tufts University,CooperationAndReligion,2015,1
Tufts University,Religion2020,2015,18
Tufts University School of Medicine,Religion2020,2015,3
Tulane University,Cooperation2021,2015,3
Tulane University,Religion2020,2015,16
TurkPrime.com,Religion2020,2015,3
Tyndale University,Religion2020,2015,1
Tzu Chi Hospital,Religion2020,2015,6
U.S. Army Reserves,Religion2020,2015,1
U.S. Department of Health and Human Services,Religion2020,2015,8
U.S. Department of Veterans Affairs,Religion2020,2015,2
U.S. Public Health Commissioned Corps of the (Lower 2015; Upper 2020),Religion2020,2015,1
UC San Diego,Religion2020,2015,10
UCHealth,Cooperation2021,2015,1
UCHealth,Religion2020,2015,4
UCSF Division of Prevention Science,Religion2020,2015,5
Udayana University,Religion2020,2015,1
UIN Antasari,Religion2020,2015,13
UIN Malang,Religion2020,2015,6
UIN Mataram,Religion2020,2015,6
UIN Sunan Ampel,Religion2020,2015,4
UIN Sunan Kalijaga Yogyakarta,Cooperation2021,2015,4
UIN Sunan Kalijaga Yogyakarta,CooperationAndReligion,2015,1
UIN Sunan Kalijaga Yogyakarta,Religion2020,2015,24
UIN Syarif Hidayatullah,Religion2020,2015,6
UiT The Arctic University of Norway,Religion2020,2015,5
Ukrainian Association of Ressearchers of Religion,Religion2020,2015,7
Ulster University,Religion2020,2015,14
UMAE Hospital de Especialidade,Cooperation2021,2015,10
UMAE Hospital de Especialidade,CooperationAndReligion,2015,9
UMAE Hospital de Especialidade,Religion2020,2015,10
Umeå University,Cooperation2021,2015,8
Umeå University,Religion2020,2015,5
Umm Al-Qura University,Religion2020,2015,8
UNESCO,Cooperation2021,2015,6
UNESCO,Religion2020,2015,4
Uniformed Services University of the Health Sciences,Religion2020,2015,3
Unilever,Religion2020,2015,2
Uninettuno international telematic university,Cooperation2021,2015,1
Union Christian College,Religion2020,2015,15
Union College,Religion2020,2015,6
Union Presbyterian Seminary,Religion2020,2015,12
Union Theological Seminary,Religion2020,2015,32
Union University,Religion2020,2015,2
United Arab Emirates University,Religion2020,2015,2
United Health Care Inc.,Religion2020,2015,2
United Nations,Religion2020,2015,9
United Nations University - International Institute of Global Health,Religion2020,2015,3
United States Army,Religion2020,2015,11
United States Army Research Laboratory,Religion2020,2015,1
United States Naval Academy,Religion2020,2015,3
United Theological College,Religion2020,2015,1
United Theological Seminary of the Twin Cities,Religion2020,2015,2
Universidad Arturo Prat,Religion2020,2015,5
Universidad Autónoma de Barcelona,Religion2020,2015,4
Universidad Carlos III de Madrid,Cooperation2021,2015,3
Universidad Carlos III de Madrid,CooperationAndReligion,2015,1
Universidad Carlos III de Madrid,Religion2020,2015,1
Universidad Catolica de Murcia,Cooperation2021,2015,22
Universidad Catolica de Murcia,CooperationAndReligion,2015,21
Universidad Catolica de Murcia,Religion2020,2015,22
Universidad Católica San Antonio de Murcia,Cooperation2021,2015,10
Universidad Católica San Antonio de Murcia,CooperationAndReligion,2015,9
Universidad Católica San Antonio de Murcia,Religion2020,2015,9
Universidad Complutense de Madrid,Cooperation2021,2015,5
Universidad Complutense de Madrid,Religion2020,2015,4
Universidad de Alcalá,Cooperation2021,2015,5
Universidad de Alcalá,Religion2020,2015,2
Universidad de Almeria,Religion2020,2015,2
Universidad de Buenos Aires,Religion2020,2015,7
Universidad de Castilla-La Mancha-CSIC,Cooperation2021,2015,4
Universidad de Chile,Cooperation2021,2015,7
Universidad de Chile,Religion2020,2015,3
Universidad de Costa Rica,Cooperation2021,2015,3
Universidad de Sao Paulo,Religion2020,2015,1
Universidad de Sevilla,Cooperation2021,2015,7
Universidad del Pais Vasco,Cooperation2021,2015,1
Universidad del Pais Vasco,Religion2020,2015,4
Universidad Nacional Autonoma de Mexico,Cooperation2021,2015,2
Universidad Nacional de Educacion a Distancia,Cooperation2021,2015,3
Universidad Nacional de Educacion a Distancia,Religion2020,2015,4
Universidad Nacional de La Pampa,Religion2020,2015,4
Universidad Nacional de Rosario  Universidad Nacional de Rosario  Universidad Nacional de Rosario,Religion2020,2015,10
Universidad Politécnica de Madrid,Cooperation2021,2015,4
Universidade de Lisboa,Cooperation2021,2015,5
Universidade de Lisboa,Religion2020,2015,15
Universidade do Porto,Religion2020,2015,2
Universidade Federal de Juiz de Fora,Religion2020,2015,7
Universidade Federal de Pernambuco,Cooperation2021,2015,1
Universidade Federal de Pernambuco,Religion2020,2015,8
Universidade Federal de San Carlos   São Carlos,Religion2020,2015,4
Universidade Federal de São Paulo,Cooperation2021,2015,1
Universidade Federal de São Paulo,Religion2020,2015,6
Universidade Federal do Ceará,Religion2020,2015,1
Universidade Federal do Rio Grande do Sul,Religion2020,2015,5
Universidade NOVA de Lisboa,Religion2020,2015,4
Universita Bocconi,Cooperation2021,2015,1
Universita degli Studi di Foggia,Religion2020,2015,5
Università degli Studi di Milano,Cooperation2021,2015,8
Università degli Studi di Milano,Religion2020,2015,4
Università della Svizzera italiana,Cooperation2021,2015,1
Università della Svizzera italiana,Religion2020,2015,1
Università di Bari,Religion2020,2015,4
Universita di Catania,Cooperation2021,2015,3
Università di Padova,Religion2020,2015,7
Universita LUM Jean Monnet,Religion2020,2015,5
Universitas Batam,Cooperation2021,2015,2
Universitas Batam,CooperationAndReligion,2015,1
Universitas Batam,Religion2020,2015,5
Universitas Gadjah Mada,Religion2020,2015,18
Universitas Ibn Khaldun Bogor,Cooperation2021,2015,1
Universitas Ibn Khaldun Bogor,CooperationAndReligion,2015,1
Universitas Ibn Khaldun Bogor,Religion2020,2015,5
Universitas Islam Negeri Alauddin,Religion2020,2015,6
Universitas Islam Negeri Ar-Raniry,Cooperation2021,2015,1
Universitas Islam Negeri Ar-Raniry,Religion2020,2015,10
Universitas Islam Negeri Maulana Malik Ibrahim Malang,Cooperation2021,2015,1
Universitas Islam Negeri Maulana Malik Ibrahim Malang,CooperationAndReligion,2015,1
Universitas Islam Negeri Maulana Malik Ibrahim Malang,Religion2020,2015,11
Universitas Islam Negeri Sultan Maulana Hasanuddin Banten,Religion2020,2015,2
Universitas Islam Negeri Sunan Ampel,Cooperation2021,2015,1
Universitas Islam Negeri Sunan Ampel,CooperationAndReligion,2015,1
Universitas Islam Negeri Sunan Ampel,Religion2020,2015,15
Universitas Islam Negeri Sunan Kalijaga,Religion2020,2015,12
Universitas Islam Negeri Sunan Kalijaga Yogyakarta,Religion2020,2015,6
universitas muhammadiyah yogyakarta,Religion2020,2015,4
Universitas Negeri Jakarta,Religion2020,2015,8
Universitas Negeri Manado,Religion2020,2015,7
Universitas Padjadjaran,Religion2020,2015,6
Universitas Pendidikan Indonesia,Religion2020,2015,10
Universitas Sebelas Maret,Religion2020,2015,7
Universitas Sultan Ageng Tirtayasa,Cooperation2021,2015,1
Universitas Sultan Ageng Tirtayasa,CooperationAndReligion,2015,1
Universitas Sultan Ageng Tirtayasa,Religion2020,2015,10
Universitas Yarsi,Religion2020,2015,4
Universitat Autonoma de Barcelona,Religion2020,2015,9
Universitat Autònoma de Barcelona,Religion2020,2015,2
Universitat de Barcelona,Religion2020,2015,5
Universitat Gottingngen,Religion2020,2015,3
Universitat Jaume I,Religion2020,2015,6
Universitat Oberta de Catalunya,Religion2020,2015,4
Universitat Pompeu Fabra,Religion2020,2015,4
Universität Regensburg,Religion2020,2015,1
Universitat Wurzburg,Religion2020,2015,4
Universitatea Valahia,Religion2020,2015,3
Université Catholique de Louvain,Religion2020,2015,24
Université de Caen Normandie,Religion2020,2015,5
Université de Fribourg,Religion2020,2015,9
Universite de Lorraine,Religion2020,2015,2
Université de Paris,Religion2020,2015,8
Université de Rennes 1,Cooperation2021,2015,1
Universite de Versailles,Religion2020,2015,8
Université du Québec à Montréal,Religion2020,2015,1
Université du Québec en Outaouais,Religion2020,2015,8
Université Libre de Bruxelles,Cooperation2021,2015,11
Université Libre in Brussels,Cooperation2021,2015,4
Université Libre in Brussels,Religion2020,2015,8
Université Paris I,Cooperation2021,2015,4
Université Saint-Louis,Cooperation2021,2015,1
Universitetet Agder,Religion2020,2015,11
Universiti Brunei Darussalam,Religion2020,2015,6
Universiti Kebangsaan Malaysia,Cooperation2021,2015,2
Universiti Kebangsaan Malaysia,CooperationAndReligion,2015,2
Universiti Kebangsaan Malaysia,Religion2020,2015,20
Universiti Putra Malaysia,Religion2020,2015,15
Universiti Sains Islam Malaysia,Cooperation2021,2015,2
Universiti Sains Islam Malaysia,Religion2020,2015,3
Universiti Sains Malaysia,Cooperation2021,2015,1
Universiti Sains Malaysia,Religion2020,2015,36
Universiti Teknologi MARA,Cooperation2021,2015,1
Universiti Teknologi MARA,Religion2020,2015,23
University “G. d’Annunzio” of Chieti-Pescara,Religion2020,2015,2
"University at Albany, The State University of New York",Religion2020,2015,3
University at Buffalo,Cooperation2021,2015,1
University at Buffalo,CooperationAndReligion,2015,1
University at Buffalo,Religion2020,2015,17
University Clinical Hospital of Mostar,Religion2020,2015,2
University College Cork,Cooperation2021,2015,2
University College Cork,Religion2020,2015,29
University College Dublin,Cooperation2021,2015,3
University College Dublin,Religion2020,2015,9
University College London,Cooperation2021,2015,93
University College London,CooperationAndReligion,2015,5
University College London,Religion2020,2015,163
University College Utrecht,Religion2020,2015,1
University Duisburg-Essen,Religion2020,2015,6
University François Rabelais Tours,Religion2020,2015,5
University Hassan II Casablanca,Cooperation2021,2015,6
University Health Network,Religion2020,2015,1
University Hospital Cologne,Religion2020,2015,2
University Hospital Lewisham,Religion2020,2015,4
University Hospital of Odense,Cooperation2021,2015,6
University Hospital Regensburg,Cooperation2021,2015,1
University Hospital Regensburg,CooperationAndReligion,2015,1
University Hospital Regensburg,Religion2020,2015,4
University Hospitals Birmingham NHS Foundation Trust,Religion2020,2015,2
University Hospitals Connor,Religion2020,2015,1
University Institute of Psychological Social and Life Science,Religion2020,2015,7
University Leuven,Religion2020,2015,4
University Medical Center Gottingen,Religion2020,2015,2
University Medical Center Utrecht,Religion2020,2015,3
University Milan Bicocca,Religion2020,2015,6
University of Aarhus,Cooperation2021,2015,1
University of Aarhus,CooperationAndReligion,2015,1
University of Aarhus,Religion2020,2015,32
University of Aberdeen,Cooperation2021,2015,4
University of Aberdeen,CooperationAndReligion,2015,1
University of Aberdeen,Religion2020,2015,95
University of Adelaide,Cooperation2021,2015,1
University of Adelaide,CooperationAndReligion,2015,1
University of Adelaide,Religion2020,2015,17
University of Agder,Cooperation2021,2015,1
University of Agder,CooperationAndReligion,2015,1
University of Agder,Religion2020,2015,20
University of Akron,Religion2020,2015,4
University of Alabama,Cooperation2021,2015,6
University of Alabama,CooperationAndReligion,2015,1
University of Alabama,Religion2020,2015,23
University of Alabama at Birmingham,Cooperation2021,2015,1
University of Alabama at Birmingham,Religion2020,2015,37
University of Alabama in Huntsville,Religion2020,2015,22
University of Alaska,Religion2020,2015,2
University of Alaska Anchorage,Cooperation2021,2015,1
University of Alaska Anchorage,Religion2020,2015,1
University of Albany,Cooperation2021,2015,4
University of Albany,Religion2020,2015,1
University of Alberta,Cooperation2021,2015,8
University of Alberta,CooperationAndReligion,2015,1
University of Alberta,Religion2020,2015,74
University of Alicante,Cooperation2021,2015,4
University of Alicante,Religion2020,2015,1
University of Amsterdam,Cooperation2021,2015,52
University of Amsterdam,CooperationAndReligion,2015,1
University of Amsterdam,Religion2020,2015,100
University of Antwerp,Cooperation2021,2015,8
University of Antwerp,Religion2020,2015,13
University of Applied Sciences Coburg,Religion2020,2015,6
University of Applied Sciences of the Grisons,Cooperation2021,2015,4
University of Arizona,Cooperation2021,2015,25
University of Arizona,CooperationAndReligion,2015,5
University of Arizona,Religion2020,2015,78
University of Arizona College of Medicine,Religion2020,2015,9
University of Arkansas,Cooperation2021,2015,6
University of Arkansas,Religion2020,2015,17
University of Arkansas for Medical Science,Religion2020,2015,13
University of Athens,Religion2020,2015,11
University of Auckland,Cooperation2021,2015,15
University of Auckland,CooperationAndReligion,2015,9
University of Auckland,Religion2020,2015,89
University of Ballarat,Religion2020,2015,6
University of Bamberg,Cooperation2021,2015,2
University of Bamberg,Religion2020,2015,10
University of Barcelona,Cooperation2021,2015,7
University of Barcelona,Religion2020,2015,3
University of Basel,Cooperation2021,2015,8
University of Basel,Religion2020,2015,31
University of Basque Country,Cooperation2021,2015,1
University of Basque Country,Religion2020,2015,2
University of Bath,Cooperation2021,2015,6
University of Bath,Religion2020,2015,18
University of Bayreuth,Religion2020,2015,19
University of Bedfordshire,Cooperation2021,2015,1
University of Bedfordshire,CooperationAndReligion,2015,1
University of Bedfordshire,Religion2020,2015,4
University of Belgrade,Cooperation2021,2015,2
University of Belgrade,Religion2020,2015,7
University of Bergamo,Cooperation2021,2015,4
University of Bergamo,Religion2020,2015,11
University of Bergen,Cooperation2021,2015,3
University of Bergen,Religion2020,2015,41
University of Bern,Cooperation2021,2015,10
University of Bern,Religion2020,2015,25
University of Bielefeld,Cooperation2021,2015,1
University of Bielefeld,CooperationAndReligion,2015,1
University of Bielefeld,Religion2020,2015,46
University of Birmingham,Cooperation2021,2015,13
University of Birmingham,Religion2020,2015,137
University of Bocconi,Cooperation2021,2015,2
University of Bocconi,Religion2020,2015,1
University of Bochum,Religion2020,2015,1
University of Bologna,Cooperation2021,2015,6
University of Bologna,Religion2020,2015,1
University of Bolton,Religion2020,2015,4
University of Bonn,Cooperation2021,2015,31
University of Bonn,Religion2020,2015,39
University of Botswana,Cooperation2021,2015,1
University of Botswana,Religion2020,2015,16
University of Bradford,Religion2020,2015,6
University of Brasilia,Cooperation2021,2015,2
University of Brawijaya,Religion2020,2015,4
University of Bremen,Cooperation2021,2015,8
University of Bremen,Religion2020,2015,8
University of Bridgeport,Religion2020,2015,2
University of Brighton,Religion2020,2015,14
University of Bristol,Cooperation2021,2015,24
University of Bristol,Religion2020,2015,93
University of British Columbia,Cooperation2021,2015,40
University of British Columbia,CooperationAndReligion,2015,13
University of British Columbia,Religion2020,2015,105
University of Brunei Darussalam,Religion2020,2015,2
University of Buckingham,Religion2020,2015,3
University of Buenos Aires,Religion2020,2015,22
University of Buffalo,Cooperation2021,2015,10
University of Buffalo,Religion2020,2015,2
University of Burdwan,Religion2020,2015,2
University of Cagliari,Cooperation2021,2015,10
University of Calcutta,Religion2020,2015,2
University of Calgary,Cooperation2021,2015,2
University of Calgary,Religion2020,2015,67
University of California,Cooperation2021,2015,3
University of California,CooperationAndReligion,2015,3
University of California,Religion2020,2015,6
University of California - Berkeley,Cooperation2021,2015,10
University of California - Berkeley,Religion2020,2015,38
University of California - Davis,Cooperation2021,2015,4
University of California - Davis,Religion2020,2015,15
University of California - Irvine,Cooperation2021,2015,3
University of California - Irvine,Religion2020,2015,38
University of California - Los Angeles,Cooperation2021,2015,16
University of California - Los Angeles,Religion2020,2015,84
University of California - Riverside,Cooperation2021,2015,8
University of California - Riverside,Religion2020,2015,13
University of California - San Diego,Cooperation2021,2015,5
University of California - San Diego,CooperationAndReligion,2015,1
University of California - San Diego,Religion2020,2015,45
University of California - San Francisco,Cooperation2021,2015,18
University of California - San Francisco,Religion2020,2015,52
University of California - Santa Barbara,Cooperation2021,2015,5
University of California - Santa Barbara,Religion2020,2015,17
University of California- Santa Barbara,Religion2020,2015,3
"University of California, Berkeley",Cooperation2021,2015,8
"University of California, Berkeley",CooperationAndReligion,2015,1
"University of California, Berkeley",Religion2020,2015,85
"University of California, Davis",Cooperation2021,2015,10
"University of California, Davis",Religion2020,2015,32
"University of California, Irvine",Cooperation2021,2015,2
"University of California, Irvine",Religion2020,2015,27
"University of California, Los Angeles",Cooperation2021,2015,22
"University of California, Los Angeles",Religion2020,2015,35
"University of California, Riverside",Cooperation2021,2015,15
"University of California, Riverside",Religion2020,2015,22
"University of California, San Diego",Cooperation2021,2015,6
"University of California, San Diego",CooperationAndReligion,2015,1
"University of California, San Diego",Religion2020,2015,56
"University of California, San Francisco",Cooperation2021,2015,2
"University of California, San Francisco",Religion2020,2015,14
"University of California, Santa Barbara",Cooperation2021,2015,29
"University of California, Santa Barbara",CooperationAndReligion,2015,2
"University of California, Santa Barbara",Religion2020,2015,30
"University of California, Santa Cruz",Religion2020,2015,1
University of Cambridge,Cooperation2021,2015,50
University of Cambridge,CooperationAndReligion,2015,3
University of Cambridge,Religion2020,2015,237
University of Canberra,Religion2020,2015,2
University of Canterbury,Religion2020,2015,7
University of Cape Town,Cooperation2021,2015,7
University of Cape Town,Religion2020,2015,55
University of Catania,Cooperation2021,2015,5
University of Catania,Religion2020,2015,2
University of Central Florida,Cooperation2021,2015,1
University of Central Florida,Religion2020,2015,35
University of Central Lancashire,Religion2020,2015,4
University of Chester,Religion2020,2015,60
University of Chicago,Cooperation2021,2015,14
University of Chicago,CooperationAndReligion,2015,1
University of Chicago,Religion2020,2015,136
University of Chicago Divinity School,Religion2020,2015,9
"University of Chicago, Brain and Language Paper",Religion2020,2015,2
University of Chichester,Religion2020,2015,9
University of Chieti-Pescara,Religion2020,2015,1
University of Cincinnati,Religion2020,2015,23
University of Coimbra,Religion2020,2015,5
University of Cologne,Cooperation2021,2015,18
University of Cologne,Religion2020,2015,11
University of Colorado Anschutz Medical Campus,Cooperation2021,2015,1
University of Colorado Anschutz Medical Campus,Religion2020,2015,5
University of Colorado at Boulder,Cooperation2021,2015,5
University of Colorado at Boulder,CooperationAndReligion,2015,1
University of Colorado at Boulder,Religion2020,2015,14
University of Colorado at Colorado Springs,Religion2020,2015,20
University of Colorado Boulder,Religion2020,2015,7
University of Colorado Denver,Religion2020,2015,10
"University of Colorado, Boulder",Cooperation2021,2015,2
"University of Colorado, Boulder",Religion2020,2015,6
University of Concepción,Religion2020,2015,8
University of Concordia,Religion2020,2015,1
University of Connecticut,Cooperation2021,2015,12
University of Connecticut,CooperationAndReligion,2015,7
University of Connecticut,Religion2020,2015,97
University of Constance,Religion2020,2015,3
University of Copenhagen,Cooperation2021,2015,29
University of Copenhagen,Religion2020,2015,78
University of Crete,Cooperation2021,2015,4
University of Crete,CooperationAndReligion,2015,1
University of Crete,Religion2020,2015,7
University of Cumbria,Cooperation2021,2015,1
University of Cumbria,Religion2020,2015,3
University of Cyprus,Religion2020,2015,9
University of Dallas,Religion2020,2015,3
University of Darussalam Gontor,Religion2020,2015,6
University of Dayton,Religion2020,2015,17
University of Delaware,Cooperation2021,2015,2
University of Delaware,Religion2020,2015,13
University of Denver,Cooperation2021,2015,8
University of Denver,Religion2020,2015,8
University of Derby,Cooperation2021,2015,2
University of Derby,Religion2020,2015,23
University of Detroit Mercy,Religion2020,2015,2
University of Divinity,Religion2020,2015,2
University of Dubuque Theological Seminary,Religion2020,2015,8
University of Duisburg-Essen,Cooperation2021,2015,1
University of Duisburg-Essen,Religion2020,2015,13
University of Dundee,Cooperation2021,2015,4
University of Dundee,Religion2020,2015,5
University of Durham,Religion2020,2015,18
University of Düsseldorf,Religion2020,2015,5
University of East Anglia,Cooperation2021,2015,9
University of East Anglia,Religion2020,2015,17
University of East London,Cooperation2021,2015,2
University of East London,Religion2020,2015,21
University of Eastern Finland,Religion2020,2015,13
University of Eastern Piedmont,Religion2020,2015,5
University of Edinburgh,Cooperation2021,2015,28
University of Edinburgh,Religion2020,2015,190
University of Edinburgh - Napier,Religion2020,2015,3
University of Electronic Science and Technology of China,Cooperation2021,2015,12
University of Electronic Science and Technology of China,CooperationAndReligion,2015,1
University of Electronic Science and Technology of China,Religion2020,2015,1
University of Erfurt,Cooperation2021,2015,1
University of Erfurt,Religion2020,2015,11
University of Erlangen,Cooperation2021,2015,1
University of Erlangen,CooperationAndReligion,2015,1
University of Erlangen,Religion2020,2015,4
University of Erlangen-Nuremberg,Religion2020,2015,4
University of Essex,Cooperation2021,2015,5
University of Essex,Religion2020,2015,8
University of Evry-Val d'Essonne,Cooperation2021,2015,1
University of Evry-Val d'Essonne,Religion2020,2015,2
University of Exeter,Cooperation2021,2015,21
University of Exeter,CooperationAndReligion,2015,4
University of Exeter,Religion2020,2015,167
University of Ferrara,Religion2020,2015,9
University of Florence,Cooperation2021,2015,6
University of Florida,Cooperation2021,2015,6
University of Florida,Religion2020,2015,58
University of Foggia,Cooperation2021,2015,1
University of Frankfurt,Religion2020,2015,3
University of Freiburg,Cooperation2021,2015,1
University of Freiburg,Religion2020,2015,44
University of Fribourg,Cooperation2021,2015,2
University of Fribourg,Religion2020,2015,6
University of Galve,Cooperation2021,2015,1
University of Galve,CooperationAndReligion,2015,1
University of Galve,Religion2020,2015,11
University of Gastronomic Sciences,Religion2020,2015,10
University of Gävle,Cooperation2021,2015,1
University of Gävle,Religion2020,2015,7
University of Gdańsk,Cooperation2021,2015,1
University of Gdańsk,CooperationAndReligion,2015,1
University of Gdańsk,Religion2020,2015,4
University of Geneva,Cooperation2021,2015,5
University of Geneva,Religion2020,2015,32
University of Genoa,Religion2020,2015,3
University of Georgia,Cooperation2021,2015,4
University of Georgia,Religion2020,2015,44
University of Ghana,Religion2020,2015,17
University of Girona,Religion2020,2015,6
University of Glasgow,Cooperation2021,2015,5
University of Glasgow,Religion2020,2015,117
University of Gloucestershire,Religion2020,2015,13
University of Glyndwr,Cooperation2021,2015,1
University of Glyndwr,Religion2020,2015,6
University of Gothenburg,Cooperation2021,2015,22
University of Gothenburg,CooperationAndReligion,2015,5
University of Gothenburg,Religion2020,2015,42
University of Gottingen,Religion2020,2015,7
University of Göttingen,Cooperation2021,2015,39
University of Göttingen,Religion2020,2015,88
University of Granada,Cooperation2021,2015,12
University of Granada,Religion2020,2015,15
University of Graz,Cooperation2021,2015,8
University of Graz,CooperationAndReligion,2015,1
University of Graz,Religion2020,2015,23
University of Greenwich,Religion2020,2015,6
University of Greifswald,Cooperation2021,2015,4
University of Greifswald,Religion2020,2015,1
University of Grenoble,Religion2020,2015,8
University of Groningen,Cooperation2021,2015,34
University of Groningen,CooperationAndReligion,2015,1
University of Groningen,Religion2020,2015,84
University of Guelph,Cooperation2021,2015,11
University of Guelph-Humber,Religion2020,2015,2
University of Haifa,Cooperation2021,2015,12
University of Haifa,CooperationAndReligion,2015,1
University of Haifa,Religion2020,2015,99
University of Hamburg,Cooperation2021,2015,12
University of Hamburg,CooperationAndReligion,2015,1
University of Hamburg,Religion2020,2015,10
University of Hannover,Cooperation2021,2015,1
University of Hannover,Religion2020,2015,15
University of Hawai‘i,Religion2020,2015,12
University of Hawai‘i at Mānoa,Cooperation2021,2015,13
University of Hawai‘i at Mānoa,Religion2020,2015,22
University of Hawai'i,Cooperation2021,2015,3
University of Hawai'i,Religion2020,2015,5
University of Hawaii at Manoa,Religion2020,2015,1
University of Health and Allied Sciences,Cooperation2021,2015,1
University of Health and Allied Sciences,Religion2020,2015,5
University of Heidelberg,Religion2020,2015,10
University of Helsinki,Cooperation2021,2015,12
University of Helsinki,CooperationAndReligion,2015,1
University of Helsinki,Religion2020,2015,184
University of Hertfordshire,Religion2020,2015,7
University of Hong Kong,Cooperation2021,2015,6
University of Hong Kong,Religion2020,2015,56
University of Houston,Cooperation2021,2015,22
University of Houston,Religion2020,2015,18
University of Houston - Victoria,Religion2020,2015,1
University of Huddersfield,Cooperation2021,2015,2
University of Huddersfield,CooperationAndReligion,2015,2
University of Huddersfield,Religion2020,2015,19
University of Hull,Cooperation2021,2015,1
University of Hull,Religion2020,2015,9
University of Humanistic Studies,Religion2020,2015,19
University of Ibadan,Religion2020,2015,6
University of Illinois,Cooperation2021,2015,2
University of Illinois,Religion2020,2015,18
University of Illinois at Chicago,Cooperation2021,2015,7
University of Illinois at Chicago,Religion2020,2015,18
University of Illinois at Urbana-Champaign,Cooperation2021,2015,5
University of Illinois at Urbana-Champaign,Religion2020,2015,39
University of Illinois Urbana-Champaign,Cooperation2021,2015,1
University of Illinois Urbana-Champaign,CooperationAndReligion,2015,1
University of Illinois Urbana-Champaign,Religion2020,2015,9
University of Indonesia,Cooperation2021,2015,2
University of Indonesia,Religion2020,2015,16
University of Information Technology and Management based in Rzeszów,Cooperation2021,2015,2
University of Information Technology and Management based in Rzeszów,CooperationAndReligion,2015,1
University of Information Technology and Management based in Rzeszów,Religion2020,2015,11
University of Innsbruck,Cooperation2021,2015,5
University of Innsbruck,CooperationAndReligion,2015,2
University of Innsbruck,Religion2020,2015,37
University of Iowa,Cooperation2021,2015,2
University of Iowa,Religion2020,2015,40
University of Jena,Cooperation2021,2015,6
University of Johannesburg,Religion2020,2015,18
University of Jyväskylä,Cooperation2021,2015,1
University of Jyväskylä,Religion2020,2015,10
University of Kansas,Religion2020,2015,20
University of Kansas Medical Center,Religion2020,2015,3
University of Karachi,Religion2020,2015,5
University of Kent,Cooperation2021,2015,8
University of Kent,CooperationAndReligion,2015,1
University of Kent,Religion2020,2015,42
University of Kent at Canterbury,Religion2020,2015,34
University of Kentucky,Cooperation2021,2015,3
University of Kentucky,CooperationAndReligion,2015,2
University of Kentucky,Religion2020,2015,59
University of Kiel,Religion2020,2015,2
University of Koblenz-Landau,Cooperation2021,2015,16
University of Koblenz-Landau,Religion2020,2015,5
University of Koln,Religion2020,2015,2
University of Konstanz,Cooperation2021,2015,5
University of Konstanz,CooperationAndReligion,2015,1
University of Konstanz,Religion2020,2015,10
University of Kurdistan Hawler,Religion2020,2015,7
University of KwaZulu-Natal,Cooperation2021,2015,1
University of KwaZulu-Natal,Religion2020,2015,38
University of La Laguna,Cooperation2021,2015,6
University of La Laguna,CooperationAndReligion,2015,1
University of La Laguna,Religion2020,2015,4
University of Lancaster,Religion2020,2015,20
University of L'Aquila,Religion2020,2015,3
University of Lausanne,Cooperation2021,2015,18
University of Lausanne,CooperationAndReligion,2015,1
University of Lausanne,Religion2020,2015,42
University of Lausanne Medical School,Religion2020,2015,4
University of Leeds,Cooperation2021,2015,4
University of Leeds,CooperationAndReligion,2015,1
University of Leeds,Religion2020,2015,114
University of Leicester,Cooperation2021,2015,14
University of Leicester,CooperationAndReligion,2015,3
University of Leicester,Religion2020,2015,63
University of Leiden,Cooperation2021,2015,4
University of Leiden,CooperationAndReligion,2015,1
University of Leiden,Religion2020,2015,31
University of Leipzig,Cooperation2021,2015,5
University of Leipzig,Religion2020,2015,63
University of Lethbridge,Cooperation2021,2015,10
University of Lethbridge,Religion2020,2015,2
University of Leuven,Religion2020,2015,29
University of Liege,Religion2020,2015,15
University of Lille,Cooperation2021,2015,10
University of Lille,Religion2020,2015,4
University of Lille III,Religion2020,2015,5
University of Limerick,Cooperation2021,2015,1
University of Limerick,Religion2020,2015,4
University of Lincoln,Religion2020,2015,18
University of Lisbon,Cooperation2021,2015,1
University of Lisbon,Religion2020,2015,5
University of Liverpool,Cooperation2021,2015,6
University of Liverpool,Religion2020,2015,28
University of Ljubljana,Religion2020,2015,3
University of Lleida,Religion2020,2015,9
University of Lodz,Religion2020,2015,2
University of London,Cooperation2021,2015,1
University of London,Religion2020,2015,105
University of Louisville,Cooperation2021,2015,7
University of Louisville,CooperationAndReligion,2015,1
University of Louisville,Religion2020,2015,20
University of Louvain,Religion2020,2015,4
University of Lucerne,Cooperation2021,2015,1
University of Lucerne,Religion2020,2015,24
University of Lucknow,Religion2020,2015,1
University of Ludwigsburg,Religion2020,2015,8
University of Lund,Cooperation2021,2015,1
University of Lund,Religion2020,2015,2
University of Luxembourg,Cooperation2021,2015,1
University of Lynchburg,Religion2020,2015,4
University of Lyon,Cooperation2021,2015,13
University of Lyon-II,Religion2020,2015,20
University of Maastricht,Cooperation2021,2015,2
University of Maastricht,Religion2020,2015,4
University of Macau,Religion2020,2015,8
University of Macerata,Cooperation2021,2015,2
University of Macerata,Religion2020,2015,4
University of Madras,Religion2020,2015,1
University of Magdeburg,Cooperation2021,2015,4
University of Maine,Cooperation2021,2015,1
University of Maine,Religion2020,2015,15
University of Málaga,Religion2020,2015,1
University of Malang,Religion2020,2015,16
University of Malaya,Cooperation2021,2015,2
University of Malaya,CooperationAndReligion,2015,2
University of Malaya,Religion2020,2015,51
University of Malta,Religion2020,2015,12
University of Manchester,Cooperation2021,2015,5
University of Manchester,Religion2020,2015,72
University of Manitoba,Cooperation2021,2015,6
University of Manitoba,Religion2020,2015,18
University of Mannheim,Religion2020,2015,10
University of Marburg,Religion2020,2015,3
University of Maribor,Cooperation2021,2015,42
University of Maribor,Religion2020,2015,4
University of Mary Hardin-Baylor,Cooperation2021,2015,1
University of Mary Hardin-Baylor,CooperationAndReligion,2015,1
University of Mary Hardin-Baylor,Religion2020,2015,7
University of Mary Washington,Religion2020,2015,1
University of Maryland,Cooperation2021,2015,16
University of Maryland,Religion2020,2015,52
University of Maryland - College Park,Religion2020,2015,1
"University of Maryland, Baltimore",Religion2020,2015,16
"University of Maryland, College Park",Cooperation2021,2015,8
"University of Maryland, College Park",Religion2020,2015,34
University of Massachusetts - Amherst,Cooperation2021,2015,9
University of Massachusetts - Amherst,Religion2020,2015,12
University of Massachusetts - Boston,Religion2020,2015,20
University of Massachusetts Amherst,Cooperation2021,2015,1
University of Massachusetts Lowell,Religion2020,2015,5
University of Massachusetts Medical School,Cooperation2021,2015,1
University of Massachusetts Medical School,Religion2020,2015,4
University of Massachusetts Memorial Medical Center,Religion2020,2015,23
"University of Massachusetts, Boston",Cooperation2021,2015,1
"University of Massachusetts, Boston",Religion2020,2015,4
University of Melbourne,Cooperation2021,2015,28
University of Melbourne,CooperationAndReligion,2015,1
University of Melbourne,Religion2020,2015,34
University of Memphis,Religion2020,2015,15
University of Miami,Cooperation2021,2015,13
University of Miami,Religion2020,2015,85
University of Michigan,Cooperation2021,2015,45
University of Michigan,CooperationAndReligion,2015,2
University of Michigan,Religion2020,2015,160
University of Michigan Medical School,Religion2020,2015,3
University of Michigan School of Public Health,Cooperation2021,2015,1
University of Michigan School of Public Health,Religion2020,2015,4
"University of Michigan, Ann Arbor",Religion2020,2015,4
"University of Michigan, Dearborn",Religion2020,2015,2
University of Milan,Cooperation2021,2015,1
University of Milan,CooperationAndReligion,2015,1
University of Milan,Religion2020,2015,8
University of Milan - Bicocca,Cooperation2021,2015,1
University of Minho,Cooperation2021,2015,17
University of Minnesota,Cooperation2021,2015,13
University of Minnesota,Religion2020,2015,64
"University of Minnesota, Duluth",Religion2020,2015,13
University of Mississippi,Cooperation2021,2015,4
University of Mississippi,Religion2020,2015,14
University of Missouri,Cooperation2021,2015,2
University of Missouri,Religion2020,2015,23
University of Missouri Mizzou,Cooperation2021,2015,1
University of Missouri Mizzou,Religion2020,2015,21
University of Missouri-Kansas City,Religion2020,2015,12
University of Modena and Reggio Emilia,Cooperation2021,2015,4
University of Modena and Reggio Emilia,Religion2020,2015,4
University of Montpellier,Cooperation2021,2015,5
University of Montreal,Cooperation2021,2015,5
University of Montreal,Religion2020,2015,49
University of Mostar,Religion2020,2015,4
University of Muenster,Religion2020,2015,21
"University of Muhammadiyah Malang, Campus II",Religion2020,2015,7
University of Munich,Religion2020,2015,13
University of Munster,Cooperation2021,2015,3
University of Munster,Religion2020,2015,7
University of Münster,Cooperation2021,2015,1
University of Münster,Religion2020,2015,34
University of Murcia,Cooperation2021,2015,30
University of Murcia,CooperationAndReligion,2015,28
University of Murcia,Religion2020,2015,30
University of Nairobi,Religion2020,2015,3
University of Namur,Cooperation2021,2015,3
University of Namur,CooperationAndReligion,2015,2
University of Namur,Religion2020,2015,9
University of Nantes,Religion2020,2015,16
University of Navarra,Cooperation2021,2015,1
University of Navarra,Religion2020,2015,20
University of Nebraska,Cooperation2021,2015,6
University of Nebraska,CooperationAndReligion,2015,1
University of Nebraska,Religion2020,2015,13
"University of Nebraska, Omaha",Cooperation2021,2015,4
University of Nebraska-Lincoln,Cooperation2021,2015,4
University of Nebraska-Lincoln,Religion2020,2015,18
University of Neuchâtel,Cooperation2021,2015,10
University of Neuchâtel,Religion2020,2015,1
University of Nevada,Religion2020,2015,1
University of Nevada - Reno,Cooperation2021,2015,1
University of Nevada - Reno,CooperationAndReligion,2015,1
University of Nevada - Reno,Religion2020,2015,32
"University of Nevada, Las Vegas",Cooperation2021,2015,8
"University of Nevada, Las Vegas",Religion2020,2015,13
University of New Brunswick,Cooperation2021,2015,1
University of New Brunswick,Religion2020,2015,16
University of New England,Cooperation2021,2015,3
University of New England,Religion2020,2015,5
University of New Hampshire,Religion2020,2015,8
University of New Mexico,Cooperation2021,2015,22
University of New Mexico,CooperationAndReligion,2015,1
University of New Mexico,Religion2020,2015,35
University of New Orleans,Religion2020,2015,11
University of New South Wales,Cooperation2021,2015,6
University of New South Wales,Religion2020,2015,16
University of New York in Prague,Religion2020,2015,4
University of Newcastle,Cooperation2021,2015,2
University of Newcastle,Religion2020,2015,46
University of Nigeria,Religion2020,2015,1
University of Niigata Prefecture,Religion2020,2015,4
University of Nijmegen,Religion2020,2015,1
University of North Alabama,Religion2020,2015,7
University of North Carolina,Cooperation2021,2015,21
University of North Carolina,Religion2020,2015,21
University of North Carolina - Chapel Hill,Religion2020,2015,1
University of North Carolina at Chapel Hill,Cooperation2021,2015,8
University of North Carolina at Chapel Hill,CooperationAndReligion,2015,1
University of North Carolina at Chapel Hill,Religion2020,2015,75
University of North Carolina at Charlotte,Cooperation2021,2015,1
University of North Carolina at Charlotte,Religion2020,2015,39
University of North Carolina at Greensboro,Cooperation2021,2015,2
University of North Carolina at Greensboro,Religion2020,2015,12
University of North Carolina at Wilmington,Cooperation2021,2015,1
University of North Carolina at Wilmington,CooperationAndReligion,2015,1
University of North Carolina at Wilmington,Religion2020,2015,9
University of North Carolina Hospitals,Religion2020,2015,1
University of North Dakota,Religion2020,2015,6
University of North Florida,Cooperation2021,2015,1
University of North Florida,CooperationAndReligion,2015,1
University of North Florida,Religion2020,2015,9
University of North Texas,Cooperation2021,2015,1
University of North Texas,Religion2020,2015,98
University of Northampton,Religion2020,2015,2
University of Northern Iowa,Religion2020,2015,3
University of Notre Dame,Cooperation2021,2015,5
University of Notre Dame,CooperationAndReligion,2015,3
University of Notre Dame,Religion2020,2015,235
University of Notre Dame Australia,Cooperation2021,2015,1
University of Notre Dame Australia,CooperationAndReligion,2015,1
University of Notre Dame Australia,Religion2020,2015,34
University of Notre Dame- Australia,Religion2020,2015,9
University of Nottingham,Cooperation2021,2015,33
University of Nottingham,Religion2020,2015,65
University of Oklahoma,Cooperation2021,2015,3
University of Oklahoma,CooperationAndReligion,2015,1
University of Oklahoma,Religion2020,2015,65
University of Oldenburg,Cooperation2021,2015,7
University of Oldenburg,Religion2020,2015,1
University of Oran,Cooperation2021,2015,2
University of Oran,Religion2020,2015,5
University of Oregon,Cooperation2021,2015,13
University of Oregon,CooperationAndReligion,2015,3
University of Oregon,Religion2020,2015,41
University of Oslo,Cooperation2021,2015,11
University of Oslo,Religion2020,2015,102
University of Osnabruck,Cooperation2021,2015,1
University of Osnabruck,CooperationAndReligion,2015,1
University of Osnabruck,Religion2020,2015,13
University of Osnabrück,Religion2020,2015,3
University of Otago,Cooperation2021,2015,21
University of Otago,CooperationAndReligion,2015,6
University of Otago,Religion2020,2015,104
University of Ottawa,Cooperation2021,2015,12
University of Ottawa,CooperationAndReligion,2015,1
University of Ottawa,Religion2020,2015,117
University of Oulu,Cooperation2021,2015,2
University of Oulu,Religion2020,2015,6
University of Oxford,Cooperation2021,2015,132
University of Oxford,CooperationAndReligion,2015,9
University of Oxford,Religion2020,2015,375
"University of Oxford, Champion Hall",Religion2020,2015,11
University of Paderborn,Religion2020,2015,12
University of Padova,Cooperation2021,2015,4
University of Padova,Religion2020,2015,7
University of Padua,Religion2020,2015,51
University of Palermo,Cooperation2021,2015,4
University of Pantanjali,Religion2020,2015,6
University of Paris,Religion2020,2015,6
University of Paris at Nanterre,Religion2020,2015,7
University of Paris Ouest,Religion2020,2015,2
University of Paris VIII,Religion2020,2015,6
University of Parma,Cooperation2021,2015,4
University of Parma,Religion2020,2015,1
University of Passau,Religion2020,2015,4
University of Patanjali,Religion2020,2015,1
University of Patras,Cooperation2021,2015,3
University of Pavia,Religion2020,2015,6
University of Pécs,Cooperation2021,2015,1
University of Peloponnese,Religion2020,2015,6
University of Pennsylvania,Cooperation2021,2015,70
University of Pennsylvania,CooperationAndReligion,2015,6
University of Pennsylvania,Religion2020,2015,101
University of Pennsylvania Program for Mindfulness,Religion2020,2015,1
University of Pisa,Cooperation2021,2015,10
University of Pisa,Religion2020,2015,2
University of Pittsburgh,Cooperation2021,2015,6
University of Pittsburgh,Religion2020,2015,51
University of Pittsburgh Cancer Institute,Religion2020,2015,2
University of Plymouth,Cooperation2021,2015,1
University of Plymouth,Religion2020,2015,17
University of Portland,Religion2020,2015,7
University of Porto,Cooperation2021,2015,1
University of Porto,Religion2020,2015,9
University of Portsmouth,Cooperation2021,2015,1
University of Portsmouth,Religion2020,2015,15
University of Potsdam,Religion2020,2015,5
University of Prešov,Religion2020,2015,2
University of Pretoria,Cooperation2021,2015,2
University of Pretoria,Religion2020,2015,200
University of Québec at Montreal,Cooperation2021,2015,2
University of Québec at Montreal,Religion2020,2015,10
University of Quebec in Montreal,Religion2020,2015,2
University of Queensland,Cooperation2021,2015,19
University of Queensland,Religion2020,2015,70
University of Quetzalcoatl en Irapuato,Cooperation2021,2015,4
University of Quetzalcoatl en Irapuato,CooperationAndReligion,2015,3
University of Quetzalcoatl en Irapuato,Religion2020,2015,4
University of Reading,Cooperation2021,2015,1
University of Reading,Religion2020,2015,28
University of Redlands,Religion2020,2015,12
University of Regensburg,Cooperation2021,2015,4
University of Regensburg,Religion2020,2015,18
University of Regina,Religion2020,2015,15
University of Rennes,Cooperation2021,2015,2
University of Richmond,Religion2020,2015,21
University of Rijeka,Religion2020,2015,2
University of Rochester,Religion2020,2015,30
University of Rochester Medical Center,Religion2020,2015,4
University of Roehampton,Religion2020,2015,11
University of Rome,Religion2020,2015,5
University of Rostock,Cooperation2021,2015,1
University of Rostock,Religion2020,2015,1
University of Rotterdam,Cooperation2021,2015,2
University of Saint Mary of the Lake,Religion2020,2015,10
University of San Diego,Religion2020,2015,28
University of San Francisco,Religion2020,2015,6
university of san martin,Religion2020,2015,4
University of Santiago,Religion2020,2015,2
University of Santo Tomas,Cooperation2021,2015,1
University of Santo Tomas,Religion2020,2015,5
University of Sao Paulo,Religion2020,2015,11
University of São Paulo,Religion2020,2015,38
University of Saskatchewan,Cooperation2021,2015,4
University of Saskatchewan,Religion2020,2015,22
University of Sassari,Cooperation2021,2015,1
University of Sau Paulo,Religion2020,2015,2
University of Savoie,Religion2020,2015,1
University of Science and Culture,Cooperation2021,2015,1
University of Science and Technology,Cooperation2021,2015,6
University of Science and Technology of China,Cooperation2021,2015,17
University of Scranton,Religion2020,2015,4
University of Shanghai,Religion2020,2015,8
University of Sheffield,Cooperation2021,2015,9
University of Sheffield,Religion2020,2015,26
University of Sherbrooke,Religion2020,2015,12
University of Shiraz,Religion2020,2015,1
University of Siegen,Religion2020,2015,8
University of Siena,Cooperation2021,2015,3
University of Sindh,Religion2020,2015,4
University of Singapore,Religion2020,2015,5
University of Sistan,Religion2020,2015,6
University of Social Welfare and Rehabilitation Science,Cooperation2021,2015,2
University of Social Welfare and Rehabilitation Science,Religion2020,2015,7
University of Social Welfare and Rehabilitation Sciences,Cooperation2021,2015,1
University of Social Welfare and Rehabilitation Sciences,CooperationAndReligion,2015,1
University of Social Welfare and Rehabilitation Sciences,Religion2020,2015,13
University of South Africa,Cooperation2021,2015,4
University of South Africa,CooperationAndReligion,2015,2
University of South Africa,Religion2020,2015,151
University of South Alabama,Religion2020,2015,32
University of South Australia,Religion2020,2015,29
University of South Bohemia,Cooperation2021,2015,8
University of South Carolina,Cooperation2021,2015,9
University of South Carolina,Religion2020,2015,40
University of South Dakota,Religion2020,2015,9
University of South Florida,Cooperation2021,2015,10
University of South Florida,Religion2020,2015,34
University of South Florida - Saint Petersburg,Religion2020,2015,10
University of South Wales,Cooperation2021,2015,4
University of South Wales,Religion2020,2015,11
University of Southampton,Cooperation2021,2015,12
University of Southampton,Religion2020,2015,10
University of South-Eastern Norway,Cooperation2021,2015,1
University of South-Eastern Norway,Religion2020,2015,5
University of Southern California,Cooperation2021,2015,19
University of Southern California,Religion2020,2015,70
University of Southern Denmark,Cooperation2021,2015,5
University of Southern Denmark,CooperationAndReligion,2015,3
University of Southern Denmark,Religion2020,2015,75
University of Southern Maine,Religion2020,2015,1
University of Southern Mississippi,Religion2020,2015,3
University of Southern Queensland,Religion2020,2015,5
University of Split,Religion2020,2015,3
University of St Andrews,Cooperation2021,2015,44
University of St Andrews,Religion2020,2015,63
University of St Michaels,Religion2020,2015,3
University of St. Gallen,Religion2020,2015,6
University of St. Thomas,Religion2020,2015,21
University of Stavanger,Cooperation2021,2015,5
University of Stavanger,Religion2020,2015,8
University of Stellenbosch,Cooperation2021,2015,3
University of Stellenbosch,Religion2020,2015,30
University of Stirling,Cooperation2021,2015,4
University of Stirling,Religion2020,2015,34
University of Strasbourg,Cooperation2021,2015,3
University of Strasbourg,Religion2020,2015,14
University of Strathclyde,Religion2020,2015,20
University of Sunderland,Religion2020,2015,4
University of Surrey,Cooperation2021,2015,2
University of Surrey,Religion2020,2015,4
University of Sussex,Cooperation2021,2015,10
University of Sussex,CooperationAndReligion,2015,1
University of Sussex,Religion2020,2015,47
University of Sydney,Cooperation2021,2015,15
University of Sydney,CooperationAndReligion,2015,2
University of Sydney,Religion2020,2015,163
University of Szeged,Religion2020,2015,1
University of Tampa,Religion2020,2015,26
University of Tampere,Cooperation2021,2015,1
University of Tampere,Religion2020,2015,1
University of Tartu,Cooperation2021,2015,1
University of Tartu,Religion2020,2015,12
University of Tasmania,Religion2020,2015,15
University of Technology Sydney,Cooperation2021,2015,4
University of Technology Sydney,Religion2020,2015,58
University of Teesside,Cooperation2021,2015,1
University of Teesside,Religion2020,2015,1
University of Tehran,Cooperation2021,2015,1
University of Tehran,Religion2020,2015,23
University of Tennessee,Cooperation2021,2015,2
University of Tennessee,CooperationAndReligion,2015,1
University of Tennessee,Religion2020,2015,23
University of Tennessee - Knoxville,Cooperation2021,2015,7
University of Tennessee - Knoxville,Religion2020,2015,4
University of Tennessee at Chattanooga,Religion2020,2015,24
University of Texas at Arlington,Religion2020,2015,1
University of Texas at Austin,Cooperation2021,2015,7
University of Texas at Austin,CooperationAndReligion,2015,2
University of Texas at Austin,Religion2020,2015,55
University of Texas at Dallas,Cooperation2021,2015,5
University of Texas at Dallas,Religion2020,2015,6
University of Texas at El Paso,Religion2020,2015,6
University of Texas at Houston,Cooperation2021,2015,1
University of Texas at Houston,Religion2020,2015,11
University of Texas at San Antonio,Religion2020,2015,44
University of Texas Health Science Center at Houston (UTHealth),Religion2020,2015,86
University of Texas MD Anderson Cancer Center,Cooperation2021,2015,1
University of Texas MD Anderson Cancer Center,Religion2020,2015,54
University of Texas Medical Branch,Cooperation2021,2015,1
University of Texas Medical Branch,Religion2020,2015,4
University of Texas Medical Branch at Galveston,Cooperation2021,2015,3
University of Texas Medical Branch at Galveston,Religion2020,2015,4
University of Texas Pan American,Religion2020,2015,2
University of Texas Rio Grande Valley,Religion2020,2015,1
University of Texas School of Education,Religion2020,2015,2
University of Texas School of Public Health,Cooperation2021,2015,1
University of Texas School of Public Health,Religion2020,2015,12
University of Texas Southwestern Medical Center,Cooperation2021,2015,2
University of Texas Southwestern Medical Center,Religion2020,2015,15
University of the Balearic Islands,Cooperation2021,2015,1
University of the Balearic Islands,Religion2020,2015,1
University of the Basque Country,Religion2020,2015,8
University of the Free State,Religion2020,2015,50
University of the Highlands and Islands,Religion2020,2015,1
University of the Peloponnese,Religion2020,2015,6
University of the South,Religion2020,2015,4
University of the Sunshine Coast,Religion2020,2015,1
University of the West of England,Religion2020,2015,22
University of the Western Cape,Religion2020,2015,18
University of the Witwatersrand,Religion2020,2015,9
University of Thessaloniki,Cooperation2021,2015,1
University of Thessaloniki,Religion2020,2015,2
University of Thessaly,Cooperation2021,2015,4
University of Thessaly,Religion2020,2015,5
University of Tilburg,Religion2020,2015,4
University of Tokyo,Cooperation2021,2015,24
University of Tokyo,Religion2020,2015,16
University of Toledo,Religion2020,2015,1
University of Torino,Cooperation2021,2015,1
University of Toronto,Cooperation2021,2015,28
University of Toronto,CooperationAndReligion,2015,4
University of Toronto,Religion2020,2015,267
University of Toronto Scarborough,Cooperation2021,2015,3
University of Toronto Scarborough,CooperationAndReligion,2015,2
University of Toronto Scarborough,Religion2020,2015,9
University of Toulouse,Religion2020,2015,11
University of Tours,Cooperation2021,2015,6
University of Tours,Religion2020,2015,6
University of Trento,Cooperation2021,2015,11
University of Trento,Religion2020,2015,19
University of Trier,Cooperation2021,2015,1
University of Trier,Religion2020,2015,1
University of Tromsø,Religion2020,2015,7
University of Tsukuba,Religion2020,2015,3
University of Tubigen Germany,Religion2020,2015,21
University of Tubingen,Cooperation2021,2015,1
University of Tubingen,Religion2020,2015,3
University of Tübingen,Cooperation2021,2015,9
University of Tübingen,CooperationAndReligion,2015,3
University of Tübingen,Religion2020,2015,91
University of Tulsa,Cooperation2021,2015,1
University of Tulsa,Religion2020,2015,5
University of Turbat,Religion2020,2015,8
University of Turin,Cooperation2021,2015,4
University of Turin,CooperationAndReligion,2015,2
University of Turin,Religion2020,2015,62
University of Turku,Cooperation2021,2015,3
University of Turku,Religion2020,2015,30
University of Tuzla,Religion2020,2015,3
University of Uberaba,Religion2020,2015,6
University of Udine,Religion2020,2015,16
University of Ulm,Religion2020,2015,6
University of Ulsan,Religion2020,2015,6
University of Ulster,Religion2020,2015,8
University of Utah,Cooperation2021,2015,3
University of Utah,Religion2020,2015,110
University of Utrecht,Cooperation2021,2015,5
University of Utrecht,Religion2020,2015,38
University of Valencia,Cooperation2021,2015,2
University of Valencia,Religion2020,2015,22
University of Vermont,Cooperation2021,2015,3
University of Vermont,Religion2020,2015,30
University of Verona,Cooperation2021,2015,6
University of Versailles,Religion2020,2015,1
University of Victoria,Cooperation2021,2015,2
University of Victoria,Religion2020,2015,26
University of Vienna,Cooperation2021,2015,102
University of Vienna,CooperationAndReligion,2015,21
University of Vienna,Religion2020,2015,169
University of Vigo,Cooperation2021,2015,10
University of Virginia,Cooperation2021,2015,11
University of Virginia,Religion2020,2015,76
University of Waikato,Cooperation2021,2015,3
University of Waikato,Religion2020,2015,13
University of Wales,Religion2020,2015,5
University of Wales at Lampeter,Religion2020,2015,4
University of Wales Trinity Saint David,Religion2020,2015,11
University of Warsaw,Religion2020,2015,7
University of Warwick,Cooperation2021,2015,22
University of Warwick,CooperationAndReligion,2015,2
University of Warwick,Religion2020,2015,184
University of Washington,Cooperation2021,2015,24
University of Washington,CooperationAndReligion,2015,1
University of Washington,Religion2020,2015,83
University of Washington Bothell,Religion2020,2015,2
University of Waterloo,Cooperation2021,2015,11
University of Waterloo,Religion2020,2015,22
University of West England,Religion2020,2015,6
University of West Florida,Religion2020,2015,6
University of West Georgia,Cooperation2021,2015,1
University of West Georgia,CooperationAndReligion,2015,1
University of West Georgia,Religion2020,2015,8
University of Western Attica,Religion2020,2015,3
University of Western Australia,Cooperation2021,2015,5
University of Western Australia,Religion2020,2015,25
University of Western Cape,Religion2020,2015,6
University of Western Macedonia,Religion2020,2015,2
University of Western Ontario,Religion2020,2015,23
University of Western Sydney,Religion2020,2015,13
University of Westminster,Religion2020,2015,10
University of Winchester,Religion2020,2015,56
University of Windsor,Cooperation2021,2015,1
University of Windsor,Religion2020,2015,13
University of Winnipeg,Religion2020,2015,1
University of Wisconsin,Cooperation2021,2015,4
University of Wisconsin,Religion2020,2015,36
University of Wisconsin - Eau Claire,Religion2020,2015,2
University of Wisconsin - Madison,Cooperation2021,2015,11
University of Wisconsin - Madison,CooperationAndReligion,2015,1
University of Wisconsin - Madison,Religion2020,2015,84
University of Wisconsin - Milwaukee,Religion2020,2015,6
University of Wisconsin at Parkside,Religion2020,2015,1
University of Wisconsin System,Religion2020,2015,2
University of Wisconsin-Madison,Cooperation2021,2015,4
University of Wisconsin-Madison,Religion2020,2015,40
University of Wisconsin-Milwaukee,Religion2020,2015,4
University of Witten/Herdecke,Cooperation2021,2015,1
University of Witten/Herdecke,CooperationAndReligion,2015,1
University of Witten/Herdecke,Religion2020,2015,57
University of Witwatersrand,Religion2020,2015,4
University of Wollongong,Religion2020,2015,15
University of Wolverhampton,Religion2020,2015,3
University of Worcester,Religion2020,2015,36
University of Wrocław,Religion2020,2015,6
University of Wuppertal,Religion2020,2015,1
University of Würzburg,Cooperation2021,2015,4
University of Würzburg,CooperationAndReligion,2015,1
University of Würzburg,Religion2020,2015,23
University of Wyoming,Cooperation2021,2015,7
University of Wyoming,CooperationAndReligion,2015,1
University of Wyoming,Religion2020,2015,24
University of York,Cooperation2021,2015,3
University of York,Religion2020,2015,35
University of Zagreb,Cooperation2021,2015,2
University of Zagreb,CooperationAndReligion,2015,2
University of Zagreb,Religion2020,2015,14
University of Zambia,Religion2020,2015,13
University of Zaragoza,Cooperation2021,2015,17
University of Zaragoza,Religion2020,2015,18
University of Zenica,Religion2020,2015,3
University of Zimbabwe,Cooperation2021,2015,1
University of Zimbabwe,Religion2020,2015,9
University of Zurich,Cooperation2021,2015,76
University of Zurich,CooperationAndReligion,2015,1
University of Zurich,Religion2020,2015,48
University Psychiatric Center KU Leuven,Religion2020,2015,3
University Saint Paul,Religion2020,2015,4
University Ulm,Religion2020,2015,6
University West,Religion2020,2015,10
Uniwersytet Jagielloński Collegium Medicum,Religion2020,2015,3
Uniwersytet Papieski Jana Pawla II w Krakowie,Religion2020,2015,4
UNSW Australia,Religion2020,2015,1
"UNSW, Sydney",Religion2020,2015,3
Unternehmensberatung,Religion2020,2015,8
Uplift Appalachia,Religion2020,2015,5
UPMC Health,Religion2020,2015,1
UPMC Western Psychiatric Hospital,Religion2020,2015,2
Uppsala University,Cooperation2021,2015,11
Uppsala University,Religion2020,2015,52
Ursinus College,Religion2020,2015,4
US Army,Religion2020,2015,3
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Religion2020,2015,5
Utah State University,Religion2020,2015,12
Utah Valley University,Religion2020,2015,2
UTC HRDC,Religion2020,2015,9
Utrecht University,Cooperation2021,2015,18
Utrecht University,CooperationAndReligion,2015,1
Utrecht University,Religion2020,2015,117
VA,Religion2020,2015,9
VA Atlanta,Religion2020,2015,1
"VA Bay Pines, FL",Religion2020,2015,7
VA Boston Healthcare System,Religion2020,2015,3
VA Durham,Religion2020,2015,8
VA Hospital Durham,Religion2020,2015,1
VA HSR&D Center for Health Services Research in Primary Care,Religion2020,2015,8
VA HSR&D Center of Excellence,Religion2020,2015,4
VA Medical Center- Minneapolis,Religion2020,2015,12
VA Minneapolis Medical Center,Religion2020,2015,1
VA Palo Alto Health Care System,Religion2020,2015,4
VA Providence,Religion2020,2015,1
VA San Diego,Religion2020,2015,2
Valaparaiso University,Religion2020,2015,5
Valdosta State University,Religion2020,2015,7
Valley Christian Schools (Northeast Ohio),Religion2020,2015,3
Valparaiso University,Religion2020,2015,16
Vanderbilt Divinity School,Religion2020,2015,17
Vanderbilt University,Cooperation2021,2015,2
Vanderbilt University,CooperationAndReligion,2015,2
Vanderbilt University,Religion2020,2015,82
Vanguard University,Religion2020,2015,3
Vanung University,Religion2020,2015,1
Vassar College,Religion2020,2015,6
Vatican Bank,Religion2020,2015,5
Vencore Labs,Cooperation2021,2015,5
Vencore Labs,Religion2020,2015,2
Veracode,Cooperation2021,2015,3
"Versiti, Inc.",Cooperation2021,2015,1
Veterans Health Adminstration,Religion2020,2015,14
Viadrina European University,Cooperation2021,2015,6
Victoria Associates,Religion2020,2015,8
Victoria University Melbourne,Cooperation2021,2015,2
Victoria University Melbourne,Religion2020,2015,7
Victoria University of Wellington,Cooperation2021,2015,17
Victoria University of Wellington,CooperationAndReligion,2015,8
Victoria University of Wellington,Religion2020,2015,50
"Victoria University, Toronto",Religion2020,2015,2
VID Specialized University,Cooperation2021,2015,2
VID Specialized University,CooperationAndReligion,2015,1
VID Specialized University,Religion2020,2015,24
Vigie de laïcité,Religion2020,2015,2
Villanova University,Cooperation2021,2015,1
Villanova University,CooperationAndReligion,2015,1
Villanova University,Religion2020,2015,50
Vilnius Gediminas Technical University,Religion2020,2015,6
Vilnius University,Religion2020,2015,3
Vinayaka Missions University,Cooperation2021,2015,1
Vinayaka Missions University,CooperationAndReligion,2015,1
Vinayaka Missions University,Religion2020,2015,7
Vipassana Support International,Religion2020,2015,3
Virginia Commonwealth University,Cooperation2021,2015,11
Virginia Commonwealth University,Religion2020,2015,50
Virginia Polytechnic Institute and State University,Cooperation2021,2015,5
Virginia Polytechnic Institute and State University,Religion2020,2015,10
Virginia Tech,Cooperation2021,2015,3
Virginia Tech,Religion2020,2015,17
Virginia Theological Seminary,Religion2020,2015,5
Virginia Wesleyan College,Religion2020,2015,3
Virtual Scientific Laboratories,Cooperation2021,2015,5
Vision2Health BV,Religion2020,2015,2
Vital Research,Religion2020,2015,3
Voice Medicine Australia,Religion2020,2015,4
Vrije Universiteit Amsterdam,Cooperation2021,2015,50
Vrije Universiteit Amsterdam,CooperationAndReligion,2015,1
Vrije Universiteit Amsterdam,Religion2020,2015,178
Vrije Universiteit Brussel,Cooperation2021,2015,14
Vrije Universiteit Brussel,Religion2020,2015,11
Vrije Universiteit Medical Center,Cooperation2021,2015,1
Vrije Universiteit Medical Center,Religion2020,2015,16
Vrije University Brussels,Cooperation2021,2015,3
VYASA Arogyadhama,Religion2020,2015,8
VYASA University,Religion2020,2015,2
Vytautas Magnus University,Religion2020,2015,3
Vytautus Magnus University,Religion2020,2015,5
Wabash Center for Teaching and Learning in Theology and Religion,Religion2020,2015,5
Wabash College,Religion2020,2015,6
Wadham College,Religion2020,2015,8
Wageningen University,Cooperation2021,2015,16
Wageningen University,CooperationAndReligion,2015,2
Wageningen University,Religion2020,2015,20
Wake Forest,Religion2020,2015,4
Wake Forest Baptist Health,Religion2020,2015,20
Wake Forest University,Cooperation2021,2015,7
Wake Forest University,Religion2020,2015,35
Wakefield Diocese,Religion2020,2015,2
Wakiato University,Religion2020,2015,12
Walden University,Religion2020,2015,3
Walter and Eliza Hall Research Institute,Cooperation2021,2015,2
Walter Reed Army Institute of Research,Cooperation2021,2015,2
Walter Reed Army Institute of Research,Religion2020,2015,3
Walter Reed National Military Medical Center,Religion2020,2015,8
Warburg Institute,Religion2020,2015,5
Wartburg College,Religion2020,2015,2
Wartburg Theological Seminary,Religion2020,2015,12
Warwick University,Religion2020,2015,4
Waseda University,Cooperation2021,2015,30
Waseda University,Religion2020,2015,14
Washington and Lee University,Religion2020,2015,3
Washington State University,Cooperation2021,2015,3
Washington State University,Religion2020,2015,9
Washington University in St Louis,Cooperation2021,2015,24
Washington University in St Louis,Religion2020,2015,58
Waterford Institute of Technology,Religion2020,2015,12
Wayne State University,Cooperation2021,2015,11
Wayne State University,Religion2020,2015,28
Webster University,Religion2020,2015,3
Webster University Geneva,Religion2020,2015,1
Weill Cornell Medical College,Cooperation2021,2015,1
Weill Cornell Medical College,CooperationAndReligion,2015,1
Weill Cornell Medical College,Religion2020,2015,9
Weill Cornell Medical College in Qatar,Religion2020,2015,2
Weizmann Institute of Science,Cooperation2021,2015,4
Weizmann Institute of Science,Religion2020,2015,4
Wellcome Sanger Institute,Religion2020,2015,3
Wellesley College,Religion2020,2015,9
Welsh National Centre for Religious Education,Cooperation2021,2015,1
Welsh National Centre for Religious Education,Religion2020,2015,6
Wenzhou University,Cooperation2021,2015,10
Wesley Theological Seminary,Religion2020,2015,1
Wesleyan University,Religion2020,2015,7
West Chester University of Pennsylvania,Religion2020,2015,4
West Park Healthcare Center,Religion2020,2015,2
West Virginia University,Cooperation2021,2015,4
West Virginia University,CooperationAndReligion,2015,1
West Virginia University,Religion2020,2015,63
Western Carolina University,Religion2020,2015,13
Western Galilee College,Religion2020,2015,7
Western Illinois University,Religion2020,2015,1
Western Michigan University,Religion2020,2015,17
Western Sydney University,Cooperation2021,2015,1
Western Sydney University,Religion2020,2015,67
Western University,Cooperation2021,2015,2
Western University,Religion2020,2015,12
Western University of Health Sciences,Religion2020,2015,1
Western Washington University,Religion2020,2015,2
Westfalische University,Religion2020,2015,2
Westminster College,Religion2020,2015,3
Westminster Pastoral Foundation,Religion2020,2015,3
Westminster Seminary California,Religion2020,2015,6
Westmont College,Religion2020,2015,22
Westphalian Wilhelms University,Religion2020,2015,13
Wheaton College,Religion2020,2015,69
Whitefield Theological Seminary,Religion2020,2015,1
Whitley College,Religion2020,2015,5
Whittier College,Religion2020,2015,1
Whitworth University,Religion2020,2015,4
WHO collaborating centre for research and training in mental health,Religion2020,2015,6
Wichita State University,Religion2020,2015,14
Widener University,Religion2020,2015,13
Wildhouse Publications,Religion2020,2015,1
Wilfrid Laurier University,Cooperation2021,2015,11
Wilfrid Laurier University,CooperationAndReligion,2015,1
Wilfrid Laurier University,Religion2020,2015,12
Willamette University,Cooperation2021,2015,6
Willamette University,Religion2020,2015,12
William & Mary,Religion2020,2015,24
William Carey International University,Religion2020,2015,5
William James College,Religion2020,2015,10
William Paterson University,Religion2020,2015,1
Williams College,Religion2020,2015,2
Wilmette Institute,Religion2020,2015,5
Winona State University,Religion2020,2015,1
Wistar Institute,Cooperation2021,2015,2
Wistar Institute,Religion2020,2015,1
Witten/Herdecke University,Religion2020,2015,6
Wittenberg University,Cooperation2021,2015,3
Wittenberg University,Religion2020,2015,1
Wittgenstein Centre for Demography and Global Human Capital  Demography and Global Human Capital,Religion2020,2015,6
WODC (Ministry of Security and Justice),Religion2020,2015,5
Wolfson College,Cooperation2021,2015,6
Wolfson College,Religion2020,2015,1
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,2015,4
Woodbrooke Quaker Study Centre,Religion2020,2015,5
Woodbury University,Cooperation2021,2015,1
Woodbury University,CooperationAndReligion,2015,1
Woodbury University,Religion2020,2015,21
Woolf Institute,Religion2020,2015,1
worcester polytechnic institute,Religion2020,2015,1
World Council of Churches,Religion2020,2015,3
World Health Organisation,Cooperation2021,2015,1
World Health Organisation,Religion2020,2015,4
World Health Organization,Cooperation2021,2015,2
World Health Organization,Religion2020,2015,4
World Health Organization - UK,Religion2020,2015,3
Wrexham Glyndwr University,Religion2020,2015,5
Wright State University,Cooperation2021,2015,3
Wright State University,Religion2020,2015,3
Wuhan University,Cooperation2021,2015,16
Wuhan University,CooperationAndReligion,2015,1
Wuhan University,Religion2020,2015,26
Wuhan University of Technology,Cooperation2021,2015,13
Wycliffe College,Religion2020,2015,2
Xavier University,Religion2020,2015,15
Xi’an Jiaotong University,Cooperation2021,2015,2
Xi’an Jiaotong University,Religion2020,2015,2
Xiamen University,Cooperation2021,2015,1
Xiamen University,Religion2020,2015,6
Xi'an JiaoTong University,Cooperation2021,2015,5
Xi'an JiaoTong University,Religion2020,2015,2
Xidian University,Cooperation2021,2015,5
Yale Divinity School,Cooperation2021,2015,1
Yale Divinity School,CooperationAndReligion,2015,1
Yale Divinity School,Religion2020,2015,52
Yale University,Cooperation2021,2015,66
Yale University,CooperationAndReligion,2015,3
Yale University,Religion2020,2015,142
Yale University Mind and Development Lab,Cooperation2021,2015,1
Yale University Mind and Development Lab,Religion2020,2015,38
Yale University School of Medicine,Religion2020,2015,3
Yale-NUS College,Religion2020,2015,11
Yamaguchi Prefectural University,Religion2020,2015,3
Yasouj University,Religion2020,2015,8
Yat-sen University,Cooperation2021,2015,5
Yat-sen University,Religion2020,2015,2
Yeshiva University,Cooperation2021,2015,1
Yeshiva University,CooperationAndReligion,2015,1
Yeshiva University,Religion2020,2015,5
Yeungnam University,Religion2020,2015,6
Yezreel Valley Academic College,Religion2020,2015,8
Yogis in Service,Religion2020,2015,4
Yogyakarta State University,Cooperation2021,2015,1
Yogyakarta State University,Religion2020,2015,6
Yonsei University,Cooperation2021,2015,2
Yonsei University,CooperationAndReligion,2015,1
Yonsei University,Religion2020,2015,34
York College of Pennsylvania,Religion2020,2015,8
York St John University,Cooperation2021,2015,2
York St John University,Religion2020,2015,49
York University,Cooperation2021,2015,8
York University,Religion2020,2015,64
Youngstown State University,Religion2020,2015,26
Yunnan University,Cooperation2021,2015,16
Yunnan University of Finance and Economics,Cooperation2021,2015,9
Zagazig University,Religion2020,2015,5
Zayed University,Religion2020,2015,14
Zaytuna College,Religion2020,2015,5
ZEW - Leibniz Centre for European Economic Research,Cooperation2021,2015,7
Zhejiang Sci-tech University,Cooperation2021,2015,2
Zhejiang Sci-tech University,Religion2020,2015,3
Zhejiang University,Cooperation2021,2015,22
Zhejiang University,Religion2020,2015,2
Zhejiang University of Finance and Economics,Cooperation2021,2015,13
Zhejiang University of Finance and Economics,Religion2020,2015,1
Ziauddin University,Religion2020,2015,4
Zurich University of Applied Sciences,Religion2020,2015,2
 All Souls College,Religion2020,2010,7
 Al-Quds University,Religion2020,2010,2
 Brandenburg Medical School Theodor Fontane,Religion2020,2010,1
 Düsseldorf Institute for Competition,Cooperation2021,2010,1
 École Pratique des Hautes Études,Religion2020,2010,2
 Faraday Institute for Science and Religion,Religion2020,2010,5
 Harvest Bible College,Religion2020,2010,1
 Institute for Public Health and Medicine,Religion2020,2010,11
 Isfahan University of Medical Sciences,Religion2020,2010,5
 Kabale University,Religion2020,2010,11
 Meir Medical Center,Religion2020,2010,3
 Michigan Center for Urban African American Aging Research Community Liaison Core,Religion2020,2010,2
 New Orleans V.A.M.C. & DePaul Hospital,Religion2020,2010,1
 Open Stories Foundation,Religion2020,2010,2
 Pontificia Universidade Católica do Rio de Janeiro,Religion2020,2010,7
 Stuckinski Centre for Alzheimer’s Disease Care and Research,Cooperation2021,2010,1
 University of the District of Columbia,Religion2020,2010,1
 Xi’an Jiaotong University,Cooperation2021,2010,1
 University of Massachusetts Medical School,Religion2020,2010,3
Aahrus University,Religion2020,2010,4
Aalto University School of Science and Technology,Cooperation2021,2010,2
Aarhus University,Cooperation2021,2010,25
Aarhus University,CooperationAndReligion,2010,5
Aarhus University,Religion2020,2010,92
Abbott Northwestern Hospital,Religion2020,2010,1
Aberdeen University,Religion2020,2010,4
Aberystwyth University,Religion2020,2010,8
Abilene Christian University,Religion2020,2010,5
Ables Editing Services,Religion2020,2010,4
abo akademi university,Religion2020,2010,3
Åbo Akademi University,Religion2020,2010,28
Abt Associates,Religion2020,2010,1
"ABVIMS, Dr.R.M.L.Hospital",Religion2020,2010,1
Academia Sinica,Cooperation2021,2010,4
Academia Sinica,Religion2020,2010,1
Academy Ignatianum,Religion2020,2010,2
Academy of Athens,Religion2020,2010,36
Academy of Finland,Religion2020,2010,6
Academy of Russian Medical Sciences,Religion2020,2010,1
Academy of Sciences of Albania,Religion2020,2010,2
Acadia University,Cooperation2021,2010,2
Acadia University,Religion2020,2010,27
Achva Academic College,Religion2020,2010,4
ACT of Living Psychological Services,Cooperation2021,2010,1
ACT of Living Psychological Services,CooperationAndReligion,2010,1
ACT of Living Psychological Services,Religion2020,2010,1
Acton Institute for the Study of Religion and Liberty,Religion2020,2010,8
Addis Ababa University,Religion2020,2010,2
Adelphi University,Cooperation2021,2010,4
Adelphi University,Religion2020,2010,4
Adler University,Religion2020,2010,7
Adnan Menderes University,Religion2020,2010,1
Adventist Health System,Religion2020,2010,2
Africa Institute for Missiology,Religion2020,2010,7
Africa Mental Health Foundation (AMHF),Religion2020,2010,1
African Governance and Development Institute,Cooperation2021,2010,1
African Governance and Development Institute,Religion2020,2010,16
African Growth Institute,Religion2020,2010,3
African Population and Health Research Center,Religion2020,2010,1
Aga Khan University,Cooperation2021,2010,1
Aga Khan University,Religion2020,2010,5
Airlangga University,Religion2020,2010,1
Aix-Marseille Université,Cooperation2021,2010,4
Aix-Marseille Université,Religion2020,2010,23
Akrofi-Christaller Institute for Theology,Religion2020,2010,7
Akron General Medical Center,Religion2020,2010,2
Al Akhawayn University,Religion2020,2010,4
Al al-Bayt University,Religion2020,2010,2
Alabama Department of Corrections,Religion2020,2010,2
Albany Law School,Religion2020,2010,3
Albert Einstein College of Medicine,Cooperation2021,2010,4
Albert Einstein College of Medicine,Religion2020,2010,16
Alberta Health Services,Religion2020,2010,6
Albion College,Religion2020,2010,3
Alfaisal University,Cooperation2021,2010,1
Alfaisal University,Religion2020,2010,8
Alfred University,Religion2020,2010,2
Alfred Wegener Institute Helmholtz center for polar and marine research,Cooperation2021,2010,6
Alkermes,Religion2020,2010,3
All India Institute of Medical Sciences,Cooperation2021,2010,2
All India Institute of Medical Sciences,CooperationAndReligion,2010,1
All India Institute of Medical Sciences,Religion2020,2010,13
All India Institute of Medical Sciences - New Delhi,Religion2020,2010,4
All Souls College,Religion2020,2010,7
Allamaeh Tabataba'i University,Cooperation2021,2010,2
Allamaeh Tabataba'i University,CooperationAndReligion,2010,1
Allamaeh Tabataba'i University,Religion2020,2010,13
Allegheny College,Religion2020,2010,2
Alliant International University,Religion2020,2010,3
Alma College,Religion2020,2010,3
Alphacrucis College,Religion2020,2010,6
Alta Bates Summit Medical Center,Religion2020,2010,1
Alzheimer's Association,Religion2020,2010,6
Amazon,Cooperation2021,2010,5
Ambrose University,Religion2020,2010,6
american academy of neurology,Religion2020,2010,1
American Academy of Nursing,Religion2020,2010,8
American Cancer Society,Religion2020,2010,1
American College of Education,Cooperation2021,2010,1
American College of Education,Religion2020,2010,8
American College Personnel Association,Religion2020,2010,5
American Council on Education,Religion2020,2010,2
American Geographical Society,Religion2020,2010,1
American Institutes for Research,Religion2020,2010,1
American Museum of Natural History,Religion2020,2010,3
American Psychiatric Association,Religion2020,2010,7
American Psychological Association,Religion2020,2010,3
American University,Cooperation2021,2010,3
American University,CooperationAndReligion,2010,1
American University,Religion2020,2010,27
American University of Beirut,Cooperation2021,2010,1
American University of Beirut,Religion2020,2010,8
"American University of Iraq, Sulaimani",Religion2020,2010,5
American University of Kuwait,Religion2020,2010,3
American University of Sharjah,Religion2020,2010,4
American University of Sovereign Nations,Cooperation2021,2010,1
Amherst College,Religion2020,2010,6
Amsterdam UMC,Religion2020,2010,10
Analytical Centre of the Russian Academy of Sciences,Religion2020,2010,2
Andhra University,Religion2020,2010,1
Andover Newton Theological School,Religion2020,2010,3
Andrews University,Religion2020,2010,3
Anglia Ruskin University,Religion2020,2010,18
Anglo American University,Religion2020,2010,9
Anhui University of Technology,Cooperation2021,2010,10
Anscombe Bioethics Centre,Religion2020,2010,1
Antares Foundation,Cooperation2021,2010,1
Antares Foundation,Religion2020,2010,4
Anthony Nolan Research Institute,Cooperation2021,2010,3
Anthony Nolan Research Institute,Religion2020,2010,1
Antwerp University,Cooperation2021,2010,1
Antwerp University,Religion2020,2010,1
Aoyama Gakuin University,Cooperation2021,2010,10
Apex School of Theology,Religion2020,2010,1
Appalachian State University,Cooperation2021,2010,8
Archdiocese of Johannesburg,Religion2020,2010,5
"Archeological & Environmental Consultants, LLC",Religion2020,2010,3
Arctic University of Norway,Cooperation2021,2010,1
Arctic University of Norway,Religion2020,2010,4
Arden University,Cooperation2021,2010,1
Arden University,Religion2020,2010,2
Argentine Catholic University,Religion2020,2010,3
Argonne National Laboratory,Cooperation2021,2010,3
Argosy University,Religion2020,2010,13
Ariel University,Cooperation2021,2010,1
Ariel University,Religion2020,2010,28
Aristotle University of Thessaloniki,Religion2020,2010,51
Arizona State University,Cooperation2021,2010,55
Arizona State University,CooperationAndReligion,2010,3
Arizona State University,Religion2020,2010,165
ARK,Religion2020,2010,1
Arkansas State University,Religion2020,2010,6
ARTIS International,Cooperation2021,2010,6
ARTIS International,CooperationAndReligion,2010,3
ARTIS International,Religion2020,2010,7
Artisan Clinical Associates,Religion2020,2010,3
Asahikawa Medical University,Cooperation2021,2010,2
Asahikawa Medical University,Religion2020,2010,1
Asbury Theological Seminary,Religion2020,2010,22
Ascension Health,Religion2020,2010,3
Ashkelon Academic College,Religion2020,2010,4
Ashland University,Religion2020,2010,4
Ashoka University,Cooperation2021,2010,1
Ashoka University,Religion2020,2010,1
Asia Pacific University of Technology and Innovation,Religion2020,2010,1
Asia University,Cooperation2021,2010,6
Asia University,Religion2020,2010,2
ASM Affiliates Inc,Religion2020,2010,1
Associacao Medico-Espirita de Sao Paulo,Religion2020,2010,1
Association of Improvement for Mental Health Programmes,Cooperation2021,2010,1
Association of Religion Data Archives,Religion2020,2010,3
Aston University,Religion2020,2010,13
Ateneo de Manila University,Religion2020,2010,4
Atlantic School of Theology,Religion2020,2010,3
Atrium Health,Religion2020,2010,10
Auburn University,Religion2020,2010,25
Auburn University at Montgomery,Religion2020,2010,10
Auckland University of Technology,Religion2020,2010,17
Augusta University,Cooperation2021,2010,2
Augusta University,Religion2020,2010,19
Augustana College,Religion2020,2010,2
Augustana University,Religion2020,2010,8
Austin Presbyterian Theological Seminary,Cooperation2021,2010,3
Austin Presbyterian Theological Seminary,Religion2020,2010,24
Australia Theological Hall,Religion2020,2010,1
Australian Catholic University,Cooperation2021,2010,6
Australian Catholic University,CooperationAndReligion,2010,3
Australian Catholic University,Religion2020,2010,139
Australian College of Applied Psychology,Religion2020,2010,1
Australian Council for Educational Research,Religion2020,2010,2
Australian National University,Cooperation2021,2010,16
Australian National University,Religion2020,2010,29
Australian Research Center for Complementary & Integrative Medicine [ARCCIM],Religion2020,2010,6
Austrian Academy of Sciences,Religion2020,2010,2
Autonomous University of Barcelona,Religion2020,2010,11
Ave Maria University,Religion2020,2010,2
Awake to Wisdom Centre for Meditation and Mindfulness Research,Religion2020,2010,11
Ayurvedic Medical College,Religion2020,2010,2
Ayushman College,Religion2020,2010,2
Azabu University,Cooperation2021,2010,5
Azienda Unita Sanitaria Modena,Religion2020,2010,6
Azim Premji University,Religion2020,2010,3
Azusa Pacific University,Religion2020,2010,17
B.G. Rickhi Professional Corp.,Religion2020,2010,2
Bahiana School of Medicine and Public Health,Religion2020,2010,1
Ball State University,Religion2020,2010,6
Banaras Hindu University,Religion2020,2010,1
Bangkok University,Cooperation2021,2010,1
Bangkok University,Religion2020,2010,1
Bangor University,Religion2020,2010,13
Baptist Seminary of Kentucky,Religion2020,2010,4
Baptist Union of Victoria,Religion2020,2010,12
Baqiyatallah University of Medical Sciences,Religion2020,2010,8
Bar Ilan University,Cooperation2021,2010,8
Bar Ilan University,Religion2020,2010,48
Bard College,Religion2020,2010,9
Bar-Ilan University,Cooperation2021,2010,7
Bar-Ilan University,CooperationAndReligion,2010,2
Bar-Ilan University,Religion2020,2010,86
Barnard College,Religion2020,2010,18
Barre Center for Buddhist Studies,Religion2020,2010,1
Barretos Cancer Hospital,Religion2020,2010,3
Barrington College,Religion2020,2010,1
Barrow Neurological Institute,Religion2020,2010,6
Barry University,Religion2020,2010,1
Barts Cancer Center,Cooperation2021,2010,1
Barts Cancer Center,CooperationAndReligion,2010,1
Barts Cancer Center,Religion2020,2010,3
Bashkir State University,Religion2020,2010,14
Basic Health International,Cooperation2021,2010,2
Basic Health International,CooperationAndReligion,2010,2
Basic Health International,Religion2020,2010,2
Bassini Hospital,Religion2020,2010,1
Bastyr University,Religion2020,2010,3
Bath Spa University,Religion2020,2010,10
Bauer College of Business,Religion2020,2010,2
Baylor College of Medicine,Cooperation2021,2010,3
Baylor College of Medicine,Religion2020,2010,44
Baylor University,Cooperation2021,2010,13
Baylor University,Religion2020,2010,148
BC Centre for Palliative Care,Religion2020,2010,8
BE Works,Religion2020,2010,2
Beckman Research Institute of City of Hope,Religion2020,2010,8
Behavioral Health of the Palm Beaches,Religion2020,2010,1
Beihang University,Cooperation2021,2010,10
Beijing Foreign Studies University,Cooperation2021,2010,1
"Beijing Hospital of Traditional Medicine, Capital Medical Univeristy",Cooperation2021,2010,1
Beijing Institute of Technology,Cooperation2021,2010,3
Beijing Municipal Institute of Labor Protections,Cooperation2021,2010,1
Beijing Normal University,Cooperation2021,2010,25
Beijing Normal University,Religion2020,2010,11
Beijing University of Posts and Telecommunications,Cooperation2021,2010,29
Beijing University of Posts and Telecommunications,Religion2020,2010,2
Bellvitge University Hospital,Cooperation2021,2010,1
Bellvitge University Hospital,Religion2020,2010,3
Belmont College,Religion2020,2010,1
Bemidji State University,Religion2020,2010,2
Ben Gurion University in the Negev,Cooperation2021,2010,2
Ben Gurion University in the Negev,Religion2020,2010,26
Ben-Gurion University of the Negev,Cooperation2021,2010,16
Ben-Gurion University of the Negev,CooperationAndReligion,2010,3
Ben-Gurion University of the Negev,Religion2020,2010,34
Bentley University,Religion2020,2010,3
Berea College,Religion2020,2010,3
Berkshire Publishing Group,Cooperation2021,2010,1
Berlin-Brandenburgischen Akademie der Wissenschaften,Religion2020,2010,21
Bern University of Applied Sciences,Religion2020,2010,7
Beth Israel Deaconess Medical Center,Religion2020,2010,4
Bethany College,Religion2020,2010,4
Bethel University,Religion2020,2010,9
Bethlem Royal Hospital,Religion2020,2010,5
Bexley Seabury Seminary Federation,Religion2020,2010,3
Bharath Hospital and Institute of Oncology,Cooperation2021,2010,1
Bharath Hospital and Institute of Oncology,Religion2020,2010,9
BI Norwegian Business School,Cooperation2021,2010,1
BI Norwegian Business School,Religion2020,2010,1
Bible College of Malaysia,Religion2020,2010,3
biblical-theological academy,Religion2020,2010,4
Bielefeld University,Religion2020,2010,6
Bina Nusantra University,Religion2020,2010,3
Binghampton University,Cooperation2021,2010,7
Biola University,Religion2020,2010,48
Biostructures and Biosystems National Institute,Cooperation2021,2010,2
Birbeck University of London,Cooperation2021,2010,1
Birbeck University of London,Religion2020,2010,13
"Birkbeck, University of London",Religion2020,2010,4
Birla Insitute of Technology and Science,Religion2020,2010,1
Birmingham and Solihull Mental Health NHS Foundation Trust,Cooperation2021,2010,1
Birmingham and Solihull Mental Health NHS Foundation Trust,Religion2020,2010,1
Birmingham Children's Hospital NHS Foundation Trust,Religion2020,2010,7
Birmingham City University,Religion2020,2010,24
Birmingham Southern College,Religion2020,2010,10
Birmingham University,Religion2020,2010,4
Bishop Grosseteste University,Religion2020,2010,21
Bishop Kember School of Ministry,Religion2020,2010,4
Bishop of Guilford,Cooperation2021,2010,2
Bishop of Guilford,Religion2020,2010,2
Bishop's University,Cooperation2021,2010,1
Bishop's University,Religion2020,2010,5
Blanton-Peale Institute,Religion2020,2010,10
Bloomberg LP,Religion2020,2010,1
Bloomsburg University,Religion2020,2010,3
Blueprint 1543,Religion2020,2010,11
Bocconi University,Cooperation2021,2010,2
Boğaziçi University,Religion2020,2010,3
Boise State University,Religion2020,2010,1
Bon Secours Mercy Health,Religion2020,2010,12
Bordeaux Montaigne University,Religion2020,2010,9
Bosch,Cooperation2021,2010,5
Boston College,Cooperation2021,2010,3
Boston College,Religion2020,2010,84
Boston University,Cooperation2021,2010,13
Boston University,CooperationAndReligion,2010,1
Boston University,Religion2020,2010,188
Boston University School of Medicine,Cooperation2021,2010,1
Boston University School of Medicine,Religion2020,2010,8
Bournemouth University,Cooperation2021,2010,1
Bournemouth University,Religion2020,2010,10
Bowdoin College,Religion2020,2010,1
Bowling Green State University,Cooperation2021,2010,4
Bowling Green State University,Religion2020,2010,63
Bradford City Libraries,Religion2020,2010,3
Bradford Institute for Health Research,Cooperation2021,2010,2
Bradley University,Religion2020,2010,2
Brandeis University,Cooperation2021,2010,5
Brandeis University,Religion2020,2010,23
Brawijaya University,Cooperation2021,2010,1
Brawijaya University,CooperationAndReligion,2010,1
Brawijaya University,Religion2020,2010,9
Bridgewater College,Cooperation2021,2010,3
Brigham & Women's Hospital,Cooperation2021,2010,5
Brigham & Women's Hospital,CooperationAndReligion,2010,3
Brigham & Women's Hospital,Religion2020,2010,42
Brigham Young University,Cooperation2021,2010,11
Brigham Young University,CooperationAndReligion,2010,2
Brigham Young University,Religion2020,2010,132
"Bristol Institute of Technology, UWE",Cooperation2021,2010,1
Brite Divinity,Religion2020,2010,5
British Academy,Religion2020,2010,8
British Journal of Psychiatry,Cooperation2021,2010,1
British Journal of Psychiatry,Religion2020,2010,8
British Museum,Religion2020,2010,10
British Psychoanalytic Society,Religion2020,2010,3
Brock University,Religion2020,2010,9
Brookings Institution,Religion2020,2010,3
Brooklyn College,Religion2020,2010,3
Brooklyn University,Religion2020,2010,23
Brown University,Cooperation2021,2010,21
Brown University,Religion2020,2010,92
Bruce Rappaport Faculty of Medicine,Religion2020,2010,6
Brugmann University Hospital,Cooperation2021,2010,3
Brunel University London,Cooperation2021,2010,9
Brunel University London,Religion2020,2010,21
Bryant Temple AME Church,Religion2020,2010,2
Bryn Mawr College,Cooperation2021,2010,2
Bryn Mawr College,Religion2020,2010,2
Buck Institute,Cooperation2021,2010,2
Buckingham University Centre for Security and Intelligence Studies,Cooperation2021,2010,1
Buckingham University Centre for Security and Intelligence Studies,Religion2020,2010,2
Bucknell University,Religion2020,2010,8
Buffalo State College,Cooperation2021,2010,2
Buffalo State College,Religion2020,2010,8
Burgholzli Asylum,Religion2020,2010,1
Burlington College,Religion2020,2010,4
Butler University,Religion2020,2010,12
C.G. Jung Institute of New York,Religion2020,2010,5
C.G. Jung Institute-Zurich,Religion2020,2010,13
Ca' Foscari University,Cooperation2021,2010,1
Ca' Foscari University,Religion2020,2010,1
CableLabs,Cooperation2021,2010,1
CAFM Educational Research Alliance (CERA),Religion2020,2010,2
Cairnmiller institute,Religion2020,2010,8
Californa Institute of Integral Studies  California Institute of Integral Studies,Religion2020,2010,5
California Baptist University,Religion2020,2010,6
California Institute of Integral Studies,Religion2020,2010,1
California Institute of Technology,Cooperation2021,2010,12
California Lutheran University,Cooperation2021,2010,1
California Lutheran University,CooperationAndReligion,2010,1
California Lutheran University,Religion2020,2010,17
California Pacific Medical Center,Religion2020,2010,1
California State Polytechnic University,Religion2020,2010,5
California State Polytechnic University-Pomona,Cooperation2021,2010,2
California State University,Religion2020,2010,7
"California State University, Chico",Religion2020,2010,1
"California State University, Fullerton",Cooperation2021,2010,4
"California State University, Fullerton",CooperationAndReligion,2010,1
"California State University, Fullerton",Religion2020,2010,6
"California State University, Long Beach",Religion2020,2010,12
"California State University, Los Angeles",Religion2020,2010,4
"California State University, Northridge",Religion2020,2010,2
"California State University, Sacramento",Religion2020,2010,10
"California State University, Stanislaus",Religion2020,2010,2
Calvary Health Care,Religion2020,2010,1
Calvin College,Cooperation2021,2010,2
Calvin College,CooperationAndReligion,2010,1
Calvin College,Religion2020,2010,29
Calvin University,Religion2020,2010,23
Cambridge Health Alliance,Religion2020,2010,1
Cambridge University,Cooperation2021,2010,1
Cambridge University,Religion2020,2010,46
Canada Research Chairs,Cooperation2021,2010,1
Canadian Institute of Natural and Integrative Medicine,Religion2020,2010,2
Cancer Institute NSW,Cooperation2021,2010,1
Cancer Institute NSW,Religion2020,2010,5
Cancer Prevention Research Center,Religion2020,2010,5
Cancer Research UK London,Cooperation2021,2010,1
CancerLife,Cooperation2021,2010,2
Canterbury Christ Church University,Religion2020,2010,2
Canterbury Christchurch University,Religion2020,2010,10
Canterbury District Health Board,Religion2020,2010,1
Cape Breton University,Cooperation2021,2010,1
Cape Breton University,Religion2020,2010,2
Cape College of Theology,Religion2020,2010,1
Capital Commission,Religion2020,2010,24
CAPS Methods Core,Religion2020,2010,3
Cardiff University,Cooperation2021,2010,15
Cardiff University,CooperationAndReligion,2010,1
Cardiff University,Religion2020,2010,92
Cardozo School of Law,Religion2020,2010,3
Care Dimensions Inc,Religion2020,2010,1
Carleton College,Cooperation2021,2010,3
Carleton College,Religion2020,2010,14
Carleton University,Cooperation2021,2010,2
Carleton University,Religion2020,2010,9
Carnegie Mellon University,Cooperation2021,2010,20
Carnegie Mellon University,Religion2020,2010,13
Case Western Reserve University,Cooperation2021,2010,10
Case Western Reserve University,Religion2020,2010,67
Catawba College,Religion2020,2010,7
Catholic Health Association,Religion2020,2010,2
Catholic Institute of Paris,Religion2020,2010,2
Catholic Research and Development Centre,Religion2020,2010,5
Catholic Theological Union,Religion2020,2010,3
Catholic University of America,Cooperation2021,2010,6
Catholic University of America,CooperationAndReligion,2010,4
Catholic University of America,Religion2020,2010,57
Catholic University of Eichstätt-Ingolstadt,Religion2020,2010,20
Catholic University of Korea,Religion2020,2010,8
Catholic University of Leuven,Cooperation2021,2010,2
Catholic University of Leuven,Religion2020,2010,26
Catholic University of Louvain,Cooperation2021,2010,7
Catholic University of Louvain,CooperationAndReligion,2010,1
Catholic University of Louvain,Religion2020,2010,26
Catholic University of Milan,Religion2020,2010,5
Catholic University of Pernambuco,Religion2020,2010,7
Catholic University of Portugal,Religion2020,2010,4
Catholic University of Sacred Heart,Religion2020,2010,4
Catholic University of the Sacred Heart,Cooperation2021,2010,7
Catholic University of the Sacred Heart,Religion2020,2010,4
Cedars-Sinai Medical Center,Cooperation2021,2010,3
Cedars-Sinai Medical Center,CooperationAndReligion,2010,3
Cedars-Sinai Medical Center,Religion2020,2010,3
Cedarville University,Religion2020,2010,3
Cégep Édouard-Montpetit,Religion2020,2010,2
Cell-El Therapeutics LTD,Religion2020,2010,3
Center for Adolescent Studies,Religion2020,2010,3
Center for Advanced Studies Oslo,Religion2020,2010,3
Center for Change,Religion2020,2010,10
Center for Critical Research on Religion,Religion2020,2010,6
"Center for Health Education, Medicine, & Dentistry",Religion2020,2010,19
"Center for Holistic Systems Analysis in Fairfield, Iowa",Religion2020,2010,1
Center for Inside-Out Understanding,Religion2020,2010,1
Center for Interfaith Reconciliation,Religion2020,2010,2
Center for Marketing and Consumer Science,Cooperation2021,2010,2
Center for Mind and Culture,Religion2020,2010,21
Center for Open Science,Cooperation2021,2010,1
Center for Open Science,CooperationAndReligion,2010,1
Center for Open Science,Religion2020,2010,2
Center for Optimal Living,Religion2020,2010,9
Center For Partnership Studies,Religion2020,2010,1
Center for Professional Studies,Religion2020,2010,8
Center for Psychobiology of Personality,Cooperation2021,2010,3
Center for Psychobiology of Personality,Religion2020,2010,2
Center for Psychosocial Research,Religion2020,2010,19
Center for Religion and Contemporary Society,Religion2020,2010,7
Center for Research and Advanced Studies of the National Polytechnic Institute,Cooperation2021,2010,3
Center for Research and Higher Studies in Social Anthropology,Religion2020,2010,6
Center for Research and Post Graduate Studies in Social Anthropology,Cooperation2021,2010,2
Center for Research and Post Graduate Studies in Social Anthropology,CooperationAndReligion,2010,2
Center for Research and Post Graduate Studies in Social Anthropology,Religion2020,2010,8
Center for Scientific Research,Cooperation2021,2010,4
Center for Scientific Research,Religion2020,2010,2
Center for Southeast Asia Studies,Religion2020,2010,3
Center for Studies on New Religions,Religion2020,2010,4
Center for Taiji Studies,Cooperation2021,2010,6
Center for Taiji Studies,Religion2020,2010,1
Center for the Study on New Religions,Religion2020,2010,3
Center of Innovation for Complex Chronic Healthcare,Religion2020,2010,3
Center to Advance Palliative Care,Religion2020,2010,5
Centered Living LLC,Religion2020,2010,1
Centers for Disease Control and Prevention,Religion2020,2010,5
Central Baptist Theological Seminary,Religion2020,2010,2
Central European University,Cooperation2021,2010,3
Central European University,Religion2020,2010,1
Central Michigan University,Cooperation2021,2010,1
Central Michigan University,CooperationAndReligion,2010,1
Central Michigan University,Religion2020,2010,9
Central Queensland University,Cooperation2021,2010,3
Central Queensland University,CooperationAndReligion,2010,1
Central Queensland University,Religion2020,2010,3
Central Research Institute for Physics,Cooperation2021,2010,10
Central South University,Cooperation2021,2010,2
Central University of Finance and Economics,Cooperation2021,2010,2
Central University of Finance and Economics,Religion2020,2010,1
Central University of Kerala,Religion2020,2010,3
CentraleSupelec,Cooperation2021,2010,5
Centre Alexandre Koyre,Religion2020,2010,14
Centre d'Ecologie Fonctionnelle et Evolutive,Cooperation2021,2010,3
Centre for Addiction and Mental Health,Cooperation2021,2010,10
Centre for Addiction and Mental Health,Religion2020,2010,7
Centre for Cellular & Molecular Biology,Religion2020,2010,2
Centre for Classical and Near Eastern Studies of Australia,Religion2020,2010,3
Centre for European Economic Research,Cooperation2021,2010,2
Centre for Research and Evidence on Security Threats,Religion2020,2010,4
Centre for Research and Professional Learning- University of Exeter,Religion2020,2010,8
Centre for Social and Behavior Change,Cooperation2021,2010,2
Centre for the Study of Developing Societies,Religion2020,2010,7
Centre Hospitalier Universitaire Vaudois,Cooperation2021,2010,2
Centre Hospitalier Universitaire Vaudois,CooperationAndReligion,2010,1
Centre Hospitalier Universitaire Vaudois,Religion2020,2010,13
Centre National de la Recherche Scientifique,Cooperation2021,2010,4
Centre National de la Recherche Scientifique,Religion2020,2010,24
Centre of Biomedical Research,Religion2020,2010,2
Centro Clinico NEMO,Religion2020,2010,2
Centro de Investigaciones Medicas Quirurgicas,Cooperation2021,2010,6
Centro de Investigaciones Medicas Quirurgicas,CooperationAndReligion,2010,4
Centro de Investigaciones Medicas Quirurgicas,Religion2020,2010,6
Centro di Riferimento Oncologico della Basilicata,Cooperation2021,2010,2
Centro Universitário Franciscano,Religion2020,2010,1
Centro Universitário São Camilo,Religion2020,2010,1
CERGE-E,Cooperation2021,2010,4
Cervera Real Estate,Religion2020,2010,6
CHA University,Religion2020,2010,1
Chalmers University of Technology,Cooperation2021,2010,1
Chamberlain University,Religion2020,2010,2
Chang Gung Medical Foundation,Religion2020,2010,1
Chang Gung Memorial Hospital at Linkou,Cooperation2021,2010,2
Chang Gung Memorial Hospital at Linkou,Religion2020,2010,3
Chang Gung University,Cooperation2021,2010,3
Chang Gung University,Religion2020,2010,8
Changwon National University,Cooperation2021,2010,1
Changwon National University,Religion2020,2010,8
Chapman University,Cooperation2021,2010,14
Chapman University,Religion2020,2010,14
Charite Universitatsmedizin Berlin,Religion2020,2010,1
Charité Universitätsmedizin Berlin,Cooperation2021,2010,1
Charité Universitätsmedizin Berlin,Religion2020,2010,12
Charles R. Drew University of Medicine & Science,Cooperation2021,2010,1
Charles R. Drew University of Medicine & Science,CooperationAndReligion,2010,1
Charles R. Drew University of Medicine & Science,Religion2020,2010,10
Charles Sturt University,Cooperation2021,2010,1
Charles Sturt University,Religion2020,2010,35
Charles University,Religion2020,2010,9
Charles University in Prague,Cooperation2021,2010,1
Charles University in Prague,Religion2020,2010,5
Chatham University,Religion2020,2010,3
Chelsea Art School,Religion2020,2010,3
Chemnitz University of Technology,Religion2020,2010,2
Chennai School of Music Therapy,Religion2020,2010,1
Cheongju University,Cooperation2021,2010,1
Cheongju University,Religion2020,2010,4
Cherry Hill Seminary,Religion2020,2010,4
Cheung Kong Graduate School of Business,Cooperation2021,2010,13
Cheung Kong Graduate School of Business,Religion2020,2010,1
Chiang Mai University,Cooperation2021,2010,2
Chiang Mai University,Religion2020,2010,4
Chiba University,Religion2020,2010,1
Chicago Center for Family Health,Religion2020,2010,2
Chicago Medical School,Religion2020,2010,1
Chicago Theological Seminary,Religion2020,2010,1
CHILD USA,Religion2020,2010,3
Children’s National Medical Center,Religion2020,2010,9
Children's Diagnostic & Treatment Ctr,Religion2020,2010,2
Children's Hospital Alabama,Religion2020,2010,3
Children's Hospital Boston,Religion2020,2010,11
Children's Hospital of Philadelphia,Cooperation2021,2010,4
Children's Hospital of Philadelphia,Religion2020,2010,2
Children's Investment Fund Foundation,Religion2020,2010,1
Children's National Medical Center,Religion2020,2010,1
Children's Wisconsin,Religion2020,2010,2
China Medical University,Cooperation2021,2010,1
China Medical University,Religion2020,2010,1
China University of Mining and Technology,Cooperation2021,2010,15
China University of Mining and Technology,Religion2020,2010,1
China University of Political Science and Law,Religion2020,2010,11
Chinese Academy of Sciences,Cooperation2021,2010,40
Chinese Academy of Sciences,Religion2020,2010,5
Chinese Academy of Social Sciences,Religion2020,2010,1
Chinese University of Hong Kong,Cooperation2021,2010,8
Chinese University of Hong Kong,Religion2020,2010,42
Chongqin University,Cooperation2021,2010,13
Chongqin University,Religion2020,2010,3
Chongqing Institute of Green and Intelligent Technology,Cooperation2021,2010,1
Christ Church College,Religion2020,2010,3
Christian Brothers University,Religion2020,2010,2
Christian Council on Ageing,Religion2020,2010,2
Christian Michelsen Institute,Religion2020,2010,2
Christian Psychological Services,Religion2020,2010,8
Christian Service University College,Cooperation2021,2010,1
Christopher Newport University,Religion2020,2010,12
Chulalongkorn University,Cooperation2021,2010,1
Chung Shan Medical University,Religion2020,2010,2
Chungbuk National University,Religion2020,2010,1
Church Mission Society,Religion2020,2010,6
Church of England,Cooperation2021,2010,2
Church of England,Religion2020,2010,38
Church of Ireland Theological College,Religion2020,2010,4
Church of Scotland,Religion2020,2010,3
Church of Sweden,Religion2020,2010,1
Church Research Institute,Religion2020,2010,3
CICERO Center for International Climate Research,Cooperation2021,2010,2
Cincinnati Children's Hospital Medical Center,Cooperation2021,2010,2
Cincinnati Children's Hospital Medical Center,Religion2020,2010,11
CIRANO,Cooperation2021,2010,1
Cisco,Religion2020,2010,6
Citrus College,Religion2020,2010,14
City College of New York- Brooklyn College,Religion2020,2010,7
City College of New York Hunter College,Religion2020,2010,4
City of Hope National Medical Center,Cooperation2021,2010,2
City of Hope National Medical Center,Religion2020,2010,20
City University London,Cooperation2021,2010,2
City University London,Religion2020,2010,4
City University of Hong Kong,Cooperation2021,2010,7
City University of Hong Kong,Religion2020,2010,15
City University of New York,Cooperation2021,2010,3
City University of New York,Religion2020,2010,44
City University of New York Graduate Center,Religion2020,2010,7
Claremont Graduate University,Cooperation2021,2010,9
Claremont Graduate University,Religion2020,2010,61
Claremont McKenna College,Religion2020,2010,6
Claremont school of theology,Religion2020,2010,5
Clarian Health Partners,Religion2020,2010,1
Clark University,Religion2020,2010,1
Claude Bernard University Lyon 1,Religion2020,2010,12
Clemson University,Cooperation2021,2010,8
Clemson University,Religion2020,2010,45
Cleveland Clinic,Cooperation2021,2010,3
Cleveland Clinic,CooperationAndReligion,2010,2
Cleveland Clinic,Religion2020,2010,2
Cleveland State University,Religion2020,2010,2
Cliff College,Cooperation2021,2010,1
Cliff College,Religion2020,2010,4
Clinic for Senology,Cooperation2021,2010,1
Clinic SGM Langenthal and Research Institute for Spirituality and Health,Religion2020,2010,7
Clinica Daniel Lerario,Religion2020,2010,1
Clinical Psychologist,Religion2020,2010,6
Clinics Essen - Mitte,Religion2020,2010,7
Club of Budapest,Religion2020,2010,1
CMTF UP in Olomouc,Religion2020,2010,4
CNRS Comité national de la recherche scientifique,Cooperation2021,2010,4
CNRS Comité national de la recherche scientifique,Religion2020,2010,9
CNRS Délégation Rhône-Auvergne,Cooperation2021,2010,1
Coalition for Compassionate Care of California,Religion2020,2010,13
Coe College,Religion2020,2010,3
Colby College,Religion2020,2010,3
Colgate University,Religion2020,2010,6
Collaborative Caring,Religion2020,2010,13
Collège de France,Religion2020,2010,17
College of Charleston,Religion2020,2010,5
College of Charlestown,Religion2020,2010,11
College of Communication Engineering,Cooperation2021,2010,4
College of Dentistry,Religion2020,2010,2
College of France,Religion2020,2010,5
College of Holy Cross,Religion2020,2010,8
College of Idaho,Religion2020,2010,2
College of St. Rose,Religion2020,2010,1
College of St. Scholastica,Religion2020,2010,1
College of the Canyons,Religion2020,2010,14
College of the Holy Cross,Religion2020,2010,9
College of Wooster,Religion2020,2010,4
Collegiate Church of st Mary,Religion2020,2010,2
Collegium Helveticum,Religion2020,2010,5
Colorado School of Mines,Religion2020,2010,1
Colorado State University,Cooperation2021,2010,4
Colorado State University,Religion2020,2010,26
Columbia Theological Seminary,Religion2020,2010,9
Columbia University,Cooperation2021,2010,27
Columbia University,CooperationAndReligion,2010,2
Columbia University,Religion2020,2010,185
Columbia University Medical Center,Religion2020,2010,4
Columbus State University,Religion2020,2010,2
Coma Science Group,Cooperation2021,2010,1
Coma Science Group,Religion2020,2010,2
Comenius Institute,Religion2020,2010,8
Community Care Western Cheshire,Religion2020,2010,2
Community Health Cooperative,Religion2020,2010,2
Compass Health Research Institute,Religion2020,2010,1
"Complejo Hospitalario Universitario de Badajoz, Badajoz",Religion2020,2010,1
Complutense University of Madrid,Cooperation2021,2010,16
Complutense University of Madrid,CooperationAndReligion,2010,1
Complutense University of Madrid,Religion2020,2010,10
Conception Seminary College,Religion2020,2010,1
Concordia College,Religion2020,2010,8
Concordia Seminary,Religion2020,2010,9
Concordia University,Cooperation2021,2010,5
Concordia University,Religion2020,2010,58
Concordia University Chicago,Religion2020,2010,5
Concordia University Irvine,Religion2020,2010,1
Connecticut College,Religion2020,2010,3
Consejo Nacional de Investigaciones Cientificas y Tecnicas,Religion2020,2010,1
Constantine the Philosopher University,Religion2020,2010,19
Coordination of Improvement of Higher Education Personnel,Religion2020,2010,4
Copenhagen Business School,Religion2020,2010,6
Cornell University,Cooperation2021,2010,33
Cornell University,Religion2020,2010,86
Cornerstone of Recovery,Religion2020,2010,1
Corporate Health Solutions,Religion2020,2010,3
Corvinus University of Budapest,Cooperation2021,2010,5
Corvinus University of Budapest,Religion2020,2010,3
Cosmos & Creation,Religion2020,2010,3
Council of European Churches,Religion2020,2010,2
Council on Spiritual Practices,Religion2020,2010,1
Coventry University,Cooperation2021,2010,1
Coventry University,Religion2020,2010,26
"Cox Family Medicine Associates, Springfield",Religion2020,2010,1
Crandall University,Religion2020,2010,3
Cranfield University,Religion2020,2010,3
Creative Computing Solutions,Religion2020,2010,2
Creighton University,Religion2020,2010,11
CRI,Cooperation2021,2010,3
CSIRO,Cooperation2021,2010,3
CSIRO,Religion2020,2010,2
Cuban Academy of Sciences,Cooperation2021,2010,2
Cuban Academy of Sciences,CooperationAndReligion,2010,1
Cuban Academy of Sciences,Religion2020,2010,2
CUNY,Cooperation2021,2010,1
CUNY,CooperationAndReligion,2010,1
CUNY,Religion2020,2010,53
CUNY Graduate Center,Religion2020,2010,4
"CUNY, Baruch College",Religion2020,2010,3
"CUNY, Hunter College",Religion2020,2010,4
Curtin University,Cooperation2021,2010,2
Curtin University,CooperationAndReligion,2010,2
Curtin University,Religion2020,2010,6
Curtin University of Technology,Cooperation2021,2010,1
Curtin University of Technology,Religion2020,2010,2
CWI Amsterdam,Cooperation2021,2010,11
Czech Academy of Sciences,Religion2020,2010,16
Daegu University,Religion2020,2010,3
Dalarna University,Religion2020,2010,3
Dalhousie University,Cooperation2021,2010,1
Dalhousie University,Religion2020,2010,39
Dalian University of Technology,Cooperation2021,2010,9
Dalian University of Technology,Religion2020,2010,3
Dana-Farber Cancer Institute,Cooperation2021,2010,5
Dana-Farber Cancer Institute,Religion2020,2010,24
Danish Cancer Society Research Center,Religion2020,2010,4
Danish Institute for Human Rights,Religion2020,2010,7
Danish Institute for International Studies,Religion2020,2010,3
Danish National Stem Cell Center,Cooperation2021,2010,2
Dartmouth College,Cooperation2021,2010,2
Dartmouth College,Religion2020,2010,20
David and Carol Myers Foundation,Cooperation2021,2010,1
David and Carol Myers Foundation,Religion2020,2010,2
David Yellin College of Education,Religion2020,2010,5
Davidson College,Religion2020,2010,3
Daya Counselling Centre,Religion2020,2010,1
De La Salle University,Religion2020,2010,3
De Montfort University,Cooperation2021,2010,3
De Montfort University,Religion2020,2010,6
Deakin University,Cooperation2021,2010,21
Deakin University,Religion2020,2010,76
DeepMind,Cooperation2021,2010,4
Defense Centers of Excellence for Psychological Health,Religion2020,2010,2
Defense Institute of Physiology and Allied Sciences,Religion2020,2010,3
Delft University of Technology,Cooperation2021,2010,36
Delft University of Technology,Religion2020,2010,5
Delhi University,Religion2020,2010,2
DeltaQuest Foundation,Religion2020,2010,1
Denali Therapeutics,Cooperation2021,2010,3
Denison University,Religion2020,2010,13
Department of Religious Affairs of the Ministry of the Interior,Religion2020,2010,4
Department of Veterans Affairs,Religion2020,2010,1
DePaul University,Religion2020,2010,21
DePauw University,Religion2020,2010,4
Dharma Drum Institute of Liberal Arts,Religion2020,2010,1
Diakonhjemmet Hospital,Religion2020,2010,1
Diakonova University College,Religion2020,2010,3
Divine Mercy University,Religion2020,2010,4
Division for Applied Social Psychology Research,Religion2020,2010,1
Divya Yog Mandir Trust,Religion2020,2010,5
Doha Institute for Graduate Studies,Religion2020,2010,11
Dominican School of Philosophy and Theology,Religion2020,2010,6
Dominican University of California,Religion2020,2010,5
Dongbei University of Finance and Economics,Religion2020,2010,1
Dongguan University of Technology,Cooperation2021,2010,6
Dongguk University,Religion2020,2010,5
Dongseo University,Religion2020,2010,4
"Douglas Mental Health University Institute, DMHUI",Religion2020,2010,4
Downing College,Religion2020,2010,4
Dr Kerris Clinical & Counselling Psychology Practice,Religion2020,2010,1
Dr Williams's Library,Religion2020,2010,2
Dr. Barlow Counselling,Religion2020,2010,2
Dr. Justin Jones (Licensed Clinical Psychologist),Religion2020,2010,5
Drake University,Religion2020,2010,7
Draper Laboratory,Cooperation2021,2010,2
Dresden University of Technology,Cooperation2021,2010,1
Dresden University of Technology,CooperationAndReligion,2010,1
Dresden University of Technology,Religion2020,2010,11
Drew University,Religion2020,2010,13
Drexel University,Cooperation2021,2010,6
Drexel University,Religion2020,2010,5
Dublin City University,Religion2020,2010,13
Duke Divinity School,Religion2020,2010,33
Duke Global Health Institute,Religion2020,2010,6
Duke Kunshan University,Religion2020,2010,6
Duke University,Cooperation2021,2010,41
Duke University,CooperationAndReligion,2010,4
Duke University,Religion2020,2010,218
Duke University Medical Center,Religion2020,2010,3
Duksung Women's University,Religion2020,2010,6
Duquesne University,Cooperation2021,2010,2
Duquesne University,Religion2020,2010,41
Durban University of Technology,Religion2020,2010,8
Durham University,Cooperation2021,2010,14
Durham University,Religion2020,2010,131
Durham Veterans Affairs Medical Center,Religion2020,2010,2
Duta Wacana University,Religion2020,2010,1
"DynaMed, EBSCO Health",Religion2020,2010,1
EAPC Primary Care Reference Group,Religion2020,2010,2
EAPC Spiritual Care Reference Group,Religion2020,2010,2
East Carolina University,Cooperation2021,2010,1
East Carolina University,Religion2020,2010,9
East China Normal University,Cooperation2021,2010,19
East China Normal University,Religion2020,2010,3
East China University of Science and Technology,Cooperation2021,2010,7
East China University of Science and Technology,Religion2020,2010,2
East Tennessee State University,Religion2020,2010,20
Eastern Connecticut University,Religion2020,2010,1
Eastern Institute of Technology,Religion2020,2010,2
Eastern Kentucky University,Religion2020,2010,4
Eastern Mennonite University,Cooperation2021,2010,1
Eastern Mennonite University,Religion2020,2010,4
Eastern Michigan University,Cooperation2021,2010,2
Eastern University,Religion2020,2010,16
E-Bus Competence Center,Cooperation2021,2010,11
Ecclesiastical University of Education Vienna,Religion2020,2010,2
Eckerd College,Religion2020,2010,14
Ecole des Hautes Etudes,Religion2020,2010,10
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,2010,12
École des Hautes Etudes en Sciences Sociales,Religion2020,2010,3
Ecole Francaise D'Extreme-Orient,Religion2020,2010,4
Ecole Normale Supérieure,Religion2020,2010,3
École Normale Supérieure,Cooperation2021,2010,13
École Normale Supérieure,CooperationAndReligion,2010,1
École Normale Supérieure,Religion2020,2010,3
Ecole Normale Supérieure de Saint-Cloud,Religion2020,2010,10
Ecole Polytechnique,Cooperation2021,2010,7
Ecole Polytechnique Fédérale de Lausanne,Cooperation2021,2010,4
Ecole Pratique des Hautes Etudes,Religion2020,2010,63
École Pratique des Hautes Etudes,Religion2020,2010,15
École pratique des hautes études,Cooperation2021,2010,1
École pratique des hautes études,Religion2020,2010,8
Economic and Social Research Institute,Cooperation2021,2010,2
Economic and Social Research Institute,Religion2020,2010,7
Ecumenical Institute,Religion2020,2010,12
Ecumenical Theological Seminary,Religion2020,2010,3
Edge Hill University,Cooperation2021,2010,1
Edge Hill University,Religion2020,2010,13
Edgewalkers International,Religion2020,2010,17
Edinburgh Napier University,Cooperation2021,2010,7
Edinburgh Napier University,Religion2020,2010,7
Edinburgh University,Religion2020,2010,3
Edith Cowan University,Religion2020,2010,1
Eindhoven University of Technology,Cooperation2021,2010,3
"El Colegio de México, AC",Religion2020,2010,1
ELCA Informatique SA / ELCA Informatik AG,Cooperation2021,2010,2
Elements Behavioral Health,Religion2020,2010,1
Eliot Hospital,Religion2020,2010,5
Elizabethtown College,Religion2020,2010,3
Ellis Effect Psychological Group,Religion2020,2010,2
Emanuel University of Oradea,Religion2020,2010,1
Embry-Riddle Aeronautical University,Religion2020,2010,11
Emerson College,Religion2020,2010,3
eMindful Inc,Religion2020,2010,2
Emmanuel College,Cooperation2021,2010,1
Emmanuel College,CooperationAndReligion,2010,1
Emmanuel College,Religion2020,2010,21
"Emmy-Noether Junior Research Group, University of Mannheim",Religion2020,2010,7
Emory College,Religion2020,2010,2
Emory University,Cooperation2021,2010,47
Emory University,CooperationAndReligion,2010,4
Emory University,Religion2020,2010,169
Empire State College,Religion2020,2010,4
Employment Assistance Program,Religion2020,2010,2
Engender Health,Religion2020,2010,2
Environmental Health Agency,Cooperation2021,2010,2
Environmental Health Agency,CooperationAndReligion,2010,2
Environmental Health Agency,Religion2020,2010,2
Eotvos Lorand University,Cooperation2021,2010,3
Eötvös Loránd University,Cooperation2021,2010,11
Episcopal Divinity School,Religion2020,2010,2
Epsilon Consultants,Religion2020,2010,2
Equinox Publishing Ltd,Religion2020,2010,2
Erasmus University Rotterdam,Cooperation2021,2010,16
Erasmus University Rotterdam,Religion2020,2010,23
Erin Presbyterian Church,Religion2020,2010,2
Esprit Journal,Religion2020,2010,3
ESSEC Business School,Cooperation2021,2010,4
essex county college,Religion2020,2010,2
Esslingen University of Applied Sciences,Religion2020,2010,3
ETH Zurich,Cooperation2021,2010,44
ETH Zurich,Religion2020,2010,1
Eubios Ethics Institute,Cooperation2021,2010,1
European Commission,Cooperation2021,2010,7
European Commission,Religion2020,2010,10
European Institute of Design,Religion2020,2010,17
European Institute of Florence,Religion2020,2010,14
European Institute of Oncology,Cooperation2021,2010,1
European School of Molecular Medicine,Cooperation2021,2010,1
European University Institute,Cooperation2021,2010,4
European University Institute,Religion2020,2010,9
European University Viadrina,Cooperation2021,2010,3
European University Viadrina,Religion2020,2010,10
European Xtramile Centre of African Studies (EXCAS),Cooperation2021,2010,1
European Xtramile Centre of African Studies (EXCAS),Religion2020,2010,13
Evangel University,Religion2020,2010,1
Evangelical Central Office for Weltanschauung,Religion2020,2010,1
Evangelical Lutheran Church in Bavaria,Religion2020,2010,4
Evangelische Studiengemeinschaft eV,Religion2020,2010,5
Evangelische Theologische Faculteit,Religion2020,2010,3
Evesham Methodist Church,Religion2020,2010,1
Ewha Women's University,Cooperation2021,2010,3
Ewha Women's University,Religion2020,2010,4
Excelsia College,Religion2020,2010,15
Extreme Wellness Center,Religion2020,2010,4
Facebook,Cooperation2021,2010,10
Facebook,Religion2020,2010,9
Faculdade de Medicina da USP,Religion2020,2010,1
Faculty of Medicine of Itajubá,Religion2020,2010,1
Fairfield University,Religion2020,2010,60
Fairleigh Dickinson University,Religion2020,2010,7
Fakultas Dakwah IAIN Purwokerto,Religion2020,2010,1
Fakultas Humaniora UIN Maulana Malik Ibrahim Malang,Religion2020,2010,5
Family First Psychotherapy,Religion2020,2010,3
Family Youth Institute,Religion2020,2010,6
Faulkner University,Religion2020,2010,6
Fayetteville State University,Religion2020,2010,15
Federal University for Public Administration - HS Bund,Religion2020,2010,10
Federal University of Alfenas,Religion2020,2010,3
Federal University of Bahia,Religion2020,2010,2
Federal University of Juiz de Fora,Cooperation2021,2010,1
Federal University of Juiz de Fora,CooperationAndReligion,2010,1
Federal University of Juiz de Fora,Religion2020,2010,52
Federal University of Pernambuco,Religion2020,2010,2
Federal University of Rio de Janeiro,Religion2020,2010,9
federal university of rio grande do norte,Cooperation2021,2010,2
Federal University of Rio Grande do Sul,Religion2020,2010,2
Federal University of Santa Catarina,Religion2020,2010,5
Federal University of São Paulo (UNIFESP),Cooperation2021,2010,1
Federal University of São Paulo (UNIFESP),Religion2020,2010,19
Federal University of Tocantins,Religion2020,2010,3
Federation University Australia,Cooperation2021,2010,1
Federation University Australia,CooperationAndReligion,2010,1
Federation University Australia,Religion2020,2010,24
Fern University,Religion2020,2010,3
Fielding Graduate University,Religion2020,2010,5
First Baptist Church of Stockton,Religion2020,2010,8
First Things,Religion2020,2010,2
Flame University,Religion2020,2010,3
Flinders University,Cooperation2021,2010,8
Flinders University,Religion2020,2010,22
Flordia State,Religion2020,2010,7
Florida Agricultural and Mechanical University,Cooperation2021,2010,1
Florida Atlantic University,Religion2020,2010,27
Florida International University,Cooperation2021,2010,8
Florida International University,Religion2020,2010,34
Florida SouthWestern State College,Religion2020,2010,3
Florida State University,Cooperation2021,2010,29
Florida State University,Religion2020,2010,91
Florida State University Panama Campus,Religion2020,2010,10
flourishing metrics,Religion2020,2010,2
Floyd Medical Center,Religion2020,2010,1
Fluminese Federal University,Religion2020,2010,2
Fooyin University,Religion2020,2010,4
Fordham University,Cooperation2021,2010,4
Fordham University,Religion2020,2010,140
Forlaget Univers,Religion2020,2010,3
Fors Marsh Group,Religion2020,2010,2
Fortune Institute of Technology,Religion2020,2010,1
Forum on European Expansion and Global Interaction,Religion2020,2010,4
Foundation for Shamanic Studies,Cooperation2021,2010,1
Foundation for Shamanic Studies,Religion2020,2010,1
"Founder and Director of the Jung's Center Mind, Body, Spirit Institute",Religion2020,2010,4
Four Seasons Hospice Care,Cooperation2021,2010,1
Four Seasons Hospice Care,Religion2020,2010,3
Fox Chase Cancer Center,Cooperation2021,2010,3
Fox Valley Memory Project,Religion2020,2010,5
Francis Marion University,Religion2020,2010,3
Francis Taylor Building,Religion2020,2010,8
Franciscan University,Religion2020,2010,15
Franciscan University of Steubenville,Religion2020,2010,3
Franklin & Marshall College,Religion2020,2010,4
Fraser Health Authority,Cooperation2021,2010,1
Fraser Health Authority,CooperationAndReligion,2010,1
Fraser Health Authority,Religion2020,2010,2
Fraunhofer FIT,Cooperation2021,2010,4
Fred Hutchinson Cancer Research Center,Cooperation2021,2010,2
Fred Hutchinson Cancer Research Center,Religion2020,2010,3
frederick university,Religion2020,2010,1
Free State University,Cooperation2021,2010,4
Free State University,Religion2020,2010,11
Free University Berlin,Cooperation2021,2010,8
Free University Berlin,Religion2020,2010,5
Free University of Berlin,Cooperation2021,2010,6
Free University of Berlin,Religion2020,2010,18
Freelance Researcher,Religion2020,2010,4
Freiburg University of Mining and Technology,Cooperation2021,2010,4
Freie Universität Berlin,Religion2020,2010,47
French Institute for Anatolian Studies,Religion2020,2010,2
French Institute for Research in Computer Science and Automation,Cooperation2021,2010,3
French Institute of Health and Medical Research,Cooperation2021,2010,1
French Institute of Health and Medical Research,CooperationAndReligion,2010,1
French Institute of Health and Medical Research,Religion2020,2010,4
French National Center for Scientific Research,Cooperation2021,2010,5
French National Center for Scientific Research,Religion2020,2010,19
French National Council of Universities,Religion2020,2010,2
French National Institute for Agricultural Research,Cooperation2021,2010,3
Fresno Pacific University,Religion2020,2010,5
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,2010,8
Friedrich Schiller University Jena,Cooperation2021,2010,6
Friedrich-Alexander University Erlangen-Nuremberg,Religion2020,2010,9
Friedrich-Alexander-Universität Erlangen,Religion2020,2010,9
Friends Research Institute,Religion2020,2010,3
Frontiers of Immunology,Religion2020,2010,2
FTSE Russell,Cooperation2021,2010,3
Fu Jen Catholic University,Religion2020,2010,2
Fudan University,Cooperation2021,2010,11
Fudan University,Religion2020,2010,14
Fuller Graduate School of Psychology,Religion2020,2010,1
Fuller Theological Seminary,Cooperation2021,2010,3
Fuller Theological Seminary,Religion2020,2010,169
Fundação Faculdade de Medicina,Religion2020,2010,3
Fundação Getulio Vargas,Cooperation2021,2010,2
Fundacion Botin,Cooperation2021,2010,3
Furman University,Religion2020,2010,17
Fuzhou University,Cooperation2021,2010,6
FWD.us,Cooperation2021,2010,1
FWD.us,CooperationAndReligion,2010,1
FWD.us,Religion2020,2010,8
Gadjah Mada University,Religion2020,2010,7
Gajara Raja Medical College,Religion2020,2010,2
Gandhi Naturopathic Medical College,Religion2020,2010,2
Gansu University of Traditional Chinese Medicine,Cooperation2021,2010,5
Gardner-Webb University,Religion2020,2010,3
Garrett-Evangelical Theological Seminary,Cooperation2021,2010,1
Garrett-Evangelical Theological Seminary,CooperationAndReligion,2010,1
Garrett-Evangelical Theological Seminary,Religion2020,2010,13
Geestelijk Verzorger Zinzia Zorggroep,Religion2020,2010,3
Geha Mental Health Center,Religion2020,2010,4
Geisel School of Medicine,Religion2020,2010,1
Genentech,Cooperation2021,2010,3
General Hospital Virovitica,Religion2020,2010,2
General Theological Seminary,Religion2020,2010,1
Generation Next,Cooperation2021,2010,1
Generation Next,Religion2020,2010,6
Georg August University of Göttingen,Cooperation2021,2010,2
Georg August University of Göttingen,Religion2020,2010,7
George Fox University,Religion2020,2010,11
George Kurian Reference Books,Cooperation2021,2010,3
George Kurian Reference Books,CooperationAndReligion,2010,1
George Kurian Reference Books,Religion2020,2010,61
George Mason University,Cooperation2021,2010,35
George Mason University,Religion2020,2010,21
George Washington University,Cooperation2021,2010,11
George Washington University,CooperationAndReligion,2010,2
George Washington University,Religion2020,2010,59
Georgetown University,Cooperation2021,2010,6
Georgetown University,Religion2020,2010,126
Georgetown University School of Medicine,Religion2020,2010,1
Georgia Institute of Technology,Cooperation2021,2010,8
Georgia Institute of Technology,Religion2020,2010,5
Georgia Southern University,Religion2020,2010,9
Georgia State University,Religion2020,2010,43
Gereformeerde Hogeschool,Religion2020,2010,2
German Institute for Economic Research (DIW Berlin),Cooperation2021,2010,1
German Institute for Economic Research (DIW Berlin),Religion2020,2010,2
German Primate Center,Cooperation2021,2010,8
GESIS-Leibniz Institute for the Social Sciences,Religion2020,2010,6
Getty Images,Religion2020,2010,2
Gettysburg College,Religion2020,2010,9
GGZ Central,Religion2020,2010,7
Ghent University,Cooperation2021,2010,11
Ghent University,Religion2020,2010,63
GIGA German Institute of Global and Area Studies,Cooperation2021,2010,1
GIGA German Institute of Global and Area Studies,Religion2020,2010,9
Glasgow Caledonian University,Religion2020,2010,3
Glasgow Centre for Population Health,Religion2020,2010,1
Glendale Adventist Medical Center,Religion2020,2010,2
Glendale Community College,Religion2020,2010,3
Global Alliance in Management Education,Religion2020,2010,3
Global Cyber University,Religion2020,2010,3
Global Ethic Institute,Religion2020,2010,4
Glyndwr University,Cooperation2021,2010,2
Glyndwr University,Religion2020,2010,36
Goa Medical College,Religion2020,2010,2
Goce Delčev University of Štip,Cooperation2021,2010,3
Goddard College,Religion2020,2010,13
Goethe University Frankfurt,Religion2020,2010,15
Goettingen University,Religion2020,2010,2
Golden Gate Baptist Theological Seminary,Cooperation2021,2010,2
Golden Gate Baptist Theological Seminary,Religion2020,2010,1
"Goldsmiths, University of London",Cooperation2021,2010,4
"Goldsmiths, University of London",CooperationAndReligion,2010,1
"Goldsmiths, University of London",Religion2020,2010,82
Gonzaga University,Religion2020,2010,8
Google,Religion2020,2010,2
Gordon College,Cooperation2021,2010,1
Gordon College,CooperationAndReligion,2010,1
Gordon College,Religion2020,2010,7
Gordon Conwell Theological Seminary,Religion2020,2010,2
Gordon Life Science Institute,Cooperation2021,2010,2
Gordon-Conwell Theological Seminary,Religion2020,2010,10
Governemnt Science College,Religion2020,2010,1
Graduate Institute of International and Developmental Studies in Geneva,Cooperation2021,2010,4
Graduate Theological Foundation,Religion2020,2010,1
Graduate Theological Union,Cooperation2021,2010,1
Graduate Theological Union,CooperationAndReligion,2010,1
Graduate Theological Union,Religion2020,2010,44
Graduate University of Advanced Studies (SOKENDAI),Cooperation2021,2010,10
Grady Health System,Religion2020,2010,2
Grand Valley State University,Cooperation2021,2010,1
Grand Valley State University,CooperationAndReligion,2010,1
Grand Valley State University,Religion2020,2010,8
Grand View University,Religion2020,2010,2
Grande Ospedale Metropolitano Niguarda,Religion2020,2010,2
Green Templeton College,Cooperation2021,2010,2
Green Templeton College,Religion2020,2010,6
Greenwall Foundation,Religion2020,2010,1
Greenwich Hospital,Religion2020,2010,4
Greenwich School of Theology,Religion2020,2010,6
Gregorian University of the Jesuit Order,Religion2020,2010,3
Grenoble Alpes University,Cooperation2021,2010,2
Grenoble Alpes University,Religion2020,2010,1
Gresham College,Religion2020,2010,6
Griffin Hospital,Religion2020,2010,1
Griffith University,Cooperation2021,2010,10
Griffith University,Religion2020,2010,15
Grove City College,Religion2020,2010,2
Guangdong University of Foreign Studies,Religion2020,2010,11
Guangxi Normal University,Cooperation2021,2010,7
Guangzhou University,Cooperation2021,2010,1
Gustavus Adolphus College,Religion2020,2010,1
Gwynedd Mercy College,Religion2020,2010,5
H&S Ventures,Religion2020,2010,3
H. Lee Moffitt Cancer Center and Research Institute,Cooperation2021,2010,4
Hacettepe University,Religion2020,2010,4
Hadassah University Medical Center,Cooperation2021,2010,2
Hadassah University Medical Center,CooperationAndReligion,2010,1
Hadassah University Medical Center,Religion2020,2010,13
Haifa University,Religion2020,2010,15
Haight-Ashbury Free Clinic (HAFC),Religion2020,2010,2
Hainan Medical University,Religion2020,2010,1
Halmstad University,Religion2020,2010,8
Hamad bin Khalifa College of Law and Public Policy,Religion2020,2010,4
Hamad Bin Khalifa University,Cooperation2021,2010,1
Hamad Bin Khalifa University,CooperationAndReligion,2010,1
Hamad Bin Khalifa University,Religion2020,2010,12
Hamburg University,Cooperation2021,2010,1
Hamburg University,Religion2020,2010,13
Hamilton College,Religion2020,2010,10
Hampton University,Religion2020,2010,1
Handong University,Religion2020,2010,5
handzo consulting,Religion2020,2010,10
Hangzhou Dianzi University,Cooperation2021,2010,1
Hangzhou Dianzi University,Religion2020,2010,1
Hankuk university of foreign studies,Cooperation2021,2010,1
Hankuk university of foreign studies,Religion2020,2010,7
Hanshin University,Religion2020,2010,5
Harbin Institute of Technology,Cooperation2021,2010,2
Harbin Institute of Technology,Religion2020,2010,1
Hardin-Simmons University,Religion2020,2010,17
Harper College,Religion2020,2010,3
Harris Health System,Religion2020,2010,1
Harris Manchester College,Religion2020,2010,13
Hartford Seminary,Religion2020,2010,1
Hartford University,Religion2020,2010,1
Harvard Business School,Cooperation2021,2010,2
Harvard Business School,Religion2020,2010,5
Harvard Center for World Religions,Religion2020,2010,1
Harvard Divinity School,Religion2020,2010,27
Harvard Graduate School of Education,Religion2020,2010,1
Harvard Medical School,Cooperation2021,2010,30
Harvard Medical School,CooperationAndReligion,2010,1
Harvard Medical School,Religion2020,2010,95
Harvard School of Public Health,Cooperation2021,2010,23
Harvard School of Public Health,CooperationAndReligion,2010,1
Harvard School of Public Health,Religion2020,2010,95
Harvard T.H. Chan School of Public Health,Religion2020,2010,10
Harvard University,Cooperation2021,2010,129
Harvard University,CooperationAndReligion,2010,6
Harvard University,Religion2020,2010,140
Hastings Center,Religion2020,2010,2
Havering College of Further and Higher Education,Religion2020,2010,6
Hawaii Pacific University,Religion2020,2010,4
Heal,Religion2020,2010,3
Healthcare Chaplaincy and Spiritual Care,Cooperation2021,2010,1
Healthcare Chaplaincy and Spiritual Care,Religion2020,2010,9
Healthed,Cooperation2021,2010,1
Healthed,Religion2020,2010,6
HealthPartners,Cooperation2021,2010,1
HealthPartners,Religion2020,2010,7
Hebrew Union College,Religion2020,2010,5
Hebrew University - Hadessah,Cooperation2021,2010,1
Hebrew University - Hadessah,CooperationAndReligion,2010,1
Hebrew University - Hadessah,Religion2020,2010,4
Hebrew University of Jerusalem,Cooperation2021,2010,13
Hebrew University of Jerusalem,Religion2020,2010,65
HEC Montreal,Cooperation2021,2010,1
Hefei University of Technology,Cooperation2021,2010,7
Heidelberg Center for American Studies,Religion2020,2010,2
Heidelberg University,Cooperation2021,2010,6
Heidelberg University,Religion2020,2010,33
Heights Therapy,Religion2020,2010,10
Heinrich Heine University Düsseldorf,Cooperation2021,2010,2
Heinrich Heine University Düsseldorf,Religion2020,2010,4
Heinrich-Heine University,Cooperation2021,2010,4
Heinrich-Heine University,Religion2020,2010,1
Helen Dowling Institute,Religion2020,2010,6
Hellenic Foundation for European and Foreign Policy,Cooperation2021,2010,1
Hellenic Foundation for European and Foreign Policy,Religion2020,2010,1
Hellenic Open University,Religion2020,2010,9
HELP University,Religion2020,2010,4
Henan Normal University,Cooperation2021,2010,1
Henderson State University,Religion2020,2010,6
Hendrix College,Religion2020,2010,2
Henry M Jackson Foundation for the Advancement of Military Medicine,Religion2020,2010,2
Heriot-Watt University,Religion2020,2010,4
Hermanos Ameijeiras Hospital,Cooperation2021,2010,5
Hermanos Ameijeiras Hospital,CooperationAndReligion,2010,3
Hermanos Ameijeiras Hospital,Religion2020,2010,5
Hertie School,Cooperation2021,2010,3
"Herzog Hospital, Jerusalem",Cooperation2021,2010,2
"Herzog Hospital, Jerusalem",CooperationAndReligion,2010,1
"Herzog Hospital, Jerusalem",Religion2020,2010,6
Heythrop College,Religion2020,2010,9
Higher School of Economics,Cooperation2021,2010,2
Highland Theological College,Religion2020,2010,6
Hindu University of America,Religion2020,2010,4
Hitotsubashi University,Cooperation2021,2010,21
Hobart and William Smith Colleges,Religion2020,2010,7
Hofstra University,Religion2020,2010,10
Hogeschool Utrecht,Religion2020,2010,10
Hokkaido University,Cooperation2021,2010,9
Hokkaido University,Religion2020,2010,4
Holbaek Hospital,Religion2020,2010,1
Hollins University,Religion2020,2010,6
Holy Cross College,Religion2020,2010,5
Holy Ghost College,Religion2020,2010,4
Holy Spirit Seminary College of Theology & Philosophy,Religion2020,2010,1
Homerton University Hospital NHS Foundation Trust,Religion2020,2010,4
"Homewood Research Institute, Guelph, Ontario",Religion2020,2010,2
Hong Kong Baptist University,Cooperation2021,2010,1
Hong Kong Baptist University,Religion2020,2010,10
Hong Kong Polytechnic University,Cooperation2021,2010,23
Hong Kong Polytechnic University,Religion2020,2010,20
Hong Kong Shue Yan College,Cooperation2021,2010,6
Hong Kong University of Science and Technology,Cooperation2021,2010,6
Hongik University,Religion2020,2010,4
Hope College,Cooperation2021,2010,1
Hope College,Religion2020,2010,15
Hopewell Presbyterian Church,Religion2020,2010,1
Hôpital Paul-Brousse – Hôpitaux universitaires Paris-Sud,Cooperation2021,2010,1
Hôpital Paul-Brousse – Hôpitaux universitaires Paris-Sud,CooperationAndReligion,2010,1
Hôpital Paul-Brousse – Hôpitaux universitaires Paris-Sud,Religion2020,2010,4
Hosei University,Cooperation2021,2010,4
Hospice by the Bay,Religion2020,2010,1
Hospice palliative care ssociation of south africa,Religion2020,2010,8
Hospice Rieti,Religion2020,2010,44
Hospital Clínico Universitario Virgen de la Arrixaca,Cooperation2021,2010,15
Hospital Clínico Universitario Virgen de la Arrixaca,CooperationAndReligion,2010,10
Hospital Clínico Universitario Virgen de la Arrixaca,Religion2020,2010,15
Hospital de Cancer de Barretos,Religion2020,2010,4
Hospital De Clínicas De Porto Alegre,Religion2020,2010,2
Hospital for Sick Children,Religion2020,2010,3
Hospital Israelita Albert Einstein,Religion2020,2010,7
Hospital Pérola Byington,Religion2020,2010,2
Hospital Regional de Alta Especialidad del Bajío,Cooperation2021,2010,6
Hospital Regional de Alta Especialidad del Bajío,CooperationAndReligion,2010,4
Hospital Regional de Alta Especialidad del Bajío,Religion2020,2010,6
House of Lords,Religion2020,2010,3
Houston Baptist University,Religion2020,2010,7
Houston Methodist Hospital,Cooperation2021,2010,2
Houston Methodist Hospital,CooperationAndReligion,2010,2
Houston Methodist Hospital,Religion2020,2010,8
Howard University,Cooperation2021,2010,1
Howard University,CooperationAndReligion,2010,1
Howard University,Religion2020,2010,9
Huawei Technologies,Cooperation2021,2010,8
Huawei Technologies,Religion2020,2010,1
Huazhong University of Science and Technology,Cooperation2021,2010,8
Huazhong University of Science and Technology,Religion2020,2010,1
Hubei University of Medicine,Religion2020,2010,1
Hubrecht Institute,Cooperation2021,2010,2
Hudson College of Public Health,Cooperation2021,2010,4
Hudson College of Public Health,Religion2020,2010,1
Hult International Business School,Religion2020,2010,2
Human Sciences Research Council,Cooperation2021,2010,4
Human Sciences Research Council,Religion2020,2010,11
Humboldt State University,Religion2020,2010,2
Humboldt University of Berlin,Cooperation2021,2010,8
Humboldt University of Berlin,Religion2020,2010,28
Hunan Normal University,Cooperation2021,2010,5
Hunan Normal University,Religion2020,2010,2
Hunan University,Cooperation2021,2010,6
Hungarian Academy of Sciences,Cooperation2021,2010,4
Hungarian Academy of Sciences,Religion2020,2010,11
"Hunter College, CUNY",Religion2020,2010,14
Hunter HIV/AIDS Research Team (HART),Religion2020,2010,2
Hunter Research Foundation,Cooperation2021,2010,1
Hunter Research Foundation,Religion2020,2010,18
Huntingdon College,Religion2020,2010,1
Huntington University,Religion2020,2010,2
Huntsman Cancer Institute,Religion2020,2010,10
Huron University College,Religion2020,2010,7
Huston-Tilloston University,Religion2020,2010,4
IAIN Langsa,Religion2020,2010,3
IAIN Pongoro,Religion2020,2010,1
IAIN Purwokerto,Religion2020,2010,3
Ibaraki University,Religion2020,2010,1
Ibn Haldun University,Religion2020,2010,1
Ibnu Khaldun University of Bogor,Religion2020,2010,4
Icahn School of Medicine at Mount Sinai,Religion2020,2010,11
ICERRS,Religion2020,2010,5
ICHEC Brussels Management School,Religion2020,2010,1
Idaho State University,Religion2020,2010,2
Ignatianum Academy in Krakow,Religion2020,2010,3
Iliff School of Theology,Religion2020,2010,9
Illif School of Theology,Religion2020,2010,3
Illinois College,Religion2020,2010,19
Illinois Institute of Technology,Religion2020,2010,12
Illinois State University,Religion2020,2010,12
Illinois Wesleyan University,Religion2020,2010,2
Immanuel Hospital Berlin,Religion2020,2010,7
Immersion Neuroscience,Cooperation2021,2010,2
Imperial Cancer Research Fund Oxford,Cooperation2021,2010,1
Imperial College London,Cooperation2021,2010,35
Imperial College London,Religion2020,2010,6
IMT School for Advanced Studies Lucca,Cooperation2021,2010,1
IMT Strategy and Oversight,Religion2020,2010,3
Incheon National University,Cooperation2021,2010,1
Incheon National University,Religion2020,2010,1
Independent Consulting,Religion2020,2010,1
Independent Researcher,Religion2020,2010,4
India Institute of Technology,Cooperation2021,2010,3
India Institute of Technology,CooperationAndReligion,2010,2
India Institute of Technology,Religion2020,2010,19
Indian Council of Social Science Research,Religion2020,2010,1
Indian Institute of Management,Religion2020,2010,3
Indian Institute of Science,Cooperation2021,2010,1
Indian Institute of Technology,Cooperation2021,2010,1
Indian Institute of Technology,Religion2020,2010,8
Indian Institute of Technology Bombay,Religion2020,2010,1
Indian Naturopathy & Yoga Graduates Medical Association [INYGMA],Religion2020,2010,6
Indian River State College,Religion2020,2010,3
Indian Statistical Institute,Religion2020,2010,3
Indiana School of Medicine,Religion2020,2010,1
Indiana State University,Religion2020,2010,11
Indiana University,Cooperation2021,2010,31
Indiana University,CooperationAndReligion,2010,1
Indiana University,Religion2020,2010,95
Indiana University at Bloomington,Cooperation2021,2010,4
Indiana University at Bloomington,Religion2020,2010,4
Indiana University of Pennsylvania,Religion2020,2010,3
Indiana University South Bend,Religion2020,2010,5
Indiana University-Purdue University Columbus,Religion2020,2010,7
Indiana University-Purdue University Fort Wayne,Religion2020,2010,5
Indiana University-Purdue University Indianapolis,Religion2020,2010,4
Indiana Wesleyan University,Religion2020,2010,1
Indic Academy,Religion2020,2010,3
"Indo US Projects, CEIMH, ABVIMS Dr.R.M.L.Hospital",Religion2020,2010,1
Indonesia Institute of Sciences,Religion2020,2010,5
Indonesian Institute of Sciences,Cooperation2021,2010,1
Indonesian Institute of Sciences,CooperationAndReligion,2010,1
Indonesian Institute of Sciences,Religion2020,2010,7
Indus Business Academy,Religion2020,2010,2
Infinity Publishing,Religion2020,2010,1
Inholland University,Religion2020,2010,15
Inland Norway University of Applied Science,Religion2020,2010,2
Inner Mongolia University of Science and Technology,Cooperation2021,2010,3
Innlandet Hospital Trust,Cooperation2021,2010,1
Innlandet Hospital Trust,Religion2020,2010,7
"Innovations and EBM Development, EBSCO Health",Religion2020,2010,1
"INSEAD, Singapore",Religion2020,2010,11
Inserm,Cooperation2021,2010,14
InsightLA,Religion2020,2010,1
Institut Agama Islam Negeri Palu,Cooperation2021,2010,2
Institut Agama Islam Negeri Palu,Religion2020,2010,5
Institut Agama Islam Negeri Sorong,Religion2020,2010,3
Institut Agama Islam Negeri Sultan Amai,Religion2020,2010,3
Institut Curie,Cooperation2021,2010,1
Institut Curie,Religion2020,2010,2
Institut für Evangelische Theologie,Religion2020,2010,3
Institut für Grenzgebiete der Psychologie und Psychohygiene,Religion2020,2010,6
Institut national de la recherche scientifique,Cooperation2021,2010,1
Institut universitaire de gériatrie de Montréal (IUGM),Religion2020,2010,4
Institute Ecumenical and Cultural Research,Religion2020,2010,2
Institute for Advanced Study,Religion2020,2010,40
Institute for American Constitutional Heritage,Religion2020,2010,1
Institute for Applied Statistics and Data Analysis,Religion2020,2010,1
Institute for Ecumenical Research,Religion2020,2010,2
Institute for Frontier Areas of Psychology and Mental Health,Religion2020,2010,3
Institute for Genetic and Biomedical Research,Cooperation2021,2010,2
Institute for Global Engagement,Religion2020,2010,8
Institute for Human Science and Service,Religion2020,2010,1
Institute for Integrative Health,Cooperation2021,2010,1
Institute for Neuro-Immune Medicine,Religion2020,2010,1
Institute for Orthodox Christian Studies,Religion2020,2010,2
Institute for Political Science,Religion2020,2010,1
Institute for Regenerative Medicine,Cooperation2021,2010,2
Institute for Research in Immunology and Cancer,Cooperation2021,2010,1
Institute for Social Policy and Understanding,Religion2020,2010,1
Institute for Strategic Dialogue,Religion2020,2010,4
Institute for the Biocultural Study of Religion,Religion2020,2010,17
Institute for the Study of Complex Systems,Cooperation2021,2010,1
Institute for Transpersonal Psychology,Religion2020,2010,5
Institute of Applied Psychology,Religion2020,2010,13
Institute of Basic Medical Sciences,Religion2020,2010,1
Institute of Child Health and Human Development,Cooperation2021,2010,1
Institute of Communications Engineering,Cooperation2021,2010,3
Institute of Developing Economics,Cooperation2021,2010,1
Institute of Electrical and Electronics Engineers,Cooperation2021,2010,3
Institute of Health Economics,Religion2020,2010,1
Institute of Human Behavioral Medicine,Religion2020,2010,4
Institute of Ismaili Studies,Religion2020,2010,66
Institute of Italian Studies,Religion2020,2010,17
Institute of Lutheran Theology,Religion2020,2010,2
Institute of Medical Psychology at Charité,Religion2020,2010,2
Institute of Mental Health,Religion2020,2010,5
Institute of Modern History,Religion2020,2010,7
Institute of Molecular Pathology,Cooperation2021,2010,3
Institute of Noetic Sciences,Religion2020,2010,8
Institute of Psychiatry & Human Behavior,Religion2020,2010,2
Institute of Psychology,Cooperation2021,2010,3
Institute of Psychology,Religion2020,2010,1
Institute of Race Relations,Religion2020,2010,1
Institute of Science and Technology Austria,Cooperation2021,2010,3
"Institute of Science, Technology and Public Policy",Religion2020,2010,1
Institute of Security and Global Affairs,Religion2020,2010,5
Institute of Technical Physics and Material Sciene,Cooperation2021,2010,1
Instituto de Análisis Económico,Cooperation2021,2010,3
Instituto de Astrofisica de Canarias,Religion2020,2010,1
Instituto de Telecomunicacones,Cooperation2021,2010,4
Instituto Universitário de Ciências Psicológicas,Religion2020,2010,5
Insubria University,Religion2020,2010,1
Interdisciplinary Center Herzliya,Cooperation2021,2010,1
Interdisciplinary Center Herzliya,Religion2020,2010,11
Interdisciplinary Center Herzliya:,Cooperation2021,2010,14
"International Association for Human Values, United Nations",Religion2020,2010,2
International Baptist Theological Seminary,Religion2020,2010,8
International Children's Palliative Care Network,Cooperation2021,2010,1
International Children's Palliative Care Network,Religion2020,2010,10
International Christian University,Religion2020,2010,6
International Institute for Applied Systems Analysis,Cooperation2021,2010,9
International Institute for Applied Systems Analysis,Religion2020,2010,3
International Institute for Population Sciences,Religion2020,2010,1
International Institute of Spiritual Leadership,Religion2020,2010,6
International Islamic University Malaysia,Religion2020,2010,6
International Journal of Reformed Theology and Life,Religion2020,2010,1
International Labour Organization,Cooperation2021,2010,4
International Peace and Development Ethics Center,Cooperation2021,2010,1
International Primary Palliative Care Network (IPPCN),Religion2020,2010,2
International Society of Addiction Medicine,Religion2020,2010,1
International Telematic University UNINETTUNO,Cooperation2021,2010,7
International University of Rabat,Religion2020,2010,4
Inter-University Centre Dubrovnik,Religion2020,2010,5
IOIO,Cooperation2021,2010,8
IOIO,CooperationAndReligion,2010,6
IOIO,Religion2020,2010,8
Iona College,Religion2020,2010,2
Iowa State University,Cooperation2021,2010,16
Iowa State University,Religion2020,2010,18
Iran University of Medical Sciences,Religion2020,2010,2
Iranian Institute for Health Sciences Research,Cooperation2021,2010,1
Iranian Institute for Health Sciences Research,Religion2020,2010,4
IRCCS,Religion2020,2010,5
IRCCS Eugenio Medea,Religion2020,2010,5
IRCCS Istituto Nazionale dei Tumori,Religion2020,2010,4
IRCCS San Raffaeele Pisana,Religion2020,2010,3
ISAP- Zurich,Religion2020,2010,7
ISAPEG,Cooperation2021,2010,8
ISAPEG,CooperationAndReligion,2010,6
ISAPEG,Religion2020,2010,8
Isfahan University,Cooperation2021,2010,1
Isfahan University,Religion2020,2010,12
Isfahan University of Technology,Cooperation2021,2010,1
Islamic Azad University,Religion2020,2010,9
Islamic University of Gaza,Religion2020,2010,1
ISPM,Religion2020,2010,7
Israel Institute of Technology,Religion2020,2010,8
Istanbul Bilgi University,Religion2020,2010,4
Istanbul Sabahattin Zaim University,Cooperation2021,2010,1
Istanbul Sabahattin Zaim University,Religion2020,2010,3
Istituto Auxologico Italiano,Religion2020,2010,3
Istituto di Scienze e Technologie della Cognizione,Cooperation2021,2010,1
Istituto di Scienze e Technologie della Cognizione,CooperationAndReligion,2010,1
Istituto di Scienze e Technologie della Cognizione,Religion2020,2010,3
Istituto Mente e Corpo,Religion2020,2010,10
Istituto Nazionale dei Tumori,Cooperation2021,2010,1
Istituto Nazionale dei Tumori,Religion2020,2010,2
Italian National Institute of Health,Cooperation2021,2010,1
Italian National Research Council,Cooperation2021,2010,3
Ithaca College,Religion2020,2010,22
Jackson State University,Religion2020,2010,1
Jacobs University Bremen,Religion2020,2010,8
Jadavpur University,Cooperation2021,2010,4
Jagiellonian University,Cooperation2021,2010,2
Jagiellonian University,Religion2020,2010,16
Jakarta State University,Religion2020,2010,1
James Cook University,Cooperation2021,2010,8
James Cook University,Religion2020,2010,5
James Cook University-Singapore,Religion2020,2010,1
James Madison University,Cooperation2021,2010,1
James Madison University,Religion2020,2010,10
Jangxi University of Finance and Economics,Religion2020,2010,3
Janki Foundation for Spirituality in Healthcare,Religion2020,2010,4
Japan Advanced Institute of Science and Technology,Cooperation2021,2010,2
Jawaharlal Nehru University,Religion2020,2010,4
Jean Monnet Centre of Excellence,Religion2020,2010,4
Jefferson Medical College,Religion2020,2010,2
Jena University,Religion2020,2010,2
Jesuit Faculty of Philosophy and Theology,Religion2020,2010,2
Jesuit Ignatianum University,Religion2020,2010,3
Jesuit Institute,Religion2020,2010,5
Jesuit School of Theology,Religion2020,2010,3
Jesus College,Religion2020,2010,6
Jewish Theological Seminary,Religion2020,2010,3
Jiangsu Normal University,Cooperation2021,2010,5
Jilin University,Cooperation2021,2010,14
Jilin University,Religion2020,2010,1
Jimma University,Religion2020,2010,5
Jindal Global University,Religion2020,2010,2
jobZology,Religion2020,2010,2
Johann Wolfgang Goethe-University,Religion2020,2010,3
Johannes a Lasco Library,Religion2020,2010,5
Johannes Gutenberg University,Religion2020,2010,9
John Cabot University,Religion2020,2010,3
John Carroll University,Religion2020,2010,4
John F. Kennedy University,Religion2020,2010,13
John Hopkins University,Cooperation2021,2010,16
John Hopkins University,Religion2020,2010,21
John Innes Centre,Cooperation2021,2010,4
John Jay College,Religion2020,2010,2
John Jay College of Criminal Justice,Religion2020,2010,13
John Paul II Catholic University of Lublin,Religion2020,2010,8
John Templeton Foundation Board of Advisors,Religion2020,2010,2
John Wycliffe Theological College,Religion2020,2010,4
Johns Hopkins Bloomberg School of Public Health,Religion2020,2010,9
Johns Hopkins Hospital,Religion2020,2010,4
Johns Hopkins School of Medicine,Cooperation2021,2010,1
Johns Hopkins School of Medicine,Religion2020,2010,8
Johns Hopkins University,Cooperation2021,2010,20
Johns Hopkins University,CooperationAndReligion,2010,1
Johns Hopkins University,Religion2020,2010,57
Joint Special Operations University,Religion2020,2010,3
Jonkoping University,Religion2020,2010,2
Jönköping University,Cooperation2021,2010,1
Jönköping University,Religion2020,2010,8
Josep Carreras Leukaemia Research Institute,Cooperation2021,2010,3
Josep Carreras Leukaemia Research Institute,Religion2020,2010,2
Journal of Law and Religion,Cooperation2021,2010,1
Journal of Law and Religion,CooperationAndReligion,2010,1
Journal of Law and Religion,Religion2020,2010,7
"Journal of Policing, Intelligence and Counter Terrorism (JPICT)",Religion2020,2010,2
Journal of the American Medical Association (JAMA),Cooperation2021,2010,1
Journey Mental Health Center,Religion2020,2010,2
Józef Pilsudski Academy of Physical Education,Religion2020,2010,6
JPsych and Center for Anxiety,Religion2020,2010,19
Jungian Psychoanalytic Association of New York,Cooperation2021,2010,2
Jungian Psychoanalytic Association of New York,Religion2020,2010,17
Juniata College,Religion2020,2010,2
Justice Resource Institute,Religion2020,2010,1
Justus Liebig University Giessen,Cooperation2021,2010,2
Justus Liebig University Giessen,CooperationAndReligion,2010,1
Justus Liebig University Giessen,Religion2020,2010,25
Kadir Has University,Religion2020,2010,2
Kaiser Permanente,Religion2020,2010,2
Kalinga Institute of Industrial Technology,Religion2020,2010,5
KalpaTaru,Religion2020,2010,1
Kampen Theological University,Religion2020,2010,1
Kansai University,Cooperation2021,2010,5
Kansas State University,Religion2020,2010,2
Kansas University,Cooperation2021,2010,1
Kansas University,Religion2020,2010,5
Kaplan University,Religion2020,2010,3
Karakter Child and Adolescent  Psychiatry,Cooperation2021,2010,2
Karakter Child and Adolescent  Psychiatry,Religion2020,2010,1
Karen D. Lincoln Consulting Services,Religion2020,2010,2
Karl Landsteiner University of Health Sciences,Religion2020,2010,2
Karl-Franzens University Graz,Religion2020,2010,2
Karlstad University,Religion2020,2010,2
Karnatak University,Religion2020,2010,3
Karolinska Institute,Cooperation2021,2010,7
Karolinska Institute,Religion2020,2010,13
Karolinska Institutet,Religion2020,2010,2
Karuna-Shechen,Cooperation2021,2010,1
Karuna-Shechen,Religion2020,2010,3
Kashan University of Medical Sciences,Religion2020,2010,1
Katholieke Universiteit Leuven,Cooperation2021,2010,3
Katholieke Universiteit Leuven,Religion2020,2010,9
KBC Bank,Religion2020,2010,1
Kean University,Cooperation2021,2010,2
Kean University,CooperationAndReligion,2010,1
Kean University,Religion2020,2010,27
Keele University,Cooperation2021,2010,2
Keele University,CooperationAndReligion,2010,2
Keele University,Religion2020,2010,36
Keenesaw State University,Cooperation2021,2010,2
Keimyung University,Cooperation2021,2010,1
Keimyung University,CooperationAndReligion,2010,1
Keimyung University,Religion2020,2010,17
Keio University,Cooperation2021,2010,9
Keio University Shonan Fujisawa,Cooperation2021,2010,5
Keio University Shonan Fujisawa,Religion2020,2010,3
Kellogg School of Management,Religion2020,2010,1
KEMRI-Wellcome Trust Research Programme,Cooperation2021,2010,5
Kennesaw State University,Religion2020,2010,1
Kenrick Glennon Seminary,Religion2020,2010,8
Kent State University,Religion2020,2010,15
Kenyatta University,Religion2020,2010,1
Kenyon College,Cooperation2021,2010,1
Kenyon College,Religion2020,2010,1
Kerman University of Medical Sciences,Religion2020,2010,8
Keston Institute,Religion2020,2010,7
Kfar Shaul Hospital,Religion2020,2010,4
Khalifa University,Cooperation2021,2010,7
Khon Kaen University,Religion2020,2010,2
Kickbusch Health Consult,Cooperation2021,2010,4
Kiel Institute for the World Economy,Cooperation2021,2010,8
Kiev University,Cooperation2021,2010,1
Kiev University,CooperationAndReligion,2010,1
Kiev University,Religion2020,2010,3
KIFO Institute,Religion2020,2010,1
Kindred Hospital El Paso,Cooperation2021,2010,2
King Abdulaziz University,Cooperation2021,2010,3
King Abdulaziz University,Religion2020,2010,8
King Abdullah University of Science and Technology,Cooperation2021,2010,8
King County Department of Community and Human Services,Religion2020,2010,2
King Faisal University,Cooperation2021,2010,1
King Saud University,Cooperation2021,2010,2
King Saud University,Religion2020,2010,5
King’s College,Religion2020,2010,3
King’s College London,Cooperation2021,2010,5
King’s College London,CooperationAndReligion,2010,2
King’s College London,Religion2020,2010,51
King's College,Cooperation2021,2010,4
King's College,Religion2020,2010,47
King's College London,Cooperation2021,2010,12
King's College London,CooperationAndReligion,2010,5
King's College London,Religion2020,2010,95
Kings College London University,Cooperation2021,2010,6
Kings College London University,Religion2020,2010,18
Kings University College,Religion2020,2010,5
Kingston University,Cooperation2021,2010,2
Kingston University,Religion2020,2010,21
Kintampo Health Research Centre,Religion2020,2010,2
Kipalapala Senior Seminary,Religion2020,2010,1
Kliniken Essen-Mitte,Religion2020,2010,9
KM Behavioral Consulting,Religion2020,2010,1
Knight Analysis,Religion2020,2010,1
Knowledge Moves,Cooperation2021,2010,1
Knox College,Cooperation2021,2010,1
Kobe University,Cooperation2021,2010,15
Kobe University,Religion2020,2010,2
Koc University,Religion2020,2010,5
Kochi University of Technology,Cooperation2021,2010,8
Kohima Institute,Religion2020,2010,3
Kokugakuin University,Religion2020,2010,2
Konrad Lorenz Institute in Vienna,Cooperation2021,2010,2
Kookmin University,Religion2020,2010,1
Korea Advanced Institute of Science and Technology (KAIST),Cooperation2021,2010,11
Korea Institute for International Economic Policy,Cooperation2021,2010,5
Korea Research Institute of Chemical Technology,Cooperation2021,2010,3
Korea University,Religion2020,2010,2
Korea University Guro Hospital,Religion2020,2010,3
Kripalu Center,Cooperation2021,2010,1
Kripalu Center,Religion2020,2010,2
KU Eichstätt-Ingolstadt,Religion2020,2010,7
KU Leuven,Cooperation2021,2010,32
KU Leuven,CooperationAndReligion,2010,1
KU Leuven,Religion2020,2010,139
Kumamoto University,Cooperation2021,2010,3
Kunming Institute of Botany,Cooperation2021,2010,11
Kunsan National University,Religion2020,2010,2
Kurume University,Religion2020,2010,6
Kutztown University of Pennsylvania,Religion2020,2010,2
Kuwait University,Religion2020,2010,13
KW Counselling Services,Religion2020,2010,2
Kwansei Gakuin University,Cooperation2021,2010,2
Kwansei Gakuin University,Religion2020,2010,3
Kyoto University,Cooperation2021,2010,8
Kyoto University,Religion2020,2010,9
Kyung Hee University,Religion2020,2010,7
Kyungpook National University,Cooperation2021,2010,2
Kyushu University,Cooperation2021,2010,25
Kyushu University,Religion2020,2010,10
La Salle University,Cooperation2021,2010,7
La Salle University,Religion2020,2010,1
La Salpetriere Hospital,Cooperation2021,2010,1
La Salpetriere Hospital,Religion2020,2010,1
La Trobe University,Cooperation2021,2010,2
La Trobe University,Religion2020,2010,35
Laboratoire d’Anthropologie Sociale,Religion2020,2010,3
Laboratory for Analysis and Architecture of Systems,Cooperation2021,2010,1
labspitec at Univeristy of Valencia,Religion2020,2010,1
Lady Hardinge Medical College,Religion2020,2010,1
Lafayette University,Religion2020,2010,7
Lahore University of Management Sciences,Cooperation2021,2010,1
Lahore University of Management Sciences,CooperationAndReligion,2010,1
Lahore University of Management Sciences,Religion2020,2010,12
Laidlaw College,Religion2020,2010,1
Lake Forest College,Cooperation2021,2010,1
Lake Forest College,Religion2020,2010,11
Lamar University,Cooperation2021,2010,3
Lamar University,Religion2020,2010,1
Lancaster Theological Seminary,Religion2020,2010,5
Lancaster University,Cooperation2021,2010,11
Lancaster University,Religion2020,2010,65
Lander University,Religion2020,2010,3
Land's End,Religion2020,2010,2
Lanzhou University,Cooperation2021,2010,2
Lanzhou University,Religion2020,2010,3
Lariboisière Hospital,Cooperation2021,2010,3
LaTrobe University,Religion2020,2010,6
Laurentian University,Religion2020,2010,5
Laval University,Cooperation2021,2010,2
Laval University,Religion2020,2010,30
Lebanon Valley College,Religion2020,2010,6
Lee University,Religion2020,2010,12
Leeds Beckett University,Cooperation2021,2010,2
Leeds Beckett University,Religion2020,2010,2
Leeds Metropolitan University,Cooperation2021,2010,1
Leeds Metropolitan University,Religion2020,2010,5
Legacy Health,Religion2020,2010,1
Lehigh University,Cooperation2021,2010,1
Lehigh University,Religion2020,2010,8
Leibniz Institute for Evolution and Biodiversity Research,Cooperation2021,2010,3
Leibniz Institute of European History,Religion2020,2010,6
Leibniz University of Hanover,Religion2020,2010,2
Leibniz-Institut für Wissensmedien,Cooperation2021,2010,1
Leibniz-Institut für Wissensmedien,Religion2020,2010,2
Leibniz-Zentrum Moderner Orient,Religion2020,2010,2
Leicester Cathedral,Religion2020,2010,3
Leiden University,Cooperation2021,2010,46
Leiden University,Religion2020,2010,55
Leiden University Institute for Philosophy,Religion2020,2010,22
Leipzig University,Cooperation2021,2010,5
Leipzig University,CooperationAndReligion,2010,1
Leipzig University,Religion2020,2010,23
Leiria Polytechnic,Cooperation2021,2010,3
Leo Baeck College,Religion2020,2010,2
Leuphana University of Luneburg,Cooperation2021,2010,1
Leuphana University of Luneburg,Religion2020,2010,1
Leuven University,Religion2020,2010,7
Leviathan Security Group,Religion2020,2010,3
Lewis University,Religion2020,2010,4
Lexington Theological Seminary,Religion2020,2010,4
Libera Università Maria SS. Assunta,Cooperation2021,2010,4
Libera Università Maria SS. Assunta,Religion2020,2010,1
Liberty Fund,Cooperation2021,2010,1
Liberty Fund,Religion2020,2010,6
Liberty University,Religion2020,2010,5
Lille Catholic University,Religion2020,2010,1
Limetree Research LLC,Cooperation2021,2010,1
Limetree Research LLC,Religion2020,2010,3
Linfield University,Religion2020,2010,1
Lingnan University,Cooperation2021,2010,5
Link Care Center,Religion2020,2010,3
Linköping University,Cooperation2021,2010,8
Linköping University,CooperationAndReligion,2010,1
Linköping University,Religion2020,2010,18
Linnaeus University,Cooperation2021,2010,6
Linnaeus University,Religion2020,2010,6
Lisbon University Institute,Religion2020,2010,4
Lithuanian Cultural Research Institute,Cooperation2021,2010,1
Lithuanian Cultural Research Institute,CooperationAndReligion,2010,1
Lithuanian Cultural Research Institute,Religion2020,2010,2
Lithuanian University of Educational Sciences,Religion2020,2010,11
Liverpool Hope University,Religion2020,2010,26
Liverpool John Moores University,Cooperation2021,2010,6
Liverpool John Moores University,Religion2020,2010,8
Llywodraeth Cymru/Welsh Government,Religion2020,2010,4
LMU Munich,Cooperation2021,2010,1
LMU Munich,CooperationAndReligion,2010,1
LMU Munich,Religion2020,2010,12
LoKation® Real Estate,Religion2020,2010,6
Loma Linda University,Religion2020,2010,31
London Health Sciences Centre,Religion2020,2010,2
London Interdisciplinary School,Cooperation2021,2010,1
London Metropolitan University,Cooperation2021,2010,14
London Metropolitan University,CooperationAndReligion,2010,1
London Metropolitan University,Religion2020,2010,37
London School of Economics,Cooperation2021,2010,9
London School of Economics,CooperationAndReligion,2010,3
London School of Economics,Religion2020,2010,36
London School of Economics and Political Science,Cooperation2021,2010,15
London School of Economics and Political Science,CooperationAndReligion,2010,1
London School of Economics and Political Science,Religion2020,2010,2
London School of Hygiene and Tropical Medicine,Cooperation2021,2010,24
London School of Hygiene and Tropical Medicine,CooperationAndReligion,2010,2
London School of Hygiene and Tropical Medicine,Religion2020,2010,10
London School of Theology,Religion2020,2010,2
London South Bank University,Cooperation2021,2010,6
London University,Cooperation2021,2010,1
London University,Religion2020,2010,5
Los Angeles First Church of the Nazarene,Religion2020,2010,3
Los Angeles Valley College,Religion2020,2010,2
Loughborough University,Cooperation2021,2010,8
Loughborough University,Religion2020,2010,15
Louisiana State University,Cooperation2021,2010,2
Louisiana State University,Religion2020,2010,31
Louisville Institute,Cooperation2021,2010,3
Louisville Institute,Religion2020,2010,1
Louisville Presbyterian Theological Seminary,Religion2020,2010,3
Lovisenberg Diaconal College,Religion2020,2010,1
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,2010,1
Loyola Marymount University,Religion2020,2010,56
Loyola School of Theology,Religion2020,2010,13
Loyola University Chicago,Cooperation2021,2010,1
Loyola University Chicago,Religion2020,2010,54
Loyola University Maryland,Cooperation2021,2010,2
Loyola University Maryland,CooperationAndReligion,2010,1
Loyola University Maryland,Religion2020,2010,73
Loyola University New Orleans,Cooperation2021,2010,1
Loyola University New Orleans,Religion2020,2010,19
LSU Health Sciences Center/Our Lady of the Lake,Religion2020,2010,1
Ltrek,Religion2020,2010,5
Ludwig Maximilian University of Munich,Cooperation2021,2010,10
Ludwig Maximilian University of Munich,Religion2020,2010,15
Ludwig-Maximilians-University of Munich,Cooperation2021,2010,1
Ludwig-Maximilians-University of Munich,Religion2020,2010,26
LUISS Guido Carli University,Religion2020,2010,6
Luiss University Rome,Cooperation2021,2010,12
Lumiere University Lyon 2,Religion2020,2010,1
Lund University,Cooperation2021,2010,18
Lund University,Religion2020,2010,13
Luther College,Religion2020,2010,16
Luther Seminary,Religion2020,2010,12
Luther Theological Seminary,Religion2020,2010,3
Lutheran School of theology at Chicago,Religion2020,2010,6
Lutheran Theological Seminary,Religion2020,2010,4
Lutheran Theological Seminary at Philadelphia,Religion2020,2010,8
Lutheran Theological Southern Seminary,Religion2020,2010,5
Maastricht University,Cooperation2021,2010,39
Maastricht University,Religion2020,2010,11
Macalaster College,Religion2020,2010,5
Macalester College,Cooperation2021,2010,3
Macau University of Science and Technology,Cooperation2021,2010,2
Macau University of Science and Technology,Religion2020,2010,1
Macedonian Academy of Arts and Sciences,Cooperation2021,2010,3
Macquarie University,Cooperation2021,2010,15
Macquarie University,CooperationAndReligion,2010,2
Macquarie University,Religion2020,2010,41
Magdalene College,Cooperation2021,2010,14
Magdalene College,Religion2020,2010,1
Mahana Theraputics,Cooperation2021,2010,5
Maharishi International University,Religion2020,2010,1
Maharishi Markandeshwar University,Religion2020,2010,6
Maharishi University of Management,Religion2020,2010,17
Mahidol University,Cooperation2021,2010,3
Mahidol University,Religion2020,2010,11
Mail & Guardian,Religion2020,2010,5
Maimonides Medical Center,Religion2020,2010,5
Makerere University,Cooperation2021,2010,1
Makerere University,Religion2020,2010,15
Malardalen University,Cooperation2021,2010,4
Malardalen University,Religion2020,2010,3
Malmberg,Religion2020,2010,3
Malmo University,Religion2020,2010,1
Malmö University,Cooperation2021,2010,2
Malmö University,Religion2020,2010,1
Management Development Institute,Religion2020,2010,3
Manchester Cathedral,Religion2020,2010,4
Manchester Metropolitan University,Cooperation2021,2010,8
Manchester Metropolitan University,Religion2020,2010,7
Manchester University,Cooperation2021,2010,1
Manchester University,Religion2020,2010,3
Manhattan College,Cooperation2021,2010,6
Manhattan College,Religion2020,2010,13
Mansoura University,Cooperation2021,2010,2
Marburg University,Religion2020,2010,5
Maria Curie-Sklodowska University,Cooperation2021,2010,1
Maria Curie-Sklodowska University,Religion2020,2010,3
Marian University,Religion2020,2010,2
Marie Curie Hospice Homestead,Cooperation2021,2010,1
Marie Curie Hospice Homestead,CooperationAndReligion,2010,1
Marie Curie Hospice Homestead,Religion2020,2010,2
Marquette University,Cooperation2021,2010,2
Marquette University,Religion2020,2010,26
Marshall University,Religion2020,2010,1
Martin Luther University of Halle-Wittenberg,Cooperation2021,2010,2
Martin Luther University of Halle-Wittenberg,Religion2020,2010,7
Maryland University of Integrative Health,Religion2020,2010,3
Marymount Manhattan College,Religion2020,2010,13
Marymount University,Religion2020,2010,1
Maryville College,Religion2020,2010,3
Masaryk University,Religion2020,2010,1
Mashhad University,Religion2020,2010,2
Massachusetts General Hospital,Cooperation2021,2010,2
Massachusetts General Hospital,Religion2020,2010,22
Massachusetts Institute of Technology,Cooperation2021,2010,70
Massachusetts Institute of Technology,CooperationAndReligion,2010,3
Massachusetts Institute of Technology,Religion2020,2010,14
Massachusetts Medical Society,Cooperation2021,2010,1
Massachusetts Medical Society,Religion2020,2010,5
Massey University,Cooperation2021,2010,5
Massey University,Religion2020,2010,47
Masters and Johnson,Religion2020,2010,4
Mater Dei Hospital,Religion2020,2010,3
Mater Misericordiae University Hospital,Religion2020,2010,2
Maulana Malik Ibrahim State Islamic University,Religion2020,2010,1
Max Planck Institute,Cooperation2021,2010,26
Max Planck Institute,CooperationAndReligion,2010,1
Max Planck Institute,Religion2020,2010,13
max planck Institute for Brain Research,Religion2020,2010,1
Max Planck Institute for Chemical Ecology,Cooperation2021,2010,4
Max Planck Institute for Comparative Public Law and International Law,Cooperation2021,2010,3
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Cooperation2021,2010,7
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Religion2020,2010,1
Max Planck Institute for Evolutionary Biology,Cooperation2021,2010,36
Max Planck Institute for Human Cognitive and Brain Sciences,Cooperation2021,2010,2
Max Planck Institute for Human Cognitive and Brain Sciences,Religion2020,2010,8
Max Planck Institute for Human Development,Cooperation2021,2010,11
Max Planck Institute for Human Development,Religion2020,2010,6
Max Planck Institute for Ornithology,Religion2020,2010,3
Max Planck Institute for Psychological Research,Cooperation2021,2010,1
Max Planck Institute for Research on Collective Goods,Cooperation2021,2010,9
Max Planck Institute for Social Anthropology,Religion2020,2010,10
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,2010,1
Max Planck Institute for the Science of Human History,Cooperation2021,2010,4
Max Planck Institute for the Science of Human History,CooperationAndReligion,2010,2
Max Planck Institute for the Science of Human History,Religion2020,2010,5
Max Planck Institute for the Study of Religious and Ethnic Diversity,Religion2020,2010,22
Max Weber Center for Advanced Cultural and Social Scudies,Cooperation2021,2010,1
Max Weber Center for Advanced Cultural and Social Scudies,CooperationAndReligion,2010,1
Max Weber Center for Advanced Cultural and Social Scudies,Religion2020,2010,1
Maynooth University,Cooperation2021,2010,1
Maynooth University,Religion2020,2010,9
Mayo Clinic - Phoenix,Cooperation2021,2010,2
Mayo Clinic - Phoenix,CooperationAndReligion,2010,1
Mayo Clinic - Phoenix,Religion2020,2010,7
Mayo Clinic - Rochester,Cooperation2021,2010,4
Mayo Clinic - Rochester,Religion2020,2010,17
Mayo Clinic College of Medicine and Science,Cooperation2021,2010,4
Mayo Clinic School of Medicine,Cooperation2021,2010,2
Mayo Clinic School of Medicine,CooperationAndReligion,2010,1
Mayo Clinic School of Medicine,Religion2020,2010,34
Mayo Foundation for Medical Education and Research,Religion2020,2010,5
McCann Enterprise,Cooperation2021,2010,3
McCann Enterprise,CooperationAndReligion,2010,3
McCann Enterprise,Religion2020,2010,3
McDaniel College,Religion2020,2010,5
McGill University,Cooperation2021,2010,3
McGill University,Religion2020,2010,65
McLean Hospital,Cooperation2021,2010,2
McLean Hospital,Religion2020,2010,11
McMaster Divinity School,Religion2020,2010,2
McMaster University,Cooperation2021,2010,10
McMaster University,Religion2020,2010,37
Medica Campestre,Cooperation2021,2010,8
Medica Campestre,CooperationAndReligion,2010,6
Medica Campestre,Religion2020,2010,8
Medical Academy of Warsaw,Cooperation2021,2010,2
Medical College of Georgia,Religion2020,2010,1
Medical College of Wisconsin,Cooperation2021,2010,2
Medical College of Wisconsin,CooperationAndReligion,2010,1
Medical College of Wisconsin,Religion2020,2010,23
Medical University of Graz,Religion2020,2010,8
Medical University of Lübeck,Religion2020,2010,1
Medical University of Lublin,Cooperation2021,2010,1
Medical University of Lublin,CooperationAndReligion,2010,1
Medical University of Lublin,Religion2020,2010,3
Medical University of Poznan,Religion2020,2010,20
Medical University of Silesia,Religion2020,2010,1
Medical University of South Carolina,Religion2020,2010,13
Medical University of Vienna,Religion2020,2010,5
Medipol University,Cooperation2021,2010,1
Meertens Instituut (KNAW),Religion2020,2010,2
MeiHo University,Religion2020,2010,1
Meiji University,Cooperation2021,2010,2
Memorial Sloan Kettering Cancer Center,Cooperation2021,2010,8
Memorial Sloan Kettering Cancer Center,CooperationAndReligion,2010,1
Memorial Sloan Kettering Cancer Center,Religion2020,2010,50
Memorial University of Newfoundland,Religion2020,2010,5
Mens Sana Monographs,Religion2020,2010,2
Mental Health Center,Religion2020,2010,3
Mental Health Christoph,Religion2020,2010,1
Mercer University,Cooperation2021,2010,1
Mercer University,Religion2020,2010,8
Mercy Health,Religion2020,2010,4
Merrimack College,Religion2020,2010,3
Messerli Research Institute,Cooperation2021,2010,1
Messiah College,Cooperation2021,2010,1
Messiah College,CooperationAndReligion,2010,1
Messiah College,Religion2020,2010,9
Methodist Homes (MHA),Religion2020,2010,2
Methodist Theological School,Religion2020,2010,2
Methodist University,Religion2020,2010,4
Methodist University of Sao Paulo,Religion2020,2010,9
Metropolitan State University,Religion2020,2010,7
Mexican Institute of Social Security,Cooperation2021,2010,6
Mexican Institute of Social Security,CooperationAndReligion,2010,4
Mexican Institute of Social Security,Religion2020,2010,6
"MF Norwegian School of Theology, Religion, and Society",Cooperation2021,2010,1
"MF Norwegian School of Theology, Religion, and Society",Religion2020,2010,21
MGH Institute of Health Professions,Religion2020,2010,5
Miami University,Cooperation2021,2010,3
Miami University,CooperationAndReligion,2010,1
Miami University,Religion2020,2010,93
Miami Veterans Administration Hospital,Religion2020,2010,1
Michael E. DeBakey VA Medical Center,Religion2020,2010,3
Michael E. DeBakey VAMC,Religion2020,2010,11
Michigan State University,Cooperation2021,2010,14
Michigan State University,Religion2020,2010,42
Michlalah Jersusalem Academic College,Cooperation2021,2010,1
Microsoft,Cooperation2021,2010,12
"Microsoft Research, Israel",Cooperation2021,2010,1
Middle East Cancer Consortium,Religion2020,2010,5
Middle Tennesse State University,Religion2020,2010,6
Middlebury College,Cooperation2021,2010,6
Middlebury College,Religion2020,2010,5
Middlesex University,Cooperation2021,2010,9
Middlesex University,CooperationAndReligion,2010,1
Middlesex University,Religion2020,2010,10
Midland Centre for Neurosurgery and Neurology,Religion2020,2010,1
Millersville State College,Religion2020,2010,2
Milligan College - Emmanuel School of Religion,Religion2020,2010,2
"Milltown Institute, Dublin",Religion2020,2010,4
Milwaukee County Mental Health Complex,Religion2020,2010,1
Mind and Life Institute,Religion2020,2010,11
"Mindfulness, Compassion and Yoga Based Therapies",Religion2020,2010,3
Mindstream,Religion2020,2010,2
Ministry of Finance,Religion2020,2010,6
"Ministry of Health, Iran",Religion2020,2010,1
"Ministry of health, Israel",Cooperation2021,2010,1
"Ministry of health, Israel",CooperationAndReligion,2010,1
"Ministry of health, Israel",Religion2020,2010,2
Ministry of Religious Affairs of the Republic of Indonesia,Religion2020,2010,2
Minneapolis VA Health Care System,Religion2020,2010,3
Miriam Hospital,Religion2020,2010,1
Mission Economic Development Agency,Religion2020,2010,5
Mississippi College,Cooperation2021,2010,1
Mississippi College,Religion2020,2010,3
Mississippi State University,Cooperation2021,2010,1
Mississippi State University,Religion2020,2010,3
Missouri State University,Cooperation2021,2010,7
Missouri State University,Religion2020,2010,17
Missouri University of Science and Technology,Cooperation2021,2010,1
Missouri Western State University,Religion2020,2010,1
MIT Media Lab,Cooperation2021,2010,5
MIT Media Lab,Religion2020,2010,4
MJHS Institute for Innovation and Palliative Care,Religion2020,2010,2
Moffitt Cancer Center and Research Institute,Cooperation2021,2010,2
Moffitt Cancer Center and Research Institute,Religion2020,2010,14
Mohammad V University,Religion2020,2010,9
Mon Ethos Pro,Religion2020,2010,1
Monash University,Cooperation2021,2010,54
Monash University,CooperationAndReligion,2010,2
Monash University,Religion2020,2010,146
Monash University Malaysia,Cooperation2021,2010,1
Monash University Malaysia,Religion2020,2010,2
Monmouth College,Religion2020,2010,4
Montefiore Medical Center,Religion2020,2010,1
Montefiore-Einstein Center for Cancer Care,Religion2020,2010,2
Montreal School of Theology,Religion2020,2010,1
Moody Bible Institute,Religion2020,2010,6
"Moravian Church Diabetes Clinics, Bluefields and  Pearl Lagoon",Religion2020,2010,2
Moravian Theological Seminary,Religion2020,2010,6
Morehead State University,Religion2020,2010,4
Morehouse College,Cooperation2021,2010,11
Morehouse School of Medicine,Religion2020,2010,5
Morgan State University,Religion2020,2010,1
Mormon Stories Podcast,Religion2020,2010,2
Mount Holyoke College,Cooperation2021,2010,1
Mount Royal University,Religion2020,2010,27
Mount Saint Mary's University,Religion2020,2010,24
Mount Saint Vincent University,Religion2020,2010,6
Mount San Antonio College,Religion2020,2010,1
Mount Sinai School of Medicine,Cooperation2021,2010,2
Mount Sinai School of Medicine,Religion2020,2010,5
Mount Vernon Hospital,Religion2020,2010,3
Moving Byzantium,Religion2020,2010,2
MRC/UVRI Uganda Research Unit,Religion2020,2010,4
Mt. Auburn Hospital,Religion2020,2010,1
Mt. Sinai School of Medicine,Religion2020,2010,2
MTs Babussalam Pagelaran Malang,Religion2020,2010,1
Muhlenberg College,Religion2020,2010,2
Munich School of Philosophy,Religion2020,2010,12
Murdoch University,Cooperation2021,2010,1
Murdoch University,CooperationAndReligion,2010,1
Murdoch University,Religion2020,2010,6
Murray Edwards College,Cooperation2021,2010,3
Murray Edwards College,CooperationAndReligion,2010,2
Murray Edwards College,Religion2020,2010,15
Murray State,Cooperation2021,2010,1
Museo Nacional de Ciencias Naturales,Cooperation2021,2010,2
Museum for Cultural History,Cooperation2021,2010,2
Museum for Cultural History,Religion2020,2010,2
Museum für Naturkunde Berlin,Cooperation2021,2010,4
Myriad Neuroscience,Cooperation2021,2010,1
Myriad Neuroscience,Religion2020,2010,2
Nagasaki University,Cooperation2021,2010,1
Nagasaki University,Religion2020,2010,3
Nagoya City University,Religion2020,2010,5
Nagoya Institute of Technology,Cooperation2021,2010,4
Nagoya University,Cooperation2021,2010,13
Nagoya University,Religion2020,2010,1
Nalanda Institute for Contemplative Science,Religion2020,2010,2
Nanjing Normal University,Cooperation2021,2010,5
Nanjing Normal University,Religion2020,2010,1
Nanjing University,Cooperation2021,2010,17
Nanjing University,Religion2020,2010,15
Nanjing University of Aeronautics and Astronautics,Cooperation2021,2010,2
Nanjing University of Aeronautics and Astronautics,Religion2020,2010,1
Nanjing University of Posts and Telecommunications,Cooperation2021,2010,12
Nankai University,Cooperation2021,2010,15
Nanyang Technological University,Cooperation2021,2010,20
Nanyang Technological University,CooperationAndReligion,2010,1
Nanyang Technological University,Religion2020,2010,39
Naropa University,Religion2020,2010,2
Nashotah House,Religion2020,2010,7
National Academy of Sciences of Ukraine,Religion2020,2010,4
National Association for the Advancement of Psychoanalysis,Religion2020,2010,9
National Autonomous University of Mexico,Cooperation2021,2010,4
National Autonomous University of Mexico,Religion2020,2010,1
National Cancer Institute,Religion2020,2010,9
National Cancer Institute of Milan,Religion2020,2010,9
National Cancer Research Center,Cooperation2021,2010,3
National Center for Dual Diagnoses,Religion2020,2010,5
National Center for Nanoscience and Technology,Cooperation2021,2010,3
National Center for PTSD,Religion2020,2010,5
National Center for Science Education,Religion2020,2010,3
National Center for Veterans Studies,Religion2020,2010,10
National Center of Neurology and Psychiatry,Religion2020,2010,10
National Central University,Religion2020,2010,7
National Centre for Immunization Research and Surveillance of Vaccine Preventable Diseases,Religion2020,2010,1
National Centre of Medicine and Science in Sports,Religion2020,2010,1
National Cerebral and Cardiovascular Center,Cooperation2021,2010,1
National Cheng Kung University,Religion2020,2010,1
National Chiao Tung University,Religion2020,2010,4
National Church Life Survey,Cooperation2021,2010,1
National Conservatory of Arts and Trades,Cooperation2021,2010,5
National Council for Scientific and Technological Development,Religion2020,2010,7
National Counterterrorism Agency,Religion2020,2010,3
National Distance Education University,Cooperation2021,2010,1
National Distance Education University,Religion2020,2010,1
National Economic Research Institute,Cooperation2021,2010,1
National Economic Research Institute,Religion2020,2010,4
National Geographic,Religion2020,2010,3
"National Heart, Lung and Blood Institute",Religion2020,2010,1
National Insitute for Health Research,Cooperation2021,2010,3
National Insitute for Health Research,CooperationAndReligion,2010,3
National Insitute for Health Research,Religion2020,2010,3
National Institue for Mental Health in England,Religion2020,2010,18
national institute for advanced industrial science and technology,Cooperation2021,2010,1
national institute for advanced industrial science and technology,Religion2020,2010,2
"National Institute for Health and Welfare, Finland",Cooperation2021,2010,2
"National Institute for Health and Welfare, Finland",Religion2020,2010,1
National Institute for Healthcare Research,Cooperation2021,2010,1
National Institute for Healthcare Research,Religion2020,2010,12
National Institute for Mathematical Sciences,Cooperation2021,2010,2
National Institute for Mental Health and Neurosciences,Religion2020,2010,10
National Institute for Psychotherapies,Religion2020,2010,10
National institute of Child Health,Cooperation2021,2010,2
National Institute of Child Health & Human Development,Cooperation2021,2010,1
National Institute of Education – Singapore,Religion2020,2010,1
National Institute of Environmental Research,Religion2020,2010,9
National Institute of Health and Science on Aging,Religion2020,2010,1
National Institute of Industrial Engineering,Cooperation2021,2010,3
National Institute of Mental Health,Cooperation2021,2010,2
National Institute of Mental Health and Neuro Science,Religion2020,2010,1
National Institute of Mental Health and Neuro Sciences,Religion2020,2010,1
National Institute of Mental Health and Neurosciences,Religion2020,2010,7
National Institute Of Mental Health Harvard Medical School,Cooperation2021,2010,1
National Institute Of Mental Health Harvard Medical School,CooperationAndReligion,2010,1
National Institute Of Mental Health Harvard Medical School,Religion2020,2010,8
National Institute of Siddha Medical Sciences,Religion2020,2010,2
National Institute on Aging,Religion2020,2010,1
National Institute on Deafness and Other Communication Disorders,Cooperation2021,2010,1
National Institutes of Health,Cooperation2021,2010,2
National Institutes of Health,Religion2020,2010,8
National Kaohsiung Normal University,Religion2020,2010,3
National Library of Israel,Religion2020,2010,8
National Museum of Denmark,Religion2020,2010,2
National Museum of Ethnology,Religion2020,2010,1
National Museum of Natural Sciences,Cooperation2021,2010,2
National Palliative Care Research Center,Religion2020,2010,3
National Psychological Association for Psychoanalysis,Religion2020,2010,12
National Public Radio,Cooperation2021,2010,1
National Public Radio,Religion2020,2010,3
National Renewable Energy Laboratory,Cooperation2021,2010,1
National Renewable Energy Laboratory,CooperationAndReligion,2010,1
National Renewable Energy Laboratory,Religion2020,2010,10
National Research Council,Cooperation2021,2010,7
National Research Council of Italy,Cooperation2021,2010,4
National Research Council of Italy,Religion2020,2010,2
National Research University Higher School of Economics,Cooperation2021,2010,2
National Research University Higher School of Economics,Religion2020,2010,6
National Science Foundation,Cooperation2021,2010,1
National Science Foundation,Religion2020,2010,5
National Scientific and Technical Research Council,Religion2020,2010,2
National Study of Treatment and Addiction Recovery Residences,Religion2020,2010,1
National Taichung University of Science and Technology,Religion2020,2010,2
National Taipei University,Religion2020,2010,5
National Taiwan Sport University,Religion2020,2010,2
National Taiwan University,Cooperation2021,2010,3
National Taiwan University,Religion2020,2010,7
National Technical University of Athens,Cooperation2021,2010,2
National University of Colombia,Cooperation2021,2010,7
National University of Colombia,Religion2020,2010,1
National University of Distance Education,Cooperation2021,2010,1
National University of Distance Education,Religion2020,2010,1
National University of Ireland,Cooperation2021,2010,2
National University of Ireland,Religion2020,2010,14
"National University of Ireland, Galway",Cooperation2021,2010,2
National University of Malaysia,Cooperation2021,2010,2
National University of Singapore,Cooperation2021,2010,24
National University of Singapore,Religion2020,2010,49
National Yang-Ming University,Cooperation2021,2010,3
National Yunlin University of Science and Technology,Religion2020,2010,7
Natural Sciences and Engineering Research Council of Canada,Cooperation2021,2010,4
Nature,Cooperation2021,2010,1
Nature,Religion2020,2010,1
Nature Publishing Group,Cooperation2021,2010,2
Nature Publishing Group,Religion2020,2010,1
Naval Amphibious Base Coronado,Religion2020,2010,17
Naval War College,Cooperation2021,2010,1
Naval War College,Religion2020,2010,3
Nazarbayev University,Religion2020,2010,1
Nazarene Theological Seminary,Religion2020,2010,3
NCLS Research,Religion2020,2010,3
Nebraska Occupational Health Center,Religion2020,2010,2
NEC Labs,Cooperation2021,2010,1
NEC Labs,Religion2020,2010,1
Nelson Mandela Metropolitan University,Religion2020,2010,3
Netanya University,Religion2020,2010,1
Netherlands Institute for Advanced Study in the Humanities and Social Sciences,Religion2020,2010,2
Netherlands Institute of Ecology,Cooperation2021,2010,2
Netherlands Interdiscplinary Demographic Institute,Religion2020,2010,2
Neumann University,Religion2020,2010,1
Neuroelectrics,Religion2020,2010,2
NeuroMyth,Religion2020,2010,5
Neuroscience Institute-El Camino Hospital,Religion2020,2010,2
NEURO-SYS,Cooperation2021,2010,1
New College of California,Religion2020,2010,3
New England Complex Systems Institute,Cooperation2021,2010,1
New England Complex Systems Institute,Religion2020,2010,4
New England School of Acupuncture,Cooperation2021,2010,1
New England School of Acupuncture,Religion2020,2010,1
New Mexico State University,Religion2020,2010,1
New School for Social Research,Religion2020,2010,2
New York City College of Technology,Religion2020,2010,3
New York Medical College,Religion2020,2010,1
New York Presbyterian Hospital,Religion2020,2010,2
New York Psychosynthesis Institute,Religion2020,2010,2
New York State Psychiatric Institute,Religion2020,2010,1
New York Theological Seminary,Religion2020,2010,1
New York University,Cooperation2021,2010,33
New York University,CooperationAndReligion,2010,1
New York University,Religion2020,2010,97
New York University Abu Dhabi,Religion2020,2010,26
New York University Endocrine Associates,Religion2020,2010,2
New York University School of Medicine,Cooperation2021,2010,2
Newcastle University,Cooperation2021,2010,9
Newcastle University,Religion2020,2010,20
newham college,Religion2020,2010,1
NHS Foundation,Religion2020,2010,8
"NHS Foundation, Lancashire Care",Religion2020,2010,1
Niagara County Community College,Cooperation2021,2010,3
Niagara County Community College,Religion2020,2010,3
NICM (National Institute of Complementary Medicine) Western Sydney University,Religion2020,2010,3
Nigerian Institute of Advanced Legal Studies,Religion2020,2010,3
NIH Clinical Center,Cooperation2021,2010,1
NIH Clinical Center,Religion2020,2010,5
Nihon University,Cooperation2021,2010,5
Nihon University,Religion2020,2010,2
Nihon University School of Medicine,Religion2020,2010,1
NIMH Family Research Consortium IV- UCLA,Cooperation2021,2010,2
NIMH Family Research Consortium IV- UCLA,Religion2020,2010,1
Ningxia Medical University,Religion2020,2010,2
Ningxia University,Cooperation2021,2010,3
NIVEL,Religion2020,2010,1
Njala University,Cooperation2021,2010,1
Njala University,Religion2020,2010,3
Noona by Varian Medical Systems,Religion2020,2010,2
Nord University,Religion2020,2010,12
Nordic School of Holistic Medicine,Cooperation2021,2010,1
North American Lutheran Seminary,Religion2020,2010,3
North Carolina State University,Religion2020,2010,30
North Carolina Wesleyan University,Religion2020,2010,1
North China Electric Power University,Religion2020,2010,11
North Dakota State University,Cooperation2021,2010,2
North Dakota State University,Religion2020,2010,8
North Park University,Religion2020,2010,5
North West University,Religion2020,2010,21
Northeastern Seminary,Religion2020,2010,7
Northeastern University,Cooperation2021,2010,16
Northeastern University,Religion2020,2010,28
"Northeastern University (Shenyang, China)",Cooperation2021,2010,2
Northern Arizona University,Religion2020,2010,11
Northern Illinois University,Religion2020,2010,12
Northern Roots Therapy Center,Religion2020,2010,2
Northern Sydney Local Health District,Religion2020,2010,1
Northumbria University,Cooperation2021,2010,2
Northumbria University,Religion2020,2010,2
Northwell Health,Religion2020,2010,5
Northwest University,Religion2020,2010,21
North-West University,Cooperation2021,2010,1
North-West University,Religion2020,2010,29
Northwestern College,Religion2020,2010,2
Northwestern Medicine,Religion2020,2010,1
Northwestern University,Cooperation2021,2010,16
Northwestern University,Religion2020,2010,81
Northwood University,Religion2020,2010,1
Norwegian Institute of International Affairs,Cooperation2021,2010,2
Norwegian Institute of International Affairs,Religion2020,2010,2
Norwegian Institute of Public Health,Religion2020,2010,6
Norwegian Ministry of Foreign Affaris,Cooperation2021,2010,1
Norwegian School of Theology,Religion2020,2010,12
Norwegian University of Science and Technology,Cooperation2021,2010,15
Norwegian University of Science and Technology,CooperationAndReligion,2010,1
Norwegian University of Science and Technology,Religion2020,2010,57
Norweign School of Theology,Religion2020,2010,5
Norwich University,Religion2020,2010,2
Notre Dame College,Religion2020,2010,5
Nottingham Trent University,Religion2020,2010,29
Nova Southeastern University,Cooperation2021,2010,3
Nova Southeastern University,Religion2020,2010,21
NOVA University of Lisbon,Cooperation2021,2010,6
Novartis,Religion2020,2010,2
Nove de Julho University,Religion2020,2010,6
Novosibirsk State University,Religion2020,2010,1
NPO-Interational Research Institute,Religion2020,2010,1
Nurses Christian Fellowship,Religion2020,2010,3
NVIDIA,Cooperation2021,2010,3
NVIDIA,Religion2020,2010,1
Nyenrode Business University,Religion2020,2010,4
NYU Grossman School of Medicine,Cooperation2021,2010,1
NYU Grossman School of Medicine,Religion2020,2010,3
Oakland University,Cooperation2021,2010,3
Oakland University,Religion2020,2010,18
Oakwood University,Religion2020,2010,3
Obafemi Awolowo University,Religion2020,2010,2
Oberlin College,Religion2020,2010,4
ObjectVideo Labs,Cooperation2021,2010,4
ObjectVideo Labs,Religion2020,2010,1
"Ochsner Health, Institute for Medicine, Education, and Spirituality",Religion2020,2010,1
Odense University,Cooperation2021,2010,1
Ohio Health,Religion2020,2010,3
Ohio Northern University,Religion2020,2010,18
Ohio State University,Cooperation2021,2010,25
Ohio State University,Religion2020,2010,74
Ohio University,Religion2020,2010,12
Okayama University,Cooperation2021,2010,9
Okayama University,Religion2020,2010,1
Oklahoma State University,Religion2020,2010,19
Old Dominion University,Religion2020,2010,5
Omega Graduate School,Cooperation2021,2010,1
Omega Graduate School,Religion2020,2010,8
ONE Research Institute,Religion2020,2010,10
OneOncology,Religion2020,2010,3
Ono Academic College,Religion2020,2010,1
Open University (The),Cooperation2021,2010,1
Open University (The),Religion2020,2010,55
Open University of Cyprus,Cooperation2021,2010,3
Open University of Cyprus,Religion2020,2010,2
Opole University,Religion2020,2010,2
Oral Roberts University,Religion2020,2010,9
Orange Labs,Cooperation2021,2010,1
Oranim College of Education,Religion2020,2010,5
Oregon College of Oriental Medicine,Religion2020,2010,1
Oregon Health & Science University,Religion2020,2010,16
Oregon Research Institute,Cooperation2021,2010,1
Oregon Research Institute,Religion2020,2010,1
Oregon State University,Cooperation2021,2010,2
Oregon State University,Religion2020,2010,12
Organisation for Economic Co-operation and Development,Cooperation2021,2010,3
Orot Israel College,Religion2020,2010,1
Orthodox Church,Religion2020,2010,4
Osaka Prefecture University,Cooperation2021,2010,8
Osaka Savings Credit Union,Religion2020,2010,3
Osaka University Graduate School,Cooperation2021,2010,1
Osaka University Graduate School,Religion2020,2010,4
Oslo Metropolitan University,Religion2020,2010,1
Oslo University,Religion2020,2010,6
Osmania University,Religion2020,2010,2
Østfold University College,Religion2020,2010,1
Oswaldo Cruz Foundation,Cooperation2021,2010,3
Otago University,Religion2020,2010,2
Ottawa Hospital Research Institute,Cooperation2021,2010,1
Otto-von-Guericke University Magdeburg,Cooperation2021,2010,4
Otto-von-Guericke-Universität,Religion2020,2010,1
Oxford Brookes University,Religion2020,2010,40
Oxford Centre for Mission Studies,Religion2020,2010,1
Oxford University,Cooperation2021,2010,21
Oxford University,CooperationAndReligion,2010,1
Oxford University,Religion2020,2010,100
Pace University,Religion2020,2010,1
Pace Univesity,Religion2020,2010,4
Pacific College of Health and Science,Religion2020,2010,1
Pacific Lutheran University,Religion2020,2010,7
Pacific Neuroscience Medical Group,Religion2020,2010,1
Pacific School of Religion,Religion2020,2010,1
Pacific University,Religion2020,2010,10
Pacifica Graduate Institute,Religion2020,2010,22
Padjadjaran University,Cooperation2021,2010,2
Padjadjaran University,CooperationAndReligion,2010,1
Padjadjaran University,Religion2020,2010,7
Pakistan Institute of Development Economics,Cooperation2021,2010,6
"Palacky University, Olomouc",Religion2020,2010,50
Palo Alto University,Religion2020,2010,20
Panjab University,Religion2020,2010,3
Panteion University of Social and Political Sciences,Religion2020,2010,9
Pantheon-Sorbonne University,Religion2020,2010,18
Paracelsus Medical University,Religion2020,2010,1
Pardee RAND Graduate School,Religion2020,2010,10
Parent Spring,Religion2020,2010,3
Parexel,Religion2020,2010,1
Paris Descarte University,Cooperation2021,2010,1
Paris Descarte University,Religion2020,2010,5
Paris Diderot University,Religion2020,2010,2
Paris School of Economics,Cooperation2021,2010,1
Paris Sud University,Cooperation2021,2010,1
Paris Sud University,CooperationAndReligion,2010,1
Paris Sud University,Religion2020,2010,4
Paris West University Nanterre La Défense,Religion2020,2010,2
Paris-Sorbonne University,Religion2020,2010,8
Park University,Religion2020,2010,11
Parkhust Associates Mental Health Services,Religion2020,2010,9
Pasteur Institute,Cooperation2021,2010,1
Patanjali Food and Herbal Park,Religion2020,2010,5
Patanjali Research Foundation,Religion2020,2010,6
Patanjali Yogpeeth,Religion2020,2010,19
Paul Sabatier University,Religion2020,2010,6
Paulista State University Júlio de Mesquita Filho,Religion2020,2010,2
Pazmny Peter Catholic University BTK,Religion2020,2010,1
Peace Research Institute Frankfurt,Religion2020,2010,4
Peace Research Institute Oslo PRIO,Cooperation2021,2010,4
PEAK Lab,Religion2020,2010,2
PEAR Institute,Cooperation2021,2010,2
Peking University,Cooperation2021,2010,46
Peking University,Religion2020,2010,15
Peking University Cancer Hospital and Institute,Cooperation2021,2010,2
Peking University Cancer Hospital and Institute,Religion2020,2010,1
Peking University People's Hospital,Cooperation2021,2010,1
"Peking University, Beijing",Religion2020,2010,1
Penn State University,Cooperation2021,2010,1
Penn State University,Religion2020,2010,22
Penn State York,Religion2020,2010,4
PennState Harrisburg,Religion2020,2010,8
Pennsylvania State University,Cooperation2021,2010,16
Pennsylvania State University,CooperationAndReligion,2010,1
Pennsylvania State University,Religion2020,2010,56
Pentecostal Theological Seminary,Religion2020,2010,5
Pepperdine University,Religion2020,2010,20
Perdana University,Religion2020,2010,4
Peres Academic Center,Religion2020,2010,4
Perimeter Institute for Theoretical Physics,Cooperation2021,2010,4
Persada Indonesia University,Religion2020,2010,1
Péter Pázmány Catholic University,Religion2020,2010,1
Pew Research Center,Religion2020,2010,6
Philadelphia Corporation for Aging,Cooperation2021,2010,1
Philipps University Marburg,Religion2020,2010,11
Philipps-Universität Marburg,Cooperation2021,2010,3
Phillips University,Religion2020,2010,1
Philosophical-Theological University of Munster,Religion2020,2010,1
Philosophical-Theological University Sankt Georgen,Religion2020,2010,3
Philosophy Institute of the Ukrainian Academy of Sciences,Religion2020,2010,5
Phoenix Australia - Centre for Posttraumatic Mental Health,Religion2020,2010,1
Piedmont Virginia Community College,Religion2020,2010,1
Pima Community College,Religion2020,2010,1
PIRE,Cooperation2021,2010,3
PIRE,Religion2020,2010,2
Pittsburgh Theological Seminary,Religion2020,2010,7
Pitzer College,Religion2020,2010,4
PLA University of Science and Technology,Cooperation2021,2010,3
Planned Parenthood,Religion2020,2010,4
Pohang University of Science and Technology,Cooperation2021,2010,5
Point Loma Nazarene University,Religion2020,2010,11
Polish Academy of Sciences,Religion2020,2010,5
Politecnico di Milano,Religion2020,2010,5
Pollack Peace,Religion2020,2010,1
Polytechnic University of Madrid,Cooperation2021,2010,2
Polytechnic University of Madrid,Religion2020,2010,1
Pomona College,Religion2020,2010,4
Pompestichting Centre for Forensic Psychiatry,Cooperation2021,2010,1
Pompestichting Centre for Forensic Psychiatry,Religion2020,2010,1
Pompeu Fabra University,Cooperation2021,2010,4
Pontifical Academy of Theology,Cooperation2021,2010,1
Pontifical Academy of Theology,Religion2020,2010,2
Pontifical Biblical Institute,Religion2020,2010,4
Pontifical Catholic University of Campinas,Religion2020,2010,14
Pontifical Catholic University of Chile,Cooperation2021,2010,3
Pontifical Catholic University of Parana,Religion2020,2010,5
Pontifical Catholic University of Peru,Religion2020,2010,9
Pontifical Catholic University of Rio,Cooperation2021,2010,1
Pontifical Catholic University of Sao Paulo,Religion2020,2010,3
Pontifical Gregorian University,Religion2020,2010,5
Pontifical University Antonianum,Religion2020,2010,21
Pontifical University of John Paul II in Krakow,Religion2020,2010,21
Pontifical University of John Paul II in Kraków,Religion2020,2010,5
Pontifical University of the Holy Cross,Religion2020,2010,5
Pontificia Universidade Catolica do Parana,Religion2020,2010,4
Population Studies Center,Cooperation2021,2010,3
Portland DBT Institute,Religion2020,2010,1
Portland State University,Cooperation2021,2010,7
Portland State University,Religion2020,2010,3
Portuguese Catholic University,Religion2020,2010,3
Postgraduate Institute of Medical Education & Research,Religion2020,2010,10
Prague University,Religion2020,2010,3
Prairie View A&M university,Religion2020,2010,1
Prakash Neurology and Psychiatry Clinic,Cooperation2021,2010,1
Prakash Neurology and Psychiatry Clinic,Religion2020,2010,3
"Presbyterian Theological Seminary, Seoul",Religion2020,2010,8
Press Ganey Associates,Religion2020,2010,6
Prevention Research Center,Religion2020,2010,12
Prince of Songkla University,Religion2020,2010,6
Princess Alice Hospital,Religion2020,2010,10
Princess Margaret Cancer Centre,Religion2020,2010,8
Princeton Theological Seminary,Cooperation2021,2010,2
Princeton Theological Seminary,CooperationAndReligion,2010,1
Princeton Theological Seminary,Religion2020,2010,94
Princeton University,Cooperation2021,2010,42
Princeton University,CooperationAndReligion,2010,1
Princeton University,Religion2020,2010,64
Priory of St Michael the Archangel,Religion2020,2010,4
Private Practice,Religion2020,2010,53
"Private University College of Education, Diocese of Linz",Religion2020,2010,5
PRN Pharmaceutical Research Network LLC,Religion2020,2010,5
Proctor & Gamble,Religion2020,2010,1
Protection of Conscience Project,Religion2020,2010,7
Protestant Theological University,Religion2020,2010,22
Protestant University of Applied Sciences,Religion2020,2010,2
Protestant University Wuppertal/Bethel,Religion2020,2010,4
Providence Care,Religion2020,2010,2
Providence College,Cooperation2021,2010,1
Providence College,CooperationAndReligion,2010,1
Providence College,Religion2020,2010,2
pruvio,Religion2020,2010,2
"Psychological Resources of Toledo, Ltd.",Religion2020,2010,1
Psychological Safety Australia,Cooperation2021,2010,1
Psychological Safety Australia,CooperationAndReligion,2010,1
Psychological Safety Australia,Religion2020,2010,1
Psychology of Technology Institute,Religion2020,2010,1
Public Health England,Religion2020,2010,4
Public Health Foundation of India,Cooperation2021,2010,2
Public Health Foundation of India,Religion2020,2010,4
Public Health Institute of India,Cooperation2021,2010,2
Public Health Institute of India,Religion2020,2010,1
Purdue University,Cooperation2021,2010,23
Purdue University,CooperationAndReligion,2010,2
Purdue University,Religion2020,2010,54
Purdue University Global,Religion2020,2010,2
Pusan National University,Religion2020,2010,6
Qatar Computing Research Institute,Cooperation2021,2010,1
Qatar Computing Research Institute,CooperationAndReligion,2010,1
Qatar Computing Research Institute,Religion2020,2010,4
Qazvin University of Medical Science,Religion2020,2010,4
QIMR Berhofer Medical Research Institute,Religion2020,2010,2
Qualcomm Inc,Cooperation2021,2010,5
Queen Margaret University,Cooperation2021,2010,1
Queen Margaret University,Religion2020,2010,1
Queen Mary College,Religion2020,2010,1
Queen Mary University of London,Cooperation2021,2010,2
Queen Mary University of London,Religion2020,2010,16
Queen’s University Belfast,Religion2020,2010,4
Queens College (CUNY),Religion2020,2010,1
"Queen's College, Birmingham",Religion2020,2010,1
"Queens College, City University of New York",Religion2020,2010,2
Queen's Foundation,Religion2020,2010,5
Queen's University,Cooperation2021,2010,6
Queen's University,Religion2020,2010,35
Queens University Belfast,Religion2020,2010,8
Queen's University Belfast,Cooperation2021,2010,6
Queen's University Belfast,CooperationAndReligion,2010,1
Queen's University Belfast,Religion2020,2010,52
Queensland Health,Religion2020,2010,1
Queensland University of Technology,Cooperation2021,2010,12
Queensland University of Technology,CooperationAndReligion,2010,6
Queensland University of Technology,Religion2020,2010,15
Quinnipiac University,Religion2020,2010,6
Radboud University,Cooperation2021,2010,6
Radboud University,CooperationAndReligion,2010,2
Radboud University,Religion2020,2010,113
Radboud University Medical Centre,Religion2020,2010,19
Radboud University Nijmegen,Cooperation2021,2010,3
Radboud University Nijmegen,Religion2020,2010,55
Radford College,Religion2020,2010,2
Radford University,Religion2020,2010,3
Rafsanjan University of Medical Sciences,Cooperation2021,2010,2
Rafsanjan University of Medical Sciences,Religion2020,2010,1
Ramakrishna Mission Vivekananda Educational and Research Institute,Religion2020,2010,2
Rambam Health Care Campus,Cooperation2021,2010,1
Rambam Health Care Campus,Religion2020,2010,5
Rambam Hospital Haifa,Cooperation2021,2010,2
Rambam Hospital Haifa,Religion2020,2010,5
Ramon Llull University,Cooperation2021,2010,1
Ramon Llull University,Religion2020,2010,4
RAND Corporation,Religion2020,2010,15
Rare Disease Social Research Center,Religion2020,2010,4
Rationality Enhancement at Max Planck Institute,Religion2020,2010,3
Ray Marshall Center,Religion2020,2010,1
Real Estate Investor,Religion2020,2010,4
Redeemer University College,Religion2020,2010,10
Reed College,Cooperation2021,2010,1
Reed College,Religion2020,2010,1
Reformed Theological Seminary,Cooperation2021,2010,1
Reformed Theological Seminary,Religion2020,2010,11
Reformed University of Applied Sciences,Religion2020,2010,2
Refua Shlema Clinic,Cooperation2021,2010,1
Regent College,Cooperation2021,2010,1
Regent College,Religion2020,2010,17
Regent University,Cooperation2021,2010,1
Regent University,Religion2020,2010,45
Regina Apostolorum Pontifical University,Religion2020,2010,1
Religion,Cooperation2021,2010,1
Religion,Religion2020,2010,3
Religion and Science Course Program,Religion2020,2010,3
religion news service,Religion2020,2010,1
Religions of South Asia,Religion2020,2010,2
Religious Freedom & Business Foundation,Religion2020,2010,8
Religious Freedom Institute,Religion2020,2010,5
Renaissance Center for Higher Education,Religion2020,2010,18
Renmin University,Cooperation2021,2010,70
Renmin University,Religion2020,2010,2
Renmin University of China,Religion2020,2010,32
Rennes 1 University,Cooperation2021,2010,11
Rensselaer Polytechnic Institute,Cooperation2021,2010,1
Rensselaer Polytechnic Institute,Religion2020,2010,1
Research Africa Network,Cooperation2021,2010,1
Research Africa Network,Religion2020,2010,13
"Research Centre of Regional Resources (PSDR), the Indonesian Institute of Sciences (LIPI)",Religion2020,2010,1
Research Collaborator and Public Health Practioner,Religion2020,2010,2
"Research Design Associates, Inc.",Religion2020,2010,1
Research Institute for Theology and Religion,Religion2020,2010,7
Research Institute of Industrial Economics (IFN),Cooperation2021,2010,2
Research Institute of Industrial Economics (IFN),Religion2020,2010,4
Resources for the Study of Social Engagement,Religion2020,2010,3
Rethink Priorities,Cooperation2021,2010,1
Rethink Priorities,CooperationAndReligion,2010,1
Rethink Priorities,Religion2020,2010,1
Review of Religious Research,Religion2020,2010,10
Rheinische Friedrich-Wilhelms-Universität Bonn,Religion2020,2010,7
Rhodes College,Religion2020,2010,4
Rhodes University,Cooperation2021,2010,4
Rhodes University,Religion2020,2010,5
Rice University,Cooperation2021,2010,28
Rice University,Religion2020,2010,67
Richard Stockton University,Religion2020,2010,2
Rider University,Religion2020,2010,6
Rigshospitalet,Religion2020,2010,6
RINO Groep,Cooperation2021,2010,2
RINRI Institute,Cooperation2021,2010,7
Ripon College,Religion2020,2010,2
Ripon College Cuddesdon,Religion2020,2010,15
"Risk-Eraser, LLC",Cooperation2021,2010,5
"Risk-Eraser, LLC",CooperationAndReligion,2010,1
"Risk-Eraser, LLC",Religion2020,2010,2
Ritual Studied Internatinoal,Religion2020,2010,4
RIVM,Religion2020,2010,2
RMIT University,Cooperation2021,2010,1
RMIT University,CooperationAndReligion,2010,1
RMIT University,Religion2020,2010,10
Roanoke College,Religion2020,2010,4
Robert Morris University,Religion2020,2010,1
Robert Mundle Productons Inc.,Religion2020,2010,2
Roberts Wesleyan College,Religion2020,2010,3
Roche,Cooperation2021,2010,1
Rochester Cathedral,Religion2020,2010,4
Rochester Institute of Technology,Religion2020,2010,3
Rockefeller University,Cooperation2021,2010,1
Rockefeller University,Religion2020,2010,11
Rockhurst University,Cooperation2021,2010,1
Rockhurst University,Religion2020,2010,16
Rocky Mountain Biological Laboratory,Cooperation2021,2010,7
Roehampton University,Religion2020,2010,4
Rollins College,Religion2020,2010,6
Roma Tre University,Religion2020,2010,10
Romanian-American University,Religion2020,2010,1
ronin institute,Religion2020,2010,1
Rosalind Franklin University,Religion2020,2010,6
Roskilde University,Religion2020,2010,17
Rotterdam University of Applied Sciences,Religion2020,2010,4
Rowan University,Cooperation2021,2010,1
Rowan University,Religion2020,2010,15
Royal Academy of Fine Arts,Religion2020,2010,2
Royal College of Psychiatrists,Religion2020,2010,8
Royal Historical Society,Religion2020,2010,4
"Royal Holloway, University of London",Cooperation2021,2010,13
"Royal Holloway, University of London",CooperationAndReligion,2010,3
"Royal Holloway, University of London",Religion2020,2010,29
Royal Institute for Cultural Heritage,Religion2020,2010,8
Royal Roads University,Religion2020,2010,2
Royal Society,Cooperation2021,2010,1
Royal University of Phnom Phen,Religion2020,2010,2
Royan Institute,Cooperation2021,2010,2
Royan Institute,CooperationAndReligion,2010,1
Royan Institute,Religion2020,2010,1
RTI International,Religion2020,2010,7
RUDN University,Religion2020,2010,5
Rudolf Steiner University College,Religion2020,2010,2
Ruhr University Bochum,Cooperation2021,2010,2
Ruhr University Bochum,CooperationAndReligion,2010,1
Ruhr University Bochum,Religion2020,2010,73
Ruppin Academic Center,Religion2020,2010,4
Ruprecht-Karls-Universität Heidelberg,Religion2020,2010,6
Rush University,Cooperation2021,2010,3
Rush University,Religion2020,2010,71
Russian Academy of  Sciences,Religion2020,2010,4
Russian Academy of Educational Science,Religion2020,2010,1
Russian Academy of Medical Sciences,Religion2020,2010,1
Russian Academy of National Economy and Public Administration,Religion2020,2010,4
Russian Academy of Sciences,Religion2020,2010,2
Russian Armenian University,Religion2020,2010,5
Russian State University for the Humanities,Religion2020,2010,4
Rutgers School of Public Heath,Religion2020,2010,1
Rutgers University,Cooperation2021,2010,31
Rutgers University,CooperationAndReligion,2010,1
Rutgers University,Religion2020,2010,120
Rutherford University,Religion2020,2010,2
RWTH Aachen University,Cooperation2021,2010,5
RWTH Aachen University,Religion2020,2010,1
Ryerson University,Cooperation2021,2010,5
Ryerson University,Religion2020,2010,2
Ryokan College,Religion2020,2010,13
Sackler School of Medicine,Cooperation2021,2010,1
Sackler School of Medicine,Religion2020,2010,1
Saginaw Valley State University,Religion2020,2010,6
Sahand University of Technology,Religion2020,2010,9
Saint Anselm College,Religion2020,2010,4
Saint Elizabeth's Hospital,Religion2020,2010,2
Saint Joseph University,Religion2020,2010,1
Saint Joseph's Theological Institute,Religion2020,2010,5
Saint Joseph's Universty,Religion2020,2010,6
Saint Louis University,Cooperation2021,2010,1
Saint Louis University,CooperationAndReligion,2010,1
Saint Louis University,Religion2020,2010,43
Saint Mary's College,Religion2020,2010,4
Saint Mary's University,Religion2020,2010,6
Saint Meinrad Seminary and School of Theology,Cooperation2021,2010,3
Saint Meinrad Seminary and School of Theology,Religion2020,2010,12
Saint Michael's College,Religion2020,2010,4
Saint Paul University,Religion2020,2010,9
Saint Petersburg State University,Cooperation2021,2010,6
Salem College,Religion2020,2010,5
Salesian Pontifical University,Religion2020,2010,9
Salk Institute,Cooperation2021,2010,1
Sam Houston State University,Religion2020,2010,3
Samford University,Cooperation2021,2010,4
Samford University,CooperationAndReligion,2010,3
Samford University,Religion2020,2010,13
Sampoerna Capital,Religion2020,2010,5
Samsung Life Insurance,Religion2020,2010,2
San Antonio College,Religion2020,2010,3
San Diego State University,Cooperation2021,2010,5
San Diego State University,CooperationAndReligion,2010,1
San Diego State University,Religion2020,2010,30
San Francisco State University,Cooperation2021,2010,2
San Francisco State University,Religion2020,2010,31
San Francisco Theological Seminary,Religion2020,2010,16
San Jose State University,Religion2020,2010,2
Sansone Family Center for Well-Being,Cooperation2021,2010,3
Sansone Family Center for Well-Being,Religion2020,2010,2
Santa Barbara Institute for Consciousness Studies,Religion2020,2010,9
Santa Clara University,Religion2020,2010,48
Santa Fe Institute,Cooperation2021,2010,12
Santa Fe Institute,Religion2020,2010,1
Sao Paulo State University,Religion2020,2010,3
Sapienta College,Religion2020,2010,4
Sapienza University of Rome,Cooperation2021,2010,6
Sapienza University of Rome,CooperationAndReligion,2010,1
Sapienza University of Rome,Religion2020,2010,48
Sarum College,Religion2020,2010,10
Saw Swee Hock School of Public Health,Religion2020,2010,4
Saybrook Graduate School,Religion2020,2010,19
Saybrook University,Religion2020,2010,7
Scarritt College,Religion2020,2010,18
School for Advanced Studies in the Social Sciences,Cooperation2021,2010,1
School for Advanced Studies in the Social Sciences,CooperationAndReligion,2010,1
School for Advanced Studies in the Social Sciences,Religion2020,2010,3
School of Oriental and African Studies,Religion2020,2010,14
SCIENCE magazine,Religion2020,2010,3
Sciences Po,Cooperation2021,2010,1
Sciences Po,Religion2020,2010,11
Scientific Association of Stress Management and Health Promotion,Religion2020,2010,4
Scripps College,Religion2020,2010,12
Scripps Research Institute,Cooperation2021,2010,1
SDM College of Naturopathy and Yogic Sciences,Religion2020,2010,6
Search Institute,Religion2020,2010,5
Seattle Cancer Care Alliance,Religion2020,2010,2
Seattle Pacific University,Religion2020,2010,13
Seattle University,Religion2020,2010,8
Sebelas Maret University,Religion2020,2010,2
Second Military Medical University,Cooperation2021,2010,10
Second Military Medical University,Religion2020,2010,2
Seirei Mikatahara General Hospital,Religion2020,2010,16
Self-employed,Religion2020,2010,14
Self-Employed Author,Religion2020,2010,1
Semmelweis University,Cooperation2021,2010,8
Semmelweis University,Religion2020,2010,5
Semnan University of Medical Sciences,Religion2020,2010,1
Seoul National University,Cooperation2021,2010,5
Seoul National University,Religion2020,2010,8
Seoul National University College of Medicine,Religion2020,2010,12
Seoul National University Graduate School,Religion2020,2010,5
Serbian Academy of Sciences and Arts,Religion2020,2010,7
Seton Hall University,Religion2020,2010,18
Sewanee: The University of the South,Cooperation2021,2010,5
Sewanee: The University of the South,CooperationAndReligion,2010,5
Sewanee: The University of the South,Religion2020,2010,7
Seward County Health Department,Religion2020,2010,1
Shaanxi Normal University,Religion2020,2010,4
Shahid Bahonar University of Kerman,Religion2020,2010,3
Shahid Beheshti University of Medical Science,Cooperation2021,2010,1
Shahid Beheshti University of Medical Science,Religion2020,2010,13
Shahid Sadoughi University of Medical Sciences and Health Services,Religion2020,2010,2
Shalem Institute for Spiritual Formation,Religion2020,2010,6
Shalom Hartman Institute,Religion2020,2010,2
Shandong University,Cooperation2021,2010,3
Shandong University,Religion2020,2010,1
Shanghai Clinical Research Center,Cooperation2021,2010,2
Shanghai Institutes for Biological Sciences,Cooperation2021,2010,1
Shanghai International Studies University,Religion2020,2010,2
Shanghai Jiao Tong University,Cooperation2021,2010,31
Shanghai Jiao Tong University,Religion2020,2010,5
Shanghai Maritime University,Cooperation2021,2010,5
Shanghai Medical University,Cooperation2021,2010,3
Shanghai University,Cooperation2021,2010,5
Shanghai University,Religion2020,2010,1
Sheffield Hallam University,Religion2020,2010,5
Sheffield Teaching Hospitals NHS Foundation Trust,Religion2020,2010,3
"Shenyang Institute of Automation, Chinese Academy of Sciences",Cooperation2021,2010,7
Shenzhen University,Cooperation2021,2010,4
Shepherd Center,Religion2020,2010,9
Sheridan College,Cooperation2021,2010,1
Sheridan College,Religion2020,2010,1
Shimane University,Cooperation2021,2010,1
Shimer College,Religion2020,2010,5
Shiraz University,Cooperation2021,2010,1
Shiraz University,Religion2020,2010,2
Shiraz University of Medical Sciences,Cooperation2021,2010,1
Shiraz University of Medical Sciences,Religion2020,2010,3
Shizuoka University,Cooperation2021,2010,9
Sichuan University,Cooperation2021,2010,6
Sichuan University,Religion2020,2010,3
SIKA,Cooperation2021,2010,2
Silver Lynx Consulting,Religion2020,2010,5
Simon Fraser University,Cooperation2021,2010,9
Simon Fraser University,Religion2020,2010,16
Sinai Urban Health Institute,Religion2020,2010,6
Singapore Management University,Cooperation2021,2010,11
Singapore Management University,Religion2020,2010,9
Singapore National Cancer Center,Religion2020,2010,1
Singapore University of Social Sciences,Cooperation2021,2010,5
SJ Grosswald & Associates,Religion2020,2010,4
Skidmore College,Religion2020,2010,6
Slate,Religion2020,2010,13
SleepScore Labs,Religion2020,2010,2
Slovak Academy of Sciences,Religion2020,2010,1
Slovak Economic Association,Cooperation2021,2010,7
Smith College,Religion2020,2010,7
Smithsonian Tropical Research Institute,Cooperation2021,2010,12
SOAS University of London,Cooperation2021,2010,7
SOAS University of London,Religion2020,2010,20
Social Sciences and Humanities Research Council of Canada,Religion2020,2010,3
society for experimental social psychology,Religion2020,2010,2
Society of Australasian Social Psychologists,Cooperation2021,2010,1
Society of Australasian Social Psychologists,CooperationAndReligion,2010,1
Society of Australasian Social Psychologists,Religion2020,2010,1
Sofia University,Religion2020,2010,58
Sogang University,Cooperation2021,2010,2
Sogang University,Religion2020,2010,6
Soka University,Cooperation2021,2010,1
Soka University,Religion2020,2010,1
Solvay,Religion2020,2010,1
Sophia University,Religion2020,2010,6
Sorbonne University,Religion2020,2010,1
Sør-Trøndelag University College,Cooperation2021,2010,1
Sør-Trøndelag University College,Religion2020,2010,7
Sør‐Trøndelag University College,Religion2020,2010,4
Sotiria General Hospital,Cooperation2021,2010,1
Sotiria General Hospital,Religion2020,2010,1
South China Agricultural University,Cooperation2021,2010,1
South Dakota State University,Religion2020,2010,6
South Mississippi State Hospital,Religion2020,2010,3
Southeastern Louisiana University,Cooperation2021,2010,1
Southeastern Louisiana University,CooperationAndReligion,2010,1
Southeastern Louisiana University,Religion2020,2010,3
Southeastern University,Religion2020,2010,6
Southern Baptist Theological Seminary,Religion2020,2010,2
Southern California Edison,Cooperation2021,2010,2
Southern California University of Health Sciences,Religion2020,2010,1
Southern Cross University,Religion2020,2010,6
Southern Illinois University,Religion2020,2010,21
Southern Illinois University Edwardsville,Religion2020,2010,3
Southern Medical University,Cooperation2021,2010,1
Southern Medical University,Religion2020,2010,2
Southern Methodist University,Religion2020,2010,27
Southern Oregon University,Religion2020,2010,1
Southern University of Science and Technology,Cooperation2021,2010,8
Southern Utah University,Religion2020,2010,2
Southwest Forestry University,Cooperation2021,2010,10
Southwest Forestry University,Religion2020,2010,3
Southwest Missouri State University,Religion2020,2010,1
Southwest National Primate Center,Cooperation2021,2010,1
Southwest National Primate Center,Religion2020,2010,1
Southwest University,Cooperation2021,2010,2
Southwestern College,Cooperation2021,2010,1
Southwestern College,Religion2020,2010,8
Spanish National Research Council,Cooperation2021,2010,3
Spanish National Research Council,Religion2020,2010,1
Spiritual Competency Academy,Religion2020,2010,5
Spring Hill College,Religion2020,2010,5
Sri Aurobindo Centre for Advanced Research,Religion2020,2010,1
Sri Aurobindo Institute of Medical Sciences,Cooperation2021,2010,7
SS. Cyril and Methodius Byzantine Catholic Seminary,Religion2020,2010,65
SS. Cyril and Methodius University,Cooperation2021,2010,3
SSM Health,Religion2020,2010,10
St Andrews University,Religion2020,2010,2
St George & Sutherland Clinical School,Religion2020,2010,2
St Mary's Centre for Religion and Education,Religion2020,2010,6
St Mary's University,Cooperation2021,2010,1
St Mary's University,Religion2020,2010,7
St Mary's University Church,Cooperation2021,2010,1
St Mary's University Church,CooperationAndReligion,2010,1
St Mary's University Church,Religion2020,2010,3
St Peter’s Saltley Trust,Religion2020,2010,3
St Peter’s University,Religion2020,2010,1
St. Ambrose Universtiy,Religion2020,2010,6
St. Augustine College,Religion2020,2010,9
St. Augustine's College,Religion2020,2010,3
St. Bonaventure University,Religion2020,2010,17
St. Edmundsbury,Religion2020,2010,2
St. Edward's University,Cooperation2021,2010,1
St. Edward's University,Religion2020,2010,3
St. Francis Xavier University,Religion2020,2010,12
St. George's University,Cooperation2021,2010,1
St. George's University,Religion2020,2010,8
St. Hilda's College,Religion2020,2010,3
St. Jerome's University,Religion2020,2010,4
St. John of God Clinical Research Centre,Cooperation2021,2010,2
St. John of God Clinical Research Centre,Religion2020,2010,2
St. John Providence Health System,Religion2020,2010,43
St. John's College,Religion2020,2010,8
"St. Johns College, Cambridge",Religion2020,2010,1
St. John's Seminary,Religion2020,2010,2
St. John's University,Religion2020,2010,6
St. Joseph's University,Religion2020,2010,1
St. Jude Children's Hospital,Religion2020,2010,2
St. Louis Medical Center,Religion2020,2010,11
St. Louis University,Cooperation2021,2010,2
St. Louis University,Religion2020,2010,23
St. Luke's Canyon View Behavioral Health Clinic,Religion2020,2010,1
St. Marylebone Healing and Counseling Center,Religion2020,2010,3
St. Mary's Seminary and University,Religion2020,2010,17
St. Mary's University,Religion2020,2010,7
St. Mary's University College,Religion2020,2010,4
"St. Mary's University, Twickenham",Religion2020,2010,5
St. Olaf College,Religion2020,2010,28
St. Patrick's College,Religion2020,2010,5
St. Patrick's Seminary,Religion2020,2010,1
St. Paul's institute of Philosophy,Religion2020,2010,1
St. Paul's United Church of Christ,Religion2020,2010,4
St. Peter's Catholic Church,Religion2020,2010,7
St. Peters College,Religion2020,2010,4
St. Petersburg State University,Cooperation2021,2010,6
St. Petersburg State University,Religion2020,2010,2
St. Philip's Centre,Religion2020,2010,6
St. Stephen's College,Religion2020,2010,2
St. Thomas More College,Religion2020,2010,1
St. Thomas University,Religion2020,2010,4
St. Tikhon's Orthodox University,Religion2020,2010,5
St. Vincent College,Religion2020,2010,1
St. Vladimir's Orthodox Theological Seminary,Religion2020,2010,2
Staffordshire University,Religion2020,2010,50
STAIN Purwokerto,Religion2020,2010,1
Stanford University,Cooperation2021,2010,48
Stanford University,CooperationAndReligion,2010,1
Stanford University,Religion2020,2010,113
Stanley Royd Hospital,Religion2020,2010,2
STAR H LLC,Religion2020,2010,6
Star Tribune,Religion2020,2010,11
State Higher Vocational School in Tarnow,Religion2020,2010,4
State Institute for Islamic Studies (STAIN) Salatiga,Religion2020,2010,2
State University of Campinas,Religion2020,2010,2
State University of New York,Religion2020,2010,3
State University of New York - Nassau,Religion2020,2010,1
State University of New York at Albany,Religion2020,2010,15
State University of New York at Binghamton University,Cooperation2021,2010,8
State University of New York at Binghamton University,Religion2020,2010,4
State University of New York at Brockport,Religion2020,2010,4
State University of New York at Buffalo,Cooperation2021,2010,6
State University of New York at Buffalo,Religion2020,2010,11
State University of New York at Geneseo,Cooperation2021,2010,3
State University of New York at Geneseo,Religion2020,2010,4
State University of New York at New Paltz,Religion2020,2010,1
State University of New York at Oswego,Cooperation2021,2010,2
State University of New York at Stony Brook,Religion2020,2010,3
State University of New York Downstate Medical Center,Religion2020,2010,1
"State University of New York, Old Westbury",Cooperation2021,2010,1
"State University of New York, Old Westbury",Religion2020,2010,5
State University of Paraiba,Cooperation2021,2010,1
State University of Paraiba,Religion2020,2010,5
Stellenbosch University,Cooperation2021,2010,4
Stellenbosch University,CooperationAndReligion,2010,1
Stellenbosch University,Religion2020,2010,30
Steppsa Counselling and Psychotherapy,Religion2020,2010,4
Stetson University,Cooperation2021,2010,1
Stetson University,Religion2020,2010,2
STH Basel,Religion2020,2010,3
Stockholm Läns Landsting,Religion2020,2010,2
Stockholm Resilience Center,Cooperation2021,2010,2
Stockholm School of Economics,Cooperation2021,2010,24
Stockholm School of Economics,CooperationAndReligion,2010,2
Stockholm School of Economics,Religion2020,2010,2
Stockholm School of Theology,Cooperation2021,2010,1
Stockholm School of Theology,CooperationAndReligion,2010,1
Stockholm School of Theology,Religion2020,2010,1
Stockholm University,Cooperation2021,2010,3
Stockholm University,Religion2020,2010,156
Stonehill College,Religion2020,2010,10
Stony Brook School of Medicine University of New York,Cooperation2021,2010,5
Stony Brook School of Medicine University of New York,Religion2020,2010,1
Stony Brook University,Cooperation2021,2010,19
Stony Brook University,CooperationAndReligion,2010,1
Stony Brook University,Religion2020,2010,30
Stranmillis University College,Religion2020,2010,3
Strayer College,Religion2020,2010,1
StressWise,Religion2020,2010,2
Strong BRAIN Institute,Religion2020,2010,1
Studia Traditionis Theologiae,Religion2020,2010,2
Substance Abuse and Mental Health Services Administration,Religion2020,2010,2
Suffolk University,Religion2020,2010,4
Sultan Agung Islamic University,Cooperation2021,2010,1
Sultan Agung Islamic University,Religion2020,2010,1
Sun Yat-Sen University,Cooperation2021,2010,9
Sunan Ampel State Islamic University Surabaya,Religion2020,2010,4
Sunan Kalijaga State Islamic University,Religion2020,2010,13
Sungkyunkwan University (SKKU),Religion2020,2010,1
Sunnybrook Health Sciences Centre,Cooperation2021,2010,3
Sunnybrook Health Sciences Centre,Religion2020,2010,2
SUNY Buffalo,Cooperation2021,2010,1
SUNY Buffalo,Religion2020,2010,2
SUNY Fredonia,Religion2020,2010,5
Surrey University,Religion2020,2010,1
Sutter Health,Religion2020,2010,1
SVYASA University,Cooperation2021,2010,2
SVYASA University,Religion2020,2010,23
S-VYASA University,Religion2020,2010,3
Swami Vivekananda Yoga Anusandhana Samsthana,Religion2020,2010,6
Swansea University,Cooperation2021,2010,4
Swansea University,Religion2020,2010,14
Swarthmore College,Religion2020,2010,17
Swedish Medical Center in Seattle,Religion2020,2010,2
Swinburne University of Technology,Cooperation2021,2010,7
Swinburne University of Technology,Religion2020,2010,4
Swiss Institute for Experimental Cancer Research,Cooperation2021,2010,3
Swiss Institute for Pastoral Sociology,Religion2020,2010,8
Syarif Hidayatullah State Islamic University,Cooperation2021,2010,1
Syarif Hidayatullah State Islamic University,CooperationAndReligion,2010,1
Syarif Hidayatullah State Islamic University,Religion2020,2010,11
sycamore primary care group,Religion2020,2010,5
Sydney College of Divinity,Religion2020,2010,8
Syiah Kuala University,Religion2020,2010,5
Synamedia,Cooperation2021,2010,5
Syracuse University,Religion2020,2010,59
T&C Chen Center for Social and Decision Neuroscience,Cooperation2021,2010,1
Tabriz University of Medical Sciences,Cooperation2021,2010,1
Tabriz University of Medical Sciences,Religion2020,2010,14
Taichung Veterans General Hospital,Religion2020,2010,1
Taisho University,Cooperation2021,2010,3
Tajen University,Religion2020,2010,1
Takeda,Cooperation2021,2010,2
Tamagawa University,Cooperation2021,2010,8
Tampere University,Religion2020,2010,6
Taos Institute,Religion2020,2010,2
Taras Shevchenko Kiev State University,Religion2020,2010,2
Taras Shevchenko National University of Kyiv,Religion2020,2010,1
Tarbiat Modares University,Cooperation2021,2010,4
Tarbiat Modares University,Religion2020,2010,19
Tata Institute of Social Sciences,Religion2020,2010,3
Taylor Seminary,Religion2020,2010,2
Taylor University,Religion2020,2010,1
Technical University of Berlin,Religion2020,2010,14
Technical University of Chemnitz,Cooperation2021,2010,2
Technical University of Chemnitz,Religion2020,2010,5
Technical University of Dresden,Religion2020,2010,3
Technical University of Munich,Cooperation2021,2010,7
Technical University of Munich,Religion2020,2010,9
Technion Israel Institute of Technology,Cooperation2021,2010,1
Technion Israel Institute of Technology,Religion2020,2010,13
Technische Universität Darmstadt,Religion2020,2010,2
Teesside University,Cooperation2021,2010,7
Tehran University of Medical Sciences,Cooperation2021,2010,3
Tehran University of Medical Sciences,CooperationAndReligion,2010,1
Tehran University of Medical Sciences,Religion2020,2010,13
Teikyo University,Cooperation2021,2010,8
Tel Aviv Medical Center,Cooperation2021,2010,5
Tel Aviv Medical Center,Religion2020,2010,1
Tel Aviv University,Cooperation2021,2010,12
Tel Aviv University,Religion2020,2010,21
Tel Aviv University Medical School Tel Hashomer Hospital,Religion2020,2010,17
Teleios Inc.,Religion2020,2010,5
tel-hai college,Cooperation2021,2010,1
Telkom University,Religion2020,2010,2
Temple University,Religion2020,2010,26
Templeton Religion Trust,Religion2020,2010,5
Tennessee State University,Religion2020,2010,5
Texas A&M University,Cooperation2021,2010,13
Texas A&M University,Religion2020,2010,66
Texas Christian University,Religion2020,2010,6
Texas State University,Religion2020,2010,6
Texas Tech University,Cooperation2021,2010,2
Texas Tech University,Religion2020,2010,28
Texas Woman's University,Cooperation2021,2010,2
Texas Woman's University,CooperationAndReligion,2010,2
Texas Woman's University,Religion2020,2010,3
Thammasat University,Religion2020,2010,2
Thanyapura Mind Centre,Religion2020,2010,9
"The Academy of Korean Studies, Seongnam",Religion2020,2010,6
The American University in Cairo,Cooperation2021,2010,1
The American University in Cairo,Religion2020,2010,12
The Arctic University of Norway,Religion2020,2010,7
The Art of Living Foundation,Religion2020,2010,1
The Artic University of Norway,Religion2020,2010,8
The British Library,Religion2020,2010,2
The Broad Institute of Harvard and MIT,Religion2020,2010,11
The Catholic University of America,Religion2020,2010,10
the Center for Koru Mindfulness,Religion2020,2010,1
The Center for Mind Body Medicine,Religion2020,2010,1
The Chicago School of Professional Psychology,Religion2020,2010,17
The Chinese University of Hong Kong,Cooperation2021,2010,16
The Chinese University of Hong Kong,Religion2020,2010,2
The Chopra Foundation,Religion2020,2010,3
The Church of Jesus Christ of Latter-day Saints,Religion2020,2010,2
The College of Idaho,Religion2020,2010,8
The College of William and Mary,Cooperation2021,2010,4
The Coventry Association for Pastoral Psychology,Religion2020,2010,11
The Donner Institute,Religion2020,2010,4
the Drug Research Center of the Sant Pau Hospital,Religion2020,2010,7
The Essential Medical Technology Foundation,Cooperation2021,2010,2
The European Graduate School,Religion2020,2010,2
The Family and Youth Institute,Religion2020,2010,4
The General Theological Seminary,Religion2020,2010,3
"The Greater Good Science Center, Berkley",Religion2020,2010,2
The Hastings Center,Religion2020,2010,1
The Hebrew University,Cooperation2021,2010,2
The Hebrew University,Religion2020,2010,11
The Helsinki Academy of Philosophy,Religion2020,2010,3
The Hong Kong Polytechnic University,Cooperation2021,2010,2
The Hong Kong Polytechnic University,Religion2020,2010,2
The Jane Goodall Institute,Cooperation2021,2010,1
The Key Institute for Brain-Mind Research,Religion2020,2010,6
"The Kinneret Center on Peace, Securityand Society",Religion2020,2010,1
The Lancet,Cooperation2021,2010,1
The Lighthouse Institute,Religion2020,2010,4
The Marcus Institute of Integrative Health,Religion2020,2010,3
The Middle East Technical University,Cooperation2021,2010,1
The Myers-JDC-Brookdale Institute,Religion2020,2010,3
The National Academy of Sciences of Ukraine,Religion2020,2010,30
The New School,Cooperation2021,2010,9
The New School,CooperationAndReligion,2010,3
The New School,Religion2020,2010,8
The Open University,Religion2020,2010,20
The Ottowa Hospital,Religion2020,2010,1
The People's Hospital of Huangmei,Cooperation2021,2010,2
The Queens Foundation,Religion2020,2010,5
The Research Institute of Industrial Economics,Religion2020,2010,3
The Resilience Centre,Religion2020,2010,10
The Salk Institute for Biological Studies,Cooperation2021,2010,3
The Science Council of Japan,Cooperation2021,2010,5
The Single Parents and Teens Foundation,Religion2020,2010,23
The Three Doors ASBL,Religion2020,2010,1
The Tiltenburg,Religion2020,2010,5
The Underground University,Religion2020,2010,3
The University of Campinas,Religion2020,2010,1
the University of Denver,Religion2020,2010,6
The University of Edinburgh,Religion2020,2010,2
The University of Hong Kong,Religion2020,2010,2
The University of New South Wales-The Kirby Institute,Religion2020,2010,3
The University of Queensland,Cooperation2021,2010,1
The University of Queensland,Religion2020,2010,6
The University of Rochester School of Medicine,Religion2020,2010,4
The University of Texas at Austin,Religion2020,2010,1
The University of Texas at Dallas,Cooperation2021,2010,2
The University of Texas at Dallas,Religion2020,2010,5
The University of Texas at San Antonio,Religion2020,2010,8
The University of the South Sewanee,Religion2020,2010,1
The University of Trans Disciplinary Health Sciences & Technology,Religion2020,2010,6
The University of Western Australia,Religion2020,2010,3
The University of Western Ontario,Religion2020,2010,1
The Westport Day School,Religion2020,2010,1
Theopolis Institute,Religion2020,2010,2
Thomas Jefferson University,Religion2020,2010,12
Thomas More College of Liberal Arts,Religion2020,2010,2
Thorneloe University,Religion2020,2010,3
Tiangong University,Cooperation2021,2010,7
Tianjin University,Cooperation2021,2010,17
Tianjin University of Traditional Chinese Medicine,Religion2020,2010,3
Tidewater Child and Family Behavioral Health,Religion2020,2010,8
Tilburg University,Cooperation2021,2010,30
Tilburg University,CooperationAndReligion,2010,2
Tilburg University,Religion2020,2010,44
Tinbergen Institute,Cooperation2021,2010,3
Tirana University,Religion2020,2010,1
Titus Brandsma Institute,Religion2020,2010,2
Toho University School of Medicine,Religion2020,2010,2
Tohoku University,Cooperation2021,2010,4
Tohoku University,Religion2020,2010,7
Tokai University,Cooperation2021,2010,1
Tokyo Gakugei University,Cooperation2021,2010,4
Tokyo Institute of Technology,Cooperation2021,2010,12
Tokyo Institute of Technology,Religion2020,2010,2
Tokyo Medical and Dental University,Religion2020,2010,3
Tokyo Woman's Christian University,Religion2020,2010,1
Tokyo Women's Medical University,Religion2020,2010,1
Tom Baker Cancer Center,Cooperation2021,2010,1
Tomas Bata University of Zlin,Religion2020,2010,2
Tongji University,Cooperation2021,2010,9
Tongji University,Religion2020,2010,9
Tor Vergata University of Rome,Cooperation2021,2010,10
Tor Vergata University of Rome,Religion2020,2010,16
Toronto School of Theology,Religion2020,2010,2
Torrens University,Cooperation2021,2010,1
Torrens University,Religion2020,2010,3
Touro College,Religion2020,2010,19
Toyohashi University of Technology,Cooperation2021,2010,5
Tree of Life Tai Chi Center,Religion2020,2010,1
Trent University,Religion2020,2010,5
Trilateral Research Ltd,Cooperation2021,2010,3
Trilateral Research Ltd,CooperationAndReligion,2010,1
Trilateral Research Ltd,Religion2020,2010,1
Trinity College,Religion2020,2010,23
Trinity College Dublin,Cooperation2021,2010,5
Trinity College Dublin,CooperationAndReligion,2010,1
Trinity College Dublin,Religion2020,2010,62
Trinity College of Arts & Sciences,Religion2020,2010,10
"Trinity College, Cambridge",Cooperation2021,2010,1
"Trinity College, Cambridge",Religion2020,2010,5
Trinity Evangelical Divinity School,Religion2020,2010,8
Trinity International University,Religion2020,2010,2
Trinity Lutheran Church,Religion2020,2010,9
Trinity Lutheran Seminary,Religion2020,2010,4
Trinity Theological College,Religion2020,2010,1
Trinity University,Religion2020,2010,1
Trinity Western University,Religion2020,2010,32
Trnavska Univerzita,Religion2020,2010,3
Truman State University,Religion2020,2010,1
Tshwane Leadership Foundation,Religion2020,2010,5
Tsinghua University,Cooperation2021,2010,22
Tsukuba Medical Center Hospital,Religion2020,2010,6
Tucker Counseling and Consulting,Religion2020,2010,2
Tufts University,Religion2020,2010,28
Tufts University School of Medicine,Religion2020,2010,1
Tulane University,Cooperation2021,2010,6
Tulane University,Religion2020,2010,14
Tusculum College,Religion2020,2010,3
Tuskegee University,Religion2020,2010,1
Tyndale Seminary,Religion2020,2010,24
Tyndale University,Cooperation2021,2010,1
Tyndale University,CooperationAndReligion,2010,1
Tyndale University,Religion2020,2010,1
Tzu Chi Hospital,Religion2020,2010,2
U.S. Army Reserves,Religion2020,2010,3
U.S. Department of Health and Human Services,Religion2020,2010,3
U.S. Department of Veterans Affairs,Religion2020,2010,13
U.S. Public Health Commissioned Corps of the (Lower 2015; Upper 2020),Religion2020,2010,1
UC San Diego,Religion2020,2010,3
UCSF Division of Prevention Science,Religion2020,2010,1
Ucsf Medical Center,Religion2020,2010,3
Udayana University,Religion2020,2010,5
UIN Antasari,Religion2020,2010,3
UIN Malang,Religion2020,2010,2
UIN Mataram,Religion2020,2010,1
UIN Sunan Ampel,Religion2020,2010,2
UIN Sunan Kalijaga Yogyakarta,Religion2020,2010,8
UIN Syarif Hidayatullah,Religion2020,2010,3
UiT The Arctic University of Norway,Religion2020,2010,3
UK Parliament,Religion2020,2010,2
Ulster University,Religion2020,2010,26
UMAE Hospital de Especialidade,Cooperation2021,2010,7
UMAE Hospital de Especialidade,CooperationAndReligion,2010,5
UMAE Hospital de Especialidade,Religion2020,2010,7
Umeå University,Cooperation2021,2010,4
Umeå University,Religion2020,2010,5
UNC Gillings School of Global Public Health,Religion2020,2010,4
UNESCO,Cooperation2021,2010,5
UNESCO,Religion2020,2010,1
Uniformed Services University of the Health Sciences,Cooperation2021,2010,6
Uniformed Services University of the Health Sciences,Religion2020,2010,3
Unilever,Religion2020,2010,4
Union College,Cooperation2021,2010,8
Union College,Religion2020,2010,2
Union Congregational United Church of Christ,Religion2020,2010,3
Union Presbyterian Seminary,Religion2020,2010,50
Union Theological Seminary,Cooperation2021,2010,1
Union Theological Seminary,Religion2020,2010,35
Union University,Religion2020,2010,11
Unitarian Universalist Wellesley Hills,Religion2020,2010,8
Unitec Institute of Technology,Religion2020,2010,9
United Arab Emirates University,Cooperation2021,2010,2
United Arab Emirates University,Religion2020,2010,1
United Church of Rockville Center,Religion2020,2010,18
United Health Care Inc.,Religion2020,2010,3
United Nations,Religion2020,2010,4
United Nations University - International Institute of Global Health,Cooperation2021,2010,3
United States Army,Religion2020,2010,1
United States Army Research Laboratory,Religion2020,2010,5
United States Naval Academy,Cooperation2021,2010,1
United States Naval Academy,CooperationAndReligion,2010,1
United States Naval Academy,Religion2020,2010,11
United Theological College,Religion2020,2010,3
United Theological Seminary of the Twin Cities,Religion2020,2010,2
Uniting College,Religion2020,2010,1
Universidad Arturo Prat,Religion2020,2010,2
Universidad Autónoma de Barcelona,Religion2020,2010,4
Universidad Autonoma Metropolitana,Religion2020,2010,1
Universidad Carlos III de Madrid,Cooperation2021,2010,4
Universidad Carlos III de Madrid,CooperationAndReligion,2010,1
Universidad Carlos III de Madrid,Religion2020,2010,1
Universidad Catolica de Murcia,Cooperation2021,2010,6
Universidad Catolica de Murcia,CooperationAndReligion,2010,4
Universidad Catolica de Murcia,Religion2020,2010,6
Universidad Complutense de Madrid,Cooperation2021,2010,3
Universidad Complutense de Madrid,Religion2020,2010,1
Universidad de Alcalá,Cooperation2021,2010,2
Universidad de Almeria,Religion2020,2010,2
Universidad de Buenos Aires,Religion2020,2010,1
Universidad de Costa Rica,Cooperation2021,2010,6
Universidad de Sao Paulo,Religion2020,2010,1
Universidad de Sevilla,Cooperation2021,2010,6
Universidad del Pais Vasco,Religion2020,2010,5
Universidad Nacional Autonoma de Mexico,Cooperation2021,2010,1
Universidad Nacional de Educacion a Distancia,Cooperation2021,2010,2
Universidad Nacional de Educacion a Distancia,Religion2020,2010,2
Universidad Nacional de La Pampa,Religion2020,2010,3
Universidad Nacional de Rosario  Universidad Nacional de Rosario  Universidad Nacional de Rosario,Religion2020,2010,4
Universidad Politécnica de Madrid,Cooperation2021,2010,4
Universidad Politécnica de Madrid,CooperationAndReligion,2010,1
Universidad Politécnica de Madrid,Religion2020,2010,1
Universidad Quetzalcotal en Irapuato,Cooperation2021,2010,3
Universidad Quetzalcotal en Irapuato,CooperationAndReligion,2010,2
Universidad Quetzalcotal en Irapuato,Religion2020,2010,3
Universidad Rey Juan Carlos,Cooperation2021,2010,6
Universidade de Lisboa,Cooperation2021,2010,7
Universidade de Lisboa,Religion2020,2010,10
Universidade do Porto,Religion2020,2010,5
Universidade Federal de Juiz de Fora,Religion2020,2010,5
Universidade Federal de Pernambuco,Cooperation2021,2010,1
Universidade Federal de Pernambuco,Religion2020,2010,2
Universidade Federal de San Carlos   São Carlos,Religion2020,2010,4
Universidade Federal de São Paulo,Religion2020,2010,8
Universidade Federal do Ceará,Religion2020,2010,2
Universidade Federal do Rio Grande do Sul,Religion2020,2010,5
Universidade NOVA de Lisboa,Religion2020,2010,6
Universita Bocconi,Cooperation2021,2010,4
Universita Bocconi,Religion2020,2010,1
Università degli Studi di Milano,Cooperation2021,2010,1
Universita degli Studi di Palermo,Religion2020,2010,9
Università della Svizzera italiana,Religion2020,2010,7
Università di Bari,Religion2020,2010,3
Università di Padova,Religion2020,2010,9
Universita LUM Jean Monnet,Religion2020,2010,6
Universitas Batam,Religion2020,2010,1
Universitas Gadjah Mada,Religion2020,2010,9
Universitas Ibn Khaldun Bogor,Religion2020,2010,3
Universitas Islam Negeri Alauddin,Cooperation2021,2010,2
Universitas Islam Negeri Alauddin,CooperationAndReligion,2010,1
Universitas Islam Negeri Alauddin,Religion2020,2010,2
Universitas Islam Negeri Ar-Raniry,Religion2020,2010,1
Universitas Islam Negeri Maulana Malik Ibrahim Malang,Religion2020,2010,10
Universitas Islam Negeri Sultan Maulana Hasanuddin Banten,Religion2020,2010,2
Universitas Islam Negeri Sunan Kalijaga,Religion2020,2010,1
universitas muhammadiyah yogyakarta,Cooperation2021,2010,1
universitas muhammadiyah yogyakarta,Religion2020,2010,4
Universitas Pendidikan Indonesia,Religion2020,2010,1
Universitas Yarsi,Religion2020,2010,4
Universitat Autonoma de Barcelona,Religion2020,2010,4
Universitat Autònoma de Barcelona,Cooperation2021,2010,2
Universitat Autònoma de Barcelona,Religion2020,2010,14
Universitat de Barcelona,Religion2020,2010,4
Universitat Gottingngen,Religion2020,2010,3
Universitat Oberta de Catalunya,Religion2020,2010,1
Universitat Pompeu Fabra,Religion2020,2010,4
Universitat Wien,Religion2020,2010,21
Universitat Wurzburg,Religion2020,2010,7
Universitatea Valahia,Religion2020,2010,5
Université Catholique de Louvain,Cooperation2021,2010,3
Université Catholique de Louvain,CooperationAndReligion,2010,2
Université Catholique de Louvain,Religion2020,2010,30
Université de Caen Normandie,Religion2020,2010,2
Université de Fribourg,Religion2020,2010,8
Universite de Lorraine,Religion2020,2010,7
Université de Paris,Religion2020,2010,3
Université de Rennes 1,Cooperation2021,2010,1
Université de Sherbrooke,Religion2020,2010,3
Universite d'Orleans,Religion2020,2010,1
Universite du Luxembourg,Cooperation2021,2010,5
Université du Québec à Montréal,Religion2020,2010,31
Université du Québec en Outaouais,Religion2020,2010,3
Université Libre de Bruxelles,Cooperation2021,2010,11
Université Libre in Brussels,Cooperation2021,2010,1
Université Libre in Brussels,Religion2020,2010,9
Université Paris I,Cooperation2021,2010,5
Universite Saint Joseph,Religion2020,2010,21
Université Saint-Louis,Cooperation2021,2010,3
Universite Sorbonne Paris Nord,Cooperation2021,2010,4
Universitetet Agder,Religion2020,2010,3
Universiti Brunei Darussalam,Religion2020,2010,6
Universiti Kebangsaan Malaysia,Cooperation2021,2010,3
Universiti Kebangsaan Malaysia,Religion2020,2010,11
Universiti Putra Malaysia,Cooperation2021,2010,1
Universiti Putra Malaysia,Religion2020,2010,13
Universiti Sains Islam Malaysia,Religion2020,2010,2
Universiti Sains Malaysia,Religion2020,2010,11
Universiti Teknologi MARA,Cooperation2021,2010,3
Universiti Teknologi MARA,CooperationAndReligion,2010,1
Universiti Teknologi MARA,Religion2020,2010,4
University “G. d’Annunzio” of Chieti-Pescara,Religion2020,2010,1
"University at Albany, The State University of New York",Religion2020,2010,2
University at Buffalo,Cooperation2021,2010,2
University at Buffalo,CooperationAndReligion,2010,1
University at Buffalo,Religion2020,2010,7
University College Chester,Cooperation2021,2010,1
University College Chester,Religion2020,2010,5
University College Cork,Cooperation2021,2010,1
University College Cork,Religion2020,2010,10
University College Dublin,Cooperation2021,2010,3
University College Dublin,Religion2020,2010,18
University College London,Cooperation2021,2010,71
University College London,CooperationAndReligion,2010,1
University College London,Religion2020,2010,165
University College London Hospitals NHS Foundation Trust,Cooperation2021,2010,2
University College of North Wales,Religion2020,2010,3
University College Utrecht,Religion2020,2010,3
University Duisburg-Essen,Religion2020,2010,7
University François Rabelais Tours,Religion2020,2010,1
University Graduate Center at Kjeller,Cooperation2021,2010,5
University Hassan II Casablanca,Cooperation2021,2010,3
University Health Network,Religion2020,2010,7
University Hospital Cologne,Religion2020,2010,4
University Hospital Lewisham,Cooperation2021,2010,1
University Hospital Lewisham,Religion2020,2010,12
University Hospital of Odense,Cooperation2021,2010,2
University Hospital Virgen de las Nieves,Religion2020,2010,1
University Hospitals Birmingham NHS Foundation Trust,Religion2020,2010,1
University Hospitals Connor,Religion2020,2010,4
University Institute of Psychological Social and Life Science,Religion2020,2010,4
University Medical Center Gottingen,Religion2020,2010,1
University Medical Center Utrecht,Religion2020,2010,2
University Milan Bicocca,Cooperation2021,2010,1
University Milan Bicocca,Religion2020,2010,1
University of Aarhus,Cooperation2021,2010,4
University of Aarhus,CooperationAndReligion,2010,1
University of Aarhus,Religion2020,2010,23
University of Aberdeen,Cooperation2021,2010,10
University of Aberdeen,CooperationAndReligion,2010,1
University of Aberdeen,Religion2020,2010,113
University of Adelaide,Cooperation2021,2010,7
University of Adelaide,Religion2020,2010,16
University of Agder,Cooperation2021,2010,1
University of Agder,CooperationAndReligion,2010,1
University of Agder,Religion2020,2010,18
University of Akron,Religion2020,2010,11
University of Alabama,Cooperation2021,2010,6
University of Alabama,CooperationAndReligion,2010,1
University of Alabama,Religion2020,2010,22
University of Alabama at Birmingham,Cooperation2021,2010,4
University of Alabama at Birmingham,CooperationAndReligion,2010,3
University of Alabama at Birmingham,Religion2020,2010,40
University of Alabama in Huntsville,Cooperation2021,2010,3
University of Alabama in Huntsville,Religion2020,2010,3
University of Alaska,Religion2020,2010,3
University of Alaska Anchorage,Religion2020,2010,2
University of Albany,Cooperation2021,2010,2
University of Albany,Religion2020,2010,1
University of Alberta,Cooperation2021,2010,10
University of Alberta,Religion2020,2010,60
University of Alicante,Cooperation2021,2010,3
University of Alicante,Religion2020,2010,1
University of Amsterdam,Cooperation2021,2010,65
University of Amsterdam,CooperationAndReligion,2010,1
University of Amsterdam,Religion2020,2010,106
University of Antwerp,Cooperation2021,2010,5
University of Antwerp,Religion2020,2010,5
University of Applied Sciences Coburg,Religion2020,2010,9
University of Applied Sciences of the Grisons,Cooperation2021,2010,10
University of Arizona,Cooperation2021,2010,20
University of Arizona,Religion2020,2010,71
University of Arkansas,Cooperation2021,2010,1
University of Arkansas,Religion2020,2010,17
University of Arkansas at Little Rock,Religion2020,2010,1
University of Arkansas for Medical Science,Religion2020,2010,9
University of Athens,Religion2020,2010,38
University of Auckland,Cooperation2021,2010,14
University of Auckland,CooperationAndReligion,2010,3
University of Auckland,Religion2020,2010,82
University of Ballarat,Religion2020,2010,6
University of Bamberg,Religion2020,2010,5
University of Barcelona,Cooperation2021,2010,5
University of Barcelona,Religion2020,2010,7
University of Bari Aldo Moro,Religion2020,2010,33
University of Basel,Cooperation2021,2010,5
University of Basel,Religion2020,2010,62
University of Bath,Cooperation2021,2010,11
University of Bath,CooperationAndReligion,2010,1
University of Bath,Religion2020,2010,24
University of Bayreuth,Religion2020,2010,11
University of Bedfordshire,Cooperation2021,2010,3
University of Bedfordshire,CooperationAndReligion,2010,3
University of Bedfordshire,Religion2020,2010,16
University of Belgrade,Cooperation2021,2010,2
University of Belgrade,Religion2020,2010,13
University of Bergamo,Cooperation2021,2010,4
University of Bergamo,Religion2020,2010,1
University of Bergen,Cooperation2021,2010,2
University of Bergen,Religion2020,2010,49
University of Bern,Cooperation2021,2010,6
University of Bern,CooperationAndReligion,2010,1
University of Bern,Religion2020,2010,31
University of Bielefeld,Cooperation2021,2010,3
University of Bielefeld,Religion2020,2010,11
University of Birmingham,Cooperation2021,2010,11
University of Birmingham,Religion2020,2010,134
University of Bocconi,Cooperation2021,2010,4
University of Bocconi,Religion2020,2010,1
University of Bochum,Religion2020,2010,49
University of Bologna,Cooperation2021,2010,13
University of Bologna,CooperationAndReligion,2010,1
University of Bologna,Religion2020,2010,12
University of Bolton,Religion2020,2010,2
University of Bonn,Cooperation2021,2010,58
University of Bonn,CooperationAndReligion,2010,1
University of Bonn,Religion2020,2010,23
University of Botswana,Cooperation2021,2010,2
University of Botswana,Religion2020,2010,10
University of Bradford,Cooperation2021,2010,2
University of Bradford,Religion2020,2010,8
University of Brasilia,Cooperation2021,2010,2
University of Brasilia,Religion2020,2010,2
University of Brawijaya,Cooperation2021,2010,1
University of Brawijaya,CooperationAndReligion,2010,1
University of Brawijaya,Religion2020,2010,3
University of Bremen,Cooperation2021,2010,8
University of Bremen,Religion2020,2010,7
University of Bridgeport,Religion2020,2010,1
University of Brighton,Cooperation2021,2010,1
University of Brighton,CooperationAndReligion,2010,1
University of Brighton,Religion2020,2010,15
University of Bristol,Cooperation2021,2010,18
University of Bristol,CooperationAndReligion,2010,1
University of Bristol,Religion2020,2010,131
University of British Columbia,Cooperation2021,2010,42
University of British Columbia,CooperationAndReligion,2010,4
University of British Columbia,Religion2020,2010,111
University of Brunei Darussalam,Religion2020,2010,1
University of Buckingham,Cooperation2021,2010,1
University of Buckingham,Religion2020,2010,2
University of Buenos Aires,Cooperation2021,2010,1
University of Buenos Aires,Religion2020,2010,13
University of Buffalo,Cooperation2021,2010,21
University of Cagliari,Cooperation2021,2010,2
University of Cairo,Religion2020,2010,1
University of Calgary,Cooperation2021,2010,7
University of Calgary,Religion2020,2010,51
University of California,Religion2020,2010,2
University of California - Berkeley,Cooperation2021,2010,14
University of California - Berkeley,CooperationAndReligion,2010,1
University of California - Berkeley,Religion2020,2010,33
University of California - Davis,Cooperation2021,2010,10
University of California - Davis,CooperationAndReligion,2010,1
University of California - Davis,Religion2020,2010,14
University of California - Irvine,Cooperation2021,2010,8
University of California - Irvine,Religion2020,2010,41
University of California - Los Angeles,Cooperation2021,2010,16
University of California - Los Angeles,CooperationAndReligion,2010,1
University of California - Los Angeles,Religion2020,2010,52
University of California - Riverside,Cooperation2021,2010,10
University of California - Riverside,Religion2020,2010,7
University of California - San Diego,Cooperation2021,2010,17
University of California - San Diego,Religion2020,2010,28
University of California - San Francisco,Cooperation2021,2010,6
University of California - San Francisco,Religion2020,2010,39
University of California - Santa Barbara,Cooperation2021,2010,6
University of California - Santa Barbara,Religion2020,2010,14
"University of California, Berkeley",Cooperation2021,2010,11
"University of California, Berkeley",CooperationAndReligion,2010,1
"University of California, Berkeley",Religion2020,2010,65
"University of California, Davis",Cooperation2021,2010,25
"University of California, Davis",CooperationAndReligion,2010,1
"University of California, Davis",Religion2020,2010,34
"University of California, Irvine",Cooperation2021,2010,8
"University of California, Irvine",Religion2020,2010,36
"University of California, Los Angeles",Cooperation2021,2010,25
"University of California, Los Angeles",Religion2020,2010,39
"University of California, Riverside",Cooperation2021,2010,14
"University of California, Riverside",CooperationAndReligion,2010,1
"University of California, Riverside",Religion2020,2010,22
"University of California, San Diego",Cooperation2021,2010,9
"University of California, San Diego",CooperationAndReligion,2010,1
"University of California, San Diego",Religion2020,2010,37
"University of California, San Francisco",Religion2020,2010,7
"University of California, Santa Barbara",Cooperation2021,2010,21
"University of California, Santa Barbara",Religion2020,2010,23
"University of California, Santa Cruz",Cooperation2021,2010,1
University of Cambridge,Cooperation2021,2010,41
University of Cambridge,CooperationAndReligion,2010,1
University of Cambridge,Religion2020,2010,268
University of Canterbury,Religion2020,2010,12
University of Cape Town,Cooperation2021,2010,7
University of Cape Town,Religion2020,2010,50
University of Catania,Cooperation2021,2010,4
University of Catania,Religion2020,2010,2
University of Central England,Cooperation2021,2010,1
University of Central England,Religion2020,2010,1
University of Central Florida,Cooperation2021,2010,6
University of Central Florida,Religion2020,2010,39
University of Central Lancashire,Cooperation2021,2010,1
University of Central Lancashire,Religion2020,2010,4
University of Chester,Religion2020,2010,43
University of Chicago,Cooperation2021,2010,26
University of Chicago,Religion2020,2010,155
University of Chicago Divinity School,Religion2020,2010,13
"University of Chicago, Brain and Language Paper",Religion2020,2010,2
University of Chichester,Religion2020,2010,13
University of Chieti-Pescara,Religion2020,2010,1
University of Cincinnati,Cooperation2021,2010,1
University of Cincinnati,Religion2020,2010,36
University of Coimbra,Religion2020,2010,9
University of Cologne,Cooperation2021,2010,19
University of Cologne,Religion2020,2010,19
University of Colorado Anschutz Medical Campus,Religion2020,2010,1
University of Colorado at Boulder,Cooperation2021,2010,5
University of Colorado at Boulder,Religion2020,2010,15
University of Colorado at Colorado Springs,Cooperation2021,2010,2
University of Colorado at Colorado Springs,Religion2020,2010,10
University of Colorado Boulder,Religion2020,2010,10
University of Colorado Denver,Cooperation2021,2010,2
University of Colorado Denver,CooperationAndReligion,2010,1
University of Colorado Denver,Religion2020,2010,40
University of Colorado Medical School,Religion2020,2010,6
"University of Colorado, Boulder",Cooperation2021,2010,1
"University of Colorado, Boulder",Religion2020,2010,5
University of Concepción,Religion2020,2010,1
University of Connecticut,Cooperation2021,2010,12
University of Connecticut,CooperationAndReligion,2010,3
University of Connecticut,Religion2020,2010,185
University of Constance,Religion2020,2010,7
University of Copenhagen,Cooperation2021,2010,19
University of Copenhagen,Religion2020,2010,77
University of Crete,Religion2020,2010,6
University of Cumbria,Religion2020,2010,7
University of Cyprus,Religion2020,2010,9
University of Dalhousie,Religion2020,2010,5
University of Dallas,Religion2020,2010,2
University of Darussalam Gontor,Religion2020,2010,2
University of Dayton,Religion2020,2010,11
University of Debrecen,Cooperation2021,2010,3
University of Delaware,Cooperation2021,2010,1
University of Delaware,Religion2020,2010,5
University of Delhi,Cooperation2021,2010,1
University of Delhi,Religion2020,2010,4
University of Denver,Cooperation2021,2010,2
University of Denver,Religion2020,2010,48
University of Derby,Cooperation2021,2010,1
University of Derby,Religion2020,2010,17
University of Detroit Mercy,Religion2020,2010,11
University of Divinity,Religion2020,2010,9
University of Dubuque Theological Seminary,Religion2020,2010,9
University of Duisburg-Essen,Cooperation2021,2010,11
University of Duisburg-Essen,Religion2020,2010,12
University of Dundee,Religion2020,2010,6
University of Durham,Cooperation2021,2010,1
University of Durham,Religion2020,2010,14
University of Düsseldorf,Religion2020,2010,1
University of East Anglia,Cooperation2021,2010,20
University of East Anglia,Religion2020,2010,18
University of East London,Cooperation2021,2010,1
University of East London,Religion2020,2010,15
University of Eastern Finland,Religion2020,2010,4
University of Eastern Piedmont,Religion2020,2010,5
University of Edinburgh,Cooperation2021,2010,21
University of Edinburgh,CooperationAndReligion,2010,1
University of Edinburgh,Religion2020,2010,159
University of Edinburgh - Napier,Religion2020,2010,1
University of Electronic Science and Technology of China,Cooperation2021,2010,5
University of Erfurt,Religion2020,2010,7
University of Erlangen,Religion2020,2010,3
University of Erlangen-Nuremberg,Religion2020,2010,3
University of Essex,Cooperation2021,2010,15
University of Essex,CooperationAndReligion,2010,1
University of Essex,Religion2020,2010,52
University of Evry-Val d'Essonne,Religion2020,2010,7
University of Exeter,Cooperation2021,2010,18
University of Exeter,CooperationAndReligion,2010,1
University of Exeter,Religion2020,2010,140
University of Ferrara,Cooperation2021,2010,1
University of Ferrara,Religion2020,2010,4
University of Florence,Cooperation2021,2010,3
University of Florida,Cooperation2021,2010,5
University of Florida,Religion2020,2010,50
University of Florida Health Center for Movement Disorders and Neurorestoration,Cooperation2021,2010,1
University of Foggia,Cooperation2021,2010,2
University of Frankfurt,Religion2020,2010,11
University of Freiburg,Cooperation2021,2010,3
University of Freiburg,Religion2020,2010,33
University of Fribourg,Cooperation2021,2010,1
University of Fribourg,Religion2020,2010,29
University of Galve,Religion2020,2010,2
University of Gastronomic Sciences,Religion2020,2010,3
University of Gdańsk,Religion2020,2010,2
University of Geneva,Cooperation2021,2010,3
University of Geneva,CooperationAndReligion,2010,1
University of Geneva,Religion2020,2010,91
University of Genoa,Religion2020,2010,2
University of Georgia,Cooperation2021,2010,11
University of Georgia,Religion2020,2010,37
University of Ghana,Religion2020,2010,10
University of Girona,Religion2020,2010,19
University of Glasgow,Cooperation2021,2010,8
University of Glasgow,Religion2020,2010,80
University of Gloucestershire,Cooperation2021,2010,1
University of Gloucestershire,Religion2020,2010,17
University of Glyndwr,Religion2020,2010,2
University of Gothenburg,Cooperation2021,2010,13
University of Gothenburg,Religion2020,2010,40
University of Gottingen,Religion2020,2010,4
University of Göttingen,Cooperation2021,2010,38
University of Göttingen,Religion2020,2010,61
University of Granada,Cooperation2021,2010,7
University of Granada,CooperationAndReligion,2010,1
University of Granada,Religion2020,2010,5
University of Graz,Cooperation2021,2010,5
University of Graz,Religion2020,2010,20
University of Greenwich,Religion2020,2010,1
University of Greifswald,Cooperation2021,2010,2
University of Groningen,Cooperation2021,2010,31
University of Groningen,CooperationAndReligion,2010,1
University of Groningen,Religion2020,2010,74
University of Guelph,Cooperation2021,2010,8
University of Guelph-Humber,Religion2020,2010,9
University of Haifa,Cooperation2021,2010,18
University of Haifa,CooperationAndReligion,2010,1
University of Haifa,Religion2020,2010,115
University of Halle-Wittenberg,Cooperation2021,2010,3
University of Hamburg,Cooperation2021,2010,9
University of Hamburg,CooperationAndReligion,2010,1
University of Hamburg,Religion2020,2010,11
University of Hannover,Religion2020,2010,3
University of Hawai‘i,Religion2020,2010,3
University of Hawai‘i at Mānoa,Cooperation2021,2010,13
University of Hawai‘i at Mānoa,Religion2020,2010,22
University of Hawai‘i–West O‘ahu,Religion2020,2010,1
University of Hawai'i,Cooperation2021,2010,6
University of Hawai'i,Religion2020,2010,7
University of Hawaii at Manoa,Cooperation2021,2010,2
University of Health and Allied Sciences,Religion2020,2010,2
University of Heidelberg,Religion2020,2010,29
University of Helsinki,Cooperation2021,2010,13
University of Helsinki,CooperationAndReligion,2010,2
University of Helsinki,Religion2020,2010,272
University of Hertfordshire,Religion2020,2010,1
University of Hong Kong,Cooperation2021,2010,8
University of Hong Kong,Religion2020,2010,39
University of Houston,Cooperation2021,2010,17
University of Houston,Religion2020,2010,35
University of Houston - Victoria,Religion2020,2010,2
University of Huddersfield,Religion2020,2010,15
University of Hull,Cooperation2021,2010,4
University of Hull,Religion2020,2010,30
University of Humanistic Studies,Religion2020,2010,6
University of Ibadan,Cooperation2021,2010,1
University of Ibadan,Religion2020,2010,1
University of Iceland,Religion2020,2010,3
University of Illinois,Cooperation2021,2010,13
University of Illinois,Religion2020,2010,24
University of Illinois at Chicago,Cooperation2021,2010,4
University of Illinois at Chicago,Religion2020,2010,16
University of Illinois at Urbana-Champaign,Cooperation2021,2010,5
University of Illinois at Urbana-Champaign,Religion2020,2010,35
University of Illinois Urbana-Champaign,Cooperation2021,2010,2
University of Illinois Urbana-Champaign,Religion2020,2010,9
University of Indonesia,Religion2020,2010,4
University of Information Technology and Management based in Rzeszów,Religion2020,2010,1
University of Innsbruck,Cooperation2021,2010,10
University of Innsbruck,CooperationAndReligion,2010,1
University of Innsbruck,Religion2020,2010,32
University of Iowa,Cooperation2021,2010,10
University of Iowa,CooperationAndReligion,2010,1
University of Iowa,Religion2020,2010,87
University of Jena,Cooperation2021,2010,5
University of Johannesburg,Religion2020,2010,8
University of Jyväskylä,Cooperation2021,2010,6
University of Jyväskylä,Religion2020,2010,55
University of Kansas,Cooperation2021,2010,6
University of Kansas,CooperationAndReligion,2010,1
University of Kansas,Religion2020,2010,29
University of Kansas Medical Center,Religion2020,2010,5
University of Karachi,Religion2020,2010,5
University of Kent,Cooperation2021,2010,5
University of Kent,Religion2020,2010,71
University of Kent at Canterbury,Religion2020,2010,29
University of Kentucky,Cooperation2021,2010,2
University of Kentucky,CooperationAndReligion,2010,1
University of Kentucky,Religion2020,2010,48
University of Kiel,Religion2020,2010,1
University of Koblenz-Landau,Cooperation2021,2010,11
University of Koblenz-Landau,Religion2020,2010,7
University of Konstanz,Cooperation2021,2010,11
University of Konstanz,Religion2020,2010,3
University of Kurdistan Hawler,Religion2020,2010,3
University of KwaZulu-Natal,Cooperation2021,2010,2
University of KwaZulu-Natal,Religion2020,2010,41
University of La Laguna,Cooperation2021,2010,3
University of Lancaster,Religion2020,2010,8
University of L'Aquila,Cooperation2021,2010,3
University of L'Aquila,Religion2020,2010,4
University of Lausanne,Cooperation2021,2010,37
University of Lausanne,CooperationAndReligion,2010,2
University of Lausanne,Religion2020,2010,40
University of Lausanne Medical School,Religion2020,2010,5
University of Leeds,Cooperation2021,2010,12
University of Leeds,Religion2020,2010,94
University of Leicester,Cooperation2021,2010,12
University of Leicester,Religion2020,2010,45
University of Leiden,Cooperation2021,2010,2
University of Leiden,CooperationAndReligion,2010,1
University of Leiden,Religion2020,2010,18
University of Leipzig,Cooperation2021,2010,5
University of Leipzig,Religion2020,2010,37
University of Lethbridge,Cooperation2021,2010,12
University of Lethbridge,Religion2020,2010,9
University of Leuven,Cooperation2021,2010,2
University of Leuven,CooperationAndReligion,2010,1
University of Leuven,Religion2020,2010,57
University of Liege,Cooperation2021,2010,1
University of Liege,Religion2020,2010,14
University of Lille,Cooperation2021,2010,3
University of Lille,Religion2020,2010,2
University of Lille III,Religion2020,2010,5
University of Limerick,Cooperation2021,2010,1
University of Limerick,Religion2020,2010,13
University of Lincoln,Cooperation2021,2010,1
University of Lincoln,Religion2020,2010,12
University of Lisbon,Cooperation2021,2010,2
University of Lisbon,Religion2020,2010,27
University of Liverpool,Cooperation2021,2010,6
University of Liverpool,Religion2020,2010,22
University of Ljubljana,Religion2020,2010,3
University of Lleida,Cooperation2021,2010,1
University of Lleida,CooperationAndReligion,2010,1
University of Lleida,Religion2020,2010,2
University of Lodz,Religion2020,2010,8
University of Łódź,Religion2020,2010,25
University of London,Religion2020,2010,152
University of Louisville,Cooperation2021,2010,4
University of Louisville,Religion2020,2010,23
University of Louvain,Cooperation2021,2010,2
University of Louvain,Religion2020,2010,8
University of Lucerne,Cooperation2021,2010,1
University of Lucerne,CooperationAndReligion,2010,1
University of Lucerne,Religion2020,2010,22
University of Ludwigsburg,Religion2020,2010,2
University of Luxembourg,Cooperation2021,2010,4
University of Lynchburg,Religion2020,2010,7
University of Lyon,Cooperation2021,2010,9
University of Lyon,Religion2020,2010,1
University of Lyon-II,Religion2020,2010,13
University of Maastricht,Cooperation2021,2010,1
University of Maastricht,CooperationAndReligion,2010,1
University of Maastricht,Religion2020,2010,2
University of Macau,Religion2020,2010,11
University of Madras,Religion2020,2010,37
University of Magdeburg,Cooperation2021,2010,3
University of Maine,Religion2020,2010,11
University of Málaga,Religion2020,2010,1
University of Malawi,Religion2020,2010,3
University of Malaya,Cooperation2021,2010,2
University of Malaya,CooperationAndReligion,2010,2
University of Malaya,Religion2020,2010,26
University of Malta,Cooperation2021,2010,1
University of Malta,CooperationAndReligion,2010,1
University of Malta,Religion2020,2010,16
University of Manchester,Cooperation2021,2010,17
University of Manchester,CooperationAndReligion,2010,1
University of Manchester,Religion2020,2010,69
University of Manitoba,Cooperation2021,2010,5
University of Manitoba,Religion2020,2010,25
University of Mannheim,Cooperation2021,2010,2
University of Mannheim,CooperationAndReligion,2010,1
University of Mannheim,Religion2020,2010,10
University of Marburg,Religion2020,2010,2
University of Maribor,Cooperation2021,2010,63
University of Maribor,Religion2020,2010,10
University of Mary Hardin-Baylor,Religion2020,2010,3
University of Mary Washington,Religion2020,2010,6
University of Maryland,Cooperation2021,2010,31
University of Maryland,Religion2020,2010,65
University of Maryland - College Park,Cooperation2021,2010,4
University of Maryland - College Park,Religion2020,2010,27
"University of Maryland, Baltimore",Religion2020,2010,4
"University of Maryland, Baltimore Country",Religion2020,2010,2
"University of Maryland, College Park",Cooperation2021,2010,6
"University of Maryland, College Park",Religion2020,2010,38
University of Massachusetts - Amherst,Cooperation2021,2010,8
University of Massachusetts - Amherst,CooperationAndReligion,2010,1
University of Massachusetts - Amherst,Religion2020,2010,40
University of Massachusetts - Boston,Religion2020,2010,11
University of Massachusetts Amherst,Cooperation2021,2010,5
University of Massachusetts Lowell,Cooperation2021,2010,1
University of Massachusetts Lowell,Religion2020,2010,4
University of Massachusetts Medical School,Cooperation2021,2010,1
University of Massachusetts Medical School,Religion2020,2010,3
University of Massachusetts Memorial Medical Center,Religion2020,2010,30
"University of Massachusetts, Boston",Cooperation2021,2010,3
"University of Massachusetts, Boston",Religion2020,2010,4
"University of Massachusetts, Worcester",Religion2020,2010,1
University of Medicine and Dentistry of New Jersey,Religion2020,2010,5
University of Melbourne,Cooperation2021,2010,28
University of Melbourne,CooperationAndReligion,2010,3
University of Melbourne,Religion2020,2010,22
University of Memphis,Religion2020,2010,43
University of Miami,Cooperation2021,2010,9
University of Miami,Religion2020,2010,59
University of Michigan,Cooperation2021,2010,58
University of Michigan,CooperationAndReligion,2010,3
University of Michigan,Religion2020,2010,182
University of Michigan Medical School,Religion2020,2010,1
"University of Michigan, Ann Arbor",Religion2020,2010,8
"University of Michigan, Dearborn",Religion2020,2010,1
University of Milan,Cooperation2021,2010,6
University of Milan,CooperationAndReligion,2010,2
University of Milan,Religion2020,2010,5
University of Milan - Bicocca,Cooperation2021,2010,6
University of Minho,Cooperation2021,2010,28
University of Minnesota,Cooperation2021,2010,26
University of Minnesota,Religion2020,2010,62
"University of Minnesota, Duluth",Religion2020,2010,1
University of Mississippi,Cooperation2021,2010,4
University of Mississippi,Religion2020,2010,8
University of Mississippi Medical Center,Religion2020,2010,1
University of Missouri,Cooperation2021,2010,7
University of Missouri,Religion2020,2010,25
University of Missouri Mizzou,Religion2020,2010,13
University of Missouri-Kansas City,Religion2020,2010,12
University of Missouri-St. Louis,Religion2020,2010,6
University of Modena and Reggio Emilia,Cooperation2021,2010,4
University of Modena and Reggio Emilia,Religion2020,2010,2
University of Montpellier,Cooperation2021,2010,2
University of Montreal,Cooperation2021,2010,4
University of Montreal,Religion2020,2010,114
University of Muenster,Religion2020,2010,15
University of Munich,Religion2020,2010,13
University of Munster,Cooperation2021,2010,5
University of Munster,Religion2020,2010,12
University of Münster,Cooperation2021,2010,5
University of Münster,Religion2020,2010,29
University of Murcia,Cooperation2021,2010,15
University of Murcia,CooperationAndReligion,2010,10
University of Murcia,Religion2020,2010,15
University of Nairobi,Religion2020,2010,1
University of Namur,Religion2020,2010,3
University of Nantes,Religion2020,2010,4
University of Navarra,Religion2020,2010,12
University of Nebraska,Cooperation2021,2010,6
University of Nebraska,Religion2020,2010,78
"University of Nebraska, Lincoln",Religion2020,2010,2
"University of Nebraska, Omaha",Cooperation2021,2010,1
"University of Nebraska, Omaha",Religion2020,2010,1
University of Nebraska-Lincoln,Cooperation2021,2010,4
University of Nebraska-Lincoln,Religion2020,2010,18
University of Neuchâtel,Cooperation2021,2010,11
University of Neuchâtel,Religion2020,2010,10
University of Nevada,Religion2020,2010,4
University of Nevada - Reno,Cooperation2021,2010,2
University of Nevada - Reno,Religion2020,2010,34
"University of Nevada, Las Vegas",Cooperation2021,2010,6
"University of Nevada, Las Vegas",CooperationAndReligion,2010,2
"University of Nevada, Las Vegas",Religion2020,2010,19
University of New Brunswick,Cooperation2021,2010,1
University of New Brunswick,Religion2020,2010,18
University of New England,Cooperation2021,2010,6
University of New England,Religion2020,2010,8
University of New Hampshire,Religion2020,2010,9
University of New Mexico,Cooperation2021,2010,10
University of New Mexico,Religion2020,2010,33
University of New Orleans,Cooperation2021,2010,1
University of New Orleans,Religion2020,2010,18
University of New South Wales,Cooperation2021,2010,9
University of New South Wales,Religion2020,2010,17
University of New York (CUNY),Religion2020,2010,2
University of Newcastle,Cooperation2021,2010,5
University of Newcastle,Religion2020,2010,59
University of Niigata Prefecture,Cooperation2021,2010,1
University of Niigata Prefecture,Religion2020,2010,1
University of Nijmegen,Religion2020,2010,1
University of North Carolina,Cooperation2021,2010,19
University of North Carolina,Religion2020,2010,20
University of North Carolina - Chapel Hill,Religion2020,2010,2
University of North Carolina at Chapel Hill,Cooperation2021,2010,6
University of North Carolina at Chapel Hill,Religion2020,2010,73
University of North Carolina at Charlotte,Cooperation2021,2010,2
University of North Carolina at Charlotte,CooperationAndReligion,2010,1
University of North Carolina at Charlotte,Religion2020,2010,19
University of North Carolina at Greensboro,Cooperation2021,2010,3
University of North Carolina at Greensboro,Religion2020,2010,18
University of North Carolina at Wilmington,Religion2020,2010,3
University of North Carolina Hospitals,Religion2020,2010,3
University of North Dakota,Religion2020,2010,5
University of North Florida,Religion2020,2010,10
University of North Texas,Religion2020,2010,53
University of Northampton,Religion2020,2010,1
University of Northern Iowa,Religion2020,2010,5
University of Notre Dame,Cooperation2021,2010,7
University of Notre Dame,CooperationAndReligion,2010,2
University of Notre Dame,Religion2020,2010,224
University of Notre Dame Australia,Cooperation2021,2010,1
University of Notre Dame Australia,CooperationAndReligion,2010,1
University of Notre Dame Australia,Religion2020,2010,23
University of Notre Dame- Australia,Religion2020,2010,8
University of Nottingham,Cooperation2021,2010,27
University of Nottingham,Religion2020,2010,52
University of Oklahoma,Cooperation2021,2010,2
University of Oklahoma,Religion2020,2010,32
University of Oklahoma Health Sciences Center,Cooperation2021,2010,1
University of Oldenburg,Cooperation2021,2010,5
University of Oldenburg,Religion2020,2010,3
University of Oran,Religion2020,2010,2
University of Oregon,Cooperation2021,2010,8
University of Oregon,CooperationAndReligion,2010,4
University of Oregon,Religion2020,2010,38
University of Oslo,Cooperation2021,2010,10
University of Oslo,Religion2020,2010,52
University of Osnabruck,Religion2020,2010,6
University of Osnabrück,Religion2020,2010,4
University of Otago,Cooperation2021,2010,4
University of Otago,CooperationAndReligion,2010,1
University of Otago,Religion2020,2010,46
University of Ottawa,Cooperation2021,2010,22
University of Ottawa,CooperationAndReligion,2010,1
University of Ottawa,Religion2020,2010,79
University of Oulu,Cooperation2021,2010,4
University of Oulu,Religion2020,2010,2
University of Oxford,Cooperation2021,2010,113
University of Oxford,CooperationAndReligion,2010,5
University of Oxford,Religion2020,2010,301
University of Oxford Mindfulness Research Centre,Religion2020,2010,5
"University of Oxford, Champion Hall",Religion2020,2010,1
University of Paderborn,Religion2020,2010,6
University of Padova,Cooperation2021,2010,18
University of Padova,CooperationAndReligion,2010,1
University of Padova,Religion2020,2010,27
University of Padua,Cooperation2021,2010,2
University of Padua,Religion2020,2010,17
University of Palermo,Religion2020,2010,7
University of Pantanjali,Religion2020,2010,5
University of Paris,Cooperation2021,2010,1
University of Paris,Religion2020,2010,11
University of Paris at Nanterre,Religion2020,2010,1
University of Paris Ouest,Religion2020,2010,4
University of Paris VIII,Religion2020,2010,18
University of Parma,Cooperation2021,2010,4
University of Parma,Religion2020,2010,36
University of Passau,Cooperation2021,2010,1
University of Passau,Religion2020,2010,9
University of Patanjali,Religion2020,2010,1
University of Pavia,Cooperation2021,2010,2
University of Pavia,Religion2020,2010,2
University of Pécs,Cooperation2021,2010,5
University of Pennsylvania,Cooperation2021,2010,59
University of Pennsylvania,CooperationAndReligion,2010,1
University of Pennsylvania,Religion2020,2010,81
University of Pennsylvania Program for Mindfulness,Religion2020,2010,1
University of Pisa,Cooperation2021,2010,5
University of Pisa,Religion2020,2010,37
University of Pittsburgh,Cooperation2021,2010,11
University of Pittsburgh,CooperationAndReligion,2010,1
University of Pittsburgh,Religion2020,2010,43
University of Pittsburgh Cancer Institute,Religion2020,2010,3
University of Plymouth,Cooperation2021,2010,1
University of Plymouth,Religion2020,2010,15
University of Portland,Religion2020,2010,1
University of Porto,Religion2020,2010,5
University of Portsmouth,Cooperation2021,2010,1
University of Portsmouth,Religion2020,2010,21
University of Prešov,Religion2020,2010,3
University of Pretoria,Cooperation2021,2010,5
University of Pretoria,Religion2020,2010,143
University of Prince Edward Island,Religion2020,2010,1
University of Québec at Montreal,Religion2020,2010,8
University of Quebec in Chicoutimi,Cooperation2021,2010,1
University of Queensland,Cooperation2021,2010,27
University of Queensland,CooperationAndReligion,2010,2
University of Queensland,Religion2020,2010,76
University of Reading,Religion2020,2010,35
University of Redlands,Religion2020,2010,16
University of Regensburg,Cooperation2021,2010,6
University of Regensburg,Religion2020,2010,30
University of Regina,Religion2020,2010,9
University of Rennes,Cooperation2021,2010,1
University of Rennes,Religion2020,2010,1
University of Richmond,Cooperation2021,2010,3
University of Richmond,Religion2020,2010,5
University of Rijeka,Religion2020,2010,2
University of Rochester,Religion2020,2010,45
University of Rochester Medical Center,Religion2020,2010,2
University of Roehampton,Religion2020,2010,14
University of Rome,Cooperation2021,2010,3
University of Rome,Religion2020,2010,13
University of Rostock,Cooperation2021,2010,8
University of Rostock,Religion2020,2010,2
University of Rotterdam,Religion2020,2010,1
University of Saint Mary of the Lake,Religion2020,2010,7
University of Salzburg,Religion2020,2010,1
University of San Diego,Cooperation2021,2010,1
University of San Diego,Religion2020,2010,26
University of San Francisco,Religion2020,2010,8
university of san martin,Religion2020,2010,5
University of Santo Tomas,Religion2020,2010,1
University of Sao Paulo,Religion2020,2010,7
University of São Paulo,Cooperation2021,2010,1
University of São Paulo,Religion2020,2010,28
University of Saskatchewan,Cooperation2021,2010,8
University of Saskatchewan,Religion2020,2010,35
University of Sassari,Cooperation2021,2010,1
University of Sassari,Religion2020,2010,8
University of Sau Paulo,Cooperation2021,2010,1
University of Sau Paulo,Religion2020,2010,5
University of Savoie,Religion2020,2010,2
University of Science and Culture,Cooperation2021,2010,2
University of Science and Culture,CooperationAndReligion,2010,1
University of Science and Culture,Religion2020,2010,1
University of Science and Technology,Cooperation2021,2010,24
University of Science and Technology of China,Cooperation2021,2010,16
University of Science and Technology of China,Religion2020,2010,1
University of Scranton,Religion2020,2010,11
University of Shanghai,Religion2020,2010,17
University of Sheffield,Cooperation2021,2010,19
University of Sheffield,Religion2020,2010,33
University of Sherbrooke,Religion2020,2010,9
University of Shiraz,Religion2020,2010,1
University of Siegen,Religion2020,2010,10
University of Siena,Cooperation2021,2010,9
University of Siena,Religion2020,2010,11
University of Sindh,Cooperation2021,2010,1
University of Singapore,Religion2020,2010,3
University of Sistan,Religion2020,2010,1
University of Social Welfare and Rehabilitation Science,Religion2020,2010,1
University of Social Welfare and Rehabilitation Sciences,Cooperation2021,2010,1
University of Social Welfare and Rehabilitation Sciences,CooperationAndReligion,2010,1
University of Social Welfare and Rehabilitation Sciences,Religion2020,2010,7
University of Sousse,Cooperation2021,2010,1
University of South Africa,Cooperation2021,2010,5
University of South Africa,Religion2020,2010,91
University of South Alabama,Religion2020,2010,7
University of South Australia,Cooperation2021,2010,1
University of South Australia,Religion2020,2010,13
University of South Carolina,Cooperation2021,2010,6
University of South Carolina,Religion2020,2010,34
University of South Dakota,Cooperation2021,2010,1
University of South Dakota,Religion2020,2010,4
University of South Florida,Cooperation2021,2010,4
University of South Florida,Religion2020,2010,43
University of South Florida - Saint Petersburg,Religion2020,2010,3
University of South Wales,Cooperation2021,2010,6
University of South Wales,Religion2020,2010,13
University of Southampton,Cooperation2021,2010,23
University of Southampton,Religion2020,2010,27
University of South-Eastern Norway,Cooperation2021,2010,2
University of South-Eastern Norway,Religion2020,2010,1
University of Southern California,Cooperation2021,2010,20
University of Southern California,Religion2020,2010,72
University of Southern Denmark,Cooperation2021,2010,8
University of Southern Denmark,CooperationAndReligion,2010,1
University of Southern Denmark,Religion2020,2010,74
University of Southern Mississippi,Religion2020,2010,18
University of Southern Queensland,Religion2020,2010,7
University of Split,Religion2020,2010,2
University of St Andrews,Cooperation2021,2010,37
University of St Andrews,CooperationAndReligion,2010,2
University of St Andrews,Religion2020,2010,41
University of St Michaels,Religion2020,2010,6
University of St. Gallen,Religion2020,2010,11
University of St. Thomas,Religion2020,2010,51
University of Stavanger,Religion2020,2010,6
University of Stellenbosch,Cooperation2021,2010,1
University of Stellenbosch,Religion2020,2010,36
University of Stirling,Cooperation2021,2010,4
University of Stirling,Religion2020,2010,20
University of Strasbourg,Cooperation2021,2010,10
University of Strasbourg,Religion2020,2010,26
University of Strathclyde,Cooperation2021,2010,2
University of Strathclyde,Religion2020,2010,19
University of Sunderland,Religion2020,2010,2
University of Surrey,Cooperation2021,2010,4
University of Surrey,Religion2020,2010,6
University of Sussex,Cooperation2021,2010,21
University of Sussex,Religion2020,2010,33
University of Sydney,Cooperation2021,2010,35
University of Sydney,CooperationAndReligion,2010,5
University of Sydney,Religion2020,2010,144
University of Szeged,Cooperation2021,2010,1
University of Szeged,Religion2020,2010,9
University of Tampa,Religion2020,2010,11
University of Tartu,Religion2020,2010,13
University of Tasmania,Religion2020,2010,8
University of Technology Sydney,Cooperation2021,2010,17
University of Technology Sydney,CooperationAndReligion,2010,1
University of Technology Sydney,Religion2020,2010,33
University of Teesside,Cooperation2021,2010,2
University of Tehran,Cooperation2021,2010,5
University of Tehran,Religion2020,2010,16
University of Tennessee,Cooperation2021,2010,7
University of Tennessee,CooperationAndReligion,2010,1
University of Tennessee,Religion2020,2010,113
University of Tennessee - Knoxville,Cooperation2021,2010,5
University of Tennessee - Knoxville,Religion2020,2010,1
University of Tennessee at Chattanooga,Religion2020,2010,21
University of Texas at Arlington,Cooperation2021,2010,1
University of Texas at Arlington,Religion2020,2010,2
University of Texas at Austin,Cooperation2021,2010,9
University of Texas at Austin,Religion2020,2010,57
University of Texas at Dallas,Cooperation2021,2010,2
University of Texas at Dallas,Religion2020,2010,9
University of Texas at El Paso,Religion2020,2010,7
University of Texas at Houston,Religion2020,2010,9
University of Texas at San Antonio,Religion2020,2010,60
University of Texas Health Science Center at Houston (UTHealth),Religion2020,2010,56
University of Texas MD Anderson Cancer Center,Cooperation2021,2010,3
University of Texas MD Anderson Cancer Center,Religion2020,2010,27
University of Texas Medical Branch,Religion2020,2010,4
University of Texas Medical Branch at Galveston,Cooperation2021,2010,6
University of Texas Medical Branch at Galveston,Religion2020,2010,3
University of Texas Pan American,Religion2020,2010,1
University of Texas Rio Grande Valley,Religion2020,2010,5
University of Texas School of Education,Religion2020,2010,2
University of Texas School of Public Health,Cooperation2021,2010,1
University of Texas School of Public Health,Religion2020,2010,23
University of Texas Southwestern Medical Center,Cooperation2021,2010,1
University of Texas Southwestern Medical Center,CooperationAndReligion,2010,1
University of Texas Southwestern Medical Center,Religion2020,2010,4
"University of Texas, Southwestern Medical Center",Religion2020,2010,3
University of the Balearic Islands,Religion2020,2010,1
University of the Basque Country,Cooperation2021,2010,1
University of the Basque Country,Religion2020,2010,5
University of the Free State,Cooperation2021,2010,1
University of the Free State,CooperationAndReligion,2010,1
University of the Free State,Religion2020,2010,43
University of the Highlands and Islands,Religion2020,2010,2
University of the Incarnate Word,Religion2020,2010,2
University of the South,Religion2020,2010,2
University of the West of England,Religion2020,2010,24
University of the Western Cape,Religion2020,2010,15
University of the Witwatersrand,Religion2020,2010,3
University of Thessaloniki,Religion2020,2010,1
University of Thessaly,Cooperation2021,2010,9
University of Thessaly,Religion2020,2010,1
University of Tilburg,Religion2020,2010,2
University of Tokyo,Cooperation2021,2010,42
University of Tokyo,Religion2020,2010,15
University of Toledo,Religion2020,2010,2
University of Torino,Cooperation2021,2010,2
University of Toronto,Cooperation2021,2010,29
University of Toronto,Religion2020,2010,225
University of Toronto Scarborough,Cooperation2021,2010,1
University of Toronto Scarborough,Religion2020,2010,6
University of Toulouse,Religion2020,2010,3
University of Tours,Cooperation2021,2010,5
University of Tours,Religion2020,2010,35
University of Trento,Cooperation2021,2010,13
University of Trento,Religion2020,2010,39
University of Trier,Religion2020,2010,16
University of Tromsø,Religion2020,2010,16
University of Tsukuba,Religion2020,2010,4
University of Tubigen Germany,Cooperation2021,2010,4
University of Tubigen Germany,Religion2020,2010,15
University of Tubingen,Cooperation2021,2010,3
University of Tubingen,Religion2020,2010,3
University of Tübingen,Cooperation2021,2010,12
University of Tübingen,CooperationAndReligion,2010,1
University of Tübingen,Religion2020,2010,69
University of Tulsa,Cooperation2021,2010,2
University of Tulsa,Religion2020,2010,3
University of Turin,Cooperation2021,2010,5
University of Turin,CooperationAndReligion,2010,1
University of Turin,Religion2020,2010,37
University of Turku,Cooperation2021,2010,6
University of Turku,CooperationAndReligion,2010,1
University of Turku,Religion2020,2010,24
University of Tuzla,Religion2020,2010,8
University of Twente,Religion2020,2010,3
University of Udine,Cooperation2021,2010,1
University of Udine,Religion2020,2010,12
University of Ulm,Cooperation2021,2010,7
University of Ulm,Religion2020,2010,5
University of Ulster,Cooperation2021,2010,1
University of Ulster,Religion2020,2010,1
University of Utah,Cooperation2021,2010,9
University of Utah,CooperationAndReligion,2010,1
University of Utah,Religion2020,2010,104
University of Utrecht,Cooperation2021,2010,7
University of Utrecht,Religion2020,2010,34
University of Valencia,Religion2020,2010,6
University of Vermont,Cooperation2021,2010,2
University of Vermont,CooperationAndReligion,2010,1
University of Vermont,Religion2020,2010,29
University of Verona,Cooperation2021,2010,7
University of Versailles,Religion2020,2010,9
University of Victoria,Cooperation2021,2010,6
University of Victoria,CooperationAndReligion,2010,1
University of Victoria,Religion2020,2010,36
University of Vienna,Cooperation2021,2010,68
University of Vienna,CooperationAndReligion,2010,1
University of Vienna,Religion2020,2010,192
University of Vigo,Cooperation2021,2010,7
University of Virginia,Cooperation2021,2010,20
University of Virginia,CooperationAndReligion,2010,2
University of Virginia,Religion2020,2010,68
University of Waikato,Cooperation2021,2010,1
University of Waikato,Religion2020,2010,9
University of Wales,Religion2020,2010,10
University of Wales at Lampeter,Cooperation2021,2010,1
University of Wales at Lampeter,CooperationAndReligion,2010,1
University of Wales at Lampeter,Religion2020,2010,5
University of Wales Trinity Saint David,Religion2020,2010,6
University of Warsaw,Cooperation2021,2010,4
University of Warsaw,Religion2020,2010,5
University of Warwick,Cooperation2021,2010,23
University of Warwick,CooperationAndReligion,2010,1
University of Warwick,Religion2020,2010,159
University of Washington,Cooperation2021,2010,35
University of Washington,CooperationAndReligion,2010,4
University of Washington,Religion2020,2010,83
University of Washington at Seattle,Religion2020,2010,5
University of Washington Bothell,Religion2020,2010,6
University of Waterloo,Cooperation2021,2010,14
University of Waterloo,Religion2020,2010,20
University of West England,Religion2020,2010,6
University of West Florida,Religion2020,2010,2
University of West Georgia,Religion2020,2010,9
University of Western Australia,Cooperation2021,2010,4
University of Western Australia,Religion2020,2010,27
University of Western Cape,Religion2020,2010,13
University of Western Ontario,Cooperation2021,2010,3
University of Western Ontario,Religion2020,2010,24
University of Western States,Religion2020,2010,1
University of Western Sydney,Cooperation2021,2010,1
University of Western Sydney,Religion2020,2010,21
University of Westminster,Religion2020,2010,10
University of Winchester,Cooperation2021,2010,1
University of Winchester,Religion2020,2010,43
University of Windsor,Cooperation2021,2010,4
University of Windsor,CooperationAndReligion,2010,1
University of Windsor,Religion2020,2010,10
University of Winnipeg,Religion2020,2010,3
University of Wisconsin,Cooperation2021,2010,2
University of Wisconsin,Religion2020,2010,23
University of Wisconsin - Eau Claire,Religion2020,2010,16
University of Wisconsin - Madison,Cooperation2021,2010,7
University of Wisconsin - Madison,Religion2020,2010,48
University of Wisconsin - Milwaukee,Religion2020,2010,6
University of Wisconsin at Parkside,Religion2020,2010,34
University of Wisconsin-Madison,Cooperation2021,2010,10
University of Wisconsin-Madison,Religion2020,2010,28
University of Wisconsin-Milwaukee,Religion2020,2010,7
University of Witten/Herdecke,Religion2020,2010,52
University of Witwatersrand,Religion2020,2010,9
University of Wollongong,Cooperation2021,2010,4
University of Wollongong,Religion2020,2010,8
University of Wolverhampton,Religion2020,2010,2
University of Worcester,Religion2020,2010,11
University of Wrocław,Religion2020,2010,1
University of Wuppertal,Religion2020,2010,3
University of Würzburg,Religion2020,2010,15
University of Wyoming,Cooperation2021,2010,6
University of Wyoming,Religion2020,2010,5
University of York,Cooperation2021,2010,5
University of York,Religion2020,2010,11
University of Zagreb,Religion2020,2010,8
University of Zambia,Religion2020,2010,5
University of Zaragoza,Cooperation2021,2010,25
University of Zaragoza,Religion2020,2010,6
University of Zenica,Religion2020,2010,4
University of Zimbabwe,Cooperation2021,2010,1
University of Zimbabwe,Religion2020,2010,6
University of Zurich,Cooperation2021,2010,55
University of Zurich,CooperationAndReligion,2010,2
University of Zurich,Religion2020,2010,40
University Psychiatric Center KU Leuven,Cooperation2021,2010,2
University Saint Paul,Religion2020,2010,1
University Ulm,Religion2020,2010,2
Uniwersytet Papieski Jana Pawla II w Krakowie,Religion2020,2010,3
"UNSW, Sydney",Cooperation2021,2010,1
Uplift Appalachia,Religion2020,2010,1
UPMC Presbyterian,Religion2020,2010,2
UPMC Western Psychiatric Hospital,Religion2020,2010,2
Uppsala University,Cooperation2021,2010,8
Uppsala University,Religion2020,2010,82
Urbino University,Religion2020,2010,5
Ursinus College,Religion2020,2010,1
US Army,Cooperation2021,2010,1
US Army,Religion2020,2010,2
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Cooperation2021,2010,1
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Religion2020,2010,6
Usona Insitute,Religion2020,2010,4
Utah State University,Cooperation2021,2010,2
Utah State University,Religion2020,2010,7
Utah Valley University,Religion2020,2010,9
Utrecht University,Cooperation2021,2010,19
Utrecht University,Religion2020,2010,157
VA,Religion2020,2010,8
VA Atlanta,Cooperation2021,2010,1
VA Atlanta,Religion2020,2010,2
VA Boston Healthcare System,Religion2020,2010,3
VA Cincinnatti,Religion2020,2010,1
VA Durham,Religion2020,2010,5
VA Hospital Durham,Religion2020,2010,10
VA HSR&D Center for Health Services Research in Primary Care,Religion2020,2010,4
VA HSR&D Center of Excellence,Religion2020,2010,1
VA Medical Center- Minneapolis,Religion2020,2010,5
"VA Medical Center, New Orleans",Religion2020,2010,2
VA Minneapolis Medical Center,Religion2020,2010,3
VA Palo Alto Health Care System,Religion2020,2010,4
VA Providence,Religion2020,2010,3
VA Puget Sound,Religion2020,2010,3
VA San Diego,Religion2020,2010,2
VA San Diego Healthcare System,Cooperation2021,2010,1
VA San Diego Healthcare System,Religion2020,2010,4
Valaparaiso University,Religion2020,2010,3
Valdosta State University,Religion2020,2010,7
Valley Christian Schools (Northeast Ohio),Cooperation2021,2010,1
Valley Christian Schools (Northeast Ohio),Religion2020,2010,8
Valparaiso University,Religion2020,2010,18
Vanderbilt Divinity School,Religion2020,2010,14
Vanderbilt University,Cooperation2021,2010,6
Vanderbilt University,Religion2020,2010,117
Vanguard University,Religion2020,2010,2
Vanung University,Religion2020,2010,2
Vassar College,Cooperation2021,2010,2
Vassar College,Religion2020,2010,4
Vencore Labs,Cooperation2021,2010,2
Veracode,Cooperation2021,2010,3
"Versiti, Inc.",Cooperation2021,2010,1
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Cooperation2021,2010,2
Veterans Health Adminstration,Religion2020,2010,7
Viadrina European University,Cooperation2021,2010,2
Victoria University Melbourne,Cooperation2021,2010,3
Victoria University Melbourne,Religion2020,2010,5
Victoria University of Wellington,Cooperation2021,2010,13
Victoria University of Wellington,CooperationAndReligion,2010,6
Victoria University of Wellington,Religion2020,2010,44
"Victoria University, Toronto",Religion2020,2010,5
VID Specialized University,Religion2020,2010,15
Vigie de laïcité,Religion2020,2010,4
Villanova University,Cooperation2021,2010,1
Villanova University,CooperationAndReligion,2010,1
Villanova University,Religion2020,2010,34
Vilnius Gediminas Technical University,Religion2020,2010,3
Vilnius University,Religion2020,2010,8
Vinayaka Missions University,Religion2020,2010,1
Virginia Beach Psychiatric Center,Religion2020,2010,3
Virginia Commonwealth University,Cooperation2021,2010,3
Virginia Commonwealth University,CooperationAndReligion,2010,1
Virginia Commonwealth University,Religion2020,2010,50
Virginia Military Institute,Religion2020,2010,3
Virginia Polytechnic Institute and State University,Cooperation2021,2010,2
Virginia Polytechnic Institute and State University,Religion2020,2010,14
Virginia State University,Religion2020,2010,1
Virginia Tech,Cooperation2021,2010,2
Virginia Tech,Religion2020,2010,18
Virginia Theological Seminary,Religion2020,2010,4
Virginia Wesleyan College,Religion2020,2010,3
Virtual Scientific Laboratories,Cooperation2021,2010,7
Vision of Hope Baptist Church,Religion2020,2010,1
Vital Research,Religion2020,2010,5
Voice Medicine Australia,Religion2020,2010,1
Vrije Universiteit Amsterdam,Cooperation2021,2010,49
Vrije Universiteit Amsterdam,CooperationAndReligion,2010,2
Vrije Universiteit Amsterdam,Religion2020,2010,162
Vrije Universiteit Brussel,Cooperation2021,2010,13
Vrije Universiteit Brussel,Religion2020,2010,27
Vrije Universiteit Medical Center,Religion2020,2010,8
Vrije University Brussels,Cooperation2021,2010,4
Vrije University Brussels,Religion2020,2010,11
Vytautas Magnus University,Religion2020,2010,5
Wabash Center for Teaching and Learning in Theology and Religion,Religion2020,2010,2
Wabash College,Religion2020,2010,5
Wadham College,Religion2020,2010,3
Wageningen University,Cooperation2021,2010,10
Wageningen University,Religion2020,2010,11
Wake Forest,Religion2020,2010,1
Wake Forest Baptist Health,Religion2020,2010,21
Wake Forest University,Cooperation2021,2010,3
Wake Forest University,Religion2020,2010,36
Wakefield Diocese,Religion2020,2010,5
Walden University,Religion2020,2010,8
Wallace Community College,Cooperation2021,2010,1
Walter Reed Army Institute of Research,Religion2020,2010,3
Warburg Institute,Religion2020,2010,17
Wartburg College,Religion2020,2010,3
Wartburg Theological Seminary,Religion2020,2010,18
Warwick University,Religion2020,2010,1
Waseda University,Cooperation2021,2010,32
Waseda University,Religion2020,2010,5
Washington and Lee University,Religion2020,2010,3
Washington College,Religion2020,2010,1
Washington State University,Cooperation2021,2010,5
Washington State University,Religion2020,2010,3
Washington University in St Louis,Cooperation2021,2010,31
Washington University in St Louis,CooperationAndReligion,2010,2
Washington University in St Louis,Religion2020,2010,41
Washington University School of Medicine,Religion2020,2010,10
Wasiwaska,Religion2020,2010,1
Waterford Institute of Technology,Religion2020,2010,2
Waterloo Lutheran Seminary,Religion2020,2010,3
Wayne State University,Cooperation2021,2010,7
Wayne State University,Religion2020,2010,20
We Honor Veterans,Religion2020,2010,3
Webster University,Religion2020,2010,2
Weill Cornell Medical College,Cooperation2021,2010,1
Weill Cornell Medical College,Religion2020,2010,13
Weill Cornell Medical College in Qatar,Cooperation2021,2010,2
Weill Cornell Medical College in Qatar,Religion2020,2010,2
Weill Medical College of Cornell University,Religion2020,2010,1
Weizmann Institute of Science,Cooperation2021,2010,4
Wellcome Sanger Institute,Religion2020,2010,3
Wellesley College,Religion2020,2010,20
welsh government,Religion2020,2010,3
Welsh National Centre for Religious Education,Religion2020,2010,2
Wenzhou University,Cooperation2021,2010,7
Wesley Theological Seminary,Religion2020,2010,4
Wesleyan University,Religion2020,2010,8
West Chester University of Pennsylvania,Religion2020,2010,2
West Virginia University,Cooperation2021,2010,9
West Virginia University,CooperationAndReligion,2010,3
West Virginia University,Religion2020,2010,38
Western Carolina University,Religion2020,2010,9
Western Galilee College,Religion2020,2010,7
Western Michigan University,Cooperation2021,2010,1
Western Michigan University,CooperationAndReligion,2010,1
Western Michigan University,Religion2020,2010,17
Western Sydney University,Religion2020,2010,28
Western University,Cooperation2021,2010,5
Western University,Religion2020,2010,3
Western Washington University,Religion2020,2010,8
Westfalische University,Religion2020,2010,2
Westminster College,Religion2020,2010,13
Westminster Pastoral Foundation,Religion2020,2010,3
Westminster Seminary California,Religion2020,2010,1
Westmont College,Cooperation2021,2010,1
Westmont College,CooperationAndReligion,2010,1
Westmont College,Religion2020,2010,33
Westphalian Wilhelms University,Religion2020,2010,5
Wheaton College,Cooperation2021,2010,2
Wheaton College,Religion2020,2010,74
Whitefield Theological Seminary,Religion2020,2010,7
Whitley College,Religion2020,2010,5
Whittier College,Religion2020,2010,6
Whitworth University,Religion2020,2010,7
WHO collaborating centre for research and training in mental health,Cooperation2021,2010,1
WHO collaborating centre for research and training in mental health,Religion2020,2010,1
Wichita State University,Religion2020,2010,14
Widener University,Religion2020,2010,5
Wildhouse Publications,Religion2020,2010,3
Wilfrid Laurier University,Cooperation2021,2010,11
Wilfrid Laurier University,Religion2020,2010,32
Willamette University,Cooperation2021,2010,3
Willamette University,Religion2020,2010,13
William & Mary,Cooperation2021,2010,3
William & Mary,CooperationAndReligion,2010,1
William & Mary,Religion2020,2010,24
William Carey International University,Religion2020,2010,6
William James College,Religion2020,2010,3
William Paterson University,Religion2020,2010,3
Wilmette Institute,Religion2020,2010,4
Winona State University,Religion2020,2010,4
Wisconsin School of Professional Psychology,Religion2020,2010,15
Wistar Institute,Cooperation2021,2010,2
Witten/Herdecke University,Cooperation2021,2010,1
Witten/Herdecke University,Religion2020,2010,9
Wittenberg University,Cooperation2021,2010,2
Wittenberg University,Religion2020,2010,1
Wittgenstein Centre for Demography and Global Human Capital  Demography and Global Human Capital,Religion2020,2010,2
WODC (Ministry of Security and Justice),Religion2020,2010,8
Wolfson College,Cooperation2021,2010,4
Wolfson College,Religion2020,2010,3
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,2010,7
Wonkwang University,Cooperation2021,2010,1
Woodbrooke Quaker Study Centre,Religion2020,2010,1
Woodbury University,Cooperation2021,2010,2
Woodbury University,Religion2020,2010,18
Woolf Institute,Religion2020,2010,1
Worcester Acute Hospital Trust,Religion2020,2010,12
worcester polytechnic institute,Religion2020,2010,3
World Council of Churches,Religion2020,2010,7
World Health Organisation,Cooperation2021,2010,3
World Health Organization,Cooperation2021,2010,4
Wrexham Glyndwr University,Religion2020,2010,14
Wright State University,Cooperation2021,2010,6
Wright State University,Religion2020,2010,8
Wuhan University,Cooperation2021,2010,4
Wuhan University,Religion2020,2010,17
Wuhan University of Technology,Cooperation2021,2010,2
Wyoming Department of Health,Religion2020,2010,4
Xavier University,Religion2020,2010,9
Xiamen University,Religion2020,2010,4
Xi'an JiaoTong University,Cooperation2021,2010,2
Xidian University,Cooperation2021,2010,9
Yale Divinity School,Cooperation2021,2010,1
Yale Divinity School,Religion2020,2010,34
Yale University,Cooperation2021,2010,77
Yale University,CooperationAndReligion,2010,2
Yale University,Religion2020,2010,119
Yale University Mind and Development Lab,Cooperation2021,2010,2
Yale University Mind and Development Lab,CooperationAndReligion,2010,1
Yale University Mind and Development Lab,Religion2020,2010,31
Yale University School of Medicine,Cooperation2021,2010,1
Yale University School of Medicine,Religion2020,2010,5
Yale-NUS College,Religion2020,2010,1
Yamaguchi Prefectural University,Religion2020,2010,6
Yat-sen University,Cooperation2021,2010,2
Yeshiva University,Religion2020,2010,18
Yeshivah of Flatbush High School,Religion2020,2010,2
Yeungnam University,Religion2020,2010,4
Yezreel Valley Academic College,Religion2020,2010,6
yoga for arthritis,Religion2020,2010,2
Yogis in Service,Religion2020,2010,2
Yonsei University,Cooperation2021,2010,10
Yonsei University,CooperationAndReligion,2010,1
Yonsei University,Religion2020,2010,40
York College of Pennsylvania,Religion2020,2010,10
York St John University,Cooperation2021,2010,1
York St John University,Religion2020,2010,36
York University,Cooperation2021,2010,6
York University,CooperationAndReligion,2010,1
York University,Religion2020,2010,67
Yorkshire County Cricket club and Aston Villa,Religion2020,2010,3
Youngstown State University,Religion2020,2010,7
Yuzuncu Yil University,Religion2020,2010,2
Zagazig University,Religion2020,2010,3
Zayed University,Religion2020,2010,1
ZEW - Leibniz Centre for European Economic Research,Cooperation2021,2010,8
Zhejiang Normal University,Religion2020,2010,3
Zhejiang Sci-tech University,Cooperation2021,2010,2
Zhejiang Sci-tech University,CooperationAndReligion,2010,1
Zhejiang Sci-tech University,Religion2020,2010,4
Zhejiang University,Cooperation2021,2010,17
Zhejiang University,Religion2020,2010,1
Zhejiang University of Finance and Economics,Cooperation2021,2010,8
Ziauddin University,Cooperation2021,2010,1
Zurich University of Applied Sciences,Religion2020,2010,3
 All Souls College,Religion2020,2005,1
 H.L. Moffitt Cancer Center and Research Institute,Cooperation2021,2005,1
 H.L. Moffitt Cancer Center and Research Institute,Religion2020,2005,4
 Meir Medical Center,Religion2020,2005,3
 Michigan Center for Urban African American Aging Research Community Liaison Core,Religion2020,2005,1
 University of the District of Columbia,Religion2020,2005,4
 Xi’an Jiaotong University,Cooperation2021,2005,2
16Pixels IIT Gandhinagar  16Pixels IIT Gandhinagar  Vajra fellow and Adjunct professor  16Pixels IIT Gandhinagar,Religion2020,2005,1
Aahrus University,Religion2020,2005,3
Aarhus University,Cooperation2021,2005,7
Aarhus University,Religion2020,2005,41
Abbott Northwestern Hospital,Religion2020,2005,1
Aberdeen University,Religion2020,2005,1
Aberystwyth University,Religion2020,2005,3
Abilene Christian University,Cooperation2021,2005,1
Abilene Christian University,Religion2020,2005,7
Ables Editing Services,Religion2020,2005,4
abo akademi university,Religion2020,2005,1
Åbo Akademi University,Religion2020,2005,10
Abt Associates,Religion2020,2005,2
Academia Sinica,Cooperation2021,2005,5
Academia Sinica,Religion2020,2005,1
Academy of Finland,Religion2020,2005,3
Academy of Sciences of Albania,Religion2020,2005,3
Acadia University,Cooperation2021,2005,1
Acadia University,Religion2020,2005,1
Achva Academic College,Religion2020,2005,2
Acton Institute for the Study of Religion and Liberty,Religion2020,2005,1
Addis Ababa University,Religion2020,2005,1
Adelphi University,Cooperation2021,2005,1
Adnan Menderes University,Religion2020,2005,1
Advocate Aurora Health Care,Religion2020,2005,1
Advocates for African American Elders,Religion2020,2005,1
Aga Khan University,Cooperation2021,2005,2
Aga Khan University,Religion2020,2005,9
Agricultural Institute,Cooperation2021,2005,4
Aix-Marseille Université,Cooperation2021,2005,3
Aix-Marseille Université,Religion2020,2005,10
Akron General Medical Center,Religion2020,2005,3
Alabama Department of Corrections,Religion2020,2005,1
Albany Law School,Religion2020,2005,4
Albert Einstein College of Medicine,Cooperation2021,2005,3
Albert Einstein College of Medicine,Religion2020,2005,8
Alberta Health Services,Religion2020,2005,4
Albion College,Religion2020,2005,3
Alcohol Research Group,Religion2020,2005,3
Alfaisal University,Religion2020,2005,7
Alfred University,Religion2020,2005,3
Alfred Wegener Institute Helmholtz center for polar and marine research,Cooperation2021,2005,1
Aliz Health Apps LLC,Religion2020,2005,1
All India Institute of Medical Sciences,Religion2020,2005,6
All India Institute of Medical Sciences - New Delhi,Religion2020,2005,3
Allegheny College,Religion2020,2005,2
Alliant International University,Religion2020,2005,4
Allina Health,Religion2020,2005,2
Alma College,Religion2020,2005,3
Alphacrucis College,Religion2020,2005,14
Alzheimer's Association,Religion2020,2005,3
Amazon,Cooperation2021,2005,3
Ambrose University,Cooperation2021,2005,1
Ambrose University,Religion2020,2005,3
american academy of neurology,Religion2020,2005,7
American Academy of Nursing,Religion2020,2005,5
American College Personnel Association,Religion2020,2005,1
American Council on Education,Religion2020,2005,1
American Enterprise Institute,Religion2020,2005,1
American Geographical Society,Cooperation2021,2005,2
American Geographical Society,Religion2020,2005,1
American Institutes for Research,Religion2020,2005,1
American Jewish University,Religion2020,2005,1
American Museum of Natural History,Religion2020,2005,2
American University,Cooperation2021,2005,3
American University,Religion2020,2005,12
American University of Beirut,Religion2020,2005,4
American University of Kuwait,Religion2020,2005,7
American University of Sharjah,Religion2020,2005,1
American University of Sovereign Nations,Cooperation2021,2005,1
Amherst College,Religion2020,2005,5
Amherst H. Wilder Foundation,Religion2020,2005,1
Amniotics,Religion2020,2005,1
Amsterdam UMC,Religion2020,2005,6
Analytical Centre of the Russian Academy of Sciences,Religion2020,2005,1
Andhra University,Religion2020,2005,2
Andover Newton Theological School,Religion2020,2005,4
Andrews University,Religion2020,2005,2
Anglia Ruskin University,Religion2020,2005,2
Anscombe Bioethics Centre,Religion2020,2005,2
Antares Foundation,Religion2020,2005,1
Aoyama Gakuin University,Cooperation2021,2005,5
Apex School of Theology,Religion2020,2005,1
Appalachian State University,Cooperation2021,2005,8
Appalachian State University,Religion2020,2005,2
Archdiocese of Johannesburg,Religion2020,2005,1
"Archeological & Environmental Consultants, LLC",Religion2020,2005,2
Arctic University of Norway,Religion2020,2005,1
Argentine Catholic University,Religion2020,2005,5
Argonne National Laboratory,Cooperation2021,2005,1
Ariel University,Cooperation2021,2005,1
Ariel University,Religion2020,2005,9
Arizona State University,Cooperation2021,2005,42
Arizona State University,CooperationAndReligion,2005,4
Arizona State University,Religion2020,2005,103
Arkansas State University,Religion2020,2005,2
ARTIS International,Cooperation2021,2005,2
ARTIS International,CooperationAndReligion,2005,1
ARTIS International,Religion2020,2005,4
Asahikawa Medical University,Religion2020,2005,3
Ascension Health,Religion2020,2005,2
Ashkelon Academic College,Religion2020,2005,7
Ashland University,Religion2020,2005,2
Ashoka University,Cooperation2021,2005,1
Asia University,Cooperation2021,2005,1
Asia University,Religion2020,2005,5
ASM Affiliates Inc,Religion2020,2005,2
Association of Improvement for Mental Health Programmes,Cooperation2021,2005,1
Association of Improvement for Mental Health Programmes,Religion2020,2005,1
Association of Religion Data Archives,Religion2020,2005,1
Aston University,Religion2020,2005,8
AstraZeneca,Cooperation2021,2005,4
Ateneo de Manila University,Religion2020,2005,1
Athens State University,Cooperation2021,2005,1
Athens State University,CooperationAndReligion,2005,1
Athens State University,Religion2020,2005,2
Atlantic School of Theology,Religion2020,2005,1
Auburn University,Religion2020,2005,12
Auckland University of Technology,Cooperation2021,2005,1
Auckland University of Technology,Religion2020,2005,5
Augusta State College,Religion2020,2005,3
Augusta University,Cooperation2021,2005,2
Augusta University,Religion2020,2005,8
Augustana College,Religion2020,2005,6
Augustana University,Religion2020,2005,9
Austin Presbyterian Theological Seminary,Religion2020,2005,19
Australia Theological Hall,Religion2020,2005,4
Australian Catholic University,Cooperation2021,2005,1
Australian Catholic University,Religion2020,2005,78
Australian Council for Educational Research,Religion2020,2005,7
Australian National University,Cooperation2021,2005,10
Australian National University,Religion2020,2005,27
Australian Research Center for Complementary & Integrative Medicine [ARCCIM],Religion2020,2005,1
Austrian Academy of Sciences,Religion2020,2005,3
Autonomous University of Barcelona,Cooperation2021,2005,1
Ave Maria University,Religion2020,2005,6
Axios Consulting Group,Cooperation2021,2005,3
Azabu University,Cooperation2021,2005,2
Azienda Unita Sanitaria Modena,Religion2020,2005,1
Azim Premji University,Religion2020,2005,1
Azusa Pacific University,Cooperation2021,2005,1
Azusa Pacific University,Religion2020,2005,6
B.G. Rickhi Professional Corp.,Religion2020,2005,2
Ball State University,Religion2020,2005,4
Bangalore University,Religion2020,2005,2
Bangor University,Religion2020,2005,4
Baptist Seminary of Kentucky,Religion2020,2005,3
Baqiyatallah University of Medical Sciences,Religion2020,2005,1
Bar Ilan University,Cooperation2021,2005,2
Bar Ilan University,Religion2020,2005,21
Bard College,Religion2020,2005,25
Bar-Ilan University,Cooperation2021,2005,2
Bar-Ilan University,Religion2020,2005,60
Barnabas Health Jersey City Medical Center,Religion2020,2005,1
Barnard College,Religion2020,2005,10
Barrow Neurological Institute,Religion2020,2005,5
Barry University,Religion2020,2005,3
Barts Cancer Center,Religion2020,2005,2
Bashkir State University,Religion2020,2005,16
Bastyr University,Cooperation2021,2005,1
Bastyr University,Religion2020,2005,7
Bath Spa University,Religion2020,2005,10
Baycrest Geriatric Centre,Cooperation2021,2005,1
Baycrest Geriatric Centre,Religion2020,2005,2
Baylor College of Medicine,Cooperation2021,2005,9
Baylor College of Medicine,Religion2020,2005,14
Baylor University,Cooperation2021,2005,5
Baylor University,Religion2020,2005,104
Baystate Medical Center,Religion2020,2005,1
BC Centre for Palliative Care,Religion2020,2005,14
Beihang University,Cooperation2021,2005,13
Beijing Foreign Studies University,Cooperation2021,2005,1
"Beijing Hospital of Traditional Medicine, Capital Medical Univeristy",Cooperation2021,2005,3
Beijing Institute of Technology,Cooperation2021,2005,1
Beijing Normal University,Cooperation2021,2005,21
Beijing Normal University,Religion2020,2005,8
Beijing University of Posts and Telecommunications,Cooperation2021,2005,10
Belaire Counseling Services,Religion2020,2005,1
Bellvitge University Hospital,Religion2020,2005,2
Bemidji State University,Religion2020,2005,1
Ben Gurion University in the Negev,Cooperation2021,2005,1
Ben Gurion University in the Negev,Religion2020,2005,4
Ben-Gurion University of the Negev,Cooperation2021,2005,6
Ben-Gurion University of the Negev,Religion2020,2005,21
Berea College,Religion2020,2005,2
Berry College,Religion2020,2005,4
Beth Israel Deaconess Medical Center,Religion2020,2005,3
Bethel College,Cooperation2021,2005,1
Bethel College,Religion2020,2005,2
Bethel University,Religion2020,2005,5
Bethlem Royal Hospital,Religion2020,2005,1
Bexley Seabury Seminary Federation,Religion2020,2005,6
BI Norwegian Business School,Cooperation2021,2005,6
Bible College of Malaysia,Religion2020,2005,1
biblical-theological academy,Religion2020,2005,5
Bielefeld University,Cooperation2021,2005,1
Bielefeld University,Religion2020,2005,2
Binghamton University,Religion2020,2005,3
Biola University,Cooperation2021,2005,2
Biola University,CooperationAndReligion,2005,1
Biola University,Religion2020,2005,31
Biostructures and Biosystems National Institute,Cooperation2021,2005,1
Birbeck University of London,Religion2020,2005,1
"Birkbeck, University of London",Religion2020,2005,2
Birmingham and Solihull Mental Health NHS Foundation Trust,Religion2020,2005,1
Birmingham Children's Hospital NHS Foundation Trust,Religion2020,2005,4
Birmingham City University,Religion2020,2005,22
Birmingham Southern College,Religion2020,2005,8
Birmingham University,Religion2020,2005,4
Bishop Grosseteste University,Religion2020,2005,7
Bishop's University,Religion2020,2005,1
Blackfox Philanthropy,Religion2020,2005,2
Bloomsburg University,Religion2020,2005,4
Blueprint 1543,Cooperation2021,2005,1
Blueprint 1543,CooperationAndReligion,2005,1
Blueprint 1543,Religion2020,2005,5
BM-Science - Brain & Mind Technologies Research Centre,Religion2020,2005,1
Bocconi University,Cooperation2021,2005,1
Boise State University,Religion2020,2005,2
Bon Secours Mercy Health,Religion2020,2005,10
Bosch,Cooperation2021,2005,4
Boston College,Cooperation2021,2005,2
Boston College,Religion2020,2005,47
Boston University,Cooperation2021,2005,5
Boston University,Religion2020,2005,107
Boston University School of Medicine,Religion2020,2005,2
Bournemouth University,Cooperation2021,2005,2
Bournemouth University,Religion2020,2005,6
Bowdoin College,Religion2020,2005,1
Bowie State University,Religion2020,2005,3
Bowling Green State University,Cooperation2021,2005,4
Bowling Green State University,Religion2020,2005,40
Bradford City Libraries,Religion2020,2005,2
Bradford Institute for Health Research,Cooperation2021,2005,1
Bradford Institute for Health Research,Religion2020,2005,3
Bradley University,Religion2020,2005,8
Brandeis University,Cooperation2021,2005,11
Brandeis University,Religion2020,2005,29
Brandon University,Religion2020,2005,2
Brawijaya University,Religion2020,2005,1
Bridgewater College,Cooperation2021,2005,4
Brigham & Women's Hospital,Religion2020,2005,24
Brigham Young University,Cooperation2021,2005,18
Brigham Young University,CooperationAndReligion,2005,2
Brigham Young University,Religion2020,2005,80
"Brio Systems, Inc.",Cooperation2021,2005,3
"Bristol Institute of Technology, UWE",Cooperation2021,2005,5
Brite Divinity,Religion2020,2005,6
British Academy,Religion2020,2005,9
British Journal of Psychiatry,Cooperation2021,2005,1
British Journal of Psychiatry,Religion2020,2005,5
Brock University,Cooperation2021,2005,3
Brock University,Religion2020,2005,6
Brookings Institution,Religion2020,2005,5
Brooklyn College,Religion2020,2005,8
Brooklyn Psychotherapy,Religion2020,2005,2
Brown University,Cooperation2021,2005,14
Brown University,CooperationAndReligion,2005,1
Brown University,Religion2020,2005,49
Brown University Medical School,Religion2020,2005,2
Brown University School of Public Health,Religion2020,2005,2
Bruce Rappaport Faculty of Medicine,Religion2020,2005,2
Brunel University London,Cooperation2021,2005,14
Brunel University London,CooperationAndReligion,2005,1
Brunel University London,Religion2020,2005,20
Bryn Mawr College,Religion2020,2005,4
Bucknell University,Religion2020,2005,8
Buffalo State College,Cooperation2021,2005,7
Buffalo State College,Religion2020,2005,3
Butler University,Religion2020,2005,4
C.G. Jung Institute of New York,Religion2020,2005,7
Ca' Foscari University,Cooperation2021,2005,3
CableLabs,Cooperation2021,2005,1
CAFM Educational Research Alliance (CERA),Religion2020,2005,4
Californa Institute of Integral Studies  California Institute of Integral Studies,Religion2020,2005,3
California Baptist University,Religion2020,2005,1
California Institute of Integral Studies,Religion2020,2005,2
California Institute of Technology,Cooperation2021,2005,12
California Lutheran University,Cooperation2021,2005,1
California Lutheran University,CooperationAndReligion,2005,1
California Lutheran University,Religion2020,2005,18
California Pacific Medical Center,Religion2020,2005,3
California State Polytechnic University,Religion2020,2005,4
California State University,Religion2020,2005,8
"California State University, Chico",Religion2020,2005,5
"California State University, Dominguez Hills",Religion2020,2005,7
"California State University, Fullerton",Religion2020,2005,6
"California State University, Long Beach",Religion2020,2005,8
"California State University, Northridge",Religion2020,2005,1
"California State University, Sacramento",Religion2020,2005,1
"California State University, Stanislaus",Religion2020,2005,1
Calvin College,Religion2020,2005,27
Calvin University,Cooperation2021,2005,1
Calvin University,Religion2020,2005,13
Cambridge Health Alliance,Religion2020,2005,3
Cambridge University,Religion2020,2005,26
Canada Research Chairs,Cooperation2021,2005,6
Canadian Institute of Natural and Integrative Medicine,Religion2020,2005,2
Canadian Mental Health Association,Religion2020,2005,3
Cancer Research UK London,Cooperation2021,2005,1
Cancer Treatment centers of America,Religion2020,2005,9
CancerLife,Cooperation2021,2005,3
Canterbury Christ Church University,Cooperation2021,2005,1
Canterbury Christ Church University,Religion2020,2005,2
Canterbury Christchurch University,Religion2020,2005,7
Canterbury District Health Board,Religion2020,2005,1
Cantonal Hospital,Religion2020,2005,1
Cape Breton University,Cooperation2021,2005,1
Cape Breton University,Religion2020,2005,4
Cardiff University,Cooperation2021,2005,10
Cardiff University,CooperationAndReligion,2005,2
Cardiff University,Religion2020,2005,69
Cardozo School of Law,Religion2020,2005,1
Carleton College,Cooperation2021,2005,2
Carleton College,Religion2020,2005,10
Carleton University,Cooperation2021,2005,4
Carleton University,Religion2020,2005,9
Carnegie Mellon University,Cooperation2021,2005,3
Carnegie Mellon University,Religion2020,2005,6
Carroll College,Religion2020,2005,2
Case Western Reserve University,Religion2020,2005,24
Catawba College,Religion2020,2005,4
Catholic Ecumenical Institute,Cooperation2021,2005,1
Catholic Ecumenical Institute,Religion2020,2005,2
Catholic Health Association,Religion2020,2005,3
Catholic Institute of Paris,Religion2020,2005,1
Catholic Research and Development Centre,Religion2020,2005,5
Catholic Theological Union,Religion2020,2005,2
Catholic University of America,Cooperation2021,2005,1
Catholic University of America,Religion2020,2005,41
Catholic University of Brasília,Religion2020,2005,4
Catholic University of Eichstätt-Ingolstadt,Religion2020,2005,5
Catholic University of Korea,Religion2020,2005,1
Catholic University of Leuven,Cooperation2021,2005,1
Catholic University of Leuven,CooperationAndReligion,2005,1
Catholic University of Leuven,Religion2020,2005,18
Catholic University of Louvain,Cooperation2021,2005,7
Catholic University of Louvain,Religion2020,2005,3
Catholic University of Lublin,Religion2020,2005,2
Catholic University of Milan,Religion2020,2005,1
Catholic University of the Sacred Heart,Religion2020,2005,1
Cedars-Sinai Medical Center,Cooperation2021,2005,1
Cedarville University,Religion2020,2005,4
Cégep Édouard-Montpetit,Religion2020,2005,5
Cell-El Therapeutics LTD,Cooperation2021,2005,1
Cell-El Therapeutics LTD,Religion2020,2005,4
Center for Applied Research in the Apostolate,Religion2020,2005,1
Center for Care Delivery and Outcomes Research,Religion2020,2005,1
Center for Change,Religion2020,2005,12
Center for Critical Research on Religion,Religion2020,2005,4
Center for Executive Solutions,Religion2020,2005,2
"Center for Health Education, Medicine, & Dentistry",Religion2020,2005,4
Center for Interfaith Reconciliation,Religion2020,2005,1
Center for Marketing and Consumer Science,Cooperation2021,2005,9
Center for Marketing and Consumer Science,Religion2020,2005,1
Center for Mind and Culture,Religion2020,2005,6
Center for Oneness & Transforming Leadership,Religion2020,2005,1
Center for Open Science,Religion2020,2005,1
Center For Partnership Studies,Religion2020,2005,1
Center for Professional Studies,Religion2020,2005,11
Center for Psychobiology of Personality,Cooperation2021,2005,2
Center for Psychobiology of Personality,Religion2020,2005,3
Center for Psychosocial Research,Religion2020,2005,27
Center for Religion and Contemporary Society,Religion2020,2005,5
Center for Religious Tolerance,Religion2020,2005,2
Center for Research and Advanced Studies of the National Polytechnic Institute,Cooperation2021,2005,5
Center for Research and Higher Studies in Social Anthropology,Religion2020,2005,2
Center for Research and Post Graduate Studies in Social Anthropology,Religion2020,2005,2
Center for Scientific Research,Cooperation2021,2005,3
Center for Scientific Research,CooperationAndReligion,2005,1
Center for Scientific Research,Religion2020,2005,1
Center for Studies on New Religions,Religion2020,2005,2
Center for Taiji Studies,Cooperation2021,2005,3
Center for Taiji Studies,Religion2020,2005,2
Center for the Study on New Religions,Religion2020,2005,2
Center for Theological Inquiry,Religion2020,2005,2
Center for Trauma-Sensitive Faith Leadership,Religion2020,2005,1
Center of Innovation for Complex Chronic Healthcare,Religion2020,2005,3
Center to Advance Palliative Care,Cooperation2021,2005,1
Center to Advance Palliative Care,Religion2020,2005,2
Centers for Disease Control and Prevention,Religion2020,2005,8
Central Baptist Theological Seminary,Religion2020,2005,4
Central Michigan University,Religion2020,2005,13
Central Pennsylvania's Community College,Religion2020,2005,4
Central Queensland University,Cooperation2021,2005,4
Central Queensland University,CooperationAndReligion,2005,1
Central Queensland University,Religion2020,2005,5
Central Research Institute for Physics,Cooperation2021,2005,10
Central South University,Cooperation2021,2005,1
"Central University of Bihar, Patna",Religion2020,2005,5
Central University of Kerala,Religion2020,2005,4
CentraleSupelec,Cooperation2021,2005,3
Centre Alexandre Koyre,Religion2020,2005,1
Centre d'Ecologie Fonctionnelle et Evolutive,Cooperation2021,2005,3
Centre for Addiction and Mental Health,Cooperation2021,2005,3
Centre for Addiction and Mental Health,Religion2020,2005,5
Centre for Cellular & Molecular Biology,Religion2020,2005,2
Centre for Classical and Near Eastern Studies of Australia,Religion2020,2005,4
Centre for Economic Research and Graduate Education,Cooperation2021,2005,1
Centre for Research and Evidence on Security Threats,Religion2020,2005,8
Centre for Research and Professional Learning- University of Exeter,Cooperation2021,2005,1
Centre for Research and Professional Learning- University of Exeter,Religion2020,2005,3
Centre for Social and Behavior Change,Cooperation2021,2005,1
Centre for the Study of Developing Societies,Religion2020,2005,2
Centre Hospitalier Universitaire Vaudois,Cooperation2021,2005,2
Centre Hospitalier Universitaire Vaudois,Religion2020,2005,5
Centre National de la Recherche Scientifique,Cooperation2021,2005,4
Centre National de la Recherche Scientifique,Religion2020,2005,12
Centre of Addiction and Mental Health (CAMH),Religion2020,2005,1
CERGE-E,Cooperation2021,2005,2
CERGE-E,Religion2020,2005,1
Cervera Real Estate,Religion2020,2005,6
CHA University,Religion2020,2005,2
Chamberlain University,Religion2020,2005,8
Chang Gung University,Cooperation2021,2005,1
Chang Gung University,Religion2020,2005,5
Changwon National University,Religion2020,2005,2
Chapman University,Cooperation2021,2005,11
Chapman University,Religion2020,2005,6
Charité Universitätsmedizin Berlin,Religion2020,2005,2
Charles R. Drew University of Medicine & Science,Cooperation2021,2005,1
Charles R. Drew University of Medicine & Science,CooperationAndReligion,2005,1
Charles R. Drew University of Medicine & Science,Religion2020,2005,2
Charles Sturt University,Religion2020,2005,10
Charles University,Religion2020,2005,1
Charles University in Prague,Religion2020,2005,2
ChenMed,Religion2020,2005,9
Chennai School of Music Therapy,Religion2020,2005,3
Cheongju University,Religion2020,2005,1
Cherry Hill Seminary,Religion2020,2005,3
Cheung Kong Graduate School of Business,Cooperation2021,2005,6
Cheung Kong Graduate School of Business,Religion2020,2005,1
Chiba University,Religion2020,2005,2
Chicago Center for Family Health,Religion2020,2005,3
CHILD USA,Religion2020,2005,5
Children’s National Medical Center,Religion2020,2005,2
Children's Hospital of Michigan,Religion2020,2005,7
Children's Hospital of Philadelphia,Cooperation2021,2005,4
Children's Hospital of Philadelphia,Religion2020,2005,4
Children's Investment Fund Foundation,Religion2020,2005,7
Children's National Medical Center,Religion2020,2005,2
Children's Wisconsin,Religion2020,2005,1
China Medical University,Cooperation2021,2005,3
China University of Mining and Technology,Cooperation2021,2005,4
Chinese Academy of Sciences,Cooperation2021,2005,12
Chinese University of Hong Kong,Cooperation2021,2005,11
Chinese University of Hong Kong,Religion2020,2005,21
CHOICE Humanitarian,Religion2020,2005,2
Chongqin University,Cooperation2021,2005,1
Chongqing Institute of Green and Intelligent Technology,Cooperation2021,2005,1
Christian Brothers University,Religion2020,2005,2
Christian Council on Ageing,Religion2020,2005,2
Christian Michelsen Institute,Religion2020,2005,3
Christian Psychological Services,Religion2020,2005,4
Christian Theological Seminary,Religion2020,2005,1
Christopher Newport University,Religion2020,2005,1
Chulalongkorn University,Cooperation2021,2005,1
Chung Shan Medical University,Religion2020,2005,1
Church of England,Religion2020,2005,21
Church of Ireland Theological College,Religion2020,2005,3
Church of Northampton,Religion2020,2005,1
Church of Scotland,Religion2020,2005,4
Church of Sweden,Religion2020,2005,6
Church Research Institute,Religion2020,2005,2
CICERO Center for International Climate Research,Cooperation2021,2005,4
Cincinnati Children's Hospital Medical Center,Religion2020,2005,1
CIRANO,Cooperation2021,2005,1
Cisco,Religion2020,2005,8
City College of New York- Brooklyn College,Religion2020,2005,5
City College of New York Hunter College,Religion2020,2005,2
"City Hospital, Nottingham",Religion2020,2005,3
City of Hope National Medical Center,Cooperation2021,2005,1
City of Hope National Medical Center,Religion2020,2005,17
City University London,Religion2020,2005,2
City University of Hong Kong,Cooperation2021,2005,1
City University of Hong Kong,Religion2020,2005,3
City University of New York,Cooperation2021,2005,5
City University of New York,Religion2020,2005,14
City University of New York Graduate Center,Cooperation2021,2005,1
City University of New York Graduate Center,Religion2020,2005,2
Claremont Graduate University,Cooperation2021,2005,5
Claremont Graduate University,CooperationAndReligion,2005,1
Claremont Graduate University,Religion2020,2005,45
Claremont McKenna College,Religion2020,2005,9
Claremont school of theology,Religion2020,2005,11
Clarian Health Partners,Religion2020,2005,1
Clark University,Religion2020,2005,3
Claude Bernard University Lyon 1,Religion2020,2005,8
Clemson University,Cooperation2021,2005,1
Clemson University,Religion2020,2005,21
Cleveland State University,Cooperation2021,2005,1
Cleveland State University,Religion2020,2005,2
Clinic SGM Langenthal and Research Institute for Spirituality and Health,Religion2020,2005,1
Clinical Psychologist,Religion2020,2005,2
Clinical Psychology Doctoral Training Programme,Religion2020,2005,3
Club of Budapest,Religion2020,2005,2
CNRS Comité national de la recherche scientifique,Cooperation2021,2005,7
CNRS Comité national de la recherche scientifique,Religion2020,2005,8
CNRS Délégation Rhône-Auvergne,Cooperation2021,2005,2
Coalition for Compassionate Care of California,Religion2020,2005,5
Coe College,Religion2020,2005,1
Colby College,Religion2020,2005,1
Colgate University,Religion2020,2005,5
Collaborative Caring,Religion2020,2005,5
"Collaborative for Academic, Social, and Emotional Learning (CASEL)",Religion2020,2005,1
Collège de France,Religion2020,2005,8
College of Charleston,Religion2020,2005,3
College of Charlestown,Religion2020,2005,5
College of Communication Engineering,Cooperation2021,2005,2
College of France,Religion2020,2005,4
College of Holy Cross,Religion2020,2005,5
College of St. Scholastica,Religion2020,2005,1
College of the Holy Cross,Religion2020,2005,6
Colorado School of Mines,Religion2020,2005,3
Colorado State University,Cooperation2021,2005,4
Colorado State University,Religion2020,2005,17
Columbia Theological Seminary,Religion2020,2005,8
Columbia University,Cooperation2021,2005,15
Columbia University,Religion2020,2005,100
Columbia University Medical Center,Religion2020,2005,2
Coma Science Group,Religion2020,2005,2
Comenius Institute,Religion2020,2005,3
Commap - Communicatie Apparatuur BV,Religion2020,2005,1
Community Health Cooperative,Religion2020,2005,2
Compass Health Research Institute,Religion2020,2005,1
Complutense University of Madrid,Cooperation2021,2005,8
Complutense University of Madrid,Religion2020,2005,1
Conception Seminary College,Religion2020,2005,3
Concordia College,Religion2020,2005,8
Concordia Seminary,Religion2020,2005,3
Concordia University,Cooperation2021,2005,8
Concordia University,Religion2020,2005,28
Connecticut College,Religion2020,2005,3
Constantine the Philosopher University,Cooperation2021,2005,2
Constantine the Philosopher University,CooperationAndReligion,2005,2
Constantine the Philosopher University,Religion2020,2005,12
Copenhagen Business School,Religion2020,2005,14
"Corium, Inc.",Cooperation2021,2005,3
Cornell University,Cooperation2021,2005,27
Cornell University,Religion2020,2005,42
Corporate Health Solutions,Religion2020,2005,1
Corvinus University of Budapest,Cooperation2021,2005,3
Corvinus University of Budapest,Religion2020,2005,2
Cosmos & Creation,Religion2020,2005,2
Council of European Churches,Religion2020,2005,1
Council on Spiritual Practices,Religion2020,2005,2
Courage of Care Coalition,Religion2020,2005,3
Coventry University,Cooperation2021,2005,1
Coventry University,CooperationAndReligion,2005,1
Coventry University,Religion2020,2005,10
"Cox Family Medicine Associates, Springfield",Religion2020,2005,1
Crandall University,Religion2020,2005,3
Cranfield University,Religion2020,2005,3
Creighton University,Religion2020,2005,12
CRI,Cooperation2021,2005,2
CSIRO,Cooperation2021,2005,7
CSIRO,Religion2020,2005,1
CUNY,Cooperation2021,2005,2
CUNY,Religion2020,2005,37
CUNY Graduate Center,Religion2020,2005,2
"CUNY, College of Staten Island",Religion2020,2005,3
"CUNY, Hunter College",Cooperation2021,2005,1
Curtin University,Cooperation2021,2005,5
Curtin University,CooperationAndReligion,2005,3
Curtin University,Religion2020,2005,1
Curtin University of Technology,Religion2020,2005,2
CWI Amsterdam,Cooperation2021,2005,5
Czech Academy of Sciences,Cooperation2021,2005,1
Daegu University,Religion2020,2005,2
Dalhousie University,Cooperation2021,2005,3
Dalhousie University,Religion2020,2005,15
Dalian University of Technology,Cooperation2021,2005,1
Dalian University of Technology,Religion2020,2005,2
Dana-Farber Cancer Institute,Cooperation2021,2005,11
Dana-Farber Cancer Institute,Religion2020,2005,20
Dartmouth College,Cooperation2021,2005,2
Dartmouth College,Religion2020,2005,16
Davidson College,Religion2020,2005,6
De Montfort University,Cooperation2021,2005,1
De Montfort University,CooperationAndReligion,2005,1
De Montfort University,Religion2020,2005,3
Deakin University,Cooperation2021,2005,1
Deakin University,Religion2020,2005,33
DeepMind,Cooperation2021,2005,3
Delaware Law School,Religion2020,2005,1
Delft University of Technology,Cooperation2021,2005,11
DeltaQuest Foundation,Religion2020,2005,2
Denison University,Religion2020,2005,11
Denver Seminary,Religion2020,2005,1
Department of Religious Affairs of the Ministry of the Interior,Religion2020,2005,1
DePaul University,Religion2020,2005,8
DePauw University,Religion2020,2005,4
Dickinson College,Religion2020,2005,1
Divine Mercy University,Religion2020,2005,6
Division of Diabetes Translation,Religion2020,2005,4
Doha Institute for Graduate Studies,Religion2020,2005,2
Domar Center for Mind/Body Health,Religion2020,2005,1
Dominican University of California,Religion2020,2005,1
Dongguan University of Technology,Cooperation2021,2005,3
Dongseo University,Religion2020,2005,1
D'Or Institute of Education and Research,Cooperation2021,2005,1
Downing College,Religion2020,2005,2
Dr. Barlow Counselling,Religion2020,2005,1
Dr. Justin Jones (Licensed Clinical Psychologist),Religion2020,2005,1
Drake University,Religion2020,2005,1
Draper Laboratory,Cooperation2021,2005,9
Dresden University of Technology,Religion2020,2005,3
Drew University,Religion2020,2005,8
Drew University Theological School,Religion2020,2005,1
Drexel University,Cooperation2021,2005,6
Drexel University,Religion2020,2005,2
Dublin City University,Religion2020,2005,7
Duke Divinity School,Cooperation2021,2005,1
Duke Divinity School,Religion2020,2005,30
Duke Kunshan University,Religion2020,2005,3
Duke University,Cooperation2021,2005,40
Duke University,Religion2020,2005,163
Duquesne University,Religion2020,2005,31
Durban University of Technology,Religion2020,2005,1
Durham University,Cooperation2021,2005,3
Durham University,CooperationAndReligion,2005,1
Durham University,Religion2020,2005,100
Duta Wacana University,Religion2020,2005,2
EAPC Primary Care Reference Group,Religion2020,2005,2
EAPC Spiritual Care Reference Group,Religion2020,2005,2
East Carolina University,Cooperation2021,2005,1
East Carolina University,Religion2020,2005,15
East China Normal University,Cooperation2021,2005,4
East China University of Science and Technology,Cooperation2021,2005,9
East Tennessee State University,Religion2020,2005,1
Eastern Kentucky University,Religion2020,2005,2
Eastern Mennonite University,Religion2020,2005,3
Eastern Michigan University,Religion2020,2005,1
Eastern University,Religion2020,2005,1
E-Bus Competence Center,Cooperation2021,2005,5
Ecclesiastical University of Education Vienna,Religion2020,2005,1
Eckerd College,Religion2020,2005,1
Ecole des Hautes Etudes,Religion2020,2005,9
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,2005,8
École des Hautes Etudes en Sciences Sociales,Religion2020,2005,3
Ecole Francaise D'Extreme-Orient,Religion2020,2005,3
Ecole Normale Supérieure de Saint-Cloud,Religion2020,2005,3
Ecole Polytechnique,Cooperation2021,2005,1
Ecole Polytechnique Fédérale de Lausanne,Cooperation2021,2005,8
Ecole Pratique des Hautes Etudes,Religion2020,2005,14
École Pratique des Hautes Etudes,Cooperation2021,2005,1
École Pratique des Hautes Etudes,CooperationAndReligion,2005,1
École Pratique des Hautes Etudes,Religion2020,2005,13
École pratique des hautes études,Cooperation2021,2005,1
École pratique des hautes études,CooperationAndReligion,2005,1
École pratique des hautes études,Religion2020,2005,15
ECU Physicians-Adult & Pediatric Health Care,Religion2020,2005,2
Ecumenical Institute,Religion2020,2005,8
Edge Hill University,Religion2020,2005,2
Edgewalkers International,Religion2020,2005,7
Edinburgh Napier University,Cooperation2021,2005,3
Edinburgh University,Religion2020,2005,1
Edith Cowan University,Religion2020,2005,1
Eindhoven University of Technology,Cooperation2021,2005,1
"El Colegio de México, AC",Cooperation2021,2005,9
ELCA Informatique SA / ELCA Informatik AG,Cooperation2021,2005,11
Eliot Hospital,Religion2020,2005,2
Embry-Riddle Aeronautical University,Religion2020,2005,1
Emerson College,Religion2020,2005,1
Emmanuel College,Religion2020,2005,6
Emory College,Religion2020,2005,3
Emory University,Cooperation2021,2005,35
Emory University,CooperationAndReligion,2005,2
Emory University,Religion2020,2005,96
Employment Assistance Program,Religion2020,2005,1
Engender Health,Religion2020,2005,2
Environmental Health Agency,Cooperation2021,2005,1
Environmental Health Agency,CooperationAndReligion,2005,1
Environmental Health Agency,Religion2020,2005,1
Eotvos Lorand University,Cooperation2021,2005,3
Eötvös Loránd University,Cooperation2021,2005,9
Epilepsy Foundation of New Zealand,Religion2020,2005,1
Erasmus University Rotterdam,Cooperation2021,2005,4
Erasmus University Rotterdam,Religion2020,2005,13
Erin Presbyterian Church,Religion2020,2005,4
Esprit Journal,Religion2020,2005,11
Esslingen University of Applied Sciences,Religion2020,2005,3
ETH Zurich,Cooperation2021,2005,13
ETH Zurich,Religion2020,2005,1
Eubios Ethics Institute,Cooperation2021,2005,1
European Business School,Religion2020,2005,6
European Commission,Cooperation2021,2005,3
European Commission,Religion2020,2005,3
European Institute of Design,Religion2020,2005,2
European Institute of Florence,Religion2020,2005,2
European University Institute,Cooperation2021,2005,15
European University Institute,Religion2020,2005,11
European University Viadrina,Cooperation2021,2005,2
European University Viadrina,Religion2020,2005,5
Evangel University,Religion2020,2005,5
Evangelical Central Office for Weltanschauung,Religion2020,2005,3
Evangelical Lutheran Church in Bavaria,Religion2020,2005,6
Evangelische Studiengemeinschaft eV,Religion2020,2005,4
Evesham Methodist Church,Religion2020,2005,4
EvE-Urgent!,Cooperation2021,2005,3
EvE-Urgent!,Religion2020,2005,8
Ewha Women's University,Cooperation2021,2005,3
Excelsia College,Religion2020,2005,8
Extreme Wellness Center,Religion2020,2005,1
Facebook,Cooperation2021,2005,3
Facebook,Religion2020,2005,1
Faculdade de Medicina da USP,Religion2020,2005,4
Fairfield University,Religion2020,2005,14
Fairleigh Dickinson University,Religion2020,2005,2
Fakultas Humaniora UIN Maulana Malik Ibrahim Malang,Religion2020,2005,2
Family Youth Institute,Religion2020,2005,2
Far Western Anthropological Research Group Inc.,Cooperation2021,2005,2
"Fatima Jinnah Medical College, University of Punjab",Cooperation2021,2005,1
"Fatima Jinnah Medical College, University of Punjab",CooperationAndReligion,2005,1
"Fatima Jinnah Medical College, University of Punjab",Religion2020,2005,2
Fayetteville State University,Religion2020,2005,52
Federal University for Public Administration - HS Bund,Religion2020,2005,1
Federal University of Bahia,Religion2020,2005,1
Federal University of Juiz de Fora,Religion2020,2005,10
Federal University of Pernambuco,Religion2020,2005,1
federal university of rio grande do norte,Cooperation2021,2005,3
Federal University of São Paulo (UNIFESP),Religion2020,2005,15
Federation University Australia,Cooperation2021,2005,1
Federation University Australia,Religion2020,2005,17
Fernando Pessoa University,Religion2020,2005,1
Fielding Graduate University,Cooperation2021,2005,1
Fielding Graduate University,Religion2020,2005,1
First Things,Religion2020,2005,4
Flame University,Religion2020,2005,1
Flinders University,Cooperation2021,2005,12
Flinders University,Religion2020,2005,7
Flordia State,Religion2020,2005,11
Florida Atlantic University,Cooperation2021,2005,1
Florida Atlantic University,Religion2020,2005,12
Florida International University,Cooperation2021,2005,4
Florida International University,Religion2020,2005,15
Florida SouthWestern State College,Religion2020,2005,1
Florida State University,Cooperation2021,2005,22
Florida State University,Religion2020,2005,68
Florida State University Panama Campus,Religion2020,2005,2
flourishing metrics,Religion2020,2005,6
Fondazione Edo Ed Elvo Tempia Valenta,Cooperation2021,2005,2
Fooyin University,Religion2020,2005,2
Forbes Hospital,Religion2020,2005,5
Fordham Road Collaborative,Religion2020,2005,2
Fordham University,Cooperation2021,2005,1
Fordham University,Religion2020,2005,60
Forlaget Univers,Religion2020,2005,1
Forum on European Expansion and Global Interaction,Religion2020,2005,1
Foundation for Shamanic Studies,Religion2020,2005,1
Four Seasons Hospice Care,Religion2020,2005,1
Fox Chase Cancer Center,Cooperation2021,2005,2
Fox Valley Memory Project,Religion2020,2005,4
Francis Taylor Building,Religion2020,2005,6
Franciscan University,Religion2020,2005,1
Franciscan University of Steubenville,Religion2020,2005,1
Fraser Health Authority,Religion2020,2005,1
Fraunhofer FIT,Cooperation2021,2005,2
Fred Hutchinson Cancer Research Center,Cooperation2021,2005,7
Fred Hutchinson Cancer Research Center,Religion2020,2005,2
frederick university,Religion2020,2005,2
Free State University,Religion2020,2005,8
Free University Berlin,Religion2020,2005,3
Free University of Berlin,Cooperation2021,2005,3
Free University of Berlin,Religion2020,2005,6
Freedom Forum Institute,Religion2020,2005,24
Freelance Researcher,Religion2020,2005,12
Freiburg Institute for Advanced Studies,Religion2020,2005,2
Freie Universität Berlin,Religion2020,2005,1
French Institute for Anatolian Studies,Religion2020,2005,1
French Institute for Research in Computer Science and Automation,Cooperation2021,2005,7
French Institute of Health and Medical Research,Religion2020,2005,1
French National Center for Scientific Research,Cooperation2021,2005,7
French National Center for Scientific Research,Religion2020,2005,5
French National Council of Universities,Religion2020,2005,3
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,2005,21
Friedrich Schiller University Jena,Cooperation2021,2005,9
FTSE Russell,Religion2020,2005,1
Fudan University,Religion2020,2005,5
Fukushima medical University,Cooperation2021,2005,1
Fuller Theological Seminary,Religion2020,2005,109
Furman University,Religion2020,2005,8
Future University Hakodate,Cooperation2021,2005,3
Fuzhou University,Cooperation2021,2005,1
FWD.us,Religion2020,2005,3
Gajara Raja Medical College,Religion2020,2005,8
Gansu University of Traditional Chinese Medicine,Cooperation2021,2005,4
Gansu University of Traditional Chinese Medicine,Religion2020,2005,1
Gardner-Webb University,Religion2020,2005,2
Garrett-Evangelical Theological Seminary,Religion2020,2005,9
Gazi University,Religion2020,2005,4
Geestelijk Verzorger Zinzia Zorggroep,Religion2020,2005,1
Geha Mental Health Center,Religion2020,2005,2
Geisel School of Medicine,Religion2020,2005,3
Genentech,Cooperation2021,2005,1
General Theological Seminary,Religion2020,2005,1
Generation Next,Religion2020,2005,1
Georg August University of Göttingen,Cooperation2021,2005,2
Georg August University of Göttingen,Religion2020,2005,4
George Fox University,Religion2020,2005,6
George Mason University,Cooperation2021,2005,19
George Mason University,Religion2020,2005,12
George Washington University,Cooperation2021,2005,6
George Washington University,CooperationAndReligion,2005,1
George Washington University,Religion2020,2005,39
Georgetown University,Cooperation2021,2005,7
Georgetown University,CooperationAndReligion,2005,1
Georgetown University,Religion2020,2005,98
Georgetown University School of Medicine,Religion2020,2005,2
Georgia Institute of Technology,Cooperation2021,2005,2
Georgia Institute of Technology,Religion2020,2005,8
Georgia Southern University,Religion2020,2005,8
Georgia State University,Cooperation2021,2005,1
Georgia State University,Religion2020,2005,3
German Institute for Economic Research (DIW Berlin),Religion2020,2005,1
German Primate Center,Cooperation2021,2005,1
GESIS-Leibniz Institute for the Social Sciences,Religion2020,2005,1
Gettysburg College,Cooperation2021,2005,1
Gettysburg College,CooperationAndReligion,2005,1
Gettysburg College,Religion2020,2005,4
Ghent University,Cooperation2021,2005,11
Ghent University,Religion2020,2005,41
GIGA German Institute of Global and Area Studies,Cooperation2021,2005,1
GIGA German Institute of Global and Area Studies,Religion2020,2005,2
GKT School of Medical Education,Religion2020,2005,1
Gladstone Institute,Cooperation2021,2005,3
Global Alliance in Management Education,Religion2020,2005,2
Global Ethic Institute,Religion2020,2005,1
Glyndwr University,Religion2020,2005,29
Goethe University Frankfurt,Religion2020,2005,4
Goettingen University,Cooperation2021,2005,2
Goettingen University,Religion2020,2005,3
Golden Gate Baptist Theological Seminary,Cooperation2021,2005,5
Golden Gate Baptist Theological Seminary,Religion2020,2005,2
"Goldsmiths, University of London",Cooperation2021,2005,3
"Goldsmiths, University of London",Religion2020,2005,42
Gonzaga University,Religion2020,2005,1
Gordon College,Cooperation2021,2005,1
Gordon College,Religion2020,2005,7
Gordon Conwell Theological Seminary,Religion2020,2005,5
Gordon-Conwell Theological Seminary,Religion2020,2005,3
Governemnt Science College,Religion2020,2005,3
Graduate Theological Foundation,Religion2020,2005,4
Graduate Theological Union,Cooperation2021,2005,1
Graduate Theological Union,CooperationAndReligion,2005,1
Graduate Theological Union,Religion2020,2005,30
Graduate University of Advanced Studies (SOKENDAI),Cooperation2021,2005,20
Grady Health System,Religion2020,2005,2
Grand Valley State University,Religion2020,2005,2
Grand View University,Religion2020,2005,1
Green Templeton College,Religion2020,2005,2
Greenwich Hospital,Religion2020,2005,2
Gregorian University of the Jesuit Order,Religion2020,2005,1
Grenoble Alpes University,Religion2020,2005,4
Gresham College,Religion2020,2005,5
Griffin Hospital,Religion2020,2005,1
Griffith University,Cooperation2021,2005,2
Griffith University,Religion2020,2005,21
Grove City College,Religion2020,2005,2
Guangxi Normal University,Cooperation2021,2005,2
Guestbrook Project,Religion2020,2005,3
Gunasheela,Religion2020,2005,2
Gunasheela Surgical & Maternity Hospital,Religion2020,2005,2
Gustave Roussy Institute,Cooperation2021,2005,1
Gustavus Adolphus College,Religion2020,2005,5
Guttmacher Institute,Religion2020,2005,2
Gwynedd Mercy College,Cooperation2021,2005,1
Gwynedd Mercy College,Religion2020,2005,2
H&S Ventures,Religion2020,2005,1
Hadassah University Medical Center,Cooperation2021,2005,1
Hadassah University Medical Center,CooperationAndReligion,2005,1
Hadassah University Medical Center,Religion2020,2005,8
Haifa University,Cooperation2021,2005,1
Haifa University,CooperationAndReligion,2005,1
Haifa University,Religion2020,2005,10
Haight-Ashbury Free Clinic (HAFC),Religion2020,2005,2
Hainan Medical University,Religion2020,2005,1
Halmstad University,Religion2020,2005,4
Hamad bin Khalifa College of Law and Public Policy,Religion2020,2005,2
Hamad Bin Khalifa University,Religion2020,2005,1
Hamburg University,Cooperation2021,2005,1
Hamburg University,Religion2020,2005,1
Hamilton College,Religion2020,2005,5
Hampton University,Religion2020,2005,1
Handong University,Religion2020,2005,2
handzo consulting,Religion2020,2005,2
Hangzhou Dianzi University,Cooperation2021,2005,1
Hanover Hospital,Religion2020,2005,5
Hanshin University,Religion2020,2005,2
Harbin Institute of Technology,Cooperation2021,2005,5
Harbor UCLA Medical Center,Religion2020,2005,3
Hardin-Simmons University,Religion2020,2005,18
Harper College,Religion2020,2005,2
Harris Health System,Religion2020,2005,3
Harris Manchester College,Religion2020,2005,8
Hartford Seminary,Religion2020,2005,12
Harvard Business School,Religion2020,2005,2
Harvard Divinity School,Religion2020,2005,26
Harvard Graduate School of Education,Religion2020,2005,1
Harvard Medical School,Cooperation2021,2005,18
Harvard Medical School,Religion2020,2005,41
Harvard School of Public Health,Cooperation2021,2005,10
Harvard School of Public Health,Religion2020,2005,49
Harvard University,Cooperation2021,2005,82
Harvard University,CooperationAndReligion,2005,2
Harvard University,Religion2020,2005,85
Hastings Center,Religion2020,2005,3
Hawaii Pacific University,Religion2020,2005,1
HCA Healthcare,Religion2020,2005,2
HCF Nutrition Foundation,Religion2020,2005,2
Heal,Religion2020,2005,15
health action inc,Religion2020,2005,1
Healthcare Chaplaincy,Religion2020,2005,1
HealthCorps,Religion2020,2005,2
Healthed,Religion2020,2005,1
healthquest,Cooperation2021,2005,1
healthquest,Religion2020,2005,1
Hebrew Union College,Religion2020,2005,4
Hebrew University - Hadessah,Religion2020,2005,2
Hebrew University of Jerusalem,Cooperation2021,2005,20
Hebrew University of Jerusalem,Religion2020,2005,51
HEC Montreal,Cooperation2021,2005,1
Hefei University of Technology,Cooperation2021,2005,1
Heidelberg Center for American Studies,Cooperation2021,2005,1
Heidelberg Center for American Studies,Religion2020,2005,2
Heidelberg University,Cooperation2021,2005,1
Heidelberg University,Religion2020,2005,4
Heights Therapy,Religion2020,2005,17
Heinrich-Heine University,Religion2020,2005,2
Helen Dowling Institute,Religion2020,2005,3
Hellenic Foundation for European and Foreign Policy,Religion2020,2005,5
HELP University,Religion2020,2005,4
Helsinki University,Religion2020,2005,1
Henan Normal University,Cooperation2021,2005,2
Henderson State University,Religion2020,2005,2
Hendrix College,Religion2020,2005,2
Heriot-Watt University,Religion2020,2005,9
Hertie School,Cooperation2021,2005,1
"Herzog Hospital, Jerusalem",Religion2020,2005,6
Heythrop College,Cooperation2021,2005,1
Heythrop College,Religion2020,2005,6
Higher School of Economics,Cooperation2021,2005,2
Highland Theological College,Religion2020,2005,5
Hillsdale College,Religion2020,2005,1
Hindu University of America,Religion2020,2005,2
Hitotsubashi University,Cooperation2021,2005,26
Hobart and William Smith Colleges,Religion2020,2005,3
Hofstra University,Cooperation2021,2005,2
Hofstra University,CooperationAndReligion,2005,1
Hofstra University,Religion2020,2005,7
Hogeschool Utrecht,Religion2020,2005,1
Hokkaido University,Cooperation2021,2005,12
Hokkaido University,Religion2020,2005,4
Holbaek Hospital,Religion2020,2005,2
Hollins University,Religion2020,2005,1
Holy Ghost College,Religion2020,2005,2
Holy Names University,Religion2020,2005,4
Homerton University Hospital NHS Foundation Trust,Religion2020,2005,6
"Homewood Research Institute, Guelph, Ontario",Religion2020,2005,1
Hong Kong Adventist College,Religion2020,2005,1
Hong Kong Baptist University,Religion2020,2005,4
Hong Kong Polytechnic University,Cooperation2021,2005,16
Hong Kong Polytechnic University,Religion2020,2005,10
Hong Kong Shue Yan College,Cooperation2021,2005,1
Hong Kong University of Science and Technology,Cooperation2021,2005,16
Hongik University,Cooperation2021,2005,2
Hongik University,Religion2020,2005,2
Hope College,Religion2020,2005,6
Hôpital Paul-Brousse – Hôpitaux universitaires Paris-Sud,Religion2020,2005,1
Hosei University,Cooperation2021,2005,1
Hospice palliative care ssociation of south africa,Religion2020,2005,1
Hospice Rieti,Religion2020,2005,40
Hospital Clínico Universitario Virgen de la Arrixaca,Cooperation2021,2005,7
Hospital Clínico Universitario Virgen de la Arrixaca,CooperationAndReligion,2005,7
Hospital Clínico Universitario Virgen de la Arrixaca,Religion2020,2005,7
Hospital for Sick Children,Religion2020,2005,1
Hospital Israelita Albert Einstein,Religion2020,2005,3
Hospital Pérola Byington,Religion2020,2005,1
Hospital Santo Antonio/Centro Hospitalar do Porto,Religion2020,2005,2
Hospital Universitario Virgen de la Arrixaca,Cooperation2021,2005,1
Hospital Universitario Virgen de la Arrixaca,CooperationAndReligion,2005,1
Hospital Universitario Virgen de la Arrixaca,Religion2020,2005,1
Howard University,Religion2020,2005,8
Huawei Technologies,Cooperation2021,2005,3
Huazhong University of Science and Technology,Cooperation2021,2005,1
Huazhong University of Science and Technology,Religion2020,2005,2
Hubei University of Medicine,Cooperation2021,2005,1
Hubrecht Institute,Cooperation2021,2005,1
Hudson College of Public Health,Cooperation2021,2005,2
Hudson College of Public Health,Religion2020,2005,1
Human Sciences Research Council,Religion2020,2005,8
Humboldt University of Berlin,Cooperation2021,2005,6
Humboldt University of Berlin,CooperationAndReligion,2005,2
Humboldt University of Berlin,Religion2020,2005,13
Hunan Normal University,Cooperation2021,2005,1
Hunan University,Cooperation2021,2005,1
Hungarian Academy of Sciences,Cooperation2021,2005,2
"Hunter College, CUNY",Cooperation2021,2005,1
"Hunter College, CUNY",Religion2020,2005,5
Hunter Research Foundation,Religion2020,2005,1
Huntington University,Religion2020,2005,3
Huntsman Cancer Institute,Religion2020,2005,3
Huron University College,Religion2020,2005,4
Huston-Tilloston University,Religion2020,2005,4
Ibaraki University,Religion2020,2005,1
Icahn School of Medicine at Mount Sinai,Religion2020,2005,15
ICHEC Brussels Management School,Religion2020,2005,5
Idaho State University,Religion2020,2005,6
Iliff School of Theology,Religion2020,2005,7
Illif School of Theology,Religion2020,2005,5
Illinois Institute of Technology,Religion2020,2005,2
Illinois State University,Cooperation2021,2005,1
Illinois State University,Religion2020,2005,4
Illumeo,Religion2020,2005,2
Immanuel Hospital Berlin,Religion2020,2005,5
Immersion Neuroscience,Cooperation2021,2005,3
Imperial Cancer Research Fund Oxford,Cooperation2021,2005,1
Imperial College London,Cooperation2021,2005,1
Imperial College London,Religion2020,2005,1
IMT School for Advanced Studies Lucca,Cooperation2021,2005,1
IMT Strategy and Oversight,Religion2020,2005,3
In His Image Family Medicine Residency,Religion2020,2005,2
Independent Consulting,Religion2020,2005,1
Independent Researcher,Cooperation2021,2005,1
Independent Researcher,Religion2020,2005,2
India Institute of Technology,Cooperation2021,2005,2
India Institute of Technology,CooperationAndReligion,2005,1
India Institute of Technology,Religion2020,2005,6
Indian Institute for Foreign Trade,Cooperation2021,2005,2
Indian Institute of Management,Religion2020,2005,4
Indian Institute of Technology,Religion2020,2005,5
Indian Institute of Technology Bombay,Religion2020,2005,3
Indian Naturopathy & Yoga Graduates Medical Association [INYGMA],Religion2020,2005,1
Indian Statistical Institute,Religion2020,2005,1
Indiana State University,Religion2020,2005,13
Indiana University,Cooperation2021,2005,23
Indiana University,CooperationAndReligion,2005,1
Indiana University,Religion2020,2005,54
Indiana University at Bloomington,Cooperation2021,2005,1
Indiana University at Bloomington,Religion2020,2005,5
Indiana University of Pennsylvania,Cooperation2021,2005,1
Indiana University of Pennsylvania,Religion2020,2005,2
Indiana University South Bend,Religion2020,2005,7
Indiana University-Purdue University Columbus,Religion2020,2005,1
Indiana University-Purdue University Fort Wayne,Religion2020,2005,4
Indiana Wesleyan University,Religion2020,2005,1
Indic Academy,Religion2020,2005,4
Indus Business Academy,Religion2020,2005,2
Infinity Publishing,Religion2020,2005,4
Inholland University,Religion2020,2005,1
Inserm,Cooperation2021,2005,11
InsightLA,Religion2020,2005,1
Institut Agama Islam Negeri Palu,Religion2020,2005,2
Institut für Evangelische Theologie,Religion2020,2005,3
Institut für Grenzgebiete der Psychologie und Psychohygiene,Religion2020,2005,2
Institut national de la recherche scientifique,Religion2020,2005,1
Institut Psycho Neuro,Religion2020,2005,1
Institut universitaire de gériatrie de Montréal (IUGM),Religion2020,2005,2
Institute for Advanced Study,Cooperation2021,2005,2
Institute for Advanced Study,Religion2020,2005,21
Institute for American Constitutional Heritage,Religion2020,2005,2
Institute for Applied Statistics and Data Analysis,Religion2020,2005,2
Institute for Cognitive Sciences and Technologies,Cooperation2021,2005,5
Institute for Ecumenical Research,Religion2020,2005,1
Institute for Frontier Areas of Psychology and Mental Health,Religion2020,2005,1
Institute for Global Engagement,Cooperation2021,2005,2
Institute for Global Engagement,CooperationAndReligion,2005,1
Institute for Global Engagement,Religion2020,2005,12
Institute for Human Science and Service,Religion2020,2005,2
Institute for Orthodox Christian Studies,Religion2020,2005,1
Institute for Political Science,Cooperation2021,2005,1
Institute for Political Science,Religion2020,2005,2
Institute for Regenerative Medicine,Cooperation2021,2005,1
Institute for Research in Immunology and Cancer,Cooperation2021,2005,1
Institute for Research of Subconscious Psychology,Religion2020,2005,2
Institute for Safe Medication Practices,Cooperation2021,2005,1
Institute for Social Sciences at the TU Braunschweig,Religion2020,2005,1
Institute for Strategic Dialogue,Religion2020,2005,3
Institute for the Biocultural Study of Religion,Religion2020,2005,5
Institute for the Psychological Sciences,Religion2020,2005,3
Institute for the Study of Complex Systems,Cooperation2021,2005,1
Institute for Transpersonal Psychology,Religion2020,2005,4
Institute of Archaeology,Cooperation2021,2005,1
Institute of Basic Medical Sciences,Religion2020,2005,2
Institute of Communications Engineering,Cooperation2021,2005,2
Institute of East West Medicine,Religion2020,2005,1
Institute of Electrical and Electronics Engineers,Cooperation2021,2005,3
Institute of Health Economics,Religion2020,2005,3
Institute of Ismaili Studies,Religion2020,2005,1
Institute of Lutheran Theology,Religion2020,2005,1
Institute of Medical Psychology at Charité,Religion2020,2005,1
Institute of Modern History,Religion2020,2005,4
Institute of Noetic Sciences,Religion2020,2005,1
Institute of Psychiatry,Religion2020,2005,2
Institute of Race Relations,Cooperation2021,2005,2
Institute of Race Relations,Religion2020,2005,1
Institute of Security and Global Affairs,Religion2020,2005,3
Institute of Technical Physics and Material Sciene,Cooperation2021,2005,6
Institute on Global Conflict and Cooperation,Religion2020,2005,7
Instituto de Análisis Económico,Cooperation2021,2005,3
Instituto Piaget,Cooperation2021,2005,2
Insubria University,Cooperation2021,2005,1
Insubria University,Religion2020,2005,3
Integral Institute,Religion2020,2005,1
Interdisciplinary Center Herzliya,Cooperation2021,2005,1
Interdisciplinary Center Herzliya,Religion2020,2005,2
Interdisciplinary Center Herzliya:,Cooperation2021,2005,3
"International Association for Human Values, United Nations",Religion2020,2005,5
International Baptist Theological Seminary,Religion2020,2005,6
International Christian University,Religion2020,2005,5
International Institute for Applied Systems Analysis,Cooperation2021,2005,1
International Institute of Advanced Islamic Studies,Religion2020,2005,3
International Institute of Molecular and Cell Biology in Warsaw,Cooperation2021,2005,1
International Islamic University Malaysia,Cooperation2021,2005,1
International Islamic University Malaysia,CooperationAndReligion,2005,1
International Islamic University Malaysia,Religion2020,2005,2
International Peace and Development Ethics Center,Cooperation2021,2005,1
International Primary Palliative Care Network (IPPCN),Religion2020,2005,2
International Society of Addiction Medicine,Religion2020,2005,3
International Telematic University UNINETTUNO,Cooperation2021,2005,3
International University of Rabat,Religion2020,2005,2
Inter-University Centre Dubrovnik,Religion2020,2005,1
Iowa State University,Cooperation2021,2005,16
Iowa State University,Religion2020,2005,6
Iranian Institute for Health Sciences Research,Religion2020,2005,2
IRCCS,Religion2020,2005,3
IRCCS Carlo Besta,Religion2020,2005,2
IRCCS San Raffaeele Pisana,Religion2020,2005,3
ISAP- Zurich,Religion2020,2005,4
Isfahan University,Religion2020,2005,2
Isfahan University of Technology,Cooperation2021,2005,1
Islamic Azad University,Cooperation2021,2005,1
Islamic Azad University,Religion2020,2005,2
Israel Institute of Technology,Religion2020,2005,2
Istanbul Bilgi University,Religion2020,2005,1
Istituto Auxologico Italiano,Religion2020,2005,1
Istituto Mente e Corpo,Religion2020,2005,2
Italian Institute of Transcultural Mental Health,Religion2020,2005,4
Italian National Research Council,Cooperation2021,2005,2
Italian Society of Sociology,Religion2020,2005,3
Jacobs University Bremen,Religion2020,2005,8
Jagiellonian University,Cooperation2021,2005,3
Jagiellonian University,Religion2020,2005,6
James Cook University,Cooperation2021,2005,5
James Cook University,Religion2020,2005,3
James Madison University,Religion2020,2005,5
Jangxi University of Finance and Economics,Religion2020,2005,1
Janki Foundation for Spirituality in Healthcare,Religion2020,2005,3
Japan Advanced Institute of Science and Technology,Cooperation2021,2005,1
Jawaharlal Nehru University,Religion2020,2005,4
Jean Monnet Centre of Excellence,Religion2020,2005,1
Jena University,Religion2020,2005,1
Jesuit Institute,Religion2020,2005,1
Jesuit School of Theology,Religion2020,2005,2
Jesus College,Religion2020,2005,4
Jewish Theological Seminary,Religion2020,2005,6
Jiangsu Normal University,Cooperation2021,2005,2
Jilin University,Cooperation2021,2005,2
Jindal Global University,Religion2020,2005,2
jobZology,Religion2020,2005,2
Johann Wolfgang Goethe-University,Religion2020,2005,3
Johannes a Lasco Library,Religion2020,2005,4
John Carroll University,Religion2020,2005,4
John Hopkins University,Cooperation2021,2005,5
John Hopkins University,Religion2020,2005,10
John Jay College of Criminal Justice,Religion2020,2005,1
John Wycliffe Theological College,Religion2020,2005,1
Johns Hopkins University,Cooperation2021,2005,11
Johns Hopkins University,Religion2020,2005,45
Joint Special Operations University,Religion2020,2005,5
Jonkoping University,Cooperation2021,2005,1
Jönköping University,Religion2020,2005,4
Joshi-Bedekar College,Cooperation2021,2005,1
Joshi-Bedekar College,Religion2020,2005,3
"Journal of Policing, Intelligence and Counter Terrorism (JPICT)",Religion2020,2005,1
journal of theological studies,Religion2020,2005,11
Józef Pilsudski Academy of Physical Education,Religion2020,2005,2
JPsych and Center for Anxiety,Religion2020,2005,4
Juniata College,Cooperation2021,2005,1
Juniata College,CooperationAndReligion,2005,1
Juniata College,Religion2020,2005,5
Justus Liebig University Giessen,Cooperation2021,2005,3
Justus Liebig University Giessen,Religion2020,2005,5
Kadir Has University,Religion2020,2005,3
Kampen Theological University,Religion2020,2005,5
Kansai University,Cooperation2021,2005,3
Kansas State University,Cooperation2021,2005,2
Kansas State University,Religion2020,2005,2
Kansas University,Religion2020,2005,6
Kaohsiung Medical University,Religion2020,2005,2
Karakter Child and Adolescent  Psychiatry,Religion2020,2005,1
Karatay University,Religion2020,2005,4
Karen D. Lincoln Consulting Services,Religion2020,2005,8
Karl Franzens University,Religion2020,2005,2
Karl Landsteiner University of Health Sciences,Religion2020,2005,1
Karlstad University,Religion2020,2005,2
Karolinska Institute,Cooperation2021,2005,3
Karolinska Institute,Religion2020,2005,5
Karolinska Institutet,Religion2020,2005,3
Katholieke Universiteit Leuven,Religion2020,2005,3
KBC Bank,Religion2020,2005,4
Keele University,Cooperation2021,2005,2
Keele University,Religion2020,2005,14
Keenesaw State University,Religion2020,2005,1
Keimyung University,Religion2020,2005,1
Keio University,Cooperation2021,2005,2
Keio University Shonan Fujisawa,Cooperation2021,2005,2
Kellogg School of Management,Religion2020,2005,1
KEMRI-Wellcome Trust Research Programme,Cooperation2021,2005,1
Kennesaw State University,Religion2020,2005,1
Kent State University,Religion2020,2005,3
Kenyatta University,Religion2020,2005,1
Kerman University of Medical Sciences,Religion2020,2005,2
Keston Institute,Religion2020,2005,3
Khalifa University,Cooperation2021,2005,2
Khon Kaen University,Religion2020,2005,2
Kiel Institute for the World Economy,Cooperation2021,2005,4
Kiev University,Religion2020,2005,5
KIFO Institute,Religion2020,2005,1
Kindred Hospital El Paso,Cooperation2021,2005,4
King Abdulaziz University,Cooperation2021,2005,4
King Abdulaziz University,CooperationAndReligion,2005,1
King Abdulaziz University,Religion2020,2005,3
King County Department of Community and Human Services,Religion2020,2005,2
King Saud University,Religion2020,2005,4
King’s College,Cooperation2021,2005,1
King’s College,Religion2020,2005,1
King’s College London,Religion2020,2005,32
King's College,Religion2020,2005,16
King's College London,Cooperation2021,2005,5
King's College London,Religion2020,2005,49
Kings College London University,Cooperation2021,2005,2
Kings College London University,Religion2020,2005,29
Kings University College,Cooperation2021,2005,1
King's University College,Religion2020,2005,2
Kingston University,Religion2020,2005,7
Kliniken Essen-Mitte,Religion2020,2005,2
Knowledge Moves,Religion2020,2005,3
Kobe University,Cooperation2021,2005,6
Kobe University,Religion2020,2005,1
Koc University,Cooperation2021,2005,3
Koc University,Religion2020,2005,2
Kochi University of Technology,Cooperation2021,2005,5
Kohima Institute,Religion2020,2005,1
Kokugakuin University,Religion2020,2005,2
Konrad Lorenz Institute in Vienna,Cooperation2021,2005,1
Korea Advanced Institute of Science and Technology (KAIST),Cooperation2021,2005,1
Korea University,Religion2020,2005,4
Korea University Guro Hospital,Religion2020,2005,1
Kripalu Center,Religion2020,2005,6
KU Leuven,Cooperation2021,2005,31
KU Leuven,CooperationAndReligion,2005,1
KU Leuven,Religion2020,2005,71
Kunming Institute of Botany,Cooperation2021,2005,3
Kunming Medical University,Cooperation2021,2005,1
Kutztown University of Pennsylvania,Religion2020,2005,5
Kuwait University,Religion2020,2005,11
KW Counselling Services,Religion2020,2005,7
Kwansei Gakuin University,Cooperation2021,2005,1
Kyoto University,Cooperation2021,2005,10
Kyoto University,CooperationAndReligion,2005,1
Kyoto University,Religion2020,2005,6
Kyung Hee University,Religion2020,2005,5
Kyungpook National University,Cooperation2021,2005,3
Kyushu University,Cooperation2021,2005,17
La Salpetriere Hospital,Cooperation2021,2005,1
La Sierra University,Religion2020,2005,4
La Trobe University,Cooperation2021,2005,1
La Trobe University,Religion2020,2005,8
Lahore University of Management Sciences,Cooperation2021,2005,1
Lahore University of Management Sciences,CooperationAndReligion,2005,1
Lahore University of Management Sciences,Religion2020,2005,8
Laidlaw College,Religion2020,2005,3
Lake Forest College,Cooperation2021,2005,1
Lake Forest College,Religion2020,2005,2
Lamar University,Cooperation2021,2005,3
Lamar University,Religion2020,2005,3
Lancaster Theological Seminary,Religion2020,2005,2
Lancaster University,Cooperation2021,2005,9
Lancaster University,CooperationAndReligion,2005,1
Lancaster University,Religion2020,2005,40
Lander University,Religion2020,2005,1
Landspitali University Hospital,Religion2020,2005,3
langara college,Cooperation2021,2005,1
langara college,Religion2020,2005,3
Lanzhou University,Cooperation2021,2005,14
Lanzhou University,Religion2020,2005,1
LaTrobe University,Religion2020,2005,2
Laurentian University,Religion2020,2005,4
Laval University,Cooperation2021,2005,1
Laval University,Religion2020,2005,32
Leavitt Partners,Religion2020,2005,9
Lebanon Valley College,Religion2020,2005,2
Lee University,Religion2020,2005,1
Leeds Beckett University,Cooperation2021,2005,3
Leeds Metropolitan University,Cooperation2021,2005,1
Leeds Metropolitan University,Religion2020,2005,1
Lehigh University,Religion2020,2005,7
Leibniz-Zentrum Moderner Orient,Religion2020,2005,4
Leicester Cathedral,Religion2020,2005,7
Leiden University,Cooperation2021,2005,33
Leiden University,Religion2020,2005,16
Leiden University Institute for Philosophy,Religion2020,2005,1
Leipzig University,Religion2020,2005,8
Leiria Polytechnic,Cooperation2021,2005,5
Leo Baeck College,Religion2020,2005,4
Lesley University,Religion2020,2005,1
Lesya Ukrainka Volyn National University,Religion2020,2005,2
Leuven University,Religion2020,2005,4
Leviathan Security Group,Religion2020,2005,1
Lewis & Clark College,Religion2020,2005,1
Lewis Clark State College,Religion2020,2005,2
Lewis University,Religion2020,2005,2
Lexington Medical Center,Religion2020,2005,4
Liberty Fund,Cooperation2021,2005,1
Liberty Fund,Religion2020,2005,1
Liberty University,Religion2020,2005,8
Lifespan Centers for Behavioral and Preventive Medicine,Religion2020,2005,1
LifeWork Choices - Career Counseling and Planning,Religion2020,2005,1
Limetree Research LLC,Religion2020,2005,1
Lingnan University,Cooperation2021,2005,6
Link Care Center,Religion2020,2005,5
Linköping University,Cooperation2021,2005,7
Linköping University,CooperationAndReligion,2005,1
Linköping University,Religion2020,2005,5
Linnaeus University,Cooperation2021,2005,2
Linnaeus University,Religion2020,2005,6
Lisbon University Institute,Religion2020,2005,2
Lithuanian Cultural Research Institute,Religion2020,2005,3
Lithuanian University of Educational Sciences,Religion2020,2005,2
Liverpool Hope University,Religion2020,2005,17
Liverpool John Moores University,Cooperation2021,2005,3
Liverpool John Moores University,Religion2020,2005,2
LMU Munich,Religion2020,2005,2
LoKation® Real Estate,Religion2020,2005,6
Loma Linda University,Cooperation2021,2005,2
Loma Linda University,CooperationAndReligion,2005,1
Loma Linda University,Religion2020,2005,31
London Health Sciences Centre,Religion2020,2005,1
London Metropolitan University,Cooperation2021,2005,2
London Metropolitan University,CooperationAndReligion,2005,1
London Metropolitan University,Religion2020,2005,19
London School of Economics,Cooperation2021,2005,1
London School of Economics,Religion2020,2005,35
London School of Economics and Political Science,Cooperation2021,2005,8
London School of Economics and Political Science,Religion2020,2005,3
London School of Hygiene and Tropical Medicine,Cooperation2021,2005,13
London School of Hygiene and Tropical Medicine,Religion2020,2005,1
London School of Theology,Religion2020,2005,3
London South Bank University,Cooperation2021,2005,1
London University,Cooperation2021,2005,1
Los Angeles Christian Health Centers,Religion2020,2005,1
Los Angeles Valley College,Religion2020,2005,1
Loughborough University,Cooperation2021,2005,3
Louisburg College,Religion2020,2005,1
Louisiana State University,Religion2020,2005,17
Louisville Institute,Religion2020,2005,3
Louisville Presbyterian Theological Seminary,Religion2020,2005,3
Lovisenberg Diaconal College,Cooperation2021,2005,1
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,2005,1
Loyola Marymount University,Religion2020,2005,23
Loyola University Chicago,Religion2020,2005,39
Loyola University Maryland,Cooperation2021,2005,1
Loyola University Maryland,Religion2020,2005,42
Loyola University New Orleans,Religion2020,2005,4
Ltrek,Cooperation2021,2005,1
Ludwig Maximilian University of Munich,Cooperation2021,2005,5
Ludwig Maximilian University of Munich,Religion2020,2005,8
Ludwig-Maximilians-University of Munich,Cooperation2021,2005,2
Ludwig-Maximilians-University of Munich,CooperationAndReligion,2005,1
Ludwig-Maximilians-University of Munich,Religion2020,2005,9
Luiss University Rome,Cooperation2021,2005,16
Lund University,Cooperation2021,2005,15
Lund University,Religion2020,2005,11
Luther College,Religion2020,2005,4
Luther Seminary,Religion2020,2005,17
Luther Theological Seminary,Religion2020,2005,9
Lutheran School of theology at Chicago,Religion2020,2005,13
Lutheran Theological Seminary,Religion2020,2005,1
Lutheran Theological Seminary at Philadelphia,Religion2020,2005,7
Lutheran Theological Southern Seminary,Religion2020,2005,1
Maastricht University,Cooperation2021,2005,28
Maastricht University,CooperationAndReligion,2005,1
Maastricht University,Religion2020,2005,10
Macalaster College,Religion2020,2005,2
Macalester College,Religion2020,2005,1
Macau University of Science and Technology,Cooperation2021,2005,1
Macquarie University,Cooperation2021,2005,14
Macquarie University,Religion2020,2005,7
Magdalene College,Cooperation2021,2005,14
Magdalene College,Religion2020,2005,4
Mahana Theraputics,Cooperation2021,2005,1
Maharishi University of Management,Religion2020,2005,15
Mahidol University,Religion2020,2005,1
Mail & Guardian,Religion2020,2005,1
Maimonides Medical Center,Religion2020,2005,3
Makerere University,Religion2020,2005,1
Malardalen University,Cooperation2021,2005,1
Malmö University,Cooperation2021,2005,1
Manchester Cathedral,Religion2020,2005,3
Manchester Metropolitan University,Cooperation2021,2005,10
Manchester Metropolitan University,Religion2020,2005,1
Manchester University,Religion2020,2005,2
Manhattan College,Cooperation2021,2005,1
Manhattan College,Religion2020,2005,2
Mansoura University,Cooperation2021,2005,5
Marburg University,Religion2020,2005,1
Mardin Artuklu University,Religion2020,2005,5
Maria Curie-Sklodowska University,Religion2020,2005,1
Marian University,Religion2020,2005,1
Marie Curie Hospice Homestead,Religion2020,2005,2
Marquette University,Cooperation2021,2005,6
Marquette University,Religion2020,2005,17
Marshall University,Religion2020,2005,1
Martin Luther University of Halle-Wittenberg,Cooperation2021,2005,1
Martin Luther University of Halle-Wittenberg,Religion2020,2005,6
Maryland University of Integrative Health,Religion2020,2005,1
Marymount Manhattan College,Religion2020,2005,2
Marymount University,Religion2020,2005,2
Maryville College,Religion2020,2005,2
Masaryk Memorial Cancer Institute,Religion2020,2005,3
Massachusetts General Hospital,Cooperation2021,2005,2
Massachusetts General Hospital,Religion2020,2005,6
Massachusetts Institute of Technology,Cooperation2021,2005,16
Massachusetts Institute of Technology,CooperationAndReligion,2005,1
Massachusetts Institute of Technology,Religion2020,2005,7
Massey University,Cooperation2021,2005,6
Massey University,Religion2020,2005,16
Masters and Johnson,Religion2020,2005,9
Mater Dei Institute of Education,Religion2020,2005,2
Mater Misericordiae University Hospital,Cooperation2021,2005,2
Mater Misericordiae University Hospital,Religion2020,2005,2
Maulana Malik Ibrahim State Islamic University,Cooperation2021,2005,1
Maulana Malik Ibrahim State Islamic University,CooperationAndReligion,2005,1
Maulana Malik Ibrahim State Islamic University,Religion2020,2005,4
Max Planck Institute,Cooperation2021,2005,19
Max Planck Institute,CooperationAndReligion,2005,1
Max Planck Institute,Religion2020,2005,5
max planck Institute for Brain Research,Religion2020,2005,1
Max Planck Institute for Chemical Ecology,Cooperation2021,2005,5
Max Planck Institute for Comparative Public Law and International Law,Cooperation2021,2005,1
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Cooperation2021,2005,1
Max Planck Institute for Evolutionary Biology,Cooperation2021,2005,15
Max Planck Institute for Human Cognitive and Brain Sciences,Cooperation2021,2005,2
Max Planck Institute for Human Cognitive and Brain Sciences,Religion2020,2005,1
Max Planck Institute for Human Development,Cooperation2021,2005,3
Max Planck Institute for Human Development,Religion2020,2005,1
Max Planck Institute for Ornithology,Religion2020,2005,2
Max Planck Institute for Psychological Research,Cooperation2021,2005,3
Max Planck Institute for Research on Collective Goods,Cooperation2021,2005,7
Max Planck Institute for Social Anthropology,Religion2020,2005,2
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,2005,2
Max Planck Institute for the Study of Religious and Ethnic Diversity,Cooperation2021,2005,1
Max Planck Institute for the Study of Religious and Ethnic Diversity,Religion2020,2005,5
Max Weber Center for Advanced Cultural and Social Scudies,Cooperation2021,2005,1
Max-Planck-Institute for Research for Collective Goods,Cooperation2021,2005,1
Maynooth University,Religion2020,2005,11
Mayo Clinic - Phoenix,Cooperation2021,2005,1
Mayo Clinic - Phoenix,CooperationAndReligion,2005,1
Mayo Clinic - Phoenix,Religion2020,2005,4
Mayo Clinic - Rochester,Cooperation2021,2005,3
Mayo Clinic - Rochester,CooperationAndReligion,2005,3
Mayo Clinic - Rochester,Religion2020,2005,7
Mayo Clinic - Scottsdale,Religion2020,2005,1
Mayo Clinic School of Medicine,Cooperation2021,2005,2
Mayo Clinic School of Medicine,CooperationAndReligion,2005,1
Mayo Clinic School of Medicine,Religion2020,2005,22
Mayo Foundation for Medical Education and Research,Religion2020,2005,2
McDaniel College,Religion2020,2005,4
McGill University,Cooperation2021,2005,4
McGill University,Religion2020,2005,43
McLean Hospital,Cooperation2021,2005,2
McLean Hospital,Religion2020,2005,4
McMaster Divinity School,Religion2020,2005,3
McMaster University,Cooperation2021,2005,11
McMaster University,Religion2020,2005,25
Meadville Lombard Theological School,Religion2020,2005,2
Medical Academy of Warsaw,Cooperation2021,2005,2
Medical College of Georgia,Religion2020,2005,1
Medical College of Wisconsin,Cooperation2021,2005,1
Medical College of Wisconsin,Religion2020,2005,2
Medical University of Lübeck,Religion2020,2005,4
Medical University of Lublin,Religion2020,2005,1
Medical University of Poznan,Religion2020,2005,23
Medical University of South Carolina,Religion2020,2005,7
Medical University of Vienna,Religion2020,2005,3
Meertens Instituut (KNAW),Religion2020,2005,2
Meharry Medical College,Religion2020,2005,4
MeiHo University,Cooperation2021,2005,1
MeiHo University,Religion2020,2005,2
Meiji University,Cooperation2021,2005,5
Memorial Sloan Kettering Cancer Center,Cooperation2021,2005,2
Memorial Sloan Kettering Cancer Center,Religion2020,2005,28
Memorial University of Newfoundland,Religion2020,2005,7
Mens Sana Monographs,Cooperation2021,2005,1
Mens Sana Monographs,Religion2020,2005,3
Mental Health Center,Cooperation2021,2005,1
Mental Health Center,CooperationAndReligion,2005,1
Mental Health Center,Religion2020,2005,3
Mental Health Christoph,Religion2020,2005,1
Mercer University,Religion2020,2005,8
"Merck & Co, Inc.",Religion2020,2005,4
Mercy Health,Religion2020,2005,7
Merrimack College,Religion2020,2005,4
Messerli Research Institute,Cooperation2021,2005,1
Methodist Homes (MHA),Religion2020,2005,1
Methodist Theological School,Religion2020,2005,4
Methodist University,Religion2020,2005,2
"MF Norwegian School of Theology, Religion, and Society",Religion2020,2005,6
MGH Institute of Health Professions,Religion2020,2005,3
Miami University,Cooperation2021,2005,2
Miami University,Religion2020,2005,13
Michael E. DeBakey VA Medical Center,Religion2020,2005,1
Michigan State University,Cooperation2021,2005,12
Michigan State University,Religion2020,2005,22
Michlalah Jersusalem Academic College,Cooperation2021,2005,1
Michlalah Jersusalem Academic College,Religion2020,2005,2
Microsoft,Cooperation2021,2005,4
"Microsoft Research, Israel",Cooperation2021,2005,1
Middle Tennesse State University,Religion2020,2005,25
Middlebury College,Cooperation2021,2005,9
Middlebury College,Religion2020,2005,2
Middlesex University,Cooperation2021,2005,7
Middlesex University,Religion2020,2005,8
Millersville State College,Religion2020,2005,4
"Milltown Institute, Dublin",Religion2020,2005,1
Milwaukee County Mental Health Complex,Religion2020,2005,1
Mind and Life Institute,Religion2020,2005,10
"Mindfulness, Compassion and Yoga Based Therapies",Religion2020,2005,1
Ministry of Finance,Religion2020,2005,1
"Ministry of health, Israel",Cooperation2021,2005,3
"Ministry of health, Israel",Religion2020,2005,3
Minneapolis Medical Research Foundation,Religion2020,2005,1
Minneapolis VA Health Care System,Religion2020,2005,1
Minneapolis Veterans Affairs Medical Center,Religion2020,2005,1
MIRECC,Religion2020,2005,3
Missouri State University,Cooperation2021,2005,7
Missouri State University,Religion2020,2005,14
Missouri University of Science and Technology,Cooperation2021,2005,4
Missouri Western State University,Religion2020,2005,2
MIT Media Lab,Cooperation2021,2005,2
MJHS Institute for Innovation and Palliative Care,Religion2020,2005,3
Moffitt Cancer Center and Research Institute,Religion2020,2005,3
Mohammad V University,Religion2020,2005,1
Mon Ethos Pro,Religion2020,2005,2
Monash University,Cooperation2021,2005,25
Monash University,Religion2020,2005,50
Montana State University,Religion2020,2005,1
Montclair State University,Religion2020,2005,1
Montefiore Medical Center,Religion2020,2005,1
Montefiore-Einstein Center for Cancer Care,Religion2020,2005,1
Montreat College,Religion2020,2005,1
Moody Bible Institute,Religion2020,2005,1
Moorpark College,Religion2020,2005,3
"Moravian Church Diabetes Clinics, Bluefields and  Pearl Lagoon",Religion2020,2005,2
Moravian Theological Seminary,Religion2020,2005,1
Morehead State University,Cooperation2021,2005,1
Morehead State University,Religion2020,2005,4
Morehouse College,Cooperation2021,2005,2
Morehouse School of Medicine,Cooperation2021,2005,1
Morehouse School of Medicine,CooperationAndReligion,2005,1
Morehouse School of Medicine,Religion2020,2005,3
Mother Teresa Hospital,Religion2020,2005,3
mount allison university,Religion2020,2005,2
Mount Holyoke College,Religion2020,2005,1
Mount Royal University,Religion2020,2005,18
Mount Saint Mary's University,Religion2020,2005,3
Mount Saint Vincent University,Religion2020,2005,1
Mount San Antonio College,Religion2020,2005,1
Mount Sinai Hospital Toronto,Cooperation2021,2005,1
Mount Sinai Hospital Toronto,Religion2020,2005,2
Mount Sinai School of Medicine,Cooperation2021,2005,5
Mount Sinai School of Medicine,Religion2020,2005,7
Moving Byzantium,Religion2020,2005,4
Mt. Auburn Hospital,Religion2020,2005,4
Mt. Sinai School of Medicine,Cooperation2021,2005,1
Mt. Sinai School of Medicine,CooperationAndReligion,2005,1
Mt. Sinai School of Medicine,Religion2020,2005,2
Muhlenberg College,Religion2020,2005,2
Munich School of Philosophy,Religion2020,2005,2
Murdoch University,Religion2020,2005,6
Murray Edwards College,Religion2020,2005,6
Museo Nacional de Ciencias Naturales,Cooperation2021,2005,4
Museum for Cultural History,Religion2020,2005,1
Myriad Neuroscience,Religion2020,2005,1
Nagasaki University,Cooperation2021,2005,2
Nagoya City University,Religion2020,2005,2
Nagoya University,Cooperation2021,2005,7
Nalanda Institute for Contemplative Science,Religion2020,2005,1
Nanjing University,Cooperation2021,2005,6
Nanjing University,Religion2020,2005,1
Nanjing University of Aeronautics and Astronautics,Cooperation2021,2005,1
Nanjing University of Posts and Telecommunications,Cooperation2021,2005,5
Nanyang Technological University,Cooperation2021,2005,7
Nanyang Technological University,CooperationAndReligion,2005,1
Nanyang Technological University,Religion2020,2005,21
Naropa University,Religion2020,2005,4
Nashotah House,Cooperation2021,2005,1
Nashotah House,Religion2020,2005,4
National Autonomous University of Mexico,Cooperation2021,2005,2
National Autonomous University of Mexico,Religion2020,2005,1
National Cancer Institute,Cooperation2021,2005,3
National Cancer Institute,Religion2020,2005,2
National Cancer Institute of Milan,Religion2020,2005,1
National Center for Nanoscience and Technology,Cooperation2021,2005,2
National Center for Science Education,Religion2020,2005,5
National Center for Veterans Studies,Religion2020,2005,3
National Center of Neurology and Psychiatry,Religion2020,2005,1
National Central University,Religion2020,2005,4
National Centre for Immunization Research and Surveillance of Vaccine Preventable Diseases,Religion2020,2005,1
National Centre of Medicine and Science in Sports,Religion2020,2005,1
National Cerebral and Cardiovascular Center,Cooperation2021,2005,4
National Cheng Kung University,Religion2020,2005,2
National Chiao Tung University,Religion2020,2005,13
National Conservatory of Arts and Trades,Cooperation2021,2005,2
National Council for Scientific and Technological Development,Religion2020,2005,5
National Defense Medical College,Religion2020,2005,2
National Economic Research Institute,Religion2020,2005,1
National Geographic,Religion2020,2005,10
National Institue for Mental Health in England,Religion2020,2005,11
national institute for advanced industrial science and technology,Cooperation2021,2005,2
"National Institute for Health and Welfare, Finland",Cooperation2021,2005,3
National Institute for Healthcare Research,Religion2020,2005,5
National Institute for Mathematical Sciences,Cooperation2021,2005,6
National Institute for Mental Health and Neurosciences,Religion2020,2005,3
National Institute of Advanced Industrial Science and Technology,Cooperation2021,2005,3
National Institute of Cancer Prevention and Research,Religion2020,2005,4
National institute of Child Health,Cooperation2021,2005,1
National Institute of Child Health & Human Development,Cooperation2021,2005,1
National Institute of Health,Cooperation2021,2005,3
National Institute of Industrial Engineering,Cooperation2021,2005,2
National Institute of Industrial Engineering,Religion2020,2005,1
National Institute of Mental Health,Cooperation2021,2005,2
National Institute of Mental Health,Religion2020,2005,1
National Institute of Mental Health and Neuro Sciences,Religion2020,2005,1
National Institute of Mental Health and Neurosciences,Religion2020,2005,1
National Institute Of Mental Health Harvard Medical School,Religion2020,2005,3
National Institute on Aging,Religion2020,2005,3
National Institute on Deafness and Other Communication Disorders,Cooperation2021,2005,1
National Institutes of Health,Cooperation2021,2005,3
National Institutes of Health,Religion2020,2005,4
National Kaohsiung First University of Science and Technology,Religion2020,2005,1
National Law School of India University,Religion2020,2005,1
National Library of Israel,Religion2020,2005,1
National Museum of Denmark,Religion2020,2005,1
National Museum of Ethnology,Religion2020,2005,2
National Museum of Natural Sciences,Cooperation2021,2005,1
National Palliative Care Research Center,Religion2020,2005,4
National Psychological Association for Psychoanalysis,Religion2020,2005,1
National Public Radio,Cooperation2021,2005,1
National Public Radio,Religion2020,2005,10
National Renewable Energy Laboratory,Religion2020,2005,3
National Research Council,Cooperation2021,2005,1
National Research Council,Religion2020,2005,1
National Research Council of Italy,Cooperation2021,2005,1
National Research University Higher School of Economics,Religion2020,2005,4
National Science Foundation,Religion2020,2005,5
National Study of Treatment and Addiction Recovery Residences,Religion2020,2005,4
National Sun Yat-sen University,Cooperation2021,2005,6
National Taipei University,Cooperation2021,2005,1
National Taipei University,Religion2020,2005,4
National Taiwan University,Cooperation2021,2005,1
National Taiwan University,Religion2020,2005,18
National University Hospital of Iceland,Religion2020,2005,3
National University of Distance Education,Cooperation2021,2005,2
National University of Ireland,Cooperation2021,2005,5
National University of Ireland,Religion2020,2005,4
"National University of Ireland, Galway",Cooperation2021,2005,5
National University of Malaysia,Religion2020,2005,1
National University of Singapore,Cooperation2021,2005,35
National University of Singapore,CooperationAndReligion,2005,1
National University of Singapore,Religion2020,2005,38
National Yang-Ming University,Cooperation2021,2005,2
Natural Sciences and Engineering Research Council of Canada,Cooperation2021,2005,6
Nature,Cooperation2021,2005,3
Nature,Religion2020,2005,3
Nature Publishing Group,Cooperation2021,2005,3
Nature Publishing Group,Religion2020,2005,2
Naval Amphibious Base Coronado,Cooperation2021,2005,2
Naval Amphibious Base Coronado,CooperationAndReligion,2005,1
Naval Amphibious Base Coronado,Religion2020,2005,9
Naval War College,Cooperation2021,2005,1
Naval War College,Religion2020,2005,1
Nazarbayev University,Religion2020,2005,1
Nazareth College,Religion2020,2005,2
NEC Labs,Cooperation2021,2005,1
Netherlands Institute of Ecology,Cooperation2021,2005,3
Netherlands Interdiscplinary Demographic Institute,Religion2020,2005,3
Neumann University,Religion2020,2005,3
NeuroMyth,Religion2020,2005,2
New College of California,Religion2020,2005,5
New England Complex Systems Institute,Cooperation2021,2005,2
New England School of Acupuncture,Religion2020,2005,1
New Mexico State University,Religion2020,2005,1
New School for Social Research,Religion2020,2005,5
New World Medical,Religion2020,2005,1
New York City College of Technology,Religion2020,2005,6
New York Medical College,Religion2020,2005,2
New York Presbytarian Hospital,Religion2020,2005,2
New York State Psychiatric Institute,Religion2020,2005,1
New York University,Cooperation2021,2005,20
New York University,Religion2020,2005,83
New York University Abu Dhabi,Religion2020,2005,3
New York University Endocrine Associates,Religion2020,2005,2
New York University School of Medicine,Cooperation2021,2005,3
New York University School of Medicine,Religion2020,2005,1
Newcastle University,Cooperation2021,2005,11
Newcastle University,Religion2020,2005,18
newham college,Religion2020,2005,1
NHS Foundation,Cooperation2021,2005,2
NHS Foundation,Religion2020,2005,6
NHS Foundation Sue Ryder Duchess of Kent Hospice,Religion2020,2005,3
"NHS Foundation, Lancashire Care",Religion2020,2005,1
Niagara County Community College,Cooperation2021,2005,1
Niagara County Community College,Religion2020,2005,2
NICM (National Institute of Complementary Medicine) Western Sydney University,Religion2020,2005,1
NIH Clinical Center,Religion2020,2005,1
Nihon University,Cooperation2021,2005,1
NIMH Family Research Consortium IV- UCLA,Religion2020,2005,2
Ningxia University,Cooperation2021,2005,2
Njala University,Religion2020,2005,3
Noona by Varian Medical Systems,Religion2020,2005,3
Nord University,Religion2020,2005,3
Nordic School of Holistic Medicine,Cooperation2021,2005,1
Nordic School of Holistic Medicine,Religion2020,2005,8
North American Lutheran Seminary,Religion2020,2005,1
North Carolina Agricultural & Technical State University,Religion2020,2005,3
North Carolina Central University,Religion2020,2005,6
North Carolina State University,Cooperation2021,2005,1
North Carolina State University,Religion2020,2005,6
North Dakota State University,Cooperation2021,2005,1
North Dakota State University,Religion2020,2005,3
North Park University,Religion2020,2005,2
North Shore University Hospital,Religion2020,2005,2
North West University,Religion2020,2005,6
Northeastern Seminary,Religion2020,2005,5
Northeastern University,Cooperation2021,2005,5
Northeastern University,Religion2020,2005,4
Northern Arizona University,Religion2020,2005,5
Northern Illinois University,Religion2020,2005,7
Northern Sydney Local Health District,Religion2020,2005,1
Northumbria University,Cooperation2021,2005,2
Northumbria University,Religion2020,2005,2
Northwell Health,Cooperation2021,2005,1
Northwell Health,Religion2020,2005,4
Northwest University,Religion2020,2005,5
North-West University,Religion2020,2005,19
Northwestern College,Religion2020,2005,1
Northwestern Medicine,Cooperation2021,2005,2
Northwestern University,Cooperation2021,2005,17
Northwestern University,Religion2020,2005,46
Norwegian Institute of International Affairs,Religion2020,2005,1
Norwegian Ministry of Foreign Affaris,Cooperation2021,2005,1
Norwegian School of Economics and Business Administration,Cooperation2021,2005,1
Norwegian School of Theology,Religion2020,2005,10
Norwegian University of Science and Technology,Cooperation2021,2005,11
Norwegian University of Science and Technology,Religion2020,2005,16
Nottingham Trent University,Cooperation2021,2005,2
Nottingham Trent University,Religion2020,2005,9
Nova Southeastern University,Religion2020,2005,13
Novartis,Religion2020,2005,2
Nove de Julho University,Religion2020,2005,3
Novosibirsk State University,Religion2020,2005,1
NPO-Interational Research Institute,Religion2020,2005,1
Nurses Christian Fellowship,Cooperation2021,2005,1
Nurses Christian Fellowship,Religion2020,2005,4
Oakland University,Cooperation2021,2005,1
Oakland University,CooperationAndReligion,2005,1
Oakland University,Religion2020,2005,15
Oakwood University,Religion2020,2005,2
Obafemi Awolowo University,Religion2020,2005,2
Oberlin College,Religion2020,2005,1
ObjectVideo Labs,Cooperation2021,2005,2
Occidental College,Religion2020,2005,2
Ohio Health,Religion2020,2005,1
Ohio Northern University,Religion2020,2005,18
Ohio State University,Cooperation2021,2005,12
Ohio State University,Religion2020,2005,35
Ohio University,Religion2020,2005,2
Okayama University,Cooperation2021,2005,1
Oklahoma State University,Religion2020,2005,11
Old Dominion University,Religion2020,2005,6
ONE Research Institute,Religion2020,2005,4
OneOncology,Religion2020,2005,2
Ono Academic College,Religion2020,2005,3
Open University (The),Cooperation2021,2005,2
Open University (The),CooperationAndReligion,2005,1
Open University (The),Religion2020,2005,30
Open University of Cyprus,Religion2020,2005,1
Opole University,Religion2020,2005,1
Oral Roberts University,Religion2020,2005,1
Orange Labs,Cooperation2021,2005,1
Oregon Health & Science University,Cooperation2021,2005,6
Oregon Health & Science University,Religion2020,2005,8
Oregon Research Institute,Cooperation2021,2005,3
Oregon State University,Religion2020,2005,6
Organisation for Economic Co-operation and Development,Cooperation2021,2005,4
Orot Israel College,Religion2020,2005,1
Orthodox Church,Religion2020,2005,1
Osaka Prefecture University,Cooperation2021,2005,7
Osaka Savings Credit Union,Religion2020,2005,2
Osaka University,Cooperation2021,2005,4
Osaka University Graduate School,Religion2020,2005,1
Oslo University,Religion2020,2005,2
Ostfalia Hochschule,Cooperation2021,2005,1
Otago University,Religion2020,2005,3
Ottawa Hospital Research Institute,Cooperation2021,2005,2
Otto-von-Guericke University Magdeburg,Cooperation2021,2005,5
Otto-von-Guericke-Universität,Religion2020,2005,1
Oxford Brookes University,Religion2020,2005,21
Oxford Centre for Mission Studies,Religion2020,2005,5
Oxford University,Cooperation2021,2005,10
Oxford University,CooperationAndReligion,2005,1
Oxford University,Religion2020,2005,56
Pace University,Religion2020,2005,2
Pace Univesity,Religion2020,2005,3
Pacific Lutheran University,Religion2020,2005,7
Pacific Neuroscience Medical Group,Religion2020,2005,1
Pacific School of Religion,Religion2020,2005,2
Pacific University,Religion2020,2005,6
Pacifica Graduate Institute,Religion2020,2005,1
Padjadjaran University,Religion2020,2005,2
Pakistan Institute of Development Economics,Cooperation2021,2005,2
"Palacky University, Olomouc",Cooperation2021,2005,1
Palo Alto University,Religion2020,2005,6
Panjab University,Religion2020,2005,3
Pardee RAND Graduate School,Cooperation2021,2005,1
Pardee RAND Graduate School,Religion2020,2005,1
Parexel,Religion2020,2005,3
Paris Descarte University,Cooperation2021,2005,5
Paris Descarte University,Religion2020,2005,1
Paris Diderot University,Religion2020,2005,1
Paris School of Economics,Religion2020,2005,1
Paris Sud University,Religion2020,2005,1
Pasteur Institute,Cooperation2021,2005,2
Patanjali Yogpeeth,Religion2020,2005,12
Pavol Jozef Safarik University,Cooperation2021,2005,1
Pazmny Peter Catholic University BTK,Religion2020,2005,1
Peace Research Institute Frankfurt,Religion2020,2005,2
Peace Research Institute Oslo PRIO,Cooperation2021,2005,1
PEAR Institute,Cooperation2021,2005,1
Peking University,Cooperation2021,2005,22
Peking University,Religion2020,2005,12
Peking University Cancer Hospital and Institute,Cooperation2021,2005,1
Peking University People's Hospital,Cooperation2021,2005,1
"Peninsula Medical School, Universities of Exeter & Plymouth",Religion2020,2005,1
Penn State University,Religion2020,2005,9
PennState Harrisburg,Religion2020,2005,3
Pennsylvania State University,Cooperation2021,2005,9
Pennsylvania State University,Religion2020,2005,51
Pentecostal Theological Seminary,Religion2020,2005,13
Peoples' Friendship University of Russia,Religion2020,2005,1
Pepperdine University,Religion2020,2005,16
Perdana University,Religion2020,2005,4
Péter Pázmány Catholic University,Religion2020,2005,2
Pew Research Center,Religion2020,2005,5
Philadelphia Corporation for Aging,Religion2020,2005,4
Philadelphia VA Medical Center,Religion2020,2005,2
Philipps University Marburg,Religion2020,2005,5
Phillips University,Religion2020,2005,2
Philosophical-Theological University Sankt Georgen,Religion2020,2005,3
Philosophy Institute of the Ukrainian Academy of Sciences,Religion2020,2005,4
PIRE,Religion2020,2005,1
Pittsburg State University,Religion2020,2005,2
Pittsburgh Theological Seminary,Religion2020,2005,6
Pitzer College,Religion2020,2005,3
PLA University of Science and Technology,Cooperation2021,2005,2
Pohang University of Science and Technology,Cooperation2021,2005,2
Point Loma Nazarene University,Cooperation2021,2005,1
Point Loma Nazarene University,Religion2020,2005,8
Polish Academy of Sciences,Religion2020,2005,3
Politecnico di Milano,Religion2020,2005,3
Polytechnic University of Madrid,Cooperation2021,2005,7
Pomona College,Religion2020,2005,2
Pompeu Fabra University,Cooperation2021,2005,2
Pontifical Academy of Theology,Cooperation2021,2005,1
Pontifical Academy of Theology,Religion2020,2005,2
Pontifical Biblical Institute,Cooperation2021,2005,1
Pontifical Biblical Institute,Religion2020,2005,3
Pontifical Catholic University of Campinas,Religion2020,2005,1
Pontifical Catholic University of Parana,Religion2020,2005,2
Pontifical Catholic University of Rio,Religion2020,2005,1
Pontifical Catholic University of Sao Paulo,Religion2020,2005,1
Pontifical Gregorian University,Religion2020,2005,2
Pontifical University Antonianum,Religion2020,2005,18
Pontifical University of John Paul II in Krakow,Religion2020,2005,2
Pontificia Universidade Catolica do Parana,Religion2020,2005,1
Portland Commuity College,Religion2020,2005,1
Portland DBT Institute,Religion2020,2005,1
Portland State University,Cooperation2021,2005,2
Portland State University,Religion2020,2005,1
Portuguese Catholic University,Religion2020,2005,1
Postgraduate Institute of Medical Education & Research,Religion2020,2005,1
Prague University,Religion2020,2005,1
Prakash Neurology and Psychiatry Clinic,Religion2020,2005,1
Prevention Research Center,Religion2020,2005,2
Prince of Songkla University,Religion2020,2005,2
Princess Alice Hospital,Religion2020,2005,3
Princess Margaret Cancer Centre,Religion2020,2005,1
Princess Máxima Center for Pediatric Oncology,Religion2020,2005,10
Princeton Theological Seminary,Cooperation2021,2005,2
Princeton Theological Seminary,Religion2020,2005,73
Princeton University,Cooperation2021,2005,35
Princeton University,Religion2020,2005,31
Priory of St Michael the Archangel,Religion2020,2005,1
Private Practice,Religion2020,2005,36
"Private University College of Education, Diocese of Linz",Religion2020,2005,4
Protestant Church in Hesse and Nassau,Religion2020,2005,1
Protestant Theological University,Religion2020,2005,5
Protestant University of Applied Sciences,Religion2020,2005,3
pruvio,Religion2020,2005,6
"Psychological Resources of Toledo, Ltd.",Religion2020,2005,2
Psychologische praktijk voor zingeving,Religion2020,2005,3
Public Health England,Religion2020,2005,6
Public Health Institute of India,Religion2020,2005,3
Purdue University,Cooperation2021,2005,11
Purdue University,Religion2020,2005,20
Purdue University Global,Religion2020,2005,8
Pusan National University,Religion2020,2005,1
Qatar University,Religion2020,2005,1
QIMR Berhofer Medical Research Institute,Cooperation2021,2005,1
QIMR Berhofer Medical Research Institute,Religion2020,2005,1
Qualcomm Inc,Cooperation2021,2005,5
Queen Margaret University,Religion2020,2005,2
Queen Mary College,Religion2020,2005,8
Queen Mary University of London,Cooperation2021,2005,4
Queen Mary University of London,Religion2020,2005,12
Queen’s University Belfast,Religion2020,2005,4
Queens College (CUNY),Religion2020,2005,2
"Queen's College, Birmingham",Religion2020,2005,2
Queen's Foundation,Religion2020,2005,12
Queen's University,Cooperation2021,2005,9
Queen's University,Religion2020,2005,26
Queens University Belfast,Religion2020,2005,10
Queen's University Belfast,Cooperation2021,2005,5
Queen's University Belfast,Religion2020,2005,35
Queen's University of Belfast,Religion2020,2005,3
Queensland Health,Religion2020,2005,1
Queensland University of Technology,Religion2020,2005,6
Quincy College,Religion2020,2005,2
Quinnipiac University,Religion2020,2005,5
Rabin Medical Center,Religion2020,2005,1
Rabin Medical Center- Davidoff Cancer Center,Religion2020,2005,1
Radboud University,Cooperation2021,2005,1
Radboud University,Religion2020,2005,37
Radboud University Medical Centre,Religion2020,2005,1
Radboud University Nijmegen,Cooperation2021,2005,4
Radboud University Nijmegen,Religion2020,2005,11
Radford University,Religion2020,2005,1
Rafsanjan University of Medical Sciences,Cooperation2021,2005,1
Rafsanjan University of Medical Sciences,Religion2020,2005,2
Ramakrishna Mission Vivekananda Educational and Research Institute,Religion2020,2005,1
Rambam Hospital Haifa,Cooperation2021,2005,1
Rambam Hospital Haifa,Religion2020,2005,2
RAND Corporation,Cooperation2021,2005,4
RAND Corporation,Religion2020,2005,4
Ray Marshall Center,Religion2020,2005,2
Real Estate Investor,Religion2020,2005,3
Redeemer University College,Religion2020,2005,4
Reed College,Religion2020,2005,1
Reformed Theological Seminary,Cooperation2021,2005,1
Reformed Theological Seminary,Religion2020,2005,5
Reformed University of Applied Sciences,Religion2020,2005,6
Refua Shlema Clinic,Cooperation2021,2005,3
Regent College,Cooperation2021,2005,2
Regent College,Religion2020,2005,13
Regent University,Religion2020,2005,22
Rehabilitation Institute of Chicago,Religion2020,2005,2
Religion,Cooperation2021,2005,1
Religion,Religion2020,2005,10
Religion and Science Course Program,Religion2020,2005,1
religion news service,Religion2020,2005,8
Religions of South Asia,Religion2020,2005,1
Religious Freedom & Business Foundation,Religion2020,2005,4
Religious Freedom Institute,Religion2020,2005,5
Renaissance Center for Higher Education,Religion2020,2005,1
Renmin University,Cooperation2021,2005,6
Renmin University of China,Religion2020,2005,21
Rennes 1 University,Cooperation2021,2005,6
"Republic Hospital, Bangalore",Religion2020,2005,2
Research Collaborator and Public Health Practioner,Religion2020,2005,1
"Research Design Associates, Inc.",Religion2020,2005,1
Research Institute for Theology and Religion,Religion2020,2005,1
Research Institute of Industrial Economics (IFN),Religion2020,2005,1
REsourceit,Religion2020,2005,4
Review of Religious Research,Religion2020,2005,11
Rhodes College,Cooperation2021,2005,2
Rhodes College,Religion2020,2005,1
Rhodes University,Cooperation2021,2005,5
Rice University,Cooperation2021,2005,14
Rice University,Religion2020,2005,38
Richard Stockton University,Religion2020,2005,3
Rigshospitalet,Cooperation2021,2005,1
Rigshospitalet,Religion2020,2005,1
RIKEN Center For Developmental Biology,Cooperation2021,2005,2
RINO Groep,Cooperation2021,2005,2
Ripon College Cuddesdon,Religion2020,2005,1
"Risk-Eraser, LLC",Cooperation2021,2005,4
Rissho University,Cooperation2021,2005,6
RIVM,Religion2020,2005,4
RMIT University,Cooperation2021,2005,5
RMIT University,Religion2020,2005,6
Roanoke College,Religion2020,2005,1
Roberts Wesleyan College,Religion2020,2005,4
Rochester Cathedral,Religion2020,2005,16
Rochester Institute of Technology,Religion2020,2005,3
Rockefeller University,Cooperation2021,2005,1
Rockefeller University,Religion2020,2005,2
Rockhurst University,Religion2020,2005,22
Rocky Mountain Biological Laboratory,Cooperation2021,2005,4
Roehampton University,Religion2020,2005,6
Rollins College,Religion2020,2005,5
Roma Tre University,Religion2020,2005,4
ronin institute,Religion2020,2005,2
Rosalind Franklin University,Religion2020,2005,8
Roskilde University,Religion2020,2005,4
Rotman Research Institute,Religion2020,2005,1
Rotterdam University of Applied Sciences,Religion2020,2005,2
Rowan University,Religion2020,2005,2
Royal Academy of Fine Arts,Religion2020,2005,2
Royal Australian and New Zealand College of Psychiatrists,Religion2020,2005,2
Royal College of Psychiatrists,Religion2020,2005,4
Royal Historical Society,Religion2020,2005,4
"Royal Holloway, University of London",Cooperation2021,2005,7
"Royal Holloway, University of London",Religion2020,2005,6
Royal Institute for Cultural Heritage,Religion2020,2005,1
Royal Roads University,Religion2020,2005,3
Roybal Institute on Aging,Religion2020,2005,1
RTI International,Religion2020,2005,2
RUDN University,Religion2020,2005,5
Rudolf Steiner University College,Religion2020,2005,2
Ruhr University Bochum,Cooperation2021,2005,2
Ruhr University Bochum,Religion2020,2005,20
Ruppin Academic Center,Religion2020,2005,2
Ruprecht-Karls-Universität Heidelberg,Religion2020,2005,1
Rush University,Cooperation2021,2005,2
Rush University,Religion2020,2005,44
Russian Academy of  Sciences,Religion2020,2005,1
Russian Academy of National Economy and Public Administration,Religion2020,2005,2
Russian Academy of Sciences,Religion2020,2005,1
Russian State University for the Humanities,Religion2020,2005,2
Rutgers New Jersey Medical School,Religion2020,2005,2
Rutgers School of Public Heath,Cooperation2021,2005,1
Rutgers School of Public Heath,Religion2020,2005,1
Rutgers University,Cooperation2021,2005,11
Rutgers University,Religion2020,2005,47
Rutherford University,Religion2020,2005,10
RWTH Aachen University,Cooperation2021,2005,4
Ryerson University,Cooperation2021,2005,6
Ryerson University,Religion2020,2005,1
Saarland University,Cooperation2021,2005,3
Sackler School of Medicine,Cooperation2021,2005,4
Sackler School of Medicine,Religion2020,2005,2
Sagami Women's University,Religion2020,2005,6
Saginaw Valley State University,Religion2020,2005,1
Saint Anselm College,Religion2020,2005,2
Saint Elizabeth's Hospital,Religion2020,2005,1
Saint Ignatius Church,Religion2020,2005,1
Saint Louis University,Cooperation2021,2005,1
Saint Louis University,Religion2020,2005,18
Saint Mary's College,Religion2020,2005,3
Saint Mary's University,Religion2020,2005,8
Saint Meinrad Seminary and School of Theology,Cooperation2021,2005,1
Saint Meinrad Seminary and School of Theology,Religion2020,2005,10
Saint Michael's College,Religion2020,2005,1
Saint Paul University,Religion2020,2005,17
Saint Petersburg State University,Cooperation2021,2005,1
Salem College,Religion2020,2005,3
Salesian Pontifical University,Religion2020,2005,3
Salk Institute,Religion2020,2005,1
Sam Houston State University,Religion2020,2005,1
Samford University,Religion2020,2005,8
Sampoerna Capital,Religion2020,2005,1
San Diego State University,Cooperation2021,2005,2
San Diego State University,CooperationAndReligion,2005,1
San Diego State University,Religion2020,2005,24
San Francisco State University,Religion2020,2005,12
San Francisco Theological Seminary,Religion2020,2005,3
San Jose State University,Religion2020,2005,4
Sansone Family Center for Well-Being,Cooperation2021,2005,2
Sansone Family Center for Well-Being,Religion2020,2005,3
Santa Barbara Institute for Consciousness Studies,Religion2020,2005,1
Santa Clara University,Religion2020,2005,58
Santa Fe Institute,Cooperation2021,2005,9
Sapienza University of Rome,Cooperation2021,2005,5
Sapienza University of Rome,Religion2020,2005,10
Sarah Herzog Memorial Hospital,Religion2020,2005,1
Sarum College,Religion2020,2005,7
saudi center for organ transplantation,Cooperation2021,2005,1
saudi center for organ transplantation,CooperationAndReligion,2005,1
saudi center for organ transplantation,Religion2020,2005,2
Saybrook Graduate School,Religion2020,2005,12
Saybrook University,Religion2020,2005,6
Scarritt College,Religion2020,2005,8
School for Advanced Studies in the Social Sciences,Religion2020,2005,1
School of Oriental and African Studies,Religion2020,2005,5
Schussental Clinic,Religion2020,2005,1
SCIENCE magazine,Religion2020,2005,16
Sciences Po,Religion2020,2005,6
Scientific Integrity Institute,Religion2020,2005,1
Scripps College,Religion2020,2005,5
SDM College of Naturopathy and Yogic Sciences,Religion2020,2005,1
Search Institute,Religion2020,2005,9
Seattle Cancer Care Alliance,Religion2020,2005,2
Seattle Pacific University,Religion2020,2005,7
Seattle University,Religion2020,2005,2
Second Military Medical University,Cooperation2021,2005,3
Seirei Mikatahara General Hospital,Religion2020,2005,10
Self-employed,Cooperation2021,2005,1
Self-employed,Religion2020,2005,5
Self-Employed Author,Religion2020,2005,3
Semmelweis University,Cooperation2021,2005,5
Seoul National University,Cooperation2021,2005,7
Seoul National University,Religion2020,2005,4
Seoul National University College of Medicine,Religion2020,2005,2
Serbian Academy of Sciences and Arts,Religion2020,2005,8
Seton Hall University,Religion2020,2005,7
Sewanee: The University of the South,Religion2020,2005,1
Seward County Health Department,Religion2020,2005,1
Shaanxi Normal University,Religion2020,2005,9
Shahid Bahonar University of Kerman,Cooperation2021,2005,1
Shahid Bahonar University of Kerman,Religion2020,2005,5
Shahid Beheshti University of Medical Science,Religion2020,2005,3
Shahid Sadoughi University of Medical Sciences and Health Services,Religion2020,2005,1
Shalem Institute for Spiritual Formation,Religion2020,2005,4
Shalom Hartman Institute,Religion2020,2005,1
Shandong University,Cooperation2021,2005,1
Shanghai Clinical Research Center,Cooperation2021,2005,1
Shanghai Clinical Research Center,Religion2020,2005,1
Shanghai International Studies University,Cooperation2021,2005,4
Shanghai Jiao Tong University,Cooperation2021,2005,7
Shanghai Jiao Tong University,Religion2020,2005,2
Shanghai Medical University,Cooperation2021,2005,1
Shanghai University,Cooperation2021,2005,3
Sheffield Hallam University,Religion2020,2005,4
Sheffield Teaching Hospitals NHS Foundation Trust,Religion2020,2005,3
"Shenyang Institute of Automation, Chinese Academy of Sciences",Cooperation2021,2005,3
Shenzhen University,Cooperation2021,2005,5
Shepherd Center,Religion2020,2005,12
Sheridan College,Cooperation2021,2005,2
Sheridan College,Religion2020,2005,2
Shimane University,Cooperation2021,2005,1
Shimer College,Religion2020,2005,3
Shizuoka University,Cooperation2021,2005,2
Sichuan University,Cooperation2021,2005,1
SIKA,Cooperation2021,2005,1
Simon Fraser University,Cooperation2021,2005,9
Simon Fraser University,Religion2020,2005,5
Sinai Urban Health Institute,Religion2020,2005,8
Singapore Management University,Cooperation2021,2005,3
Singapore Management University,CooperationAndReligion,2005,1
Singapore Management University,Religion2020,2005,7
SJ Grosswald & Associates,Religion2020,2005,3
Skidmore College,Religion2020,2005,1
Slate,Cooperation2021,2005,1
Slate,Religion2020,2005,10
SleepScore Labs,Religion2020,2005,2
Slovak Economic Association,Cooperation2021,2005,6
Smith College,Religion2020,2005,3
Smithsonian Tropical Research Institute,Cooperation2021,2005,1
SOAS University of London,Religion2020,2005,10
Social Sciences and Humanities Research Council of Canada,Religion2020,2005,3
Society of General Internal Medicine,Religion2020,2005,5
Sofia University,Religion2020,2005,1
Soka University,Religion2020,2005,1
Solvay,Religion2020,2005,4
Sophia University,Religion2020,2005,6
Sorbonne University,Religion2020,2005,2
South Dakota State University,Religion2020,2005,11
South Mississippi State Hospital,Religion2020,2005,2
Southeast University,Religion2020,2005,1
Southeastern Louisiana University,Cooperation2021,2005,1
Southeastern Louisiana University,CooperationAndReligion,2005,1
Southeastern Louisiana University,Religion2020,2005,6
Southern Baptist Theological Seminary,Religion2020,2005,8
Southern Cross University,Religion2020,2005,1
Southern Illinois University,Cooperation2021,2005,1
Southern Illinois University,Religion2020,2005,13
Southern Medical University,Cooperation2021,2005,1
Southern Methodist University,Cooperation2021,2005,3
Southern Methodist University,Religion2020,2005,17
Southern University of Science and Technology,Cooperation2021,2005,7
Southern Utah University,Cooperation2021,2005,1
Southern Utah University,Religion2020,2005,3
Southwest Forestry University,Cooperation2021,2005,9
Southwest Missouri State University,Religion2020,2005,2
Southwest National Primate Center,Religion2020,2005,1
Spiritual Competency Academy,Religion2020,2005,4
Sri Aurobindo Centre for Advanced Research,Religion2020,2005,2
Ss. Peter & Paul Parish,Religion2020,2005,1
SSM Health,Cooperation2021,2005,1
SSM Health,Religion2020,2005,6
St Andrews University,Religion2020,2005,3
St George & Sutherland Clinical School,Religion2020,2005,1
St Mary's Centre for Religion and Education,Cooperation2021,2005,2
St Mary's Centre for Religion and Education,Religion2020,2005,6
St Mary's University,Religion2020,2005,13
St Mary's University Church,Religion2020,2005,6
St Peter’s Saltley Trust,Cooperation2021,2005,1
St Peter’s Saltley Trust,Religion2020,2005,1
St. Ambrose Universtiy,Religion2020,2005,4
St. Augustine College,Religion2020,2005,1
St. Bonaventure University,Religion2020,2005,2
St. Catharine's College,Religion2020,2005,2
St. Edmundsbury,Religion2020,2005,1
St. Edward's University,Religion2020,2005,1
St. Francis Xavier University,Religion2020,2005,17
St. George's University,Religion2020,2005,4
St. Hilda's College,Religion2020,2005,2
St. Jerome's University,Religion2020,2005,1
St. John of God Clinical Research Centre,Cooperation2021,2005,1
St. John of God Clinical Research Centre,Religion2020,2005,2
St. John Providence Health System,Religion2020,2005,4
St. John's College,Religion2020,2005,4
"St. Johns College, Cambridge",Religion2020,2005,1
St. John's Seminary,Religion2020,2005,7
St. John's University,Religion2020,2005,5
St. Louis University,Religion2020,2005,7
St. Luke's Canyon View Behavioral Health Clinic,Religion2020,2005,2
St. Marylebone Healing and Counseling Center,Religion2020,2005,5
St. Mary's Seminary and University,Religion2020,2005,5
St. Mary's University,Religion2020,2005,6
"St. Mary's University, Twickenham",Religion2020,2005,4
St. Michael's College,Religion2020,2005,1
St. Olaf College,Religion2020,2005,10
St. Patrick's College,Religion2020,2005,1
St. Patrick's Seminary,Religion2020,2005,2
St. Paul's institute of Philosophy,Religion2020,2005,2
St. Peter's Catholic Church,Religion2020,2005,3
St. Peters College,Religion2020,2005,3
St. Petersburg State University,Cooperation2021,2005,1
St. Petersburg State University,Religion2020,2005,2
St. Philip's Centre,Religion2020,2005,2
St. Stephen's College,Religion2020,2005,1
"St. Thomas Hospital, London",Religion2020,2005,2
St. Thomas More College,Cooperation2021,2005,1
St. Thomas More College,Religion2020,2005,1
St. Thomas University,Religion2020,2005,1
St. Vincent College,Religion2020,2005,1
St. Vladimir's Orthodox Theological Seminary,Religion2020,2005,3
Staffordshire University,Cooperation2021,2005,1
Staffordshire University,Religion2020,2005,32
Stanford University,Cooperation2021,2005,32
Stanford University,Religion2020,2005,81
Stanley Royd Hospital,Religion2020,2005,5
STAR H LLC,Religion2020,2005,6
Star Tribune,Religion2020,2005,8
State Institute for Islamic Studies (STAIN) Salatiga,Religion2020,2005,1
State University of Campinas,Religion2020,2005,9
State University of New York,Religion2020,2005,3
State University of New York - Nassau,Religion2020,2005,2
State University of New York at Albany,Cooperation2021,2005,1
State University of New York at Albany,Religion2020,2005,6
State University of New York at Binghamton University,Cooperation2021,2005,8
State University of New York at Binghamton University,CooperationAndReligion,2005,1
State University of New York at Binghamton University,Religion2020,2005,4
State University of New York at Brockport,Religion2020,2005,1
State University of New York at Buffalo,Cooperation2021,2005,1
State University of New York at Buffalo,Religion2020,2005,5
State University of New York at Oneonta,Cooperation2021,2005,1
State University of New York at Oneonta,Religion2020,2005,7
State University of New York at Oswego,Cooperation2021,2005,1
State University of New York Downstate Medical Center,Religion2020,2005,1
"State University of New York, Old Westbury",Religion2020,2005,2
Stellenbosch University,Cooperation2021,2005,1
Stellenbosch University,Religion2020,2005,20
Stetson University,Religion2020,2005,8
STH Basel,Cooperation2021,2005,1
STH Basel,CooperationAndReligion,2005,1
STH Basel,Religion2020,2005,3
Stockholm Läns Landsting,Religion2020,2005,3
Stockholm School of Economics,Cooperation2021,2005,10
Stockholm University,Cooperation2021,2005,3
Stockholm University,Religion2020,2005,38
Stonehill College,Religion2020,2005,1
Stony Brook School of Medicine University of New York,Cooperation2021,2005,2
Stony Brook University,Cooperation2021,2005,9
Stony Brook University,Religion2020,2005,7
Stranmillis University College,Cooperation2021,2005,1
Strayer College,Religion2020,2005,1
StressWise,Religion2020,2005,1
Studia Traditionis Theologiae,Religion2020,2005,3
Suffolk University,Religion2020,2005,4
Sun Yat-Sen University,Cooperation2021,2005,3
Sunan Ampel State Islamic University Surabaya,Religion2020,2005,2
Sungkyunkwan University (SKKU),Cooperation2021,2005,2
Sungkyunkwan University (SKKU),Religion2020,2005,2
Sunnybrook Health Sciences Centre,Cooperation2021,2005,1
SUNY Fredonia,Religion2020,2005,2
SUNY Oswego,Religion2020,2005,1
SUNY Upstate Medical University,Cooperation2021,2005,1
SUNY Upstate Medical University,Religion2020,2005,1
Surrey University,Religion2020,2005,4
Sutter Health,Religion2020,2005,2
SVYASA University,Cooperation2021,2005,2
SVYASA University,Religion2020,2005,12
S-VYASA University,Religion2020,2005,2
Swami Vivekananda Yoga Anusandhana Samsthana,Religion2020,2005,5
Swansea University,Religion2020,2005,8
Swarthmore College,Religion2020,2005,11
Swinburne University of Technology,Cooperation2021,2005,7
Swinburne University of Technology,Religion2020,2005,4
Swiss Federal Institute of Technology Zurich,Religion2020,2005,3
Swiss Institute for Experimental Cancer Research,Cooperation2021,2005,3
Syarif Hidayatullah State Islamic University,Religion2020,2005,1
Sydney College of Divinity,Religion2020,2005,7
Syiah Kuala University,Religion2020,2005,1
Synamedia,Cooperation2021,2005,1
Syracuse University,Cooperation2021,2005,1
Syracuse University,Religion2020,2005,28
T&C Chen Center for Social and Decision Neuroscience,Cooperation2021,2005,5
Taipei Medical University,Cooperation2021,2005,2
Taisho University,Cooperation2021,2005,2
Tajen University,Religion2020,2005,2
Takeda,Cooperation2021,2005,5
Tamagawa University,Cooperation2021,2005,3
Tamkang University,Religion2020,2005,1
Tampere University,Religion2020,2005,1
Taos Institute,Religion2020,2005,1
Taras Shevchenko National University of Kyiv,Religion2020,2005,3
Tarbiat Modares University,Cooperation2021,2005,1
Tarbiat Modares University,Religion2020,2005,2
Taylor Seminary,Religion2020,2005,4
Taylor University,Religion2020,2005,7
Technical University of Berlin,Religion2020,2005,2
Technical University of Chemnitz,Religion2020,2005,2
Technical University of Denmark,Cooperation2021,2005,1
Technical University of Munich,Cooperation2021,2005,1
Technion Israel Institute of Technology,Cooperation2021,2005,2
Technion Israel Institute of Technology,Religion2020,2005,5
Teesside University,Cooperation2021,2005,3
Teesside University,CooperationAndReligion,2005,1
Teesside University,Religion2020,2005,2
Tehran University of Medical Sciences,Cooperation2021,2005,2
Tehran University of Medical Sciences,CooperationAndReligion,2005,1
Tehran University of Medical Sciences,Religion2020,2005,13
Teikyo University,Cooperation2021,2005,3
Tel Aviv Medical Center,Cooperation2021,2005,1
Tel Aviv University,Cooperation2021,2005,14
Tel Aviv University,CooperationAndReligion,2005,1
Tel Aviv University,Religion2020,2005,22
Telenor Research,Cooperation2021,2005,2
Telenor Research,Religion2020,2005,6
Temple University,Cooperation2021,2005,2
Temple University,Religion2020,2005,13
Templeton Religion Trust,Religion2020,2005,4
Tennessee State University,Religion2020,2005,6
Tenton Behavior Theraphy,Religion2020,2005,3
Terry Fox Research Institute,Cooperation2021,2005,1
Texas A&M University,Cooperation2021,2005,14
Texas A&M University,CooperationAndReligion,2005,3
Texas A&M University,Religion2020,2005,27
Texas Christian University,Religion2020,2005,7
Texas State University,Religion2020,2005,6
Texas Tech University,Religion2020,2005,17
Thammasat University,Religion2020,2005,1
Thanyapura Mind Centre,Religion2020,2005,1
"The Academy of Korean Studies, Seongnam",Religion2020,2005,3
The American University in Cairo,Religion2020,2005,3
The Artic University of Norway,Religion2020,2005,5
The Aspen Institute,Religion2020,2005,3
The Brain Research Institute,Religion2020,2005,2
The British Library,Religion2020,2005,3
The Catholic University of America,Religion2020,2005,3
The Center for Mind Body Medicine,Religion2020,2005,2
The Chinese University of Hong Kong,Cooperation2021,2005,7
The Chinese University of Hong Kong,Religion2020,2005,2
The Chopra Foundation,Religion2020,2005,1
The Donner Institute,Religion2020,2005,1
the Drug Research Center of the Sant Pau Hospital,Religion2020,2005,1
The Essential Medical Technology Foundation,Religion2020,2005,1
The European Graduate School,Religion2020,2005,1
The General Theological Seminary,Religion2020,2005,7
The Hebrew University,Cooperation2021,2005,2
The Hebrew University,Religion2020,2005,4
The International Institute of Organisational Psychological Medicine,Religion2020,2005,1
The Jane Goodall Institute,Cooperation2021,2005,1
The Jane Goodall Institute,Religion2020,2005,1
The Key Institute for Brain-Mind Research,Religion2020,2005,3
"The Kinneret Center on Peace, Securityand Society",Cooperation2021,2005,2
"The Kinneret Center on Peace, Securityand Society",Religion2020,2005,1
The Lighthouse Institute,Cooperation2021,2005,1
The Lighthouse Institute,Religion2020,2005,4
The Marcus Institute of Integrative Health,Religion2020,2005,1
The Middle East Technical University,Cooperation2021,2005,3
The National Academy of Sciences of Ukraine,Religion2020,2005,26
The New School,Religion2020,2005,1
The New York Botanical Garden,Religion2020,2005,2
The Open University,Religion2020,2005,5
The Ottowa Hospital,Religion2020,2005,2
The People's Hospital of Huangmei,Cooperation2021,2005,3
The Queens Foundation,Religion2020,2005,2
The Research Institute of Industrial Economics,Religion2020,2005,1
The Single Parents and Teens Foundation,Religion2020,2005,1
The Three Doors ASBL,Religion2020,2005,5
The Tiltenburg,Religion2020,2005,4
The Underground University,Religion2020,2005,1
The University of Campinas,Cooperation2021,2005,1
the University of Denver,Religion2020,2005,8
The University of Edinburgh,Religion2020,2005,4
The University of Hong Kong,Religion2020,2005,1
The University of New South Wales-The Kirby Institute,Religion2020,2005,1
The University of Queensland,Religion2020,2005,2
The University of Rochester School of Medicine,Religion2020,2005,2
The University of Texas at Austin,Religion2020,2005,2
The University of Texas at Dallas,Religion2020,2005,1
The University of Texas at San Antonio,Religion2020,2005,3
The University of the South Sewanee,Religion2020,2005,3
The University of Trans Disciplinary Health Sciences & Technology,Religion2020,2005,1
The University of Western Australia,Cooperation2021,2005,1
The Van Leer Jerusalem Institute,Cooperation2021,2005,1
The Van Leer Jerusalem Institute,Religion2020,2005,3
The Westport Day School,Cooperation2021,2005,1
The Westport Day School,Religion2020,2005,5
Theopolis Institute,Religion2020,2005,1
Thomas Jefferson University,Religion2020,2005,9
Thomas More College of Liberal Arts,Religion2020,2005,3
Thorneloe University,Religion2020,2005,1
Tiangong University,Cooperation2021,2005,5
Tianjin University,Cooperation2021,2005,4
Tianjin University of Traditional Chinese Medicine,Religion2020,2005,1
Tidewater Child and Family Behavioral Health,Religion2020,2005,3
Tilburg University,Cooperation2021,2005,24
Tilburg University,Religion2020,2005,30
Tinbergen Institute,Cooperation2021,2005,2
Tirana University,Cooperation2021,2005,1
Tirana University,Religion2020,2005,4
Titus Brandsma Institute,Religion2020,2005,8
Toho University School of Medicine,Cooperation2021,2005,2
Tohoku University,Cooperation2021,2005,6
Tohoku University,Religion2020,2005,4
Tokai University,Cooperation2021,2005,4
Tokyo Gakugei University,Cooperation2021,2005,6
Tokyo Institute of Technology,Cooperation2021,2005,13
Tokyo Institute of Technology,Religion2020,2005,1
Tokyo Medical and Dental University,Religion2020,2005,3
Tokyo Women's Medical University,Religion2020,2005,1
Tor Vergata University of Rome,Cooperation2021,2005,1
Touro College,Religion2020,2005,4
Toyohashi University of Technology,Cooperation2021,2005,11
Transforming Shattered Identities,Religion2020,2005,1
Tree of Life Tai Chi Center,Religion2020,2005,1
Trent University,Religion2020,2005,6
Tribunal of the Catholic Diocese of Colombus,Religion2020,2005,1
Trinity College,Religion2020,2005,15
Trinity College Dublin,Cooperation2021,2005,5
Trinity College Dublin,CooperationAndReligion,2005,1
Trinity College Dublin,Religion2020,2005,20
Trinity College of Arts & Sciences,Religion2020,2005,7
"Trinity College, Cambridge",Religion2020,2005,5
Trinity Evangelical Divinity School,Religion2020,2005,3
Trinity International University,Religion2020,2005,1
Trinity Lutheran Church,Religion2020,2005,1
Trinity Theological College,Religion2020,2005,2
Trinity University,Religion2020,2005,4
Trinity Western University,Religion2020,2005,30
Tripler Army Medical Center,Religion2020,2005,5
Trnavska Univerzita,Religion2020,2005,2
Troy University,Cooperation2021,2005,3
Tsinghua University,Cooperation2021,2005,8
Tsukuba Medical Center Hospital,Religion2020,2005,2
Tufts University,Cooperation2021,2005,1
Tufts University,Religion2020,2005,15
Tufts University School of Medicine,Religion2020,2005,2
Tulane University,Cooperation2021,2005,10
Tulane University,CooperationAndReligion,2005,1
Tulane University,Religion2020,2005,9
Tuskegee University,Religion2020,2005,1
Tyndale University,Cooperation2021,2005,1
Tyndale University,Religion2020,2005,1
U.S. Department of Health and Human Services,Religion2020,2005,1
UC Los Angeles,Religion2020,2005,1
UC San Diego,Religion2020,2005,1
UC Santa Cruz,Religion2020,2005,2
UCHealth,Religion2020,2005,5
Ucsf Medical Center,Religion2020,2005,1
Udayana University,Religion2020,2005,5
UIN Malang,Religion2020,2005,2
UIN Sunan Ampel,Religion2020,2005,1
UIN Sunan Kalijaga Yogyakarta,Religion2020,2005,2
UIN Syarif Hidayatullah,Cooperation2021,2005,1
UIN Syarif Hidayatullah,Religion2020,2005,1
UiT The Arctic University of Norway,Religion2020,2005,3
Ulster University,Cooperation2021,2005,1
Ulster University,Religion2020,2005,10
Umeå University,Cooperation2021,2005,1
Umeå University,CooperationAndReligion,2005,1
Umeå University,Religion2020,2005,4
UNC Gillings School of Global Public Health,Religion2020,2005,1
UNESCO,Cooperation2021,2005,3
Uniformed Services University of the Health Sciences,Cooperation2021,2005,3
Uniformed Services University of the Health Sciences,Religion2020,2005,1
Uninettuno international telematic university,Cooperation2021,2005,5
Union College,Religion2020,2005,2
Union Congregational United Church of Christ,Religion2020,2005,1
Union Presbyterian Seminary,Religion2020,2005,2
Union Theological College,Religion2020,2005,1
Union Theological Seminary,Religion2020,2005,9
Union University,Religion2020,2005,3
United Arab Emirates University,Cooperation2021,2005,5
United Nations,Religion2020,2005,1
United States Naval Academy,Cooperation2021,2005,1
United States Naval Academy,Religion2020,2005,5
United Theological College,Religion2020,2005,2
United Theological Seminary of the Twin Cities,Religion2020,2005,9
Uniting College,Religion2020,2005,1
Universidad Arturo Prat,Religion2020,2005,1
Universidad Autónoma de Barcelona,Religion2020,2005,2
Universidad Autonoma Metropolitana,Religion2020,2005,1
Universidad Carlos III de Madrid,Cooperation2021,2005,8
Universidad de Sevilla,Cooperation2021,2005,2
Universidad del Pais Vasco,Cooperation2021,2005,1
Universidad del Pais Vasco,Religion2020,2005,2
Universidad Nacional de Educacion a Distancia,Cooperation2021,2005,2
Universidad Nacional de Rosario  Universidad Nacional de Rosario  Universidad Nacional de Rosario,Religion2020,2005,2
Universidad Rey Juan Carlos,Cooperation2021,2005,10
Universidade de Lisboa,Cooperation2021,2005,10
Universidade de Lisboa,Religion2020,2005,3
Universidade do Porto,Religion2020,2005,1
Universidade Federal de San Carlos   São Carlos,Religion2020,2005,2
Universidade Federal de São Paulo,Religion2020,2005,2
Universidade NOVA de Lisboa,Religion2020,2005,4
Universita Bocconi,Cooperation2021,2005,3
Universita Bocconi,Religion2020,2005,1
Università della Svizzera italiana,Cooperation2021,2005,1
Università di Bari,Religion2020,2005,1
Università di Padova,Religion2020,2005,4
Universitas Gadjah Mada,Religion2020,2005,6
Universitas Islam Negeri Maulana Malik Ibrahim Malang,Religion2020,2005,2
Universitas Islam Negeri Sultan Maulana Hasanuddin Banten,Religion2020,2005,3
Universitas Islam Negeri Sunan Ampel,Religion2020,2005,1
universitas muhammadiyah yogyakarta,Religion2020,2005,2
Universitas Sebelas Maret,Religion2020,2005,1
Universitat Autònoma de Barcelona,Religion2020,2005,1
Universitat de Barcelona,Religion2020,2005,1
Universitat Gottingngen,Religion2020,2005,2
Universitat Pompeu Fabra,Religion2020,2005,2
Universität Regensburg,Religion2020,2005,2
Universitat Wurzburg,Religion2020,2005,13
Université Catholique de Louvain,Cooperation2021,2005,5
Université Catholique de Louvain,Religion2020,2005,16
Université de Fribourg,Religion2020,2005,3
Universite de Lorraine,Religion2020,2005,1
Université de Paris,Religion2020,2005,2
Universite du Luxembourg,Cooperation2021,2005,3
Université du Québec à Montréal,Religion2020,2005,2
Université du Québec en Outaouais,Religion2020,2005,1
Universite Laval,Religion2020,2005,4
Université Libre de Bruxelles,Cooperation2021,2005,12
Université Libre in Brussels,Cooperation2021,2005,6
Université Libre in Brussels,Religion2020,2005,2
Université Paris I,Cooperation2021,2005,10
Universite Sorbonne Paris Nord,Cooperation2021,2005,2
Universitetet Agder,Religion2020,2005,2
Universiti Sains Malaysia,Religion2020,2005,2
University “G. d’Annunzio” of Chieti-Pescara,Religion2020,2005,1
"University at Albany, The State University of New York",Cooperation2021,2005,2
"University at Albany, The State University of New York",Religion2020,2005,3
University College Chester,Cooperation2021,2005,2
University College Chester,Religion2020,2005,5
University College Cork,Cooperation2021,2005,2
University College Cork,Religion2020,2005,12
University College Dublin,Cooperation2021,2005,1
University College Dublin,Religion2020,2005,19
University College London,Cooperation2021,2005,29
University College London,Religion2020,2005,98
University College London Hospitals NHS Foundation Trust,Cooperation2021,2005,1
University College of North Wales,Religion2020,2005,2
University Graduate Center at Kjeller,Cooperation2021,2005,4
University Health Network,Religion2020,2005,3
University Hospital Center Sisters of Mercy Zagreb,Religion2020,2005,1
University Hospital Cologne,Religion2020,2005,1
University Hospital Lewisham,Religion2020,2005,5
University Hospitals Connor,Religion2020,2005,4
University Medical Center Gottingen,Cooperation2021,2005,1
University Medical Center Gottingen,Religion2020,2005,2
University Medical Center Utrecht,Cooperation2021,2005,4
University of Aarhus,Cooperation2021,2005,1
University of Aarhus,Religion2020,2005,9
University of Aberdeen,Cooperation2021,2005,1
University of Aberdeen,Religion2020,2005,58
University of Adelaide,Religion2020,2005,7
University of Agder,Religion2020,2005,6
University of Akron,Religion2020,2005,8
University of Akureyri,Religion2020,2005,2
University of Alabama,Religion2020,2005,14
University of Alabama at Birmingham,Cooperation2021,2005,1
University of Alabama at Birmingham,Religion2020,2005,25
University of Alaska,Religion2020,2005,1
University of Alaska Anchorage,Religion2020,2005,1
University of Albany,Cooperation2021,2005,4
University of Albany,Religion2020,2005,3
University of Alberta,Cooperation2021,2005,14
University of Alberta,Religion2020,2005,39
University of Alicante,Cooperation2021,2005,1
University of Allahabad,Religion2020,2005,5
University of Amsterdam,Cooperation2021,2005,36
University of Amsterdam,Religion2020,2005,54
University of Antwerp,Cooperation2021,2005,2
University of Antwerp,Religion2020,2005,1
University of Applied Sciences Coburg,Religion2020,2005,9
University of Applied Sciences of the Grisons,Cooperation2021,2005,5
University of Arizona,Cooperation2021,2005,8
University of Arizona,Religion2020,2005,50
University of Arkansas,Cooperation2021,2005,1
University of Arkansas,Religion2020,2005,11
University of Arkansas at Little Rock,Religion2020,2005,5
University of Arkansas for Medical Science,Religion2020,2005,3
University of Athens,Religion2020,2005,11
University of Auckland,Cooperation2021,2005,8
University of Auckland,Religion2020,2005,26
University of Bamberg,Religion2020,2005,6
University of Barcelona,Religion2020,2005,1
University of Basel,Cooperation2021,2005,4
University of Basel,Religion2020,2005,8
University of Bath,Cooperation2021,2005,5
University of Bath,CooperationAndReligion,2005,1
University of Bath,Religion2020,2005,9
University of Bayreuth,Religion2020,2005,3
University of Bedfordshire,Cooperation2021,2005,1
University of Bedfordshire,CooperationAndReligion,2005,1
University of Bedfordshire,Religion2020,2005,1
University of Belgrade,Religion2020,2005,8
University of Bergen,Religion2020,2005,29
University of Bern,Cooperation2021,2005,2
University of Bern,Religion2020,2005,12
University of Bielefeld,Cooperation2021,2005,1
University of Bielefeld,Religion2020,2005,5
University of Birmingham,Cooperation2021,2005,1
University of Birmingham,Religion2020,2005,85
University of Bocconi,Cooperation2021,2005,4
University of Bologna,Cooperation2021,2005,6
University of Bologna,Religion2020,2005,3
University of Bolton,Cooperation2021,2005,1
University of Bonn,Cooperation2021,2005,15
University of Bonn,Religion2020,2005,17
University of Botswana,Cooperation2021,2005,1
University of Botswana,CooperationAndReligion,2005,1
University of Botswana,Religion2020,2005,6
University of Bradford,Religion2020,2005,10
University of Brasilia,Cooperation2021,2005,1
University of Bremen,Cooperation2021,2005,1
University of Bremen,Religion2020,2005,26
University of Brighton,Religion2020,2005,4
University of Bristol,Cooperation2021,2005,15
University of Bristol,Religion2020,2005,73
University of British Columbia,Cooperation2021,2005,36
University of British Columbia,CooperationAndReligion,2005,1
University of British Columbia,Religion2020,2005,62
University of Buenos Aires,Religion2020,2005,4
University of Buffalo,Cooperation2021,2005,6
University of Calgary,Cooperation2021,2005,8
University of Calgary,CooperationAndReligion,2005,1
University of Calgary,Religion2020,2005,50
University of California,Religion2020,2005,11
University of California - Berkeley,Cooperation2021,2005,9
University of California - Berkeley,Religion2020,2005,24
University of California - Davis,Cooperation2021,2005,2
University of California - Davis,Religion2020,2005,7
University of California - Irvine,Cooperation2021,2005,3
University of California - Irvine,Religion2020,2005,15
University of California - Los Angeles,Cooperation2021,2005,9
University of California - Los Angeles,Religion2020,2005,48
University of California - Riverside,Cooperation2021,2005,14
University of California - Riverside,Religion2020,2005,17
University of California - San Diego,Cooperation2021,2005,17
University of California - San Diego,Religion2020,2005,14
University of California - San Francisco,Cooperation2021,2005,11
University of California - San Francisco,Religion2020,2005,28
University of California - Santa Barbara,Cooperation2021,2005,7
University of California - Santa Barbara,Religion2020,2005,16
"University of California, Berkeley",Cooperation2021,2005,4
"University of California, Berkeley",Religion2020,2005,44
"University of California, Davis",Cooperation2021,2005,13
"university of california, davis",Religion2020,2005,11
"University of California, Irvine",Cooperation2021,2005,4
"University of California, Irvine",CooperationAndReligion,2005,2
"University of California, Irvine",Religion2020,2005,31
"University of California, Los Angeles",Cooperation2021,2005,9
"University of California, Los Angeles",Religion2020,2005,26
"University of California, Riverside",Cooperation2021,2005,8
"University of California, Riverside",Religion2020,2005,12
"University of California, San Diego",Cooperation2021,2005,5
"University of California, San Diego",CooperationAndReligion,2005,1
"University of California, San Diego",Religion2020,2005,36
"University of California, San Francisco",Cooperation2021,2005,1
"University of California, San Francisco",Religion2020,2005,5
"University of California, Santa Barbara",Cooperation2021,2005,17
"University of California, Santa Barbara",CooperationAndReligion,2005,4
"University of California, Santa Barbara",Religion2020,2005,29
"University of California, Santa Cruz",Cooperation2021,2005,5
"University of California, Santa Cruz",Religion2020,2005,1
University of Cambridge,Cooperation2021,2005,53
University of Cambridge,CooperationAndReligion,2005,2
University of Cambridge,Religion2020,2005,140
University of Canberra,Religion2020,2005,2
University of Canterbury,Cooperation2021,2005,1
University of Canterbury,Religion2020,2005,7
University of Cape Town,Cooperation2021,2005,5
University of Cape Town,Religion2020,2005,16
University of Catania,Cooperation2021,2005,2
University of Central England,Cooperation2021,2005,1
University of Central England,Religion2020,2005,5
University of Central Florida,Cooperation2021,2005,5
University of Central Florida,Religion2020,2005,17
University of Central Lancashire,Cooperation2021,2005,5
University of Central Lancashire,Religion2020,2005,1
University of Chester,Religion2020,2005,33
University of Chicago,Cooperation2021,2005,11
University of Chicago,Religion2020,2005,110
University of Chicago Divinity School,Religion2020,2005,2
University of Chichester,Religion2020,2005,5
University of Cincinnati,Religion2020,2005,34
University of Coimbra,Religion2020,2005,1
University of Cologne,Cooperation2021,2005,16
University of Cologne,CooperationAndReligion,2005,1
University of Cologne,Religion2020,2005,1
University of Colorado,Cooperation2021,2005,1
University of Colorado Anschutz Medical Campus,Cooperation2021,2005,2
University of Colorado Anschutz Medical Campus,Religion2020,2005,11
University of Colorado at Boulder,Religion2020,2005,16
University of Colorado at Colorado Springs,Cooperation2021,2005,2
University of Colorado at Colorado Springs,CooperationAndReligion,2005,2
University of Colorado at Colorado Springs,Religion2020,2005,14
University of Colorado Boulder,Religion2020,2005,3
University of Colorado Denver,Cooperation2021,2005,1
University of Colorado Denver,Religion2020,2005,27
University of Colorado Medical School,Religion2020,2005,1
University of Colorado School of Medicine,Religion2020,2005,3
"University of Colorado, Boulder",Cooperation2021,2005,1
"University of Colorado, Boulder",Religion2020,2005,7
University of Concordia,Cooperation2021,2005,1
University of Connecticut,Cooperation2021,2005,4
University of Connecticut,Religion2020,2005,43
University of Constance,Religion2020,2005,3
University of Copenhagen,Cooperation2021,2005,8
University of Copenhagen,Religion2020,2005,46
University of Crete,Religion2020,2005,2
University of Cumbria,Religion2020,2005,1
University of Cyprus,Religion2020,2005,5
University of Dalhousie,Religion2020,2005,1
University of Dallas,Religion2020,2005,1
University of Dayton,Religion2020,2005,11
University of Debrecen,Cooperation2021,2005,1
University of Delaware,Cooperation2021,2005,2
University of Delaware,Religion2020,2005,7
University of Delhi,Religion2020,2005,4
University of Denver,Cooperation2021,2005,1
University of Denver,Religion2020,2005,8
University of Derby,Religion2020,2005,3
University of Detroit Mercy,Religion2020,2005,6
University of Divinity,Religion2020,2005,12
University of Dubuque Theological Seminary,Religion2020,2005,1
University of Duisburg-Essen,Cooperation2021,2005,1
University of Duisburg-Essen,Religion2020,2005,5
University of Dundee,Cooperation2021,2005,3
University of Durham,Religion2020,2005,7
University of Düsseldorf,Religion2020,2005,1
University of East Anglia,Cooperation2021,2005,15
University of East Anglia,CooperationAndReligion,2005,1
University of East Anglia,Religion2020,2005,16
University of East London,Cooperation2021,2005,2
University of Eastern Finland,Religion2020,2005,4
University of Eastern Piedmont,Religion2020,2005,3
University of Edinburgh,Cooperation2021,2005,14
University of Edinburgh,CooperationAndReligion,2005,3
University of Edinburgh,Religion2020,2005,110
University of Electronic Science and Technology of China,Cooperation2021,2005,5
University of Enginerring and Technology,Cooperation2021,2005,3
University of Erfurt,Religion2020,2005,6
University of Erlangen-Nuremberg,Cooperation2021,2005,1
University of Erlangen-Nuremberg,Religion2020,2005,6
University of Essex,Cooperation2021,2005,4
University of Essex,Religion2020,2005,8
University of Evry-Val d'Essonne,Religion2020,2005,2
University of Exeter,Cooperation2021,2005,11
University of Exeter,CooperationAndReligion,2005,1
University of Exeter,Religion2020,2005,72
University of Florence,Cooperation2021,2005,1
University of Florida,Cooperation2021,2005,4
University of Florida,Religion2020,2005,33
University of Foggia,Cooperation2021,2005,2
University of Frankfurt,Religion2020,2005,6
University of Freiburg,Cooperation2021,2005,1
University of Freiburg,Religion2020,2005,4
University of Fribourg,Religion2020,2005,2
University of Fukui,Religion2020,2005,2
University of Gastronomic Sciences,Religion2020,2005,4
University of Gdańsk,Religion2020,2005,1
University of Geneva,Cooperation2021,2005,2
University of Geneva,Religion2020,2005,29
University of Georgia,Cooperation2021,2005,6
University of Georgia,Religion2020,2005,12
University of Ghana,Religion2020,2005,1
University of Girona,Religion2020,2005,4
University of Glasgow,Cooperation2021,2005,3
University of Glasgow,Religion2020,2005,48
University of Gloucestershire,Religion2020,2005,10
University of Gothenburg,Cooperation2021,2005,8
University of Gothenburg,Religion2020,2005,16
University of Gottingen,Religion2020,2005,1
University of Göttingen,Cooperation2021,2005,9
University of Göttingen,CooperationAndReligion,2005,1
University of Göttingen,Religion2020,2005,43
University of Granada,Cooperation2021,2005,1
University of Granada,Religion2020,2005,1
University of Graz,Cooperation2021,2005,4
University of Graz,Religion2020,2005,5
University of Greifswald,Cooperation2021,2005,1
University of Groningen,Cooperation2021,2005,15
University of Groningen,Religion2020,2005,37
University of Guelph,Cooperation2021,2005,10
University of Guelph-Humber,Religion2020,2005,2
University of Haifa,Cooperation2021,2005,4
University of Haifa,Religion2020,2005,47
University of Hamburg,Cooperation2021,2005,11
University of Hamburg,CooperationAndReligion,2005,1
University of Hamburg,Religion2020,2005,9
University of Hannover,Religion2020,2005,6
University of Hawai‘i,Cooperation2021,2005,1
University of Hawai‘i,Religion2020,2005,3
University of Hawai‘i at Mānoa,Cooperation2021,2005,9
University of Hawai‘i at Mānoa,Religion2020,2005,7
University of Hawai‘i–West O‘ahu,Religion2020,2005,3
University of Hawai'i,Cooperation2021,2005,7
University of Hawai'i,Religion2020,2005,2
University of Hawaii at Manoa,Cooperation2021,2005,1
University of Hawaii at Manoa,Religion2020,2005,3
"University of Hawaii, Hilo",Religion2020,2005,2
University of Heidelberg,Religion2020,2005,10
University of Helsinki,Cooperation2021,2005,5
University of Helsinki,Religion2020,2005,53
University of Hertfordshire,Religion2020,2005,1
University of Hong Kong,Cooperation2021,2005,4
University of Hong Kong,CooperationAndReligion,2005,1
University of Hong Kong,Religion2020,2005,26
University of Houston,Cooperation2021,2005,18
University of Houston,CooperationAndReligion,2005,1
University of Houston,Religion2020,2005,12
University of Huddersfield,Religion2020,2005,6
University of Hull,Religion2020,2005,9
University of Ibadan,Religion2020,2005,2
University of Iceland,Religion2020,2005,2
University of Illinois,Cooperation2021,2005,5
University of Illinois,Religion2020,2005,12
University of Illinois at Chicago,Cooperation2021,2005,2
University of Illinois at Chicago,Religion2020,2005,5
University of Illinois at Urbana-Champaign,Cooperation2021,2005,3
University of Illinois at Urbana-Champaign,Religion2020,2005,25
University of Illinois Urbana-Champaign,Religion2020,2005,2
University of Innsbruck,Cooperation2021,2005,11
University of Innsbruck,Religion2020,2005,13
University of Iowa,Cooperation2021,2005,7
University of Iowa,Religion2020,2005,57
University of Jena,Cooperation2021,2005,3
University of Johannesburg,Cooperation2021,2005,1
University of Johannesburg,Religion2020,2005,2
University of Jyväskylä,Cooperation2021,2005,2
University of Kansas,Cooperation2021,2005,2
University of Kansas,Religion2020,2005,29
University of Kansas Medical Center,Religion2020,2005,1
University of Karachi,Cooperation2021,2005,1
University of Karachi,Religion2020,2005,5
University of Kent,Cooperation2021,2005,6
University of Kent,Religion2020,2005,24
University of Kent at Canterbury,Religion2020,2005,12
University of Kentucky,Cooperation2021,2005,2
University of Kentucky,Religion2020,2005,33
University of Kiel,Religion2020,2005,2
University of Koblenz-Landau,Cooperation2021,2005,3
University of Koblenz-Landau,Religion2020,2005,4
University of Koln,Religion2020,2005,1
University of Konstanz,Cooperation2021,2005,11
University of Konstanz,Religion2020,2005,1
University of Kurdistan Hawler,Religion2020,2005,3
University of KwaZulu-Natal,Religion2020,2005,21
University of Lancaster,Religion2020,2005,21
University of L'Aquila,Cooperation2021,2005,2
University of Lausanne,Cooperation2021,2005,26
University of Lausanne,CooperationAndReligion,2005,1
University of Lausanne,Religion2020,2005,23
University of Leeds,Cooperation2021,2005,2
University of Leeds,CooperationAndReligion,2005,1
University of Leeds,Religion2020,2005,40
University of Leicester,Cooperation2021,2005,8
University of Leicester,CooperationAndReligion,2005,1
University of Leicester,Religion2020,2005,31
University of Leiden,Cooperation2021,2005,1
University of Leiden,Religion2020,2005,9
University of Leipzig,Cooperation2021,2005,7
University of Leipzig,Religion2020,2005,13
University of Lethbridge,Cooperation2021,2005,2
University of Lethbridge,Religion2020,2005,3
University of Leuven,Cooperation2021,2005,5
University of Leuven,Religion2020,2005,17
University of Liege,Cooperation2021,2005,2
University of Liege,Religion2020,2005,3
University of Lille,Religion2020,2005,3
University of Lille III,Religion2020,2005,4
University of Limerick,Religion2020,2005,3
University of Lincoln,Religion2020,2005,3
University of Lisbon,Religion2020,2005,1
University of Liverpool,Religion2020,2005,17
University of Ljubljana,Religion2020,2005,2
University of Lleida,Religion2020,2005,1
University of London,Cooperation2021,2005,2
University of London,CooperationAndReligion,2005,1
University of London,Religion2020,2005,70
University of Louisville,Cooperation2021,2005,2
University of Louisville,Religion2020,2005,13
University of Louvain,Cooperation2021,2005,2
University of Louvain,Religion2020,2005,2
University of Lucerne,Religion2020,2005,7
University of Ludwigsburg,Religion2020,2005,1
University of Lund,Cooperation2021,2005,2
University of Luxembourg,Cooperation2021,2005,3
University of Lynchburg,Religion2020,2005,3
University of Lyon,Cooperation2021,2005,2
University of Lyon-II,Religion2020,2005,4
University of Maastricht,Religion2020,2005,2
University of Macau,Religion2020,2005,11
University of Madras,Religion2020,2005,2
University of Madrid,Religion2020,2005,3
University of Magdeburg,Cooperation2021,2005,2
University of Maine,Religion2020,2005,8
University of Málaga,Cooperation2021,2005,1
University of Málaga,Religion2020,2005,2
University of Malawi,Cooperation2021,2005,1
University of Malawi,Religion2020,2005,5
University of Malaya,Cooperation2021,2005,1
University of Malaya,Religion2020,2005,5
University of Malta,Cooperation2021,2005,1
University of Malta,Religion2020,2005,7
University of Manchester,Cooperation2021,2005,5
University of Manchester,Religion2020,2005,46
University of Manitoba,Cooperation2021,2005,8
University of Manitoba,Religion2020,2005,15
University of Mannheim,Religion2020,2005,4
University of Marburg,Religion2020,2005,13
University of Maribor,Cooperation2021,2005,20
University of Maribor,Religion2020,2005,15
University of Mary Washington,Religion2020,2005,5
University of Maryland,Cooperation2021,2005,32
University of Maryland,Religion2020,2005,37
University of Maryland - College Park,Cooperation2021,2005,12
University of Maryland - College Park,Religion2020,2005,1
"University of Maryland, Baltimore",Cooperation2021,2005,1
"University of Maryland, Baltimore",Religion2020,2005,1
"University of Maryland, Baltimore Country",Religion2020,2005,5
"University of Maryland, College Park",Cooperation2021,2005,9
"University of Maryland, College Park",Religion2020,2005,17
University of Massachusetts,Religion2020,2005,1
University of Massachusetts - Amherst,Cooperation2021,2005,11
University of Massachusetts - Amherst,Religion2020,2005,5
University of Massachusetts - Boston,Cooperation2021,2005,1
University of Massachusetts - Boston,Religion2020,2005,9
University of Massachusetts Amherst,Cooperation2021,2005,7
University of Massachusetts Lowell,Cooperation2021,2005,1
University of Massachusetts Medical School,Cooperation2021,2005,1
University of Massachusetts Medical School,Religion2020,2005,1
University of Massachusetts Memorial Medical Center,Religion2020,2005,21
"University of Massachusetts, Boston",Cooperation2021,2005,2
"University of Massachusetts, Boston",Religion2020,2005,1
University of Medicine and Dentistry of New Jersey,Cooperation2021,2005,1
University of Melbourne,Cooperation2021,2005,21
University of Melbourne,Religion2020,2005,13
University of Memphis,Cooperation2021,2005,1
University of Memphis,Religion2020,2005,6
University of Miami,Cooperation2021,2005,14
University of Miami,CooperationAndReligion,2005,4
University of Miami,Religion2020,2005,44
University of Michigan,Cooperation2021,2005,39
University of Michigan,CooperationAndReligion,2005,2
University of Michigan,Religion2020,2005,139
University of Michigan Medical School,Religion2020,2005,2
"University of Michigan, Ann Arbor",Religion2020,2005,4
"University of Michigan, Dearborn",Religion2020,2005,3
University of Milan,Cooperation2021,2005,4
University of Milan - Bicocca,Cooperation2021,2005,6
University of Minho,Cooperation2021,2005,17
University of Minnesota,Cooperation2021,2005,21
University of Minnesota,CooperationAndReligion,2005,1
University of Minnesota,Religion2020,2005,43
University of Mississippi,Religion2020,2005,11
University of Mississippi Medical Center,Religion2020,2005,2
University of Missouri,Cooperation2021,2005,8
University of Missouri,Religion2020,2005,19
University of Missouri Mizzou,Religion2020,2005,12
University of Missouri-Kansas City,Religion2020,2005,18
University of Missouri-St. Louis,Religion2020,2005,2
University of Modena and Reggio Emilia,Cooperation2021,2005,3
University of Modena and Reggio Emilia,Religion2020,2005,3
University of Montpellier,Cooperation2021,2005,1
University of Montreal,Cooperation2021,2005,6
University of Montreal,Religion2020,2005,62
University of Muenster,Religion2020,2005,6
University of Munich,Religion2020,2005,14
University of Munster,Religion2020,2005,3
University of Münster,Cooperation2021,2005,1
University of Münster,Religion2020,2005,8
University of Murcia,Cooperation2021,2005,7
University of Murcia,CooperationAndReligion,2005,7
University of Murcia,Religion2020,2005,7
University of Namur,Cooperation2021,2005,1
University of Namur,Religion2020,2005,1
University of Nantes,Religion2020,2005,2
University of Navarra,Religion2020,2005,1
University of Nebraska,Cooperation2021,2005,2
University of Nebraska,Religion2020,2005,8
"University of Nebraska, Lincoln",Religion2020,2005,6
"University of Nebraska, Omaha",Religion2020,2005,1
University of Nebraska-Lincoln,Cooperation2021,2005,3
University of Nebraska-Lincoln,Religion2020,2005,2
University of Neuchâtel,Cooperation2021,2005,4
University of Neuchâtel,Religion2020,2005,1
University of Nevada,Religion2020,2005,1
University of Nevada - Reno,Cooperation2021,2005,1
University of Nevada - Reno,CooperationAndReligion,2005,1
University of Nevada - Reno,Religion2020,2005,28
"University of Nevada, Las Vegas",Cooperation2021,2005,4
"University of Nevada, Las Vegas",Religion2020,2005,12
University of New Brunswick,Cooperation2021,2005,2
University of New Brunswick,Religion2020,2005,8
University of New England,Cooperation2021,2005,7
University of New England,Religion2020,2005,5
University of New Hampshire,Religion2020,2005,15
University of New Mexico,Cooperation2021,2005,6
University of New Mexico,Religion2020,2005,34
University of New South Wales,Cooperation2021,2005,7
University of New South Wales,Religion2020,2005,14
University of Newcastle,Cooperation2021,2005,4
University of Newcastle,Religion2020,2005,27
University of Nice,Cooperation2021,2005,1
University of Nigeria,Religion2020,2005,3
University of Niigata Prefecture,Cooperation2021,2005,2
University of Niigata Prefecture,Religion2020,2005,3
University of Nijmegen,Religion2020,2005,9
University of North Carolina,Cooperation2021,2005,14
University of North Carolina,Religion2020,2005,7
University of North Carolina - Chapel Hill,Religion2020,2005,4
University of North Carolina at Chapel Hill,Cooperation2021,2005,8
University of North Carolina at Chapel Hill,Religion2020,2005,61
University of North Carolina at Charlotte,Cooperation2021,2005,3
University of North Carolina at Charlotte,CooperationAndReligion,2005,3
University of North Carolina at Charlotte,Religion2020,2005,21
University of North Carolina at Greensboro,Religion2020,2005,9
University of North Carolina at Wilmington,Cooperation2021,2005,1
University of North Carolina at Wilmington,Religion2020,2005,6
University of North Dakota,Religion2020,2005,7
University of North Florida,Religion2020,2005,2
University of North Texas,Cooperation2021,2005,2
University of North Texas,Religion2020,2005,17
University of Northampton,Religion2020,2005,3
University of Northern Colorado,Religion2020,2005,3
University of Notre Dame,Cooperation2021,2005,1
University of Notre Dame,CooperationAndReligion,2005,1
University of Notre Dame,Religion2020,2005,147
University of Notre Dame Australia,Cooperation2021,2005,1
University of Notre Dame Australia,Religion2020,2005,5
University of Notre Dame- Australia,Religion2020,2005,10
University of Nottingham,Cooperation2021,2005,11
University of Nottingham,Religion2020,2005,31
University of Oklahoma,Cooperation2021,2005,1
University of Oklahoma,Religion2020,2005,10
University of Oldenburg,Religion2020,2005,3
University of Oran,Religion2020,2005,3
University of Oregon,Cooperation2021,2005,8
University of Oregon,CooperationAndReligion,2005,1
University of Oregon,Religion2020,2005,12
University of Oslo,Cooperation2021,2005,3
University of Oslo,Religion2020,2005,18
University of Osnabruck,Religion2020,2005,2
University of Otago,Cooperation2021,2005,4
University of Otago,CooperationAndReligion,2005,1
University of Otago,Religion2020,2005,26
University of Ottawa,Cooperation2021,2005,12
University of Ottawa,Religion2020,2005,38
University of Oulu,Cooperation2021,2005,3
University of Oulu,Religion2020,2005,1
University of Oxford,Cooperation2021,2005,75
University of Oxford,CooperationAndReligion,2005,2
University of Oxford,Religion2020,2005,202
University of Oxford Mindfulness Research Centre,Religion2020,2005,6
"University of Oxford, Champion Hall",Religion2020,2005,4
University of Paderborn,Religion2020,2005,8
University of Padova,Cooperation2021,2005,3
University of Padova,Religion2020,2005,1
University of Padua,Cooperation2021,2005,3
University of Padua,Religion2020,2005,18
University of Paris,Cooperation2021,2005,1
University of Paris,CooperationAndReligion,2005,1
University of Paris,Religion2020,2005,14
University of Paris Ouest,Cooperation2021,2005,1
University of Paris Ouest,Religion2020,2005,2
University of Paris VIII,Religion2020,2005,13
University of Parma,Cooperation2021,2005,1
University of Passau,Religion2020,2005,1
University of Pavia,Cooperation2021,2005,2
University of Pécs,Cooperation2021,2005,1
University of Pennsylvania,Cooperation2021,2005,23
University of Pennsylvania,CooperationAndReligion,2005,2
University of Pennsylvania,Religion2020,2005,74
University of Pennsylvania Program for Mindfulness,Religion2020,2005,1
University of Pisa,Cooperation2021,2005,4
University of Pittsburgh,Cooperation2021,2005,14
University of Pittsburgh,Religion2020,2005,46
University of Pittsburgh Cancer Institute,Religion2020,2005,2
University of Plymouth,Cooperation2021,2005,3
University of Plymouth,Religion2020,2005,4
University of Porto,Cooperation2021,2005,1
University of Porto,CooperationAndReligion,2005,1
University of Porto,Religion2020,2005,7
University of Portsmouth,Cooperation2021,2005,2
University of Portsmouth,Religion2020,2005,8
University of Potsdam,Religion2020,2005,2
University of Prešov,Religion2020,2005,2
University of Pretoria,Cooperation2021,2005,1
University of Pretoria,Religion2020,2005,69
University of Prince Edward Island,Religion2020,2005,1
University of Québec at Montreal,Religion2020,2005,8
University of Quebec at Trois-Rivières,Religion2020,2005,1
University of Quebec in Chicoutimi,Religion2020,2005,1
University of Quebec in Montreal,Religion2020,2005,2
University of Queensland,Cooperation2021,2005,11
University of Queensland,Religion2020,2005,30
"University of Rajshahi, Bangladesh",Religion2020,2005,3
University of Reading,Religion2020,2005,8
University of Redlands,Religion2020,2005,7
University of Regensburg,Cooperation2021,2005,6
University of Regensburg,Religion2020,2005,6
University of Regina,Religion2020,2005,4
University of Rhode Island,Religion2020,2005,1
University of Richmond,Cooperation2021,2005,2
University of Richmond,Religion2020,2005,8
University of Rijeka,Religion2020,2005,1
University of Rochester,Religion2020,2005,8
University of Roehampton,Religion2020,2005,8
University of Rome,Cooperation2021,2005,3
University of Rome,Religion2020,2005,5
University of Rostock,Cooperation2021,2005,4
University of Rostock,Religion2020,2005,2
University of Rotterdam,Cooperation2021,2005,4
University of Rotterdam,Religion2020,2005,1
University of Saint Mary of the Lake,Religion2020,2005,8
University of San Diego,Religion2020,2005,20
University of San Francisco,Religion2020,2005,7
university of san martin,Religion2020,2005,3
University of Santiago,Religion2020,2005,1
University of Santo Tomas,Religion2020,2005,4
University of Sao Paulo,Cooperation2021,2005,1
University of Sao Paulo,Religion2020,2005,1
University of São Paulo,Religion2020,2005,10
University of Saskatchewan,Cooperation2021,2005,9
University of Saskatchewan,CooperationAndReligion,2005,1
University of Saskatchewan,Religion2020,2005,16
University of Sassari,Cooperation2021,2005,1
University of Sau Paulo,Religion2020,2005,1
University of Science and Technology,Cooperation2021,2005,8
University of Science and Technology of China,Cooperation2021,2005,3
University of Scranton,Religion2020,2005,9
University of Shanghai,Religion2020,2005,2
University of Sheffield,Cooperation2021,2005,7
University of Sheffield,Religion2020,2005,8
University of Sherbrooke,Cooperation2021,2005,1
University of Sherbrooke,Religion2020,2005,11
University of Shiraz,Religion2020,2005,1
University of Siegen,Religion2020,2005,7
University of Siena,Cooperation2021,2005,15
University of Siena,Religion2020,2005,1
University of Sindh,Cooperation2021,2005,1
University of Sindh,Religion2020,2005,1
University of South Africa,Cooperation2021,2005,1
University of South Africa,Religion2020,2005,29
University of South Australia,Religion2020,2005,2
University of South Carolina,Cooperation2021,2005,7
University of South Carolina,Religion2020,2005,30
University of South Dakota,Religion2020,2005,7
University of South Florida,Religion2020,2005,12
University of South Florida - Saint Petersburg,Religion2020,2005,8
University of South Wales,Religion2020,2005,2
University of Southampton,Cooperation2021,2005,8
University of Southampton,Religion2020,2005,12
University of South-Eastern Norway,Cooperation2021,2005,1
University of Southern California,Cooperation2021,2005,7
University of Southern California,Religion2020,2005,44
University of Southern Denmark,Cooperation2021,2005,3
University of Southern Denmark,Religion2020,2005,18
University of Southern Mississippi,Religion2020,2005,2
University of Southern Queensland,Religion2020,2005,1
University of Split,Religion2020,2005,2
University of St Andrews,Cooperation2021,2005,31
University of St Andrews,CooperationAndReligion,2005,1
University of St Andrews,Religion2020,2005,42
University of St Michaels,Religion2020,2005,5
University of St. Gallen,Religion2020,2005,3
University of St. Thomas,Religion2020,2005,39
University of Stavanger,Cooperation2021,2005,5
University of Stavanger,Religion2020,2005,2
University of Stellenbosch,Cooperation2021,2005,1
University of Stellenbosch,Religion2020,2005,16
University of Stirling,Cooperation2021,2005,2
University of Stirling,CooperationAndReligion,2005,1
University of Stirling,Religion2020,2005,26
University of Strasbourg,Cooperation2021,2005,3
University of Strasbourg,Religion2020,2005,14
University of Strathclyde,Cooperation2021,2005,1
University of Strathclyde,Religion2020,2005,5
University of Sunderland,Cooperation2021,2005,1
University of Sunderland,Religion2020,2005,1
University of Surrey,Cooperation2021,2005,1
University of Surrey,Religion2020,2005,4
University of Sussex,Cooperation2021,2005,13
University of Sussex,Religion2020,2005,20
University of Sydney,Cooperation2021,2005,23
University of Sydney,Religion2020,2005,70
University of Szeged,Religion2020,2005,2
University of Tampa,Religion2020,2005,4
University of Tampere,Cooperation2021,2005,1
University of Tampere,Religion2020,2005,1
University of Tartu,Cooperation2021,2005,1
University of Tartu,Religion2020,2005,11
University of Tasmania,Religion2020,2005,7
University of Technology Sydney,Cooperation2021,2005,6
University of Technology Sydney,CooperationAndReligion,2005,2
University of Technology Sydney,Religion2020,2005,13
University of Teesside,Cooperation2021,2005,3
University of Teesside,Religion2020,2005,1
University of Tehran,Cooperation2021,2005,1
University of Tehran,Religion2020,2005,7
University of Tennessee,Cooperation2021,2005,2
University of Tennessee,Religion2020,2005,24
University of Tennessee - Knoxville,Cooperation2021,2005,1
University of Tennessee - Knoxville,Religion2020,2005,4
University of Tennessee at Chattanooga,Cooperation2021,2005,1
University of Tennessee at Chattanooga,Religion2020,2005,7
University of Texas at Arlington,Cooperation2021,2005,2
University of Texas at Arlington,Religion2020,2005,5
University of Texas at Austin,Cooperation2021,2005,7
University of Texas at Austin,Religion2020,2005,63
University of Texas at Dallas,Cooperation2021,2005,6
University of Texas at Dallas,Religion2020,2005,8
University of Texas at El Paso,Religion2020,2005,5
University of Texas at Houston,Religion2020,2005,9
University of Texas at San Antonio,Religion2020,2005,52
University of Texas Health Science Center at Houston (UTHealth),Cooperation2021,2005,1
University of Texas Health Science Center at Houston (UTHealth),Religion2020,2005,34
University of Texas MD Anderson Cancer Center,Cooperation2021,2005,2
University of Texas MD Anderson Cancer Center,Religion2020,2005,11
University of Texas Medical Branch,Religion2020,2005,3
University of Texas Medical Branch at Galveston,Religion2020,2005,4
University of Texas Pan American,Religion2020,2005,8
University of Texas Rio Grande Valley,Religion2020,2005,7
University of Texas School of Education,Religion2020,2005,1
University of Texas School of Public Health,Religion2020,2005,21
University of Texas Southwestern Medical Center,Cooperation2021,2005,3
"University of Texas, Southwestern Medical Center",Religion2020,2005,4
University of the Basque Country,Cooperation2021,2005,3
University of the Basque Country,Religion2020,2005,1
University of the Free State,Religion2020,2005,8
University of the Highlands and Islands,Religion2020,2005,2
University of the Incarnate Word,Religion2020,2005,6
University of the South,Religion2020,2005,3
University of the Sunshine Coast,Religion2020,2005,1
University of the West of England,Cooperation2021,2005,1
University of the West of England,Religion2020,2005,9
University of the Western Cape,Religion2020,2005,6
University of Thessaly,Cooperation2021,2005,3
University of Thessaly,Religion2020,2005,1
University of Tilburg,Religion2020,2005,5
University of Tokyo,Cooperation2021,2005,21
University of Tokyo,Religion2020,2005,14
University of Toronto,Cooperation2021,2005,21
University of Toronto,Religion2020,2005,133
University of Toronto Scarborough,Religion2020,2005,1
University of Toulouse,Cooperation2021,2005,1
University of Toulouse,CooperationAndReligion,2005,1
University of Toulouse,Religion2020,2005,3
University of Tours,Cooperation2021,2005,1
University of Trento,Cooperation2021,2005,8
University of Trento,Religion2020,2005,2
University of Trier,Cooperation2021,2005,1
University of Trier,Religion2020,2005,2
University of Tromsø,Religion2020,2005,1
University of Tsukuba,Religion2020,2005,1
University of Tubigen Germany,Cooperation2021,2005,3
University of Tubigen Germany,Religion2020,2005,7
University of Tubingen,Cooperation2021,2005,4
University of Tubingen,Religion2020,2005,2
University of Tübingen,Cooperation2021,2005,7
University of Tübingen,Religion2020,2005,31
University of Tulsa,Cooperation2021,2005,6
University of Tulsa,Religion2020,2005,7
University of Turin,Religion2020,2005,6
University of Turku,Cooperation2021,2005,1
University of Turku,Religion2020,2005,9
University of Tuzla,Cooperation2021,2005,1
University of Tuzla,CooperationAndReligion,2005,1
University of Tuzla,Religion2020,2005,5
University of Ulm,Religion2020,2005,4
University of Ulster,Religion2020,2005,5
University of Utah,Cooperation2021,2005,2
University of Utah,Religion2020,2005,74
University of Utrecht,Cooperation2021,2005,8
University of Utrecht,Religion2020,2005,8
University of Vermont,Cooperation2021,2005,2
University of Vermont,Religion2020,2005,8
University of Verona,Cooperation2021,2005,6
University of Victoria,Cooperation2021,2005,4
University of Victoria,Religion2020,2005,25
University of Vienna,Cooperation2021,2005,36
University of Vienna,Religion2020,2005,36
University of Vigo,Cooperation2021,2005,2
University of Virginia,Cooperation2021,2005,10
University of Virginia,Religion2020,2005,60
University of Waikato,Cooperation2021,2005,2
University of Waikato,Religion2020,2005,5
University of Wales,Cooperation2021,2005,1
University of Wales,Religion2020,2005,19
University of Wales at Lampeter,Religion2020,2005,7
University of Wales Trinity Saint David,Religion2020,2005,6
University of Warsaw,Cooperation2021,2005,3
University of Warsaw,Religion2020,2005,3
University of Warwick,Cooperation2021,2005,19
University of Warwick,Religion2020,2005,69
University of Washington,Cooperation2021,2005,23
University of Washington,Religion2020,2005,63
University of Washington at Seattle,Religion2020,2005,5
University of Washington School of Medicine,Religion2020,2005,2
University of Waterloo,Cooperation2021,2005,10
University of Waterloo,Religion2020,2005,15
University of West Florida,Religion2020,2005,1
University of West Georgia,Religion2020,2005,9
University of Western Australia,Religion2020,2005,9
University of Western Cape,Religion2020,2005,6
University of Western Ontario,Cooperation2021,2005,7
University of Western Ontario,Religion2020,2005,3
University of Western Sydney,Religion2020,2005,4
University of Westminster,Cooperation2021,2005,3
University of Westminster,Religion2020,2005,7
University of Winchester,Religion2020,2005,26
University of Windsor,Cooperation2021,2005,4
University of Windsor,Religion2020,2005,15
University of Winnipeg,Religion2020,2005,1
University of Wisconsin,Cooperation2021,2005,1
University of Wisconsin,Religion2020,2005,25
University of Wisconsin - Eau Claire,Religion2020,2005,7
University of Wisconsin - Madison,Cooperation2021,2005,8
University of Wisconsin - Madison,Religion2020,2005,24
University of Wisconsin–Eau Claire,Religion2020,2005,3
University of Wisconsin-Madison,Cooperation2021,2005,2
University of Wisconsin-Madison,Religion2020,2005,19
University of Wisconsin-Milwaukee,Religion2020,2005,8
University of Witten/Herdecke,Religion2020,2005,45
University of Witwatersrand,Cooperation2021,2005,3
University of Witwatersrand,Religion2020,2005,2
University of Wollongong,Religion2020,2005,16
University of Wolverhampton,Cooperation2021,2005,2
University of Wolverhampton,Religion2020,2005,6
University of Worcester,Religion2020,2005,6
University of Würzburg,Cooperation2021,2005,1
University of Würzburg,Religion2020,2005,7
University of Wyoming,Cooperation2021,2005,5
University of Wyoming,Religion2020,2005,3
University of York,Cooperation2021,2005,3
University of York,CooperationAndReligion,2005,1
University of York,Religion2020,2005,16
University of Zagreb,Religion2020,2005,8
University of Zambia,Religion2020,2005,5
University of Zaragoza,Cooperation2021,2005,13
University of Zenica,Cooperation2021,2005,1
University of Zenica,CooperationAndReligion,2005,1
University of Zenica,Religion2020,2005,2
University of Zimbabwe,Cooperation2021,2005,1
University of Zimbabwe,Religion2020,2005,5
University of Zurich,Cooperation2021,2005,28
University of Zurich,Religion2020,2005,23
University Psychiatric Center KU Leuven,Cooperation2021,2005,1
University Ulm,Religion2020,2005,2
"UNSW, Sydney",Cooperation2021,2005,1
UPMC Presbyterian,Religion2020,2005,2
Uppsala University,Cooperation2021,2005,11
Uppsala University,Religion2020,2005,19
Urbino University,Religion2020,2005,4
Ursinus College,Religion2020,2005,3
US Army,Religion2020,2005,1
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Religion2020,2005,2
Utah State University,Cooperation2021,2005,1
Utah State University,Religion2020,2005,5
Utah Valley University,Religion2020,2005,2
Utrecht University,Cooperation2021,2005,7
Utrecht University,Religion2020,2005,54
VA,Religion2020,2005,2
VA Cincinnatti,Religion2020,2005,1
VA Durham,Religion2020,2005,3
VA HSR&D Center for Health Services Research in Primary Care,Religion2020,2005,2
VA Long Beach Healthcare System,Religion2020,2005,3
VA Medical Center- Minneapolis,Religion2020,2005,1
"VA Medical Center, New Orleans",Religion2020,2005,2
VA Minneapolis Medical Center,Religion2020,2005,1
VA Palo Alto Health Care System,Religion2020,2005,3
VA San Diego,Religion2020,2005,6
VA San Diego Healthcare System,Religion2020,2005,9
Valdosta State University,Religion2020,2005,1
Valparaiso University,Religion2020,2005,32
Vancouver CBT Centre,Religion2020,2005,3
Vanderbilt Divinity School,Religion2020,2005,13
Vanderbilt University,Cooperation2021,2005,6
Vanderbilt University,Religion2020,2005,59
Vanguard University,Religion2020,2005,2
Vanung University,Religion2020,2005,1
Vassar College,Cooperation2021,2005,4
Vassar College,Religion2020,2005,8
Vatican Bank,Religion2020,2005,1
Vencore Labs,Cooperation2021,2005,1
Venebio,Religion2020,2005,1
Veracode,Cooperation2021,2005,2
"Versiti, Inc.",Cooperation2021,2005,1
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Cooperation2021,2005,2
Veterans Health Adminstration,Cooperation2021,2005,1
Veterans Health Adminstration,Religion2020,2005,2
Viadrina European University,Cooperation2021,2005,5
Victoria University Melbourne,Cooperation2021,2005,1
Victoria University Melbourne,Religion2020,2005,1
Victoria University of Wellington,Cooperation2021,2005,8
Victoria University of Wellington,Religion2020,2005,5
"Victoria University, Toronto",Religion2020,2005,1
VID Specialized University,Cooperation2021,2005,2
VID Specialized University,Religion2020,2005,2
Vigie de laïcité,Religion2020,2005,3
ViiV Healthcare,Religion2020,2005,8
Villanova University,Religion2020,2005,36
Vilnius University,Religion2020,2005,6
Virgen de la Victoria University Hospital,Religion2020,2005,1
Virginia Commonwealth University,Cooperation2021,2005,2
Virginia Commonwealth University,CooperationAndReligion,2005,1
Virginia Commonwealth University,Religion2020,2005,27
Virginia Department of Health,Religion2020,2005,6
Virginia Military Institute,Religion2020,2005,1
Virginia Polytechnic Institute and State University,Cooperation2021,2005,6
Virginia Polytechnic Institute and State University,Religion2020,2005,5
Virginia Tech,Cooperation2021,2005,4
Virginia Tech,Religion2020,2005,9
Virginia Theological Seminary,Religion2020,2005,10
Virginia Wesleyan College,Religion2020,2005,7
Virtual Scientific Laboratories,Cooperation2021,2005,6
Vision of Hope Baptist Church,Religion2020,2005,1
Vision2Health BV,Cooperation2021,2005,2
Vision2Health BV,Religion2020,2005,2
Vital Research,Religion2020,2005,3
Vrije Universiteit Amsterdam,Cooperation2021,2005,33
Vrije Universiteit Amsterdam,Religion2020,2005,90
Vrije Universiteit Brussel,Cooperation2021,2005,14
Vrije Universiteit Brussel,Religion2020,2005,7
Vrije Universiteit Medical Center,Religion2020,2005,8
Vrije University Brussels,Cooperation2021,2005,1
VYASA University,Religion2020,2005,3
Vytautus Magnus University,Religion2020,2005,3
Wabash College,Religion2020,2005,20
Wadham College,Religion2020,2005,5
Wageningen University,Cooperation2021,2005,8
Wageningen University,Religion2020,2005,5
Wake Forest,Religion2020,2005,4
Wake Forest Baptist Health,Religion2020,2005,8
Wake Forest University,Religion2020,2005,22
Wakefield Diocese,Religion2020,2005,3
Walden University,Religion2020,2005,8
Walter and Eliza Hall Research Institute,Cooperation2021,2005,2
Walter Reed Army Institute of Research,Cooperation2021,2005,2
Warburg Institute,Religion2020,2005,7
Wartburg College,Religion2020,2005,1
Wartburg Theological Seminary,Religion2020,2005,4
Warwick University,Cooperation2021,2005,4
Warwick University,Religion2020,2005,3
Waseda University,Cooperation2021,2005,9
Waseda University,Religion2020,2005,5
Washington and Lee University,Religion2020,2005,1
Washington State University,Cooperation2021,2005,5
Washington State University,Religion2020,2005,5
Washington University in St Louis,Cooperation2021,2005,16
Washington University in St Louis,Religion2020,2005,22
Wasiwaska,Religion2020,2005,1
Waterloo Lutheran Seminary,Religion2020,2005,4
Wayne State University,Cooperation2021,2005,2
Wayne State University,Religion2020,2005,10
We Honor Veterans,Religion2020,2005,1
Weill Cornell Medical College,Religion2020,2005,3
Weill Cornell Medical College in Qatar,Religion2020,2005,2
Weill Medical College of Cornell University,Religion2020,2005,2
Wellcome Sanger Institute,Religion2020,2005,3
Wellesley College,Cooperation2021,2005,1
Wellesley College,Religion2020,2005,19
Wenzhou University,Cooperation2021,2005,2
Wesley Theological Seminary,Religion2020,2005,2
West Chester University of Pennsylvania,Religion2020,2005,3
West Virginia University,Cooperation2021,2005,4
West Virginia University,Religion2020,2005,21
Western Carolina University,Religion2020,2005,5
Western Galilee College,Religion2020,2005,2
Western Illinois University,Religion2020,2005,1
Western Michigan University,Religion2020,2005,13
Western Sydney University,Religion2020,2005,15
Western University,Cooperation2021,2005,13
Western University,Religion2020,2005,5
Western University of Health Sciences,Religion2020,2005,1
Western Washington University,Cooperation2021,2005,1
Western Washington University,CooperationAndReligion,2005,1
Western Washington University,Religion2020,2005,2
Westfalische University,Religion2020,2005,2
Westminster College,Religion2020,2005,6
Westminster Seminary California,Religion2020,2005,2
Westmont College,Religion2020,2005,6
Westphalian Wilhelms University,Religion2020,2005,4
Wheaton College,Cooperation2021,2005,2
Wheaton College,Religion2020,2005,39
Whitley College,Religion2020,2005,1
Whittier College,Religion2020,2005,3
WHO collaborating centre for research and training in mental health,Religion2020,2005,2
Wichita State University,Religion2020,2005,4
Wildhouse Publications,Religion2020,2005,3
Wilfrid Laurier University,Cooperation2021,2005,10
Wilfrid Laurier University,CooperationAndReligion,2005,1
Wilfrid Laurier University,Religion2020,2005,20
Willamette University,Cooperation2021,2005,4
Willamette University,Religion2020,2005,8
William & Mary,Cooperation2021,2005,3
William & Mary,CooperationAndReligion,2005,1
William & Mary,Religion2020,2005,15
William Carey International University,Religion2020,2005,3
William James College,Religion2020,2005,1
William Paterson University,Religion2020,2005,4
Williams College,Religion2020,2005,1
Winona State University,Cooperation2021,2005,1
Winona State University,CooperationAndReligion,2005,1
Winona State University,Religion2020,2005,4
Wistar Institute,Religion2020,2005,3
Witten/Herdecke University,Religion2020,2005,15
Wittgenstein Centre for Demography and Global Human Capital  Demography and Global Human Capital,Religion2020,2005,1
Wolfson College,Religion2020,2005,2
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,2005,3
Women's College Research Institute,Religion2020,2005,2
Woodbrooke Quaker Study Centre,Religion2020,2005,1
Woodbury University,Religion2020,2005,1
Woolf Institute,Religion2020,2005,2
worcester polytechnic institute,Religion2020,2005,1
World Council of Churches,Religion2020,2005,1
World Health Organisation,Religion2020,2005,3
World Organisation for Animal Health,Cooperation2021,2005,5
Wrexham Glyndwr University,Cooperation2021,2005,1
Wrexham Glyndwr University,Religion2020,2005,12
Wright State University,Religion2020,2005,2
Wuhan University,Cooperation2021,2005,1
Wuhan University,Religion2020,2005,3
Xavier University,Religion2020,2005,5
Xi’an Jiaotong University,Cooperation2021,2005,2
Xi'an JiaoTong University,Cooperation2021,2005,1
Xidian University,Cooperation2021,2005,10
Yale Divinity School,Religion2020,2005,21
Yale University,Cooperation2021,2005,23
Yale University,CooperationAndReligion,2005,4
Yale University,Religion2020,2005,79
Yale University Mind and Development Lab,Cooperation2021,2005,3
Yale University Mind and Development Lab,Religion2020,2005,16
Yale University School of Medicine,Religion2020,2005,5
Yale-NUS College,Religion2020,2005,4
Yamaguchi Prefectural University,Religion2020,2005,2
Yeshiva University,Religion2020,2005,9
Yeshivah of Flatbush High School,Religion2020,2005,1
Yeungnam University,Religion2020,2005,1
Yezreel Valley Academic College,Religion2020,2005,2
yoga for arthritis,Religion2020,2005,1
Yonsei University,Cooperation2021,2005,3
Yonsei University,CooperationAndReligion,2005,1
Yonsei University,Religion2020,2005,7
York College of Pennsylvania,Religion2020,2005,3
York St John University,Religion2020,2005,11
York University,Cooperation2021,2005,1
York University,Religion2020,2005,26
Yorkshire County Cricket club and Aston Villa,Religion2020,2005,2
Youngstown State University,Religion2020,2005,2
Yuhing Junior College of Health Care and Management,Religion2020,2005,1
Yuzuncu Yil University,Religion2020,2005,1
ZEW - Leibniz Centre for European Economic Research,Cooperation2021,2005,2
Zhejiang University,Cooperation2021,2005,11
Zhejiang University,Religion2020,2005,1
Zhejiang University of Finance and Economics,Cooperation2021,2005,2
Ziauddin University,Cooperation2021,2005,1
Ziauddin University,Religion2020,2005,1
 École Pratique des Hautes Études,Religion2020,2000,6
 H.L. Moffitt Cancer Center and Research Institute,Religion2020,2000,1
 I. M. Sechenov First Moscow State Medical University.,Religion2020,2000,1
 University of the District of Columbia,Religion2020,2000,3
 Xi’an Jiaotong University,Cooperation2021,2000,5
Aahrus University,Religion2020,2000,1
Aalto University School of Science and Technology,Cooperation2021,2000,1
Aarhus University,Cooperation2021,2000,7
Aarhus University,Religion2020,2000,21
Abbott Northwestern Hospital,Religion2020,2000,1
Aberdeen University,Religion2020,2000,2
Aberystwyth University,Religion2020,2000,2
Abilene Christian University,Religion2020,2000,7
abo akademi university,Religion2020,2000,1
Åbo Akademi University,Religion2020,2000,1
Academy of Sciences of Albania,Religion2020,2000,5
Achva Academic College,Cooperation2021,2000,1
Achva Academic College,Religion2020,2000,3
Adelphi University,Cooperation2021,2000,4
Adnan Menderes University,Religion2020,2000,1
AETHOS Consulting Group,Religion2020,2000,4
Aga Khan University,Cooperation2021,2000,2
Aga Khan University,CooperationAndReligion,2000,2
Aga Khan University,Religion2020,2000,4
Agricultural Institute,Cooperation2021,2000,1
Aix-Marseille Université,Cooperation2021,2000,2
Aix-Marseille Université,Religion2020,2000,4
Akrofi-Christaller Institute for Theology,Religion2020,2000,3
Akron General Medical Center,Religion2020,2000,12
Albany Law School,Religion2020,2000,1
Albert Einstein College of Medicine,Cooperation2021,2000,4
Albert Einstein College of Medicine,Religion2020,2000,5
Alberta Health Services,Religion2020,2000,4
Alcohol Research Group,Religion2020,2000,2
Alfaisal University,Cooperation2021,2000,1
Alfred University,Religion2020,2000,2
Aliz Health Apps LLC,Religion2020,2000,1
All India Institute of Medical Sciences,Religion2020,2000,1
Allegheny College,Religion2020,2000,1
Alliant International University,Religion2020,2000,2
Allina Health,Religion2020,2000,2
Alma College,Religion2020,2000,1
Alphacrucis College,Religion2020,2000,4
american academy of neurology,Religion2020,2000,1
American Bible Society,Religion2020,2000,1
American Council on Education,Religion2020,2000,1
American Enterprise Institute,Religion2020,2000,2
American Geographical Society,Religion2020,2000,1
American Jewish University,Religion2020,2000,1
American Medical Association,Religion2020,2000,24
American Museum of Natural History,Religion2020,2000,1
American Psychological Association,Religion2020,2000,2
American Society of Anesthesiologists,Religion2020,2000,8
American University,Religion2020,2000,15
American University of Beirut,Religion2020,2000,1
American University of Kuwait,Religion2020,2000,2
Amherst College,Religion2020,2000,2
amity university,Religion2020,2000,1
Amsterdam UMC,Religion2020,2000,3
Analytical Centre of the Russian Academy of Sciences,Religion2020,2000,6
Andover Newton Theological School,Religion2020,2000,5
Andrews University,Religion2020,2000,6
Anglia Ruskin University,Religion2020,2000,2
Anglo American University,Religion2020,2000,2
Anhui University of Technology,Cooperation2021,2000,1
Annals of Internal Medicine,Religion2020,2000,1
Anthony Nolan Research Institute,Cooperation2021,2000,1
Aoyama Gakuin University,Cooperation2021,2000,2
Apex School of Theology,Religion2020,2000,2
Appalachian State University,Cooperation2021,2000,5
Appalachian State University,Religion2020,2000,3
Arden University,Cooperation2021,2000,2
Argentine Catholic University,Religion2020,2000,4
Ariel University,Religion2020,2000,3
Arizona State University,Cooperation2021,2000,25
Arizona State University,Religion2020,2000,51
ARTIS International,Cooperation2021,2000,1
ARTIS International,Religion2020,2000,6
Asahikawa Medical University,Cooperation2021,2000,1
Asbury Theological Seminary,Religion2020,2000,1
Ascension Health,Religion2020,2000,2
Asia Pacific University of Technology and Innovation,Cooperation2021,2000,1
Asia University,Religion2020,2000,1
ASM Affiliates Inc,Religion2020,2000,2
Association of Improvement for Mental Health Programmes,Religion2020,2000,1
AstraZeneca,Cooperation2021,2000,3
Atlantic School of Theology,Religion2020,2000,1
Auburn University,Religion2020,2000,2
Auckland University of Technology,Religion2020,2000,5
Augusta State College,Religion2020,2000,1
Augusta University,Religion2020,2000,4
Augustana College,Religion2020,2000,3
Augustana University,Religion2020,2000,10
Austin Presbyterian Theological Seminary,Cooperation2021,2000,1
Austin Presbyterian Theological Seminary,Religion2020,2000,4
Australian Catholic University,Cooperation2021,2000,4
Australian Catholic University,Religion2020,2000,30
Australian National University,Cooperation2021,2000,5
Australian National University,Religion2020,2000,11
Australian Research Center for Complementary & Integrative Medicine [ARCCIM],Religion2020,2000,1
Austrian Academy of Sciences,Religion2020,2000,1
Ave Maria University,Religion2020,2000,1
Avila University,Religion2020,2000,2
Azusa Pacific University,Religion2020,2000,2
Ball State University,Religion2020,2000,1
Bangor University,Religion2020,2000,5
Baptist Seminary of Kentucky,Religion2020,2000,3
Bar Ilan University,Cooperation2021,2000,3
Bar Ilan University,Religion2020,2000,15
Bard College,Religion2020,2000,23
Bar-Ilan University,Cooperation2021,2000,5
Bar-Ilan University,CooperationAndReligion,2000,1
Bar-Ilan University,Religion2020,2000,37
Barnabas Health Jersey City Medical Center,Religion2020,2000,2
Barnard College,Religion2020,2000,11
Barrington College,Religion2020,2000,1
Barrow Neurological Institute,Religion2020,2000,5
Barry University,Religion2020,2000,6
Bashkir State University,Religion2020,2000,17
Bastyr University,Religion2020,2000,2
Bates College,Religion2020,2000,1
Bath Spa University,Religion2020,2000,7
Baycrest Geriatric Centre,Religion2020,2000,2
Baylor College of Medicine,Cooperation2021,2000,3
Baylor College of Medicine,Religion2020,2000,16
Baylor University,Cooperation2021,2000,5
Baylor University,CooperationAndReligion,2000,1
Baylor University,Religion2020,2000,82
Baystate Medical Center,Religion2020,2000,1
BC Centre for Palliative Care,Religion2020,2000,4
Beijing Foreign Studies University,Cooperation2021,2000,1
"Beijing Hospital of Traditional Medicine, Capital Medical Univeristy",Cooperation2021,2000,1
Beijing Institute of Technology,Religion2020,2000,1
Beijing Normal University,Cooperation2021,2000,1
Beijing Normal University,Religion2020,2000,3
Belaire Counseling Services,Religion2020,2000,4
Bellvitge University Hospital,Religion2020,2000,1
Bemidji State University,Religion2020,2000,1
Ben Gurion University in the Negev,Religion2020,2000,4
Ben-Gurion University of the Negev,Cooperation2021,2000,2
Ben-Gurion University of the Negev,CooperationAndReligion,2000,1
Ben-Gurion University of the Negev,Religion2020,2000,17
Berkshire Publishing Group,Cooperation2021,2000,2
Berkshire Publishing Group,Religion2020,2000,5
Berry College,Religion2020,2000,2
Beth Israel Deaconess Medical Center,Religion2020,2000,3
Bethel College,Religion2020,2000,3
Bethel University,Religion2020,2000,3
Bethlem Royal Hospital,Religion2020,2000,6
Bielefeld University,Religion2020,2000,1
Binghampton University,Cooperation2021,2000,1
Biola University,Cooperation2021,2000,1
Biola University,Religion2020,2000,18
Birbeck University of London,Religion2020,2000,2
Birmingham and Solihull Mental Health NHS Foundation Trust,Cooperation2021,2000,1
Birmingham and Solihull Mental Health NHS Foundation Trust,Religion2020,2000,3
Birmingham City University,Religion2020,2000,2
Birmingham University,Religion2020,2000,2
Bishop Grosseteste University,Religion2020,2000,3
Bishop of Guilford,Religion2020,2000,2
Blackfox Philanthropy,Religion2020,2000,3
Bloomberg LP,Cooperation2021,2000,1
Bloomsburg University,Religion2020,2000,4
Blueprint 1543,Religion2020,2000,7
Bocconi University,Cooperation2021,2000,2
Boğaziçi University,Religion2020,2000,2
Boise State University,Religion2020,2000,3
Boston College,Cooperation2021,2000,2
Boston College,Religion2020,2000,28
Boston University,Cooperation2021,2000,8
Boston University,Religion2020,2000,57
Boston University School of Medicine,Religion2020,2000,1
Bournemouth University,Cooperation2021,2000,2
Bournemouth University,Religion2020,2000,4
Bowdoin College,Religion2020,2000,3
Bowie State University,Religion2020,2000,1
Bowling Green State University,Cooperation2021,2000,6
Bowling Green State University,Religion2020,2000,23
Bradford City Libraries,Religion2020,2000,1
Bradley University,Religion2020,2000,9
Brandeis University,Cooperation2021,2000,1
Brandeis University,Religion2020,2000,18
Bridgewater College,Cooperation2021,2000,3
Brigham & Women's Hospital,Cooperation2021,2000,1
Brigham & Women's Hospital,Religion2020,2000,5
Brigham Young University,Cooperation2021,2000,8
Brigham Young University,Religion2020,2000,61
"Bristol Institute of Technology, UWE",Cooperation2021,2000,4
Brite Divinity,Religion2020,2000,1
British Academy,Religion2020,2000,4
British Journal of Psychiatry,Religion2020,2000,3
British Psychoanalytic Society,Religion2020,2000,1
Brock University,Cooperation2021,2000,2
Brock University,Religion2020,2000,2
Brooklyn College,Religion2020,2000,5
Brooklyn Psychotherapy,Religion2020,2000,2
Brown University,Cooperation2021,2000,9
Brown University,Religion2020,2000,18
Brunel University London,Cooperation2021,2000,3
Brunel University London,Religion2020,2000,8
Bryn Mawr College,Religion2020,2000,2
Buckingham University Centre for Security and Intelligence Studies,Cooperation2021,2000,2
Bucknell University,Cooperation2021,2000,1
Bucknell University,Religion2020,2000,6
Buffalo State College,Religion2020,2000,1
Burlington College,Religion2020,2000,1
Ca' Foscari University,Cooperation2021,2000,3
CableLabs,Cooperation2021,2000,3
Californa Institute of Integral Studies  California Institute of Integral Studies,Religion2020,2000,2
California Institute of Integral Studies,Religion2020,2000,5
California Institute of Technology,Cooperation2021,2000,8
California Institute of Technology,Religion2020,2000,2
California Lutheran University,Religion2020,2000,10
California Pacific Medical Center,Religion2020,2000,9
California State University,Religion2020,2000,5
"California State University, Fullerton",Cooperation2021,2000,3
"California State University, Fullerton",Religion2020,2000,10
"California State University, Long Beach",Religion2020,2000,24
"California State University, Los Angeles",Religion2020,2000,3
"California State University, Northridge",Religion2020,2000,3
"California State University, Sacramento",Cooperation2021,2000,1
"California State University, Sacramento",Religion2020,2000,1
"California State University, Stanislaus",Religion2020,2000,6
Calvin College,Religion2020,2000,10
Calvin University,Cooperation2021,2000,1
Calvin University,Religion2020,2000,7
Cambridge University,Religion2020,2000,17
Canada Research Chairs,Cooperation2021,2000,2
Cancer Prevention Institute of California,Religion2020,2000,2
Cancer Research UK London,Cooperation2021,2000,1
CancerLife,Cooperation2021,2000,1
Candler School of Theology,Religion2020,2000,2
Canterbury Christ Church University,Religion2020,2000,1
Canterbury Christchurch University,Religion2020,2000,5
Cape Breton University,Religion2020,2000,2
Cardiff University,Cooperation2021,2000,4
Cardiff University,Religion2020,2000,24
Cardinal Stritch University,Religion2020,2000,1
Cardozo School of Law,Religion2020,2000,2
Care Dimensions Inc,Religion2020,2000,5
Carleton College,Cooperation2021,2000,3
Carleton College,Religion2020,2000,8
Carleton University,Cooperation2021,2000,2
Carleton University,Religion2020,2000,5
Carnegie Mellon University,Cooperation2021,2000,5
Carnegie Mellon University,Religion2020,2000,3
Carroll College,Religion2020,2000,5
Case Western Reserve University,Cooperation2021,2000,2
Case Western Reserve University,CooperationAndReligion,2000,1
Case Western Reserve University,Religion2020,2000,12
Catholic Ecumenical Institute,Religion2020,2000,2
Catholic Health Association,Religion2020,2000,7
Catholic Research and Development Centre,Religion2020,2000,2
Catholic Theological Union,Religion2020,2000,6
Catholic University of America,Religion2020,2000,25
Catholic University of Eichstätt-Ingolstadt,Religion2020,2000,3
Catholic University of Korea,Cooperation2021,2000,1
Catholic University of Leuven,Religion2020,2000,4
Catholic University of Louvain,Cooperation2021,2000,4
Catholic University of Louvain,Religion2020,2000,2
Catholic University of Lublin,Religion2020,2000,2
Catholic University of the Sacred Heart,Cooperation2021,2000,1
Cedars-Sinai Medical Center,Cooperation2021,2000,4
Cégep Édouard-Montpetit,Religion2020,2000,2
Cell-El Therapeutics LTD,Religion2020,2000,1
Center for Advanced Studies Oslo,Religion2020,2000,1
Center for Applied Research in the Apostolate,Religion2020,2000,4
Center for Change,Religion2020,2000,10
"Center for Holistic Systems Analysis in Fairfield, Iowa",Religion2020,2000,1
Center for Marketing and Consumer Science,Cooperation2021,2000,2
Center for Mind and Culture,Religion2020,2000,1
Center for Mindfulness and Contemplative Studies,Cooperation2021,2000,1
Center For Partnership Studies,Cooperation2021,2000,1
Center for Professional Studies,Religion2020,2000,6
Center for Psychobiology of Personality,Religion2020,2000,1
Center for Psychosocial Research,Religion2020,2000,20
Center for Research and Advanced Studies of the National Polytechnic Institute,Cooperation2021,2000,3
Center for Scientific Research,Religion2020,2000,1
Center for Southeast Asia Studies,Religion2020,2000,3
Center for Studies on New Religions,Religion2020,2000,6
Center for Taiji Studies,Religion2020,2000,1
Center for the Study on New Religions,Religion2020,2000,1
Center for Theological Inquiry,Religion2020,2000,4
Center of Innovation for Complex Chronic Healthcare,Religion2020,2000,3
Center to Advance Palliative Care,Religion2020,2000,2
Centers for Disease Control and Prevention,Religion2020,2000,3
Central Baptist Theological Seminary,Religion2020,2000,4
Central European University,Cooperation2021,2000,1
Central European University,Religion2020,2000,1
Central Michigan University,Religion2020,2000,6
Central Pennsylvania's Community College,Religion2020,2000,2
Central Queensland University,Cooperation2021,2000,1
Central Queensland University,Religion2020,2000,11
Central Research Institute for Physics,Cooperation2021,2000,4
Central Research Institute for Yoga,Religion2020,2000,1
"Central University of Bihar, Patna",Religion2020,2000,1
Central University of Finance and Economics,Cooperation2021,2000,1
Centre for Addiction and Mental Health,Cooperation2021,2000,1
Centre for Addiction and Mental Health,Religion2020,2000,2
Centre for Economic Research and Graduate Education,Cooperation2021,2000,2
Centre for Research and Evidence on Security Threats,Religion2020,2000,1
Centre for Research and Professional Learning- University of Exeter,Religion2020,2000,1
Centre Hospitalier Universitaire Vaudois,Religion2020,2000,2
Centre National de la Recherche Scientifique,Cooperation2021,2000,3
Centre National de la Recherche Scientifique,Religion2020,2000,4
Centre Tricontinental (CETRI),Religion2020,2000,1
Centro Universitário Franciscano,Religion2020,2000,1
CERGE-E,Religion2020,2000,1
CHA University,Religion2020,2000,1
Chang Gung University,Cooperation2021,2000,1
Chang Gung University,Religion2020,2000,3
Chapman University,Cooperation2021,2000,8
Chapman University,Religion2020,2000,3
Charles R. Drew University of Medicine & Science,Religion2020,2000,1
Charles Sturt University,Religion2020,2000,8
Charles University,Religion2020,2000,2
Chelsea Art School,Religion2020,2000,1
Cherry Hill Seminary,Religion2020,2000,1
Cheung Kong Graduate School of Business,Cooperation2021,2000,2
Chiba University,Cooperation2021,2000,1
Chicago Center for Family Health,Religion2020,2000,2
CHILD USA,Religion2020,2000,3
Children’s National Medical Center,Religion2020,2000,1
Children's Diagnostic & Treatment Ctr,Religion2020,2000,1
Children's Hospital of Michigan,Religion2020,2000,1
Children's Hospital of Philadelphia,Religion2020,2000,1
Children's Investment Fund Foundation,Religion2020,2000,11
Chinese Academy of Social Sciences,Religion2020,2000,1
Chinese University of Hong Kong,Cooperation2021,2000,8
Chinese University of Hong Kong,Religion2020,2000,11
Chongqing Institute of Green and Intelligent Technology,Cooperation2021,2000,2
Christ Church College,Religion2020,2000,2
Christian Council on Ageing,Religion2020,2000,1
Christian Psychological Services,Religion2020,2000,2
Christian Service University College,Religion2020,2000,1
Christian Theological Seminary,Religion2020,2000,1
Church of England,Religion2020,2000,5
Church of Northampton,Cooperation2021,2000,1
Church of Sweden,Religion2020,2000,3
Church Research Institute,Cooperation2021,2000,1
Church Research Institute,CooperationAndReligion,2000,1
Church Research Institute,Religion2020,2000,1
CICERO Center for International Climate Research,Cooperation2021,2000,3
CIRANO,Cooperation2021,2000,2
Cisco,Religion2020,2000,1
Citrus College,Religion2020,2000,2
City College of New York- Brooklyn College,Religion2020,2000,1
City College of New York Hunter College,Religion2020,2000,3
City of Hope National Medical Center,Religion2020,2000,5
City University London,Religion2020,2000,1
City University of Hong Kong,Religion2020,2000,5
City University of New York,Cooperation2021,2000,1
City University of New York,Religion2020,2000,11
Claremont Graduate University,Cooperation2021,2000,3
Claremont Graduate University,Religion2020,2000,33
Claremont McKenna College,Religion2020,2000,2
Claremont school of theology,Religion2020,2000,4
Clarian Health Partners,Religion2020,2000,1
Clark University,Religion2020,2000,1
Claude Bernard University Lyon 1,Religion2020,2000,1
Clemson University,Cooperation2021,2000,1
Clemson University,Religion2020,2000,7
Cleveland State University,Cooperation2021,2000,1
Clinical Psychologist,Religion2020,2000,1
Clinical Psychology Doctoral Training Programme,Religion2020,2000,1
Club of Budapest,Religion2020,2000,2
CNRS Comité national de la recherche scientifique,Cooperation2021,2000,5
CNRS Comité national de la recherche scientifique,Religion2020,2000,4
CNRS Délégation Rhône-Auvergne,Cooperation2021,2000,1
Coalition for Compassionate Care of California,Religion2020,2000,1
Colgate University,Religion2020,2000,2
Collaborative Caring,Religion2020,2000,1
"Collaborative for Academic, Social, and Emotional Learning (CASEL)",Religion2020,2000,3
Collège de France,Religion2020,2000,7
College of Charleston,Religion2020,2000,1
College of Charlestown,Religion2020,2000,4
College of Dentistry,Cooperation2021,2000,1
College of France,Religion2020,2000,12
College of Holy Cross,Religion2020,2000,1
College of New Rochelle,Cooperation2021,2000,1
College of New Rochelle,Religion2020,2000,3
College of St. Rose,Religion2020,2000,1
College of the Canyons,Religion2020,2000,2
College of the Holy Cross,Religion2020,2000,4
Collegiate Church of st Mary,Religion2020,2000,2
Collegium Pharmaceutical Inc.,Religion2020,2000,3
Colorado School of Mines,Cooperation2021,2000,1
Colorado State University,Religion2020,2000,5
Columbia Theological Seminary,Religion2020,2000,16
Columbia University,Cooperation2021,2000,14
Columbia University,CooperationAndReligion,2000,3
Columbia University,Religion2020,2000,48
Columbia University Medical Center,Religion2020,2000,1
Columbus State University,Religion2020,2000,1
Comenius Institute,Cooperation2021,2000,1
Comenius Institute,CooperationAndReligion,2000,1
Comenius Institute,Religion2020,2000,2
"Community Health Center, Meriden",Religion2020,2000,1
"Complejo Hospitalario Universitario de Badajoz, Badajoz",Religion2020,2000,1
Conception Seminary College,Religion2020,2000,1
Concordia College,Religion2020,2000,4
Concordia Seminary,Religion2020,2000,3
Concordia University,Cooperation2021,2000,3
Concordia University,Religion2020,2000,14
Connecticut College,Religion2020,2000,10
Constantine the Philosopher University,Religion2020,2000,9
Copenhagen Business School,Religion2020,2000,2
Cornell University,Cooperation2021,2000,34
Cornell University,Religion2020,2000,18
Corvinus University of Budapest,Religion2020,2000,2
Cosmos & Creation,Religion2020,2000,1
Council of European Churches,Religion2020,2000,1
Council on Spiritual Practices,Religion2020,2000,1
Coventry University,Religion2020,2000,4
"Cox Family Medicine Associates, Springfield",Religion2020,2000,2
Cranfield University,Religion2020,2000,5
Creighton University,Cooperation2021,2000,1
Creighton University,Religion2020,2000,5
Crozer Theological Seminary,Cooperation2021,2000,1
CUNY,Cooperation2021,2000,4
CUNY,Religion2020,2000,13
CUNY Graduate Center,Cooperation2021,2000,1
CUNY Graduate Center,CooperationAndReligion,2000,1
CUNY Graduate Center,Religion2020,2000,1
"CUNY, College of Staten Island",Religion2020,2000,7
Curtin University,Cooperation2021,2000,2
Curtin University,Religion2020,2000,1
Curtin University of Technology,Religion2020,2000,1
Czech Academy of Sciences,Cooperation2021,2000,2
Dalhousie University,Religion2020,2000,9
Dana-Farber Cancer Institute,Cooperation2021,2000,7
Dana-Farber Cancer Institute,Religion2020,2000,9
Danish National Stem Cell Center,Cooperation2021,2000,1
Dartmouth College,Religion2020,2000,21
Davidson College,Religion2020,2000,5
Deakin University,Cooperation2021,2000,2
Deakin University,Religion2020,2000,7
Defence Institute of Psychological Research,Religion2020,2000,1
Defense Institute of Physiology and Allied Sciences,Religion2020,2000,1
Delft University of Technology,Cooperation2021,2000,2
Delhi University,Cooperation2021,2000,1
Delhi University,Religion2020,2000,1
DeltaQuest Foundation,Religion2020,2000,2
Denison University,Religion2020,2000,8
Denver Seminary,Religion2020,2000,1
DePaul University,Cooperation2021,2000,1
DePaul University,Religion2020,2000,7
DePauw University,Religion2020,2000,5
Diakonhjemmet Hospital,Religion2020,2000,2
Dickinson College,Religion2020,2000,2
Division of Diabetes Translation,Cooperation2021,2000,1
Doha Institute for Graduate Studies,Religion2020,2000,6
Domar Center for Mind/Body Health,Religion2020,2000,1
Dominican School of Philosophy and Theology,Religion2020,2000,2
Dongbei University of Finance and Economics,Religion2020,2000,1
Dongguan University of Technology,Religion2020,2000,1
Downing College,Religion2020,2000,1
Dr Williams's Library,Religion2020,2000,1
Dresden University of Technology,Religion2020,2000,1
Drew University,Religion2020,2000,5
Drew University Theological School,Religion2020,2000,2
Drexel University,Cooperation2021,2000,4
Drexel University,Religion2020,2000,1
Dublin City University,Religion2020,2000,2
Duke Divinity School,Religion2020,2000,14
Duke Kunshan University,Religion2020,2000,1
Duke University,Cooperation2021,2000,23
Duke University,CooperationAndReligion,2000,2
Duke University,Religion2020,2000,144
Duke-NUS Medical School,Religion2020,2000,2
Duquesne University,Religion2020,2000,6
Durham University,Religion2020,2000,50
"DynaMed, EBSCO Health",Religion2020,2000,1
EAPC Primary Care Reference Group,Religion2020,2000,2
East Carolina University,Cooperation2021,2000,2
East Carolina University,Religion2020,2000,4
East China Normal University,Cooperation2021,2000,1
East Tennessee State University,Religion2020,2000,6
Eastern Connecticut University,Cooperation2021,2000,1
Eastern Mennonite University,Religion2020,2000,2
Eastern Michigan University,Religion2020,2000,2
Eastern Nebraska Office of Retardation,Religion2020,2000,6
Eastern University,Religion2020,2000,1
Eckerd College,Religion2020,2000,1
Ecole des Hautes Etudes,Religion2020,2000,5
Ecole des Hautes Etudes en Sciences Sociales,Cooperation2021,2000,1
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,2000,14
École des Hautes Etudes en Sciences Sociales,Religion2020,2000,3
Ecole Polytechnique Fédérale de Lausanne,Cooperation2021,2000,4
Ecole Pratique des Hautes Etudes,Religion2020,2000,7
École Pratique des Hautes Etudes,Religion2020,2000,4
École pratique des hautes études,Religion2020,2000,9
ECU Physicians-Adult & Pediatric Health Care,Religion2020,2000,1
Ecumenical Institute,Religion2020,2000,3
Ecumenical Theological Seminary,Religion2020,2000,1
Edge Hill University,Religion2020,2000,2
Edgewalkers International,Religion2020,2000,4
Eindhoven University of Technology,Cooperation2021,2000,2
Elizabethtown College,Religion2020,2000,1
Emmanuel College,Religion2020,2000,12
Emory College,Religion2020,2000,2
Emory University,Cooperation2021,2000,26
Emory University,CooperationAndReligion,2000,1
Emory University,Religion2020,2000,80
Empire State College,Religion2020,2000,1
Eotvos Lorand University,Cooperation2021,2000,4
Eötvös Loránd University,Cooperation2021,2000,2
Episcopal Divinity School,Religion2020,2000,1
Erasmus Medical Center,Religion2020,2000,1
Erasmus University Rotterdam,Cooperation2021,2000,2
Erasmus University Rotterdam,Religion2020,2000,7
Erin Presbyterian Church,Religion2020,2000,1
Esalen Institute,Religion2020,2000,1
Esprit Journal,Religion2020,2000,1
Esslingen University of Applied Sciences,Religion2020,2000,1
ETH Zurich,Cooperation2021,2000,1
European Business School,Religion2020,2000,1
European Commission,Cooperation2021,2000,1
European Institute of Design,Cooperation2021,2000,1
European Institute of Design,Religion2020,2000,2
European University Institute,Cooperation2021,2000,2
European University Institute,Religion2020,2000,2
European University Viadrina,Cooperation2021,2000,1
European University Viadrina,Religion2020,2000,7
Evesham Methodist Church,Religion2020,2000,1
Ewha Women's University,Cooperation2021,2000,1
Excelsia College,Religion2020,2000,1
faculte de pharmacie et de medecine de casablanca,Religion2020,2000,1
Fairfield University,Religion2020,2000,1
Family-Counselling Services,Religion2020,2000,2
Fayetteville State University,Cooperation2021,2000,1
Fayetteville State University,Religion2020,2000,1
Federal University of Bahia,Religion2020,2000,1
Federal University of Pernambuco,Religion2020,2000,4
Federal University of Rio de Janeiro,Religion2020,2000,3
federal university of rio grande do norte,Cooperation2021,2000,2
Federal University of Rio Grande do Sul,Religion2020,2000,1
Federal University of São Paulo (UNIFESP),Religion2020,2000,4
Federal University of Tocantins,Cooperation2021,2000,1
Federation University Australia,Cooperation2021,2000,1
Federation University Australia,Religion2020,2000,8
Fielding Graduate University,Religion2020,2000,5
First Things,Religion2020,2000,3
Flinders University,Cooperation2021,2000,6
Flinders University,Religion2020,2000,8
Florida Atlantic University,Cooperation2021,2000,5
Florida Atlantic University,Religion2020,2000,9
Florida International University,Cooperation2021,2000,1
Florida International University,Religion2020,2000,11
Florida State University,Cooperation2021,2000,18
Florida State University,Religion2020,2000,34
flourishing metrics,Religion2020,2000,4
Floyd Medical Center,Religion2020,2000,1
Fondazione Edo Ed Elvo Tempia Valenta,Cooperation2021,2000,1
Forbes Hospital,Religion2020,2000,2
Fordham University,Cooperation2021,2000,2
Fordham University,CooperationAndReligion,2000,1
Fordham University,Religion2020,2000,38
Forlaget Univers,Religion2020,2000,2
Forum on European Expansion and Global Interaction,Religion2020,2000,1
Foundation for Advancement of International Science,Religion2020,2000,1
"Founder and Director of the Jung's Center Mind, Body, Spirit Institute",Religion2020,2000,1
Fox Valley Memory Project,Religion2020,2000,8
Francis Crick Institute,Cooperation2021,2000,3
Francis Taylor Building,Religion2020,2000,4
Franklin College,Religion2020,2000,2
Fraser Health Authority,Religion2020,2000,3
Fraunhofer FIT,Cooperation2021,2000,1
Fraunhofer FIT,Religion2020,2000,1
Fred Hutchinson Cancer Research Center,Cooperation2021,2000,3
Fred Hutchinson Cancer Research Center,Religion2020,2000,2
Free State University,Religion2020,2000,4
Free University Berlin,Cooperation2021,2000,2
Free University Berlin,Religion2020,2000,1
Free University of Berlin,Cooperation2021,2000,4
Free University of Berlin,Religion2020,2000,4
Freelance Researcher,Religion2020,2000,4
Freie Universität Berlin,Religion2020,2000,5
French National Center for Scientific Research,Cooperation2021,2000,4
French National Center for Scientific Research,Religion2020,2000,2
French National Council of Universities,Religion2020,2000,5
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,2000,5
Friedrich Schiller University Jena,Cooperation2021,2000,4
Fudan University,Cooperation2021,2000,2
Fuller Theological Seminary,Cooperation2021,2000,2
Fuller Theological Seminary,CooperationAndReligion,2000,1
Fuller Theological Seminary,Religion2020,2000,64
Furman University,Religion2020,2000,7
Future University Hakodate,Cooperation2021,2000,3
Gansu University of Traditional Chinese Medicine,Cooperation2021,2000,2
Gardner-Webb University,Religion2020,2000,5
Garrett-Evangelical Theological Seminary,Religion2020,2000,14
Geha Mental Health Center,Religion2020,2000,1
Genentech,Cooperation2021,2000,2
General Theological Seminary,Religion2020,2000,2
Generation Next,Religion2020,2000,2
Georg August University of Göttingen,Cooperation2021,2000,3
Georg August University of Göttingen,Religion2020,2000,1
George Fox University,Religion2020,2000,13
George Mason University,Cooperation2021,2000,9
George Mason University,Religion2020,2000,9
George Washington University,Cooperation2021,2000,3
George Washington University,Religion2020,2000,32
Georgetown University,Cooperation2021,2000,7
Georgetown University,CooperationAndReligion,2000,2
Georgetown University,Religion2020,2000,66
Georgetown University School of Medicine,Religion2020,2000,2
Georgia Southern University,Religion2020,2000,3
Georgia State University,Religion2020,2000,5
German Institute for Economic Research (DIW Berlin),Cooperation2021,2000,3
GESIS-Leibniz Institute for the Social Sciences,Cooperation2021,2000,1
GESIS-Leibniz Institute for the Social Sciences,Religion2020,2000,3
Gettysburg College,Religion2020,2000,1
Ghent University,Cooperation2021,2000,4
Ghent University,CooperationAndReligion,2000,1
Ghent University,Religion2020,2000,10
GKT School of Medical Education,Religion2020,2000,1
Glasgow Centre for Population Health,Religion2020,2000,1
Global Alliance in Management Education,Religion2020,2000,2
Glyndwr University,Religion2020,2000,17
Goa University,Religion2020,2000,1
Goethe University Frankfurt,Religion2020,2000,3
Golden Gate Baptist Theological Seminary,Cooperation2021,2000,2
Golden Gate Baptist Theological Seminary,Religion2020,2000,1
"Goldsmiths, University of London",Cooperation2021,2000,1
"Goldsmiths, University of London",Religion2020,2000,13
Gordon College,Religion2020,2000,1
Gordon Conwell Theological Seminary,Religion2020,2000,1
Gordon-Conwell Theological Seminary,Cooperation2021,2000,1
Gordon-Conwell Theological Seminary,Religion2020,2000,4
Grace,Religion2020,2000,1
Graduate Institute of International and Developmental Studies in Geneva,Cooperation2021,2000,3
Graduate Theological Foundation,Religion2020,2000,3
Graduate Theological Union,Cooperation2021,2000,1
Graduate Theological Union,Religion2020,2000,18
Graduate University of Advanced Studies (SOKENDAI),Cooperation2021,2000,2
Graduate University of Advanced Studies (SOKENDAI),Religion2020,2000,1
Grand Valley State University,Religion2020,2000,1
Greater Omaha Arc,Religion2020,2000,6
Green Templeton College,Religion2020,2000,1
Greenwall Foundation,Religion2020,2000,3
Greenwich Hospital,Religion2020,2000,2
Gregorian University of the Jesuit Order,Religion2020,2000,2
Grenoble Alpes University,Religion2020,2000,9
Gresham College,Religion2020,2000,2
Griffin Hospital,Religion2020,2000,1
Griffith University,Cooperation2021,2000,2
Griffith University,Religion2020,2000,23
Grove City College,Religion2020,2000,2
Guestbrook Project,Religion2020,2000,1
Gustave Roussy Institute,Cooperation2021,2000,2
Gustavus Adolphus College,Religion2020,2000,4
H&S Ventures,Religion2020,2000,3
Hadassah University Medical Center,Cooperation2021,2000,2
Hadassah University Medical Center,Religion2020,2000,4
Haifa University,Religion2020,2000,1
Hamburg University,Religion2020,2000,1
Hamilton College,Religion2020,2000,2
handzo consulting,Religion2020,2000,2
Hardin-Simmons University,Religion2020,2000,3
Harper College,Religion2020,2000,6
Harris Manchester College,Religion2020,2000,5
Hartford Seminary,Religion2020,2000,1
Harvard Business School,Cooperation2021,2000,3
Harvard Divinity School,Religion2020,2000,29
Harvard Medical School,Cooperation2021,2000,14
Harvard Medical School,Religion2020,2000,22
Harvard School of Public Health,Cooperation2021,2000,2
Harvard School of Public Health,Religion2020,2000,24
Harvard University,Cooperation2021,2000,36
Harvard University,Religion2020,2000,60
Hastings Center,Religion2020,2000,3
Havering College of Further and Higher Education,Cooperation2021,2000,1
Hazara University,Religion2020,2000,2
Heal,Religion2020,2000,22
Healthcare Chaplaincy,Religion2020,2000,1
Healthcare Chaplaincy and Spiritual Care,Religion2020,2000,1
Healthed,Religion2020,2000,2
healthquest,Religion2020,2000,1
Hebrew Union College,Religion2020,2000,4
Hebrew University - Hadessah,Religion2020,2000,2
Hebrew University of Jerusalem,Cooperation2021,2000,12
Hebrew University of Jerusalem,Religion2020,2000,46
HEC Montreal,Cooperation2021,2000,1
heckercounseling,Religion2020,2000,3
Heidelberg Center for American Studies,Religion2020,2000,2
Heidelberg University,Cooperation2021,2000,1
Heidelberg University,Religion2020,2000,4
Henderson State University,Religion2020,2000,3
Henry M Jackson Foundation for the Advancement of Military Medicine,Religion2020,2000,1
Heriot-Watt University,Cooperation2021,2000,1
Heriot-Watt University,Religion2020,2000,7
"Herzog Hospital, Jerusalem",Religion2020,2000,6
Heythrop College,Cooperation2021,2000,1
Heythrop College,Religion2020,2000,7
Higher School of Economics,Cooperation2021,2000,1
Higher School of Economics,Religion2020,2000,2
Highland Theological College,Religion2020,2000,4
Hillcroft College,Cooperation2021,2000,2
Hillcroft College,Religion2020,2000,2
Hillsdale College,Religion2020,2000,7
Hitotsubashi University,Cooperation2021,2000,4
Hobart and William Smith Colleges,Religion2020,2000,4
Hofstra University,Cooperation2021,2000,1
Hofstra University,Religion2020,2000,2
Hokkaido University,Religion2020,2000,3
Holbaek Hospital,Religion2020,2000,1
Holistic Nursing Consultants,Religion2020,2000,2
Holy Cross Health Care System,Religion2020,2000,5
Holy Ghost College,Religion2020,2000,5
Hong Kong Baptist University,Religion2020,2000,1
Hong Kong Polytechnic University,Cooperation2021,2000,1
Hong Kong Polytechnic University,Religion2020,2000,4
Hope College,Religion2020,2000,6
Hospice Rieti,Religion2020,2000,24
Hospital De Clínicas De Porto Alegre,Religion2020,2000,1
Hospital Universitario Virgen de la Arrixaca,Cooperation2021,2000,1
Hospital Universitario Virgen de la Arrixaca,Religion2020,2000,1
House of Lords,Religion2020,2000,1
Houston Baptist University,Religion2020,2000,1
Hudson College of Public Health,Cooperation2021,2000,1
Human Population Laboratory,Religion2020,2000,3
Human Sciences Research Council,Religion2020,2000,4
Humboldt University of Berlin,Cooperation2021,2000,4
Humboldt University of Berlin,Religion2020,2000,10
Hungarian Academy of Sciences,Cooperation2021,2000,5
"Hunter College, CUNY",Cooperation2021,2000,1
"Hunter College, CUNY",Religion2020,2000,7
"Huntington School, York",Religion2020,2000,3
Huntington University,Religion2020,2000,1
Huron University College,Religion2020,2000,1
Ibadan University,Religion2020,2000,2
Ibaraki University,Religion2020,2000,1
Icahn School of Medicine at Mount Sinai,Cooperation2021,2000,2
Icahn School of Medicine at Mount Sinai,Religion2020,2000,6
Idaho State University,Religion2020,2000,6
Iliff School of Theology,Religion2020,2000,6
Illif School of Theology,Religion2020,2000,6
Illinois Institute of Technology,Cooperation2021,2000,2
Illinois State University,Cooperation2021,2000,1
Illinois State University,Religion2020,2000,1
Immersion Neuroscience,Cooperation2021,2000,1
Impact Publishers,Cooperation2021,2000,1
Imperial Cancer Research Fund Oxford,Cooperation2021,2000,1
Imperial College London,Cooperation2021,2000,8
In His Image Family Medicine Residency,Religion2020,2000,2
Independent Consulting,Cooperation2021,2000,1
Independent Consulting,Religion2020,2000,5
Independent Researcher,Cooperation2021,2000,1
Independent Researcher,Religion2020,2000,1
India Institute of Technology,Religion2020,2000,2
Indian Institute of Science,Cooperation2021,2000,2
Indian Institute of Technology,Religion2020,2000,2
Indian Naturopathy & Yoga Graduates Medical Association [INYGMA],Religion2020,2000,1
Indiana State University,Religion2020,2000,3
Indiana University,Cooperation2021,2000,14
Indiana University,Religion2020,2000,40
Indiana University at Bloomington,Cooperation2021,2000,7
Indiana University at Bloomington,Religion2020,2000,7
Indiana University of Pennsylvania,Cooperation2021,2000,1
Indiana University of Pennsylvania,Religion2020,2000,6
Indiana University Purdue University Indianapolis,Religion2020,2000,1
Indiana University South Bend,Cooperation2021,2000,1
Indiana University South Bend,Religion2020,2000,1
Indiana University Southeast,Religion2020,2000,1
Indiana University-Purdue University Fort Wayne,Religion2020,2000,5
Indiana Wesleyan University,Religion2020,2000,1
Indus Business Academy,Cooperation2021,2000,1
Indus Business Academy,Religion2020,2000,2
Infinity Publishing,Religion2020,2000,2
"Innovations and EBM Development, EBSCO Health",Religion2020,2000,1
Inserm,Cooperation2021,2000,2
Institut national de la recherche scientifique,Cooperation2021,2000,1
Institut national de la recherche scientifique,Religion2020,2000,2
Institute Ecumenical and Cultural Research,Religion2020,2000,2
Institute for Advanced Study,Cooperation2021,2000,1
Institute for Advanced Study,Religion2020,2000,9
Institute for Cognitive Sciences and Technologies,Cooperation2021,2000,4
Institute for Global Engagement,Religion2020,2000,3
Institute for Integrative Health,Religion2020,2000,1
Institute for Orthodox Christian Studies,Religion2020,2000,1
Institute for Political Science,Cooperation2021,2000,2
Institute for Political Science,CooperationAndReligion,2000,1
Institute for Political Science,Religion2020,2000,2
Institute for Safe Medication Practices,Cooperation2021,2000,7
Institute for Social Sciences at the TU Braunschweig,Religion2020,2000,1
Institute for the Biocultural Study of Religion,Religion2020,2000,1
Institute for the Study of Complex Systems,Cooperation2021,2000,2
Institute for the Study of Complex Systems,Religion2020,2000,1
Institute for Transpersonal Psychology,Religion2020,2000,1
Institute of Communications Engineering,Cooperation2021,2000,1
Institute of Developing Economics,Cooperation2021,2000,2
Institute of East West Medicine,Religion2020,2000,3
Institute of Electrical and Electronics Engineers,Cooperation2021,2000,1
Institute of Ismaili Studies,Religion2020,2000,4
Institute of Lutheran Theology,Religion2020,2000,2
Institute of Modern History,Religion2020,2000,4
Institute of Psychiatry,Cooperation2021,2000,1
Institute of Public Health,Religion2020,2000,3
Institute of Race Relations,Religion2020,2000,1
"Institute of Science, Technology and Public Policy",Religion2020,2000,1
Institute of Security and Global Affairs,Religion2020,2000,4
Institute on Religion in an Age of Science,Religion2020,2000,4
Instituto de Análisis Económico,Cooperation2021,2000,2
Interdisciplinary Center Herzliya,Cooperation2021,2000,1
Interdisciplinary Center Herzliya,Religion2020,2000,2
"International Association for Human Values, United Nations",Religion2020,2000,3
International Baptist Theological Seminary,Religion2020,2000,2
International Christian University,Religion2020,2000,2
International Institute for Applied Systems Analysis,Cooperation2021,2000,2
International Institute of Advanced Islamic Studies,Religion2020,2000,1
International Institute of Spiritual Leadership,Religion2020,2000,1
International Primary Palliative Care Network (IPPCN),Religion2020,2000,2
International Society of Addiction Medicine,Cooperation2021,2000,1
International Society of Addiction Medicine,Religion2020,2000,2
International Telematic University UNINETTUNO,Cooperation2021,2000,7
International University of Rabat,Religion2020,2000,2
Iowa State University,Cooperation2021,2000,4
Iowa State University,Religion2020,2000,10
Iran University of Medical Sciences,Religion2020,2000,1
Iranian Institute for Health Sciences Research,Religion2020,2000,1
Istituto Affari Internazionali,Cooperation2021,2000,2
Istituto Affari Internazionali,Religion2020,2000,1
Istituto Auxologico Italiano,Cooperation2021,2000,1
Italian Institute of Transcultural Mental Health,Religion2020,2000,3
Italian National Research Council,Cooperation2021,2000,1
Italian Society of Sociology,Religion2020,2000,2
Ithaca College,Religion2020,2000,1
Jacobs University Bremen,Religion2020,2000,5
Jagiellonian University,Cooperation2021,2000,1
Jagiellonian University,Religion2020,2000,5
James Cook University,Cooperation2021,2000,8
James Madison University,Religion2020,2000,3
Jangxi University of Finance and Economics,Cooperation2021,2000,1
Japan Society for Buddhist-Christian Studies,Religion2020,2000,1
Jawaharlal Nehru University,Religion2020,2000,4
Jefferson Medical College,Religion2020,2000,1
Jesuit Ignatianum University,Cooperation2021,2000,1
Jesuit Ignatianum University,CooperationAndReligion,2000,1
Jesuit Ignatianum University,Religion2020,2000,1
Jesuit School of Theology,Religion2020,2000,2
Jesus College,Religion2020,2000,2
Jewish Theological Seminary,Religion2020,2000,4
Jiangsu Normal University,Cooperation2021,2000,1
Jilin University,Cooperation2021,2000,1
Jinan University,Cooperation2021,2000,2
Jindal Global University,Religion2020,2000,1
Johann Wolfgang Goethe-University,Religion2020,2000,2
John Carroll University,Cooperation2021,2000,1
John Carroll University,Religion2020,2000,6
John Hopkins University,Cooperation2021,2000,7
John Hopkins University,CooperationAndReligion,2000,3
John Hopkins University,Religion2020,2000,12
John Jay College of Criminal Justice,Religion2020,2000,2
John Wycliffe Theological College,Religion2020,2000,1
Johns Hopkins Bayview Medical Center,Religion2020,2000,1
Johns Hopkins Bloomberg School of Public Health,Religion2020,2000,2
Johns Hopkins Hospital,Religion2020,2000,2
Johns Hopkins School of Medicine,Cooperation2021,2000,3
Johns Hopkins School of Medicine,Religion2020,2000,1
Johns Hopkins University,Cooperation2021,2000,3
Johns Hopkins University,Religion2020,2000,24
Joint Centre of Excellence for Research on Immigration & Settlement,Cooperation2021,2000,2
Joint Centre of Excellence for Research on Immigration & Settlement,CooperationAndReligion,2000,1
Joint Centre of Excellence for Research on Immigration & Settlement,Religion2020,2000,2
Joint Special Operations University,Religion2020,2000,2
Jonkoping University,Religion2020,2000,1
Jönköping University,Religion2020,2000,2
Joshi-Bedekar College,Religion2020,2000,2
Journal of Law and Religion,Religion2020,2000,1
journal of theological studies,Religion2020,2000,1
Jungian Psychoanalytic Association of New York,Religion2020,2000,1
Justus Liebig University Giessen,Cooperation2021,2000,1
Justus Liebig University Giessen,Religion2020,2000,1
Kaiser Permanente,Religion2020,2000,2
Kansai University,Cooperation2021,2000,2
Kansas Neurological Institute,Religion2020,2000,6
Kansas State University,Religion2020,2000,4
Kansas University,Cooperation2021,2000,1
Kansas University,Religion2020,2000,1
Kaohsiung Medical University,Religion2020,2000,1
Karen D. Lincoln Consulting Services,Religion2020,2000,10
Karolinska Institute,Cooperation2021,2000,1
Karolinska Institute,Religion2020,2000,6
Karolinska Institutet,Religion2020,2000,2
Karuna-Shechen,Religion2020,2000,1
Keele University,Cooperation2021,2000,1
Keele University,Religion2020,2000,15
Keenesaw State University,Religion2020,2000,2
Kellogg School of Management,Religion2020,2000,1
Kennesaw State University,Religion2020,2000,4
Kent State University,Religion2020,2000,5
Keston Institute,Religion2020,2000,6
Khon Kaen University,Religion2020,2000,1
Kickbusch Health Consult,Cooperation2021,2000,3
Kiev University,Religion2020,2000,1
KIFO Institute,Religion2020,2000,2
King Abdulaziz University,Cooperation2021,2000,1
King Abdulaziz University,Religion2020,2000,3
King Abdullah University of Science and Technology,Cooperation2021,2000,2
King Saud University,Cooperation2021,2000,1
King’s College,Religion2020,2000,2
King’s College London,Religion2020,2000,16
King's College,Religion2020,2000,9
King's College London,Cooperation2021,2000,9
King's College London,Religion2020,2000,24
Kings College London University,Cooperation2021,2000,2
Kings College London University,Religion2020,2000,15
Kings University College,Religion2020,2000,1
Kingston University,Religion2020,2000,3
Knowledge Moves,Religion2020,2000,1
Knox College,Cooperation2021,2000,1
Kochi University of Technology,Cooperation2021,2000,2
Kokugakuin University,Religion2020,2000,2
Korea Advanced Institute of Science and Technology (KAIST),Cooperation2021,2000,1
Korea Advanced Institute of Science and Technology (KAIST),Religion2020,2000,1
Korea University,Cooperation2021,2000,3
Korea University,Religion2020,2000,3
Korea University Guro Hospital,Religion2020,2000,1
Kripalu Center,Cooperation2021,2000,1
Kripalu Center,Religion2020,2000,1
KU Leuven,Cooperation2021,2000,8
KU Leuven,Religion2020,2000,23
Kuwait University,Religion2020,2000,1
KW Counselling Services,Religion2020,2000,4
Kwansei Gakuin University,Cooperation2021,2000,1
Kwantlen Polytechnic Institute,Religion2020,2000,3
Kyoto University,Cooperation2021,2000,13
Kyoto University,Religion2020,2000,1
Kyungpook National University,Cooperation2021,2000,2
Kyushu University,Cooperation2021,2000,3
Kyushu University,Religion2020,2000,1
La Salle University,Religion2020,2000,13
La Salpetriere Hospital,Religion2020,2000,1
La Sierra University,Religion2020,2000,3
La Trobe University,Religion2020,2000,3
Laboratory for Analysis and Architecture of Systems,Cooperation2021,2000,2
Laboratory for Statistics and Computation,Religion2020,2000,3
Lafayette College,Religion2020,2000,2
Laidlaw College,Religion2020,2000,1
Lake Forest College,Religion2020,2000,2
Lamar University,Cooperation2021,2000,2
Lamar University,Religion2020,2000,6
Lancaster Theological Seminary,Religion2020,2000,3
Lancaster University,Cooperation2021,2000,6
Lancaster University,Religion2020,2000,26
langara college,Religion2020,2000,4
Lanzhou University,Religion2020,2000,1
Lariboisière Hospital,Cooperation2021,2000,1
LaTrobe University,Religion2020,2000,1
Laurentian University,Religion2020,2000,3
Laval University,Cooperation2021,2000,3
Laval University,Religion2020,2000,19
Lebanon Valley College,Religion2020,2000,1
Lee University,Religion2020,2000,4
Leeds Beckett University,Cooperation2021,2000,1
Leeds Metropolitan University,Religion2020,2000,4
Lehigh University,Religion2020,2000,3
Leibniz University of Hanover,Religion2020,2000,5
Leiden University,Cooperation2021,2000,11
Leiden University,Religion2020,2000,23
Leipzig University,Religion2020,2000,7
Leo Baeck College,Religion2020,2000,1
Lesya Ukrainka Volyn National University,Religion2020,2000,5
Leuven University,Religion2020,2000,1
Lewis & Clark College,Religion2020,2000,5
Lewis University,Religion2020,2000,2
LifeWork Choices - Career Counseling and Planning,Religion2020,2000,1
Lincoln Memorial University,Religion2020,2000,2
Lingnan University,Cooperation2021,2000,5
Link Care Center,Cooperation2021,2000,1
Link Care Center,Religion2020,2000,3
Linköping University,Cooperation2021,2000,1
Linköping University,Religion2020,2000,1
Linnaeus University,Religion2020,2000,4
Liverpool Hope University,Religion2020,2000,13
Liverpool John Moores University,Cooperation2021,2000,2
Liverpool School of Tropical Medicine,Religion2020,2000,3
Loma Linda University,Cooperation2021,2000,1
Loma Linda University,CooperationAndReligion,2000,1
Loma Linda University,Religion2020,2000,15
London Metropolitan University,Cooperation2021,2000,1
London Metropolitan University,Religion2020,2000,7
London School of Economics,Cooperation2021,2000,7
London School of Economics,Religion2020,2000,11
London School of Hygiene and Tropical Medicine,Cooperation2021,2000,9
London School of Hygiene and Tropical Medicine,Religion2020,2000,1
London School of Theology,Religion2020,2000,4
London University,Religion2020,2000,1
Los Angeles Christian Health Centers,Religion2020,2000,2
Los Angeles Coutny Jail,Religion2020,2000,1
Loughborough University,Cooperation2021,2000,5
Louisburg College,Religion2020,2000,1
Louisiana State University,Cooperation2021,2000,4
Louisiana State University,Religion2020,2000,6
Louisville Institute,Religion2020,2000,3
Louisville Presbyterian Theological Seminary,Religion2020,2000,10
Loyola College of Maryland  College of Maryland  College of Maryland,Cooperation2021,2000,1
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,2000,5
Loyola Marymount University,Religion2020,2000,8
Loyola University Chicago,Cooperation2021,2000,3
Loyola University Chicago,Religion2020,2000,31
Loyola University Maryland,Religion2020,2000,21
Loyola University New Orleans,Religion2020,2000,2
Ludwig Maximilian University of Munich,Cooperation2021,2000,3
Ludwig Maximilian University of Munich,Religion2020,2000,3
Ludwig-Maximilians-University of Munich,Religion2020,2000,3
Luiss University Rome,Cooperation2021,2000,17
Lund University,Cooperation2021,2000,5
Lund University,Religion2020,2000,3
Luther College,Religion2020,2000,4
Luther Seminary,Religion2020,2000,6
Luther Theological Seminary,Religion2020,2000,2
Lutheran School of theology at Chicago,Religion2020,2000,11
Lutheran Theological Seminary at Philadelphia,Religion2020,2000,3
Lutheran Theological Southern Seminary,Religion2020,2000,2
Maastricht University,Cooperation2021,2000,10
Maastricht University,CooperationAndReligion,2000,2
Maastricht University,Religion2020,2000,4
Macalaster College,Religion2020,2000,2
Macalester College,Religion2020,2000,2
Macquarie University,Cooperation2021,2000,1
Macquarie University,Religion2020,2000,2
Madras Medical College,Cooperation2021,2000,1
Magdalene College,Cooperation2021,2000,3
Magdalene College,Religion2020,2000,3
Maharishi International University,Religion2020,2000,1
Maharishi Markandeshwar University,Religion2020,2000,1
Maharishi University of Management,Religion2020,2000,26
Mahidol University,Cooperation2021,2000,1
Maimonides Medical Center,Religion2020,2000,3
Malmo University,Religion2020,2000,2
Malmö University,Cooperation2021,2000,2
Manchester Metropolitan University,Cooperation2021,2000,13
Manchester Metropolitan University,Religion2020,2000,3
Manchester University,Religion2020,2000,4
Manhattan College,Cooperation2021,2000,1
Manhattan College,Religion2020,2000,1
Mansoura University,Cooperation2021,2000,8
Mansoura University,Religion2020,2000,1
Marburg University,Religion2020,2000,1
Marian University,Religion2020,2000,6
Marie Curie Hospice Homestead,Religion2020,2000,1
Marquette University,Cooperation2021,2000,2
Marquette University,Religion2020,2000,12
Mars Hill Graduate School,Religion2020,2000,7
Martin Luther University of Halle-Wittenberg,Cooperation2021,2000,1
Martin Luther University of Halle-Wittenberg,Religion2020,2000,3
Maryland University of Integrative Health,Religion2020,2000,1
Marymount University,Religion2020,2000,1
Massachusetts General Hospital,Cooperation2021,2000,1
Massachusetts General Hospital,Religion2020,2000,10
Massachusetts Institute of Technology,Cooperation2021,2000,4
Massachusetts Institute of Technology,Religion2020,2000,3
Massey University,Cooperation2021,2000,8
Massey University,Religion2020,2000,6
Masters and Johnson,Religion2020,2000,2
Max Planck Institute,Cooperation2021,2000,10
Max Planck Institute for Chemical Ecology,Cooperation2021,2000,3
Max Planck Institute for Comparative Public Law and International Law,Cooperation2021,2000,1
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Cooperation2021,2000,2
Max Planck Institute for Evolutionary Biology,Cooperation2021,2000,2
Max Planck Institute for Human Cognitive and Brain Sciences,Cooperation2021,2000,1
Max Planck Institute for Human Development,Cooperation2021,2000,6
Max Planck Institute for Ornithology,Cooperation2021,2000,1
Max Planck Institute for Ornithology,Religion2020,2000,1
Max Planck Institute for Psychological Research,Religion2020,2000,2
Max Planck Institute for Research on Collective Goods,Cooperation2021,2000,3
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,2000,2
Max Plank Institute for Evolutionary Anthropology,Cooperation2021,2000,5
Max Weber Center for Advanced Cultural and Social Scudies,Cooperation2021,2000,2
Maynooth University,Cooperation2021,2000,1
Maynooth University,Religion2020,2000,2
Mayo Clinic - Rochester,Religion2020,2000,3
Mayo Clinic - Scottsdale,Religion2020,2000,1
Mayo Clinic School of Medicine,Religion2020,2000,6
McDaniel College,Religion2020,2000,6
McGill University,Cooperation2021,2000,3
McGill University,Religion2020,2000,7
McLean Hospital,Cooperation2021,2000,1
McMaster Divinity School,Religion2020,2000,3
McMaster University,Cooperation2021,2000,1
McMaster University,Religion2020,2000,8
Medical Academy of Warsaw,Cooperation2021,2000,4
Medical College of Georgia,Religion2020,2000,4
Medical College of Wisconsin,Religion2020,2000,1
Medical Research Council,Religion2020,2000,1
Medical University of Poznan,Religion2020,2000,4
Medical University of South Carolina,Religion2020,2000,3
"Meek and Associates, PC",Religion2020,2000,2
Meertens Instituut (KNAW),Religion2020,2000,1
Meharry Medical College,Cooperation2021,2000,1
Meiji University,Cooperation2021,2000,3
Memorial Sloan Kettering Cancer Center,Religion2020,2000,20
Memphis State University,Religion2020,2000,1
Mens Sana Monographs,Religion2020,2000,2
Mercer University,Religion2020,2000,1
"Merck & Co, Inc.",Religion2020,2000,2
Messerli Research Institute,Cooperation2021,2000,1
Messiah College,Religion2020,2000,4
Methodist Theological School,Religion2020,2000,3
MGH Institute of Health Professions,Religion2020,2000,5
Miami University,Religion2020,2000,16
Miami Veterans Administration Hospital,Religion2020,2000,1
Michael E. DeBakey VA Medical Center,Religion2020,2000,2
Michael E. DeBakey VAMC,Religion2020,2000,1
Michigan State University,Cooperation2021,2000,10
Michigan State University,Religion2020,2000,2
Microsoft,Cooperation2021,2000,2
"Microsoft Research, Israel",Cooperation2021,2000,3
Middle Tennesse State University,Religion2020,2000,1
Middlebury College,Cooperation2021,2000,5
Middlebury College,Religion2020,2000,3
Middlesex University,Cooperation2021,2000,1
Middlesex University,Religion2020,2000,2
Millersville State College,Religion2020,2000,2
"Milltown Institute, Dublin",Religion2020,2000,1
Mind and Life Institute,Religion2020,2000,1
Mindstream,Religion2020,2000,1
Mindstrong,Cooperation2021,2000,2
"Ministry of health, Israel",Cooperation2021,2000,3
"Ministry of health, Israel",CooperationAndReligion,2000,1
"Ministry of health, Israel",Religion2020,2000,5
Minneapolis Medical Research Foundation,Religion2020,2000,1
Minneapolis VA Health Care System,Religion2020,2000,1
Missouri State University,Religion2020,2000,8
Missouri University of Science and Technology,Cooperation2021,2000,3
Missouri Western State University,Religion2020,2000,1
MIT Media Lab,Cooperation2021,2000,3
MJHS Institute for Innovation and Palliative Care,Religion2020,2000,1
Moffitt Cancer Center and Research Institute,Religion2020,2000,2
Mon Ethos Pro,Religion2020,2000,1
Monash University,Cooperation2021,2000,8
Monash University,Religion2020,2000,23
Monash University Malaysia,Cooperation2021,2000,1
Montana State University,Religion2020,2000,3
Montclair State University,Religion2020,2000,1
Montreat College,Religion2020,2000,2
Moorpark College,Religion2020,2000,1
"Moravian Church Diabetes Clinics, Bluefields and  Pearl Lagoon",Religion2020,2000,2
Moravian Theological Seminary,Religion2020,2000,1
Morehouse College,Cooperation2021,2000,3
Morehouse School of Medicine,Religion2020,2000,1
mount allison university,Cooperation2021,2000,1
mount allison university,Religion2020,2000,2
Mount Holyoke College,Cooperation2021,2000,1
Mount Holyoke College,Religion2020,2000,1
Mount Royal University,Cooperation2021,2000,1
Mount Royal University,CooperationAndReligion,2000,1
Mount Royal University,Religion2020,2000,6
Mount Saint Mary's University,Religion2020,2000,1
Mount Saint Vincent University,Religion2020,2000,2
Mount San Antonio College,Religion2020,2000,1
Mount Sinai Hospital Toronto,Religion2020,2000,2
Mount Sinai School of Medicine,Cooperation2021,2000,3
Mount Sinai School of Medicine,Religion2020,2000,2
MRC/UVRI Uganda Research Unit,Religion2020,2000,1
Mt. Auburn Hospital,Religion2020,2000,1
Mt. Sinai School of Medicine,Cooperation2021,2000,1
Mt. Sinai School of Medicine,Religion2020,2000,3
Muhlenberg College,Religion2020,2000,2
Murdoch University,Religion2020,2000,1
Murray Edwards College,Religion2020,2000,8
Murray State,Cooperation2021,2000,1
Nagasaki University,Cooperation2021,2000,1
Nagasaki University,Religion2020,2000,1
Nagoya University,Cooperation2021,2000,4
Nanjing Normal University,Cooperation2021,2000,1
Nanjing University,Cooperation2021,2000,2
Nanyang Technological University,Cooperation2021,2000,1
Nanyang Technological University,Religion2020,2000,3
Nashotah House,Religion2020,2000,2
National Academy of Sciences of Ukraine,Religion2020,2000,2
National Autonomous University of Mexico,Cooperation2021,2000,1
National Cancer Institute,Cooperation2021,2000,3
National Cancer Institute,Religion2020,2000,1
National Cancer Institute of Milan,Cooperation2021,2000,1
National Center for Science Education,Cooperation2021,2000,1
National Center for Science Education,Religion2020,2000,1
National Center of Neurology and Psychiatry,Religion2020,2000,1
National Central University,Religion2020,2000,4
National Cheng Kung University,Religion2020,2000,2
National Chiao Tung University,Religion2020,2000,2
National Council for Scientific and Technological Development,Religion2020,2000,4
"National Institute for Health and Welfare, Finland",Religion2020,2000,1
National Institute for Healthcare Research,Religion2020,2000,5
National Institute for Mathematical Sciences,Cooperation2021,2000,1
National Institute for Mental Health and Neurosciences,Religion2020,2000,1
National Institute of Advanced Industrial Science and Technology,Cooperation2021,2000,7
National Institute of Mental Health,Cooperation2021,2000,1
National Institute of Psychology,Religion2020,2000,1
National Institute on Deafness and Other Communication Disorders,Cooperation2021,2000,1
National Institutes of Health,Religion2020,2000,4
National Law School of India University,Religion2020,2000,1
National Museum of Denmark,Religion2020,2000,1
National Public Radio,Cooperation2021,2000,1
National Public Radio,Religion2020,2000,2
National Research Council,Cooperation2021,2000,1
National Research Council,CooperationAndReligion,2000,1
National Research Council,Religion2020,2000,2
National Research University Higher School of Economics,Religion2020,2000,2
National Science Foundation,Cooperation2021,2000,1
National Science Foundation,Religion2020,2000,4
National Study of Treatment and Addiction Recovery Residences,Religion2020,2000,3
National Sun Yat-sen University,Cooperation2021,2000,2
National Taipei University,Religion2020,2000,3
National Taiwan University,Religion2020,2000,2
National University of Colombia,Cooperation2021,2000,1
National University of Distance Education,Cooperation2021,2000,3
National University of Ireland,Cooperation2021,2000,1
National University of Singapore,Cooperation2021,2000,19
National University of Singapore,Religion2020,2000,7
Nature,Cooperation2021,2000,3
Nature,Religion2020,2000,1
Nature Publishing Group,Cooperation2021,2000,3
Nature Publishing Group,Religion2020,2000,2
Naval Amphibious Base Coronado,Religion2020,2000,3
Nazareth College,Religion2020,2000,3
Netherlands Institute of Ecology,Cooperation2021,2000,2
Netherlands Interdiscplinary Demographic Institute,Religion2020,2000,3
Neumann University,Religion2020,2000,1
NeuroMyth,Cooperation2021,2000,1
NeuroMyth,Religion2020,2000,3
New England Complex Systems Institute,Cooperation2021,2000,3
New Mexico State University,Religion2020,2000,1
New School for Social Research,Religion2020,2000,2
New York City College of Technology,Religion2020,2000,2
New York Theological Seminary,Religion2020,2000,1
New York University,Cooperation2021,2000,12
New York University,Religion2020,2000,46
New York University Abu Dhabi,Religion2020,2000,3
New York University Endocrine Associates,Religion2020,2000,2
New York University School of Medicine,Cooperation2021,2000,3
Newcastle University,Cooperation2021,2000,6
Newcastle University,Religion2020,2000,8
NHS Foundation,Cooperation2021,2000,2
NHS Foundation,Religion2020,2000,2
"NHS Foundation, Lancashire Care",Religion2020,2000,1
Niagara County Community College,Cooperation2021,2000,1
NIH Clinical Center,Cooperation2021,2000,1
NIH Clinical Center,Religion2020,2000,3
Njala University,Religion2020,2000,2
Nordic School of Holistic Medicine,Religion2020,2000,2
Normandale Community College,Religion2020,2000,2
North Carolina Central University,Religion2020,2000,1
North Carolina State University,Religion2020,2000,4
North Carolina Wesleyan University,Religion2020,2000,1
North Dakota State University,Cooperation2021,2000,1
North Dakota State University,Religion2020,2000,4
North Manchester General Hospital,Religion2020,2000,3
North of England Institute for Christian Education,Religion2020,2000,2
North West University,Religion2020,2000,4
Northern Illinois University,Religion2020,2000,1
Northern Kentucky University,Religion2020,2000,2
Northumbria University,Cooperation2021,2000,1
Northumbria University,Religion2020,2000,1
Northwest University,Religion2020,2000,10
North-West University,Religion2020,2000,5
Northwestern College,Religion2020,2000,1
Northwestern University,Cooperation2021,2000,12
Northwestern University,Religion2020,2000,47
Northwood University,Religion2020,2000,1
Norwegian School of Economics and Business Administration,Cooperation2021,2000,1
Norwegian School of Theology,Religion2020,2000,1
Norwegian University of Science and Technology,Cooperation2021,2000,3
Norwegian University of Science and Technology,Religion2020,2000,8
Norweign School of Theology,Religion2020,2000,2
Nottingham Trent University,Cooperation2021,2000,1
Nottingham Trent University,Religion2020,2000,4
Nova Southeastern University,Religion2020,2000,6
NOVA University of Lisbon,Cooperation2021,2000,1
Novo Nordisk,Religion2020,2000,3
Novosibirsk State University,Religion2020,2000,2
NPO-Interational Research Institute,Religion2020,2000,4
Oakland University,Cooperation2021,2000,2
Oakland University,Religion2020,2000,2
Oakwood University,Religion2020,2000,2
Obafemi Awolowo University,Religion2020,2000,1
Occidental College,Religion2020,2000,2
Ohio Health,Religion2020,2000,3
Ohio State University,Cooperation2021,2000,6
Ohio State University,Religion2020,2000,33
Ohio University,Religion2020,2000,2
Oklahoma State University,Religion2020,2000,3
Old Dominion University,Religion2020,2000,3
Ono Academic College,Religion2020,2000,3
Open University (The),Cooperation2021,2000,2
Open University (The),Religion2020,2000,20
Open University of Japan,Religion2020,2000,1
Oral Roberts University,Religion2020,2000,2
Oregon Health & Science University,Cooperation2021,2000,2
Oregon Health & Science University,Religion2020,2000,6
Oregon Research Institute,Cooperation2021,2000,1
Oregon Research Institute,Religion2020,2000,2
Oregon State University,Religion2020,2000,2
Organisation for Economic Co-operation and Development,Cooperation2021,2000,1
Orot Israel College,Religion2020,2000,3
Orthodox Church,Religion2020,2000,3
Osaka University,Cooperation2021,2000,3
Osaka University Graduate School,Religion2020,2000,1
Oslo University,Religion2020,2000,4
Ostfalia Hochschule,Cooperation2021,2000,2
Ottawa Hospital Research Institute,Cooperation2021,2000,1
Otto-von-Guericke University Magdeburg,Cooperation2021,2000,1
Oxford Brookes University,Religion2020,2000,22
Oxford Centre for Mission Studies,Religion2020,2000,3
Oxford University,Cooperation2021,2000,13
Oxford University,Religion2020,2000,16
Pacific College of Health and Science,Religion2020,2000,2
Pacific Lutheran University,Religion2020,2000,1
Pacific Neuroscience Medical Group,Religion2020,2000,3
Pacific School of Religion,Religion2020,2000,1
Pacific Union College,Religion2020,2000,2
Pacific University,Cooperation2021,2000,1
Pacifica Graduate Institute,Religion2020,2000,2
Palo Alto University,Religion2020,2000,2
Panjab University,Religion2020,2000,1
Pardee RAND Graduate School,Cooperation2021,2000,1
Pardee RAND Graduate School,CooperationAndReligion,2000,1
Pardee RAND Graduate School,Religion2020,2000,2
Paris Diderot University,Religion2020,2000,5
Paris School of Economics,Cooperation2021,2000,3
Paris School of Economics,Religion2020,2000,2
Paris West University Nanterre La Défense,Cooperation2021,2000,1
Paris-Sorbonne University,Religion2020,2000,2
Patanjali Yogpeeth,Religion2020,2000,4
Peace Research Institute Oslo PRIO,Cooperation2021,2000,1
PEAR Institute,Cooperation2021,2000,1
Peking University,Cooperation2021,2000,7
Peking University,Religion2020,2000,1
Peking University Cancer Hospital and Institute,Cooperation2021,2000,2
Peking University People's Hospital,Cooperation2021,2000,1
"Peking University, Beijing",Religion2020,2000,1
"Peninsula Medical School, Universities of Exeter & Plymouth",Religion2020,2000,3
Penn State University,Religion2020,2000,6
Pennsylvania State University,Cooperation2021,2000,12
Pennsylvania State University,Religion2020,2000,39
Pentecostal Theological Seminary,Religion2020,2000,1
Pepperdine University,Religion2020,2000,12
Perimeter Institute for Theoretical Physics,Cooperation2021,2000,2
Péter Pázmány Catholic University,Religion2020,2000,1
Pew Research Center,Religion2020,2000,1
Philadelphia Corporation for Aging,Religion2020,2000,1
Philadelphia VA Medical Center,Religion2020,2000,1
Philipps University Marburg,Religion2020,2000,3
Philipps-Universität Marburg,Cooperation2021,2000,1
Philipps-Universität Marburg,Religion2020,2000,2
Phillips University,Religion2020,2000,1
Philosophy Institute of the Ukrainian Academy of Sciences,Religion2020,2000,5
Piedmont Research Strategies,Religion2020,2000,2
Pima Community College,Religion2020,2000,1
Pittsburg State University,Religion2020,2000,4
Pitzer College,Religion2020,2000,4
Polish Academy of Sciences,Religion2020,2000,2
Politecnico di Milano,Religion2020,2000,1
Pollack Peace,Religion2020,2000,2
Polytechnic University of Madrid,Cooperation2021,2000,7
Pompeu Fabra University,Cooperation2021,2000,2
Pontifical Academy of Theology,Cooperation2021,2000,2
Pontifical Academy of Theology,Religion2020,2000,1
Pontifical Biblical Institute,Religion2020,2000,2
Pontifical Catholic University of Rio,Cooperation2021,2000,3
Pontifical Catholic University of Rio,Religion2020,2000,2
Pontifical Catholic University of Sao Paulo,Religion2020,2000,2
Pontifical Gregorian University,Religion2020,2000,6
Pontifical University Antonianum,Religion2020,2000,2
Pontifical University of John Paul II in Krakow,Religion2020,2000,1
Population Studies Center,Religion2020,2000,2
Portland Commuity College,Religion2020,2000,2
Portland DBT Institute,Religion2020,2000,2
Portland State University,Cooperation2021,2000,3
Portland State University,Religion2020,2000,1
Postgraduate Institute of Medical Education & Research,Cooperation2021,2000,1
Postgraduate Institute of Medical Education & Research,Religion2020,2000,2
Press Ganey Associates,Religion2020,2000,1
Prevention Research Center,Religion2020,2000,1
Prince of Songkla University,Religion2020,2000,4
Princess Alice Hospital,Religion2020,2000,1
Princess Máxima Center for Pediatric Oncology,Religion2020,2000,2
Princeton Theological Seminary,Cooperation2021,2000,1
Princeton Theological Seminary,Religion2020,2000,54
Princeton University,Cooperation2021,2000,11
Princeton University,Religion2020,2000,45
Priory of St Michael the Archangel,Religion2020,2000,4
Private Practice,Religion2020,2000,14
Proctor & Gamble,Religion2020,2000,2
Protestant Church in Hesse and Nassau,Cooperation2021,2000,1
Protestant Church in Hesse and Nassau,CooperationAndReligion,2000,1
Protestant Church in Hesse and Nassau,Religion2020,2000,1
Protestant Theological University,Religion2020,2000,3
Protestant University of Applied Sciences,Religion2020,2000,1
pruvio,Religion2020,2000,4
Psychological Pedagogic Development Centre,Religion2020,2000,1
Public Health Institute of India,Religion2020,2000,1
Purdue University,Cooperation2021,2000,4
Purdue University,Religion2020,2000,51
QIMR Berhofer Medical Research Institute,Cooperation2021,2000,1
QIMR Berhofer Medical Research Institute,Religion2020,2000,2
Queen Mary College,Cooperation2021,2000,1
Queen Mary College,Religion2020,2000,11
Queen Mary University of London,Cooperation2021,2000,1
Queen Mary University of London,Religion2020,2000,5
Queen’s University Belfast,Religion2020,2000,9
"Queens College, City University of New York",Religion2020,2000,1
Queen's University,Cooperation2021,2000,7
Queen's University,Religion2020,2000,14
Queen's University Belfast,Cooperation2021,2000,2
Queen's University Belfast,Religion2020,2000,29
Queen's University of Belfast,Religion2020,2000,5
Queensland University of Technology,Religion2020,2000,1
Quincy College,Religion2020,2000,2
Rabin Medical Center,Religion2020,2000,1
Rabin Medical Center- Davidoff Cancer Center,Cooperation2021,2000,1
Rabin Medical Center- Davidoff Cancer Center,Religion2020,2000,3
Radboud University,Cooperation2021,2000,1
Radboud University,Religion2020,2000,24
Radboud University Medical Centre,Religion2020,2000,1
Radboud University Nijmegen,Cooperation2021,2000,1
Radboud University Nijmegen,Religion2020,2000,24
Radford College,Religion2020,2000,1
Radford University,Religion2020,2000,4
Rambam Hospital Haifa,Religion2020,2000,2
RAND Corporation,Cooperation2021,2000,2
RAND Corporation,CooperationAndReligion,2000,2
RAND Corporation,Religion2020,2000,4
Ray Marshall Center,Religion2020,2000,1
Reformed Theological Seminary,Cooperation2021,2000,1
Reformed University of Applied Sciences,Religion2020,2000,1
Regent College,Religion2020,2000,7
Regent University,Cooperation2021,2000,1
Regent University,Religion2020,2000,12
Rehabilitation Institute of Chicago,Cooperation2021,2000,1
Religion,Cooperation2021,2000,1
Religion,Religion2020,2000,7
religion news service,Religion2020,2000,1
Religions of South Asia,Religion2020,2000,2
Renmin University,Cooperation2021,2000,1
Renmin University of China,Cooperation2021,2000,1
Rennes 1 University,Cooperation2021,2000,1
Rensselaer Polytechnic Institute,Religion2020,2000,1
REsourceit,Religion2020,2000,4
Review of Religious Research,Religion2020,2000,10
Rhodes College,Religion2020,2000,1
Rhodes University,Cooperation2021,2000,2
Rice University,Cooperation2021,2000,12
Rice University,Religion2020,2000,31
Richard Stockton University,Religion2020,2000,1
Ripon College Cuddesdon,Religion2020,2000,1
"Risk-Eraser, LLC",Cooperation2021,2000,4
Ritsumeikan University,Cooperation2021,2000,1
Ritsumeikan University,Religion2020,2000,2
Ritual Studied Internatinoal,Religion2020,2000,3
RMIT University,Cooperation2021,2000,6
Roanoke College,Religion2020,2000,2
Robert Morris University,Religion2020,2000,1
Roberts Wesleyan College,Religion2020,2000,4
Rochester Institute of Technology,Religion2020,2000,5
Rockefeller University,Cooperation2021,2000,1
Rockford University,Religion2020,2000,1
Rockhurst University,Religion2020,2000,18
Rocky Mountain Biological Laboratory,Cooperation2021,2000,2
Rollins College,Cooperation2021,2000,1
Rollins College,Religion2020,2000,4
Roma Tre University,Religion2020,2000,3
Romanian-American University,Religion2020,2000,1
ronin institute,Religion2020,2000,3
Roosevelt University,Religion2020,2000,6
Rosalind Franklin University,Religion2020,2000,2
Roskilde University,Religion2020,2000,2
Rotterdam University of Applied Sciences,Cooperation2021,2000,1
Rowan University,Religion2020,2000,2
Royal College of Psychiatrists,Religion2020,2000,5
Royal Historical Society,Cooperation2021,2000,1
"Royal Holloway, University of London",Cooperation2021,2000,1
"Royal Holloway, University of London",Religion2020,2000,14
Royal Roads University,Religion2020,2000,1
Royal University of Phnom Phen,Religion2020,2000,1
RUDN University,Religion2020,2000,2
Ruhr University Bochum,Religion2020,2000,9
Ruppin Academic Center,Religion2020,2000,1
Rush University,Cooperation2021,2000,1
Rush University,Religion2020,2000,23
Russian Academy of  Sciences,Religion2020,2000,1
Russian Academy of National Economy and Public Administration,Religion2020,2000,2
Russian Armenian University,Religion2020,2000,1
Russian State University for the Humanities,Religion2020,2000,2
Rutgers New Jersey Medical School,Religion2020,2000,3
Rutgers School of Public Heath,Religion2020,2000,4
Rutgers University,Cooperation2021,2000,6
Rutgers University,Religion2020,2000,35
Rutherford University,Religion2020,2000,12
RWTH Aachen University,Cooperation2021,2000,1
RWTH Aachen University,Religion2020,2000,1
Ryerson University,Cooperation2021,2000,5
Ryerson University,Religion2020,2000,1
Saarland University,Cooperation2021,2000,2
Sackler School of Medicine,Cooperation2021,2000,2
Saint Ignatius Church,Religion2020,2000,1
Saint Joseph's Universty,Religion2020,2000,2
Saint Louis University,Cooperation2021,2000,2
Saint Louis University,CooperationAndReligion,2000,1
Saint Louis University,Religion2020,2000,27
Saint Mary's College,Religion2020,2000,7
Saint Mary's University,Religion2020,2000,1
Saint Meinrad Seminary and School of Theology,Cooperation2021,2000,1
Saint Meinrad Seminary and School of Theology,Religion2020,2000,7
Saint Paul University,Religion2020,2000,9
Salem College,Religion2020,2000,2
Salesian Pontifical University,Religion2020,2000,1
Samford University,Cooperation2021,2000,1
Samford University,Religion2020,2000,7
Sampoerna Capital,Religion2020,2000,1
San Diego State University,Cooperation2021,2000,1
San Diego State University,Religion2020,2000,11
San Francisco State University,Cooperation2021,2000,1
San Francisco State University,Religion2020,2000,6
San Francisco Theological Seminary,Cooperation2021,2000,1
San Francisco Theological Seminary,Religion2020,2000,3
San Jose State University,Religion2020,2000,2
Sansone Family Center for Well-Being,Religion2020,2000,1
Santa Clara University,Cooperation2021,2000,1
Santa Clara University,Religion2020,2000,37
Santa Fe Institute,Cooperation2021,2000,11
Sapienza University of Rome,Cooperation2021,2000,1
Sapienza University of Rome,Religion2020,2000,4
saudi center for organ transplantation,Cooperation2021,2000,1
saudi center for organ transplantation,CooperationAndReligion,2000,1
saudi center for organ transplantation,Religion2020,2000,2
Saybrook Graduate School,Religion2020,2000,8
Saybrook University,Religion2020,2000,7
Scarritt College,Religion2020,2000,2
School for Advanced Studies in the Social Sciences,Religion2020,2000,3
School of Oriental and African Studies,Religion2020,2000,5
Schussental Clinic,Religion2020,2000,3
SCIENCE magazine,Cooperation2021,2000,1
SCIENCE magazine,Religion2020,2000,2
Sciences Po,Cooperation2021,2000,1
Sciences Po,Religion2020,2000,9
Scripps College,Religion2020,2000,2
Scripps Research Institute,Cooperation2021,2000,3
SDM College of Naturopathy and Yogic Sciences,Religion2020,2000,1
Search Institute,Religion2020,2000,3
Seattle Pacific University,Religion2020,2000,4
Seattle University,Religion2020,2000,3
Second Military Medical University,Cooperation2021,2000,1
Seirei Mikatahara General Hospital,Religion2020,2000,3
Self-employed,Religion2020,2000,7
Seoul National University,Cooperation2021,2000,1
Seton Hall University,Religion2020,2000,6
Seward County Health Department,Cooperation2021,2000,1
Seward County Health Department,CooperationAndReligion,2000,1
Seward County Health Department,Religion2020,2000,2
Shaanxi Normal University,Religion2020,2000,4
Shalem Institute for Spiritual Formation,Religion2020,2000,4
Shandong University,Cooperation2021,2000,1
Shanghai Jiao Tong University,Cooperation2021,2000,1
Shanghai Maritime University,Cooperation2021,2000,1
Sheffield Hallam University,Religion2020,2000,11
Shimane University,Cooperation2021,2000,1
Shimer College,Religion2020,2000,2
Sichuan University,Cooperation2021,2000,2
Simon Fraser University,Cooperation2021,2000,2
Simon Fraser University,Religion2020,2000,2
Sinai Urban Health Institute,Religion2020,2000,2
Singapore Management University,Religion2020,2000,5
Skidmore College,Cooperation2021,2000,1
Skidmore College,Religion2020,2000,2
Smith College,Religion2020,2000,6
Smithsonian Tropical Research Institute,Cooperation2021,2000,7
SOAS University of London,Cooperation2021,2000,2
SOAS University of London,Religion2020,2000,13
Social Sciences and Humanities Research Council of Canada,Religion2020,2000,2
Society of General Internal Medicine,Religion2020,2000,1
Sofia University,Religion2020,2000,2
Sophia University,Religion2020,2000,9
Sorbonne University,Religion2020,2000,6
South Dakota State University,Religion2020,2000,13
Southeastern Louisiana University,Religion2020,2000,1
Southern Baptist Theological Seminary,Religion2020,2000,3
Southern Illinois University,Cooperation2021,2000,1
Southern Illinois University,CooperationAndReligion,2000,1
Southern Illinois University,Religion2020,2000,21
Southern Methodist University,Religion2020,2000,11
Southwest Missouri State University,Religion2020,2000,3
Spiritual Competency Academy,Religion2020,2000,1
Sri Aurobindo Centre for Advanced Research,Religion2020,2000,5
SS. Cyril and Methodius Byzantine Catholic Seminary,Religion2020,2000,1
Ss. Peter & Paul Parish,Religion2020,2000,3
SSM Health,Religion2020,2000,4
St Andrews University,Religion2020,2000,1
St George & Sutherland Clinical School,Cooperation2021,2000,1
St George & Sutherland Clinical School,Religion2020,2000,1
St Mary's Centre for Religion and Education,Religion2020,2000,3
St Mary's University,Religion2020,2000,2
St. Andrews University,Religion2020,2000,3
St. Bonaventure University,Religion2020,2000,2
St. Catharine's College,Religion2020,2000,1
"St. Edmund Hall, Oxford",Religion2020,2000,3
St. Edmundsbury,Religion2020,2000,2
St. Edward's University,Religion2020,2000,3
St. Francis Xavier University,Religion2020,2000,7
St. Jerome's University,Religion2020,2000,2
St. John Fisher College,Religion2020,2000,1
St. John's College,Religion2020,2000,4
"St. Johns College, Cambridge",Cooperation2021,2000,1
"St. Johns College, Cambridge",Religion2020,2000,4
St. John's Medical College,Cooperation2021,2000,1
St. John's Medical College,Religion2020,2000,1
St. John's Seminary,Religion2020,2000,3
St. John's University,Religion2020,2000,3
St. Joseph's University,Religion2020,2000,4
St. Jude Children's Hospital,Religion2020,2000,1
St. Louis University,Cooperation2021,2000,1
St. Louis University,Religion2020,2000,3
St. Marylebone Healing and Counseling Center,Religion2020,2000,2
St. Mary's Seminary and University,Religion2020,2000,1
"St. Mary's University, Twickenham",Religion2020,2000,1
St. Olaf College,Religion2020,2000,7
St. Patrick's College,Religion2020,2000,2
St. Patrick's Seminary,Religion2020,2000,1
St. Paul's institute of Philosophy,Religion2020,2000,1
St. Petersburg State University,Religion2020,2000,4
St. Vincent College,Religion2020,2000,4
St. Vladimir's Orthodox Theological Seminary,Religion2020,2000,2
Staffordshire University,Cooperation2021,2000,1
Staffordshire University,Religion2020,2000,20
Stanford University,Cooperation2021,2000,26
Stanford University,Religion2020,2000,47
Stanley Royd Hospital,Religion2020,2000,2
State University of Campinas,Religion2020,2000,2
State University of New York,Religion2020,2000,1
State University of New York at Albany,Cooperation2021,2000,1
State University of New York at Albany,Religion2020,2000,8
State University of New York at Binghamton University,Cooperation2021,2000,5
State University of New York at Binghamton University,Religion2020,2000,2
State University of New York at Buffalo,Cooperation2021,2000,2
State University of New York at Buffalo,Religion2020,2000,2
State University of New York at Oneonta,Religion2020,2000,6
State University of New York at Stony Brook,Religion2020,2000,1
"State University of New York, Old Westbury",Religion2020,2000,1
Stellenbosch University,Cooperation2021,2000,2
Stellenbosch University,Religion2020,2000,9
Stetson University,Religion2020,2000,8
STH Basel,Religion2020,2000,1
Stockholm Läns Landsting,Religion2020,2000,2
Stockholm School of Economics,Cooperation2021,2000,4
Stockholm University,Cooperation2021,2000,2
Stockholm University,Religion2020,2000,22
Stonehill College,Religion2020,2000,5
Stony Brook School of Medicine University of New York,Cooperation2021,2000,1
Stony Brook University,Cooperation2021,2000,10
Stony Brook University,CooperationAndReligion,2000,1
Stony Brook University,Religion2020,2000,7
Strayer College,Cooperation2021,2000,1
Strayer College,Religion2020,2000,5
Strong BRAIN Institute,Cooperation2021,2000,1
Strong BRAIN Institute,Religion2020,2000,1
Studia Traditionis Theologiae,Religion2020,2000,1
Suffolk University,Religion2020,2000,3
Sungkyunkwan University (SKKU),Cooperation2021,2000,5
SUNY Buffalo,Religion2020,2000,1
SUNY Fredonia,Religion2020,2000,1
SUNY Upstate Medical University,Cooperation2021,2000,1
Surrey University,Religion2020,2000,1
SVYASA University,Religion2020,2000,1
Swansea University,Religion2020,2000,5
Swarthmore College,Religion2020,2000,5
Swinburne University of Technology,Religion2020,2000,1
Swiss Federal Institute of Technology Zurich,Religion2020,2000,1
Swiss Institute for Experimental Cancer Research,Cooperation2021,2000,4
Sydney College of Divinity,Religion2020,2000,7
Syracuse University,Cooperation2021,2000,6
Syracuse University,Religion2020,2000,21
Szabo Consultnacy LTD,Cooperation2021,2000,1
T&C Chen Center for Social and Decision Neuroscience,Cooperation2021,2000,2
Taisho University,Cooperation2021,2000,2
Taos Institute,Religion2020,2000,1
Taras Shevchenko National University of Kyiv,Religion2020,2000,1
Taylor Seminary,Religion2020,2000,1
Taylor University,Religion2020,2000,2
Technical University of Berlin,Religion2020,2000,7
Technical University of Denmark,Cooperation2021,2000,2
Technische Universität Darmstadt,Cooperation2021,2000,1
Teesside University,Cooperation2021,2000,2
Teesside University,Religion2020,2000,1
Tel Aviv University,Cooperation2021,2000,14
Tel Aviv University,Religion2020,2000,13
Temple University,Religion2020,2000,15
Templeton Religion Trust,Religion2020,2000,2
Tennessee State University,Religion2020,2000,5
Texas A&M University,Cooperation2021,2000,5
Texas A&M University,Religion2020,2000,17
Texas Christian University,Religion2020,2000,6
Texas State University,Cooperation2021,2000,1
Texas State University,Religion2020,2000,2
Texas Tech University,Cooperation2021,2000,1
Texas Tech University,Religion2020,2000,14
The Artic University of Norway,Religion2020,2000,1
The Aspen Institute,Religion2020,2000,2
The Brain Research Institute,Religion2020,2000,3
The Catholic University of America,Cooperation2021,2000,1
The Catholic University of America,Religion2020,2000,4
The Center for Mind Body Medicine,Religion2020,2000,2
The Chinese University of Hong Kong,Cooperation2021,2000,1
The Chopra Foundation,Religion2020,2000,2
The Coventry Association for Pastoral Psychology,Religion2020,2000,1
the Drug Research Center of the Sant Pau Hospital,Religion2020,2000,2
The Essential Medical Technology Foundation,Cooperation2021,2000,1
The Essential Medical Technology Foundation,Religion2020,2000,1
The European Graduate School,Religion2020,2000,1
The Hebrew University,Religion2020,2000,6
The International Institute of Organisational Psychological Medicine,Religion2020,2000,5
The Israel Society for the Prevention of Alcoholism,Religion2020,2000,1
The Key Institute for Brain-Mind Research,Religion2020,2000,1
"The Kinneret Center on Peace, Securityand Society",Religion2020,2000,1
The Lancet,Cooperation2021,2000,3
The Middle East Technical University,Cooperation2021,2000,4
The National Academy of Sciences of Ukraine,Religion2020,2000,10
The New York Botanical Garden,Cooperation2021,2000,2
The New York Botanical Garden,Religion2020,2000,3
The Open University,Religion2020,2000,1
The Queens Foundation,Religion2020,2000,4
The Science Council of Japan,Cooperation2021,2000,1
The Tiltenburg,Religion2020,2000,1
The University of Edinburgh,Religion2020,2000,2
The University of Hong Kong,Religion2020,2000,1
The University of Manchester,Religion2020,2000,3
The University of Texas at Austin,Religion2020,2000,1
The University of the South Sewanee,Religion2020,2000,1
The University of Trans Disciplinary Health Sciences & Technology,Religion2020,2000,1
The Van Leer Jerusalem Institute,Religion2020,2000,2
Theopolis Institute,Religion2020,2000,4
Thomas Jefferson University,Religion2020,2000,8
Tianjin University of Traditional Chinese Medicine,Religion2020,2000,1
Tidewater Child and Family Behavioral Health,Religion2020,2000,2
Tilburg University,Cooperation2021,2000,6
Tilburg University,Religion2020,2000,16
Tirana University,Religion2020,2000,1
Toho University School of Medicine,Cooperation2021,2000,1
Tohoku University,Religion2020,2000,1
Tokyo Gakugei University,Cooperation2021,2000,2
Tokyo Institute of Technology,Cooperation2021,2000,7
Tokyo Medical and Dental University,Religion2020,2000,2
Tokyo University of Science,Cooperation2021,2000,2
Tokyo Women's Medical University,Cooperation2021,2000,1
Tokyo Women's Medical University,Religion2020,2000,6
Tom Baker Cancer Center,Religion2020,2000,3
Toronto School of Theology,Religion2020,2000,3
Torrens University,Religion2020,2000,1
Transnational Family Research Institute,Cooperation2021,2000,1
Trent University,Religion2020,2000,9
Tribunal of the Catholic Diocese of Colombus,Religion2020,2000,1
Trilateral Research Ltd,Cooperation2021,2000,1
Trinity College,Cooperation2021,2000,4
Trinity College,Religion2020,2000,7
Trinity College Dublin,Cooperation2021,2000,1
Trinity College Dublin,Religion2020,2000,15
Trinity College of Arts & Sciences,Cooperation2021,2000,1
Trinity College of Arts & Sciences,Religion2020,2000,8
"Trinity College, Cambridge",Religion2020,2000,8
Trinity Evangelical Divinity School,Religion2020,2000,4
Trinity International University,Religion2020,2000,3
Trinity Theological College,Religion2020,2000,1
Trinity University,Religion2020,2000,6
Trinity Western University,Religion2020,2000,8
Tripler Army Medical Center,Religion2020,2000,1
Trnavska Univerzita,Religion2020,2000,1
Troy University,Cooperation2021,2000,2
Tufts University,Religion2020,2000,6
Tufts University School of Medicine,Religion2020,2000,2
Tulane University,Cooperation2021,2000,3
Tulane University,CooperationAndReligion,2000,3
Tulane University,Religion2020,2000,13
Tyndale University,Religion2020,2000,2
U.S. Department of Health and Human Services,Religion2020,2000,1
U.S. Naval War College,Religion2020,2000,9
UC Los Angeles,Religion2020,2000,1
UC San Diego,Religion2020,2000,2
UC Santa Cruz,Religion2020,2000,3
UCHealth,Religion2020,2000,1
UK Parliament,Cooperation2021,2000,1
UK Parliament,Religion2020,2000,2
Ukrainian Association of Ressearchers of Religion,Religion2020,2000,2
Ulm University,Religion2020,2000,3
Ulster University,Cooperation2021,2000,1
Ulster University,Religion2020,2000,5
Umeå University,Religion2020,2000,1
UNESCO,Cooperation2021,2000,1
Uninettuno international telematic university,Cooperation2021,2000,4
Union College,Religion2020,2000,3
Union Congregational United Church of Christ,Religion2020,2000,1
Union Presbyterian Seminary,Religion2020,2000,4
Union Theological College,Religion2020,2000,2
Union Theological Seminary,Religion2020,2000,15
United Arab Emirates University,Cooperation2021,2000,8
United Arab Emirates University,Religion2020,2000,1
United Nations,Religion2020,2000,1
United States Naval Academy,Religion2020,2000,9
United Theological College,Religion2020,2000,2
United Theological Seminary of the Twin Cities,Religion2020,2000,5
Universidad Autonoma Metropolitana,Religion2020,2000,3
Universidad Carlos III de Madrid,Cooperation2021,2000,1
Universidad Nacional de Educacion a Distancia,Cooperation2021,2000,3
Universidad Nacional de La Pampa,Religion2020,2000,2
Universidad Nacional de Rosario  Universidad Nacional de Rosario  Universidad Nacional de Rosario,Religion2020,2000,2
Universidade de Lisboa,Cooperation2021,2000,1
Universidade Federal de São Paulo,Religion2020,2000,1
Università della Svizzera italiana,Cooperation2021,2000,2
Universitas Gadjah Mada,Religion2020,2000,4
Universitas Islam Negeri Sultan Maulana Hasanuddin Banten,Religion2020,2000,1
Universitas Islam Negeri Sunan Kalijaga,Religion2020,2000,1
universitas muhammadiyah yogyakarta,Religion2020,2000,1
Universitat Autònoma de Barcelona,Religion2020,2000,2
Universitat Gottingngen,Religion2020,2000,1
Universitat Wurzburg,Religion2020,2000,4
Université Catholique de Louvain,Religion2020,2000,15
Université de Fribourg,Religion2020,2000,1
Universite de Lorraine,Religion2020,2000,1
Université de Rennes 1,Cooperation2021,2000,1
Universite d'Orleans,Religion2020,2000,1
Universite du Luxembourg,Cooperation2021,2000,1
Universite Laval,Religion2020,2000,3
Université Libre de Bruxelles,Cooperation2021,2000,3
Université Libre in Brussels,Cooperation2021,2000,3
Universite Sorbonne Paris Nord,Cooperation2021,2000,1
Universiti Putra Malaysia,Religion2020,2000,1
Universiti Teknologi MARA,Religion2020,2000,1
"University at Albany, The State University of New York",Religion2020,2000,2
University College Chester,Religion2020,2000,3
University College Cork,Cooperation2021,2000,5
University College Cork,Religion2020,2000,3
University College Dublin,Cooperation2021,2000,1
University College Dublin,Religion2020,2000,2
University College London,Cooperation2021,2000,16
University College London,CooperationAndReligion,2000,1
University College London,Religion2020,2000,46
University College of North Wales,Religion2020,2000,1
University College Utrecht,Cooperation2021,2000,3
University Hospital Cologne,Religion2020,2000,1
University Hospital Lewisham,Religion2020,2000,3
University Hospital of Odense,Cooperation2021,2000,1
University Hospital Virgen de las Nieves,Religion2020,2000,1
University Hospitals Connor,Religion2020,2000,2
University Medical Center Gottingen,Religion2020,2000,1
University Medical Center Utrecht,Cooperation2021,2000,2
University of Aarhus,Cooperation2021,2000,2
University of Aarhus,Religion2020,2000,7
University of Aberdeen,Cooperation2021,2000,3
University of Aberdeen,Religion2020,2000,27
University of Adelaide,Cooperation2021,2000,4
University of Adelaide,Religion2020,2000,3
University of Agder,Religion2020,2000,6
University of Akron,Religion2020,2000,8
University of Akureyri,Cooperation2021,2000,1
University of Akureyri,Religion2020,2000,1
University of Alabama,Religion2020,2000,3
University of Alabama at Birmingham,Religion2020,2000,19
University of Alabama in Huntsville,Religion2020,2000,1
University of Albany,Religion2020,2000,2
University of Alberta,Cooperation2021,2000,2
University of Alberta,Religion2020,2000,16
University of Alicante,Cooperation2021,2000,1
University of Allahabad,Religion2020,2000,1
University of Amsterdam,Cooperation2021,2000,20
University of Amsterdam,CooperationAndReligion,2000,2
University of Amsterdam,Religion2020,2000,17
University of Antwerp,Cooperation2021,2000,2
University of Arizona,Cooperation2021,2000,12
University of Arizona,Religion2020,2000,30
University of Arkansas,Cooperation2021,2000,1
University of Arkansas,Religion2020,2000,9
University of Arkansas at Little Rock,Religion2020,2000,4
University of Arkansas for Medical Science,Religion2020,2000,5
University of Athens,Religion2020,2000,1
University of Auckland,Cooperation2021,2000,7
University of Auckland,Religion2020,2000,12
University of Ballarat,Religion2020,2000,4
University of Barcelona,Religion2020,2000,2
University of Bari Aldo Moro,Religion2020,2000,1
University of Basel,Cooperation2021,2000,10
University of Basel,Religion2020,2000,8
University of Bath,Cooperation2021,2000,5
University of Bath,Religion2020,2000,13
University of Bayreuth,Religion2020,2000,2
University of Bedfordshire,Cooperation2021,2000,1
University of Belgrade,Religion2020,2000,2
University of Bergen,Religion2020,2000,14
University of Bern,Cooperation2021,2000,1
University of Bern,Religion2020,2000,3
University of Bielefeld,Cooperation2021,2000,3
University of Bielefeld,Religion2020,2000,7
University of Birmingham,Cooperation2021,2000,3
University of Birmingham,Religion2020,2000,65
University of Bonn,Cooperation2021,2000,6
University of Bonn,Religion2020,2000,19
University of Bordeaux,Religion2020,2000,1
University of Botswana,Religion2020,2000,2
University of Bradford,Cooperation2021,2000,1
University of Bradford,Religion2020,2000,3
University of Brasilia,Cooperation2021,2000,2
University of Bremen,Cooperation2021,2000,7
University of Bremen,Religion2020,2000,11
University of Bridgeport,Religion2020,2000,2
University of Brighton,Religion2020,2000,4
University of Bristol,Cooperation2021,2000,10
University of Bristol,CooperationAndReligion,2000,1
University of Bristol,Religion2020,2000,39
University of British Columbia,Cooperation2021,2000,26
University of British Columbia,CooperationAndReligion,2000,1
University of British Columbia,Religion2020,2000,30
University of Brunei Darussalam,Religion2020,2000,1
University of Buckingham,Cooperation2021,2000,2
University of Buffalo,Cooperation2021,2000,2
University of Buffalo,Religion2020,2000,1
University of Calgary,Religion2020,2000,21
University of California,Cooperation2021,2000,1
University of California - Berkeley,Cooperation2021,2000,10
University of California - Berkeley,CooperationAndReligion,2000,1
University of California - Berkeley,Religion2020,2000,18
University of California - Davis,Cooperation2021,2000,3
University of California - Davis,Religion2020,2000,8
University of California - Irvine,Cooperation2021,2000,6
University of California - Irvine,Religion2020,2000,15
University of California - Los Angeles,Cooperation2021,2000,8
University of California - Los Angeles,Religion2020,2000,35
University of California - Riverside,Cooperation2021,2000,12
University of California - Riverside,Religion2020,2000,7
University of California - San Diego,Cooperation2021,2000,3
University of California - San Diego,Religion2020,2000,5
University of California - San Francisco,Religion2020,2000,33
University of California - Santa Barbara,Cooperation2021,2000,2
University of California - Santa Barbara,Religion2020,2000,16
"University of California, Berkeley",Cooperation2021,2000,10
"University of California, Berkeley",Religion2020,2000,27
"University of California, Davis",Cooperation2021,2000,7
"university of california, davis",Religion2020,2000,6
"University of California, Irvine",Cooperation2021,2000,9
"University of California, Irvine",CooperationAndReligion,2000,1
"University of California, Irvine",Religion2020,2000,21
"University of California, Los Angeles",Cooperation2021,2000,11
"University of California, Los Angeles",Religion2020,2000,12
"University of California, Riverside",Cooperation2021,2000,3
"University of California, Riverside",Religion2020,2000,14
"University of California, San Diego",Cooperation2021,2000,5
"University of California, San Diego",Religion2020,2000,13
"University of California, San Francisco",Cooperation2021,2000,3
"University of California, San Francisco",CooperationAndReligion,2000,3
"University of California, San Francisco",Religion2020,2000,4
"University of California, Santa Barbara",Cooperation2021,2000,12
"University of California, Santa Barbara",Religion2020,2000,18
"University of California, Santa Cruz",Cooperation2021,2000,13
University of Cambridge,Cooperation2021,2000,43
University of Cambridge,CooperationAndReligion,2000,2
University of Cambridge,Religion2020,2000,86
University of Canberra,Religion2020,2000,7
University of Canterbury,Cooperation2021,2000,1
University of Canterbury,Religion2020,2000,4
University of Cape Town,Cooperation2021,2000,4
University of Cape Town,Religion2020,2000,4
University of Central England,Religion2020,2000,5
University of Central Florida,Cooperation2021,2000,1
University of Central Florida,CooperationAndReligion,2000,1
University of Central Florida,Religion2020,2000,5
University of Central Missouri,Religion2020,2000,3
University of Chester,Religion2020,2000,21
University of Chicago,Cooperation2021,2000,6
University of Chicago,Religion2020,2000,65
University of Chicago Divinity School,Religion2020,2000,7
University of Chichester,Religion2020,2000,5
University of Cincinnati,Religion2020,2000,7
University of Cologne,Cooperation2021,2000,9
University of Cologne,Religion2020,2000,1
University of Colorado,Cooperation2021,2000,2
University of Colorado Anschutz Medical Campus,Cooperation2021,2000,1
University of Colorado Anschutz Medical Campus,Religion2020,2000,6
University of Colorado at Boulder,Cooperation2021,2000,4
University of Colorado at Boulder,Religion2020,2000,10
University of Colorado at Colorado Springs,Cooperation2021,2000,3
University of Colorado at Colorado Springs,Religion2020,2000,5
University of Colorado Boulder,Religion2020,2000,5
University of Colorado Denver,Cooperation2021,2000,1
University of Colorado Denver,Religion2020,2000,11
University of Colorado Medical School,Religion2020,2000,2
"University of Colorado, Boulder",Cooperation2021,2000,5
"University of Colorado, Boulder",CooperationAndReligion,2000,1
"University of Colorado, Boulder",Religion2020,2000,8
University of Connecticut,Cooperation2021,2000,6
University of Connecticut,CooperationAndReligion,2000,2
University of Connecticut,Religion2020,2000,31
University of Constance,Religion2020,2000,1
University of Copenhagen,Cooperation2021,2000,3
University of Copenhagen,Religion2020,2000,18
University of Crete,Religion2020,2000,1
University of Cumbria,Religion2020,2000,7
University of Cyprus,Religion2020,2000,2
University of Dayton,Religion2020,2000,11
University of Delaware,Cooperation2021,2000,2
University of Delaware,Religion2020,2000,2
University of Delhi,Cooperation2021,2000,1
University of Delhi,Religion2020,2000,5
University of Denver,Cooperation2021,2000,1
University of Denver,Religion2020,2000,5
University of Derby,Cooperation2021,2000,5
University of Derby,Religion2020,2000,2
University of Detroit Mercy,Religion2020,2000,8
University of Divinity,Religion2020,2000,3
University of Dundee,Cooperation2021,2000,1
University of Dundee,Religion2020,2000,1
University of Durham,Religion2020,2000,10
University of Düsseldorf,Religion2020,2000,2
University of East Anglia,Cooperation2021,2000,14
University of East Anglia,Religion2020,2000,9
University of East London,Cooperation2021,2000,1
University of East London,Religion2020,2000,1
University of Eastern Piedmont,Religion2020,2000,1
University of Edinburgh,Cooperation2021,2000,16
University of Edinburgh,Religion2020,2000,94
University of Erfurt,Religion2020,2000,5
University of Erlangen,Religion2020,2000,1
University of Erlangen-Nuremberg,Cooperation2021,2000,2
University of Erlangen-Nuremberg,Religion2020,2000,4
University of Essex,Cooperation2021,2000,2
University of Essex,Religion2020,2000,5
University of Exeter,Cooperation2021,2000,8
University of Exeter,CooperationAndReligion,2000,1
University of Exeter,Religion2020,2000,63
University of Florida,Cooperation2021,2000,7
University of Florida,Religion2020,2000,34
University of Frankfurt,Religion2020,2000,3
University of Freiburg,Religion2020,2000,1
University of Fribourg,Cooperation2021,2000,1
University of Fribourg,CooperationAndReligion,2000,1
University of Fribourg,Religion2020,2000,1
University of Fukui,Religion2020,2000,1
University of Galve,Religion2020,2000,1
University of Gastronomic Sciences,Religion2020,2000,2
University of Geneva,Cooperation2021,2000,4
University of Geneva,Religion2020,2000,12
University of Georgia,Cooperation2021,2000,8
University of Georgia,Religion2020,2000,6
University of Ghana,Religion2020,2000,1
University of Glasgow,Cooperation2021,2000,5
University of Glasgow,Religion2020,2000,37
University of Gloucestershire,Cooperation2021,2000,1
University of Gloucestershire,Religion2020,2000,2
University of Gothenburg,Cooperation2021,2000,15
University of Gothenburg,Religion2020,2000,9
University of Göttingen,Cooperation2021,2000,13
University of Göttingen,Religion2020,2000,10
University of Graz,Cooperation2021,2000,6
University of Graz,Religion2020,2000,3
University of Groningen,Cooperation2021,2000,14
University of Groningen,Religion2020,2000,15
University of Guelph,Cooperation2021,2000,8
University of Haifa,Cooperation2021,2000,5
University of Haifa,Religion2020,2000,31
University of Hamburg,Cooperation2021,2000,6
University of Hamburg,Religion2020,2000,2
University of Hannover,Religion2020,2000,4
University of Hawai‘i,Cooperation2021,2000,2
University of Hawai‘i,Religion2020,2000,10
University of Hawai‘i at Mānoa,Religion2020,2000,6
University of Hawai‘i–West O‘ahu,Religion2020,2000,3
University of Hawai'i,Cooperation2021,2000,1
University of Hawai'i,Religion2020,2000,1
University of Hawaii at Manoa,Cooperation2021,2000,1
University of Hawaii at Manoa,Religion2020,2000,6
"University of Hawaii, Hilo",Religion2020,2000,3
"University of Hawaii, Manoa",Cooperation2021,2000,1
"University of Hawaii, Manoa",Religion2020,2000,2
University of Heidelberg,Religion2020,2000,7
University of Helsinki,Cooperation2021,2000,6
University of Helsinki,CooperationAndReligion,2000,1
University of Helsinki,Religion2020,2000,21
University of Hertfordshire,Cooperation2021,2000,2
University of Hertfordshire,Religion2020,2000,2
University of Hong Kong,Cooperation2021,2000,7
University of Hong Kong,Religion2020,2000,12
University of Houston,Cooperation2021,2000,4
University of Houston,CooperationAndReligion,2000,2
University of Houston,Religion2020,2000,16
University of Huddersfield,Religion2020,2000,1
University of Hull,Cooperation2021,2000,1
University of Hull,Religion2020,2000,13
University of Ibadan,Religion2020,2000,2
University of Illinois,Cooperation2021,2000,5
University of Illinois,Religion2020,2000,7
University of Illinois at Chicago,Cooperation2021,2000,5
University of Illinois at Chicago,Religion2020,2000,4
University of Illinois at Urbana-Champaign,Cooperation2021,2000,4
University of Illinois at Urbana-Champaign,Religion2020,2000,28
University of Illinois Urbana-Champaign,Cooperation2021,2000,2
University of Illinois Urbana-Champaign,Religion2020,2000,1
University of Innsbruck,Cooperation2021,2000,5
University of Iowa,Cooperation2021,2000,3
University of Iowa,Religion2020,2000,27
University of Jena,Cooperation2021,2000,2
University of Jyväskylä,Cooperation2021,2000,2
University of Kansas,Cooperation2021,2000,3
University of Kansas,Religion2020,2000,17
University of Kansas Medical Center,Religion2020,2000,1
University of Karachi,Religion2020,2000,1
University of Kent,Cooperation2021,2000,2
University of Kent,Religion2020,2000,21
University of Kent at Canterbury,Religion2020,2000,6
University of Kentucky,Religion2020,2000,18
University of Kiel,Religion2020,2000,7
University of Koblenz-Landau,Religion2020,2000,1
University of Koln,Religion2020,2000,1
University of Konstanz,Cooperation2021,2000,14
University of Konstanz,Religion2020,2000,3
University of KwaZulu-Natal,Religion2020,2000,3
University of Lancaster,Religion2020,2000,9
University of L'Aquila,Cooperation2021,2000,1
University of Lausanne,Cooperation2021,2000,12
University of Lausanne,Religion2020,2000,7
University of Leeds,Religion2020,2000,34
University of Leicester,Cooperation2021,2000,9
University of Leicester,Religion2020,2000,28
University of Leiden,Religion2020,2000,4
University of Leipzig,Cooperation2021,2000,2
University of Leipzig,Religion2020,2000,15
University of Lethbridge,Cooperation2021,2000,4
University of Lethbridge,Religion2020,2000,4
University of Leuven,Cooperation2021,2000,3
University of Leuven,Religion2020,2000,11
University of Lille,Religion2020,2000,5
University of Lille III,Religion2020,2000,1
University of Limerick,Religion2020,2000,3
University of Lincoln,Religion2020,2000,2
University of Liverpool,Cooperation2021,2000,1
University of Liverpool,Religion2020,2000,6
University of London,Religion2020,2000,42
University of Louisville,Cooperation2021,2000,4
University of Louisville,Religion2020,2000,18
University of Louvain,Cooperation2021,2000,1
University of Lucerne,Religion2020,2000,1
University of Lucknow,Religion2020,2000,1
University of Ludwigsburg,Religion2020,2000,1
University of Luxembourg,Cooperation2021,2000,3
University of Lynchburg,Religion2020,2000,3
University of Lyon,Cooperation2021,2000,1
University of Maastricht,Religion2020,2000,2
University of Macau,Religion2020,2000,2
University of Madrid,Religion2020,2000,4
University of Maine,Religion2020,2000,2
University of Malawi,Cooperation2021,2000,4
University of Malawi,Religion2020,2000,3
University of Malaya,Cooperation2021,2000,2
University of Malaya,Religion2020,2000,4
University of Malta,Religion2020,2000,3
University of Manchester,Cooperation2021,2000,3
University of Manchester,Religion2020,2000,39
University of Manitoba,Religion2020,2000,7
University of Mannheim,Religion2020,2000,3
University of Marburg,Religion2020,2000,11
University of Maribor,Religion2020,2000,1
University of Mary Washington,Religion2020,2000,2
University of Maryland,Cooperation2021,2000,6
University of Maryland,CooperationAndReligion,2000,1
University of Maryland,Religion2020,2000,18
"University of Maryland, Baltimore",Religion2020,2000,2
"University of Maryland, Baltimore Country",Cooperation2021,2000,1
"University of Maryland, College Park",Cooperation2021,2000,5
"University of Maryland, College Park",Religion2020,2000,19
University of Massachusetts,Religion2020,2000,1
University of Massachusetts - Amherst,Cooperation2021,2000,17
University of Massachusetts - Amherst,CooperationAndReligion,2000,1
University of Massachusetts - Amherst,Religion2020,2000,5
University of Massachusetts - Boston,Religion2020,2000,5
University of Massachusetts Amherst,Cooperation2021,2000,9
University of Massachusetts Medical School,Cooperation2021,2000,1
University of Massachusetts Medical School,Religion2020,2000,2
University of Massachusetts Memorial Medical Center,Religion2020,2000,15
"University of Massachusetts, Boston",Cooperation2021,2000,5
"University of Massachusetts, Dartmouth",Religion2020,2000,1
University of Melbourne,Cooperation2021,2000,6
University of Melbourne,Religion2020,2000,10
University of Memphis,Religion2020,2000,5
University of Miami,Cooperation2021,2000,5
University of Miami,Religion2020,2000,32
University of Michigan,Cooperation2021,2000,23
University of Michigan,Religion2020,2000,101
University of Michigan Medical School,Religion2020,2000,1
University of Michigan School of Public Health,Religion2020,2000,1
"University of Michigan, Dearborn",Religion2020,2000,1
University of Milan,Cooperation2021,2000,1
University of Minnesota,Cooperation2021,2000,23
University of Minnesota,Religion2020,2000,54
University of Mississippi,Cooperation2021,2000,1
University of Mississippi,Religion2020,2000,7
University of Missouri,Cooperation2021,2000,9
University of Missouri,Religion2020,2000,12
University of Missouri Mizzou,Cooperation2021,2000,3
University of Missouri Mizzou,Religion2020,2000,7
University of Missouri-Kansas City,Religion2020,2000,5
University of Missouri-St. Louis,Religion2020,2000,1
University of Modena and Reggio Emilia,Cooperation2021,2000,4
University of Montpellier,Cooperation2021,2000,2
University of Montreal,Cooperation2021,2000,7
University of Montreal,Religion2020,2000,39
University of Mostar,Religion2020,2000,1
University of Muenster,Religion2020,2000,10
University of Munich,Cooperation2021,2000,2
University of Munich,Religion2020,2000,10
University of Munster,Religion2020,2000,7
University of Münster,Religion2020,2000,15
University of Namur,Cooperation2021,2000,1
University of Nantes,Religion2020,2000,1
University of Navarra,Religion2020,2000,1
University of Nebraska,Cooperation2021,2000,4
University of Nebraska,Religion2020,2000,3
"University of Nebraska, Lincoln",Religion2020,2000,5
"University of Nebraska, Omaha",Religion2020,2000,1
University of Nebraska-Lincoln,Cooperation2021,2000,2
University of Nebraska-Lincoln,Religion2020,2000,1
University of Neuchâtel,Cooperation2021,2000,3
University of Neuchâtel,Religion2020,2000,3
University of Nevada,Cooperation2021,2000,1
University of Nevada,Religion2020,2000,3
University of Nevada - Reno,Cooperation2021,2000,1
University of Nevada - Reno,CooperationAndReligion,2000,1
University of Nevada - Reno,Religion2020,2000,18
"University of Nevada, Las Vegas",Cooperation2021,2000,1
"University of Nevada, Las Vegas",Religion2020,2000,8
University of New Brunswick,Cooperation2021,2000,1
University of New Brunswick,Religion2020,2000,8
University of New England,Cooperation2021,2000,3
University of New England,Religion2020,2000,10
University of New Hampshire,Religion2020,2000,8
University of New Mexico,Cooperation2021,2000,6
University of New Mexico,Religion2020,2000,19
University of New South Wales,Cooperation2021,2000,5
University of New South Wales,Religion2020,2000,9
University of Newcastle,Cooperation2021,2000,4
University of Newcastle,Religion2020,2000,2
University of Nice,Cooperation2021,2000,1
University of Nigeria,Religion2020,2000,1
University of North Carolina,Cooperation2021,2000,5
University of North Carolina,Religion2020,2000,1
University of North Carolina - Chapel Hill,Religion2020,2000,6
University of North Carolina at Chapel Hill,Cooperation2021,2000,16
University of North Carolina at Chapel Hill,CooperationAndReligion,2000,1
University of North Carolina at Chapel Hill,Religion2020,2000,50
University of North Carolina at Charlotte,Religion2020,2000,5
University of North Carolina at Greensboro,Religion2020,2000,16
University of North Carolina at Wilmington,Religion2020,2000,1
University of North Carolina Hospitals,Religion2020,2000,1
University of North Dakota,Religion2020,2000,3
University of North Florida,Religion2020,2000,1
University of North Texas,Religion2020,2000,10
University of Northampton,Religion2020,2000,4
University of Notre Dame,Cooperation2021,2000,1
University of Notre Dame,Religion2020,2000,107
University of Notre Dame Australia,Cooperation2021,2000,1
University of Notre Dame Australia,Religion2020,2000,1
University of Notre Dame- Australia,Cooperation2021,2000,1
University of Notre Dame- Australia,Religion2020,2000,12
University of Nottingham,Cooperation2021,2000,14
University of Nottingham,Religion2020,2000,33
University of Oregon,Cooperation2021,2000,10
University of Oregon,Religion2020,2000,4
University of Oslo,Cooperation2021,2000,4
University of Oslo,Religion2020,2000,13
University of Otago,Cooperation2021,2000,4
University of Otago,Religion2020,2000,14
University of Ottawa,Cooperation2021,2000,4
University of Ottawa,CooperationAndReligion,2000,1
University of Ottawa,Religion2020,2000,30
University of Oxford,Cooperation2021,2000,25
University of Oxford,Religion2020,2000,145
University of Oxford Mindfulness Research Centre,Cooperation2021,2000,1
University of Oxford Mindfulness Research Centre,Religion2020,2000,1
"University of Oxford, Champion Hall",Religion2020,2000,3
University of Paderborn,Religion2020,2000,1
University of Padua,Religion2020,2000,2
University of Paris,Cooperation2021,2000,2
University of Paris,Religion2020,2000,3
University of Paris VIII,Religion2020,2000,5
University of Pavia,Cooperation2021,2000,2
University of Pavia,Religion2020,2000,1
University of Pécs,Cooperation2021,2000,1
University of Pennsylvania,Cooperation2021,2000,28
University of Pennsylvania,CooperationAndReligion,2000,1
University of Pennsylvania,Religion2020,2000,53
University of Pennsylvania Program for Mindfulness,Religion2020,2000,2
University of Pisa,Cooperation2021,2000,1
University of Pisa,Religion2020,2000,1
University of Pittsburgh,Cooperation2021,2000,11
University of Pittsburgh,CooperationAndReligion,2000,1
University of Pittsburgh,Religion2020,2000,23
University of Plymouth,Cooperation2021,2000,5
University of Porto,Cooperation2021,2000,1
University of Porto,Religion2020,2000,2
University of Portsmouth,Cooperation2021,2000,2
University of Portsmouth,Religion2020,2000,2
University of Potsdam,Religion2020,2000,1
University of Prešov,Religion2020,2000,1
University of Pretoria,Cooperation2021,2000,6
University of Pretoria,Religion2020,2000,40
University of Québec at Montreal,Religion2020,2000,6
University of Quebec at Trois-Rivières,Religion2020,2000,1
University of Queensland,Cooperation2021,2000,4
University of Queensland,Religion2020,2000,9
University of Reading,Religion2020,2000,17
University of Redlands,Religion2020,2000,8
University of Regensburg,Cooperation2021,2000,5
University of Regensburg,Religion2020,2000,2
University of Regina,Religion2020,2000,7
University of Rennes 2,Religion2020,2000,3
University of Rhode Island,Religion2020,2000,1
University of Richmond,Religion2020,2000,6
University of Rijeka,Religion2020,2000,2
University of Rochester,Cooperation2021,2000,1
University of Rochester,Religion2020,2000,7
University of Roehampton,Cooperation2021,2000,1
University of Roehampton,CooperationAndReligion,2000,1
University of Roehampton,Religion2020,2000,7
University of Rome,Cooperation2021,2000,3
University of Rome,Religion2020,2000,2
University of Rostock,Cooperation2021,2000,1
University of Rostock,CooperationAndReligion,2000,1
University of Rostock,Religion2020,2000,1
University of Saint Mary of the Lake,Religion2020,2000,9
University of San Diego,Religion2020,2000,15
University of San Francisco,Religion2020,2000,6
university of san martin,Religion2020,2000,1
University of Santiago,Religion2020,2000,3
University of São Paulo,Cooperation2021,2000,1
University of Saskatchewan,Cooperation2021,2000,5
University of Saskatchewan,Religion2020,2000,17
University of Savoie,Cooperation2021,2000,1
University of Science and Technology,Cooperation2021,2000,12
University of Science and Technology of China,Cooperation2021,2000,8
University of Scranton,Religion2020,2000,9
University of Shanghai,Cooperation2021,2000,1
University of Shanghai,Religion2020,2000,2
University of Sheffield,Cooperation2021,2000,9
University of Sheffield,Religion2020,2000,6
University of Sherbrooke,Religion2020,2000,7
University of Siegen,Religion2020,2000,1
University of Siena,Cooperation2021,2000,13
University of South Africa,Cooperation2021,2000,1
University of South Africa,Religion2020,2000,15
University of South Australia,Religion2020,2000,1
University of South Carolina,Cooperation2021,2000,1
University of South Carolina,Religion2020,2000,14
University of South Florida,Religion2020,2000,5
University of South Florida - Saint Petersburg,Cooperation2021,2000,1
University of South Florida - Saint Petersburg,Religion2020,2000,5
University of South Wales,Religion2020,2000,2
University of Southampton,Cooperation2021,2000,4
University of Southampton,Religion2020,2000,8
University of South-Eastern Norway,Cooperation2021,2000,1
University of Southern California,Cooperation2021,2000,6
University of Southern California,Religion2020,2000,29
University of Southern Denmark,Cooperation2021,2000,2
University of Southern Denmark,Religion2020,2000,8
University of Southern Maine,Religion2020,2000,1
University of Southern Mississippi,Religion2020,2000,2
University of Split,Religion2020,2000,1
University of St Andrews,Cooperation2021,2000,13
University of St Andrews,Religion2020,2000,38
University of St. Gallen,Religion2020,2000,1
University of St. Thomas,Religion2020,2000,7
University of Stavanger,Cooperation2021,2000,1
University of Stavanger,Religion2020,2000,1
University of Stellenbosch,Religion2020,2000,7
University of Stirling,Cooperation2021,2000,4
University of Stirling,Religion2020,2000,17
University of Strasbourg,Religion2020,2000,6
University of Sunderland,Cooperation2021,2000,3
University of Sunderland,Religion2020,2000,2
University of Surrey,Religion2020,2000,2
University of Sussex,Cooperation2021,2000,18
University of Sussex,Religion2020,2000,9
University of Sydney,Cooperation2021,2000,12
University of Sydney,Religion2020,2000,28
University of Szeged,Religion2020,2000,1
University of Tampere,Religion2020,2000,2
University of Tartu,Religion2020,2000,4
University of Tasmania,Cooperation2021,2000,1
University of Tasmania,Religion2020,2000,7
University of Technology Sydney,Cooperation2021,2000,1
University of Technology Sydney,Religion2020,2000,3
University of Teesside,Religion2020,2000,1
University of Tehran,Cooperation2021,2000,1
University of Tehran,Religion2020,2000,6
University of Tennessee,Cooperation2021,2000,2
University of Tennessee,Religion2020,2000,19
University of Tennessee at Chattanooga,Religion2020,2000,8
University of Texas at Arlington,Cooperation2021,2000,1
University of Texas at Arlington,Religion2020,2000,1
University of Texas at Austin,Cooperation2021,2000,3
University of Texas at Austin,Religion2020,2000,36
University of Texas at Dallas,Cooperation2021,2000,10
University of Texas at Dallas,CooperationAndReligion,2000,1
University of Texas at Dallas,Religion2020,2000,3
University of Texas at Houston,Cooperation2021,2000,1
University of Texas at San Antonio,Religion2020,2000,28
University of Texas Health Science Center at Houston (UTHealth),Religion2020,2000,8
University of Texas MD Anderson Cancer Center,Religion2020,2000,2
University of Texas Medical Branch,Religion2020,2000,3
University of Texas Medical Branch at Galveston,Religion2020,2000,1
University of Texas Rio Grande Valley,Religion2020,2000,4
University of Texas School of Education,Religion2020,2000,1
University of Texas School of Public Health,Cooperation2021,2000,1
University of Texas School of Public Health,Religion2020,2000,17
University of the Free State,Religion2020,2000,5
University of the Highlands and Islands,Religion2020,2000,1
University of the West of England,Cooperation2021,2000,1
University of the West of England,Religion2020,2000,12
University of the Witwatersrand,Religion2020,2000,3
University of Tilburg,Religion2020,2000,3
University of Tokyo,Cooperation2021,2000,11
University of Tokyo,Religion2020,2000,6
University of Toronto,Cooperation2021,2000,17
University of Toronto,CooperationAndReligion,2000,4
University of Toronto,Religion2020,2000,115
University of Trento,Religion2020,2000,4
University of Trier,Religion2020,2000,4
University of Tubigen Germany,Cooperation2021,2000,4
University of Tubigen Germany,Religion2020,2000,6
University of Tubingen,Religion2020,2000,3
University of Tübingen,Religion2020,2000,16
University of Tulsa,Cooperation2021,2000,7
University of Tulsa,Religion2020,2000,4
University of Turin,Cooperation2021,2000,1
University of Turin,Religion2020,2000,2
University of Turku,Religion2020,2000,13
University of Twente,Religion2020,2000,3
University of Ulm,Cooperation2021,2000,1
University of Ulm,Religion2020,2000,5
University of Ulsan,Religion2020,2000,1
University of Ulster,Religion2020,2000,4
University of Utah,Cooperation2021,2000,3
University of Utah,Religion2020,2000,44
University of Utrecht,Cooperation2021,2000,4
University of Utrecht,Religion2020,2000,9
University of Vermont,Cooperation2021,2000,2
University of Vermont,Religion2020,2000,16
University of Verona,Cooperation2021,2000,2
University of Victoria,Cooperation2021,2000,2
University of Victoria,Religion2020,2000,8
University of Vienna,Cooperation2021,2000,12
University of Vienna,Religion2020,2000,16
University of Virginia,Cooperation2021,2000,11
University of Virginia,Religion2020,2000,47
University of Waikato,Religion2020,2000,6
University of Wales,Religion2020,2000,14
University of Wales at Lampeter,Religion2020,2000,2
University of Wales Trinity Saint David,Religion2020,2000,4
University of Warsaw,Cooperation2021,2000,4
University of Warwick,Cooperation2021,2000,8
University of Warwick,CooperationAndReligion,2000,1
University of Warwick,Religion2020,2000,67
University of Washington,Cooperation2021,2000,37
University of Washington,Religion2020,2000,36
University of Washington at Seattle,Cooperation2021,2000,1
University of Washington at Seattle,Religion2020,2000,2
"University of Washington, Seattle",Cooperation2021,2000,1
University of Waterloo,Cooperation2021,2000,5
University of Waterloo,Religion2020,2000,6
University of West England,Religion2020,2000,4
University of West Florida,Religion2020,2000,2
University of West Georgia,Religion2020,2000,3
University of Western Australia,Cooperation2021,2000,2
University of Western Australia,Religion2020,2000,3
University of Western Cape,Religion2020,2000,4
University of Western Macedonia,Religion2020,2000,1
University of Western Ontario,Cooperation2021,2000,6
University of Western Ontario,Religion2020,2000,3
University of Western Sydney,Religion2020,2000,1
University of Westminster,Cooperation2021,2000,7
University of Westminster,Religion2020,2000,2
University of Winchester,Religion2020,2000,20
University of Windsor,Cooperation2021,2000,1
University of Windsor,Religion2020,2000,4
University of Wisconsin,Cooperation2021,2000,4
University of Wisconsin,Religion2020,2000,11
University of Wisconsin - Eau Claire,Religion2020,2000,4
University of Wisconsin - Madison,Cooperation2021,2000,8
University of Wisconsin - Madison,Religion2020,2000,18
University of Wisconsin–Eau Claire,Religion2020,2000,1
University of Wisconsin-Madison,Cooperation2021,2000,3
University of Wisconsin-Madison,Religion2020,2000,7
University of Wisconsin-Milwaukee,Religion2020,2000,6
University of Witten/Herdecke,Religion2020,2000,5
University of Wollongong,Cooperation2021,2000,1
University of Wollongong,Religion2020,2000,2
University of Wolverhampton,Cooperation2021,2000,1
University of Wolverhampton,Religion2020,2000,6
University of Worcester,Religion2020,2000,1
University of Wuppertal,Religion2020,2000,4
University of Würzburg,Religion2020,2000,6
University of Wyoming,Cooperation2021,2000,4
University of Wyoming,Religion2020,2000,3
University of York,Cooperation2021,2000,3
University of York,CooperationAndReligion,2000,1
University of York,Religion2020,2000,14
University of Zagreb,Religion2020,2000,2
University of Zambia,Religion2020,2000,2
University of Zimbabwe,Cooperation2021,2000,2
University of Zimbabwe,Religion2020,2000,6
University of Zurich,Cooperation2021,2000,42
University of Zurich,Religion2020,2000,5
University Psychiatric Center KU Leuven,Cooperation2021,2000,1
Universty of Kansas,Religion2020,2000,2
"UNSW, Sydney",Religion2020,2000,2
Uplift Appalachia,Religion2020,2000,1
UPMC Presbyterian,Religion2020,2000,3
Uppsala University,Cooperation2021,2000,5
Uppsala University,Religion2020,2000,21
US Army,Religion2020,2000,1
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Religion2020,2000,2
Utah State University,Religion2020,2000,4
Utah Valley University,Religion2020,2000,2
Utrecht University,Cooperation2021,2000,1
Utrecht University,Religion2020,2000,29
VA Cincinnatti,Religion2020,2000,3
VA Durham,Religion2020,2000,2
VA HSR&D Center for Health Services Research in Primary Care,Religion2020,2000,2
VA Medical Center- Minneapolis,Religion2020,2000,1
"VA Medical Center, New Orleans",Religion2020,2000,1
VA Pittsburgh Healthcare System,Religion2020,2000,1
Valparaiso University,Religion2020,2000,11
Vanderbilt Divinity School,Religion2020,2000,4
Vanderbilt University,Cooperation2021,2000,6
Vanderbilt University,Religion2020,2000,27
Vanguard University,Religion2020,2000,4
Vassar College,Cooperation2021,2000,1
Vassar College,Religion2020,2000,2
Vatsal Hospital,Religion2020,2000,1
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Cooperation2021,2000,1
Veterans Health Adminstration,Religion2020,2000,2
Viadrina European University,Cooperation2021,2000,3
Victoria University of Wellington,Cooperation2021,2000,4
Victoria University of Wellington,Religion2020,2000,5
VID Specialized University,Religion2020,2000,3
Villanova University,Cooperation2021,2000,1
Villanova University,Religion2020,2000,29
Virginia Beach Psychiatric Center,Religion2020,2000,2
Virginia Commonwealth University,Cooperation2021,2000,2
Virginia Commonwealth University,Religion2020,2000,17
Virginia Department of Health,Religion2020,2000,3
Virginia Hospital Center,Religion2020,2000,1
Virginia Polytechnic Institute and State University,Cooperation2021,2000,6
Virginia Polytechnic Institute and State University,Religion2020,2000,2
Virginia Tech,Cooperation2021,2000,3
Virginia Tech,Religion2020,2000,5
Virginia Theological Seminary,Religion2020,2000,9
Virginia Wesleyan College,Religion2020,2000,3
Vision of Hope Baptist Church,Religion2020,2000,1
Vision2Health BV,Cooperation2021,2000,1
Vision2Health BV,Religion2020,2000,1
Vital Research,Religion2020,2000,1
Vitalant Research Institute,Cooperation2021,2000,3
Vrije Universiteit Amsterdam,Cooperation2021,2000,27
Vrije Universiteit Amsterdam,Religion2020,2000,64
Vrije Universiteit Brussel,Cooperation2021,2000,3
Vrije Universiteit Brussel,Religion2020,2000,2
Vrije Universiteit Medical Center,Religion2020,2000,6
Vrije University Brussels,Cooperation2021,2000,1
Wabash College,Religion2020,2000,9
Wadham College,Cooperation2021,2000,1
Wadham College,Religion2020,2000,1
Wageningen University,Cooperation2021,2000,2
Wageningen University,Religion2020,2000,2
Wake Forest University,Religion2020,2000,11
Wakefield Diocese,Religion2020,2000,1
Walden University,Religion2020,2000,7
Wartburg College,Religion2020,2000,1
Wartburg Theological Seminary,Religion2020,2000,2
Waseda University,Cooperation2021,2000,3
Waseda University,Religion2020,2000,4
Washington and Lee University,Religion2020,2000,5
Washington State University,Religion2020,2000,1
Washington Theological Coalition,Religion2020,2000,1
Washington University in St Louis,Cooperation2021,2000,18
Washington University in St Louis,CooperationAndReligion,2000,1
Washington University in St Louis,Religion2020,2000,45
Waterloo Lutheran Seminary,Religion2020,2000,3
Wayne State University,Cooperation2021,2000,4
Wayne State University,Religion2020,2000,5
We Honor Veterans,Religion2020,2000,1
Webster University,Religion2020,2000,6
Webster University Geneva,Religion2020,2000,2
Weill Cornell Medical College,Religion2020,2000,1
Weill Cornell Medical College in Qatar,Religion2020,2000,1
Weill Medical College of Cornell University,Religion2020,2000,1
Wellesley College,Religion2020,2000,12
Wesley Theological Seminary,Religion2020,2000,3
Wesleyan University,Religion2020,2000,2
West Chester University of Pennsylvania,Cooperation2021,2000,3
West Chester University of Pennsylvania,Religion2020,2000,1
West Virginia University,Cooperation2021,2000,6
West Virginia University,Religion2020,2000,21
Western Carolina University,Religion2020,2000,8
Western Kentucky University,Religion2020,2000,2
Western Michigan University,Religion2020,2000,8
Western Sydney University,Religion2020,2000,9
Western University,Cooperation2021,2000,2
Western University,Religion2020,2000,1
Western Washington University,Religion2020,2000,1
Westfalische University,Religion2020,2000,2
Westfield College,Religion2020,2000,1
Westminster College,Cooperation2021,2000,1
Westminster College,Religion2020,2000,7
Westminster Pastoral Foundation,Religion2020,2000,1
Westminster Seminary California,Religion2020,2000,1
Westmont College,Cooperation2021,2000,1
Westmont College,CooperationAndReligion,2000,1
Westmont College,Religion2020,2000,6
Westphalian Wilhelms University,Religion2020,2000,1
Wheaton College,Religion2020,2000,21
Whitefield Theological Seminary,Religion2020,2000,1
Whitley College,Religion2020,2000,2
WHO collaborating centre for research and training in mental health,Religion2020,2000,2
Wichita State University,Religion2020,2000,2
Wilfrid Laurier University,Cooperation2021,2000,5
Wilfrid Laurier University,CooperationAndReligion,2000,3
Wilfrid Laurier University,Religion2020,2000,16
Wilkes University,Religion2020,2000,3
Willamette University,Cooperation2021,2000,2
Willamette University,Religion2020,2000,3
William & Mary,Religion2020,2000,12
William Lyon University,Religion2020,2000,3
William Paterson University,Religion2020,2000,1
William S. Middleton Memorial Veterans Hospital,Religion2020,2000,1
Williams College,Religion2020,2000,1
Witten/Herdecke University,Religion2020,2000,2
Wittenberg University,Cooperation2021,2000,2
Wolfson College,Cooperation2021,2000,2
Wolfson College,Religion2020,2000,1
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,2000,5
Wonkwang University,Religion2020,2000,1
Woodbrooke Quaker Study Centre,Religion2020,2000,2
Woodstock College,Religion2020,2000,1
Woolf Institute,Religion2020,2000,4
worcester polytechnic institute,Religion2020,2000,1
World Council of Churches,Religion2020,2000,5
World Health Organisation,Cooperation2021,2000,2
World Health Organisation,Religion2020,2000,3
World Health Organization,Cooperation2021,2000,6
World Organisation for Animal Health,Cooperation2021,2000,2
Wrexham Glyndwr University,Religion2020,2000,3
Wright State University,Religion2020,2000,4
Wuhan University,Religion2020,2000,2
Wycliffe College,Religion2020,2000,3
Xavier University,Religion2020,2000,7
Xi’an Jiaotong University,Cooperation2021,2000,1
Yale Divinity School,Religion2020,2000,26
Yale University,Cooperation2021,2000,18
Yale University,CooperationAndReligion,2000,1
Yale University,Religion2020,2000,77
Yale University Mind and Development Lab,Cooperation2021,2000,1
Yale University Mind and Development Lab,Religion2020,2000,15
Yale University School of Medicine,Religion2020,2000,6
Yale-NUS College,Religion2020,2000,3
Yamaguchi Prefectural University,Religion2020,2000,1
Yeshiva University,Cooperation2021,2000,2
Yeshiva University,Religion2020,2000,9
Yeshivah of Flatbush High School,Religion2020,2000,1
Yonsei University,Religion2020,2000,3
York St John University,Religion2020,2000,3
York University,Cooperation2021,2000,3
York University,Religion2020,2000,20
Youngstown State University,Religion2020,2000,3
Zhejiang University,Cooperation2021,2000,1
 École Pratique des Hautes Études,Religion2020,1995,1
 University of the District of Columbia,Religion2020,1995,1
 Xi’an Jiaotong University,Cooperation2021,1995,1
Aahrus University,Religion2020,1995,2
Aarhus University,Cooperation2021,1995,4
Aarhus University,Religion2020,1995,19
Aberdeen University,Cooperation2021,1995,2
Aberdeen University,Religion2020,1995,1
Aberystwyth University,Religion2020,1995,4
Abilene Christian University,Religion2020,1995,1
Abington Memorial Hospital,Religion2020,1995,4
abo akademi university,Religion2020,1995,1
Åbo Akademi University,Religion2020,1995,2
Academia Sinica,Religion2020,1995,5
Achva Academic College,Cooperation2021,1995,1
Achva Academic College,Religion2020,1995,8
Adelphi University,Cooperation2021,1995,3
Advocates for African American Elders,Religion2020,1995,1
Aix-Marseille Université,Religion2020,1995,3
Akrofi-Christaller Institute for Theology,Religion2020,1995,4
Akron General Medical Center,Religion2020,1995,12
Albert Einstein College of Medicine,Cooperation2021,1995,3
Albert Einstein College of Medicine,Religion2020,1995,4
Alfred University,Cooperation2021,1995,1
Alfred University,Religion2020,1995,3
Allegheny College,Religion2020,1995,1
Alliant International University,Religion2020,1995,2
Alphacrucis College,Religion2020,1995,2
American Academy of Nursing,Religion2020,1995,1
American Enterprise Institute,Religion2020,1995,1
American Geographical Society,Religion2020,1995,1
American Jewish University,Religion2020,1995,2
American Medical Association,Religion2020,1995,19
American Museum of Natural History,Religion2020,1995,5
American Sociological Association,Religion2020,1995,1
American University,Cooperation2021,1995,1
American University,Religion2020,1995,9
American University of Beirut,Religion2020,1995,1
American University of Kuwait,Religion2020,1995,2
Amherst College,Religion2020,1995,8
Amsterdam UMC,Religion2020,1995,4
Analytical Centre of the Russian Academy of Sciences,Religion2020,1995,2
Andover Newton Theological School,Religion2020,1995,8
Andrews University,Religion2020,1995,1
Appalachian State University,Religion2020,1995,4
Archdiocese of Johannesburg,Religion2020,1995,1
Arden University,Cooperation2021,1995,1
Argentine Catholic University,Religion2020,1995,3
Arizona State University,Cooperation2021,1995,8
Arizona State University,Religion2020,1995,20
ARTIS International,Cooperation2021,1995,2
Asahikawa Medical University,Cooperation2021,1995,1
Ascension Health,Religion2020,1995,1
Ashland University,Religion2020,1995,1
ASM Affiliates Inc,Religion2020,1995,1
AstraZeneca,Cooperation2021,1995,3
Atlantic School of Theology,Religion2020,1995,4
Auburn University,Religion2020,1995,3
Auckland University of Technology,Religion2020,1995,1
Augusta State College,Religion2020,1995,1
Augusta University,Religion2020,1995,1
Augustana College,Religion2020,1995,2
Augustana University,Religion2020,1995,7
Austin Presbyterian Theological Seminary,Cooperation2021,1995,2
Austin Presbyterian Theological Seminary,Religion2020,1995,2
Australian Catholic University,Cooperation2021,1995,1
Australian Catholic University,Religion2020,1995,14
Australian Council for Educational Research,Religion2020,1995,1
Australian National University,Cooperation2021,1995,3
Australian National University,Religion2020,1995,8
Ave Maria University,Religion2020,1995,3
Avila University,Religion2020,1995,1
Ball State University,Cooperation2021,1995,1
Ball State University,Religion2020,1995,1
Bangor University,Religion2020,1995,4
Baptist Seminary of Kentucky,Religion2020,1995,1
Bar Ilan University,Religion2020,1995,9
Bard College,Religion2020,1995,8
Bar-Ilan University,Cooperation2021,1995,2
Bar-Ilan University,Religion2020,1995,17
Barnard College,Cooperation2021,1995,3
Barnard College,Religion2020,1995,4
Barry University,Religion2020,1995,3
Bashkir State University,Religion2020,1995,12
Bastyr University,Religion2020,1995,1
Bates College,Religion2020,1995,4
Bath Spa University,Religion2020,1995,7
Baylor College of Medicine,Cooperation2021,1995,1
Baylor College of Medicine,Religion2020,1995,1
Baylor University,Cooperation2021,1995,3
Baylor University,Religion2020,1995,51
Beckman Initiative for Macular Research,Religion2020,1995,1
Beckman Research Institute of City of Hope,Religion2020,1995,1
Belmont College,Religion2020,1995,3
Ben Gurion University in the Negev,Religion2020,1995,7
Ben-Gurion University of the Negev,Cooperation2021,1995,1
Ben-Gurion University of the Negev,Religion2020,1995,10
Berry College,Religion2020,1995,4
Bethel College,Religion2020,1995,1
Bethel University,Religion2020,1995,1
biblical-theological academy,Religion2020,1995,2
Bielefeld University,Religion2020,1995,2
Binghamton University,Religion2020,1995,1
Biola University,Religion2020,1995,15
Birbeck University of London,Religion2020,1995,1
Birmingham University,Religion2020,1995,3
Bishop of Guilford,Religion2020,1995,2
Bloomsburg University,Religion2020,1995,3
Blueprint 1543,Religion2020,1995,5
Bocconi University,Cooperation2021,1995,4
Boston College,Cooperation2021,1995,3
Boston College,CooperationAndReligion,1995,1
Boston College,Religion2020,1995,29
Boston University,Cooperation2021,1995,7
Boston University,Religion2020,1995,44
Bournemouth University,Religion2020,1995,1
Bowdoin College,Religion2020,1995,1
Bowling Green State University,Cooperation2021,1995,3
Bowling Green State University,Religion2020,1995,15
Bradford City Libraries,Religion2020,1995,5
Bradford Institute for Health Research,Cooperation2021,1995,1
Bradley University,Cooperation2021,1995,1
Bradley University,Religion2020,1995,12
Brandeis University,Cooperation2021,1995,1
Brandeis University,Religion2020,1995,17
Brigham Young University,Cooperation2021,1995,5
Brigham Young University,CooperationAndReligion,1995,1
Brigham Young University,Religion2020,1995,31
"Bristol Institute of Technology, UWE",Cooperation2021,1995,1
Brite Divinity,Religion2020,1995,2
British Journal of Psychiatry,Religion2020,1995,1
Brock University,Religion2020,1995,2
Brooklyn College,Religion2020,1995,5
Brown University,Cooperation2021,1995,1
Brown University,Religion2020,1995,20
Brunel University London,Cooperation2021,1995,2
Brunel University London,Religion2020,1995,8
Bryn Mawr College,Cooperation2021,1995,1
Bryn Mawr College,Religion2020,1995,4
Buckingham University Centre for Security and Intelligence Studies,Cooperation2021,1995,1
Bucknell University,Religion2020,1995,5
Burlington College,Religion2020,1995,1
C&V Senior Care Specialists,Religion2020,1995,1
CableLabs,Cooperation2021,1995,6
California Institute of Technology,Cooperation2021,1995,3
California Lutheran University,Religion2020,1995,4
California Pacific Medical Center,Religion2020,1995,4
California State University,Religion2020,1995,5
"California State University, Chico",Cooperation2021,1995,1
"California State University, Chico",Religion2020,1995,2
"California State University, Fullerton",Cooperation2021,1995,1
"California State University, Fullerton",Religion2020,1995,3
"California State University, Long Beach",Religion2020,1995,3
"California State University, Los Angeles",Religion2020,1995,3
"California State University, Northridge",Religion2020,1995,4
"California State University, Stanislaus",Religion2020,1995,3
Calvin College,Religion2020,1995,13
Calvin University,Religion2020,1995,2
Cambridge Health Alliance,Religion2020,1995,1
Cambridge University,Cooperation2021,1995,1
Cambridge University,CooperationAndReligion,1995,1
Cambridge University,Religion2020,1995,14
Canada Research Chairs,Cooperation2021,1995,1
Cancer Prevention Institute of California,Religion2020,1995,2
Cancer Research UK London,Cooperation2021,1995,3
Canterbury Christ Church University,Religion2020,1995,2
Canterbury Christchurch University,Religion2020,1995,2
Cape Breton University,Cooperation2021,1995,1
Cape Breton University,Religion2020,1995,2
Cardiff University,Cooperation2021,1995,4
Cardiff University,Religion2020,1995,11
Cardinal Stritch University,Religion2020,1995,2
Cardozo School of Law,Religion2020,1995,2
Carleton College,Cooperation2021,1995,3
Carleton College,Religion2020,1995,6
Carleton University,Cooperation2021,1995,1
Carleton University,Religion2020,1995,5
Carnegie Mellon University,Cooperation2021,1995,3
Case Western Reserve University,Religion2020,1995,6
Catholic Ecumenical Institute,Religion2020,1995,1
Catholic Research and Development Centre,Religion2020,1995,1
Catholic Theological Union,Religion2020,1995,2
Catholic University of America,Cooperation2021,1995,6
Catholic University of America,CooperationAndReligion,1995,1
Catholic University of America,Religion2020,1995,25
Catholic University of Leuven,Cooperation2021,1995,1
Catholic University of Leuven,Religion2020,1995,5
Catholic University of Louvain,Cooperation2021,1995,4
Catholic University of Lublin,Cooperation2021,1995,1
Catholic University of Lublin,Religion2020,1995,1
Center for Applied Research in the Apostolate,Religion2020,1995,7
Center for Change,Religion2020,1995,5
Center for Executive Solutions,Cooperation2021,1995,1
Center for Executive Solutions,CooperationAndReligion,1995,1
Center for Executive Solutions,Religion2020,1995,2
"Center for Holistic Systems Analysis in Fairfield, Iowa",Religion2020,1995,1
Center for Interfaith Reconciliation,Religion2020,1995,2
Center for Mind and Culture,Religion2020,1995,1
Center For Partnership Studies,Cooperation2021,1995,1
Center For Partnership Studies,Religion2020,1995,1
Center for Professional Studies,Religion2020,1995,3
Center for Psychosocial Research,Religion2020,1995,1
Center for Scientific Research,Cooperation2021,1995,1
Center for Studies on New Religions,Religion2020,1995,4
Center for the Study on New Religions,Religion2020,1995,2
Center for Theological Inquiry,Religion2020,1995,3
Central Michigan University,Religion2020,1995,3
Central Queensland University,Cooperation2021,1995,2
Central Queensland University,Religion2020,1995,5
Central Research Institute for Physics,Cooperation2021,1995,2
Centre for Addiction and Mental Health,Religion2020,1995,2
Centre for Research and Evidence on Security Threats,Religion2020,1995,2
CERGE-E,Cooperation2021,1995,2
Chang Gung University,Religion2020,1995,3
Chapman University,Cooperation2021,1995,4
Chapman University,Religion2020,1995,11
China University of Mining and Technology,Cooperation2021,1995,1
Chinese University of Hong Kong,Cooperation2021,1995,3
Chinese University of Hong Kong,Religion2020,1995,5
Chongqing Institute of Green and Intelligent Technology,Cooperation2021,1995,1
Christ Church College,Religion2020,1995,5
Christian Council on Ageing,Religion2020,1995,1
Christian Theological Seminary,Religion2020,1995,1
Church Divinity School of the Pacific,Religion2020,1995,2
Church of England,Religion2020,1995,7
Church of Ireland Theological College,Religion2020,1995,1
Church Research Institute,Religion2020,1995,1
CIRANO,Cooperation2021,1995,1
City of Hope National Medical Center,Cooperation2021,1995,1
City of Hope National Medical Center,Religion2020,1995,5
City University of Hong Kong,Cooperation2021,1995,2
City University of Hong Kong,Religion2020,1995,4
City University of New York,Cooperation2021,1995,1
City University of New York,Religion2020,1995,5
City University of New York Graduate Center,Cooperation2021,1995,1
Claremont Graduate University,Cooperation2021,1995,1
Claremont Graduate University,Religion2020,1995,40
Claremont McKenna College,Religion2020,1995,2
Claremont school of theology,Religion2020,1995,3
Clark University,Cooperation2021,1995,1
Clark University,Religion2020,1995,2
Clemson University,Religion2020,1995,3
Clinical Psychology Doctoral Training Programme,Religion2020,1995,1
Club of Budapest,Cooperation2021,1995,1
Club of Budapest,Religion2020,1995,1
Coalition for Compassionate Care of California,Religion2020,1995,1
Colgate University,Religion2020,1995,2
Collaborative Caring,Religion2020,1995,1
"Collaborative for Academic, Social, and Emotional Learning (CASEL)",Religion2020,1995,3
Collège de France,Religion2020,1995,2
College of Charlestown,Religion2020,1995,6
College of New Rochelle,Religion2020,1995,1
College of the Holy Cross,Religion2020,1995,5
Collegium Pharmaceutical Inc.,Religion2020,1995,2
Colorado State University,Religion2020,1995,4
Columbia Theological Seminary,Religion2020,1995,10
Columbia University,Cooperation2021,1995,16
Columbia University,Religion2020,1995,37
Columbus State University,Cooperation2021,1995,2
"Community Health Center, Meriden",Religion2020,1995,1
"Complejo Hospitalario Universitario de Badajoz, Badajoz",Religion2020,1995,1
Conception Seminary College,Religion2020,1995,4
Concordia College,Religion2020,1995,4
Concordia University,Religion2020,1995,13
Connecticut College,Religion2020,1995,5
Copenhagen Business School,Cooperation2021,1995,2
Cornell University,Cooperation2021,1995,20
Cornell University,Religion2020,1995,4
Cosmos & Creation,Religion2020,1995,1
Council of European Churches,Religion2020,1995,3
Coventry University,Religion2020,1995,1
Crafton Hills College,Religion2020,1995,2
Creighton University,Religion2020,1995,13
Crozer Theological Seminary,Religion2020,1995,1
CSIRO,Cooperation2021,1995,1
CUNY,Religion2020,1995,6
CUNY Graduate Center,Religion2020,1995,3
"Cuyamungue Institute, New Mexico",Religion2020,1995,1
Dalhousie University,Religion2020,1995,5
Dana-Farber Cancer Institute,Cooperation2021,1995,1
Dartmouth College,Cooperation2021,1995,1
Dartmouth College,CooperationAndReligion,1995,1
Dartmouth College,Religion2020,1995,19
David and Carol Myers Foundation,Religion2020,1995,1
Davidson College,Religion2020,1995,3
Deakin University,Religion2020,1995,9
Delft University of Technology,Cooperation2021,1995,3
Denison University,Religion2020,1995,4
Denver Seminary,Religion2020,1995,2
DePaul University,Cooperation2021,1995,1
DePaul University,Religion2020,1995,3
DePauw University,Religion2020,1995,5
Diakonhjemmet Hospital,Religion2020,1995,2
Divine Mercy University,Religion2020,1995,2
Dominican School of Philosophy and Theology,Religion2020,1995,2
Dr Williams's Library,Religion2020,1995,2
Drew University,Religion2020,1995,2
Drexel University,Cooperation2021,1995,3
Dublin City University,Religion2020,1995,1
Duke Divinity School,Religion2020,1995,11
Duke University,Cooperation2021,1995,11
Duke University,Religion2020,1995,97
Duke-NUS Medical School,Religion2020,1995,1
Duquesne University,Religion2020,1995,6
Durham University,Cooperation2021,1995,3
Durham University,Religion2020,1995,54
"DynaMed, EBSCO Health",Religion2020,1995,1
East Carolina University,Religion2020,1995,3
East China Normal University,Religion2020,1995,1
East Tennessee State University,Religion2020,1995,8
Eastern Baptist Theological Seminary,Religion2020,1995,1
Eastern Mennonite University,Religion2020,1995,1
Eastern Michigan University,Religion2020,1995,1
Eastern University,Religion2020,1995,1
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1995,9
École des Hautes Etudes en Sciences Sociales,Religion2020,1995,1
Ecole Francaise D'Extreme-Orient,Religion2020,1995,1
Ecole Pratique des Hautes Etudes,Religion2020,1995,1
École Pratique des Hautes Etudes,Religion2020,1995,2
École pratique des hautes études,Cooperation2021,1995,1
École pratique des hautes études,Religion2020,1995,3
ECU Physicians-Adult & Pediatric Health Care,Religion2020,1995,1
Ecumenical Institute,Religion2020,1995,2
Edge Hill University,Religion2020,1995,2
Edgewalkers International,Religion2020,1995,2
Edith Cowan University,Religion2020,1995,3
Emmanuel College,Religion2020,1995,6
Emory College,Religion2020,1995,1
Emory University,Cooperation2021,1995,7
Emory University,CooperationAndReligion,1995,1
Emory University,Religion2020,1995,45
Empire State College,Religion2020,1995,1
Eotvos Lorand University,Cooperation2021,1995,3
Episcopal Divinity School,Religion2020,1995,1
Erasmus Medical Center,Religion2020,1995,2
Erasmus University Rotterdam,Religion2020,1995,2
ETH Zurich,Cooperation2021,1995,5
European Business School,Religion2020,1995,3
European University Institute,Cooperation2021,1995,2
European University Viadrina,Cooperation2021,1995,5
European University Viadrina,Religion2020,1995,1
Evangelische Hochschule Tabor in Marburg,Religion2020,1995,2
Facebook,Religion2020,1995,2
faculte de pharmacie et de medecine de casablanca,Religion2020,1995,1
Fairfield University,Religion2020,1995,1
Federal University of Pernambuco,Religion2020,1995,1
Federal University of São Paulo (UNIFESP),Religion2020,1995,3
Federation University Australia,Religion2020,1995,1
First Things,Religion2020,1995,1
Fisk University,Religion2020,1995,1
Flinders University,Cooperation2021,1995,1
Flinders University,Religion2020,1995,3
Florida Atlantic University,Cooperation2021,1995,2
Florida Atlantic University,Religion2020,1995,4
Florida International University,Religion2020,1995,14
Florida State University,Cooperation2021,1995,13
Florida State University,Religion2020,1995,18
flourishing metrics,Religion2020,1995,5
Floyd Medical Center,Religion2020,1995,2
Forbes Magazine,Religion2020,1995,36
Fordham University,Religion2020,1995,38
Forlaget Univers,Religion2020,1995,1
Fox Chase Cancer Center,Cooperation2021,1995,1
Fox Valley Memory Project,Religion2020,1995,7
Franklin College,Religion2020,1995,4
Free State University,Cooperation2021,1995,1
Free State University,Religion2020,1995,1
Free University of Berlin,Religion2020,1995,2
Freelance Researcher,Religion2020,1995,1
Freie Universität Berlin,Religion2020,1995,1
French National Center for Scientific Research,Religion2020,1995,3
Fudan University,Religion2020,1995,1
Fuller Theological Seminary,Cooperation2021,1995,1
Fuller Theological Seminary,Religion2020,1995,49
Furman University,Religion2020,1995,3
Future University Hakodate,Cooperation2021,1995,1
Garrett-Evangelical Theological Seminary,Religion2020,1995,13
General Theological Seminary,Religion2020,1995,3
Georg August University of Göttingen,Cooperation2021,1995,1
George Fox University,Religion2020,1995,6
George Mason University,Cooperation2021,1995,2
George Mason University,Religion2020,1995,9
George Washington University,Cooperation2021,1995,3
George Washington University,Religion2020,1995,18
Georgetown University,Cooperation2021,1995,1
Georgetown University,CooperationAndReligion,1995,1
Georgetown University,Religion2020,1995,57
Georgia Southern University,Religion2020,1995,2
Georgia State University,Religion2020,1995,5
German Institute for Economic Research (DIW Berlin),Cooperation2021,1995,2
GESIS-Leibniz Institute for the Social Sciences,Religion2020,1995,1
Ghent University,Cooperation2021,1995,1
Ghent University,Religion2020,1995,2
GIGA German Institute of Global and Area Studies,Cooperation2021,1995,1
GIGA German Institute of Global and Area Studies,CooperationAndReligion,1995,1
GIGA German Institute of Global and Area Studies,Religion2020,1995,1
GKT School of Medical Education,Religion2020,1995,2
Glasgow Centre for Population Health,Religion2020,1995,1
Global Ethic Institute,Religion2020,1995,1
Glyndwr University,Religion2020,1995,10
Goa University,Religion2020,1995,3
Goethe University Frankfurt,Religion2020,1995,4
Golden Gate Baptist Theological Seminary,Cooperation2021,1995,1
Golden Gate Baptist Theological Seminary,Religion2020,1995,2
"Goldsmiths, University of London",Religion2020,1995,14
Gordon College,Religion2020,1995,1
Goucher College,Religion2020,1995,2
Grace,Religion2020,1995,2
Graduate Theological Foundation,Religion2020,1995,4
Graduate Theological Union,Cooperation2021,1995,1
Graduate Theological Union,Religion2020,1995,15
Greenwall Foundation,Cooperation2021,1995,2
Greenwall Foundation,Religion2020,1995,1
Grenoble Alpes University,Religion2020,1995,1
Griffith University,Cooperation2021,1995,2
Griffith University,Religion2020,1995,7
Groningen University,Religion2020,1995,2
Grove City College,Religion2020,1995,2
Gustavus Adolphus College,Religion2020,1995,4
Hadassah University Medical Center,Cooperation2021,1995,3
Hadassah University Medical Center,CooperationAndReligion,1995,1
Hadassah University Medical Center,Religion2020,1995,10
Hamburg University,Religion2020,1995,1
Hardin-Simmons University,Religion2020,1995,2
Harper College,Religion2020,1995,3
Harris Manchester College,Religion2020,1995,1
Hartford Institute for Religion Research,Religion2020,1995,4
Hartford Seminary,Religion2020,1995,2
Harvard Business School,Cooperation2021,1995,3
Harvard Business School,Religion2020,1995,1
Harvard Center for World Religions,Religion2020,1995,1
Harvard Divinity School,Religion2020,1995,34
Harvard Medical School,Cooperation2021,1995,3
Harvard Medical School,Religion2020,1995,19
Harvard School of Public Health,Cooperation2021,1995,6
Harvard School of Public Health,Religion2020,1995,5
Harvard University,Cooperation2021,1995,27
Harvard University,CooperationAndReligion,1995,1
Harvard University,Religion2020,1995,56
Hassan II Foundation for Scientific and Medical Research on Health and Ramadan,Religion2020,1995,1
Haverford College,Religion2020,1995,1
Heal,Religion2020,1995,10
healthquest,Religion2020,1995,1
Hebrew University - Hadessah,Cooperation2021,1995,1
Hebrew University - Hadessah,Religion2020,1995,3
Hebrew University of Jerusalem,Cooperation2021,1995,9
Hebrew University of Jerusalem,Religion2020,1995,39
HEC Montreal,Cooperation2021,1995,1
Heidelberg Center for American Studies,Religion2020,1995,2
Heidelberg University,Religion2020,1995,1
Henderson State University,Religion2020,1995,2
Heriot-Watt University,Religion2020,1995,3
Hertie School,Cooperation2021,1995,1
Heythrop College,Religion2020,1995,2
Highland Theological College,Religion2020,1995,2
Hillcroft College,Cooperation2021,1995,3
Hillcroft College,Religion2020,1995,1
Hillsdale College,Religion2020,1995,4
Hitotsubashi University,Cooperation2021,1995,9
Hobart and William Smith Colleges,Religion2020,1995,2
Hofstra University,Cooperation2021,1995,1
Hofstra University,Religion2020,1995,1
Hokkaido University,Cooperation2021,1995,6
Hokkaido University,Religion2020,1995,9
Holistic Nursing Consultants,Religion2020,1995,1
Holy Cross Health Care System,Religion2020,1995,1
Holy Ghost College,Religion2020,1995,2
Hong Kong Polytechnic University,Cooperation2021,1995,3
Hope College,Religion2020,1995,13
Hospice Rieti,Religion2020,1995,15
House of Lords,Religion2020,1995,2
Houston Baptist University,Religion2020,1995,2
Human Population Laboratory,Religion2020,1995,2
Human Sciences Research Council,Cooperation2021,1995,1
Human Sciences Research Council,Religion2020,1995,1
Humboldt University of Berlin,Cooperation2021,1995,2
Humboldt University of Berlin,Religion2020,1995,1
Hungarian Academy of Sciences,Cooperation2021,1995,1
"Huntington School, York",Religion2020,1995,2
Huron University College,Religion2020,1995,4
Ibadan University,Religion2020,1995,1
Ibaraki University,Religion2020,1995,1
Icahn School of Medicine at Mount Sinai,Religion2020,1995,6
Ignatianum Academy in Krakow,Religion2020,1995,1
Iliff School of Theology,Religion2020,1995,6
Illif School of Theology,Religion2020,1995,5
Illinois Institute of Technology,Cooperation2021,1995,1
Illinois State University,Cooperation2021,1995,1
Illinois State University,Religion2020,1995,2
Impact Publishers,Cooperation2021,1995,1
Imperial Cancer Research Fund Oxford,Cooperation2021,1995,3
Imperial College London,Cooperation2021,1995,4
Independent Consulting,Religion2020,1995,2
Independent Researcher,Cooperation2021,1995,1
Indian Council of Social Science Research,Religion2020,1995,1
Indian Institute of Science,Cooperation2021,1995,3
Indiana State University,Religion2020,1995,3
Indiana University,Cooperation2021,1995,9
Indiana University,Religion2020,1995,50
Indiana University at Bloomington,Cooperation2021,1995,3
Indiana University at Bloomington,Religion2020,1995,10
Indiana University of Pennsylvania,Cooperation2021,1995,2
Indiana University of Pennsylvania,Religion2020,1995,2
Indiana University Purdue University Indianapolis,Religion2020,1995,1
Indiana University South Bend,Religion2020,1995,6
Indiana University Southeast,Religion2020,1995,1
Indiana University-Purdue University Fort Wayne,Religion2020,1995,4
Indus Business Academy,Religion2020,1995,1
"Innovations and EBM Development, EBSCO Health",Religion2020,1995,1
Institute for Advanced Study,Religion2020,1995,6
Institute for American Constitutional Heritage,Cooperation2021,1995,1
Institute for American Constitutional Heritage,Religion2020,1995,4
Institute for Cognitive Sciences and Technologies,Cooperation2021,1995,3
Institute for Frontier Areas of Psychology and Mental Health,Religion2020,1995,1
Institute for Integrative Health,Religion2020,1995,3
Institute for Orthodox Christian Studies,Religion2020,1995,1
Institute for Social Sciences at the TU Braunschweig,Religion2020,1995,1
Institute for the Biocultural Study of Religion,Religion2020,1995,1
Institute for the Psychological Sciences,Cooperation2021,1995,1
Institute for the Psychological Sciences,Religion2020,1995,4
Institute for Transpersonal Psychology,Religion2020,1995,6
Institute of Communications Engineering,Cooperation2021,1995,1
Institute of Ismaili Studies,Religion2020,1995,1
Institute of Lutheran Theology,Religion2020,1995,1
Institute of Public Health,Religion2020,1995,2
Institute of Race Relations,Religion2020,1995,1
"Institute of Science, Technology and Public Policy",Religion2020,1995,1
Institute on Global Conflict and Cooperation,Cooperation2021,1995,1
Institute on Global Conflict and Cooperation,CooperationAndReligion,1995,1
Institute on Global Conflict and Cooperation,Religion2020,1995,1
Institute on Religion in an Age of Science,Religion2020,1995,2
Integral Institute,Religion2020,1995,1
Interdisciplinary Center Herzliya,Cooperation2021,1995,2
Interdisciplinary Center Herzliya,Religion2020,1995,2
"International Association for Human Values, United Nations",Religion2020,1995,2
International Baptist Theological Seminary,Religion2020,1995,8
International Christian University,Religion2020,1995,1
International Institute for Applied Systems Analysis,Cooperation2021,1995,1
International Telematic University UNINETTUNO,Cooperation2021,1995,2
International University of Rabat,Religion2020,1995,4
Iowa State University,Cooperation2021,1995,9
Iowa State University,Religion2020,1995,1
Istituto Affari Internazionali,Cooperation2021,1995,5
Jacobs University Bremen,Religion2020,1995,7
Jagiellonian University,Religion2020,1995,4
James Cook University,Cooperation2021,1995,3
James Madison University,Religion2020,1995,3
Japan Society for Buddhist-Christian Studies,Religion2020,1995,2
Jawaharlal Nehru University,Religion2020,1995,1
Jesuit Institute,Religion2020,1995,1
Jesus College,Religion2020,1995,4
Jewish Theological Seminary,Religion2020,1995,3
Jinan University,Cooperation2021,1995,2
Jindal Global University,Cooperation2021,1995,1
Jindal Global University,Religion2020,1995,1
John Carroll University,Religion2020,1995,1
John Hopkins University,Religion2020,1995,1
John Wycliffe Theological College,Religion2020,1995,1
Johns Hopkins Bayview Medical Center,Religion2020,1995,1
Johns Hopkins School of Medicine,Religion2020,1995,1
Johns Hopkins University,Cooperation2021,1995,5
Johns Hopkins University,CooperationAndReligion,1995,1
Johns Hopkins University,Religion2020,1995,16
Joint Special Operations University,Religion2020,1995,3
Jönköping University,Religion2020,1995,2
journal of theological studies,Religion2020,1995,2
Juniata College,Religion2020,1995,1
Justus Liebig University Giessen,Cooperation2021,1995,2
Justus Liebig University Giessen,Religion2020,1995,1
Kansai University,Cooperation2021,1995,1
Kansas State University,Religion2020,1995,3
Kaohsiung Medical University,Cooperation2021,1995,1
Kaohsiung Medical University,CooperationAndReligion,1995,1
Kaohsiung Medical University,Religion2020,1995,1
Karen D. Lincoln Consulting Services,Religion2020,1995,2
Karlstad University,Religion2020,1995,1
Karolinska Institute,Religion2020,1995,4
Keele University,Religion2020,1995,12
Keenesaw State University,Cooperation2021,1995,1
Keenesaw State University,Religion2020,1995,2
Kennesaw State University,Religion2020,1995,5
Kent State University,Religion2020,1995,6
Keston Institute,Religion2020,1995,1
King Abdulaziz University,Religion2020,1995,2
King George's Medical University,Religion2020,1995,1
King’s College,Cooperation2021,1995,1
King’s College,CooperationAndReligion,1995,1
King’s College,Religion2020,1995,2
King’s College London,Religion2020,1995,10
Kings College,Religion2020,1995,1
King's College,Religion2020,1995,25
King's College London,Cooperation2021,1995,6
King's College London,Religion2020,1995,24
Kings College London University,Cooperation2021,1995,3
Kings College London University,Religion2020,1995,2
King's University College,Religion2020,1995,1
Kingston University,Religion2020,1995,3
KM Behavioral Consulting,Religion2020,1995,4
Kobe Gakuin University,Cooperation2021,1995,3
Kochi University of Technology,Cooperation2021,1995,2
Korea Advanced Institute of Science and Technology (KAIST),Religion2020,1995,1
Korea University,Cooperation2021,1995,1
Korean Institute of Brain Science,Religion2020,1995,1
KU Leuven,Cooperation2021,1995,2
KU Leuven,Religion2020,1995,20
KW Counselling Services,Religion2020,1995,2
Kwansei Gakuin University,Cooperation2021,1995,3
Kyoto University,Religion2020,1995,3
Kyushu University,Cooperation2021,1995,2
La Salpetriere Hospital,Cooperation2021,1995,1
La Sierra University,Cooperation2021,1995,1
La Sierra University,Religion2020,1995,1
La Trobe University,Religion2020,1995,17
La Vie Counseling Center,Religion2020,1995,2
Lafayette College,Religion2020,1995,2
Lake Forest College,Cooperation2021,1995,1
Lake Forest College,CooperationAndReligion,1995,1
Lake Forest College,Religion2020,1995,2
Lakota Books Publishing Company,Religion2020,1995,1
Lamar University,Religion2020,1995,3
Lancaster Theological Seminary,Religion2020,1995,2
Lancaster University,Cooperation2021,1995,9
Lancaster University,Religion2020,1995,9
Lanzhou University,Religion2020,1995,2
LaTrobe University,Religion2020,1995,1
Laurentian University,Religion2020,1995,2
Laval University,Religion2020,1995,17
Lee University,Religion2020,1995,5
Lehigh University,Cooperation2021,1995,1
Lehigh University,Religion2020,1995,7
Leibniz University of Hanover,Religion2020,1995,2
Leicester Cathedral,Religion2020,1995,1
Leiden University,Cooperation2021,1995,10
Leiden University,Religion2020,1995,33
Leipzig University,Religion2020,1995,5
Leo Baeck College,Religion2020,1995,5
Lesya Ukrainka Volyn National University,Religion2020,1995,1
Lewis & Clark College,Religion2020,1995,2
Lewis Clark State College,Cooperation2021,1995,1
Lewis Clark State College,Religion2020,1995,1
Lewis University,Religion2020,1995,1
Liberty University,Religion2020,1995,2
Lincoln Memorial University,Cooperation2021,1995,1
Lincoln Memorial University,Religion2020,1995,4
Lingnan University,Cooperation2021,1995,8
Link Care Center,Religion2020,1995,2
Linnaeus University,Religion2020,1995,3
Liverpool Hope University,Religion2020,1995,13
Liverpool John Moores University,Cooperation2021,1995,1
Loma Linda University,Cooperation2021,1995,4
Loma Linda University,Religion2020,1995,12
London Metropolitan University,Religion2020,1995,10
London School of Economics,Cooperation2021,1995,3
London School of Economics,Religion2020,1995,17
London School of Economics and Political Science,Religion2020,1995,1
London School of Hygiene and Tropical Medicine,Cooperation2021,1995,3
London School of Theology,Religion2020,1995,1
London University,Religion2020,1995,2
Los Angeles Coutny Jail,Religion2020,1995,2
Los Angeles Valley College,Religion2020,1995,1
Louisiana State University,Religion2020,1995,2
Louisville Institute,Religion2020,1995,3
Louisville Presbyterian Theological Seminary,Religion2020,1995,5
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,1995,2
Loyola Marymount University,Religion2020,1995,10
Loyola University Chicago,Cooperation2021,1995,2
Loyola University Chicago,Religion2020,1995,36
Loyola University Maryland,Religion2020,1995,12
Loyola University New Orleans,Religion2020,1995,8
Ltrek,Cooperation2021,1995,1
Ludwig Maximilian University of Munich,Cooperation2021,1995,2
Ludwig Maximilian University of Munich,Religion2020,1995,1
Ludwig-Maximilians-University of Munich,Religion2020,1995,1
Luiss University Rome,Cooperation2021,1995,5
Lund University,Religion2020,1995,3
Luther Seminary,Religion2020,1995,5
Luther Theological Seminary,Religion2020,1995,2
Lutheran School of theology at Chicago,Cooperation2021,1995,1
Lutheran School of theology at Chicago,CooperationAndReligion,1995,1
Lutheran School of theology at Chicago,Religion2020,1995,11
Lutheran Theological Seminary at Philadelphia,Religion2020,1995,4
Maastricht University,Cooperation2021,1995,5
Maastricht University,Religion2020,1995,1
Macquarie University,Cooperation2021,1995,3
Macquarie University,Religion2020,1995,6
Madras Medical College,Religion2020,1995,2
Magdalene College,Religion2020,1995,2
Maharishi International University,Religion2020,1995,1
Maharishi University of Management,Religion2020,1995,18
Mail & Guardian,Religion2020,1995,1
Makerere University,Religion2020,1995,1
Manchester Cathedral,Religion2020,1995,2
Manchester Metropolitan University,Cooperation2021,1995,3
Manchester University,Religion2020,1995,1
Marburg University,Religion2020,1995,1
Marian University,Religion2020,1995,4
Marie Curie Hospice Homestead,Cooperation2021,1995,1
Marquette University,Religion2020,1995,9
Mars Hill Graduate School,Religion2020,1995,3
Martin Luther University of Halle-Wittenberg,Cooperation2021,1995,1
Massachusetts General Hospital,Cooperation2021,1995,1
Massachusetts General Hospital,Religion2020,1995,5
Massachusetts Institute of Technology,Cooperation2021,1995,4
Massachusetts Institute of Technology,Religion2020,1995,4
Massey University,Cooperation2021,1995,1
Massey University,Religion2020,1995,3
Masters and Johnson,Religion2020,1995,3
Mater Dei Institute of Education,Religion2020,1995,1
Max Planck Institute,Cooperation2021,1995,6
Max Planck Institute,Religion2020,1995,1
Max Planck Institute for Human Development,Cooperation2021,1995,2
Max Planck Institute for Psychological Research,Religion2020,1995,1
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,1995,3
Max Planck Institute for the Study of Religious and Ethnic Diversity,Religion2020,1995,4
Max Plank Institute for Evolutionary Anthropology,Cooperation2021,1995,3
Maynooth University,Religion2020,1995,2
Mayo Clinic School of Medicine,Religion2020,1995,1
McGill University,Religion2020,1995,18
McLean Hospital,Religion2020,1995,2
McMaster Divinity School,Religion2020,1995,1
McMaster University,Religion2020,1995,3
Medical College of Georgia,Religion2020,1995,5
Medical College of Wisconsin,Religion2020,1995,3
Medical Research Council,Religion2020,1995,1
Medical University of South Carolina,Religion2020,1995,1
"Meek and Associates, PC",Religion2020,1995,2
Memorial Sloan Kettering Cancer Center,Religion2020,1995,5
Memphis State University,Religion2020,1995,1
Memphis Theological Seminary,Religion2020,1995,5
Mercer University,Religion2020,1995,2
"Merck & Co, Inc.",Religion2020,1995,1
Merrimack College,Religion2020,1995,1
Messiah College,Religion2020,1995,4
Methodist Theological School,Religion2020,1995,5
Mettaa Institute,Religion2020,1995,1
MGH Institute of Health Professions,Religion2020,1995,2
Miami University,Religion2020,1995,15
Michael E. DeBakey VA Medical Center,Religion2020,1995,1
Michigan State University,Cooperation2021,1995,10
Michigan State University,Religion2020,1995,4
Michlalah Jersusalem Academic College,Religion2020,1995,1
Microsoft,Religion2020,1995,2
"Microsoft Research, Israel",Cooperation2021,1995,2
Middle Tennesse State University,Religion2020,1995,4
Middlebury College,Religion2020,1995,4
Midland Centre for Neurosurgery and Neurology,Religion2020,1995,13
Millersville State College,Religion2020,1995,4
Milligan College - Emmanuel School of Religion,Religion2020,1995,1
Mindstrong,Cooperation2021,1995,8
Mississippi College,Religion2020,1995,2
Missouri State University,Religion2020,1995,2
Missouri Western State University,Religion2020,1995,1
MJHS Institute for Innovation and Palliative Care,Religion2020,1995,1
Monash University,Cooperation2021,1995,7
Monash University,Religion2020,1995,12
Montclair State University,Religion2020,1995,1
Montefiore-Einstein Center for Cancer Care,Religion2020,1995,1
Moorpark College,Religion2020,1995,1
Mount Holyoke College,Religion2020,1995,1
Mount Saint Mary's University,Religion2020,1995,4
Mount Saint Vincent University,Religion2020,1995,1
Mt. Sinai School of Medicine,Religion2020,1995,6
Muhlenberg College,Religion2020,1995,2
Murdoch University,Religion2020,1995,1
Murray Edwards College,Religion2020,1995,1
Murray State,Cooperation2021,1995,1
Myriad Neuroscience,Cooperation2021,1995,1
Nagoya Institute of Technology,Cooperation2021,1995,1
Nanjing University,Cooperation2021,1995,1
Naropa University,Religion2020,1995,2
National Academies Keck Futures Initiative,Religion2020,1995,1
National Academy of Sciences of Ukraine,Religion2020,1995,3
National Cancer Institute,Cooperation2021,1995,2
National Center for Dual Diagnoses,Religion2020,1995,2
National Center for Science Education,Religion2020,1995,3
National Cheng Kung University,Religion2020,1995,1
National Council for Scientific and Technological Development,Religion2020,1995,3
"National Institute for Health and Welfare, Finland",Religion2020,1995,1
National institute of Child Health,Cooperation2021,1995,1
National Institute of Child Health & Human Development,Cooperation2021,1995,1
National Institute of Mental Health,Cooperation2021,1995,2
National Institute of Mental Health,Religion2020,1995,1
National Institutes of Health,Cooperation2021,1995,1
National Public Radio,Religion2020,1995,1
National Research Council,Religion2020,1995,1
National Research University Higher School of Economics,Cooperation2021,1995,3
National Research University Higher School of Economics,CooperationAndReligion,1995,1
National Research University Higher School of Economics,Religion2020,1995,5
National Science Foundation,Religion2020,1995,6
National Study of Treatment and Addiction Recovery Residences,Religion2020,1995,1
National Sun Yat-sen University,Cooperation2021,1995,1
National Taipei University,Religion2020,1995,1
National University of Distance Education,Cooperation2021,1995,1
National University of Singapore,Cooperation2021,1995,4
National University of Singapore,Religion2020,1995,8
Netanya University,Religion2020,1995,1
Netherlands Interdiscplinary Demographic Institute,Religion2020,1995,2
Neumann University,Religion2020,1995,1
NeuroMyth,Religion2020,1995,2
New School for Social Research,Religion2020,1995,1
New Scientist at Reed Business Information,Cooperation2021,1995,3
New York Psychosynthesis Institute,Religion2020,1995,5
New York State Psychiatric Institute,Religion2020,1995,2
New York University,Cooperation2021,1995,13
New York University,Religion2020,1995,30
Newcastle University,Cooperation2021,1995,3
Newcastle University,Religion2020,1995,2
NHS Foundation,Cooperation2021,1995,1
NHS Foundation,Religion2020,1995,1
NIMH Family Research Consortium IV- UCLA,Cooperation2021,1995,1
North Carolina Central University,Religion2020,1995,2
North Carolina State University,Religion2020,1995,4
North of England Institute for Christian Education,Religion2020,1995,6
North West University,Religion2020,1995,2
Northeastern University,Religion2020,1995,1
Northern Arizona University,Religion2020,1995,1
northern Baptist theological seminary,Religion2020,1995,1
Northern California Cancer Center,Religion2020,1995,2
Northern Kentucky University,Religion2020,1995,3
Northumbria University,Cooperation2021,1995,2
Northumbria University,Religion2020,1995,2
Northwest University,Religion2020,1995,1
Northwestern College,Religion2020,1995,1
Northwestern University,Cooperation2021,1995,14
Northwestern University,Religion2020,1995,20
Norwegian School of Theology,Religion2020,1995,1
Norwegian University of Science and Technology,Cooperation2021,1995,5
Norwegian University of Science and Technology,Religion2020,1995,3
Norweign School of Theology,Religion2020,1995,1
Nottingham Trent University,Religion2020,1995,1
Nova Southeastern University,Religion2020,1995,3
Novo Nordisk,Religion2020,1995,1
Oakland University,Cooperation2021,1995,2
Oakland University,Religion2020,1995,1
Obafemi Awolowo University,Religion2020,1995,2
Oberlin College,Religion2020,1995,6
Occidental College,Religion2020,1995,1
Ohio State University,Cooperation2021,1995,2
Ohio State University,Religion2020,1995,30
Oklahoma State University,Religion2020,1995,2
Old Dominion University,Religion2020,1995,2
Open University (The),Cooperation2021,1995,1
Open University (The),CooperationAndReligion,1995,1
Open University (The),Religion2020,1995,21
Oral Roberts University,Religion2020,1995,1
Oregon Health & Science University,Religion2020,1995,1
Oregon State University,Religion2020,1995,4
Original Root Zen Center,Religion2020,1995,3
Oslo University,Cooperation2021,1995,1
Oslo University,CooperationAndReligion,1995,1
Oslo University,Religion2020,1995,4
Otto-von-Guericke University Magdeburg,Cooperation2021,1995,1
Oxford Brookes University,Cooperation2021,1995,1
Oxford Brookes University,Religion2020,1995,8
Oxford Centre for Mission Studies,Religion2020,1995,1
Oxford University,Cooperation2021,1995,5
Oxford University,Religion2020,1995,41
Pace University,Cooperation2021,1995,1
Pacific Union College,Religion2020,1995,2
Peace Research Institute Oslo PRIO,Cooperation2021,1995,1
Peking University,Cooperation2021,1995,2
Peking University,Religion2020,1995,1
Penn State University,Religion2020,1995,2
Pennsylvania State University,Cooperation2021,1995,1
Pennsylvania State University,Religion2020,1995,25
Pepperdine University,Religion2020,1995,2
Péter Pázmány Catholic University,Religion2020,1995,2
Philipps University Marburg,Religion2020,1995,3
Phillips University,Religion2020,1995,1
Philosophy Institute of the Ukrainian Academy of Sciences,Religion2020,1995,7
Phoenix Australia - Centre for Posttraumatic Mental Health,Religion2020,1995,1
Pittsburg State University,Religion2020,1995,1
Pittsburgh Theological Seminary,Religion2020,1995,2
Pitzer College,Religion2020,1995,2
Polytechnic University of Madrid,Cooperation2021,1995,2
Pomona College,Religion2020,1995,1
Pontifical Biblical Institute,Religion2020,1995,6
Pontifical Catholic University of Sao Paulo,Religion2020,1995,1
Pontifical Gregorian University,Religion2020,1995,2
Pontifical University Antonianum,Religion2020,1995,1
Pontifical University of the Holy Cross,Religion2020,1995,1
Portland State University,Religion2020,1995,3
Prague University,Religion2020,1995,1
Princeton Theological Seminary,Religion2020,1995,44
Princeton University,Cooperation2021,1995,2
Princeton University,Religion2020,1995,25
Priory of St Michael the Archangel,Religion2020,1995,4
Private Practice,Religion2020,1995,13
Protestant Church in Hesse and Nassau,Cooperation2021,1995,2
Protestant Church in Hesse and Nassau,CooperationAndReligion,1995,2
Protestant Church in Hesse and Nassau,Religion2020,1995,2
pruvio,Religion2020,1995,5
"Psychological Resources of Toledo, Ltd.",Religion2020,1995,3
Public Health Institute,Religion2020,1995,2
Purdue University,Cooperation2021,1995,3
Purdue University,Religion2020,1995,35
Purdue University Northwest,Religion2020,1995,1
QIMR Berhofer Medical Research Institute,Cooperation2021,1995,1
QIMR Berhofer Medical Research Institute,CooperationAndReligion,1995,1
QIMR Berhofer Medical Research Institute,Religion2020,1995,6
Queen Margaret University,Cooperation2021,1995,1
Queen Mary College,Religion2020,1995,3
Queen Mary University of London,Religion2020,1995,2
Queen’s University Belfast,Religion2020,1995,2
Queens College (CUNY),Religion2020,1995,3
Queen's University,Cooperation2021,1995,4
Queen's University,Religion2020,1995,3
Queen's University Belfast,Cooperation2021,1995,2
Queen's University Belfast,Religion2020,1995,27
Queen's University of Belfast,Religion2020,1995,2
Queensland Health,Cooperation2021,1995,3
Queensland University of Technology,Cooperation2021,1995,3
Quincy College,Religion2020,1995,2
Rabin Medical Center,Religion2020,1995,1
Radboud University,Religion2020,1995,14
Radboud University Medical Centre,Religion2020,1995,1
Radboud University Nijmegen,Religion2020,1995,17
RAND Corporation,Religion2020,1995,1
Reformed Theological Seminary,Cooperation2021,1995,2
Reformed Theological Seminary,CooperationAndReligion,1995,1
Reformed Theological Seminary,Religion2020,1995,1
Refua Shlema Clinic,Religion2020,1995,2
Regent College,Religion2020,1995,8
Regent University,Religion2020,1995,3
Religion,Religion2020,1995,2
religion news service,Religion2020,1995,2
Religions of South Asia,Religion2020,1995,1
Renmin University of China,Religion2020,1995,2
Research Institute for Theology and Religion,Religion2020,1995,2
Resources for the Study of Social Engagement,Religion2020,1995,1
Rheinische Friedrich-Wilhelms-Universität Bonn,Religion2020,1995,1
Rhodes College,Cooperation2021,1995,1
Rhodes University,Cooperation2021,1995,2
Rice University,Cooperation2021,1995,11
Rice University,CooperationAndReligion,1995,1
Rice University,Religion2020,1995,24
Ripon College,Cooperation2021,1995,1
Ripon College,Religion2020,1995,3
Ripon College Cuddesdon,Religion2020,1995,1
Ritsumeikan University,Religion2020,1995,1
Ritual Studied Internatinoal,Religion2020,1995,5
RMIT University,Cooperation2021,1995,4
RMIT University,Religion2020,1995,1
Roanoke College,Religion2020,1995,5
Roberts Wesleyan College,Religion2020,1995,4
Rockefeller University,Cooperation2021,1995,2
Rockford University,Religion2020,1995,1
Rockhurst University,Religion2020,1995,1
Rocky Mountain Biological Laboratory,Cooperation2021,1995,3
Roehampton University,Religion2020,1995,3
Rollins College,Religion2020,1995,7
ronin institute,Religion2020,1995,1
Roosevelt University,Religion2020,1995,1
Rowan University,Religion2020,1995,1
Royal College of Psychiatrists,Religion2020,1995,1
"Royal Holloway, University of London",Religion2020,1995,14
Royal Roads University,Religion2020,1995,2
Royal Society,Cooperation2021,1995,2
Royal University of Phnom Phen,Religion2020,1995,1
Roybal Institute on Aging,Religion2020,1995,1
RUDN University,Religion2020,1995,2
Rudolf Steiner University College,Religion2020,1995,1
Ruhr University Bochum,Religion2020,1995,5
Rush University,Cooperation2021,1995,6
Rush University,CooperationAndReligion,1995,1
Rush University,Religion2020,1995,15
Ruskin College Oxford,Religion2020,1995,1
Russian Academy of National Economy and Public Administration,Religion2020,1995,1
Russian State University for the Humanities,Religion2020,1995,1
Rutgers New Jersey Medical School,Religion2020,1995,2
Rutgers University,Cooperation2021,1995,11
Rutgers University,Religion2020,1995,19
Rutherford University,Religion2020,1995,6
RWTH Aachen University,Cooperation2021,1995,4
Ryerson University,Cooperation2021,1995,1
Saarland University,Cooperation2021,1995,1
Saint Anselm College,Religion2020,1995,1
Saint Joseph's Universty,Religion2020,1995,3
Saint Louis University,Religion2020,1995,10
Saint Mary's College,Religion2020,1995,7
Saint Mary's University,Religion2020,1995,1
Saint Meinrad Seminary and School of Theology,Cooperation2021,1995,1
Saint Meinrad Seminary and School of Theology,Religion2020,1995,4
Saint Michael's College,Religion2020,1995,1
Saint Paul University,Religion2020,1995,6
Sam Houston State University,Religion2020,1995,3
Samford University,Religion2020,1995,7
Sampoerna Capital,Religion2020,1995,1
San Francisco State University,Religion2020,1995,5
San Francisco Theological Seminary,Cooperation2021,1995,1
San Francisco Theological Seminary,Religion2020,1995,1
San Jose State University,Religion2020,1995,1
Santa Clara University,Religion2020,1995,26
Santa Fe Institute,Cooperation2021,1995,5
Sarum College,Religion2020,1995,1
saudi center for organ transplantation,Religion2020,1995,1
Saw Swee Hock School of Public Health,Religion2020,1995,1
Saybrook Graduate School,Religion2020,1995,1
Saybrook University,Religion2020,1995,3
Scarritt College,Religion2020,1995,1
School for Advanced Studies in the Social Sciences,Religion2020,1995,6
SCIENCE magazine,Religion2020,1995,1
Sciences Po,Cooperation2021,1995,3
Sciences Po,Religion2020,1995,2
Scientific Association of Stress Management and Health Promotion,Religion2020,1995,1
Scripps College,Religion2020,1995,4
Search Institute,Cooperation2021,1995,1
Search Institute,Religion2020,1995,1
Seattle University,Religion2020,1995,1
Self-employed,Religion2020,1995,2
Seton Hall University,Religion2020,1995,6
Shaanxi Normal University,Religion2020,1995,5
Shadow Work,Religion2020,1995,3
Shanghai Clinical Research Center,Cooperation2021,1995,1
Shanghai Clinical Research Center,Religion2020,1995,1
Shanghai Jiao Tong University,Cooperation2021,1995,2
Sheffield Hallam University,Religion2020,1995,2
Shukutoku University,Religion2020,1995,1
Simon Fraser University,Cooperation2021,1995,7
Simon Fraser University,Religion2020,1995,2
Singapore Management University,Religion2020,1995,2
Smith College,Religion2020,1995,3
Smithsonian Tropical Research Institute,Cooperation2021,1995,1
SOAS University of London,Religion2020,1995,8
Sofia University,Religion2020,1995,1
Sophia University,Religion2020,1995,10
Sorbonne University,Religion2020,1995,1
South Dakota State University,Religion2020,1995,3
Southampton University,Religion2020,1995,1
Southern Baptist Theological Seminary,Religion2020,1995,4
Southern Illinois University,Religion2020,1995,20
Southern Methodist University,Cooperation2021,1995,2
Southern Methodist University,Religion2020,1995,10
Southwest Missouri State University,Religion2020,1995,1
Southwest National Primate Center,Cooperation2021,1995,2
Spiritual Competency Academy,Religion2020,1995,6
Ss. Peter & Paul Parish,Religion2020,1995,3
SSM Health,Religion2020,1995,1
St Andrews University,Religion2020,1995,3
St Botolph's Aldgate,Religion2020,1995,1
St Mary's University,Religion2020,1995,2
St Peter’s Saltley Trust,Cooperation2021,1995,1
St. Andrews University,Religion2020,1995,3
St. Augustine College,Religion2020,1995,1
St. Augustine's College,Religion2020,1995,1
St. Augustine's University,Religion2020,1995,1
St. Bonaventure University,Religion2020,1995,1
St. Catharine's College,Religion2020,1995,3
"St. Edmund Hall, Oxford",Religion2020,1995,1
St. Edward's University,Religion2020,1995,5
St. Francis Hospice,Religion2020,1995,1
St. Francis Xavier University,Religion2020,1995,4
St. Hilda's College,Religion2020,1995,1
St. Jerome's University,Cooperation2021,1995,1
St. Jerome's University,Religion2020,1995,3
St. John Fisher College,Religion2020,1995,2
St. John's College,Religion2020,1995,6
"St. Johns College, Cambridge",Cooperation2021,1995,1
St. John's Medical College,Religion2020,1995,1
St. John's Seminary,Religion2020,1995,7
St. John's University,Religion2020,1995,6
St. Joseph's University,Religion2020,1995,2
St. Louis University,Religion2020,1995,1
St. Mary's Seminary and University,Religion2020,1995,1
St. Olaf College,Religion2020,1995,8
St. Petersburg State University,Religion2020,1995,1
"St. Thomas Hospital, London",Religion2020,1995,2
St. Vincent College,Religion2020,1995,2
Staffordshire University,Religion2020,1995,5
Stanford University,Cooperation2021,1995,10
Stanford University,CooperationAndReligion,1995,1
Stanford University,Religion2020,1995,30
Stanley Royd Hospital,Religion2020,1995,2
State University of Campinas,Religion2020,1995,1
State University of New York,Religion2020,1995,1
State University of New York - Nassau,Religion2020,1995,1
State University of New York at Albany,Cooperation2021,1995,1
State University of New York at Albany,Religion2020,1995,7
State University of New York at Binghamton University,Cooperation2021,1995,3
State University of New York at Brockport,Religion2020,1995,2
State University of New York at Buffalo,Religion2020,1995,4
State University of New York at Oneonta,Religion2020,1995,3
State University of New York at Stony Brook,Cooperation2021,1995,1
State University of New York at Stony Brook,Religion2020,1995,3
Stellenbosch University,Religion2020,1995,3
Stetson University,Religion2020,1995,10
Stichting Gelderloos Foundation,Religion2020,1995,1
Stockholm School of Economics,Cooperation2021,1995,1
Stockholm University,Cooperation2021,1995,1
Stockholm University,Religion2020,1995,23
Stony Brook University,Cooperation2021,1995,7
Stony Brook University,CooperationAndReligion,1995,1
Stony Brook University,Religion2020,1995,12
Strayer College,Religion2020,1995,2
Studia Traditionis Theologiae,Religion2020,1995,1
Suffolk University,Cooperation2021,1995,1
Suffolk University,Religion2020,1995,9
SUNY Buffalo,Religion2020,1995,2
SUNY Upstate Medical University,Religion2020,1995,1
SVYASA University,Cooperation2021,1995,1
S-VYASA University,Religion2020,1995,3
Swansea University,Religion2020,1995,11
Swarthmore College,Religion2020,1995,6
Swedish Medical Center in Seattle,Religion2020,1995,1
Swinburne University of Technology,Religion2020,1995,1
Syarif Hidayatullah State Islamic University,Religion2020,1995,1
Sydney College of Divinity,Religion2020,1995,3
Syracuse University,Cooperation2021,1995,4
Syracuse University,Religion2020,1995,23
Szabo Consultnacy LTD,Cooperation2021,1995,2
T&C Chen Center for Social and Decision Neuroscience,Cooperation2021,1995,1
Tabor College,Religion2020,1995,4
Tamkang University,Cooperation2021,1995,1
Tamkang University,Religion2020,1995,5
Tampere University,Cooperation2021,1995,1
Tampere University,Religion2020,1995,1
Taos Institute,Religion2020,1995,1
Technical University of Berlin,Religion2020,1995,1
Technical University of Munich,Religion2020,1995,1
Tel Aviv University,Cooperation2021,1995,3
Tel Aviv University,Religion2020,1995,6
tel-hai college,Cooperation2021,1995,4
Temple University,Religion2020,1995,6
Tennessee State University,Religion2020,1995,6
Texas A&M University,Cooperation2021,1995,4
Texas A&M University,Religion2020,1995,7
Texas Christian University,Cooperation2021,1995,1
Texas Christian University,Religion2020,1995,4
Texas State University,Religion2020,1995,3
Texas Tech University,Religion2020,1995,4
The Arctic University of Norway,Religion2020,1995,1
The Aspen Institute,Religion2020,1995,2
The British Library,Religion2020,1995,2
The Catholic University of America,Religion2020,1995,3
The Chinese University of Hong Kong,Religion2020,1995,5
The Coventry Association for Pastoral Psychology,Religion2020,1995,1
The Cullition Group,Religion2020,1995,1
The Hastings Center,Religion2020,1995,4
The Hebrew University,Cooperation2021,1995,2
The Hebrew University,Religion2020,1995,2
The Israel Society for the Prevention of Alcoholism,Religion2020,1995,6
The Jane Goodall Institute,Cooperation2021,1995,3
"The Kinneret Center on Peace, Securityand Society",Religion2020,1995,1
The Middle East Technical University,Religion2020,1995,1
The National Academy of Sciences of Ukraine,Cooperation2021,1995,1
The National Academy of Sciences of Ukraine,CooperationAndReligion,1995,1
The National Academy of Sciences of Ukraine,Religion2020,1995,22
The New York Times,Religion2020,1995,1
The Open University,Religion2020,1995,2
The Research Institute of Industrial Economics,Religion2020,1995,1
The University of Hong Kong,Cooperation2021,1995,1
The University of New South Wales-The Kirby Institute,Cooperation2021,1995,1
The University of New South Wales-The Kirby Institute,Religion2020,1995,1
The University of the South Sewanee,Religion2020,1995,1
Theopolis Institute,Religion2020,1995,1
Tianjin University of Traditional Chinese Medicine,Religion2020,1995,2
Tilburg University,Cooperation2021,1995,8
Tilburg University,Religion2020,1995,12
Tokyo Institute of Technology,Cooperation2021,1995,2
Tokyo University of Science,Cooperation2021,1995,1
Tokyo Woman's Christian University,Religion2020,1995,1
Toronto School of Theology,Religion2020,1995,2
Tribunal of the Catholic Diocese of Colombus,Religion2020,1995,1
Trilateral Research Ltd,Religion2020,1995,1
Trinity College,Religion2020,1995,11
Trinity College Dublin,Religion2020,1995,6
Trinity College of Arts & Sciences,Religion2020,1995,6
Trinity Evangelical Divinity School,Religion2020,1995,1
Trinity International University,Religion2020,1995,2
Trinity University,Religion2020,1995,4
Troy University,Cooperation2021,1995,1
Truman State University,Religion2020,1995,3
Tufts University,Religion2020,1995,9
Tulane University,Religion2020,1995,4
UK Parliament,Religion2020,1995,2
Ukrainian Association of Ressearchers of Religion,Religion2020,1995,1
Ulster University,Religion2020,1995,2
Umeå University,Cooperation2021,1995,1
Umeå University,Religion2020,1995,2
UNESCO,Cooperation2021,1995,2
Uninettuno international telematic university,Cooperation2021,1995,3
Union College,Religion2020,1995,2
Union Congregational United Church of Christ,Religion2020,1995,3
Union Presbyterian Seminary,Religion2020,1995,8
Union Theological College,Religion2020,1995,2
Union Theological Seminary,Religion2020,1995,14
United States Naval Academy,Cooperation2021,1995,1
United States Naval Academy,Religion2020,1995,4
United Theological College,Religion2020,1995,3
United Theological Seminary of the Twin Cities,Religion2020,1995,2
Universidad Autonoma Metropolitana,Religion2020,1995,1
Universidad Nacional de Educacion a Distancia,Cooperation2021,1995,1
Universidade do Porto,Religion2020,1995,1
Universidade Federal de San Carlos   São Carlos,Religion2020,1995,4
Università della Svizzera italiana,Cooperation2021,1995,1
Universitas Gadjah Mada,Religion2020,1995,3
Université Catholique de Louvain,Religion2020,1995,1
Université de Paris,Religion2020,1995,1
Universite Laval,Religion2020,1995,3
Université Libre de Bruxelles,Cooperation2021,1995,3
Université Libre in Brussels,Cooperation2021,1995,1
Université Paris I,Cooperation2021,1995,2
Universiti Putra Malaysia,Religion2020,1995,3
Universiti Sains Islam Malaysia,Religion2020,1995,1
Universiti Sains Malaysia,Religion2020,1995,5
Universiti Teknologi MARA,Religion2020,1995,1
University at Buffalo,Religion2020,1995,4
University College Chester,Religion2020,1995,1
University College Cork,Cooperation2021,1995,1
University College Cork,Religion2020,1995,3
University College Dublin,Cooperation2021,1995,1
University College Dublin,Religion2020,1995,4
University College London,Cooperation2021,1995,5
University College London,CooperationAndReligion,1995,1
University College London,Religion2020,1995,48
University College of North Wales,Religion2020,1995,5
University Hospital Virgen de las Nieves,Religion2020,1995,1
University Hospitals Connor,Religion2020,1995,1
University of Aarhus,Cooperation2021,1995,1
University of Aarhus,Religion2020,1995,6
University of Aberdeen,Cooperation2021,1995,1
University of Aberdeen,Religion2020,1995,27
University of Agder,Religion2020,1995,2
University of Akron,Religion2020,1995,7
University of Akureyri,Religion2020,1995,1
University of Alabama,Cooperation2021,1995,2
University of Alabama,Religion2020,1995,7
University of Alabama at Birmingham,Religion2020,1995,12
University of Alberta,Religion2020,1995,20
University of Alicante,Cooperation2021,1995,1
University of Amsterdam,Cooperation2021,1995,11
University of Amsterdam,Religion2020,1995,19
University of Antwerp,Cooperation2021,1995,3
University of Arizona,Cooperation2021,1995,4
University of Arizona,Religion2020,1995,19
University of Arkansas,Cooperation2021,1995,2
University of Arkansas,Religion2020,1995,7
University of Arkansas at Little Rock,Cooperation2021,1995,3
University of Arkansas at Little Rock,Religion2020,1995,5
University of Arkansas for Medical Science,Religion2020,1995,2
University of Auckland,Religion2020,1995,3
University of Ballarat,Cooperation2021,1995,1
University of Basel,Cooperation2021,1995,3
University of Basel,Religion2020,1995,1
University of Basque Country,Religion2020,1995,1
University of Bath,Cooperation2021,1995,1
University of Bath,Religion2020,1995,4
University of Bedfordshire,Cooperation2021,1995,1
University of Bedfordshire,Religion2020,1995,1
University of Bergen,Religion2020,1995,6
University of Bern,Cooperation2021,1995,1
University of Bern,Religion2020,1995,4
University of Bielefeld,Religion2020,1995,5
University of Birmingham,Religion2020,1995,29
University of Bologna,Cooperation2021,1995,1
University of Bonn,Cooperation2021,1995,2
University of Bonn,Religion2020,1995,9
University of Bordeaux,Cooperation2021,1995,1
University of Botswana,Religion2020,1995,2
University of Bradford,Cooperation2021,1995,1
University of Bradford,Religion2020,1995,2
University of Bremen,Cooperation2021,1995,1
University of Bremen,Religion2020,1995,4
University of Bridgeport,Religion2020,1995,1
University of Brighton,Religion2020,1995,1
University of Bristol,Cooperation2021,1995,3
University of Bristol,Religion2020,1995,38
University of British Columbia,Cooperation2021,1995,18
University of British Columbia,Religion2020,1995,22
University of Brunei Darussalam,Religion2020,1995,1
University of Buckingham,Cooperation2021,1995,1
University of Buffalo,Religion2020,1995,2
University of Burdwan,Religion2020,1995,1
University of Calcutta,Religion2020,1995,1
University of Calgary,Religion2020,1995,23
University of California,Religion2020,1995,2
University of California - Berkeley,Cooperation2021,1995,5
University of California - Berkeley,Religion2020,1995,14
University of California - Davis,Cooperation2021,1995,1
University of California - Davis,Religion2020,1995,8
University of California - Irvine,Cooperation2021,1995,7
University of California - Irvine,Religion2020,1995,8
University of California - Los Angeles,Cooperation2021,1995,5
University of California - Los Angeles,Religion2020,1995,19
University of California - Riverside,Cooperation2021,1995,3
University of California - Riverside,Religion2020,1995,10
University of California - San Diego,Cooperation2021,1995,3
University of California - San Francisco,Religion2020,1995,23
University of California - Santa Barbara,Cooperation2021,1995,3
University of California - Santa Barbara,Religion2020,1995,23
"University of California, Berkeley",Cooperation2021,1995,3
"University of California, Berkeley",Religion2020,1995,15
"University of California, Davis",Cooperation2021,1995,3
"University of California, Davis",Religion2020,1995,9
"University of California, Irvine",Cooperation2021,1995,7
"University of California, Irvine",Religion2020,1995,10
"University of California, Los Angeles",Cooperation2021,1995,12
"University of California, Los Angeles",Religion2020,1995,22
"University of California, Riverside",Cooperation2021,1995,5
"University of California, Riverside",Religion2020,1995,14
"University of California, San Diego",Cooperation2021,1995,2
"University of California, San Diego",CooperationAndReligion,1995,1
"University of California, San Diego",Religion2020,1995,11
"University of California, San Francisco",Religion2020,1995,1
"University of California, Santa Barbara",Cooperation2021,1995,1
"University of California, Santa Barbara",Religion2020,1995,31
"University of California, Santa Cruz",Religion2020,1995,4
University of Cambridge,Cooperation2021,1995,21
University of Cambridge,Religion2020,1995,73
University of Canterbury,Cooperation2021,1995,1
University of Canterbury,Religion2020,1995,5
University of Cape Town,Cooperation2021,1995,2
University of Cape Town,Religion2020,1995,7
University of Central England,Religion2020,1995,2
University of Central Florida,Religion2020,1995,2
University of Central Missouri,Religion2020,1995,2
University of Chester,Religion2020,1995,13
University of Chicago,Religion2020,1995,75
University of Chicago Divinity School,Religion2020,1995,9
University of Chichester,Cooperation2021,1995,1
University of Chichester,Religion2020,1995,4
University of Cincinnati,Religion2020,1995,12
University of Coimbra,Cooperation2021,1995,1
University of Cologne,Cooperation2021,1995,3
University of Colorado Anschutz Medical Campus,Religion2020,1995,1
University of Colorado at Boulder,Cooperation2021,1995,4
University of Colorado at Boulder,Religion2020,1995,8
University of Colorado at Colorado Springs,Cooperation2021,1995,3
University of Colorado at Colorado Springs,Religion2020,1995,12
University of Colorado Boulder,Cooperation2021,1995,3
University of Colorado Boulder,Religion2020,1995,2
University of Colorado Denver,Cooperation2021,1995,1
University of Colorado Denver,Religion2020,1995,6
University of Colorado Medical School,Religion2020,1995,7
"University of Colorado, Boulder",Cooperation2021,1995,1
"University of Colorado, Boulder",Religion2020,1995,3
University of Connecticut,Cooperation2021,1995,1
University of Connecticut,Religion2020,1995,17
University of Constance,Religion2020,1995,3
University of Copenhagen,Cooperation2021,1995,1
University of Copenhagen,Religion2020,1995,13
University of Cumbria,Religion2020,1995,5
University of Dayton,Religion2020,1995,12
University of Delaware,Cooperation2021,1995,1
University of Delaware,Religion2020,1995,6
University of Delhi,Religion2020,1995,1
University of Denver,Religion2020,1995,5
University of Derby,Cooperation2021,1995,7
University of Detroit Mercy,Religion2020,1995,3
University of Divinity,Religion2020,1995,3
University of Dubuque Theological Seminary,Religion2020,1995,1
University of Dundee,Cooperation2021,1995,3
University of Dundee,Religion2020,1995,3
University of Durham,Religion2020,1995,7
University of East Anglia,Religion2020,1995,3
University of East London,Religion2020,1995,1
University of Edinburgh,Cooperation2021,1995,2
University of Edinburgh,Religion2020,1995,89
University of Essex,Religion2020,1995,5
University of Exeter,Cooperation2021,1995,2
University of Exeter,Religion2020,1995,25
University of Ferrara,Religion2020,1995,1
University of Florida,Cooperation2021,1995,4
University of Florida,Religion2020,1995,25
University of Fribourg,Religion2020,1995,2
University of Galve,Religion2020,1995,4
University of Geneva,Cooperation2021,1995,3
University of Geneva,Religion2020,1995,2
University of Georgia,Cooperation2021,1995,10
University of Georgia,Religion2020,1995,6
University of Girona,Religion2020,1995,1
University of Glasgow,Cooperation2021,1995,1
University of Glasgow,Religion2020,1995,34
University of Gloucestershire,Religion2020,1995,5
University of Gothenburg,Cooperation2021,1995,6
University of Gothenburg,Religion2020,1995,1
University of Göttingen,Cooperation2021,1995,12
University of Göttingen,Religion2020,1995,6
University of Groningen,Cooperation2021,1995,13
University of Groningen,Religion2020,1995,11
University of Guelph,Cooperation2021,1995,4
University of Guelph,Religion2020,1995,1
University of Haifa,Cooperation2021,1995,6
University of Haifa,Religion2020,1995,16
University of Hamburg,Cooperation2021,1995,1
University of Hamburg,Religion2020,1995,3
University of Hawai‘i at Mānoa,Religion2020,1995,5
University of Hawai‘i–West O‘ahu,Cooperation2021,1995,1
University of Hawai‘i–West O‘ahu,CooperationAndReligion,1995,1
University of Hawai‘i–West O‘ahu,Religion2020,1995,4
University of Hawai'i,Religion2020,1995,2
University of Hawaii at Manoa,Cooperation2021,1995,2
University of Hawaii at Manoa,Religion2020,1995,1
University of Heidelberg,Religion2020,1995,4
University of Helsinki,Cooperation2021,1995,6
University of Helsinki,Religion2020,1995,8
University of Hertfordshire,Religion2020,1995,1
University of Hong Kong,Religion2020,1995,8
University of Houston,Religion2020,1995,5
University of Huddersfield,Religion2020,1995,2
University of Hull,Cooperation2021,1995,1
University of Hull,Religion2020,1995,10
University of Humanistic Studies,Religion2020,1995,1
University of Iceland,Religion2020,1995,1
University of Illinois,Cooperation2021,1995,5
University of Illinois,Religion2020,1995,20
University of Illinois at Chicago,Religion2020,1995,4
University of Illinois at Urbana-Champaign,Cooperation2021,1995,5
University of Illinois at Urbana-Champaign,Religion2020,1995,14
University of Illinois Urbana-Champaign,Cooperation2021,1995,1
University of Iowa,Cooperation2021,1995,1
University of Iowa,Religion2020,1995,24
University of Jena,Cooperation2021,1995,1
University of Jyväskylä,Cooperation2021,1995,3
University of Kansas,Cooperation2021,1995,6
University of Kansas,Religion2020,1995,19
University of Kent,Cooperation2021,1995,4
University of Kent,Religion2020,1995,16
University of Kent at Canterbury,Religion2020,1995,2
University of Kentucky,Religion2020,1995,8
University of Kerala,Religion2020,1995,1
University of Kiel,Religion2020,1995,2
University of Koblenz-Landau,Religion2020,1995,2
University of Koln,Religion2020,1995,1
University of Konstanz,Religion2020,1995,4
University of KwaZulu-Natal,Religion2020,1995,1
University of Lancaster,Religion2020,1995,8
University of L'Aquila,Cooperation2021,1995,3
University of Lausanne,Cooperation2021,1995,3
University of Lausanne,Religion2020,1995,5
University of Leeds,Religion2020,1995,17
University of Leicester,Cooperation2021,1995,1
University of Leicester,Religion2020,1995,31
University of Leiden,Religion2020,1995,6
University of Leipzig,Religion2020,1995,4
University of Lethbridge,Cooperation2021,1995,2
University of Lethbridge,Religion2020,1995,4
University of Leuven,Cooperation2021,1995,1
University of Leuven,Religion2020,1995,6
University of Lille III,Religion2020,1995,1
University of Limerick,Religion2020,1995,1
University of Lincoln,Religion2020,1995,4
University of Liverpool,Religion2020,1995,9
University of London,Cooperation2021,1995,1
University of London,CooperationAndReligion,1995,1
University of London,Religion2020,1995,48
University of Louisville,Cooperation2021,1995,7
University of Louisville,Religion2020,1995,3
University of Lucerne,Religion2020,1995,4
University of Lynchburg,Religion2020,1995,1
University of Macau,Religion2020,1995,3
University of Madras,Religion2020,1995,1
University of Maine,Religion2020,1995,3
University of Malawi,Religion2020,1995,2
University of Malaya,Cooperation2021,1995,1
University of Malaya,Religion2020,1995,3
University of Manchester,Cooperation2021,1995,4
University of Manchester,Religion2020,1995,21
University of Manitoba,Cooperation2021,1995,1
University of Manitoba,CooperationAndReligion,1995,1
University of Manitoba,Religion2020,1995,12
University of Mannheim,Religion2020,1995,1
University of Marburg,Religion2020,1995,2
University of Maryland,Cooperation2021,1995,5
University of Maryland,Religion2020,1995,7
"University of Maryland, College Park",Cooperation2021,1995,8
"University of Maryland, College Park",Religion2020,1995,10
University of Massachusetts - Amherst,Cooperation2021,1995,4
University of Massachusetts - Amherst,Religion2020,1995,5
University of Massachusetts - Boston,Cooperation2021,1995,1
University of Massachusetts - Boston,Religion2020,1995,1
University of Massachusetts Amherst,Cooperation2021,1995,1
University of Massachusetts Medical School,Religion2020,1995,2
University of Massachusetts Memorial Medical Center,Religion2020,1995,8
"University of Massachusetts, Boston",Cooperation2021,1995,3
"University of Massachusetts, Worcester",Religion2020,1995,1
University of Medicine and Dentistry of New Jersey,Cooperation2021,1995,3
University of Melbourne,Religion2020,1995,14
University of Memphis,Religion2020,1995,9
University of Miami,Cooperation2021,1995,2
University of Miami,Religion2020,1995,29
University of Michigan,Cooperation2021,1995,11
University of Michigan,CooperationAndReligion,1995,1
University of Michigan,Religion2020,1995,76
"University of Michigan, Ann Arbor",Religion2020,1995,4
"University of Michigan, Dearborn",Religion2020,1995,1
University of Milan,Cooperation2021,1995,2
University of Minnesota,Cooperation2021,1995,10
University of Minnesota,CooperationAndReligion,1995,1
University of Minnesota,Religion2020,1995,22
University of Mississippi,Religion2020,1995,2
University of Missouri,Cooperation2021,1995,5
University of Missouri,Religion2020,1995,3
University of Missouri Mizzou,Religion2020,1995,1
University of Missouri-Kansas City,Cooperation2021,1995,1
University of Missouri-Kansas City,Religion2020,1995,1
University of Missouri-St. Louis,Religion2020,1995,1
University of Montpellier,Cooperation2021,1995,1
University of Montreal,Cooperation2021,1995,2
University of Montreal,Religion2020,1995,25
University of Muenster,Religion2020,1995,7
University of Munich,Religion2020,1995,4
University of Munster,Religion2020,1995,1
University of Münster,Religion2020,1995,2
University of Namur,Cooperation2021,1995,1
University of Nanterre,Religion2020,1995,1
University of Navarra,Cooperation2021,1995,2
University of Nebraska,Cooperation2021,1995,4
University of Nebraska,Religion2020,1995,5
"University of Nebraska, Lincoln",Religion2020,1995,9
"University of Nebraska, Omaha",Cooperation2021,1995,2
"University of Nebraska, Omaha",Religion2020,1995,2
University of Nevada,Religion2020,1995,8
University of Nevada - Reno,Religion2020,1995,12
"University of Nevada, Las Vegas",Cooperation2021,1995,1
"University of Nevada, Las Vegas",Religion2020,1995,13
University of New Brunswick,Religion2020,1995,9
University of New England,Religion2020,1995,7
University of New Hampshire,Cooperation2021,1995,1
University of New Hampshire,Religion2020,1995,7
University of New Mexico,Cooperation2021,1995,2
University of New Mexico,Religion2020,1995,11
University of New South Wales,Cooperation2021,1995,6
University of New South Wales,Religion2020,1995,4
University of Newcastle,Cooperation2021,1995,4
University of Newcastle,Religion2020,1995,5
University of Nice,Cooperation2021,1995,1
University of Niigata Prefecture,Cooperation2021,1995,1
University of Nijmegen,Religion2020,1995,2
University of North Carolina,Cooperation2021,1995,4
University of North Carolina,Religion2020,1995,5
University of North Carolina - Chapel Hill,Religion2020,1995,16
University of North Carolina at Chapel Hill,Cooperation2021,1995,7
University of North Carolina at Chapel Hill,Religion2020,1995,26
University of North Carolina at Charlotte,Religion2020,1995,6
University of North Carolina at Greensboro,Religion2020,1995,6
University of North Carolina-Chapel Hill,Cooperation2021,1995,1
University of North Florida,Religion2020,1995,1
University of North Texas,Cooperation2021,1995,1
University of North Texas,Religion2020,1995,3
University of Notre Dame,Cooperation2021,1995,1
University of Notre Dame,CooperationAndReligion,1995,1
University of Notre Dame,Religion2020,1995,92
University of Notre Dame- Australia,Cooperation2021,1995,2
University of Notre Dame- Australia,Religion2020,1995,5
University of Nottingham,Religion2020,1995,19
University of Oklahoma,Cooperation2021,1995,2
University of Oklahoma,Religion2020,1995,9
University of Oregon,Cooperation2021,1995,7
University of Oregon,Religion2020,1995,11
University of Oslo,Cooperation2021,1995,2
University of Oslo,Religion2020,1995,13
University of Otago,Religion2020,1995,7
University of Ottawa,Cooperation2021,1995,2
University of Ottawa,Religion2020,1995,25
University of Oxford,Cooperation2021,1995,7
University of Oxford,Religion2020,1995,109
University of Paderborn,Cooperation2021,1995,1
University of Paderborn,Religion2020,1995,5
University of Padua,Religion2020,1995,1
University of Paris,Cooperation2021,1995,1
University of Paris,Religion2020,1995,2
University of Paris Ouest,Religion2020,1995,2
University of Paris VIII,Religion2020,1995,3
University of Pavia,Cooperation2021,1995,1
University of Pennsylvania,Cooperation2021,1995,10
University of Pennsylvania,CooperationAndReligion,1995,1
University of Pennsylvania,Religion2020,1995,26
University of Pisa,Cooperation2021,1995,4
University of Pisa,Religion2020,1995,1
University of Pittsburgh,Cooperation2021,1995,8
University of Pittsburgh,CooperationAndReligion,1995,2
University of Pittsburgh,Religion2020,1995,11
University of Plymouth,Cooperation2021,1995,1
University of Plymouth,Religion2020,1995,1
University of Port Harcourt,Religion2020,1995,3
University of Porto,Religion2020,1995,1
University of Portsmouth,Religion2020,1995,1
University of Pretoria,Cooperation2021,1995,1
University of Pretoria,Religion2020,1995,24
University of Québec at Montreal,Religion2020,1995,5
University of Quebec at Trois-Rivières,Religion2020,1995,3
University of Quebec in Chicoutimi,Cooperation2021,1995,3
University of Quebec in Chicoutimi,Religion2020,1995,1
University of Quebec in Montreal,Religion2020,1995,1
University of Queensland,Cooperation2021,1995,5
University of Queensland,Religion2020,1995,5
University of Reading,Cooperation2021,1995,4
University of Reading,Religion2020,1995,7
University of Redlands,Religion2020,1995,6
University of Regensburg,Cooperation2021,1995,3
University of Regensburg,Religion2020,1995,2
University of Regina,Religion2020,1995,6
University of Rennes 2,Religion2020,1995,5
University of Rhode Island,Religion2020,1995,1
University of Richmond,Cooperation2021,1995,1
University of Richmond,Religion2020,1995,8
University of Rijeka,Religion2020,1995,2
University of Rochester,Cooperation2021,1995,1
University of Rochester,Religion2020,1995,1
University of Roehampton,Religion2020,1995,8
University of Rome,Religion2020,1995,2
University of Rostock,Religion2020,1995,4
University of Saint Mary of the Lake,Religion2020,1995,2
University of San Diego,Religion2020,1995,7
University of San Francisco,Religion2020,1995,6
University of Santiago,Religion2020,1995,2
University of São Paulo,Religion2020,1995,2
University of Saskatchewan,Cooperation2021,1995,5
University of Saskatchewan,Religion2020,1995,8
"University of Science, Malaysia",Religion2020,1995,2
University of Scranton,Religion2020,1995,3
University of Sheffield,Cooperation2021,1995,2
University of Sheffield,Religion2020,1995,6
University of Sherbrooke,Religion2020,1995,5
University of Siegen,Religion2020,1995,3
University of Siena,Cooperation2021,1995,4
University of South Africa,Religion2020,1995,13
University of South Australia,Religion2020,1995,2
University of South Carolina,Cooperation2021,1995,1
University of South Carolina,Religion2020,1995,19
University of South Florida,Religion2020,1995,2
University of South Florida - Saint Petersburg,Religion2020,1995,2
University of Southampton,Cooperation2021,1995,2
University of Southampton,Religion2020,1995,5
University of Southern California,Cooperation2021,1995,10
University of Southern California,Religion2020,1995,26
University of Southern Denmark,Religion2020,1995,4
University of Southern Maine,Religion2020,1995,1
University of Split,Religion2020,1995,2
University of St Andrews,Cooperation2021,1995,1
University of St Andrews,Religion2020,1995,12
University of St. Thomas,Religion2020,1995,7
University of Stavanger,Cooperation2021,1995,3
University of Stavanger,Religion2020,1995,1
University of Stellenbosch,Cooperation2021,1995,1
University of Stellenbosch,Religion2020,1995,5
University of Stirling,Religion2020,1995,12
University of Strasbourg,Cooperation2021,1995,1
University of Strasbourg,Religion2020,1995,8
University of Strassburg,Religion2020,1995,2
University of Strathclyde,Cooperation2021,1995,2
University of Strathclyde,Religion2020,1995,1
University of Sunderland,Religion2020,1995,3
University of Surrey,Religion2020,1995,3
University of Sussex,Cooperation2021,1995,5
University of Sussex,Religion2020,1995,4
University of Sydney,Cooperation2021,1995,6
University of Sydney,Religion2020,1995,14
University of Tampere,Cooperation2021,1995,1
University of Tartu,Religion2020,1995,2
University of Tennessee,Cooperation2021,1995,4
University of Tennessee,Religion2020,1995,27
University of Tennessee at Chattanooga,Cooperation2021,1995,1
University of Tennessee at Chattanooga,Religion2020,1995,5
University of Texas at Austin,Cooperation2021,1995,6
University of Texas at Austin,Religion2020,1995,17
University of Texas at Dallas,Cooperation2021,1995,5
University of Texas at Dallas,Religion2020,1995,2
University of Texas at San Antonio,Religion2020,1995,20
University of Texas Health Science Center at Houston (UTHealth),Cooperation2021,1995,1
University of Texas Health Science Center at Houston (UTHealth),Religion2020,1995,5
University of Texas MD Anderson Cancer Center,Religion2020,1995,5
University of Texas Medical Branch,Religion2020,1995,2
University of Texas Pan American,Cooperation2021,1995,1
University of Texas Pan American,Religion2020,1995,4
University of Texas Rio Grande Valley,Religion2020,1995,3
University of Texas School of Public Health,Religion2020,1995,5
University of the Free State,Cooperation2021,1995,1
University of the Free State,Religion2020,1995,8
University of the Highlands and Islands,Religion2020,1995,1
University of the South,Cooperation2021,1995,1
University of the South,Religion2020,1995,1
University of the Sunshine Coast,Cooperation2021,1995,1
University of the Sunshine Coast,Religion2020,1995,5
University of the West of England,Cooperation2021,1995,1
University of the West of England,Religion2020,1995,5
University of Tilburg,Religion2020,1995,2
University of Tokyo,Cooperation2021,1995,3
University of Tokyo,Religion2020,1995,2
University of Toronto,Cooperation2021,1995,9
University of Toronto,CooperationAndReligion,1995,1
University of Toronto,Religion2020,1995,64
University of Trento,Cooperation2021,1995,1
University of Trento,Religion2020,1995,1
University of Tsukuba,Religion2020,1995,1
University of Tubigen Germany,Cooperation2021,1995,1
University of Tubigen Germany,Religion2020,1995,4
University of Tübingen,Religion2020,1995,20
University of Tulsa,Cooperation2021,1995,1
University of Tulsa,Religion2020,1995,5
University of Turin,Cooperation2021,1995,1
University of Turku,Religion2020,1995,6
University of Twente,Religion2020,1995,1
University of Udine,Religion2020,1995,1
University of Ulm,Religion2020,1995,3
University of Ulsan,Religion2020,1995,2
University of Ulster,Religion2020,1995,4
University of Utah,Cooperation2021,1995,3
University of Utah,Religion2020,1995,28
University of Utrecht,Cooperation2021,1995,5
University of Utrecht,Religion2020,1995,8
University of Vermont,Cooperation2021,1995,2
University of Vermont,Religion2020,1995,13
University of Victoria,Cooperation2021,1995,1
University of Victoria,Religion2020,1995,4
University of Vienna,Cooperation2021,1995,15
University of Vienna,Religion2020,1995,12
University of Virginia,Cooperation2021,1995,2
University of Virginia,Religion2020,1995,32
University of Waikato,Cooperation2021,1995,1
University of Wales,Religion2020,1995,19
University of Wales at Lampeter,Religion2020,1995,5
University of Wales Trinity Saint David,Religion2020,1995,3
University of Warsaw,Cooperation2021,1995,1
University of Warwick,Cooperation2021,1995,3
University of Warwick,CooperationAndReligion,1995,1
University of Warwick,Religion2020,1995,48
University of Washington,Cooperation2021,1995,18
University of Washington,Religion2020,1995,12
"University of Washington, Seattle",Cooperation2021,1995,3
University of Waterloo,Cooperation2021,1995,1
University of Waterloo,Religion2020,1995,13
University of West England,Cooperation2021,1995,1
University of West Florida,Religion2020,1995,1
University of West Georgia,Religion2020,1995,9
University of Western Australia,Cooperation2021,1995,1
University of Western Australia,Religion2020,1995,3
University of Western Cape,Religion2020,1995,2
University of Western Ontario,Cooperation2021,1995,4
University of Western Ontario,Religion2020,1995,7
University of Westminster,Cooperation2021,1995,1
University of Westminster,Religion2020,1995,3
University of Winchester,Religion2020,1995,7
University of Windsor,Religion2020,1995,2
University of Wisconsin,Cooperation2021,1995,1
University of Wisconsin,Religion2020,1995,13
University of Wisconsin - Madison,Cooperation2021,1995,3
University of Wisconsin - Madison,Religion2020,1995,15
University of Wisconsin–Eau Claire,Religion2020,1995,1
University of Wisconsin-Madison,Cooperation2021,1995,2
University of Wisconsin-Madison,Religion2020,1995,1
University of Wisconsin-Milwaukee,Religion2020,1995,3
University of Witten/Herdecke,Religion2020,1995,1
University of Witwatersrand,Religion2020,1995,1
University of Wolverhampton,Religion2020,1995,2
University of Worcester,Cooperation2021,1995,1
University of Worcester,Religion2020,1995,2
University of Würzburg,Religion2020,1995,3
University of Wyoming,Religion2020,1995,1
University of York,Religion2020,1995,11
University of Zambia,Religion2020,1995,1
University of Zimbabwe,Religion2020,1995,2
University of Zurich,Cooperation2021,1995,8
University of Zurich,Religion2020,1995,6
Uppsala University,Cooperation2021,1995,2
Uppsala University,Religion2020,1995,14
Utah State University,Religion2020,1995,3
Utrecht University,Cooperation2021,1995,2
Utrecht University,Religion2020,1995,28
VA Cincinnatti,Religion2020,1995,4
VA Minneapolis Medical Center,Religion2020,1995,1
Valparaiso University,Religion2020,1995,11
Vanderbilt Divinity School,Religion2020,1995,1
Vanderbilt University,Cooperation2021,1995,1
Vanderbilt University,Religion2020,1995,38
Vanguard University,Religion2020,1995,2
Venebio,Religion2020,1995,2
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Religion2020,1995,1
Veterans Health Adminstration,Cooperation2021,1995,1
Veterans Health Adminstration,CooperationAndReligion,1995,1
Veterans Health Adminstration,Religion2020,1995,2
Viadrina European University,Cooperation2021,1995,1
Victoria University of Wellington,Cooperation2021,1995,1
Victoria University of Wellington,Religion2020,1995,2
Villanova University,Cooperation2021,1995,1
Villanova University,Religion2020,1995,19
Virginia Beach Psychiatric Center,Religion2020,1995,4
Virginia Commonwealth University,Religion2020,1995,17
Virginia Hospital Center,Religion2020,1995,1
Virginia Polytechnic Institute and State University,Cooperation2021,1995,2
Virginia Polytechnic Institute and State University,Religion2020,1995,3
Virginia Tech,Religion2020,1995,3
Virginia Theological Seminary,Religion2020,1995,1
Virginia Wesleyan College,Religion2020,1995,1
Vision of Hope Baptist Church,Religion2020,1995,1
Vitalant Research Institute,Cooperation2021,1995,1
Vrije Universiteit Amsterdam,Cooperation2021,1995,14
Vrije Universiteit Amsterdam,Religion2020,1995,13
Vrije Universiteit Brussel,Religion2020,1995,1
Vrije Universiteit Medical Center,Religion2020,1995,8
Wabash College,Religion2020,1995,8
Wadham College,Religion2020,1995,2
Wageningen University,Cooperation2021,1995,1
Wake Forest University,Religion2020,1995,15
Walden University,Religion2020,1995,6
Wallace Community College,Religion2020,1995,1
Wartburg Theological Seminary,Religion2020,1995,4
Waseda University,Cooperation2021,1995,1
Waseda University,Religion2020,1995,4
Washington and Lee University,Religion2020,1995,2
Washington College,Religion2020,1995,2
Washington State University,Cooperation2021,1995,1
Washington State University,Religion2020,1995,1
Washington Theological Coalition,Religion2020,1995,2
Washington University in St Louis,Cooperation2021,1995,13
Washington University in St Louis,CooperationAndReligion,1995,2
Washington University in St Louis,Religion2020,1995,40
Waterloo Lutheran Seminary,Religion2020,1995,1
Wayne State University,Cooperation2021,1995,2
Wayne State University,Religion2020,1995,8
Webster University,Religion2020,1995,4
Weill Cornell Medical College,Cooperation2021,1995,1
Weill Cornell Medical College,Religion2020,1995,4
Wellesley College,Cooperation2021,1995,1
Wellesley College,CooperationAndReligion,1995,1
Wellesley College,Religion2020,1995,4
Wesleyan University,Cooperation2021,1995,1
Wesleyan University,Religion2020,1995,3
West Chester University of Pennsylvania,Cooperation2021,1995,1
West Chester University of Pennsylvania,Religion2020,1995,2
West Virginia University,Religion2020,1995,8
Western Carolina University,Religion2020,1995,4
Western Kentucky University,Religion2020,1995,2
Western Michigan University,Religion2020,1995,1
Western Sydney University,Religion2020,1995,3
Western University,Religion2020,1995,4
Western Washington University,Religion2020,1995,2
Westmont College,Religion2020,1995,5
Wheaton College,Religion2020,1995,14
Whitley College,Religion2020,1995,2
Wichita State University,Religion2020,1995,2
Wilfrid Laurier University,Religion2020,1995,9
Willamette University,Religion2020,1995,8
William & Mary,Religion2020,1995,5
William Lyon University,Religion2020,1995,3
William Paterson University,Religion2020,1995,1
Williams College,Religion2020,1995,1
Wilmette Institute,Religion2020,1995,1
Witten/Herdecke University,Religion2020,1995,1
Wolfson College,Religion2020,1995,2
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,1995,3
Wonkwang University,Religion2020,1995,2
Woodbrooke Quaker Study Centre,Religion2020,1995,2
worcester polytechnic institute,Religion2020,1995,2
Wrexham Glyndwr University,Religion2020,1995,4
Wright State University,Religion2020,1995,4
Wuhan University,Religion2020,1995,4
Wycliffe College,Religion2020,1995,2
Xavier University,Religion2020,1995,6
Xi’an Jiaotong University,Cooperation2021,1995,1
Yale Divinity School,Cooperation2021,1995,1
Yale Divinity School,Religion2020,1995,10
Yale University,Cooperation2021,1995,13
Yale University,Religion2020,1995,63
Yale University Mind and Development Lab,Religion2020,1995,4
Yale University School of Medicine,Religion2020,1995,5
Yamaguchi Prefectural University,Religion2020,1995,3
Yeshiva University,Cooperation2021,1995,3
Yeshiva University,Religion2020,1995,4
Yeshivah of Flatbush High School,Cooperation2021,1995,1
Yeshivah of Flatbush High School,CooperationAndReligion,1995,1
Yeshivah of Flatbush High School,Religion2020,1995,2
Yonsei University,Religion2020,1995,3
York University,Religion2020,1995,12
Zhejiang University,Cooperation2021,1995,1
Aahrus University,Religion2020,1990,3
Aarhus University,Religion2020,1990,9
Aberystwyth University,Religion2020,1990,5
Abilene Christian University,Religion2020,1990,2
Abington Memorial Hospital,Religion2020,1990,1
abo akademi university,Religion2020,1990,4
Academia Sinica,Religion2020,1990,1
Achva Academic College,Religion2020,1990,1
Adelphi University,Cooperation2021,1990,1
Adelphi University,Religion2020,1990,1
AGB Search,Religion2020,1990,1
Aix-Marseille Université,Religion2020,1990,3
Akrofi-Christaller Institute for Theology,Religion2020,1990,1
Akron General Medical Center,Religion2020,1990,8
Albert Einstein College of Medicine,Cooperation2021,1990,3
Albert Einstein College of Medicine,Religion2020,1990,1
Alfred University,Cooperation2021,1990,1
Alfred University,Religion2020,1990,2
All India Institute of Medical Sciences,Religion2020,1990,1
Allegheny College,Religion2020,1990,2
Alliant International University,Religion2020,1990,2
Alphacrucis College,Cooperation2021,1990,1
Alphacrucis College,Religion2020,1990,2
American Academy of Nursing,Religion2020,1990,1
American Bible Society,Religion2020,1990,1
American Jewish University,Cooperation2021,1990,1
American Jewish University,Religion2020,1990,5
American Museum of Natural History,Religion2020,1990,1
American University,Religion2020,1990,9
American University of Kuwait,Religion2020,1990,4
American University of Sovereign Nations,Cooperation2021,1990,5
American University of Sovereign Nations,Religion2020,1990,3
Amherst College,Religion2020,1990,2
Analytical Centre of the Russian Academy of Sciences,Religion2020,1990,1
Andover Newton Theological School,Religion2020,1990,5
Andrews University,Religion2020,1990,4
Appalachian State University,Cooperation2021,1990,1
Arizona State University,Cooperation2021,1990,8
Arizona State University,Religion2020,1990,22
ARK,Religion2020,1990,1
ASM Affiliates Inc,Religion2020,1990,1
Atlantic School of Theology,Religion2020,1990,1
Auburn University,Religion2020,1990,1
Augusta State College,Religion2020,1990,1
Augustana College,Religion2020,1990,3
Augustana University,Religion2020,1990,21
Austin Presbyterian Theological Seminary,Cooperation2021,1990,3
Austin Presbyterian Theological Seminary,Religion2020,1990,2
Australia Theological Hall,Religion2020,1990,2
Australian Catholic University,Religion2020,1990,11
Australian National University,Cooperation2021,1990,2
Australian National University,Religion2020,1990,8
Austrian Academy of Sciences,Religion2020,1990,1
Avila University,Religion2020,1990,4
Ball State University,Religion2020,1990,8
Bangor University,Religion2020,1990,3
Baptist Seminary of Kentucky,Religion2020,1990,1
Bar Ilan University,Religion2020,1990,4
Bard College,Religion2020,1990,10
Bar-Ilan University,Cooperation2021,1990,7
Bar-Ilan University,Religion2020,1990,17
Barry University,Religion2020,1990,2
Bashkir State University,Religion2020,1990,5
Bates College,Cooperation2021,1990,1
Bates College,Religion2020,1990,5
Bath Spa University,Religion2020,1990,4
Baylor College of Medicine,Cooperation2021,1990,1
Baylor College of Medicine,Religion2020,1990,1
Baylor University,Cooperation2021,1990,1
Baylor University,Religion2020,1990,50
Belmont College,Religion2020,1990,3
Bemidji State University,Cooperation2021,1990,1
Ben Gurion University in the Negev,Religion2020,1990,2
Ben-Gurion University of the Negev,Religion2020,1990,11
Berkshire Publishing Group,Religion2020,1990,1
Berry College,Cooperation2021,1990,1
Bethel College,Religion2020,1990,1
Bethlem Royal Hospital,Religion2020,1990,2
Bielefeld University,Religion2020,1990,1
Binghamton University,Religion2020,1990,1
Biola University,Religion2020,1990,2
Birmingham University,Religion2020,1990,1
Bishop of Guilford,Religion2020,1990,2
Bloomsburg University,Religion2020,1990,2
Bocconi University,Cooperation2021,1990,2
Boston College,Cooperation2021,1990,2
Boston College,Religion2020,1990,23
Boston University,Cooperation2021,1990,3
Boston University,Religion2020,1990,31
Bournemouth University,Religion2020,1990,1
Bowling Green State University,Cooperation2021,1990,2
Bowling Green State University,Religion2020,1990,10
Bradley University,Cooperation2021,1990,1
Bradley University,Religion2020,1990,7
Brandeis University,Religion2020,1990,9
Brigham & Women's Hospital,Religion2020,1990,1
Brigham Young University,Religion2020,1990,36
Brite Divinity,Religion2020,1990,3
Brock University,Cooperation2021,1990,2
Brock University,Religion2020,1990,1
Brooklyn College,Religion2020,1990,1
Brown University,Cooperation2021,1990,2
Brown University,CooperationAndReligion,1990,1
Brown University,Religion2020,1990,11
Brunel University London,Religion2020,1990,5
Bryn Mawr College,Religion2020,1990,2
Bucknell University,Religion2020,1990,1
Burlington College,Religion2020,1990,1
C&V Senior Care Specialists,Religion2020,1990,2
C.G. Jung Institute of New York,Religion2020,1990,1
CableLabs,Cooperation2021,1990,1
California Institute of Integral Studies,Religion2020,1990,2
California Institute of Technology,Cooperation2021,1990,4
California Lutheran University,Religion2020,1990,3
California State University,Religion2020,1990,2
"California State University, Fullerton",Cooperation2021,1990,1
"California State University, Fullerton",Religion2020,1990,1
"California State University, Long Beach",Religion2020,1990,1
"California State University, Northridge",Religion2020,1990,2
"California State University, Stanislaus",Religion2020,1990,1
Calvin College,Religion2020,1990,11
Calvin University,Religion2020,1990,7
Cambridge Health Alliance,Religion2020,1990,1
Cambridge University,Religion2020,1990,28
Candler School of Theology,Religion2020,1990,4
Canterbury Christchurch University,Religion2020,1990,3
Cape Breton University,Religion2020,1990,2
Cape College of Theology,Religion2020,1990,2
Cardiff University,Religion2020,1990,9
Carleton College,Religion2020,1990,5
Carleton University,Religion2020,1990,4
Carnegie Mellon University,Cooperation2021,1990,6
Case Western Reserve University,Religion2020,1990,3
Catholic Ecumenical Institute,Religion2020,1990,1
Catholic Health Association,Religion2020,1990,10
Catholic Institute of Paris,Religion2020,1990,3
Catholic Theological Union,Religion2020,1990,1
Catholic University of America,Cooperation2021,1990,1
Catholic University of America,Religion2020,1990,20
Catholic University of Leuven,Religion2020,1990,1
Catholic University of Louvain,Religion2020,1990,2
Catholic University of Lublin,Cooperation2021,1990,1
Catholic University of Lublin,Religion2020,1990,2
Center for Advanced Studies Oslo,Religion2020,1990,1
Center for Applied Research in the Apostolate,Religion2020,1990,4
Center for Change,Religion2020,1990,6
Center for Interfaith Reconciliation,Religion2020,1990,2
Center for Southeast Asia Studies,Religion2020,1990,1
Center for the Study on New Religions,Religion2020,1990,4
Center for Theological Inquiry,Religion2020,1990,1
Central Michigan University,Religion2020,1990,3
Centre for Addiction and Mental Health,Religion2020,1990,1
Centre for Research and Evidence on Security Threats,Religion2020,1990,3
Centre National de la Recherche Scientifique,Religion2020,1990,3
Centre Tricontinental (CETRI),Religion2020,1990,2
Chang Gung Memorial Hospital at Linkou,Cooperation2021,1990,2
Chapman University,Cooperation2021,1990,2
Chapman University,Religion2020,1990,5
Charles Sturt University,Religion2020,1990,2
Cheung Kong Graduate School of Business,Cooperation2021,1990,2
Chinese University of Hong Kong,Cooperation2021,1990,1
Chinese University of Hong Kong,Religion2020,1990,1
Christ Church College,Religion2020,1990,3
Christian Medical College,Religion2020,1990,1
Christian Service University College,Religion2020,1990,1
Christian Theological Seminary,Religion2020,1990,3
Christ's College,Religion2020,1990,2
Chulalongkorn University,Cooperation2021,1990,5
Chulalongkorn University,Religion2020,1990,3
Church of England,Religion2020,1990,10
Church of Ireland Theological College,Religion2020,1990,1
City College of New York Hunter College,Religion2020,1990,1
City of Hope National Medical Center,Religion2020,1990,3
City University of New York,Religion2020,1990,2
City University of New York Graduate Center,Cooperation2021,1990,1
Claremont Graduate University,Cooperation2021,1990,1
Claremont Graduate University,Religion2020,1990,35
Claremont McKenna College,Religion2020,1990,2
Clemson University,Cooperation2021,1990,1
Clemson University,Religion2020,1990,3
Cleveland State University,Religion2020,1990,8
Clinical Psychology Doctoral Training Programme,Religion2020,1990,1
Club of Budapest,Cooperation2021,1990,1
Club of Budapest,Religion2020,1990,1
CNRS Comité national de la recherche scientifique,Religion2020,1990,3
Colgate University,Religion2020,1990,5
"Collaborative for Academic, Social, and Emotional Learning (CASEL)",Religion2020,1990,1
Collège de France,Religion2020,1990,3
College of Charleston,Religion2020,1990,3
College of Charlestown,Religion2020,1990,2
College of Dentistry,Cooperation2021,1990,1
College of Holy Cross,Religion2020,1990,2
College of the Holy Cross,Religion2020,1990,5
Colorado School of Mines,Religion2020,1990,1
Colorado State University,Religion2020,1990,4
Columbia Theological Seminary,Religion2020,1990,5
Columbia University,Cooperation2021,1990,11
Columbia University,Religion2020,1990,29
Comenius Institute,Religion2020,1990,1
Concordia College,Religion2020,1990,4
Concordia University,Religion2020,1990,8
Connecticut College,Religion2020,1990,7
Copenhagen Business School,Cooperation2021,1990,1
Cornell University,Cooperation2021,1990,5
Cornell University,Religion2020,1990,10
Coventry University,Religion2020,1990,8
Crafton Hills College,Religion2020,1990,2
Creighton University,Religion2020,1990,2
Crozer Theological Seminary,Religion2020,1990,1
CUNY,Religion2020,1990,3
CUNY Graduate Center,Religion2020,1990,2
"Cuyamungue Institute, New Mexico",Religion2020,1990,3
Dalhousie University,Religion2020,1990,2
Dartmouth College,Religion2020,1990,13
Davidson College,Religion2020,1990,3
Deakin University,Religion2020,1990,2
Delft University of Technology,Cooperation2021,1990,2
Denison University,Religion2020,1990,1
Denver Seminary,Religion2020,1990,1
DePaul University,Religion2020,1990,2
DePauw University,Religion2020,1990,1
Divine Mercy University,Cooperation2021,1990,1
Divine Mercy University,Religion2020,1990,4
Dominican School of Philosophy and Theology,Religion2020,1990,1
Downing College,Religion2020,1990,1
Dr Williams's Library,Religion2020,1990,3
Drew University,Religion2020,1990,4
Dropsie College,Religion2020,1990,1
Duke Divinity School,Religion2020,1990,17
Duke University,Cooperation2021,1990,2
Duke University,Religion2020,1990,62
Duquesne University,Cooperation2021,1990,1
Duquesne University,Religion2020,1990,9
Durham University,Religion2020,1990,39
East Carolina University,Cooperation2021,1990,1
East Carolina University,Religion2020,1990,1
East Tennessee State University,Religion2020,1990,2
Eastern Baptist Theological Seminary,Religion2020,1990,1
Eastern Connecticut University,Religion2020,1990,1
Eastern Mennonite University,Cooperation2021,1990,1
Eastern Michigan University,Religion2020,1990,8
Ecole des Hautes Etudes,Religion2020,1990,1
Ecole des Hautes Etudes en Sciences Sociales,Cooperation2021,1990,2
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1990,10
Ecole Pratique des Hautes Etudes,Religion2020,1990,1
École Pratique des Hautes Etudes,Religion2020,1990,2
École pratique des hautes études,Religion2020,1990,2
Ecumenical Institute,Religion2020,1990,2
Edith Cowan University,Religion2020,1990,6
Emmanuel College,Religion2020,1990,4
Emory University,Cooperation2021,1990,7
Emory University,Religion2020,1990,34
Empire State College,Religion2020,1990,1
Eotvos Lorand University,Cooperation2021,1990,2
Episcopal Divinity School,Religion2020,1990,2
Erasmus Medical Center,Religion2020,1990,3
Erasmus University Rotterdam,Religion2020,1990,1
ETH Zurich,Cooperation2021,1990,2
Eubios Ethics Institute,Cooperation2021,1990,5
Eubios Ethics Institute,Religion2020,1990,3
European Business School,Religion2020,1990,3
European University Viadrina,Cooperation2021,1990,2
Evangelische Hochschule Tabor in Marburg,Religion2020,1990,2
Facebook,Religion2020,1990,1
faculte de pharmacie et de medecine de casablanca,Religion2020,1990,1
Fairfield University,Religion2020,1990,6
Federal University of Pernambuco,Religion2020,1990,2
Federal University of Rio de Janeiro,Religion2020,1990,1
Federal University of São Paulo (UNIFESP),Cooperation2021,1990,1
Federal University of São Paulo (UNIFESP),CooperationAndReligion,1990,1
Federal University of São Paulo (UNIFESP),Religion2020,1990,1
First Things,Religion2020,1990,1
Flinders University,Religion2020,1990,4
Florida International University,Cooperation2021,1990,1
Florida International University,Religion2020,1990,6
Florida State University,Cooperation2021,1990,3
Florida State University,Religion2020,1990,23
flourishing metrics,Religion2020,1990,1
Forbes Magazine,Religion2020,1990,9
Fordham University,Religion2020,1990,30
Forum on European Expansion and Global Interaction,Religion2020,1990,3
Franklin College,Religion2020,1990,2
Free University of Berlin,Religion2020,1990,1
French National Center for Scientific Research,Religion2020,1990,1
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,1990,1
Fuller Theological Seminary,Religion2020,1990,38
Furman University,Religion2020,1990,14
Garrett-Evangelical Theological Seminary,Religion2020,1990,13
George Fox University,Religion2020,1990,1
George Mason University,Cooperation2021,1990,2
George Mason University,Religion2020,1990,2
George Washington University,Cooperation2021,1990,1
George Washington University,Religion2020,1990,18
Georgetown University,Cooperation2021,1990,6
Georgetown University,Religion2020,1990,72
Georgetown University School of Medicine,Religion2020,1990,1
Georgia State University,Religion2020,1990,10
German Institute for Economic Research (DIW Berlin),Religion2020,1990,1
GKT School of Medical Education,Religion2020,1990,1
Goa University,Religion2020,1990,2
Goethe University Frankfurt,Religion2020,1990,3
Golden Gate Baptist Theological Seminary,Cooperation2021,1990,6
"Goldsmiths, University of London",Religion2020,1990,8
Gordon College,Religion2020,1990,3
Grace,Religion2020,1990,4
Graduate Theological Union,Religion2020,1990,16
Grand View University,Religion2020,1990,1
Gresham College,Religion2020,1990,1
Griffith University,Religion2020,1990,1
Groningen University,Religion2020,1990,1
Grove City College,Religion2020,1990,2
Gustavus Adolphus College,Religion2020,1990,6
Hadassah University Medical Center,Cooperation2021,1990,1
Hadassah University Medical Center,CooperationAndReligion,1990,1
Hadassah University Medical Center,Religion2020,1990,7
Haight-Ashbury Free Clinic (HAFC),Cooperation2021,1990,1
Haight-Ashbury Free Clinic (HAFC),Religion2020,1990,1
Harris Manchester College,Religion2020,1990,5
Hartford Institute for Religion Research,Religion2020,1990,1
Hartford Seminary,Religion2020,1990,5
Harvard Business School,Religion2020,1990,4
Harvard Center for World Religions,Religion2020,1990,2
Harvard Divinity School,Cooperation2021,1990,1
Harvard Divinity School,Religion2020,1990,30
Harvard Medical School,Cooperation2021,1990,4
Harvard Medical School,CooperationAndReligion,1990,1
Harvard Medical School,Religion2020,1990,11
Harvard School of Public Health,Cooperation2021,1990,1
Harvard School of Public Health,Religion2020,1990,5
Harvard University,Cooperation2021,1990,17
Harvard University,Religion2020,1990,42
Haverford College,Religion2020,1990,4
Heal,Religion2020,1990,2
Healthcare Chaplaincy,Religion2020,1990,2
Hebrew University - Hadessah,Cooperation2021,1990,1
Hebrew University - Hadessah,Religion2020,1990,3
Hebrew University of Jerusalem,Cooperation2021,1990,6
Hebrew University of Jerusalem,Religion2020,1990,29
Heidelberg Center for American Studies,Cooperation2021,1990,1
Heinrich-Heine University,Religion2020,1990,3
Heriot-Watt University,Religion2020,1990,1
"Herzog Hospital, Jerusalem",Cooperation2021,1990,1
"Herzog Hospital, Jerusalem",Religion2020,1990,6
Hillcroft College,Religion2020,1990,1
Hillsdale College,Religion2020,1990,2
Hitotsubashi University,Cooperation2021,1990,5
Hobart and William Smith Colleges,Religion2020,1990,9
Hofstra University,Religion2020,1990,6
Hokkaido University,Cooperation2021,1990,2
Hokkaido University,CooperationAndReligion,1990,1
Hokkaido University,Religion2020,1990,4
Holistic Nursing Consultants,Cooperation2021,1990,1
Holistic Nursing Consultants,Religion2020,1990,1
Holy Cross Health Care System,Religion2020,1990,4
Holy Ghost College,Religion2020,1990,2
Hong Kong Baptist University,Religion2020,1990,1
Hope College,Religion2020,1990,5
Hopewell Presbyterian Church,Religion2020,1990,9
Hospice Rieti,Religion2020,1990,5
House of Lords,Religion2020,1990,3
Houston Baptist University,Religion2020,1990,1
Howard University,Religion2020,1990,3
Humboldt University of Berlin,Cooperation2021,1990,1
Humboldt University of Berlin,Religion2020,1990,2
Huntington University,Religion2020,1990,3
Ibadan University,Religion2020,1990,1
Iliff School of Theology,Religion2020,1990,8
Illif School of Theology,Religion2020,1990,4
Impact Publishers,Religion2020,1990,3
Imperial College London,Cooperation2021,1990,3
Independent Consulting,Religion2020,1990,5
Independent Researcher,Cooperation2021,1990,2
Indian Institute of Science,Cooperation2021,1990,4
Indiana State University,Religion2020,1990,4
Indiana University,Cooperation2021,1990,7
Indiana University,Religion2020,1990,24
Indiana University at Bloomington,Religion2020,1990,6
Indiana University of Pennsylvania,Religion2020,1990,5
Indiana University South Bend,Religion2020,1990,4
Indiana University-Purdue University Fort Wayne,Cooperation2021,1990,1
Indiana University-Purdue University Fort Wayne,Religion2020,1990,10
Institute Ecumenical and Cultural Research,Religion2020,1990,1
Institute for American Constitutional Heritage,Religion2020,1990,1
Institute for Cognitive Sciences and Technologies,Cooperation2021,1990,1
Institute for Social Sciences at the TU Braunschweig,Religion2020,1990,2
Institute for the Psychological Sciences,Religion2020,1990,1
Institute for Transpersonal Psychology,Religion2020,1990,1
Institute of Archaeology,Religion2020,1990,4
Institute of Buddhist Studies,Religion2020,1990,1
Institute of Modern History,Religion2020,1990,1
Institute of Psychiatry,Cooperation2021,1990,1
Institute on Religion in an Age of Science,Religion2020,1990,1
Interdisciplinary Center Herzliya,Religion2020,1990,1
International Baptist Theological Seminary,Religion2020,1990,2
International Peace and Development Ethics Center,Cooperation2021,1990,5
International Peace and Development Ethics Center,Religion2020,1990,3
International Telematic University UNINETTUNO,Cooperation2021,1990,1
Iowa State University,Cooperation2021,1990,1
Iowa State University,Religion2020,1990,3
Italian Society of Sociology,Religion2020,1990,1
Jacobs University Bremen,Religion2020,1990,3
Jagiellonian University,Cooperation2021,1990,1
Jagiellonian University,Religion2020,1990,1
James Madison University,Religion2020,1990,5
Jamestown Community College,Religion2020,1990,8
Jawaharlal Nehru University,Religion2020,1990,4
Jesuit School of Theology,Religion2020,1990,3
Jesus College,Religion2020,1990,3
Jewish Theological Seminary,Religion2020,1990,4
Jindal Global University,Religion2020,1990,1
John Carroll University,Cooperation2021,1990,1
John Carroll University,Religion2020,1990,1
John Hopkins School of Medicine,Religion2020,1990,1
John Hopkins University,Religion2020,1990,1
John Jay College,Religion2020,1990,1
Johns Hopkins School of Medicine,Religion2020,1990,1
Johns Hopkins University,Religion2020,1990,17
journal of theological studies,Religion2020,1990,1
Justus Liebig University Giessen,Religion2020,1990,1
Kansas State University,Religion2020,1990,3
Karlstad University,Religion2020,1990,1
Keele University,Religion2020,1990,5
Keenesaw State University,Cooperation2021,1990,1
Keenesaw State University,Religion2020,1990,1
Kellogg School of Management,Religion2020,1990,2
Kent State University,Religion2020,1990,6
Keston Institute,Religion2020,1990,1
King’s College,Cooperation2021,1990,1
King’s College London,Cooperation2021,1990,1
King’s College London,Religion2020,1990,1
Kings College,Religion2020,1990,1
King's College,Religion2020,1990,11
King's College London,Religion2020,1990,23
Kings College London University,Cooperation2021,1990,2
Kings College London University,Religion2020,1990,6
Kipalapala Senior Seminary,Religion2020,1990,2
Kokugakuin University,Religion2020,1990,1
KU Leuven,Religion2020,1990,11
Kwansei Gakuin University,Cooperation2021,1990,1
Kyoto University,Cooperation2021,1990,2
Kyoto University,Religion2020,1990,2
Kyushu University,Cooperation2021,1990,1
Kyushu University,Religion2020,1990,1
La Salle University,Religion2020,1990,1
La Trobe University,Religion2020,1990,8
Lafayette College,Religion2020,1990,6
Lancaster Theological Seminary,Religion2020,1990,3
Lancaster University,Cooperation2021,1990,1
Lancaster University,Religion2020,1990,8
Laurentian University,Religion2020,1990,8
Laval University,Religion2020,1990,13
Lee University,Religion2020,1990,3
Leeds Beckett University,Cooperation2021,1990,1
Leeds Metropolitan University,Religion2020,1990,1
Lehigh University,Cooperation2021,1990,1
Lehigh University,Religion2020,1990,4
Leibniz University of Hanover,Religion2020,1990,2
Leicester Cathedral,Religion2020,1990,3
Leiden University,Religion2020,1990,8
Leo Baeck College,Religion2020,1990,2
Lesley University,Religion2020,1990,2
Lewis & Clark College,Religion2020,1990,1
Lewis Clark State College,Religion2020,1990,1
Lewis University,Religion2020,1990,1
Lingnan University,Cooperation2021,1990,3
Link Care Center,Religion2020,1990,1
Liverpool Hope University,Religion2020,1990,9
Loma Linda University,Cooperation2021,1990,2
Loma Linda University,Religion2020,1990,14
London Metropolitan University,Religion2020,1990,1
London School of Economics,Religion2020,1990,13
London School of Hygiene and Tropical Medicine,Religion2020,1990,1
London University,Religion2020,1990,6
Long Island University,Religion2020,1990,1
Loughborough University,Cooperation2021,1990,5
Louisiana State University,Cooperation2021,1990,1
Louisiana State University,Religion2020,1990,3
Louisiana Tech University,Religion2020,1990,2
Louisville Institute,Religion2020,1990,1
Louisville Presbyterian Theological Seminary,Religion2020,1990,6
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,1990,5
Loyola Marymount University,Religion2020,1990,3
Loyola University Chicago,Cooperation2021,1990,1
Loyola University Chicago,Religion2020,1990,37
Loyola University Maryland,Religion2020,1990,6
Loyola University New Orleans,Cooperation2021,1990,1
Loyola University New Orleans,Religion2020,1990,2
Ludwig Maximilian University of Munich,Religion2020,1990,1
Ludwig-Maximilians-University of Munich,Religion2020,1990,1
Luiss University Rome,Cooperation2021,1990,4
Lund University,Religion2020,1990,1
Luther Seminary,Religion2020,1990,2
Lutheran School of theology at Chicago,Cooperation2021,1990,1
Lutheran School of theology at Chicago,CooperationAndReligion,1990,1
Lutheran School of theology at Chicago,Religion2020,1990,12
Lutheran Theological Seminary at Philadelphia,Religion2020,1990,5
Lutheran Theological Southern Seminary,Religion2020,1990,1
Macalaster College,Religion2020,1990,3
Macalester College,Religion2020,1990,2
Macquarie University,Cooperation2021,1990,1
Madras Medical College,Religion2020,1990,1
Magdalene College,Religion2020,1990,5
Maharishi International University,Religion2020,1990,3
Maharishi University of Management,Religion2020,1990,8
Mahidol University,Cooperation2021,1990,1
Mahidol University,Religion2020,1990,1
Manchester Cathedral,Religion2020,1990,3
Manchester Metropolitan University,Cooperation2021,1990,1
Manchester Metropolitan University,Religion2020,1990,1
Manhattan College,Cooperation2021,1990,1
Marquette University,Religion2020,1990,8
Massachusetts General Hospital,Religion2020,1990,2
Massachusetts Institute of Technology,Religion2020,1990,2
Massey University,Religion2020,1990,3
Masters and Johnson,Religion2020,1990,2
Mater Dei Institute of Education,Religion2020,1990,1
Max Planck Institute,Cooperation2021,1990,1
"Max Planck Institute for Evolutionary Anthropology, Leipzig University",Cooperation2021,1990,1
Max Planck Institute for Human Development,Religion2020,1990,1
Max Planck Institute for Tax Law and Public Finance,Cooperation2021,1990,1
Max Planck Institute for the Study of Religious and Ethnic Diversity,Cooperation2021,1990,1
Max Planck Institute for the Study of Religious and Ethnic Diversity,Religion2020,1990,4
Max Weber Center for Advanced Cultural and Social Scudies,Cooperation2021,1990,1
Maynooth University,Religion2020,1990,4
McDaniel College,Religion2020,1990,2
McGill University,Religion2020,1990,5
McLean Hospital,Cooperation2021,1990,2
McMaster University,Religion2020,1990,8
Medical College of Georgia,Cooperation2021,1990,1
Meiji University,Cooperation2021,1990,1
Memorial Sloan Kettering Cancer Center,Cooperation2021,1990,1
Memorial University of Newfoundland,Religion2020,1990,1
Memphis State University,Religion2020,1990,4
Mercer University,Religion2020,1990,1
Merrimack College,Religion2020,1990,1
Messiah College,Religion2020,1990,5
Methodist Theological School,Religion2020,1990,1
Mettaa Institute,Religion2020,1990,2
Miami University,Religion2020,1990,9
Michigan State University,Cooperation2021,1990,4
Michigan State University,Religion2020,1990,4
Michlalah Jersusalem Academic College,Religion2020,1990,6
Microsoft,Religion2020,1990,3
"Microsoft Research, Israel",Cooperation2021,1990,1
Middle Tennesse State University,Religion2020,1990,1
Middlebury College,Religion2020,1990,7
Millersville State College,Religion2020,1990,1
Milligan College - Emmanuel School of Religion,Religion2020,1990,1
Milwaukee County Mental Health Complex,Cooperation2021,1990,3
Milwaukee County Mental Health Complex,Religion2020,1990,2
Mindstrong,Cooperation2021,1990,1
"Minnesota State University, Mankato",Religion2020,1990,3
Mississippi State University,Religion2020,1990,3
Missouri Western State University,Religion2020,1990,2
Moffitt Cancer Center and Research Institute,Cooperation2021,1990,1
Moffitt Cancer Center and Research Institute,Religion2020,1990,1
Monash University,Cooperation2021,1990,3
Monash University,Religion2020,1990,9
Montclair State University,Cooperation2021,1990,1
Montclair State University,Religion2020,1990,2
Morris Institute for Human Values,Religion2020,1990,5
mount allison university,Religion2020,1990,1
Mount Saint Mary's University,Religion2020,1990,6
Mount Saint Vincent University,Religion2020,1990,1
Mt. Sinai School of Medicine,Religion2020,1990,4
Muhlenberg College,Religion2020,1990,1
Murdoch University,Religion2020,1990,1
Murray Edwards College,Religion2020,1990,1
Murray State,Cooperation2021,1990,1
Murray State,Religion2020,1990,1
Nara National Research Institute for Cultural Properties,Religion2020,1990,5
National Cancer Institute,Religion2020,1990,2
National Center for Nanoscience and Technology,Cooperation2021,1990,1
National Center for Science Education,Religion2020,1990,1
National Central University,Religion2020,1990,1
National Council of Churches,Religion2020,1990,3
National Institute for Healthcare Research,Religion2020,1990,2
National Institute of Child Health & Human Development,Cooperation2021,1990,1
National Institute of Mental Health,Cooperation2021,1990,1
National Institute of Mental Health and Neurosciences,Religion2020,1990,2
National Institute on Aging,Religion2020,1990,1
National Institutes of Health,Cooperation2021,1990,1
National Museum of Ethnology,Religion2020,1990,2
National Research Council,Religion2020,1990,1
National Science Foundation,Religion2020,1990,2
National University of Malaysia,Religion2020,1990,2
Netanya University,Cooperation2021,1990,1
Netanya University,Religion2020,1990,1
Neumann University,Religion2020,1990,2
New London Synagogue,Religion2020,1990,1
New School for Social Research,Religion2020,1990,5
New Scientist at Reed Business Information,Cooperation2021,1990,2
New York State Psychiatric Institute,Cooperation2021,1990,1
New York University,Cooperation2021,1990,7
New York University,Religion2020,1990,23
Newcastle University,Religion2020,1990,4
North Carolina Agricultural & Technical State University,Cooperation2021,1990,1
North Carolina Central University,Religion2020,1990,1
North Carolina State University,Religion2020,1990,7
North Carolina Wesleyan University,Religion2020,1990,4
North of England Institute for Christian Education,Religion2020,1990,1
North West University,Religion2020,1990,1
Northeastern University,Religion2020,1990,1
Northern Arizona University,Religion2020,1990,1
northern Baptist theological seminary,Religion2020,1990,1
Northern Illinois University,Religion2020,1990,2
Northern Kentucky University,Religion2020,1990,2
Northumbria University,Cooperation2021,1990,1
Northumbria University,Religion2020,1990,1
Northwestern College,Religion2020,1990,2
Northwestern University,Cooperation2021,1990,5
Northwestern University,Religion2020,1990,16
Norwegian School of Theology,Religion2020,1990,4
Norwegian University of Science and Technology,Religion2020,1990,1
Nova Southeastern University,Cooperation2021,1990,1
Nova Southeastern University,Religion2020,1990,1
Oakland University,Cooperation2021,1990,1
Oakland University,Religion2020,1990,5
Oberlin College,Religion2020,1990,4
Occidental College,Religion2020,1990,1
Ohio State University,Religion2020,1990,20
Oklahoma State University,Religion2020,1990,1
Old Dominion University,Religion2020,1990,3
Open University (The),Religion2020,1990,11
Oregon State University,Cooperation2021,1990,1
Oregon State University,CooperationAndReligion,1990,1
Oregon State University,Religion2020,1990,7
Østfold University College,Religion2020,1990,1
Otto-von-Guericke-Universität,Religion2020,1990,1
Oxford Brookes University,Cooperation2021,1990,1
Oxford University,Cooperation2021,1990,5
Oxford University,Religion2020,1990,27
Pacific Lutheran University,Cooperation2021,1990,1
Pacific Lutheran University,Religion2020,1990,2
Pacific School of Religion,Religion2020,1990,3
Pacific Union College,Religion2020,1990,1
Palmer Theological Seminary,Religion2020,1990,3
Paris West University Nanterre La Défense,Cooperation2021,1990,1
Paris-Sorbonne University,Religion2020,1990,1
Patanjali Yogpeeth,Religion2020,1990,2
Peace Research Institute Oslo PRIO,Religion2020,1990,1
PEAR Institute,Cooperation2021,1990,2
"Peking University, Beijing",Religion2020,1990,2
Penn State University,Religion2020,1990,2
Pennsylvania State University,Cooperation2021,1990,4
Pennsylvania State University,Religion2020,1990,33
Pepperdine University,Religion2020,1990,4
Péter Pázmány Catholic University,Religion2020,1990,2
Philipps University Marburg,Religion2020,1990,1
Phillips University,Religion2020,1990,3
Pittsburg State University,Religion2020,1990,3
Pittsburgh Theological Seminary,Religion2020,1990,1
Pomona College,Religion2020,1990,2
Pompeu Fabra University,Cooperation2021,1990,1
Pontifical Academy of Theology,Religion2020,1990,1
Pontifical Gregorian University,Religion2020,1990,2
Pontifical University at Maynooth,Religion2020,1990,2
Portland Commuity College,Religion2020,1990,2
Portland State University,Religion2020,1990,6
Postgraduate Institute of Medical Education & Research,Religion2020,1990,1
Presidential Counsel,Religion2020,1990,2
Princeton Theological Seminary,Religion2020,1990,28
Princeton University,Cooperation2021,1990,7
Princeton University,Religion2020,1990,16
Priory of St Michael the Archangel,Religion2020,1990,5
Private Practice,Religion2020,1990,5
Protestant Theological University,Religion2020,1990,1
pruvio,Religion2020,1990,1
Psychocultural Institute- Toronto,Religion2020,1990,1
Punjab Agricultural University,Religion2020,1990,2
Purdue University,Cooperation2021,1990,1
Purdue University,Religion2020,1990,12
QIMR Berhofer Medical Research Institute,Religion2020,1990,2
Queen Mary College,Religion2020,1990,3
Queen Mary University of London,Cooperation2021,1990,1
Queen Mary University of London,Religion2020,1990,4
Queen’s University Belfast,Religion2020,1990,7
Queens College (CUNY),Religion2020,1990,3
Queen's University,Cooperation2021,1990,1
Queen's University,Religion2020,1990,2
Queen's University Belfast,Religion2020,1990,8
Quincy College,Religion2020,1990,5
Radboud University,Religion2020,1990,9
Radboud University Nijmegen,Religion2020,1990,14
Radford University,Religion2020,1990,1
Reformed Theological Seminary,Cooperation2021,1990,1
Reformed Theological Seminary,Religion2020,1990,1
Refua Shlema Clinic,Religion2020,1990,1
Regent College,Religion2020,1990,5
Regent University,Religion2020,1990,2
Religion,Religion2020,1990,1
religion news service,Religion2020,1990,1
Religions of South Asia,Religion2020,1990,1
Renmin University of China,Religion2020,1990,1
Rensselaer Polytechnic Institute,Religion2020,1990,2
Research Institute for Theology and Religion,Religion2020,1990,1
Resources for the Study of Social Engagement,Religion2020,1990,2
Rhodes University,Religion2020,1990,2
Rice University,Cooperation2021,1990,9
Rice University,Religion2020,1990,13
Ripon College,Religion2020,1990,1
"Risk-Eraser, LLC",Cooperation2021,1990,2
Ritual Studied Internatinoal,Religion2020,1990,5
Roberts Wesleyan College,Religion2020,1990,3
Rockford University,Religion2020,1990,3
Roehampton University,Religion2020,1990,2
Rollins College,Religion2020,1990,3
Roma Tre University,Religion2020,1990,2
Romanian-American University,Religion2020,1990,1
Roosevelt University,Religion2020,1990,2
Royal College of Psychiatrists,Religion2020,1990,2
"Royal Holloway, University of London",Cooperation2021,1990,1
"Royal Holloway, University of London",Religion2020,1990,7
Royal Society,Cooperation2021,1990,2
Royal University of Phnom Phen,Religion2020,1990,2
RUDN University,Religion2020,1990,1
Rudolf Steiner University College,Religion2020,1990,1
Ruhr University Bochum,Cooperation2021,1990,2
Ruhr University Bochum,Religion2020,1990,5
Rush University,Religion2020,1990,6
Rutgers University,Cooperation2021,1990,3
Rutgers University,Religion2020,1990,19
Rutherford University,Religion2020,1990,5
RWTH Aachen University,Cooperation2021,1990,4
Saint Ignatius Church,Religion2020,1990,4
Saint Joseph's Theological Institute,Religion2020,1990,1
Saint Joseph's Universty,Religion2020,1990,1
Saint Louis University,Religion2020,1990,6
Saint Mary's College,Religion2020,1990,5
Saint Michael's College,Religion2020,1990,2
Saint Paul University,Religion2020,1990,4
Salem College,Religion2020,1990,4
Samford University,Religion2020,1990,5
Sampoerna Capital,Religion2020,1990,1
San Diego State University,Religion2020,1990,3
San Francisco State University,Cooperation2021,1990,1
San Jose State University,Religion2020,1990,3
Santa Clara University,Religion2020,1990,14
Santa Fe Institute,Cooperation2021,1990,2
Sapienza University of Rome,Religion2020,1990,1
Sarum College,Religion2020,1990,2
Saybrook Graduate School,Religion2020,1990,2
Saybrook University,Religion2020,1990,1
Scarritt College,Religion2020,1990,1
School for Advanced Studies in the Social Sciences,Religion2020,1990,2
SCIENCE magazine,Cooperation2021,1990,1
SCIENCE magazine,Religion2020,1990,1
Sciences Po,Religion2020,1990,1
Scottsdale Healthcare Research Institute,Religion2020,1990,2
Scripps College,Religion2020,1990,2
Self-employed,Religion2020,1990,1
Seton Hall University,Religion2020,1990,6
Shaanxi Normal University,Religion2020,1990,1
Sheffield Hallam University,Cooperation2021,1990,1
Shimer College,Religion2020,1990,4
Shukutoku University,Religion2020,1990,1
Simon Fraser University,Cooperation2021,1990,5
Simon Fraser University,Religion2020,1990,1
Singapore Management University,Cooperation2021,1990,1
Singapore Management University,Religion2020,1990,3
Skidmore College,Religion2020,1990,1
Smith College,Religion2020,1990,6
Smithsonian Tropical Research Institute,Cooperation2021,1990,3
SOAS University of London,Religion2020,1990,14
Sofia University,Religion2020,1990,2
Sophia University,Religion2020,1990,3
Southern Baptist Theological Seminary,Religion2020,1990,1
Southern Illinois University,Religion2020,1990,12
Southern Methodist University,Cooperation2021,1990,2
Southern Methodist University,Religion2020,1990,8
Southern Oregon University,Cooperation2021,1990,1
Southwest Missouri State University,Religion2020,1990,1
Spiritual Competency Academy,Religion2020,1990,1
Ss. Peter & Paul Parish,Religion2020,1990,1
SSM Health,Religion2020,1990,1
St Andrews University,Religion2020,1990,2
St George & Sutherland Clinical School,Religion2020,1990,1
St Mary's University,Religion2020,1990,10
St Mary's University Church,Religion2020,1990,1
St. Andrews University,Religion2020,1990,2
St. Bonaventure University,Religion2020,1990,4
"St. Edmund Hall, Oxford",Religion2020,1990,1
St. Edward's University,Religion2020,1990,4
St. Francis Hospice,Religion2020,1990,3
St. Francis Xavier University,Religion2020,1990,3
St. Hilda's College,Religion2020,1990,1
St. Jerome's University,Religion2020,1990,2
St. John's College,Religion2020,1990,2
"St. Johns College, Cambridge",Cooperation2021,1990,3
"St. Johns College, Cambridge",Religion2020,1990,1
St. John's Medical College,Religion2020,1990,1
St. John's Seminary,Religion2020,1990,2
St. John's University,Religion2020,1990,9
St. Joseph’s College of Maine,Religion2020,1990,1
St. Louis University,Religion2020,1990,2
St. Mary's Seminary and University,Religion2020,1990,1
"St. Mary's University, Twickenham",Religion2020,1990,1
St. Michael's College,Religion2020,1990,6
St. Olaf College,Religion2020,1990,10
St. Paul's institute of Philosophy,Religion2020,1990,1
St. Petersburg State University,Religion2020,1990,3
"St. Thomas Hospital, London",Religion2020,1990,3
St. Vincent College,Religion2020,1990,2
Staffordshire University,Religion2020,1990,6
Stanford University,Cooperation2021,1990,13
Stanford University,Religion2020,1990,13
Stanley Royd Hospital,Religion2020,1990,2
State University of Campinas,Religion2020,1990,2
State University of New York,Religion2020,1990,1
State University of New York - Nassau,Religion2020,1990,2
State University of New York at Albany,Cooperation2021,1990,1
State University of New York at Binghamton University,Cooperation2021,1990,1
State University of New York at Binghamton University,Religion2020,1990,1
State University of New York at New Paltz,Religion2020,1990,1
State University of New York at Oneonta,Religion2020,1990,2
State University of New York at Stony Brook,Religion2020,1990,7
Stellenbosch University,Cooperation2021,1990,2
Stellenbosch University,Religion2020,1990,1
Stetson University,Religion2020,1990,1
Stichting Gelderloos Foundation,Religion2020,1990,2
Stockholm University,Cooperation2021,1990,1
Stockholm University,Religion2020,1990,12
Stony Brook University,Cooperation2021,1990,1
Stony Brook University,Religion2020,1990,9
Strayer College,Religion2020,1990,5
Suffolk University,Religion2020,1990,7
SUNY Oswego,Religion2020,1990,2
SVYASA University,Cooperation2021,1990,1
SVYASA University,Religion2020,1990,1
S-VYASA University,Religion2020,1990,2
Swansea University,Religion2020,1990,5
Swarthmore College,Religion2020,1990,9
sycamore primary care group,Religion2020,1990,1
Sydney College of Divinity,Religion2020,1990,1
Syracuse University,Cooperation2021,1990,1
Syracuse University,Religion2020,1990,27
Taos Institute,Religion2020,1990,2
Tel Aviv University,Cooperation2021,1990,1
Tel Aviv University,Religion2020,1990,5
tel-hai college,Cooperation2021,1990,1
Temple Buell College,Religion2020,1990,1
Temple University,Religion2020,1990,11
Tennessee State University,Religion2020,1990,3
Texas A&M University,Cooperation2021,1990,1
Texas A&M University,Religion2020,1990,7
Texas Christian University,Religion2020,1990,3
Texas Tech University,Religion2020,1990,4
The Arctic University of Norway,Religion2020,1990,3
The British Library,Religion2020,1990,1
The Catholic University of America,Religion2020,1990,4
The Center for Mind Body Medicine,Religion2020,1990,1
The Chinese University of Hong Kong,Religion2020,1990,1
The Chopra Foundation,Religion2020,1990,1
The Coventry Association for Pastoral Psychology,Religion2020,1990,3
The Essential Medical Technology Foundation,Cooperation2021,1990,1
The Hastings Center,Cooperation2021,1990,1
The Hastings Center,CooperationAndReligion,1990,1
The Hastings Center,Religion2020,1990,5
The Hebrew University,Cooperation2021,1990,1
The Hebrew University,Religion2020,1990,1
The Israel Society for the Prevention of Alcoholism,Religion2020,1990,4
"The Kinneret Center on Peace, Securityand Society",Religion2020,1990,2
The Middle East Technical University,Cooperation2021,1990,2
The Open University,Religion2020,1990,1
The Queens Foundation,Religion2020,1990,2
The Science Council of Japan,Cooperation2021,1990,1
The University of New South Wales-The Kirby Institute,Cooperation2021,1990,2
The University of New South Wales-The Kirby Institute,Religion2020,1990,1
The Van Leer Jerusalem Institute,Religion2020,1990,1
Tilburg University,Cooperation2021,1990,3
Tilburg University,Religion2020,1990,3
Toronto School of Theology,Religion2020,1990,1
Trilateral Research Ltd,Religion2020,1990,1
Trinity College,Religion2020,1990,14
Trinity College Dublin,Religion2020,1990,6
Trinity Evangelical Divinity School,Religion2020,1990,2
Trinity International University,Religion2020,1990,6
Trinity University,Religion2020,1990,7
Trinity Western University,Religion2020,1990,2
Truman State University,Religion2020,1990,2
Tufts University,Religion2020,1990,6
Tulane University,Cooperation2021,1990,1
Tulane University,Religion2020,1990,3
UC San Diego,Religion2020,1990,1
UC Santa Cruz,Religion2020,1990,3
UK Parliament,Religion2020,1990,2
Ulster University,Cooperation2021,1990,1
Ulster University,Religion2020,1990,1
Umeå University,Cooperation2021,1990,1
Umeå University,Religion2020,1990,1
Uninettuno international telematic university,Cooperation2021,1990,1
Union Congregational United Church of Christ,Religion2020,1990,3
Union Presbyterian Seminary,Religion2020,1990,5
Union Theological College,Religion2020,1990,1
Union Theological Seminary,Religion2020,1990,18
United States Naval Academy,Religion2020,1990,2
United Theological College,Religion2020,1990,3
United Theological Seminary,Religion2020,1990,1
United Theological Seminary of the Twin Cities,Religion2020,1990,3
Uniting College,Religion2020,1990,1
Universidad Autonoma Metropolitana,Religion2020,1990,1
Universitas Gadjah Mada,Religion2020,1990,3
Universitat Wurzburg,Religion2020,1990,1
Université Catholique de Louvain,Religion2020,1990,2
Université de Paris,Religion2020,1990,1
Universite d'Orleans,Religion2020,1990,3
Universite Laval,Religion2020,1990,3
Universiti Putra Malaysia,Religion2020,1990,1
Universiti Sains Malaysia,Religion2020,1990,1
University College Chester,Religion2020,1990,2
University College Cork,Religion2020,1990,3
University College Dublin,Religion2020,1990,2
University College London,Cooperation2021,1990,4
University College London,Religion2020,1990,26
University College Utrecht,Cooperation2021,1990,1
University of Aarhus,Cooperation2021,1990,1
University of Aarhus,Religion2020,1990,3
University of Aberdeen,Cooperation2021,1990,4
University of Aberdeen,Religion2020,1990,21
University of Agder,Religion2020,1990,1
University of Akron,Religion2020,1990,12
University of Alabama at Birmingham,Cooperation2021,1990,2
University of Alabama at Birmingham,Religion2020,1990,4
University of Alabama in Huntsville,Religion2020,1990,1
University of Alberta,Cooperation2021,1990,1
University of Alberta,Religion2020,1990,27
University of Amsterdam,Cooperation2021,1990,1
University of Amsterdam,Religion2020,1990,7
University of Arizona,Cooperation2021,1990,7
University of Arizona,Religion2020,1990,21
University of Arkansas,Religion2020,1990,2
University of Arkansas at Little Rock,Cooperation2021,1990,1
University of Auckland,Cooperation2021,1990,1
University of Auckland,Religion2020,1990,7
University of Ballarat,Religion2020,1990,1
University of Basel,Cooperation2021,1990,2
University of Bath,Religion2020,1990,3
University of Bergen,Cooperation2021,1990,1
University of Bergen,Religion2020,1990,5
University of Bern,Cooperation2021,1990,1
University of Bern,Religion2020,1990,1
University of Bielefeld,Religion2020,1990,2
University of Birmingham,Cooperation2021,1990,1
University of Birmingham,Religion2020,1990,22
University of Bonn,Cooperation2021,1990,7
University of Bonn,Religion2020,1990,6
University of Botswana,Religion2020,1990,4
University of Bremen,Religion2020,1990,2
University of Brighton,Religion2020,1990,9
University of Bristol,Cooperation2021,1990,3
University of Bristol,Religion2020,1990,31
University of British Columbia,Cooperation2021,1990,3
University of British Columbia,Religion2020,1990,8
University of Brunei Darussalam,Religion2020,1990,1
University of Buffalo,Religion2020,1990,3
University of Burdwan,Religion2020,1990,4
University of Calcutta,Religion2020,1990,4
University of Calgary,Religion2020,1990,21
University of California,Cooperation2021,1990,2
University of California - Berkeley,Cooperation2021,1990,3
University of California - Berkeley,Religion2020,1990,9
University of California - Davis,Religion2020,1990,1
University of California - Irvine,Cooperation2021,1990,1
University of California - Irvine,Religion2020,1990,4
University of California - Los Angeles,Cooperation2021,1990,7
University of California - Los Angeles,Religion2020,1990,29
University of California - Riverside,Cooperation2021,1990,4
University of California - Riverside,Religion2020,1990,4
University of California - San Diego,Cooperation2021,1990,2
University of California - San Diego,Religion2020,1990,2
University of California - San Francisco,Cooperation2021,1990,3
University of California - San Francisco,Religion2020,1990,22
University of California - Santa Barbara,Cooperation2021,1990,4
University of California - Santa Barbara,Religion2020,1990,12
"University of California, Berkeley",Religion2020,1990,12
"University of California, Davis",Cooperation2021,1990,3
"university of california, davis",Religion2020,1990,6
"University of California, Irvine",Cooperation2021,1990,3
"University of California, Irvine",Religion2020,1990,9
"University of California, Los Angeles",Cooperation2021,1990,5
"University of California, Los Angeles",Religion2020,1990,11
"University of California, Riverside",Cooperation2021,1990,5
"University of California, Riverside",CooperationAndReligion,1990,1
"University of California, Riverside",Religion2020,1990,13
"University of California, San Diego",Cooperation2021,1990,1
"University of California, San Diego",Religion2020,1990,9
"University of California, San Francisco",Religion2020,1990,4
"University of California, Santa Barbara",Cooperation2021,1990,7
"University of California, Santa Barbara",CooperationAndReligion,1990,1
"University of California, Santa Barbara",Religion2020,1990,24
"University of California, Santa Cruz",Religion2020,1990,6
University of Cambridge,Cooperation2021,1990,3
University of Cambridge,Religion2020,1990,54
University of Canterbury,Religion2020,1990,1
University of Cape Town,Religion2020,1990,3
University of Central Arkansas,Religion2020,1990,1
University of Central Florida,Religion2020,1990,2
University of Chester,Religion2020,1990,8
University of Chicago,Cooperation2021,1990,2
University of Chicago,Religion2020,1990,85
University of Chicago Divinity School,Religion2020,1990,11
University of Chichester,Religion2020,1990,1
University of Colorado at Boulder,Cooperation2021,1990,1
University of Colorado at Boulder,Religion2020,1990,10
University of Colorado at Colorado Springs,Cooperation2021,1990,2
University of Colorado at Colorado Springs,Religion2020,1990,2
University of Colorado Boulder,Cooperation2021,1990,2
University of Colorado Denver,Religion2020,1990,5
"University of Colorado, Boulder",Cooperation2021,1990,1
"University of Colorado, Boulder",Religion2020,1990,2
University of Concordia,Cooperation2021,1990,1
University of Concordia,Religion2020,1990,2
University of Connecticut,Cooperation2021,1990,4
University of Connecticut,Religion2020,1990,17
University of Copenhagen,Cooperation2021,1990,2
University of Copenhagen,CooperationAndReligion,1990,1
University of Copenhagen,Religion2020,1990,8
University of Cumbria,Religion2020,1990,2
University of Dayton,Religion2020,1990,7
University of Delaware,Religion2020,1990,3
University of Delhi,Religion2020,1990,1
University of Denver,Religion2020,1990,9
University of Derby,Cooperation2021,1990,3
University of Derby,Religion2020,1990,8
University of Detroit Mercy,Religion2020,1990,2
University of Dubuque Theological Seminary,Religion2020,1990,1
University of Dundee,Cooperation2021,1990,1
University of Durham,Religion2020,1990,6
University of East Anglia,Cooperation2021,1990,2
University of East Anglia,Religion2020,1990,1
University of East London,Religion2020,1990,1
University of Edinburgh,Cooperation2021,1990,2
University of Edinburgh,Religion2020,1990,22
University of Erlangen,Religion2020,1990,2
University of Essex,Cooperation2021,1990,1
University of Essex,Religion2020,1990,2
University of Exeter,Cooperation2021,1990,3
University of Exeter,Religion2020,1990,23
University of Florida,Cooperation2021,1990,4
University of Florida,Religion2020,1990,29
University of Frankfurt,Religion2020,1990,1
University of Fribourg,Religion2020,1990,4
University of Fukui,Religion2020,1990,1
University of Geneva,Religion2020,1990,4
University of Georgia,Cooperation2021,1990,4
University of Georgia,Religion2020,1990,9
University of Ghana,Religion2020,1990,1
University of Glasgow,Religion2020,1990,31
University of Gloucestershire,Religion2020,1990,2
University of Glyndwr,Religion2020,1990,1
University of Gothenburg,Cooperation2021,1990,1
University of Göttingen,Cooperation2021,1990,6
University of Göttingen,Religion2020,1990,6
University of Graz,Religion2020,1990,2
University of Groningen,Cooperation2021,1990,5
University of Groningen,Religion2020,1990,6
University of Guelph,Religion2020,1990,2
University of Haifa,Cooperation2021,1990,3
University of Haifa,Religion2020,1990,11
University of Hamburg,Religion2020,1990,1
University of Hannover,Religion2020,1990,1
University of Hawai‘i,Religion2020,1990,4
University of Hawai‘i at Mānoa,Religion2020,1990,1
University of Hawai‘i–West O‘ahu,Cooperation2021,1990,1
University of Hawai‘i–West O‘ahu,Religion2020,1990,1
University of Heidelberg,Religion2020,1990,6
University of Helsinki,Cooperation2021,1990,2
University of Helsinki,Religion2020,1990,5
University of Hertfordshire,Cooperation2021,1990,2
University of Hong Kong,Religion2020,1990,7
University of Houston,Religion2020,1990,8
University of Huddersfield,Religion2020,1990,1
University of Hull,Religion2020,1990,5
University of Iceland,Religion2020,1990,1
University of Illinois,Cooperation2021,1990,2
University of Illinois,Religion2020,1990,6
University of Illinois at Chicago,Religion2020,1990,4
University of Illinois at Urbana-Champaign,Cooperation2021,1990,5
University of Illinois at Urbana-Champaign,Religion2020,1990,30
University of Iowa,Cooperation2021,1990,1
University of Iowa,Religion2020,1990,25
University of Johannesburg,Religion2020,1990,3
University of Jyväskylä,Cooperation2021,1990,2
University of Kansas,Cooperation2021,1990,3
University of Kansas,CooperationAndReligion,1990,1
University of Kansas,Religion2020,1990,21
University of Kent,Religion2020,1990,8
University of Kentucky,Cooperation2021,1990,2
University of Kentucky,CooperationAndReligion,1990,1
University of Kentucky,Religion2020,1990,9
University of Kiel,Religion2020,1990,1
University of Koln,Religion2020,1990,1
University of Konstanz,Religion2020,1990,5
University of KwaZulu-Natal,Cooperation2021,1990,1
University of KwaZulu-Natal,Religion2020,1990,1
University of Lancaster,Religion2020,1990,6
University of L'Aquila,Cooperation2021,1990,1
University of Lausanne,Cooperation2021,1990,1
University of Lausanne,Religion2020,1990,6
University of Leeds,Religion2020,1990,20
University of Leicester,Religion2020,1990,7
University of Leipzig,Religion2020,1990,1
University of Lethbridge,Religion2020,1990,2
University of Leuven,Religion2020,1990,1
University of Lincoln,Religion2020,1990,1
University of Liverpool,Religion2020,1990,10
University of London,Religion2020,1990,40
University of Louisville,Cooperation2021,1990,4
University of Louisville,Religion2020,1990,7
University of Maine,Religion2020,1990,7
University of Malawi,Religion2020,1990,4
University of Malaya,Religion2020,1990,3
University of Malta,Religion2020,1990,1
University of Manchester,Cooperation2021,1990,1
University of Manchester,Religion2020,1990,34
University of Manitoba,Religion2020,1990,7
University of Marburg,Religion2020,1990,8
University of Maribor,Religion2020,1990,1
University of Maryland,Cooperation2021,1990,1
University of Maryland,Religion2020,1990,10
"University of Maryland, Baltimore",Religion2020,1990,3
"University of Maryland, College Park",Cooperation2021,1990,1
"University of Maryland, College Park",Religion2020,1990,3
University of Massachusetts - Amherst,Religion2020,1990,7
University of Massachusetts Memorial Medical Center,Religion2020,1990,4
"University of Massachusetts, Worcester",Religion2020,1990,1
University of Melbourne,Cooperation2021,1990,4
University of Melbourne,Religion2020,1990,18
University of Memphis,Religion2020,1990,2
University of Miami,Cooperation2021,1990,1
University of Miami,Religion2020,1990,11
University of Michigan,Cooperation2021,1990,10
University of Michigan,Religion2020,1990,39
"University of Michigan, Ann Arbor",Religion2020,1990,2
University of Minnesota,Cooperation2021,1990,8
University of Minnesota,Religion2020,1990,13
University of Mississippi,Religion2020,1990,11
University of Missouri,Religion2020,1990,11
University of Missouri-Kansas City,Religion2020,1990,1
University of Missouri-St. Louis,Religion2020,1990,2
University of Montreal,Cooperation2021,1990,3
University of Montreal,Religion2020,1990,28
University of Muenster,Religion2020,1990,3
University of Munich,Cooperation2021,1990,1
University of Munich,Religion2020,1990,5
University of Münster,Religion2020,1990,3
University of Nanterre,Religion2020,1990,1
University of Nebraska,Religion2020,1990,4
"University of Nebraska, Lincoln",Religion2020,1990,7
"University of Nebraska, Omaha",Religion2020,1990,3
University of Nebraska-Lincoln,Cooperation2021,1990,1
University of Nebraska-Lincoln,Religion2020,1990,2
University of Nevada,Religion2020,1990,7
University of Nevada - Reno,Religion2020,1990,7
"University of Nevada, Las Vegas",Religion2020,1990,26
University of New Brunswick,Religion2020,1990,4
University of New Delhi,Religion2020,1990,2
University of New England,Religion2020,1990,3
University of New Mexico,Cooperation2021,1990,5
University of New Mexico,Religion2020,1990,3
University of New South Wales,Cooperation2021,1990,2
University of New South Wales,Religion2020,1990,7
University of Newcastle,Religion2020,1990,1
University of Nigeria,Religion2020,1990,2
University of Nijmegen,Religion2020,1990,1
University of North Carolina,Cooperation2021,1990,3
University of North Carolina,Religion2020,1990,4
University of North Carolina - Chapel Hill,Religion2020,1990,7
University of North Carolina at Chapel Hill,Cooperation2021,1990,7
University of North Carolina at Chapel Hill,Religion2020,1990,19
University of North Carolina at Charlotte,Religion2020,1990,1
University of North Carolina at Greensboro,Religion2020,1990,7
University of North Carolina-Chapel Hill,Cooperation2021,1990,5
University of North Florida,Religion2020,1990,2
University of North Texas,Religion2020,1990,5
University of Notre Dame,Religion2020,1990,83
University of Notre Dame Australia,Religion2020,1990,1
University of Nottingham,Cooperation2021,1990,2
University of Nottingham,Religion2020,1990,14
University of Oklahoma,Religion2020,1990,13
University of Oregon,Cooperation2021,1990,7
University of Oregon,CooperationAndReligion,1990,1
University of Oregon,Religion2020,1990,5
University of Oslo,Cooperation2021,1990,5
University of Oslo,Religion2020,1990,14
University of Otago,Religion2020,1990,4
University of Ottawa,Cooperation2021,1990,1
University of Ottawa,Religion2020,1990,17
University of Oxford,Cooperation2021,1990,2
University of Oxford,Religion2020,1990,93
University of Paderborn,Religion2020,1990,1
University of Padua,Religion2020,1990,1
University of Paris,Religion2020,1990,1
University of Paris VIII,Cooperation2021,1990,1
University of Paris VIII,Religion2020,1990,2
University of Pennsylvania,Cooperation2021,1990,10
University of Pennsylvania,Religion2020,1990,24
University of Pittsburgh,Cooperation2021,1990,3
University of Pittsburgh,Religion2020,1990,4
University of Plymouth,Cooperation2021,1990,2
University of Port Harcourt,Religion2020,1990,2
University of Portsmouth,Cooperation2021,1990,1
University of Portsmouth,Religion2020,1990,1
University of Pretoria,Cooperation2021,1990,1
University of Pretoria,Religion2020,1990,41
University of Prince Edward Island,Religion2020,1990,1
University of Québec at Montreal,Religion2020,1990,7
University of Quebec at Trois-Rivières,Religion2020,1990,1
University of Queensland,Religion2020,1990,10
University of Reading,Cooperation2021,1990,4
University of Reading,Religion2020,1990,5
University of Redlands,Religion2020,1990,7
University of Regensburg,Cooperation2021,1990,3
University of Regensburg,Religion2020,1990,1
University of Regina,Religion2020,1990,1
University of Rennes 2,Religion2020,1990,5
University of Rhode Island,Religion2020,1990,1
University of Richmond,Cooperation2021,1990,2
University of Richmond,Religion2020,1990,2
University of Rochester,Cooperation2021,1990,1
University of Rochester,Religion2020,1990,5
University of Roehampton,Religion2020,1990,9
University of Rome,Religion2020,1990,6
University of Saint Mary of the Lake,Religion2020,1990,1
University of San Diego,Religion2020,1990,5
University of San Francisco,Religion2020,1990,1
University of Santiago,Religion2020,1990,1
University of São Paulo,Religion2020,1990,1
University of Saskatchewan,Cooperation2021,1990,4
University of Saskatchewan,Religion2020,1990,5
University of Savoie,Cooperation2021,1990,1
"University of Science, Malaysia",Religion2020,1990,1
University of Sheffield,Cooperation2021,1990,2
University of Sheffield,Religion2020,1990,6
University of Sherbrooke,Religion2020,1990,2
University of Siena,Cooperation2021,1990,1
University of South Africa,Religion2020,1990,5
University of South Carolina,Religion2020,1990,9
University of South Florida - Saint Petersburg,Religion2020,1990,7
University of Southampton,Religion2020,1990,3
University of Southern California,Cooperation2021,1990,6
University of Southern California,Religion2020,1990,28
University of Southern Denmark,Religion2020,1990,5
University of Southern Maine,Religion2020,1990,1
University of Southern Mississippi,Religion2020,1990,1
University of Split,Religion2020,1990,3
University of St Andrews,Cooperation2021,1990,1
University of St Andrews,Religion2020,1990,18
University of St. Thomas,Religion2020,1990,1
University of Stellenbosch,Religion2020,1990,4
University of Stirling,Religion2020,1990,9
University of Strasbourg,Cooperation2021,1990,1
University of Strasbourg,Religion2020,1990,3
University of Strathclyde,Religion2020,1990,2
University of Sunderland,Religion2020,1990,3
University of Surrey,Religion2020,1990,3
University of Sussex,Cooperation2021,1990,3
University of Sussex,Religion2020,1990,2
University of Sydney,Cooperation2021,1990,3
University of Sydney,Religion2020,1990,13
University of Tartu,Religion2020,1990,1
University of Tasmania,Cooperation2021,1990,1
University of Tennessee,Cooperation2021,1990,1
University of Tennessee,CooperationAndReligion,1990,1
University of Tennessee,Religion2020,1990,35
University of Tennessee at Chattanooga,Religion2020,1990,10
University of Texas at Arlington,Religion2020,1990,1
University of Texas at Austin,Cooperation2021,1990,1
University of Texas at Austin,Religion2020,1990,20
University of Texas at Dallas,Cooperation2021,1990,3
University of Texas at Dallas,Religion2020,1990,4
University of Texas at San Antonio,Religion2020,1990,10
University of Texas MD Anderson Cancer Center,Religion2020,1990,2
University of Texas Medical School,Religion2020,1990,2
University of Texas Pan American,Cooperation2021,1990,1
University of Texas Rio Grande Valley,Religion2020,1990,1
University of Texas Southwestern Medical Center,Cooperation2021,1990,1
University of the Basque Country,Religion2020,1990,1
University of the Free State,Religion2020,1990,1
University of the Incarnate Word,Cooperation2021,1990,1
University of the South,Religion2020,1990,2
University of Tokyo,Cooperation2021,1990,1
University of Tokyo,Religion2020,1990,2
University of Toronto,Cooperation2021,1990,6
University of Toronto,CooperationAndReligion,1990,1
University of Toronto,Religion2020,1990,49
University of Trento,Religion2020,1990,3
University of Tromsø,Religion2020,1990,2
University of Tsukuba,Religion2020,1990,1
University of Tübingen,Religion2020,1990,11
University of Tulsa,Religion2020,1990,4
University of Turin,Religion2020,1990,2
University of Turku,Religion2020,1990,3
University of Udine,Religion2020,1990,1
University of Ulm,Religion2020,1990,1
University of Ulster,Religion2020,1990,5
University of Utah,Cooperation2021,1990,1
University of Utah,Religion2020,1990,28
University of Utrecht,Cooperation2021,1990,2
University of Utrecht,Religion2020,1990,4
University of Vermont,Religion2020,1990,10
University of Victoria,Religion2020,1990,4
University of Vienna,Cooperation2021,1990,7
University of Vienna,Religion2020,1990,7
University of Virginia,Cooperation2021,1990,5
University of Virginia,Religion2020,1990,55
University of Wales,Religion2020,1990,27
University of Wales at Lampeter,Religion2020,1990,4
University of Wales Trinity Saint David,Religion2020,1990,1
University of Warwick,Religion2020,1990,31
University of Washington,Cooperation2021,1990,5
University of Washington,Religion2020,1990,9
University of Washington School of Medicine,Cooperation2021,1990,1
University of Washington School of Medicine,Religion2020,1990,3
University of Waterloo,Cooperation2021,1990,2
University of Waterloo,Religion2020,1990,7
University of West England,Religion2020,1990,1
University of West Florida,Religion2020,1990,3
University of Western Cape,Religion2020,1990,9
University of Western Ontario,Cooperation2021,1990,4
University of Westminster,Religion2020,1990,2
University of Winchester,Religion2020,1990,2
University of Windsor,Religion2020,1990,2
University of Wisconsin,Religion2020,1990,5
University of Wisconsin - Eau Claire,Religion2020,1990,3
University of Wisconsin - Madison,Cooperation2021,1990,4
University of Wisconsin - Madison,Religion2020,1990,25
University of Wisconsin–Eau Claire,Religion2020,1990,1
University of Wisconsin-Madison,Cooperation2021,1990,5
University of Wisconsin-Milwaukee,Religion2020,1990,6
University of Wolverhampton,Religion2020,1990,2
University of Worcester,Religion2020,1990,2
University of Würzburg,Religion2020,1990,4
University of Wyoming,Cooperation2021,1990,1
University of Wyoming,Religion2020,1990,1
University of York,Religion2020,1990,8
University of Zimbabwe,Cooperation2021,1990,1
University of Zimbabwe,Religion2020,1990,1
University of Zurich,Cooperation2021,1990,4
University of Zurich,Religion2020,1990,2
University Psychiatric Center KU Leuven,Cooperation2021,1990,1
Uppsala University,Cooperation2021,1990,3
Uppsala University,Religion2020,1990,10
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Cooperation2021,1990,1
USF HEALTH THE UNIVERSITY OF SOUTH FLORIDA,Religion2020,1990,1
Utah State University,Cooperation2021,1990,1
Utah State University,Religion2020,1990,4
Utrecht University,Cooperation2021,1990,2
Utrecht University,Religion2020,1990,14
Valparaiso University,Cooperation2021,1990,1
Valparaiso University,Religion2020,1990,13
Vanderbilt Divinity School,Religion2020,1990,5
Vanderbilt University,Religion2020,1990,24
Vanguard University,Religion2020,1990,3
Vatican Bank,Religion2020,1990,1
Veterans Health Adminstration,Cooperation2021,1990,1
Viadrina European University,Cooperation2021,1990,2
Victoria University of Wellington,Religion2020,1990,2
Villanova University,Religion2020,1990,15
Vipassana Support International,Religion2020,1990,1
Virginia Commonwealth University,Religion2020,1990,9
Virginia Polytechnic Institute and State University,Cooperation2021,1990,1
Virginia Theological Seminary,Religion2020,1990,1
Vrije Universiteit Amsterdam,Cooperation2021,1990,3
Vrije Universiteit Amsterdam,Religion2020,1990,10
Vrije Universiteit Brussel,Religion2020,1990,1
Wabash College,Religion2020,1990,5
Wadham College,Religion2020,1990,2
Wake Forest Baptist Health,Religion2020,1990,3
Wake Forest University,Cooperation2021,1990,1
Wake Forest University,Religion2020,1990,5
Wakefield Diocese,Religion2020,1990,1
Walden University,Religion2020,1990,4
Waseda University,Religion2020,1990,2
Washington and Lee University,Religion2020,1990,7
Washington College,Religion2020,1990,1
Washington State University,Cooperation2021,1990,2
Washington State University,Religion2020,1990,4
Washington University in St Louis,Cooperation2021,1990,6
Washington University in St Louis,Religion2020,1990,34
Wasiwaska,Religion2020,1990,1
Waterloo Lutheran Seminary,Religion2020,1990,1
Wayne State University,Religion2020,1990,4
Webster University,Religion2020,1990,2
Weill Cornell Medical College,Religion2020,1990,2
Weill Cornell Medical College in Qatar,Religion2020,1990,1
Wellesley College,Religion2020,1990,3
Welsh National Centre for Religious Education,Religion2020,1990,1
Wesleyan University,Religion2020,1990,1
West Chester University of Pennsylvania,Religion2020,1990,4
West Suffolk Hospital,Religion2020,1990,3
West Virginia University,Cooperation2021,1990,2
West Virginia University,Religion2020,1990,2
Western Carolina University,Religion2020,1990,2
Western Kentucky University,Religion2020,1990,1
Western Michigan University,Religion2020,1990,6
Western University,Cooperation2021,1990,2
Western University,Religion2020,1990,5
Westfield College,Religion2020,1990,1
Westmont College,Religion2020,1990,4
Wheaton College,Religion2020,1990,12
Wichita State University,Religion2020,1990,1
Wilfrid Laurier University,Cooperation2021,1990,1
Wilfrid Laurier University,Religion2020,1990,10
Wilkes University,Religion2020,1990,1
Willamette University,Religion2020,1990,1
William & Mary,Religion2020,1990,8
William Jewell College,Religion2020,1990,3
William Lyon University,Religion2020,1990,4
William Paterson University,Religion2020,1990,4
Wilmette Institute,Religion2020,1990,1
Winthrop University,Religion2020,1990,1
Wistar Institute,Religion2020,1990,1
Witten/Herdecke University,Religion2020,1990,1
"Women's Alliance for Theology, Ethics, and Ritual",Religion2020,1990,1
Woodbrooke Quaker Study Centre,Religion2020,1990,1
Wright State University,Religion2020,1990,9
Wuhan University,Religion2020,1990,1
Xavier University,Religion2020,1990,3
Yale Divinity School,Religion2020,1990,9
Yale University,Cooperation2021,1990,11
Yale University,CooperationAndReligion,1990,1
Yale University,Religion2020,1990,62
Yale University Mind and Development Lab,Religion2020,1990,3
Yamaguchi Prefectural University,Religion2020,1990,1
Yeshiva University,Cooperation2021,1990,1
Yeshiva University,CooperationAndReligion,1990,1
Yeshiva University,Religion2020,1990,5
Yeshivah of Flatbush High School,Religion2020,1990,1
Yonsei University,Religion2020,1990,3
York University,Cooperation2021,1990,1
York University,Religion2020,1990,17
Zart,Religion2020,1990,3
 I. M. Sechenov First Moscow State Medical University.,Religion2020,1985,1
 Xi’an Jiaotong University,Cooperation2021,1985,4
Aahrus University,Religion2020,1985,1
Aarhus University,Religion2020,1985,11
Abilene Christian University,Religion2020,1985,3
abo akademi university,Religion2020,1985,3
Adelphi University,Religion2020,1985,3
Africa Mental Health Foundation (AMHF),Religion2020,1985,3
Aga Khan University,Religion2020,1985,1
AGB Search,Religion2020,1985,4
Aix-Marseille Université,Religion2020,1985,4
Akrofi-Christaller Institute for Theology,Religion2020,1985,1
Albert Einstein College of Medicine,Cooperation2021,1985,2
Albert Einstein College of Medicine,Religion2020,1985,3
Alfred University,Religion2020,1985,1
Allegheny College,Religion2020,1985,1
Alliant International University,Cooperation2021,1985,1
American Enterprise Institute,Religion2020,1985,3
American Geographical Society,Religion2020,1985,1
American Health Foundation,Cooperation2021,1985,2
American Health Foundation,Religion2020,1985,6
American Museum of Natural History,Religion2020,1985,7
American University,Religion2020,1985,13
American University of Kuwait,Religion2020,1985,1
Amherst College,Religion2020,1985,1
Andover Newton Theological School,Religion2020,1985,7
Andrews University Seventh-day Adventist Theological Seminary,Religion2020,1985,1
Arizona State University,Cooperation2021,1985,12
Arizona State University,Religion2020,1985,18
ARK,Religion2020,1985,1
ASM Affiliates Inc,Religion2020,1985,1
Association of Improvement for Mental Health Programmes,Religion2020,1985,1
Atlantic School of Theology,Religion2020,1985,2
Auburn University,Religion2020,1985,2
Augusta University,Cooperation2021,1985,1
Augusta University,Religion2020,1985,1
Augustana College,Cooperation2021,1985,1
Augustana College,Religion2020,1985,1
Augustana University,Religion2020,1985,7
Austin Presbyterian Theological Seminary,Cooperation2021,1985,6
Australian Catholic University,Cooperation2021,1985,1
Australian Catholic University,Religion2020,1985,9
Australian National University,Cooperation2021,1985,2
Australian National University,Religion2020,1985,15
Austrian Academy of Sciences,Cooperation2021,1985,1
Ave Maria University,Religion2020,1985,1
Ball State University,Religion2020,1985,8
Baptist Seminary of Kentucky,Religion2020,1985,3
Bard College,Religion2020,1985,5
Bar-Ilan University,Cooperation2021,1985,2
Bar-Ilan University,Religion2020,1985,21
Barrington College,Religion2020,1985,2
Bates College,Religion2020,1985,1
Bath Spa University,Religion2020,1985,1
Baycrest Geriatric Centre,Cooperation2021,1985,1
Baylor University,Cooperation2021,1985,1
Baylor University,Religion2020,1985,42
Belmont College,Religion2020,1985,2
Ben-Gurion University of the Negev,Religion2020,1985,3
Berkshire Publishing Group,Religion2020,1985,1
Berry College,Religion2020,1985,1
Bethel College,Cooperation2021,1985,1
Bethlem Royal Hospital,Religion2020,1985,1
Biola University,Religion2020,1985,4
Birbeck University of London,Religion2020,1985,2
Birmingham University,Religion2020,1985,4
Bishop of Guilford,Religion2020,1985,1
Boston College,Religion2020,1985,15
Boston University,Cooperation2021,1985,2
Boston University,Religion2020,1985,31
Boston University School of Medicine,Religion2020,1985,4
Bournemouth University,Religion2020,1985,1
Bowdoin College,Religion2020,1985,5
Bowling Green State University,Cooperation2021,1985,1
Bowling Green State University,Religion2020,1985,4
Bradley University,Religion2020,1985,5
Brandeis University,Cooperation2021,1985,1
Brandeis University,Religion2020,1985,7
Brigham & Women's Hospital,Religion2020,1985,1
Brigham Young University,Religion2020,1985,27
British Academy,Religion2020,1985,1
Brock University,Cooperation2021,1985,3
Brock University,Religion2020,1985,1
Brooklyn College,Religion2020,1985,1
Brown University,Cooperation2021,1985,4
Brown University,Religion2020,1985,19
Brunel University London,Religion2020,1985,2
Bryn Mawr College,Cooperation2021,1985,1
Bryn Mawr College,Religion2020,1985,2
Bucknell University,Religion2020,1985,2
C&V Senior Care Specialists,Religion2020,1985,3
California Institute of Integral Studies,Religion2020,1985,3
California Institute of Technology,Cooperation2021,1985,2
California Lutheran University,Religion2020,1985,1
California State University,Religion2020,1985,5
"California State University, Fullerton",Religion2020,1985,1
"California State University, Long Beach",Religion2020,1985,3
"California State University, Stanislaus",Religion2020,1985,1
Calvin College,Religion2020,1985,2
Calvin University,Religion2020,1985,5
Cambridge Health Alliance,Religion2020,1985,2
Cambridge University,Cooperation2021,1985,2
Cambridge University,CooperationAndReligion,1985,1
Cambridge University,Religion2020,1985,12
Canterbury Christchurch University,Religion2020,1985,1
Canterbury District Health Board,Cooperation2021,1985,1
Cape Breton University,Religion2020,1985,1
Cape College of Theology,Religion2020,1985,5
Cardiff University,Cooperation2021,1985,1
Cardiff University,Religion2020,1985,8
Cardinal Stritch University,Religion2020,1985,3
Cardozo School of Law,Religion2020,1985,1
Carleton College,Religion2020,1985,7
Carleton University,Cooperation2021,1985,1
Carleton University,Religion2020,1985,2
Carnegie Mellon University,Cooperation2021,1985,5
Case Western Reserve University,Cooperation2021,1985,3
Case Western Reserve University,Religion2020,1985,2
Catholic Ecumenical Institute,Religion2020,1985,1
Catholic Institute of Paris,Religion2020,1985,2
Catholic Research and Development Centre,Religion2020,1985,1
Catholic Theological Union,Religion2020,1985,1
Catholic University of America,Religion2020,1985,32
Catholic University of Brasília,Religion2020,1985,1
Catholic University of Lublin,Cooperation2021,1985,1
Catholic University of Lublin,Religion2020,1985,1
Center for Advanced Studies Oslo,Religion2020,1985,1
Center for Applied Research in the Apostolate,Religion2020,1985,1
Center for Change,Religion2020,1985,3
Center for Ethics and Toxics,Cooperation2021,1985,1
Center for Interfaith Reconciliation,Religion2020,1985,5
Center For Partnership Studies,Cooperation2021,1985,2
Center for Southeast Asia Studies,Religion2020,1985,3
Center for the Study on New Religions,Religion2020,1985,1
Center to Advance Palliative Care,Cooperation2021,1985,1
Central Michigan University,Cooperation2021,1985,2
Centre for Addiction and Mental Health,Cooperation2021,1985,2
Centre for Addiction and Mental Health,Religion2020,1985,4
Centre for Research and Evidence on Security Threats,Religion2020,1985,1
Centre for Sensory Studies,Religion2020,1985,1
Centre National de la Recherche Scientifique,Religion2020,1985,4
Centre Tricontinental (CETRI),Religion2020,1985,1
Chang Gung Memorial Hospital at Linkou,Cooperation2021,1985,1
Chapman University,Cooperation2021,1985,1
Chapman University,Religion2020,1985,4
Charles Sturt University,Religion2020,1985,1
Chicago Theological Seminary,Religion2020,1985,5
Chinese University of Hong Kong,Cooperation2021,1985,1
Chinese University of Hong Kong,Religion2020,1985,2
Christ Church College,Religion2020,1985,2
Christian Theological Seminary,Religion2020,1985,7
Church Divinity School of the Pacific,Religion2020,1985,1
Church of England,Religion2020,1985,3
City University of Hong Kong,Religion2020,1985,3
City University of New York,Religion2020,1985,9
Claremont Graduate University,Cooperation2021,1985,5
Claremont Graduate University,Religion2020,1985,33
Claremont McKenna College,Religion2020,1985,2
Claremont school of theology,Religion2020,1985,2
Clark University,Religion2020,1985,2
Clemson University,Religion2020,1985,3
Cleveland State University,Religion2020,1985,14
Clinical Psychology Doctoral Training Programme,Religion2020,1985,1
Club of Budapest,Cooperation2021,1985,2
CNRS Comité national de la recherche scientifique,Religion2020,1985,1
Colgate University,Religion2020,1985,5
Collège de France,Religion2020,1985,5
College of Dentistry,Religion2020,1985,1
College of Marin,Cooperation2021,1985,1
College of St. Rose,Religion2020,1985,2
College of the Holy Cross,Religion2020,1985,4
Colorado School of Mines,Religion2020,1985,5
Colorado State University,Religion2020,1985,7
Columbia Theological Seminary,Religion2020,1985,6
Columbia University,Cooperation2021,1985,9
Columbia University,Religion2020,1985,20
Complutense University of Madrid,Religion2020,1985,1
Concordia College,Cooperation2021,1985,1
Concordia College,Religion2020,1985,10
Concordia University,Religion2020,1985,9
Connecticut College,Religion2020,1985,1
Constantine the Philosopher University,Religion2020,1985,1
Cornell University,Cooperation2021,1985,3
Cornell University,Religion2020,1985,3
Corvinus University of Budapest,Cooperation2021,1985,1
Creighton University,Religion2020,1985,4
Crozer Theological Seminary,Religion2020,1985,2
CUNY,Religion2020,1985,6
"Cuyamungue Institute, New Mexico",Religion2020,1985,1
Dartmouth College,Religion2020,1985,15
Deakin University,Religion2020,1985,3
Denison University,Religion2020,1985,1
Denver Seminary,Religion2020,1985,1
DePaul University,Cooperation2021,1985,1
DePaul University,Religion2020,1985,2
DePauw University,Religion2020,1985,2
Dickinson College,Religion2020,1985,7
Divine Mercy University,Cooperation2021,1985,1
Divine Mercy University,CooperationAndReligion,1985,1
Divine Mercy University,Religion2020,1985,2
Dongbei University of Finance and Economics,Religion2020,1985,2
Drew University,Religion2020,1985,4
Dublin City University,Religion2020,1985,2
Duke Divinity School,Religion2020,1985,15
Duke University,Cooperation2021,1985,2
Duke University,Religion2020,1985,59
Duquesne University,Religion2020,1985,6
Durham University,Religion2020,1985,23
East Carolina University,Religion2020,1985,1
East China Normal University,Religion2020,1985,2
East Tennessee State University,Religion2020,1985,4
Eastern Mennonite University,Cooperation2021,1985,1
Eastern Mennonite University,Religion2020,1985,1
Eastern Michigan University,Religion2020,1985,1
Eastern University,Religion2020,1985,2
Eckerd College,Religion2020,1985,2
Ecole des Hautes Etudes,Religion2020,1985,1
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1985,5
Ecole Pratique des Hautes Etudes,Religion2020,1985,1
École Pratique des Hautes Etudes,Religion2020,1985,2
École pratique des hautes études,Cooperation2021,1985,2
École pratique des hautes études,Religion2020,1985,1
Edith Cowan University,Religion2020,1985,3
Emmanuel College,Religion2020,1985,4
Emory University,Cooperation2021,1985,3
Emory University,Religion2020,1985,24
Eotvos Lorand University,Cooperation2021,1985,1
Episcopal Divinity School,Religion2020,1985,1
Erasmus University Rotterdam,Religion2020,1985,3
Esalen Institute,Religion2020,1985,1
Eurasian National University,Religion2020,1985,1
Fairfield University,Religion2020,1985,4
Fairsource Botanicals,Religion2020,1985,2
Federal University of São Paulo (UNIFESP),Religion2020,1985,1
Fisk University,Religion2020,1985,2
Flinders University,Religion2020,1985,4
Florida International University,Religion2020,1985,3
Florida State University,Cooperation2021,1985,1
Florida State University,Religion2020,1985,19
Fordham University,Religion2020,1985,30
Fox Valley Memory Project,Religion2020,1985,1
Free University of Berlin,Cooperation2021,1985,1
Friedrich Alexander University of Erlangen-Nürnberg,Religion2020,1985,1
Fuller Theological Seminary,Religion2020,1985,36
Furman University,Religion2020,1985,2
Garrett-Evangelical Theological Seminary,Religion2020,1985,8
Geha Mental Health Center,Religion2020,1985,1
General Theological Seminary,Religion2020,1985,4
George Fox University,Religion2020,1985,2
George Mason University,Cooperation2021,1985,1
George Mason University,Religion2020,1985,5
George Washington University,Religion2020,1985,16
Georgetown College,Religion2020,1985,4
Georgetown Medical School,Religion2020,1985,1
Georgetown University,Cooperation2021,1985,8
Georgetown University,Religion2020,1985,62
Georgia Institute of Technology,Religion2020,1985,2
Georgia State University,Religion2020,1985,3
GKT School of Medical Education,Religion2020,1985,2
Global Academic Professionals,Cooperation2021,1985,1
Global Academic Professionals,Religion2020,1985,1
Glyndwr University,Religion2020,1985,3
Goa University,Religion2020,1985,1
Goethe University Frankfurt,Religion2020,1985,1
Golden Gate Baptist Theological Seminary,Religion2020,1985,1
"Goldsmiths, University of London",Religion2020,1985,4
Gordon College,Religion2020,1985,3
Goucher College,Religion2020,1985,1
Grace,Religion2020,1985,1
Graduate Theological Foundation,Religion2020,1985,1
Graduate Theological Union,Religion2020,1985,13
Griffith University,Religion2020,1985,3
Groningen University,Religion2020,1985,2
Guestbrook Project,Religion2020,1985,2
Gustavus Adolphus College,Religion2020,1985,4
Hadassah University Medical Center,Cooperation2021,1985,1
Hadassah University Medical Center,Religion2020,1985,2
Hardin-Simmons University,Religion2020,1985,2
Harris Manchester College,Religion2020,1985,2
Hartford Institute for Religion Research,Religion2020,1985,2
Hartford Seminary,Religion2020,1985,2
Harvard Business School,Cooperation2021,1985,1
Harvard Business School,Religion2020,1985,3
Harvard Center for World Religions,Religion2020,1985,2
Harvard Divinity School,Religion2020,1985,24
Harvard Medical School,Cooperation2021,1985,3
Harvard Medical School,Religion2020,1985,10
Harvard School of Public Health,Religion2020,1985,7
Harvard University,Cooperation2021,1985,8
Harvard University,Religion2020,1985,47
Haverford College,Religion2020,1985,1
Hebrew Union College,Religion2020,1985,2
Hebrew University - Hadessah,Religion2020,1985,1
Hebrew University of Jerusalem,Cooperation2021,1985,6
Hebrew University of Jerusalem,Religion2020,1985,18
Heidelberg Center for American Studies,Religion2020,1985,2
Heidelberg University,Religion2020,1985,3
Hendrix College,Religion2020,1985,2
"Herzog Hospital, Jerusalem",Religion2020,1985,1
Heythrop College,Religion2020,1985,1
Highland Theological College,Religion2020,1985,2
Hillsdale College,Religion2020,1985,2
Hindu University of America,Religion2020,1985,1
Hitotsubashi University,Cooperation2021,1985,4
Hobart and William Smith Colleges,Religion2020,1985,7
Hofstra University,Religion2020,1985,1
Hokkaido University,Religion2020,1985,1
Holy Cross Health Care System,Religion2020,1985,2
Hong Kong Baptist University,Religion2020,1985,1
Hong Kong Polytechnic University,Religion2020,1985,1
Hope College,Religion2020,1985,2
Hospice Rieti,Religion2020,1985,4
Houston Baptist University,Religion2020,1985,2
Humboldt University of Berlin,Religion2020,1985,3
Huntington University,Religion2020,1985,2
Ibadan University,Religion2020,1985,1
Icahn School of Medicine at Mount Sinai,Cooperation2021,1985,1
Iliff School of Theology,Religion2020,1985,16
Illinois Institute of Technology,Cooperation2021,1985,1
Impact Publishers,Religion2020,1985,1
Independent Consulting,Religion2020,1985,3
Independent Researcher,Religion2020,1985,2
Indian Council of Social Science Research,Religion2020,1985,2
Indian Institute of Science,Cooperation2021,1985,4
Indiana State University,Religion2020,1985,3
Indiana University,Cooperation2021,1985,8
Indiana University,Religion2020,1985,30
Indiana University at Bloomington,Religion2020,1985,8
Indiana University of Pennsylvania,Religion2020,1985,4
Indiana University Purdue University Indianapolis,Religion2020,1985,5
Indiana University South Bend,Religion2020,1985,4
Indiana University Southeast,Religion2020,1985,5
Indiana University-Purdue University Fort Wayne,Religion2020,1985,5
Institut national de la recherche scientifique,Cooperation2021,1985,2
Institute for Advanced Study,Religion2020,1985,1
Institute for American Constitutional Heritage,Religion2020,1985,4
Institute for Social Sciences at the TU Braunschweig,Religion2020,1985,3
Institute for the Psychological Sciences,Religion2020,1985,4
Institute for the Study of Complex Systems,Cooperation2021,1985,2
Institute for Transpersonal Psychology,Religion2020,1985,1
Institute of Race Relations,Religion2020,1985,1
Integral Institute,Religion2020,1985,4
"International Association for Human Values, United Nations",Religion2020,1985,1
International Baptist Theological Seminary,Religion2020,1985,2
ISAP- Zurich,Religion2020,1985,2
Jacobs University Bremen,Religion2020,1985,1
James Cook University,Cooperation2021,1985,1
James Madison University,Religion2020,1985,1
Janki Foundation for Spirituality in Healthcare,Religion2020,1985,1
Japan Society for Buddhist-Christian Studies,Religion2020,1985,2
Jawaharlal Nehru University,Cooperation2021,1985,1
Jawaharlal Nehru University,Religion2020,1985,1
Jesuit School of Theology,Religion2020,1985,1
Jesus College,Religion2020,1985,3
Jewish Theological Seminary,Religion2020,1985,2
John Carroll University,Religion2020,1985,1
John Hopkins School of Medicine,Religion2020,1985,1
John Hopkins University,Religion2020,1985,2
Johns Hopkins Bloomberg School of Public Health,Religion2020,1985,2
Johns Hopkins University,Religion2020,1985,11
Joint Centre of Excellence for Research on Immigration & Settlement,Religion2020,1985,2
Journal of Preventative Medicine,Cooperation2021,1985,1
Journal of Preventative Medicine,Religion2020,1985,3
journal of theological studies,Religion2020,1985,3
Justus Liebig University Giessen,Cooperation2021,1985,1
Justus Liebig University Giessen,Religion2020,1985,2
Kansai University,Cooperation2021,1985,2
Kansas State University,Religion2020,1985,3
Karlstad University,Religion2020,1985,1
Keele University,Religion2020,1985,3
Keston Institute,Religion2020,1985,1
King Abdulaziz University,Cooperation2021,1985,1
King Abdulaziz University,Religion2020,1985,1
Kings College,Religion2020,1985,3
King's College,Religion2020,1985,8
King's College London,Religion2020,1985,18
Kings College London University,Cooperation2021,1985,1
Kings College London University,Religion2020,1985,4
Kobe Gakuin University,Cooperation2021,1985,1
KU Leuven,Religion2020,1985,10
Kwansei Gakuin University,Cooperation2021,1985,2
Kyushu University,Cooperation2021,1985,1
La Salle University,Religion2020,1985,2
La Salpetriere Hospital,Religion2020,1985,1
La Sapienza University,Religion2020,1985,1
La Trobe University,Religion2020,1985,3
Lafayette College,Religion2020,1985,1
Lake Forest College,Cooperation2021,1985,1
Lakota Books Publishing Company,Religion2020,1985,3
Lamar University,Religion2020,1985,1
Lancaster Theological Seminary,Religion2020,1985,3
Lancaster University,Religion2020,1985,4
Laurentian University,Religion2020,1985,4
Laval University,Cooperation2021,1985,1
Laval University,Religion2020,1985,18
Lehigh University,Cooperation2021,1985,3
Lehigh University,CooperationAndReligion,1985,1
Lehigh University,Religion2020,1985,9
Leibniz University of Hanover,Religion2020,1985,2
Leicester Cathedral,Religion2020,1985,2
Leiden University,Religion2020,1985,1
Lewis & Clark College,Religion2020,1985,1
Lewis University,Religion2020,1985,1
Lingnan University,Cooperation2021,1985,5
Link Care Center,Religion2020,1985,1
Liverpool Hope University,Religion2020,1985,8
Loma Linda University,Religion2020,1985,11
London School of Economics,Cooperation2021,1985,1
London School of Economics,Religion2020,1985,5
London University,Religion2020,1985,8
Long Island University,Religion2020,1985,2
Louisiana State University,Religion2020,1985,4
Louisiana Tech University,Religion2020,1985,4
Louisville Institute,Religion2020,1985,1
Louisville Presbyterian Theological Seminary,Religion2020,1985,6
Lousiana State University,Religion2020,1985,1
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,1985,3
Loyola Marymount University,Religion2020,1985,8
Loyola University Chicago,Cooperation2021,1985,1
Loyola University Chicago,Religion2020,1985,9
Loyola University Maryland,Religion2020,1985,10
Loyola University New Orleans,Religion2020,1985,1
Ludwig Maximilian University of Munich,Religion2020,1985,3
Luiss University Rome,Cooperation2021,1985,1
Luther Theological Seminary,Religion2020,1985,1
Lutheran School of theology at Chicago,Religion2020,1985,3
Lutheran Theological Southern Seminary,Religion2020,1985,1
Macalaster College,Religion2020,1985,1
Macalester College,Religion2020,1985,2
Macquarie University,Religion2020,1985,1
Magdalene College,Religion2020,1985,2
Maharishi International University,Religion2020,1985,4
Maharishi University of Management,Cooperation2021,1985,1
Maharishi University of Management,Religion2020,1985,9
Mahidol University,Religion2020,1985,4
Marian University,Religion2020,1985,3
Marquette University,Cooperation2021,1985,1
Marquette University,Religion2020,1985,12
Martin Luther University of Halle-Wittenberg,Cooperation2021,1985,2
Massachusetts General Hospital,Religion2020,1985,4
Massachusetts Institute of Technology,Cooperation2021,1985,1
Massachusetts Institute of Technology,Religion2020,1985,4
Massey University,Religion2020,1985,1
Masters and Johnson,Religion2020,1985,1
Max Planck Institute,Cooperation2021,1985,3
Max Planck Institute for Ornithology,Religion2020,1985,1
Maynooth University,Religion2020,1985,1
Mayo Clinic - Rochester,Cooperation2021,1985,1
McDaniel College,Religion2020,1985,8
McGill University,Religion2020,1985,4
McLean Hospital,Cooperation2021,1985,1
McLean Hospital,Religion2020,1985,2
McMaster University,Cooperation2021,1985,1
McMaster University,Religion2020,1985,10
Meadville Lombard Theological School,Cooperation2021,1985,1
Meadville Lombard Theological School,CooperationAndReligion,1985,1
Meadville Lombard Theological School,Religion2020,1985,3
Medical College of Georgia,Religion2020,1985,2
Memorial Sloan Kettering Cancer Center,Cooperation2021,1985,1
Memphis State University,Religion2020,1985,2
Memphis Theological Seminary,Religion2020,1985,1
Menninger Clinic,Religion2020,1985,3
Mercer University,Cooperation2021,1985,1
Messiah College,Religion2020,1985,1
Methodist Theological School,Religion2020,1985,4
Mettaa Institute,Religion2020,1985,2
Miami University,Religion2020,1985,3
Michigan State University,Cooperation2021,1985,4
Michlalah Jersusalem Academic College,Religion2020,1985,3
Microsoft,Religion2020,1985,3
Middle Tennesse State University,Religion2020,1985,2
Middlebury College,Religion2020,1985,2
Millersville State College,Religion2020,1985,1
Milligan College - Emmanuel School of Religion,Religion2020,1985,2
"Milltown Institute, Dublin",Religion2020,1985,5
Milwaukee County Mental Health Complex,Religion2020,1985,1
"Minnesota State University, Mankato",Religion2020,1985,3
Mississippi College,Religion2020,1985,1
Mississippi State University,Religion2020,1985,4
Monash University,Religion2020,1985,4
Morris Institute for Human Values,Religion2020,1985,1
mount allison university,Religion2020,1985,4
Mount Carmel College of Nursing,Religion2020,1985,3
Mount Saint Mary's University,Religion2020,1985,5
Mount Sinai Hospital Toronto,Cooperation2021,1985,1
Mount Sinai School of Medicine,Cooperation2021,1985,1
Mt. Sinai School of Medicine,Religion2020,1985,1
Muhlenberg College,Religion2020,1985,2
Murdoch University,Religion2020,1985,1
Museum for Cultural History,Cooperation2021,1985,2
Nara National Research Institute for Cultural Properties,Religion2020,1985,2
National Center for Science Education,Religion2020,1985,1
National Council of Churches,Religion2020,1985,1
National Institute of Cancer Prevention and Research,Religion2020,1985,1
National Institute of Mental Health,Religion2020,1985,2
National Law School of India University,Religion2020,1985,1
National Research University Higher School of Economics,Religion2020,1985,1
National Science Foundation,Religion2020,1985,5
National University of Ireland,Cooperation2021,1985,1
National University of Ireland,CooperationAndReligion,1985,1
National University of Ireland,Religion2020,1985,1
National University of Singapore,Religion2020,1985,4
Naval Amphibious Base Coronado,Religion2020,1985,1
Neumann University,Religion2020,1985,5
New College of California,Cooperation2021,1985,1
New London Synagogue,Religion2020,1985,4
New School for Social Research,Religion2020,1985,2
New Scientist at Reed Business Information,Cooperation2021,1985,2
New York University,Cooperation2021,1985,11
New York University,Religion2020,1985,14
Newcastle University,Religion2020,1985,11
NHS Foundation,Religion2020,1985,1
Njala University,Religion2020,1985,1
North Carolina State University,Religion2020,1985,4
northern Baptist theological seminary,Religion2020,1985,1
Northern Kentucky University,Religion2020,1985,1
Northumbria University,Religion2020,1985,4
Northwestern University,Cooperation2021,1985,8
Northwestern University,Religion2020,1985,11
Norwegian Institute of International Affairs,Cooperation2021,1985,2
Norwegian University of Science and Technology,Cooperation2021,1985,1
Norweign School of Theology,Religion2020,1985,1
Nova Southeastern University,Religion2020,1985,1
Oakland University,Religion2020,1985,3
Obafemi Awolowo University,Religion2020,1985,1
Oberlin College,Cooperation2021,1985,1
Oberlin College,Religion2020,1985,6
Ohio State University,Religion2020,1985,5
Ohio University,Cooperation2021,1985,5
Oklahoma State University,Religion2020,1985,4
Old Dominion University,Religion2020,1985,4
Open University (The),Religion2020,1985,1
Oregon State University,Religion2020,1985,2
Otto-von-Guericke-Universität,Religion2020,1985,1
Oxford University,Cooperation2021,1985,1
Oxford University,Religion2020,1985,14
Pacific Lutheran University,Religion2020,1985,2
Pacific School of Religion,Religion2020,1985,2
Pacific Union College,Religion2020,1985,4
Palo Alto University,Religion2020,1985,1
Paris West University Nanterre La Défense,Religion2020,1985,1
Paris-Sorbonne University,Religion2020,1985,1
Peace Research Institute Oslo PRIO,Cooperation2021,1985,1
PEAR Institute,Cooperation2021,1985,1
PEAR Institute,Religion2020,1985,2
Penn State University,Religion2020,1985,1
Pennsylvania State University,Cooperation2021,1985,2
Pennsylvania State University,Religion2020,1985,19
Pepperdine University,Religion2020,1985,3
Péter Pázmány Catholic University,Religion2020,1985,1
Phillips University,Religion2020,1985,2
Pima Community College,Religion2020,1985,2
Pomona College,Religion2020,1985,1
Pontifical Gregorian University,Religion2020,1985,1
Pontifical University at Maynooth,Religion2020,1985,1
Postgraduate Institute of Medical Education & Research,Religion2020,1985,1
Presidential Counsel,Religion2020,1985,1
Princeton Theological Seminary,Religion2020,1985,32
Princeton University,Cooperation2021,1985,4
Princeton University,CooperationAndReligion,1985,1
Princeton University,Religion2020,1985,18
Priory of St Michael the Archangel,Religion2020,1985,6
Private Practice,Religion2020,1985,2
Providence College,Religion2020,1985,2
Psychocultural Institute- Toronto,Religion2020,1985,2
Punjab Agricultural University,Cooperation2021,1985,1
Purdue University,Religion2020,1985,2
Queen Mary College,Religion2020,1985,1
Queen's University,Religion2020,1985,3
Queens University Belfast,Cooperation2021,1985,1
Queen's University Belfast,Cooperation2021,1985,1
Queen's University Belfast,Religion2020,1985,22
Quincy College,Religion2020,1985,5
Radboud University,Religion2020,1985,2
Radboud University Nijmegen,Cooperation2021,1985,1
Radboud University Nijmegen,Religion2020,1985,5
Radford University,Religion2020,1985,3
RAND Corporation,Religion2020,1985,1
Reformed Theological Seminary,Religion2020,1985,1
Regent College,Cooperation2021,1985,1
Regent College,Religion2020,1985,2
Regent University,Religion2020,1985,1
Religion,Cooperation2021,1985,1
Religion,Religion2020,1985,3
religion news service,Religion2020,1985,1
Religions of South Asia,Religion2020,1985,1
Renmin University of China,Religion2020,1985,5
Rensselaer Polytechnic Institute,Religion2020,1985,1
Resources for the Study of Social Engagement,Religion2020,1985,4
Rhodes College,Religion2020,1985,2
Rhodes University,Religion2020,1985,1
Rice University,Cooperation2021,1985,5
Rice University,Religion2020,1985,16
Ripon College,Cooperation2021,1985,1
Ritual Studied Internatinoal,Religion2020,1985,6
RMIT University,Religion2020,1985,1
Roberts Wesleyan College,Religion2020,1985,1
Rochester Cathedral,Religion2020,1985,1
Rockefeller University,Cooperation2021,1985,1
Rockford University,Religion2020,1985,2
Rollins College,Religion2020,1985,4
Romanian-American University,Religion2020,1985,3
Roosevelt University,Religion2020,1985,1
Royal College of Psychiatrists,Religion2020,1985,1
Royal Society,Cooperation2021,1985,1
Rudolf Steiner University College,Religion2020,1985,1
Ruhr University Bochum,Cooperation2021,1985,2
Ruhr University Bochum,Religion2020,1985,2
Rush University,Cooperation2021,1985,2
Rush University,Religion2020,1985,2
Rutgers University,Cooperation2021,1985,2
Rutgers University,Religion2020,1985,11
RWTH Aachen University,Cooperation2021,1985,2
Saarland University,Cooperation2021,1985,1
Saint Ignatius Church,Religion2020,1985,2
Saint Louis University,Cooperation2021,1985,1
Saint Louis University,Religion2020,1985,12
Saint Mary's College,Religion2020,1985,6
Saint Mary's University of Minnesota,Cooperation2021,1985,7
Salem College,Religion2020,1985,2
Sam Houston State University,Religion2020,1985,8
San Francisco Theological Seminary,Religion2020,1985,3
San Jose State University,Religion2020,1985,5
Santa Clara University,Cooperation2021,1985,1
Santa Clara University,Religion2020,1985,18
Santa Fe Institute,Cooperation2021,1985,1
Saybrook Graduate School,Religion2020,1985,1
Scarritt College,Religion2020,1985,1
School for Advanced Studies in the Social Sciences,Religion2020,1985,2
School of Oriental and African Studies,Religion2020,1985,2
Sciences Po,Religion2020,1985,1
Scottsdale Healthcare Research Institute,Religion2020,1985,1
Scripps College,Religion2020,1985,2
Search Institute,Religion2020,1985,5
Seattle Pacific University,Religion2020,1985,2
Seton Hall University,Religion2020,1985,7
Shaanxi Normal University,Religion2020,1985,2
Shanghai Jiao Tong University,Cooperation2021,1985,1
Shimer College,Religion2020,1985,2
Simon Fraser University,Cooperation2021,1985,1
Simon Fraser University,Religion2020,1985,2
Smith College,Religion2020,1985,3
SOAS University of London,Religion2020,1985,16
Sofia University,Religion2020,1985,2
Sophia University,Religion2020,1985,2
Southampton University,Religion2020,1985,1
Southern Illinois University,Religion2020,1985,4
Southern Methodist University,Cooperation2021,1985,2
Southern Methodist University,Religion2020,1985,12
Southwest National Primate Center,Cooperation2021,1985,1
Spalding University,Religion2020,1985,3
Spelman College,Religion2020,1985,2
Spiritual Competency Academy,Religion2020,1985,1
St Andrews University,Religion2020,1985,1
St Botolph's Aldgate,Religion2020,1985,2
St. Bonaventure University,Religion2020,1985,3
St. Edward's University,Religion2020,1985,3
St. Francis Hospice,Religion2020,1985,1
St. Hilda's College,Religion2020,1985,3
St. John's College,Religion2020,1985,2
"St. Johns College, Cambridge",Cooperation2021,1985,3
St. John's Seminary,Religion2020,1985,8
St. John's University,Cooperation2021,1985,1
St. John's University,Religion2020,1985,8
St. Joseph’s College of Maine,Religion2020,1985,5
St. Joseph's University,Religion2020,1985,2
St. Louis University,Religion2020,1985,1
St. Mary's Seminary and University,Religion2020,1985,2
"St. Mary's University, Twickenham",Religion2020,1985,1
St. Michael's College,Religion2020,1985,3
St. Olaf College,Religion2020,1985,7
St. Patrick's Seminary,Religion2020,1985,2
St. Petersburg State University,Religion2020,1985,2
St. Philip's Centre,Religion2020,1985,4
St. Thomas University,Religion2020,1985,2
St. Vincent College,Religion2020,1985,1
St. Vladimir's Orthodox Theological Seminary,Religion2020,1985,1
Staffordshire University,Religion2020,1985,3
Stanford University,Cooperation2021,1985,11
Stanford University,Religion2020,1985,9
Stanley Royd Hospital,Religion2020,1985,1
State University of New York - Nassau,Religion2020,1985,6
State University of New York at Albany,Cooperation2021,1985,2
State University of New York at Albany,Religion2020,1985,3
State University of New York at Binghamton University,Cooperation2021,1985,3
State University of New York at Binghamton University,Religion2020,1985,1
State University of New York at New Paltz,Religion2020,1985,1
State University of New York at Oneonta,Religion2020,1985,3
State University of New York at Stony Brook,Religion2020,1985,3
Stellenbosch University,Cooperation2021,1985,1
Stetson University,Religion2020,1985,1
Stichting Gelderloos Foundation,Religion2020,1985,2
Stockholm University,Religion2020,1985,9
Stony Brook University,Religion2020,1985,4
Strayer College,Religion2020,1985,3
Suffolk University,Religion2020,1985,3
SUNY Oswego,Religion2020,1985,1
SUNY Upstate Medical University,Religion2020,1985,1
SVYASA University,Religion2020,1985,2
S-VYASA University,Religion2020,1985,2
Swansea University,Religion2020,1985,5
Swarthmore College,Religion2020,1985,5
Swiss Federal Institute of Technology Zurich,Religion2020,1985,2
Syracuse University,Cooperation2021,1985,7
Syracuse University,Religion2020,1985,10
Tabor College,Religion2020,1985,2
Tamkang University,Religion2020,1985,1
Taos Institute,Religion2020,1985,1
Tehran Allameh Tabatabaee University,Cooperation2021,1985,1
Tehran Allameh Tabatabaee University,Religion2020,1985,3
Tel Aviv University,Cooperation2021,1985,8
Tel Aviv University,CooperationAndReligion,1985,1
Tel Aviv University,Religion2020,1985,6
Temple Buell College,Religion2020,1985,3
Temple University,Cooperation2021,1985,3
Temple University,Religion2020,1985,7
Tennessee State University,Religion2020,1985,10
Texas A&M University,Religion2020,1985,2
Texas Christian University,Religion2020,1985,1
Texas State University,Religion2020,1985,1
Texas Tech University,Religion2020,1985,8
The Arctic University of Norway,Religion2020,1985,2
The Art of Living Foundation,Religion2020,1985,1
The Catholic University of America,Religion2020,1985,7
The Hastings Center,Cooperation2021,1985,2
The Hastings Center,Religion2020,1985,1
The Hebrew University,Religion2020,1985,4
The Israel Society for the Prevention of Alcoholism,Religion2020,1985,2
The Jane Goodall Institute,Cooperation2021,1985,2
The New School,Religion2020,1985,8
The Open University,Religion2020,1985,1
The Queens Foundation,Religion2020,1985,9
The University of Rochester School of Medicine,Religion2020,1985,1
The Van Leer Jerusalem Institute,Religion2020,1985,1
Tilburg University,Cooperation2021,1985,2
Tilburg University,Religion2020,1985,2
Torrens University,Cooperation2021,1985,1
Trinity College,Religion2020,1985,3
Trinity College Dublin,Religion2020,1985,10
Trinity Evangelical Divinity School,Religion2020,1985,1
Trinity University,Religion2020,1985,4
Tufts University,Religion2020,1985,2
Tulane University,Religion2020,1985,5
Tyndale University,Religion2020,1985,1
UC Santa Cruz,Religion2020,1985,2
Ulster University,Religion2020,1985,1
Union College,Religion2020,1985,1
Union Presbyterian Seminary,Religion2020,1985,6
Union Theological Seminary,Religion2020,1985,21
United Health Care Inc.,Cooperation2021,1985,1
United Theological College,Religion2020,1985,1
United Theological Seminary,Religion2020,1985,1
Universite d'Orleans,Religion2020,1985,3
Universite Laval,Religion2020,1985,6
University College Cork,Religion2020,1985,1
University College Dublin,Religion2020,1985,1
University College London,Cooperation2021,1985,5
University College London,Religion2020,1985,14
University of Aarhus,Religion2020,1985,1
University of Aberdeen,Cooperation2021,1985,3
University of Aberdeen,Religion2020,1985,24
University of Akron,Religion2020,1985,3
University of Alabama,Religion2020,1985,3
University of Alabama in Huntsville,Religion2020,1985,3
University of Alberta,Religion2020,1985,13
University of Amsterdam,Cooperation2021,1985,1
University of Arizona,Cooperation2021,1985,15
University of Arizona,Religion2020,1985,18
University of Arkansas,Cooperation2021,1985,1
University of Auckland,Cooperation2021,1985,1
University of Basel,Cooperation2021,1985,1
University of Basel,Religion2020,1985,5
University of Bergen,Religion2020,1985,3
University of Berlin,Religion2020,1985,3
University of Bern,Religion2020,1985,1
University of Bielefeld,Religion2020,1985,2
University of Birmingham,Religion2020,1985,4
University of Bonn,Cooperation2021,1985,7
University of Bonn,Religion2020,1985,5
University of Botswana,Religion2020,1985,1
University of Brighton,Religion2020,1985,3
University of Bristol,Cooperation2021,1985,1
University of Bristol,Religion2020,1985,16
University of British Columbia,Cooperation2021,1985,2
University of British Columbia,Religion2020,1985,13
University of Brunei Darussalam,Religion2020,1985,1
University of Buffalo,Religion2020,1985,3
University of Burdwan,Religion2020,1985,1
University of Calcutta,Religion2020,1985,1
University of Calgary,Religion2020,1985,23
University of California - Berkeley,Cooperation2021,1985,2
University of California - Berkeley,Religion2020,1985,6
University of California - Davis,Cooperation2021,1985,1
University of California - Davis,Religion2020,1985,1
University of California - Irvine,Cooperation2021,1985,1
University of California - Irvine,CooperationAndReligion,1985,1
University of California - Irvine,Religion2020,1985,9
University of California - Los Angeles,Cooperation2021,1985,7
University of California - Los Angeles,Religion2020,1985,24
University of California - Riverside,Cooperation2021,1985,9
University of California - Riverside,Religion2020,1985,12
University of California - San Diego,Religion2020,1985,1
University of California - San Francisco,Cooperation2021,1985,2
University of California - San Francisco,Religion2020,1985,18
University of California - Santa Barbara,Religion2020,1985,30
"University of California, Berkeley",Cooperation2021,1985,7
"University of California, Berkeley",Religion2020,1985,8
"University of California, Davis",Cooperation2021,1985,3
"university of california, davis",Religion2020,1985,4
"University of California, Irvine",Cooperation2021,1985,1
"University of California, Irvine",Religion2020,1985,7
"University of California, Los Angeles",Cooperation2021,1985,5
"University of California, Los Angeles",Religion2020,1985,5
"University of California, Riverside",Cooperation2021,1985,3
"University of California, Riverside",Religion2020,1985,14
"University of California, San Diego",Cooperation2021,1985,1
"University of California, San Diego",Religion2020,1985,7
"University of California, San Francisco",Religion2020,1985,7
"University of California, Santa Barbara",Cooperation2021,1985,2
"University of California, Santa Barbara",Religion2020,1985,23
"University of California, Santa Cruz",Religion2020,1985,3
University of Cambridge,Cooperation2021,1985,2
University of Cambridge,Religion2020,1985,55
University of Canterbury,Religion2020,1985,1
University of Cape Town,Religion2020,1985,4
University of Central Arkansas,Religion2020,1985,1
University of Central Florida,Religion2020,1985,1
University of Chester,Religion2020,1985,12
University of Chicago,Cooperation2021,1985,2
University of Chicago,Religion2020,1985,94
University of Chicago Divinity School,Religion2020,1985,5
University of Cincinnati,Religion2020,1985,1
University of Cologne,Cooperation2021,1985,1
University of Colorado at Boulder,Cooperation2021,1985,2
University of Colorado at Boulder,Religion2020,1985,4
University of Colorado Boulder,Cooperation2021,1985,1
University of Colorado Denver,Religion2020,1985,2
"University of Colorado, Boulder",Cooperation2021,1985,1
"University of Colorado, Boulder",Religion2020,1985,1
University of Concordia,Cooperation2021,1985,1
University of Concordia,Religion2020,1985,2
University of Connecticut,Religion2020,1985,7
University of Copenhagen,Cooperation2021,1985,2
University of Copenhagen,Religion2020,1985,1
University of Cumbria,Religion2020,1985,1
University of Dayton,Religion2020,1985,2
University of Delaware,Cooperation2021,1985,3
University of Delaware,Religion2020,1985,3
University of Denver,Religion2020,1985,7
University of Derby,Cooperation2021,1985,1
University of Detroit Mercy,Religion2020,1985,1
University of Duisburg-Essen,Cooperation2021,1985,1
University of Dundee,Cooperation2021,1985,1
University of Dundee,Religion2020,1985,3
University of Durham,Religion2020,1985,6
University of Düsseldorf,Religion2020,1985,3
University of East Anglia,Religion2020,1985,3
University of Eastern Piedmont,Cooperation2021,1985,1
University of Edinburgh,Cooperation2021,1985,1
University of Edinburgh,CooperationAndReligion,1985,1
University of Edinburgh,Religion2020,1985,23
University of Exeter,Cooperation2021,1985,2
University of Exeter,Religion2020,1985,16
University of Florida,Cooperation2021,1985,1
University of Florida,Religion2020,1985,18
University of Fribourg,Religion2020,1985,2
University of Geneva,Cooperation2021,1985,1
University of Geneva,Religion2020,1985,2
University of Georgia,Cooperation2021,1985,5
University of Georgia,Religion2020,1985,8
University of Ghana,Religion2020,1985,3
University of Glasgow,Cooperation2021,1985,2
University of Glasgow,Religion2020,1985,20
University of Glyndwr,Religion2020,1985,1
University of Groningen,Cooperation2021,1985,4
University of Groningen,Religion2020,1985,6
University of Guelph,Religion2020,1985,1
University of Haifa,Cooperation2021,1985,2
University of Haifa,Religion2020,1985,3
University of Hamburg,Cooperation2021,1985,2
University of Hawai‘i,Religion2020,1985,1
University of Hawai‘i at Mānoa,Religion2020,1985,8
University of Hawai'i,Cooperation2021,1985,1
University of Heidelberg,Religion2020,1985,2
University of Helsinki,Cooperation2021,1985,1
University of Helsinki,Religion2020,1985,2
University of Hertfordshire,Religion2020,1985,9
University of Hong Kong,Cooperation2021,1985,1
University of Hong Kong,Religion2020,1985,3
University of Houston,Religion2020,1985,3
University of Huddersfield,Cooperation2021,1985,1
University of Hull,Religion2020,1985,5
University of Iceland,Religion2020,1985,1
University of Illinois,Cooperation2021,1985,1
University of Illinois,Religion2020,1985,2
University of Illinois at Chicago,Cooperation2021,1985,1
University of Illinois at Chicago,Religion2020,1985,1
University of Illinois at Urbana-Champaign,Cooperation2021,1985,1
University of Illinois at Urbana-Champaign,Religion2020,1985,10
University of Illinois Urbana-Champaign,Cooperation2021,1985,2
University of Iowa,Cooperation2021,1985,1
University of Iowa,Religion2020,1985,12
University of Jyväskylä,Cooperation2021,1985,1
University of Kansas,Cooperation2021,1985,4
University of Kansas,Religion2020,1985,9
University of Kent,Cooperation2021,1985,1
University of Kent,Religion2020,1985,8
University of Kentucky,Cooperation2021,1985,1
University of Kentucky,Religion2020,1985,6
University of Koblenz-Landau,Religion2020,1985,2
University of KwaZulu-Natal,Religion2020,1985,1
University of Lancaster,Religion2020,1985,9
University of Lausanne,Religion2020,1985,4
University of Leeds,Religion2020,1985,4
University of Leicester,Cooperation2021,1985,1
University of Leicester,Religion2020,1985,9
University of Leiden,Religion2020,1985,2
University of Leipzig,Cooperation2021,1985,1
University of Leipzig,Religion2020,1985,2
University of Lethbridge,Religion2020,1985,3
University of Liverpool,Religion2020,1985,7
University of London,Cooperation2021,1985,1
University of London,Religion2020,1985,33
University of Louisville,Religion2020,1985,5
University of Madras,Religion2020,1985,1
University of Maine,Cooperation2021,1985,1
University of Maine,Religion2020,1985,2
University of Malawi,Religion2020,1985,1
University of Malaya,Cooperation2021,1985,2
University of Malaya,Religion2020,1985,7
University of Manchester,Religion2020,1985,22
University of Manitoba,Religion2020,1985,2
University of Marburg,Religion2020,1985,2
University of Maryland,Cooperation2021,1985,2
University of Maryland,Religion2020,1985,10
University of Maryland - College Park,Religion2020,1985,1
"University of Maryland, Baltimore",Religion2020,1985,1
"University of Maryland, College Park",Religion2020,1985,2
University of Massachusetts - Amherst,Religion2020,1985,1
University of Massachusetts - Boston,Religion2020,1985,1
University of Massachusetts Medical School,Cooperation2021,1985,2
University of Massachusetts Memorial Medical Center,Cooperation2021,1985,1
University of Massachusetts Memorial Medical Center,Religion2020,1985,3
University of Medicine and Dentistry of New Jersey,Cooperation2021,1985,2
University of Melbourne,Cooperation2021,1985,1
University of Melbourne,Religion2020,1985,9
University of Memphis,Cooperation2021,1985,3
University of Memphis,Religion2020,1985,5
University of Miami,Cooperation2021,1985,1
University of Miami,Religion2020,1985,8
University of Michigan,Cooperation2021,1985,3
University of Michigan,Religion2020,1985,28
University of Minnesota,Cooperation2021,1985,12
University of Minnesota,Religion2020,1985,6
University of Mississippi,Religion2020,1985,2
University of Missouri,Religion2020,1985,12
University of Montana,Religion2020,1985,1
University of Montreal,Religion2020,1985,22
University of Munich,Religion2020,1985,5
University of Nairobi,Religion2020,1985,3
University of Nanterre,Religion2020,1985,3
University of Nebraska,Cooperation2021,1985,1
University of Nebraska,Religion2020,1985,2
"University of Nebraska, Lincoln",Religion2020,1985,8
"University of Nebraska, Omaha",Cooperation2021,1985,1
"University of Nebraska, Omaha",Religion2020,1985,1
University of Nebraska-Lincoln,Cooperation2021,1985,3
University of Nebraska-Lincoln,Religion2020,1985,3
University of Nevada - Reno,Religion2020,1985,11
"University of Nevada, Las Vegas",Religion2020,1985,6
University of New Brunswick,Cooperation2021,1985,1
University of New Brunswick,Religion2020,1985,2
University of New Delhi,Religion2020,1985,3
University of New Mexico,Cooperation2021,1985,3
University of New South Wales,Cooperation2021,1985,2
University of New South Wales,Religion2020,1985,1
University of North Carolina,Cooperation2021,1985,2
University of North Carolina,Religion2020,1985,1
University of North Carolina - Chapel Hill,Religion2020,1985,6
University of North Carolina at Chapel Hill,Cooperation2021,1985,10
University of North Carolina at Chapel Hill,Religion2020,1985,18
University of North Carolina at Greensboro,Religion2020,1985,2
University of North Florida,Religion2020,1985,1
University of North Texas,Religion2020,1985,1
University of North Texas HSC,Religion2020,1985,1
University of Notre Dame,Religion2020,1985,62
University of Nottingham,Religion2020,1985,11
University of Oklahoma,Religion2020,1985,8
University of Oregon,Cooperation2021,1985,6
University of Oregon,Religion2020,1985,6
University of Oslo,Cooperation2021,1985,4
University of Otago,Cooperation2021,1985,2
University of Otago,Religion2020,1985,1
University of Ottawa,Religion2020,1985,6
University of Oxford,Cooperation2021,1985,2
University of Oxford,Religion2020,1985,82
University of Pennsylvania,Cooperation2021,1985,7
University of Pennsylvania,Religion2020,1985,26
University of Pittsburgh,Cooperation2021,1985,2
University of Pittsburgh,Religion2020,1985,7
University of Port Harcourt,Religion2020,1985,1
University of Pretoria,Religion2020,1985,21
University of Québec at Montreal,Religion2020,1985,3
University of Quebec in Chicoutimi,Cooperation2021,1985,3
University of Queensland,Religion2020,1985,8
University of Reading,Cooperation2021,1985,1
University of Reading,Religion2020,1985,5
University of Redlands,Religion2020,1985,3
University of Regensburg,Religion2020,1985,1
University of Richmond,Cooperation2021,1985,3
University of Richmond,Religion2020,1985,4
University of Rochester,Cooperation2021,1985,1
University of Rochester,Religion2020,1985,3
University of Roehampton,Religion2020,1985,8
University of Rome,Religion2020,1985,1
University of Saint Mary of the Lake,Religion2020,1985,1
University of San Diego,Religion2020,1985,6
University of San Francisco,Religion2020,1985,1
University of Santiago,Religion2020,1985,1
University of Saskatchewan,Religion2020,1985,4
University of Savoie,Religion2020,1985,1
University of Scranton,Religion2020,1985,3
University of Sheffield,Religion2020,1985,7
University of South Africa,Religion2020,1985,8
University of South Australia,Religion2020,1985,2
University of South Carolina,Religion2020,1985,1
University of South Florida - Saint Petersburg,Cooperation2021,1985,1
University of South Florida - Saint Petersburg,Religion2020,1985,2
University of Southampton,Cooperation2021,1985,1
University of Southern California,Cooperation2021,1985,4
University of Southern California,CooperationAndReligion,1985,1
University of Southern California,Religion2020,1985,19
University of Southern Maine,Religion2020,1985,3
University of St Andrews,Religion2020,1985,8
University of Stirling,Cooperation2021,1985,2
University of Stirling,Religion2020,1985,37
University of Strasbourg,Religion2020,1985,1
University of Stuttgart,Religion2020,1985,3
University of Sunderland,Religion2020,1985,1
University of Surrey,Religion2020,1985,1
University of Sussex,Cooperation2021,1985,1
University of Sydney,Religion2020,1985,10
University of Tartu,Religion2020,1985,3
University of Tennessee,Religion2020,1985,19
University of Tennessee at Chattanooga,Religion2020,1985,14
University of Texas at Arlington,Religion2020,1985,1
University of Texas at Austin,Cooperation2021,1985,1
University of Texas at Austin,Religion2020,1985,13
University of Texas at Dallas,Cooperation2021,1985,2
University of Texas at Houston,Religion2020,1985,1
University of Texas at San Antonio,Religion2020,1985,1
University of Texas Medical School,Religion2020,1985,1
University of Texas Pan American,Religion2020,1985,3
University of Texas Rio Grande Valley,Religion2020,1985,2
University of Texas School of Public Health,Cooperation2021,1985,1
University of Texas School of Public Health,Religion2020,1985,3
University of Texas Southwestern Medical Center,Religion2020,1985,2
University of the Free State,Religion2020,1985,1
University of the South,Religion2020,1985,6
University of the Sunshine Coast,Religion2020,1985,1
University of Tokyo,Religion2020,1985,2
University of Toronto,Cooperation2021,1985,12
University of Toronto,Religion2020,1985,33
University of Trento,Religion2020,1985,2
University of Tromsø,Religion2020,1985,2
University of Tubigen Germany,Cooperation2021,1985,3
University of Tubigen Germany,Religion2020,1985,1
University of Tübingen,Religion2020,1985,5
University of Tulsa,Religion2020,1985,7
University of Turin,Religion2020,1985,1
University of Turku,Religion2020,1985,4
University of Ulm,Religion2020,1985,1
University of Ulster,Religion2020,1985,3
University of Utah,Cooperation2021,1985,2
University of Utah,Religion2020,1985,29
University of Utrecht,Cooperation2021,1985,3
University of Utrecht,Religion2020,1985,2
University of Vermont,Cooperation2021,1985,1
University of Vermont,Religion2020,1985,3
University of Victoria,Cooperation2021,1985,1
University of Victoria,Religion2020,1985,3
University of Vienna,Cooperation2021,1985,7
University of Vienna,Religion2020,1985,7
University of Virginia,Cooperation2021,1985,4
University of Virginia,Religion2020,1985,38
University of Wales,Cooperation2021,1985,1
University of Wales,Religion2020,1985,13
University of Wales at Lampeter,Religion2020,1985,5
University of Warsaw,Cooperation2021,1985,3
University of Warwick,Religion2020,1985,16
University of Washington,Cooperation2021,1985,5
University of Washington,Religion2020,1985,13
University of Washington School of Medicine,Cooperation2021,1985,1
University of Washington School of Medicine,Religion2020,1985,1
University of Waterloo,Religion2020,1985,4
University of West Georgia,Religion2020,1985,5
University of Western Australia,Religion2020,1985,1
University of Western Cape,Religion2020,1985,3
University of Western Ontario,Cooperation2021,1985,1
University of Windsor,Religion2020,1985,3
University of Wisconsin,Religion2020,1985,9
University of Wisconsin - Madison,Cooperation2021,1985,2
University of Wisconsin - Madison,Religion2020,1985,19
University of Wisconsin-La Crosse,Religion2020,1985,1
University of Wisconsin-Madison,Cooperation2021,1985,2
University of Wisconsin-Madison,Religion2020,1985,4
University of Wisconsin-Milwaukee,Religion2020,1985,3
University of Wolverhampton,Cooperation2021,1985,1
University of Worcester,Religion2020,1985,1
University of Wyoming,Religion2020,1985,5
University of York,Religion2020,1985,7
University of Zambia,Religion2020,1985,1
University of Zimbabwe,Religion2020,1985,1
University of Zurich,Religion2020,1985,10
Uppsala University,Religion2020,1985,3
Utah State University,Cooperation2021,1985,1
Utah State University,Religion2020,1985,4
Utrecht University,Religion2020,1985,13
Valparaiso University,Religion2020,1985,8
Vanderbilt Divinity School,Religion2020,1985,1
Vanderbilt University,Religion2020,1985,26
Vatican Bank,Cooperation2021,1985,1
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Religion2020,1985,6
Victoria University of Wellington,Religion2020,1985,2
Villanova University,Religion2020,1985,11
Virginia Commonwealth University,Religion2020,1985,12
Virginia Polytechnic Institute and State University,Religion2020,1985,8
Vrije Universiteit Amsterdam,Cooperation2021,1985,1
Vrije Universiteit Amsterdam,Religion2020,1985,10
Vrije Universiteit Brussel,Religion2020,1985,1
Wabash College,Religion2020,1985,4
Wadham College,Cooperation2021,1985,1
Wadham College,CooperationAndReligion,1985,1
Wadham College,Religion2020,1985,3
Wake Forest Baptist Health,Religion2020,1985,3
Wake Forest University,Religion2020,1985,6
Walden University,Religion2020,1985,1
Wallace Community College,Religion2020,1985,2
Warburg Institute,Religion2020,1985,1
Washington and Lee University,Religion2020,1985,3
Washington College,Religion2020,1985,1
Washington State University,Religion2020,1985,3
Washington Theological Coalition,Religion2020,1985,3
Washington University in St Louis,Cooperation2021,1985,6
Washington University in St Louis,Religion2020,1985,9
Wayne State University,Cooperation2021,1985,1
Wayne State University,Religion2020,1985,5
Webster University,Religion2020,1985,1
Weill Cornell Medical College in Qatar,Religion2020,1985,1
Wellesley College,Religion2020,1985,1
Welsh National Centre for Religious Education,Religion2020,1985,1
Wesleyan University,Religion2020,1985,2
West Chester University of Pennsylvania,Religion2020,1985,2
West Suffolk Hospital,Religion2020,1985,7
West Virginia University,Cooperation2021,1985,1
West Virginia University,Religion2020,1985,2
Western Kentucky University,Religion2020,1985,1
Western Michigan University,Religion2020,1985,5
Western University,Cooperation2021,1985,5
Western University,Religion2020,1985,1
Westmont College,Religion2020,1985,5
Wheaton College,Cooperation2021,1985,2
Wheaton College,Religion2020,1985,4
Wichita State University,Religion2020,1985,7
Wilfrid Laurier University,Religion2020,1985,5
Wilkes University,Religion2020,1985,2
Willamette University,Religion2020,1985,3
William & Mary,Religion2020,1985,3
William Jewell College,Religion2020,1985,2
Williams College,Religion2020,1985,5
Winthrop University,Religion2020,1985,2
Wolfson College,Religion2020,1985,4
Wright State University,Religion2020,1985,10
Wuhan University,Religion2020,1985,2
Xavier University,Religion2020,1985,3
Yale Divinity School,Religion2020,1985,2
Yale University,Cooperation2021,1985,13
Yale University,CooperationAndReligion,1985,1
Yale University,Religion2020,1985,69
Yale University Mind and Development Lab,Religion2020,1985,4
Yale University School of Medicine,Religion2020,1985,3
Yeshiva University,Cooperation2021,1985,1
Yeshiva University,Religion2020,1985,6
Yonsei University,Religion2020,1985,1
York University,Cooperation2021,1985,1
York University,Religion2020,1985,10
Zart,Religion2020,1985,3
 Xi’an Jiaotong University,Cooperation2021,1980,3
Aahrus University,Religion2020,1980,3
Aarhus University,Religion2020,1980,5
Abilene Christian University,Religion2020,1980,2
ADRx,Religion2020,1980,1
Africa Mental Health Foundation (AMHF),Religion2020,1980,1
AGB Search,Religion2020,1980,3
Akrofi-Christaller Institute for Theology,Religion2020,1980,1
Albert Einstein College of Medicine,Cooperation2021,1980,3
Albert Einstein College of Medicine,Religion2020,1980,1
Alfred University,Cooperation2021,1980,1
Alfred University,Religion2020,1980,8
Allegheny College,Religion2020,1980,1
Alliant International University,Religion2020,1980,1
American Enterprise Institute,Religion2020,1980,4
American Health Foundation,Religion2020,1980,3
American Museum of Natural History,Religion2020,1980,2
American Society of Anesthesiologists,Religion2020,1980,1
American University,Religion2020,1980,5
Amgen Inc,Religion2020,1980,1
Amherst College,Religion2020,1980,9
Andover Newton Theological School,Religion2020,1980,7
Arizona State University,Cooperation2021,1980,7
Arizona State University,Religion2020,1980,4
ARK,Religion2020,1980,1
Ascension Health,Religion2020,1980,1
Auburn University,Cooperation2021,1980,2
Auburn University,Religion2020,1980,4
Augusta University,Religion2020,1980,3
Augustana University,Religion2020,1980,4
Austin Presbyterian Theological Seminary,Cooperation2021,1980,5
Australian Catholic University,Religion2020,1980,8
Australian National University,Cooperation2021,1980,1
Australian National University,Religion2020,1980,7
Ball State University,Religion2020,1980,8
Bangor University,Religion2020,1980,2
Baptist Seminary of Kentucky,Religion2020,1980,1
Bard College,Religion2020,1980,4
Bar-Ilan University,Cooperation2021,1980,4
Bar-Ilan University,Religion2020,1980,18
Barnard College,Cooperation2021,1980,2
Barnard College,Religion2020,1980,2
Barrington College,Religion2020,1980,4
Bates College,Religion2020,1980,4
Baycrest Geriatric Centre,Cooperation2021,1980,1
Baylor University,Cooperation2021,1980,4
Baylor University,Religion2020,1980,18
Ben Gurion University in the Negev,Religion2020,1980,1
Ben-Gurion University of the Negev,Religion2020,1980,1
Berry College,Religion2020,1980,1
Binghamton University,Religion2020,1980,1
Biola University,Cooperation2021,1980,2
Biola University,Religion2020,1980,2
Birmingham University,Religion2020,1980,1
Boston College,Religion2020,1980,14
Boston University,Cooperation2021,1980,4
Boston University,Religion2020,1980,23
Boston University School of Medicine,Religion2020,1980,5
Bowdoin College,Religion2020,1980,3
Bowling Green State University,Cooperation2021,1980,1
Bowling Green State University,Religion2020,1980,4
Bradley University,Religion2020,1980,4
Brandeis University,Religion2020,1980,4
Brigham & Women's Hospital,Cooperation2021,1980,1
Brigham & Women's Hospital,Religion2020,1980,1
Brigham Young University,Religion2020,1980,15
Brock University,Religion2020,1980,7
Brooklyn College,Religion2020,1980,2
Brown University,Cooperation2021,1980,1
Brown University,Religion2020,1980,11
Bryn Mawr College,Religion2020,1980,2
Bucknell University,Religion2020,1980,3
C&V Senior Care Specialists,Religion2020,1980,1
California Institute of Integral Studies,Religion2020,1980,3
California Institute of Technology,Cooperation2021,1980,2
California Lutheran University,Religion2020,1980,1
California State University,Religion2020,1980,3
"California State University, Fullerton",Religion2020,1980,3
"California State University, Long Beach",Religion2020,1980,3
"California State University, Stanislaus",Religion2020,1980,2
Calvin College,Religion2020,1980,1
Calvin University,Religion2020,1980,1
Cambridge Health Alliance,Religion2020,1980,4
Cambridge University,Religion2020,1980,8
Candler School of Theology,Religion2020,1980,2
Cardiff University,Religion2020,1980,16
Cardinal Stritch University,Religion2020,1980,2
Cardozo School of Law,Religion2020,1980,1
Carleton College,Religion2020,1980,4
Carleton University,Cooperation2021,1980,2
Carleton University,Religion2020,1980,7
Carnegie Mellon University,Cooperation2021,1980,1
Case Western Reserve University,Cooperation2021,1980,1
Catholic Ecumenical Institute,Religion2020,1980,3
Catholic Research and Development Centre,Religion2020,1980,2
Catholic Theological Union,Religion2020,1980,1
Catholic University of America,Religion2020,1980,43
Catholic University of Leuven,Religion2020,1980,2
Catholic University of Louvain,Religion2020,1980,1
Catholic University of Lublin,Religion2020,1980,4
Center for Advanced Studies Oslo,Religion2020,1980,2
Center for Ethics and Toxics,Cooperation2021,1980,1
Center for Ethics and Toxics,Religion2020,1980,1
Center for Interfaith Reconciliation,Religion2020,1980,2
Center For Partnership Studies,Cooperation2021,1980,1
Center for the Study on New Religions,Religion2020,1980,8
Center for Theological Inquiry,Religion2020,1980,1
Central Michigan University,Cooperation2021,1980,1
Central Michigan University,Religion2020,1980,1
Centre for Addiction and Mental Health,Cooperation2021,1980,2
Centre for Addiction and Mental Health,Religion2020,1980,1
Centre for Research and Evidence on Security Threats,Religion2020,1980,1
Centre National de la Recherche Scientifique,Religion2020,1980,5
Centre Tricontinental (CETRI),Religion2020,1980,3
Chapman University,Cooperation2021,1980,1
Chicago Medical School,Religion2020,1980,2
Chicago Theological Seminary,Religion2020,1980,7
Christ Church College,Religion2020,1980,1
Christian Service University College,Religion2020,1980,1
Christian Theological Seminary,Religion2020,1980,4
Christ's College,Religion2020,1980,1
Church of England,Religion2020,1980,1
City University of New York,Religion2020,1980,4
Claremont Graduate University,Cooperation2021,1980,1
Claremont Graduate University,Religion2020,1980,15
Claremont McKenna College,Religion2020,1980,1
Claremont school of theology,Religion2020,1980,4
Clark University,Cooperation2021,1980,1
Clemson University,Religion2020,1980,2
Clinical Psychology Doctoral Training Programme,Religion2020,1980,1
Club of Budapest,Cooperation2021,1980,1
Colgate University,Religion2020,1980,4
Collège de France,Religion2020,1980,2
College of Charlestown,Religion2020,1980,3
College of Marin,Cooperation2021,1980,1
College of Marin,Religion2020,1980,1
College of St. Rose,Religion2020,1980,4
College of the Holy Cross,Religion2020,1980,6
Colombiere Center,Religion2020,1980,1
Colorado School of Mines,Religion2020,1980,1
Colorado State University,Cooperation2021,1980,1
Colorado State University,Religion2020,1980,6
Columbia Theological Seminary,Religion2020,1980,3
Columbia University,Cooperation2021,1980,7
Columbia University,CooperationAndReligion,1980,1
Columbia University,Religion2020,1980,19
Concordia College,Religion2020,1980,8
Concordia University,Religion2020,1980,23
Connecticut College,Religion2020,1980,6
Constantine the Philosopher University,Religion2020,1980,1
Cornell University,Cooperation2021,1980,4
Cornell University,Religion2020,1980,5
Corvinus University of Budapest,Cooperation2021,1980,1
Council of European Churches,Religion2020,1980,1
Crafton Hills College,Religion2020,1980,3
Creighton University,Religion2020,1980,2
Crozer Theological Seminary,Religion2020,1980,1
CUNY,Religion2020,1980,9
CUNY Graduate Center,Religion2020,1980,5
Dartmouth College,Religion2020,1980,5
Davidson College,Religion2020,1980,2
Denver Seminary,Religion2020,1980,1
DePaul University,Religion2020,1980,1
DePauw University,Religion2020,1980,2
Divine Mercy University,Religion2020,1980,1
Division of Diabetes Translation,Cooperation2021,1980,1
Division of Diabetes Translation,CooperationAndReligion,1980,1
Division of Diabetes Translation,Religion2020,1980,2
Dongbei University of Finance and Economics,Religion2020,1980,4
Downside Abbey,Religion2020,1980,1
Drew University,Religion2020,1980,2
Drexel University,Religion2020,1980,5
Dropsie College,Religion2020,1980,1
Dublin City University,Religion2020,1980,1
Duke Divinity School,Religion2020,1980,8
Duke University,Cooperation2021,1980,5
Duke University,Religion2020,1980,23
Duquesne University,Religion2020,1980,4
Durham University,Religion2020,1980,13
East Carolina University,Religion2020,1980,3
East Tennessee State University,Religion2020,1980,1
Eastern Connecticut University,Religion2020,1980,1
Eastern Mennonite University,Religion2020,1980,3
Eastern Michigan University,Religion2020,1980,1
Eastern University,Religion2020,1980,4
Eckerd College,Religion2020,1980,4
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1980,6
École pratique des hautes études,Religion2020,1980,4
Edith Cowan University,Religion2020,1980,1
Emmanuel College,Religion2020,1980,1
Emory University,Cooperation2021,1980,1
Emory University,Religion2020,1980,23
Eurasian National University,Religion2020,1980,1
Fairfield University,Religion2020,1980,1
Fisk University,Religion2020,1980,4
Florida International University,Religion2020,1980,5
Florida State University,Cooperation2021,1980,2
Florida State University,Religion2020,1980,21
Fordham University,Religion2020,1980,24
Franklin College,Religion2020,1980,6
Free University of Berlin,Religion2020,1980,3
Fuller Theological Seminary,Religion2020,1980,27
Furman University,Religion2020,1980,1
Garrett Theological Seminary,Religion2020,1980,4
Garrett-Evangelical Theological Seminary,Religion2020,1980,10
General Theological Seminary,Religion2020,1980,5
Georg August University of Göttingen,Cooperation2021,1980,1
George Fox University,Religion2020,1980,2
George Mason University,Religion2020,1980,3
George Washington University,Religion2020,1980,5
Georgetown College,Religion2020,1980,1
Georgetown Medical School,Cooperation2021,1980,1
Georgetown Medical School,Religion2020,1980,1
Georgetown University,Cooperation2021,1980,2
Georgetown University,Religion2020,1980,23
Georgetown University School of Medicine,Religion2020,1980,1
Georgia Institute of Technology,Religion2020,1980,6
Georgia State University,Religion2020,1980,1
GKT School of Medical Education,Religion2020,1980,2
Glasgow Centre for Population Health,Religion2020,1980,1
Global Academic Professionals,Religion2020,1980,5
Glyndwr University,Religion2020,1980,11
Goethe University Frankfurt,Religion2020,1980,1
Golden Gate Baptist Theological Seminary,Cooperation2021,1980,2
"Goldsmiths, University of London",Religion2020,1980,2
Grace,Religion2020,1980,8
Graduate Theological Foundation,Religion2020,1980,3
Graduate Theological Union,Religion2020,1980,10
Green Templeton College,Religion2020,1980,1
Gregorian University of the Jesuit Order,Religion2020,1980,1
Gresham College,Religion2020,1980,2
Groningen University,Religion2020,1980,4
Gustavus Adolphus College,Religion2020,1980,2
Hadassah University Medical Center,Religion2020,1980,1
Hardin-Simmons University,Religion2020,1980,1
Hartford Institute for Religion Research,Religion2020,1980,1
Hartford Seminary,Religion2020,1980,2
Harvard Business School,Religion2020,1980,2
Harvard Center for World Religions,Religion2020,1980,1
Harvard Divinity School,Religion2020,1980,19
Harvard Medical School,Religion2020,1980,6
Harvard School of Public Health,Cooperation2021,1980,2
Harvard School of Public Health,Religion2020,1980,3
Harvard University,Cooperation2021,1980,11
Harvard University,Religion2020,1980,32
Haverford College,Religion2020,1980,3
Hebrew Union College,Religion2020,1980,2
Hebrew University - Hadessah,Religion2020,1980,1
Hebrew University of Jerusalem,Cooperation2021,1980,1
Hebrew University of Jerusalem,Religion2020,1980,11
Heidelberg University,Religion2020,1980,1
Hendrix College,Religion2020,1980,2
"Herzog Hospital, Jerusalem",Religion2020,1980,1
Heythrop College,Cooperation2021,1980,1
Heythrop College,Religion2020,1980,1
Highland Theological College,Religion2020,1980,5
Hitotsubashi University,Cooperation2021,1980,3
Hobart and William Smith Colleges,Religion2020,1980,4
Hokkaido University,Cooperation2021,1980,2
Holy Ghost College,Religion2020,1980,1
Hospice Rieti,Religion2020,1980,5
Howard University,Religion2020,1980,1
"Hunter College, CUNY",Religion2020,1980,7
"Huntington School, York",Religion2020,1980,2
ICOS Corp,Religion2020,1980,1
Iliff School of Theology,Religion2020,1980,16
Imperial College London,Cooperation2021,1980,2
Independent Consulting,Religion2020,1980,2
Independent Researcher,Religion2020,1980,1
Indian Cancer Society,Religion2020,1980,1
Indian Institute of Science,Cooperation2021,1980,1
Indian Statistical Institute,Cooperation2021,1980,1
Indiana State University,Religion2020,1980,1
Indiana University,Cooperation2021,1980,21
Indiana University,Religion2020,1980,17
Indiana University at Bloomington,Religion2020,1980,1
Indiana University Purdue University Indianapolis,Religion2020,1980,1
Indiana University South Bend,Religion2020,1980,1
Indiana University-Purdue University Fort Wayne,Religion2020,1980,1
Institute Ecumenical and Cultural Research,Religion2020,1980,1
Institute for Advanced Study,Religion2020,1980,1
Institute for the Study of Complex Systems,Cooperation2021,1980,1
Institute of Buddhist Studies,Religion2020,1980,1
Institute of Psychiatry,Cooperation2021,1980,1
ISAP- Zurich,Religion2020,1980,1
Italian Society of Sociology,Religion2020,1980,1
Jacobs University Bremen,Religion2020,1980,2
Jagiellonian University,Cooperation2021,1980,2
Jagiellonian University,Religion2020,1980,1
James Cook University,Cooperation2021,1980,1
Japan Society for Buddhist-Christian Studies,Religion2020,1980,1
Jesuit School of Theology,Religion2020,1980,3
Jesus College,Religion2020,1980,1
Jindal Global University,Religion2020,1980,1
John Carroll University,Religion2020,1980,1
John Hopkins School of Medicine,Religion2020,1980,1
John Hopkins University,Religion2020,1980,1
Johns Hopkins School of Medicine,Cooperation2021,1980,1
Johns Hopkins University,Cooperation2021,1980,5
Johns Hopkins University,Religion2020,1980,11
Journal of Preventative Medicine,Religion2020,1980,2
Kansas State University,Religion2020,1980,1
Keele University,Religion2020,1980,1
Kenrick Glennon Seminary,Religion2020,1980,2
Kent State University,Religion2020,1980,1
Keston College,Religion2020,1980,1
King Abdulaziz University,Cooperation2021,1980,1
King Abdulaziz University,Religion2020,1980,1
King Saud University,Cooperation2021,1980,7
Kings College,Religion2020,1980,1
King's College,Religion2020,1980,5
King's College London,Religion2020,1980,8
Kings College London University,Cooperation2021,1980,1
Kings College London University,Religion2020,1980,1
Kobe Gakuin University,Cooperation2021,1980,1
Kreiskrankenhaus Siegen,Religion2020,1980,5
KU Leuven,Religion2020,1980,7
Kyushu University,Cooperation2021,1980,2
La Salle University,Religion2020,1980,3
La Sapienza University,Religion2020,1980,1
La Trobe University,Religion2020,1980,5
Lake Forest College,Religion2020,1980,1
Lakota Books Publishing Company,Religion2020,1980,3
Lamar University,Religion2020,1980,6
Lancaster University,Religion2020,1980,13
Laurentian University,Religion2020,1980,3
Laval University,Religion2020,1980,13
Lehigh University,Religion2020,1980,4
Leicester Cathedral,Religion2020,1980,3
Leiden University,Cooperation2021,1980,2
Lingnan University,Cooperation2021,1980,1
Liverpool Hope University,Religion2020,1980,2
Loma Linda University,Religion2020,1980,5
London School of Economics,Religion2020,1980,11
London University,Religion2020,1980,4
Long Island University,Religion2020,1980,1
Los Angeles Valley College,Religion2020,1980,1
Louisburg College,Religion2020,1980,2
Louisiana State University,Religion2020,1980,2
Louisiana Tech University,Religion2020,1980,5
Louisville Presbyterian Theological Seminary,Religion2020,1980,2
Loyola College of Maryland  College of Maryland  College of Maryland,Religion2020,1980,2
Loyola Marymount University,Religion2020,1980,3
Loyola University Chicago,Cooperation2021,1980,4
Loyola University Chicago,Religion2020,1980,5
Loyola University Maryland,Cooperation2021,1980,1
Loyola University Maryland,Religion2020,1980,7
Loyola University New Orleans,Religion2020,1980,6
Luiss University Rome,Cooperation2021,1980,1
Lund University,Religion2020,1980,1
Luther Theological Seminary,Religion2020,1980,1
Lutheran School of theology at Chicago,Religion2020,1980,3
Lutheran Theological Southern Seminary,Religion2020,1980,1
Macalester College,Religion2020,1980,1
Magdalene College,Religion2020,1980,4
Maharishi International University,Cooperation2021,1980,1
Maharishi International University,Religion2020,1980,7
Maharishi University of Management,Religion2020,1980,3
Manchester University,Religion2020,1980,4
Marian University,Religion2020,1980,2
Marquette University,Religion2020,1980,8
Massachusetts Institute of Technology,Religion2020,1980,5
Massey University,Religion2020,1980,1
Max Planck Institute,Cooperation2021,1980,1
Max Planck Institute for Comparative Public Law and International Law,Religion2020,1980,1
Maynooth University,Religion2020,1980,1
McDaniel College,Religion2020,1980,1
McGill University,Cooperation2021,1980,1
McGill University,Religion2020,1980,7
McMaster University,Religion2020,1980,14
Meadville Lombard Theological School,Religion2020,1980,2
Meiji University,Cooperation2021,1980,6
Memphis State University,Religion2020,1980,3
Memphis Theological Seminary,Religion2020,1980,2
Menninger Clinic,Religion2020,1980,3
Merrimack College,Religion2020,1980,2
Messiah College,Religion2020,1980,1
Methodist Theological School,Religion2020,1980,1
Mettaa Institute,Religion2020,1980,1
Miami University,Religion2020,1980,10
Michigan State University,Cooperation2021,1980,3
Microsoft,Religion2020,1980,3
Middle Tennesse State University,Religion2020,1980,3
Milligan College - Emmanuel School of Religion,Religion2020,1980,3
"Minnesota State University, Mankato",Religion2020,1980,2
Missouri Western State University,Religion2020,1980,2
Monash University,Cooperation2021,1980,2
Monash University,Religion2020,1980,5
Morris Institute for Human Values,Religion2020,1980,2
Mount Carmel College of Nursing,Religion2020,1980,1
Mount Sinai Hospital Toronto,Cooperation2021,1980,1
Mt. Sinai School of Medicine,Cooperation2021,1980,1
Mt. Sinai School of Medicine,Religion2020,1980,4
Murdoch University,Religion2020,1980,1
Nara National Research Institute for Cultural Properties,Religion2020,1980,1
National Council of Churches,Religion2020,1980,4
National Museum of Ethnology,Religion2020,1980,1
National Research University Higher School of Economics,Cooperation2021,1980,2
National Science Foundation,Religion2020,1980,7
Neumann University,Religion2020,1980,7
Neuroscience Center,Religion2020,1980,1
New London Synagogue,Religion2020,1980,3
New School for Social Research,Religion2020,1980,3
New Scientist at Reed Business Information,Cooperation2021,1980,1
New York Theological Seminary,Religion2020,1980,2
New York University,Cooperation2021,1980,8
New York University,CooperationAndReligion,1980,2
New York University,Religion2020,1980,21
Newcastle University,Religion2020,1980,2
Njala University,Cooperation2021,1980,1
North Carolina State University,Religion2020,1980,9
Northern Kentucky University,Cooperation2021,1980,1
Northern Kentucky University,Religion2020,1980,5
Northwestern University,Cooperation2021,1980,5
Northwestern University,Religion2020,1980,9
Norwegian University of Science and Technology,Cooperation2021,1980,1
Nova Southeastern University,Religion2020,1980,1
Oakland University,Religion2020,1980,2
Oberlin College,Religion2020,1980,10
Occidental College,Religion2020,1980,1
Ohio State University,Religion2020,1980,1
Ohio University,Cooperation2021,1980,3
Oklahoma State University,Religion2020,1980,1
Old Dominion University,Religion2020,1980,3
Open University (The),Religion2020,1980,2
Oregon State University,Religion2020,1980,1
Oxford University,Cooperation2021,1980,3
Oxford University,Religion2020,1980,14
Pacific School of Religion,Religion2020,1980,5
Palmer Theological Seminary,Religion2020,1980,1
Paris-Sorbonne University,Cooperation2021,1980,1
Peace Research Institute Oslo PRIO,Cooperation2021,1980,1
"Peking University, Beijing",Religion2020,1980,1
Penn State University,Religion2020,1980,1
Pennsylvania State University,Cooperation2021,1980,3
Pennsylvania State University,Religion2020,1980,17
Perkins School of Theology,Religion2020,1980,3
Péter Pázmány Catholic University,Religion2020,1980,3
Philipps University Marburg,Religion2020,1980,1
Phillipps Universitat Marburg,Religion2020,1980,4
Pima Community College,Religion2020,1980,4
Pittsburg State University,Religion2020,1980,2
Pomona College,Religion2020,1980,3
Pontifical Biblical Institute,Religion2020,1980,1
Portland State University,Religion2020,1980,1
Postgraduate Institute of Medical Education & Research,Religion2020,1980,2
Presidential Counsel,Religion2020,1980,4
Princeton Theological Seminary,Religion2020,1980,35
Princeton University,Cooperation2021,1980,1
Princeton University,CooperationAndReligion,1980,1
Princeton University,Religion2020,1980,26
Priory of St Michael the Archangel,Religion2020,1980,1
Private Practice,Religion2020,1980,6
Providence College,Religion2020,1980,3
Psychocultural Institute- Toronto,Religion2020,1980,1
Punjab Agricultural University,Cooperation2021,1980,2
Punjab Agricultural University,Religion2020,1980,1
Purdue University,Religion2020,1980,13
Queen Mary College,Religion2020,1980,1
Queen's University,Cooperation2021,1980,1
Queen's University Belfast,Cooperation2021,1980,1
Queen's University Belfast,Religion2020,1980,17
Quincy College,Religion2020,1980,5
Radboud University Nijmegen,Religion2020,1980,2
Reformed Theological Seminary,Cooperation2021,1980,1
Reformed Theological Seminary,Religion2020,1980,3
Regent College,Religion2020,1980,2
Regent University,Religion2020,1980,1
Religions of South Asia,Religion2020,1980,1
Renmin University of China,Religion2020,1980,1
Rhodes College,Religion2020,1980,4
Rhodes University,Cooperation2021,1980,5
Rhodes University,Religion2020,1980,1
Rice University,Cooperation2021,1980,3
Rice University,Religion2020,1980,3
Ripon College,Cooperation2021,1980,1
Ripon College,Religion2020,1980,1
Ritual Studied Internatinoal,Religion2020,1980,4
RMIT University,Religion2020,1980,4
Rockefeller University,Cooperation2021,1980,1
Rollins College,Religion2020,1980,1
Roma Tre University,Religion2020,1980,1
Romanian-American University,Religion2020,1980,1
Royal Society,Cooperation2021,1980,1
Royal University of Phnom Phen,Religion2020,1980,2
Ruhr University Bochum,Religion2020,1980,2
Rush University,Religion2020,1980,2
Rutgers University,Cooperation2021,1980,1
Rutgers University,Religion2020,1980,20
Saint Ignatius Church,Religion2020,1980,3
Saint Joseph's Universty,Religion2020,1980,1
Santa Clara University,Religion2020,1980,10
Santa Fe Institute,Cooperation2021,1980,1
Saybrook University,Cooperation2021,1980,1
Saybrook University,Religion2020,1980,1
School of Oriental and African Studies,Religion2020,1980,1
SCIENCE magazine,Cooperation2021,1980,2
SCIENCE magazine,Religion2020,1980,1
Scripps College,Religion2020,1980,8
Search Institute,Religion2020,1980,2
Seattle Pacific University,Religion2020,1980,4
Seattle University,Religion2020,1980,2
Shaanxi Normal University,Religion2020,1980,1
Shukutoku University,Religion2020,1980,2
Simmons College,Cooperation2021,1980,1
Simmons College,Religion2020,1980,5
Simon Fraser University,Cooperation2021,1980,6
Skidmore College,Religion2020,1980,1
Smith College,Religion2020,1980,6
SOAS University of London,Religion2020,1980,10
Sofia University,Religion2020,1980,2
Sophia University,Religion2020,1980,2
Sorbonne University,Religion2020,1980,6
Southampton University,Religion2020,1980,3
Southern Illinois University,Religion2020,1980,1
Southern Methodist University,Cooperation2021,1980,1
Southern Methodist University,Religion2020,1980,6
Southwest National Primate Center,Cooperation2021,1980,1
Spalding University,Religion2020,1980,7
Spelman College,Religion2020,1980,2
St Andrews University,Religion2020,1980,4
St Botolph's Aldgate,Religion2020,1980,1
St. Bonaventure University,Religion2020,1980,2
St. John's Medical College,Religion2020,1980,1
St. John's Seminary,Religion2020,1980,1
St. John's University,Religion2020,1980,4
St. Joseph’s College of Maine,Religion2020,1980,4
St. Joseph's University,Religion2020,1980,2
St. Louis University,Religion2020,1980,1
St. Mary's Seminary and University,Religion2020,1980,1
St. Olaf College,Religion2020,1980,4
St. Patrick's Seminary,Religion2020,1980,5
St. Petersburg State University,Religion2020,1980,1
St. Stephen's Episcopal Church,Religion2020,1980,3
St. Vladimir's Orthodox Theological Seminary,Religion2020,1980,1
Staffordshire University,Religion2020,1980,1
Stanford University,Cooperation2021,1980,5
Stanford University,Religion2020,1980,13
Stanley Royd Hospital,Religion2020,1980,2
State University of New York - Nassau,Religion2020,1980,3
State University of New York at Albany,Cooperation2021,1980,3
State University of New York at Albany,Religion2020,1980,2
State University of New York at Binghamton University,Religion2020,1980,5
State University of New York at Brockport,Religion2020,1980,1
State University of New York at Stony Brook,Religion2020,1980,4
State University of New York Downstate Medical Center,Cooperation2021,1980,1
Stellenbosch University,Religion2020,1980,2
Stockholm University,Religion2020,1980,7
Stonehill College,Religion2020,1980,1
Stony Brook University,Cooperation2021,1980,1
Stony Brook University,Religion2020,1980,3
Strayer College,Religion2020,1980,2
Strong BRAIN Institute,Religion2020,1980,3
SUNY Upstate Medical University,Religion2020,1980,1
Surrey University,Religion2020,1980,1
Swansea University,Religion2020,1980,2
Swarthmore College,Cooperation2021,1980,1
Swarthmore College,Religion2020,1980,2
Syracuse University,Cooperation2021,1980,2
Syracuse University,Religion2020,1980,7
Tabor College,Religion2020,1980,1
Taos Institute,Cooperation2021,1980,1
Tel Aviv University,Cooperation2021,1980,4
Tel Aviv University,Religion2020,1980,3
Temple University,Cooperation2021,1980,3
Temple University,Religion2020,1980,2
Tennessee State University,Religion2020,1980,1
Texas A&M University,Cooperation2021,1980,2
Texas A&M University,Religion2020,1980,6
Texas Christian University,Religion2020,1980,9
Texas State University,Religion2020,1980,1
Texas Tech University,Religion2020,1980,5
The Catholic University of America,Religion2020,1980,5
The Center for Mind Body Medicine,Religion2020,1980,1
The Chopra Foundation,Cooperation2021,1980,1
The Chopra Foundation,Religion2020,1980,3
The Coventry Association for Pastoral Psychology,Religion2020,1980,2
The Hastings Center,Religion2020,1980,2
The Hebrew University,Cooperation2021,1980,1
The Hebrew University,Religion2020,1980,1
"The King's Fund, London",Religion2020,1980,3
The Open University,Religion2020,1980,1
The Queens Foundation,Religion2020,1980,1
The University of Rochester School of Medicine,Religion2020,1980,1
The University of the South Sewanee,Religion2020,1980,2
Tilburg University,Religion2020,1980,1
Torrens University,Religion2020,1980,2
Transnational Family Research Institute,Cooperation2021,1980,1
Trinity College,Religion2020,1980,13
Trinity College Dublin,Religion2020,1980,6
Trinity University,Cooperation2021,1980,1
Trinity University,Religion2020,1980,9
Tufts University,Religion2020,1980,1
Tyndale University,Religion2020,1980,1
UC San Diego,Cooperation2021,1980,1
UC San Diego,Religion2020,1980,3
Ulster University,Cooperation2021,1980,1
Ulster University,Religion2020,1980,1
Union Presbyterian Seminary,Religion2020,1980,1
Union Theological College,Religion2020,1980,2
Union Theological Seminary,Religion2020,1980,21
United Theological Seminary,Religion2020,1980,4
United Theological Seminary of the Twin Cities,Religion2020,1980,2
Universitat Wurzburg,Religion2020,1980,3
Universite Laval,Religion2020,1980,4
University College Cork,Religion2020,1980,1
University College Dublin,Religion2020,1980,1
University College London,Cooperation2021,1980,3
University College London,CooperationAndReligion,1980,1
University College London,Religion2020,1980,11
University of Aarhus,Religion2020,1980,1
University of Aberdeen,Religion2020,1980,23
University of Akron,Religion2020,1980,2
University of Alabama in Huntsville,Religion2020,1980,1
University of Alberta,Religion2020,1980,8
University of Amsterdam,Religion2020,1980,5
University of Arizona,Cooperation2021,1980,6
University of Arizona,Religion2020,1980,19
University of Basel,Religion2020,1980,2
University of Bergen,Religion2020,1980,3
University of Berlin,Religion2020,1980,1
University of Bielefeld,Cooperation2021,1980,1
University of Bielefeld,Religion2020,1980,1
University of Birmingham,Cooperation2021,1980,1
University of Birmingham,Religion2020,1980,21
University of Bonn,Religion2020,1980,6
University of Botswana,Religion2020,1980,1
University of Bridgeport,Religion2020,1980,1
University of Brighton,Religion2020,1980,3
University of Bristol,Cooperation2021,1980,1
University of Bristol,Religion2020,1980,5
University of British Columbia,Cooperation2021,1980,2
University of British Columbia,CooperationAndReligion,1980,2
University of British Columbia,Religion2020,1980,8
University of Calgary,Religion2020,1980,25
University of California - Berkeley,Cooperation2021,1980,6
University of California - Berkeley,Religion2020,1980,9
University of California - Davis,Cooperation2021,1980,1
University of California - Davis,Religion2020,1980,2
University of California - Irvine,Cooperation2021,1980,2
University of California - Irvine,Religion2020,1980,4
University of California - Los Angeles,Cooperation2021,1980,3
University of California - Los Angeles,Religion2020,1980,10
University of California - Riverside,Cooperation2021,1980,1
University of California - Riverside,Religion2020,1980,8
University of California - San Diego,Cooperation2021,1980,4
University of California - San Diego,Religion2020,1980,3
University of California - San Francisco,Cooperation2021,1980,1
University of California - San Francisco,Religion2020,1980,16
University of California - Santa Barbara,Cooperation2021,1980,1
University of California - Santa Barbara,Religion2020,1980,37
"University of California, Berkeley",Cooperation2021,1980,8
"University of California, Berkeley",Religion2020,1980,13
"University of California, Davis",Cooperation2021,1980,2
"University of California, Davis",Religion2020,1980,1
"University of California, Irvine",Religion2020,1980,5
"University of California, Los Angeles",Cooperation2021,1980,4
"University of California, Los Angeles",Religion2020,1980,13
"University of California, Riverside",Religion2020,1980,5
"University of California, San Diego",Cooperation2021,1980,1
"University of California, San Diego",Religion2020,1980,9
"University of California, Santa Barbara",Cooperation2021,1980,3
"University of California, Santa Barbara",Religion2020,1980,26
"University of California, Santa Cruz",Religion2020,1980,5
University of Cambridge,Cooperation2021,1980,1
University of Cambridge,Religion2020,1980,45
University of Cape Town,Cooperation2021,1980,1
University of Cape Town,CooperationAndReligion,1980,1
University of Central Arkansas,Religion2020,1980,3
University of Chester,Religion2020,1980,12
University of Chicago,Cooperation2021,1980,1
University of Chicago,Religion2020,1980,59
University of Chicago Divinity School,Religion2020,1980,8
University of Cincinnati,Religion2020,1980,5
University of Colorado at Boulder,Cooperation2021,1980,2
University of Colorado at Boulder,Religion2020,1980,1
University of Colorado Boulder,Religion2020,1980,7
University of Colorado Denver,Cooperation2021,1980,1
"University of Colorado, Boulder",Cooperation2021,1980,1
University of Concordia,Religion2020,1980,4
University of Connecticut,Religion2020,1980,12
University of Constance,Religion2020,1980,1
University of Copenhagen,Religion2020,1980,2
University of Dakar,Cooperation2021,1980,1
University of Dakar,CooperationAndReligion,1980,1
University of Dakar,Religion2020,1980,9
University of Dayton,Religion2020,1980,3
University of Delaware,Religion2020,1980,3
University of Denver,Religion2020,1980,6
University of Detroit Mercy,Religion2020,1980,5
University of Dundee,Religion2020,1980,2
University of Durham,Religion2020,1980,10
University of Düsseldorf,Religion2020,1980,1
University of East Anglia,Religion2020,1980,2
University of East London,Religion2020,1980,3
University of Edinburgh,Religion2020,1980,25
University of Essex,Cooperation2021,1980,1
University of Essex,Religion2020,1980,4
University of Exeter,Cooperation2021,1980,1
University of Exeter,Religion2020,1980,8
University of Florida,Cooperation2021,1980,2
University of Florida,Religion2020,1980,7
University of Freiburg,Religion2020,1980,1
University of Geneva,Religion2020,1980,3
University of Georgia,Cooperation2021,1980,1
University of Georgia,Religion2020,1980,9
University of Ghana,Religion2020,1980,1
University of Glasgow,Cooperation2021,1980,1
University of Glasgow,Religion2020,1980,16
University of Göttingen,Religion2020,1980,2
University of Groningen,Cooperation2021,1980,2
University of Groningen,Religion2020,1980,1
University of Guelph,Religion2020,1980,5
University of Haifa,Religion2020,1980,7
University of Hamburg,Cooperation2021,1980,1
University of Hamburg,Religion2020,1980,6
University of Hawai‘i,Religion2020,1980,2
University of Hawai‘i at Mānoa,Religion2020,1980,3
University of Hawai‘i–West O‘ahu,Cooperation2021,1980,1
University of Helsinki,Cooperation2021,1980,1
University of Helsinki,Religion2020,1980,2
University of Hertfordshire,Religion2020,1980,2
University of Hong Kong,Cooperation2021,1980,1
University of Houston,Religion2020,1980,5
University of Hull,Religion2020,1980,3
University of Illinois,Cooperation2021,1980,1
University of Illinois,Religion2020,1980,3
University of Illinois at Chicago,Cooperation2021,1980,2
University of Illinois at Urbana-Champaign,Cooperation2021,1980,2
University of Illinois at Urbana-Champaign,Religion2020,1980,4
University of Iowa,Religion2020,1980,6
University of Johannesburg,Religion2020,1980,2
University of Kansas,Cooperation2021,1980,2
University of Kansas,Religion2020,1980,13
University of Kent,Religion2020,1980,2
University of Kentucky,Religion2020,1980,2
University of Koln,Religion2020,1980,1
University of Lancaster,Religion2020,1980,4
University of Lausanne,Religion2020,1980,3
University of Leeds,Cooperation2021,1980,1
University of Leeds,Religion2020,1980,6
University of Leicester,Religion2020,1980,1
University of Lethbridge,Religion2020,1980,4
University of Liverpool,Religion2020,1980,3
University of London,Religion2020,1980,23
University of Louisville,Religion2020,1980,8
University of Maine,Religion2020,1980,1
University of Malawi,Religion2020,1980,1
University of Malaya,Cooperation2021,1980,1
University of Malaya,Religion2020,1980,2
University of Manchester,Cooperation2021,1980,1
University of Manchester,Religion2020,1980,14
University of Manitoba,Religion2020,1980,6
University of Marburg,Religion2020,1980,3
University of Maryland,Cooperation2021,1980,3
University of Maryland,Religion2020,1980,4
"University of Maryland, College Park",Cooperation2021,1980,1
"University of Maryland, College Park",Religion2020,1980,1
University of Massachusetts - Amherst,Religion2020,1980,1
University of Massachusetts - Boston,Religion2020,1980,1
University of Massachusetts Memorial Medical Center,Religion2020,1980,4
University of Melbourne,Religion2020,1980,6
University of Memphis,Cooperation2021,1980,1
University of Memphis,Religion2020,1980,4
University of Michigan,Cooperation2021,1980,4
University of Michigan,Religion2020,1980,21
University of Minnesota,Cooperation2021,1980,4
University of Minnesota,Religion2020,1980,8
University of Mississippi,Religion2020,1980,5
University of Missouri,Religion2020,1980,8
University of Montana,Religion2020,1980,2
University of Montreal,Cooperation2021,1980,3
University of Montreal,Religion2020,1980,11
University of Munich,Religion2020,1980,2
University of Nairobi,Religion2020,1980,1
University of Nanterre,Religion2020,1980,4
University of Nebraska,Cooperation2021,1980,1
University of Nebraska,Religion2020,1980,1
"University of Nebraska, Lincoln",Religion2020,1980,8
"University of Nebraska, Omaha",Cooperation2021,1980,2
"University of Nebraska, Omaha",Religion2020,1980,2
University of Nebraska-Lincoln,Cooperation2021,1980,3
University of Nevada,Religion2020,1980,1
University of Nevada - Reno,Religion2020,1980,5
"University of Nevada, Las Vegas",Religion2020,1980,1
University of New Brunswick,Religion2020,1980,3
University of New Delhi,Religion2020,1980,2
University of New England,Religion2020,1980,2
University of New Mexico,Cooperation2021,1980,2
University of New South Wales,Cooperation2021,1980,3
University of New South Wales,Religion2020,1980,3
University of North Carolina,Cooperation2021,1980,1
University of North Carolina - Chapel Hill,Religion2020,1980,2
University of North Carolina at Chapel Hill,Cooperation2021,1980,1
University of North Carolina at Chapel Hill,Religion2020,1980,12
University of North Texas,Religion2020,1980,2
University of North Texas HSC,Religion2020,1980,1
University of Notre Dame,Religion2020,1980,41
University of Nottingham,Religion2020,1980,1
University of Oregon,Cooperation2021,1980,1
University of Oregon,Religion2020,1980,3
University of Oslo,Religion2020,1980,2
University of Ottawa,Religion2020,1980,6
University of Oxford,Cooperation2021,1980,2
University of Oxford,Religion2020,1980,56
University of Oxford Mindfulness Research Centre,Cooperation2021,1980,1
University of Oxford Mindfulness Research Centre,CooperationAndReligion,1980,1
University of Oxford Mindfulness Research Centre,Religion2020,1980,2
University of Padua,Religion2020,1980,1
University of Pennsylvania,Cooperation2021,1980,5
University of Pennsylvania,Religion2020,1980,19
University of Pittsburgh,Cooperation2021,1980,2
University of Pittsburgh,Religion2020,1980,4
University of Port Harcourt,Religion2020,1980,1
University of Québec at Montreal,Religion2020,1980,1
University of Quebec at Trois-Rivières,Cooperation2021,1980,1
University of Quebec in Montreal,Religion2020,1980,2
University of Queensland,Religion2020,1980,4
University of Regensburg,Religion2020,1980,1
University of Rhode Island,Religion2020,1980,2
University of Richmond,Cooperation2021,1980,1
University of Richmond,Religion2020,1980,2
University of Rochester,Religion2020,1980,1
University of Roehampton,Religion2020,1980,7
University of Salzburg,Religion2020,1980,1
University of San Diego,Religion2020,1980,11
University of Saskatchewan,Religion2020,1980,1
University of Sheffield,Religion2020,1980,3
University of Sherbrooke,Religion2020,1980,1
University of South Africa,Religion2020,1980,1
University of South Australia,Religion2020,1980,4
University of South Carolina,Religion2020,1980,9
University of South-Eastern Norway,Cooperation2021,1980,1
University of South-Eastern Norway,Religion2020,1980,1
University of Southern California,Cooperation2021,1980,1
University of Southern California,Religion2020,1980,14
University of Southern Maine,Religion2020,1980,2
University of Split,Religion2020,1980,1
University of St Andrews,Religion2020,1980,14
University of Stellenbosch,Cooperation2021,1980,1
University of Stellenbosch,CooperationAndReligion,1980,1
University of Stirling,Religion2020,1980,18
University of Strathclyde,Cooperation2021,1980,1
University of Strathclyde,Religion2020,1980,1
University of Stuttgart,Religion2020,1980,1
University of Surrey,Religion2020,1980,1
University of Sussex,Cooperation2021,1980,1
University of Sussex,Religion2020,1980,6
University of Sydney,Religion2020,1980,10
University of Tartu,Religion2020,1980,1
University of Tennessee,Cooperation2021,1980,1
University of Tennessee,Religion2020,1980,9
University of Tennessee at Chattanooga,Religion2020,1980,9
University of Texas at Arlington,Religion2020,1980,4
University of Texas at Austin,Religion2020,1980,4
University of Texas at Dallas,Cooperation2021,1980,1
University of Texas at Dallas,Religion2020,1980,1
University of Texas Medical Branch at Galveston,Religion2020,1980,1
University of Texas Pan American,Religion2020,1980,1
University of Texas School of Public Health,Religion2020,1980,3
University of the South,Religion2020,1980,2
University of Tokyo,Religion2020,1980,4
University of Toronto,Cooperation2021,1980,10
University of Toronto,Religion2020,1980,9
University of Trento,Religion2020,1980,1
University of Tubigen Germany,Religion2020,1980,1
University of Tulsa,Religion2020,1980,8
University of Turku,Religion2020,1980,2
University of Ulm,Cooperation2021,1980,1
University of Ulm,Religion2020,1980,2
University of Ulster,Religion2020,1980,7
University of Utah,Cooperation2021,1980,2
University of Utah,Religion2020,1980,14
University of Utrecht,Religion2020,1980,1
University of Vermont,Religion2020,1980,2
University of Victoria,Cooperation2021,1980,2
University of Victoria,Religion2020,1980,8
University of Vienna,Cooperation2021,1980,2
University of Vienna,Religion2020,1980,11
University of Virginia,Cooperation2021,1980,4
University of Virginia,Religion2020,1980,41
University of Waikato,Religion2020,1980,2
University of Wales,Religion2020,1980,6
University of Warwick,Religion2020,1980,13
University of Washington,Cooperation2021,1980,2
University of Washington,Religion2020,1980,14
University of Washington School of Medicine,Cooperation2021,1980,2
University of Washington School of Medicine,Religion2020,1980,3
"University of Washington, Seattle",Religion2020,1980,1
University of Waterloo,Religion2020,1980,8
University of West Florida,Religion2020,1980,2
University of West Georgia,Religion2020,1980,2
University of Western Australia,Religion2020,1980,2
University of Western Cape,Religion2020,1980,1
University of Western Ontario,Cooperation2021,1980,1
University of Windsor,Religion2020,1980,2
University of Winnipeg,Religion2020,1980,1
University of Wisconsin,Cooperation2021,1980,1
University of Wisconsin,Religion2020,1980,2
University of Wisconsin - Eau Claire,Religion2020,1980,2
University of Wisconsin - Madison,Cooperation2021,1980,2
University of Wisconsin - Madison,Religion2020,1980,6
University of Wisconsin-La Crosse,Religion2020,1980,4
University of Wisconsin-Madison,Cooperation2021,1980,2
University of Wisconsin-Milwaukee,Religion2020,1980,2
University of Witwatersrand,Religion2020,1980,1
University of Wollongong,Religion2020,1980,1
University of Wolverhampton,Cooperation2021,1980,1
University of Wyoming,Cooperation2021,1980,1
University of Wyoming,Religion2020,1980,2
University of Zambia,Religion2020,1980,2
University of Zimbabwe,Religion2020,1980,2
University of Zurich,Religion2020,1980,2
Uppsala University,Religion2020,1980,4
Utah State University,Religion2020,1980,7
Utrecht University,Religion2020,1980,4
Valparaiso University,Religion2020,1980,2
Vanderbilt Divinity School,Religion2020,1980,3
Vanderbilt University,Cooperation2021,1980,1
Vanderbilt University,Religion2020,1980,20
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Religion2020,1980,1
Victoria University of Wellington,Religion2020,1980,4
Villanova University,Religion2020,1980,11
Virginia Commonwealth University,Religion2020,1980,5
Virginia Polytechnic Institute and State University,Religion2020,1980,3
Virginia Tech,Religion2020,1980,1
Vrije Universiteit Amsterdam,Cooperation2021,1980,1
Vrije Universiteit Amsterdam,Religion2020,1980,2
Vrije Universiteit Brussel,Religion2020,1980,1
Wake Forest Baptist Health,Religion2020,1980,1
Wake Forest University,Religion2020,1980,1
Warburg Institute,Religion2020,1980,1
Wartburg Theological Seminary,Religion2020,1980,3
Washington and Lee University,Religion2020,1980,4
Washington College,Religion2020,1980,1
Washington State University,Religion2020,1980,5
Washington Theological Coalition,Religion2020,1980,2
Washington University in St Louis,Cooperation2021,1980,3
Washington University in St Louis,Religion2020,1980,2
Wayne State University,Cooperation2021,1980,1
Wayne State University,Religion2020,1980,11
Webster University,Religion2020,1980,2
Weill Cornell Medical College in Qatar,Cooperation2021,1980,1
Wellesley College,Religion2020,1980,3
Wesleyan University,Religion2020,1980,1
West Chester University of Pennsylvania,Cooperation2021,1980,1
West Chester University of Pennsylvania,Religion2020,1980,1
West Suffolk Hospital,Religion2020,1980,5
West Virginia University,Religion2020,1980,1
Western Kentucky University,Religion2020,1980,1
Western Michigan University,Religion2020,1980,6
Western University,Cooperation2021,1980,2
Westfield College,Religion2020,1980,1
Westminster Choir College,Religion2020,1980,1
Westmont College,Religion2020,1980,2
Wheaton College,Cooperation2021,1980,1
Wheaton College,Religion2020,1980,2
Wichita State University,Religion2020,1980,5
Wilfrid Laurier University,Religion2020,1980,3
Wilkes University,Religion2020,1980,2
William & Mary,Religion2020,1980,2
Williams College,Religion2020,1980,1
Wolfson College,Religion2020,1980,1
World Health Organization,Religion2020,1980,1
Wright State University,Religion2020,1980,1
Xavier University,Religion2020,1980,1
Yale Divinity School,Religion2020,1980,1
Yale University,Cooperation2021,1980,4
Yale University,Religion2020,1980,23
Yale University Mind and Development Lab,Religion2020,1980,4
Yale University School of Medicine,Religion2020,1980,1
Yeshiva University,Religion2020,1980,1
Yeshivah of Flatbush High School,Religion2020,1980,1
Yonsei University,Religion2020,1980,3
York University,Religion2020,1980,8
Zart,Religion2020,1980,2
 Xi’an Jiaotong University,Cooperation2021,1975,1
Aarhus University,Religion2020,1975,1
Abilene Christian University,Religion2020,1975,1
Adelphi University,Religion2020,1975,2
ADRx,Cooperation2021,1975,1
ADRx,Religion2020,1975,2
Albert Einstein College of Medicine,Cooperation2021,1975,1
Albert Einstein College of Medicine,Religion2020,1975,2
Alfred University,Religion2020,1975,1
Allegheny University of the Health Sciences,Cooperation2021,1975,2
Allegheny University of the Health Sciences,Religion2020,1975,1
Alliant International University,Cooperation2021,1975,5
American Council on Education,Religion2020,1975,1
American Enterprise Institute,Religion2020,1975,1
American Health Foundation,Religion2020,1975,3
American University,Religion2020,1975,5
American University of Beirut,Cooperation2021,1975,1
American University of Beirut,Religion2020,1975,2
American University of Kuwait,Religion2020,1975,1
Amgen Inc,Cooperation2021,1975,1
Amgen Inc,Religion2020,1975,2
Amherst College,Religion2020,1975,5
Andover Newton Theological School,Religion2020,1975,2
Andrews University Seventh-day Adventist Theological Seminary,Religion2020,1975,1
Arizona State University,Cooperation2021,1975,5
Arizona State University,Religion2020,1975,2
Atlantic School of Theology,Religion2020,1975,1
Auburn University,Religion2020,1975,1
Augusta University,Religion2020,1975,2
Augustana University,Religion2020,1975,1
Austin Presbyterian Theological Seminary,Cooperation2021,1975,3
Australian Catholic University,Religion2020,1975,5
Australian National University,Religion2020,1975,3
Azusa Pacific University,Religion2020,1975,1
Ball State University,Religion2020,1975,5
Bangor University,Religion2020,1975,1
Bard College,Religion2020,1975,10
Bar-Ilan University,Religion2020,1975,5
Barnard College,Cooperation2021,1975,2
Barrington College,Religion2020,1975,2
Bates College,Religion2020,1975,1
Baycrest Geriatric Centre,Cooperation2021,1975,1
Baylor College of Medicine,Cooperation2021,1975,1
Baylor University,Religion2020,1975,6
Beckman Initiative for Macular Research,Cooperation2021,1975,8
Ben Gurion University in the Negev,Religion2020,1975,1
Biola University,Religion2020,1975,2
Birmingham University,Religion2020,1975,1
Bishop of Guilford,Religion2020,1975,1
Boston College,Cooperation2021,1975,1
Boston College,Religion2020,1975,3
Boston University,Religion2020,1975,24
Boston University School of Medicine,Religion2020,1975,7
Bowling Green State University,Cooperation2021,1975,1
Bowling Green State University,Religion2020,1975,3
Brandeis University,Religion2020,1975,1
Brigham Young University,Cooperation2021,1975,1
Brigham Young University,Religion2020,1975,4
Brock University,Cooperation2021,1975,1
Brooklyn College,Religion2020,1975,1
Brown University,Religion2020,1975,9
California Institute of Integral Studies,Religion2020,1975,2
California Institute of Technology,Cooperation2021,1975,1
California Lutheran University,Religion2020,1975,5
California State University,Religion2020,1975,2
"California State University, Long Beach",Religion2020,1975,2
"California State University, Stanislaus",Religion2020,1975,1
Calvin College,Religion2020,1975,1
Calvin University,Cooperation2021,1975,1
Calvin University,Religion2020,1975,4
Cambridge University,Cooperation2021,1975,1
Cambridge University,Religion2020,1975,8
Candler School of Theology,Religion2020,1975,2
Cardiff University,Religion2020,1975,2
Carleton College,Religion2020,1975,2
Carleton University,Religion2020,1975,17
Carnegie Mellon University,Cooperation2021,1975,2
Catholic Ecumenical Institute,Religion2020,1975,1
Catholic Theological Union,Religion2020,1975,1
Catholic University of America,Cooperation2021,1975,1
Catholic University of America,Religion2020,1975,21
Catholic University of Louvain,Religion2020,1975,1
Center for Ethics and Toxics,Cooperation2021,1975,1
Center for Psychosocial Research,Cooperation2021,1975,2
Center for the Study on New Religions,Religion2020,1975,6
Center for Theological Inquiry,Religion2020,1975,1
"Central University of Bihar, Patna",Cooperation2021,1975,2
Centre for Addiction and Mental Health,Cooperation2021,1975,3
Centre National de la Recherche Scientifique,Religion2020,1975,3
Centre Tricontinental (CETRI),Religion2020,1975,6
Chatham University,Cooperation2021,1975,2
Chatham University,CooperationAndReligion,1975,2
Chatham University,Religion2020,1975,2
Chicago Medical School,Cooperation2021,1975,1
Chicago Medical School,Religion2020,1975,4
Chicago Theological Seminary,Religion2020,1975,9
Christian Theological Seminary,Religion2020,1975,1
Christ's College,Religion2020,1975,1
Church of England,Cooperation2021,1975,1
Church of England,Religion2020,1975,1
City University of New York,Cooperation2021,1975,1
Claremont Graduate University,Religion2020,1975,13
Claremont McKenna College,Religion2020,1975,1
Claremont school of theology,Religion2020,1975,1
Clemson University,Religion2020,1975,2
Colgate University,Religion2020,1975,3
College of Charlestown,Religion2020,1975,5
College of France,Religion2020,1975,3
College of Marin,Cooperation2021,1975,1
College of St. Rose,Religion2020,1975,2
Colombiere Center,Religion2020,1975,3
Colorado State University,Religion2020,1975,1
Columbia Theological Seminary,Religion2020,1975,3
Columbia University,Cooperation2021,1975,10
Columbia University,Religion2020,1975,15
Concordia College,Religion2020,1975,2
Concordia Seminary,Religion2020,1975,1
Concordia University,Cooperation2021,1975,1
Concordia University,Religion2020,1975,13
Connecticut College,Religion2020,1975,5
Consortium for Research on Emotional Intelligence in Organizations,Cooperation2021,1975,1
Consortium for Research on Emotional Intelligence in Organizations,Religion2020,1975,3
Cornell University,Cooperation2021,1975,5
Cornell University,Religion2020,1975,7
Crafton Hills College,Religion2020,1975,5
Creighton University,Religion2020,1975,1
Crozer Theological Seminary,Religion2020,1975,1
CUNY,Religion2020,1975,4
"Cuyamungue Institute, New Mexico",Religion2020,1975,2
Dalhousie University,Cooperation2021,1975,4
Dartmouth College,Religion2020,1975,2
David and Carol Myers Foundation,Cooperation2021,1975,2
Delaware Law School,Religion2020,1975,1
DePauw University,Religion2020,1975,1
Divine Mercy University,Religion2020,1975,1
Division of Diabetes Translation,Religion2020,1975,3
Dongbei University of Finance and Economics,Religion2020,1975,2
Downing College,Cooperation2021,1975,1
Downside Abbey,Religion2020,1975,1
Drew University,Religion2020,1975,6
Drexel University,Religion2020,1975,5
Dropsie College,Religion2020,1975,2
Duke Divinity School,Religion2020,1975,8
Duke University,Cooperation2021,1975,2
Duke University,Religion2020,1975,19
Duquesne University,Religion2020,1975,2
Durham University,Religion2020,1975,11
East African Institute of Social Research,Religion2020,1975,1
East Carolina University,Religion2020,1975,1
Eastern Baptist Theological Seminary,Religion2020,1975,1
Eastern Mennonite University,Religion2020,1975,2
Eastern Michigan University,Religion2020,1975,1
Eastern University,Religion2020,1975,2
Eckerd College,Religion2020,1975,2
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1975,2
École Pratique des Hautes Etudes,Religion2020,1975,1
École pratique des hautes études,Religion2020,1975,3
Emmanuel College,Religion2020,1975,5
Emory University,Cooperation2021,1975,1
Emory University,Religion2020,1975,35
Esalen Institute,Religion2020,1975,1
Eurasian National University,Cooperation2021,1975,1
European Institute of Design,Religion2020,1975,1
Fairfield University,Cooperation2021,1975,1
Fairfield University,Religion2020,1975,2
Federal University of São Paulo (UNIFESP),Religion2020,1975,1
Fisk University,Religion2020,1975,3
Flinders University,Religion2020,1975,1
Florida International University,Religion2020,1975,4
Florida State University,Cooperation2021,1975,8
Florida State University,Religion2020,1975,3
Fordham University,Religion2020,1975,17
Franklin College,Religion2020,1975,6
Free University of Berlin,Religion2020,1975,2
Fuller Theological Seminary,Cooperation2021,1975,1
Fuller Theological Seminary,CooperationAndReligion,1975,1
Fuller Theological Seminary,Religion2020,1975,17
Garrett Theological Seminary,Religion2020,1975,1
Garrett-Evangelical Theological Seminary,Religion2020,1975,4
General Theological Seminary,Religion2020,1975,2
George Mason University,Cooperation2021,1975,1
George Mason University,Religion2020,1975,4
George Washington University,Cooperation2021,1975,2
George Washington University,Religion2020,1975,7
Georgetown College,Religion2020,1975,5
Georgetown University,Cooperation2021,1975,4
Georgetown University,Religion2020,1975,21
Georgia State University,Cooperation2021,1975,1
Georgia State University,Religion2020,1975,2
GKT School of Medical Education,Religion2020,1975,2
Global Academic Professionals,Religion2020,1975,1
Goethe University Frankfurt,Religion2020,1975,1
Golden Gate Baptist Theological Seminary,Cooperation2021,1975,1
"Goldsmiths, University of London",Religion2020,1975,1
Grace,Religion2020,1975,6
Graduate Theological Foundation,Religion2020,1975,1
Graduate Theological Union,Religion2020,1975,11
Gresham College,Religion2020,1975,2
Griffith University,Religion2020,1975,1
Groningen University,Religion2020,1975,1
Guestbrook Project,Cooperation2021,1975,1
Guttmacher Institute,Cooperation2021,1975,4
Guttmacher Institute,Religion2020,1975,1
Haight-Ashbury Free Clinic (HAFC),Religion2020,1975,1
Harris Manchester College,Religion2020,1975,1
Hartford Seminary,Religion2020,1975,2
Harvard Business School,Religion2020,1975,2
Harvard Center for World Religions,Religion2020,1975,4
Harvard Divinity School,Religion2020,1975,11
Harvard Medical School,Cooperation2021,1975,1
Harvard Medical School,Religion2020,1975,2
Harvard School of Public Health,Cooperation2021,1975,15
Harvard School of Public Health,Religion2020,1975,1
Harvard University,Cooperation2021,1975,21
Harvard University,Religion2020,1975,19
Healthcare Chaplaincy,Religion2020,1975,1
Hebrew Union College,Religion2020,1975,1
Hebrew University of Jerusalem,Cooperation2021,1975,2
Hebrew University of Jerusalem,Religion2020,1975,6
Heidelberg University,Religion2020,1975,1
Heriot-Watt University,Religion2020,1975,1
Heythrop College,Religion2020,1975,2
Highland Theological College,Cooperation2021,1975,1
Highland Theological College,Religion2020,1975,7
Hobart and William Smith Colleges,Religion2020,1975,3
Hofstra University,Cooperation2021,1975,1
Holy Ghost College,Religion2020,1975,1
Hope College,Cooperation2021,1975,2
"Hunter College, CUNY",Religion2020,1975,1
"Huntington School, York",Religion2020,1975,3
Huntington University,Religion2020,1975,1
Ibadan University,Religion2020,1975,2
ICOS Corp,Cooperation2021,1975,1
ICOS Corp,Religion2020,1975,2
Iliff School of Theology,Religion2020,1975,6
Impact Publishers,Cooperation2021,1975,1
Imperial College London,Cooperation2021,1975,2
Indian Cancer Society,Religion2020,1975,2
Indiana University,Cooperation2021,1975,6
Indiana University,CooperationAndReligion,1975,1
Indiana University,Religion2020,1975,15
Indiana University of Pennsylvania,Religion2020,1975,2
Indiana University South Bend,Religion2020,1975,1
Institut national de la recherche scientifique,Religion2020,1975,1
Institute Ecumenical and Cultural Research,Religion2020,1975,1
Institute for Advanced Study,Religion2020,1975,1
Institute of Buddhist Studies,Religion2020,1975,1
Institute of Psychiatry,Cooperation2021,1975,3
Italian Society of Sociology,Religion2020,1975,1
Jagiellonian University,Cooperation2021,1975,2
Jagiellonian University,Religion2020,1975,1
James Madison University,Religion2020,1975,1
Jawaharlal Nehru University,Cooperation2021,1975,1
Jesuit School of Theology,Religion2020,1975,2
Jewish Theological Seminary,Religion2020,1975,1
John Hopkins School of Medicine,Religion2020,1975,4
John Hopkins University,Cooperation2021,1975,1
John Hopkins University,Religion2020,1975,1
Johns Hopkins University,Cooperation2021,1975,11
Johns Hopkins University,Religion2020,1975,5
Joint Centre of Excellence for Research on Immigration & Settlement,Religion2020,1975,1
Journal of Preventative Medicine,Religion2020,1975,1
Kalamazoo College,Religion2020,1975,2
Keele University,Religion2020,1975,2
Kent State University,Cooperation2021,1975,2
Kent State University,Religion2020,1975,4
Keston College,Religion2020,1975,1
Keston Institute,Religion2020,1975,2
Kiet group of institutions Ghaziabad,Religion2020,1975,1
Kings College,Religion2020,1975,1
King's College,Religion2020,1975,4
King's College London,Religion2020,1975,17
Kings College London University,Cooperation2021,1975,1
Kobe Gakuin University,Cooperation2021,1975,1
Kreiskrankenhaus Siegen,Cooperation2021,1975,1
Kreiskrankenhaus Siegen,Religion2020,1975,3
Kyushu University,Religion2020,1975,1
La Salle University,Religion2020,1975,4
La Sapienza University,Religion2020,1975,2
La Trobe University,Religion2020,1975,1
Lakota Books Publishing Company,Religion2020,1975,3
Lancaster University,Religion2020,1975,6
Laval University,Religion2020,1975,4
Lehigh University,Cooperation2021,1975,6
Lewis & Clark College,Religion2020,1975,1
Liberty University,Religion2020,1975,1
LifeWork Choices - Career Counseling and Planning,Cooperation2021,1975,5
Lingnan University,Cooperation2021,1975,1
Liverpool Hope University,Religion2020,1975,1
Logos Center,Religion2020,1975,1
Loma Linda University,Cooperation2021,1975,3
Loma Linda University,Religion2020,1975,2
London School of Economics,Religion2020,1975,4
London School of Economics and Political Science,Religion2020,1975,3
London University,Religion2020,1975,7
Long Island University,Religion2020,1975,2
Loughborough University,Cooperation2021,1975,2
Louisburg College,Religion2020,1975,1
Louisiana State University,Cooperation2021,1975,1
Louisiana State University,Religion2020,1975,1
Louisville Institute,Religion2020,1975,1
Louisville Presbyterian Theological Seminary,Religion2020,1975,6
Loyola University Chicago,Religion2020,1975,3
Loyola University Maryland,Cooperation2021,1975,1
Loyola University New Orleans,Religion2020,1975,2
Ludwig Maximilian University of Munich,Religion2020,1975,1
Lund University,Cooperation2021,1975,7
Luther Theological Seminary,Religion2020,1975,1
Lutheran School of theology at Chicago,Religion2020,1975,3
Madras Medical College,Religion2020,1975,1
Magdalene College,Religion2020,1975,6
Maharishi International University,Religion2020,1975,1
Maharishi University of Management,Religion2020,1975,1
Makerere University,Religion2020,1975,1
Malmö General Hospital,Cooperation2021,1975,7
Manchester University,Religion2020,1975,1
Marquette University,Religion2020,1975,4
Max Planck Institute,Cooperation2021,1975,1
McGill University,Cooperation2021,1975,1
McGill University,Religion2020,1975,8
McMaster Divinity School,Religion2020,1975,1
McMaster University,Religion2020,1975,8
Meadville Lombard Theological School,Cooperation2021,1975,1
Meadville Lombard Theological School,CooperationAndReligion,1975,1
Meadville Lombard Theological School,Religion2020,1975,4
Medical University of South Carolina,Cooperation2021,1975,1
Memphis State University,Religion2020,1975,1
Memphis Theological Seminary,Religion2020,1975,1
Menninger Clinic,Religion2020,1975,4
Merrimack College,Religion2020,1975,2
Methodist Theological School,Religion2020,1975,2
Mettaa Institute,Cooperation2021,1975,1
Miami University,Religion2020,1975,5
Michigan State University,Cooperation2021,1975,2
Microsoft,Religion2020,1975,1
Midland Centre for Neurosurgery and Neurology,Cooperation2021,1975,1
Millersville State College,Religion2020,1975,3
"Minnesota State University, Mankato",Religion2020,1975,2
Missouri University of Science and Technology,Religion2020,1975,3
Monash University,Religion2020,1975,4
Mount Mary University,Religion2020,1975,3
Mount Sinai Hospital Toronto,Cooperation2021,1975,1
Mt. Sinai School of Medicine,Cooperation2021,1975,1
Mt. Sinai School of Medicine,Religion2020,1975,5
Murray Edwards College,Religion2020,1975,1
Naropa University,Cooperation2021,1975,1
National Academies Keck Futures Initiative,Cooperation2021,1975,8
National Institute of Child Health & Human Development,Cooperation2021,1975,2
National Institute of Mental Health,Cooperation2021,1975,1
National Institutes of Health,Cooperation2021,1975,2
National Science Foundation,Religion2020,1975,1
National University of Ireland,Religion2020,1975,1
National University of Malaysia,Religion2020,1975,2
Naval Amphibious Base Coronado,Cooperation2021,1975,1
Naval Amphibious Base Coronado,Religion2020,1975,1
New London Synagogue,Religion2020,1975,2
New Orleans Baptist Theological Seminary,Religion2020,1975,1
New School for Social Research,Cooperation2021,1975,1
New School for Social Research,Religion2020,1975,4
New York University,Cooperation2021,1975,10
New York University,Religion2020,1975,24
Newcastle University,Religion2020,1975,4
North Carolina State University,Religion2020,1975,9
North-West University,Religion2020,1975,1
Northwestern University,Cooperation2021,1975,1
Northwestern University,Religion2020,1975,1
Nova Southeastern University,Cooperation2021,1975,1
Oakland University,Cooperation2021,1975,1
Oberlin College,Religion2020,1975,10
Ohio State University,Religion2020,1975,1
Ohio University,Cooperation2021,1975,5
Old Dominion University,Religion2020,1975,5
Open University (The),Religion2020,1975,1
Oregon State University,Cooperation2021,1975,1
Oxford University,Cooperation2021,1975,7
Oxford University,Religion2020,1975,18
Pacific Lutheran University,Religion2020,1975,1
Pacific School of Religion,Religion2020,1975,5
Palo Alto University,Cooperation2021,1975,2
Paris-Sorbonne University,Religion2020,1975,1
Pennsylvania State University,Religion2020,1975,11
Perkins School of Theology,Religion2020,1975,2
Phillipps Universitat Marburg,Religion2020,1975,2
Phillips University,Religion2020,1975,2
Pima Community College,Religion2020,1975,2
Pittsburg State University,Religion2020,1975,1
Pomona College,Religion2020,1975,2
Pontifical Biblical Institute,Cooperation2021,1975,1
Pontifical Biblical Institute,Religion2020,1975,3
Portland State University,Religion2020,1975,1
Postgraduate Institute of Medical Education & Research,Religion2020,1975,2
Presidential Counsel,Religion2020,1975,1
Princeton Theological Seminary,Religion2020,1975,31
Princeton University,Religion2020,1975,26
Providence College,Religion2020,1975,1
Purdue University,Cooperation2021,1975,1
Purdue University,Religion2020,1975,8
Queen’s University Belfast,Religion2020,1975,1
"Queens College, City University of New York",Religion2020,1975,3
Queen's University Belfast,Religion2020,1975,7
Quincy College,Religion2020,1975,5
Radboud University Nijmegen,Religion2020,1975,1
Radford College,Religion2020,1975,3
Refua Shlema Clinic,Cooperation2021,1975,1
Regent College,Religion2020,1975,3
Rhodes College,Religion2020,1975,2
Rice University,Cooperation2021,1975,1
Rice University,Religion2020,1975,7
Ritual Studied Internatinoal,Religion2020,1975,1
Rollins College,Religion2020,1975,1
Roma Tre University,Religion2020,1975,1
Roosevelt University,Cooperation2021,1975,2
Roosevelt University,Religion2020,1975,3
Royal Society,Cooperation2021,1975,2
Ruhr University Bochum,Religion2020,1975,1
Rush University,Cooperation2021,1975,1
Rush University,Religion2020,1975,1
Ruskin College Oxford,Religion2020,1975,3
Rutgers University,Cooperation2021,1975,4
Rutgers University,Religion2020,1975,19
Ryerson University,Religion2020,1975,1
Saint Ignatius Church,Religion2020,1975,1
Saint Louis University,Cooperation2021,1975,1
Saint Mary's College,Religion2020,1975,6
Saint Mary's University of Minnesota,Cooperation2021,1975,1
San Francisco Theological Seminary,Religion2020,1975,1
Santa Clara University,Religion2020,1975,2
Saybrook University,Cooperation2021,1975,4
School of Oriental and African Studies,Religion2020,1975,8
Scientific Integrity Institute,Religion2020,1975,1
Scripps College,Religion2020,1975,2
Seattle Pacific University,Religion2020,1975,2
Shukutoku University,Religion2020,1975,5
Simmons College,Religion2020,1975,1
Smith College,Religion2020,1975,15
Smithsonian Tropical Research Institute,Cooperation2021,1975,1
SOAS University of London,Religion2020,1975,6
Sofia University,Cooperation2021,1975,1
Sophia University,Religion2020,1975,2
Sorbonne University,Religion2020,1975,3
Southampton University,Religion2020,1975,1
Southern Illinois University,Cooperation2021,1975,1
Southern Illinois University,Religion2020,1975,1
Southern Methodist University,Religion2020,1975,10
Spalding University,Religion2020,1975,1
Spelman College,Religion2020,1975,1
St Andrews University,Religion2020,1975,4
St Botolph's Aldgate,Religion2020,1975,1
St. Bonaventure University,Religion2020,1975,4
"St. Edmund Hall, Oxford",Religion2020,1975,1
"St. Johns College, Cambridge",Cooperation2021,1975,1
St. Joseph's University,Religion2020,1975,6
St. Louis University,Religion2020,1975,6
St. Mary's Seminary and University,Religion2020,1975,5
"St. Mary's University, Twickenham",Religion2020,1975,2
St. Michael's College,Religion2020,1975,4
St. Olaf College,Religion2020,1975,1
St. Patrick's Seminary,Religion2020,1975,3
St. Stephen's Episcopal Church,Religion2020,1975,3
St. Thomas University,Religion2020,1975,1
Staffordshire University,Religion2020,1975,1
Stanford University,Cooperation2021,1975,5
Stanford University,Religion2020,1975,12
State University of New York,Religion2020,1975,2
State University of New York at Binghamton University,Religion2020,1975,2
State University of New York at Brockport,Religion2020,1975,1
State University of New York at Stony Brook,Religion2020,1975,3
State University of New York Downstate Medical Center,Cooperation2021,1975,2
Stockholm University,Cooperation2021,1975,1
Stockholm University,Religion2020,1975,6
Stony Brook University,Cooperation2021,1975,3
Stony Brook University,Religion2020,1975,2
Strong BRAIN Institute,Religion2020,1975,1
SUNY Brockport University,Cooperation2021,1975,1
SUNY Brockport University,Religion2020,1975,1
SUNY Oswego,Religion2020,1975,1
SUNY Upstate Medical University,Religion2020,1975,1
Surrey University,Religion2020,1975,3
Swansea University,Religion2020,1975,11
Swarthmore College,Religion2020,1975,3
Swiss Federal Institute of Technology Zurich,Religion2020,1975,1
Syracuse University,Cooperation2021,1975,3
Syracuse University,Religion2020,1975,4
Tehran Allameh Tabatabaee University,Religion2020,1975,1
Tel Aviv University,Cooperation2021,1975,1
Temple University,Cooperation2021,1975,1
Temple University,Religion2020,1975,10
Tennessee State University,Religion2020,1975,3
Texas A&M University,Religion2020,1975,8
Texas Christian University,Religion2020,1975,5
Texas State University,Religion2020,1975,4
The Catholic University of America,Religion2020,1975,4
The Coventry Association for Pastoral Psychology,Religion2020,1975,1
The Cullition Group,Cooperation2021,1975,8
The Hastings Center,Cooperation2021,1975,1
The Hastings Center,Religion2020,1975,1
"The King's Fund, London",Religion2020,1975,2
The New York Times,Cooperation2021,1975,7
The Open University,Cooperation2021,1975,1
The University of the South Sewanee,Religion2020,1975,6
Torrens University,Cooperation2021,1975,1
Transnational Family Research Institute,Cooperation2021,1975,2
Trinity College,Religion2020,1975,11
Trinity University,Religion2020,1975,2
Tufts University,Cooperation2021,1975,1
Tulane University,Religion2020,1975,2
UK Parliament,Religion2020,1975,1
Ulster University,Religion2020,1975,1
Union College,Cooperation2021,1975,1
Union College,Religion2020,1975,1
Union Presbyterian Seminary,Religion2020,1975,2
Union Theological College,Religion2020,1975,2
Union Theological Seminary,Religion2020,1975,12
United Nations University - International Institute of Global Health,Religion2020,1975,1
United Theological Seminary,Religion2020,1975,1
Universitat Wurzburg,Religion2020,1975,4
Universite Laval,Religion2020,1975,6
Universiteit Utrecht,Religion2020,1975,3
University College London,Religion2020,1975,6
University of Aberdeen,Cooperation2021,1975,1
University of Aberdeen,Religion2020,1975,23
University of Akron,Religion2020,1975,2
University of Alberta,Religion2020,1975,5
University of Allahabad,Cooperation2021,1975,2
University of Amsterdam,Religion2020,1975,2
University of Arizona,Cooperation2021,1975,9
University of Arizona,Religion2020,1975,16
University of Auckland,Religion2020,1975,5
University of Ballarat,Religion2020,1975,1
University of Basel,Cooperation2021,1975,1
University of Basel,Religion2020,1975,1
University of Bergen,Religion2020,1975,2
University of Bern,Religion2020,1975,2
University of Bielefeld,Cooperation2021,1975,1
University of Birmingham,Cooperation2021,1975,4
University of Birmingham,Religion2020,1975,19
University of Bonn,Cooperation2021,1975,1
University of Bonn,Religion2020,1975,3
University of Botswana,Religion2020,1975,1
University of Bristol,Cooperation2021,1975,1
University of Bristol,Religion2020,1975,3
University of British Columbia,Cooperation2021,1975,1
University of British Columbia,Religion2020,1975,15
University of Brunei Darussalam,Religion2020,1975,1
University of Burdwan,Religion2020,1975,1
University of Calcutta,Religion2020,1975,1
University of Calgary,Cooperation2021,1975,2
University of Calgary,Religion2020,1975,16
University of California - Berkeley,Religion2020,1975,15
University of California - Davis,Cooperation2021,1975,1
University of California - Irvine,Cooperation2021,1975,2
University of California - Irvine,Religion2020,1975,15
University of California - Los Angeles,Cooperation2021,1975,1
University of California - Los Angeles,Religion2020,1975,11
University of California - Riverside,Cooperation2021,1975,2
University of California - Riverside,Religion2020,1975,12
University of California - San Diego,Cooperation2021,1975,1
University of California - San Diego,Religion2020,1975,3
University of California - San Francisco,Religion2020,1975,8
University of California - Santa Barbara,Religion2020,1975,19
"University of California, Berkeley",Cooperation2021,1975,1
"University of California, Berkeley",Religion2020,1975,15
"University of California, Davis",Cooperation2021,1975,2
"University of California, Davis",Religion2020,1975,2
"University of California, Irvine",Cooperation2021,1975,1
"University of California, Irvine",Religion2020,1975,10
"University of California, Los Angeles",Cooperation2021,1975,2
"University of California, Los Angeles",Religion2020,1975,5
"University of California, Riverside",Cooperation2021,1975,2
"University of California, Riverside",Religion2020,1975,3
"University of California, San Diego",Cooperation2021,1975,2
"University of California, San Diego",Religion2020,1975,13
"University of California, Santa Barbara",Cooperation2021,1975,2
"University of California, Santa Barbara",Religion2020,1975,15
"University of California, Santa Cruz",Religion2020,1975,1
University of Cambridge,Cooperation2021,1975,8
University of Cambridge,Religion2020,1975,30
University of Cape Town,Religion2020,1975,1
University of Central Arkansas,Religion2020,1975,4
University of Chicago,Cooperation2021,1975,3
University of Chicago,Religion2020,1975,54
University of Chicago Divinity School,Religion2020,1975,12
University of Cincinnati,Religion2020,1975,4
University of Colorado at Boulder,Religion2020,1975,1
University of Colorado Boulder,Religion2020,1975,8
"University of Colorado, Boulder",Cooperation2021,1975,1
University of Connecticut,Cooperation2021,1975,4
University of Connecticut,Religion2020,1975,10
University of Copenhagen,Religion2020,1975,1
University of Delaware,Cooperation2021,1975,1
University of Delaware,Religion2020,1975,3
University of Denver,Religion2020,1975,7
University of Detroit Mercy,Religion2020,1975,7
University of Dundee,Religion2020,1975,2
University of Durham,Religion2020,1975,6
University of East Anglia,Religion2020,1975,2
University of East London,Religion2020,1975,1
University of Edinburgh,Cooperation2021,1975,2
University of Edinburgh,Religion2020,1975,16
University of Erfurt,Cooperation2021,1975,1
University of Essex,Religion2020,1975,3
University of Exeter,Cooperation2021,1975,1
University of Exeter,Religion2020,1975,14
University of Florida,Cooperation2021,1975,4
University of Florida,Religion2020,1975,6
University of Geneva,Religion2020,1975,4
University of Georgia,Religion2020,1975,3
University of Ghana,Religion2020,1975,2
University of Glasgow,Religion2020,1975,8
University of Gothenburg,Cooperation2021,1975,1
University of Graz,Religion2020,1975,1
University of Guelph,Cooperation2021,1975,1
University of Guelph,Religion2020,1975,8
University of Haifa,Religion2020,1975,13
University of Hamburg,Cooperation2021,1975,1
University of Hamburg,Religion2020,1975,5
University of Hannover,Religion2020,1975,1
University of Helsinki,Religion2020,1975,2
University of Houston,Religion2020,1975,5
University of Hull,Religion2020,1975,6
University of Illinois,Cooperation2021,1975,2
University of Illinois,Religion2020,1975,4
University of Illinois at Urbana-Champaign,Cooperation2021,1975,6
University of Illinois at Urbana-Champaign,Religion2020,1975,6
University of Iowa,Religion2020,1975,18
University of Johannesburg,Religion2020,1975,2
University of Kansas,Religion2020,1975,16
University of Kent,Religion2020,1975,5
University of Koln,Religion2020,1975,1
University of Konstanz,Religion2020,1975,1
University of Lancaster,Religion2020,1975,5
University of Lausanne,Religion2020,1975,8
University of Leeds,Cooperation2021,1975,1
University of Leeds,Religion2020,1975,2
University of Leicester,Religion2020,1975,1
University of Lethbridge,Religion2020,1975,3
University of Lille III,Religion2020,1975,2
University of Liverpool,Religion2020,1975,2
University of London,Cooperation2021,1975,4
University of London,Religion2020,1975,23
University of Louisville,Religion2020,1975,1
University of Maine,Religion2020,1975,1
University of Malawi,Religion2020,1975,1
University of Manchester,Cooperation2021,1975,1
University of Manchester,Religion2020,1975,9
University of Manitoba,Cooperation2021,1975,1
University of Manitoba,Religion2020,1975,8
University of Marburg,Religion2020,1975,8
University of Maryland,Cooperation2021,1975,5
University of Maryland,Religion2020,1975,4
"University of Maryland, College Park",Cooperation2021,1975,1
"University of Maryland, College Park",Religion2020,1975,5
University of Massachusetts - Amherst,Cooperation2021,1975,1
University of Massachusetts - Amherst,Religion2020,1975,15
University of Massachusetts - Boston,Religion2020,1975,7
University of Massachusetts Memorial Medical Center,Religion2020,1975,6
University of Melbourne,Religion2020,1975,1
University of Memphis,Religion2020,1975,3
University of Miami,Religion2020,1975,3
University of Michigan,Cooperation2021,1975,4
University of Michigan,Religion2020,1975,9
University of Minnesota,Cooperation2021,1975,4
University of Minnesota,Religion2020,1975,8
University of Missouri,Cooperation2021,1975,2
University of Missouri,Religion2020,1975,1
University of Montana,Religion2020,1975,5
University of Montreal,Religion2020,1975,5
University of Munich,Cooperation2021,1975,4
University of Nebraska,Religion2020,1975,1
University of Nebraska-Lincoln,Cooperation2021,1975,1
University of Nevada - Reno,Cooperation2021,1975,1
University of Nevada - Reno,Religion2020,1975,8
University of New England,Religion2020,1975,1
University of New Mexico,Cooperation2021,1975,3
University of New South Wales,Cooperation2021,1975,1
University of Nigeria,Cooperation2021,1975,1
University of Nigeria,Religion2020,1975,3
University of Nijmegen,Religion2020,1975,2
University of North Carolina,Cooperation2021,1975,1
University of North Carolina - Chapel Hill,Religion2020,1975,9
University of North Carolina at Chapel Hill,Cooperation2021,1975,2
University of North Carolina at Chapel Hill,Religion2020,1975,14
University of North Carolina at Charlotte,Religion2020,1975,1
University of North Carolina at Greensboro,Cooperation2021,1975,1
University of North Carolina at Greensboro,Religion2020,1975,1
University of North Texas,Religion2020,1975,3
University of North Texas HSC,Religion2020,1975,1
University of Notre Dame,Religion2020,1975,32
University of Nottingham,Religion2020,1975,5
University of Oklahoma,Religion2020,1975,1
University of Oklahoma Health Sciences Center,Religion2020,1975,1
University of Oregon,Cooperation2021,1975,3
University of Oregon,Religion2020,1975,4
University of Oslo,Cooperation2021,1975,2
University of Oslo,Religion2020,1975,2
University of Otago,Religion2020,1975,5
University of Ottawa,Cooperation2021,1975,1
University of Ottawa,Religion2020,1975,2
University of Oxford,Cooperation2021,1975,2
University of Oxford,Religion2020,1975,35
University of Oxford Mindfulness Research Centre,Religion2020,1975,3
University of Paderborn,Religion2020,1975,1
University of Padua,Religion2020,1975,2
University of Paris,Religion2020,1975,1
University of Pennsylvania,Cooperation2021,1975,1
University of Pennsylvania,Religion2020,1975,17
University of Pittsburgh,Cooperation2021,1975,7
University of Pittsburgh,Religion2020,1975,2
University of Port Harcourt,Religion2020,1975,1
University of Portsmouth,Religion2020,1975,2
University of Québec at Montreal,Religion2020,1975,1
University of Quebec at Trois-Rivières,Religion2020,1975,1
University of Quebec in Chicoutimi,Religion2020,1975,1
University of Queensland,Religion2020,1975,2
University of Reading,Religion2020,1975,10
University of Rhode Island,Religion2020,1975,3
University of Richmond,Religion2020,1975,2
University of Rochester,Cooperation2021,1975,1
University of Rochester,Religion2020,1975,7
University of Roehampton,Religion2020,1975,1
University of Salzburg,Religion2020,1975,1
University of San Diego,Religion2020,1975,4
University of Shanghai,Religion2020,1975,1
University of Sheffield,Religion2020,1975,1
University of South Australia,Religion2020,1975,3
University of South Carolina,Religion2020,1975,1
University of South Florida,Religion2020,1975,3
University of Southern California,Cooperation2021,1975,5
University of Southern California,Religion2020,1975,16
University of St Andrews,Religion2020,1975,12
University of Stirling,Religion2020,1975,14
University of Strathclyde,Cooperation2021,1975,1
University of Sussex,Religion2020,1975,7
University of Sydney,Cooperation2021,1975,1
University of Sydney,Religion2020,1975,13
University of Tartu,Religion2020,1975,1
University of Tennessee,Religion2020,1975,6
University of Tennessee at Chattanooga,Religion2020,1975,5
University of Texas at Arlington,Religion2020,1975,2
University of Texas at Austin,Religion2020,1975,1
University of Texas at Dallas,Cooperation2021,1975,1
University of Texas at Dallas,Religion2020,1975,1
University of Texas at Houston,Religion2020,1975,1
University of Texas Medical Branch at Galveston,Cooperation2021,1975,1
University of Texas Medical Branch at Galveston,Religion2020,1975,1
University of Texas School of Public Health,Religion2020,1975,2
University of the South,Religion2020,1975,2
University of Tokyo,Religion2020,1975,3
University of Toronto,Cooperation2021,1975,6
University of Toronto,Religion2020,1975,16
University of Tübingen,Religion2020,1975,5
University of Turin,Religion2020,1975,1
University of Utah,Religion2020,1975,10
University of Vermont,Religion2020,1975,3
University of Victoria,Cooperation2021,1975,1
University of Victoria,Religion2020,1975,4
University of Vienna,Religion2020,1975,12
University of Virginia,Cooperation2021,1975,2
University of Virginia,Religion2020,1975,26
University of Wales,Religion2020,1975,2
University of Wales at Lampeter,Religion2020,1975,3
University of Wales Trinity Saint David,Religion2020,1975,2
University of Warwick,Religion2020,1975,9
University of Washington,Cooperation2021,1975,6
University of Washington,Religion2020,1975,10
"University of Washington, Seattle",Religion2020,1975,2
University of Waterloo,Cooperation2021,1975,1
University of Waterloo,Religion2020,1975,9
University of West England,Religion2020,1975,1
University of Western Australia,Religion2020,1975,3
University of Western Ontario,Cooperation2021,1975,5
University of Westminster,Religion2020,1975,1
University of Windsor,Religion2020,1975,6
University of Winnipeg,Religion2020,1975,2
University of Wisconsin,Religion2020,1975,2
University of Wisconsin - Madison,Cooperation2021,1975,1
University of Wisconsin - Madison,Religion2020,1975,6
University of Wisconsin - Milwaukee,Religion2020,1975,3
University of Wisconsin-La Crosse,Religion2020,1975,2
University of Wisconsin-Madison,Cooperation2021,1975,3
University of Wisconsin-Madison,Religion2020,1975,4
University of Wolverhampton,Cooperation2021,1975,1
University of Wyoming,Religion2020,1975,3
University of York,Cooperation2021,1975,2
University of York,Religion2020,1975,11
University of Zimbabwe,Religion2020,1975,4
University of Zurich,Religion2020,1975,7
Uppsala University,Cooperation2021,1975,3
Uppsala University,Religion2020,1975,8
Utah State University,Cooperation2021,1975,1
Utah State University,Religion2020,1975,1
Valparaiso University,Cooperation2021,1975,1
Valparaiso University,Religion2020,1975,1
Vanderbilt Divinity School,Religion2020,1975,3
Vanderbilt University,Religion2020,1975,7
Vermont College of Norwich University,Religion2020,1975,3
"Veterans Affairs Center of Excellence for Stress and Mental Health, San Diego",Cooperation2021,1975,1
Victoria University of Wellington,Religion2020,1975,4
Villanova University,Religion2020,1975,5
Virginia Commonwealth University,Religion2020,1975,1
Virginia Polytechnic Institute and State University,Religion2020,1975,2
Wake Forest Baptist Health,Religion2020,1975,1
Wake Forest University,Religion2020,1975,6
Wartburg Theological Seminary,Religion2020,1975,1
Washington and Lee University,Religion2020,1975,5
Washington College,Religion2020,1975,2
Washington State University,Cooperation2021,1975,1
Washington State University,Religion2020,1975,2
Washington University in St Louis,Religion2020,1975,4
Wayne State University,Cooperation2021,1975,2
Webster University,Religion2020,1975,3
Wellesley College,Religion2020,1975,11
Wesleyan University,Religion2020,1975,1
West Suffolk Hospital,Religion2020,1975,1
West Virginia University,Cooperation2021,1975,1
West Virginia University,Religion2020,1975,2
Western Michigan University,Religion2020,1975,5
Western Sydney University,Religion2020,1975,1
Western University,Cooperation2021,1975,1
Westfield College,Religion2020,1975,1
Westminster Choir College,Religion2020,1975,3
Westminster College,Religion2020,1975,1
Westmont College,Cooperation2021,1975,1
Westmont College,Religion2020,1975,1
Wheaton College,Religion2020,1975,1
Wilfrid Laurier University,Religion2020,1975,5
William & Mary,Religion2020,1975,1
Williams College,Religion2020,1975,1
Wolfson College,Religion2020,1975,1
Wright State University,Religion2020,1975,1
Xavier University,Religion2020,1975,1
Yale Divinity School,Religion2020,1975,2
Yale University,Cooperation2021,1975,8
Yale University,Religion2020,1975,28
Yeshiva University,Cooperation2021,1975,1
Yeshiva University,Religion2020,1975,2
Yonsei University,Religion2020,1975,3
York University,Religion2020,1975,3
Åbo Akademi University,Religion2020,1970,1
Adelphi University,Religion2020,1970,1
Agnes Scott College,Religion2020,1970,6
Albert Einstein College of Medicine,Religion2020,1970,1
Alfred University,Religion2020,1970,1
Allegheny University of the Health Sciences,Cooperation2021,1970,3
Alliant International University,Cooperation2021,1970,19
Alliant International University,Religion2020,1970,2
American Bible Society,Religion2020,1970,1
American Council on Education,Religion2020,1970,1
American Health Foundation,Religion2020,1970,3
American Sociological Association,Religion2020,1970,1
American University,Religion2020,1970,9
American University of Beirut,Religion2020,1970,1
Amherst College,Religion2020,1970,12
Andover Newton Theological School,Religion2020,1970,1
Arizona State University,Cooperation2021,1970,1
Arizona State University,Religion2020,1970,3
Association of Improvement for Mental Health Programmes,Cooperation2021,1970,1
Auburn University,Religion2020,1970,1
Augusta University,Religion2020,1970,2
Australian Catholic University,Religion2020,1970,4
Australian National University,Cooperation2021,1970,2
Australian National University,Religion2020,1970,3
Ball State University,Religion2020,1970,1
Bangor University,Religion2020,1970,1
Baptist Seminary of Kentucky,Religion2020,1970,1
Bard College,Religion2020,1970,3
Bar-Ilan University,Religion2020,1970,3
Barnard College,Cooperation2021,1970,4
Barrington College,Religion2020,1970,5
Baylor University,Religion2020,1970,17
Beckman Initiative for Macular Research,Cooperation2021,1970,2
Birbeck University of London,Religion2020,1970,1
Birmingham Children's Hospital NHS Foundation Trust,Religion2020,1970,1
Bishop of Guilford,Religion2020,1970,1
Boston College,Cooperation2021,1970,1
Boston College,Religion2020,1970,5
Boston University,Cooperation2021,1970,6
Boston University,Religion2020,1970,6
Boston University School of Medicine,Religion2020,1970,3
Bowling Green State University,Religion2020,1970,1
Brandeis University,Religion2020,1970,2
Brigham Young University,Cooperation2021,1970,1
Brigham Young University,Religion2020,1970,3
British Army,Cooperation2021,1970,1
British Army,Religion2020,1970,2
Brooklyn College,Cooperation2021,1970,1
Brown University,Cooperation2021,1970,1
Brown University,Religion2020,1970,3
California State University,Religion2020,1970,2
"California State University, Northridge",Religion2020,1970,1
Calvin College,Religion2020,1970,2
Calvin University,Religion2020,1970,2
Cambridge University,Religion2020,1970,1
Cardiff University,Religion2020,1970,12
Carleton University,Religion2020,1970,1
Case Western Reserve University,Religion2020,1970,2
Catholic Theological Union,Religion2020,1970,1
Catholic University of America,Religion2020,1970,21
Catholic University of Leuven,Religion2020,1970,2
Center for Ethics and Toxics,Cooperation2021,1970,3
Center for Psychobiology of Personality,Religion2020,1970,1
Center for the Study on New Religions,Religion2020,1970,1
Centre for Addiction and Mental Health,Cooperation2021,1970,4
Centre National de la Recherche Scientifique,Religion2020,1970,9
Centre Tricontinental (CETRI),Religion2020,1970,4
Chatham University,Religion2020,1970,2
Chicago Medical School,Religion2020,1970,3
Chicago Theological Seminary,Religion2020,1970,7
Chinese University of Hong Kong,Religion2020,1970,1
Christian Theological Seminary,Religion2020,1970,1
Christ's College,Religion2020,1970,1
Church Divinity School of the Pacific,Religion2020,1970,4
Church of England,Cooperation2021,1970,1
Church of England,Religion2020,1970,3
City University of New York,Cooperation2021,1970,2
Claremont Graduate University,Cooperation2021,1970,5
Claremont Graduate University,Religion2020,1970,13
Club of Budapest,Cooperation2021,1970,1
Colgate University,Religion2020,1970,2
College of France,Religion2020,1970,2
College of Marin,Cooperation2021,1970,3
College of St. Rose,Religion2020,1970,5
Colombiere Center,Religion2020,1970,2
Colorado State University,Religion2020,1970,5
Columbia University,Cooperation2021,1970,11
Columbia University,Religion2020,1970,11
Concordia University,Religion2020,1970,15
Connecticut College,Religion2020,1970,3
Cornell University,Cooperation2021,1970,1
Cornell University,Religion2020,1970,4
Creighton University,Cooperation2021,1970,1
Crozer Theological Seminary,Religion2020,1970,3
CUNY,Religion2020,1970,5
"Cuyamungue Institute, New Mexico",Religion2020,1970,2
Dalhousie University,Cooperation2021,1970,1
Dartmouth College,Religion2020,1970,2
David and Carol Myers Foundation,Cooperation2021,1970,1
Deakin University,Cooperation2021,1970,2
Delaware Law School,Cooperation2021,1970,1
Delaware Law School,Religion2020,1970,4
Dongbei University of Finance and Economics,Religion2020,1970,5
Downside Abbey,Religion2020,1970,3
Dropsie College,Religion2020,1970,2
Duke Divinity School,Cooperation2021,1970,1
Duke Divinity School,Religion2020,1970,7
Duke University,Cooperation2021,1970,2
Duke University,Religion2020,1970,20
Durham University,Religion2020,1970,7
East African Institute of Social Research,Religion2020,1970,4
Eastern Baptist Theological Seminary,Religion2020,1970,5
Eastern Mennonite University,Religion2020,1970,3
Eastern Nebraska Office of Retardation,Religion2020,1970,1
Eastern University,Religion2020,1970,5
Eckerd College,Religion2020,1970,5
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1970,2
Ecole Pratique des Hautes Etudes,Religion2020,1970,1
École Pratique des Hautes Etudes,Religion2020,1970,1
Edith Cowan University,Religion2020,1970,2
Emmanuel College,Religion2020,1970,2
Emory University,Cooperation2021,1970,14
Emory University,Religion2020,1970,11
Eurasian National University,Cooperation2021,1970,2
Eurasian National University,Religion2020,1970,1
Fairfield University,Cooperation2021,1970,5
Fakultas Dakwah IAIN Purwokerto,Religion2020,1970,2
Flinders University,Cooperation2021,1970,1
Flinders University,Religion2020,1970,1
Florida State University,Cooperation2021,1970,3
Florida State University,Religion2020,1970,8
Fordham University,Religion2020,1970,7
Fuller Theological Seminary,Religion2020,1970,22
Garrett Theological Seminary,Religion2020,1970,1
Garrett-Evangelical Theological Seminary,Religion2020,1970,1
George Washington University,Cooperation2021,1970,4
George Washington University,Religion2020,1970,3
Georgetown College,Religion2020,1970,2
Georgetown University,Cooperation2021,1970,4
Georgetown University,Religion2020,1970,19
Golden Gate Baptist Theological Seminary,Religion2020,1970,5
"Goldsmiths, University of London",Religion2020,1970,1
Goucher College,Religion2020,1970,4
Grace,Religion2020,1970,1
Graduate Theological Foundation,Religion2020,1970,2
Graduate Theological Union,Religion2020,1970,1
Greater Omaha Arc,Religion2020,1970,1
Gresham College,Religion2020,1970,2
Guttmacher Institute,Cooperation2021,1970,3
Guttmacher Institute,Religion2020,1970,1
Haight-Ashbury Free Clinic (HAFC),Religion2020,1970,1
Hartford Institute for Religion Research,Religion2020,1970,1
Hartford Seminary,Religion2020,1970,1
Harvard Business School,Religion2020,1970,4
Harvard Divinity School,Religion2020,1970,7
Harvard Medical School,Cooperation2021,1970,5
Harvard Medical School,Religion2020,1970,6
Harvard School of Public Health,Cooperation2021,1970,2
Harvard University,Cooperation2021,1970,10
Harvard University,Religion2020,1970,17
Hebrew Union College,Religion2020,1970,5
Hebrew University of Jerusalem,Religion2020,1970,5
Heriot-Watt University,Religion2020,1970,1
Heythrop College,Religion2020,1970,6
Highland Theological College,Religion2020,1970,2
Hofstra University,Religion2020,1970,2
Holy Ghost College,Religion2020,1970,1
Hope College,Cooperation2021,1970,1
Hope College,Religion2020,1970,1
"Hunter College, CUNY",Religion2020,1970,2
"Huntington School, York",Religion2020,1970,1
IAIN Pongoro,Religion2020,1970,1
IAIN Purwokerto,Religion2020,1970,3
Ibadan University,Religion2020,1970,1
Iliff School of Theology,Religion2020,1970,2
Indian Council of Social Science Research,Religion2020,1970,1
Indiana University,Religion2020,1970,8
Indiana University South Bend,Religion2020,1970,2
Institut Agama Islam Negeri Purwokerto,Religion2020,1970,6
Institute for Advanced Study,Cooperation2021,1970,1
Institute for Advanced Study,Religion2020,1970,2
Institute for the Study of Complex Systems,Cooperation2021,1970,1
Institute of Buddhist Studies,Religion2020,1970,4
Institute of Psychiatry,Cooperation2021,1970,2
International Islamic University Malaysia,Religion2020,1970,1
Jesus College,Cooperation2021,1970,1
Jewish Theological Seminary,Religion2020,1970,1
John Hopkins School of Medicine,Cooperation2021,1970,2
John Hopkins School of Medicine,Religion2020,1970,1
John Hopkins University,Religion2020,1970,2
Johns Hopkins University,Cooperation2021,1970,3
Johns Hopkins University,Religion2020,1970,4
Joint Centre of Excellence for Research on Immigration & Settlement,Cooperation2021,1970,2
Journal of Preventative Medicine,Religion2020,1970,2
Kalamazoo College,Religion2020,1970,3
Kansas Neurological Institute,Religion2020,1970,1
Kent State University,Cooperation2021,1970,1
Keston College,Religion2020,1970,2
Keston Institute,Religion2020,1970,1
Kings College,Religion2020,1970,1
King's College,Religion2020,1970,6
King's College London,Cooperation2021,1970,2
King's College London,Religion2020,1970,31
Kipalapala Senior Seminary,Religion2020,1970,2
Kobe Gakuin University,Cooperation2021,1970,1
KU Leuven,Religion2020,1970,1
Kyushu University,Religion2020,1970,1
La Salle University,Religion2020,1970,2
La Sapienza University,Religion2020,1970,1
La Trobe University,Cooperation2021,1970,1
La Trobe University,Religion2020,1970,2
Lake Forest College,Cooperation2021,1970,1
Lancaster University,Religion2020,1970,8
Laval University,Religion2020,1970,1
Lehigh University,Cooperation2021,1970,1
Leibniz University of Hanover,Religion2020,1970,1
Leicester Cathedral,Religion2020,1970,2
Liberty University,Religion2020,1970,8
Liverpool Hope University,Religion2020,1970,2
Loma Linda University,Cooperation2021,1970,2
Loma Linda University,Religion2020,1970,1
London School of Economics,Cooperation2021,1970,5
London School of Economics,Religion2020,1970,13
London University,Religion2020,1970,2
Los Angeles Valley College,Religion2020,1970,1
Loughborough University,Cooperation2021,1970,6
Louisburg College,Religion2020,1970,2
Louisiana State University,Religion2020,1970,1
Louisville Presbyterian Theological Seminary,Religion2020,1970,1
Lousiana State University,Religion2020,1970,1
Loyola University Chicago,Religion2020,1970,2
Loyola University Maryland,Cooperation2021,1970,1
Loyola University New Orleans,Religion2020,1970,3
Lund University,Cooperation2021,1970,1
Lutheran School of theology at Chicago,Religion2020,1970,2
Lutheran Theological Southern Seminary,Religion2020,1970,2
Maastricht University,Cooperation2021,1970,1
Macquarie University,Religion2020,1970,1
Maharishi University of Management,Religion2020,1970,3
Makerere University,Religion2020,1970,4
Malmö General Hospital,Cooperation2021,1970,1
Marquette University,Religion2020,1970,2
Massachusetts Institute of Technology,Cooperation2021,1970,1
Massachusetts Institute of Technology,Religion2020,1970,1
Massey University,Religion2020,1970,1
McGill University,Religion2020,1970,5
McMaster Divinity School,Religion2020,1970,1
McMaster University,Religion2020,1970,23
Meadville Lombard Theological School,Religion2020,1970,2
Menninger Clinic,Religion2020,1970,2
Miami University,Religion2020,1970,2
Missouri University of Science and Technology,Religion2020,1970,5
Monash University,Religion2020,1970,6
Mount Mary University,Religion2020,1970,2
Mt. Sinai School of Medicine,Religion2020,1970,7
MTs Babussalam Pagelaran Malang,Religion2020,1970,2
Murdoch University,Religion2020,1970,2
National Academies Keck Futures Initiative,Cooperation2021,1970,2
National Council of Churches,Cooperation2021,1970,1
National Council of Churches,CooperationAndReligion,1970,1
National Council of Churches,Religion2020,1970,2
National Institute of Child Health & Human Development,Cooperation2021,1970,5
National Institute of Mental Health,Cooperation2021,1970,1
National Institutes of Health,Cooperation2021,1970,5
National Research University Higher School of Economics,Cooperation2021,1970,1
National University of Ireland,Religion2020,1970,1
New London Synagogue,Religion2020,1970,2
New Orleans Baptist Theological Seminary,Religion2020,1970,1
New School for Social Research,Cooperation2021,1970,1
New York University,Cooperation2021,1970,7
New York University,Religion2020,1970,13
Newcastle University,Religion2020,1970,3
Northern Kentucky University,Religion2020,1970,1
Northwestern University,Cooperation2021,1970,1
Northwestern University,Religion2020,1970,2
Nova Southeastern University,Cooperation2021,1970,4
Oakland University,Religion2020,1970,1
Oberlin College,Religion2020,1970,12
Ohio State University,Cooperation2021,1970,1
Ohio State University,Religion2020,1970,4
Ohio University,Cooperation2021,1970,12
Oklahoma State University,Religion2020,1970,1
Old Dominion University,Religion2020,1970,7
Ono Academic College,Religion2020,1970,1
Oregon State University,Cooperation2021,1970,2
Oregon State University,Religion2020,1970,1
Oslo Metropolitan University,Cooperation2021,1970,1
Oslo Metropolitan University,CooperationAndReligion,1970,1
Oslo Metropolitan University,Religion2020,1970,4
Oxford University,Religion2020,1970,11
Pacific Lutheran University,Religion2020,1970,1
Pacific School of Religion,Religion2020,1970,3
Padjadjaran University,Religion2020,1970,8
Pennsylvania State University,Religion2020,1970,15
Perkins School of Theology,Religion2020,1970,1
Phillipps Universitat Marburg,Religion2020,1970,1
Pima Community College,Religion2020,1970,5
Pomona College,Religion2020,1970,7
Pontifical Gregorian University,Religion2020,1970,1
Pontifical University at Maynooth,Religion2020,1970,4
Press Ganey Associates,Cooperation2021,1970,1
Princeton Theological Seminary,Cooperation2021,1970,2
Princeton Theological Seminary,Religion2020,1970,31
Princeton University,Religion2020,1970,22
Private Practice,Cooperation2021,1970,2
Providence College,Religion2020,1970,1
Purdue University,Religion2020,1970,6
"Queens College, City University of New York",Religion2020,1970,1
Queen's University Belfast,Religion2020,1970,1
Quincy College,Religion2020,1970,1
Radford College,Religion2020,1970,1
Reformed Theological Seminary,Religion2020,1970,1
Regent College,Cooperation2021,1970,2
Religious Studies,Religion2020,1970,1
Rensselaer Polytechnic Institute,Religion2020,1970,1
Rhodes College,Religion2020,1970,5
Rhodes University,Religion2020,1970,1
Rice University,Religion2020,1970,6
Ritual Studied Internatinoal,Religion2020,1970,1
Rockford University,Religion2020,1970,2
Rutgers University,Cooperation2021,1970,3
Rutgers University,Religion2020,1970,2
Saint Ignatius Church,Religion2020,1970,1
Saint Louis University,Religion2020,1970,2
Saint Mary's College,Religion2020,1970,4
Saint Mary's University of Minnesota,Cooperation2021,1970,1
San Francisco Theological Seminary,Religion2020,1970,1
Sansone Family Center for Well-Being,Religion2020,1970,1
Santa Clara University,Cooperation2021,1970,2
Santa Clara University,Religion2020,1970,1
Saybrook University,Cooperation2021,1970,2
SCIENCE magazine,Cooperation2021,1970,1
Scripps College,Religion2020,1970,5
Seattle Pacific University,Religion2020,1970,5
Sebelas Maret University,Religion2020,1970,2
Shukutoku University,Religion2020,1970,3
Simon Fraser University,Cooperation2021,1970,1
Simon Fraser University,Religion2020,1970,2
Smith College,Religion2020,1970,2
SOAS University of London,Cooperation2021,1970,2
SOAS University of London,Religion2020,1970,10
Sofia University,Cooperation2021,1970,2
Sofia University,Religion2020,1970,1
Sorbonne University,Religion2020,1970,4
Southampton University,Religion2020,1970,1
Southern Illinois University,Religion2020,1970,4
Southern Methodist University,Religion2020,1970,14
Spelman College,Religion2020,1970,1
St Andrews University,Religion2020,1970,2
St Botolph's Aldgate,Religion2020,1970,1
St Mary's University Church,Religion2020,1970,2
St. Francis Hospice,Cooperation2021,1970,1
St. John's University,Religion2020,1970,2
St. Louis University,Religion2020,1970,7
St. Mary's Seminary and University,Religion2020,1970,2
St. Michael's College,Religion2020,1970,1
St. Olaf College,Religion2020,1970,1
STAIN Purwokerto,Religion2020,1970,2
Stanford University,Cooperation2021,1970,2
Stanford University,Religion2020,1970,19
State University of New York at Albany,Cooperation2021,1970,1
State University of New York at Binghamton University,Religion2020,1970,2
State University of New York at Brockport,Religion2020,1970,4
State University of New York at Oneonta,Cooperation2021,1970,1
Stetson University,Religion2020,1970,5
Stockholm University,Cooperation2021,1970,2
Stockholm University,CooperationAndReligion,1970,1
Stockholm University,Religion2020,1970,9
Stony Brook University,Cooperation2021,1970,1
Stony Brook University,Religion2020,1970,2
Strong BRAIN Institute,Cooperation2021,1970,1
Strong BRAIN Institute,Religion2020,1970,1
Sunan Kalijaga State Islamic University,Religion2020,1970,6
SUNY Brockport University,Cooperation2021,1970,4
SUNY Brockport University,Religion2020,1970,3
SUNY Oswego,Cooperation2021,1970,1
SUNY Oswego,Religion2020,1970,4
SUNY Upstate Medical University,Cooperation2021,1970,1
Surrey University,Religion2020,1970,3
Swansea University,Religion2020,1970,2
Swarthmore College,Religion2020,1970,7
Syiah Kuala University,Religion2020,1970,1
Syracuse University,Cooperation2021,1970,1
Syracuse University,Religion2020,1970,5
Taos Institute,Religion2020,1970,1
Tel Aviv University,Cooperation2021,1970,1
Temple Buell College,Religion2020,1970,5
Temple University,Cooperation2021,1970,2
Temple University,Religion2020,1970,5
Texas A&M University,Religion2020,1970,7
Texas Christian University,Religion2020,1970,2
Texas State University,Cooperation2021,1970,1
The American University in Cairo,Religion2020,1970,1
The Arctic University of Norway,Religion2020,1970,2
The Catholic University of America,Religion2020,1970,1
The Cullition Group,Cooperation2021,1970,2
The Hastings Center,Cooperation2021,1970,5
The Hastings Center,Religion2020,1970,1
The New School,Religion2020,1970,1
The New York Times,Cooperation2021,1970,1
The University of Rochester School of Medicine,Religion2020,1970,3
The University of the South Sewanee,Religion2020,1970,2
Trinity College,Religion2020,1970,6
Trinity Presbytarian Church,Religion2020,1970,8
Tufts University,Religion2020,1970,3
Tulane University,Religion2020,1970,1
Tyndale University,Religion2020,1970,1
U.S. Department of Health and Human Services,Religion2020,1970,1
Ulster University,Religion2020,1970,1
Union Presbyterian Seminary,Religion2020,1970,1
Union Theological College,Religion2020,1970,1
Union Theological Seminary,Religion2020,1970,18
United Theological College,Religion2020,1970,1
United Theological Seminary,Religion2020,1970,1
Universitas Gadjah Mada,Religion2020,1970,1
Universitas Islam Negeri Sunan Ampel,Religion2020,1970,1
Universitas Islam Negeri Sunan Kalijaga Yogyakarta,Religion2020,1970,1
universitas muhammadiyah yogyakarta,Religion2020,1970,1
Universitas Padjadjaran,Religion2020,1970,2
Universite Laval,Religion2020,1970,5
Universiteit Utrecht,Religion2020,1970,6
Universitetet Agder,Religion2020,1970,1
University College London,Cooperation2021,1970,1
University College London,Religion2020,1970,2
University of Aberdeen,Cooperation2021,1970,2
University of Aberdeen,Religion2020,1970,15
University of Alabama,Religion2020,1970,3
University of Alberta,Cooperation2021,1970,1
University of Alberta,Religion2020,1970,3
University of Amsterdam,Religion2020,1970,2
University of Arizona,Cooperation2021,1970,4
University of Arizona,Religion2020,1970,21
University of Ballarat,Religion2020,1970,1
University of Basel,Cooperation2021,1970,1
University of Bergen,Religion2020,1970,1
University of Berlin,Religion2020,1970,1
University of Bern,Religion2020,1970,7
University of Bielefeld,Cooperation2021,1970,2
University of Birmingham,Cooperation2021,1970,1
University of Birmingham,Religion2020,1970,10
University of Bonn,Religion2020,1970,2
University of Bridgeport,Religion2020,1970,1
University of British Columbia,Cooperation2021,1970,7
University of British Columbia,Religion2020,1970,5
University of Brunei Darussalam,Religion2020,1970,2
University of Buffalo,Religion2020,1970,2
University of Calgary,Religion2020,1970,22
University of California - Berkeley,Cooperation2021,1970,1
University of California - Berkeley,Religion2020,1970,24
University of California - Davis,Cooperation2021,1970,1
University of California - Davis,Religion2020,1970,5
University of California - Irvine,Cooperation2021,1970,2
University of California - Irvine,Religion2020,1970,1
University of California - Los Angeles,Religion2020,1970,14
University of California - Riverside,Religion2020,1970,5
University of California - San Francisco,Cooperation2021,1970,2
University of California - San Francisco,Religion2020,1970,4
University of California - Santa Barbara,Religion2020,1970,34
"University of California, Berkeley",Religion2020,1970,7
"University of California, Davis",Cooperation2021,1970,6
"University of California, Irvine",Cooperation2021,1970,4
"University of California, Irvine",Religion2020,1970,3
"University of California, Los Angeles",Cooperation2021,1970,2
"University of California, Los Angeles",Religion2020,1970,10
"University of California, Riverside",Cooperation2021,1970,1
"University of California, Riverside",Religion2020,1970,4
"University of California, San Diego",Cooperation2021,1970,1
"University of California, Santa Barbara",Cooperation2021,1970,2
"University of California, Santa Barbara",Religion2020,1970,7
"University of California, Santa Cruz",Religion2020,1970,2
University of Cambridge,Cooperation2021,1970,1
University of Cambridge,Religion2020,1970,35
University of Cape Town,Religion2020,1970,6
University of Chicago,Cooperation2021,1970,1
University of Chicago,Religion2020,1970,60
University of Chicago Divinity School,Religion2020,1970,5
University of Cincinnati,Cooperation2021,1970,2
University of Cincinnati,Religion2020,1970,5
University of Colorado at Boulder,Cooperation2021,1970,1
University of Concordia,Religion2020,1970,1
University of Connecticut,Cooperation2021,1970,4
University of Connecticut,Religion2020,1970,2
University of Constance,Religion2020,1970,1
University of Delaware,Cooperation2021,1970,3
University of Delaware,Religion2020,1970,2
University of Denver,Religion2020,1970,7
University of Detroit Mercy,Religion2020,1970,2
University of Dundee,Religion2020,1970,4
University of Durham,Cooperation2021,1970,1
University of Durham,CooperationAndReligion,1970,1
University of Durham,Religion2020,1970,5
University of Düsseldorf,Religion2020,1970,1
University of East Anglia,Religion2020,1970,1
University of Edinburgh,Cooperation2021,1970,1
University of Edinburgh,Religion2020,1970,18
University of Exeter,Cooperation2021,1970,2
University of Exeter,CooperationAndReligion,1970,1
University of Exeter,Religion2020,1970,5
University of Florida,Cooperation2021,1970,4
University of Florida,Religion2020,1970,5
University of Geneva,Religion2020,1970,2
University of Georgia,Cooperation2021,1970,2
University of Georgia,Religion2020,1970,6
University of Glasgow,Religion2020,1970,13
University of Guelph,Religion2020,1970,2
University of Haifa,Religion2020,1970,7
University of Hamburg,Religion2020,1970,1
University of Hawai‘i,Religion2020,1970,3
University of Hawaii at Manoa,Cooperation2021,1970,1
University of Hong Kong,Religion2020,1970,1
University of Houston,Religion2020,1970,4
University of Hull,Cooperation2021,1970,1
University of Hull,Religion2020,1970,2
University of Illinois,Cooperation2021,1970,6
University of Illinois,Religion2020,1970,8
University of Illinois at Chicago,Cooperation2021,1970,1
University of Illinois at Urbana-Champaign,Cooperation2021,1970,4
University of Illinois at Urbana-Champaign,Religion2020,1970,5
University of Iowa,Religion2020,1970,13
University of Kansas,Religion2020,1970,9
University of Kent,Religion2020,1970,4
University of Konstanz,Religion2020,1970,1
University of Lancaster,Religion2020,1970,4
University of Lausanne,Religion2020,1970,4
University of Leeds,Cooperation2021,1970,1
University of Leeds,Religion2020,1970,2
University of Leicester,Religion2020,1970,2
University of Lethbridge,Religion2020,1970,1
University of Liverpool,Religion2020,1970,1
University of London,Cooperation2021,1970,1
University of London,Religion2020,1970,16
University of Louisville,Religion2020,1970,2
University of Lyon-II,Cooperation2021,1970,1
University of Lyon-II,Religion2020,1970,1
University of Maine,Religion2020,1970,1
University of Manchester,Religion2020,1970,8
University of Manitoba,Cooperation2021,1970,1
University of Manitoba,Religion2020,1970,3
University of Marburg,Religion2020,1970,5
University of Maryland,Cooperation2021,1970,2
University of Maryland,Religion2020,1970,1
University of Maryland - College Park,Religion2020,1970,2
"University of Maryland, College Park",Cooperation2021,1970,4
"University of Maryland, College Park",Religion2020,1970,1
University of Massachusetts - Amherst,Religion2020,1970,5
University of Massachusetts Memorial Medical Center,Religion2020,1970,2
University of Miami,Cooperation2021,1970,1
University of Michigan,Cooperation2021,1970,4
University of Michigan,Religion2020,1970,6
University of Minnesota,Cooperation2021,1970,3
University of Minnesota,Religion2020,1970,6
University of Missouri,Religion2020,1970,1
University of Montana,Religion2020,1970,4
University of Montreal,Religion2020,1970,1
University of Munich,Religion2020,1970,3
University of Nanterre,Religion2020,1970,2
University of Nebraska,Cooperation2021,1970,1
University of Nebraska-Lincoln,Cooperation2021,1970,2
University of Nebraska-Lincoln,Religion2020,1970,3
University of Nevada - Reno,Religion2020,1970,1
University of New Delhi,Religion2020,1970,1
University of New Mexico,Cooperation2021,1970,2
University of New South Wales,Religion2020,1970,1
University of North Carolina - Chapel Hill,Religion2020,1970,1
University of North Carolina at Chapel Hill,Religion2020,1970,7
University of North Carolina-Chapel Hill,Cooperation2021,1970,1
University of North Texas,Religion2020,1970,1
University of Notre Dame,Cooperation2021,1970,1
University of Notre Dame,Religion2020,1970,23
University of Nottingham,Religion2020,1970,2
University of Oklahoma,Religion2020,1970,3
University of Oklahoma Health Sciences Center,Cooperation2021,1970,4
University of Oregon,Religion2020,1970,8
University of Oslo,Cooperation2021,1970,1
University of Ottawa,Religion2020,1970,1
University of Oxford,Religion2020,1970,25
University of Paris,Cooperation2021,1970,4
University of Paris,Religion2020,1970,3
University of Pennsylvania,Cooperation2021,1970,2
University of Pennsylvania,Religion2020,1970,21
University of Pittsburgh,Cooperation2021,1970,2
University of Pittsburgh,Religion2020,1970,2
University of Pretoria,Religion2020,1970,1
University of Québec at Montreal,Religion2020,1970,1
University of Reading,Religion2020,1970,2
University of Regensburg,Religion2020,1970,2
University of Rochester,Cooperation2021,1970,1
University of Rochester,Religion2020,1970,3
University of Roehampton,Religion2020,1970,3
University of Salzburg,Religion2020,1970,7
University of San Diego,Religion2020,1970,2
University of San Francisco,Religion2020,1970,4
University of South Africa,Religion2020,1970,1
University of South Carolina,Religion2020,1970,3
University of South Florida,Religion2020,1970,1
University of Southampton,Cooperation2021,1970,4
University of Southern California,Religion2020,1970,9
University of Split,Religion2020,1970,1
University of St Andrews,Religion2020,1970,7
University of Stavanger,Cooperation2021,1970,1
University of Stavanger,CooperationAndReligion,1970,1
University of Stavanger,Religion2020,1970,3
University of Strathclyde,Religion2020,1970,1
University of Stuttgart,Religion2020,1970,1
University of Sydney,Religion2020,1970,4
University of Teesside,Religion2020,1970,1
University of Tennessee,Cooperation2021,1970,2
University of Tennessee,Religion2020,1970,2
University of Tennessee at Chattanooga,Religion2020,1970,9
University of Texas at Austin,Religion2020,1970,1
University of Texas Medical Branch at Galveston,Cooperation2021,1970,4
University of Texas Pan American,Cooperation2021,1970,3
University of the South,Religion2020,1970,1
University of Toledo,Religion2020,1970,1
University of Toronto,Cooperation2021,1970,5
University of Toronto,Religion2020,1970,10
University of Tübingen,Cooperation2021,1970,1
University of Tübingen,Religion2020,1970,4
University of Utah,Cooperation2021,1970,1
University of Vermont,Religion2020,1970,1
University of Victoria,Religion2020,1970,7
University of Virginia,Religion2020,1970,18
University of Wales Trinity Saint David,Religion2020,1970,2
University of Washington,Cooperation2021,1970,7
University of Washington,Religion2020,1970,8
"University of Washington, Seattle",Religion2020,1970,1
University of Waterloo,Cooperation2021,1970,1
University of Waterloo,Religion2020,1970,3
University of West England,Religion2020,1970,1
University of West Florida,Religion2020,1970,2
University of Western Ontario,Cooperation2021,1970,4
University of Western Ontario,Religion2020,1970,5
University of Westminster,Cooperation2021,1970,2
University of Windsor,Religion2020,1970,6
University of Winnipeg,Religion2020,1970,2
University of Wisconsin,Religion2020,1970,3
University of Wisconsin - Madison,Cooperation2021,1970,3
University of Wisconsin - Madison,Religion2020,1970,1
University of Wisconsin - Milwaukee,Religion2020,1970,2
University of Wisconsin-La Crosse,Religion2020,1970,4
University of Wisconsin-Madison,Cooperation2021,1970,2
University of Wisconsin-Madison,Religion2020,1970,1
University of Wisconsin-Milwaukee,Religion2020,1970,2
University of Witwatersrand,Cooperation2021,1970,1
University of Wolverhampton,Religion2020,1970,1
University of York,Religion2020,1970,5
University of Zimbabwe,Religion2020,1970,2
University of Zurich,Cooperation2021,1970,1
University of Zurich,Religion2020,1970,1
Uppsala University,Religion2020,1970,8
Utah State University,Cooperation2021,1970,2
Utah State University,Religion2020,1970,2
Vanderbilt Divinity School,Religion2020,1970,2
Vanderbilt University,Religion2020,1970,8
Vermont College of Norwich University,Religion2020,1970,4
Victoria University of Wellington,Religion2020,1970,5
VID Specialized University,Religion2020,1970,1
Virginia Polytechnic Institute and State University,Religion2020,1970,4
Washington State University,Religion2020,1970,2
Washington University in St Louis,Religion2020,1970,9
Webster University,Religion2020,1970,4
Wellesley College,Religion2020,1970,3
West Virginia University,Cooperation2021,1970,2
Western Kentucky University,Religion2020,1970,1
Western Michigan University,Religion2020,1970,10
Westfield College,Religion2020,1970,1
Westminster College,Religion2020,1970,1
William & Mary,Religion2020,1970,4
Williams College,Religion2020,1970,3
Yale Divinity School,Religion2020,1970,3
Yale University,Cooperation2021,1970,11
Yale University,Religion2020,1970,21
Yale University Mind and Development Lab,Cooperation2021,1970,1
Yale University Mind and Development Lab,Religion2020,1970,1
Yeshivah of Flatbush High School,Religion2020,1970,1
Yonsei University,Religion2020,1970,1
York University,Cooperation2021,1970,1
York University,Religion2020,1970,3
Agnes Scott College,Religion2020,1965,1
Allegheny University of the Health Sciences,Cooperation2021,1965,2
Alliant International University,Cooperation2021,1965,11
American Enterprise Institute,Religion2020,1965,2
American Health Foundation,Religion2020,1965,1
American Sociological Association,Religion2020,1965,3
American University of Beirut,Religion2020,1965,1
Amherst College,Religion2020,1965,2
Arizona State University,Religion2020,1965,3
Augusta University,Religion2020,1965,4
Austin Presbyterian Theological Seminary,Cooperation2021,1965,2
Australian Catholic University,Religion2020,1965,1
Australian National University,Religion2020,1965,2
Ball State University,Religion2020,1965,4
Bangor University,Religion2020,1965,2
Bard College,Religion2020,1965,6
Bar-Ilan University,Religion2020,1965,1
Barrington College,Religion2020,1965,5
Baylor University,Cooperation2021,1965,1
Baylor University,Religion2020,1965,22
Bishop of Guilford,Religion2020,1965,3
Boston College,Religion2020,1965,2
Boston University,Religion2020,1965,17
Brandeis University,Religion2020,1965,1
Brigham Young University,Cooperation2021,1965,1
Brigham Young University,Religion2020,1965,11
British Army,Religion2020,1965,1
Brooklyn College,Religion2020,1965,1
Brown University,Religion2020,1965,10
Bucknell University,Religion2020,1965,3
California State University,Religion2020,1965,2
"California State University, Sacramento",Religion2020,1965,1
Cambridge University,Religion2020,1965,5
Canterbury Christchurch University,Religion2020,1965,1
Cardiff University,Religion2020,1965,2
Carleton University,Religion2020,1965,6
Catholic Ecumenical Institute,Religion2020,1965,1
Catholic University of America,Religion2020,1965,9
Centre National de la Recherche Scientifique,Religion2020,1965,1
Charles University,Religion2020,1965,10
Chicago Theological Seminary,Religion2020,1965,6
Christian Theological Seminary,Religion2020,1965,4
Christ's College,Religion2020,1965,1
Church Divinity School of the Pacific,Religion2020,1965,2
Claremont Graduate University,Cooperation2021,1965,2
Claremont Graduate University,Religion2020,1965,20
College of St. Rose,Religion2020,1965,5
College of Wooster,Religion2020,1965,3
Colombiere Center,Religion2020,1965,1
Colorado State University,Religion2020,1965,1
Columbia Theological Seminary,Religion2020,1965,1
Columbia University,Cooperation2021,1965,6
Columbia University,Religion2020,1965,7
Concordia University,Religion2020,1965,5
Connecticut College,Cooperation2021,1965,1
Connecticut College,Religion2020,1965,1
Constantine the Philosopher University,Religion2020,1965,6
Cornell University,Cooperation2021,1965,1
Crozer Theological Seminary,Religion2020,1965,2
"CUNY, Hunter College",Religion2020,1965,1
Dartmouth College,Religion2020,1965,2
David and Carol Myers Foundation,Cooperation2021,1965,1
Delaware Law School,Religion2020,1965,2
Dongbei University of Finance and Economics,Religion2020,1965,5
Downside Abbey,Religion2020,1965,1
Dropsie College,Religion2020,1965,2
Duke University,Cooperation2021,1965,2
Duke University,Religion2020,1965,11
Durham University,Religion2020,1965,3
East African Institute of Social Research,Religion2020,1965,1
Eastern Connecticut University,Religion2020,1965,1
Eastern Nebraska Office of Retardation,Religion2020,1965,1
Eastern University,Religion2020,1965,5
Eckerd College,Religion2020,1965,5
Ecole des Hautes Etudes en Sciences Sociales,Cooperation2021,1965,4
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1965,1
Emory University,Cooperation2021,1965,4
Emory University,Religion2020,1965,13
Esalen Institute,Religion2020,1965,1
Eurasian National University,Cooperation2021,1965,1
Fairfield University,Cooperation2021,1965,1
Fairfield University,Religion2020,1965,2
Flinders University,Cooperation2021,1965,1
Flinders University,Religion2020,1965,1
Florida State University,Religion2020,1965,2
Fordham University,Religion2020,1965,5
Franklin & Marshall College,Religion2020,1965,1
Free University of Berlin,Religion2020,1965,2
Fuller Theological Seminary,Religion2020,1965,11
Garrett Theological Seminary,Religion2020,1965,2
Garrett-Evangelical Theological Seminary,Religion2020,1965,1
George Mason University,Religion2020,1965,1
George Washington University,Cooperation2021,1965,2
Georgetown University,Religion2020,1965,10
Goethe University Frankfurt,Religion2020,1965,1
Golden Gate Baptist Theological Seminary,Religion2020,1965,2
"Goldsmiths, University of London",Religion2020,1965,1
Goucher College,Religion2020,1965,2
Graduate Theological Foundation,Religion2020,1965,5
Graduate Theological Union,Religion2020,1965,2
Greater Omaha Arc,Religion2020,1965,1
Guttmacher Institute,Religion2020,1965,1
Harvard Business School,Religion2020,1965,3
Harvard Divinity School,Religion2020,1965,6
Harvard Medical School,Religion2020,1965,2
Harvard School of Public Health,Cooperation2021,1965,1
Harvard School of Public Health,CooperationAndReligion,1965,1
Harvard School of Public Health,Religion2020,1965,3
Harvard University,Cooperation2021,1965,4
Harvard University,Religion2020,1965,12
Healthcare Chaplaincy,Religion2020,1965,1
healthquest,Religion2020,1965,1
Hebrew University of Jerusalem,Religion2020,1965,2
Heythrop College,Religion2020,1965,2
Highland Theological College,Religion2020,1965,2
Hofstra University,Religion2020,1965,1
Hope College,Cooperation2021,1965,1
Iliff School of Theology,Religion2020,1965,1
Indiana University,Religion2020,1965,11
Indiana University at Bloomington,Religion2020,1965,1
Institute for Advanced Study,Religion2020,1965,6
Institute of Buddhist Studies,Religion2020,1965,2
Institute of Psychiatry,Cooperation2021,1965,1
John Hopkins University,Religion2020,1965,3
Journal of Preventative Medicine,Religion2020,1965,1
Kalamazoo College,Religion2020,1965,3
Kansas Neurological Institute,Religion2020,1965,1
Kent State University,Cooperation2021,1965,3
Keston Institute,Religion2020,1965,2
Kings College,Religion2020,1965,1
King's College London,Religion2020,1965,15
Kipalapala Senior Seminary,Religion2020,1965,2
Kobe Gakuin University,Cooperation2021,1965,1
La Sapienza University,Religion2020,1965,2
La Trobe University,Cooperation2021,1965,1
Lancaster University,Religion2020,1965,3
Laval University,Cooperation2021,1965,9
Laval University,Religion2020,1965,2
Lehigh University,Cooperation2021,1965,1
Logos Center,Religion2020,1965,1
London School of Economics,Religion2020,1965,13
London School of Economics and Political Science,Cooperation2021,1965,1
London School of Economics and Political Science,Religion2020,1965,1
Long Island University,Religion2020,1965,2
Louisburg College,Religion2020,1965,1
Lousiana State University,Religion2020,1965,3
Loyola University Chicago,Cooperation2021,1965,1
Loyola University Chicago,Religion2020,1965,1
Loyola University New Orleans,Religion2020,1965,6
Lutheran School of theology at Chicago,Religion2020,1965,3
Macalester College,Religion2020,1965,1
Makerere University,Religion2020,1965,1
Manchester University,Religion2020,1965,1
Massachusetts Institute of Technology,Religion2020,1965,3
McCormick Theological Seminary,Religion2020,1965,3
McGill University,Religion2020,1965,5
McMaster University,Religion2020,1965,13
Meadville Lombard Theological School,Religion2020,1965,1
Menninger Clinic,Religion2020,1965,5
Methodist Theological School,Religion2020,1965,1
Miami University,Religion2020,1965,6
Mount Mary University,Religion2020,1965,1
Mt. Sinai School of Medicine,Religion2020,1965,5
National University of Ireland,Religion2020,1965,1
National University of Malaysia,Religion2020,1965,2
New School for Social Research,Religion2020,1965,1
New York University,Cooperation2021,1965,3
New York University,Religion2020,1965,6
Newcastle University,Religion2020,1965,2
Northwestern University,Religion2020,1965,2
Oberlin College,Religion2020,1965,10
Ohio State University,Religion2020,1965,1
Ohio University,Cooperation2021,1965,3
Oklahoma State University,Religion2020,1965,1
Old Dominion University,Religion2020,1965,9
Oregon State University,Cooperation2021,1965,1
Oxford University,Religion2020,1965,7
Pennsylvania State University,Religion2020,1965,10
Perkins School of Theology,Religion2020,1965,1
Phillipps Universitat Marburg,Religion2020,1965,1
Pima Community College,Religion2020,1965,5
Pomona College,Religion2020,1965,2
Pontifical University at Maynooth,Religion2020,1965,2
Princeton Theological Seminary,Religion2020,1965,37
Princeton University,Cooperation2021,1965,3
Princeton University,Religion2020,1965,19
Private Practice,Cooperation2021,1965,6
Private Practice,Religion2020,1965,2
Purdue University,Religion2020,1965,7
"Queens College, City University of New York",Religion2020,1965,1
Radford College,Religion2020,1965,1
RAND Corporation,Cooperation2021,1965,1
Reed College,Religion2020,1965,4
Rhodes College,Religion2020,1965,5
Rhodes University,Religion2020,1965,3
Rice University,Religion2020,1965,2
Rutgers University,Cooperation2021,1965,1
Rutgers University,Religion2020,1965,4
Saint Louis University,Religion2020,1965,6
Saint Mary's College,Religion2020,1965,2
San Francisco Theological Seminary,Religion2020,1965,1
Santa Clara University,Cooperation2021,1965,1
Saybrook University,Cooperation2021,1965,5
Scripps College,Religion2020,1965,4
Seattle Pacific University,Religion2020,1965,5
Shukutoku University,Religion2020,1965,5
Simon Fraser University,Religion2020,1965,1
SOAS University of London,Cooperation2021,1965,1
SOAS University of London,Religion2020,1965,12
Sofia University,Cooperation2021,1965,2
Sorbonne University,Religion2020,1965,2
Southern Methodist University,Cooperation2021,1965,1
Southern Methodist University,Religion2020,1965,6
St Andrews University,Religion2020,1965,1
St Botolph's Aldgate,Religion2020,1965,1
St Mary's University Church,Religion2020,1965,2
"St. Edmund Hall, Oxford",Religion2020,1965,1
St. John's University,Religion2020,1965,2
St. Louis University,Religion2020,1965,7
"St. Mary's University, Twickenham",Religion2020,1965,1
St. Olaf College,Religion2020,1965,1
"St. Thomas Hospital, London",Religion2020,1965,1
Staffordshire University,Religion2020,1965,1
Stanford University,Cooperation2021,1965,1
Stanford University,Religion2020,1965,12
State University of New York at Albany,Cooperation2021,1965,1
Stockholm University,Religion2020,1965,8
Stony Brook University,Religion2020,1965,2
SUNY Oswego,Religion2020,1965,5
Swansea University,Religion2020,1965,4
Syracuse University,Religion2020,1965,7
Tel Aviv University,Religion2020,1965,1
Temple University,Religion2020,1965,5
Texas A&M University,Religion2020,1965,1
Texas Christian University,Religion2020,1965,1
The Hastings Center,Religion2020,1965,2
The New School,Religion2020,1965,2
The Open University,Religion2020,1965,1
The University of Rochester School of Medicine,Religion2020,1965,3
Transnational Family Research Institute,Religion2020,1965,1
Trinity College,Religion2020,1965,6
"Trinity College, Cambridge",Religion2020,1965,1
Trinity Presbytarian Church,Religion2020,1965,1
Union Presbyterian Seminary,Religion2020,1965,4
Union Theological College,Religion2020,1965,2
Union Theological Seminary,Religion2020,1965,11
Universite Laval,Religion2020,1965,2
Universiteit Utrecht,Religion2020,1965,1
University of Aberdeen,Religion2020,1965,2
University of Alabama,Religion2020,1965,1
University of Alabama at Birmingham,Religion2020,1965,1
University of Amsterdam,Religion2020,1965,5
University of Arizona,Cooperation2021,1965,2
University of Arizona,Religion2020,1965,10
University of Basel,Religion2020,1965,1
University of Berlin,Religion2020,1965,3
University of Bern,Religion2020,1965,1
University of Birmingham,Religion2020,1965,7
University of Bonn,Religion2020,1965,7
University of Bridgeport,Religion2020,1965,1
University of Bristol,Religion2020,1965,2
University of British Columbia,Cooperation2021,1965,2
University of Buffalo,Religion2020,1965,3
University of Calgary,Religion2020,1965,7
University of California - Berkeley,Religion2020,1965,16
University of California - Davis,Religion2020,1965,8
University of California - Irvine,Religion2020,1965,1
University of California - Los Angeles,Cooperation2021,1965,2
University of California - Los Angeles,Religion2020,1965,12
University of California - Riverside,Cooperation2021,1965,3
University of California - San Diego,Religion2020,1965,4
University of California - San Francisco,Cooperation2021,1965,2
University of California - San Francisco,Religion2020,1965,2
University of California - Santa Barbara,Religion2020,1965,21
"University of California, Berkeley",Religion2020,1965,16
"University of California, Irvine",Cooperation2021,1965,2
"University of California, Los Angeles",Religion2020,1965,8
"University of California, Riverside",Cooperation2021,1965,1
"University of California, Riverside",Religion2020,1965,7
"University of California, Santa Barbara",Cooperation2021,1965,1
"University of California, Santa Barbara",Religion2020,1965,6
University of Cambridge,Religion2020,1965,23
University of Chicago,Religion2020,1965,58
University of Chicago Divinity School,Religion2020,1965,1
University of Connecticut,Cooperation2021,1965,1
University of Delaware,Cooperation2021,1965,3
University of Delaware,Religion2020,1965,1
University of Denver,Religion2020,1965,4
University of Dundee,Religion2020,1965,2
University of Durham,Religion2020,1965,1
University of Düsseldorf,Religion2020,1965,3
University of Edinburgh,Cooperation2021,1965,4
University of Edinburgh,Religion2020,1965,6
University of Essex,Cooperation2021,1965,1
University of Exeter,Religion2020,1965,15
University of Florida,Religion2020,1965,3
University of Geneva,Religion2020,1965,1
University of Georgia,Cooperation2021,1965,2
University of Georgia,Religion2020,1965,7
University of Glasgow,Religion2020,1965,8
University of Haifa,Religion2020,1965,1
University of Hawai‘i,Religion2020,1965,1
University of Hawai'i,Religion2020,1965,1
University of Houston,Religion2020,1965,1
University of Hull,Cooperation2021,1965,1
University of Hull,Religion2020,1965,1
University of Illinois,Cooperation2021,1965,3
University of Illinois,Religion2020,1965,4
University of Illinois at Urbana-Champaign,Cooperation2021,1965,4
University of Illinois at Urbana-Champaign,Religion2020,1965,4
University of Iowa,Religion2020,1965,8
University of Kansas,Religion2020,1965,5
University of Kentucky,Religion2020,1965,1
University of Konstanz,Religion2020,1965,3
University of Lausanne,Religion2020,1965,2
University of Leeds,Religion2020,1965,1
University of Leicester,Religion2020,1965,2
University of Liverpool,Religion2020,1965,3
University of London,Religion2020,1965,21
University of Lucerne,Religion2020,1965,1
University of Lucknow,Religion2020,1965,1
University of Manchester,Religion2020,1965,11
University of Marburg,Religion2020,1965,3
University of Maryland,Cooperation2021,1965,2
University of Maryland,Religion2020,1965,1
"University of Maryland, College Park",Religion2020,1965,4
University of Massachusetts - Amherst,Cooperation2021,1965,1
University of Massachusetts - Amherst,Religion2020,1965,8
University of Massachusetts Memorial Medical Center,Religion2020,1965,4
University of Michigan,Cooperation2021,1965,1
University of Michigan,Religion2020,1965,5
University of Minnesota,Cooperation2021,1965,1
University of Minnesota,Religion2020,1965,4
University of Montana,Religion2020,1965,1
University of Nanterre,Religion2020,1965,1
University of Nebraska-Lincoln,Cooperation2021,1965,1
University of Nebraska-Lincoln,Religion2020,1965,2
University of Nevada - Reno,Cooperation2021,1965,1
University of New Delhi,Religion2020,1965,1
University of New Mexico,Cooperation2021,1965,2
University of New South Wales,Religion2020,1965,4
University of Newcastle,Religion2020,1965,1
University of North Carolina - Chapel Hill,Religion2020,1965,3
University of North Carolina at Chapel Hill,Cooperation2021,1965,2
University of North Carolina at Chapel Hill,Religion2020,1965,11
University of North Carolina at Greensboro,Cooperation2021,1965,2
University of North Texas,Religion2020,1965,1
University of Notre Dame,Religion2020,1965,11
University of Nottingham,Religion2020,1965,3
University of Oklahoma,Religion2020,1965,7
University of Oklahoma Health Sciences Center,Religion2020,1965,2
University of Oregon,Religion2020,1965,3
University of Oslo,Cooperation2021,1965,1
University of Ottawa,Religion2020,1965,1
University of Oxford,Cooperation2021,1965,3
University of Oxford,Religion2020,1965,33
University of Pennsylvania,Cooperation2021,1965,1
University of Pennsylvania,Religion2020,1965,25
University of Pittsburgh,Religion2020,1965,5
University of Port Harcourt,Religion2020,1965,2
University of Reading,Religion2020,1965,3
University of Salzburg,Religion2020,1965,9
University of San Francisco,Religion2020,1965,2
University of Saskatchewan,Religion2020,1965,1
University of Sheffield,Religion2020,1965,1
University of Sherbrooke,Religion2020,1965,1
University of South Carolina,Religion2020,1965,2
University of South Dakota,Religion2020,1965,1
University of South Florida,Cooperation2021,1965,1
University of South Florida,Religion2020,1965,1
University of Southampton,Cooperation2021,1965,3
University of Southern California,Religion2020,1965,2
University of St Andrews,Religion2020,1965,6
University of Strathclyde,Cooperation2021,1965,1
University of Strathclyde,Religion2020,1965,2
University of Stuttgart,Religion2020,1965,3
University of Sydney,Religion2020,1965,2
University of Teesside,Religion2020,1965,6
University of Tennessee,Religion2020,1965,3
University of Tennessee at Chattanooga,Religion2020,1965,1
University of Texas Medical Branch at Galveston,Religion2020,1965,2
University of Texas Pan American,Cooperation2021,1965,4
University of Texas Pan American,Religion2020,1965,2
University of Texas School of Public Health,Religion2020,1965,2
University of the South,Religion2020,1965,3
University of Toronto,Cooperation2021,1965,9
University of Toronto,Religion2020,1965,1
University of Tübingen,Religion2020,1965,2
University of Vermont,Cooperation2021,1965,1
University of Victoria,Religion2020,1965,1
University of Virginia,Religion2020,1965,10
University of Wales Trinity Saint David,Religion2020,1965,2
University of Washington,Cooperation2021,1965,2
University of Washington,Religion2020,1965,2
University of Western Australia,Cooperation2021,1965,2
University of Western Ontario,Cooperation2021,1965,1
University of Windsor,Religion2020,1965,1
University of Winnipeg,Religion2020,1965,4
University of Wisconsin,Religion2020,1965,1
University of Wisconsin - Madison,Cooperation2021,1965,1
University of Wisconsin - Milwaukee,Religion2020,1965,1
University of Wollongong,Religion2020,1965,3
University of York,Religion2020,1965,2
University of Zimbabwe,Religion2020,1965,4
University of Zurich,Religion2020,1965,5
Uppsala University,Religion2020,1965,13
Vanderbilt University,Religion2020,1965,8
Vermont College of Norwich University,Religion2020,1965,2
Victoria University of Wellington,Religion2020,1965,2
Virginia Polytechnic Institute and State University,Religion2020,1965,1
Vrije Universiteit Brussel,Religion2020,1965,1
Washington State University,Religion2020,1965,1
Washington University in St Louis,Religion2020,1965,5
Wayne State University,Religion2020,1965,1
Webster University,Religion2020,1965,3
Wellesley College,Religion2020,1965,4
Wesley Theological Seminary,Religion2020,1965,1
Western Michigan University,Religion2020,1965,9
Westfield College,Religion2020,1965,3
Wheaton College,Religion2020,1965,2
Williams College,Religion2020,1965,1
Woodstock College,Religion2020,1965,1
Yale Divinity School,Religion2020,1965,12
Yale University,Cooperation2021,1965,2
Yale University,Religion2020,1965,18
Yale University Mind and Development Lab,Cooperation2021,1965,3
York University,Cooperation2021,1965,1
York University,Religion2020,1965,5
Agnes Scott College,Religion2020,1960,1
Alphacrucis College,Religion2020,1960,2
American Bible Society,Religion2020,1960,2
American Council on Education,Religion2020,1960,1
American Geographical Society,Religion2020,1960,1
American Sociological Association,Religion2020,1960,8
Amherst College,Religion2020,1960,4
Andover Newton Theological School,Religion2020,1960,1
Arizona State University,Religion2020,1960,1
Ball State University,Religion2020,1960,1
Banaras Hindu University,Religion2020,1960,1
Bangor University,Religion2020,1960,1
Bard College,Religion2020,1960,1
Bar-Ilan University,Cooperation2021,1960,1
Bar-Ilan University,Religion2020,1960,6
Barnard College,Religion2020,1960,1
Barrington College,Religion2020,1960,1
Baylor University,Religion2020,1960,8
Boston College,Religion2020,1960,2
Boston University,Religion2020,1960,20
Brigham Young University,Cooperation2021,1960,1
Brigham Young University,Religion2020,1960,6
British Army,Religion2020,1960,2
Brooklyn College,Religion2020,1960,1
Brown University,Religion2020,1960,1
Bryn Mawr College,Religion2020,1960,2
Cambridge University,Religion2020,1960,1
Canterbury Christchurch University,Religion2020,1960,2
Carleton University,Religion2020,1960,1
Catholic University of America,Religion2020,1960,4
Centre National de la Recherche Scientifique,Religion2020,1960,7
Centre Tricontinental (CETRI),Religion2020,1960,2
Chicago Theological Seminary,Religion2020,1960,3
Christian Theological Seminary,Religion2020,1960,2
Christ's College,Religion2020,1960,1
Church of Northampton,Religion2020,1960,1
Claremont Graduate University,Religion2020,1960,21
Colgate Rochester Divinity School,Religion2020,1960,1
Colgate University,Religion2020,1960,1
College of St. Rose,Religion2020,1960,1
College of Wooster,Religion2020,1960,1
Colorado State University,Religion2020,1960,2
Columbia University,Cooperation2021,1960,1
Columbia University,Religion2020,1960,9
Concordia University,Religion2020,1960,4
Cornell University,Cooperation2021,1960,2
Crozer Theological Seminary,Religion2020,1960,1
CUNY,Religion2020,1960,8
Dongbei University of Finance and Economics,Religion2020,1960,1
Downside Abbey,Religion2020,1960,2
Drew University,Religion2020,1960,5
Duke University,Cooperation2021,1960,4
Duke University,Religion2020,1960,3
Durham University,Religion2020,1960,1
Eastern University,Religion2020,1960,1
Eckerd College,Religion2020,1960,1
Ecole des Hautes Etudes en Sciences Sociales,Cooperation2021,1960,1
Ecole des Hautes Etudes en Sciences Sociales,Religion2020,1960,1
Emory University,Religion2020,1960,9
Episcopal Divinity School,Religion2020,1960,1
Fairfield University,Cooperation2021,1960,1
Flinders University,Religion2020,1960,1
Florida State University,Religion2020,1960,1
Fordham University,Religion2020,1960,1
Franklin & Marshall College,Religion2020,1960,1
Fuller Theological Seminary,Religion2020,1960,3
Garrett-Evangelical Theological Seminary,Religion2020,1960,2
Georgetown University,Religion2020,1960,5
Goethe University Frankfurt,Religion2020,1960,3
Graduate Theological Foundation,Religion2020,1960,2
Graduate Theological Union,Religion2020,1960,2
Greater India Research Committee,Religion2020,1960,3
Harvard Business School,Religion2020,1960,1
Harvard Divinity School,Religion2020,1960,4
Harvard Medical School,Religion2020,1960,1
Harvard School of Public Health,Religion2020,1960,1
Harvard University,Cooperation2021,1960,3
Harvard University,Religion2020,1960,19
Hebrew Union College,Religion2020,1960,1
Hofstra University,Religion2020,1960,1
Indiana University,Religion2020,1960,1
Institute for Advanced Study,Religion2020,1960,7
Institute of Buddhist Studies,Religion2020,1960,1
Institute of Race Relations,Religion2020,1960,1
International center Lumen vitae,Religion2020,1960,1
Jewish Theological Seminary,Religion2020,1960,1
John Hopkins School of Medicine,Cooperation2021,1960,1
John Hopkins University,Religion2020,1960,2
Kent State University,Cooperation2021,1960,3
Kings College,Religion2020,1960,3
King's College London,Religion2020,1960,11
La Sapienza University,Religion2020,1960,1
Laval University,Cooperation2021,1960,7
Laval University,Religion2020,1960,1
Lehigh University,Religion2020,1960,2
London School of Economics,Religion2020,1960,2
London School of Economics and Political Science,Religion2020,1960,5
Long Island University,Religion2020,1960,3
Louisiana State University,Cooperation2021,1960,1
Lousiana State University,Religion2020,1960,3
Loyola University Chicago,Cooperation2021,1960,1
Loyola University New Orleans,Religion2020,1960,7
Lutheran School of theology at Chicago,Religion2020,1960,3
Macalester College,Religion2020,1960,8
Manchester University,Religion2020,1960,1
McCormick Theological Seminary,Religion2020,1960,4
McGill University,Religion2020,1960,2
McMaster University,Religion2020,1960,3
Menninger Clinic,Religion2020,1960,4
Methodist Theological School,Religion2020,1960,1
Miami University,Religion2020,1960,1
Michigan State University,Cooperation2021,1960,2
Mississippi State University,Cooperation2021,1960,1
Murdoch University,Religion2020,1960,3
National Council of Churches,Religion2020,1960,1
New Orleans Baptist Theological Seminary,Religion2020,1960,1
New School for Social Research,Religion2020,1960,1
New York Theological Seminary,Cooperation2021,1960,1
New York Theological Seminary,Religion2020,1960,3
New York University,Religion2020,1960,4
Newcastle University,Religion2020,1960,2
Oberlin College,Religion2020,1960,13
Old Dominion University,Religion2020,1960,1
Open University (The),Religion2020,1960,1
Oxford University,Religion2020,1960,1
Pacific School of Religion,Religion2020,1960,1
Pennsylvania State University,Religion2020,1960,8
Pima Community College,Religion2020,1960,1
Pontifical University at Maynooth,Religion2020,1960,3
Princeton Theological Seminary,Religion2020,1960,18
Princeton University,Cooperation2021,1960,1
Princeton University,Religion2020,1960,18
Purdue University,Religion2020,1960,1
Radcliffe College,Religion2020,1960,1
Reed College,Religion2020,1960,7
Religious Studies,Religion2020,1960,2
Rhodes College,Religion2020,1960,1
Rice University,Religion2020,1960,7
Rockford University,Religion2020,1960,1
Rutgers University,Religion2020,1960,3
Saint Louis University,Religion2020,1960,1
School of Oriental and African Studies,Religion2020,1960,1
Scripps College,Religion2020,1960,1
Seattle Pacific University,Religion2020,1960,1
Simmons College,Religion2020,1960,1
Sorbonne University,Religion2020,1960,2
Southern Methodist University,Religion2020,1960,9
St. Louis University,Religion2020,1960,5
St. Mary's College,Religion2020,1960,1
Stanford University,Religion2020,1960,8
Stetson University,Religion2020,1960,1
Swansea University,Religion2020,1960,1
Syracuse University,Religion2020,1960,6
"Teachers College, Columbia University",Religion2020,1960,3
Temple University,Religion2020,1960,5
The Hague University of Applied Sciences,Religion2020,1960,13
The Hastings Center,Religion2020,1960,1
The New School,Religion2020,1960,1
The Open University,Religion2020,1960,2
The University of Rochester School of Medicine,Religion2020,1960,8
Trinity College,Religion2020,1960,5
Trinity Presbytarian Church,Religion2020,1960,2
Tufts University,Religion2020,1960,6
Union Presbyterian Seminary,Religion2020,1960,1
Union Theological Seminary,Religion2020,1960,5
Universite Laval,Religion2020,1960,1
Universiteit Utrecht,Religion2020,1960,1
University at Buffalo,Cooperation2021,1960,1
University College London,Religion2020,1960,1
University of Alberta,Religion2020,1960,1
University of Arizona,Religion2020,1960,15
University of Basel,Religion2020,1960,2
University of Birmingham,Cooperation2021,1960,1
University of Birmingham,Religion2020,1960,6
University of Bonn,Religion2020,1960,4
University of Bridgeport,Religion2020,1960,8
University of British Columbia,Cooperation2021,1960,1
University of Buffalo,Religion2020,1960,1
University of Calgary,Religion2020,1960,7
University of California - Berkeley,Religion2020,1960,8
University of California - Los Angeles,Religion2020,1960,4
University of California - Riverside,Religion2020,1960,6
University of California - San Francisco,Religion2020,1960,1
University of California - Santa Barbara,Religion2020,1960,19
"University of California, Berkeley",Religion2020,1960,3
"University of California, Los Angeles",Religion2020,1960,5
"University of California, Riverside",Religion2020,1960,2
"University of California, Santa Barbara",Religion2020,1960,2
University of Cambridge,Religion2020,1960,11
University of Cape Town,Religion2020,1960,2
University of Chicago,Cooperation2021,1960,2
University of Chicago,Religion2020,1960,52
University of Chicago Divinity School,Religion2020,1960,3
University of Cincinnati,Religion2020,1960,1
University of Concordia,Religion2020,1960,2
University of Delaware,Religion2020,1960,4
University of Divinity,Religion2020,1960,2
University of Edinburgh,Cooperation2021,1960,2
University of Edinburgh,Religion2020,1960,15
University of Exeter,Religion2020,1960,1
University of Georgia,Cooperation2021,1960,1
University of Georgia,Religion2020,1960,4
University of Glasgow,Religion2020,1960,1
University of Illinois,Religion2020,1960,5
University of Illinois at Urbana-Champaign,Religion2020,1960,5
University of Iowa,Religion2020,1960,21
University of Konstanz,Religion2020,1960,2
University of Leicester,Religion2020,1960,1
University of Liverpool,Religion2020,1960,3
University of London,Religion2020,1960,15
University of Louisville,Religion2020,1960,4
University of Lund,Religion2020,1960,1
University of Manchester,Religion2020,1960,5
University of Marburg,Religion2020,1960,1
University of Massachusetts - Amherst,Cooperation2021,1960,1
University of Massachusetts - Amherst,Religion2020,1960,10
University of Minnesota,Religion2020,1960,1
University of Munich,Cooperation2021,1960,1
"University of Nebraska, Omaha",Religion2020,1960,4
University of New South Wales,Religion2020,1960,1
University of North Carolina at Chapel Hill,Religion2020,1960,6
University of Notre Dame,Religion2020,1960,6
University of Nottingham,Religion2020,1960,2
University of Oklahoma,Religion2020,1960,3
University of Oklahoma Health Sciences Center,Cooperation2021,1960,1
University of Oregon,Religion2020,1960,2
University of Ottawa,Religion2020,1960,1
University of Oxford,Cooperation2021,1960,2
University of Oxford,Religion2020,1960,19
University of Pennsylvania,Religion2020,1960,18
University of Pittsburgh,Cooperation2021,1960,1
University of Pittsburgh,Religion2020,1960,1
University of Port Harcourt,Religion2020,1960,5
University of Reading,Religion2020,1960,1
University of Salzburg,Religion2020,1960,1
University of Sheffield,Religion2020,1960,2
University of Southampton,Cooperation2021,1960,1
University of Southern California,Religion2020,1960,3
University of St Andrews,Religion2020,1960,6
University of Strassburg,Religion2020,1960,2
University of Strathclyde,Cooperation2021,1960,1
University of Sydney,Religion2020,1960,5
University of Teesside,Religion2020,1960,3
University of Texas at Dallas,Religion2020,1960,1
University of Texas Medical Branch at Galveston,Cooperation2021,1960,1
University of the South,Religion2020,1960,11
University of Toronto,Cooperation2021,1960,1
University of Toronto,Religion2020,1960,1
University of Virginia,Cooperation2021,1960,1
University of Virginia,Religion2020,1960,5
University of Wales Trinity Saint David,Religion2020,1960,2
University of Western Australia,Cooperation2021,1960,3
University of Western Ontario,Cooperation2021,1960,3
University of Winnipeg,Religion2020,1960,3
University of Wisconsin,Religion2020,1960,3
University of Wisconsin - Madison,Religion2020,1960,2
University of Wisconsin-Madison,Cooperation2021,1960,1
University of Wisconsin-Madison,Religion2020,1960,1
University of Witwatersrand,Cooperation2021,1960,1
Uppsala University,Religion2020,1960,3
Vanderbilt University,Religion2020,1960,5
Webster University,Religion2020,1960,5
Wellesley College,Religion2020,1960,2
Wesley Theological Seminary,Religion2020,1960,3
Westminster Choir College,Religion2020,1960,3
Williams College,Religion2020,1960,2
Woodstock College,Religion2020,1960,1
Yale Divinity School,Religion2020,1960,3
Yale University,Religion2020,1960,25
York University,Cooperation2021,1960,1
York University,Religion2020,1960,1
Alphacrucis College,Religion2020,1955,1
American Bible Society,Religion2020,1955,2
American Health Foundation,Religion2020,1955,1
American School of Classical Studies,Religion2020,1955,4
Andover Newton Theological School,Religion2020,1955,1
Banaras Hindu University,Religion2020,1955,2
Bar-Ilan University,Religion2020,1955,1
Berea College,Religion2020,1955,2
Boston University,Religion2020,1955,11
British Army,Cooperation2021,1955,1
Brown University,Religion2020,1955,1
Bryn Mawr College,Religion2020,1955,1
Cambridge University,Cooperation2021,1955,2
Cambridge University,Religion2020,1955,2
Carleton University,Religion2020,1955,1
Catholic University of America,Religion2020,1955,3
Centre National de la Recherche Scientifique,Religion2020,1955,1
Chatham University,Religion2020,1955,2
Chicago Theological Seminary,Religion2020,1955,10
Christ's College,Religion2020,1955,5
Claremont Graduate University,Religion2020,1955,9
Colgate Rochester Divinity School,Religion2020,1955,4
Colgate University,Religion2020,1955,1
College of Wooster,Religion2020,1955,4
Colorado State University,Religion2020,1955,2
Columbia University,Religion2020,1955,1
Cornell University,Cooperation2021,1955,3
Cornell University,Religion2020,1955,1
Crozer Theological Seminary,Religion2020,1955,4
Downside Abbey,Religion2020,1955,2
Drew University,Religion2020,1955,3
Dropsie College,Religion2020,1955,1
Duke Divinity School,Religion2020,1955,1
Duke University,Cooperation2021,1955,3
Duke University,Religion2020,1955,3
Durham University,Cooperation2021,1955,1
East African Institute of Social Research,Religion2020,1955,4
Ecole des Hautes Etudes en Sciences Sociales,Cooperation2021,1955,3
Emory University,Religion2020,1955,4
Fairmount West Virginia State College,Religion2020,1955,8
Franklin & Marshall College,Religion2020,1955,3
Fuller Theological Seminary,Religion2020,1955,2
Garrett-Evangelical Theological Seminary,Religion2020,1955,4
George Mason University,Religion2020,1955,1
Georgetown University,Religion2020,1955,4
Goethe University Frankfurt,Religion2020,1955,3
Graduate Theological Foundation,Religion2020,1955,3
Graduate Theological Union,Religion2020,1955,1
Greater India Research Committee,Religion2020,1955,3
Hartford Seminary,Religion2020,1955,2
Harvard School of Public Health,Religion2020,1955,1
Harvard University,Cooperation2021,1955,4
Harvard University,Religion2020,1955,5
Hebrew Union College,Religion2020,1955,1
Hebrew University of Jerusalem,Religion2020,1955,7
Heythrop College,Religion2020,1955,4
Hofstra University,Cooperation2021,1955,2
Hofstra University,Religion2020,1955,1
"Hunter College, CUNY",Religion2020,1955,2
Indiana University,Religion2020,1955,5
Institute for Advanced Study,Religion2020,1955,2
Institute of Buddhist Studies,Religion2020,1955,1
Jewish Theological Seminary,Religion2020,1955,5
John Hopkins University,Religion2020,1955,1
Journal of Preventative Medicine,Religion2020,1955,1
King's College London,Religion2020,1955,6
Laval University,Cooperation2021,1955,5
Laval University,CooperationAndReligion,1955,1
Laval University,Religion2020,1955,2
Lehigh University,Cooperation2021,1955,1
Lehigh University,Religion2020,1955,4
London School of Economics,Religion2020,1955,2
London School of Economics and Political Science,Religion2020,1955,1
Louisiana State University,Religion2020,1955,1
Loyola University New Orleans,Religion2020,1955,3
Magdalene College,Religion2020,1955,3
Makerere University,Religion2020,1955,4
Marquette University,Religion2020,1955,1
McCormick Theological Seminary,Religion2020,1955,2
McGill University,Cooperation2021,1955,1
McGill University,Religion2020,1955,1
Menninger Clinic,Religion2020,1955,1
Methodist Theological School,Religion2020,1955,1
National Council of Churches,Religion2020,1955,2
New School for Social Research,Cooperation2021,1955,1
Northwestern University,Religion2020,1955,3
Oberlin College,Religion2020,1955,11
Oklahoma State University,Religion2020,1955,1
Pacific School of Religion,Religion2020,1955,2
Pennsylvania State University,Religion2020,1955,3
Perkins School of Theology,Religion2020,1955,2
Pontifical University at Maynooth,Religion2020,1955,2
Princeton Theological Seminary,Religion2020,1955,13
Princeton University,Cooperation2021,1955,1
Princeton University,Religion2020,1955,13
Queen's University Belfast,Religion2020,1955,1
Religious Studies,Religion2020,1955,6
Sanskrit College,Cooperation2021,1955,1
Smith College,Religion2020,1955,2
Sorbonne University,Religion2020,1955,1
Southern Methodist University,Religion2020,1955,4
St Mary's University Church,Religion2020,1955,10
St. Louis University,Religion2020,1955,1
Stanford University,Cooperation2021,1955,1
Stanford University,Religion2020,1955,4
Stockholm University,Religion2020,1955,3
SUNY Oswego,Religion2020,1955,1
Swansea University,Religion2020,1955,3
Syracuse University,Religion2020,1955,3
"Teachers College, Columbia University",Religion2020,1955,8
Temple University,Religion2020,1955,3
The New School,Religion2020,1955,3
The University of Rochester School of Medicine,Religion2020,1955,7
"Trinity College, Cambridge",Religion2020,1955,2
Union Theological Seminary,Religion2020,1955,8
Universiteit Utrecht,Religion2020,1955,7
University College London,Cooperation2021,1955,1
University College London,Religion2020,1955,1
University of Aberdeen,Religion2020,1955,1
University of Adelaide,Religion2020,1955,1
University of Ballarat,Religion2020,1955,1
University of Bern,Religion2020,1955,1
University of Bridgeport,Cooperation2021,1955,1
University of Bridgeport,Religion2020,1955,1
University of California - Berkeley,Cooperation2021,1955,1
University of California - Berkeley,Religion2020,1955,1
University of California - Davis,Religion2020,1955,1
University of California - Riverside,Religion2020,1955,1
University of California - Santa Barbara,Religion2020,1955,2
"University of California, Berkeley",Religion2020,1955,8
"University of California, Los Angeles",Religion2020,1955,11
University of Cambridge,Cooperation2021,1955,3
University of Cambridge,Religion2020,1955,4
University of Chicago,Cooperation2021,1955,2
University of Chicago,CooperationAndReligion,1955,1
University of Chicago,Religion2020,1955,43
University of Chicago Divinity School,Religion2020,1955,8
University of Denver,Religion2020,1955,1
University of Divinity,Religion2020,1955,1
University of Edinburgh,Cooperation2021,1955,1
University of Edinburgh,Religion2020,1955,9
University of Exeter,Religion2020,1955,1
University of Glasgow,Religion2020,1955,1
University of Hawai‘i–West O‘ahu,Religion2020,1955,1
University of Illinois,Religion2020,1955,1
University of Iowa,Cooperation2021,1955,2
University of Iowa,Religion2020,1955,8
University of Leeds,Religion2020,1955,1
University of Liverpool,Cooperation2021,1955,1
University of Liverpool,Religion2020,1955,1
University of London,Cooperation2021,1955,1
University of London,Religion2020,1955,12
University of Louisville,Religion2020,1955,6
University of Lund,Religion2020,1955,2
University of Manchester,Religion2020,1955,2
University of Marburg,Religion2020,1955,2
University of Michigan,Cooperation2021,1955,1
University of Michigan,Religion2020,1955,2
University of Minnesota,Religion2020,1955,1
University of Munich,Religion2020,1955,1
University of Nebraska,Religion2020,1955,1
University of North Carolina at Chapel Hill,Religion2020,1955,2
University of Notre Dame,Religion2020,1955,3
University of Nottingham,Religion2020,1955,1
University of Oklahoma,Religion2020,1955,2
University of Oregon,Religion2020,1955,1
University of Oxford,Religion2020,1955,15
University of Paris,Religion2020,1955,1
University of Pennsylvania,Religion2020,1955,8
University of Pennsylvania Program for Mindfulness,Religion2020,1955,19
University of Pittsburgh,Religion2020,1955,3
University of Reading,Religion2020,1955,2
University of South Carolina,Religion2020,1955,2
University of Southampton,Cooperation2021,1955,1
University of Southern California,Religion2020,1955,1
University of St Andrews,Religion2020,1955,7
University of Strassburg,Religion2020,1955,3
University of Sydney,Religion2020,1955,2
University of the South,Religion2020,1955,6
University of Toronto,Cooperation2021,1955,2
University of Virginia,Religion2020,1955,4
University of Wales Trinity Saint David,Religion2020,1955,3
University of Waterloo,Religion2020,1955,2
University of West England,Religion2020,1955,1
University of Wisconsin - Madison,Religion2020,1955,3
University of Zimbabwe,Religion2020,1955,1
Uppsala University,Religion2020,1955,1
Vanderbilt University,Religion2020,1955,5
Webster University,Religion2020,1955,6
Wellesley College,Religion2020,1955,2
Wesley Theological Seminary,Religion2020,1955,1
Westfield College,Cooperation2021,1955,1
Westminster Choir College,Religion2020,1955,1
Woodstock College,Religion2020,1955,1
Yale Divinity School,Religion2020,1955,4
Yale University,Cooperation2021,1955,2
Yale University,CooperationAndReligion,1955,1
Yale University,Religion2020,1955,15
Yeshiva University,Religion2020,1955,1
American School of Classical Studies,Religion2020,1950,5
Amherst College,Religion2020,1950,1
Andover Newton Theological School,Religion2020,1950,5
Barnard College,Religion2020,1950,1
Berea College,Religion2020,1950,6
Boston University,Religion2020,1950,9
Brigham Young University,Religion2020,1950,1
Brown University,Religion2020,1950,7
Cambridge University,Religion2020,1950,1
Canterbury Christ Church University,Religion2020,1950,1
Chatham University,Religion2020,1950,5
Chicago Theological Seminary,Religion2020,1950,2
Christ's College,Religion2020,1950,2
Claremont Graduate University,Religion2020,1950,11
Colby College,Religion2020,1950,5
Colgate Rochester Divinity School,Religion2020,1950,4
College of France,Religion2020,1950,1
Columbia University,Religion2020,1950,12
Cornell University,Religion2020,1950,2
Crozer Theological Seminary,Religion2020,1950,5
CUNY,Cooperation2021,1950,7
Drew University,Religion2020,1950,3
Dropsie College,Religion2020,1950,3
Duke Divinity School,Religion2020,1950,2
Duke University,Religion2020,1950,2
Emory University,Religion2020,1950,2
Franklin & Marshall College,Religion2020,1950,3
Garrett Theological Seminary,Religion2020,1950,1
George Mason University,Religion2020,1950,4
George Washington University,Religion2020,1950,1
Goethe University Frankfurt,Religion2020,1950,3
Greater India Research Committee,Religion2020,1950,2
Hartford Seminary,Religion2020,1950,9
Harvard Divinity School,Religion2020,1950,1
Harvard School of Public Health,Religion2020,1950,3
Harvard University,Religion2020,1950,14
"Hunter College, CUNY",Religion2020,1950,1
Indiana University,Religion2020,1950,1
Institute for Advanced Study,Religion2020,1950,1
Jewish Theological Seminary,Religion2020,1950,2
King's College London,Religion2020,1950,1
Laval University,Cooperation2021,1950,1
Lehigh University,Religion2020,1950,2
London School of Economics,Religion2020,1950,3
Long Island University,Religion2020,1950,4
Loughborough University,Religion2020,1950,1
Louisiana State University,Cooperation2021,1950,1
Louisiana State University,Religion2020,1950,1
Loyola University Chicago,Religion2020,1950,1
Loyola University New Orleans,Religion2020,1950,2
Manchester University,Religion2020,1950,1
McCormick Theological Seminary,Religion2020,1950,6
Methodist Theological School,Religion2020,1950,1
Mills College,Religion2020,1950,6
Nanking University,Religion2020,1950,11
National Council of Churches,Religion2020,1950,1
Oberlin College,Religion2020,1950,1
Pacific School of Religion,Religion2020,1950,5
Pacifica Graduate Institute,Religion2020,1950,1
Pennsylvania State University,Religion2020,1950,1
Perkins School of Theology,Religion2020,1950,10
Pomona College,Religion2020,1950,1
Pontifical Biblical Institute,Religion2020,1950,2
Princeton Theological Seminary,Religion2020,1950,18
Princeton University,Cooperation2021,1950,2
Princeton University,Religion2020,1950,22
Randolph-Macon Woman’s College,Religion2020,1950,13
Religious Studies,Religion2020,1950,5
Saint Louis University,Religion2020,1950,1
Shadyside Presbyterian Church,Religion2020,1950,6
Sorbonne University,Religion2020,1950,2
Southern Baptist Theological Seminary,Religion2020,1950,1
St. Louis University,Religion2020,1950,3
St. Mary's College,Religion2020,1950,5
Stanford University,Cooperation2021,1950,1
Stanford University,Religion2020,1950,2
Stony Brook University,Cooperation2021,1950,1
Stony Brook University,Religion2020,1950,3
Syracuse University,Religion2020,1950,2
"Teachers College, Columbia University",Religion2020,1950,6
Temple University,Religion2020,1950,1
The New School,Religion2020,1950,1
The University of Rochester School of Medicine,Religion2020,1950,1
Trinity College,Religion2020,1950,2
Ulster University,Religion2020,1950,1
Union Theological Seminary,Cooperation2021,1950,1
Union Theological Seminary,CooperationAndReligion,1950,1
Union Theological Seminary,Religion2020,1950,7
University College London,Cooperation2021,1950,4
University of Alberta,Religion2020,1950,1
University of Amsterdam,Religion2020,1950,1
University of Basel,Religion2020,1950,2
University of Bridgeport,Religion2020,1950,2
University of Buffalo,Religion2020,1950,1
University of California - Davis,Religion2020,1950,1
University of California - Riverside,Religion2020,1950,2
University of California - Santa Barbara,Religion2020,1950,1
University of Cambridge,Religion2020,1950,4
University of Cape Town,Religion2020,1950,1
University of Chicago,Cooperation2021,1950,4
University of Chicago,Religion2020,1950,33
University of Chicago Divinity School,Religion2020,1950,4
University of Denver,Religion2020,1950,3
University of Edinburgh,Religion2020,1950,11
University of Glasgow,Religion2020,1950,2
University of Hawai‘i,Cooperation2021,1950,1
University of Hawai‘i,Religion2020,1950,1
University of Iowa,Religion2020,1950,6
University of Leeds,Religion2020,1950,2
University of Liverpool,Religion2020,1950,3
University of London,Religion2020,1950,10
University of Lund,Religion2020,1950,9
University of Marburg,Religion2020,1950,1
University of Massachusetts - Amherst,Religion2020,1950,1
University of Michigan,Religion2020,1950,6
University of Minnesota,Religion2020,1950,3
University of Munich,Religion2020,1950,3
University of Nebraska,Religion2020,1950,1
"University of Nebraska, Omaha",Religion2020,1950,2
University of North Carolina at Chapel Hill,Religion2020,1950,6
University of Notre Dame,Religion2020,1950,4
University of Nottingham,Religion2020,1950,1
University of Oxford,Religion2020,1950,3
University of Pennsylvania,Religion2020,1950,9
University of Pennsylvania Program for Mindfulness,Religion2020,1950,14
University of Pittsburgh,Religion2020,1950,1
University of San Francisco,Religion2020,1950,1
University of South Carolina,Religion2020,1950,3
University of South Dakota,Religion2020,1950,9
University of St Andrews,Religion2020,1950,10
University of Sydney,Religion2020,1950,1
University of the South,Religion2020,1950,3
University of Toronto,Religion2020,1950,1
University of Vermont,Cooperation2021,1950,1
University of Virginia,Cooperation2021,1950,1
University of Virginia,Religion2020,1950,8
University of Wales Trinity Saint David,Religion2020,1950,1
University of Western Australia,Religion2020,1950,2
University of Wisconsin - Madison,Religion2020,1950,5
Wesley Theological Seminary,Religion2020,1950,4
Westfield College,Religion2020,1950,1
Woodstock College,Religion2020,1950,1
Yale Divinity School,Religion2020,1950,3
Yale University,Religion2020,1950,14
Amherst College,Religion2020,1945,1
Andover Newton Theological School,Religion2020,1945,1
Auburn Theological Seminary,Religion2020,1945,4
Boston Museum of Fine Arts,Religion2020,1945,3
Boston University,Religion2020,1945,27
Cambridge University,Religion2020,1945,4
Catholic University of America,Religion2020,1945,2
Chicago Theological Seminary,Religion2020,1945,1
Christ's College,Religion2020,1945,1
Claremont Graduate University,Religion2020,1945,6
Colby College,Religion2020,1945,1
Colgate Rochester Divinity School,Religion2020,1945,4
College of France,Religion2020,1945,1
College of Wooster,Religion2020,1945,4
Colorado State University,Religion2020,1945,1
Columbia University,Religion2020,1945,8
Cornell University,Religion2020,1945,3
Crozer Theological Seminary,Religion2020,1945,4
Dropsie College,Religion2020,1945,3
Duke Divinity School,Religion2020,1945,1
Duke University,Religion2020,1945,2
Emory University,Religion2020,1945,2
Florida Southern College,Religion2020,1945,1
Garrett-Evangelical Theological Seminary,Religion2020,1945,1
George Mason University,Religion2020,1945,1
George Washington University,Cooperation2021,1945,2
George Washington University,Religion2020,1945,3
Hartford Seminary,Religion2020,1945,11
Harvard Divinity School,Religion2020,1945,2
Harvard School of Public Health,Cooperation2021,1945,1
Harvard School of Public Health,Religion2020,1945,1
Harvard University,Religion2020,1945,14
Hofstra University,Cooperation2021,1945,2
Hofstra University,Religion2020,1945,1
"Hunter College, CUNY",Religion2020,1945,1
Indiana University,Cooperation2021,1945,1
Indiana University,Religion2020,1945,1
Jewish Theological Seminary,Religion2020,1945,2
John Hopkins School of Medicine,Cooperation2021,1945,1
John Hopkins University,Religion2020,1945,2
King's College London,Religion2020,1945,1
London School of Economics,Religion2020,1945,1
McCormick Theological Seminary,Religion2020,1945,6
Michigan State University,Religion2020,1945,1
Mills College,Religion2020,1945,5
Nanking University,Religion2020,1945,4
National Council of Churches,Religion2020,1945,6
New York University,Cooperation2021,1945,1
Oberlin College,Religion2020,1945,10
Ohio State University,Religion2020,1945,1
Oxford University,Religion2020,1945,2
Pacific School of Religion,Religion2020,1945,4
Perkins School of Theology,Religion2020,1945,9
Pittsburg State University,Cooperation2021,1945,1
Presbyterian Church (U.S.A.),Religion2020,1945,1
Princeton Theological Seminary,Religion2020,1945,14
Princeton University,Religion2020,1945,4
Randolph-Macon Woman’s College,Religion2020,1945,1
Religious Studies,Religion2020,1945,1
Rutgers University,Cooperation2021,1945,1
Saint Louis University,Religion2020,1945,1
Shadyside Presbyterian Church,Religion2020,1945,12
St. Louis University,Religion2020,1945,5
St. Mary's College,Religion2020,1945,2
Stanford University,Religion2020,1945,3
Stony Brook University,Religion2020,1945,5
Swansea University,Religion2020,1945,1
"Teachers College, Columbia University",Religion2020,1945,3
Union Theological Seminary,Religion2020,1945,10
University of Aberdeen,Religion2020,1945,1
University of Adelaide,Religion2020,1945,2
University of Amsterdam,Religion2020,1945,7
University of Bridgeport,Religion2020,1945,1
University of Buffalo,Religion2020,1945,3
University of California - Berkeley,Religion2020,1945,3
University of California - Davis,Religion2020,1945,1
University of Cambridge,Religion2020,1945,6
University of Chicago,Cooperation2021,1945,3
University of Chicago,Religion2020,1945,24
University of Edinburgh,Cooperation2021,1945,2
University of Edinburgh,Religion2020,1945,13
University of Hawai‘i,Religion2020,1945,3
University of Hawai‘i–West O‘ahu,Religion2020,1945,2
University of Iowa,Religion2020,1945,4
University of Leeds,Religion2020,1945,2
University of London,Religion2020,1945,10
University of Lund,Cooperation2021,1945,1
University of Lund,CooperationAndReligion,1945,1
University of Lund,Religion2020,1945,3
University of Manchester,Cooperation2021,1945,1
University of Manchester,Religion2020,1945,1
University of Marburg,Religion2020,1945,1
University of Michigan,Religion2020,1945,5
University of Minnesota,Religion2020,1945,1
University of Munich,Religion2020,1945,1
University of Nebraska,Religion2020,1945,6
"University of Nebraska, Omaha",Religion2020,1945,2
University of North Carolina at Chapel Hill,Religion2020,1945,4
University of Notre Dame,Religion2020,1945,3
University of Oxford,Religion2020,1945,4
University of Pennsylvania,Religion2020,1945,2
University of Pennsylvania Program for Mindfulness,Religion2020,1945,2
University of Pittsburgh,Religion2020,1945,1
University of Reading,Religion2020,1945,1
University of South Dakota,Religion2020,1945,2
University of St Andrews,Religion2020,1945,5
University of Virginia,Religion2020,1945,2
University of Wisconsin - Madison,Religion2020,1945,3
Wesley Theological Seminary,Religion2020,1945,8
Williams College,Religion2020,1945,1
Woodstock College,Religion2020,1945,3
Yale Divinity School,Religion2020,1945,5
Yale University,Religion2020,1945,12
 Stuckinski Centre for Alzheimer’s Disease Care and Research,Religion2020,1940,1
Auburn Theological Seminary,Religion2020,1940,6
Bishop of Guilford,Religion2020,1940,1
Boston Museum of Fine Arts,Religion2020,1940,1
Boston University,Cooperation2021,1940,1
Boston University,Religion2020,1940,16
Cambridge University,Cooperation2021,1940,1
Chicago Theological Seminary,Religion2020,1940,2
Claremont Graduate University,Religion2020,1940,14
Colby College,Religion2020,1940,2
Colgate Rochester Divinity School,Religion2020,1940,2
Colorado State University,Religion2020,1940,1
Columbia University,Religion2020,1940,3
Cornell University,Religion2020,1940,2
Dartmouth College,Religion2020,1940,1
Dropsie College,Religion2020,1940,8
Duke Divinity School,Religion2020,1940,3
Emory University,Religion2020,1940,6
Florida State University,Religion2020,1940,1
Hartford Seminary,Religion2020,1940,9
Harvard Divinity School,Religion2020,1940,2
Harvard University,Cooperation2021,1940,1
Harvard University,Religion2020,1940,13
Haverford College,Religion2020,1940,1
Hofstra University,Cooperation2021,1940,3
Hofstra University,Religion2020,1940,1
"Hunter College, CUNY",Religion2020,1940,5
Jewish Theological Seminary,Religion2020,1940,6
King's College London,Religion2020,1940,5
Lancaster University,Religion2020,1940,1
McCormick Theological Seminary,Religion2020,1940,3
Michigan State University,Religion2020,1940,2
Mills College,Religion2020,1940,11
Mount Sinai School of Medicine,Religion2020,1940,1
National Council of Churches,Religion2020,1940,1
Oberlin College,Religion2020,1940,3
Oxford University,Religion2020,1940,1
Pacific School of Religion,Religion2020,1940,2
Pennsylvania State College,Cooperation2021,1940,3
Perkins School of Theology,Religion2020,1940,4
Presbyterian Church (U.S.A.),Religion2020,1940,2
Princeton Theological Seminary,Religion2020,1940,3
Princeton University,Religion2020,1940,10
Radcliffe College,Religion2020,1940,6
Rutgers University,Religion2020,1940,2
"Teachers College, Columbia University",Religion2020,1940,3
Tel Aviv University,Religion2020,1940,1
The New School,Religion2020,1940,1
Union Theological Seminary,Religion2020,1940,14
University College London,Religion2020,1940,2
University of Aberdeen,Religion2020,1940,5
University of Amsterdam,Religion2020,1940,2
University of Cambridge,Religion2020,1940,3
University of Chicago,Cooperation2021,1940,2
University of Chicago,Religion2020,1940,28
University of Chicago Divinity School,Religion2020,1940,2
University of Edinburgh,Religion2020,1940,4
University of Iowa,Religion2020,1940,1
University of London,Religion2020,1940,4
University of Lund,Religion2020,1940,7
University of Manchester,Cooperation2021,1940,1
University of Manchester,Religion2020,1940,2
University of Michigan,Religion2020,1940,5
University of Minnesota,Religion2020,1940,1
University of North Carolina at Chapel Hill,Religion2020,1940,1
University of Notre Dame,Cooperation2021,1940,1
University of Notre Dame,Religion2020,1940,4
University of Oxford,Religion2020,1940,3
University of Pennsylvania,Religion2020,1940,10
University of Pittsburgh,Religion2020,1940,2
University of Reading,Religion2020,1940,3
University of South Dakota,Religion2020,1940,5
University of St Andrews,Religion2020,1940,1
University of the South,Religion2020,1940,1
University of Wisconsin - Madison,Religion2020,1940,7
Wellesley College,Religion2020,1940,1
Woodstock College,Religion2020,1940,4
Yale Divinity School,Religion2020,1940,3
Yale University,Religion2020,1940,9
Amherst College,Religion2020,1935,2
Andover Newton Theological School,Religion2020,1935,1
Auburn Theological Seminary,Religion2020,1935,5
Boston Museum of Fine Arts,Religion2020,1935,2
Burgholzli Asylum,Religion2020,1935,1
Cambridge University,Religion2020,1935,5
Chicago Theological Seminary,Religion2020,1935,1
Claremont Graduate University,Religion2020,1935,7
Colby College,Religion2020,1935,3
Colgate Rochester Divinity School,Religion2020,1935,3
College of Wooster,Religion2020,1935,2
Columbia University,Religion2020,1935,9
Dartmouth College,Religion2020,1935,1
Dropsie College,Religion2020,1935,1
Duke Divinity School,Religion2020,1935,2
Duke University,Religion2020,1935,2
Florida Southern College,Religion2020,1935,3
Garrett-Evangelical Theological Seminary,Religion2020,1935,1
Hartford Seminary,Religion2020,1935,4
Harvard Divinity School,Religion2020,1935,2
Harvard University,Religion2020,1935,12
Hofstra University,Cooperation2021,1935,1
"Hunter College, CUNY",Religion2020,1935,4
Jewish Theological Seminary,Religion2020,1935,1
King's College London,Religion2020,1935,2
London School of Economics,Religion2020,1935,1
Meadville Lombard Theological School,Religion2020,1935,1
Miami University,Religion2020,1935,1
Michigan State University,Religion2020,1935,6
Mills College,Religion2020,1935,4
Moorpark College,Cooperation2021,1935,1
Nanking University,Religion2020,1935,1
National Council of Churches,Religion2020,1935,2
New College London,Religion2020,1935,3
Oxford University,Religion2020,1935,2
Pacific School of Religion,Religion2020,1935,3
Pennsylvania State College,Cooperation2021,1935,1
Pennsylvania State College,Religion2020,1935,2
Perkins School of Theology,Religion2020,1935,4
Presbyterian Church (U.S.A.),Religion2020,1935,6
Princeton University,Religion2020,1935,2
Radcliffe College,Religion2020,1935,6
Stanford University,Religion2020,1935,3
Swansea University,Religion2020,1935,2
Temple University,Religion2020,1935,1
"Trinity College, Cambridge",Religion2020,1935,2
Tufts University,Cooperation2021,1935,1
Union Theological Seminary,Religion2020,1935,12
University College London,Religion2020,1935,1
University of Amsterdam,Religion2020,1935,1
University of Basel,Religion2020,1935,1
University of Birmingham,Religion2020,1935,2
University of Cairo,Religion2020,1935,4
University of Cambridge,Cooperation2021,1935,1
University of Cambridge,Religion2020,1935,5
University of Chicago,Religion2020,1935,28
University of Chicago Divinity School,Religion2020,1935,1
University of Edinburgh,Religion2020,1935,5
University of London,Religion2020,1935,3
University of Lund,Religion2020,1935,1
University of Manchester,Cooperation2021,1935,1
University of Manchester,Religion2020,1935,1
University of Michigan,Religion2020,1935,3
"University of Nebraska, Omaha",Religion2020,1935,1
University of Notre Dame,Religion2020,1935,1
University of Oxford,Cooperation2021,1935,1
University of Oxford,Religion2020,1935,4
University of Pennsylvania,Religion2020,1935,11
University of Reading,Religion2020,1935,1
University of South Dakota,Religion2020,1935,1
University of St Andrews,Religion2020,1935,8
University of Sydney,Religion2020,1935,1
University of Tasmania,Religion2020,1935,1
University of Wisconsin - Madison,Religion2020,1935,1
Wesleyan University,Religion2020,1935,1
Williams College,Religion2020,1935,1
Yale Divinity School,Religion2020,1935,2
Yale University,Religion2020,1935,3
Amherst College,Religion2020,1930,2
Boston University,Religion2020,1930,4
Bryn Mawr College,Religion2020,1930,1
Cambridge University,Religion2020,1930,2
Claremont Graduate University,Cooperation2021,1930,1
Colby College,Religion2020,1930,3
Colgate Rochester Divinity School,Religion2020,1930,1
College of Wooster,Religion2020,1930,3
Columbia University,Religion2020,1930,5
Cornell University,Religion2020,1930,2
Dartmouth College,Religion2020,1930,3
Duke Divinity School,Religion2020,1930,1
Duke University,Religion2020,1930,6
Emory University,Religion2020,1930,3
Florida Southern College,Cooperation2021,1930,1
Florida Southern College,Religion2020,1930,1
Garrett-Evangelical Theological Seminary,Religion2020,1930,5
George Washington University,Cooperation2021,1930,1
George Washington University,Religion2020,1930,2
Greater India Research Committee,Religion2020,1930,1
Hartford Seminary,Religion2020,1930,4
Harvard School of Public Health,Cooperation2021,1930,1
Harvard School of Public Health,Religion2020,1930,1
Harvard University,Religion2020,1930,15
Haverford College,Religion2020,1930,2
Hebrew Union College,Religion2020,1930,1
Institute of Archaeology,Religion2020,1930,1
Jewish Theological Seminary,Religion2020,1930,3
King's College London,Religion2020,1930,1
London School of Economics,Religion2020,1930,1
McCormick Theological Seminary,Religion2020,1930,1
Meadville Lombard Theological School,Religion2020,1930,9
Michigan State University,Religion2020,1930,1
Mills College,Religion2020,1930,8
Nanking University,Religion2020,1930,1
National Council of Churches,Religion2020,1930,3
New College London,Religion2020,1930,5
Northwestern University,Religion2020,1930,2
Oxford University,Religion2020,1930,5
Pacific School of Religion,Religion2020,1930,1
Pennsylvania State College,Cooperation2021,1930,1
Perkins School of Theology,Religion2020,1930,1
Presbyterian Church (U.S.A.),Religion2020,1930,1
Princeton University,Religion2020,1930,3
Radcliffe College,Religion2020,1930,1
Rochester Theological Seminary,Religion2020,1930,2
Stanford University,Religion2020,1930,1
"Teachers College, Columbia University",Religion2020,1930,1
Temple University,Religion2020,1930,1
"Trinity College, Cambridge",Religion2020,1930,1
Union Theological Seminary,Religion2020,1930,9
University College London,Religion2020,1930,2
University of Amsterdam,Religion2020,1930,1
University of Cairo,Religion2020,1930,3
University of Cambridge,Cooperation2021,1930,1
University of Cambridge,Religion2020,1930,7
University of Chicago,Religion2020,1930,33
University of Chicago Divinity School,Religion2020,1930,1
University of Edinburgh,Religion2020,1930,10
University of Glasgow,Religion2020,1930,7
University of London,Religion2020,1930,8
University of Michigan,Religion2020,1930,3
University of Oxford,Religion2020,1930,1
University of Pennsylvania,Religion2020,1930,16
University of Reading,Religion2020,1930,2
University of South Dakota,Religion2020,1930,1
University of St Andrews,Religion2020,1930,14
University of Tasmania,Religion2020,1930,2
University of Toronto,Religion2020,1930,1
University of Wisconsin - Madison,Religion2020,1930,1
Wesleyan University,Religion2020,1930,1
Williams College,Religion2020,1930,3
Yale Divinity School,Religion2020,1930,1
Yale University,Cooperation2021,1930,4
Yale University,Religion2020,1930,7
Amherst College,Religion2020,1925,3
Auburn Theological Seminary,Religion2020,1925,2
Boston Museum of Fine Arts,Religion2020,1925,3
Boston University,Religion2020,1925,5
Bryn Mawr College,Religion2020,1925,11
California Institute of Technology,Religion2020,1925,5
Cambridge University,Religion2020,1925,4
Catholic University of America,Religion2020,1925,6
Claremont Graduate University,Religion2020,1925,1
Colby College,Religion2020,1925,3
Columbia University,Religion2020,1925,9
Cornell University,Religion2020,1925,2
Dartmouth College,Religion2020,1925,2
Duke University,Cooperation2021,1925,1
Duke University,Religion2020,1925,4
Florida Southern College,Religion2020,1925,2
George Washington University,Cooperation2021,1925,2
George Washington University,Religion2020,1925,2
Hartford Seminary,Religion2020,1925,2
Harvard School of Public Health,Religion2020,1925,1
Harvard University,Religion2020,1925,10
Haverford College,Religion2020,1925,1
Hebrew Union College,Religion2020,1925,1
"Hunter College, CUNY",Religion2020,1925,2
John Hopkins University,Religion2020,1925,1
King's College London,Religion2020,1925,6
London School of Economics,Religion2020,1925,8
Meadville Lombard Theological School,Religion2020,1925,1
Mills College,Religion2020,1925,3
New York University,Religion2020,1925,1
Northwestern University,Religion2020,1925,5
Oxford University,Religion2020,1925,5
Pacific School of Religion,Religion2020,1925,1
Pennsylvania State College,Religion2020,1925,2
Princeton University,Religion2020,1925,1
Smith College,Religion2020,1925,2
Stanford University,Religion2020,1925,4
Temple University,Religion2020,1925,1
Tufts University,Religion2020,1925,2
Union Theological Seminary,Religion2020,1925,5
University College London,Cooperation2021,1925,1
University College London,Religion2020,1925,3
University of Aberdeen,Religion2020,1925,1
University of Birmingham,Religion2020,1925,1
University of California - Berkeley,Cooperation2021,1925,1
University of California - Davis,Cooperation2021,1925,1
University of Cambridge,Religion2020,1925,3
University of Chicago,Cooperation2021,1925,1
University of Chicago,Religion2020,1925,24
University of Chicago Divinity School,Religion2020,1925,11
University of Edinburgh,Religion2020,1925,3
University of Glasgow,Religion2020,1925,5
University of London,Religion2020,1925,5
University of Lund,Religion2020,1925,2
University of Michigan,Religion2020,1925,2
University of Notre Dame,Religion2020,1925,1
University of Oxford,Religion2020,1925,1
University of Pennsylvania,Religion2020,1925,7
University of Pittsburgh,Religion2020,1925,1
University of Reading,Religion2020,1925,1
University of St Andrews,Religion2020,1925,5
University of Toronto,Religion2020,1925,1
Williams College,Religion2020,1925,2
Yale Divinity School,Religion2020,1925,3
Yale University,Cooperation2021,1925,1
Yale University,Religion2020,1925,9
Zionist Federation of Great Britain and Ireland,Religion2020,1925,1
Banaras Hindu University,Religion2020,1920,3
Bryn Mawr College,Religion2020,1920,6
Cambridge University,Religion2020,1920,1
Cardiff University,Religion2020,1920,2
Colby College,Religion2020,1920,1
Columbia University,Religion2020,1920,6
Dartmouth College,Religion2020,1920,5
Duke University,Cooperation2021,1920,2
Duke University,Religion2020,1920,1
Florida Southern College,Religion2020,1920,4
Hartford Seminary,Religion2020,1920,1
Harvard University,Religion2020,1920,12
Haverford College,Religion2020,1920,1
"Hunter College, CUNY",Religion2020,1920,1
Indian Antiquary,Religion2020,1920,1
Indian Civil Service,Religion2020,1920,1
Jewish Theological Seminary,Religion2020,1920,1
King's College London,Religion2020,1920,1
London School of Economics,Cooperation2021,1920,1
London School of Economics,Religion2020,1920,8
Meadville Lombard Theological School,Religion2020,1920,1
Mills College,Religion2020,1920,1
Nanking University,Religion2020,1920,2
National Council of Churches,Religion2020,1920,1
newham college,Religion2020,1920,1
Northwestern University,Religion2020,1920,8
Oxford University,Religion2020,1920,2
Pacific School of Religion,Religion2020,1920,2
Pennsylvania State College,Cooperation2021,1920,1
Pennsylvania State College,Religion2020,1920,2
Rochester Theological Seminary,Religion2020,1920,3
Southern Baptist Theological Seminary,Religion2020,1920,2
Union Theological Seminary,Religion2020,1920,5
University College London,Religion2020,1920,1
University College Utrecht,Cooperation2021,1920,1
University College Utrecht,Religion2020,1920,1
University of Alaska,Cooperation2021,1920,2
University of Amsterdam,Cooperation2021,1920,1
University of Amsterdam,Religion2020,1920,1
University of Cairo,Religion2020,1920,2
University of California - Berkeley,Religion2020,1920,2
University of Cambridge,Religion2020,1920,10
University of Chicago,Religion2020,1920,6
University of Chicago Divinity School,Religion2020,1920,11
University of Edinburgh,Religion2020,1920,2
University of Glasgow,Religion2020,1920,1
University of London,Religion2020,1920,6
University of Manchester,Religion2020,1920,1
University of Michigan,Religion2020,1920,1
University of Minnesota,Religion2020,1920,1
University of Pennsylvania,Religion2020,1920,6
University of Reading,Religion2020,1920,1
University of St Andrews,Religion2020,1920,4
University of Wisconsin-Madison,Cooperation2021,1920,1
Wellesley College,Religion2020,1920,1
Wesleyan University,Religion2020,1920,1
Williams College,Religion2020,1920,10
Yale University,Religion2020,1920,4
Zionist Federation of Great Britain and Ireland,Religion2020,1920,1
Amherst College,Religion2020,1915,1
Banaras Hindu University,Religion2020,1915,2
Boston University,Religion2020,1915,1
Bryn Mawr College,Religion2020,1915,14
California Institute of Technology,Religion2020,1915,9
College of France,Religion2020,1915,1
Columbia University,Religion2020,1915,10
Dartmouth College,Religion2020,1915,4
Duke University,Cooperation2021,1915,4
Duke University,Religion2020,1915,1
Florida Southern College,Religion2020,1915,8
Hartford Seminary,Religion2020,1915,4
Harvard University,Religion2020,1915,14
Haverford College,Religion2020,1915,4
Indian Antiquary,Religion2020,1915,3
Indian Civil Service,Religion2020,1915,3
Kyoto University,Religion2020,1915,1
Meadville Lombard Theological School,Religion2020,1915,4
newham college,Religion2020,1915,1
Northwestern University,Religion2020,1915,1
Oxford University,Religion2020,1915,6
Pacific School of Religion,Religion2020,1915,4
Pennsylvania State College,Cooperation2021,1915,1
Rochester Theological Seminary,Religion2020,1915,12
Smith College,Religion2020,1915,3
Southern Baptist Theological Seminary,Religion2020,1915,1
Union Theological Seminary,Religion2020,1915,6
University College London,Religion2020,1915,1
University of California - Berkeley,Religion2020,1915,1
University of Cambridge,Religion2020,1915,4
University of Chicago,Religion2020,1915,13
University of Chicago Divinity School,Religion2020,1915,16
University of Cincinnati,Cooperation2021,1915,1
University of Edinburgh,Religion2020,1915,2
University of London,Religion2020,1915,2
University of Minnesota,Religion2020,1915,1
University of Paris,Religion2020,1915,5
University of Pennsylvania,Religion2020,1915,8
University of St Andrews,Religion2020,1915,1
University of Tasmania,Religion2020,1915,1
Wellesley College,Religion2020,1915,2
Wesleyan University,Religion2020,1915,3
Western University,Religion2020,1915,5
Williams College,Religion2020,1915,5
Yale University,Religion2020,1915,11
Zionist Federation of Great Britain and Ireland,Religion2020,1915,1
Bryn Mawr College,Religion2020,1910,12
California Institute of Technology,Religion2020,1910,2
Cambridge University,Religion2020,1910,1
Columbia University,Religion2020,1910,8
Duke University,Cooperation2021,1910,1
Duke University,Religion2020,1910,1
Florida Southern College,Religion2020,1910,9
Hartford Seminary,Religion2020,1910,4
Harvard University,Cooperation2021,1910,1
Harvard University,Religion2020,1910,15
Haverford College,Religion2020,1910,1
John Hopkins University,Religion2020,1910,1
Lancaster University,Religion2020,1910,1
London Metropolitan University,Religion2020,1910,1
London School of Economics,Religion2020,1910,5
Meadville Lombard Theological School,Religion2020,1910,1
newham college,Religion2020,1910,2
Northwestern University,Religion2020,1910,2
Rochester Theological Seminary,Religion2020,1910,4
Smith College,Religion2020,1910,2
Southern Baptist Theological Seminary,Religion2020,1910,2
The New School,Religion2020,1910,2
Union Theological Seminary,Religion2020,1910,22
University College London,Cooperation2021,1910,1
University College London,Religion2020,1910,1
University of Alaska,Cooperation2021,1910,2
University of Alaska,Religion2020,1910,1
University of Birmingham,Religion2020,1910,3
University of California - Berkeley,Religion2020,1910,2
University of Cambridge,Religion2020,1910,8
University of Chicago,Cooperation2021,1910,1
University of Chicago,Religion2020,1910,22
University of Chicago Divinity School,Religion2020,1910,19
University of Cincinnati,Religion2020,1910,8
University of Durham,Religion2020,1910,1
University of Edinburgh,Religion2020,1910,5
University of London,Religion2020,1910,6
University of Oxford,Religion2020,1910,2
University of Pennsylvania,Religion2020,1910,12
University of St Andrews,Religion2020,1910,2
University of Tartu,Religion2020,1910,1
University of Tasmania,Religion2020,1910,5
University of Toronto,Religion2020,1910,1
University of Warwick,Religion2020,1910,1
Wellesley College,Religion2020,1910,4
Wesleyan University,Religion2020,1910,9
Western University,Religion2020,1910,2
Williams College,Religion2020,1910,2
Yale University,Religion2020,1910,5
Zionist Federation of Great Britain and Ireland,Religion2020,1910,2
Boston Museum of Fine Arts,Religion2020,1905,3
Bryn Mawr College,Religion2020,1905,6
California Institute of Technology,Religion2020,1905,1
Columbia University,Religion2020,1905,7
Duke University,Cooperation2021,1905,1
Duke University,Religion2020,1905,2
Florida Southern College,Religion2020,1905,2
Florida State University,Religion2020,1905,1
Harvard University,Religion2020,1905,4
Haverford College,Religion2020,1905,1
"Hunter College, CUNY",Religion2020,1905,1
John Hopkins University,Religion2020,1905,1
Meadville Lombard Theological School,Religion2020,1905,2
newham college,Religion2020,1905,1
Oxford University,Religion2020,1905,1
Pacific School of Religion,Religion2020,1905,2
Rochester Theological Seminary,Religion2020,1905,3
Smith College,Religion2020,1905,5
Southern Baptist Theological Seminary,Religion2020,1905,2
Union Theological Seminary,Religion2020,1905,9
University College London,Religion2020,1905,1
University of Cambridge,Religion2020,1905,14
University of Chicago,Religion2020,1905,14
University of Chicago Divinity School,Religion2020,1905,7
University of Durham,Religion2020,1905,1
University of Edinburgh,Religion2020,1905,5
University of Oxford,Religion2020,1905,1
University of Pennsylvania,Religion2020,1905,3
University of Southampton,Religion2020,1905,1
University of Southern California,Religion2020,1905,7
University of St Andrews,Religion2020,1905,1
University of Strassburg,Religion2020,1905,1
University of Toronto,Religion2020,1905,1
Wellesley College,Religion2020,1905,1
Wesleyan University,Religion2020,1905,1
Williams College,Religion2020,1905,5
Yale University,Religion2020,1905,3
Australian National University,Religion2020,1900,1
Boston Museum of Fine Arts,Religion2020,1900,3
Bryn Mawr College,Religion2020,1900,6
Church of England,Religion2020,1900,1
Claremont Graduate University,Religion2020,1900,1
College of France,Religion2020,1900,9
Columbia University,Religion2020,1900,15
Harvard University,Religion2020,1900,7
John Hopkins University,Religion2020,1900,2
Leiden University,Religion2020,1900,1
newham college,Religion2020,1900,1
Oxford University,Religion2020,1900,1
Princeton University,Religion2020,1900,1
Smith College,Religion2020,1900,3
Southern Baptist Theological Seminary,Religion2020,1900,2
Union Theological Seminary,Religion2020,1900,2
University College London,Cooperation2021,1900,3
University of Birmingham,Religion2020,1900,2
University of California - Santa Barbara,Religion2020,1900,8
University of Cambridge,Religion2020,1900,4
University of Chicago,Religion2020,1900,35
University of Chicago Divinity School,Religion2020,1900,10
University of Freiburg,Religion2020,1900,6
University of Michigan,Cooperation2021,1900,2
University of Michigan,Religion2020,1900,2
University of Pennsylvania,Religion2020,1900,1
University of Southampton,Religion2020,1900,3
University of Southern California,Religion2020,1900,7
University of Strassburg,Religion2020,1900,1
University of Wisconsin-Madison,Cooperation2021,1900,6
Wesleyan University,Religion2020,1900,1
Yale University,Religion2020,1900,2
Boston Museum of Fine Arts,Religion2020,1895,3
Bryn Mawr College,Religion2020,1895,1
Cardiff University,Religion2020,1895,2
Columbia University,Religion2020,1895,4
Florida State University,Religion2020,1895,1
Harvard University,Cooperation2021,1895,1
Harvard University,Religion2020,1895,1
Princeton University,Religion2020,1895,10
Rochester Theological Seminary,Religion2020,1895,1
Smith College,Religion2020,1895,2
University College London,Cooperation2021,1895,2
University of California - Santa Barbara,Religion2020,1895,2
University of Cambridge,Religion2020,1895,1
University of Chicago,Religion2020,1895,33
University of Durham,Religion2020,1895,2
University of Freiburg,Religion2020,1895,4
University of Michigan,Religion2020,1895,8
University of Pennsylvania,Religion2020,1895,3
University of Southern California,Religion2020,1895,1
University of Wisconsin-Madison,Cooperation2021,1895,12
University of Wisconsin-Madison,Religion2020,1895,1
Yale University,Religion2020,1895,1
Zionist Federation of Great Britain and Ireland,Religion2020,1895,1
California Institute of Technology,Religion2020,1890,2
Cardiff University,Religion2020,1890,4
Emmanuel College,Religion2020,1890,1
Florida State University,Religion2020,1890,1
Georgetown University,Religion2020,1890,1
Harvard University,Religion2020,1890,4
Oxford University,Religion2020,1890,1
Union Theological Seminary,Religion2020,1890,1
University College London,Cooperation2021,1890,1
University of Birmingham,Religion2020,1890,2
University of Chicago,Religion2020,1890,2
University of Freiburg,Religion2020,1890,4
University of Michigan,Religion2020,1890,4
University of Oxford,Religion2020,1890,1
University of Pennsylvania,Religion2020,1890,2
University of Southampton,Religion2020,1890,6
University of Virginia,Religion2020,1890,1
Harvard University,Religion2020,1885,1
University of Cambridge,Religion2020,1885,1
University of Oxford,Religion2020,1885,1
University of Southampton,Religion2020,1885,1
University of Tübingen,Religion2020,1885,9
Institute of Archaeology,Religion2020,1880,1
University of Marburg,Religion2020,1880,8
University of Oxford,Religion2020,1880,1
New York University,Cooperation2021,1875,1
New York University,Religion2020,1875,8
University of Oxford,Religion2020,1875,1
Institute of Archaeology,Cooperation2021,1865,1
Church of England,Religion2020,1860,1
Sanskrit College,Religion2020,1860,1
University of Aberdeen,Religion2020,1855,1
Church of England,Religion2020,1850,1
Indiana University,Religion2020,1850,1
Sanskrit College,Religion2020,1850,2
University College Cork,Religion2020,1850,1
University of Cambridge,Religion2020,1850,1
Church of England,Religion2020,1845,2
Sanskrit College,Religion2020,1845,2
Church of Northampton,Cooperation2021,1840,1
Church of Northampton,CooperationAndReligion,1840,1
Church of Northampton,Religion2020,1840,5
Duke University,Religion2020,1840,1
Church of England,Religion2020,1835,1
Sanskrit College,Religion2020,1835,1
Church of Northampton,Religion2020,1830,1
St Mary's University Church,Religion2020,1830,1
Church of England,Religion2020,1825,1
Church of Northampton,Religion2020,1825,10
Church of England,Religion2020,1820,1
Church of Northampton,Religion2020,1820,1
`

export default institution;