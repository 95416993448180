const citation_cluster_colors = {
    '224': 'rgba(0,222,255,255)',
    '617': 'rgba(255,110,19,255)',
    '1014': 'rgba(45,220,0,255)',
    '332': 'rgba(255,106,243,255)',
    '980': 'rgba(81,70,23,255)',
    '187': 'rgba(80,80,133,255)',
    '978': 'rgb(255,88,136, 255)',
    '698': 'rgba(119,205,202,255)',
    '773': 'rgba(234,155,0,255)',
    '308': 'rgba(0,185,255,255)',
    '565': 'rgba(241,171,150,255)',
    '353': 'rgba(175,201,108,255)',
    '100': 'rgb(0,205,181)',
    '340': 'rgba(226,175,233,255)',
    '80': 'rgba(0,215,103,255)'
}

export default citation_cluster_colors;