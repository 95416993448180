const deptrank = `deptTypeName,lastRank,collectionName,pentaSliceEnd,pubCount
Divinity,Adjunct Professor,Cooperation2021,2010,1
Divinity,Associate Professor,Cooperation2021,1985,1
Divinity,Non-Academic,Cooperation2021,2010,1
Divinity,Postdoctoral Fellow,Cooperation2021,1970,1
Divinity,Professor,Cooperation2021,1950,1
Divinity,Professor,Cooperation2021,1975,1
Divinity,Professor,Cooperation2021,1985,2
Divinity,Professor,Cooperation2021,1995,1
Divinity,Professor,Cooperation2021,2000,2
Divinity,Professor,Cooperation2021,2005,1
Divinity,Professor,Cooperation2021,2010,4
Divinity,Professor Emeritus,Cooperation2021,1955,2
Divinity,Professor Emeritus,Cooperation2021,2000,1
Divinity,Professor Emeritus,Cooperation2021,2015,1
Divinity,Researcher,Cooperation2021,1840,1
Divinity,Researcher,Cooperation2021,2000,1
Economics,,Cooperation2021,2000,2
Economics,,Cooperation2021,2005,4
Economics,,Cooperation2021,2010,5
Economics,,Cooperation2021,2015,7
Economics,Adjunct Professor,Cooperation2021,1985,3
Economics,Adjunct Professor,Cooperation2021,1995,5
Economics,Adjunct Professor,Cooperation2021,2000,13
Economics,Adjunct Professor,Cooperation2021,2005,21
Economics,Adjunct Professor,Cooperation2021,2010,41
Economics,Adjunct Professor,Cooperation2021,2015,25
Economics,Assistant Professor,Cooperation2021,1995,2
Economics,Assistant Professor,Cooperation2021,2000,7
Economics,Assistant Professor,Cooperation2021,2005,21
Economics,Assistant Professor,Cooperation2021,2010,76
Economics,Assistant Professor,Cooperation2021,2015,79
Economics,Associate Professor,Cooperation2021,1980,2
Economics,Associate Professor,Cooperation2021,1990,3
Economics,Associate Professor,Cooperation2021,1995,3
Economics,Associate Professor,Cooperation2021,2000,11
Economics,Associate Professor,Cooperation2021,2005,49
Economics,Associate Professor,Cooperation2021,2010,104
Economics,Associate Professor,Cooperation2021,2015,126
Economics,Non-Academic,Cooperation2021,2000,4
Economics,Non-Academic,Cooperation2021,2005,10
Economics,Non-Academic,Cooperation2021,2010,12
Economics,Non-Academic,Cooperation2021,2015,11
Economics,Postdoctoral Fellow,Cooperation2021,1995,2
Economics,Postdoctoral Fellow,Cooperation2021,2000,2
Economics,Postdoctoral Fellow,Cooperation2021,2005,5
Economics,Postdoctoral Fellow,Cooperation2021,2010,33
Economics,Postdoctoral Fellow,Cooperation2021,2015,24
Economics,Professor,Cooperation2021,1895,12
Economics,Professor,Cooperation2021,1900,6
Economics,Professor,Cooperation2021,1920,1
Economics,Professor,Cooperation2021,1960,3
Economics,Professor,Cooperation2021,1965,3
Economics,Professor,Cooperation2021,1970,4
Economics,Professor,Cooperation2021,1980,14
Economics,Professor,Cooperation2021,1985,26
Economics,Professor,Cooperation2021,1990,53
Economics,Professor,Cooperation2021,1995,94
Economics,Professor,Cooperation2021,2000,228
Economics,Professor,Cooperation2021,2005,363
Economics,Professor,Cooperation2021,2010,593
Economics,Professor,Cooperation2021,2015,492
Economics,Professor Emeritus,Cooperation2021,1940,3
Economics,Professor Emeritus,Cooperation2021,1950,1
Economics,Professor Emeritus,Cooperation2021,1955,2
Economics,Professor Emeritus,Cooperation2021,1960,1
Economics,Professor Emeritus,Cooperation2021,1975,4
Economics,Professor Emeritus,Cooperation2021,1980,3
Economics,Professor Emeritus,Cooperation2021,1985,7
Economics,Professor Emeritus,Cooperation2021,1990,13
Economics,Professor Emeritus,Cooperation2021,1995,24
Economics,Professor Emeritus,Cooperation2021,2000,24
Economics,Professor Emeritus,Cooperation2021,2005,46
Economics,Professor Emeritus,Cooperation2021,2010,26
Economics,Professor Emeritus,Cooperation2021,2015,28
Economics,Researcher,Cooperation2021,1975,1
Economics,Researcher,Cooperation2021,1985,3
Economics,Researcher,Cooperation2021,1995,7
Economics,Researcher,Cooperation2021,2000,14
Economics,Researcher,Cooperation2021,2005,35
Economics,Researcher,Cooperation2021,2010,75
Economics,Researcher,Cooperation2021,2015,44
Education,,Cooperation2021,1990,2
Education,,Cooperation2021,2000,2
Education,,Cooperation2021,2005,1
Education,Assistant Professor,Cooperation2021,2015,1
Education,Associate Professor,Cooperation2021,1980,1
Education,Associate Professor,Cooperation2021,2000,2
Education,Associate Professor,Cooperation2021,2005,1
Education,Associate Professor,Cooperation2021,2010,1
Education,Associate Professor,Cooperation2021,2015,5
Education,Non-Academic,Cooperation2021,2000,3
Education,Non-Academic,Cooperation2021,2005,4
Education,Non-Academic,Cooperation2021,2010,7
Education,Non-Academic,Cooperation2021,2015,2
Education,Professor,Cooperation2021,1970,1
Education,Professor,Cooperation2021,1980,1
Education,Professor,Cooperation2021,1985,1
Education,Professor,Cooperation2021,1995,4
Education,Professor,Cooperation2021,2000,9
Education,Professor,Cooperation2021,2005,16
Education,Professor,Cooperation2021,2010,22
Education,Professor,Cooperation2021,2015,22
Education,Professor Emeritus,Cooperation2021,1970,1
Education,Professor Emeritus,Cooperation2021,1975,2
Education,Professor Emeritus,Cooperation2021,1980,3
Education,Professor Emeritus,Cooperation2021,1985,6
Education,Professor Emeritus,Cooperation2021,1990,5
Education,Professor Emeritus,Cooperation2021,1995,8
Education,Professor Emeritus,Cooperation2021,2000,7
Education,Professor Emeritus,Cooperation2021,2005,4
Education,Professor Emeritus,Cooperation2021,2010,8
Education,Professor Emeritus,Cooperation2021,2015,4
Education,Researcher,Cooperation2021,2000,2
Education,Researcher,Cooperation2021,2005,3
Education,Researcher,Cooperation2021,2010,10
Education,Researcher,Cooperation2021,2015,1
Engineering,,Cooperation2021,2005,2
Engineering,,Cooperation2021,2010,18
Engineering,,Cooperation2021,2015,14
Engineering,Adjunct Professor,Cooperation2021,2000,1
Engineering,Adjunct Professor,Cooperation2021,2005,3
Engineering,Adjunct Professor,Cooperation2021,2010,15
Engineering,Adjunct Professor,Cooperation2021,2015,19
Engineering,Assistant Professor,Cooperation2021,2010,2
Engineering,Assistant Professor,Cooperation2021,2015,8
Engineering,Associate Professor,Cooperation2021,1985,1
Engineering,Associate Professor,Cooperation2021,1990,1
Engineering,Associate Professor,Cooperation2021,2000,4
Engineering,Associate Professor,Cooperation2021,2005,14
Engineering,Associate Professor,Cooperation2021,2010,42
Engineering,Associate Professor,Cooperation2021,2015,23
Engineering,Professor,Cooperation2021,1995,3
Engineering,Professor,Cooperation2021,2000,8
Engineering,Professor,Cooperation2021,2005,37
Engineering,Professor,Cooperation2021,2010,80
Engineering,Professor,Cooperation2021,2015,45
Engineering,Professor Emeritus,Cooperation2021,2005,9
Engineering,Researcher,Cooperation2021,1975,1
Engineering,Researcher,Cooperation2021,1980,1
Engineering,Researcher,Cooperation2021,1985,1
Engineering,Researcher,Cooperation2021,2000,5
Engineering,Researcher,Cooperation2021,2005,3
Engineering,Researcher,Cooperation2021,2010,2
English,Adjunct Professor,Cooperation2021,1990,1
English,Adjunct Professor,Cooperation2021,2000,1
English,Adjunct Professor,Cooperation2021,2010,1
English,Assistant Professor,Cooperation2021,2015,1
English,Professor,Cooperation2021,1940,1
English,Professor,Cooperation2021,1970,1
English,Professor,Cooperation2021,1975,1
English,Professor,Cooperation2021,1985,3
English,Professor,Cooperation2021,1990,1
English,Professor,Cooperation2021,2000,3
English,Professor,Cooperation2021,2005,4
English,Professor,Cooperation2021,2010,5
English,Professor,Cooperation2021,2015,7
English,Professor Emeritus,Cooperation2021,1970,1
English,Professor Emeritus,Cooperation2021,1975,1
English,Professor Emeritus,Cooperation2021,2005,1
English,Professor Emeritus,Cooperation2021,2010,1
English,Professor Emeritus,Cooperation2021,2015,1
Geography,,Cooperation2021,2000,2
Geography,,Cooperation2021,2005,1
Geography,Adjunct Professor,Cooperation2021,1985,1
Geography,Adjunct Professor,Cooperation2021,2000,2
Geography,Adjunct Professor,Cooperation2021,2005,1
Geography,Assistant Professor,Cooperation2021,2005,4
Geography,Assistant Professor,Cooperation2021,2010,3
Geography,Assistant Professor,Cooperation2021,2015,1
Geography,Associate Professor,Cooperation2021,1995,2
Geography,Associate Professor,Cooperation2021,2000,2
Geography,Associate Professor,Cooperation2021,2005,2
Geography,Associate Professor,Cooperation2021,2010,6
Geography,Associate Professor,Cooperation2021,2015,2
Geography,Professor,Cooperation2021,1935,1
Geography,Professor,Cooperation2021,1940,1
Geography,Professor,Cooperation2021,1945,1
Geography,Professor,Cooperation2021,1985,1
Geography,Professor,Cooperation2021,1990,1
Geography,Professor,Cooperation2021,1995,5
Geography,Professor,Cooperation2021,2000,12
Geography,Professor,Cooperation2021,2005,15
Geography,Professor,Cooperation2021,2010,14
Geography,Professor,Cooperation2021,2015,9
Geography,Professor Emeritus,Cooperation2021,1970,1
Geography,Professor Emeritus,Cooperation2021,1980,2
Geography,Professor Emeritus,Cooperation2021,1990,2
Geography,Professor Emeritus,Cooperation2021,2000,1
Health,,Cooperation2021,1970,1
Health,,Cooperation2021,1975,7
Health,,Cooperation2021,1995,1
Health,,Cooperation2021,2000,3
Health,,Cooperation2021,2005,8
Health,,Cooperation2021,2010,9
Health,,Cooperation2021,2015,9
Health,Adjunct Professor,Cooperation2021,1980,1
Health,Adjunct Professor,Cooperation2021,1995,1
Health,Adjunct Professor,Cooperation2021,2000,7
Health,Adjunct Professor,Cooperation2021,2005,15
Health,Adjunct Professor,Cooperation2021,2010,18
Health,Adjunct Professor,Cooperation2021,2015,8
Health,Assistant Professor,Cooperation2021,1970,2
Health,Assistant Professor,Cooperation2021,1990,2
Health,Assistant Professor,Cooperation2021,1995,1
Health,Assistant Professor,Cooperation2021,2000,1
Health,Assistant Professor,Cooperation2021,2005,5
Health,Assistant Professor,Cooperation2021,2010,7
Health,Assistant Professor,Cooperation2021,2015,15
Health,Associate Professor,Cooperation2021,2000,8
Health,Associate Professor,Cooperation2021,2005,8
Health,Associate Professor,Cooperation2021,2010,21
Health,Associate Professor,Cooperation2021,2015,16
Health,Non-Academic,Cooperation2021,1970,1
Health,Non-Academic,Cooperation2021,1990,1
Health,Non-Academic,Cooperation2021,1995,6
Health,Non-Academic,Cooperation2021,2000,16
Health,Non-Academic,Cooperation2021,2005,18
Health,Non-Academic,Cooperation2021,2010,27
Health,Non-Academic,Cooperation2021,2015,17
Health,Professor,Cooperation2021,1970,1
Health,Professor,Cooperation2021,1975,1
Health,Professor,Cooperation2021,1980,1
Health,Professor,Cooperation2021,1985,5
Health,Professor,Cooperation2021,1990,4
Health,Professor,Cooperation2021,1995,12
Health,Professor,Cooperation2021,2000,27
Health,Professor,Cooperation2021,2005,44
Health,Professor,Cooperation2021,2010,64
Health,Professor,Cooperation2021,2015,32
Health,Professor Emeritus,Cooperation2021,1965,3
Health,Professor Emeritus,Cooperation2021,1970,1
Health,Professor Emeritus,Cooperation2021,1980,1
Health,Professor Emeritus,Cooperation2021,1985,3
Health,Professor Emeritus,Cooperation2021,1990,1
Health,Professor Emeritus,Cooperation2021,2000,6
Health,Professor Emeritus,Cooperation2021,2005,12
Health,Professor Emeritus,Cooperation2021,2010,7
Health,Professor Emeritus,Cooperation2021,2015,2
Health,Researcher,Cooperation2021,1965,1
Health,Researcher,Cooperation2021,1970,2
Health,Researcher,Cooperation2021,1975,17
Health,Researcher,Cooperation2021,1980,1
Health,Researcher,Cooperation2021,1990,1
Health,Researcher,Cooperation2021,1995,4
Health,Researcher,Cooperation2021,2000,9
Health,Researcher,Cooperation2021,2005,26
Health,Researcher,Cooperation2021,2010,52
Health,Researcher,Cooperation2021,2015,28
History,,Cooperation2021,1955,2
History,,Cooperation2021,2010,1
History,Adjunct Professor,Cooperation2021,1980,1
History,Adjunct Professor,Cooperation2021,1985,2
History,Adjunct Professor,Cooperation2021,2000,2
History,Adjunct Professor,Cooperation2021,2010,10
History,Adjunct Professor,Cooperation2021,2015,4
History,Assistant Professor,Cooperation2021,2010,7
History,Assistant Professor,Cooperation2021,2015,11
History,Associate Professor,Cooperation2021,1975,1
History,Associate Professor,Cooperation2021,1985,1
History,Associate Professor,Cooperation2021,1995,1
History,Associate Professor,Cooperation2021,2000,2
History,Associate Professor,Cooperation2021,2005,5
History,Associate Professor,Cooperation2021,2010,5
History,Associate Professor,Cooperation2021,2015,1
History,Postdoctoral Fellow,Cooperation2021,2000,1
History,Postdoctoral Fellow,Cooperation2021,2015,4
History,Professor,Cooperation2021,1920,1
History,Professor,Cooperation2021,1950,1
History,Professor,Cooperation2021,1955,1
History,Professor,Cooperation2021,1960,1
History,Professor,Cooperation2021,1965,4
History,Professor,Cooperation2021,1975,1
History,Professor,Cooperation2021,1980,4
History,Professor,Cooperation2021,1985,1
History,Professor,Cooperation2021,1990,8
History,Professor,Cooperation2021,1995,6
History,Professor,Cooperation2021,2000,20
History,Professor,Cooperation2021,2005,11
History,Professor,Cooperation2021,2010,18
History,Professor,Cooperation2021,2015,40
History,Professor Emeritus,Cooperation2021,1955,1
History,Professor Emeritus,Cooperation2021,1965,1
History,Professor Emeritus,Cooperation2021,1970,5
History,Professor Emeritus,Cooperation2021,1975,3
History,Professor Emeritus,Cooperation2021,1980,5
History,Professor Emeritus,Cooperation2021,1985,3
History,Professor Emeritus,Cooperation2021,1990,5
History,Professor Emeritus,Cooperation2021,1995,2
History,Professor Emeritus,Cooperation2021,2000,9
History,Professor Emeritus,Cooperation2021,2005,3
History,Professor Emeritus,Cooperation2021,2010,3
History,Professor Emeritus,Cooperation2021,2015,4
History,Researcher,Cooperation2021,2000,3
History,Researcher,Cooperation2021,2005,1
History,Researcher,Cooperation2021,2010,1
History,Researcher,Cooperation2021,2015,1
Islamic Studies,Adjunct Professor,Cooperation2021,2015,1
Islamic Studies,Assistant Professor,Cooperation2021,2015,3
Islamic Studies,Associate Professor,Cooperation2021,2010,3
Islamic Studies,Non-Academic,Cooperation2021,2010,1
Islamic Studies,Professor,Cooperation2021,2000,1
Islamic Studies,Professor,Cooperation2021,2005,1
Islamic Studies,Professor,Cooperation2021,2010,3
Islamic Studies,Professor,Cooperation2021,2015,1
Islamic Studies,Professor Emeritus,Cooperation2021,2015,1
Islamic Studies,Researcher,Cooperation2021,2015,1
Law,,Cooperation2021,2000,1
Law,,Cooperation2021,2005,1
Law,,Cooperation2021,2010,3
Law,Associate Professor,Cooperation2021,1990,1
Law,Associate Professor,Cooperation2021,2000,1
Law,Associate Professor,Cooperation2021,2005,2
Law,Associate Professor,Cooperation2021,2010,4
Law,Associate Professor,Cooperation2021,2015,4
Law,Postdoctoral Fellow,Cooperation2021,2010,2
Law,Postdoctoral Fellow,Cooperation2021,2015,1
Law,Professor,Cooperation2021,1970,4
Law,Professor,Cooperation2021,1985,3
Law,Professor,Cooperation2021,1990,3
Law,Professor,Cooperation2021,1995,4
Law,Professor,Cooperation2021,2000,13
Law,Professor,Cooperation2021,2005,25
Law,Professor,Cooperation2021,2010,52
Law,Professor,Cooperation2021,2015,24
Law,Professor Emeritus,Cooperation2021,1965,2
Law,Professor Emeritus,Cooperation2021,1970,1
Law,Professor Emeritus,Cooperation2021,1975,7
Law,Professor Emeritus,Cooperation2021,1980,1
Law,Professor Emeritus,Cooperation2021,1985,2
Law,Professor Emeritus,Cooperation2021,1990,3
Law,Professor Emeritus,Cooperation2021,1995,1
Law,Professor Emeritus,Cooperation2021,2000,2
Law,Professor Emeritus,Cooperation2021,2005,2
Law,Professor Emeritus,Cooperation2021,2010,10
Law,Researcher,Cooperation2021,1975,1
Law,Researcher,Cooperation2021,2000,1
Law,Researcher,Cooperation2021,2015,9
Management,Associate Professor,Cooperation2021,1995,1
Management,Associate Professor,Cooperation2021,2000,2
Management,Associate Professor,Cooperation2021,2005,8
Management,Associate Professor,Cooperation2021,2010,17
Management,Associate Professor,Cooperation2021,2015,24
Management,Non-Academic,Cooperation2021,2005,1
Management,Non-Academic,Cooperation2021,2010,1
Management,Non-Academic,Cooperation2021,2015,11
Management,Postdoctoral Fellow,Cooperation2021,2010,1
Management,Postdoctoral Fellow,Cooperation2021,2015,3
Management,Professor,Cooperation2021,1980,1
Management,Professor,Cooperation2021,1990,4
Management,Professor,Cooperation2021,1995,8
Management,Professor,Cooperation2021,2000,28
Management,Professor,Cooperation2021,2005,49
Management,Professor,Cooperation2021,2010,85
Management,Professor,Cooperation2021,2015,99
Management,Professor Emeritus,Cooperation2021,1965,2
Management,Professor Emeritus,Cooperation2021,1970,1
Management,Professor Emeritus,Cooperation2021,1975,3
Management,Professor Emeritus,Cooperation2021,1980,6
Management,Professor Emeritus,Cooperation2021,1985,18
Management,Professor Emeritus,Cooperation2021,1990,11
Management,Professor Emeritus,Cooperation2021,1995,21
Management,Professor Emeritus,Cooperation2021,2000,14
Management,Professor Emeritus,Cooperation2021,2005,12
Management,Professor Emeritus,Cooperation2021,2010,5
Management,Professor Emeritus,Cooperation2021,2015,6
Management,Researcher,Cooperation2021,2000,1
Mathematics,,Cooperation2021,2000,1
Mathematics,,Cooperation2021,2010,9
Mathematics,,Cooperation2021,2015,3
Mathematics,Adjunct Professor,Cooperation2021,1980,1
Mathematics,Assistant Professor,Cooperation2021,1990,1
Mathematics,Assistant Professor,Cooperation2021,2000,2
Mathematics,Assistant Professor,Cooperation2021,2005,4
Mathematics,Assistant Professor,Cooperation2021,2010,5
Mathematics,Assistant Professor,Cooperation2021,2015,6
Mathematics,Associate Professor,Cooperation2021,1995,3
Mathematics,Associate Professor,Cooperation2021,2000,8
Mathematics,Associate Professor,Cooperation2021,2005,27
Mathematics,Associate Professor,Cooperation2021,2010,40
Mathematics,Associate Professor,Cooperation2021,2015,39
Mathematics,Graduate Student,Cooperation2021,2015,10
Mathematics,Non-Academic,Cooperation2021,2015,11
Mathematics,Postdoctoral Fellow,Cooperation2021,2005,1
Mathematics,Postdoctoral Fellow,Cooperation2021,2010,10
Mathematics,Postdoctoral Fellow,Cooperation2021,2015,25
Mathematics,Professor,Cooperation2021,1975,1
Mathematics,Professor,Cooperation2021,1980,1
Mathematics,Professor,Cooperation2021,1985,9
Mathematics,Professor,Cooperation2021,1990,13
Mathematics,Professor,Cooperation2021,1995,19
Mathematics,Professor,Cooperation2021,2000,39
Mathematics,Professor,Cooperation2021,2005,93
Mathematics,Professor,Cooperation2021,2010,120
Mathematics,Professor,Cooperation2021,2015,119
Mathematics,Professor Emeritus,Cooperation2021,1970,1
Mathematics,Professor Emeritus,Cooperation2021,1975,1
Mathematics,Professor Emeritus,Cooperation2021,1980,4
Mathematics,Professor Emeritus,Cooperation2021,1985,5
Mathematics,Professor Emeritus,Cooperation2021,1990,2
Mathematics,Professor Emeritus,Cooperation2021,1995,7
Mathematics,Professor Emeritus,Cooperation2021,2000,6
Mathematics,Professor Emeritus,Cooperation2021,2005,4
Mathematics,Professor Emeritus,Cooperation2021,2010,9
Mathematics,Professor Emeritus,Cooperation2021,2015,8
Mathematics,Researcher,Cooperation2021,2000,1
Mathematics,Researcher,Cooperation2021,2005,6
Mathematics,Researcher,Cooperation2021,2010,8
Mathematics,Researcher,Cooperation2021,2015,31
Medicine,,Cooperation2021,1980,1
Medicine,,Cooperation2021,1995,4
Medicine,,Cooperation2021,2000,6
Medicine,,Cooperation2021,2005,6
Medicine,,Cooperation2021,2010,26
Medicine,,Cooperation2021,2015,14
Medicine,Adjunct Professor,Cooperation2021,1970,1
Medicine,Adjunct Professor,Cooperation2021,1980,1
Medicine,Adjunct Professor,Cooperation2021,1995,2
Medicine,Adjunct Professor,Cooperation2021,2000,7
Medicine,Adjunct Professor,Cooperation2021,2005,8
Medicine,Adjunct Professor,Cooperation2021,2010,15
Medicine,Adjunct Professor,Cooperation2021,2015,17
Medicine,Assistant Professor,Cooperation2021,1975,1
Medicine,Assistant Professor,Cooperation2021,2000,1
Medicine,Assistant Professor,Cooperation2021,2005,9
Medicine,Assistant Professor,Cooperation2021,2010,24
Medicine,Assistant Professor,Cooperation2021,2015,19
Medicine,Associate Professor,Cooperation2021,1960,1
Medicine,Associate Professor,Cooperation2021,1970,1
Medicine,Associate Professor,Cooperation2021,1975,1
Medicine,Associate Professor,Cooperation2021,1990,2
Medicine,Associate Professor,Cooperation2021,1995,3
Medicine,Associate Professor,Cooperation2021,2000,14
Medicine,Associate Professor,Cooperation2021,2005,29
Medicine,Associate Professor,Cooperation2021,2010,54
Medicine,Associate Professor,Cooperation2021,2015,68
Medicine,Graduate Student,Cooperation2021,2010,1
Medicine,Non-Academic,Cooperation2021,1965,4
Medicine,Non-Academic,Cooperation2021,1970,4
Medicine,Non-Academic,Cooperation2021,1975,6
Medicine,Non-Academic,Cooperation2021,1980,1
Medicine,Non-Academic,Cooperation2021,1985,5
Medicine,Non-Academic,Cooperation2021,1995,1
Medicine,Non-Academic,Cooperation2021,2000,9
Medicine,Non-Academic,Cooperation2021,2005,16
Medicine,Non-Academic,Cooperation2021,2010,29
Medicine,Non-Academic,Cooperation2021,2015,33
Medicine,Postdoctoral Fellow,Cooperation2021,2010,1
Medicine,Postdoctoral Fellow,Cooperation2021,2015,14
Medicine,Professor,Cooperation2021,1945,1
Medicine,Professor,Cooperation2021,1950,1
Medicine,Professor,Cooperation2021,1955,1
Medicine,Professor,Cooperation2021,1965,1
Medicine,Professor,Cooperation2021,1970,3
Medicine,Professor,Cooperation2021,1975,10
Medicine,Professor,Cooperation2021,1980,12
Medicine,Professor,Cooperation2021,1985,17
Medicine,Professor,Cooperation2021,1990,13
Medicine,Professor,Cooperation2021,1995,18
Medicine,Professor,Cooperation2021,2000,56
Medicine,Professor,Cooperation2021,2005,130
Medicine,Professor,Cooperation2021,2010,136
Medicine,Professor,Cooperation2021,2015,154
Medicine,Professor Emeritus,Cooperation2021,1955,1
Medicine,Professor Emeritus,Cooperation2021,1965,4
Medicine,Professor Emeritus,Cooperation2021,1970,4
Medicine,Professor Emeritus,Cooperation2021,1975,5
Medicine,Professor Emeritus,Cooperation2021,1980,5
Medicine,Professor Emeritus,Cooperation2021,1985,8
Medicine,Professor Emeritus,Cooperation2021,1990,6
Medicine,Professor Emeritus,Cooperation2021,1995,3
Medicine,Professor Emeritus,Cooperation2021,2000,7
Medicine,Professor Emeritus,Cooperation2021,2005,7
Medicine,Professor Emeritus,Cooperation2021,2010,9
Medicine,Researcher,Cooperation2021,1975,1
Medicine,Researcher,Cooperation2021,1980,1
Medicine,Researcher,Cooperation2021,2000,5
Medicine,Researcher,Cooperation2021,2005,19
Medicine,Researcher,Cooperation2021,2010,38
Medicine,Researcher,Cooperation2021,2015,47
NA,,Cooperation2021,1975,1
NA,,Cooperation2021,1980,2
NA,,Cooperation2021,1985,5
NA,,Cooperation2021,1990,5
NA,,Cooperation2021,1995,11
NA,,Cooperation2021,2000,10
NA,,Cooperation2021,2005,30
NA,,Cooperation2021,2010,43
NA,,Cooperation2021,2015,55
NA,Adjunct Professor,Cooperation2021,2000,1
NA,Adjunct Professor,Cooperation2021,2010,4
NA,Adjunct Professor,Cooperation2021,2015,1
NA,Assistant Professor,Cooperation2021,1995,3
NA,Assistant Professor,Cooperation2021,2000,5
NA,Assistant Professor,Cooperation2021,2005,13
NA,Assistant Professor,Cooperation2021,2010,7
NA,Assistant Professor,Cooperation2021,2015,13
NA,Associate Professor,Cooperation2021,2000,1
NA,Associate Professor,Cooperation2021,2005,3
NA,Associate Professor,Cooperation2021,2010,7
NA,Associate Professor,Cooperation2021,2015,20
NA,Non-Academic,Cooperation2021,1955,1
NA,Non-Academic,Cooperation2021,1960,1
NA,Non-Academic,Cooperation2021,1965,1
NA,Non-Academic,Cooperation2021,1970,13
NA,Non-Academic,Cooperation2021,1975,17
NA,Non-Academic,Cooperation2021,1980,4
NA,Non-Academic,Cooperation2021,1985,7
NA,Non-Academic,Cooperation2021,1990,19
NA,Non-Academic,Cooperation2021,1995,38
NA,Non-Academic,Cooperation2021,2000,47
NA,Non-Academic,Cooperation2021,2005,63
NA,Non-Academic,Cooperation2021,2010,107
NA,Non-Academic,Cooperation2021,2015,112
NA,Postdoctoral Fellow,Cooperation2021,2000,1
NA,Postdoctoral Fellow,Cooperation2021,2010,6
NA,Postdoctoral Fellow,Cooperation2021,2015,1
NA,Professor,Cooperation2021,1915,1
NA,Professor,Cooperation2021,1920,1
NA,Professor,Cooperation2021,1930,1
NA,Professor,Cooperation2021,1935,1
NA,Professor,Cooperation2021,1940,3
NA,Professor,Cooperation2021,1965,4
NA,Professor,Cooperation2021,1970,1
NA,Professor,Cooperation2021,1975,1
NA,Professor,Cooperation2021,1980,2
NA,Professor,Cooperation2021,1990,4
NA,Professor,Cooperation2021,1995,9
NA,Professor,Cooperation2021,2000,11
NA,Professor,Cooperation2021,2005,46
NA,Professor,Cooperation2021,2010,54
NA,Professor,Cooperation2021,2015,65
NA,Professor Emeritus,Cooperation2021,1995,3
NA,Professor Emeritus,Cooperation2021,2000,1
NA,Professor Emeritus,Cooperation2021,2005,2
NA,Professor Emeritus,Cooperation2021,2010,3
NA,Professor Emeritus,Cooperation2021,2015,2
NA,Researcher,Cooperation2021,1965,1
NA,Researcher,Cooperation2021,1970,5
NA,Researcher,Cooperation2021,1975,3
NA,Researcher,Cooperation2021,1980,1
NA,Researcher,Cooperation2021,1985,1
NA,Researcher,Cooperation2021,1990,5
NA,Researcher,Cooperation2021,1995,11
NA,Researcher,Cooperation2021,2000,29
NA,Researcher,Cooperation2021,2005,66
NA,Researcher,Cooperation2021,2010,134
NA,Researcher,Cooperation2021,2015,109
Neurology,Associate Professor,Cooperation2021,1995,1
Neurology,Associate Professor,Cooperation2021,2000,2
Neurology,Associate Professor,Cooperation2021,2005,6
Neurology,Associate Professor,Cooperation2021,2010,10
Neurology,Associate Professor,Cooperation2021,2015,6
Neurology,Non-Academic,Cooperation2021,1975,1
Neurology,Professor,Cooperation2021,2000,4
Neurology,Professor,Cooperation2021,2005,3
Neurology,Professor,Cooperation2021,2010,2
Neurology,Professor,Cooperation2021,2015,1
Neurology,Researcher,Cooperation2021,2005,1
Neurology,Researcher,Cooperation2021,2010,7
Neuroscience,,Cooperation2021,2000,2
Neuroscience,,Cooperation2021,2010,6
Neuroscience,,Cooperation2021,2015,10
Neuroscience,Adjunct Professor,Cooperation2021,2010,1
Neuroscience,Assistant Professor,Cooperation2021,2010,1
Neuroscience,Assistant Professor,Cooperation2021,2015,2
Neuroscience,Associate Professor,Cooperation2021,2000,3
Neuroscience,Associate Professor,Cooperation2021,2005,2
Neuroscience,Associate Professor,Cooperation2021,2010,2
Neuroscience,Associate Professor,Cooperation2021,2015,9
Neuroscience,Non-Academic,Cooperation2021,2000,1
Neuroscience,Non-Academic,Cooperation2021,2005,1
Neuroscience,Non-Academic,Cooperation2021,2010,2
Neuroscience,Postdoctoral Fellow,Cooperation2021,2010,1
Neuroscience,Postdoctoral Fellow,Cooperation2021,2015,5
Neuroscience,Professor,Cooperation2021,1970,1
Neuroscience,Professor,Cooperation2021,1975,2
Neuroscience,Professor,Cooperation2021,1980,2
Neuroscience,Professor,Cooperation2021,1985,2
Neuroscience,Professor,Cooperation2021,1990,3
Neuroscience,Professor,Cooperation2021,1995,11
Neuroscience,Professor,Cooperation2021,2000,22
Neuroscience,Professor,Cooperation2021,2005,33
Neuroscience,Professor,Cooperation2021,2010,47
Neuroscience,Professor,Cooperation2021,2015,48
Neuroscience,Professor Emeritus,Cooperation2021,2000,1
Neuroscience,Professor Emeritus,Cooperation2021,2005,1
Neuroscience,Researcher,Cooperation2021,2000,1
Neuroscience,Researcher,Cooperation2021,2005,1
Neuroscience,Researcher,Cooperation2021,2010,13
Neuroscience,Researcher,Cooperation2021,2015,16
Nursing,,Cooperation2021,2000,2
Nursing,,Cooperation2021,2005,6
Nursing,,Cooperation2021,2010,6
Nursing,,Cooperation2021,2015,6
Nursing,Adjunct Professor,Cooperation2021,1995,1
Nursing,Adjunct Professor,Cooperation2021,2005,2
Nursing,Adjunct Professor,Cooperation2021,2010,2
Nursing,Adjunct Professor,Cooperation2021,2015,1
Nursing,Assistant Professor,Cooperation2021,2000,1
Nursing,Assistant Professor,Cooperation2021,2010,7
Nursing,Assistant Professor,Cooperation2021,2015,6
Nursing,Associate Professor,Cooperation2021,2000,1
Nursing,Associate Professor,Cooperation2021,2005,2
Nursing,Associate Professor,Cooperation2021,2010,7
Nursing,Associate Professor,Cooperation2021,2015,11
Nursing,Non-Academic,Cooperation2021,1990,1
Nursing,Non-Academic,Cooperation2021,2005,1
Nursing,Non-Academic,Cooperation2021,2010,3
Nursing,Professor,Cooperation2021,1965,1
Nursing,Professor,Cooperation2021,1970,1
Nursing,Professor,Cooperation2021,1985,3
Nursing,Professor,Cooperation2021,1990,2
Nursing,Professor,Cooperation2021,1995,11
Nursing,Professor,Cooperation2021,2000,23
Nursing,Professor,Cooperation2021,2005,21
Nursing,Professor,Cooperation2021,2010,22
Nursing,Professor,Cooperation2021,2015,19
Nursing,Professor Emeritus,Cooperation2021,1985,1
Nursing,Professor Emeritus,Cooperation2021,1990,1
Nursing,Professor Emeritus,Cooperation2021,1995,1
Nursing,Professor Emeritus,Cooperation2021,2000,4
Nursing,Professor Emeritus,Cooperation2021,2005,5
Nursing,Professor Emeritus,Cooperation2021,2010,3
Nursing,Professor Emeritus,Cooperation2021,2015,3
Nursing,Researcher,Cooperation2021,2010,2
Nursing,Researcher,Cooperation2021,2015,1
Philosophy,,Cooperation2021,1900,2
Philosophy,,Cooperation2021,2000,1
Philosophy,,Cooperation2021,2005,2
Philosophy,,Cooperation2021,2010,2
Philosophy,Adjunct Professor,Cooperation2021,1990,4
Philosophy,Adjunct Professor,Cooperation2021,1995,7
Philosophy,Adjunct Professor,Cooperation2021,2000,4
Philosophy,Adjunct Professor,Cooperation2021,2005,4
Philosophy,Adjunct Professor,Cooperation2021,2010,8
Philosophy,Adjunct Professor,Cooperation2021,2015,3
Philosophy,Assistant Professor,Cooperation2021,1970,5
Philosophy,Assistant Professor,Cooperation2021,1975,1
Philosophy,Assistant Professor,Cooperation2021,1985,2
Philosophy,Assistant Professor,Cooperation2021,1990,1
Philosophy,Assistant Professor,Cooperation2021,2005,2
Philosophy,Assistant Professor,Cooperation2021,2010,9
Philosophy,Assistant Professor,Cooperation2021,2015,9
Philosophy,Associate Professor,Cooperation2021,2000,1
Philosophy,Associate Professor,Cooperation2021,2005,2
Philosophy,Associate Professor,Cooperation2021,2010,13
Philosophy,Associate Professor,Cooperation2021,2015,12
Philosophy,Postdoctoral Fellow,Cooperation2021,2010,1
Philosophy,Professor,Cooperation2021,1895,1
Philosophy,Professor,Cooperation2021,1910,1
Philosophy,Professor,Cooperation2021,1945,2
Philosophy,Professor,Cooperation2021,1970,2
Philosophy,Professor,Cooperation2021,1975,4
Philosophy,Professor,Cooperation2021,1985,5
Philosophy,Professor,Cooperation2021,1990,6
Philosophy,Professor,Cooperation2021,1995,19
Philosophy,Professor,Cooperation2021,2000,19
Philosophy,Professor,Cooperation2021,2005,33
Philosophy,Professor,Cooperation2021,2010,58
Philosophy,Professor,Cooperation2021,2015,51
Philosophy,Professor Emeritus,Cooperation2021,1930,1
Philosophy,Professor Emeritus,Cooperation2021,1940,1
Philosophy,Professor Emeritus,Cooperation2021,1955,1
Philosophy,Professor Emeritus,Cooperation2021,1970,4
Philosophy,Professor Emeritus,Cooperation2021,1975,6
Philosophy,Professor Emeritus,Cooperation2021,1980,3
Philosophy,Professor Emeritus,Cooperation2021,1985,4
Philosophy,Professor Emeritus,Cooperation2021,1990,1
Philosophy,Professor Emeritus,Cooperation2021,1995,9
Philosophy,Professor Emeritus,Cooperation2021,2000,9
Philosophy,Professor Emeritus,Cooperation2021,2005,6
Philosophy,Professor Emeritus,Cooperation2021,2010,1
Philosophy,Professor Emeritus,Cooperation2021,2015,7
Philosophy,Researcher,Cooperation2021,1970,5
Philosophy,Researcher,Cooperation2021,1975,1
Philosophy,Researcher,Cooperation2021,1985,2
Philosophy,Researcher,Cooperation2021,1990,2
Philosophy,Researcher,Cooperation2021,2005,3
Philosophy,Researcher,Cooperation2021,2010,2
Philosophy,Researcher,Cooperation2021,2015,1
Philosophy and Religion,Assistant Professor,Cooperation2021,2015,1
Philosophy and Religion,Non-Academic,Cooperation2021,2000,1
Philosophy and Religion,Professor,Cooperation2021,2005,1
Philosophy and Religion,Professor,Cooperation2021,2015,1
Physics,,Cooperation2021,2005,14
Physics,,Cooperation2021,2010,2
Physics,Assistant Professor,Cooperation2021,2005,20
Physics,Assistant Professor,Cooperation2021,2010,26
Physics,Assistant Professor,Cooperation2021,2015,2
Physics,Associate Professor,Cooperation2021,2000,6
Physics,Associate Professor,Cooperation2021,2005,8
Physics,Associate Professor,Cooperation2021,2010,16
Physics,Associate Professor,Cooperation2021,2015,13
Physics,Professor,Cooperation2021,2000,32
Physics,Professor,Cooperation2021,2005,50
Physics,Professor,Cooperation2021,2010,118
Physics,Professor,Cooperation2021,2015,89
Physics,Researcher,Cooperation2021,1995,2
Physics,Researcher,Cooperation2021,2000,6
Physics,Researcher,Cooperation2021,2005,14
Physics,Researcher,Cooperation2021,2010,43
Physics,Researcher,Cooperation2021,2015,25
Political Science,,Cooperation2021,1965,1
Political Science,Adjunct Professor,Cooperation2021,1995,1
Political Science,Adjunct Professor,Cooperation2021,2005,6
Political Science,Adjunct Professor,Cooperation2021,2010,4
Political Science,Adjunct Professor,Cooperation2021,2015,3
Political Science,Assistant Professor,Cooperation2021,1975,2
Political Science,Assistant Professor,Cooperation2021,1980,8
Political Science,Assistant Professor,Cooperation2021,1985,11
Political Science,Assistant Professor,Cooperation2021,1990,1
Political Science,Assistant Professor,Cooperation2021,1995,4
Political Science,Assistant Professor,Cooperation2021,2000,10
Political Science,Assistant Professor,Cooperation2021,2005,4
Political Science,Assistant Professor,Cooperation2021,2010,8
Political Science,Assistant Professor,Cooperation2021,2015,3
Political Science,Associate Professor,Cooperation2021,2000,2
Political Science,Associate Professor,Cooperation2021,2005,7
Political Science,Associate Professor,Cooperation2021,2010,12
Political Science,Associate Professor,Cooperation2021,2015,13
Political Science,Graduate Student,Cooperation2021,2000,1
Political Science,Non-Academic,Cooperation2021,2010,2
Political Science,Professor,Cooperation2021,1970,1
Political Science,Professor,Cooperation2021,1975,7
Political Science,Professor,Cooperation2021,1980,5
Political Science,Professor,Cooperation2021,1985,15
Political Science,Professor,Cooperation2021,1990,18
Political Science,Professor,Cooperation2021,1995,30
Political Science,Professor,Cooperation2021,2000,48
Political Science,Professor,Cooperation2021,2005,70
Political Science,Professor,Cooperation2021,2010,92
Political Science,Professor,Cooperation2021,2015,57
Political Science,Professor Emeritus,Cooperation2021,1925,1
Political Science,Professor Emeritus,Cooperation2021,1945,1
Political Science,Professor Emeritus,Cooperation2021,1955,1
Political Science,Professor Emeritus,Cooperation2021,1980,1
Political Science,Professor Emeritus,Cooperation2021,1985,5
Political Science,Professor Emeritus,Cooperation2021,1990,5
Political Science,Professor Emeritus,Cooperation2021,1995,5
Political Science,Professor Emeritus,Cooperation2021,2000,12
Political Science,Professor Emeritus,Cooperation2021,2005,6
Political Science,Professor Emeritus,Cooperation2021,2010,8
Political Science,Professor Emeritus,Cooperation2021,2015,2
Political Science,Undergraduate Student,Cooperation2021,2000,1
Psychiatry,,Cooperation2021,1975,3
Psychiatry,,Cooperation2021,1980,1
Psychiatry,,Cooperation2021,1995,2
Psychiatry,,Cooperation2021,2000,7
Psychiatry,,Cooperation2021,2005,5
Psychiatry,,Cooperation2021,2010,9
Psychiatry,,Cooperation2021,2015,13
Psychiatry,Adjunct Professor,Cooperation2021,2000,1
Psychiatry,Adjunct Professor,Cooperation2021,2010,3
Psychiatry,Assistant Professor,Cooperation2021,1945,1
Psychiatry,Assistant Professor,Cooperation2021,1960,1
Psychiatry,Assistant Professor,Cooperation2021,1970,2
Psychiatry,Assistant Professor,Cooperation2021,1975,2
Psychiatry,Assistant Professor,Cooperation2021,1980,1
Psychiatry,Assistant Professor,Cooperation2021,1985,1
Psychiatry,Assistant Professor,Cooperation2021,2000,1
Psychiatry,Assistant Professor,Cooperation2021,2010,4
Psychiatry,Assistant Professor,Cooperation2021,2015,12
Psychiatry,Associate Professor,Cooperation2021,1975,2
Psychiatry,Associate Professor,Cooperation2021,1980,3
Psychiatry,Associate Professor,Cooperation2021,1990,1
Psychiatry,Associate Professor,Cooperation2021,1995,3
Psychiatry,Associate Professor,Cooperation2021,2000,9
Psychiatry,Associate Professor,Cooperation2021,2005,5
Psychiatry,Associate Professor,Cooperation2021,2010,6
Psychiatry,Associate Professor,Cooperation2021,2015,5
Psychiatry,Non-Academic,Cooperation2021,1955,1
Psychiatry,Non-Academic,Cooperation2021,1970,1
Psychiatry,Non-Academic,Cooperation2021,1980,1
Psychiatry,Non-Academic,Cooperation2021,1995,2
Psychiatry,Non-Academic,Cooperation2021,2000,3
Psychiatry,Non-Academic,Cooperation2021,2005,5
Psychiatry,Non-Academic,Cooperation2021,2010,8
Psychiatry,Non-Academic,Cooperation2021,2015,3
Psychiatry,Postdoctoral Fellow,Cooperation2021,2010,1
Psychiatry,Postdoctoral Fellow,Cooperation2021,2015,14
Psychiatry,Professor,Cooperation2021,1970,10
Psychiatry,Professor,Cooperation2021,1975,13
Psychiatry,Professor,Cooperation2021,1980,5
Psychiatry,Professor,Cooperation2021,1985,10
Psychiatry,Professor,Cooperation2021,1990,10
Psychiatry,Professor,Cooperation2021,1995,25
Psychiatry,Professor,Cooperation2021,2000,43
Psychiatry,Professor,Cooperation2021,2005,60
Psychiatry,Professor,Cooperation2021,2010,76
Psychiatry,Professor,Cooperation2021,2015,62
Psychiatry,Professor Emeritus,Cooperation2021,1955,1
Psychiatry,Professor Emeritus,Cooperation2021,1965,2
Psychiatry,Professor Emeritus,Cooperation2021,1970,8
Psychiatry,Professor Emeritus,Cooperation2021,1975,5
Psychiatry,Professor Emeritus,Cooperation2021,1980,4
Psychiatry,Professor Emeritus,Cooperation2021,1985,8
Psychiatry,Professor Emeritus,Cooperation2021,1990,6
Psychiatry,Professor Emeritus,Cooperation2021,1995,4
Psychiatry,Professor Emeritus,Cooperation2021,2000,3
Psychiatry,Professor Emeritus,Cooperation2021,2005,11
Psychiatry,Professor Emeritus,Cooperation2021,2010,4
Psychiatry,Professor Emeritus,Cooperation2021,2015,4
Psychiatry,Researcher,Cooperation2021,1970,2
Psychiatry,Researcher,Cooperation2021,1975,2
Psychiatry,Researcher,Cooperation2021,1980,1
Psychiatry,Researcher,Cooperation2021,1985,1
Psychiatry,Researcher,Cooperation2021,1990,4
Psychiatry,Researcher,Cooperation2021,1995,2
Psychiatry,Researcher,Cooperation2021,2000,6
Psychiatry,Researcher,Cooperation2021,2005,6
Psychiatry,Researcher,Cooperation2021,2010,17
Psychiatry,Researcher,Cooperation2021,2015,37
Psychology,,Cooperation2021,1945,1
Psychology,,Cooperation2021,1960,1
Psychology,,Cooperation2021,1965,1
Psychology,,Cooperation2021,1970,2
Psychology,,Cooperation2021,1975,7
Psychology,,Cooperation2021,1980,4
Psychology,,Cooperation2021,1985,3
Psychology,,Cooperation2021,1990,5
Psychology,,Cooperation2021,1995,6
Psychology,,Cooperation2021,2000,8
Psychology,,Cooperation2021,2005,19
Psychology,,Cooperation2021,2010,26
Psychology,,Cooperation2021,2015,57
Psychology,Adjunct Professor,Cooperation2021,1950,1
Psychology,Adjunct Professor,Cooperation2021,1965,1
Psychology,Adjunct Professor,Cooperation2021,1970,2
Psychology,Adjunct Professor,Cooperation2021,1975,1
Psychology,Adjunct Professor,Cooperation2021,1980,3
Psychology,Adjunct Professor,Cooperation2021,1985,3
Psychology,Adjunct Professor,Cooperation2021,1990,4
Psychology,Adjunct Professor,Cooperation2021,1995,10
Psychology,Adjunct Professor,Cooperation2021,2000,8
Psychology,Adjunct Professor,Cooperation2021,2005,15
Psychology,Adjunct Professor,Cooperation2021,2010,34
Psychology,Adjunct Professor,Cooperation2021,2015,32
Psychology,Assistant Professor,Cooperation2021,1980,5
Psychology,Assistant Professor,Cooperation2021,1985,2
Psychology,Assistant Professor,Cooperation2021,1990,1
Psychology,Assistant Professor,Cooperation2021,1995,3
Psychology,Assistant Professor,Cooperation2021,2000,4
Psychology,Assistant Professor,Cooperation2021,2005,26
Psychology,Assistant Professor,Cooperation2021,2010,61
Psychology,Assistant Professor,Cooperation2021,2015,90
Psychology,Associate Professor,Cooperation2021,1960,1
Psychology,Associate Professor,Cooperation2021,1965,4
Psychology,Associate Professor,Cooperation2021,1970,8
Psychology,Associate Professor,Cooperation2021,1975,1
Psychology,Associate Professor,Cooperation2021,1985,3
Psychology,Associate Professor,Cooperation2021,1990,9
Psychology,Associate Professor,Cooperation2021,1995,11
Psychology,Associate Professor,Cooperation2021,2000,23
Psychology,Associate Professor,Cooperation2021,2005,76
Psychology,Associate Professor,Cooperation2021,2010,158
Psychology,Associate Professor,Cooperation2021,2015,152
Psychology,Graduate Student,Cooperation2021,2010,2
Psychology,Non-Academic,Cooperation2021,1965,6
Psychology,Non-Academic,Cooperation2021,1970,7
Psychology,Non-Academic,Cooperation2021,1975,6
Psychology,Non-Academic,Cooperation2021,1980,2
Psychology,Non-Academic,Cooperation2021,1985,4
Psychology,Non-Academic,Cooperation2021,1990,6
Psychology,Non-Academic,Cooperation2021,1995,7
Psychology,Non-Academic,Cooperation2021,2000,13
Psychology,Non-Academic,Cooperation2021,2005,13
Psychology,Non-Academic,Cooperation2021,2010,17
Psychology,Non-Academic,Cooperation2021,2015,17
Psychology,Postdoctoral Fellow,Cooperation2021,1985,3
Psychology,Postdoctoral Fellow,Cooperation2021,1990,2
Psychology,Postdoctoral Fellow,Cooperation2021,2000,5
Psychology,Postdoctoral Fellow,Cooperation2021,2005,6
Psychology,Postdoctoral Fellow,Cooperation2021,2010,35
Psychology,Postdoctoral Fellow,Cooperation2021,2015,50
Psychology,Professor,Cooperation2021,1910,1
Psychology,Professor,Cooperation2021,1925,2
Psychology,Professor,Cooperation2021,1930,2
Psychology,Professor,Cooperation2021,1945,3
Psychology,Professor,Cooperation2021,1950,1
Psychology,Professor,Cooperation2021,1955,2
Psychology,Professor,Cooperation2021,1960,1
Psychology,Professor,Cooperation2021,1965,24
Psychology,Professor,Cooperation2021,1970,44
Psychology,Professor,Cooperation2021,1975,47
Psychology,Professor,Cooperation2021,1980,23
Psychology,Professor,Cooperation2021,1985,50
Psychology,Professor,Cooperation2021,1990,65
Psychology,Professor,Cooperation2021,1995,113
Psychology,Professor,Cooperation2021,2000,170
Psychology,Professor,Cooperation2021,2005,196
Psychology,Professor,Cooperation2021,2010,400
Psychology,Professor,Cooperation2021,2015,412
Psychology,Professor Emeritus,Cooperation2021,1925,2
Psychology,Professor Emeritus,Cooperation2021,1930,4
Psychology,Professor Emeritus,Cooperation2021,1950,7
Psychology,Professor Emeritus,Cooperation2021,1955,3
Psychology,Professor Emeritus,Cooperation2021,1960,10
Psychology,Professor Emeritus,Cooperation2021,1965,42
Psychology,Professor Emeritus,Cooperation2021,1970,75
Psychology,Professor Emeritus,Cooperation2021,1975,53
Psychology,Professor Emeritus,Cooperation2021,1980,30
Psychology,Professor Emeritus,Cooperation2021,1985,44
Psychology,Professor Emeritus,Cooperation2021,1990,36
Psychology,Professor Emeritus,Cooperation2021,1995,59
Psychology,Professor Emeritus,Cooperation2021,2000,59
Psychology,Professor Emeritus,Cooperation2021,2005,53
Psychology,Professor Emeritus,Cooperation2021,2010,61
Psychology,Professor Emeritus,Cooperation2021,2015,39
Psychology,Researcher,Cooperation2021,1965,1
Psychology,Researcher,Cooperation2021,1970,9
Psychology,Researcher,Cooperation2021,1975,2
Psychology,Researcher,Cooperation2021,1980,5
Psychology,Researcher,Cooperation2021,1985,2
Psychology,Researcher,Cooperation2021,1990,2
Psychology,Researcher,Cooperation2021,1995,9
Psychology,Researcher,Cooperation2021,2000,21
Psychology,Researcher,Cooperation2021,2005,44
Psychology,Researcher,Cooperation2021,2010,74
Psychology,Researcher,Cooperation2021,2015,80
Public Health,Adjunct Professor,Cooperation2021,1970,3
Public Health,Adjunct Professor,Cooperation2021,1975,1
Public Health,Adjunct Professor,Cooperation2021,1980,1
Public Health,Adjunct Professor,Cooperation2021,1985,1
Public Health,Adjunct Professor,Cooperation2021,2005,5
Public Health,Adjunct Professor,Cooperation2021,2010,12
Public Health,Adjunct Professor,Cooperation2021,2015,1
Public Health,Associate Professor,Cooperation2021,2005,4
Public Health,Associate Professor,Cooperation2021,2010,6
Public Health,Associate Professor,Cooperation2021,2015,5
Public Health,Graduate Student,Cooperation2021,2015,1
Public Health,Non-Academic,Cooperation2021,2005,5
Public Health,Non-Academic,Cooperation2021,2010,7
Public Health,Non-Academic,Cooperation2021,2015,6
Public Health,Postdoctoral Fellow,Cooperation2021,2015,7
Public Health,Professor,Cooperation2021,1970,4
Public Health,Professor,Cooperation2021,1975,9
Public Health,Professor,Cooperation2021,1980,4
Public Health,Professor,Cooperation2021,1985,4
Public Health,Professor,Cooperation2021,1990,5
Public Health,Professor,Cooperation2021,1995,9
Public Health,Professor,Cooperation2021,2000,31
Public Health,Professor,Cooperation2021,2005,31
Public Health,Professor,Cooperation2021,2010,50
Public Health,Professor,Cooperation2021,2015,28
Public Health,Professor Emeritus,Cooperation2021,1965,2
Public Health,Professor Emeritus,Cooperation2021,1970,2
Public Health,Professor Emeritus,Cooperation2021,1980,1
Public Health,Professor Emeritus,Cooperation2021,2000,1
Public Health,Professor Emeritus,Cooperation2021,2005,2
Public Health,Professor Emeritus,Cooperation2021,2010,5
Public Health,Professor Emeritus,Cooperation2021,2015,3
Public Health,Researcher,Cooperation2021,1995,2
Public Health,Researcher,Cooperation2021,2000,1
Public Health,Researcher,Cooperation2021,2005,9
Public Health,Researcher,Cooperation2021,2010,13
Public Health,Researcher,Cooperation2021,2015,17
Religion,,Cooperation2021,1970,1
Religion,,Cooperation2021,1980,1
Religion,,Cooperation2021,2000,1
Religion,,Cooperation2021,2005,1
Religion,,Cooperation2021,2010,4
Religion,,Cooperation2021,2015,4
Religion,Adjunct Professor,Cooperation2021,1970,2
Religion,Adjunct Professor,Cooperation2021,1985,2
Religion,Adjunct Professor,Cooperation2021,2005,3
Religion,Adjunct Professor,Cooperation2021,2010,3
Religion,Assistant Professor,Cooperation2021,2010,3
Religion,Assistant Professor,Cooperation2021,2015,9
Religion,Associate Professor,Cooperation2021,1965,2
Religion,Associate Professor,Cooperation2021,1975,3
Religion,Associate Professor,Cooperation2021,1980,6
Religion,Associate Professor,Cooperation2021,1985,6
Religion,Associate Professor,Cooperation2021,1990,4
Religion,Associate Professor,Cooperation2021,1995,2
Religion,Associate Professor,Cooperation2021,2000,1
Religion,Associate Professor,Cooperation2021,2005,5
Religion,Associate Professor,Cooperation2021,2010,10
Religion,Associate Professor,Cooperation2021,2015,9
Religion,Non-Academic,Cooperation2021,1970,1
Religion,Non-Academic,Cooperation2021,1985,3
Religion,Non-Academic,Cooperation2021,1990,2
Religion,Non-Academic,Cooperation2021,1995,1
Religion,Non-Academic,Cooperation2021,2005,3
Religion,Non-Academic,Cooperation2021,2010,3
Religion,Non-Academic,Cooperation2021,2015,2
Religion,Postdoctoral Fellow,Cooperation2021,2010,4
Religion,Postdoctoral Fellow,Cooperation2021,2015,4
Religion,Professor,Cooperation2021,1960,2
Religion,Professor,Cooperation2021,1970,6
Religion,Professor,Cooperation2021,1975,3
Religion,Professor,Cooperation2021,1980,5
Religion,Professor,Cooperation2021,1985,3
Religion,Professor,Cooperation2021,1990,2
Religion,Professor,Cooperation2021,1995,4
Religion,Professor,Cooperation2021,2000,14
Religion,Professor,Cooperation2021,2005,12
Religion,Professor,Cooperation2021,2010,27
Religion,Professor,Cooperation2021,2015,24
Religion,Professor Emeritus,Cooperation2021,1950,1
Religion,Professor Emeritus,Cooperation2021,1960,1
Religion,Professor Emeritus,Cooperation2021,1970,3
Religion,Professor Emeritus,Cooperation2021,1980,4
Religion,Professor Emeritus,Cooperation2021,1985,1
Religion,Professor Emeritus,Cooperation2021,1990,4
Religion,Professor Emeritus,Cooperation2021,1995,5
Religion,Professor Emeritus,Cooperation2021,2000,3
Religion,Professor Emeritus,Cooperation2021,2005,3
Religion,Professor Emeritus,Cooperation2021,2010,7
Religion,Professor Emeritus,Cooperation2021,2015,7
Religion,Researcher,Cooperation2021,1985,1
Religion,Researcher,Cooperation2021,1995,1
Religion,Researcher,Cooperation2021,2000,1
Religion,Researcher,Cooperation2021,2005,5
Religion,Researcher,Cooperation2021,2010,7
Religion,Researcher,Cooperation2021,2015,8
Religious Studies,,Cooperation2021,1970,1
Religious Studies,Adjunct Professor,Cooperation2021,2005,1
Religious Studies,Adjunct Professor,Cooperation2021,2010,1
Religious Studies,Adjunct Professor,Cooperation2021,2015,4
Religious Studies,Associate Professor,Cooperation2021,2005,1
Religious Studies,Associate Professor,Cooperation2021,2010,2
Religious Studies,Associate Professor,Cooperation2021,2015,2
Religious Studies,Professor,Cooperation2021,1970,1
Religious Studies,Professor,Cooperation2021,1975,1
Religious Studies,Professor,Cooperation2021,2000,1
Religious Studies,Professor,Cooperation2021,2005,2
Religious Studies,Professor,Cooperation2021,2010,4
Religious Studies,Professor,Cooperation2021,2015,1
Religious Studies,Professor Emeritus,Cooperation2021,1975,2
Religious Studies,Professor Emeritus,Cooperation2021,2010,2
Research,,Cooperation2021,2010,3
Research,Assistant Professor,Cooperation2021,2010,5
Research,Assistant Professor,Cooperation2021,2015,3
Research,Associate Professor,Cooperation2021,2000,1
Research,Associate Professor,Cooperation2021,2005,2
Research,Associate Professor,Cooperation2021,2010,4
Research,Associate Professor,Cooperation2021,2015,2
Research,Non-Academic,Cooperation2021,2005,1
Research,Professor,Cooperation2021,2000,3
Research,Professor,Cooperation2021,2005,10
Research,Professor,Cooperation2021,2010,16
Research,Professor,Cooperation2021,2015,9
Research,Researcher,Cooperation2021,1980,1
Research,Researcher,Cooperation2021,1985,2
Research,Researcher,Cooperation2021,1995,3
Research,Researcher,Cooperation2021,2000,5
Research,Researcher,Cooperation2021,2005,14
Research,Researcher,Cooperation2021,2010,37
Research,Researcher,Cooperation2021,2015,47
Social Sciences,,Cooperation2021,1995,1
Social Sciences,,Cooperation2021,2000,2
Social Sciences,,Cooperation2021,2005,1
Social Sciences,,Cooperation2021,2010,3
Social Sciences,,Cooperation2021,2015,12
Social Sciences,Adjunct Professor,Cooperation2021,1965,1
Social Sciences,Adjunct Professor,Cooperation2021,1970,2
Social Sciences,Adjunct Professor,Cooperation2021,1975,1
Social Sciences,Adjunct Professor,Cooperation2021,1995,1
Social Sciences,Assistant Professor,Cooperation2021,2000,1
Social Sciences,Assistant Professor,Cooperation2021,2005,9
Social Sciences,Assistant Professor,Cooperation2021,2010,19
Social Sciences,Assistant Professor,Cooperation2021,2015,27
Social Sciences,Associate Professor,Cooperation2021,1990,2
Social Sciences,Associate Professor,Cooperation2021,1995,2
Social Sciences,Associate Professor,Cooperation2021,2000,2
Social Sciences,Associate Professor,Cooperation2021,2005,4
Social Sciences,Associate Professor,Cooperation2021,2010,12
Social Sciences,Associate Professor,Cooperation2021,2015,13
Social Sciences,Non-Academic,Cooperation2021,1950,1
Social Sciences,Non-Academic,Cooperation2021,1955,4
Social Sciences,Non-Academic,Cooperation2021,1960,2
Social Sciences,Non-Academic,Cooperation2021,1965,4
Social Sciences,Non-Academic,Cooperation2021,1985,1
Social Sciences,Non-Academic,Cooperation2021,2005,1
Social Sciences,Non-Academic,Cooperation2021,2010,2
Social Sciences,Postdoctoral Fellow,Cooperation2021,2005,1
Social Sciences,Postdoctoral Fellow,Cooperation2021,2010,4
Social Sciences,Postdoctoral Fellow,Cooperation2021,2015,3
Social Sciences,Professor,Cooperation2021,1975,2
Social Sciences,Professor,Cooperation2021,1980,1
Social Sciences,Professor,Cooperation2021,1985,5
Social Sciences,Professor,Cooperation2021,1990,7
Social Sciences,Professor,Cooperation2021,1995,5
Social Sciences,Professor,Cooperation2021,2000,12
Social Sciences,Professor,Cooperation2021,2005,22
Social Sciences,Professor,Cooperation2021,2010,54
Social Sciences,Professor,Cooperation2021,2015,23
Social Sciences,Professor Emeritus,Cooperation2021,2000,1
Social Sciences,Researcher,Cooperation2021,1995,2
Social Sciences,Researcher,Cooperation2021,2000,1
Social Sciences,Researcher,Cooperation2021,2005,12
Social Sciences,Researcher,Cooperation2021,2010,19
Social Sciences,Researcher,Cooperation2021,2015,16
Social Work,,Cooperation2021,1995,2
Social Work,,Cooperation2021,2000,2
Social Work,,Cooperation2021,2015,1
Social Work,Adjunct Professor,Cooperation2021,2010,2
Social Work,Assistant Professor,Cooperation2021,1985,1
Social Work,Assistant Professor,Cooperation2021,2010,2
Social Work,Associate Professor,Cooperation2021,1990,1
Social Work,Associate Professor,Cooperation2021,1995,2
Social Work,Associate Professor,Cooperation2021,2005,5
Social Work,Associate Professor,Cooperation2021,2010,3
Social Work,Associate Professor,Cooperation2021,2015,3
Social Work,Non-Academic,Cooperation2021,2005,1
Social Work,Non-Academic,Cooperation2021,2015,1
Social Work,Professor,Cooperation2021,1970,2
Social Work,Professor,Cooperation2021,1975,2
Social Work,Professor,Cooperation2021,1980,2
Social Work,Professor,Cooperation2021,1985,1
Social Work,Professor,Cooperation2021,1990,6
Social Work,Professor,Cooperation2021,1995,5
Social Work,Professor,Cooperation2021,2000,18
Social Work,Professor,Cooperation2021,2005,17
Social Work,Professor,Cooperation2021,2010,23
Social Work,Professor,Cooperation2021,2015,17
Social Work,Professor Emeritus,Cooperation2021,1965,2
Social Work,Professor Emeritus,Cooperation2021,1975,1
Social Work,Professor Emeritus,Cooperation2021,1985,1
Social Work,Professor Emeritus,Cooperation2021,1990,1
Social Work,Professor Emeritus,Cooperation2021,1995,10
Social Work,Professor Emeritus,Cooperation2021,2000,6
Social Work,Professor Emeritus,Cooperation2021,2005,4
Social Work,Professor Emeritus,Cooperation2021,2010,6
Social Work,Professor Emeritus,Cooperation2021,2015,3
Social Work,Researcher,Cooperation2021,1970,1
Social Work,Researcher,Cooperation2021,1990,1
Social Work,Researcher,Cooperation2021,2015,1
Sociology,,Cooperation2021,1960,2
Sociology,,Cooperation2021,1965,2
Sociology,,Cooperation2021,1970,1
Sociology,,Cooperation2021,2005,3
Sociology,,Cooperation2021,2010,4
Sociology,,Cooperation2021,2015,3
Sociology,Adjunct Professor,Cooperation2021,1970,3
Sociology,Adjunct Professor,Cooperation2021,1980,1
Sociology,Adjunct Professor,Cooperation2021,1985,2
Sociology,Adjunct Professor,Cooperation2021,1995,1
Sociology,Adjunct Professor,Cooperation2021,2000,9
Sociology,Adjunct Professor,Cooperation2021,2005,4
Sociology,Adjunct Professor,Cooperation2021,2010,6
Sociology,Adjunct Professor,Cooperation2021,2015,3
Sociology,Assistant Professor,Cooperation2021,2000,1
Sociology,Assistant Professor,Cooperation2021,2005,3
Sociology,Assistant Professor,Cooperation2021,2010,36
Sociology,Assistant Professor,Cooperation2021,2015,18
Sociology,Associate Professor,Cooperation2021,1980,2
Sociology,Associate Professor,Cooperation2021,1990,2
Sociology,Associate Professor,Cooperation2021,2000,4
Sociology,Associate Professor,Cooperation2021,2005,15
Sociology,Associate Professor,Cooperation2021,2010,22
Sociology,Associate Professor,Cooperation2021,2015,27
Sociology,Graduate Student,Cooperation2021,2000,1
Sociology,Non-Academic,Cooperation2021,1995,2
Sociology,Non-Academic,Cooperation2021,2010,6
Sociology,Non-Academic,Cooperation2021,2015,3
Sociology,Professor,Cooperation2021,1905,1
Sociology,Professor,Cooperation2021,1910,1
Sociology,Professor,Cooperation2021,1915,5
Sociology,Professor,Cooperation2021,1920,2
Sociology,Professor,Cooperation2021,1925,1
Sociology,Professor,Cooperation2021,1935,1
Sociology,Professor,Cooperation2021,1940,3
Sociology,Professor,Cooperation2021,1945,3
Sociology,Professor,Cooperation2021,1950,1
Sociology,Professor,Cooperation2021,1955,7
Sociology,Professor,Cooperation2021,1960,9
Sociology,Professor,Cooperation2021,1965,10
Sociology,Professor,Cooperation2021,1970,20
Sociology,Professor,Cooperation2021,1975,16
Sociology,Professor,Cooperation2021,1980,14
Sociology,Professor,Cooperation2021,1985,10
Sociology,Professor,Cooperation2021,1990,27
Sociology,Professor,Cooperation2021,1995,50
Sociology,Professor,Cooperation2021,2000,66
Sociology,Professor,Cooperation2021,2005,104
Sociology,Professor,Cooperation2021,2010,121
Sociology,Professor,Cooperation2021,2015,125
Sociology,Professor Emeritus,Cooperation2021,1950,1
Sociology,Professor Emeritus,Cooperation2021,1955,5
Sociology,Professor Emeritus,Cooperation2021,1960,3
Sociology,Professor Emeritus,Cooperation2021,1965,8
Sociology,Professor Emeritus,Cooperation2021,1970,14
Sociology,Professor Emeritus,Cooperation2021,1975,34
Sociology,Professor Emeritus,Cooperation2021,1980,12
Sociology,Professor Emeritus,Cooperation2021,1985,14
Sociology,Professor Emeritus,Cooperation2021,1990,17
Sociology,Professor Emeritus,Cooperation2021,1995,25
Sociology,Professor Emeritus,Cooperation2021,2000,20
Sociology,Professor Emeritus,Cooperation2021,2005,18
Sociology,Professor Emeritus,Cooperation2021,2010,24
Sociology,Professor Emeritus,Cooperation2021,2015,10
Sociology,Researcher,Cooperation2021,1985,4
Sociology,Researcher,Cooperation2021,1990,1
Sociology,Researcher,Cooperation2021,1995,7
Sociology,Researcher,Cooperation2021,2000,4
Sociology,Researcher,Cooperation2021,2005,6
Sociology,Researcher,Cooperation2021,2010,8
Sociology,Researcher,Cooperation2021,2015,8
Theology,,Cooperation2021,2010,1
Theology,Adjunct Professor,Cooperation2021,1970,3
Theology,Adjunct Professor,Cooperation2021,1985,1
Theology,Adjunct Professor,Cooperation2021,1990,1
Theology,Adjunct Professor,Cooperation2021,2005,3
Theology,Adjunct Professor,Cooperation2021,2010,2
Theology,Adjunct Professor,Cooperation2021,2015,3
Theology,Assistant Professor,Cooperation2021,2010,1
Theology,Assistant Professor,Cooperation2021,2015,1
Theology,Associate Professor,Cooperation2021,1995,1
Theology,Associate Professor,Cooperation2021,2000,1
Theology,Associate Professor,Cooperation2021,2005,5
Theology,Associate Professor,Cooperation2021,2010,3
Theology,Associate Professor,Cooperation2021,2015,4
Theology,Graduate Student,Cooperation2021,2010,1
Theology,Non-Academic,Cooperation2021,2005,1
Theology,Non-Academic,Cooperation2021,2015,2
Theology,Professor,Cooperation2021,1930,1
Theology,Professor,Cooperation2021,1960,1
Theology,Professor,Cooperation2021,1970,6
Theology,Professor,Cooperation2021,1975,3
Theology,Professor,Cooperation2021,1980,3
Theology,Professor,Cooperation2021,1985,1
Theology,Professor,Cooperation2021,1990,5
Theology,Professor,Cooperation2021,1995,10
Theology,Professor,Cooperation2021,2000,3
Theology,Professor,Cooperation2021,2005,10
Theology,Professor,Cooperation2021,2010,19
Theology,Professor,Cooperation2021,2015,21
Theology,Professor Emeritus,Cooperation2021,1970,2
Theology,Professor Emeritus,Cooperation2021,1990,2
Theology,Professor Emeritus,Cooperation2021,1995,2
Theology,Professor Emeritus,Cooperation2021,2000,4
Theology,Professor Emeritus,Cooperation2021,2005,1
Theology,Researcher,Cooperation2021,1975,2
Theology,Researcher,Cooperation2021,1985,1
Theology,Researcher,Cooperation2021,1990,1
Theology,Researcher,Cooperation2021,2000,2
Theology,Researcher,Cooperation2021,2005,3
Theology,Researcher,Cooperation2021,2010,2
Theology,Researcher,Cooperation2021,2015,4
Zoology,Adjunct Professor,Cooperation2021,1980,3
Zoology,Adjunct Professor,Cooperation2021,1985,3
Zoology,Adjunct Professor,Cooperation2021,1995,3
Zoology,Adjunct Professor,Cooperation2021,2000,3
Zoology,Adjunct Professor,Cooperation2021,2005,2
Zoology,Adjunct Professor,Cooperation2021,2010,3
Zoology,Adjunct Professor,Cooperation2021,2015,5
Zoology,Assistant Professor,Cooperation2021,1930,1
Zoology,Assistant Professor,Cooperation2021,1935,1
Zoology,Assistant Professor,Cooperation2021,2000,3
Zoology,Assistant Professor,Cooperation2021,2005,14
Zoology,Assistant Professor,Cooperation2021,2010,14
Zoology,Assistant Professor,Cooperation2021,2015,6
Zoology,Associate Professor,Cooperation2021,1980,1
Zoology,Associate Professor,Cooperation2021,1995,1
Zoology,Associate Professor,Cooperation2021,2005,13
Zoology,Associate Professor,Cooperation2021,2010,11
Zoology,Associate Professor,Cooperation2021,2015,20
Zoology,Professor,Cooperation2021,1955,2
Zoology,Professor,Cooperation2021,1970,1
Zoology,Professor,Cooperation2021,1975,4
Zoology,Professor,Cooperation2021,1980,4
Zoology,Professor,Cooperation2021,1985,6
Zoology,Professor,Cooperation2021,1990,12
Zoology,Professor,Cooperation2021,1995,38
Zoology,Professor,Cooperation2021,2000,60
Zoology,Professor,Cooperation2021,2005,38
Zoology,Professor,Cooperation2021,2010,53
Zoology,Professor,Cooperation2021,2015,37
Zoology,Professor Emeritus,Cooperation2021,1960,1
Zoology,Professor Emeritus,Cooperation2021,1970,2
Zoology,Professor Emeritus,Cooperation2021,1975,7
Zoology,Professor Emeritus,Cooperation2021,1980,8
Zoology,Professor Emeritus,Cooperation2021,1985,1
Zoology,Professor Emeritus,Cooperation2021,1990,4
Zoology,Professor Emeritus,Cooperation2021,1995,5
Zoology,Professor Emeritus,Cooperation2021,2000,6
Zoology,Professor Emeritus,Cooperation2021,2005,7
Zoology,Professor Emeritus,Cooperation2021,2010,11
Zoology,Professor Emeritus,Cooperation2021,2015,6
Zoology,Researcher,Cooperation2021,2000,4
Zoology,Researcher,Cooperation2021,2005,15
Zoology,Researcher,Cooperation2021,2010,33
Zoology,Researcher,Cooperation2021,2015,30
Divinity,Non-Academic,CooperationAndReligion,2010,1
Divinity,Professor,CooperationAndReligion,1975,1
Divinity,Professor,CooperationAndReligion,1985,2
Divinity,Professor,CooperationAndReligion,1995,1
Divinity,Professor,CooperationAndReligion,2000,1
Divinity,Professor Emeritus,CooperationAndReligion,1955,2
Divinity,Professor Emeritus,CooperationAndReligion,2015,1
Divinity,Researcher,CooperationAndReligion,1840,1
Economics,Adjunct Professor,CooperationAndReligion,2015,1
Economics,Assistant Professor,CooperationAndReligion,2005,1
Economics,Assistant Professor,CooperationAndReligion,2010,2
Economics,Assistant Professor,CooperationAndReligion,2015,1
Economics,Associate Professor,CooperationAndReligion,2010,3
Economics,Associate Professor,CooperationAndReligion,2015,5
Economics,Non-Academic,CooperationAndReligion,2015,1
Economics,Postdoctoral Fellow,CooperationAndReligion,2010,2
Economics,Postdoctoral Fellow,CooperationAndReligion,2015,2
Economics,Professor,CooperationAndReligion,1995,1
Economics,Professor,CooperationAndReligion,2000,3
Economics,Professor,CooperationAndReligion,2005,6
Economics,Professor,CooperationAndReligion,2010,15
Economics,Professor,CooperationAndReligion,2015,6
Economics,Professor Emeritus,CooperationAndReligion,1995,1
Economics,Professor Emeritus,CooperationAndReligion,2015,1
Economics,Researcher,CooperationAndReligion,2010,5
Economics,Researcher,CooperationAndReligion,2015,1
Education,Associate Professor,CooperationAndReligion,2015,2
Education,Professor,CooperationAndReligion,1970,1
Education,Professor,CooperationAndReligion,2000,1
Education,Professor,CooperationAndReligion,2005,4
Education,Professor,CooperationAndReligion,2010,2
Education,Professor,CooperationAndReligion,2015,5
Engineering,Professor,CooperationAndReligion,2015,1
Engineering,Researcher,CooperationAndReligion,2010,1
English,Professor,CooperationAndReligion,2000,2
English,Professor Emeritus,CooperationAndReligion,2005,1
Health,,CooperationAndReligion,1995,1
Health,,CooperationAndReligion,2010,1
Health,,CooperationAndReligion,2015,2
Health,Adjunct Professor,CooperationAndReligion,2015,1
Health,Associate Professor,CooperationAndReligion,2010,4
Health,Associate Professor,CooperationAndReligion,2015,4
Health,Non-Academic,CooperationAndReligion,2000,1
Health,Non-Academic,CooperationAndReligion,2010,3
Health,Non-Academic,CooperationAndReligion,2015,4
Health,Professor,CooperationAndReligion,1995,3
Health,Professor,CooperationAndReligion,2000,3
Health,Professor,CooperationAndReligion,2005,2
Health,Professor,CooperationAndReligion,2010,4
Health,Professor,CooperationAndReligion,2015,3
Health,Professor Emeritus,CooperationAndReligion,1985,1
Health,Professor Emeritus,CooperationAndReligion,2000,2
Health,Professor Emeritus,CooperationAndReligion,2005,3
Health,Professor Emeritus,CooperationAndReligion,2015,1
Health,Researcher,CooperationAndReligion,2000,1
Health,Researcher,CooperationAndReligion,2010,3
History,Assistant Professor,CooperationAndReligion,2015,4
History,Associate Professor,CooperationAndReligion,2010,1
History,Postdoctoral Fellow,CooperationAndReligion,2015,4
History,Professor,CooperationAndReligion,1985,1
History,Professor,CooperationAndReligion,1995,1
History,Professor,CooperationAndReligion,2000,1
History,Professor,CooperationAndReligion,2005,3
History,Professor,CooperationAndReligion,2010,1
History,Professor,CooperationAndReligion,2015,20
History,Professor Emeritus,CooperationAndReligion,1975,1
History,Professor Emeritus,CooperationAndReligion,1995,1
History,Professor Emeritus,CooperationAndReligion,2000,2
History,Professor Emeritus,CooperationAndReligion,2005,2
History,Professor Emeritus,CooperationAndReligion,2010,1
History,Professor Emeritus,CooperationAndReligion,2015,2
History,Researcher,CooperationAndReligion,2000,1
History,Researcher,CooperationAndReligion,2005,1
History,Researcher,CooperationAndReligion,2010,1
History,Researcher,CooperationAndReligion,2015,1
Islamic Studies,Assistant Professor,CooperationAndReligion,2015,1
Islamic Studies,Professor,CooperationAndReligion,2005,1
Islamic Studies,Professor,CooperationAndReligion,2010,3
Islamic Studies,Professor,CooperationAndReligion,2015,1
Islamic Studies,Professor Emeritus,CooperationAndReligion,2015,1
Law,Associate Professor,CooperationAndReligion,2010,1
Law,Associate Professor,CooperationAndReligion,2015,2
Law,Professor,CooperationAndReligion,1985,1
Law,Professor,CooperationAndReligion,1990,1
Law,Professor,CooperationAndReligion,1995,1
Law,Professor,CooperationAndReligion,2000,1
Law,Professor,CooperationAndReligion,2005,4
Law,Professor,CooperationAndReligion,2010,3
Law,Professor,CooperationAndReligion,2015,5
Law,Professor Emeritus,CooperationAndReligion,2000,1
Law,Professor Emeritus,CooperationAndReligion,2010,1
Management,Associate Professor,CooperationAndReligion,2015,1
Management,Professor,CooperationAndReligion,2010,3
Management,Professor,CooperationAndReligion,2015,2
Mathematics,Assistant Professor,CooperationAndReligion,2010,1
Mathematics,Professor,CooperationAndReligion,2010,1
Mathematics,Professor,CooperationAndReligion,2015,1
Medicine,,CooperationAndReligion,2010,4
Medicine,,CooperationAndReligion,2015,1
Medicine,Associate Professor,CooperationAndReligion,2010,4
Medicine,Associate Professor,CooperationAndReligion,2015,7
Medicine,Non-Academic,CooperationAndReligion,2010,6
Medicine,Non-Academic,CooperationAndReligion,2015,6
Medicine,Professor,CooperationAndReligion,1990,1
Medicine,Professor,CooperationAndReligion,1995,1
Medicine,Professor,CooperationAndReligion,2000,5
Medicine,Professor,CooperationAndReligion,2005,13
Medicine,Professor,CooperationAndReligion,2010,17
Medicine,Professor,CooperationAndReligion,2015,33
Medicine,Professor Emeritus,CooperationAndReligion,2000,1
Medicine,Researcher,CooperationAndReligion,2000,1
Medicine,Researcher,CooperationAndReligion,2005,7
Medicine,Researcher,CooperationAndReligion,2010,11
Medicine,Researcher,CooperationAndReligion,2015,29
NA,,CooperationAndReligion,2010,3
NA,,CooperationAndReligion,2015,6
NA,Adjunct Professor,CooperationAndReligion,2000,1
NA,Adjunct Professor,CooperationAndReligion,2010,2
NA,Associate Professor,CooperationAndReligion,2005,1
NA,Associate Professor,CooperationAndReligion,2010,3
NA,Non-Academic,CooperationAndReligion,1990,1
NA,Non-Academic,CooperationAndReligion,1995,1
NA,Non-Academic,CooperationAndReligion,2000,2
NA,Non-Academic,CooperationAndReligion,2005,3
NA,Non-Academic,CooperationAndReligion,2010,21
NA,Non-Academic,CooperationAndReligion,2015,11
NA,Postdoctoral Fellow,CooperationAndReligion,2010,2
NA,Professor,CooperationAndReligion,1980,1
NA,Professor,CooperationAndReligion,2015,2
NA,Researcher,CooperationAndReligion,1995,1
NA,Researcher,CooperationAndReligion,2000,2
NA,Researcher,CooperationAndReligion,2005,2
NA,Researcher,CooperationAndReligion,2010,12
NA,Researcher,CooperationAndReligion,2015,12
Neuroscience,,CooperationAndReligion,2010,2
Neuroscience,Assistant Professor,CooperationAndReligion,2015,1
Neuroscience,Postdoctoral Fellow,CooperationAndReligion,2015,1
Neuroscience,Researcher,CooperationAndReligion,2010,1
Nursing,Assistant Professor,CooperationAndReligion,2015,2
Nursing,Associate Professor,CooperationAndReligion,2005,1
Nursing,Associate Professor,CooperationAndReligion,2010,2
Nursing,Associate Professor,CooperationAndReligion,2015,5
Nursing,Non-Academic,CooperationAndReligion,2010,1
Nursing,Professor,CooperationAndReligion,2000,1
Nursing,Professor,CooperationAndReligion,2005,1
Nursing,Professor,CooperationAndReligion,2010,2
Nursing,Professor,CooperationAndReligion,2015,7
Philosophy,Adjunct Professor,CooperationAndReligion,2010,1
Philosophy,Assistant Professor,CooperationAndReligion,1990,1
Philosophy,Assistant Professor,CooperationAndReligion,2010,1
Philosophy,Assistant Professor,CooperationAndReligion,2015,1
Philosophy,Associate Professor,CooperationAndReligion,2010,4
Philosophy,Associate Professor,CooperationAndReligion,2015,4
Philosophy,Postdoctoral Fellow,CooperationAndReligion,2010,1
Philosophy,Professor,CooperationAndReligion,1975,2
Philosophy,Professor,CooperationAndReligion,1990,1
Philosophy,Professor,CooperationAndReligion,1995,3
Philosophy,Professor,CooperationAndReligion,2005,1
Philosophy,Professor,CooperationAndReligion,2010,4
Philosophy,Professor,CooperationAndReligion,2015,7
Philosophy,Professor Emeritus,CooperationAndReligion,2015,1
Philosophy,Researcher,CooperationAndReligion,1990,1
Philosophy,Researcher,CooperationAndReligion,2015,1
Philosophy and Religion,Professor,CooperationAndReligion,2015,1
Physics,Assistant Professor,CooperationAndReligion,2010,1
Political Science,Adjunct Professor,CooperationAndReligion,2015,1
Political Science,Associate Professor,CooperationAndReligion,2010,1
Political Science,Associate Professor,CooperationAndReligion,2015,3
Political Science,Professor,CooperationAndReligion,2000,2
Political Science,Professor,CooperationAndReligion,2005,2
Political Science,Professor,CooperationAndReligion,2010,6
Political Science,Professor,CooperationAndReligion,2015,5
Political Science,Professor Emeritus,CooperationAndReligion,1985,1
Political Science,Professor Emeritus,CooperationAndReligion,1990,1
Political Science,Professor Emeritus,CooperationAndReligion,2010,1
Political Science,Professor Emeritus,CooperationAndReligion,2015,1
Psychiatry,,CooperationAndReligion,2000,1
Psychiatry,,CooperationAndReligion,2010,1
Psychiatry,Assistant Professor,CooperationAndReligion,2010,1
Psychiatry,Assistant Professor,CooperationAndReligion,2015,8
Psychiatry,Associate Professor,CooperationAndReligion,1980,1
Psychiatry,Associate Professor,CooperationAndReligion,2010,1
Psychiatry,Associate Professor,CooperationAndReligion,2015,4
Psychiatry,Non-Academic,CooperationAndReligion,2005,1
Psychiatry,Non-Academic,CooperationAndReligion,2010,1
Psychiatry,Professor,CooperationAndReligion,1980,2
Psychiatry,Professor,CooperationAndReligion,1995,2
Psychiatry,Professor,CooperationAndReligion,2000,2
Psychiatry,Professor,CooperationAndReligion,2005,2
Psychiatry,Professor,CooperationAndReligion,2010,3
Psychiatry,Professor,CooperationAndReligion,2015,7
Psychiatry,Professor Emeritus,CooperationAndReligion,1985,1
Psychiatry,Professor Emeritus,CooperationAndReligion,1995,1
Psychiatry,Professor Emeritus,CooperationAndReligion,2005,2
Psychology,,CooperationAndReligion,1980,1
Psychology,,CooperationAndReligion,2005,1
Psychology,,CooperationAndReligion,2010,3
Psychology,,CooperationAndReligion,2015,2
Psychology,Adjunct Professor,CooperationAndReligion,1990,1
Psychology,Adjunct Professor,CooperationAndReligion,2005,1
Psychology,Adjunct Professor,CooperationAndReligion,2010,1
Psychology,Adjunct Professor,CooperationAndReligion,2015,4
Psychology,Assistant Professor,CooperationAndReligion,2000,2
Psychology,Assistant Professor,CooperationAndReligion,2005,2
Psychology,Assistant Professor,CooperationAndReligion,2010,4
Psychology,Assistant Professor,CooperationAndReligion,2015,14
Psychology,Associate Professor,CooperationAndReligion,2005,3
Psychology,Associate Professor,CooperationAndReligion,2010,15
Psychology,Associate Professor,CooperationAndReligion,2015,34
Psychology,Non-Academic,CooperationAndReligion,2010,3
Psychology,Non-Academic,CooperationAndReligion,2015,6
Psychology,Postdoctoral Fellow,CooperationAndReligion,2010,1
Psychology,Postdoctoral Fellow,CooperationAndReligion,2015,4
Psychology,Professor,CooperationAndReligion,1965,1
Psychology,Professor,CooperationAndReligion,1975,1
Psychology,Professor,CooperationAndReligion,1985,2
Psychology,Professor,CooperationAndReligion,1995,2
Psychology,Professor,CooperationAndReligion,2000,7
Psychology,Professor,CooperationAndReligion,2005,8
Psychology,Professor,CooperationAndReligion,2010,28
Psychology,Professor,CooperationAndReligion,2015,41
Psychology,Professor Emeritus,CooperationAndReligion,1985,2
Psychology,Professor Emeritus,CooperationAndReligion,1990,1
Psychology,Professor Emeritus,CooperationAndReligion,1995,3
Psychology,Professor Emeritus,CooperationAndReligion,2005,3
Psychology,Professor Emeritus,CooperationAndReligion,2010,6
Psychology,Professor Emeritus,CooperationAndReligion,2015,6
Psychology,Researcher,CooperationAndReligion,1995,1
Psychology,Researcher,CooperationAndReligion,2000,1
Psychology,Researcher,CooperationAndReligion,2005,2
Psychology,Researcher,CooperationAndReligion,2010,5
Psychology,Researcher,CooperationAndReligion,2015,6
Public Health,Adjunct Professor,CooperationAndReligion,2005,2
Public Health,Adjunct Professor,CooperationAndReligion,2010,2
Public Health,Adjunct Professor,CooperationAndReligion,2015,1
Public Health,Professor,CooperationAndReligion,2000,3
Public Health,Professor,CooperationAndReligion,2005,4
Public Health,Professor,CooperationAndReligion,2010,6
Public Health,Professor,CooperationAndReligion,2015,2
Public Health,Professor Emeritus,CooperationAndReligion,2010,2
Public Health,Professor Emeritus,CooperationAndReligion,2015,1
Public Health,Researcher,CooperationAndReligion,2005,1
Public Health,Researcher,CooperationAndReligion,2010,4
Public Health,Researcher,CooperationAndReligion,2015,10
Religion,,CooperationAndReligion,2015,1
Religion,Adjunct Professor,CooperationAndReligion,2005,1
Religion,Adjunct Professor,CooperationAndReligion,2010,2
Religion,Assistant Professor,CooperationAndReligion,2010,1
Religion,Assistant Professor,CooperationAndReligion,2015,6
Religion,Associate Professor,CooperationAndReligion,2005,1
Religion,Associate Professor,CooperationAndReligion,2010,3
Religion,Associate Professor,CooperationAndReligion,2015,4
Religion,Non-Academic,CooperationAndReligion,1970,1
Religion,Non-Academic,CooperationAndReligion,2005,1
Religion,Non-Academic,CooperationAndReligion,2015,1
Religion,Postdoctoral Fellow,CooperationAndReligion,2010,3
Religion,Postdoctoral Fellow,CooperationAndReligion,2015,1
Religion,Professor,CooperationAndReligion,1970,1
Religion,Professor,CooperationAndReligion,1980,1
Religion,Professor,CooperationAndReligion,1990,1
Religion,Professor,CooperationAndReligion,1995,1
Religion,Professor,CooperationAndReligion,2000,3
Religion,Professor,CooperationAndReligion,2005,3
Religion,Professor,CooperationAndReligion,2010,8
Religion,Professor,CooperationAndReligion,2015,11
Religion,Professor Emeritus,CooperationAndReligion,1980,2
Religion,Professor Emeritus,CooperationAndReligion,1995,1
Religion,Professor Emeritus,CooperationAndReligion,2005,2
Religion,Professor Emeritus,CooperationAndReligion,2010,3
Religion,Professor Emeritus,CooperationAndReligion,2015,4
Religion,Researcher,CooperationAndReligion,2005,1
Religion,Researcher,CooperationAndReligion,2015,5
Religious Studies,Adjunct Professor,CooperationAndReligion,2010,1
Religious Studies,Adjunct Professor,CooperationAndReligion,2015,3
Religious Studies,Associate Professor,CooperationAndReligion,2005,1
Religious Studies,Associate Professor,CooperationAndReligion,2010,1
Religious Studies,Associate Professor,CooperationAndReligion,2015,1
Religious Studies,Professor,CooperationAndReligion,2005,1
Religious Studies,Professor,CooperationAndReligion,2010,4
Research,Researcher,CooperationAndReligion,2015,1
Social Sciences,Assistant Professor,CooperationAndReligion,2015,1
Social Sciences,Associate Professor,CooperationAndReligion,2010,1
Social Sciences,Associate Professor,CooperationAndReligion,2015,2
Social Sciences,Non-Academic,CooperationAndReligion,1955,1
Social Sciences,Professor,CooperationAndReligion,2005,1
Social Sciences,Professor,CooperationAndReligion,2010,1
Social Sciences,Professor,CooperationAndReligion,2015,1
Social Work,,CooperationAndReligion,2015,1
Social Work,Associate Professor,CooperationAndReligion,2005,1
Social Work,Professor,CooperationAndReligion,2000,1
Social Work,Professor,CooperationAndReligion,2005,3
Social Work,Professor,CooperationAndReligion,2015,1
Social Work,Professor Emeritus,CooperationAndReligion,1995,1
Sociology,Adjunct Professor,CooperationAndReligion,2010,1
Sociology,Adjunct Professor,CooperationAndReligion,2015,2
Sociology,Assistant Professor,CooperationAndReligion,2010,4
Sociology,Assistant Professor,CooperationAndReligion,2015,2
Sociology,Associate Professor,CooperationAndReligion,2005,2
Sociology,Associate Professor,CooperationAndReligion,2010,2
Sociology,Associate Professor,CooperationAndReligion,2015,9
Sociology,Non-Academic,CooperationAndReligion,2010,2
Sociology,Non-Academic,CooperationAndReligion,2015,2
Sociology,Professor,CooperationAndReligion,1985,1
Sociology,Professor,CooperationAndReligion,1990,2
Sociology,Professor,CooperationAndReligion,2000,1
Sociology,Professor,CooperationAndReligion,2005,5
Sociology,Professor,CooperationAndReligion,2010,9
Sociology,Professor,CooperationAndReligion,2015,11
Sociology,Professor Emeritus,CooperationAndReligion,1990,1
Sociology,Professor Emeritus,CooperationAndReligion,1995,1
Sociology,Professor Emeritus,CooperationAndReligion,2010,1
Sociology,Professor Emeritus,CooperationAndReligion,2015,2
Sociology,Researcher,CooperationAndReligion,2010,1
Theology,,CooperationAndReligion,2010,1
Theology,Adjunct Professor,CooperationAndReligion,1970,1
Theology,Adjunct Professor,CooperationAndReligion,2015,3
Theology,Assistant Professor,CooperationAndReligion,2015,1
Theology,Associate Professor,CooperationAndReligion,2005,2
Theology,Associate Professor,CooperationAndReligion,2015,2
Theology,Non-Academic,CooperationAndReligion,2005,1
Theology,Non-Academic,CooperationAndReligion,2015,1
Theology,Professor,CooperationAndReligion,1990,2
Theology,Professor,CooperationAndReligion,1995,2
Theology,Professor,CooperationAndReligion,2005,3
Theology,Professor,CooperationAndReligion,2010,3
Theology,Professor,CooperationAndReligion,2015,11
Theology,Professor Emeritus,CooperationAndReligion,1970,1
Theology,Professor Emeritus,CooperationAndReligion,2005,1
Theology,Researcher,CooperationAndReligion,2000,1
Theology,Researcher,CooperationAndReligion,2005,1
Theology,Researcher,CooperationAndReligion,2010,1
Divinity,,Religion2020,1890,2
Divinity,,Religion2020,1895,2
Divinity,,Religion2020,1900,7
Divinity,,Religion2020,1905,3
Divinity,,Religion2020,1910,8
Divinity,,Religion2020,1915,6
Divinity,,Religion2020,1920,3
Divinity,,Religion2020,1925,1
Divinity,,Religion2020,1930,2
Divinity,,Religion2020,1935,1
Divinity,Adjunct Professor,Religion2020,1980,3
Divinity,Adjunct Professor,Religion2020,1985,6
Divinity,Adjunct Professor,Religion2020,1990,3
Divinity,Adjunct Professor,Religion2020,1995,7
Divinity,Adjunct Professor,Religion2020,2000,7
Divinity,Adjunct Professor,Religion2020,2005,7
Divinity,Adjunct Professor,Religion2020,2010,2
Divinity,Adjunct Professor,Religion2020,2015,2
Divinity,Assistant Professor,Religion2020,1980,1
Divinity,Assistant Professor,Religion2020,1985,1
Divinity,Assistant Professor,Religion2020,1990,3
Divinity,Assistant Professor,Religion2020,1995,1
Divinity,Assistant Professor,Religion2020,2000,4
Divinity,Assistant Professor,Religion2020,2005,5
Divinity,Assistant Professor,Religion2020,2010,31
Divinity,Assistant Professor,Religion2020,2015,28
Divinity,Associate Professor,Religion2020,1985,1
Divinity,Associate Professor,Religion2020,1990,5
Divinity,Associate Professor,Religion2020,1995,11
Divinity,Associate Professor,Religion2020,2000,8
Divinity,Associate Professor,Religion2020,2005,13
Divinity,Associate Professor,Religion2020,2010,39
Divinity,Associate Professor,Religion2020,2015,18
Divinity,Non-Academic,Religion2020,1965,1
Divinity,Non-Academic,Religion2020,1980,1
Divinity,Non-Academic,Religion2020,1985,1
Divinity,Non-Academic,Religion2020,1990,2
Divinity,Non-Academic,Religion2020,1995,5
Divinity,Non-Academic,Religion2020,2000,3
Divinity,Non-Academic,Religion2020,2005,4
Divinity,Non-Academic,Religion2020,2010,4
Divinity,Non-Academic,Religion2020,2015,11
Divinity,Postdoctoral Fellow,Religion2020,1980,1
Divinity,Postdoctoral Fellow,Religion2020,1995,3
Divinity,Postdoctoral Fellow,Religion2020,2000,1
Divinity,Postdoctoral Fellow,Religion2020,2005,1
Divinity,Postdoctoral Fellow,Religion2020,2015,3
Divinity,Professor,Religion2020,1905,2
Divinity,Professor,Religion2020,1910,3
Divinity,Professor,Religion2020,1915,4
Divinity,Professor,Religion2020,1920,4
Divinity,Professor,Religion2020,1925,12
Divinity,Professor,Religion2020,1930,17
Divinity,Professor,Religion2020,1935,13
Divinity,Professor,Religion2020,1940,10
Divinity,Professor,Religion2020,1945,14
Divinity,Professor,Religion2020,1950,12
Divinity,Professor,Religion2020,1955,8
Divinity,Professor,Religion2020,1960,17
Divinity,Professor,Religion2020,1965,17
Divinity,Professor,Religion2020,1970,25
Divinity,Professor,Religion2020,1975,29
Divinity,Professor,Religion2020,1980,35
Divinity,Professor,Religion2020,1985,56
Divinity,Professor,Religion2020,1990,80
Divinity,Professor,Religion2020,1995,66
Divinity,Professor,Religion2020,2000,65
Divinity,Professor,Religion2020,2005,66
Divinity,Professor,Religion2020,2010,104
Divinity,Professor,Religion2020,2015,112
Divinity,Professor Emeritus,Religion2020,1920,2
Divinity,Professor Emeritus,Religion2020,1925,3
Divinity,Professor Emeritus,Religion2020,1930,15
Divinity,Professor Emeritus,Religion2020,1935,5
Divinity,Professor Emeritus,Religion2020,1940,3
Divinity,Professor Emeritus,Religion2020,1945,5
Divinity,Professor Emeritus,Religion2020,1950,3
Divinity,Professor Emeritus,Religion2020,1955,8
Divinity,Professor Emeritus,Religion2020,1960,8
Divinity,Professor Emeritus,Religion2020,1965,17
Divinity,Professor Emeritus,Religion2020,1970,25
Divinity,Professor Emeritus,Religion2020,1975,16
Divinity,Professor Emeritus,Religion2020,1980,7
Divinity,Professor Emeritus,Religion2020,1985,11
Divinity,Professor Emeritus,Religion2020,1990,16
Divinity,Professor Emeritus,Religion2020,1995,30
Divinity,Professor Emeritus,Religion2020,2000,12
Divinity,Professor Emeritus,Religion2020,2005,15
Divinity,Professor Emeritus,Religion2020,2010,45
Divinity,Professor Emeritus,Religion2020,2015,22
Divinity,Researcher,Religion2020,1820,1
Divinity,Researcher,Religion2020,1825,10
Divinity,Researcher,Religion2020,1830,1
Divinity,Researcher,Religion2020,1840,5
Divinity,Researcher,Religion2020,1910,3
Divinity,Researcher,Religion2020,1915,8
Divinity,Researcher,Religion2020,1920,2
Divinity,Researcher,Religion2020,1960,1
Divinity,Researcher,Religion2020,1970,1
Divinity,Researcher,Religion2020,1975,3
Divinity,Researcher,Religion2020,1980,5
Divinity,Researcher,Religion2020,1985,2
Divinity,Researcher,Religion2020,1990,9
Divinity,Researcher,Religion2020,1995,3
Divinity,Researcher,Religion2020,2000,7
Divinity,Researcher,Religion2020,2005,7
Divinity,Researcher,Religion2020,2010,11
Divinity,Researcher,Religion2020,2015,20
Economics,,Religion2020,2000,1
Economics,,Religion2020,2005,4
Economics,,Religion2020,2010,23
Economics,,Religion2020,2015,10
Economics,Adjunct Professor,Religion2020,1970,1
Economics,Adjunct Professor,Religion2020,1990,1
Economics,Adjunct Professor,Religion2020,2005,2
Economics,Adjunct Professor,Religion2020,2010,9
Economics,Adjunct Professor,Religion2020,2015,21
Economics,Assistant Professor,Religion2020,1980,1
Economics,Assistant Professor,Religion2020,2000,1
Economics,Assistant Professor,Religion2020,2005,8
Economics,Assistant Professor,Religion2020,2010,14
Economics,Assistant Professor,Religion2020,2015,14
Economics,Associate Professor,Religion2020,1940,2
Economics,Associate Professor,Religion2020,1975,1
Economics,Associate Professor,Religion2020,1980,1
Economics,Associate Professor,Religion2020,1990,6
Economics,Associate Professor,Religion2020,1995,3
Economics,Associate Professor,Religion2020,2000,5
Economics,Associate Professor,Religion2020,2005,8
Economics,Associate Professor,Religion2020,2010,29
Economics,Associate Professor,Religion2020,2015,75
Economics,Graduate Student,Religion2020,2010,3
Economics,Non-Academic,Religion2020,2000,4
Economics,Non-Academic,Religion2020,2005,3
Economics,Non-Academic,Religion2020,2010,29
Economics,Non-Academic,Religion2020,2015,33
Economics,Postdoctoral Fellow,Religion2020,2010,3
Economics,Postdoctoral Fellow,Religion2020,2015,5
Economics,Professor,Religion2020,1850,1
Economics,Professor,Religion2020,1895,1
Economics,Professor,Religion2020,1920,3
Economics,Professor,Religion2020,1925,2
Economics,Professor,Religion2020,1930,1
Economics,Professor,Religion2020,1945,1
Economics,Professor,Religion2020,1950,1
Economics,Professor,Religion2020,1955,1
Economics,Professor,Religion2020,1960,5
Economics,Professor,Religion2020,1965,1
Economics,Professor,Religion2020,1975,7
Economics,Professor,Religion2020,1980,3
Economics,Professor,Religion2020,1985,2
Economics,Professor,Religion2020,1990,18
Economics,Professor,Religion2020,1995,36
Economics,Professor,Religion2020,2000,50
Economics,Professor,Religion2020,2005,88
Economics,Professor,Religion2020,2010,150
Economics,Professor,Religion2020,2015,181
Economics,Professor Emeritus,Religion2020,1935,1
Economics,Professor Emeritus,Religion2020,1940,3
Economics,Professor Emeritus,Religion2020,1945,2
Economics,Professor Emeritus,Religion2020,1960,1
Economics,Professor Emeritus,Religion2020,1965,3
Economics,Professor Emeritus,Religion2020,1975,1
Economics,Professor Emeritus,Religion2020,1980,1
Economics,Professor Emeritus,Religion2020,1985,2
Economics,Professor Emeritus,Religion2020,1990,11
Economics,Professor Emeritus,Religion2020,1995,7
Economics,Professor Emeritus,Religion2020,2000,15
Economics,Professor Emeritus,Religion2020,2005,20
Economics,Professor Emeritus,Religion2020,2010,23
Economics,Professor Emeritus,Religion2020,2015,8
Economics,Researcher,Religion2020,1995,1
Economics,Researcher,Religion2020,2005,2
Economics,Researcher,Religion2020,2010,17
Economics,Researcher,Religion2020,2015,20
Education,,Religion2020,1980,7
Education,,Religion2020,1985,2
Education,,Religion2020,1990,3
Education,,Religion2020,1995,2
Education,,Religion2020,2000,4
Education,,Religion2020,2005,3
Education,,Religion2020,2010,11
Education,,Religion2020,2015,22
Education,Adjunct Professor,Religion2020,1970,2
Education,Adjunct Professor,Religion2020,1975,5
Education,Adjunct Professor,Religion2020,1980,1
Education,Adjunct Professor,Religion2020,1985,2
Education,Adjunct Professor,Religion2020,1990,1
Education,Adjunct Professor,Religion2020,1995,1
Education,Adjunct Professor,Religion2020,2000,1
Education,Adjunct Professor,Religion2020,2005,2
Education,Adjunct Professor,Religion2020,2010,11
Education,Adjunct Professor,Religion2020,2015,4
Education,Assistant Professor,Religion2020,1970,1
Education,Assistant Professor,Religion2020,2000,5
Education,Assistant Professor,Religion2020,2005,18
Education,Assistant Professor,Religion2020,2010,37
Education,Assistant Professor,Religion2020,2015,82
Education,Associate Professor,Religion2020,1970,2
Education,Associate Professor,Religion2020,1985,2
Education,Associate Professor,Religion2020,1990,4
Education,Associate Professor,Religion2020,1995,10
Education,Associate Professor,Religion2020,2000,20
Education,Associate Professor,Religion2020,2005,38
Education,Associate Professor,Religion2020,2010,74
Education,Associate Professor,Religion2020,2015,135
Education,Graduate Student,Religion2020,2005,3
Education,Graduate Student,Religion2020,2010,8
Education,Graduate Student,Religion2020,2015,1
Education,Non-Academic,Religion2020,1920,1
Education,Non-Academic,Religion2020,1925,6
Education,Non-Academic,Religion2020,1930,1
Education,Non-Academic,Religion2020,1935,1
Education,Non-Academic,Religion2020,1945,1
Education,Non-Academic,Religion2020,1985,4
Education,Non-Academic,Religion2020,1990,12
Education,Non-Academic,Religion2020,1995,3
Education,Non-Academic,Religion2020,2000,17
Education,Non-Academic,Religion2020,2005,16
Education,Non-Academic,Religion2020,2010,40
Education,Non-Academic,Religion2020,2015,51
Education,Postdoctoral Fellow,Religion2020,2000,2
Education,Postdoctoral Fellow,Religion2020,2010,20
Education,Postdoctoral Fellow,Religion2020,2015,14
Education,Professor,Religion2020,1920,6
Education,Professor,Religion2020,1930,1
Education,Professor,Religion2020,1935,2
Education,Professor,Religion2020,1960,4
Education,Professor,Religion2020,1965,1
Education,Professor,Religion2020,1970,6
Education,Professor,Religion2020,1975,8
Education,Professor,Religion2020,1980,9
Education,Professor,Religion2020,1985,13
Education,Professor,Religion2020,1990,30
Education,Professor,Religion2020,1995,32
Education,Professor,Religion2020,2000,89
Education,Professor,Religion2020,2005,177
Education,Professor,Religion2020,2010,300
Education,Professor,Religion2020,2015,363
Education,Professor Emeritus,Religion2020,1960,1
Education,Professor Emeritus,Religion2020,1965,3
Education,Professor Emeritus,Religion2020,1970,3
Education,Professor Emeritus,Religion2020,1980,1
Education,Professor Emeritus,Religion2020,1985,12
Education,Professor Emeritus,Religion2020,1990,15
Education,Professor Emeritus,Religion2020,1995,14
Education,Professor Emeritus,Religion2020,2000,30
Education,Professor Emeritus,Religion2020,2005,68
Education,Professor Emeritus,Religion2020,2010,76
Education,Professor Emeritus,Religion2020,2015,72
Education,Researcher,Religion2020,1960,1
Education,Researcher,Religion2020,1970,1
Education,Researcher,Religion2020,1975,5
Education,Researcher,Religion2020,1980,2
Education,Researcher,Religion2020,1985,3
Education,Researcher,Religion2020,1990,2
Education,Researcher,Religion2020,1995,9
Education,Researcher,Religion2020,2000,11
Education,Researcher,Religion2020,2005,24
Education,Researcher,Religion2020,2010,43
Education,Researcher,Religion2020,2015,43
Engineering,,Religion2020,2010,5
Engineering,,Religion2020,2015,1
Engineering,Adjunct Professor,Religion2020,2000,3
Engineering,Adjunct Professor,Religion2020,2005,3
Engineering,Adjunct Professor,Religion2020,2010,3
Engineering,Associate Professor,Religion2020,1970,1
Engineering,Associate Professor,Religion2020,1975,1
Engineering,Associate Professor,Religion2020,1990,1
Engineering,Associate Professor,Religion2020,2000,2
Engineering,Associate Professor,Religion2020,2005,5
Engineering,Associate Professor,Religion2020,2010,9
Engineering,Associate Professor,Religion2020,2015,14
Engineering,Non-Academic,Religion2020,2005,1
Engineering,Non-Academic,Religion2020,2015,9
Engineering,Professor,Religion2020,1970,1
Engineering,Professor,Religion2020,1985,1
Engineering,Professor,Religion2020,1990,2
Engineering,Professor,Religion2020,2000,3
Engineering,Professor,Religion2020,2005,24
Engineering,Professor,Religion2020,2010,10
Engineering,Professor,Religion2020,2015,25
Engineering,Researcher,Religion2020,1995,2
Engineering,Researcher,Religion2020,2000,1
Engineering,Researcher,Religion2020,2005,6
Engineering,Researcher,Religion2020,2010,10
Engineering,Researcher,Religion2020,2015,6
English,,Religion2020,1960,1
English,,Religion2020,1965,1
English,,Religion2020,1970,1
English,,Religion2020,1990,1
English,,Religion2020,1995,1
English,,Religion2020,2005,2
English,,Religion2020,2010,10
English,,Religion2020,2015,3
English,Adjunct Professor,Religion2020,1980,1
English,Adjunct Professor,Religion2020,1985,1
English,Adjunct Professor,Religion2020,1995,2
English,Adjunct Professor,Religion2020,2000,5
English,Adjunct Professor,Religion2020,2005,7
English,Adjunct Professor,Religion2020,2010,18
English,Adjunct Professor,Religion2020,2015,23
English,Assistant Professor,Religion2020,2010,3
English,Assistant Professor,Religion2020,2015,5
English,Associate Professor,Religion2020,1990,4
English,Associate Professor,Religion2020,1995,6
English,Associate Professor,Religion2020,2000,2
English,Associate Professor,Religion2020,2005,9
English,Associate Professor,Religion2020,2010,14
English,Associate Professor,Religion2020,2015,34
English,Professor,Religion2020,1910,1
English,Professor,Religion2020,1915,1
English,Professor,Religion2020,1925,2
English,Professor,Religion2020,1935,1
English,Professor,Religion2020,1940,4
English,Professor,Religion2020,1945,6
English,Professor,Religion2020,1950,4
English,Professor,Religion2020,1955,1
English,Professor,Religion2020,1960,3
English,Professor,Religion2020,1970,10
English,Professor,Religion2020,1975,3
English,Professor,Religion2020,1980,13
English,Professor,Religion2020,1985,23
English,Professor,Religion2020,1990,14
English,Professor,Religion2020,1995,22
English,Professor,Religion2020,2000,38
English,Professor,Religion2020,2005,76
English,Professor,Religion2020,2010,84
English,Professor,Religion2020,2015,138
English,Professor Emeritus,Religion2020,1960,1
English,Professor Emeritus,Religion2020,1965,1
English,Professor Emeritus,Religion2020,1970,7
English,Professor Emeritus,Religion2020,1975,7
English,Professor Emeritus,Religion2020,1980,3
English,Professor Emeritus,Religion2020,1985,13
English,Professor Emeritus,Religion2020,1990,10
English,Professor Emeritus,Religion2020,1995,15
English,Professor Emeritus,Religion2020,2000,32
English,Professor Emeritus,Religion2020,2005,26
English,Professor Emeritus,Religion2020,2010,21
English,Professor Emeritus,Religion2020,2015,28
Geography,,Religion2020,2015,1
Geography,Adjunct Professor,Religion2020,1995,1
Geography,Adjunct Professor,Religion2020,2000,1
Geography,Adjunct Professor,Religion2020,2005,5
Geography,Adjunct Professor,Religion2020,2010,9
Geography,Adjunct Professor,Religion2020,2015,8
Geography,Assistant Professor,Religion2020,2005,3
Geography,Assistant Professor,Religion2020,2010,20
Geography,Assistant Professor,Religion2020,2015,6
Geography,Associate Professor,Religion2020,2000,4
Geography,Associate Professor,Religion2020,2005,9
Geography,Associate Professor,Religion2020,2010,13
Geography,Associate Professor,Religion2020,2015,24
Geography,Professor,Religion2020,1920,1
Geography,Professor,Religion2020,1935,1
Geography,Professor,Religion2020,1940,2
Geography,Professor,Religion2020,1945,7
Geography,Professor,Religion2020,1950,1
Geography,Professor,Religion2020,1955,1
Geography,Professor,Religion2020,1975,1
Geography,Professor,Religion2020,1980,3
Geography,Professor,Religion2020,1985,2
Geography,Professor,Religion2020,1990,5
Geography,Professor,Religion2020,1995,3
Geography,Professor,Religion2020,2000,7
Geography,Professor,Religion2020,2005,21
Geography,Professor,Religion2020,2010,58
Geography,Professor,Religion2020,2015,41
Geography,Professor Emeritus,Religion2020,1960,1
Geography,Professor Emeritus,Religion2020,1965,1
Geography,Professor Emeritus,Religion2020,1970,4
Geography,Professor Emeritus,Religion2020,1975,2
Geography,Professor Emeritus,Religion2020,1980,1
Geography,Professor Emeritus,Religion2020,1985,1
Geography,Professor Emeritus,Religion2020,1990,3
Geography,Professor Emeritus,Religion2020,1995,3
Geography,Professor Emeritus,Religion2020,2000,9
Geography,Professor Emeritus,Religion2020,2005,4
Geography,Professor Emeritus,Religion2020,2010,5
Geography,Professor Emeritus,Religion2020,2015,9
Geography,Researcher,Religion2020,2005,2
Geography,Researcher,Religion2020,2010,13
Geography,Researcher,Religion2020,2015,11
Health,,Religion2020,1975,3
Health,,Religion2020,1980,1
Health,,Religion2020,1985,4
Health,,Religion2020,1990,8
Health,,Religion2020,1995,18
Health,,Religion2020,2000,20
Health,,Religion2020,2005,46
Health,,Religion2020,2010,93
Health,,Religion2020,2015,94
Health,Adjunct Professor,Religion2020,1985,3
Health,Adjunct Professor,Religion2020,1990,1
Health,Adjunct Professor,Religion2020,1995,2
Health,Adjunct Professor,Religion2020,2000,19
Health,Adjunct Professor,Religion2020,2005,46
Health,Adjunct Professor,Religion2020,2010,89
Health,Adjunct Professor,Religion2020,2015,74
Health,Assistant Professor,Religion2020,1975,1
Health,Assistant Professor,Religion2020,1985,1
Health,Assistant Professor,Religion2020,1990,1
Health,Assistant Professor,Religion2020,1995,2
Health,Assistant Professor,Religion2020,2000,3
Health,Assistant Professor,Religion2020,2005,34
Health,Assistant Professor,Religion2020,2010,100
Health,Assistant Professor,Religion2020,2015,113
Health,Associate Professor,Religion2020,1965,3
Health,Associate Professor,Religion2020,1975,1
Health,Associate Professor,Religion2020,1980,4
Health,Associate Professor,Religion2020,1990,4
Health,Associate Professor,Religion2020,1995,17
Health,Associate Professor,Religion2020,2000,51
Health,Associate Professor,Religion2020,2005,98
Health,Associate Professor,Religion2020,2010,210
Health,Associate Professor,Religion2020,2015,213
Health,Graduate Student,Religion2020,1975,5
Health,Graduate Student,Religion2020,1980,2
Health,Graduate Student,Religion2020,1995,2
Health,Graduate Student,Religion2020,2010,4
Health,Graduate Student,Religion2020,2015,6
Health,Non-Academic,Religion2020,1940,1
Health,Non-Academic,Religion2020,1960,7
Health,Non-Academic,Religion2020,1965,1
Health,Non-Academic,Religion2020,1970,1
Health,Non-Academic,Religion2020,1975,2
Health,Non-Academic,Religion2020,1980,5
Health,Non-Academic,Religion2020,1985,4
Health,Non-Academic,Religion2020,1990,6
Health,Non-Academic,Religion2020,1995,12
Health,Non-Academic,Religion2020,2000,61
Health,Non-Academic,Religion2020,2005,151
Health,Non-Academic,Religion2020,2010,217
Health,Non-Academic,Religion2020,2015,296
Health,Postdoctoral Fellow,Religion2020,2005,3
Health,Postdoctoral Fellow,Religion2020,2010,6
Health,Postdoctoral Fellow,Religion2020,2015,37
Health,Professor,Religion2020,1945,2
Health,Professor,Religion2020,1950,14
Health,Professor,Religion2020,1955,19
Health,Professor,Religion2020,1965,1
Health,Professor,Religion2020,1970,3
Health,Professor,Religion2020,1975,9
Health,Professor,Religion2020,1980,11
Health,Professor,Religion2020,1985,27
Health,Professor,Religion2020,1990,43
Health,Professor,Religion2020,1995,73
Health,Professor,Religion2020,2000,195
Health,Professor,Religion2020,2005,352
Health,Professor,Religion2020,2010,567
Health,Professor,Religion2020,2015,576
Health,Professor Emeritus,Religion2020,1965,4
Health,Professor Emeritus,Religion2020,1970,3
Health,Professor Emeritus,Religion2020,1975,3
Health,Professor Emeritus,Religion2020,1980,6
Health,Professor Emeritus,Religion2020,1985,10
Health,Professor Emeritus,Religion2020,1990,9
Health,Professor Emeritus,Religion2020,1995,17
Health,Professor Emeritus,Religion2020,2000,34
Health,Professor Emeritus,Religion2020,2005,62
Health,Professor Emeritus,Religion2020,2010,48
Health,Professor Emeritus,Religion2020,2015,31
Health,Researcher,Religion2020,1980,5
Health,Researcher,Religion2020,1985,1
Health,Researcher,Religion2020,1990,15
Health,Researcher,Religion2020,1995,26
Health,Researcher,Religion2020,2000,86
Health,Researcher,Religion2020,2005,169
Health,Researcher,Religion2020,2010,345
Health,Researcher,Religion2020,2015,302
History,,Religion2020,1950,1
History,,Religion2020,1955,1
History,,Religion2020,1960,3
History,,Religion2020,1965,13
History,,Religion2020,1970,11
History,,Religion2020,1975,6
History,,Religion2020,1980,19
History,,Religion2020,1985,8
History,,Religion2020,1990,8
History,,Religion2020,1995,7
History,,Religion2020,2000,5
History,,Religion2020,2005,1
History,,Religion2020,2010,10
History,,Religion2020,2015,11
History,Adjunct Professor,Religion2020,1900,4
History,Adjunct Professor,Religion2020,1905,1
History,Adjunct Professor,Religion2020,1910,2
History,Adjunct Professor,Religion2020,1920,1
History,Adjunct Professor,Religion2020,1925,1
History,Adjunct Professor,Religion2020,1930,2
History,Adjunct Professor,Religion2020,1960,1
History,Adjunct Professor,Religion2020,1970,4
History,Adjunct Professor,Religion2020,1975,1
History,Adjunct Professor,Religion2020,1980,2
History,Adjunct Professor,Religion2020,1985,8
History,Adjunct Professor,Religion2020,1990,4
History,Adjunct Professor,Religion2020,1995,10
History,Adjunct Professor,Religion2020,2000,21
History,Adjunct Professor,Religion2020,2005,11
History,Adjunct Professor,Religion2020,2010,51
History,Adjunct Professor,Religion2020,2015,53
History,Assistant Professor,Religion2020,1955,1
History,Assistant Professor,Religion2020,1975,3
History,Assistant Professor,Religion2020,1980,2
History,Assistant Professor,Religion2020,1985,10
History,Assistant Professor,Religion2020,1990,12
History,Assistant Professor,Religion2020,1995,9
History,Assistant Professor,Religion2020,2000,15
History,Assistant Professor,Religion2020,2005,20
History,Assistant Professor,Religion2020,2010,65
History,Assistant Professor,Religion2020,2015,66
History,Associate Professor,Religion2020,1960,3
History,Associate Professor,Religion2020,1965,6
History,Associate Professor,Religion2020,1970,2
History,Associate Professor,Religion2020,1975,4
History,Associate Professor,Religion2020,1980,3
History,Associate Professor,Religion2020,1985,10
History,Associate Professor,Religion2020,1990,13
History,Associate Professor,Religion2020,1995,27
History,Associate Professor,Religion2020,2000,51
History,Associate Professor,Religion2020,2005,53
History,Associate Professor,Religion2020,2010,132
History,Associate Professor,Religion2020,2015,146
History,Graduate Student,Religion2020,2000,2
History,Graduate Student,Religion2020,2005,1
History,Graduate Student,Religion2020,2010,2
History,Graduate Student,Religion2020,2015,6
History,Non-Academic,Religion2020,1975,2
History,Non-Academic,Religion2020,1980,4
History,Non-Academic,Religion2020,1985,8
History,Non-Academic,Religion2020,1990,7
History,Non-Academic,Religion2020,1995,10
History,Non-Academic,Religion2020,2000,4
History,Non-Academic,Religion2020,2005,11
History,Non-Academic,Religion2020,2010,12
History,Non-Academic,Religion2020,2015,19
History,Postdoctoral Fellow,Religion2020,2000,1
History,Postdoctoral Fellow,Religion2020,2005,7
History,Postdoctoral Fellow,Religion2020,2010,22
History,Postdoctoral Fellow,Religion2020,2015,21
History,Professor,Religion2020,1885,9
History,Professor,Religion2020,1895,10
History,Professor,Religion2020,1900,1
History,Professor,Religion2020,1920,2
History,Professor,Religion2020,1930,5
History,Professor,Religion2020,1935,7
History,Professor,Religion2020,1940,6
History,Professor,Religion2020,1945,11
History,Professor,Religion2020,1950,9
History,Professor,Religion2020,1955,5
History,Professor,Religion2020,1960,33
History,Professor,Religion2020,1965,20
History,Professor,Religion2020,1970,40
History,Professor,Religion2020,1975,62
History,Professor,Religion2020,1980,70
History,Professor,Religion2020,1985,136
History,Professor,Religion2020,1990,191
History,Professor,Religion2020,1995,291
History,Professor,Religion2020,2000,355
History,Professor,Religion2020,2005,415
History,Professor,Religion2020,2010,622
History,Professor,Religion2020,2015,603
History,Professor Emeritus,Religion2020,1945,3
History,Professor Emeritus,Religion2020,1950,6
History,Professor Emeritus,Religion2020,1955,6
History,Professor Emeritus,Religion2020,1960,27
History,Professor Emeritus,Religion2020,1965,45
History,Professor Emeritus,Religion2020,1970,64
History,Professor Emeritus,Religion2020,1975,132
History,Professor Emeritus,Religion2020,1980,149
History,Professor Emeritus,Religion2020,1985,205
History,Professor Emeritus,Religion2020,1990,194
History,Professor Emeritus,Religion2020,1995,209
History,Professor Emeritus,Religion2020,2000,216
History,Professor Emeritus,Religion2020,2005,162
History,Professor Emeritus,Religion2020,2010,195
History,Professor Emeritus,Religion2020,2015,159
History,Researcher,Religion2020,1980,2
History,Researcher,Religion2020,1985,4
History,Researcher,Religion2020,1990,11
History,Researcher,Religion2020,1995,20
History,Researcher,Religion2020,2000,23
History,Researcher,Religion2020,2005,31
History,Researcher,Religion2020,2010,77
History,Researcher,Religion2020,2015,66
Islamic Studies,,Religion2020,2000,5
Islamic Studies,,Religion2020,2005,16
Islamic Studies,,Religion2020,2010,21
Islamic Studies,,Religion2020,2015,10
Islamic Studies,Adjunct Professor,Religion2020,2010,2
Islamic Studies,Adjunct Professor,Religion2020,2015,29
Islamic Studies,Assistant Professor,Religion2020,2010,12
Islamic Studies,Assistant Professor,Religion2020,2015,26
Islamic Studies,Associate Professor,Religion2020,1970,1
Islamic Studies,Associate Professor,Religion2020,2000,2
Islamic Studies,Associate Professor,Religion2020,2005,9
Islamic Studies,Associate Professor,Religion2020,2010,17
Islamic Studies,Associate Professor,Religion2020,2015,40
Islamic Studies,Non-Academic,Religion2020,1970,1
Islamic Studies,Non-Academic,Religion2020,1975,3
Islamic Studies,Non-Academic,Religion2020,1980,2
Islamic Studies,Non-Academic,Religion2020,1995,2
Islamic Studies,Non-Academic,Religion2020,2000,3
Islamic Studies,Non-Academic,Religion2020,2010,3
Islamic Studies,Non-Academic,Religion2020,2015,7
Islamic Studies,Postdoctoral Fellow,Religion2020,2000,2
Islamic Studies,Postdoctoral Fellow,Religion2020,2005,2
Islamic Studies,Postdoctoral Fellow,Religion2020,2015,5
Islamic Studies,Professor,Religion2020,1890,1
Islamic Studies,Professor,Religion2020,1970,1
Islamic Studies,Professor,Religion2020,1985,1
Islamic Studies,Professor,Religion2020,1995,7
Islamic Studies,Professor,Religion2020,2000,17
Islamic Studies,Professor,Religion2020,2005,39
Islamic Studies,Professor,Religion2020,2010,129
Islamic Studies,Professor,Religion2020,2015,148
Islamic Studies,Professor Emeritus,Religion2020,1965,2
Islamic Studies,Professor Emeritus,Religion2020,1970,1
Islamic Studies,Professor Emeritus,Religion2020,1975,3
Islamic Studies,Professor Emeritus,Religion2020,1985,9
Islamic Studies,Professor Emeritus,Religion2020,1990,8
Islamic Studies,Professor Emeritus,Religion2020,1995,6
Islamic Studies,Professor Emeritus,Religion2020,2000,6
Islamic Studies,Professor Emeritus,Religion2020,2005,1
Islamic Studies,Professor Emeritus,Religion2020,2010,9
Islamic Studies,Professor Emeritus,Religion2020,2015,18
Islamic Studies,Researcher,Religion2020,1995,1
Islamic Studies,Researcher,Religion2020,2000,6
Islamic Studies,Researcher,Religion2020,2005,4
Islamic Studies,Researcher,Religion2020,2010,81
Islamic Studies,Researcher,Religion2020,2015,36
Law,,Religion2020,1980,2
Law,,Religion2020,1985,1
Law,,Religion2020,1995,2
Law,,Religion2020,2000,2
Law,,Religion2020,2005,1
Law,,Religion2020,2010,3
Law,,Religion2020,2015,4
Law,Adjunct Professor,Religion2020,1975,1
Law,Adjunct Professor,Religion2020,1985,3
Law,Adjunct Professor,Religion2020,1995,2
Law,Adjunct Professor,Religion2020,2000,3
Law,Adjunct Professor,Religion2020,2005,7
Law,Adjunct Professor,Religion2020,2010,24
Law,Adjunct Professor,Religion2020,2015,38
Law,Assistant Professor,Religion2020,2000,2
Law,Assistant Professor,Religion2020,2005,3
Law,Assistant Professor,Religion2020,2010,20
Law,Assistant Professor,Religion2020,2015,12
Law,Associate Professor,Religion2020,1980,1
Law,Associate Professor,Religion2020,1985,1
Law,Associate Professor,Religion2020,1990,2
Law,Associate Professor,Religion2020,1995,2
Law,Associate Professor,Religion2020,2000,10
Law,Associate Professor,Religion2020,2005,28
Law,Associate Professor,Religion2020,2010,55
Law,Associate Professor,Religion2020,2015,63
Law,Graduate Student,Religion2020,2005,2
Law,Graduate Student,Religion2020,2010,18
Law,Non-Academic,Religion2020,2000,4
Law,Non-Academic,Religion2020,2005,6
Law,Non-Academic,Religion2020,2010,8
Law,Non-Academic,Religion2020,2015,20
Law,Postdoctoral Fellow,Religion2020,2010,6
Law,Postdoctoral Fellow,Religion2020,2015,8
Law,Professor,Religion2020,1925,1
Law,Professor,Religion2020,1930,2
Law,Professor,Religion2020,1935,2
Law,Professor,Religion2020,1940,2
Law,Professor,Religion2020,1950,6
Law,Professor,Religion2020,1955,1
Law,Professor,Religion2020,1960,6
Law,Professor,Religion2020,1965,5
Law,Professor,Religion2020,1970,7
Law,Professor,Religion2020,1975,14
Law,Professor,Religion2020,1980,8
Law,Professor,Religion2020,1985,29
Law,Professor,Religion2020,1990,35
Law,Professor,Religion2020,1995,48
Law,Professor,Religion2020,2000,107
Law,Professor,Religion2020,2005,179
Law,Professor,Religion2020,2010,339
Law,Professor,Religion2020,2015,350
Law,Professor Emeritus,Religion2020,1850,1
Law,Professor Emeritus,Religion2020,1955,1
Law,Professor Emeritus,Religion2020,1960,2
Law,Professor Emeritus,Religion2020,1965,1
Law,Professor Emeritus,Religion2020,1970,8
Law,Professor Emeritus,Religion2020,1975,5
Law,Professor Emeritus,Religion2020,1980,3
Law,Professor Emeritus,Religion2020,1985,9
Law,Professor Emeritus,Religion2020,1990,9
Law,Professor Emeritus,Religion2020,1995,7
Law,Professor Emeritus,Religion2020,2000,26
Law,Professor Emeritus,Religion2020,2005,18
Law,Professor Emeritus,Religion2020,2010,37
Law,Professor Emeritus,Religion2020,2015,25
Law,Researcher,Religion2020,1990,3
Law,Researcher,Religion2020,1995,3
Law,Researcher,Religion2020,2000,4
Law,Researcher,Religion2020,2005,7
Law,Researcher,Religion2020,2010,27
Law,Researcher,Religion2020,2015,28
Management,Adjunct Professor,Religion2020,1990,1
Management,Adjunct Professor,Religion2020,1995,3
Management,Adjunct Professor,Religion2020,2000,1
Management,Adjunct Professor,Religion2020,2005,2
Management,Adjunct Professor,Religion2020,2010,6
Management,Adjunct Professor,Religion2020,2015,3
Management,Associate Professor,Religion2020,1990,1
Management,Associate Professor,Religion2020,1995,2
Management,Associate Professor,Religion2020,2000,2
Management,Associate Professor,Religion2020,2005,7
Management,Associate Professor,Religion2020,2010,14
Management,Associate Professor,Religion2020,2015,58
Management,Non-Academic,Religion2020,2000,4
Management,Non-Academic,Religion2020,2005,26
Management,Non-Academic,Religion2020,2010,13
Management,Non-Academic,Religion2020,2015,19
Management,Professor,Religion2020,1965,1
Management,Professor,Religion2020,1980,1
Management,Professor,Religion2020,1990,1
Management,Professor,Religion2020,1995,7
Management,Professor,Religion2020,2000,27
Management,Professor,Religion2020,2005,34
Management,Professor,Religion2020,2010,40
Management,Professor,Religion2020,2015,55
Management,Professor Emeritus,Religion2020,1985,1
Management,Professor Emeritus,Religion2020,1990,3
Management,Professor Emeritus,Religion2020,1995,5
Management,Professor Emeritus,Religion2020,2000,8
Management,Professor Emeritus,Religion2020,2005,16
Management,Professor Emeritus,Religion2020,2010,8
Management,Professor Emeritus,Religion2020,2015,8
Management,Researcher,Religion2020,2000,5
Management,Researcher,Religion2020,2005,1
Management,Researcher,Religion2020,2010,3
Mathematics,,Religion2020,2010,1
Mathematics,Adjunct Professor,Religion2020,1995,1
Mathematics,Adjunct Professor,Religion2020,2005,2
Mathematics,Adjunct Professor,Religion2020,2010,5
Mathematics,Assistant Professor,Religion2020,1995,3
Mathematics,Assistant Professor,Religion2020,2000,2
Mathematics,Assistant Professor,Religion2020,2010,1
Mathematics,Associate Professor,Religion2020,2005,1
Mathematics,Associate Professor,Religion2020,2010,5
Mathematics,Associate Professor,Religion2020,2015,3
Mathematics,Professor,Religion2020,1930,1
Mathematics,Professor,Religion2020,1935,2
Mathematics,Professor,Religion2020,1955,2
Mathematics,Professor,Religion2020,1965,1
Mathematics,Professor,Religion2020,1980,1
Mathematics,Professor,Religion2020,1985,1
Mathematics,Professor,Religion2020,2000,9
Mathematics,Professor,Religion2020,2005,5
Mathematics,Professor,Religion2020,2010,11
Mathematics,Professor,Religion2020,2015,7
Mathematics,Professor Emeritus,Religion2020,1975,5
Mathematics,Professor Emeritus,Religion2020,1980,5
Mathematics,Researcher,Religion2020,1900,1
Mathematics,Researcher,Religion2020,1985,1
Mathematics,Researcher,Religion2020,1990,1
Mathematics,Researcher,Religion2020,1995,13
Mathematics,Researcher,Religion2020,2005,2
Mathematics,Researcher,Religion2020,2015,1
Medicine,,Religion2020,1975,4
Medicine,,Religion2020,1980,3
Medicine,,Religion2020,1985,14
Medicine,,Religion2020,1990,8
Medicine,,Religion2020,1995,5
Medicine,,Religion2020,2000,26
Medicine,,Religion2020,2005,26
Medicine,,Religion2020,2010,76
Medicine,,Religion2020,2015,60
Medicine,Adjunct Professor,Religion2020,1980,1
Medicine,Adjunct Professor,Religion2020,1985,13
Medicine,Adjunct Professor,Religion2020,1990,11
Medicine,Adjunct Professor,Religion2020,1995,17
Medicine,Adjunct Professor,Religion2020,2000,12
Medicine,Adjunct Professor,Religion2020,2005,49
Medicine,Adjunct Professor,Religion2020,2010,90
Medicine,Adjunct Professor,Religion2020,2015,48
Medicine,Assistant Professor,Religion2020,1970,3
Medicine,Assistant Professor,Religion2020,1975,4
Medicine,Assistant Professor,Religion2020,1980,2
Medicine,Assistant Professor,Religion2020,1985,1
Medicine,Assistant Professor,Religion2020,1990,3
Medicine,Assistant Professor,Religion2020,1995,5
Medicine,Assistant Professor,Religion2020,2000,10
Medicine,Assistant Professor,Religion2020,2005,53
Medicine,Assistant Professor,Religion2020,2010,128
Medicine,Assistant Professor,Religion2020,2015,167
Medicine,Associate Professor,Religion2020,1975,1
Medicine,Associate Professor,Religion2020,1980,7
Medicine,Associate Professor,Religion2020,1985,4
Medicine,Associate Professor,Religion2020,1990,11
Medicine,Associate Professor,Religion2020,1995,21
Medicine,Associate Professor,Religion2020,2000,46
Medicine,Associate Professor,Religion2020,2005,101
Medicine,Associate Professor,Religion2020,2010,240
Medicine,Associate Professor,Religion2020,2015,357
Medicine,Graduate Student,Religion2020,1985,1
Medicine,Graduate Student,Religion2020,1990,2
Medicine,Graduate Student,Religion2020,1995,2
Medicine,Graduate Student,Religion2020,2000,3
Medicine,Graduate Student,Religion2020,2005,3
Medicine,Graduate Student,Religion2020,2010,3
Medicine,Graduate Student,Religion2020,2015,15
Medicine,Non-Academic,Religion2020,1955,1
Medicine,Non-Academic,Religion2020,1960,1
Medicine,Non-Academic,Religion2020,1965,6
Medicine,Non-Academic,Religion2020,1970,9
Medicine,Non-Academic,Religion2020,1975,6
Medicine,Non-Academic,Religion2020,1980,4
Medicine,Non-Academic,Religion2020,1985,9
Medicine,Non-Academic,Religion2020,1990,12
Medicine,Non-Academic,Religion2020,1995,36
Medicine,Non-Academic,Religion2020,2000,86
Medicine,Non-Academic,Religion2020,2005,128
Medicine,Non-Academic,Religion2020,2010,187
Medicine,Non-Academic,Religion2020,2015,207
Medicine,Postdoctoral Fellow,Religion2020,1985,1
Medicine,Postdoctoral Fellow,Religion2020,1990,2
Medicine,Postdoctoral Fellow,Religion2020,2005,3
Medicine,Postdoctoral Fellow,Religion2020,2010,20
Medicine,Postdoctoral Fellow,Religion2020,2015,49
Medicine,Professor,Religion2020,1960,2
Medicine,Professor,Religion2020,1965,16
Medicine,Professor,Religion2020,1970,38
Medicine,Professor,Religion2020,1975,27
Medicine,Professor,Religion2020,1980,24
Medicine,Professor,Religion2020,1985,61
Medicine,Professor,Religion2020,1990,70
Medicine,Professor,Religion2020,1995,97
Medicine,Professor,Religion2020,2000,223
Medicine,Professor,Religion2020,2005,504
Medicine,Professor,Religion2020,2010,672
Medicine,Professor,Religion2020,2015,676
Medicine,Professor Emeritus,Religion2020,1955,6
Medicine,Professor Emeritus,Religion2020,1960,23
Medicine,Professor Emeritus,Religion2020,1965,4
Medicine,Professor Emeritus,Religion2020,1970,5
Medicine,Professor Emeritus,Religion2020,1975,12
Medicine,Professor Emeritus,Religion2020,1980,11
Medicine,Professor Emeritus,Religion2020,1985,13
Medicine,Professor Emeritus,Religion2020,1990,30
Medicine,Professor Emeritus,Religion2020,1995,31
Medicine,Professor Emeritus,Religion2020,2000,42
Medicine,Professor Emeritus,Religion2020,2005,70
Medicine,Professor Emeritus,Religion2020,2010,50
Medicine,Professor Emeritus,Religion2020,2015,37
Medicine,Researcher,Religion2020,1970,1
Medicine,Researcher,Religion2020,1975,4
Medicine,Researcher,Religion2020,1980,4
Medicine,Researcher,Religion2020,1985,2
Medicine,Researcher,Religion2020,1990,12
Medicine,Researcher,Religion2020,1995,31
Medicine,Researcher,Religion2020,2000,48
Medicine,Researcher,Religion2020,2005,117
Medicine,Researcher,Religion2020,2010,228
Medicine,Researcher,Religion2020,2015,250
NA,,Religion2020,1925,2
NA,,Religion2020,1930,6
NA,,Religion2020,1935,4
NA,,Religion2020,1940,5
NA,,Religion2020,1945,4
NA,,Religion2020,1950,5
NA,,Religion2020,1965,2
NA,,Religion2020,1970,6
NA,,Religion2020,1975,16
NA,,Religion2020,1980,3
NA,,Religion2020,1985,14
NA,,Religion2020,1990,16
NA,,Religion2020,1995,21
NA,,Religion2020,2000,37
NA,,Religion2020,2005,67
NA,,Religion2020,2010,174
NA,,Religion2020,2015,143
NA,Adjunct Professor,Religion2020,1915,1
NA,Adjunct Professor,Religion2020,1925,3
NA,Adjunct Professor,Religion2020,1930,2
NA,Adjunct Professor,Religion2020,1935,2
NA,Adjunct Professor,Religion2020,1945,1
NA,Adjunct Professor,Religion2020,1950,1
NA,Adjunct Professor,Religion2020,1960,1
NA,Adjunct Professor,Religion2020,1965,1
NA,Adjunct Professor,Religion2020,1975,2
NA,Adjunct Professor,Religion2020,1980,2
NA,Adjunct Professor,Religion2020,1985,7
NA,Adjunct Professor,Religion2020,1990,3
NA,Adjunct Professor,Religion2020,1995,6
NA,Adjunct Professor,Religion2020,2000,14
NA,Adjunct Professor,Religion2020,2005,20
NA,Adjunct Professor,Religion2020,2010,72
NA,Adjunct Professor,Religion2020,2015,69
NA,Assistant Professor,Religion2020,1930,1
NA,Assistant Professor,Religion2020,1935,6
NA,Assistant Professor,Religion2020,1940,2
NA,Assistant Professor,Religion2020,1945,1
NA,Assistant Professor,Religion2020,1960,3
NA,Assistant Professor,Religion2020,1990,1
NA,Assistant Professor,Religion2020,2000,6
NA,Assistant Professor,Religion2020,2005,11
NA,Assistant Professor,Religion2020,2010,24
NA,Assistant Professor,Religion2020,2015,32
NA,Associate Professor,Religion2020,1980,1
NA,Associate Professor,Religion2020,1990,2
NA,Associate Professor,Religion2020,1995,3
NA,Associate Professor,Religion2020,2000,2
NA,Associate Professor,Religion2020,2005,16
NA,Associate Professor,Religion2020,2010,36
NA,Associate Professor,Religion2020,2015,39
NA,Graduate Student,Religion2020,2005,1
NA,Graduate Student,Religion2020,2010,7
NA,Graduate Student,Religion2020,2015,4
NA,Non-Academic,Religion2020,1835,1
NA,Non-Academic,Religion2020,1845,2
NA,Non-Academic,Religion2020,1850,2
NA,Non-Academic,Religion2020,1860,1
NA,Non-Academic,Religion2020,1895,1
NA,Non-Academic,Religion2020,1910,2
NA,Non-Academic,Religion2020,1915,4
NA,Non-Academic,Religion2020,1920,3
NA,Non-Academic,Religion2020,1925,4
NA,Non-Academic,Religion2020,1930,5
NA,Non-Academic,Religion2020,1935,15
NA,Non-Academic,Religion2020,1940,10
NA,Non-Academic,Religion2020,1945,15
NA,Non-Academic,Religion2020,1950,16
NA,Non-Academic,Religion2020,1955,8
NA,Non-Academic,Religion2020,1960,8
NA,Non-Academic,Religion2020,1965,7
NA,Non-Academic,Religion2020,1970,19
NA,Non-Academic,Religion2020,1975,26
NA,Non-Academic,Religion2020,1980,50
NA,Non-Academic,Religion2020,1985,58
NA,Non-Academic,Religion2020,1990,104
NA,Non-Academic,Religion2020,1995,101
NA,Non-Academic,Religion2020,2000,153
NA,Non-Academic,Religion2020,2005,342
NA,Non-Academic,Religion2020,2010,582
NA,Non-Academic,Religion2020,2015,565
NA,Postdoctoral Fellow,Religion2020,1955,3
NA,Postdoctoral Fellow,Religion2020,1975,2
NA,Postdoctoral Fellow,Religion2020,1980,2
NA,Postdoctoral Fellow,Religion2020,1985,2
NA,Postdoctoral Fellow,Religion2020,1990,4
NA,Postdoctoral Fellow,Religion2020,1995,4
NA,Postdoctoral Fellow,Religion2020,2000,11
NA,Postdoctoral Fellow,Religion2020,2005,14
NA,Postdoctoral Fellow,Religion2020,2010,34
NA,Postdoctoral Fellow,Religion2020,2015,35
NA,Professor,Religion2020,1920,2
NA,Professor,Religion2020,1925,2
NA,Professor,Religion2020,1930,3
NA,Professor,Religion2020,1935,6
NA,Professor,Religion2020,1940,9
NA,Professor,Religion2020,1945,14
NA,Professor,Religion2020,1950,10
NA,Professor,Religion2020,1955,3
NA,Professor,Religion2020,1960,2
NA,Professor,Religion2020,1965,12
NA,Professor,Religion2020,1970,12
NA,Professor,Religion2020,1975,8
NA,Professor,Religion2020,1980,9
NA,Professor,Religion2020,1985,15
NA,Professor,Religion2020,1990,13
NA,Professor,Religion2020,1995,27
NA,Professor,Religion2020,2000,25
NA,Professor,Religion2020,2005,44
NA,Professor,Religion2020,2010,78
NA,Professor,Religion2020,2015,84
NA,Professor Emeritus,Religion2020,1965,1
NA,Professor Emeritus,Religion2020,1980,2
NA,Professor Emeritus,Religion2020,1985,5
NA,Professor Emeritus,Religion2020,1990,10
NA,Professor Emeritus,Religion2020,1995,2
NA,Professor Emeritus,Religion2020,2000,5
NA,Professor Emeritus,Religion2020,2005,7
NA,Professor Emeritus,Religion2020,2010,31
NA,Professor Emeritus,Religion2020,2015,13
NA,Researcher,Religion2020,1960,1
NA,Researcher,Religion2020,1970,4
NA,Researcher,Religion2020,1975,7
NA,Researcher,Religion2020,1980,4
NA,Researcher,Religion2020,1985,22
NA,Researcher,Religion2020,1990,24
NA,Researcher,Religion2020,1995,45
NA,Researcher,Religion2020,2000,86
NA,Researcher,Religion2020,2005,138
NA,Researcher,Religion2020,2010,226
NA,Researcher,Religion2020,2015,277
Neurology,,Religion2020,1965,1
Neurology,,Religion2020,1970,1
Neurology,,Religion2020,2000,7
Neurology,,Religion2020,2005,9
Neurology,,Religion2020,2010,5
Neurology,,Religion2020,2015,4
Neurology,Adjunct Professor,Religion2020,2005,1
Neurology,Adjunct Professor,Religion2020,2010,6
Neurology,Adjunct Professor,Religion2020,2015,22
Neurology,Associate Professor,Religion2020,1995,2
Neurology,Associate Professor,Religion2020,2000,1
Neurology,Associate Professor,Religion2020,2005,2
Neurology,Associate Professor,Religion2020,2010,23
Neurology,Associate Professor,Religion2020,2015,26
Neurology,Non-Academic,Religion2020,1995,13
Neurology,Non-Academic,Religion2020,2005,1
Neurology,Non-Academic,Religion2020,2010,4
Neurology,Non-Academic,Religion2020,2015,7
Neurology,Postdoctoral Fellow,Religion2020,2010,6
Neurology,Postdoctoral Fellow,Religion2020,2015,8
Neurology,Professor,Religion2020,1965,3
Neurology,Professor,Religion2020,1970,6
Neurology,Professor,Religion2020,1995,2
Neurology,Professor,Religion2020,2000,8
Neurology,Professor,Religion2020,2005,22
Neurology,Professor,Religion2020,2010,54
Neurology,Professor,Religion2020,2015,49
Neurology,Professor Emeritus,Religion2020,1995,7
Neurology,Professor Emeritus,Religion2020,2000,2
Neurology,Professor Emeritus,Religion2020,2005,1
Neurology,Professor Emeritus,Religion2020,2010,6
Neurology,Researcher,Religion2020,2000,3
Neurology,Researcher,Religion2020,2005,4
Neurology,Researcher,Religion2020,2010,4
Neurology,Researcher,Religion2020,2015,16
Neuroscience,,Religion2020,1975,1
Neuroscience,,Religion2020,1985,1
Neuroscience,,Religion2020,1990,2
Neuroscience,,Religion2020,1995,3
Neuroscience,,Religion2020,2000,5
Neuroscience,,Religion2020,2005,13
Neuroscience,,Religion2020,2010,13
Neuroscience,,Religion2020,2015,11
Neuroscience,Adjunct Professor,Religion2020,1990,2
Neuroscience,Adjunct Professor,Religion2020,1995,1
Neuroscience,Adjunct Professor,Religion2020,2005,3
Neuroscience,Adjunct Professor,Religion2020,2010,14
Neuroscience,Adjunct Professor,Religion2020,2015,19
Neuroscience,Assistant Professor,Religion2020,1990,1
Neuroscience,Assistant Professor,Religion2020,2000,1
Neuroscience,Assistant Professor,Religion2020,2005,5
Neuroscience,Assistant Professor,Religion2020,2010,15
Neuroscience,Assistant Professor,Religion2020,2015,29
Neuroscience,Associate Professor,Religion2020,1995,1
Neuroscience,Associate Professor,Religion2020,2000,7
Neuroscience,Associate Professor,Religion2020,2005,27
Neuroscience,Associate Professor,Religion2020,2010,35
Neuroscience,Associate Professor,Religion2020,2015,26
Neuroscience,Graduate Student,Religion2020,2010,6
Neuroscience,Graduate Student,Religion2020,2015,11
Neuroscience,Non-Academic,Religion2020,2000,3
Neuroscience,Non-Academic,Religion2020,2005,6
Neuroscience,Non-Academic,Religion2020,2010,21
Neuroscience,Non-Academic,Religion2020,2015,7
Neuroscience,Postdoctoral Fellow,Religion2020,1995,1
Neuroscience,Postdoctoral Fellow,Religion2020,2000,1
Neuroscience,Postdoctoral Fellow,Religion2020,2005,13
Neuroscience,Postdoctoral Fellow,Religion2020,2010,27
Neuroscience,Postdoctoral Fellow,Religion2020,2015,16
Neuroscience,Professor,Religion2020,1975,2
Neuroscience,Professor,Religion2020,1980,12
Neuroscience,Professor,Religion2020,1985,18
Neuroscience,Professor,Religion2020,1990,17
Neuroscience,Professor,Religion2020,1995,24
Neuroscience,Professor,Religion2020,2000,37
Neuroscience,Professor,Religion2020,2005,76
Neuroscience,Professor,Religion2020,2010,138
Neuroscience,Professor,Religion2020,2015,123
Neuroscience,Professor Emeritus,Religion2020,1995,1
Neuroscience,Professor Emeritus,Religion2020,2000,1
Neuroscience,Professor Emeritus,Religion2020,2005,4
Neuroscience,Professor Emeritus,Religion2020,2010,8
Neuroscience,Professor Emeritus,Religion2020,2015,6
Neuroscience,Researcher,Religion2020,1970,3
Neuroscience,Researcher,Religion2020,1975,7
Neuroscience,Researcher,Religion2020,1980,6
Neuroscience,Researcher,Religion2020,1985,4
Neuroscience,Researcher,Religion2020,1995,1
Neuroscience,Researcher,Religion2020,2000,7
Neuroscience,Researcher,Religion2020,2005,35
Neuroscience,Researcher,Religion2020,2010,71
Neuroscience,Researcher,Religion2020,2015,95
Nursing,,Religion2020,1940,1
Nursing,,Religion2020,1950,1
Nursing,,Religion2020,1970,5
Nursing,,Religion2020,1975,1
Nursing,,Religion2020,1980,1
Nursing,,Religion2020,1985,1
Nursing,,Religion2020,1990,3
Nursing,,Religion2020,1995,3
Nursing,,Religion2020,2000,11
Nursing,,Religion2020,2005,15
Nursing,,Religion2020,2010,23
Nursing,,Religion2020,2015,38
Nursing,Adjunct Professor,Religion2020,2000,3
Nursing,Adjunct Professor,Religion2020,2005,45
Nursing,Adjunct Professor,Religion2020,2010,44
Nursing,Adjunct Professor,Religion2020,2015,30
Nursing,Assistant Professor,Religion2020,1985,1
Nursing,Assistant Professor,Religion2020,1990,2
Nursing,Assistant Professor,Religion2020,1995,7
Nursing,Assistant Professor,Religion2020,2000,12
Nursing,Assistant Professor,Religion2020,2005,17
Nursing,Assistant Professor,Religion2020,2010,58
Nursing,Assistant Professor,Religion2020,2015,102
Nursing,Associate Professor,Religion2020,1970,2
Nursing,Associate Professor,Religion2020,1980,1
Nursing,Associate Professor,Religion2020,1985,3
Nursing,Associate Professor,Religion2020,1995,1
Nursing,Associate Professor,Religion2020,2000,17
Nursing,Associate Professor,Religion2020,2005,54
Nursing,Associate Professor,Religion2020,2010,103
Nursing,Associate Professor,Religion2020,2015,211
Nursing,Graduate Student,Religion2020,2015,8
Nursing,Non-Academic,Religion2020,1980,1
Nursing,Non-Academic,Religion2020,1985,2
Nursing,Non-Academic,Religion2020,1990,2
Nursing,Non-Academic,Religion2020,1995,4
Nursing,Non-Academic,Religion2020,2000,8
Nursing,Non-Academic,Religion2020,2005,34
Nursing,Non-Academic,Religion2020,2010,31
Nursing,Non-Academic,Religion2020,2015,35
Nursing,Professor,Religion2020,1970,6
Nursing,Professor,Religion2020,1975,1
Nursing,Professor,Religion2020,1980,4
Nursing,Professor,Religion2020,1985,6
Nursing,Professor,Religion2020,1990,13
Nursing,Professor,Religion2020,1995,40
Nursing,Professor,Religion2020,2000,106
Nursing,Professor,Religion2020,2005,217
Nursing,Professor,Religion2020,2010,279
Nursing,Professor,Religion2020,2015,366
Nursing,Professor Emeritus,Religion2020,1985,8
Nursing,Professor Emeritus,Religion2020,1990,9
Nursing,Professor Emeritus,Religion2020,1995,25
Nursing,Professor Emeritus,Religion2020,2000,27
Nursing,Professor Emeritus,Religion2020,2005,41
Nursing,Professor Emeritus,Religion2020,2010,36
Nursing,Professor Emeritus,Religion2020,2015,27
Nursing,Researcher,Religion2020,1995,1
Nursing,Researcher,Religion2020,2000,5
Nursing,Researcher,Religion2020,2005,28
Nursing,Researcher,Religion2020,2010,44
Nursing,Researcher,Religion2020,2015,48
Nursing,Undergraduate Student,Religion2020,2005,2
Nursing,Undergraduate Student,Religion2020,2015,4
Philosophy,,Religion2020,1895,1
Philosophy,,Religion2020,1935,1
Philosophy,,Religion2020,1950,1
Philosophy,,Religion2020,1955,2
Philosophy,,Religion2020,1960,6
Philosophy,,Religion2020,1965,27
Philosophy,,Religion2020,1970,20
Philosophy,,Religion2020,1975,24
Philosophy,,Religion2020,1980,11
Philosophy,,Religion2020,1985,11
Philosophy,,Religion2020,1990,9
Philosophy,,Religion2020,1995,12
Philosophy,,Religion2020,2000,27
Philosophy,,Religion2020,2005,13
Philosophy,,Religion2020,2010,106
Philosophy,,Religion2020,2015,21
Philosophy,Adjunct Professor,Religion2020,1960,6
Philosophy,Adjunct Professor,Religion2020,1965,9
Philosophy,Adjunct Professor,Religion2020,1970,18
Philosophy,Adjunct Professor,Religion2020,1975,9
Philosophy,Adjunct Professor,Religion2020,1980,12
Philosophy,Adjunct Professor,Religion2020,1985,5
Philosophy,Adjunct Professor,Religion2020,1990,20
Philosophy,Adjunct Professor,Religion2020,1995,11
Philosophy,Adjunct Professor,Religion2020,2000,19
Philosophy,Adjunct Professor,Religion2020,2005,37
Philosophy,Adjunct Professor,Religion2020,2010,77
Philosophy,Adjunct Professor,Religion2020,2015,85
Philosophy,Assistant Professor,Religion2020,1910,4
Philosophy,Assistant Professor,Religion2020,1920,3
Philosophy,Assistant Professor,Religion2020,1925,2
Philosophy,Assistant Professor,Religion2020,1930,1
Philosophy,Assistant Professor,Religion2020,1960,5
Philosophy,Assistant Professor,Religion2020,1965,4
Philosophy,Assistant Professor,Religion2020,1970,14
Philosophy,Assistant Professor,Religion2020,1975,7
Philosophy,Assistant Professor,Religion2020,1980,10
Philosophy,Assistant Professor,Religion2020,1985,4
Philosophy,Assistant Professor,Religion2020,1990,6
Philosophy,Assistant Professor,Religion2020,1995,13
Philosophy,Assistant Professor,Religion2020,2000,3
Philosophy,Assistant Professor,Religion2020,2005,13
Philosophy,Assistant Professor,Religion2020,2010,65
Philosophy,Assistant Professor,Religion2020,2015,53
Philosophy,Associate Professor,Religion2020,1960,2
Philosophy,Associate Professor,Religion2020,1965,1
Philosophy,Associate Professor,Religion2020,1980,4
Philosophy,Associate Professor,Religion2020,1985,3
Philosophy,Associate Professor,Religion2020,1990,7
Philosophy,Associate Professor,Religion2020,1995,10
Philosophy,Associate Professor,Religion2020,2000,15
Philosophy,Associate Professor,Religion2020,2005,38
Philosophy,Associate Professor,Religion2020,2010,235
Philosophy,Associate Professor,Religion2020,2015,165
Philosophy,Graduate Student,Religion2020,1965,4
Philosophy,Graduate Student,Religion2020,1975,5
Philosophy,Graduate Student,Religion2020,1980,1
Philosophy,Graduate Student,Religion2020,1990,2
Philosophy,Graduate Student,Religion2020,1995,2
Philosophy,Graduate Student,Religion2020,2000,1
Philosophy,Graduate Student,Religion2020,2005,1
Philosophy,Graduate Student,Religion2020,2010,3
Philosophy,Graduate Student,Religion2020,2015,4
Philosophy,Non-Academic,Religion2020,1980,2
Philosophy,Non-Academic,Religion2020,1985,1
Philosophy,Non-Academic,Religion2020,1990,5
Philosophy,Non-Academic,Religion2020,2005,11
Philosophy,Non-Academic,Religion2020,2010,16
Philosophy,Non-Academic,Religion2020,2015,31
Philosophy,Postdoctoral Fellow,Religion2020,2010,13
Philosophy,Postdoctoral Fellow,Religion2020,2015,38
Philosophy,Professor,Religion2020,1880,8
Philosophy,Professor,Religion2020,1885,1
Philosophy,Professor,Religion2020,1890,14
Philosophy,Professor,Religion2020,1895,11
Philosophy,Professor,Religion2020,1900,11
Philosophy,Professor,Religion2020,1905,14
Philosophy,Professor,Religion2020,1910,19
Philosophy,Professor,Religion2020,1915,20
Philosophy,Professor,Religion2020,1920,21
Philosophy,Professor,Religion2020,1925,28
Philosophy,Professor,Religion2020,1930,29
Philosophy,Professor,Religion2020,1935,12
Philosophy,Professor,Religion2020,1940,32
Philosophy,Professor,Religion2020,1945,35
Philosophy,Professor,Religion2020,1950,49
Philosophy,Professor,Religion2020,1955,28
Philosophy,Professor,Religion2020,1960,36
Philosophy,Professor,Religion2020,1965,63
Philosophy,Professor,Religion2020,1970,91
Philosophy,Professor,Religion2020,1975,79
Philosophy,Professor,Religion2020,1980,92
Philosophy,Professor,Religion2020,1985,147
Philosophy,Professor,Religion2020,1990,161
Philosophy,Professor,Religion2020,1995,240
Philosophy,Professor,Religion2020,2000,309
Philosophy,Professor,Religion2020,2005,451
Philosophy,Professor,Religion2020,2010,855
Philosophy,Professor,Religion2020,2015,797
Philosophy,Professor Emeritus,Religion2020,1895,1
Philosophy,Professor Emeritus,Religion2020,1900,5
Philosophy,Professor Emeritus,Religion2020,1905,2
Philosophy,Professor Emeritus,Religion2020,1910,9
Philosophy,Professor Emeritus,Religion2020,1915,6
Philosophy,Professor Emeritus,Religion2020,1920,16
Philosophy,Professor Emeritus,Religion2020,1925,13
Philosophy,Professor Emeritus,Religion2020,1930,10
Philosophy,Professor Emeritus,Religion2020,1935,9
Philosophy,Professor Emeritus,Religion2020,1940,18
Philosophy,Professor Emeritus,Religion2020,1945,19
Philosophy,Professor Emeritus,Religion2020,1950,19
Philosophy,Professor Emeritus,Religion2020,1955,13
Philosophy,Professor Emeritus,Religion2020,1960,26
Philosophy,Professor Emeritus,Religion2020,1965,39
Philosophy,Professor Emeritus,Religion2020,1970,66
Philosophy,Professor Emeritus,Religion2020,1975,79
Philosophy,Professor Emeritus,Religion2020,1980,69
Philosophy,Professor Emeritus,Religion2020,1985,73
Philosophy,Professor Emeritus,Religion2020,1990,118
Philosophy,Professor Emeritus,Religion2020,1995,109
Philosophy,Professor Emeritus,Religion2020,2000,93
Philosophy,Professor Emeritus,Religion2020,2005,111
Philosophy,Professor Emeritus,Religion2020,2010,247
Philosophy,Professor Emeritus,Religion2020,2015,154
Philosophy,Researcher,Religion2020,1960,1
Philosophy,Researcher,Religion2020,1965,2
Philosophy,Researcher,Religion2020,1970,3
Philosophy,Researcher,Religion2020,1975,2
Philosophy,Researcher,Religion2020,1980,5
Philosophy,Researcher,Religion2020,1985,5
Philosophy,Researcher,Religion2020,1990,16
Philosophy,Researcher,Religion2020,1995,24
Philosophy,Researcher,Religion2020,2000,20
Philosophy,Researcher,Religion2020,2005,33
Philosophy,Researcher,Religion2020,2010,120
Philosophy,Researcher,Religion2020,2015,119
Philosophy and Religion,Adjunct Professor,Religion2020,2010,16
Philosophy and Religion,Assistant Professor,Religion2020,2005,6
Philosophy and Religion,Assistant Professor,Religion2020,2010,9
Philosophy and Religion,Assistant Professor,Religion2020,2015,18
Philosophy and Religion,Associate Professor,Religion2020,1985,1
Philosophy and Religion,Associate Professor,Religion2020,2005,10
Philosophy and Religion,Associate Professor,Religion2020,2010,26
Philosophy and Religion,Associate Professor,Religion2020,2015,43
Philosophy and Religion,Graduate Student,Religion2020,2010,8
Philosophy and Religion,Graduate Student,Religion2020,2015,7
Philosophy and Religion,Non-Academic,Religion2020,1970,1
Philosophy and Religion,Non-Academic,Religion2020,1975,1
Philosophy and Religion,Non-Academic,Religion2020,2000,5
Philosophy and Religion,Non-Academic,Religion2020,2005,1
Philosophy and Religion,Non-Academic,Religion2020,2010,3
Philosophy and Religion,Professor,Religion2020,1980,3
Philosophy and Religion,Professor,Religion2020,1985,7
Philosophy and Religion,Professor,Religion2020,1990,12
Philosophy and Religion,Professor,Religion2020,1995,17
Philosophy and Religion,Professor,Religion2020,2000,24
Philosophy and Religion,Professor,Religion2020,2005,50
Philosophy and Religion,Professor,Religion2020,2010,102
Philosophy and Religion,Professor,Religion2020,2015,76
Philosophy and Religion,Professor Emeritus,Religion2020,1960,1
Philosophy and Religion,Professor Emeritus,Religion2020,1965,5
Philosophy and Religion,Professor Emeritus,Religion2020,1970,5
Philosophy and Religion,Professor Emeritus,Religion2020,1975,2
Philosophy and Religion,Professor Emeritus,Religion2020,1980,4
Philosophy and Religion,Professor Emeritus,Religion2020,1985,2
Philosophy and Religion,Professor Emeritus,Religion2020,1990,3
Philosophy and Religion,Professor Emeritus,Religion2020,2000,2
Philosophy and Religion,Professor Emeritus,Religion2020,2005,1
Philosophy and Religion,Professor Emeritus,Religion2020,2010,4
Philosophy and Religion,Professor Emeritus,Religion2020,2015,3
Physics,Assistant Professor,Religion2020,1980,1
Physics,Assistant Professor,Religion2020,1985,3
Physics,Assistant Professor,Religion2020,1990,4
Physics,Assistant Professor,Religion2020,1995,4
Physics,Assistant Professor,Religion2020,2000,10
Physics,Assistant Professor,Religion2020,2005,13
Physics,Assistant Professor,Religion2020,2010,16
Physics,Assistant Professor,Religion2020,2015,9
Physics,Non-Academic,Religion2020,2010,6
Physics,Non-Academic,Religion2020,2015,3
Physics,Professor,Religion2020,1975,5
Physics,Professor,Religion2020,1980,5
Physics,Professor,Religion2020,2000,1
Physics,Professor,Religion2020,2005,3
Physics,Professor,Religion2020,2010,6
Physics,Professor,Religion2020,2015,7
Physics,Professor Emeritus,Religion2020,2000,5
Physics,Professor Emeritus,Religion2020,2005,3
Physics,Professor Emeritus,Religion2020,2010,3
Physics,Professor Emeritus,Religion2020,2015,1
Physics,Researcher,Religion2020,2010,1
Physics,Researcher,Religion2020,2015,5
Political Science,,Religion2020,1980,1
Political Science,,Religion2020,1995,1
Political Science,,Religion2020,2005,9
Political Science,,Religion2020,2010,20
Political Science,,Religion2020,2015,10
Political Science,Adjunct Professor,Religion2020,1980,7
Political Science,Adjunct Professor,Religion2020,1985,1
Political Science,Adjunct Professor,Religion2020,1990,3
Political Science,Adjunct Professor,Religion2020,1995,6
Political Science,Adjunct Professor,Religion2020,2005,8
Political Science,Adjunct Professor,Religion2020,2010,26
Political Science,Adjunct Professor,Religion2020,2015,44
Political Science,Assistant Professor,Religion2020,1970,2
Political Science,Assistant Professor,Religion2020,1980,3
Political Science,Assistant Professor,Religion2020,1985,1
Political Science,Assistant Professor,Religion2020,2000,2
Political Science,Assistant Professor,Religion2020,2005,7
Political Science,Assistant Professor,Religion2020,2010,50
Political Science,Assistant Professor,Religion2020,2015,68
Political Science,Associate Professor,Religion2020,1990,1
Political Science,Associate Professor,Religion2020,1995,3
Political Science,Associate Professor,Religion2020,2000,11
Political Science,Associate Professor,Religion2020,2005,39
Political Science,Associate Professor,Religion2020,2010,140
Political Science,Associate Professor,Religion2020,2015,233
Political Science,Graduate Student,Religion2020,1995,1
Political Science,Graduate Student,Religion2020,2000,2
Political Science,Graduate Student,Religion2020,2005,4
Political Science,Graduate Student,Religion2020,2010,13
Political Science,Graduate Student,Religion2020,2015,22
Political Science,Non-Academic,Religion2020,1965,2
Political Science,Non-Academic,Religion2020,1975,2
Political Science,Non-Academic,Religion2020,1990,2
Political Science,Non-Academic,Religion2020,2000,2
Political Science,Non-Academic,Religion2020,2005,2
Political Science,Non-Academic,Religion2020,2010,1
Political Science,Non-Academic,Religion2020,2015,2
Political Science,Postdoctoral Fellow,Religion2020,2005,4
Political Science,Postdoctoral Fellow,Religion2020,2010,4
Political Science,Professor,Religion2020,1930,1
Political Science,Professor,Religion2020,1935,6
Political Science,Professor,Religion2020,1940,2
Political Science,Professor,Religion2020,1945,1
Political Science,Professor,Religion2020,1960,1
Political Science,Professor,Religion2020,1965,4
Political Science,Professor,Religion2020,1970,5
Political Science,Professor,Religion2020,1975,10
Political Science,Professor,Religion2020,1980,34
Political Science,Professor,Religion2020,1985,39
Political Science,Professor,Religion2020,1990,82
Political Science,Professor,Religion2020,1995,101
Political Science,Professor,Religion2020,2000,147
Political Science,Professor,Religion2020,2005,240
Political Science,Professor,Religion2020,2010,406
Political Science,Professor,Religion2020,2015,398
Political Science,Professor Emeritus,Religion2020,1895,2
Political Science,Professor Emeritus,Religion2020,1900,8
Political Science,Professor Emeritus,Religion2020,1930,1
Political Science,Professor Emeritus,Religion2020,1940,2
Political Science,Professor Emeritus,Religion2020,1945,3
Political Science,Professor Emeritus,Religion2020,1950,3
Political Science,Professor Emeritus,Religion2020,1955,5
Political Science,Professor Emeritus,Religion2020,1960,5
Political Science,Professor Emeritus,Religion2020,1965,11
Political Science,Professor Emeritus,Religion2020,1970,7
Political Science,Professor Emeritus,Religion2020,1975,19
Political Science,Professor Emeritus,Religion2020,1980,32
Political Science,Professor Emeritus,Religion2020,1985,35
Political Science,Professor Emeritus,Religion2020,1990,49
Political Science,Professor Emeritus,Religion2020,1995,39
Political Science,Professor Emeritus,Religion2020,2000,45
Political Science,Professor Emeritus,Religion2020,2005,40
Political Science,Professor Emeritus,Religion2020,2010,59
Political Science,Professor Emeritus,Religion2020,2015,52
Political Science,Researcher,Religion2020,1980,1
Political Science,Researcher,Religion2020,1985,9
Political Science,Researcher,Religion2020,1990,7
Political Science,Researcher,Religion2020,1995,3
Political Science,Researcher,Religion2020,2000,4
Political Science,Researcher,Religion2020,2005,14
Political Science,Researcher,Religion2020,2010,33
Political Science,Researcher,Religion2020,2015,22
Political Science,Undergraduate Student,Religion2020,2005,1
Political Science,Undergraduate Student,Religion2020,2010,6
Political Science,Undergraduate Student,Religion2020,2015,9
Psychiatry,,Religion2020,1975,4
Psychiatry,,Religion2020,1980,8
Psychiatry,,Religion2020,1985,4
Psychiatry,,Religion2020,1990,2
Psychiatry,,Religion2020,1995,4
Psychiatry,,Religion2020,2000,13
Psychiatry,,Religion2020,2005,33
Psychiatry,,Religion2020,2010,59
Psychiatry,,Religion2020,2015,47
Psychiatry,Adjunct Professor,Religion2020,1980,6
Psychiatry,Adjunct Professor,Religion2020,1985,8
Psychiatry,Adjunct Professor,Religion2020,1990,5
Psychiatry,Adjunct Professor,Religion2020,1995,10
Psychiatry,Adjunct Professor,Religion2020,2000,16
Psychiatry,Adjunct Professor,Religion2020,2005,28
Psychiatry,Adjunct Professor,Religion2020,2010,37
Psychiatry,Adjunct Professor,Religion2020,2015,42
Psychiatry,Assistant Professor,Religion2020,1965,1
Psychiatry,Assistant Professor,Religion2020,1970,1
Psychiatry,Assistant Professor,Religion2020,1975,6
Psychiatry,Assistant Professor,Religion2020,1980,1
Psychiatry,Assistant Professor,Religion2020,1985,3
Psychiatry,Assistant Professor,Religion2020,1990,3
Psychiatry,Assistant Professor,Religion2020,1995,3
Psychiatry,Assistant Professor,Religion2020,2000,12
Psychiatry,Assistant Professor,Religion2020,2005,75
Psychiatry,Assistant Professor,Religion2020,2010,176
Psychiatry,Assistant Professor,Religion2020,2015,185
Psychiatry,Associate Professor,Religion2020,1905,1
Psychiatry,Associate Professor,Religion2020,1910,1
Psychiatry,Associate Professor,Religion2020,1925,1
Psychiatry,Associate Professor,Religion2020,1930,1
Psychiatry,Associate Professor,Religion2020,1950,1
Psychiatry,Associate Professor,Religion2020,1965,1
Psychiatry,Associate Professor,Religion2020,1975,3
Psychiatry,Associate Professor,Religion2020,1980,6
Psychiatry,Associate Professor,Religion2020,1985,1
Psychiatry,Associate Professor,Religion2020,1990,8
Psychiatry,Associate Professor,Religion2020,1995,22
Psychiatry,Associate Professor,Religion2020,2000,26
Psychiatry,Associate Professor,Religion2020,2005,84
Psychiatry,Associate Professor,Religion2020,2010,198
Psychiatry,Associate Professor,Religion2020,2015,160
Psychiatry,Graduate Student,Religion2020,1990,4
Psychiatry,Graduate Student,Religion2020,1995,3
Psychiatry,Graduate Student,Religion2020,2015,6
Psychiatry,Non-Academic,Religion2020,1960,2
Psychiatry,Non-Academic,Religion2020,1965,1
Psychiatry,Non-Academic,Religion2020,1970,2
Psychiatry,Non-Academic,Religion2020,1975,1
Psychiatry,Non-Academic,Religion2020,1980,3
Psychiatry,Non-Academic,Religion2020,1985,12
Psychiatry,Non-Academic,Religion2020,1990,8
Psychiatry,Non-Academic,Religion2020,1995,11
Psychiatry,Non-Academic,Religion2020,2000,18
Psychiatry,Non-Academic,Religion2020,2005,81
Psychiatry,Non-Academic,Religion2020,2010,107
Psychiatry,Non-Academic,Religion2020,2015,81
Psychiatry,Postdoctoral Fellow,Religion2020,2005,4
Psychiatry,Postdoctoral Fellow,Religion2020,2010,12
Psychiatry,Postdoctoral Fellow,Religion2020,2015,25
Psychiatry,Professor,Religion2020,1940,1
Psychiatry,Professor,Religion2020,1965,9
Psychiatry,Professor,Religion2020,1970,7
Psychiatry,Professor,Religion2020,1975,20
Psychiatry,Professor,Religion2020,1980,34
Psychiatry,Professor,Religion2020,1985,49
Psychiatry,Professor,Religion2020,1990,53
Psychiatry,Professor,Religion2020,1995,121
Psychiatry,Professor,Religion2020,2000,215
Psychiatry,Professor,Religion2020,2005,346
Psychiatry,Professor,Religion2020,2010,447
Psychiatry,Professor,Religion2020,2015,535
Psychiatry,Professor Emeritus,Religion2020,1960,1
Psychiatry,Professor Emeritus,Religion2020,1965,2
Psychiatry,Professor Emeritus,Religion2020,1970,8
Psychiatry,Professor Emeritus,Religion2020,1975,20
Psychiatry,Professor Emeritus,Religion2020,1980,21
Psychiatry,Professor Emeritus,Religion2020,1985,18
Psychiatry,Professor Emeritus,Religion2020,1990,17
Psychiatry,Professor Emeritus,Religion2020,1995,31
Psychiatry,Professor Emeritus,Religion2020,2000,29
Psychiatry,Professor Emeritus,Religion2020,2005,56
Psychiatry,Professor Emeritus,Religion2020,2010,56
Psychiatry,Professor Emeritus,Religion2020,2015,49
Psychiatry,Researcher,Religion2020,1985,7
Psychiatry,Researcher,Religion2020,1990,4
Psychiatry,Researcher,Religion2020,1995,12
Psychiatry,Researcher,Religion2020,2000,18
Psychiatry,Researcher,Religion2020,2005,39
Psychiatry,Researcher,Religion2020,2010,84
Psychiatry,Researcher,Religion2020,2015,89
Psychology,,Religion2020,1945,2
Psychology,,Religion2020,1950,3
Psychology,,Religion2020,1955,2
Psychology,,Religion2020,1960,2
Psychology,,Religion2020,1965,5
Psychology,,Religion2020,1970,4
Psychology,,Religion2020,1975,18
Psychology,,Religion2020,1980,18
Psychology,,Religion2020,1985,16
Psychology,,Religion2020,1990,22
Psychology,,Religion2020,1995,42
Psychology,,Religion2020,2000,56
Psychology,,Religion2020,2005,96
Psychology,,Religion2020,2010,188
Psychology,,Religion2020,2015,125
Psychology,Adjunct Professor,Religion2020,1905,1
Psychology,Adjunct Professor,Religion2020,1910,4
Psychology,Adjunct Professor,Religion2020,1915,2
Psychology,Adjunct Professor,Religion2020,1920,1
Psychology,Adjunct Professor,Religion2020,1945,5
Psychology,Adjunct Professor,Religion2020,1950,1
Psychology,Adjunct Professor,Religion2020,1955,8
Psychology,Adjunct Professor,Religion2020,1960,5
Psychology,Adjunct Professor,Religion2020,1965,5
Psychology,Adjunct Professor,Religion2020,1970,11
Psychology,Adjunct Professor,Religion2020,1975,10
Psychology,Adjunct Professor,Religion2020,1980,13
Psychology,Adjunct Professor,Religion2020,1985,16
Psychology,Adjunct Professor,Religion2020,1990,26
Psychology,Adjunct Professor,Religion2020,1995,26
Psychology,Adjunct Professor,Religion2020,2000,61
Psychology,Adjunct Professor,Religion2020,2005,107
Psychology,Adjunct Professor,Religion2020,2010,219
Psychology,Adjunct Professor,Religion2020,2015,235
Psychology,Assistant Professor,Religion2020,1980,6
Psychology,Assistant Professor,Religion2020,1985,13
Psychology,Assistant Professor,Religion2020,1990,12
Psychology,Assistant Professor,Religion2020,1995,15
Psychology,Assistant Professor,Religion2020,2000,27
Psychology,Assistant Professor,Religion2020,2005,61
Psychology,Assistant Professor,Religion2020,2010,295
Psychology,Assistant Professor,Religion2020,2015,452
Psychology,Associate Professor,Religion2020,1960,6
Psychology,Associate Professor,Religion2020,1965,12
Psychology,Associate Professor,Religion2020,1975,3
Psychology,Associate Professor,Religion2020,1980,4
Psychology,Associate Professor,Religion2020,1985,9
Psychology,Associate Professor,Religion2020,1990,8
Psychology,Associate Professor,Religion2020,1995,31
Psychology,Associate Professor,Religion2020,2000,82
Psychology,Associate Professor,Religion2020,2005,227
Psychology,Associate Professor,Religion2020,2010,640
Psychology,Associate Professor,Religion2020,2015,774
Psychology,Graduate Student,Religion2020,1990,3
Psychology,Graduate Student,Religion2020,2000,1
Psychology,Graduate Student,Religion2020,2005,8
Psychology,Graduate Student,Religion2020,2010,27
Psychology,Graduate Student,Religion2020,2015,46
Psychology,Non-Academic,Religion2020,1955,1
Psychology,Non-Academic,Religion2020,1960,4
Psychology,Non-Academic,Religion2020,1965,8
Psychology,Non-Academic,Religion2020,1970,2
Psychology,Non-Academic,Religion2020,1975,10
Psychology,Non-Academic,Religion2020,1980,20
Psychology,Non-Academic,Religion2020,1985,28
Psychology,Non-Academic,Religion2020,1990,23
Psychology,Non-Academic,Religion2020,1995,53
Psychology,Non-Academic,Religion2020,2000,83
Psychology,Non-Academic,Religion2020,2005,174
Psychology,Non-Academic,Religion2020,2010,301
Psychology,Non-Academic,Religion2020,2015,294
Psychology,Postdoctoral Fellow,Religion2020,1960,3
Psychology,Postdoctoral Fellow,Religion2020,1965,2
Psychology,Postdoctoral Fellow,Religion2020,1970,1
Psychology,Postdoctoral Fellow,Religion2020,1975,1
Psychology,Postdoctoral Fellow,Religion2020,1980,2
Psychology,Postdoctoral Fellow,Religion2020,1985,3
Psychology,Postdoctoral Fellow,Religion2020,1990,1
Psychology,Postdoctoral Fellow,Religion2020,1995,1
Psychology,Postdoctoral Fellow,Religion2020,2000,2
Psychology,Postdoctoral Fellow,Religion2020,2005,9
Psychology,Postdoctoral Fellow,Religion2020,2010,64
Psychology,Postdoctoral Fellow,Religion2020,2015,120
Psychology,Professor,Religion2020,1895,4
Psychology,Professor,Religion2020,1900,11
Psychology,Professor,Religion2020,1905,13
Psychology,Professor,Religion2020,1910,12
Psychology,Professor,Religion2020,1915,13
Psychology,Professor,Religion2020,1920,4
Psychology,Professor,Religion2020,1925,14
Psychology,Professor,Religion2020,1930,6
Psychology,Professor,Religion2020,1935,1
Psychology,Professor,Religion2020,1940,5
Psychology,Professor,Religion2020,1945,8
Psychology,Professor,Religion2020,1950,12
Psychology,Professor,Religion2020,1955,19
Psychology,Professor,Religion2020,1960,20
Psychology,Professor,Religion2020,1965,16
Psychology,Professor,Religion2020,1970,31
Psychology,Professor,Religion2020,1975,66
Psychology,Professor,Religion2020,1980,109
Psychology,Professor,Religion2020,1985,153
Psychology,Professor,Religion2020,1990,182
Psychology,Professor,Religion2020,1995,319
Psychology,Professor,Religion2020,2000,631
Psychology,Professor,Religion2020,2005,1037
Psychology,Professor,Religion2020,2010,1728
Psychology,Professor,Religion2020,2015,1844
Psychology,Professor Emeritus,Religion2020,1910,3
Psychology,Professor Emeritus,Religion2020,1915,1
Psychology,Professor Emeritus,Religion2020,1920,2
Psychology,Professor Emeritus,Religion2020,1925,5
Psychology,Professor Emeritus,Religion2020,1930,4
Psychology,Professor Emeritus,Religion2020,1940,1
Psychology,Professor Emeritus,Religion2020,1945,6
Psychology,Professor Emeritus,Religion2020,1950,4
Psychology,Professor Emeritus,Religion2020,1955,9
Psychology,Professor Emeritus,Religion2020,1960,23
Psychology,Professor Emeritus,Religion2020,1965,30
Psychology,Professor Emeritus,Religion2020,1970,48
Psychology,Professor Emeritus,Religion2020,1975,70
Psychology,Professor Emeritus,Religion2020,1980,80
Psychology,Professor Emeritus,Religion2020,1985,102
Psychology,Professor Emeritus,Religion2020,1990,142
Psychology,Professor Emeritus,Religion2020,1995,188
Psychology,Professor Emeritus,Religion2020,2000,188
Psychology,Professor Emeritus,Religion2020,2005,284
Psychology,Professor Emeritus,Religion2020,2010,379
Psychology,Professor Emeritus,Religion2020,2015,210
Psychology,Researcher,Religion2020,1935,1
Psychology,Researcher,Religion2020,1960,2
Psychology,Researcher,Religion2020,1965,1
Psychology,Researcher,Religion2020,1970,2
Psychology,Researcher,Religion2020,1975,3
Psychology,Researcher,Religion2020,1980,6
Psychology,Researcher,Religion2020,1985,9
Psychology,Researcher,Religion2020,1990,14
Psychology,Researcher,Religion2020,1995,32
Psychology,Researcher,Religion2020,2000,75
Psychology,Researcher,Religion2020,2005,139
Psychology,Researcher,Religion2020,2010,284
Psychology,Researcher,Religion2020,2015,344
Public Health,,Religion2020,1985,1
Public Health,,Religion2020,1990,3
Public Health,,Religion2020,1995,2
Public Health,,Religion2020,2000,1
Public Health,,Religion2020,2005,1
Public Health,,Religion2020,2010,4
Public Health,,Religion2020,2015,11
Public Health,Adjunct Professor,Religion2020,1980,1
Public Health,Adjunct Professor,Religion2020,1995,3
Public Health,Adjunct Professor,Religion2020,2000,12
Public Health,Adjunct Professor,Religion2020,2005,22
Public Health,Adjunct Professor,Religion2020,2010,18
Public Health,Adjunct Professor,Religion2020,2015,33
Public Health,Assistant Professor,Religion2020,2005,1
Public Health,Assistant Professor,Religion2020,2010,16
Public Health,Assistant Professor,Religion2020,2015,23
Public Health,Associate Professor,Religion2020,1980,1
Public Health,Associate Professor,Religion2020,1995,2
Public Health,Associate Professor,Religion2020,2000,7
Public Health,Associate Professor,Religion2020,2005,14
Public Health,Associate Professor,Religion2020,2010,45
Public Health,Associate Professor,Religion2020,2015,72
Public Health,Graduate Student,Religion2020,1970,1
Public Health,Graduate Student,Religion2020,1985,1
Public Health,Graduate Student,Religion2020,2005,2
Public Health,Graduate Student,Religion2020,2010,4
Public Health,Graduate Student,Religion2020,2015,4
Public Health,Non-Academic,Religion2020,2005,4
Public Health,Non-Academic,Religion2020,2010,5
Public Health,Non-Academic,Religion2020,2015,14
Public Health,Postdoctoral Fellow,Religion2020,2010,1
Public Health,Postdoctoral Fellow,Religion2020,2015,6
Public Health,Professor,Religion2020,1960,3
Public Health,Professor,Religion2020,1965,3
Public Health,Professor,Religion2020,1970,2
Public Health,Professor,Religion2020,1975,2
Public Health,Professor,Religion2020,1980,5
Public Health,Professor,Religion2020,1985,14
Public Health,Professor,Religion2020,1990,17
Public Health,Professor,Religion2020,1995,9
Public Health,Professor,Religion2020,2000,30
Public Health,Professor,Religion2020,2005,50
Public Health,Professor,Religion2020,2010,120
Public Health,Professor,Religion2020,2015,127
Public Health,Professor Emeritus,Religion2020,1965,3
Public Health,Professor Emeritus,Religion2020,1970,4
Public Health,Professor Emeritus,Religion2020,1975,3
Public Health,Professor Emeritus,Religion2020,1980,2
Public Health,Professor Emeritus,Religion2020,1985,6
Public Health,Professor Emeritus,Religion2020,1990,9
Public Health,Professor Emeritus,Religion2020,1995,4
Public Health,Professor Emeritus,Religion2020,2000,6
Public Health,Professor Emeritus,Religion2020,2005,16
Public Health,Professor Emeritus,Religion2020,2010,24
Public Health,Professor Emeritus,Religion2020,2015,9
Public Health,Researcher,Religion2020,1975,1
Public Health,Researcher,Religion2020,1980,5
Public Health,Researcher,Religion2020,1995,1
Public Health,Researcher,Religion2020,2000,3
Public Health,Researcher,Religion2020,2005,7
Public Health,Researcher,Religion2020,2010,23
Public Health,Researcher,Religion2020,2015,32
Religion,,Religion2020,1930,2
Religion,,Religion2020,1940,1
Religion,,Religion2020,1945,5
Religion,,Religion2020,1950,2
Religion,,Religion2020,1955,1
Religion,,Religion2020,1960,2
Religion,,Religion2020,1965,10
Religion,,Religion2020,1970,8
Religion,,Religion2020,1975,10
Religion,,Religion2020,1980,15
Religion,,Religion2020,1985,22
Religion,,Religion2020,1990,16
Religion,,Religion2020,1995,28
Religion,,Religion2020,2000,71
Religion,,Religion2020,2005,71
Religion,,Religion2020,2010,84
Religion,,Religion2020,2015,120
Religion,Adjunct Professor,Religion2020,1935,1
Religion,Adjunct Professor,Religion2020,1940,2
Religion,Adjunct Professor,Religion2020,1950,2
Religion,Adjunct Professor,Religion2020,1955,13
Religion,Adjunct Professor,Religion2020,1960,8
Religion,Adjunct Professor,Religion2020,1965,8
Religion,Adjunct Professor,Religion2020,1970,4
Religion,Adjunct Professor,Religion2020,1975,6
Religion,Adjunct Professor,Religion2020,1980,18
Religion,Adjunct Professor,Religion2020,1985,20
Religion,Adjunct Professor,Religion2020,1990,27
Religion,Adjunct Professor,Religion2020,1995,19
Religion,Adjunct Professor,Religion2020,2000,31
Religion,Adjunct Professor,Religion2020,2005,66
Religion,Adjunct Professor,Religion2020,2010,137
Religion,Adjunct Professor,Religion2020,2015,176
Religion,Assistant Professor,Religion2020,1960,2
Religion,Assistant Professor,Religion2020,1970,4
Religion,Assistant Professor,Religion2020,1975,4
Religion,Assistant Professor,Religion2020,1980,11
Religion,Assistant Professor,Religion2020,1985,10
Religion,Assistant Professor,Religion2020,1990,14
Religion,Assistant Professor,Religion2020,1995,18
Religion,Assistant Professor,Religion2020,2000,23
Religion,Assistant Professor,Religion2020,2005,44
Religion,Assistant Professor,Religion2020,2010,184
Religion,Assistant Professor,Religion2020,2015,237
Religion,Associate Professor,Religion2020,1935,1
Religion,Associate Professor,Religion2020,1945,2
Religion,Associate Professor,Religion2020,1950,4
Religion,Associate Professor,Religion2020,1955,10
Religion,Associate Professor,Religion2020,1960,6
Religion,Associate Professor,Religion2020,1970,3
Religion,Associate Professor,Religion2020,1975,1
Religion,Associate Professor,Religion2020,1980,15
Religion,Associate Professor,Religion2020,1985,15
Religion,Associate Professor,Religion2020,1990,44
Religion,Associate Professor,Religion2020,1995,64
Religion,Associate Professor,Religion2020,2000,84
Religion,Associate Professor,Religion2020,2005,193
Religion,Associate Professor,Religion2020,2010,367
Religion,Associate Professor,Religion2020,2015,465
Religion,Graduate Student,Religion2020,1970,1
Religion,Graduate Student,Religion2020,1975,6
Religion,Graduate Student,Religion2020,1980,8
Religion,Graduate Student,Religion2020,1985,1
Religion,Graduate Student,Religion2020,1990,4
Religion,Graduate Student,Religion2020,1995,3
Religion,Graduate Student,Religion2020,2000,1
Religion,Graduate Student,Religion2020,2005,6
Religion,Graduate Student,Religion2020,2010,21
Religion,Graduate Student,Religion2020,2015,26
Religion,Non-Academic,Religion2020,1920,1
Religion,Non-Academic,Religion2020,1935,1
Religion,Non-Academic,Religion2020,1940,1
Religion,Non-Academic,Religion2020,1945,6
Religion,Non-Academic,Religion2020,1950,3
Religion,Non-Academic,Religion2020,1960,5
Religion,Non-Academic,Religion2020,1965,10
Religion,Non-Academic,Religion2020,1970,7
Religion,Non-Academic,Religion2020,1975,13
Religion,Non-Academic,Religion2020,1980,19
Religion,Non-Academic,Religion2020,1985,17
Religion,Non-Academic,Religion2020,1990,24
Religion,Non-Academic,Religion2020,1995,17
Religion,Non-Academic,Religion2020,2000,46
Religion,Non-Academic,Religion2020,2005,118
Religion,Non-Academic,Religion2020,2010,161
Religion,Non-Academic,Religion2020,2015,159
Religion,Postdoctoral Fellow,Religion2020,2000,3
Religion,Postdoctoral Fellow,Religion2020,2005,1
Religion,Postdoctoral Fellow,Religion2020,2010,29
Religion,Postdoctoral Fellow,Religion2020,2015,52
Religion,Professor,Religion2020,1890,2
Religion,Professor,Religion2020,1895,1
Religion,Professor,Religion2020,1900,15
Religion,Professor,Religion2020,1905,14
Religion,Professor,Religion2020,1910,25
Religion,Professor,Religion2020,1915,21
Religion,Professor,Religion2020,1920,14
Religion,Professor,Religion2020,1925,27
Religion,Professor,Religion2020,1930,38
Religion,Professor,Religion2020,1935,23
Religion,Professor,Religion2020,1940,33
Religion,Professor,Religion2020,1945,31
Religion,Professor,Religion2020,1950,36
Religion,Professor,Religion2020,1955,40
Religion,Professor,Religion2020,1960,72
Religion,Professor,Religion2020,1965,103
Religion,Professor,Religion2020,1970,148
Religion,Professor,Religion2020,1975,136
Religion,Professor,Religion2020,1980,201
Religion,Professor,Religion2020,1985,243
Religion,Professor,Religion2020,1990,351
Religion,Professor,Religion2020,1995,535
Religion,Professor,Religion2020,2000,619
Religion,Professor,Religion2020,2005,978
Religion,Professor,Religion2020,2010,1774
Religion,Professor,Religion2020,2015,1766
Religion,Professor Emeritus,Religion2020,1900,1
Religion,Professor Emeritus,Religion2020,1905,1
Religion,Professor Emeritus,Religion2020,1910,6
Religion,Professor Emeritus,Religion2020,1915,1
Religion,Professor Emeritus,Religion2020,1920,3
Religion,Professor Emeritus,Religion2020,1925,5
Religion,Professor Emeritus,Religion2020,1930,3
Religion,Professor Emeritus,Religion2020,1935,3
Religion,Professor Emeritus,Religion2020,1940,1
Religion,Professor Emeritus,Religion2020,1945,4
Religion,Professor Emeritus,Religion2020,1950,6
Religion,Professor Emeritus,Religion2020,1955,28
Religion,Professor Emeritus,Religion2020,1960,42
Religion,Professor Emeritus,Religion2020,1965,90
Religion,Professor Emeritus,Religion2020,1970,113
Religion,Professor Emeritus,Religion2020,1975,214
Religion,Professor Emeritus,Religion2020,1980,213
Religion,Professor Emeritus,Religion2020,1985,243
Religion,Professor Emeritus,Religion2020,1990,268
Religion,Professor Emeritus,Religion2020,1995,243
Religion,Professor Emeritus,Religion2020,2000,284
Religion,Professor Emeritus,Religion2020,2005,273
Religion,Professor Emeritus,Religion2020,2010,304
Religion,Professor Emeritus,Religion2020,2015,290
Religion,Researcher,Religion2020,1890,1
Religion,Researcher,Religion2020,1910,1
Religion,Researcher,Religion2020,1960,1
Religion,Researcher,Religion2020,1965,2
Religion,Researcher,Religion2020,1970,9
Religion,Researcher,Religion2020,1975,36
Religion,Researcher,Religion2020,1980,28
Religion,Researcher,Religion2020,1985,20
Religion,Researcher,Religion2020,1990,36
Religion,Researcher,Religion2020,1995,50
Religion,Researcher,Religion2020,2000,84
Religion,Researcher,Religion2020,2005,139
Religion,Researcher,Religion2020,2010,315
Religion,Researcher,Religion2020,2015,362
Religion,Undergraduate Student,Religion2020,2010,8
Religion,Undergraduate Student,Religion2020,2015,7
Religious Studies,,Religion2020,1960,2
Religious Studies,,Religion2020,1965,2
Religious Studies,,Religion2020,1970,4
Religious Studies,,Religion2020,1975,9
Religious Studies,,Religion2020,1980,13
Religious Studies,,Religion2020,1985,11
Religious Studies,,Religion2020,1990,4
Religious Studies,,Religion2020,1995,2
Religious Studies,,Religion2020,2000,4
Religious Studies,,Religion2020,2005,27
Religious Studies,,Religion2020,2010,34
Religious Studies,,Religion2020,2015,23
Religious Studies,Adjunct Professor,Religion2020,1970,1
Religious Studies,Adjunct Professor,Religion2020,1975,6
Religious Studies,Adjunct Professor,Religion2020,1980,5
Religious Studies,Adjunct Professor,Religion2020,1985,8
Religious Studies,Adjunct Professor,Religion2020,1990,7
Religious Studies,Adjunct Professor,Religion2020,1995,7
Religious Studies,Adjunct Professor,Religion2020,2000,12
Religious Studies,Adjunct Professor,Religion2020,2005,19
Religious Studies,Adjunct Professor,Religion2020,2010,46
Religious Studies,Adjunct Professor,Religion2020,2015,54
Religious Studies,Assistant Professor,Religion2020,1970,1
Religious Studies,Assistant Professor,Religion2020,1985,4
Religious Studies,Assistant Professor,Religion2020,1990,3
Religious Studies,Assistant Professor,Religion2020,1995,4
Religious Studies,Assistant Professor,Religion2020,2000,2
Religious Studies,Assistant Professor,Religion2020,2005,9
Religious Studies,Assistant Professor,Religion2020,2010,72
Religious Studies,Assistant Professor,Religion2020,2015,23
Religious Studies,Associate Professor,Religion2020,1970,1
Religious Studies,Associate Professor,Religion2020,1980,1
Religious Studies,Associate Professor,Religion2020,1985,2
Religious Studies,Associate Professor,Religion2020,1990,11
Religious Studies,Associate Professor,Religion2020,1995,16
Religious Studies,Associate Professor,Religion2020,2000,14
Religious Studies,Associate Professor,Religion2020,2005,45
Religious Studies,Associate Professor,Religion2020,2010,105
Religious Studies,Associate Professor,Religion2020,2015,93
Religious Studies,Non-Academic,Religion2020,1980,1
Religious Studies,Non-Academic,Religion2020,1985,2
Religious Studies,Non-Academic,Religion2020,1995,2
Religious Studies,Non-Academic,Religion2020,2000,1
Religious Studies,Non-Academic,Religion2020,2005,4
Religious Studies,Non-Academic,Religion2020,2010,2
Religious Studies,Non-Academic,Religion2020,2015,3
Religious Studies,Postdoctoral Fellow,Religion2020,2000,3
Religious Studies,Postdoctoral Fellow,Religion2020,2010,2
Religious Studies,Postdoctoral Fellow,Religion2020,2015,2
Religious Studies,Professor,Religion2020,1940,1
Religious Studies,Professor,Religion2020,1945,4
Religious Studies,Professor,Religion2020,1950,7
Religious Studies,Professor,Religion2020,1955,10
Religious Studies,Professor,Religion2020,1960,12
Religious Studies,Professor,Religion2020,1965,21
Religious Studies,Professor,Religion2020,1970,22
Religious Studies,Professor,Religion2020,1975,41
Religious Studies,Professor,Religion2020,1980,56
Religious Studies,Professor,Religion2020,1985,69
Religious Studies,Professor,Religion2020,1990,70
Religious Studies,Professor,Religion2020,1995,95
Religious Studies,Professor,Religion2020,2000,158
Religious Studies,Professor,Religion2020,2005,229
Religious Studies,Professor,Religion2020,2010,411
Religious Studies,Professor,Religion2020,2015,267
Religious Studies,Professor Emeritus,Religion2020,1935,1
Religious Studies,Professor Emeritus,Religion2020,1955,4
Religious Studies,Professor Emeritus,Religion2020,1960,13
Religious Studies,Professor Emeritus,Religion2020,1965,26
Religious Studies,Professor Emeritus,Religion2020,1970,42
Religious Studies,Professor Emeritus,Religion2020,1975,47
Religious Studies,Professor Emeritus,Religion2020,1980,46
Religious Studies,Professor Emeritus,Religion2020,1985,37
Religious Studies,Professor Emeritus,Religion2020,1990,61
Religious Studies,Professor Emeritus,Religion2020,1995,73
Religious Studies,Professor Emeritus,Religion2020,2000,49
Religious Studies,Professor Emeritus,Religion2020,2005,53
Religious Studies,Professor Emeritus,Religion2020,2010,127
Religious Studies,Professor Emeritus,Religion2020,2015,44
Religious Studies,Researcher,Religion2020,1995,2
Religious Studies,Researcher,Religion2020,2000,1
Religious Studies,Researcher,Religion2020,2005,2
Religious Studies,Researcher,Religion2020,2010,11
Religious Studies,Researcher,Religion2020,2015,31
Research,Associate Professor,Religion2020,1980,2
Research,Associate Professor,Religion2020,1985,1
Research,Associate Professor,Religion2020,1995,1
Research,Associate Professor,Religion2020,2000,2
Research,Associate Professor,Religion2020,2005,6
Research,Associate Professor,Religion2020,2010,6
Research,Associate Professor,Religion2020,2015,9
Research,Non-Academic,Religion2020,1980,1
Research,Non-Academic,Religion2020,1985,1
Research,Non-Academic,Religion2020,1990,1
Research,Non-Academic,Religion2020,2000,2
Research,Non-Academic,Religion2020,2005,6
Research,Non-Academic,Religion2020,2010,6
Research,Non-Academic,Religion2020,2015,10
Research,Postdoctoral Fellow,Religion2020,2010,3
Research,Postdoctoral Fellow,Religion2020,2015,1
Research,Professor,Religion2020,1985,1
Research,Professor,Religion2020,1995,1
Research,Professor,Religion2020,2000,5
Research,Professor,Religion2020,2005,4
Research,Professor,Religion2020,2010,4
Research,Professor,Religion2020,2015,15
Research,Researcher,Religion2020,1980,3
Research,Researcher,Religion2020,1990,2
Research,Researcher,Religion2020,1995,6
Research,Researcher,Religion2020,2000,14
Research,Researcher,Religion2020,2005,42
Research,Researcher,Religion2020,2010,59
Research,Researcher,Religion2020,2015,89
Social Sciences,,Religion2020,2005,13
Social Sciences,,Religion2020,2010,8
Social Sciences,,Religion2020,2015,13
Social Sciences,Adjunct Professor,Religion2020,1970,2
Social Sciences,Adjunct Professor,Religion2020,1975,1
Social Sciences,Adjunct Professor,Religion2020,1980,1
Social Sciences,Adjunct Professor,Religion2020,1985,2
Social Sciences,Adjunct Professor,Religion2020,1990,1
Social Sciences,Adjunct Professor,Religion2020,1995,6
Social Sciences,Adjunct Professor,Religion2020,2000,4
Social Sciences,Adjunct Professor,Religion2020,2005,2
Social Sciences,Adjunct Professor,Religion2020,2015,1
Social Sciences,Assistant Professor,Religion2020,2000,1
Social Sciences,Assistant Professor,Religion2020,2010,13
Social Sciences,Assistant Professor,Religion2020,2015,42
Social Sciences,Associate Professor,Religion2020,1985,4
Social Sciences,Associate Professor,Religion2020,1995,2
Social Sciences,Associate Professor,Religion2020,2000,1
Social Sciences,Associate Professor,Religion2020,2005,3
Social Sciences,Associate Professor,Religion2020,2010,13
Social Sciences,Associate Professor,Religion2020,2015,20
Social Sciences,Non-Academic,Religion2020,1955,2
Social Sciences,Non-Academic,Religion2020,1960,1
Social Sciences,Non-Academic,Religion2020,1990,2
Social Sciences,Non-Academic,Religion2020,2000,1
Social Sciences,Non-Academic,Religion2020,2005,2
Social Sciences,Non-Academic,Religion2020,2010,1
Social Sciences,Professor,Religion2020,1970,1
Social Sciences,Professor,Religion2020,1980,5
Social Sciences,Professor,Religion2020,1985,1
Social Sciences,Professor,Religion2020,1990,15
Social Sciences,Professor,Religion2020,1995,7
Social Sciences,Professor,Religion2020,2000,16
Social Sciences,Professor,Religion2020,2005,30
Social Sciences,Professor,Religion2020,2010,75
Social Sciences,Professor,Religion2020,2015,78
Social Sciences,Professor Emeritus,Religion2020,1960,1
Social Sciences,Professor Emeritus,Religion2020,1985,11
Social Sciences,Professor Emeritus,Religion2020,1990,8
Social Sciences,Professor Emeritus,Religion2020,1995,3
Social Sciences,Professor Emeritus,Religion2020,2000,3
Social Sciences,Professor Emeritus,Religion2020,2005,10
Social Sciences,Professor Emeritus,Religion2020,2010,19
Social Sciences,Professor Emeritus,Religion2020,2015,6
Social Sciences,Researcher,Religion2020,1990,2
Social Sciences,Researcher,Religion2020,1995,4
Social Sciences,Researcher,Religion2020,2000,6
Social Sciences,Researcher,Religion2020,2005,15
Social Sciences,Researcher,Religion2020,2010,26
Social Sciences,Researcher,Religion2020,2015,36
Social Work,,Religion2020,1970,1
Social Work,,Religion2020,1980,1
Social Work,,Religion2020,1995,7
Social Work,,Religion2020,2000,15
Social Work,,Religion2020,2005,8
Social Work,,Religion2020,2010,10
Social Work,,Religion2020,2015,25
Social Work,Adjunct Professor,Religion2020,1995,1
Social Work,Adjunct Professor,Religion2020,2000,2
Social Work,Adjunct Professor,Religion2020,2005,1
Social Work,Adjunct Professor,Religion2020,2010,3
Social Work,Adjunct Professor,Religion2020,2015,8
Social Work,Assistant Professor,Religion2020,1970,1
Social Work,Assistant Professor,Religion2020,1980,3
Social Work,Assistant Professor,Religion2020,1985,5
Social Work,Assistant Professor,Religion2020,1990,6
Social Work,Assistant Professor,Religion2020,1995,6
Social Work,Assistant Professor,Religion2020,2000,1
Social Work,Assistant Professor,Religion2020,2005,14
Social Work,Assistant Professor,Religion2020,2010,30
Social Work,Assistant Professor,Religion2020,2015,76
Social Work,Associate Professor,Religion2020,1980,1
Social Work,Associate Professor,Religion2020,1985,1
Social Work,Associate Professor,Religion2020,1990,2
Social Work,Associate Professor,Religion2020,1995,5
Social Work,Associate Professor,Religion2020,2000,21
Social Work,Associate Professor,Religion2020,2005,54
Social Work,Associate Professor,Religion2020,2010,112
Social Work,Associate Professor,Religion2020,2015,170
Social Work,Graduate Student,Religion2020,2000,2
Social Work,Graduate Student,Religion2020,2005,2
Social Work,Graduate Student,Religion2020,2010,5
Social Work,Graduate Student,Religion2020,2015,2
Social Work,Non-Academic,Religion2020,1995,5
Social Work,Non-Academic,Religion2020,2000,4
Social Work,Non-Academic,Religion2020,2005,16
Social Work,Non-Academic,Religion2020,2010,24
Social Work,Non-Academic,Religion2020,2015,14
Social Work,Postdoctoral Fellow,Religion2020,2015,5
Social Work,Professor,Religion2020,1950,1
Social Work,Professor,Religion2020,1965,1
Social Work,Professor,Religion2020,1970,3
Social Work,Professor,Religion2020,1975,6
Social Work,Professor,Religion2020,1980,6
Social Work,Professor,Religion2020,1985,22
Social Work,Professor,Religion2020,1990,21
Social Work,Professor,Religion2020,1995,60
Social Work,Professor,Religion2020,2000,103
Social Work,Professor,Religion2020,2005,203
Social Work,Professor,Religion2020,2010,276
Social Work,Professor,Religion2020,2015,258
Social Work,Professor Emeritus,Religion2020,1975,2
Social Work,Professor Emeritus,Religion2020,1980,2
Social Work,Professor Emeritus,Religion2020,1985,3
Social Work,Professor Emeritus,Religion2020,1990,4
Social Work,Professor Emeritus,Religion2020,1995,5
Social Work,Professor Emeritus,Religion2020,2000,11
Social Work,Professor Emeritus,Religion2020,2005,27
Social Work,Professor Emeritus,Religion2020,2010,42
Social Work,Professor Emeritus,Religion2020,2015,32
Social Work,Researcher,Religion2020,1975,1
Social Work,Researcher,Religion2020,1985,1
Social Work,Researcher,Religion2020,1990,4
Social Work,Researcher,Religion2020,1995,3
Social Work,Researcher,Religion2020,2000,5
Social Work,Researcher,Religion2020,2005,7
Social Work,Researcher,Religion2020,2010,16
Social Work,Researcher,Religion2020,2015,40
Sociology,,Religion2020,1950,1
Sociology,,Religion2020,1955,1
Sociology,,Religion2020,1960,6
Sociology,,Religion2020,1965,2
Sociology,,Religion2020,1970,7
Sociology,,Religion2020,1975,13
Sociology,,Religion2020,1980,12
Sociology,,Religion2020,1985,3
Sociology,,Religion2020,1990,9
Sociology,,Religion2020,1995,2
Sociology,,Religion2020,2000,3
Sociology,,Religion2020,2005,22
Sociology,,Religion2020,2010,48
Sociology,,Religion2020,2015,65
Sociology,Adjunct Professor,Religion2020,1910,1
Sociology,Adjunct Professor,Religion2020,1950,3
Sociology,Adjunct Professor,Religion2020,1955,3
Sociology,Adjunct Professor,Religion2020,1960,3
Sociology,Adjunct Professor,Religion2020,1965,5
Sociology,Adjunct Professor,Religion2020,1975,2
Sociology,Adjunct Professor,Religion2020,1980,6
Sociology,Adjunct Professor,Religion2020,1985,12
Sociology,Adjunct Professor,Religion2020,1990,33
Sociology,Adjunct Professor,Religion2020,1995,23
Sociology,Adjunct Professor,Religion2020,2000,29
Sociology,Adjunct Professor,Religion2020,2005,38
Sociology,Adjunct Professor,Religion2020,2010,79
Sociology,Adjunct Professor,Religion2020,2015,56
Sociology,Assistant Professor,Religion2020,1980,1
Sociology,Assistant Professor,Religion2020,1995,4
Sociology,Assistant Professor,Religion2020,2000,11
Sociology,Assistant Professor,Religion2020,2005,43
Sociology,Assistant Professor,Religion2020,2010,150
Sociology,Assistant Professor,Religion2020,2015,248
Sociology,Associate Professor,Religion2020,1910,5
Sociology,Associate Professor,Religion2020,1915,1
Sociology,Associate Professor,Religion2020,1930,2
Sociology,Associate Professor,Religion2020,1935,1
Sociology,Associate Professor,Religion2020,1970,2
Sociology,Associate Professor,Religion2020,1975,2
Sociology,Associate Professor,Religion2020,1980,11
Sociology,Associate Professor,Religion2020,1985,21
Sociology,Associate Professor,Religion2020,1990,14
Sociology,Associate Professor,Religion2020,1995,38
Sociology,Associate Professor,Religion2020,2000,71
Sociology,Associate Professor,Religion2020,2005,158
Sociology,Associate Professor,Religion2020,2010,303
Sociology,Associate Professor,Religion2020,2015,469
Sociology,Graduate Student,Religion2020,1975,1
Sociology,Graduate Student,Religion2020,1980,1
Sociology,Graduate Student,Religion2020,2005,5
Sociology,Graduate Student,Religion2020,2010,17
Sociology,Graduate Student,Religion2020,2015,16
Sociology,Non-Academic,Religion2020,1960,10
Sociology,Non-Academic,Religion2020,1965,3
Sociology,Non-Academic,Religion2020,1970,11
Sociology,Non-Academic,Religion2020,1975,18
Sociology,Non-Academic,Religion2020,1980,11
Sociology,Non-Academic,Religion2020,1985,8
Sociology,Non-Academic,Religion2020,1990,7
Sociology,Non-Academic,Religion2020,1995,7
Sociology,Non-Academic,Religion2020,2000,6
Sociology,Non-Academic,Religion2020,2005,6
Sociology,Non-Academic,Religion2020,2010,15
Sociology,Non-Academic,Religion2020,2015,27
Sociology,Postdoctoral Fellow,Religion2020,2010,10
Sociology,Postdoctoral Fellow,Religion2020,2015,5
Sociology,Professor,Religion2020,1895,1
Sociology,Professor,Religion2020,1900,1
Sociology,Professor,Religion2020,1905,4
Sociology,Professor,Religion2020,1910,10
Sociology,Professor,Religion2020,1915,6
Sociology,Professor,Religion2020,1920,3
Sociology,Professor,Religion2020,1925,7
Sociology,Professor,Religion2020,1930,9
Sociology,Professor,Religion2020,1935,7
Sociology,Professor,Religion2020,1940,3
Sociology,Professor,Religion2020,1945,4
Sociology,Professor,Religion2020,1950,15
Sociology,Professor,Religion2020,1955,15
Sociology,Professor,Religion2020,1960,45
Sociology,Professor,Religion2020,1965,66
Sociology,Professor,Religion2020,1970,75
Sociology,Professor,Religion2020,1975,97
Sociology,Professor,Religion2020,1980,146
Sociology,Professor,Religion2020,1985,183
Sociology,Professor,Religion2020,1990,239
Sociology,Professor,Religion2020,1995,375
Sociology,Professor,Religion2020,2000,500
Sociology,Professor,Religion2020,2005,687
Sociology,Professor,Religion2020,2010,1050
Sociology,Professor,Religion2020,2015,1201
Sociology,Professor Emeritus,Religion2020,1840,1
Sociology,Professor Emeritus,Religion2020,1940,1
Sociology,Professor Emeritus,Religion2020,1945,3
Sociology,Professor Emeritus,Religion2020,1950,4
Sociology,Professor Emeritus,Religion2020,1955,30
Sociology,Professor Emeritus,Religion2020,1960,37
Sociology,Professor Emeritus,Religion2020,1965,96
Sociology,Professor Emeritus,Religion2020,1970,138
Sociology,Professor Emeritus,Religion2020,1975,194
Sociology,Professor Emeritus,Religion2020,1980,187
Sociology,Professor Emeritus,Religion2020,1985,175
Sociology,Professor Emeritus,Religion2020,1990,195
Sociology,Professor Emeritus,Religion2020,1995,164
Sociology,Professor Emeritus,Religion2020,2000,164
Sociology,Professor Emeritus,Religion2020,2005,203
Sociology,Professor Emeritus,Religion2020,2010,251
Sociology,Professor Emeritus,Religion2020,2015,205
Sociology,Researcher,Religion2020,1895,1
Sociology,Researcher,Religion2020,1905,1
Sociology,Researcher,Religion2020,1955,1
Sociology,Researcher,Religion2020,1960,8
Sociology,Researcher,Religion2020,1965,1
Sociology,Researcher,Religion2020,1970,12
Sociology,Researcher,Religion2020,1975,12
Sociology,Researcher,Religion2020,1980,25
Sociology,Researcher,Religion2020,1985,31
Sociology,Researcher,Religion2020,1990,45
Sociology,Researcher,Religion2020,1995,47
Sociology,Researcher,Religion2020,2000,49
Sociology,Researcher,Religion2020,2005,39
Sociology,Researcher,Religion2020,2010,84
Sociology,Researcher,Religion2020,2015,156
Theology,,Religion2020,1940,1
Theology,,Religion2020,1945,4
Theology,,Religion2020,1950,6
Theology,,Religion2020,1955,2
Theology,,Religion2020,1960,10
Theology,,Religion2020,1965,10
Theology,,Religion2020,1970,17
Theology,,Religion2020,1975,13
Theology,,Religion2020,1980,12
Theology,,Religion2020,1985,32
Theology,,Religion2020,1990,33
Theology,,Religion2020,1995,27
Theology,,Religion2020,2000,27
Theology,,Religion2020,2005,39
Theology,,Religion2020,2010,55
Theology,,Religion2020,2015,60
Theology,Adjunct Professor,Religion2020,1910,1
Theology,Adjunct Professor,Religion2020,1920,1
Theology,Adjunct Professor,Religion2020,1925,1
Theology,Adjunct Professor,Religion2020,1930,2
Theology,Adjunct Professor,Religion2020,1940,6
Theology,Adjunct Professor,Religion2020,1945,1
Theology,Adjunct Professor,Religion2020,1950,1
Theology,Adjunct Professor,Religion2020,1955,4
Theology,Adjunct Professor,Religion2020,1960,3
Theology,Adjunct Professor,Religion2020,1965,4
Theology,Adjunct Professor,Religion2020,1970,10
Theology,Adjunct Professor,Religion2020,1975,18
Theology,Adjunct Professor,Religion2020,1980,12
Theology,Adjunct Professor,Religion2020,1985,25
Theology,Adjunct Professor,Religion2020,1990,23
Theology,Adjunct Professor,Religion2020,1995,40
Theology,Adjunct Professor,Religion2020,2000,23
Theology,Adjunct Professor,Religion2020,2005,54
Theology,Adjunct Professor,Religion2020,2010,169
Theology,Adjunct Professor,Religion2020,2015,113
Theology,Assistant Professor,Religion2020,1930,1
Theology,Assistant Professor,Religion2020,1940,2
Theology,Assistant Professor,Religion2020,1945,2
Theology,Assistant Professor,Religion2020,1950,1
Theology,Assistant Professor,Religion2020,1955,1
Theology,Assistant Professor,Religion2020,1960,1
Theology,Assistant Professor,Religion2020,1965,2
Theology,Assistant Professor,Religion2020,1970,2
Theology,Assistant Professor,Religion2020,1975,2
Theology,Assistant Professor,Religion2020,1980,9
Theology,Assistant Professor,Religion2020,1985,12
Theology,Assistant Professor,Religion2020,1990,20
Theology,Assistant Professor,Religion2020,1995,17
Theology,Assistant Professor,Religion2020,2000,24
Theology,Assistant Professor,Religion2020,2005,53
Theology,Assistant Professor,Religion2020,2010,132
Theology,Assistant Professor,Religion2020,2015,267
Theology,Associate Professor,Religion2020,1905,1
Theology,Associate Professor,Religion2020,1910,1
Theology,Associate Professor,Religion2020,1915,1
Theology,Associate Professor,Religion2020,1955,4
Theology,Associate Professor,Religion2020,1960,1
Theology,Associate Professor,Religion2020,1965,7
Theology,Associate Professor,Religion2020,1970,6
Theology,Associate Professor,Religion2020,1975,7
Theology,Associate Professor,Religion2020,1980,18
Theology,Associate Professor,Religion2020,1985,21
Theology,Associate Professor,Religion2020,1990,28
Theology,Associate Professor,Religion2020,1995,56
Theology,Associate Professor,Religion2020,2000,66
Theology,Associate Professor,Religion2020,2005,176
Theology,Associate Professor,Religion2020,2010,330
Theology,Associate Professor,Religion2020,2015,382
Theology,Graduate Student,Religion2020,1955,1
Theology,Graduate Student,Religion2020,1970,1
Theology,Graduate Student,Religion2020,1980,1
Theology,Graduate Student,Religion2020,1985,3
Theology,Graduate Student,Religion2020,1995,1
Theology,Graduate Student,Religion2020,2000,14
Theology,Graduate Student,Religion2020,2005,22
Theology,Graduate Student,Religion2020,2010,57
Theology,Graduate Student,Religion2020,2015,34
Theology,Non-Academic,Religion2020,1900,2
Theology,Non-Academic,Religion2020,1905,1
Theology,Non-Academic,Religion2020,1910,1
Theology,Non-Academic,Religion2020,1915,1
Theology,Non-Academic,Religion2020,1920,2
Theology,Non-Academic,Religion2020,1950,2
Theology,Non-Academic,Religion2020,1955,1
Theology,Non-Academic,Religion2020,1960,2
Theology,Non-Academic,Religion2020,1965,1
Theology,Non-Academic,Religion2020,1970,2
Theology,Non-Academic,Religion2020,1975,7
Theology,Non-Academic,Religion2020,1980,2
Theology,Non-Academic,Religion2020,1985,19
Theology,Non-Academic,Religion2020,1990,19
Theology,Non-Academic,Religion2020,1995,38
Theology,Non-Academic,Religion2020,2000,39
Theology,Non-Academic,Religion2020,2005,50
Theology,Non-Academic,Religion2020,2010,129
Theology,Non-Academic,Religion2020,2015,107
Theology,Postdoctoral Fellow,Religion2020,1955,1
Theology,Postdoctoral Fellow,Religion2020,1960,2
Theology,Postdoctoral Fellow,Religion2020,1985,3
Theology,Postdoctoral Fellow,Religion2020,1990,3
Theology,Postdoctoral Fellow,Religion2020,2000,7
Theology,Postdoctoral Fellow,Religion2020,2005,29
Theology,Postdoctoral Fellow,Religion2020,2010,78
Theology,Postdoctoral Fellow,Religion2020,2015,90
Theology,Professor,Religion2020,1855,1
Theology,Professor,Religion2020,1890,4
Theology,Professor,Religion2020,1895,5
Theology,Professor,Religion2020,1900,8
Theology,Professor,Religion2020,1905,12
Theology,Professor,Religion2020,1910,33
Theology,Professor,Religion2020,1915,33
Theology,Professor,Religion2020,1920,12
Theology,Professor,Religion2020,1925,8
Theology,Professor,Religion2020,1930,18
Theology,Professor,Religion2020,1935,21
Theology,Professor,Religion2020,1940,26
Theology,Professor,Religion2020,1945,44
Theology,Professor,Religion2020,1950,56
Theology,Professor,Religion2020,1955,82
Theology,Professor,Religion2020,1960,96
Theology,Professor,Religion2020,1965,119
Theology,Professor,Religion2020,1970,111
Theology,Professor,Religion2020,1975,155
Theology,Professor,Religion2020,1980,216
Theology,Professor,Religion2020,1985,320
Theology,Professor,Religion2020,1990,367
Theology,Professor,Religion2020,1995,461
Theology,Professor,Religion2020,2000,689
Theology,Professor,Religion2020,2005,1111
Theology,Professor,Religion2020,2010,1821
Theology,Professor,Religion2020,2015,2002
Theology,Professor Emeritus,Religion2020,1895,3
Theology,Professor Emeritus,Religion2020,1900,7
Theology,Professor Emeritus,Religion2020,1910,2
Theology,Professor Emeritus,Religion2020,1915,3
Theology,Professor Emeritus,Religion2020,1920,2
Theology,Professor Emeritus,Religion2020,1925,1
Theology,Professor Emeritus,Religion2020,1935,1
Theology,Professor Emeritus,Religion2020,1940,12
Theology,Professor Emeritus,Religion2020,1945,9
Theology,Professor Emeritus,Religion2020,1950,18
Theology,Professor Emeritus,Religion2020,1955,21
Theology,Professor Emeritus,Religion2020,1960,19
Theology,Professor Emeritus,Religion2020,1965,40
Theology,Professor Emeritus,Religion2020,1970,88
Theology,Professor Emeritus,Religion2020,1975,112
Theology,Professor Emeritus,Religion2020,1980,162
Theology,Professor Emeritus,Religion2020,1985,171
Theology,Professor Emeritus,Religion2020,1990,200
Theology,Professor Emeritus,Religion2020,1995,224
Theology,Professor Emeritus,Religion2020,2000,202
Theology,Professor Emeritus,Religion2020,2005,250
Theology,Professor Emeritus,Religion2020,2010,316
Theology,Professor Emeritus,Religion2020,2015,272
Theology,Researcher,Religion2020,1900,1
Theology,Researcher,Religion2020,1905,5
Theology,Researcher,Religion2020,1915,1
Theology,Researcher,Religion2020,1920,2
Theology,Researcher,Religion2020,1930,2
Theology,Researcher,Religion2020,1935,1
Theology,Researcher,Religion2020,1940,2
Theology,Researcher,Religion2020,1945,4
Theology,Researcher,Religion2020,1950,2
Theology,Researcher,Religion2020,1960,1
Theology,Researcher,Religion2020,1970,1
Theology,Researcher,Religion2020,1975,6
Theology,Researcher,Religion2020,1980,8
Theology,Researcher,Religion2020,1985,10
Theology,Researcher,Religion2020,1990,10
Theology,Researcher,Religion2020,1995,23
Theology,Researcher,Religion2020,2000,38
Theology,Researcher,Religion2020,2005,106
Theology,Researcher,Religion2020,2010,180
Theology,Researcher,Religion2020,2015,257
Zoology,Adjunct Professor,Religion2020,2005,1
Zoology,Adjunct Professor,Religion2020,2010,1
Zoology,Assistant Professor,Religion2020,1895,1
Zoology,Assistant Professor,Religion2020,1900,1
Zoology,Assistant Professor,Religion2020,1905,5
Zoology,Assistant Professor,Religion2020,1910,6
Zoology,Assistant Professor,Religion2020,1915,1
Zoology,Assistant Professor,Religion2020,1925,1
Zoology,Professor,Religion2020,1955,1
Zoology,Professor,Religion2020,1995,1
Zoology,Professor,Religion2020,2010,9
Zoology,Professor,Religion2020,2015,8
Zoology,Professor Emeritus,Religion2020,1975,1
Zoology,Professor Emeritus,Religion2020,1980,2
Zoology,Professor Emeritus,Religion2020,2005,1
Zoology,Professor Emeritus,Religion2020,2010,1
`

export default deptrank;