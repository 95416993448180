const colorsCoAuthorCoop =
    {
        "3440": {
            "r": "0",
            "g": "216",
            "b": "255"
        },
        "549": {
            "r": "0",
            "g": "204",
            "b": "138"
        },
        "41": {
            "r": "247",
            "g": "168",
            "b": "205"
        },
        "9": {
            "r": "255",
            "g": "106",
            "b": "254"
        },
        "470": {
            "r": "0",
            "g": "103",
            "b": "157"
        },
        "18": {
            "r": "101",
            "g": "69",
            "b": "15"
        },
        "42": {
            "r": "252",
            "g": "149",
            "b": "0"
        },
        "535": {
            "r": "82",
            "g": "221",
            "b": "0"
        },
        "569": {
            "r": "255",
            "g": "83",
            "b": "148"
        },
    }

export default colorsCoAuthorCoop;