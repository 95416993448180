import names from "../assets/AuthorNames";
// import authors from "../assets/AuthourPubCountByPentaslice";
import authors from '../assets/reformattedAuthors';
import gender from "../assets/GenderPubCountByPentaslice";
import deptrank from "../assets/DepartmentLastRankByPentaSlice";
import colorMap from "../assets/color_map";
import colorsCoAuthorCoop from "./coauthor_cluster_colors";
import citation_cluster_colors from "./citation_cluster_colors";
import colorsCitationReligANDCoop from "./ReligAndCoopRGBCodes"
import clusterTextDict from "./clusterTextDict";
import {useEffect, useState} from "react";
import rcCitationCodes from "./RelAndCoopRGBCodes";
import clusterTextDict2 from "./clusterTextDict2";

export const useViewport = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

export const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

export const getYearRange = (data, yearIndex) => {
    let min = 5000
    let max = 0

    data.split('\n').forEach(row => {
        const colValues = row.split(',')
        if (parseInt(colValues[yearIndex])) {
            const yearValue = parseInt(colValues[yearIndex])
            min = Math.min(min, yearValue)
            max = Math.max(max, yearValue)
        }
    })

    return [min, max]
}

export const reformatAuthors = () => {
    let output = authors.split('\n')[0] + '\n'
    const numRows = authors.split('\n')[0].split(',').length

    authors.split('\n').forEach((row, index) => {
        if (index !== 0) {
            let rowString = ''
            const cols = row.split(',')

            cols.forEach((col, i) => {
                if (cols !== [''] && names[cols[0].toString()]) {
                    if (i === 0) {
                        rowString += names[cols[0].toString()]['firstName'] + ' ' + names[cols[0].toString()]['lastName'] + ','
                    } else {
                        rowString += col + ','
                    }

                    if (i === numRows - 1) {
                        rowString += '\n'
                    }
                }
            })
            output += rowString
        }
    })
    return output
}

export const removeAuthorSpaces = () => {
    let lines = authors.split('\n')
    const correctLines = []

    lines.forEach(line => {
        if (line.substring(0, 4) === '    ') {
            correctLines.push(line.substring(4))
        } else {
            correctLines.push(line)
        }
    })

    console.log(correctLines.join('\n'))

}

export const getTopSums = (inputData, top, categoryIndex, pubCountIndex, collectionIndex, collection) => {
    let institutions = {}
    let xValues = []
    let yValues = []

    inputData.split('\n').forEach((row, index) => {
        const rowVals = row.split(',')
        const cat = rowVals[categoryIndex]
        if (index !== 0 && cat !== '' && cat !== 'NULL' && cat !== 'NA' && !(cat in institutions) && rowVals[collectionIndex] === collection) {
            institutions[rowVals[categoryIndex]] = parseInt(rowVals[pubCountIndex])
        } else if (index !== 0 && cat !== '' && cat !== 'NULL' && cat !== 'NA' && rowVals[collectionIndex] === collection) {
            institutions[rowVals[categoryIndex]] += parseInt(rowVals[pubCountIndex])
        }
    })

    var sortable = [];
    for (var inst in institutions) {
        sortable.push([inst, institutions[inst]])
    }

    sortable.sort(function (a, b) {
        return b[1] - a[1];
    }).forEach(pair => {
        xValues.push(pair[0])
        yValues.push(pair[1])
    });

    return [xValues.slice(0, top), yValues.slice(0, top)]
}

export const getCategorySums = (data, collection, min, max) => {
    //first get all unique categories
    let categories = {}
    data.split('\n').forEach(row => {
        const colValues = row.split(',')

        const cat = colValues[0]
        const collectionName = colValues[1]
        const pentaStart = colValues[2]
        const pubCount = colValues[3]

        if (collectionName === collection && cat !== 'NULL' && pentaStart >= min && pentaStart <= max) {
            if (cat in categories) {
                categories[cat] += parseInt(pubCount)
            } else {
                categories[cat] = parseInt(pubCount)
            }
        }
    })

    let xValues = []
    let yValues = []

    // go through each cat and make [[xValues],[yValues]] then add to overall dataObject array to return
    for (const [ethkey, value] of Object.entries(categories)) {
        xValues.push(ethkey)
        yValues.push(value)
    }
    return [xValues, yValues]
}

export const getStackedSums = (data, catIndex, yearIndex, collectionIndex, countIndex) => {
    const dataObject = {}

    data.split('\n').forEach(row => {
        const colVals = row.split(',')
        const category = colVals[catIndex]
        const year = colVals[yearIndex]
        const count = colVals[countIndex]
        const collection = colVals[collectionIndex]

        if (dataObject[category] && category !== '') { // category is already in object
            dataObject[category][0].push(year)
            dataObject[category][1].push(count)
            dataObject[category][2].push(collection)
        } else if (category !== '') { // category is new
            dataObject[category] = [[year], [count], [collection]]
        }
    })

    return dataObject
}

export const getPercentages = (data, yearArray, countArray, collectionArray, yearIndex, countIndex) => {
    // get totals for specified years

    const yearSums = {}

    data.split('\n').forEach(row => {
        const colValues = row.split(',')
        const year = colValues[yearIndex]
        if (parseInt(year)) {
            if (yearSums[year]) {
                yearSums[year] += parseInt(colValues[countIndex])
            } else {
                yearSums[year] = parseInt(colValues[countIndex])
            }
        }
    })

    let percentageArray = []

    yearArray.forEach((year, i) => {
        const percent = countArray[i] / yearSums[year] * 100
        percentageArray.push(Math.round(percent * 100) / 100)
    })

    return percentageArray
}

export const getGenderSums = (collection, min, max) => {
    let maleSum = 0
    let femaleSum = 0

    const rows = gender.split('\n')

    rows.forEach(row => {
        const colValues = row.split(',')
        const isInRange = colValues[2] >= min && colValues[2] <= max

        if (colValues[1] === collection && colValues[0] === 'Male' && isInRange) {
            maleSum += parseInt(colValues[3])
        } else if (colValues[1] === collection && colValues[0] === 'Female' && isInRange) {
            femaleSum += parseInt(colValues[3])
        }
    })

    return [['Male', 'Female'], [maleSum, femaleSum]]
}

export const getRankDeptSums = (collection, min, max, selectedDepts) => {
    let dataArray = []
    // go through each dept and make [[xValues],[yValues]] then add to overall dataObject array to return

    selectedDepts.forEach(deptkey => {
        let ranks = {}
        deptrank.split('\n').forEach(row => {
            const colValues = row.split(',')

            const dep = colValues[0]
            const rank = colValues[1]
            const collectionName = colValues[2]
            const pentaStart = parseInt(colValues[3])
            const pubCount = parseInt(colValues[4])

            if (dep === deptkey && collectionName === collection && rank !== 'NULL' && rank !== '' && pentaStart >= min && pentaStart <= max) {
                if (rank in ranks) {
                    ranks[rank] += pubCount
                } else {
                    ranks[rank] = pubCount
                }
            }
        })
        let xValues = []
        let yValues = []
        for (const [key, value] of Object.entries(ranks)) {
            xValues.push(key)
            yValues.push(value)
        }
        dataArray.push([xValues, yValues])
    })
    return dataArray
}

export const sortByYear = (inputData, yearIndex, categoryIndex, minyr, maxyr) => {
    let outputData = []
    let outputString = ''
    let categories = {}

    // filter dataObject by year
    inputData.split('\n').forEach((row, index) => {
        const colValues = row.split(',')
        if (colValues[yearIndex] >= minyr && colValues[yearIndex] <= maxyr) {
            if (index !== 0 && colValues[categoryIndex] !== '' && colValues[categoryIndex] !== 'NULL') {
                outputData.push(colValues)
                categories[colValues[categoryIndex]] = 0
            }
        }
    })

    outputData.sort(function (a, b) {
        return parseInt(a[yearIndex]) - parseInt(b[yearIndex])
    }).forEach(arr => {
        outputString += arr.join(',') + '\n'
    })
    return [outputString, Object.keys(categories)]
}

export const getScatterData = (dataString, categoryIndex, countIndex, yearIndex) => {
    const traceArray = []
    const dataObject = {}

    // dataObject
    // value[0] : year array where years are integers [1990, 1995, 2000...]
    // value[1] : count array where counts are integers [12, 16, 29...]

    // go through rows, make data object
    dataString.split('\n').forEach(row => {
        const colValues = row.split(',')
        const cat = colValues[categoryIndex]
        const count = colValues[countIndex]
        const year = colValues[yearIndex]

        if (cat !== '') {
            if (dataObject[cat] && parseInt(year) === dataObject[cat][0][dataObject[cat][0].length - 1]) { // accounting for more than one collection
                dataObject[cat][1][-1] += parseInt(count)
            } else if (dataObject[cat]) { // category already exists
                dataObject[cat][0].push(parseInt(year))
                dataObject[cat][1].push(parseInt(count))
            } else { // need to create category
                dataObject[cat] = [[parseInt(year)], [parseInt(count)]]
            }
        }
    })

    for (const [key, value] of Object.entries(dataObject)) {
        var trace = {
            x: value[0],
            y: value[1],
            name: key,
            mode: 'lines+markers',
            marker: {color: colorMap[key], size:4},
            type: 'scatter'
        }
        traceArray.push(trace)
    }

    traceArray.sort(function(a,b) {
        return a.name.localeCompare(b.name)
    })

    return [traceArray, Object.entries(dataObject).length]
}

export const filterByCollection = (dataString, collectionIndex, collection) => {
    let filtered = []

    dataString.split('\n').forEach(row => {
        const colValues = row.split(',')
        const coll = colValues[collectionIndex]

        if (coll === collection) {
            filtered.push(row)
        }
    })

    return filtered.join('\n')
}

// very slow if number of categories is too large
export const filterByCategory = (inputData, catIndex, catValue) => {
    let filtered = ''

    inputData.split('\n').forEach((row) => {
        const rowArray = row.split(',')

        let keepRow = true
        catIndex.forEach((targetIndex, i) => {
            catValue.forEach((targetValue, j) => {
                if (i === j && rowArray[targetIndex] !== targetValue) {
                    keepRow = false
                }
            })
        })
        if (keepRow) {
            filtered += row + "\n"
        }
    })

    return filtered
}

// returns csv string of dataObject representing the items only within the specified year range
export const filterByYear = (data, min, max, yearIndex, collectionIndex, collection) => {
    let outString = ''

    min = Math.floor(min / 5) * 5
    max = Math.ceil(max / 5) * 5

    data.split('\n').forEach(row => {
        const year = row.split(',')[yearIndex]
        const coll = row.split(',')[collectionIndex]

        if (coll === collection && year >= min && year <= max) {
            outString += row + '\n'
        }
    })

    return outString
}

export const reformatData = (inputData, intColumns) => {
    let outputData = []

    inputData.split('\n')[0].split(',').forEach(() => {
        outputData.push([])
    })

    inputData.split('\n').forEach(d => {
        d.split(',').forEach((item, index) => {
            if (intColumns.includes(index)) {
                outputData[index].push(parseInt(item))
            } else {
                outputData[index].push(item)
            }
        })
    })

    return outputData
}

// logs the year ranges across collections for manual zero inject (ONLY WORKS FOR E/G/R TABS)
export const getCollectionYearRange = (file) => {
    const yearRanges = {}

    file.split('\n').forEach(row => {
        const colVals = row.split(',')
        const collection = colVals[1]
        const year = parseInt(colVals[2])

        if (collection !== 'collectionName' && collection !== undefined) {
            if (!yearRanges[collection]) {
                yearRanges[collection] = [year, year]
            } else {
                const min = yearRanges[collection][0]
                const max = yearRanges[collection][1]

                yearRanges[collection] = [Math.min(min, year), Math.max(max, year)]
            }
        }
    })
}

// generate object of legend items to display {cNum: {'color': rgb(asdjgawld),'text': 'askdghwkad'}}
export const getLegendObjects = (clusterNumArr, isCoauthor, isNetwork3, isNetwork4) => {
    const legendObjects = {}

    if (isNetwork4) {
        clusterNumArr.forEach(cNum => {
            if (clusterTextDict2[cNum]) {
                let text = clusterTextDict2[cNum].split('~')[0]
                if (text.length > 45) {
                    text = text.substring(0, 45) + '...'
                }

                let color = ''
                const colorObj = rcCitationCodes[cNum]
                color = 'rgb(' + colorObj.r + ',' + colorObj.g + ',' + colorObj.b + ')'
                legendObjects[cNum] = {'color': color, 'text': text}
            }
        })
    }

    clusterNumArr.forEach(cNum => {
        if (clusterTextDict[cNum]) {
            let text = clusterTextDict[cNum].split('~')[0]
            if (text.length > 45) {
                text = text.substring(0, 45) + '...'
            }

            let color = ''
            if (isCoauthor) {
                if (isNetwork3) {
                    const colorObj = colorsCitationReligANDCoop[cNum]
                    color = 'rgb(' + colorObj.r + ',' + colorObj.g + ',' + colorObj.b + ')'
                } else {
                    const colorObj = colorsCoAuthorCoop[cNum]
                    color = 'rgb(' + colorObj.r + ',' + colorObj.g + ',' + colorObj.b + ')'
                }
            } else {
                color = citation_cluster_colors[cNum]
            }

            legendObjects[cNum] = {'color': color, 'text': text}
        }
    })

    return legendObjects
}

export const breakDStats = (text) => {
    text = text.replace('\n','')
    const dID = text.indexOf('Departments:')
    const lID = text.indexOf('Locations:')
    const gID = text.indexOf('Gender:')
    const eID = text.indexOf('Ethnicity:')

    const first = text.substring(0,dID)
    const depts = text.substring(dID,lID)
    const locations = text.substring(lID,gID)
    const gender = text.substring(gID,eID)
    const eths = text.substring(eID,text.length)

    return [first,depts,locations,gender,eths]
}