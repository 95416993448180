import {
    sortByYear,
    getYearRange, getStackedSums, filterByYear, filterByCollection, getCollectionYearRange
} from "../assets/utils";
import rankings from '../assets/LastRankPubCountByPentaslice';
import LPLOT from "./LPLOT";
import {useState} from "react";
import MultiRangeSlider from "./MultiRangeSlider";
import BSPLOT from "./BSPLOT";

const Rank = () => {

    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const yearRange = getYearRange(rankings, 2)
    const [minYear, setMinYear] = useState(yearRange[0])
    const [maxYear, setMaxYear] = useState(yearRange[1] + 4)

    const [dataArray, setDataArray] = useState(fetchData(yearRange[0], yearRange[1]))

    if (isFirstLoad) {
        getCollectionYearRange(rankings)
        setIsFirstLoad(false)
    }

    function fetchData(min, max) {
        const [sorted, categories] = sortByYear(rankings, 2, 0, min, max)

        const religion2020 = filterByCollection(sorted, 1, 'Religion2020')
        const cooperation2021 = filterByCollection(sorted, 1, 'Cooperation2021')
        const coopANDreligion = filterByCollection(sorted, 1, 'CooperationAndReligion')

        const rcCOMBO = religion2020 + '\n' + cooperation2021

        const sortedCOMBO = sortByYear(rcCOMBO, 2, 0, min, max).toString()

        const filteredDataR = filterByYear(sorted, min, max, 2, 1, 'Religion2020')
        const stackDataR = getStackedSums(filteredDataR, 0, 2, 1, 3)

        const filteredDataC = filterByYear(sorted, min, max, 2, 1, 'Cooperation2021')
        const stackDataC = getStackedSums(filteredDataC, 0, 2, 1, 3)

        const filteredDataB = filterByYear(sorted, min, max, 2, 1, 'CooperationAndReligion')
        const stackDataB = getStackedSums(filteredDataB, 0, 2, 1, 3)

        return [religion2020, cooperation2021, coopANDreligion, sortedCOMBO, stackDataR, filteredDataR, stackDataC, filteredDataC, stackDataB, filteredDataB]
    }

    function buttonClicked() {
        const min = parseInt(document.getElementById('leftRankVal').textContent)
        const max = parseInt(document.getElementById('rightRankVal').textContent)

        setMinYear(min - (min % 5))
        setMaxYear(max - (max % 5) + 4)

        setDataArray(fetchData(min - (min % 5), max - (max % 5)))
    }

    return (
        <>
            <div className={'row range-slider'}>
                <div className={'col'}/>
                <div className={'col'}>
                    <MultiRangeSlider
                        min={yearRange[0]}
                        max={yearRange[1] + 4}
                        cat={'Rank'}
                        onChange={({min, max}) => {
                            console.log()
                        }}
                    />
                </div>
                <div className={'col'}>
                    <button className={'btn btn-primary filter-button'} onClick={buttonClicked}>Filter</button>
                </div>
            </div>

            <div className={'row top-row'}>
                <div className={'col centered-text'}>
                    {
                        'Publications per Year by Last Rank between ' + minYear + ' and ' + maxYear
                    }
                </div>
            </div>

            <div className={"row"}>
                <div className={'col-auto'}>
                    <LPLOT dataString={dataArray[0]}
                           title={'Religion'}
                           width={0.31}
                           height={0.5}
                    />
                </div>
                <div className={'col-auto'}>
                    <LPLOT dataString={dataArray[1]}
                           title={'Cooperation'}
                           width={0.31}
                           height={0.5}
                    />

                </div>
                <div className={'col-auto'}>
                    <LPLOT dataString={dataArray[2]}
                           title={'Cooperation and Religion'}
                           width={0.31}
                           height={0.5}
                    />
                </div>
            </div>

            <div className={'row'}>
                <BSPLOT title={'Religion'} dataObject={dataArray[4]}
                        dataString={dataArray[5]}
                        yearIndex={2} countIndex={3}/>
            </div>
            <div className={'row'}>
                <BSPLOT title={'Cooperation'} dataObject={dataArray[6]}
                        dataString={dataArray[7]}
                        yearIndex={2} countIndex={3}/>
            </div>
            <div className={'row'}>
                <BSPLOT title={'Cooperation and Religion'} dataObject={dataArray[8]}
                        dataString={dataArray[9]}
                        yearIndex={2} countIndex={3}/>
            </div>
        </>
    );
}

export default Rank;