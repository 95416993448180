const colorsCitationCoop =
    {
        "224": {
            "r": "0",
            "g": "222",
            "b": "255"
        },
        "353": {
            "r": "175",
            "g": "201",
            "b": "108"
        },
        "101": {
            "r": "46",
            "g": "221",
            "b": "0"
        },
        "286": {
            "r": "0",
            "g": "96",
            "b": "108"
        },
        "773": {
            "r": "234",
            "g": "155",
            "b": "0"
        },
        "332": {
            "r": "255",
            "g": "106",
            "b": "243"
        },
        "100": {
            "r": "0",
            "g": "205",
            "b": "181"
        },
        "978": {
            "r": "255",
            "g": "88",
            "b": "136"
        },
        "617": {
            "r": "255",
            "g": "110",
            "b": "19"
        },
        "187": {
            "r": "80",
            "g": "80",
            "b": "133"
        },
        "698": {
            "r": "119",
            "g": "205",
            "b": "202"
        },
        "980": {
            "r": "82",
            "g": "71",
            "b": "24"
        },
        "445": {
            "r": "0",
            "g": "119",
            "b": "45"
        },
        "691": {
            "r": "171",
            "g": "66",
            "b": "22"
        },
        "340": {
            "r": "204",
            "g": "152",
            "b": "255"
        },
        "68": {
            "r": "255",
            "g": "155",
            "b": "92"
        },
        "665": {
            "r": "161",
            "g": "52",
            "b": "97"
        },
        "102": {
            "r": "159",
            "g": "181",
            "b": "0"
        },
        "80": {
            "r": "0",
            "g": "215",
            "b": "103"
        },
        "308": {
            "r": "0",
            "g": "185",
            "b": "255"
        },
        "565": {
            "r": "241",
            "g": "171",
            "b": "150"
        },
        "305": {
            "r": "226",
            "g": "175",
            "b": "233"
        }
    }

export default colorsCitationCoop;