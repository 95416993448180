const clusterTextDict = {
    "1014": `game theory~ cooperators (2346), gaming (190), dilemmas (1284), socially (1123), prisoners’ (821), evolution (732), public, goods, networks, evolutionary, punishment, groups, reciprocity, behavior, spatial, dynamics, strategy, experiment, promotes, experimental, models, ultimatum, human, effect, populations, interactive, structured, evidence, fairness, selective, altruism, individuals, role, costs, decision, norms, repeated, emerging, free, competition, learning, trusting, sharing, heterogeneous, self, dictators, different, mechanisms, commons, community, reputation`,
    "353": `mammalian (especially primate) cooperation~ socially (297), males (93), evolution (88), primate (82), cooperative (78), females (74), group, dogs, behavior, monkeys, human, bonds, chimpanzees, macaques, wild, behavior, community, vocal, structure, signals, calls, relationships, sex, kind, complexity, different dominance, interactive, patterns, cognitive, gorilla, pan, food, macaca, selection, individual, bonobos, mating, rhesus, adult, baboons, bats, ranging, systems, dolphins, free, influence, reproductive, sexual, species, animal`,
    "224": `evolution of sociality~ social (874), evolution (726), cooperators (701), selects (570), kin (422), genetic (308), group, reproductive, male, ants, population, mating, female, fitness, behavior, structuring, conflict, sex, breeding, competition, sexual, evolutionary, relatedness, behavior, model, colony, queens, dispersal, bees, benefits, hymenoptera, eusocial, interactions, insects, individuals, workers, birds, parasitism, wasps, costs, ecology, signals, systems, communal, recognition, species, altruism, relatives, dynamics, ratio, traits`,
    "100": `evolution of human cooperation~ social (179), cooperative (115), evolution (77), humans (63), behavior (58), religious, cultural, religions, children, brain, group, synchrony, bonding, evidence, signaling, game, community, dilemma, moralizing, music, cognitive, facing, model, function, role, interpersonal, prosocial, synchronization, network, belief, emotion, competition, evolutionary, joint, primates, gods, interactive, predict, relations, ritual, selection, shared, shelf, supernatural, different, effect, increases, psychology, relationships, responses, kin`,
    "332": `experimental psychology of cooperation and bonding~ socially (464), oxytocin (249), behavior (220), games (180), cooperators (148), bonding (139), neural, decisions, ultimatum, responses, human, emotional, pair, brain, voles, interactive, receptor, male, role, associated, fairness, disorder, prairie, modulates, functional, dilemma, group, affects, different, relations, activity, vasopressin, correlation, influence, effect, attachment, economic, trust, regulation, moral, relationship, genes, evidence, systems, stress, parental, cognitive, monogamous, behavior, self, female`,
    "80": `environmental resource management~ management (94) social (71), government (56), fishery (55), cooperative (52), marine (46), change, networks, climate, policy, ecosystem, forest, case, environmental, adaptation, community, conservation, global, model, development, seas, resource, areas, approach, capital, game, international, sustainable, commons, assessing, protected, role, services, systems, conflict, fishing, framework, impact, city, collaboration, dynamics, coastal, ecological, regional, sharing, local, new, urban, integrative, natural, risk, strategy, structures, Africa`,
    "187": `community development~ social (724), capital (310), cohesion (208), community (173), neighborhood (150) networks (92), role, associations, physical, activity, bonds, development, neighbourhood, group, environment, family, relationship, bridging, adolescents, older, adults, relations, support, urban, ethnic, perceived, risk, behavior, inequality, mental, people, self, depressive, policy, factors, influence, American, individual, women, context, cooperative, change, life, impact, case, effect, intervention, measuring, multilevel, collective, evidence, mediators, rural`,
    "340": `business/industrial cooperation~ social (104), cooperative (95), group (46), capital (45), industrial (43), innovation, knowledge, relationships, managing, role, performance, team, sharing, communication, development, systems, organizational, firm, organization, cohesion, behavior, model, perspective, project, case, china, information, integration, trust, effect, conflict, approach, competition, economic, regional, technology, health, dilemma, relations, collaborative, evolution, governance, international, services, evidence, learning, alliance, business, chain, construction, control factors, game`,
    "773": `adolescent development~ socialization (146), adolescents (109), family (103), behavior (81), children (73), parent, school, health, development, model, self, bonds, child, influences, alcohol, risk, selective, peer, relationships, control, drug, functions, genetic, group, prevention, young, youth, depressive, factors, conflicts, problems, substance, cohesion, intervention, related, test, cognitive, community, role, personality, developmental, disorder, students, interactions, cultural, different, ages, American, complexity, male, predictors, programs, adult, associations`,
    "565": `computer network cooperation~ networks (505), cooperative (407), game (285), wireless (148), spectrum, sharing, systems, cognitive, allocation, multi, radios, resource, communications, approach, relay, mobile, theoretic, power, incentive, energy, social, hoc, peer, control, efficient, user, using, model, distributed, secure, channel, scheme, dynamic, mechanism, strategies, coalition, selection, access, device, optimal, aware, data, sensor, service, information, interference, management, trust, algorithm, learning, joint, routing, adaptive, cellular`,
    "308": `plant cooperation~ plants (127), indirect (57), fitness (47), interactions (38), selection (36), pollinators (32), herbivore (31), direct, species, herbivory, predators, defence, defense, evolution, trait, ecological, genetic, nectar, induces, adaptive, community, natural, reproductive, seed, variation, ants, floral, insects, mediates, parasitoid, resistance, affects, volatile, consequences, invasive, different, environment, genes, population, increasing, competition, responses, root, Arabidopsis, enhance, flowering, host, phenotypic, signals, systems`,
    "698": `intercellular cooperation~ cell (82), networks (73), protein (52), signaling (48), systems (39), model, biology, pathway, cooperativity, regulators, yeast, control, genes, activity, functional, response, approach, human, interactions, kinase, dynamic, information, cancer, complex, molecular, using, data, structural, transcriptional, commitment, cycle, computational, stress, expression, factor, fission, cellular, differentiation, prediction, processing, reveals, dependent, mitotic, specific, drugs, game, apoptosis, genomic, integrative, organization, potential, regulatory, selection, social`,
    "617": `gene cooperation in developmental biology~ cell (663), signals (236), regulation (150), development (147), committed (142), lineage (139), differentiation, notch, expression, receptor, stem, activity, hematopoietic, progenitor, cd4, genes, human protein, role, cd8, function, fate, factors, induces, responsiveness, promotes, specification, early, pathway, kinase, transcription, control, selectively, requires, mediator, tcr, thymocyte, lymphocyte, dependent, immune, embryonic, regulatory, cytokine, mechanisms, helper, mice, antigen, notch1, thymic, determines, thymus, versus, target, bone`,
    "978": `intercellular cooperation in abnormal ontogenetic development~ cells, differentiation, stem, signaling, mesenchymal, bone, regulation, human, commitment, osteoblasts, osteogenic, protein, activity, pathway, adipocyte, wnt, factor, lineage, inducible, mediates, marrow, role, adipogenesis, enhances, receptor, expression, promotes, adipogenic, genes, inhibits, derived, stromal, transcription, adipose, bmp, growth, progenitor, fate, function, dependent, development, via, kinase, tissue, acid, control, formation, mechanisms, mouse, skeletal, modulator, morphogenetic, osteogenesis, mice`,
    "980": `intercellular cooperation in ontogenetic development~ cells (334), stem (156), signalling (151), differentiation (118), regulation (112), embryonic (98), human, committed, development, lineage, neural, mouse, pluripotent, progenitor, fate, genes, activation, protein, cardiac, promotes, expression, factor, wnt, specification, inducing, controls, early, pathway, growth, mediators, required, transcriptional, cooperative, role, dependent, muscle, neurons, function, receptor, inhibits, bmp, modulation, notch, direct, molecular, embryos, via, target, cancer, interaction, kinase, mechanisms, tgf, acid,`,
    "549": `Psychological game theory 1~With central hubs in Japan and California and authors located primarily in psychology and economics
departments, this coauthorship network studies classical game theory using the methods of
experimental psychology.*

social (706), cooperative (602), dilemmas (430), game (358), groups (226), effects (202), behavior
(184), public (138), trustful (125), reciprocity (123), goods (122), prisoner (117), role (106), human (105),
evolution (104), networks (98), punishment (97), studying (96), decision (94), fairness (91), based (84),
intergroup (81), commons (80), selective (80), ultimatum (79), promotes, strategies, experimental,
resource, oxytocin, evolutionary, different, sharing, individuals, moral, value, making, orientation,
justice, community, policing, neural, emotional, experiment, cultural, conflict, preferences, economic,
self, analysis, dynamics, reputation, theory, peer, procedural, relations, unfair, function, indirect, norms,
collective, dictator, model, exchange, motives, rejection, system, person, perspective, competition,
influence, interactions, problem, versus, psychology

    Departments: psychology (50), economics (27), social psychology (15), political science (11), sociology (9), law (7), computer science (6), engineering (6)

Locations: California (13), Hokkaido (12), Tokyo (11), Amsterdam (7), Los Angeles (7), Groningen (6), Harvard (6), Tsinghua (5)

Gender: 136 male, 41 female

Ethnicity: 105 white/Caucasian, 55 East/Southeast Asian, 10 South Asian, 1 Hispanic/Latinx, 1 African/black, 1 other,`,
    "9": `Evolution of animal cooperation~Based primarily in the US and UK with authors located in zoology, biology, and ecology departments,
this coauthorship network examines the evolution of sociality and cooperation among diverse animal
species, including eusocial insects.*

Cooperatively (773), social (444), evolution (398), selects (218), kin (200), theory (142), breeding (141),
genetic (129), group (128), conflict (127), fitness (121), reproductive (118), eusociality (108), game (105),
sex (103), relatedness (97), human (95), evolutionary (94), reciprocity (86), populations (83), inclusive
(78), signals (78), ants (76), bird (76), model, sexual, competition, effects, behavior, behaviour, benefits,

structure, goods, individual, bacteria, helpers, helping, societies, altruism, punishment, dispersal, costs,
dynamics, female, queen, male, mating, public, communication, strategies, direct, pseudomonas,
colony, insects, interactions, parents, ecology, aeruginosa, quorum, rats, relatives, sensing, complex,
role, increases, productivity, wasps, systems, bacteria, bee, variation, biological, differences, dilemma,
responses

Departments: zoology (41), evolutionary biology (27), ecology (25), biology (17), psychology (11), genetics (1), anthropology (9), mathematics (7)

Locations: Oxford (14), Cambridge (12), California (11), Cornell (8), Michigan (8), Berkeley (6), Harvard (6), Lausanne (6)

Gender: 135 male, 29 female

Ethnicity: 151 white/Caucasian, 4 East/Southeast Asian, 2 Hispanic/Latinx, 1 South Asian, 1 other`,
    "42": `Systems theory and cooperation~With hubs in England, China, and the U.S. and based in engineering, health, and psychology
departments this coauthorship network is focused on systems theory and cooperation. A possible junk cluster caused by a failure to accurately distinguish multiple authors, especially those
of Asian descent (Wei Li, Jing Liu, Wei Wang, etc.) who share the same name.*

cooperative (388), networks (234), game (174), health (165), social (160), based (145), optimizer (103),
cell (97), development (95), globalization (92), effects (81), control (75), community (75), systems (73),
resource (65), analysis (64), power (63), signals (62), allocation (60), china (59), using (59), foraging (57),
competition (56), approach (54), sharing (54), wireless, strategy, bacterial, algorithm, managing, study,
behavior, policy, theory, model, learning, promotion, regulation, mechanism, relay, public, capital,
distributed, notch, information, efficient, role, theoretic, impact, dilemma, evolution, dynamic, chapter,
international, joint, selection, achieving, differentiation, commitment, government, cohesion,
perspective, evolutionary, prisoner, colony, lineage, relationships, sustainable, associational, goods,
structures, channel, data, design, function

Departments: engineering (17), health (10), psychology (10), psychology (10), information science (9), medicine (9), computer science (6), social science (6), sociology (6)

Locations: London (6), Florida (5), Peking (5), York (5), Berkeley (3), California (3), Hong Kong (3), Michigan (3), Nanjing (3)

Gender: 94 male, 32 female

Ethnicity: 58 East or Southeast Asian, 47 white/Caucasian, 15 South Asian, 2 Hispanic/Latinx, 1 African/black, 1 other`,
    "470": `Evolution of human cooperation~A coauthorship network spread across the globe, based primarily in physics, mathematics, and biology
departments, and focusing on game theory and the evolution of cooperation in human societies.*

cooperators (778), games (473), evolution (360), social (351), dilemma (294), evolutionary (264),
dynamics (212), networks (197), reciprocity (165), goods (164), prisoner (159), public (152), populations
(150), punishment (144), spatial (114), structures (104), strategy (99), selection (98), group (96),
behavior (91), humans (91), effects (88), indirect (77), interaction (70), promote (68), experiments,
model, complex, emerging, costly, theory, adaptive, altruism, reputation, direct, using, graphs, repeated,
collective, rewards, commons, norms, role, heterogeneous, culture, signaling, scale, information,
change, experimental, learning, agents, iterated, preferences, inequality, based, competition, one,
climate, continuous, multiplayer, commitment, fairness, free, player, action, altruistic, analysis,
mechanisms, two, ultimatum, optional, conditional, decision, ecological

Departments: physics (29), mathematics (22), biology (17), economics (13), psychology (10), computer science (8), philosophy (6), political science (5)

Locations: London (7), Harvard (6), Zaragoza (6), California (5), Vienna (5), Kiel (4), Kyoto (4)

Gender: 102 male, 11 female

Ethnicity: 73 white/Caucasian, 15 East/Southeast Asian, 13 Hispanic/Latinx, 3 South Asian, 2 other`,
    "535": `Economic game theory 1~Based in Europe and the U.S. with authors located primarily in economics and psychology
departments, this coauthorship network is focused on game theory in an experimental context,
including studies measuring the influence of hormones such as oxytocin.*

game (313), cooperators (300), social (254), public (177), dilemmas (165), goods (164), experiment
(141), experimental (133), ultimatum (122), behavior (111), punishment (108), group (107), oxytocin
(76), study (76), evidence (68), effects (66), bargaining (63), fairness (63), self (56), intergroup (51),
prisoner (51), contributions (47), provision (46), person (44), model (44), trust, human, reciprocity,
communication, theory, interaction, motivated, strategic, level, repeated, versus, norms, time, dictator,
information, conflict, associated, decision, competition, role, formation, analysis, choice, control, non,
evolution, parent, step, understanding, response, sharing, different, relative, gene, receptor, economic,
strategies, team, two, power, learning, testing, cognitive, individual, infant, institutions, chapter,
dynamics, voluntary, sanctioning

Departments: economics (63), psychology (22), social psychology (16), management (6), behavioral psychology (5)

Locations: Amsterdam (8), Bonn (5), North Carolina (5), Champaign Illinois (5), Urbana (5), Jerusalem (4), Hebrew (4), Munster (4)

Gender: 86 male, 28 female

Ethnicity: 91 white/Caucasian, 8 East/Southeast Asian, 4 Hispanic/Latinx, 3 African/black`,
    "3440": `Religion and human cooperation~Dispersed across the US and Europe and based out of anthropology and psychology departments, this
coauthorship network is focused on the evolution of human cooperation, including a focus on the role
of religion in that evolution.*

cooperation (144), human (119), societies (111), evolution (108), social (106), cultural (73), punishment
(62), gods (55), religions (51), behavior (47), religious (47), across (42), prosocial (39), group (38), moral
(38), ritual (37), cross (35), primate (35), game (33), experimental (32), effects (31), small (30), selective
(29), supernatural (29), scale (28), costs, history, cognition, kin, perspective, hunter, sharing, evidence,
increases, synchrony, bonds, analysis, experiences, female, life, baboons, beliefs, evolutionary, complex,
coordination, patterns, reciprocal, collective, moralistic, role, gatherers, trust, field, grooming, origins,
people, spotted, wild, natural, norm, structure, crocuta, economic, explain, food, larger, dynamics,
promote, relationships, altruism, altruistic, children, ecology, male, material

Departments: anthropology (35), psychology (25), religion (12), biology (6), economics (5), sociology (4)

Locations: California (12), Aarhus (10), Los Angeles (7), New Mexico (7), University of Cambridge (5), Harvard (5), Michigan (4), Stanford (4)

Gender: 56 Male, 19 Female

Ethnicity: 65 white/Caucasian, 2 East/Southeast Asian, 2 South Asian, 2 other, 1 Hispanic/Latinx`,
    "18": `Computer networks and cooperation~Based primarily out of China and composed primarily of East or Southeast Asian scholars located in
engineering and computer science departments, this coauthorship network is focused on game
theory, especially as applied to computer networks.*

game (752), cooperative (741), dilemma (410), networks (385), prisoner (282), spatial (281), public (251),
promotes (243), evolutionary (239), evolution (237), goods (227), social (214), dynamics (182), based
(179), strategy (164), populations (134), effects (119), group (103), interactive (92), structured (91),
mechanisms (88), systems (79), punishment (75), reciprocity (75), heterogeneous (73), coevolution,
selective, behavior, voluntary, reputation, incentive, model, role, adaptive, interdependent, aspiration,
enhancing, diversity, environments, scale, optimization, impact, learning, analysis, driven, self,
switching, agents, emergence, coevolutionary, neighborhood, ultimatum, p2p, fitness, experiments,
community, fairness, theory, updating, control, cost, partner, risk, different, individual, mixing, mobile,
free, payoff, snowdrift, migration, stability, competition, complex, stochastic

Departments: engineering (24), computer science (20), information science (11), biology (10), physics (5)

Locations: Beijing (4), china (4), Peking (4), Xidian (4), Cork (3), Groningen (3), Hong Kong (3), Kyushu (3), Lausanne (3), Tsinghua (3)

Gender: 84 male, 12 female

Ethnicity: 54 East or Southeast Asian, 28 white/Caucasian, 9 South Asian, 1 Hispanic/Latinx, 1 other`,
    "41": `Economic game theory 2~Based in the U.S. and Europe and grounded primarily in economics departments, this network is
focused on experimental approaches to game theory and the evolution of altruism.*

cooperators (301), social (218), public (190), games (188), good (185), dilemma (128), experiments
(115), punishments (115), behavior (97), experimental (84), evolution (79), evidence (78), preferences
(63), selection (63), human (63), prisoners’ (60), group (59), provision (59), effects (57), reciprocity (47),
voluntary (47), norms (46), theory (45), contributions (41), strategic (39), role, cultural, fairness, motives,
altruistic, altruism, personality, giving, environmental, institutions, model, dictator, economic,
ultimatum, costly, global, moral, nature, networks, approach, individual, kin, study, trust, signals, versus,
coordination, dynamics, conditional, heterogeneous, inequity, international, two, analysis, risk,
information, pro, sanctions, competition, level, populations, response, behavior, climate, efficiency,
free, matching, mechanisms, non, repeated

Departments: economics (60), sociology (13), psychology (8), mathematics (6), anthropology (5), biology (5)

Locations: California (6), Utrecht (6), Davis (5), Berlin (4), Bonn (4), Chicago (4), Mannheim (4), Zurich (4)

Gender: 78 male, 7 female

Ethnicity: 76 white/Caucasian, 1 East/Southeast Asian, 1 Hispanic/Latinx, 1 South Asian`,
    "569": `Psychological game theory 2~Based in the U.S. and Europe with authors located primarily in psychology, sociology, engineering, and
psychiatry departments, this network is focused on experimental approaches to game theory,
especially those exploring the role of hormones such as oxytocin and vasopressin.*

cooperative (194), game (181), social (109), effects (65), oxytocin (62), dilemma (54), spatial (48), neural
(47), based (44), decision (41), interaction (41), prisoner (38), bargaining (37), human (37), response
(37), systems (35), ultimatum (35), model (34), making (33), theory (33), behavior (32), evidence (31),

goods (31), individual (31), public (31), study, economic, network, supply, experimental, coordination,
promotes, chains, increasing, impact, vasopressin, evolution, fairness, approach, modulates, sharing,
dynamics, losses, manufacturer, reciprocity, intranasal, power, norms, agent, different, evaluation,
brain, management, religion, role, trust, advertising, analysis, experiment, lattices, non, charity, retailer,
self, using, china, exchanges, neighborhood, strategy, bias, cognitive, community, computing,
connectivity, preferences

Departments: psychology (17), sociology (11), engineering (9), psychiatry (8), medicine (7), computer science (5), neuroscience (5), political science (5), economics (4)

Locations: California (6), Texas (6), Austin (5), London (4), Berlin (3)

Gender: 53 male, 15 female

Ethnicity: 44 white/Caucasian, 17 East or Southeast Asian, 4 South Asian, 1 African/black`,
    "13": `Gods, cooperation, and conflict~ 

Based in North American and Europe and operating primarily out of psychology and anthropology departments, this coauthorship network is focused on the role of gods and other supernatural agents in promoting cooperation.* 

religion (127), religious (124), cultural (109), gods (92), evolution (90), social, cooperation, belief, human, morality, prosocial, punishment, cognitive, cross, societies, behavior, ritual, supernatural, evidence, atheists, evolutionary, experimental, group, mind, science, parochialism, system, moralistic, believe, control, history, prejudice, predict, priming, explain, material, people, psychology, big, children, game, increases, adaptive, life, synchrony, theory, identity, trust, anti, bonding, costs Departments: psychology (19), anthropology (12), religion (7), evolutionary biology (2), social psychology (2) 

Locations: British Columbia (7), Los Angeles (3), Belfast (3), California (3), Harvard (3), Masaryk (3), Stanford (3), Connecticut (2) 

Gender: 21 Male, 10 Female 

Ethnicity: 26 Caucasian/White, 1 East/Southeast Asian, 1 Hispanic/Latinx, 1 Multiracial, 1 Other, 1 South Asian `,
    "154": `Religion and organ donation~ 

Based in Latin America and located primarily in medical schools and psychology departments, this coauthorship network is focused on the factors that influence people’s willingness to donate organs, an extreme example of cooperation.* 

donation (282), attitude (227), toward (199), organ (192), Spain (181), resident, population, living, Cuba, transplantation, kidney, factors, Spanish, hospitals, American, Latin, brain, concept, death, affecting, related, personnel, multicenter, multivariate, xenotransplantation, actitud, donacion, hacia, hospitalarios, citizens, knowledge, Mexico, medical, students, Florida, immigrants, Mexican, united, Ecuadorian, studio, multicentrico, Santiago, lantinoamericano, profesionalessanitarios, vivo,  

Departments: medicine (11), psychology (6), oncology (2), surgery (2) 

Locations: Universidad Catolica (6), Universidad de Guanajuato (6), Baja Mexico (2)  

Gender: 22 Male, 12 Female 

Ethnicity: 30 Hispanic/Latinx, 4 Caucasian/White `,
    "86": `Ritual and cooperation~ 

With key hubs in Europe, New Zealand, and the U.S. and operating primarily out of psychology and anthropology departments, this coauthorship network explores the role of religious rituals in promoting cooperation.* 

religions (142), religious (109), ritual (97), cooperative (85), cultural, evolution, cognitive, human, belief, moral, gods, social, behavior, experiences, synchrony, predictive, societies, theory, new, complex, punishment, death, increases, prosociality, collective, extreme, group, history, science, action, approach, supernatural, affect, evolutionary, nature, minds, experimental 

Departments: psychology (10), anthropology (9), religion (5), economics (4), history (3), social science (4) classic (2), cognitive science (2) philosophy (2),  

Locations: Aarhus (9), Auckland (2), California (2), Otago (2), Oxford (2), Princeton (2) 

Gender: 32 Male, 1 Female 

Ethnicity: 31 Caucasian/White, 1 East/Southeast Asian, 1 South Asian`,

    "29": `Religion, violence, and cooperation~ 

Based primarily in California and operating out of sociology and political science departments, this coauthorship network examines the role of religion in mediating both cooperation and violent conflict.*  

religions (132), religious (92), state (46), global, civilizations, conflict, society, secular, discrimantion, charity, minorities, violence, politics, rituals, degradation, nationalism, democracies, international, ethnic, world, new, relations, theory, causes 

Departments: sociology (7), political science (4) 

Locations: California (3), Berkeley (2), Berlin College (1) 

Gender: 9 Male, 3 Female 

Ethnicity: 9 Caucasian/White, 1 African/Black, 1 East/Southeast Asian `,
    "77": `Spirituality and palliative care~  

Based in Europe and New Zealand and grounded primarily in medical schools, this coauthorship network examines the influence of religious and spiritual beliefs in end of life and palliative care.*  

spiritual (38), religious (25), care (23), beliefs (19), life, people, adults, illness, palliative, cancer, disease, interventions, phase, terminal, black, British, Caribbean, end, white, cultural, qualitative, health, multiple, patients, sclerosis, affected, development 

Departments: medicine (5), palliative care (2) 

Locations: London (2), Auckland (1), Leiden (1) 

Gender: 5 Male, 1 Female 

Ethnicity: 6 Caucasian/White`,
    "90": `Spirituality and addiction~ 

With representatives in Canada, the U.S., and New Zealand and grounded in medical schools and departments of psychiatry, this coauthorship network is focused on spirituality’s role in helping people address alcohol and drug addiction.* 

spirituality (53), anonymous (32), alcoholics (24), recovery (20), drug (18), religious (18), community, members, orientation, addiction, contemporary, group, sect, step, therapeutic, medical, narcotics, psychiatric, twelve, role, findings, psychological, religion, student, treatment, attitudes 

Departments: medicine (3), clinical psychiatry (2), psychology (1) 

Locations: Albert College (1), Otago (1), New York (1) 

Gender: 5 Male, 1 Female 

Ethnicity: 6 Caucasian/White`
}

export default clusterTextDict