import {filterByYear, getTopSums, getYearRange} from "../assets/utils";
import BPLOT from "./BPLOT";
import finalauthors from "../assets/finalAuthors";
import MultiRangeSlider from "./MultiRangeSlider";
import {useState} from "react";

const Author = () => {
    const yearRange = getYearRange(finalauthors, 2)

    const startData = fetchData(yearRange[0], yearRange[1])

    const [minYear, setMinYear] = useState(yearRange[0])
    const [maxYear, setMaxYear] = useState(yearRange[1] + 4)

    const [dataArray, setDataArray] = useState(startData)

    function fetchData(min, max) {
        const filteredData = filterByYear(finalauthors, min, max, 2)
        const rankDeptDataREL = getTopSums(filteredData, 10, 0, 3, 1, 'Religion2020')
        const rankDeptDataCOP = getTopSums(filteredData, 10, 0, 3, 1, 'Cooperation2021')
        const rankDeptDataBOTH = getTopSums(filteredData, 10, 0, 3, 1, 'CooperationAndReligion')

        return [rankDeptDataREL, rankDeptDataCOP, rankDeptDataBOTH]
    }

    function buttonClicked() {
        const min = parseInt(document.getElementById('leftAuthorVal').textContent)
        const max = parseInt(document.getElementById('rightAuthorVal').textContent)

        const filteredData = fetchData(min - (min % 5), max - (max % 5))
        setMinYear(min - (min % 5))
        setMaxYear(max - (max % 5) + 4)
        setDataArray(filteredData)
    }

    return (
        <>
            <div className={'row range-slider'}>
                <div className={'col'}/>
                <div className={'col'}>
                    <MultiRangeSlider
                        min={yearRange[0]}
                        max={yearRange[1] + 4}
                        cat={'Author'}
                        onChange={({min, max}) => {
                            console.log()
                        }}
                    />
                </div>
                <div className={'col'}>
                    <button className={'btn btn-primary filter-button'} onClick={buttonClicked}>Filter</button>
                </div>
            </div>

            <div className={'row top-row'}>
                <div className={'col centered-text'}>
                    {
                        'Top 10 Authors by Number of Publications between ' + minYear + ' and ' + maxYear
                    }
                </div>
            </div>

            <div className={"row"}>
                <div className={'col-4'}>
                    <BPLOT
                        title={'Religion'}
                        data={dataArray[0]}
                        height={0.7}/>
                </div>
                <div className={'col-4'}>
                    <BPLOT
                        title={'Cooperation'}
                        data={dataArray[1]}
                        height={0.7}/>
                </div>
                <div className={'col-4'}>
                    <BPLOT
                        title={'Religion and Cooperation'}
                        data={dataArray[2]}
                        height={0.7}/>
                </div>
            </div>
        </>
    );
}

export default Author;