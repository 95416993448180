const colorsCitationReligANDCoop = {
  "86": {
    "r": "0",
    "g": "198",
    "b": "244"
  },
  "13": {
    "r": "0",
    "g": "195",
    "b": "78"
  },
  "90": {
    "r": "195",
    "g": "159",
    "b": "0"
  },
  "29": {
    "r": "255",
    "g": "104",
    "b": "86"
  },
  "77": {
    "r": "114",
    "g": "106",
    "b": "100"
  },
  "154": {
    "r": "238",
    "g": "133",
    "b": "243"
  }
}

export default colorsCitationReligANDCoop;