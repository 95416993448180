// import {breakDStats} from "../assets/utils";

import clusterTextDict2 from "../assets/clusterTextDict2";

const ClusterText2 = ({textNumber}) => {
    let allText = ''
    let title = ''
    let text = ''
    let description = ''
    // let textArr = []

    if (clusterTextDict2[textNumber.toString()]) {
        allText = clusterTextDict2[textNumber.toString()].split('~')
        title = allText[0]
        text = allText[1]
        const testText = text.split('*')
        if (testText.length > 1) {
            description = testText[0]
            text = testText[1]
        }
        // textArr = breakDStats(text)
    }

    return (
        allText !== '' &&
        <>
            <b>{title}</b>
            {
                description !== '' && <p style={{'marginTop': '10px'}}>{description}</p>
            }
            <p style={{'marginTop': '10px'}}>{text}</p>
        </>
    )
}

export default ClusterText2;